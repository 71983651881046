<template>
  <div class="root">
    <div class="input-group">
      <select class="form-control" style="width: auto;margin-left:0px" v-model="week" @change="onChangeWeek($event)">
        <option value=1>월</option>
        <option value=2>화</option>
        <option value=3>수</option>
        <option value=4>목</option>
        <option value=5>금</option>
        <option value=6>토</option>
        <option value=7>일</option>
      </select>
      <!-- <select class="form-control" style="width: auto;" v-model="hour" @change="onChangeHour($event)">
        <option :value="null">종일</option>
        <option value="0">00시</option>
        <option value="1">01시</option>
        <option value="2">02시</option>
        <option value="3">03시</option>
        <option value="4">04시</option>
        <option value="5">05시</option>
        <option value="6">06시</option>
        <option value="7">07시</option>
        <option value="8">08시</option>
        <option value="9">09시</option>
        <option value="10">10시</option>
        <option value="11">11시</option>
        <option value="12">12시</option>
        <option value="13">13시</option>
        <option value="14">14시</option>
        <option value="15">15시</option>
        <option value="16">16시</option>
        <option value="17">17시</option>
        <option value="18">18시</option>
        <option value="19">19시</option>
        <option value="20">20시</option>
        <option value="21">21시</option>
        <option value="22">22시</option>
        <option value="23">23시</option>
      </select> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    weekval: {
      type: Number,
      default: 1,
    },
    hourval:{
      type: Number,
      default: null,
    },
    onWeek:{
      type: Function,
      default: ()=>{},
    },
    onHour:{
      type: Function,
      default: ()=>{},
    }
  },
  data() {
    return {
      week:this.weekval,
      hour:this.hourval,
    };
  },
  computed: {},
  watch: {
    weekval()
    {
      this.week = this.weekval;
    },
    hourval()
    {
      this.hour = this.hourval;
    }
  },
  mounted() {
    this.hour = this.hourval;
    this.week = this.weekval;
  },
  methods: {
    onChangeWeek(event){
      this.onWeek(parseInt(event.target.value));
    },
    onChangeHour(event){
      this.onHour(parseInt(event.target.value));
    }
  },
};
</script>
<style scoped>
select {
  margin: 0px 5px;
}
@media (min-width: 768px) {
  .root {
    display: inline-flex;
  }
}
</style> 