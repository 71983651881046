<template>
  <section class="content">
    <div class="col-sm-offset-4 col-sm-4">
      <div class="box box-solid">
        <div class="box-body form-horizontal">
          <div class="form-group">
            <label for="userid" class="col-sm-4 control-label">아이디</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                :value="authuser"
                id="userid"
                placeholder="아이디"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="username" class="col-sm-4 control-label">이름</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                v-model="username"
                id="username"
                placeholder="이름"
              />
              <span class="text-red">{{namemsg}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="email" class="col-sm-4 control-label">이메일</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="email" id="email" placeholder="이메일" />
              <span class="text-red">{{emailmsg}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="phonenum" class="col-sm-4 control-label">휴대전화</label>
            <div class="col-sm-3">
              <select class="form-control" v-model="phonehead">
                <option>010</option>
                <option>011</option>
              </select>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                id="phonenum"
                v-model="phonenum"
                placeholder="휴대전화"
              />
              <span class="text-red">{{phonemsg}}</span>
            </div>
          </div>
        </div>
        <div class="box-footer text-center">
          <button type="button" class="btn btn-default" style="margin-right:10px" @click="cancel">취소</button>
          <button type="button" class="btn btn-success" @click="clickSave">저장</button>
        </div>
      </div>
    </div>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-body ">
            <p>수정이 완료되었습니다.</p>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-success" @click="closemodal">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import { reqPost, reqGet } from "../../lib/request";
import { adminapi } from "../../lib/api";
export default {
  data() {
    return {
      username: "",
      namemsg: "",
      email: "",
      emailmsg: "",
      phonenum: "",
      phonemsg: "",
      phonehead: "010",
      showmodal: false,
    };
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_HOME,
      path2: menuval.LV1_HOME,
      title: "관리자 정보 변경",
    });
    reqGet(adminapi.base, adminapi.myInfo, this.authToken, (data) => {
      if (data != null) {
        if (data.code == rescode.REQ_SUCCESS) {
          this.username = data.data.fullName;
          this.email = data.data.email;
          if (data.data.phoneNumber != null && data.data.phoneNumber != "") {
            this.phonenum = data.data.phoneNumber.substring(3);
            this.phonehead = data.data.phoneNumber.substring(0, 3);
          }
        }
      }
    });
  },
  computed: {
    authuser() {
      return this.$store.getters.authuser;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  methods: {
    clickSave() {
      if (this.username == "") {
        this.namemsg = "이름을 입력하세요.";
        return;
      } else {
        this.namemsg = "";
      }

      if (this.email == "") {
        this.emailmsg = "이메일을 입력하세요.";
        return;
      } else {
        this.emailmsg = "";
      }

      // if (this.phonenum == "") {
      //   this.phonemsg = "휴대폰번호를 입력하세요.";
      //   return;
      // } else {
      //   this.phonemsg = "";
      // }

      var reqphone = "";
      if (this.phonenum != "") {
        reqphone = this.phonehead + this.phonenum;
      }

      reqPost(
        adminapi.base,
        adminapi.changeInfo,
        this.authToken,
        {
          fullName: this.username,
          email: this.email,
          phoneNumber: reqphone,
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              // this.namemsg = "관리자 정보변경되었습니다.";
              this.showmodal = true;
              // this.$router.push('/');
            } else {
              this.namemsg = data.message;
            }
          } else {
            this.namemsg = "정보변경 실패되었습니다..";
          }
        }
      );
    },
    cancel() {
      this.$router.push("/");
    },
    closemodal() {
      this.showmodal = false;
    }
  },
};
</script>
<style scoped>
.modal-dialog{
  top: 40%;
}
</style>