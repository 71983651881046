<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword">전체</option>
            <option value="FullNameContaining">이름</option>
            <option value="Username">아이디</option>
            <option value="Email">이메일</option>
            <option value="PhoneNumber">전화번호</option>
            <option value="PhoneNumber4">전화번호 끝 4자리</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width:70px" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>번호</th>
              <th>아이디</th>
              <th>이름</th>
              <th>이메일</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in content" v-bind:key="user.username">
              <td>{{ totalElements - (startnum + index) }}</td>
              <td>
                <router-link :to="routes.memdetail+'?viewid='+user.username">{{user.username}}</router-link>
              </td>
              <td>
                <router-link :to="routes.memdetail+'?viewid='+user.username">{{user.fullName}}</router-link>
              </td>
              <td>{{user.email}}</td>
              <td>{{viewDate(user.registeredDate)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import { reqPost } from "../../lib/request";
import DetePicker from "../../components/DatePicker";
import { adminapi } from "../../lib/api";
import moment from 'moment';
import { DateFormat } from "../../lib/utility";
export default {
  components: {
    Pagination,
    DetePicker,
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      dateFrom: "",
      dateTo: "",
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  created() {
    if(localStorage.initval == "1")
    {
      if(localStorage.MEMG_dateFrom) {
        this.dateFrom = localStorage.MEMG_dateFrom;
      }
      if(localStorage.MEMG_dateTo) {
        this.dateTo = localStorage.MEMG_dateTo;
      }
      if (localStorage.MEMG_keywordType) {
        this.keywordType = localStorage.MEMG_keywordType;
      }
      if (localStorage.MEMG_keyword) {
        this.keyword = localStorage.MEMG_keyword;
      }
      if (localStorage.MEMG_Number) {
        this.pageNumber = parseInt(localStorage.MEMG_Number);
      }
      if (localStorage.MEMG_Size) {
        this.pageSize = parseInt(localStorage.MEMG_Size);
      }
    }
    this.$store.dispatch("doSetPath", { path1: menuval.LV1_MEM, path2: menuval.LV1_HOME, title: "일반회원" });
  },
  watch: {
    dateFrom(newfrom) {
      localStorage.MEMG_dateFrom = newfrom;
    },
    dateTo(newto) {
      localStorage.MEMG_dateTo = newto;
    },
    keywordType(newVal) {
      localStorage.MEMG_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.MEMG_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.MEMG_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.MEMG_Size = newsize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.MEMG_dateFrom = this.dateFrom;
      localStorage.MEMG_dateTo = this.dateTo;
      localStorage.MEMG_keywordType = this.keywordType;
      localStorage.MEMG_keyword = this.keyword;
      localStorage.MEMG_Number = this.pageNumber;
      localStorage.MEMG_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage();
    },
    onSearch() {},
    refreshPage() {
      if(this.keywordType !== 'NoKeyword'){
        this.pageNumber = 1; 
      }
      reqPost(
        adminapi.base,
        adminapi.userlist,
        this.authToken,
        {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          pageNumber: this.pageNumber - 1,
          pageSize: this.pageSize,
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.content = data.data.content;
              this.totalPages = data.data.totalPages; //전체 페이지 수
              this.totalElements = data.data.totalElements; //전체 아이템 수
              this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
  },
};
</script>
<style scoped>
th {
  background-color: #dee2e685;
  text-align: center;
}
td {
  text-align: center;
}
</style>