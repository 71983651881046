

export default {
  login: "/login", //로그인
  loginotp: "/loginotp", //외부 로그인
  signup: "/signup", //관리자 가입
  findpsw: "/findpsw", //비밀번호 찾기
  home: "",
  refresh: "refresh", //reload
  resetpsw: "resetpsw", //비밀번호 재설정
  editmnginf: "editmnginf", //관리자 정보 변경
  //-----------------주문관리----------
  ordhis: "ordhis", //주문내역조회
  detailorder: "detailorder", //주문내역 상세보기
  ordcnlhis: "ordcnlhis", //취소요청조회
  refundhis: "refundhis", //환불신청조회
  paycnlfailhis: "paycnlfailhis", //결제취소 실패내역
  //----------------발급관리----------
  // ****발급내역*****
  isscupon: "isscupon", //모바일 쿠폰
  issprice: "issprice", //모바일 금액권
  isspoint: "isspoint", //포인트권
  issreservation: "issreservation", //예약권
  storereservation: "storereservation",
  // ****발급취소내역*****
  isscnlcupon: "isscnlcupon", //모바일쿠폰
  isscnlprice: "isscnlprice", //모바일금액권
  isscnlreservation: "isscnlreservation", //예약권
  storecnlreservation: "storecnlreservation",
  isscnlpoint:"isscnlpoint", //포인트권
  // ****사용이력*****
  issusecupon: "issusecupon", //모바일쿠폰
  issuseprice: "issuseprice", //모바일금액권
  issusereservation: "issusereservation", //예약권
  // *****************
  issstamp: "issstamp", //인지세관리
  //----------------상품관리---------------
  // ****등록관리**********
  prdnewreg: "prdnewreg", //신규상품등록
  prdreqinspec: "prdreqinspec", //상품검수신청
  viewprodinf: "viewprodinf", //상품검수 상세보기
  prdmdfinfo: "prdmdfinfo", //상품정보수정목록
  editprodinf: "editprodinf", //상품정보수정페이지
  viewb2bimg: "viewb2bimg", //B2B이미지 보기
  ViewB2bimgFull: "ViewB2bimgFull", //B2B풀이미지
  // ****운영관리*********
  prdopermdf: "prdopermdf", //판매상태변경
  prdoption: "prdoption", //대표옵션관리
  prdoutcode: "prdoutcode", //외부코드관리
  prdcupon: "prdcupon", //쿠폰번호 커스텀
  // ****B2B 상품관리******
  b2bdeploy: "b2bdeploy", //채널배포요청
  dpchnnldetail: "dpchnnldetail", //채널 상세페이지
  channelprodreg: "channelprodreg", //채널 상품등록
  b2bcode: "b2bcode", //코드매칭관리
  b2bchannel: "b2bchannel", //채널오픈현황
  // *******************
  prdvalidity: "prdvalidity", //유효기간 만료예정
  //----------------예약관리---------------
  book: "book", //예약내역조회
  detailbook: "detailbook", //예약내역 상세보기
  //----------------제휴사 관리------------
  brndmng: "brndmng", //브랜드관리
  regbrand: "regbrand", //브랜드등록
  editbrand: "editbrand", //브랜드수정
  // ****매장관리*********
  storelist: "storelist", //매장리스트
  editstore: "editstore", //매장정보수정
  regstore: "regstore", //매장등록
  storeinspec: "storeinspec", //검수신청
  reqcheckstore: "reqcheckstore", //매장등록신청검수
  alimtalkMng:"alimtalkMng", // 알림톡 관리
  kakaoRequest:"kakaoRequest", // 카카오톡예약하기-입점신청관리
  reqKakaoRequest:"reqKakaoRequest", // 카카오톡예약하기-입점신청작성
  kakaoReservationRequestDetail:"KakaoReservationRequestDetail", // 카카오톡예약하기-입점신청상세
  kakaoPartnerMng:"kakaoPartnerMng", // 카카오톡예약하기-매니저관리
  kakaoPartnerInfoDetail:"kakaoPartnerInfoDetail",// 카카오톡예약하기-매니저관리-상세

  //----------------리뷰관리---------------
  review: "review",

  //----------------회원관리---------------
  genmem: "genmem", //일반회원
  memdetail: "memdetail", //회원상세정보
  // ****TNJ 관리자********
  mnglist: "mnglist", //관리자 리스트
  regmng: "regmng", //관리자 등록신청
  //----------------사이트관리-------------
  dsp: "dsp", //기획전
  detaildsp: "detaildsp", //기획전 상세보기
  dspmain: "dspmain", //메인페이지
  detailmain: "detailmain", //상세 메인페이지
  promotion: "promotion", //프로모션
  editpromotion: "editpromotion", //프로모션 상세페이지
  intro: "intro", //인트로
  hotplace: "hotplace", //핫플레이스
  editHotplace: "editHotplace", //핫플레이스 상세페이지

  //----------------고객혜택관리----------------
  discountAddPage: "discountAddPage",
  discountApprovalPage: "discountApprovalPage",
  discountListPage: "discountListPage",
  //----------------정산관리----------------
  storepayhis: "storepayhis", //매장별 정산내역
  billissue: "billissue", //세금계산서 발행
  // ****입금관리************
  deposit: "deposit", //금액확정
  depfinish: "depfinish", //입금완료처리
  dephis: "dephis", //입금내역조회
  //----------------에이전트 정산현황----------------
  agentstoresettlement: "agentstoresettlement", //에이전트 매장별 정산현황
  //----------------통계관리----------------
  // ****거래현황조회*******************
  dealtargt: "dealtargt", //목표대비
  dealchannel: "dealchannel", //채널별 (기간)
  dealchannelmonth: "dealchannelmonth", //채널별 (월별)
  dealprod: "dealprod", //상품별
  dealbrand: "dealbrand",
  dealbrandmonth: "dealbrandmonth",
  // ****사용현황조회*******************
  usetarget: "usetarget", //목표대비
  usechannel: "usechannel", //채널별 (기간)
  usechannelmonth: "usechannelmonth", //채널별 (월별)
  usebrand: "usebrand", //브랜드별 (기간)
  usebrandmonth: "usebrandmonth", //브랜드별 (월별)
  useprod: "useprod", //상품별
  // ******************************
  statprice: "statprice", //목표금액관리
  //-----------------게시판관리-----------------
  notice: "notice", //공지사항
  editnotice: "editnotice", //공지사항 수정
  cuscenter: "cuscenter", //고객센터
  ques: "ques", //제휴문의
  faq: "faq", //자주묻는 질문
  editfaq: "editfaq", //자주묻는 질문수정
  reqdocu: "reqdocu", //증빙서류신청
  telephone: "telephone", //전화상담 접수관리
  noticeadmin: "noticeadmin", // 하이테이블 공지사항
  editnoticeadmin: "editnoticeadmin", // 하이테이블 공지사항 수정
  //-----------------개발자기능-----------------
  b2binterworking: "b2binterworking", // B2B 연동 수동처리
  pos: "pos", // POS 연동관리
  alimTalkDev: "alimTalkDev",
  channelDev:"channelDev",
  //-----------------할인쿠폰관련-----------------

  //할인쿠폰 관리
  discountAddPage: "discountAddPage",
  //할인쿠폰발급현황조회
  discountIssuanceStatus: "discountIssuanceStatus",
  discountUseStatus: "discountUseStatus",
};
