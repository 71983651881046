<template>
    <section class="content">
        <div class="box box-solid box-primary">
            <div class="box-header">
                <h3 class="box-title">POS 인증키(Partner-Key) 생성</h3>
            </div>

          <div class="box-body form-horizontal">
            <div class="form-group">
              <label class="col-sm-2 control-label">연동사</label>
              <span class="col-sm-10">
                  <span class="form-group col-sm-3">
                    <input type="text" class="form-control" placeholder="연동사 명" v-model="partnerName"/>
                  </span>
                  <span class="col-sm-3">
                    <input type="text" class="form-control" placeholder="연동사 코드" v-model="partnerType"/>
                  </span>
                  <span lass="col-sm-4">
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="createPartnerType">
                        <i class="fa fa-plus"></i> &nbsp; 연동사 추가
                    </button>
                  </span>

              </span>
              <span class="col-sm-2 control-label"></span>
              <span class="col-sm-10">
                <span class="form-group col-sm-7" >
                  <select class="form-control" value="selectPartnerType" @change="setPartnerType($event.target.value)" v-model="agentType">
                    <option :value="item.value" v-for="(item,idx) in partnerList" :key = "idx" >
                      {{item.name}}</option>
                  </select>
                </span>
              </span>
            </div>
          </div>


          <div class="box-body form-horizontal">
              <div class="form-group">
                  <label class="col-sm-2 control-label">타입</label>
                  <div class="col-sm-10 radio">
                    <label> <input type="radio" :value="agent" v-model="isBrandType"/>연동사</label>
                    <label> <input type="radio" :value="brand" v-model="isBrandType"/>브랜드</label>
                    <label> <input type="radio" :value="store" v-model="isBrandType"/>매장</label>
                  </div>
              </div>
              <div class="form-group" v-if="isBrandType === 'brand'">
                  <label class="col-sm-2 control-label">브랜드</label>
                  <div class="col-sm-2">
                      <div class="input-group">
                          <input type="text" class="form-control" placeholder="브랜드명" v-model="brandNameContaining"/>
                          <span class="input-group-btn">
                              <button class="btn btn-default" type="button" @click="getBrandList">검색</button>
                          </span>
                      </div>
                  </div>
                  <div class="col-sm-8">
                      <div class="input-group">
                          <select class="form-control" v-model="brandInfo">
                              <option v-for="brand in brandList" v-bind:value="{brandCode: brand.brandCode}">
                                  {{ brand.brandName }} ({{ brand.brandCode }})
                              </option>
                          </select>
                      </div>
                  </div>
              </div>
              <div class="form-group" v-if="isBrandType === 'store'">
                  <label class="col-sm-2 control-label">매장</label>
                  <div class="col-sm-2">
                      <div class="input-group">
                          <input type="text" class="form-control" placeholder="매장명" v-model="brandNameContaining"/>
                          <span class="input-group-btn">
                              <button class="btn btn-default" type="button" @click="getStoreList">검색</button>
                          </span>
                      </div>
                  </div>
                  <div class="col-sm-8">
                      <div class="input-group">
                          <select class="form-control" v-model="storeInfo">
                              <option v-for="store in storeList" v-bind:value="{brandCode: store.brandCode, storeCode: store.storeCode}">
                                  {{ store.brandName }} ({{ store.brandCode }}) - {{ store.storeName }} ({{ store.storeCode }})
                              </option>
                          </select>
                      </div>
                  </div>
              </div>

              <div class="form-group">
                  <label class="col-sm-2 control-label">판매가 노출 여부</label>
                  <div class="col-sm-10 radio">
                      <label> <input type="radio" :value="true" v-model="salePriceDisplay"/>노출</label>
                      <label> <input type="radio" :value="false" v-model="salePriceDisplay"/>미노출</label>
                  </div>
              </div>
          </div>
          <div class="box-footer text-center">
              <button type="button" class="btn btn-default" @click="partnerKeyCreate">생성</button>
          </div>
        </div>
      <div class="box box-solid box-primary">
        <div class="box-body">
          <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
            <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="refreshPage">
              <option value="NO_KEYWORD"></option>
              <option value="AGENT_CODE">연동사 코드</option>
              <option value="AGENT_NAME">연동사 이름</option>
              <option value="BRAND_CODE">브랜드 코드</option>
              <option value="BRAND_NAME">브랜드 이름</option>
              <option value="STORE_CODE">매장 코드</option>
              <option value="STORE_NAME">매장 이름</option>
              <option value="PARTNER_KEY">인증키</option>
            </select>
            <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
              <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
              <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
            </div>
          </div>
        </div>
      </div>
        <div class="box box-solid box-primary">
            <div class="box-header">
                <h3 class="box-title">POS 인증키(Partner-Key) 리스트</h3>
            </div>
            <div class="box-body form-horizontal">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                        <tr>
                            <th>연동사</th>
                            <th>타입</th>
                            <th>브랜드 코드</th>
                            <th>브랜드 이름</th>
                            <th>매장 코드</th>
                            <th>매장 이름</th>
                            <th>인증키 (Partner-Key)</th>
                            <th>판매가 노출 여부</th>
                            <th>생성일</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, idx) in partnerKeyList" :key="idx" >
                            <td>{{item.agentName + " ("+item.agentCode +")"}}</td>
                            <td>{{item.userRole}}</td>
                            <td>{{item.brandCode}}</td>
                            <td>{{brandNameReturn(item.brandCode)}}</td>
                            <td>{{item.storeCode }}</td>
                            <td>{{ storeNameReturn(item.storeCode) }}</td>
                            <td>{{item.partnerKey }}</td>
                            <td>{{item.salePriceDisplay }}</td>
                            <td>{{item.createDate }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      <div class="box-footer text-center">
        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage"/>
      </div>
    </section>
</template>

<script>
    import {rescode, menuval} from "../../lib/consts";
    import {reqGet, reqPost} from "../../lib/request";
    import {adminapi, b2bapi, brandapi} from "../../lib/api";
    import store from "@/store";
    import brand from "@/views/stat/use/Brand.vue";
    import DetePicker from "@/components/DatePicker.vue";
    import Pagination from "@/components/Pagination.vue";

    export default {
      components: {Pagination, DetePicker},
        created() {
            this.$store.dispatch("doSetPath", {
                path1: menuval.LV1_DEV,
                path2: menuval.LV1_HOME,
                title: "POS 연동관리",
            });
        },
        data() {
            return {
                isBrandType: "agent",
                brandInfo: null,
                storeInfo: null,
                agentInfo: null,
                salePriceDisplay: false,
                brandNameContaining: "",
                partnerType:"",
                partnerName:"",
                agentName: "",
                brandList: [],
                storeList: [],
                partnerKeyList: [],
                storeCodeList: [],
                brandCodeList: [],
                storeInfoList: [],
                brandInfoList: [],
                partnerList:[],
                agentType:"",
                selectPartnerType:"",
                keywordType: "NO_KEYWORD",
                keyword: "",
                pageNumber: 1, //현재 페이지 번호
                pageSize: 20, //페지당 개수
                content: [],
                totalPages: 0, //전체 페이지 수
                totalElements: 0, //전체 아이템 수
                numberOfElements: 0, //현재 조회된 아이템 수
                first: true, //첫 페이지일 경우 true
                last: false, //마지막 페이지일 경우 true
                shownum: 10, //pagination에 보여지는 번호개수
            };
        },
        computed: {
          brand() {
            return "brand";
          },
          store() {
            return "store";
          },
          agent() {
            return "agent";
          },
            authToken() {
                return this.$store.getters.authToken;
            }
        },
        mounted() {
            this.refreshPage();
        },
        methods: {
          gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
          },
          setPartnerType(partnerType) {
            this.selectPartnerType = partnerType;
          },
          createPartnerType(){

            let requestData;
            if (this.partnerName ==="") {
                alert("연동사 명을 입력해주세요");
                return false;
            }

            if(this.partnerType === "") {
              alert("연동사 코드를 입력해주세요");
              return false;
            }
              requestData = {
                userRole: "AGENT",
                partnerType: this.partnerType,
                partnerName: this.partnerName
              };

            reqPost(
              b2bapi.base,
              b2bapi.partnerKeyCreate,
              this.authToken,
              requestData,
              (result) => {
                if (result != null) {
                  alert(result.message);
                  this.partnerType = "";
                  this.partnerName = "";
                } else {
                  alert("실패");
                }
                this.refreshPage();
              }
            );
          },
          refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
              pageNumber = this.pageNumber - 1;
            } else {
              this.pageNumber = 1;
            }

            this.getPartnerKeyList(pageNumber);
            this.getPartnerList();
          },
          getPartnerList() {
            this.partnerList.length = 0;
            reqGet(
              b2bapi.base,
              b2bapi.partnerKeyList,
              this.authToken,
              (result) => {
                if (result != null && result.code === rescode.REQ_SUCCESS) {
                  this.partnerList.push({name:"- 연동사 선택 - ", value: ""});
                  result.data.partnerTypeList.forEach(partnerInfo=> {
                    this.partnerList.push({name:partnerInfo.partnerName+ " "+"("+partnerInfo.partnerType+")", value:partnerInfo.partnerType});
                  });
                }
              }
            );
          },
            getPartnerKeyList(pageNumber) {
              // brandNameReturn
              let keywords;

              switch (this.keywordType) {
                case "NO_KEYWORD":
                case "AGENT_CODE":
                case "AGENT_NAME":
                case "BRAND_CODE":
                case "STORE_CODE":
                case "PARTNER_KEY":
                  keywords = new Array(this.keyword);
                  break;

                case "BRAND_NAME":
                  keywords =this.brandCodeReturn(this.keyword);
                  break;
                case "STORE_NAME":
                  keywords =this.storeCodeReturn(this.keyword);
                  break;
              }
              reqPost(
                b2bapi.base,
                b2bapi.posPartnerKeyList,
                this.authToken,
                {
                  keywordType:this.keywordType,
                  keywords:keywords,
                  pageNumber:pageNumber,
                  pageSize:this.pageSize
                },
        (result) => {
                    if (result != null && result.code === rescode.REQ_SUCCESS) {
                        this.totalPages = result.data.totalPages; //전체 페이지 수
                        this.totalElements = result.data.totalElements; //전체 아이템 수
                        this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                        this.partnerKeyList = result.data.content;

                        this.partnerKeyList.forEach(list=>{
                          this.brandCodeList.push(list.brandCode);
                          if(list.storeCode != null){
                            this.storeCodeList.push(list.storeCode);
                          }
                        });
                        this.getStoreNameAPI();
                        this.getBrandNameAPI();
                    }
                    else {
                      this.content = [];
                      this.totalPages = 0; //전체 페이지 수
                      this.totalElements = 0; //전체 아이템 수
                      this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
              );
            },
            getBrandList() {
                if (!this.brandNameContaining) {
                    alert("브랜드명을 입력해 주세요.");
                    return false;
                }
                reqGet(
                    brandapi.base,
                    brandapi.codequery + "?brandNameContaining=" + this.brandNameContaining,
                    this.authToken,
                    (result) => {
                        if (result != null && result.code === rescode.REQ_SUCCESS) {
                            this.brandList = result.data.brandCodes;
                        }
                    }
                );
            },
            getStoreList() {
                if (!this.brandNameContaining) {
                    alert("브랜드명을 입력해 주세요.");
                    return false;
                }
                reqGet(
                    brandapi.base,
                    brandapi.storesquery + "?brandNameContaining=" + this.brandNameContaining,
                    this.authToken,
                    (result) => {
                        if (result != null && result.code === rescode.REQ_SUCCESS) {
                            let storeArr = [];
                            result.data.brands.forEach(function (brand) {
                                brand.stores.forEach(function (store) {
                                    storeArr.push({
                                        brandCode: brand.brandCode,
                                        brandName: brand.brandName,
                                        storeCode: store.storeCode,
                                        storeName: store.storeName
                                    });
                                });
                            });
                            this.storeList = storeArr;
                        }
                    }
                );
            },
            partnerKeyCreate() {
                let requestData;
                if (this.isBrandType === "brand") {
                    if (!this.brandInfo) {
                        alert("브랜드를 선택해 주세요.");
                        return false;
                    }
                    if (this.agentType === "") {
                      alert("연동사를 선택 해주세요.");
                      return false;
                    }

                  requestData = {
                        userRole: "BRAND_ADMIN",
                        brandCode: this.brandInfo.brandCode,
                        storeCode: null,
                        agentCode:this.agentType,
                        salePriceDisplay: this.salePriceDisplay
                    };
                } if(this.isBrandType === "store") {
                    if (!this.storeInfo) {
                        alert("매장을 선택해 주세요.");
                        return false;
                    }
                    if (this.agentType === "") {
                      alert("연동사를 선택 해주세요.");
                      return false;
                    }

                requestData = {
                        userRole: "STORE_MANAGER",
                        brandCode: this.storeInfo.brandCode,
                        storeCode: this.storeInfo.storeCode,
                        agentCode:this.agentType,
                        salePriceDisplay: this.salePriceDisplay
                    };
                }
                if(this.isBrandType === "agent"){
                  if (this.agentType === "") {
                    alert("연동사를 선택 해주세요.");
                    return false;
                  }

                  requestData = {
                    userRole: "AGENT",
                    agentCode:this.agentType,
                    salePriceDisplay: this.salePriceDisplay
                  }
                }

                reqPost(
                    b2bapi.base,
                    b2bapi.posPartnerKeyCreate,
                    this.authToken,
                    requestData,
                    (result) => {
                        if (result != null) {
                            alert(result.message);
                        } else {
                            alert("실패");
                        }
                        this.refreshPage();
                    }
                );
            },
          getStoreNameAPI() {
              reqPost(
                brandapi.base,
                brandapi.admofStores,
                this.authToken, {
                  "storeCodes": this.storeCodeList,
                  "filter": {
                    "enabledStoresOnly": null
                  }
                },
                (result) => {
                  if (result != null) {
                    result.data.byBrands.forEach(brand=> {
                      brand.stores.forEach(store => {
                        this.storeInfoList.push({
                          storeCode:store.storeCode,
                          storeName:store.storeName
                        })
                      });
                    });
                  }
                });

          },
          getBrandNameAPI(){
            reqPost(
              brandapi.base,
              brandapi.brandCodesList,
              this.authToken, {
                "brandCodes": this.brandCodeList,
              },
              (result) => {
                if (result != null) {
                  this.brandInfoList.length = 0;
                  result.data.brandCodes.forEach(brand=> {
                    this.brandInfoList.push({
                      brandCode:brand.brandCode,
                      brandName:brand.brandName
                    })
                  });
                }
              }
            );
          },
          brandNameReturn(brandCode) {
            let brandName = "";
            this.brandInfoList.forEach(elem => {
              if (elem.brandCode === brandCode) {
                brandName = elem.brandName;
              }
            })

            return brandName;
          },
          brandCodeReturn(brandName) {
            let brandCode = [];
            this.brandInfoList.forEach(elem => {
              if (elem.brandName === brandName) {
                brandCode.push(elem.brandCode);
              }
            })
            return brandCode;
          },


          storeNameReturn(storeCode) {
            let storeName = "";
            this.storeInfoList.forEach(elem => {
              if (elem.storeCode === storeCode) {
                storeName = elem.storeName;
              }
            })
              return storeName;
          },
          storeCodeReturn(storeName) {
            let storeCode = [];
            this.storeInfoList.forEach(elem => {
              if (elem.storeName === storeName) {
                storeCode.push(elem.storeCode);
              }
            })
            return storeCode;
          },

        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .radio > label {
        margin-right: 10px;
    }
    .input-marign {
      margin-left:0px;
      margin-right: 0px;
    }
</style>
