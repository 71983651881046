<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
          <DatePicker v-model="fromDate" :last="toDate"/>
          <h4 style="margin: 5px 10px">~</h4>
          <DatePicker v-model="toDate" :first="fromDate" style="margin-right: 10px"/>
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
            전월
          </button>
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
            당월
          </button>
          <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default pull-right" @click="selectExcel">
          목표금액등록
        </button>
        <input type="file" ref="fileInput" style="display:none;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12" @change="onUploadFiles" title="파일선택" alt="파일선택"/>
    </div>
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <!-- <th>년월</th> -->
                  <th>일</th>
                  <th>거래 목표 금액</th>
                  <th>사용 목표 금액</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(target, tidx) in targetAmounts" :key="tidx">
                  <!-- <td rowspan="4">2020년 1월</td> -->
                  <td>{{target.date}}</td>
                  <td>{{target.salesAmount | numFormat}}</td>
                  <td>{{target.consumeAmount | numFormat}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :style="excelview && { display: 'block', 'z-index': '2000' }">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">목표금액등록</div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="margin: 0px">
              <div class="table-responsive excelview p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <!-- <th>년월</th> -->
                      <th>일</th>
                      <th>거래 목표 금액</th>
                      <th>사용 목표 금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(reg, ridx) in regtarget" :key="ridx">
                      <!-- <td rowspan="4">2020년 1월</td> -->
                      <td>{{reg.date}}</td>
                      <td>{{reg.salesAmount | numFormat}}</td>
                      <td>{{reg.consumeAmount | numFormat}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeExcel">
              닫기
            </button>
            <button type="button" class="btn btn-default" @click="regNewTraget">등록</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import { reqPost } from "../../lib/request";
import { orderapi } from "../../lib/api";
import { DateFormat } from "../../lib/utility";
import DatePicker from "../../components/DatePicker";
import moment from 'moment';
import XLSX from "xlsx";
export default {
  components: {
    DatePicker,
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_STAT,
      path2: menuval.LV1_HOME,
      title: "목표금액관리",
    });
  },
  data() {
    return {
      fileInput: null,
      excelview: false,
      fromDate:moment().format("YYYY-MM-01"),
      toDate:moment().format("YYYY-MM-DD"),
      targetAmounts:[],//
      /**
       * date             String    yyyy-MM-dd 날짜
       * salesAmount      String    판매 목표금액
       * consumeAmount    String    사용 목표금액
       */
      currencyCode:"",//[USD, KRW]  통화코드

      regtarget:[],//
      /**
       * date             String    yyyy-MM-dd 날짜
       * salesAmount      String    판매 목표금액
       * consumeAmount    String    사용 목표금액
       */
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    onPrvMonth()
    {
      this.fromDate = moment().subtract(1,'months').format('YYYY-MM-01');
      this.toDate = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
    },
    onNowMonth()
    {
      this.fromDate = moment().format("YYYY-MM-01");
      this.toDate = moment().format("YYYY-MM-DD");
    },
    closeExcel() {
      this.excelview = false;
    },
    selectExcel()
    {
      this.$refs.fileInput.click();
    },
    refreshPage() {
      reqPost(
        orderapi.base,
        orderapi.salesGoal,
        this.authToken,
        {
          fromDate:this.fromDate,
          toDate:this.toDate
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.targetAmounts = result.data.targetAmounts;
              this.currencyCode = result.data.currencyCode;
            } else {
              this.targetAmounts = [];
              alert(result.message);
            }
          }
        }
      );
    },
    onUploadFiles(e){
      const files = e.target.files;
      if (!files.length) {
        return ;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert("The upload format is incorrect. Please upload xls or xlsx format");
      }
      const fileReader = new FileReader();
      this.selfilename = files[0].name;
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          // const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
            cellDates: true,
            dateNF: 'YYYY-MM-DD'
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {header:"A", raw: false}); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet

            const excellist = [];  // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.exceltoInData(excellist);
        } catch (e) {
          return alert("Read failure!");
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    exceltoInData(excellist)
    {
      this.regtarget = excellist.map((titem) => {
        return {
          date:DateFormat(titem.B, "YYYY-MM-DD"),//날짜
          salesAmount:titem.C,//판매 목표금액
          consumeAmount:titem.D,//사용 목표금액
        }
      });
      this.excelview = true;
    },
    regNewTraget()
    {
      reqPost(
        orderapi.base,
        orderapi.goalregister,
        this.authToken,
        {
          targetAmounts:this.regtarget,
          currencyCode:"KRW",//this.currencyCode
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeExcel();
              this.refreshPage();
            } else {
              this.targetAmounts = [];
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.modal th {
  background-color: #dee2e685;
}

.excelview::-webkit-scrollbar {
  display: none;
}

.modal-body .control-label {
  margin: 0px;
}

.modal .table {
  margin-bottom: 0px;
}
th {
  text-align: center;
}
td {
  text-align: center;
}
</style>