<template>
  <section class="content">
<!--    <button @click="toastTest" >toastTest</button>-->

    <div class="bg-danger margin-bottom top-alert" v-if="locationAlert && !locationAlertIgnore">
      <div style="display: block">
        <h4><i class="fa fa-info-circle"></i>장소정보 변경 안내</h4>
        <span>외부 예약 채널과 연동중인 장소정보가 변경되었습니다.  변경사항을 외부 연동 채널에 반영하시겠습니까?</span>
      </div>
      <div class="pull-right" style="align-content: center">
        <input type="button" class="margin-r-5 btn btn-dark" value="무시" @click="updateKakaoSync('STORE',false)">
        <input type="button" class="margin-r-5 btn btn-danger" value="반영" @click="updateKakaoSync('STORE',true)">
      </div>
    </div>
    <div class="bg-danger margin-bottom top-alert" v-if="reserveAlert && !reserveAlertIgnore">
      <div style="display: block">
        <h4><i class="fa fa-info-circle"></i>예약정보 변경 안내</h4>
        <span>외부 예약 채널과 연동중인 예약정보가 변경되었습니다.<br>추가 수정사항이 있는 경우 설정을 모두 마친 후 “반영” 버튼을 눌러주세요.</span>
        <label style="display: block">※ 반영하지 않는 경우 외부 채널과 예약 연동 시 오류가 발생됩니다. ※</label>

      </div>
      <div class="pull-right" style="align-content: center">
        <input type="button" class="margin-r-5 btn btn-dark" value="무시" @click="updateKakaoSync('RESERVATION',false)">
        <input type="button" class="margin-r-5 btn btn-danger" value="반영" @click="updateKakaoSync('RESERVATION',true)">
      </div>
    </div>
    <div class="box box-solid">
      <ul class="nav nav-tabs">
        <li v-for="(idx, tabArray) in tabArray" :key="idx" :class="tabindex== idx && 'active'">
          <a class="linkcmp" @click="onClickTab(idx)"> {{ tabName[idx] }} </a>
        </li>
      </ul>
    </div>
    <!-- 기본정보 -->
    <div v-if="tabindex== 0">
      <storeContents @get-kakao-id="getKakaoId" @get-kakao-alert="getKakaoAlert"></storeContents>
      <StoreImg @get-kakao-alert="getKakaoAlert"></StoreImg>
      <infoSales @get-kakao-alert="getKakaoAlert"/>
      <reqProduct/>
      <Agency/>
    </div>

    <!-- 메뉴관리 -->
    <MenuAdminicular @get-kakao-alert="getKakaoAlert" v-if="tabindex== 1"/>
    <!--  예약 기본 설정  -->
    <BasesScheduleBooking  v-if="tabindex == 2" :kakao-id="kakaoId"/>
    <!--  예약가능 인원설정  -->
    <SetNumPeopleAva @get-kakao-alert="getKakaoAlert" v-if="tabindex== 3" />
    <!-- 예약관리 -->
    <ReservationManag v-if="tabindex == 4" :kakao-id="kakaoId" :place-id="placeId"/>
    <!-- 할인& 포인트 -->
    <TimeZoneDiscount v-if="tabindex == 5"/>
    <!--  프로모션  -->
    <Promotion v-if="tabindex == 6"/>
    <!--  매장 등록 정보  -->
    <StoreRegInfo @get-kakao-alert="getKakaoAlert" @get-kakao-place-id="getKakaoPlaceId" v-if="tabindex== 7" :kakao-id="kakaoId" />
    <!-- 계약정보-->
    <BookingPreferrences v-if="tabindex== 8"/>
    <!--  입금정보  -->
    <SetNumBookingAva v-if="tabindex== 9"/>
    <!--  부가기능  -->
    <notificationSendTalk v-if="tabindex== 10"/>
    <!--  채널노출  -->
    <ChannelExposure v-if="tabindex== 11" />
    <!-- 예약캘린더 -->
    <Calendar v-if="tabindex == 12" />




  </section>

</template>

<script>
import {
  rescode,
  menuval,
  imgtype,
} from "../../../lib/consts";
import SingleThumbnail from "@/components/SingleThumbnail";
import OpenHours from "@/components/OpenHours";
import BreakTime from "@/components/BreakTime";
import WeekTime from "../../../components/WeekTime";
import DatePicker from "../../../components/DatePicker";

import ListBadgeMake from "../../../components/ListBadgeMake";

import VueTimepicker from "vue2-timepicker";
import MenuAdminicular from './component/MenuAdminicular';
import ReservationManag from './component/ReservationManag';
import TimeZoneDiscount from './component/TimeZoneDiscount';
import {
  Drag,
  Drop
} from "vue-drag-drop";
import "vue2-timepicker/dist/VueTimepicker.css";
import StoreImg from "./component/StoreImg.vue";
import Promotion from './component/Promotion';
import Calendar from '../../booking/BookingCalendar.vue';
import storeContents from './editStoreComponent/storeContents';
import infoSales from './editStoreComponent/infoSales';
import reqProduct from './editStoreComponent/reqProduct';
import Agency from './editStoreComponent/agency';
import BookingPreferrences from './editStoreComponent/BookingPreferences';
import SetNumBookingAva from './editStoreComponent/SetNumBookingAva';
import BasesScheduleBooking from './editStoreComponent/BasesScheduleBooking';
import notificationSendTalk from './editStoreComponent/notificationSendTalk';
import SetNumPeopleAva from './editStoreComponent/SetNumPeopleAva';
import {
  MonthPicker
} from 'vue-month-picker';
import StoreRegInfo from "@/views/brand/store/editStoreComponent/StoreRegInfo";
import ChannelExposure from './editStoreComponent/ChannelExposure';
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import toastification from "./component/toastification.vue"

export default {
  name: "edtstore",
  components: {
    ChannelExposure,
    StoreRegInfo,
    SetNumPeopleAva,
    notificationSendTalk,
    BasesScheduleBooking,
    SetNumBookingAva,
    BookingPreferrences,
    Agency,
    storeContents,
    Calendar,
    MonthPicker,
    TimeZoneDiscount,
    SingleThumbnail,
    infoSales,
    OpenHours,
    BreakTime,
    WeekTime,
    MenuAdminicular,
    DatePicker,
    ListBadgeMake,
    Drag,
    Drop,
    VueTimepicker,
    StoreImg,
    ReservationManag,
    Promotion,
    reqProduct,

  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  data() {
    return {
      tabArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      tabName: ['기본정보', '메뉴관리', '예약 기본 설정', '예약가능 인원설정', '예약관리', '할인 & 리워드 설정', '프로모션', '매장 등록 정보', '계약정보', '입금정보', '부가기능', '전시관리', '예약캘린더'],
      tabindex: 0,
      kakaoId: 0,
      placeId:0,
      syncData:null,
      locationAlert:false,
      locationAlertIgnore:false,
      reserveAlert:false,
      reserveAlertIgnore:false,
      kakaoAlert:false
    };
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_STR,
      title: "",
    });
  },
  mounted() {
    // this.getIgnore();
    this.getKakaoId();


  },
  methods: {
    onClickTab(vidx) {
      this.tabindex = vidx;
      // this.refreshPage();
    },
    moveOnTop(){
      window.scrollTo(0,0);
    },
    getKakaoId(val){
     this.kakaoId = val
      if(this.kakaoId){
        this.getKakaoSync()
        // this.getKakaoSync();
      }
    },
    getKakaoPlaceId(val){
      this.placeId = val
    },
    getKakaoSync(){
      console.log(this.$route.query.stcode)
      // queryKakaoSync

      reqGet(
        brandapi.base,
        brandapi.queryKakaoSync + "?storeCode=" + this.$route.query.stcode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.syncData = result.data;
              this.locationAlert = result.data.needSyncStore
              this.locationAlertIgnore = result.data.ignoreSyncStore
              this.reserveAlert = result.data.needSyncReservation
              this.reserveAlertIgnore = result.data.ignoreSyncReservation

            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    updateKakaoSync(val,isSync){
      let param = {
        storeCode:this.$route.query.stcode,
        syncType:val,
        isSync:isSync
      }
      reqPost(
        brandapi.base,
        brandapi.updateKakaoSync,
        this.authToken, param,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert("반영되었습니다.")
              if(val=='STORE'){
                this.locationAlert = false;
                this.locationAlertIgnore = true;
              }else{
                this.reserveAlert = false;
                this.reserveAlertIgnore = true;
              }
              this.$forceUpdate();
            } else {
              alert(result.message);
            }
          }
        }
      );

    },
    toastTest(){
      this.$toast({
          component: toastification,
          listeners: {
            myClick: () => this.moveOnTop()
          }
      },{
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false  ,
        hideProgressBar: true,
        closeButton: false,
        icon: false,
        rtl: false,
      });
      this.getKakaoSync()
    },
    getKakaoAlert(val){
      if(this.kakaoId){
        this.toastTest()
      }



    },





  },
};
</script>

<style scoped>

.top-alert{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
}
</style>
