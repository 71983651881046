<template>
<section class="content">
    <div class="text-center pgheader" v-if="goodsType != null && stepval < 6">
        <div style="display:flex;flex-direction: row;">
            <div class="step">
                <div class="pttop" style="cursor: pointer;" @click="gotoStep(0)">상품정보</div>
                <div class="passline">
                    <div class="linebg"></div>
                    <div :class="['rightline', stepval > 0?'bgpass':'bgfail']"></div>
                    <div class="point_pass" style="cursor: pointer;" @click="gotoStep(0)"></div>
                </div>
            </div>
            <div class="step">
                <span class="ptlbl" style="cursor: pointer;" @click="gotoStep(1)">옵션/가격</span>
                <div class="passline">
                    <div class="linebg"></div>
                    <div :class="['leftline', stepval > 0?'bgpass':'bgfail']"></div>
                    <div :class="['rightline', stepval > 1?'bgpass':'bgfail']"></div>
                    <div :class="stepval > 0?'point_pass':'point_fail'" style="cursor: pointer;" @click="gotoStep(1)"></div>
                </div>
            </div>
            <div class="step">
                <span class="pttop" style="cursor: pointer;" @click="gotoStep(2)">사용조건</span>
                <div class="passline">
                    <div class="linebg"></div>
                    <div :class="['leftline', stepval > 1?'bgpass':'bgfail']"></div>
                    <div :class="['rightline', stepval > 2?'bgpass':'bgfail']"></div>
                    <div :class="stepval > 1?'point_pass':'point_fail'" style="cursor: pointer;" @click="gotoStep(2)"></div>
                </div>
            </div>
            <div class="step" v-if="goodsType != 'Booking'">
                <span class="ptlbl" style="cursor: pointer;" @click="gotoStep(3)">판매채널</span>
                <div class="passline">
                    <div class="linebg"></div>
                    <div :class="['leftline', stepval > 2?'bgpass':'bgfail']"></div>
                    <div :class="['rightline', stepval > 3?'bgpass':'bgfail']"></div>
                    <div :class="stepval > 2?'point_pass':'point_fail'" style="cursor: pointer;" @click="gotoStep(3)"></div>
                </div>
            </div>
            <div class="step">
                <span class="pttop" style="cursor: pointer;" @click="gotoStep(4)">판매정보</span>
                <div class="passline">
                    <div class="linebg"></div>
                    <div :class="['leftline', stepval > 3?'bgpass':'bgfail']"></div>
                    <div :class="['rightline', stepval > 4?'bgpass':'bgfail']"></div>
                    <div :class="stepval > 3?'point_pass':'point_fail'" style="cursor: pointer;" @click="gotoStep(4)"></div>
                </div>
            </div>
            <div class="step">
                <span class="ptlbl" style="cursor: pointer;" @click="gotoStep(5)">추가정보</span>
                <div class="passline">
                    <div class="linebg"></div>
                    <div :class="['leftline', stepval > 4?'bgpass':'bgfail']"></div>
                    <div :class="stepval > 4?'point_pass':'point_fail'" style="cursor: pointer;" @click="gotoStep(5)"></div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="stepval== 0 && goodsType != null" class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
              <label class="col-sm-2 control-label">등록 경로</label>
              <div class="col-sm-10">
                <Label>{{this.goodsBrandName}}&nbsp;({{this.goodsSiteName}})&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                <div class="input-group" style="display:inline-flex">
                <select class="form-control" style="width: auto;" value="selectedSiteName" v-model="selectedSiteName">
                <option :value="item.value" v-for="(item,idx) in selectSiteName" :key = "idx">
                  {{item.name}}</option>
                </select>
                </div>
              </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">상품 유형 *</label>
                <div class="col-sm-10">
                    <label class="control-label">{{goodsTypeVal(goodsType)}}</label>
                </div>
            </div>
            <div class="form-group" v-if="introduction.forCombined">
                <label class="col-sm-2 control-label">결합여부</label>
                <div class="col-sm-10">
                    <!-- <input type="checkbox"> -->
                    <label>결합등록용 상품입니다. (결합등록용 상품은 외부 사이트에 판매되지 않습니다.)</label>
                </div>
            </div>
            <div v-else-if="goodsType=='PointCoupon'">

            </div>
            <div class="form-group" v-else>
                <label class="col-sm-2 control-label">결합여부</label>
                <div class="col-sm-10">
                    <!-- <input type="checkbox"> -->
                    <label></label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">상품명 *</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="introduction.goodsName" />
                </div>
            </div>
            <div class="form-group" v-if="goodsType != 'PointCoupon' ">
                <label class="col-sm-2 control-label">상품명 태그</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="introduction.goodsTag" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">상품 소개</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="introduction.intro" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">대표 이미지 *</label>

                <div class="col-sm-10 displayIn">
                    <SingleThumbnail :value="introduction.mainImage.url" :change="onMainImage" :imgFile="imgFile" />
                    <div class="left-store-btn">
                        <button class="btn btn-default" @click="storeimage()">매장 이미지에서 선택</button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">추가 이미지 *</label>
                <div class="col-sm-10">
                    <button type="button" class="btn btn-default" @click="showNewSubImage">등록</button>
                </div>
            </div>
            <div class="form-group text-center">
                <ProdAddImg v-for="(simg, sidx) in introduction.subImages" :key="sidx" :title="simg.title" :imgdes="simg.description" :images="simg.images" @editProd="showEditSubImage(simg, sidx)" @remove="emvsubimg(sidx)" @sortdown="sortdownsub(sidx)" @sortup="sortupsub(sidx)" @sortTopsub="sortTopsub(sidx)" @sortlowestsub="sortlowestsub(sidx)" />
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="baseinfcheck">다음</button>
        </div>
    </div>
    <div v-if="stepval== 1 && goodsType != null" class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group" v-if="goodsType !== 'Combined' && goodsType !== 'PointCoupon'">
                <label class="col-sm-2 control-label">입금가 설정 *</label>
                <div class="col-sm-10 radio">
                    <label>
                        <input type="radio" :value="false" v-model="goodsOption.hasOwnDeliveryPrice" />매장별 수수료 적용
                    </label>
                    <label>
                        <input type="radio" :value="true" v-model="goodsOption.hasOwnDeliveryPrice" />상품별 수수료 적용
                    </label>
                </div>
            </div>
            <div class="form-group" v-if="!isMobile(goodsType) && goodsType !== 'Combined'">
                <label class="col-sm-2 control-label">적용 요일 *</label>
                <div class="col-sm-10" style="display:inline-flex;flex-wrap: wrap;">
                    <div class="radio">
                        <label>
                            <input type="radio" value="Everyday" v-model="usableHour.dayOfWeekType" />매일
                        </label>
                        <label>
                            <input type="radio" value="Weekday" v-model="usableHour.dayOfWeekType" />주중
                        </label>
                        <label>
                            <input type="radio" value="Weekend" v-model="usableHour.dayOfWeekType" />주말
                        </label>
                        <label>
                            <input type="radio" value="Custom" v-model="usableHour.dayOfWeekType" />사용자 지정
                        </label>
                    </div>
                    <div class="btn-group weeksel" v-if="usableHour.dayOfWeekType == 'Custom' && goodsType !== 'Combined'">
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.monday && 'btn-selweek']" @click="onmonday">월</button>
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.tuesday && 'btn-selweek']" @click="ontuesday">화</button>
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.wednesday && 'btn-selweek']" @click="onwednesday">수</button>
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.thursday && 'btn-selweek']" @click="onthursday">목</button>
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.friday && 'btn-selweek']" @click="onfriday">금</button>
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.saturday && 'btn-selweek']" @click="onsaturday">토</button>
                        <button type="button" :class="['btn', 'btn-sm', 'btn-default', usableHour.sunday && 'btn-selweek']" @click="onsunday">일</button>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="!isMobile(goodsType) && goodsType !== 'Combined'">
                <label class="col-sm-2 control-label">적용 시간 *</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <select class="form-control" style="width: auto; margin-right:10px" v-model="addhours">
                            <option v-for="n in 24" :key="n" :value="n-1">{{n-1}}시</option>
                        </select>
                        <select class="form-control" style="width: auto; margin-right:10px" v-model="addminutes">
                            <option v-for="n in 60" :key="n" :value="n-1">{{n-1}}분</option>
                        </select>
                        <button type="button" class="btn btn-default" @click="addUsableHour">추가</button>
                    </div>
                    <div class="input-group" style="display:inline-flex;flex-wrap: wrap;">
                        <MarkLbl v-for="(hitem, hidx) in usableHour.hours" :key="hidx" :label="getHourFormat(hitem)" :index="hidx" :close="rmvhours" />
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="!isMobile(goodsType) && goodsType !== 'Combined'">
                <label class="col-sm-2 control-label">예약불가일</label>
                <div class="col-sm-10">
                    <div class="input-group" style="display: inline-flex; flex-wrap: wrap">
                        <DatePicker v-model="unusableDate" style="margin-right:10px" />
                        <button type="button" class="btn btn-default" @click="addUnusableDay">추가</button>
                    </div><br>
                    <div class="input-group" style="display: inline-flex; flex-wrap: wrap">
                        <MarkLbl v-for="(hitem, hidx) in usableHour.unusableDays" :key="hidx" :label="getUnusableDayFormat(hitem)" :index="hidx" :close="rmvUnusableDays" />
                    </div>
                </div>
            </div>
            <!-- 20210525추가 -->
            <div class="modal" :style="showmodal && {'display': 'block'}">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header border-bottom-0">
                            <h3>상품검색</h3>
                        </div>
                        <div class="box-body">
                            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items: center;">
                                <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="refreshPage2">
                                    <option value="NoKeyword"></option>
                                    <option value="GoodsId">상품코드</option>
                                    <option value="GoodsNameContaining">상품명</option>
                                </select>
                                <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                                    <input type="text" class="form-control" style="flex:1" v-model="keyword" @change="refreshPage2" />
                                    <span class="input-group-btn">
                                        <button type="button" class="btn btn-default" @click="refreshPage2">검색</button>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <table class="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>상품코드</th>
                                            <th>상품명</th>
                                            <th>옵션코드</th>
                                            <th>옵션명</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(combined, index3) in content" v-bind:key="combined.goodsId">
                                        <tr v-for="(combined2, index2) in combined.options" :key="index2">
                                            <td v-if="index2 === 0" :rowspan="combined.options.length"> {{combined.goodsId}}</td>
                                            <td v-if="index2 === 0" :rowspan="combined.options.length"> {{combined.goodsName}}</td>
                                            <td>
                                                {{ combined.options[index2].optionId }}
                                            </td>
                                            <td>
                                                {{ combined.options[index2].optionName }}
                                            </td>
                                            <td>
                                                <button @click="combinedChild(index3,index2,combined.goodsType)">추가</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer" style="text-align:center !important">
                            <button type="button" class="btn btn-default" @click="closemodal">닫기</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- e 20210525추가 -->
            <div class="form-group">
                <label class="col-sm-2 control-label">옵션/가격 등록 *
                </label>
                <div class="col-sm-10">
                    <div class="input-group" style="width:100%">
                        <button type="button" class="btn btn-sm btn-default pull-right" @click="addOption">옵션추가</button>
                    </div>
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap text-center">
                            <thead>
                                <tr>
                                    <th>대표옵션</th>
                                    <th>옵션명</th>
                                    <th>정상가</th>
                                    <th>판매가</th>
                                    <th v-if="goodsOption.hasOwnDeliveryPrice">입금가</th>
                                    <th>재고수량</th>
                                    <th v-if="!isMobile(goodsType) && goodsType !== 'Combined'">권종</th>
                                    <th v-if="!isMobile(goodsType) && goodsType !== 'Combined'">인원</th>
                                    <th v-if="goodsType == 'Combined'">상품검색</th>
                                    <th>삭제</th>
                                    <th>순서</th>
                                </tr>
                            </thead>
                            <tbody v-for="(oitem, idx) in goodsOption.options" :key="idx">
                                <tr>
                                    <td>
                                        <label class="control-label">
                                            <input type="radio" :value="true" :checked="oitem.mainOption" @click="onmainOption(idx)" />
                                        </label>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" style="min-width:200px" v-model="oitem.optionName" />
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" :disabled="goodsType == 'Combined'" style="min-width:80px" v-if="goodsType == 'Combined'" :value="calculatedListPrice(idx,'listprice')" />
                                        <input type="number" class="form-control" style="min-width:80px" v-model="oitem.listPrice" v-else />
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" style="min-width:80px" :disabled="goodsType == 'Combined'" v-if="goodsType == 'Combined'" :value="calculatedListPrice(idx,'salePrice')" />
                                        <input type="number" class="form-control" style="min-width:80px" v-model="oitem.salePrice" v-else />
                                        <label>{{percent(oitem.listPrice - oitem.salePrice, oitem.listPrice)}}</label>
                                    </td>
                                    <td v-if="goodsOption.hasOwnDeliveryPrice">
                                        <input type="number" class="form-control" style="min-width:80px" v-model="oitem.deliveryPrice" />
                                        <label>{{percent(oitem.salePrice - oitem.deliveryPrice, oitem.salePrice)}}</label>
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" max="99999" style="min-width:80px" :disabled="goodsType == 'Combined'" :value="calculatedListPrice(idx,'inventory')" v-if="goodsType == 'Combined'" />
                                        <input type="number" class="form-control" max="99999" style="min-width:80px" v-model="oitem.inventory" v-else />
                                    </td>
                                    <td v-if="!isMobile(goodsType) && goodsType !== 'Combined'">
                                        <label class="control-label" style="display:block;text-align: left;">
                                            <input type="radio" value="Adult" v-model="oitem.ageGroup" />성인
                                        </label>
                                        <label class="control-label" style="display:block;text-align: left;">
                                            <input type="radio" value="Child" v-model="oitem.ageGroup" />어린이
                                        </label>
                                        <label class="control-label" style="display:block;text-align: left;">
                                            <input type="radio" value="Baby" v-model="oitem.ageGroup" />유아
                                        </label>
                                    </td>
                                    <td v-if="!isMobile(goodsType) && goodsType !== 'Combined'">
                                        <div class="input-group">
                                            <input type="number" class="form-control" max="99999" min="1" style="max-width:80px;" v-model="oitem.headcount" />
                                            <h5>명</h5>
                                        </div>
                                    </td>
                                    <td v-if="goodsType == 'Combined'">
                                        <button @click="onClickSearch(idx)">상품검색</button>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-social-icon" @click="rmvoption(idx)">
                                            <i class="fa fa-fw fa-trash-o"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <drop @drop="optionsort(idx, $event)">
                                            <drag class="btn btn-default btn-social-icon" :transfer-data="{index:idx, mark:'option'}">
                                                <i class="fa fa-fw fa-arrows-v"></i>
                                            </drag>
                                        </drop>
                                    </td>
                                </tr>
                                <tr v-for="(combinedChild, idx2) in oitem.childOptions" :key="idx2">
                                    <td>
                                        {{ combinedChild.optionId }}
                                    </td>
                                    <td>
                                        {{ combinedChild.goodsName}} {{ combinedChild.optionName }}
                                    </td>
                                    <td>
                                        {{ combinedChild.listPrice }}
                                    </td>
                                    <td>
                                        {{ combinedChild.salePrice }}
                                    </td>
                                    <td>
                                        {{ combinedChild.inventory }}
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-social-icon" @click="rmvsubOption(idx,idx2)">
                                            <i class="fa fa-fw fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="gotoStep_0" style="margin-right:10px">이전</button>
            <button type="button" class="btn btn-default" @click="checkOptionPrice">다음</button>
        </div>
    </div>
    <div v-if="stepval== 2 && goodsType != null" class="box box-solid">
        <div class="box-body form-horizontal">
<!--          {{ brandstores }}-->
            <UseStore :noShowLabel="false"
                      @brandkeyUpdatechilde="brandkeyUpdate"
                      @brandstoresUpdatechilde="brandstoresUpdate"
                      @tmselstoreschild="tmselstoresUpdate"
                      @tselstoreschilde="tselstoresUpdate"
                      :brandkey="brandkey"
                      :goodsType="goodsType"
                      :tmselstores="tmselstores"
                      :serviceCondition="serviceCondition"
                      :brandstores="brandstores"
                      :tselstores="tselstores" />

            <div class="form-group">
                <label class="col-sm-2 control-label">판매기간 *</label>
                <div class="col-sm-10" style="display:inline-flex;flex-wrap: wrap;">
                    <div class="radio">
                        <label>
                            <input type="radio" :value="false" v-model="serviceCondition.timeCondition.hasSalesPeriod" />제한없음
                        </label>
                        <label>
                            <input type="radio" :value="true" v-model="serviceCondition.timeCondition.hasSalesPeriod" />제한있음
                        </label>
                    </div>
                    <div class="input-group" v-if="serviceCondition.timeCondition.hasSalesPeriod" style="display: inline-flex;flex-wrap: wrap;">
                        <DatePicker v-model="serviceCondition.timeCondition.saleStartDate" :last="serviceCondition.timeCondition.saleEndDate" />
                        <span class="input-group-addon" style="border:none;display:inline-flex">-</span>
                        <DatePicker v-model="serviceCondition.timeCondition.saleEndDate" :first="serviceCondition.timeCondition.saleStartDate" />
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="!isBooking(goodsType) && goodsType !== 'Combined'">
                <label class="col-sm-2 control-label">유효기간 *</label>
                <div class="col-sm-10">
                    <div class="radio">
                        <label>
                            <input type="radio" value="SetDayCount" v-model="serviceCondition.timeCondition.availableDateSetting" />구매후
                        </label>
                        <div class="input-group" style="display:inline-flex;margin-left:20px" v-if="serviceCondition.timeCondition.availableDateSetting == 'SetDayCount'">
                            <input type="number" class="form-control" v-model="serviceCondition.timeCondition.availableDayCount" @input="inputableDayCount" @change="inputableDayCount" />
                            <h5>일</h5>
                        </div>
                    </div>
                    <div class="input-group" style="margin-top:10px;display:inline-flex;flex-wrap: wrap;">
                        <div class="radio">
                            <label>
                                <input type="radio" value="SetFromToDate" v-model="serviceCondition.timeCondition.availableDateSetting" />기간 지정
                            </label>
                        </div>
                        <div style="display: inline-flex;flex-wrap: wrap;" v-if="serviceCondition.timeCondition.availableDateSetting == 'SetFromToDate'">
                            <DatePicker v-model="serviceCondition.timeCondition.availableFromDate" :last="serviceCondition.timeCondition.availableToDate" />
                            <span class="input-group-addon" style="border:none;display:inline-flex">-</span>
                            <DatePicker v-model="serviceCondition.timeCondition.availableToDate" :first="serviceCondition.timeCondition.availableFromDate" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="!isBooking(goodsType) && !introduction.forCombined">
                <label class="col-sm-2 control-label">취소기한 *</label>
                <div class="col-sm-10">
                    <div class="radio">
                      <label>
                        <input type="radio" value="SetDayCount" v-model="serviceCondition.timeCondition.cancellableDateSetting"/>구매후
                      </label>
                      <div class="input-group" style="display: inline-flex; flex-wrap: wrap;"
                           v-if="serviceCondition.timeCondition.cancellableDateSetting == 'SetDayCount'">
                        <label class="margin-r-5">
                            <input type="radio" :value="0" v-model="serviceCondition.timeCondition.itemCancellableDayCount" />취소불가
                        </label>
                        <label class="margin-r-5">
                            <input type="radio" :value="7" v-model="serviceCondition.timeCondition.itemCancellableDayCount" />취소가능 [7일]
                        </label>
                        <label class="margin-r-5">
                            <input type="radio" :value="90" v-model="serviceCondition.timeCondition.itemCancellableDayCount" />취소가능 [90일]
                        </label>
                        <label class="margin-r-5">
                            <input type="radio" :value="100" :checked="(serviceCondition.timeCondition.itemCancellableDayCount != 0) && (serviceCondition.timeCondition.itemCancellableDayCount != 7) && (serviceCondition.timeCondition.itemCancellableDayCount != 90)"
                                   @click="onCancellableDayCount" />취소가능 [기타]
                        </label>
                    <div class="input-group" style="display:inline-flex; margin-top: -8px; padding-left: 4px;"
                         v-if="(serviceCondition.timeCondition.itemCancellableDayCount != 0) && (serviceCondition.timeCondition.itemCancellableDayCount != 7) && (serviceCondition.timeCondition.itemCancellableDayCount != 90) && (serviceCondition.timeCondition.cancellableDateSetting == 'SetDayCount')">
                        <input type="number" class="form-control"
                               v-model="serviceCondition.timeCondition.itemCancellableDayCount" />
                        <h5>일</h5>
                    </div>
                </div>
            </div>
              <div class="form-group"
                   style="margin: 0px;margin-top: 10px;display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="radio">
                  <label>
                    <input type="radio" value="SetFromToDate"
                           v-model="serviceCondition.timeCondition.cancellableDateSetting"/>기간 지정
                  </label>
                </div>
                <div style="display: inline-flex; flex-wrap: wrap"
                     v-if="serviceCondition.timeCondition.cancellableDateSetting == 'SetFromToDate'">

                  <DatePicker v-model="serviceCondition.timeCondition.cancellableToDate"/>
                </div>
              </div>
            </div>
            </div>


            <div class="form-group" v-if="goodsType ==='PointCoupon'">
                <label class="col-sm-2 control-label">사용매장 *</label>
                    <div class="col-sm-10" style="display:inline-flex;flex-wrap: wrap;">
                        <div class="radio">
                            <label>
                                <input type="radio" :value="false" v-model="serviceCondition.useStore" >제한없음
                            </label>
                            <label>
                                <input type="radio" :value="true" v-model="serviceCondition.useStore" />매장선택
                            </label>
                        </div>
                      <!--                      여기-->
                    </div>
                    <div class="col-sm-12">
                        <PointCouponUserStore v-if="goodsType == 'PointCoupon' && serviceCondition.useStore == true "
                                              :noShowLabel="true"
                                              @pointbrandkeyUpdatechilde="pointCouponbrandkeyUpdate"
                                              @pointbrandstoresUpdatechilde="pointCouponbrandstoresUpdate"
                                              @pointtmselstoreschild="pointCoupontmselstoresUpdate"
                                              @pointtselstoreschilde="pointCoupontselstoresUpdate"
                                              :brandkey="pointCouponbrandkey"
                                              :goodsType="goodsType"
                                              :tmselstores="pointCoupontmselstores"
                                              :serviceCondition="pointCouponserviceCondition"
                                              :brandstores="pointCouponbrandstores"
                                              :tselstores="pointCoupontselstores" />
                    </div>
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="gotoStep_1" style="margin-right:10px">이전</button>
            <button type="button" class="btn btn-default" @click="usecondition_Next">다음</button>
        </div>
    </div>
    <div v-if="stepval== 3 && goodsType != null" class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-2 control-label">판매채널 *</label>
                <div class="col-sm-10">
                    <div class="radio">
                        <label>
                            <input type="radio" :value="true" v-model="b2bChannel.channelSalesAgreement" />외부채널 배포 동의
                        </label>
                        <label>
                            <input type="radio" :value="false" v-model="b2bChannel.channelSalesAgreement" />외부채널 배포 동의 안함
                        </label>
                    </div>
                    <div class="input-group checkbox" style="display: inline-flex;flex-wrap: wrap;" v-if="b2bChannel.channelSalesAgreement">
                        <label v-for="(chitem, chidx) in channels" :key="chidx">
                            <input type="checkbox" :value="chitem.channelCode" :checked="isAgreeChannel(chitem.channelCode)" @change="onchangechannel(chitem.channelCode, $event)" />{{chitem.channelName}}
                        </label>
                    </div>
                    <label v-if="b2bChannel.channelSalesAgreement" style="display:block;margin-top:10px;">※ 신규 채널 추가 오픈 시 해당 채널 배포는 동의한 것으로 간주됩니다.</label>
                </div>
            </div>
        </div>
        <div class="box-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-2 control-label">테이블엔조이 배포여부</label>
                <div class="col-sm-10">
                    <div class="radio">
                        <label>
                            <input type="radio" :value="false" v-model="b2bChannel.tablenjoyExcluded" />테이블엔조이 배포 동의
                        </label>
                        <label>
                            <input type="radio" :value="true" v-model="b2bChannel.tablenjoyExcluded" />테이블엔조이 배포 동의 안함
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="gotoStep_2" style="margin-right:10px">이전</button>
            <button type="button" class="btn btn-default" @click="gotoStep_4">다음</button>
        </div>
    </div>
    <div v-if="stepval== 4 && goodsType != null" class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">판매정보</h3>
        </div>
        <div class="box-body form-horizontal">
            <h4>사용정보 *</h4>
            <div class="form-group" style="margin:0px">
                <textarea class="form-control" v-model="usage.useInfo" rows="8"></textarea>
            </div>
            <h4>기본정보 *</h4>
            <div class="form-group" style="margin:0px">
                <textarea class="form-control" v-model="usage.baseInfo" rows="8"></textarea>
            </div>
            <h4>유의사항 *</h4>
            <div class="form-group" style="margin:0px">
                <textarea class="form-control" v-model="usage.notes" rows="8"></textarea>
            </div>
            <h4>취소/환불 규정 *</h4>
            <div class="form-group" style="margin:0px">
                <textarea class="form-control" v-model="usage.returnsAndRefunds" rows="8"></textarea>
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" v-if="goodsType != 'Booking'" class="btn btn-default" @click="gotoStep_3" style="margin-right:10px">이전</button>
            <button type="button" v-if="goodsType == 'Booking'" class="btn btn-default" @click="gotoStep_2" style="margin-right:10px">이전</button>
            <button type="button" class="btn btn-default" @click="checkSaleInf">다음</button>
        </div>
    </div>
    <div v-if="stepval== 5 && goodsType != null" class="box box-solid">
        <div class="box-body form-horizontal">
            <h4>상품정보고시 *</h4>
            <div class="form-group">
                <label class="col-sm-2 control-label">발행자</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="notice.publisher" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">유효기간, 이용조건</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="notice.periodsAndConditions" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">이용가능매장</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="notice.availableStores" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">환불조건 및 방법</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="notice.refundTerms" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">소비자관련 상담전화</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" v-model="notice.contactInfo" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">할인쿠폰 사용여부 *</label>
                <div class="col-sm-10 radio">
                    <label>
                        <input type="radio" value="Usable" v-model="promo.discountCouponUsable" />사용가능
                    </label>
                    <label>
                        <input type="radio" value="Unusable" v-model="promo.discountCouponUsable" />사용불가
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">리워드 적립 *</label>
                <div class="col-sm-10" style="display:inline-flex;flex-wrap: wrap;">
                    <div class="radio">
                        <label>
                            <input type="radio" :value="false" v-model="promo.rewardPointAllowed" />리워드 적립없음
                        </label>
                        <label>
                            <input type="radio" :value="true" v-model="promo.rewardPointAllowed" />리워드 적립
                        </label>
                    </div>
                    <div v-if="promo.rewardPointAllowed" class="input-group" style="display:inline-flex;">
                        <input type="number" class="form-control" v-model="promo.rewardPointPercentage" />
                        <h5>%</h5>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">모바일쿠폰 발송여부 *</label>
                <div class="col-sm-10 radio">
                    <label>
                        <input type="radio" :value="true" v-model="promo.issueMobileCoupon" />발송
                    </label>
                    <label>
                        <input type="radio" :value="false" v-model="promo.issueMobileCoupon" />미발송
                    </label>
                </div>
            </div>

            <div class="form-group">
                <label class="col-sm-2 control-label">파트너 상품 여부 *</label>
                <div class="col-sm-10">
                    <div class="radio">
                        <label>
                            <input type="radio" value="None" v-model="promo.goodsPartner" />없음
                        </label>
                        <label>
                            <input type="radio" value="HappyMoney" v-model="promo.goodsPartner" />해피머니
                        </label>
                        <label>
                            <input type="radio" value="KoreaPrepaidCard" v-model="promo.goodsPartner" />한국선불카드
                        </label>
                    </div>
                </div>
            </div>

          <div class="form-group">
            <label class="col-sm-2 control-label">과도발급 관리여부 *</label>
            <div class="col-sm-10">
              <div class="input-group" style="display:inline-flex">
                <div class="radio">
                  <label>
                    <input type="radio" :value="false" v-model="promo.excessiveAllowed"/>
                    해당없음
                  </label>
                  <label>
                    <input type="radio" :value="true" v-model="promo.excessiveAllowed"/>
                    해당
                  </label>
                </div>
              </div>
              <label v-if="promo.excessiveAllowed">마지막 쿠폰발급 기준 1시간 이내</label>
              <div v-if="promo.excessiveAllowed" class="input-group" style="display:inline-flex">
                <input type="number" class="form-control" style="width:80px" v-model="promo.excessiveCount"/>
              </div>
              <label v-if="promo.excessiveAllowed">건 발급 초과 시 알람</label>
            </div>
          </div>

        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="showDisallowdlg" style="margin-right:10px">거절</button>
            <button type="button" class="btn btn-default" @click="showAllowdlg">승인</button>
        </div>
    </div>
    <div v-if="stepval== 6">
        <div class="text-center">
            <h3>상품 승인이 완료되었습니다.</h3>
            <button type="button" class="btn btn-default" style="margin-right:10px" @click="goBack">검수신청 더보기</button>
            <button type="button" class="btn btn-default" @click="showB2bprint" v-if="goodsType != 'Booking'">B2B이미지 생성하기</button>
        </div>
    </div>
    <div class="modal" :style="addimgdlg && {'display': 'block'}">
        <div class="modal-dialog drag">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeAddImage">
                        <span aria-hidden="true">×</span>
                    </button>
                    추가 이미지
                </div>
                <div v-if="showDragImg2">
                    <div class="modal-body addmodals">
                        <div :class="[addStoreImgUse ? 'addimgInputGroup':'addimgInputGroup wid100']">
                            <div class="form-group">
                                <label class="control-label">제목</label>
                                <input type="text" class="form-control" v-model="addsubimg.title" />
                            </div>
                            <div class="form-group">
                                <label class="control-label">설명</label>
                                <input type="text" class="form-control" v-model="addsubimg.description" />
                            </div>
                            <div class="form-group">
                                <label class="control-label flexDiv">
                                    <span>이미지 추가 최대 3개</span>
                                    <span class="lfetImgTitle">
                                        <input type="checkbox" v-model="addStoreImgUse">
                                        <label :for="addStoreImgUse">매장 이미지 사용</label>
                                    </span>
                                </label>
                                <div class="addlist" style="width:100%">
                                    <StoreProdImg :value="addsubimg.images" :index="addsubimg.images.length" style="margin-right:10px" @getsubFileData="getsubFileData" @newimgadd="onAddsubImg" :change="onRmvSubImg" ref="subImages" />
                                </div>
                            </div>
                        </div>

                        <div class="imgBoxDivGroup" v-if="addStoreImgUse">
                            <div class="imgDragbox" style="background: #dedede;">
                                <div class="imgDragbox-div">
                                    <select class="form-control imgdragselect" v-model="imgdragselect" @change="storeImgList">
                                        <option v-for="(store,idx) in storeList" :key="idx" :value="store.storeCode" :selected="imgdragselect">{{ store.name }}</option>
                                    </select>
                                    <button @click="usestoreedit('add')" type="button" class="btn btn-default">이용 가능 매장 수정</button>
                                </div>
                                <ul class="titleArr">
                                    <li class="cls_click" v-for="(titem,idx) in titleArr" :key="idx" @click="titleArrClcik(titem)">
                                        <a>{{titem.title}}</a>
                                    </li>
                                </ul>
                                <div>
                                    <ul class="tab">
                                        <li v-for="title in storeTitleList" :key="title">{{ title }}</li>
                                    </ul>
                                </div>
                                <div :id="'holder' + addsubimg.images.length" class="dropImgBox" v-on:dragover="allowDrop" v-on:drop="ondrop($event,1)" @dragenter="onDragenter">
                                    <img v-for="(img,idx) in imgDragList" :key="idx" class="imgboxChild" :src="img.url" :width="img.width" :height="img.height" draggable="true" v-on:dragstart="drag" :id="'imgidx'+idx">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="editsubimgidx < 0" type="button" class="btn btn-default" @click="onAddsubImages">추가</button>
                        <button v-if="editsubimgidx > -1" type="button" class="btn btn-default" @click="onMdfsubImages">저장</button>
                    </div>
                </div>
                <div style="height:400px" v-else>
                    <UseStore :noShowLabel="true" @brandkeyUpdatechilde="brandkeyUpdate" @brandstoresUpdatechilde="brandstoresUpdate" @tmselstoreschild="tmselstoresUpdate" @tselstoreschilde="tselstoresUpdate" :brandkey="brandkey" :goodsType="goodsType" :tmselstores="tmselstores" :serviceCondition="serviceCondition" :brandstores="brandstores" :tselstores="tselstores" />
                    <div class="text-center mt-2">
                        <button @click="storeAddSelect('add')" type="button" class="btn btn-default mr-05">반영</button>
                        <button @click="storeTableClose('add')" type="button" class="btn btn-default">취소</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal" :style="allowdlg && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="text-center">
                        <h4>검수 승인 처리하시겠습니까?</h4>
                        <h5>승인 이후 테이블엔조이 사이트는 즉시 판매가 시작되며, B2B 상품관리에 채널 배포 요청됩니다.</h5>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="closeAllowdlg">취소</button>
                    <button type="button" class="btn btn-default" @click="onapprove">확인</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :style="disallowdlg && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">불가 사유</div>
                <div class="modal-body form-horizontal">
                    <textarea class="form-control" rows="5" v-model="reason"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="closeDisallowdlg">취소</button>
                    <button type="button" class="btn btn-default" @click="ondeny">확인</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" v-if="b2bprint" style="display: block">
        <B2BPrinter :goodsId="goodsId.toString()" :onClose="closeB2bprint" :usage="usage" :subImagesInf="introduction.subImages" />
    </div>
    <!-- 매장 이미지에서 선택 추가 -->
    <div class="modal" :style="storeimgdlg && {'display': 'block'}">
        <div class="modal-dialog drag">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeStoreImage">
                        <span aria-hidden="true">×</span>
                    </button>
                    대표 이미지
                </div>
                <div class="modal-body">
                    <div class="imgBoxDivGroup" v-if="showDragImg">
                        <div>
                            <div style="width:500px;height:400px">
                                <storeThumbnail ref="stfake" @imgDragListUpdate="imgDragMainListUpdate" style="width:500px;height:400px" v-if="fakemainImage.url != null" :newsubimguse="newsubimguse" v-model="fakemainImage.url" :imgtype="imgtype.GOODS" :imgFile="imgFile" :trueImg="trueImg" :change="onFakeMainImage" />
                            </div>
                            <div class="text-center mt-2">
                                <button type="button" class="btn btn-default" @click="imgReflect">반영</button>
                            </div>
                        </div>
                        <div class="imgDragbox" style="background: #dedede;">
                            <div class="imgDragbox-div">
                                <!-- {{ imgdragselect }} -->
                                <select class="form-control imgdragselect" v-model="imgdragselect" @change="storeImgList">
                                    <option v-for="(store,idx) in storeList" :key="idx" :value="store.storeCode" :selected="imgdragselect">{{ store.name }}</option>
                                </select>
                                <button @click="usestoreedit('represent')" type="button" class="btn btn-default">이용 가능 매장 수정</button>
                            </div>
                            <ul class="titleArr">
                                <li class="cls_click" v-for="(titem,idx) in titleArr" :key="idx" @click="titleArrClcik(titem)">
                                    <a>{{titem.title}}</a>
                                </li>
                            </ul>
                            <div>
                                <ul class="tab">
                                    <li v-for="title in storeTitleList" :key="title">{{ title }}</li>
                                </ul>
                            </div>
                            <div id="holder" class="dropImgBox" v-on:dragover="allowDrop" v-on:drop="ondrop($event,1)" @dragenter="onDragenter">
                                <!-- {{ imgDragList }} -->
                                <span v-for="(img) in imgDragList" :key="img.url" style="display:none">
                                    {{ img.url }}
                                    {{ img.width }}
                                    {{ img.height }}
                                </span>
                                <img v-for="(img,idx) in imgDragList" :key="idx" class="imgboxChild" :src="img.url" :width="img.width" :height="img.height" draggable="true" v-on:dragstart="drag" :id="'imgidx'+idx">
                            </div>
                        </div>
                    </div>
                    <div style="height:400px" v-else>
                        <UseStore :noShowLabel="true" @brandkeyUpdatechilde="brandkeyUpdate" @brandstoresUpdatechilde="brandstoresUpdate" @tmselstoreschild="tmselstoresUpdate" @tselstoreschilde="tselstoresUpdate" :brandkey="brandkey" :goodsType="goodsType" :tmselstores="tmselstores" :serviceCondition="serviceCondition" :brandstores="brandstores" :tselstores="tselstores" />
                        <div class="text-center mt-2">
                            <button @click="storeAddSelect('represent')" type="button" class="btn btn-default mr-05">반영</button>
                            <button @click="storeTableClose('represent')" type="button" class="btn btn-default">취소</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                </div> -->
            </div>
        </div>
    </div>
    <div style="display:none">{{bbo}}</div>
</section>
</template>

<script>
import {
    rescode,
    menuval,
    imgtype
} from "../../../lib/consts";
import storeThumbnail from "../../../components/storeThumbnail";
import StoreProdImg from "../../../components/StoreProdImg";
import SingleThumbnail from "../../../components/SingleThumbnail";
import ProdImg from "../../../components/ProdImg";
import B2BPrinter from "../../../components/B2BPrinter";
import ProdAddImg from "../../../components/ProdAddImg";
import DatePicker from "../../../components/DatePicker";
import MarkLbl from "../../../components/MarkLbl";
import UseStore from "./component/UseStore.vue";
import PointCouponUserStore from "./component/PointCouponUserStore.vue";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    goodsapi,
    b2bapi,
    brandapi
} from "../../../lib/api";
import {
    getGoodsTypeStr,
    getAgeGroupStr,
    getPercent,
    getMobileCoupon,
    getStoreTypeStr
} from "../../../lib/utility";
import {
    Drag,
    Drop
} from "vue-drag-drop";
import moment from 'moment';
export default {
    components: {
        StoreProdImg,
        storeThumbnail,
        SingleThumbnail,
        ProdImg,
        ProdAddImg,
        Drag,
        Drop,
        MarkLbl,
        DatePicker,
        B2BPrinter,
        UseStore,
        PointCouponUserStore,
    },
    created() {
        this.registerId = this.$route.query.registerId;
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_PRD,
            path2: menuval.LV2_PRD_REG,
            title: "",
        });
    },
    data() {
        return {
            selectSiteName : [],
            selectedSiteName : "",
            goodsSiteName: "",
            goodsBrandName:"",
            updateSiteName: "",
            fakemainImage: {
                url: '', //메인 이미지 URL
                width: 0, //메인 이미지 width
                height: 0, //메인 이미지 height
            },
            titleArr: [],
            newsubimguse: false,
            imgdragselect: null,
            showDragImg2: true,
            showDragImg: true,
            imgDragList: [],
            addStoreImgUse: false,
            imgFile2: {},
            trueImg2: "",
            storeimgdlg: false,
            imgFile: {},
            trueImg: "",
            storeTitleList: [],
            storeList: [],
            showDragImg: true,
            exchn: [],
            exchn2: [],
            exchAn: [],
            bbo: [],
            goodsTypesL: true,
            keywordType: "NoKeyword",
            keyword: "",
            content: [],
            content2: [],
            storeimgdlg: false,
            showmodal: false,
            stepval: 0,
            registerId: 0,
            goodsId: "",
            goodsType: null, //[상품 종류]MobileCoupon 모바일쿠폰, BookingCoupon 예약권, Booking 예약, MoneyCoupon 금액권, CashCoupon 캐시권
            introduction: {
                goodsName: "", //상품명
                goodsTag: null,
                intro: "", //상품소개
                mainImage: {
                    url: "", //메인 이미지 URL
                    width: 0, //메인 이미지 width
                    height: 0, //메인 이미지 height
                },
                subImages: [],
                forCombined: false,
                /*
                title             String      서브 이미지 제목
                description       String      서브 이미지 설명
                displayOrder      Number      서브 이미지 배열 순서
                images[].url      String      서브 이미지 URL
                images[].width    Number      서브 이미지 width
                images[].height   Number      서브 이미지 height
                */
            },
            addsubimg: {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
                /*
                url      String      서브 이미지 URL
                width    Number      서브 이미지 width
                height   Number      서브 이미지 height
                */
            },
            editsubimgidx: -1,
            serviceCondition: {
                serviceArea: {
                    brands: [],
                    /*
                    brandCode    String    이용 가능 브랜드 코드.
                    storeCodes:[]   Array     이용 가능 매장 코드
                    stores:[]
                    storeCode, storeName, storeType
                    */
                },
                timeCondition: {
                    hasSalesPeriod: false, //판매기간 제한 있음 여부
                    saleStartDate: "", //판매 시작일 (hasSalesPeriod 가 true 일 경우 사용)
                    saleEndDate: "", //판매 종료일( hasSalesPeriod 가 false 일 경우 사용
                    availableDateSetting: "SetDayCount", //유효기간 설정 타입    SetDayCount: 구매 후 며칠동안 유효한지 설정, SetFromToDate: 시작일과 종료일을 지정(모바일쿠폰/금액권/예약권 상품일 경우 입력)
                    availableDayCount: 60, //유효기간 날짜 수  (availableDateSetting이 SetDayCount일 경우 입력)
                    availableFromDate: "", //유효기간 시작일 (availableDateSetting이 SetFromToDate일 경우 입력)
                    availableToDate: "", //유효기간 종료일 (availableDateSetting이 SetFromToDate일 경우 입력)
                    cancellableDateSetting: "SetDayCount", //유효기간 설정 타입    SetDayCount: 구매 후 며칠동안 유효한지 설정, SetFromToDate: 시작일과 종료일을 지정(모바일쿠폰/금액권/예약권 상품일 경우 입력)
                    cancellableToDate: "", //유효기간 종료일 (availableDateSetting이 SetFromToDate일 경우 입력)
                    itemCancellableDayCount: 0, //구매취소 가능 일수. 취소불가일 경우 0을 입력 (모바일쿠폰/금액권/예약권 상품일 경우 입력)
                },
                useStoreArea:{
                  brands: [],
                }
            },
            brandkey: "",
            brandstores: [],
            tselstores: [],
            tmselstores: [],
            pointCouponbrandkey: "",
            pointCoupontselstores: [],
            pointCoupontmselstores: [],
            pointCouponserviceCondition: {
              serviceArea: {
                brands: [],
                /*
                brandCode:    String    이용 가능 브랜드 코드.
                storeCodes:[]   Array     이용 가능 매장 코드
                stores:[]
                storeCode, storeName, storeType
                */
              },
              useStoreArea:{
                brands: [],
              }
            },
            pointCouponbrandstores: [],
            goodsOption: {
                hasOwnDeliveryPrice: false, //상품 별도로 입금가를 지정하여 사용할 경우 true
                options: [],
                /*
                optionName:"",//             String        옵션 이름
                mainOption:false,//             Boolean       대표옵션인 경우 true
                displayOrder:1,//           Number        표출 순서
                listPrice:"",//              String        제품 가격
                salePrice:"",//              String        판매 가격
                deliveryPrice:"",//          String        입금가. hasOwnDeliveryPrice 가 true 인 경우 필수 입력
                inventory:0,//              Number        판매 수량
                ageGroup:'Adult',//               String        성인, 어린이, 유아 구분(예약/예약권 상품일 경우 입력)
                                      [Adult, Child, Baby]
                headcount:0,//              Number        인원수  (예약/예약권 상품일 경우 입력)
                currencyCode:'KRW',//           String        통화 코드  [USD, KRW]
                */
            },
            usableHour: { //예약/예약권 상품일 경우 입력
                dayOfWeekType: "Weekday", //요일 타입 [Everyday, Weekday, Weekend, Custom]
                monday: false, //월요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                tuesday: false, //화요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                wednesday: false, //수요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                thursday: false, //목요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                friday: false, //금요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                saturday: false, //토요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                sunday: false, //일요일 지정 여부 (dayOfWeekType 이 Custom 일 때 사용)
                hours: [], //이용 시간 (입장 시간)
                unusableDays: [], //이용 불가일
            },
            addhours: 0,
            addminutes: 0,
            b2bChannel: {
                channelSalesAgreement: true, //외부채널 배포 동의 여부(모바일쿠폰/금액권/예약권 상품일 경우 입력)
                excludedChannels: [], //채널 배포 제외 리스트(모바일쿠폰/금액권/예약권 상품일 경우 입력)
                tablenjoyExcluded: true, //테이블엔조이 배포 동의 여부
            },
            channels: [],
            channels2: [],
            channels3: [],
            channels4: [],
            usage: {
                baseInfo: "", //기본정보
                useInfo: "", //사용정보
                notes: "", //유의사항
                returnsAndRefunds: "", //취소/환불 규정
            },
            allowdlg: false,
            disallowdlg: false,
            addimgdlg: false,
            b2bprint: false,
            notice: {
                publisher: "상세페이지 별도표기", //발행자
                periodsAndConditions: "상세페이지 별도표기", //이용기간, 이용조건
                availableStores: "상세페이지 별도표기", //이용가능매장
                refundTerms: "상세페이지 별도표기", //환불조건 및 방법
                contactInfo: "상세페이지 별도표기", //소비자관련 상담 연락처
            },
            promo: {
                specialOfferAllowed: true, //할인쿠폰 사용여부
                rewardPointAllowed: false, //포인트 적립 여부
                rewardPointPercentage: "0", //포인트 적립 퍼센티지
                discountCouponUsable: 'Usable', //모바일 쿠폰 발급 여부
                goodsPartner:'None',
                issueMobileCoupon: true,
                excessiveAllowed : false,
                excessiveCount : 0,
            },
            reason: "",
            unusableDate: "",
            trindex: "",
            optionId: '',
            optionName: '',
            listPrice: 0,
            salePrice: '',
            totalListPrice: 0,
        };
    },
    mounted() {
        this.refreshPage();
        this.refreshPage2();
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        imgtype() {
            return imgtype;
        },
        getTotalStore() {
            var retval = 0;
            for (var idx = 0; idx < this.serviceCondition.serviceArea.brands.length; idx++) {
                if (typeof this.serviceCondition.serviceArea.brands[idx].stores != 'undefined' && this.serviceCondition.serviceArea.brands[idx].stores != null) {
                    retval = retval + this.serviceCondition.serviceArea.brands[idx].stores.length;
                }
            }
            return retval;
        },
        getPointCouponTotalStore() {
          var retval = 0;
          for (var idx = 0; idx < this.serviceCondition.useStoreArea.brands.length; idx++) {
            if (typeof this.serviceCondition.useStoreArea.brands[idx].stores != 'undefined' && this.serviceCondition.useStoreArea.brands[idx].stores != null) {
              retval = retval + this.serviceCondition.useStoreArea.brands[idx].stores.length;
            }
          }
          return retval;
        },
    },
    methods: {
        sortlowestsub(sidx){
            if (sidx + 1 < this.introduction.subImages.length) {
                let t_array = this.introduction.subImages;
                const copyTarray = JSON.parse(JSON.stringify(t_array));

                t_array.splice(sidx,1);
                t_array.push(copyTarray[sidx]);

                this.introduction.subImages = t_array;
            }
        },
        sortTopsub(sidx){
            if (sidx > 0) {
                let t_array = this.introduction.subImages;
                const copyTarray = JSON.parse(JSON.stringify(t_array));

                t_array.splice(sidx,1);
                t_array.unshift(copyTarray[sidx]);

                this.introduction.subImages = t_array;
            }
        },
        onFakeMainImage(val, w, h) {
            this.fakemainImage.url = val;
            this.fakemainImage.width = w;
            this.fakemainImage.height = h;
        },
        // imgDragListUpdate(val, rib) {
        //     const storeimgTrue = this.imgDragListOriginal.find(element => element.url === val);
        //     console.log(storeimgTrue);
        //     if (storeimgTrue !== undefined) {
        //         this.imgDragList.push(rib);
        //     }
        // },
        imgDragMainListUpdate(val, rib) {
            const storeimgTrue = this.imgDragListOriginal.find(element => element.url === val);
            // console.log(storeimgTrue);
            if (storeimgTrue !== undefined) {
                this.imgDragList.push(rib[0]);
            }
        },

        // imgDragListDel(val, rib) {
        //     // console.log(storeimgTrue);

        //     let findss = this.imgDragList.filter(function (item) {
        //         return item.url !== val;
        //     });
        //     this.imgDragList = findss;
        //     // console.log(findss)
        // },
        getsubFileData(val) {
            this.addsubimg.images = val
        },
        allowDrop(event) {
            event.preventDefault();
        },
        drag(event) { //드래그 시작시 호출 할 함수
            event.dataTransfer.setData("Text", event.target.id);
            const targetEl = document.getElementById('holder');
        },
        onDragenter(event) {
            event.preventDefault()
        },
        onDragover(event) {
            event.preventDefault()
        },
        storeAddSelect(type) {
            let newStoreNameB = [];
            // console.log(this.serviceCondition)
            this.serviceCondition.serviceArea.brands.forEach(el => {
                el.stores.forEach(el2 => {
                    newStoreNameB.push({
                        name: el.brandName + " " + el2.storeName,
                        storeCode: el2.storeCode
                    });
                    this.imgdragselect = el2.storeCode;
                });
            });
            this.storeList = newStoreNameB;
            if (type === 'represent') {
                this.showDragImg = true;
            } else if (type === 'none') {

            } else {
                this.showDragImg2 = true;
            }
            this.storeImgList();
        },
        titleArrClcik(titleArr) {
            // console.log(titleArr)
            let vm = this;
            vm.imgDragList = [];
            titleArr.storeImages.forEach((el, idx) => {
                vm.imgDragList.push({
                    url: el.url,
                    width: el.width,
                    height: el.height,
                })
            });
            vm.imgDragListOriginal = JSON.parse(JSON.stringify(vm.imgDragList));
        },
        storeImgList() {
            reqGet(
                brandapi.base,
                brandapi.storeImageslist + "?storeCode=" + this.imgdragselect,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.imgDragList = [];
                            this.imgDragListOriginal = [];
                            this.titleArr = [];
                            // console.log(result.data.list)
                            result.data.list.forEach(el => {
                                // console.log(.url)
                                this.titleArr.push({
                                    title: el.title,
                                    storeImages: el.storeImages
                                })
                                this.imgDragListOriginal = JSON.parse(JSON.stringify(this.imgDragList));
                            });
                            this.titleArrClcik(result.data.list[0]);
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        storeTableClose(type) {
            if (type === 'represent') {
                this.showDragImg = true;
            } else {
                this.showDragImg2 = true;
            }
        },
        usestoreedit(type) {
            if (type === 'represent' || !type) {
                this.searchbrandstore();
                this.showDragImg = false;
            } else {
                this.searchbrandstore();
                this.showDragImg2 = false;
            }
        },
        brandstoresUpdate(brandstores) {
            this.brandstores = brandstores;
        },
        pointCouponbrandstoresUpdate(brandstores) {

          this.pointCouponbrandstores = brandstores;
        },
        tselstoresUpdate(tselstores) {
            this.tselstores = tselstores;
        },
        pointCoupontselstoresUpdate(tselstores) {
          this.pointCoupontselstores = tselstores;
        },
        tmselstoresUpdate(tmselstores) {
            this.tmselstores = tmselstores;
        },
        pointCoupontmselstoresUpdate(tmselstores) {
          this.pointCoupontmselstores = tmselstores;
        },
        brandkeyUpdate(brandkey) {
            this.brandkey = brandkey;
        },
        pointCouponbrandkeyUpdate(brandkey) {
          this.pointCouponbrandkey = brandkey;
        },

      //여기
        ondrop(e, id, type, idx) {

        },
        storeimage() {
            this.storeimgdlg = true;
            this.newsubimguse = false;
            if (this.introduction.mainImage.url.trim().length == 0) {
                this.$refs.subImages.initFakeData();
                this.fakemainImage = {
                    url: '', //메인 이미지 URL
                    width: 0, //메인 이미지 width
                    height: 0, //메인 이미지 height
                };
            } else {
                this.fakemainImage.url = this.introduction.mainImage.url
                this.fakemainImage.width = this.introduction.mainImage.width
                this.fakemainImage.height = this.introduction.mainImage.height
            }
            this.storeAddSelect('none');

        },
        closeStoreImage() {
            this.storeimgdlg = false;
            this.showDragImg = true;
        },
        imgReflect() {
            this.introduction.mainImage.url = this.fakemainImage.url; //메인 이미지 url
            this.introduction.mainImage.width = this.fakemainImage.width; //메인 이미지 width
            this.introduction.mainImage.height = this.fakemainImage.height; //메인 이미지 height
            if (this.introduction.mainImage.url) {
                this.storeimgdlg = false;
            } else {
                alert('대표 이미지를 선택해주세요.');
                return;
            }
        },
        calculatedListPrice(idx, type) {
            if (type == "salePrice") {
                if (this.goodsOption.options.length <= idx) {
                    return 0;
                } else if (this.goodsOption.options[idx].childOptions == undefined || this.goodsOption.options[idx].childOptions == null) {
                    return 0;
                } else {
                    return this.goodsOption.options[idx].childOptions.reduce((acc, item) => Number(acc) + Number(item.salePrice), 0);
                }
            } else if (type == "inventory") {
                if (this.goodsOption.options.length <= idx) {
                    return 0;
                } else if (this.goodsOption.options[idx].childOptions == undefined || this.goodsOption.options[idx].childOptions == null) {
                    return 0;
                } else {
                    let pushArr = [];
                    for (var i = 0; i < this.goodsOption.options[idx].childOptions.length; i++) {
                        pushArr.push(this.goodsOption.options[idx].childOptions[i].inventory);
                    }
                    return Math.min.apply(null, pushArr)
                }
            } else {
                if (this.goodsOption.options.length <= idx) {
                    return 0;
                } else if (this.goodsOption.options[idx].childOptions == undefined || this.goodsOption.options[idx].childOptions == null) {
                    return 0;
                } else {
                    return this.goodsOption.options[idx].childOptions.reduce((acc, item) => Number(acc) + Number(item.listPrice), 0);
                }
            }
        },
        combinedChild(index, index2, goodsType2s) {

            if (this.bbo[this.bbo.length - 1] !== undefined) {
                if (this.bbo[this.bbo.length - 1] == 'MoneyCoupon' || this.bbo[this.bbo.length - 1] == 'MobileCoupon') {
                    if (goodsType2s == 'BookingCoupon') {
                        alert('상품 타입을 확인 해주세요.')
                        return;
                    }
                } else if (this.bbo[this.bbo.length - 1] == 'BookingCoupon') {
                    if (goodsType2s !== 'BookingCoupon') {
                        alert('상품 타입을 확인 해주세요.')
                        return;
                    }

                }
            }

            //  if(this.bbo.indexOf('MobileCoupon') !== -1 || this.bbo.indexOf('MoneyCoupon') !== -1){
            // if(goodsType2s == 'BookingCoupon' || this.bbo.indexOf('BookingCoupon') !== -1){
            //       alert('상품 타입을 확인 해주세요.')
            //       return;
            //     }
            //   }
            // if(goodsType2s == 'BookingCoupon' || this.bbo.indexOf('BookingCoupon') !== -1){
            //   if(this.bbo.indexOf('MobileCoupon') !== -1 || this.bbo.indexOf('MoneyCoupon') !== -1 || goodsType2s == "MobileCoupon" || goodsType2s == "MoneyCoupon"){
            //       alert('상품 타입을 확인 해주세요.')
            //       return;
            //   }
            // }
            this.bbo.push(goodsType2s);
            let combinedChild = {
                optionId: this.content[index].options[index2].optionId,
                optionName: this.content[index].options[index2].optionName,
                listPrice: this.content[index].options[index2].listPrice,
                salePrice: this.content[index].options[index2].salePrice,
                inventory: this.content[index].options[index2].inventory,
                goodsName: this.content[index].goodsName,
            }
            if (this.goodsOption.options[this.trindex].childOptions == null) {
                this.goodsOption.options[this.trindex].childOptions = [];
            }
            this.goodsOption.options[this.trindex].childOptions.push(combinedChild);
            this.goodsOption.options[this.trindex].listPrice = this.content[index].options[index2].listPrice;
            this.goodsOption.options[this.trindex].salePrice = this.content[index].options[index2].salePrice;

        },
        rmvsubOption(ridx, ridx2) {
            if (confirm("세부옵션을 삭제하시겠습니까?")) {
                var t_mainOption = this.goodsOption.options[ridx].childOptions;
                console.log(t_mainOption)
                this.goodsOption.options[ridx].childOptions.splice(ridx2, 1);
            }
        },
        refreshPage2() {
            reqGet(
                goodsapi.base,
                goodsapi.combinedlist +
                "?keywordType=" + this.keywordType +
                "&keyword=" + this.keyword,
                this.authToken,
                (result3) => {
                    if (result3 != null) {
                        if (result3.code == rescode.REQ_SUCCESS) {
                            this.content = result3.data;
                        } else {
                            this.content = [];
                            alert(result3.message);
                        }
                    }
                }
            )
        },
        onClickSearch(trindex) {
            if (this.goodsOption.options[0].childOptions.length == 0) {
                this.bbo = [];
            }
            // console.log(this.goodsOption.options)
            // console.log(this.editoptions)
            this.showmodal = !this.showmodal;
            this.trindex = trindex;
        },
        closemodal() {
            this.showmodal = false;
        },
        gotoStep(n) {
            if (this.stepval == 0) {
                if (!this.checkStep_0()) {
                    return;
                }
            } else if (this.stepval == 1) {
                if (!this.checkStep_1()) {
                    return;
                }
            } else if (this.stepval == 2) {
                if (!this.checkStep_2()) {
                    return;
                }
            } else if (this.stepval == 4) {
                if (!this.checkStep_4()) {
                    return;
                }
            } else if (this.stepval == 5) {
                if (!this.checkStep_5()) {
                    return;
                }
            }

            if (n == 0) {
                this.gotoStep_0();
            } else if (n == 1) {
                this.gotoStep_1();
            } else if (n == 2) {
                this.gotoStep_2();
            } else if (n == 3) {
                this.gotoStep_3();
            } else if (n == 4) {
                this.gotoStep_4();
            } else if (n == 5) {
                this.gotoStep_5();
            }
        },
        gotoStep_0() {
            this.stepval = 0;
        },
        gotoStep_1() {
            this.stepval = 1;
        },
        gotoStep_2() {
            this.searchbrandstore();
            this.stepval = 2;
            if(this.goodsType==="PointCoupon"){
              this.pointCouponsearchbrandstore();
            }
        },
        gotoStep_3() {
            this.getgoodsSales();
            this.stepval = 3;
        },
        gotoStep_4() {
            this.stepval = 4;
        },
        gotoStep_5() {
            this.stepval = 5;
        },
        closeAddImage() {
            this.addimgdlg = false;
        },
        showEditSubImage(selsubimg, selidx) {
            this.storeAddSelect();
            if (this.storeList.length == 0) {
                this.storeList = [];
                this.serviceCondition.serviceArea.brands = [];
                this.imgDragList = [];
                this.imgDragListOriginal = [];
            } else {
                this.addStoreImgUse = true;
            }
            this.editsubimgidx = selidx;
            this.addsubimg = selsubimg;
            this.$refs.subImages.edtImgData(this.addsubimg.images)
            this.addimgdlg = true;
        },
        emvsubimg(ridx) {
            this.introduction.subImages.splice(ridx, 1);
        },
        sortdownsub(sidx) {
            if (sidx + 1 < this.introduction.subImages.length) {
                var t_array = this.introduction.subImages;
                this.introduction.subImages = [];
                var s_val = t_array[sidx];
                t_array[sidx] = t_array[sidx + 1];
                t_array[sidx + 1] = s_val;
                this.introduction.subImages = t_array;
            }
        },
        sortupsub(sidx) {
            if (sidx > 0) {
                var t_array = this.introduction.subImages;
                this.introduction.subImages = [];
                var s_val = t_array[sidx];
                t_array[sidx] = t_array[sidx - 1];
                t_array[sidx - 1] = s_val;
                this.introduction.subImages = t_array;
            }
        },
        showNewSubImage() {
            this.storeAddSelect();
            this.newsubimguse = true;
            this.editsubimgidx = -1;
            this.addStoreImgUse = false;
            if (this.storeList.length == 0) {
                this.storeList = [];
                this.serviceCondition.serviceArea.brands = [];
                this.imgDragList = [];
                this.imgDragListOriginal = [];
            } else {
                this.addStoreImgUse = true;
            }
            this.$refs.subImages.initFakeData();
            this.addsubimg = {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
            };
            this.addimgdlg = true;
        },
        closeAllowdlg() {
            this.allowdlg = false;
        },
        checkStep_5() {
            if (this.notice.publisher == "") {
                alert("발행자를 입력하세요.");
                return false;
            }
            if (this.notice.periodsAndConditions == "") {
                alert("유효기간, 이용조건을 입력하세요.");
                return false;
            }
            if (this.notice.availableStores == "") {
                alert("이용가능매장");
                return false;
            }
            if (this.notice.refundTerms == "") {
                alert("환불조건 및 방법을 입력하세요.")
                return false;
            }
            if (this.notice.contactInfo == "") {
                alert("소비자관련 상담전화을 입력하세요.");
                return false;
            }
            return true;
        },
        showAllowdlg() {
            if (!this.checkStep_5()) {
                return;
            }
            this.allowdlg = true;
        },
        closeDisallowdlg() {
            this.disallowdlg = false;
        },
        showDisallowdlg() {
            this.disallowdlg = true;
        },
        closeB2bprint() {
            this.b2bprint = false;
        },
        showB2bprint() {
            this.b2bprint = true;
        },
        refreshPage() {
          this.selectSiteName.push({name: "등록경로 변경 시 선택",value: ""});
          this.selectSiteName.push({name: "관리자",value: "tablenjoy"});
            reqGet(
                goodsapi.base,
                goodsapi.regadmget + "?registerId=" + this.registerId,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        console.log(result.data.goodsInfo.goodsOption)
                        for (var t = 0; t < result.data.goodsInfo.goodsOption.options.length; t++) {
                            for (var h = 0; h < result.data.goodsInfo.goodsOption.options[t].childOptions.length; h++) {
                                this.bbo.push(result.data.goodsInfo.goodsOption.options[t].childOptions[h].goodsType)
                            }
                        }
                        this.goodsSiteName = result.data.siteName;
                        this.goodsType = result.data.goodsInfo.goodsType;
                        this.introduction = result.data.goodsInfo.introduction;
                        // console.log(result.data.goodsInfo.serviceCondition)
                        this.fakemainImage.url = this.introduction.mainImage.url
                        this.fakemainImage.width = this.introduction.mainImage.width
                        this.fakemainImage.height = this.introduction.mainImage.height
                        // this.serviceCondition = result.data.goodsInfo.serviceCondition;
                        this.getStorebrandinf(result.data.goodsInfo.serviceCondition);
                        this.goodsOption = result.data.goodsInfo.goodsOption;
                        this.usableHour = result.data.goodsInfo.usableHour;
                        this.b2bChannel = result.data.goodsInfo.b2bChannel;
                        this.usage = result.data.goodsInfo.usage;
                        this.serviceCondition = result.data.goodsInfo.serviceCondition;
                        this.getPointCouponStorebrandinf(result.data.goodsInfo.serviceCondition);
                        this.pointCouponserviceCondition = result.data.goodsInfo.serviceCondition;
                      console.log(this.goodsType)

                    }
                }
            );
        },
        getStorebrandinf(svcnt) {
            var storeCodes = [];
            for (var bidx = 0; bidx < svcnt.serviceArea.brands.length; bidx++) {
                storeCodes = storeCodes.concat(svcnt.serviceArea.brands[bidx].storeCodes);
            }
            reqPost(
                brandapi.base,
                brandapi.admofStores,
                this.authToken, {
                    storeCodes: storeCodes,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            svcnt.serviceArea.brands = result.data.byBrands
                            this.serviceCondition = svcnt;

                            result.data.byBrands.forEach(brd =>{
                            this.selectSiteName.push({name: brd.brandName,value: brd.siteName});
                              if(brd.siteName == this.goodsSiteName){
                                this.goodsBrandName = brd.brandName;
                              }
                          })
                        }
                    }
                }
            );
        },
        getPointCouponStorebrandinf(svcnt) {
          var storeCodes = [];
          for (var bidx = 0; bidx < svcnt.useStoreArea.brands.length; bidx++) {
            storeCodes = storeCodes.concat(svcnt.useStoreArea.brands[bidx].storeCodes);
          }
          reqPost(
              brandapi.base,
              brandapi.admofStores,
              this.authToken, {
                storeCodes: storeCodes,
              },
              (result) => {
                if (result != null) {
                  if (result.code == rescode.REQ_SUCCESS) {
                    svcnt.useStoreArea.brands = result.data.byBrands
                    this.pointCouponserviceCondition = svcnt;

                    result.data.byBrands.forEach(brd =>{
                      this.selectSiteName.push({name: brd.brandName,value: brd.siteName});
                      if(brd.siteName == this.goodsSiteName){
                        this.goodsBrandName = brd.brandName;
                      }
                    })
                  }
                }
              }
          );
        },
        goodsTypeVal(val) {
            return getGoodsTypeStr(val);
        },
        getHourFormat(val) {
            const duration = moment.duration(val);
            var hours = duration.hours() + "";
            var minutes = duration.minutes() + "";
            return hours.padStart(2, '0') + ":" + minutes.padStart(2, '0');
        },
        getUnusableDayFormat(val) {
            return moment(val).format('YYYY-MM-DD');
        },
        addUsableHour() {
            var hours = this.addhours + "";
            var minutes = this.addminutes + "";
            var newtime = hours.padStart(2, '0') + ":" + minutes.padStart(2, '0') + ":00";
            var tmp_array = this.usableHour.hours;
            tmp_array.push(newtime);
            tmp_array = tmp_array.map(hitem => {
                return moment.duration(hitem).asMilliseconds()
            });
            tmp_array.sort(function (a, b) {
                return a - b
            });
            this.usableHour.hours = tmp_array.map(msitem => {
                var msdur = moment.duration(msitem, 'milliseconds');
                var mshours = msdur.hours() + "";
                var msminutes = msdur.minutes() + "";
                return mshours.padStart(2, '0') + ":" + msminutes.padStart(2, '0') + ":00";
            });
            this.addhours = 0;
            this.addminutes = 0;
        },
        addUnusableDay() {
            if (!this.unusableDate) return false;

            var newDays = moment(this.unusableDate).format('YYYYMMDD');
            var tmp_array = this.usableHour.unusableDays;

            tmp_array.push(newDays);
            tmp_array = tmp_array.map((hitem) => {
                return moment(hitem).format('YYYYMMDD');
            });
            tmp_array.sort(function (a, b) {
                return a - b;
            });
            this.usableHour.unusableDays = tmp_array.map((msitem) => {
                return (
                    moment(msitem).format('YYYY-MM-DD')
                );
            });
            this.unusableDate = "";
        },
        rmvhours(ridx) {
            this.usableHour.hours.splice(ridx, 1);
        },
        rmvUnusableDays(ridx) {
            this.usableHour.unusableDays.splice(ridx, 1);
        },
        ageGroup(val) {
            return getAgeGroupStr(val);
        },
        percent(nval, oval) {
            return getPercent(nval, oval);
        },
        isMobile(val) {
            return getMobileCoupon(val);
        },
        isBooking(val) {
            return val == "Booking";
        },
        getgoodsSales() {
            let nonCombinedOnly = false;
            if (this.goodsType == "Combined") {
                // alert('this.comTrue')
                nonCombinedOnly = true;
            } else {
                nonCombinedOnly = false
            }
            reqGet(
                b2bapi.base,
                b2bapi.channelList + "?manageType=GoodsSales" + "&nonCombinedOnly=" + nonCombinedOnly,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        this.channels = result.data.channels;
                    }
                }
            );
            reqGet(
                b2bapi.base,
                b2bapi.channelList + "?manageType=GoodsSales" + "&nonCombinedOnly=" + false,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        //기존배열
                        this.channels3 = result.data.channels;
                        // this.channels4 = this.channels3.concat(this.channels);
                        this.channels4 = this.channels3;

                        this.channels.forEach((el, idx) => {
                            //combinde채널리스트삭제 배열
                            this.defaultChan.push(el.channelCode);
                        });

                        this.channels4.forEach((el, idx) => {
                            //기존 배열
                            this.exchn.push(el.channelCode);
                            this.exchn2.push(el.channelCode);
                            // console.log(el.channelCode)

                        });
                        //원본
                        //  console.log(this.exchn2)

                        //  console.log(this.defaultChan)
                        this.exchAn = this.exchn2.filter(x => !this.defaultChan.includes(x))
                        console.log(this.exchAn)

                    }
                }
            );
        },
        searchbrandstore() {
            reqGet(
                brandapi.base,
                brandapi.storesquery + "?brandNameContaining=" + this.brandkey,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        this.brandstores = result.data.brands;
                    }
                }
            );
        },
        pointCouponsearchbrandstore() {
          reqGet(
            brandapi.base,
            brandapi.storesquery + "?brandNameContaining=" + this.pointCouponbrandkey,
            this.authToken,
            (result) => {
              if (result != null && result.code == rescode.REQ_SUCCESS) {
                this.pointCouponbrandstores = result.data.brands;
              }
            }
          );
        },
        addOption() {
            var noption = {
                optionName: "", //옵션 이름
                mainOption: false, //대표옵션인 경우 true
                displayOrder: 1, //표출 순서
                listPrice: "", //제품 가격
                salePrice: "", //판매 가격
                deliveryPrice: "", //입금가. hasOwnDeliveryPrice 가 true 인 경우 필수 입력
                inventory: 0, //판매 수량
                ageGroup: null, //성인, 어린이, 유아 구분(예약/예약권 상품일 경우 입력)[Adult, Child, Baby]
                headcount: null, //인원수  (예약/예약권 상품일 경우 입력)
                currencyCode: 'KRW', //통화 코드  [USD, KRW]
                childOptions: [],
                childOptionIds: [],
            };
            this.goodsOption.options.push(noption);
        },
        optionsort(selidx, drag) {
            if (drag.mark != 'option') {
                return;
            }
            var t_array = this.goodsOption.options;
            this.goodsOption.options = [];
            var b = t_array[drag.index];
            t_array[drag.index] = t_array[selidx];
            t_array[selidx] = b;
            this.goodsOption.options = t_array;
        },
        rmvoption(ridx) {
            if (confirm("옵션에 포함된 세부옵션이 모두 삭제됩니다. 삭제하시겠습니까?")) {
                this.bbo = [];
                var t_mainOption = this.goodsOption.options[ridx].mainOption;
                this.goodsOption.options.splice(ridx, 1);
                if (t_mainOption && this.goodsOption.options.length > 0) {
                    this.goodsOption.options[0].mainOption = t_mainOption;
                }
            }
        },
        onmainOption(idx) {
            var t_options = this.goodsOption.options.map(oitem => {
                return {
                    ...oitem,
                    mainOption: false
                }
            });
            t_options[idx].mainOption = true;
            this.goodsOption.options = t_options;
        },
        isAgreeChannel(chcode) {
            console.log(chcode)
            if (this.b2bChannel.excludedChannels.findIndex(item => item == chcode) == -1) {
                return true;
            } else {
                return false;
            }
        },
        onchangechannel(chcode, event) {
            if (event.target.checked) {
                var idx = this.b2bChannel.excludedChannels.findIndex(item => item == chcode);
                if (idx != -1) {
                    this.b2bChannel.excludedChannels.splice(idx, 1);
                }
            } else {
                this.b2bChannel.excludedChannels.push(chcode);
            }
        },

        onapprove() {
            var brands = this.serviceCondition.serviceArea.brands.map(bitem => {
                var t_storeCodes = bitem.stores.map(sitem => {
                    return sitem.storeCode
                });
                return {
                    brandCode: bitem.brandCode,
                    storeCodes: t_storeCodes
                }
            });
            var useStoreAreaBrands = this.pointCouponserviceCondition.useStoreArea.brands.map(bitem => {
              var t_storeCodes = bitem.stores.map(sitem => {
                return sitem.storeCode
              });
              return {
                brandCode: bitem.brandCode,
                storeCodes: t_storeCodes
              }
            });

            var t_serviceCondition = Object.assign({}, this.serviceCondition);
            t_serviceCondition.serviceArea = Object.assign({}, this.serviceCondition.serviceArea);
            t_serviceCondition.serviceArea.brands = brands;
            if(this.goodsType =='PointCoupon'){
              if(!this.serviceCondition.useStore){
                t_serviceCondition.useStoreArea = null
              }else{
                console.log(this.pointCouponserviceCondition)
                var t_useStoreCondition = Object.assign({}, this.pointCouponserviceCondition);
                t_useStoreCondition.useStoreArea = Object.assign({}, this.pointCouponserviceCondition.useStoreArea);
                t_useStoreCondition.useStoreArea.brands = useStoreAreaBrands;
                t_serviceCondition.useStoreArea = t_useStoreCondition.useStoreArea
              }

            }else{
              t_serviceCondition.useStoreArea = null
              t_serviceCondition.useStore = null
            }

            if(!this.serviceCondition.useStore){
              this.pointCouponserviceCondition = {
                serviceArea: {
                  brands: [],
                },
                useStoreArea:null
              }
            }
            var t_Option = this.goodsOption.options.map((oitem, oidx) => {
                var t_mainOption = oitem.mainOption;
                if (t_mainOption == null) {
                    t_mainOption = false;
                }
                return {
                    ...oitem,
                    mainOption: t_mainOption,
                    displayOrder: oidx + 1
                }
            });
            this.goodsOption.options = t_Option;
            if (this.goodsType == "Combined") {
                this.goodsOption.options.forEach(goodsOption => {
                    goodsOption.listPrice = "0";
                    goodsOption.salePrice = "0";
                    goodsOption.inventory = "0";
                })
            }

            let goodsOption2 = JSON.parse(JSON.stringify(this.goodsOption));
            goodsOption2.options.forEach(option => {
                option.childOptionIds = option.childOptions.map(childOptions => childOptions.optionId);
            });

            var t_subImages = this.introduction.subImages.map((sitem, sidx) => {
                return {
                    ...sitem,
                    displayOrder: sidx + 1
                }
            });
            this.introduction.subImages = t_subImages;
            this.promo.rewardPointPercentage = parseInt(this.promo.rewardPointPercentage);
            var t_b2bChannel = this.b2bChannel;
            var t_usableHour = this.usableHour;
            if (this.isMobile(this.goodsType)) {
                t_usableHour = null;
            }

            if (t_b2bChannel.excludedChannels != null && t_b2bChannel.excludedChannels.length == 0) {
                t_b2bChannel.excludedChannels = null;
            }
            let b2bNewArr = this.exchAn;
            b2bNewArr.forEach((bnew, i) => {
                this.b2bChannel.excludedChannels.push(bnew);
            })
            console.log(this.b2bChannel);

            if(this.selectedSiteName == null || this.selectedSiteName == ''){
              this.updateSiteName = this.goodsSiteName;
            }else{
              this.updateSiteName = this.selectedSiteName;
            }

            var reqdata = {
                registerId: this.registerId,
                goodsType: this.goodsType,
                introduction: this.introduction,
                serviceCondition: t_serviceCondition, //
                goodsOption: goodsOption2, //
                usableHour: t_usableHour,
                b2bChannel: t_b2bChannel,
                usage: this.usage,
                notice: this.notice,
                promo: this.promo,
                siteName: this.updateSiteName
            };
            reqPost(
                goodsapi.base,
                goodsapi.regtnjapprove,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.goodsId = result.data.goodsId;
                            this.stepval = 6;
                            this.closeAllowdlg();
                            // this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        ondeny() {
            this.closeDisallowdlg();
            reqPost(
                goodsapi.base,
                goodsapi.regtnjdeny,
                this.authToken, {
                    registerId: this.registerId,
                    reason: this.reason
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert("거절되었습니다.");
                            this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        onmonday() {
            this.usableHour.monday = !this.usableHour.monday;
        },
        ontuesday() {
            this.usableHour.tuesday = !this.usableHour.tuesday;
        },
        onwednesday() {
            this.usableHour.wednesday = !this.usableHour.wednesday;
        },
        onthursday() {
            this.usableHour.thursday = !this.usableHour.thursday;
        },
        onfriday() {
            this.usableHour.friday = !this.usableHour.friday;
        },
        onsaturday() {
            this.usableHour.saturday = !this.usableHour.saturday;
        },
        onsunday() {
            this.usableHour.sunday = !this.usableHour.sunday;
        },
        onCancellableDayCount() {
            this.serviceCondition.timeCondition.itemCancellableDayCount = 100;
        },
        isNotSelStore(brandCode, storeCode) {
            var bidx = this.serviceCondition.serviceArea.brands.findIndex(bitem => bitem.brandCode == brandCode);
            if (bidx == -1) {
                return true;
            }
            var bitem = this.serviceCondition.serviceArea.brands[bidx];
            if (typeof bitem.stores === 'undefined' || bitem.stores === null) {
                return true;
            }
            var sidx = bitem.stores.findIndex(sitem => sitem.storeCode == storeCode);
            if (sidx == -1) {
                return true;
            }
            return false;
        },
        onTselStore(brandCode, brandName, storeCode, storeName, storeType) {
            var tidx = this.tselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
            if (tidx == -1) {
                var nval = {
                    brandCode,
                    brandName,
                    storeCode,
                    storeName,
                    storeType
                };
                this.tselstores.push(nval);
            } else {
                this.tselstores.splice(tidx, 1);
            }
        },
        isTselStore(brandCode, storeCode) {
            var tidx = this.tselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
            if (tidx > -1) {
                return true;
            }
            return false;
        },
        onTMselStore(brandCode, brandName, storeCode, storeName, storeType) {
            var tidx = this.tmselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
            if (tidx == -1) {
                var nval = {
                    brandCode,
                    brandName,
                    storeCode,
                    storeName,
                    storeType
                };
                this.tmselstores.push(nval);
            } else {
                this.tmselstores.splice(tidx, 1);
            }
        },
        isTMselStore(brandCode, storeCode) {
            var tidx = this.tmselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
            if (tidx > -1) {
                return true;
            }
            return false;
        },
        addMyServicestore() {
            for (var idx = 0; idx < this.tselstores.length; idx++) {
                var bidx = this.serviceCondition.serviceArea.brands.findIndex(bitem => bitem.brandCode == this.tselstores[idx].brandCode);
                if (bidx == -1) {
                    var nbval = {
                        brandCode: this.tselstores[idx].brandCode,
                        brandName: this.tselstores[idx].brandName,
                        storeCodes: [],
                        stores: [{
                            storeCode: this.tselstores[idx].storeCode,
                            storeName: this.tselstores[idx].storeName,
                            storeType: this.tselstores[idx].storeType,
                        }]
                    };
                    this.serviceCondition.serviceArea.brands.push(nbval);
                } else {
                    this.serviceCondition.serviceArea.brands[bidx].stores.push({
                        storeCode: this.tselstores[idx].storeCode,
                        storeName: this.tselstores[idx].storeName,
                        storeType: this.tselstores[idx].storeType,
                    });
                }
            }
            this.tselstores = [];
        },
        rmvMyServicestore() {
            for (var idx = 0; idx < this.tmselstores.length; idx++) {
                var bidx = this.serviceCondition.serviceArea.brands.findIndex(bitem => bitem.brandCode == this.tmselstores[idx].brandCode);
                if (bidx > -1) {
                    var bitm = this.serviceCondition.serviceArea.brands[bidx];
                    var sidx = bitm.stores.findIndex(sitem => sitem.storeCode == this.tmselstores[idx].storeCode);
                    if (sidx > -1) {
                        this.serviceCondition.serviceArea.brands[bidx].stores.splice(sidx, 1);
                    }
                }
            }
            this.tmselstores = [];
        },
        getStoreType(val) {
            return getStoreTypeStr(val);
        },
        sumimagesort(drop, drag) {
            if (drag.mark != 'subimgs') {
                return;
            }
            var t_array = this.addsubimg.images;
            this.addsubimg.images = [];
            var b = t_array[drag.index];
            t_array[drag.index] = t_array[drop];
            t_array[drop] = b;
            this.addsubimg.images = t_array;
        },
        onAddsubImg(idx, url, width, height) {
            this.addsubimg.images.push({
                url,
                width,
                height
            });
        },
        onRmvSubImg(url) {
            this.addsubimg.images = url
        },
        onAddsubImages() {
            this.$refs.subImages.uploadImgArr();
            this.addsubimg.displayOrder = this.introduction.subImages.length + 1;
            var new_images = this.addsubimg.images.map((item) => {
                return item
            });
            if (new_images.length == 0) {
                alert("이미지를 추가하세요.");
                return;
            }
            this.introduction.subImages.push({
                ...this.addsubimg,
                images: new_images
            });
            this.addsubimg = {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
                /*
                url      String      서브 이미지 URL
                width    Number      서브 이미지 width
                height   Number      서브 이미지 height
                */
            };
            this.closeAddImage();
        },
        onMdfsubImages() {
            this.$refs.subImages.uploadImgArr();
            var new_images = this.addsubimg.images.map((item) => {
                return item
            });
            if (new_images.length == 0) {
                alert("이미지를 추가하세요.");
                return;
            }
            this.introduction.subImages[this.editsubimgidx] = {
                ...this.addsubimg,
                images: new_images
            };
            this.addsubimg = {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
                /*
                url      String      서브 이미지 URL
                width    Number      서브 이미지 width
                height   Number      서브 이미지 height
                */
            };
            this.editsubimgidx = -1;
            this.closeAddImage();
        },
        goBack() {
            this.$router.go(-1);
        },
        checkStep_0() {
            if (this.introduction.goodsName.trim().length == 0) {
                alert("상품명을 입력하세요.");
                return false;
            }
            if (this.introduction.mainImage.url.trim().length == 0) {
                alert("대표 이미지를 선택하세요.");
                return false;
            }

            if (this.introduction.subImages.length == 0) {
                alert("추가 이미지를 추가하세요.");
                return false;
            }

            for (var idx = 0; idx < this.introduction.subImages.length; idx++) {
                if (this.introduction.subImages[idx].images.length == 0) {
                    alert("추가 이미지를 추가하세요.");
                    return false;
                }
            }
            return true;
        },
        baseinfcheck() {
            if (!this.checkStep_0()) {
                return;
            }
            this.gotoStep_1();
        },
        onMainImage(newval, w, h) {
            this.introduction.mainImage.url = newval; //메인 이미지 url
            this.introduction.mainImage.width = w; //메인 이미지 width
            this.introduction.mainImage.height = h; //메인 이미지 height
        },
        checkStep_1() {
            if (this.goodsType !== "Combined") {
                if (!this.isMobile(this.goodsType) && this.usableHour.dayOfWeekType == 'Custom') {
                    if (!this.usableHour.monday && !this.usableHour.tuesday && !this.usableHour.wednesday && !this.usableHour.thursday && !this.usableHour.friday && !this.usableHour.saturday && !this.usableHour.sunday) {
                        alert("적용요일을 선택하세요.");
                        return false;
                    }
                }

                if (!this.isMobile(this.goodsType) && this.usableHour.hours.length == 0) {
                    alert("적용 시간을 추가하세요.");
                    return false;
                }
            }

            if (this.goodsOption.options.length == 0) {
                alert("옵션을 추가하세요.");
                return false;
            }

            if (!this.isMobile(this.goodsType) && this.goodsType !== "Combined") {
                var chval = this.goodsOption.options.filter((eitem) => eitem.ageGroup == null);
                if (chval.length > 0) {
                    alert("권종을 입력하세요.");
                    return false;
                }

                chval = this.goodsOption.options.filter((eitem) => eitem.headcount == "" || eitem.headcount == null);
                if (chval.length > 0) {
                    alert("인원수를 입력하세요.");
                    return false;
                }
            }
            return true;
        },
        checkOptionPrice() {
            if (!this.checkStep_1()) {
                return;
            }
            this.gotoStep_2();
        },
        checkStep_2() {

            if(this.goodsType!='PointCoupon'){
                if (this.getTotalStore == 0) {
                    alert("이용 매장을 등록하세요.");
                    return false;
                }
            }else if(this.serviceCondition.useStore ===true && this.getPointCouponTotalStore == 0){
                alert("사용 매장을 등록하세요.");
                return false;
            }

            if (this.serviceCondition.timeCondition.hasSalesPeriod) {
                if (this.serviceCondition.timeCondition.saleStartDate == "" || this.serviceCondition.timeCondition.saleStartDate == null || this.serviceCondition.timeCondition.saleEndDate == "" || this.serviceCondition.timeCondition.saleEndDate == null) {
                    alert("판매기간을 선택하세요.");
                    return false;
                }
            }

            if (!this.isBooking(this.goodsType)) {
                if (this.serviceCondition.timeCondition.availableDateSetting == 'SetFromToDate') {
                    if (this.serviceCondition.timeCondition.availableFromDate == "" || this.serviceCondition.timeCondition.availableFromDate == null || this.serviceCondition.timeCondition.availableToDate == "" || this.serviceCondition.timeCondition.availableToDate == null) {
                        alert("유효기간을 선택하세요.");
                        return false;
                    }
                }
            }
            return true;
        },
        usecondition_Next() {
            if (!this.checkStep_2()) {
                return;
            }
            if (this.goodsType != 'Booking') {
                this.gotoStep_3();
            } else {
                this.gotoStep_4();
            }
        },
        checkStep_4() {
            if (this.usage.useInfo == "") {
                alert("사용정보를 입력하세요.");
                return false;
            }
            if (this.usage.baseInfo == "") {
                alert("기본정보를 입력하세요.");
                return false;
            }
            if (this.usage.notes == "") {
                alert("유의사항을 입력하세요.");
                return false;
            }
            if (this.usage.returnsAndRefunds == "") {
                alert("취소/환불 규정을 입력하세요.");
                return false;
            }
            return true;
        },
        checkSaleInf() {
            if (!this.checkStep_4()) {
                return;
            }
            this.gotoStep_5();
        },
        inputableDayCount() {
            if (this.serviceCondition.timeCondition.availableDayCount < 1) {
                this.serviceCondition.timeCondition.availableDayCount = 1;
            }
        },
    },
};
</script>

<style scoped>
/* 2022-01-03 추가 */
.addimgInputGroup.wid100 {
    width: 100%;
}

.flexDiv {
    display: flex;
    justify-content: space-between;
}

.modal-body.addmodals {
    display: flex;
    justify-content: space-between;
}

.modal-body.addmodals .imgBoxDivGroup {
    width: 50%;
}

.addimgInputGroup {
    width: 45%;
    vertical-align: top;
}

.modal-body.addmodals .imgBoxDivGroup .imgDragbox {
    width: 100%;
}

.mr-05 {
    margin-right: 5px;
}

/* 2021-12-30추가 */

.left-store-btn {
    margin-left: 10px;
}

.displayIn>div {
    display: inline-block;
    vertical-align: top;
}

.thumbnail {
    width: 160px;
    height: 120px;
    justify-content: center;
    margin: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px;
    background-color: #E8E8E8;
}

.modal-dialog.drag {
    width: 70%;
    margin: 30px auto;
}

ul.tab li {
    list-style: none;
    display: inline-block;
    border-bottom: 1px solid #337ab7;
    margin-left: 15px;
    color: #337ab7;
    cursor: pointer;
}

ul.titleArr {
    margin: 0;
    text-align: left;
    padding: 0;
    margin-left: 14px;
}

ul.titleArr li {
    list-style: none;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

ul.titleArr li:first-child {
    margin: 0;
}

ul.tab li:hover {
    font-weight: 600;
}

ul.tab {
    padding: 0;
    text-align: left;
    margin-left: 13px;
    margin-bottom: 20px;
    margin-top: 5px;
}

ul.tab li:first-child {
    margin-left: 0;
}

.imgdragselect {
    width: auto;
    margin-left: 14px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}

.imgDragbox-div {
    text-align: left;
}

.mt-2 {
    margin-top: 20px;
}

.imgBoxDivGroup {
    display: flex;
    width: 100%;
    justify-content: center;
}

.imgboxChild:nth-child(1n) {
    margin-right: 10px;
}

.imgBoxDivGroup>div .imgboxChild {
    height: 200px;
    width: 200px;
    margin-bottom: 10px;
    display: inline-block;
    object-fit: contain;
    background: #f5f5f5;
    padding: 12px;
}

.imgDragbox {
    text-align: center;
    padding: 20px;
    margin-left: 20px;
    width: 50%;
}

/* 2021-12-30추가 끝 */

.radio>label {
    margin-right: 10px;
}

.checkbox>label {
    margin-right: 10px;
}

.step {
    flex: 1;
    display: block;
    text-align: center;
    position: relative;
}

.passline {
    position: relative;
}

.point_pass {
    position: absolute;
    top: 0px;
    left: calc(50% - 10px);
    border-radius: 100px;
    width: 20px;
    height: 20px;
    border: 2px solid #00a7d0;
    margin: auto;
    background-color: #00a7d0;
}

.point_fail {
    position: absolute;
    top: 0px;
    left: calc(50% - 10px);
    border-radius: 100px;
    width: 20px;
    height: 20px;
    border: 2px solid #00a7d0;
    margin: auto;
    background-color: #fff;
}

.leftline {
    width: 50%;
    height: 2px;
    position: absolute;
    left: 0px;
    top: 10px;
}

.rightline {
    width: 50%;
    height: 2px;
    position: absolute;
    right: 0px;
    top: 10px;
}

.bgpass {
    background-color: #00a7d0;
}

.bgfail {
    background-color: #d2d6de;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.btn-app h4 {
    font-weight: bold !important;
}

.btn-app {
    height: auto !important;
    padding: 15px;
    width: 170px;
}

.btn-selweek {
    background-color: #5B9BD5;
    color: #FFFFFF;
}

.imgdragselect {
    width: auto;
    margin-left: 14px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}

.dropbox>.imgboxChild,
.dropboxMain>.imgboxChild {
    display: none !important;
}

@media (min-width: 768px) {
    .mtitle {
        margin-bottom: 50px;
    }

    .weeksel {
        margin-left: 30px;
    }

    .pgheader {
        margin-bottom: 10px;
    }

    .linebg {
        width: 100%;
        height: 20px;
    }

    .addlist {
        display: inline-flex;
    }
}

@media (max-width: 768px) {
    .weeksel {
        margin-top: 10px;
    }

    .pgheader {
        margin-bottom: 30px;
    }

    .linebg {
        width: 100%;
        height: 20px;
    }

    .ptlbl {
        position: absolute;
        top: 20px;
        left: -50%;
        width: 200%;
    }

    .pttop {
        position: absolute;
        bottom: 20px;
        left: -50%;
        width: 200%;
    }

    .addlist>.thumbnail {
        margin-bottom: 10px;
    }
}
</style>
