<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <select class="form-control" style="width:auto;margin-right:10px" @change="refreshPage">
            <option value="ScheduledDate">환불예정일</option>
            <option value="CompletedDate">처리일</option>
          </select>
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="refreshPage">
            <option value="NoKeyword"></option>
            <option value="OrderNo">주문번호</option>
            <option value="B2bOrderNo">B2B주문번호</option>
            <option value="BuyerPhoneNumber">구매자 전화번호</option>
            <option value="BuyerPhoneNumber4">구매자 전화번호 끝 4자리</option>
            <option value="Username">사용자 ID</option>
            <option value="BuyerName">구매자 이름</option>
            <option value="AccountNumber">계좌번호</option>
            <option value="AccountHolder">예금주</option>
            <option value="B2bOrderNo">B2B주문번호</option>
            <option value="Channel">주문경로</option>

          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default pull-right" @click="alldoProcess">일괄 처리완료</button>
      <select class="form-control pull-right" style="width: 70px;margin-right:10px;" v-model="pageSize"
              @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
        <option>100</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="excelButtonDisabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">주문번호</th>
            <th class="text-center">은행명</th>
            <th class="text-center">계좌번호</th>
            <th class="text-center">구매자</th>
            <th class="text-center">예금주</th>
            <th class="text-center">입금액</th>
            <th class="text-center">내용</th>
            <th class="text-center">환불예정일</th>
            <th class="text-center">처리일</th>
            <th>
              <input type="checkbox" class="allch" @change="allckech($event)"/>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(trans, index) in content" v-bind:key="trans.transferCsNo">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center linkcmp" @click="goDetailViewPage(trans.orderNo)">{{ trans.orderNo }}</td>
            <td class="text-center">{{ trans.bankName }}</td>
            <td class="text-center" v-if="trans.transferCsStatus == 'Requested'">{{ trans.accountNumber }}</td>
            <td class="text-center" v-if="trans.transferCsStatus == 'Completed'">{{ showFront4(trans.accountNumber) }}
            </td>
            <td class="text-center">{{ trans.buyerName }}</td>
            <td class="text-center">{{ trans.accountHolder }}</td>
            <td class="text-center">{{ trans.transferAmount }}</td>
            <td v-if="trans.requestMessage != null && trans.requestMessage != ''" class="text-center linkcmp"
                @click="viewDetailMessage(trans.requestMessage)">{{ substring50(trans.requestMessage) }}
            </td>
            <td class="text-center" v-else>{{ substring50(trans.requestMessage) }}</td>
            <td class="text-center">{{ viewDate(trans.scheduledDate) }}</td>
            <td class="text-center" v-if="trans.transferCsStatus == 'Completed'">{{ viewDate(trans.completedDate) }}
            </td>
            <td class="text-center" v-if="trans.transferCsStatus == 'Requested'">
              <button type="button" class="btn btn-default btn-xs" @click="doJustComplete(trans.transferCsNo)">처리완료
              </button>
            </td>
            <td>
              <input v-if="trans.transferCsStatus == 'Completed'" type="checkbox" disabled/>
              <input v-if="trans.transferCsStatus != 'Completed'" type="checkbox" class="chitem"
                     :value="trans.transferCsNo"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" v-if="memodlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">처리완료</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label class="col-xs-3 control-label">메모</label>
              <div class="col-xs-9" style="display:inline-flex">
                <textarea class="form-control" rows="5" v-model="completememo"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeMemoDlg">닫기</button>
              <button type="button" class="btn btn-default" @click="doTranscomplete" :disabled="buttonDissabled">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="viewdlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">내용</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <textarea class="form-control" rows="5" v-model="modalmsg" readonly></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeViewDlg">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="reqmsg" height="auto" :adaptive="true" :styles="{'padding':'10px'}">
      <div></div>
      <div style="{padding:10px}">
        {{ modalmsg }}
      </div>
    </modal>
  </section>
</template>

<script>
import {rescode, menuval} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {reqPost} from "../../lib/request";
import {orderapi} from "../../lib/api";
import {DateFormat} from "../../lib/utility";
import $ from 'jquery';
import XLSX from "xlsx";
import moment from 'moment';

export default {
  components: {
    Pagination,
    DetePicker,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.ORDR_keywordType) {
        this.keywordType = localStorage.ORDR_keywordType;
      }
      if(localStorage.ORDR_keyword) {
        this.keyword = localStorage.ORDR_keyword;
      }
      if(localStorage.ORDR_Number) {
        this.pageNumber = parseInt(localStorage.ORDR_Number);
      }
      if(localStorage.ORDR_Size) {
        this.pageSize = parseInt(localStorage.ORDR_Size);
      }
      if(localStorage.ORDR_dateFrom) {
        this.dateFrom = localStorage.ORDR_dateFrom;
      }
      if(localStorage.ORDR_dateTo) {
        this.dateTo = localStorage.ORDR_dateTo;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_ORD, path2: menuval.LV1_HOME, title: "환불신청조회"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.ORDR_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.ORDR_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.ORDR_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.ORDR_Size = newsize;
    },
    dateFrom(newfrom) {
      localStorage.ORDR_dateFrom = newfrom;
    },
    dateTo(newto) {
      localStorage.ORDR_dateTo = newto;
    }
  },
  data() {
    return {
      memodlg: false,
      viewdlg: false,
      shownum: 10, //pagination에 보여지는 번호개수
      dateType: "ScheduledDate",
      dateFrom: moment().format("YYYY-MM-DD"),//moment().format("YYYY-01-01"),
      dateTo: moment().format("YYYY-MM-DD"),
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * transferCsNo         Number    CS 접수 번호
       * transferCsStatus     String    [Requested, Completed]  CS 진행 상태
       * orderNo              String    주문 번호
       * b2bOrderNo           String    B2B 주문 번호
       * bankCode             String    은행코드
       * bankName             String    은행명
       * accountHolder        String    예금주
       * accountNumber        String    계좌번호
       * transferAmount       String    이체금액
       * currencyCode         String    [USD, KRW] 통화코드
       * requestMessage       String    이체 요청 메시지
       * requestedDate        String    UTC yyyy-MM-ddTHH:mm:ss 취소 요청 일자
       * scheduledDate        String    yyyy-MM-dd 환불 예정일
       * completedDate        String    UTC yyyy-MM-ddTHH:mm:ss CS 처리 완료 일자
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      exceldata: [],
      transferCsNos: [],
      completememo: "",
      modalmsg: "",
      excelButtonDisabled : false,
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.ORDR_keywordType = this.keywordType;
      localStorage.ORDR_keyword = this.keyword;
      localStorage.ORDR_Number = this.pageNumber;
      localStorage.ORDR_Size = this.pageSize;
      localStorage.ORDR_dateFrom = this.dateFrom;
      localStorage.ORDR_dateTo = this.dateTo;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    closeMemoDlg() {
      this.memodlg = false;
    },
    closeViewDlg() {
      this.viewdlg = false;
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },

    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }


      reqPost(
        orderapi.base,
        orderapi.transferCspage,
        this.authToken,
        {
          dateType: this.dateType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          pageNumber: pageNumber,
          pageSize: this.pageSize
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goDetailViewPage(orderNo) {
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
    },
    dataconvert(order) {
      return {
        A: order.orderNo,
        B: order.bankName,// this.goodsTypeVal(order.goodsType),
        C: order.accountNumber,
        D: order.buyerName,
        E: order.accountHolder,
        J: order.paymentAmountInput,
        F: order.transferAmount,
        G: order.requestMessage,
        H: this.viewDate(order.scheduledDate),
        I: this.viewDate(order.completedDate),
      };
    },
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "주문번호",
            B: "은행명",
            C: "계좌번호",
            D: "구매자",
            E: "예금주",
            J: "B2B 결제금액",
            F: "입금액",
            G: "내용",
            H: "환불예정일",
            I: "처리일",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "환불신청조회");
      // export Excel file
      XLSX.writeFile(wb, "환불신청조회.xlsx");
      this.excelButtonDisabled = false;
    },
    getExcelData() {
      this.excelButtonDisabled = true
      reqPost(
        orderapi.base,
        orderapi.transferCslist,
        this.authToken,
        {
          dateType: this.dateType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            } else {
              this.exceldata = [];
              this.excelButtonDisabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    alldoProcess() {
      this.transferCsNos = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();
      this.memodlg = true;
    },
    doJustComplete(transNo) {
      this.transferCsNos = [];
      this.transferCsNos.push(transNo);
      // this.memodlg = true;
      this.completememo = "";
      this.doTranscomplete();
    },
    doTranscomplete() {
      this.buttonDissabled = true;
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.transferCscomplete,
        this.authToken,
        {
          transferCsNos: this.transferCsNos,
          memo: this.completememo,
        },
        (result) => {
          if(result != null) {
            this.$store.dispatch('setDisabledLayout');
            if(result.code == rescode.REQ_SUCCESS) {
              this.transferCsNos = [];
              this.completememo = "";
              this.buttonDissabled = false;
              this.closeMemoDlg();
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    showFront4(str) {
      var t_str = str.substring(0, 4);
      return t_str.padEnd(str.length, '*');
    },
    substring50(str) {
      if(str.length > 20) {
        return str.substring(0, 20) + "...";
      } else {
        return str;
      }
    },
    viewDetailMessage(str) {
      this.modalmsg = str;
      this.viewdlg = true;
      // this.$modal.show('reqmsg')
    }
  },
};
</script>
<style scoped>
.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
