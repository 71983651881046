<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">예약 기본 설정</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">일반예약 승인</label>
        <div class="col-sm-10">
          <div class="input-group" style="display:inline-flex">
            <div class="radio">
              <label>
                <input type="radio" :value="false" v-model="autoBooking" :disabled="!!kakaoId"/>
                대기
              </label>
              <label>
                <input type="radio" :value="true" v-model="autoBooking"/>
                실시간
              </label>
            </div>
          </div>
          <label v-if="autoBooking">(최대</label>
          <div v-if="autoBooking " class="input-group" style="display:inline-flex">
            <input type="number" class="form-control" style="width:80px" v-model="autoBookingMaxHeadcount"/>
          </div>
          <label v-if="autoBooking">명) </label>
          <label v-if="autoBooking && kakaoId" style="margin-left:8px;"> ※ 최대 인원수는 테이블엔조이 예약에서만 적용됩니다.</label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">결제예약 승인</label>
        <div class="col-sm-10">
          <div class="input-group" style="display:inline-flex">
            <div class="radio">
              <label>
                <input type="radio" :value="false" v-model="paidAutoBooking" :disabled="!!kakaoId"/>
                대기
              </label>
              <label>
                <input type="radio" :value="true" v-model="paidAutoBooking"/>
                실시간
              </label>

            </div>
          </div>
          <label v-if="paidAutoBooking">(최대</label>
          <div v-if="paidAutoBooking" class="input-group" style="display:inline-flex">
            <input type="number" class="form-control" style="width:80px" v-model="paidAutoBookingMaxHeadcount"/>
          </div>
          <label v-if="paidAutoBooking">명)</label>
          <label v-if="paidAutoBooking &&kakaoId" style="margin-left:8px;"> ※ 최대 인원수는 테이블엔조이 예약에서만 적용됩니다.</label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">예약가능시간</label>
        <div class="col-sm-10">
          <label>예약시간</label>
          <div class="input-group" style="display:inline-flex">
            <select class="form-control" style="width: auto;" v-model="canBookTillBefore" @change="chkChangeTimeSlot">
              <option value=15>15분</option>
              <option value=30>30분</option>
              <option value=45>45분</option>
              <option value=101>1시간</option>
              <option value=102>2시간</option>
              <option value=103>3시간</option>
              <option value=104>4시간</option>
              <option value=105>5시간</option>
              <option value=106>6시간</option>
              <option value=801>1일</option>
              <option value=802>2일</option>
              <option value=803>3일</option>
              <option value=807>7일</option>
              <option value=810>10일</option>
            </select>
          </div>
          <label>이전까지 예약가능</label>
        </div>
      </div>
      <!-- <div class="form-group">
          <label class="col-sm-2 control-label">예약취소기한</label>
          <div class="col-sm-10">
              <label>예약시간</label>
              <div class="input-group" style="display:inline-flex">
                  <select class="form-control" style="width: auto;" v-model="cancelTillBefore">
                      <option value=11>1시간</option>
                      <option value=12>2시간</option>
                      <option value=13>3시간</option>
                      <option value=14>4시간</option>
                      <option value=15>5시간</option>
                      <option value=16>6시간</option>
                      <option value=21>1일</option>
                      <option value=22>2일</option>
                      <option value=23>3일</option>
                      <option value=24>4일</option>
                      <option value=25>5일</option>
                      <option value=26>6일</option>
                      <option value=27>7일</option>
                  </select>
              </div>
              <label>이전까지 취소가능</label>
          </div>
      </div> -->
      <div class="form-group">
        <label class="col-sm-2 control-label">예약가능기한</label>
        <div class="col-sm-10">
          <label>당일로부터</label>
          <div class="input-group" style="display:inline-flex">
            <select class="form-control" style="width: auto;" v-model="canBookTillDaysAfter" @change="chkChangeTimeSlot">
              <option value="15">15일</option>
              <option value="30">30일</option>
              <option value="60">60일</option>
              <option value="90">90일</option>
              <option value="120">120일</option>
              <option value="150">150일</option>
            </select>
          </div>
          <label>일 까지 예약 가능</label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">제공옵션</label>
        <div class="col-sm-10">
          <ListBadgeMake v-model="offeredOptions"/>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">알림수신</label>
        <div class="col-sm-10">
          <div class="table-responsive p-0" style="margin-top:10px">
            <table class="table table-hover text-nowrap">
              <tbody>
              <tr>
                <td>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="willReceiveSms"/>SMS 문자 알림
                    </label>
                  </div>
                </td>
                <td>
                  <ListBadgeMake v-if="willReceiveSms" v-model="phoneNumbers"/>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="willReceiveEmail"/>이메일 알림
                    </label>
                  </div>
                </td>
                <td>
                  <ListBadgeMake v-if="willReceiveEmail" v-model="emailAddresses"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">야간 알림 수신</label>
        <div class="col-sm-10">
          <div class="input-group" style="display:inline-flex">
            <div class="radio">
              <label>
                <input type="radio" :value="true" v-model="willReceiveSmsAtNight"/>
                예
              </label>
              <label>
                <input type="radio" :value="false" v-model="willReceiveSmsAtNight"/>
                아니오
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">익일 자동 방문완료</label>
        <div class="col-sm-10">
          <div class="input-group" style="display:inline-flex">
            <div class="radio">
              <label>
                <input type="radio" :value="true" v-model="dayAutoBooked"/>
                사용
              </label>
              <label>
                <input type="radio" :value="false" :disabled="!!kakaoId" v-model="dayAutoBooked"/>
                미사용
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">

        <!-- 2022-01-13 추가 -->
        <label class="col-sm-2 control-label">환불 기준 설정</label>
        <div class="col-sm-10">
          <div class="input-group" style="display:inline-flex">
            <div class="radio">
              <label>
                <input type="radio" :value="true" v-model="setRefund" @change="setRefundChange(setRefund)"/>
                사용
              </label>
              <label>
                <input type="radio" :value="false" v-model="setRefund" @change="setRefundChange(setRefund)"/>
                미사용
              </label>
            </div>
          </div>
          <p v-if="!setRefund">미설정 시 100% 환불됩니다.</p>
          <p v-else>환불 불가 시 0% 입력</p>
          <div v-if="setRefund" class="nnos first">
            <template v-if="!chkRefundArrayList">
              <div v-for="(item,idx) in refundArrayList" :key="item.refundType">
                <div class="d-flex" style="margin-bottom: 8px">
                  <div class="col-sm-1 radio " style="padding-left: 0px">
                    <span >{{item.refundType ==0? '당일 방문' : `${item.refundType} 일전`}}</span>
                  </div>
                  <div class="col-sm-3 radio" :style=" idx != refundArrayList.length-1 ?'padding: 0 !important;':''">
                    <label v-if="idx != refundArrayList.length-1">
                      <input  type="text" maxlength="3" class="form-control "
                           style="max-width:110px"
                           v-model.number="item.percent"
                          oninput="this.value = this.value.replace(/[^0-9.]/g,'');" >% 환불
                    </label>
                    <span class="text-center" v-else>{{item.percent}}% 환불</span>
                  </div>
                </div>
                <label class="waring" style="display: block" v-if="idx != refundArrayList.length-1">
                  <span v-if="item.percent===''">  값을 넣어주세요.</span>
                  <span v-else-if=" idx!=0 && refundArrayList[idx-1].percent > refundArrayList[idx].percent ">  상위 항목에 입력된 환불 비율(%) 보다 작을 수 없습니다.</span>
                </label>
              </div>
              <div class="col-sm-1 d-flex mt-1" style="padding-left: 0px">
                <button class="btn btn-default margin-r-5 " @click="newAddRefund">
                  <i class="fa fa-plus"></i>
                  환불 기준 추가
                </button>
                <button class="btn btn-default" @click="setNewRefundListDel">삭제
                </button>
              </div>
            </template>
            <template v-else>
              <label class="waring" >환불 기준 설정 오류 문의요망</label>
            </template>
          </div>
        </div>
        <!-- 2022-01-13 추가 end -->

      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="kakaoConfirm">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
  import {brandapi} from "@/lib/api";
  import {rescode} from "@/lib/consts";
  import ListBadgeMake from "@/components/ListBadgeMake";

  export default {
    components:{
      ListBadgeMake,
    },
    props:{
      kakaoId:Number
    },
    data(){
      return{
        autoBooking: false, //일반예약 자동 예약 승인 여부
        autoBookingMaxHeadcount: 0, //일반예약 자동 예약 승인 개수
        paidAutoBooking: false, //결제예약 자동 예약 승인 여부
        paidAutoBookingMaxHeadcount: 0, //결제예약 자동 예약 승인 개수
        canBookTillBefore: 101, //분단위 그대로 시간단위 +100 일단위 +800
        // cancelTillBefore: "11", //1h, 2h, 3h, 4h, 5h, 6h, 1d, 2d, 3d, 4d, 5d, 6d, 7d
        canBookTillDaysAfter: 15, //당일로부터 며칠까지 예약가능
        willReceiveSms: false, //예약 알림 SMS 문자 수신 여부
        willReceiveSmsAtNight: false, //SMS 문자를 야간에도 수신할 지 여부
        phoneNumbers: [], //SMS 문자를 수신할 전화번호 리스트
        willReceiveEmail: false, //예약 알림 이메일 수신 여부
        emailAddresses: [], //예약 알림을 받을 이메일 주소 리스트
        noShowCouponDisposal: "SetVisited", //노쇼 쿠폰 처리.   SetVisited: 방문처리,   SetCancelled: 취소처리
        offeredOptions: [], //제공 옵션 리스트
        setRefundList: [],
        setRefund: false,
        srNoshow: "",
        storeCode:'',
        kakaoReserveToggle:false,
        dayAutoBooked:false,
        tmpRefundArr:[],
        zeroVal:null,
        refundArrayList:[

        ],
        chkRefundArrayList:false,
        alertChk : false,


      }
    },
    computed:{
      authToken() {
        return this.$store.getters.authToken;
      },
    },
    mounted(){
      this.getbookingSettings();
    },
    created() {
      this.storeCode = this.$route.query.stcode;
    },
    methods:{
      getbookingSettings() {
        reqGet(
          brandapi.base,
          brandapi.tnjbookingSettings + "?storeCode=" + this.storeCode,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              this.autoBooking = result.data.bookingSettings.autoBooking; //Boolean 일반예약 자동 예약 승인 여부
              this.autoBookingMaxHeadcount = result.data.bookingSettings.autoBookingMaxHeadcount; //Number  일반예약 자동 예약 승인 개수
              this.paidAutoBooking = result.data.bookingSettings.paidAutoBooking; //Boolean  결제예약 자동 예약 승인 여부
              this.paidAutoBookingMaxHeadcount = result.data.bookingSettings.paidAutoBookingMaxHeadcount; //Number  결제예약 자동 예약 승인 개수
              this.dayAutoBooked = result.data.bookingSettings.dayAutoBooked
              if (result.data.bookingSettings.canBookTillDaysBefore != null) {

                this.canBookTillBefore = result.data.bookingSettings.canBookTillDaysBefore + 800;

              } else if (result.data.bookingSettings.canBookTillMinutesBefore != null) {

                this.canBookTillBefore = result.data.bookingSettings.canBookTillMinutesBefore;

              } else if (result.data.bookingSettings.canBookTillHoursBefore !== null) {

                this.canBookTillBefore = result.data.bookingSettings.canBookTillHoursBefore + 100;

              } else {
                this.canBookTillBefore = null;
              }
              if (result.data.bookingSettings.canCancelTillDaysBefore != null) {
                // this.cancelTillBefore = result.data.bookingSettings.canCancelTillDaysBefore + 20;
              } else {
                // this.cancelTillBefore = result.data.bookingSettings.canCancelTillHoursBefore + 10;
              }
              this.canBookTillDaysAfter = result.data.bookingSettings.canBookTillDaysAfter; //Number  당일로부터 며칠까지 예약가능
              this.willReceiveSms = result.data.bookingSettings.willReceiveSms === null ? false : result.data.bookingSettings.willReceiveSms; //Boolean 예약 알림 SMS 문자 수신 여부
              this.willReceiveSmsAtNight = result.data.bookingSettings.willReceiveSmsAtNight === null ? false : result.data.bookingSettings.willReceiveSmsAtNight; //Boolean SMS 문자를 야간에도 수신할 지 여부
              this.phoneNumbers = result.data.bookingSettings.phoneNumbers; //[] Array  SMS 문자를 수신할 전화번호 리스트
              this.willReceiveEmail = result.data.bookingSettings.willReceiveEmail === null ? false : result.data.bookingSettings.willReceiveEmail; //Boolean  예약 알림 이메일 수신 여부
              this.emailAddresses = result.data.bookingSettings.emailAddresses; //[] Array  예약 알림을 받을 이메일 주소 리스트
              this.noShowCouponDisposal = result.data.bookingSettings.noShowCouponDisposal; //String  [SetVisited, SetCancelled]  노쇼 쿠폰 처리.
              //SetVisited: 방문처리,   SetCancelled: 취소처리
              this.offeredOptions = result.data.bookingSettings.offeredOptions; //[] Array  제공 옵션 리스트

              // this.setRefundList = result.data.bookingSettings.refundSettings;
              // console.log(result.data.bookingSettings.refundSettings)
              let refset = result.data.bookingSettings.refundSettings;
              console.log(refset)
              this.srNoshow = refset[0].percent;
              if (refset.length !== 0) {
                this.setRefund = true;
              } else {
                this.setRefund = false;
              }
              this.setRefundList = [];

              // 에러 수기 처리용
              if(this.setRefund){
                this.refundArrayList = result.data.bookingSettings.refundSettings
                this.refundArrayList.forEach((item,idx)=>{
                  if(item.refundType != idx){
                    this.chkRefundArrayList = true
                  }
                  if(idx == this.refundArrayList.length-1 && item.percent != 100){
                    this.refundArrayList.push({refundType : idx+1,
                      percent:100
                    })
                  }
                })
              }

            }
          }
        );
      },

      kakaoConfirm(){
        if(!this.chkRefundArrayList){
          this.savebookingSettings();
        }else{
          alert("환불기준 설정 오류입니다. 관리자에게 문의주세요.")
        }

        // if(this.kakaoId){
        //   if(confirm("변경사항을 카카오톡 예약하기에 반영하시겠습니까?")){
        //
        //   }
        // }else{
        //   this.savebookingSettings();
        // }
      },
      savebookingSettings() {

        let warning = false
        if(!this.chkRefundArrayList){
          for(let i = 0 ; i<this.refundArrayList.length-1;i++){
            if(i>0){
              if(this.refundArrayList[i-1].percent > this.refundArrayList[i].percent){
                warning = true
                break;
              }
            }
          }
          if(warning){
            alert("환불 기준 설정을 확인해 주세요.")
            return
          }
        }else{
          alert("환불기준 설정 오류입니다. 관리자에게 문의주세요.")
          return
        }

        var canBookTillDaysBefore = null;
        var canBookTillHoursBefore = null;
        var canBookTillMinutesBefore = null;
        if (this.canBookTillBefore < 100)
        {
          canBookTillMinutesBefore = this.canBookTillBefore;
        } else if (this.canBookTillBefore >100 && this.canBookTillBefore <200) {

          canBookTillHoursBefore = this.canBookTillBefore - 100;
        } else {
          canBookTillDaysBefore = this.canBookTillBefore - 800;
        }

        var canCancelTillDaysBefore = null;
        var canCancelTillHoursBefore = null;
        // if (this.cancelTillBefore < 20) //11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27
        // {
        //     canCancelTillHoursBefore = this.cancelTillBefore - 10;
        // } else {
        //     canCancelTillDaysBefore = this.cancelTillBefore - 20;
        // }

        var bookingSettings = {
          autoBooking: this.autoBooking,
          autoBookingMaxHeadcount: this.autoBookingMaxHeadcount,
          paidAutoBooking: this.paidAutoBooking,
          paidAutoBookingMaxHeadcount: this.paidAutoBookingMaxHeadcount,
          canBookTillDaysBefore: canBookTillDaysBefore,
          canBookTillHoursBefore: canBookTillHoursBefore,
          canCancelTillDaysBefore: canCancelTillDaysBefore,
          canCancelTillHoursBefore: canCancelTillHoursBefore,
          canBookTillMinutesBefore: canBookTillMinutesBefore,
          canBookTillDaysAfter: this.canBookTillDaysAfter,
          willReceiveSms: this.willReceiveSms,
          willReceiveSmsAtNight: this.willReceiveSmsAtNight,
          phoneNumbers: this.phoneNumbers,
          willReceiveEmail: this.willReceiveEmail,
          emailAddresses: this.emailAddresses,
          noShowCouponDisposal: this.noShowCouponDisposal,
          offeredOptions: this.offeredOptions,
          // refundSettings: this.refundSettings, // 얘가 바껴야함
          refundSettings: this.refundArrayList, // 얘가 바껴야함
          dayAutoBooked: this.dayAutoBooked
        };
        reqPost(
          brandapi.base,
          brandapi.tnjbookingsupdate,
          this.authToken, {
            storeCode: this.storeCode,
            bookingSettings: bookingSettings,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {

                alert("저장되었습니다.");
                this.getbookingSettings()
              } else {
                alert(result.message);
              }
            }
          }
        );
      },
      newAddRefund(){
        let tmp = this.refundArrayList.length
        if(tmp==9){
          alert("최대입니다.")
          return
        }else{
          this.refundArrayList.splice(tmp-1,0,{refundType : tmp-1, percent:0})
          this.refundArrayList.forEach((item,idx)=>{
            item.refundType = idx
            if(idx == this.refundArrayList.length-1){
              item.percent = 100
            }
          })
        }
      },
      setNewRefundListDel(){
        let tmp = this.refundArrayList.length
        if(tmp==2){
          alert("최소입니다.")
          return
        }else{
          this.refundArrayList.splice(tmp-2,1)
          this.refundArrayList.forEach((item,idx)=>{
            item.refundType = idx
            if(idx == this.refundArrayList.length-1){
              item.percent = 100
            }
          })

        }
      },
      setRefundChange(val){
        if(!val){
          this.refundArrayList=[

          ]
        }else{
          this.refundArrayList=[
            {refundType : 0,
              percent:0
            },
            {refundType : 1,
              percent:100
            }
          ]
          this.chkRefundArrayList = false
        }
      },
      chkChangeTimeSlot(){
        this.alertChk = true
      }




    }
  }
</script>

<style scoped>

</style>
