<template>
<div class="box box-solid">
    <div class="box-body form-horizontal">
        <div class="">
            <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap text-center">
                    <thead>
                        <tr>
                            <th>프로모션 코드</th>
                            <th>제목</th>
                            <th>전시 기간</th>
                            <th>전시 상태</th>
                            <th>노출 순서</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,idx) in promotionList" :key="idx">
                            <td>{{ item.promotionId }}</td>
                            <td class="linkcmp text-center" @click="edtPromotion(item.promotionId)"> {{ item.title }} </td>
                            <td>{{ item.startDate +'~' +item.endDate }} </td>
                            <td class="linkcmp text-center" style="display: inline-flex;width:100%;justify-content: center;" @click="onChangeStatus(item.status, idx,item.promotionId)">
                                <ToggleBtn :value="item.status" />
                            </td>
                            <td>

                                <drop @drop="handleDrop(item, $event)">
                                    <drag class="btn btn-default btn-social-icon" :transfer-data="{ item: item, index: idx}">
                                        <i class="fa fa-fw fa-arrows-v"></i>
                                    </drag>
                                </drop>
                            </td>
                            <td>
                                <button type="button" class="btn btn-social-icon" @click="delpromotion(item.promotionId)">
                                    <i class="fa fa-fw fa-trash-o"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="jetpo">
                <span>* 전시중인 프로모션 중 첫번째 항목이 대표 프로모션으로 노출됩니다.</span>
                <button type="button" class="btn btn-default " @click="promotionModalOpen">프로모션 만들기</button>
            </div>
        </div>

        <!-- 모달 -->
        <div class="modal" :style="promotionModal && {'display': 'block'}">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0"> {{ promotionModalName }} </div>
                    <div class="modal-body form-horizontal">
                        <!-- 시간 선택 박스 -->
                        <div class="row">
                            <div class="col-sm-2">제목 *</div>
                            <div class="col-sm-10">
                                <input type="text" v-model="title" class="form-control">
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-sm-2">내용 *</div>
                            <div class="col-sm-10">
                                <textarea v-model="description" class="form-control txtaH"></textarea>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-sm-2">전시 기간 *</div>
                            <div class="col-sm-10">
                                <DatePicker v-model="temp.startDate" />
                                <span class="" style="border:none;display:inline-flex">-</span>
                                <DatePicker v-model="temp.endDate" />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-sm-2">이미지</div>
                            <div class="col-sm-10">
                                <SingleThumbnail v-model="mainImageurl" :imgtype="imgtype.STORE" :change="onMainImage" :imgFile="{}" />
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer text-center">
                        <button type="button" class="btn btn-default" @click="promotionModalClose">취소</button>
                        <button type="button" class="btn btn-default" @click="promotionModalSave(false)" v-if="!proedt">저장</button>
                        <button type="button" class="btn btn-default" @click="promotionModalSave(true)" v-if="proedt">수정</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 모달 end -->

    </div>
</div>
</template>

<script>
import SingleThumbnail from "../../../../components/SingleThumbnail";
import DatePicker from "../../../../components/DatePicker";
import ToggleBtn from "../../../../components/ToggleBtn";
import {
    Drag,
    Drop
} from "vue-drag-drop";

import {
    reqGet,
    reqPost
} from "../../../../lib/request";
import {
    rescode,
    menuval,
    imgtype,
} from "../../../../lib/consts";
import {
    brandapi
} from '../../../../lib/api';
export default {
    components: {
        DatePicker,
        SingleThumbnail,
        ToggleBtn,
        Drag,
        Drop
    },
    computed: {
        imgtype() {
            return imgtype;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    data() {
        return {
            temp: {
                startDate: "",
                endDate: "",
            },
            proedt: false,
            promotionModalName: '',
            promotionModal: false,
            description: "",
            title: "",
            mainImageurl: "",
            image: {},
            promotionList: [],
            promotionId: "",
        }
    },
    created() {
        this.refresh();
    },
    methods: {
        delpromotion(promotionId) {
            if (confirm("삭제하시겠습니까? 삭제된 프로모션은 되돌릴 수 없습니다.")) {
                reqPost(
                    brandapi.base,
                    brandapi.storePromotionDelete,
                    this.authToken, {
                        promotionId: promotionId,
                        storeCode: this.$route.query.stcode,
                    },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                alert("삭제되었습니다.");
                                this.refresh();
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        edtPromotion(promotionId) {
            this.promotionModalName = '프로모션 수정';
            this.promotionModal = true;
            this.proedt = true;
            this.promotionId = promotionId;
            reqGet(
                brandapi.base,
                brandapi.storePromotionQuery + "?storeCode=" + this.$route.query.stcode + "&promotionId=" + promotionId,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.title = result.data.title;
                            this.description = result.data.description;
                            this.temp.startDate = result.data.startDate;
                            this.temp.endDate = result.data.endDate;
                            let url = '';
                            if(result.data.promotionImage !== null){
                                url = result.data.promotionImage.url;
                            }else{
                                url = '';
                            }
                            this.mainImageurl = url;
                            this.image = {
                                "url": url,
                                "width": result.data.promotionImage.width,
                                "height": result.data.promotionImage.height,
                                "description": result.data.promotionImage.description
                            }
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        handleDrop(myitem, drag) {
            var priorities = [];
            priorities.push({
                promotionId: drag.item.promotionId,
                priority: myitem.priority
            });
            priorities.push({
                promotionId: myitem.promotionId,
                priority: drag.item.priority
            });
            reqPost(
                brandapi.base,
                brandapi.storePromotionPriority,
                this.authToken, {
                    storeCode: this.$route.query.stcode,
                    priorities: priorities,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refresh();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        onChangeStatus(status, midx, promotionId) {
            var url = '';
            if (!status) {
                url = brandapi.storePromotionEnable;
            } else {
                url = brandapi.storePromotionDisable;
            }
            reqPost(
                brandapi.base,
                url,
                this.authToken, {
                    storeCode: this.$route.query.stcode,
                    promotionId: promotionId,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.promotionList[midx].status = !status;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refresh() {
            // console.log(this.$route.query.stcode)
            reqGet(
                brandapi.base,
                brandapi.storePromotionList + "?storeCode=" + this.$route.query.stcode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.promotionList = result.data.list;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        promotionModalOpen() {
            this.promotionModalName = '프로모션 만들기';
            this.promotionModal = true;
            this.proedt = false;
            this.title = '';
            this.description = '';
            this.temp.startDate = '';
            this.temp.endDate = '';
            this.mainImageurl = '';
            this.image = {}
        },
        onMainImage(newval, w, h) {
            this.mainImageurl = newval; //메인 이미지 url
            this.image = {
                "url": newval,
                "width": w,
                "height": h,
                "description": null
            }
        },
        promotionModalClose() {
            this.promotionModal = false;
        },
        promotionModalSave(edt) {
            if (this.title === '') {
                alert('제목을 입력해주세요');
                return;
            }
            if (this.description === '') {
                alert('내용을 입력해주세요');
                return;
            }
            if (this.temp.startDate === '' || this.temp.endDate === '') {
                alert('전시기간을 입력해주세요');
                return;
            }
            if(new Date(this.temp.startDate) > new Date(this.temp.endDate)){
                alert('시작일이 종료일보다 클 수 없습니다.');
                return;
            }
            let url = '';
            let dd = {};
            if (edt) {
                url = brandapi.storePromotionUpdate;
                dd = {
                    "promotion": {
                        "title": this.title,
                        "description": this.description,
                        "startDate": this.temp.startDate,
                        "endDate": this.temp.endDate,
                        "image": this.image,
                    },
                    'promotionId': this.promotionId,
                    "storeCode": this.$route.query.stcode,
                }
            } else {
                url = brandapi.storePromotionCreate;
                dd = {
                    "promotion": {
                        "title": this.title,
                        "description": this.description,
                        "startDate": this.temp.startDate,
                        "endDate": this.temp.endDate,
                        "image": this.image,
                    },
                    "storeCode": this.$route.query.stcode,
                }
            }
            reqPost(
                brandapi.base,
                url,
                this.authToken,
                dd,
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            alert('저장되었습니다.');
                            this.refresh();
                            this.promotionModalClose();
                        }
                    }
                }
            );
        },
    }
}
</script>

<style scoped>
.jetpo {
    justify-content: space-between;
    display: flex;
}

th {
    background-color: #dee2e685;
}

.right-btn {
    text-align: right;
}

.strongText {
    font-size: 18px;
}

.mt-6 {
    margin-top: 60px;
}

.changeInput {
    color: red;
    margin-top: 5px;
}

.btn-selweek {
    background-color: #5B9BD5;
    color: #FFFFFF;
}

.maxwidth {
    max-width: 200px;
    display: inline-block;
    vertical-align: middle;
}

.ml-1 {
    margin-left: 10px;

}

.mt-1,
.mt1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.bold {
    font-weight: 900;
}

.ml05 {
    margin-left: 5px;
}

.empty {
    color: #878787;
    font-size: 15px;
}

.mb-1 {
    margin-bottom: 10px;
}

.modal {
    overflow: auto;
}

.txtaH {
    height: 300px;
}
</style>
