<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="BrandCode">브랜드코드</option>
            <option value="BrandNameContaining">브랜드명</option>
            <option value="StoreCode">매장코드</option>
            <option value="StoreNameContaining">매장명</option>
            <option value="ManagerID">담당자ID</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th class="text-center">번호</th>
              <th class="text-center">분류</th>
              <th class="text-center">브랜드명</th>
              <th class="text-center">매장명</th>
              <th class="text-center">검수 신청일</th>
              <th class="text-center">승인여부</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(store, index) in content" v-bind:key="store.brandCode">
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{getStoreType(store.storeType)}}</td>
              <td class="text-center">{{store.brandName}}</td>
              <td class="linkcmp text-center" @click="gocheckstore(store.storeRegisterId)">{{store.storeName}}</td>
              <td class="text-center">{{viewDate(store.requestedDate)}}</td>
              <td class="text-center">
                <button class="btn btn-default" style="margin-right:10px" @click="allowstore(store.storeRegisterId, $event)">승인</button>
                <button class="btn btn-default" @click="cancelreview(store.storeRegisterId, $event)">거절</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">불가 사유안내</div>
          <div class="modal-body form-horizontal">
            <textarea class="form-control" rows="5" v-model="reason"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closemodal">취소</button>
            <button type="button" class="btn btn-default" @click="denyrequest">확인</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import { reqGet, reqPost } from "../../../lib/request";
import { brandapi } from "../../../lib/api";
import { DateFormat, getStoreTypeStr } from "../../../lib/utility";
export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.ReqIns_keywordType) {
        this.keywordType = localStorage.ReqIns_keywordType;
      }
      if (localStorage.ReqIns_keyword) {
        this.keyword = localStorage.ReqIns_keyword;
      }
      if (localStorage.ReqIns_Number) {
        this.pageNumber = parseInt(localStorage.ReqIns_Number);
      }
      if (localStorage.ReqIns_Size) {
        this.pageSize = parseInt(localStorage.ReqIns_Size);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_STR,
      title: "검수신청",
    });
  },
  watch: {
    keywordType(newVal) {
      localStorage.ReqIns_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.ReqIns_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.ReqIns_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.ReqIns_Size = newsize;
    },
  },
  data() {
    return {
      showmodal: false,
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "BrandNameContaining",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      storeRegisterId:null,
      reason:"",
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.ReqIns_keywordType = this.keywordType;
      localStorage.ReqIns_keyword = this.keyword;
      localStorage.ReqIns_Number = this.pageNumber;
      localStorage.ReqIns_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqGet(
        brandapi.base,
        brandapi.newStorepage +
          "?keywordType=" +
          this.keywordType +
          "&keyword=" +
          this.keyword +
          "&pageNumber=" +
          pageNumber +
          "&pageSize=" +
          this.pageSize,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    allowstore(storeRegisterId) {
      reqPost(
        brandapi.base,
        brandapi.newStoreapprove,
        this.authToken,
        {
          storeRegisterId: storeRegisterId,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    denyrequest()
    {
      reqPost(
        brandapi.base,
        brandapi.newStoredeny,
        this.authToken,
        {
          storeRegisterId: this.storeRegisterId,
          reason:this.reason
        },
        (result) => {
          this.closemodal();
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    cancelreview(val) {
      this.storeRegisterId = val;
      this.showmodal = true;
    },
    closemodal() {
      this.showmodal = false;
    },
    gocheckstore(val) {
      this.$router.push(this.routes.reqcheckstore+"?storeRegisterId="+val);
    },
    getStoreType(val){
      return getStoreTypeStr(val);
    }
  },
};
</script>

<style scoped>
.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}
</style>
