<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
          <div class="checkbox" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="ALL" v-model="saletype"/>전체
            </label>
            <label>
              <input type="radio" value="ONSALE" v-model="saletype"/>판매중
            </label>
            <label>
              <input type="radio" value="STOP" v-model="saletype"/>일시중지
            </label>
          </div>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsNameContaining">상품명</option>
            <option value="GoodsId">상품코드</option>
            <option value="SiteName">등록경로</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th class="text-center">번호</th>
              <th class="text-center">상품코드</th>
              <th class="text-center">등록경로</th>
              <th>상품명</th>
              <th class="text-center">정상가</th>
              <th class="text-center">판매가</th>
              <th class="text-center">판매상태</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(gitem, index) in content" v-bind:key="gitem.goodsId">
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{gitem.goodsId}}</td>
              <td class="text-center">{{getSiteBrandName(gitem.siteName)}}</td>
              <td class="linkcmp" @click="getGoodOptions(gitem.goodsId)">{{gitem.goodsName}}</td>
              <td class="text-center">{{gitem.mainOption.listPrice | numFormat }}</td>
              <td class="text-center">{{gitem.mainOption.salePrice | numFormat }}</td>
              <td class="text-center">{{getSaleState(gitem.onSale)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>선택</th>
                    <th>옵션코드</th>
                    <th>옵션명</th>
                    <th>정상가</th>
                    <th>판매가</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(option, oidx) in options" :key="oidx" >
                    <td class="linkcmp"><input type="radio" :value="true" :checked="mainOptionId == option.optionId" @click="onclickMain(option.optionId)"/></td>
                    <td>{{option.optionId}}</td>
                    <td>{{option.optionName}}</td>
                    <td>{{option.listPrice | numFormat }}</td>
                    <td>{{option.salePrice | numFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeGoodOptions">닫기</button>
            <button type="button" class="btn btn-default" @click="updateMainoption">저장</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import { reqGet, reqPost } from "../../../lib/request";
import { goodsapi, brandapi } from "../../../lib/api";
import { DateFormat } from "../../../lib/utility";
export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.OPOPT_keywordType) {
        this.keywordType = localStorage.OPOPT_keywordType;
      }
      if (localStorage.OPOPT_keyword) {
        this.keyword = localStorage.OPOPT_keyword;
      }
      if (localStorage.OPOPT_Number) {
        this.pageNumber = parseInt(localStorage.OPOPT_Number);
      }
      if (localStorage.OPOPT_Size) {
        this.pageSize = parseInt(localStorage.OPOPT_Size);
      }
      if (localStorage.OPOPT_saletype) {
        this.saletype = localStorage.OPOPT_saletype;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_OPR, title: "대표옵션관리"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.OPOPT_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.OPOPT_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.OPOPT_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.OPOPT_Size = newsize;
    },
    saletype(newsale)
    {
      localStorage.OPOPT_saletype = newsale;
    },
  },
  data() {
    return {
      showmodal: false,
      showgoodid:0,//팝업 창 상품 ID
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      saletype:"ALL",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /*
      goodsId                 Number        상품 ID
      goodsType               String        상품 타입[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
      goodsName               String        상품명
      siteName                String        상품 등록 사이트명
      soldCount               Number        판매수량
      onSale                  Boolean       판매 상태. 판매중인 경우 true
      mainImageUrl            String        메인 이미지 url
      mainOption.optionId     Number        메인 옵션 ID
      mainOption.optionName   String        메인 옵션명
      mainOption.listPrice    String        정상가
      mainOption.salePrice    String        판매가
      mainOption.currencyCode String        통화코드[USD, KRW]
      registeredDate          String        상품 등록일 UTC yyyy-MM-ddTHH:mm:ss
      saleEndDate             String        판매 종료일   yyyy-MM-dd
      */
      sitebrands: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true

      mainOptionId:0,//메인 옵션 ID
      options:[],//
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.OPOPT_keywordType = this.keywordType;
      localStorage.OPOPT_keyword = this.keyword;
      localStorage.OPOPT_Number = this.pageNumber;
      localStorage.OPOPT_Size = this.pageSize;
      localStorage.OPOPT_saletype = this.saletype;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var all = this.saletype == 'ALL';
      var onSale = this.saletype == 'ONSALE';
      reqGet(
        goodsapi.base,
        goodsapi.goodsadmpage +
          "?keywordType=" +
          this.keywordType +
          "&keyword=" +
          this.keyword +
          "&pageNumber=" +
          pageNumber +
          "&pageSize=" +
          this.pageSize + "&all="+all+"&onSale="+onSale,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.getBrandName(result.data.content);
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    getBrandName(retcnt)
    {
      if(retcnt.length == 0)
      {
        return ;
      }
      var siteArray = retcnt.map(citem => {return citem.siteName});
      reqPost(
        brandapi.base,
        brandapi.brandsbySites,
        this.authToken,
        {
          siteNames: siteArray,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.sitebrands = result.data.brands;
              this.content = retcnt;
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    getSiteBrandName(sitename)
    {
      var sbidx = this.sitebrands.findIndex(sbitem => sbitem.siteName == sitename );
      if(sbidx > -1)
      {
        return this.sitebrands[sbidx].brandName;
      }else{
        return "";
      }
    },
    closeGoodOptions() {
      this.showmodal = false;
    },
    getSaleState(val)
    {
      if(val)
      {
        return "판매중";
      }else{
        return "일시중지";
      }
    },
    getGoodOptions(val)
    {
      reqGet(
        goodsapi.base,
        goodsapi.goodsoptions + "?goodsId=" + val,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.mainOptionId = result.data.mainOptionId;
              this.options = result.data.options;//
              this.showgoodid = val;
              this.showmodal = true;
            } else {
              this.mainOptionId = 0;
              this.showgoodid = 0;
              this.hasOwnDeliveryPrice = false;//상품 별도로 입금가가 있는 경우 true
              this.options = [];//
              alert(result.message);
            }
          } else {
            this.mainOptionId = 0;
            this.showgoodid = 0;
            this.hasOwnDeliveryPrice = false;//상품 별도로 입금가가 있는 경우 true
            this.options = [];//
          }
        }
      );
    },
    onclickMain(optionid)
    {
      this.mainOptionId = optionid;
    },
    updateMainoption()
    {
      reqPost(
        goodsapi.base,
        goodsapi.optionsmainupdate,
        this.authToken,
        {
          goodsId: this.showgoodid,
          mainOptionId: this.mainOptionId
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeGoodOptions();
            }else{
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.checkbox > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}
</style>
