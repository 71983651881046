import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import {sync} from 'vuex-router-sync';
import VModal from 'vue-js-modal'
import store from './store';
import {cookielbl} from './lib/consts';
import {getMyIP} from './lib/request';
import routepath from './lib/routes';
import config from './config';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VueHtmlToPaper from 'vue-html-to-paper';
import {MonthPicker} from 'vue-month-picker'
import {MonthPickerInput} from 'vue-month-picker'
import moment from 'moment';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    // 'titlebar=yes',
    // 'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

Vue.use(VueRouter);
Vue.use(Chartkick.use(Chart))
Vue.filter('numFormat', numFormat(numeral));
Vue.use(VModal);
Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 1,
  newestOnTop: true
});
const lang = 'ko'
moment.locale(lang);
Vue.use(require('vue-moment'), {moment})
// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  console.log(to.path);

  if (store.state.userRole === 'AGENT' &&
    (
      to.path !== routepath.login &&
      to.path !== routepath.loginotp &&
      to.path !== routepath.signup &&
      to.path !== routepath.findpsw &&
      to.path !== '/' + routepath.refresh &&
      to.path !== '/' + routepath.resetpsw &&
      to.path !== '/' + routepath.editmnginf &&
      to.path !== '/' + routepath.agentstoresettlement
    )) {
    next({
      path: routepath.agentstoresettlement,
      query: {redirect: to.fullPath}
    });
  } else {
    if (
      to.matched.some(record => record.meta.requiresAuth) &&
      ((router.app.$store.getters.expiresAt - 10) < Math.floor(Date.now() / 1000))) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.

      getMyIP((myip) => {
        if (config.insideip === myip) {
          next({
            path: routepath.login,
            query: {redirect: to.fullPath}
          })
        } else {
          next({
            path: routepath.loginotp,
            query: {redirect: to.fullPath}
          })
        }
      })
    } else {
      next()
    }
  }
})

sync(store, router)

// Check local storage to handle refreshes
if (window.localStorage) {
  var localUserString = window.localStorage.getItem(cookielbl.authinf) || 'null'
  var localUser = JSON.parse(localUserString)

  if (localUser && store.state.token !== localUser.token) {
    localUser.userid = window.localStorage.getItem(cookielbl.remem)
    store.commit('SET_AUTH', localUser);
  }
}


Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
