<template>
  <!-- Navbar -->
  <header class="main-header">
    <span class="logo-mini">
      <router-link to="/"><div class="logo"><img src="/static/img/logo_H.png" alt="TableNJoy" class="img-responsive center-block hlogo"/>
          <img src="/static/img/logo_V.png" alt="TableNJoy" class="img-responsive center-block vlogo"/></div>
      </router-link>
      <!-- <router-link to="/"><img src="/static/img/copilot-logo-white.svg" alt="TableNJoy" class="img-responsive center-block logo"></router-link> -->
    </span>
    <nav class="navbar navbar-static-top" role="navigation">
      <!-- Sidebar toggle button-->
      <a href="javascript:;" class="sidebar-toggle" data-toggle="offcanvas" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>

      <!-- Navbar Right Menu -->
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li>
            <router-link :to="routes.resetpsw" title="비밀번호 변경">
              <i class="fa fa-key"></i>
            </router-link>
          </li>
          <li>
            <router-link :to="routes.editmnginf" title="관리자 정보 변경">
              <i class="fa fa-cog"></i>
            </router-link>
          </li>
          <li>
            <a @click="clicklogout" class="dropdown-toggle" title="로그아웃">
              <i class="fa fa-sign-out"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import routepath from "../lib/routes";
export default {
  name: "DashHeader",
  components: {},
  props: {},
  computed: {
    routes() {
      return routepath;
    },
  },
  methods: {
    clicklogout() {
      this.$store.dispatch("dologout", this.isLogOut);
    },
    isLogOut(val) {
      if (val) {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style scoped>
.logo-mini img {
  width: 80%;
  align-self: center;
}
.main-header .logo {
  display: flex;
}

.main-header .logo .vlogo {
  display: none;
}
.sidebar-collapse .main-header .logo {
  padding: 0;
}
.sidebar-collapse .logo-mini img {
  width: 100%;
}
.sidebar-mini.sidebar-collapse .main-header .logo .hlogo {
  display: none;
}
.sidebar-mini.sidebar-collapse .main-header .logo .vlogo {
  display: block;
}
</style>