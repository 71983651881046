<template lang="ko">
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <div style="position: relative;margin-right:10px" v-click-outside="hideEndMPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="endYearMonth" @click="onShowEndMPicker" />
                    <!-- <month-picker v-if="startMpicker" lang="ko" :max-date="new Date(endyear+'-'+endmonth+'-01')" :default-month="startmonth" :default-year="startyear" :input-pre-filled="true" @change="selStartMonth"></month-picker> -->
                    <month-picker v-if="endMpicker" lang="ko" :default-month="endmonth" :default-year="endyear" :input-pre-filled="true" @change="selEndMonth"></month-picker>
                </div>

                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
                    전월
                </button>
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
                    당월
                </button>
                <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <div class="radio" style="margin: 0px">
            <!-- <label><input type="radio" value="order" v-model="sortbase" />거래기준</label>
            <label><input type="radio" value="cancel" v-model="sortbase" />취소기준</label> -->
            <button type="button" class="btn btn-default pull-right" @click="TotaldownExcel" :disabled="buttonDissabled">
                <i class="fa fa-download"></i>Excel
            </button>
        </div>
    </div>
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th v-for="(vmon, vidx) in viewMonth" :key="vidx">{{rendMonth(vmon)}}</th>
                                <th>전월대비</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(channel, cidx) in salesByBrands" :key="cidx">
                                <td class="linkcmp" @click="viewBrandInf(channel)">{{channel.brandName}}</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{getMonthVal(channel, vmon) | numFormat}}</td>
                                <!-- <td v-if="getMonthVal(channel, viewMonth[0]) == '0'">0.00%</td>
                                <td v-else>{{getMonthVal(channel, viewMonth[1]) / (getMonthVal(channel, viewMonth[0]) ) *100 | numFormat('0.00')}}%</td> -->
                                <td>{{getPercentage( getMonthVal(channel, viewMonth[0]), getMonthVal(channel, viewMonth[1])) | numFormat('0.00') }}%</td>
                            </tr>
                            <tr>
                                <td>총계</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{getTotalMonthVal(vmon) | numFormat}}</td>
                                <td>{{(getPercentage( getTotalMonthVal(viewMonth[0]), getTotalMonthVal(viewMonth[1]))) | numFormat('0.00')}}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px" v-if="viewBrand != null">
        <div class="form-group">
            <span>상세 내역</span>
            <button type="button" class="btn btn-default pull-right" @click="DetaildownExcel" :disabled="buttonDissabled">
                <i class="fa fa-download"></i>Excel
            </button>
        </div>
    </div>
    <div class="box box-solid" v-if="viewBrand != null">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th v-for="(vmon, vidx) in viewMonth" :key="vidx">{{rendMonth(vmon)}}</th>
                                <th>전월대비</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(vdata, didx) in  viewBrand" :key="didx">
                                <td>{{didx+1}}일</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{ getDayVal(vdata, vmon) | numFormat }}</td>
                                <td>{{getPercentage(getDayVal(vdata, viewMonth[0]), (getDayVal(vdata, viewMonth[1])))| numFormat('0.00')}}%</td>
                            </tr>
                            <tr v-for="(vdata, didx) in dayTotal " :key="'o-${didx}'">
                                <td>총계</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{ getDayVal(vdata, vmon) | numFormat }}</td>
                                <td> {{getPercentage(getMonthVal(channelInfo, viewMonth[0]),getMonthVal(channelInfo,viewMonth[1])) | numFormat('0.00')}}%</td>
                                <!-- <td v-else>{{getMonthVal(channelInfo, viewMonth[1]) / (getMonthVal(channelInfo, viewMonth[0]) ) *100 | numFormat('0.00')}}%</td> -->
                         </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import {
    reqPost
} from "../../../lib/request";
import {
    orderapi
} from "../../../lib/api";
import {
    DateFormat
} from "../../../lib/utility";
import {
    MonthPicker
} from 'vue-month-picker'
import moment from 'moment';
import XLSX from "xlsx";
export default {
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.event)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.event)
            }
        }
    },
    components: {
        MonthPicker,
    },
    watch: {
        startMpicker(spicker) {
            if (spicker) {
                this.startmonth = moment(this.endYearMonth + "-01").month() +1;
                this.startyear = moment(this.endYearMonth + "-01").year();
            }
        },
        endMpicker(epicker) {
            if (epicker) {
                this.endmonth = moment(this.endYearMonth + "-01").month() + 1;
                this.endyear = moment(this.endYearMonth + "-01").year();
            }
        },
        sortbase() {
            this.viewChannel = null;
        }
    },
    created() {
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_STAT,
            path2: menuval.LV2_STT_DPL,
            title: "거래현황 브랜드별 (월별)"
        });
    },
    data() {
        return {
            sortbase: "order",
            maxdate: moment(),
            startYearMonth: moment().subtract(1, 'months').format('YYYY-MM'),
            startmonth: moment().subtract(1, 'months').month() +1,
            startyear: moment().year(),
            startMpicker: false,
            endYearMonth: moment().format('YYYY-MM'),
            endmonth: moment().month() + 1,
            endyear: moment().year(),
            endMpicker: false,

            salesByBrands: [],
            viewMonth: [], //
            totalsales: 0,
            totalcancelled: 0,
            viewBrand: null,
            dayTotal: null,
            channelInfo: null,
          buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.refreshPage();
    },
    methods: {
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        rendMonth(val) {
            return DateFormat(val + "-01", "YYYY년MM월");
        },
        hideStartMPicker() {
            this.startMpicker = false;
        },
        onShowStartMPicker() {
            this.startMpicker = !this.startMpicker;
        },
        selStartMonth(date) {
            this.startYearMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
        },
        hideEndMPicker() {
            this.endMpicker = false;
        },
        onShowEndMPicker() {
            this.endMpicker = !this.endMpicker;
        },
        selEndMonth(date) {
            this.endYearMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
            if(date.monthIndex == 1){
                this.startYearMonth = date.year -1 + "-" + "12";
            } else {
                this.startYearMonth = date.year + "-" + (date.monthIndex -1).toString().padStart(2,0);
            }
         },
        onPrvMonth() {
            this.startYearMonth = moment().subtract(2, 'months').format('YYYY-MM');
            this.endYearMonth = moment().subtract(1, 'months').format('YYYY-MM');
        },
        onNowMonth() {
            this.startYearMonth = moment().subtract(1, 'months').format('YYYY-MM');
            this.endYearMonth = moment().format('YYYY-MM');
        },
        refreshPage() {
            this.viewBrand = null;
            this.dayTotal = null;
            this.channelInfo = null;
            reqPost(
                orderapi.base,
                orderapi.totalizePurchasedSalesbrandmonth,
                this.authToken, {
                    startYearMonth: this.startYearMonth,
                    endYearMonth: this.endYearMonth
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.getViewMonths(result.data.salesByBrands);
                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getViewMonths(salesByBrands) {
            this.totalsales = {};
            this.totalcancelled = {};
            this.viewMonth = [];
            for (var idx = 0; idx < salesByBrands.length; idx++) {
                for (var midx = 0; midx < salesByBrands[idx].salesByMonths.length; midx++) {
                    var fidx = this.viewMonth.findIndex(vmonth => vmonth == salesByBrands[idx].salesByMonths[midx].salesMonth);
                    if (fidx == -1) {
                        this.viewMonth.push(salesByBrands[idx].salesByMonths[midx].salesMonth);
                        this.totalsales[salesByBrands[idx].salesByMonths[midx].salesMonth] = parseInt(salesByBrands[idx].salesByMonths[midx].sales.salesAmount);
                        this.totalcancelled[salesByBrands[idx].salesByMonths[midx].salesMonth] = parseInt(salesByBrands[idx].salesByMonths[midx].sales.cancelledAmount);
                    } else {
                        this.totalsales[salesByBrands[idx].salesByMonths[midx].salesMonth] += parseInt(salesByBrands[idx].salesByMonths[midx].sales.salesAmount);
                        this.totalcancelled[salesByBrands[idx].salesByMonths[midx].salesMonth] += parseInt(salesByBrands[idx].salesByMonths[midx].sales.cancelledAmount);
                    }
                }
            }
            this.viewMonth.sort((a, b) => {
                return a.order - b.order;
            });
            this.salesByBrands = salesByBrands;
        },
        getMonthVal(channel, vmon) {
            for (var idx = 0; idx < channel.salesByMonths.length; idx++) {
                if (channel.salesByMonths[idx].salesMonth == vmon) {
                    if (this.sortbase == "order") {
                        return channel.salesByMonths[idx].sales.salesAmount;
                    } else {
                        return channel.salesByMonths[idx].sales.cancelledAmount;
                    }
                }
            }
            return 0;
        },
        getPercentage(fre, now) {
            var percent = 0.00;
            if(fre == 0 || now == 0) {
                return 0;
            }
            else if(fre <0 && now >0) {
                return (now -fre) /-fre *100;
            }
            else if(fre>0 && now >0) {
                return now /fre *100;
            }
            else if(fre >0 && now <0){
                return (now - fre ) / fre *100;
            } else {
                 return (fre - now) /fre *100;

            }
        },
        getTotalMonthVal(vmon) {
            if (this.sortbase == "order") {
                return this.totalsales[vmon];
            } else {
                return this.totalcancelled[vmon];
            }
        },
        getTotalAllVal() {
            var retval = 0;
            for (var idx = 0; idx < this.viewMonth.length; idx++) {
                if (this.sortbase == "order") {
                    retval = retval + parseInt(this.totalsales[this.viewMonth[idx]]);
                } else {
                    retval = retval + parseInt(this.totalcancelled[this.viewMonth[idx]]);
                }
            }
            return retval;
        },
        getTotalVal(channel) {
            var retval = 0;
            for (var idx = 0; idx < channel.salesByMonths.length; idx++) {
                if (this.sortbase == "order") {
                    retval = retval + parseInt(channel.salesByMonths[idx].sales.salesAmount);
                } else {
                    retval = retval + parseInt(channel.salesByMonths[idx].sales.cancelledAmount);
                }
            }
            return retval;
        },
        viewBrandInf(channel) {
            this.channelInfo = channel;
            this.viewBrand = [];
            this.dayTotal = [];
            var maxdays = 28;
            var d_data = {};
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                if (maxdays < moment(this.viewMonth[midx] + "-01").endOf('month').date()) {
                    maxdays = moment(this.viewMonth[midx] + "-01").endOf('month').date();
                }
                d_data[this.viewMonth[midx]] ="";
                for (var sidx = 0; sidx < channel.salesByMonths.length; sidx++) {
                    if (channel.salesByMonths[sidx].salesMonth == this.viewMonth[midx]) {

                        if (this.sortbase == "order") {
                            d_data[this.viewMonth[midx]] = channel.salesByMonths[sidx].sales.salesAmount;
                        } else {
                            d_data[this.viewMonth[midx]] = channel.salesByMonths[sidx].sales.cancelledAmount;
                        }
                    }
                }
            }
            for (var didx = 0; didx < maxdays; didx++) {
                var t_data = {};
                var t_total = 0;
                for (midx = 0; midx < this.viewMonth.length; midx++) {
                    t_data[this.viewMonth[midx]] = "";
                    for (var sidx = 0; sidx < channel.salesByMonths.length; sidx++) {
                        if (channel.salesByMonths[sidx].salesMonth == this.viewMonth[midx]) {

                            if (channel.salesByMonths[sidx].salesByDates[didx] != undefined) {
                                if (this.sortbase == "order") {
                                    t_data[this.viewMonth[midx]] = channel.salesByMonths[sidx].salesByDates[didx].sales.salesAmount;
                                    t_total = t_total + parseInt(channel.salesByMonths[sidx].salesByDates[didx].sales.salesAmount);
                                } else {
                                    t_data[this.viewMonth[midx]] = channel.salesByMonths[sidx].salesByDates[didx].sales.cancelledAmount;
                                    t_total = t_total + parseInt(channel.salesByMonths[sidx].salesByDates[didx].sales.cancelledAmount);
                                }
                            }
                            break;
                        }
                    }
                }
                t_data["total"] = t_total;
                this.viewBrand.push({
                    ...t_data
                });
            }
            this.dayTotal.push({
                ...d_data
            });
        },
        getDayVal(vdata, vmon) {
            return vdata[vmon];
        },
        totalconvert(channel) {
            var celldata = {};
            celldata[0] = channel.brandName;
            for(var midx=0; midx<this.viewMonth.length; midx++)
            {
                celldata[midx+1] = this.getMonthVal(channel, this.viewMonth[midx]);
            }

            celldata[this.viewMonth.length+1] = Math.round(this.getPercentage(this.getMonthVal(channel,this.viewMonth[0]), this.getMonthVal(channel,this.viewMonth[1]))*100)/100;


            return celldata;
        },
        TotaldownExcel() {
          this.buttonDissabled = true;
            var headrow = {};
            headrow[0] = "구분";
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                headrow[midx + 1] = this.viewMonth[midx];
            }
            headrow[this.viewMonth.length + 1] = "전월대비";
            var total = {};
            total[0] = "총계";
            for(var cidx = 0; cidx< this.viewMonth.length; cidx++){
                total[cidx +1] = this.getTotalMonthVal(this.viewMonth[cidx]);
            }
            total[this.viewMonth.length +1] = Math.round(this.getPercentage( this.getTotalMonthVal(this.viewMonth[0]), this.getTotalMonthVal(this.viewMonth[1]))*100)/100;
             var excelheader = XLSX.utils.json_to_sheet(
                [
                    headrow,
                    total,
                ], {
                    skipHeader: true
                }
            );

            XLSX.utils.sheet_add_json(excelheader, this.salesByBrands.map(this.totalconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "";
            if (this.sortbase == "order") {
                subtxt = "브랜드별-월별(거래기준)";
            } else {
                subtxt = "브랜드별-월별(취소기준)";
            }
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "거래현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        },
        detailconvert(vdata, didx) {
            var celldata = {};
            celldata[0] = (didx + 1) + "일";
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                celldata[midx + 1] = this.getDayVal(vdata, this.viewMonth[midx]);
            }
            // if(this.getDayVal(vdata, this.viewMonth[0]) == '' || this.getDayVal(vdata, this.viewMonth[1]) == '' || this.getDayVal(vdata, this.viewMonth[0]) == undefined || this.getDayVal(vdata, this.viewMonth[1]) == null ) {
            //     celldata[this.viewMonth.length + 1] = 0.00;

            // } else {
                celldata[this.viewMonth.length + 1] = Math.round(this.getPercentage(this.getDayVal(vdata, this.viewMonth[0]), this.getDayVal(vdata, this.viewMonth[1])) *100)/100;
            // }
            return celldata;
        },
        DetaildownExcel() {
          this.buttonDissabled = true;
            var headrow = {};
            headrow[0] = "구분";
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                headrow[midx + 1] = this.viewMonth[midx];
            }
            headrow[this.viewMonth.length + 1] = "전월대비";
            var total = {};
            total[0] = "총계";
            for(var nidx = 0; nidx< this.dayTotal.length; nidx++) {

                for (var midx = 0; midx < this.viewMonth.length; midx++) {
                    total[midx + 1] = this.getDayVal(this.dayTotal[nidx], this.viewMonth[midx]);
                }
                total[this.viewMonth.length +1] = Math.round(this.getPercentage(this.getDayVal(this.dayTotal[nidx], this.viewMonth[0]),this.getDayVal(this.dayTotal[nidx], this.viewMonth[1])) *100)/100;
            }
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    headrow,
                    total,
                ], {
                    skipHeader: true
                }
            );

            XLSX.utils.sheet_add_json(excelheader, this.viewBrand.map(this.detailconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "";
            if (this.sortbase == "order") {
                subtxt = "브랜드별-월별(상세 거래기준)";
            } else {
                subtxt = "브랜드별-월별(상세 취소기준)";
            }
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "거래현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        },
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.month-picker__container {
    position: absolute;
    top: 3.5em;
    background-color: #fff;
    z-index: 9999;
}

th {
    text-align: center;
}

td {
    text-align: center;
}
</style>
