<template lang="ko">
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex; flex-wrap: wrap; width: 100%;">
                <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="salesMonth" @click="onShowMonthPicker" />
                    <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
                </div>
                <button class="btn btn-default" type="button" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="goodsExcelDown">
            <i class="fa fa-download"></i> 상품별 Excel
        </button>
        <button type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="mainhisDown">
            <i class="fa fa-download"></i> Excel
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th class="text-center" rowspan='2'>번호</th>
                        <th class="text-center" rowspan='2'>브랜드</th>
                        <th class="text-center" rowspan='2'>매장명</th>
                        <th class="text-center" colspan='3'>사용매출</th>
                        <th class="text-center" colspan="3">취소위약금 매출</th>
                        <th class="text-center" rowspan="2">총입금액</th>
                        <th class="text-center" rowspan="2">총수수료</th>
                        <th class="text-center" rowspan="2">담당자</th>
                        <th class="text-center" rowspan="2">정산일</th>
                    </tr>
                    <tr>
                        <th class="text-center">거래금액</th>
                        <th class="text-center">입금금액</th>
                        <th class="text-center">수수료</th>
                        <th class="text-center">위약금</th>
                        <th class="text-center">입금금액</th>
                        <th class="text-center">수수료</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">합계</td>
                        <td></td>
                        <td></td>
                        <td class="text-center">{{ salesAmountTotal | numFormat }}</td>
                        <td class="text-center">{{ salesDeliveryAmountTotal | numFormat }}</td>
                        <td class="text-center">{{ salesCommissionTotal | numFormat }}</td>
                        <td class="text-center">{{ penaltyTotal | numFormat }}</td>
                        <td class="text-center">{{ penaltyDeliveryAmountTotal | numFormat }}</td>
                        <td class="text-center">{{ penaltyCommissionTotal | numFormat }}</td>
                        <td class="text-center">{{ deliveryAmountTotal | numFormat }}</td>
                        <td class="text-center">{{ commissionTotal | numFormat }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <template v-for="(bdata, bidx) in maindata">
                        <tr v-for="(sdata, sidx) in bdata.stores" :key="bidx*100 + sidx">
                            <td class="text-center" v-if="sidx == 0" :rowspan="bdata.stores.length">{{bidx + 1}}</td>
                            <td class="text-center" v-if="sidx == 0" :rowspan="bdata.stores.length">{{bdata.brandName}}</td>
                            <td class="text-center">{{sdata.storeName}}</td>
                            <td class="linkcmp text-center" @click="showEdit(sdata,'sales')">{{sdata.salesAmountTotal | numFormat}}</td>
                            <td class="text-center">{{sdata.salesDeliveryAmountTotal | numFormat}}</td>
                            <td class="text-center">{{sdata.salesCommissionTotal | numFormat}}</td>
                            <td class="linkcmp text-center" @click="showEdit(sdata,'penalty')">{{sdata.penaltyTotal | numFormat}}</td>
                            <td class="text-center">{{sdata.penaltyDeliveryAmountTotal | numFormat}}</td>
                            <td class="text-center">{{sdata.penaltyCommissionTotal | numFormat}}</td>
                            <td class="text-center">{{sdata.deliveryAmountTotal | numFormat}}</td>
                            <td class="text-center">{{sdata.commissionTotal | numFormat}}</td>
                            <td class="text-center">{{bdata.tnjManagerID}}</td>
                            <td class="text-center" v-html="getArrayStr(sdata.settlementDates)"></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal" :style="editdlg && { display: 'block', 'z-index': '2000' }">
        <div class="modal-dialog modal-lg">
            <div class="box box-solid">
                <div class="box-header">
                    <!-- 거래금액 -->
                    <div class="input-group radio" v-if="radioType">
                        <label><input type="radio" value="goodSales" v-model="viewtype" />상품기준 보기</label>
                        <label><input type="radio" value="orderSales" v-model="viewtype" />사용일기준 보기</label>
                    </div>
                    <!-- 위약금 -->
                    <div class="input-group radio" v-else>
                        <label><input type="radio" value="good" v-model="viewtype" />상품기준 보기</label>
                        <label><input type="radio" value="order" v-model="viewtype" />사용일기준 보기</label>
                    </div>

                    <button v-if="viewtype == 'good' || viewtype === 'goodSales'" type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="goodExcel(viewtype)" :disabled="buttonDissabled">
                        <i class="fa fa-download"></i>Excel
                    </button>
                    <button v-if="viewtype == 'order' || viewtype === 'orderSales'" type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="orderExcel(viewtype)" :disabled="buttonDissabled">
                        <i class="fa fa-download"></i>Excel
                    </button>
                    <h3 class="box-title"></h3>
                    <div class="box-tools pull-right"></div>
                </div>
                <div class="box-body">
                    <div class="from-group" style="margin: 0px">
                        <div class="table-responsive p-0" style="height:200px">
                            <!-- 거래금액 -->
                            <table class="table table-hover text-nowrap" v-if="viewtype === 'goodSales'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">상품구분</th>
                                        <th class="text-center">상품명</th>
                                        <th class="text-center">옵션명</th>
                                        <th class="text-center">사용건수</th>
                                        <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th>
                                        <th class="text-center">입금가</th>
                                        <th class="text-center">마진</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(option, oidx) in goodsdata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                        <td class="text-center">{{ goodsTypeVal(option.goodsType) }}</td>
                                        <td class="text-center">{{option.goodsName}}</td>
                                        <td class="text-center">{{option.optionName }}</td>
                                        <td class="text-center">{{option.transactionCount | numFormat}}</td>
                                        <td class="text-center">{{ option.originalSalesAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesDeliveryAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesCommissionTotal | numFormat}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-hover text-nowrap" v-if="viewtype === 'orderSales'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">쿠폰번호</th>
                                        <th class="text-center">사용일시</th>
                                        <th class="text-center">상품구분</th>
                                        <th>상품명</th>
                                        <th>옵션명</th>
                                        <th class="text-center">정상가</th>
                                        <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th>
                                        <th class="text-center">마진</th>
                                        <th class="text-center">구매자</th>
                                        <th class="text-center">자체판매</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, oidx) in usedata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                        <td class="text-center">{{order.itemNo }}</td>
                                        <td class="text-center">{{viewDate(order.useDate)}}</td>
                                        <td>{{goodsTypeVal(order.goodsType) }}</td>
                                        <td>{{order.goodsName }}</td>
                                        <td>{{order.optionName }}</td>
                                        <td class="text-center">{{order.listPrice | numFormat}}</td>
                                        <td class="text-center">{{order.originalSalePrice | numFormat}}</td>
                                        <td class="text-center">{{order.salePrice | numFormat}}</td>
                                        <td class="text-center">{{order.salesCommission | numFormat}}</td>
                                        <td class="text-center">{{order.buyerName }}</td>
                                        <td class="text-center">{{order.selfSales? "Y":"" }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-hover text-nowrap" v-if="viewtype == 'good'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th>상품구분</th>
                                        <th>상품명</th>
                                        <th>옵션명</th>
                                        <th class="text-center">취소건수</th>
                                        <!-- <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th> -->
                                        <th class="text-center">취소위약금</th>
                                        <th class="text-center">취소위약급입금가</th>
                                        <th class="text-center">취소위약금수수료</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(option, oidx) in goodsdata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                        <td>{{ goodsTypeVal(option.goodsType) }}</td>
                                        <td>{{option.goodsName}}</td>
                                        <td>{{option.optionName }}</td>
                                        <td class="text-center">{{option.penaltyCount | numFormat}}</td>
                                        <!-- <td class="text-center">{{option.originalSalesAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesAmountTotal | numFormat}}</td> -->
                                        <td class="text-center">{{option.penaltyTotal | numFormat}}</td>
                                        <td class="text-center">{{option.penaltyDeliveryAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.penaltyCommissionTotal | numFormat}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-hover text-nowrap" v-if="viewtype == 'order'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">쿠폰번호</th>
                                        <th class="text-center">사용일시</th>
                                        <th class="text-center">상품구분</th>
                                        <th class="text-center">상품명</th>
                                        <th class="text-center">옵션명</th>
                                        <!-- <th class="text-center">정상가</th> -->
                                        <!-- <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th> -->
                                        <th class="text-center">취소위약금</th>
                                        <th class="text-center">취소위약금입금가</th>
                                        <th class="text-center">취소위약금수수료</th>
                                        <th class="text-center">구매자</th>
                                        <th class="text-center">자체판매</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, oidx) in usedata" :key="oidx">
                                        <td class="text-center"> {{ oidx + 1 }} </td>
                                        <td class="text-center">{{order.itemNo }}</td>
                                        <td class="text-center"> {{viewDate(order.useDate)}} </td>
                                        <td class="text-center"> {{ goodsTypeVal(order.goodsType) }} </td>
                                        <td class="text-center"> {{order.goodsName }} </td>
                                        <td class="text-center"> {{order.optionName }} </td>
                                        <!-- <td class="text-center"> {{order.listPrice | numFormat}} </td> -->
                                        <!-- <td class="text-center"> {{order.originalSalePrice | numFormat}} </td>
                                        <td class="text-center"> {{order.salePrice | numFormat}} </td> -->
                                        <td class="text-center"> {{order.penalty | numFormat}} </td>
                                        <td class="text-center"> {{order.penaltyDeliveryPrice | numFormat}} </td>
                                        <td class="text-center"> {{order.penaltyCommission | numFormat}} </td>
                                        <td class="text-center"> {{order.buyerName }} </td>
                                        <td class="text-center"> {{order.selfSales? "Y":"" }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="box-footer text-center">
                    <button type="button" class="btn btn-default" @click="closeEdit">닫기</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import {
    reqPost
} from "../../lib/request";
import {
    orderapi
} from "../../lib/api";
import {
    DateFormat,
    getGoodsTypeStr
} from "../../lib/utility";
import {
    MonthPicker
} from 'vue-month-picker'
import moment from 'moment';
import XLSX from "xlsx";

export default {
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.event)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.event)
            }
        }
    },
    components: {
        MonthPicker,
    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_PAY,
            path2: menuval.LV1_HOME,
            title: "매장별 정산내역",
        });
    },
    data() {
        return {
            radioType: false,
            salesMonth: "",
            defmonth: moment().month() + 1,
            defyear: moment().year(),
            monthpicker: false,
            content: [],
            editdlg: false,

            maindata: [],
            /**
             * brandCode                        String      브랜드 코드
             * brandName                        String      브랜드명
             * tnjManagerID                     String      해당 브랜드 TNJ 관리자 ID
             * stores[].storeCode               String      매장 코드
             * stores[].storeName               String      매장명
             * stores[].settlementDates         Array       yyyy-MM-dd  정산일 리스트
             * stores[].listPriceTotal          String      제품 가격 합계
             * stores[].salesAmountTotal        String      판매 가격 합계 (거래금액)
             * stores[].deliveryAmountTotal     String      입금금액 합계
             * stores[].salesCommissionTotal    String      판매 수수료 합계 (마진)
             * stores[].currencyCode            String      [USD, KRW]  통화코드
             * stores[].goods                   Array       상품별 집계
             * stores[].goods[].goodsType       String      [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon] 상품 타입
             * stores[].goods[].goodsId         Number      상품 ID
             * stores[].goods[].goodsName       String      상품명
             * stores[].goods[].options[].optionId            Number    옵션 ID
             * stores[].goods[].options[].optionName          String    옵션명
             * stores[].goods[].options[].listPrice           String    옵션가격 (예, 10만원 금액권일 경우 10만원)
             * stores[].goods[].options[].salePrice           String    옵션 판매가격 (예, 10만원 금액권을 9만원에 팔경우 9만원)
             * stores[].goods[].options[].transactionCount    Number    판매회수 (매장에서 사용된 회수)
             * stores[].goods[].options[].listPriceTotal      String    제품가격 합계 (단, 금액권인 경우 매장에서 사용된 금액 기준)
             * stores[].goods[].options[].salesAmountTotal    String    판매가격 합계 (단, 금액권인 경우 매장에서 사용된 금액 비율에 맞게 계산된 금액 기준)
             * stores[].goods[].options[].deliveryAmountTotal   String    입금금액 합계
             * stores[].goods[].options[].salesCommissionTotal  String    판매 수수료 합계 (마진)
             * stores[].orders                                Array       주문별 집계
             * stores[].orders[].orderNo                      String      주문번호
             * stores[].orders[].purchaseDate                 String      UTC yyyy-MM-ddTHH:mm:ss 쿠폰 구매일
             * stores[].orders[].orderItems[].itemNo          String      쿠폰번호
             * stores[].orders[].orderItems[].useDate         String      yyyy-MM-ddTHH:mm:ss 사용일자
             * stores[].orders[].orderItems[].goodsType       String      상품 타입
             * stores[].orders[].orderItems[].goodsId         Number      상품 ID
             * stores[].orders[].orderItems[].goodsName       String      상품명
             * stores[].orders[].orderItems[].optionId        Number      옵션 ID
             * stores[].orders[].orderItems[].optionName      String      옵션명
             * stores[].orders[].orderItems[].buyerName       String      구매자 이름
             * stores[].orders[].orderItems[].listPrice       String      제품가격 (단, 금액권인 경우 매장에서 사용된 금액)
             * stores[].orders[].orderItems[].salePrice       String      판매가격 (단, 금액권인 경우 매장에서 사용된 금액 비율에 맞게 계산된 판매금액)
             * stores[].orders[].orderItems[].deliveryPrice   String      입금가
             * stores[].orders[].orderItems[].salesCommission     String   판매 수수료 (마진)
             * stores[].orders[].orderItems[].channelType         String    [Tablenjoy, B2bIframe, B2bChannel] 쿠폰 주문 채널 타입
             * stores[].orders[].orderItems[].siteName            String    channelType 이 Tablenjoy 일 경우 구매 사이트명
             * stores[].orders[].orderItems[].b2bChannelCode      String    channelType 이 B2bIframe, B2bChannel 일 경우 B2B 채널명
             */
            goodsdata: [],
            usedata: [],
            viewtype: "good",
            //리뉴얼 추가
            salesDeliveryAmountTotal: 0,
            salesCommissionTotal: 0,
            penaltyTotal: 0,
            penaltyDeliveryAmountTotal: 0,
            penaltyCommissionTotal: 0,
            deliveryAmountTotal: 0,
            commissionTotal: 0,
            salesAmountTotal: 0,
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        this.refreshPage();
    },
    methods: {
        goodsTypeVal(val) {
            return getGoodsTypeStr(val);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm");
        },
        refreshPage() {
            reqPost(
                orderapi.base,
                orderapi.tnjstotalize,
                this.authToken, {
                    salesMonth: this.salesMonth
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.maindata = result.data.brands;
                            let salesAmountTotal = 0,
                                salesDeliveryAmountTotal = 0,
                                salesCommissionTotal = 0,
                                penaltyTotal = 0,
                                penaltyDeliveryAmountTotal = 0,
                                penaltyCommissionTotal = 0,
                                deliveryAmountTotal = 0,
                                commissionTotal = 0;
                            this.maindata.forEach(el => {
                                el.stores.forEach(el2 => {
                                    salesAmountTotal += Number(el2.salesAmountTotal);
                                    salesDeliveryAmountTotal += Number(el2.salesDeliveryAmountTotal);
                                    salesCommissionTotal += Number(el2.salesCommissionTotal);
                                    penaltyTotal += Number(el2.penaltyTotal);
                                    penaltyDeliveryAmountTotal += Number(el2.penaltyDeliveryAmountTotal);
                                    penaltyCommissionTotal += Number(el2.penaltyCommissionTotal);
                                    deliveryAmountTotal += Number(el2.deliveryAmountTotal);
                                    commissionTotal += Number(el2.commissionTotal);
                                });
                            });
                            this.salesAmountTotal = salesAmountTotal;
                            this.salesDeliveryAmountTotal = salesDeliveryAmountTotal;
                            this.salesCommissionTotal = salesCommissionTotal;
                            this.penaltyTotal = penaltyTotal;
                            this.penaltyDeliveryAmountTotal = penaltyDeliveryAmountTotal;
                            this.penaltyCommissionTotal = penaltyCommissionTotal;
                            this.deliveryAmountTotal = deliveryAmountTotal;
                            this.commissionTotal = commissionTotal;
                        } else {
                            this.maindata = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        closeEdit() {
            this.editdlg = false;
        },
        showEdit(sdata, type) {
            if (type === 'sales') {
                this.viewtype = "goodSales";
                this.radioType = true;
            } else {
                this.viewtype = "good";
                this.radioType = false;
            }
            // this.viewdata = sdata;
            this.usedata = [];
            this.goodsdata = [];
            for (var idx = 0; idx < sdata.goods.length; idx++) {
                for (var gidx = 0; gidx < sdata.goods[idx].options.length; gidx++) {
                    var t_data = {
                        goodsName: sdata.goods[idx].goodsName,
                        goodsType: sdata.goods[idx].goodsType,
                        optionName: sdata.goods[idx].options[gidx].optionName,
                        penaltyCount: sdata.goods[idx].options[gidx].penaltyCount,
                        originalSalesAmountTotal: sdata.goods[idx].options[gidx].originalSalesAmountTotal,
                        salesAmountTotal: sdata.goods[idx].options[gidx].salesAmountTotal,
                        penaltyTotal: sdata.goods[idx].options[gidx].penaltyTotal,
                        transactionCount: sdata.goods[idx].options[gidx].transactionCount,
                        penaltyDeliveryAmountTotal: sdata.goods[idx].options[gidx].penaltyDeliveryAmountTotal,
                        penaltyCommissionTotal: sdata.goods[idx].options[gidx].penaltyCommissionTotal,
                        deliveryAmountTotal: sdata.goods[idx].options[gidx].deliveryAmountTotal,
                        salesCommissionTotal: sdata.goods[idx].options[gidx].salesCommissionTotal,
                        salesDeliveryAmountTotal: sdata.goods[idx].options[gidx].salesDeliveryAmountTotal,
                    };
                    if (Number(sdata.goods[idx].options[gidx].salesDeliveryAmountTotal) !== 0 && this.radioType === true) {
                        this.goodsdata.push(t_data);
                    } else if (Number(sdata.goods[idx].options[gidx].penaltyTotal) !== 0 && this.radioType === false) {
                        this.goodsdata.push(t_data);
                    }
                }
            }

            for (idx = 0; idx < sdata.orders.length; idx++) {
                for (gidx = 0; gidx < sdata.orders[idx].orderItems.length; gidx++) {
                    t_data = {
                        useDate: sdata.orders[idx].orderItems[gidx].useDate,
                        goodsType: sdata.orders[idx].orderItems[gidx].goodsType,
                        itemNo: sdata.orders[idx].orderItems[gidx].itemNo,
                        goodsName: sdata.orders[idx].orderItems[gidx].goodsName,
                        optionName: sdata.orders[idx].orderItems[gidx].optionName,
                        listPrice: sdata.orders[idx].orderItems[gidx].listPrice,
                        salePrice: sdata.orders[idx].orderItems[gidx].salePrice,
                        originalSalePrice: sdata.orders[idx].orderItems[gidx].originalSalePrice,
                        salesCommission: sdata.orders[idx].orderItems[gidx].salesCommission,
                        penalty: sdata.orders[idx].orderItems[gidx].penalty,
                        penaltyDeliveryPrice: sdata.orders[idx].orderItems[gidx].penaltyDeliveryPrice,
                        penaltyCommission: sdata.orders[idx].orderItems[gidx].penaltyCommission,
                        buyerName: sdata.orders[idx].orderItems[gidx].buyerName,
                        selfSales: sdata.orders[idx].orderItems[gidx].selfSales,
                    };
                    if (Number(sdata.orders[idx].orderItems[gidx].salesDeliveryPrice) !== 0 && this.radioType === true) {
                        this.usedata.push(t_data);
                    } else if (Number(sdata.orders[idx].orderItems[gidx].penaltyDeliveryPrice) !== 0 && this.radioType === false) {
                        this.usedata.push(t_data);
                    }
                }
            }
            this.editdlg = true;
        },
        selMonth(date) {
            this.salesMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
            // this.monthpicker = false;
        },
        onShowMonthPicker() {
            this.monthpicker = !this.monthpicker;
        },
        hideMonthPicker() {
            this.monthpicker = false;
        },
        getArrayStr(ary_data) {
            var retval = "";
            for (var idx = 0; idx < ary_data.length; idx++) {
                if (retval != "") {
                    retval = retval + "<br/>";
                }
                if (moment(this.salesMonth).isSame(ary_data[idx], 'month')) {
                    retval = retval + "당월";
                } else {
                    retval = retval + "익월";
                }
                retval = retval + moment(ary_data[idx]).date();
            }
            return retval;
        },
        goodsConvert(mdata, midx){
            return {
                A: mdata.brandName,
                B: mdata.brandCode,
                C: mdata.storeName,
                D: mdata.storeCode,
                E: mdata.goodsType,
                F: mdata.goodsName,
                G: mdata.goodsId,
                H: mdata.optionName,
                J: mdata.optionId,
                K: mdata.transactionCount,
                L: mdata.originalSalesAmountTotal,
                M: mdata.salesAmountTotal,
                N: mdata.salesDeliveryAmountTotal,
                T: mdata.salesCommissionTotal,
            };
        },
        mainconvert(mdata, midx) {
            return {
                A: midx + 1,
                B: mdata.brandName,
                C: mdata.storeName,
                D: mdata.salesAmountTotal,
                E: mdata.salesDeliveryAmountTotal,
                F: mdata.salesCommissionTotal,
                G: mdata.penaltyTotal,
                H: mdata.penaltyDeliveryAmountTotal,
                J: mdata.penaltyCommissionTotal,
                K: mdata.deliveryAmountTotal,
                L: mdata.commissionTotal,
                M: mdata.tnjManagerID,
                N: mdata.settlementDates,
            };
        },
        goodsExcelDown(){
            var excelheader = XLSX.utils.json_to_sheet(
                [{
                    A: "브랜드명",
                    B: "브랜드코드",
                    C: "매장명",
                    D: "매장코드",
                    E: "상품구분",
                    F: "상품명",
                    G: "상품코드",
                    H: "옵션명",
                    J: "옵션코드",
                    K: "사용건수",
                    L: "판매가",
                    M: "실판매가",
                    N: "입금가",
                    T: "마진",
                }
                ], {
                skipHeader: true
            }
            );

            var exceldata = [];
            for (var idx = 0; idx < this.maindata.length; idx++) {
                for (var sidx = 0; sidx < this.maindata[idx].stores.length; sidx++) {
                    for (var gidx = 0; gidx < this.maindata[idx].stores[sidx].goods.length; gidx++) {
                        for (var oidx = 0; oidx < this.maindata[idx].stores[sidx].goods[gidx].options.length; oidx++) {
                            var t_data = {
                                brandName: this.maindata[idx].brandName,
                                brandCode: this.maindata[idx].brandCode,
                                storeName: this.maindata[idx].stores[sidx].storeName,
                                storeCode: this.maindata[idx].stores[sidx].storeCode,
                                goodsType: this.goodsTypeVal(this.maindata[idx].stores[sidx].goods[gidx].goodsType),
                                goodsName: this.maindata[idx].stores[sidx].goods[gidx].goodsName,
                                goodsId: this.maindata[idx].stores[sidx].goods[gidx].goodsId,
                                optionName: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].optionName,
                                optionId: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].optionId,
                                transactionCount: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].transactionCount,
                                originalSalesAmountTotal: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].originalSalesAmountTotal,
                                salesAmountTotal: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].salesAmountTotal,
                                salesDeliveryAmountTotal: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].salesDeliveryAmountTotal,
                                salesCommissionTotal: this.maindata[idx].stores[sidx].goods[gidx].options[oidx].salesCommissionTotal,
                            };
                            exceldata.push(t_data);
                        }
                    }
                }
            }
            XLSX.utils.sheet_add_json(excelheader, exceldata.map(this.goodsConvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "상품별 정산내역");
            // export Excel file
            XLSX.writeFile(wb, "상품별 정산내역.xlsx");
        },
        mainhisDown() {
            var excelheader = XLSX.utils.json_to_sheet(
                [{
                        A: "번호",
                        B: "브랜드",
                        C: "매장명",
                        D: "거래금액",
                        E: "입금금액",
                        F: "수수료",
                        G: "취소위약금",
                        H: "취소위약금입금금액",
                        J: "취소위약금수수료",
                        K: "총입금금액",
                        L: "총수수료",
                        M: "담당자",
                        N: "정산일",
                    },
                    {
                        A: "합계",
                        B: "",
                        C: "",
                        D: this.salesAmountTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        E: this.salesDeliveryAmountTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        F: this.salesCommissionTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        G: this.penaltyTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        H: this.penaltyDeliveryAmountTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        J: this.penaltyCommissionTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        K: this.deliveryAmountTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        L: this.commissionTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        M: "",
                        N: "",
                    }
                ], {
                    skipHeader: true
                }
            );

            var exceldata = [];
            for (var idx = 0; idx < this.maindata.length; idx++) {
                for (var sidx = 0; sidx < this.maindata[idx].stores.length; sidx++) {
                    var t_data = {
                        brandName: this.maindata[idx].brandName,
                        storeName: this.maindata[idx].stores[sidx].storeName,
                        salesAmountTotal: this.maindata[idx].stores[sidx].salesAmountTotal,
                        penaltyTotal: this.maindata[idx].stores[sidx].penaltyTotal,
                        penaltyDeliveryAmountTotal: this.maindata[idx].stores[sidx].penaltyDeliveryAmountTotal,
                        penaltyCommissionTotal: this.maindata[idx].stores[sidx].penaltyCommissionTotal,
                        salesDeliveryAmountTotal: this.maindata[idx].stores[sidx].salesDeliveryAmountTotal,
                        salesCommissionTotal: this.maindata[idx].stores[sidx].salesCommissionTotal,
                        commissionTotal: this.maindata[idx].stores[sidx].commissionTotal,
                        deliveryAmountTotal: this.maindata[idx].stores[sidx].deliveryAmountTotal,
                        tnjManagerID: this.maindata[idx].stores[sidx].tnjManagerID,
                        settlementDates: this.maindata[idx].stores[sidx].settlementDates.join(',')
                    };
                    exceldata.push(t_data);
                }
            }
            XLSX.utils.sheet_add_json(excelheader, exceldata.map(this.mainconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "매장별 정산내역");
            // export Excel file
            XLSX.writeFile(wb, "매장별 정산내역.xlsx");
        },
        goodconvert(gdata, gidx) {
            if (this.viewtype == 'goodSales') {
                return {
                    A: gidx + 1,
                    B: this.goodsTypeVal(gdata.goodsType),
                    C: gdata.goodsName,
                    D: gdata.optionName,
                    E: gdata.transactionCount,
                    F: gdata.originalSalesAmountTotal,
                    G: gdata.salesAmountTotal,
                    H: gdata.salesDeliveryAmountTotal,
                    J: gdata.salesCommissionTotal,
                };
            } else {
                return {
                    A: gidx + 1,
                    B: this.goodsTypeVal(gdata.goodsType),
                    C: gdata.goodsName,
                    D: gdata.optionName,
                    E: gdata.penaltyCount,
                    // F: gdata.originalSalesAmountTotal,
                    // G: gdata.salesAmountTotal,
                    H: gdata.penaltyTotal,
                    J: gdata.penaltyDeliveryAmountTotal,
                    K: gdata.penaltyCommissionTotal,
                };
            }
        },
        goodExcel(viewtype) {
          this.buttonDissabled = true;
            var excelheader = null;
            if (viewtype == 'goodSales') {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        B: "상품구분",
                        C: "상품명",
                        D: "옵션명",
                        E: "사용건수",
                        F: "판매가",
                        G: "실판매가",
                        H: "입금가",
                        J: "마진",
                    }, ], {
                        skipHeader: true
                    }
                );
            } else {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        B: "상품구분",
                        C: "상품명",
                        D: "옵션명",
                        E: "취소건수",
                        // F: "판매가",
                        // G: "실판매가",
                        H: "취소위약금",
                        J: "취소위약금입금가",
                        K: "취소위약금수수료",
                    }, ], {
                        skipHeader: true
                    }
                );

            }

            XLSX.utils.sheet_add_json(excelheader, this.goodsdata.map(this.goodconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "상품기준 매장별 정산내역 ");
            // export Excel file
            XLSX.writeFile(wb, "상품기준 매장별 정산내역.xlsx");
            this.buttonDissabled = false;
        },
        orderconvert(odata, oidx) {
            if (this.viewtype === 'orderSales') {
                return {
                    A: oidx + 1,
                    Q: odata.itemNo,
                    B: this.viewDate(odata.useDate),
                    C: this.goodsTypeVal(odata.goodsType),
                    D: odata.goodsName,
                    E: odata.optionName,
                    F: odata.listPrice,
                    G: odata.originalSalePrice,
                    H: odata.salePrice,
                    J: odata.salesCommission,
                    I: odata.buyerName,
                    K: odata.selfSales ? "Y" : "",
                };
            } else {
                return {
                    A: oidx + 1,
                    Q: odata.itemNo,
                    B: this.viewDate(odata.useDate),
                    C: this.goodsTypeVal(odata.goodsType),
                    D: odata.goodsName,
                    E: odata.optionName,
                    // F: odata.listPrice,
                    // G: odata.originalSalePrice,
                    // H: odata.salePrice,
                    J: odata.penalty,
                    I: odata.penaltyDeliveryPrice,
                    K: odata.penaltyCommission,
                    L: odata.buyerName,
                    M: odata.selfSales ? "Y" : "",
                };
            }
        },
        orderExcel(viewtype) {
          this.buttonDissabled = true;
            var excelheader = null;
            if (viewtype === 'orderSales') {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        Q: "쿠폰번호",
                        B: "사용일시",
                        C: "상품구분",
                        D: "상품명",
                        E: "옵션명",
                        F: "정상가",
                        G: "판매가",
                        H: "실판매가",
                        J: "마진",
                        I: "구매자",
                        K: "자체판매",
                    }, ], {
                        skipHeader: true
                    }
                );
            } else {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        Q: "쿠폰번호",
                        B: "사용일시",
                        C: "상품구분",
                        D: "상품명",
                        E: "옵션명",
                        // F: "정상가",
                        // G: "판매가",
                        // H: "실판매가",
                        J: "취소위약금",
                        I: "취소위약금입금가",
                        K: "취소위약금수수료",
                        L: "구매자",
                        M: "자체판매",
                    }, ], {
                        skipHeader: true
                    }
                );
            }

            XLSX.utils.sheet_add_json(excelheader, this.usedata.map(this.orderconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "사용일기준 매장별 정산내역 ");
            // export Excel file
            XLSX.writeFile(wb, "사용일기준 매장별 정산내역.xlsx");
            this.buttonDissabled = false;
        }
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.modal-body .control-label {
    margin: 0px;
}

.modal .table {
    margin-bottom: 0px;
}

.month-picker__container {
    position: absolute;
    top: 3.5em;
    background-color: #fff;
    z-index: 9999;
}

th {
    background-color: #dee2e685;
    vertical-align: middle !important;
    ;
}
</style>
