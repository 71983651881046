<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items: center;">
          <select class="form-control" style="width:auto;margin-right:10px" v-model="dateType">
            <option value="UseDate">사용일</option>
            <option value="CancelledDate">취소일</option>
          </select>
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="ItemNo">쿠폰번호</option>
            <option value="ManagerId">요청자 ID</option>
            <option value="BrandCode">브랜드코드</option>
            <option value="StoreCode">매장코드</option>
            <option value="B2bOrderNo">B2B주문번호</option>
            <option value="Channel">주문경로</option>
            <option value="GoodsNameContaining">상품명</option>

          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px;margin-right:10px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
          <label style="margin-right:10px"><input type="checkbox" :value="true" v-model="executedByStaffOnly"/>내부담당자 처리</label>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">브랜드</th>
            <th class="text-center">매장</th>
            <th class="text-center">요청자</th>
            <th>쿠폰번호</th>
            <th class="text-center">사용일시</th>
            <th class="text-center">사용취소일시</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(uitem, index) in content" v-bind:key="uitem.itemNo+'_'+index">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{ uitem.brandName }}</td>
            <td class="text-center">{{ uitem.storeName }}</td>
            <td class="text-center">{{ uitem.managerId }}</td>
            <td>{{ uitem.itemNo }}</td>
            <td class="linkcmp text-center" @click="viewDetailMessage(uitem.memo)">{{ viewDate(uitem.useDate) }}</td>
            <td v-if="uitem.cancelledDate != null && uitem.cancelledDate != ''" class="linkcmp text-center"
                @click="viewDetailMessage(uitem.cancellerMemo)">{{ viewDate(uitem.cancelledDate) }}
            </td>
            <td class="text-center" v-else></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" v-if="viewdlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">내용</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <textarea class="form-control" rows="5" v-model="modalmsg" readonly></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" @click="closeViewDlg">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import DetePicker from "../../../components/DatePicker";
import {reqPost} from "../../../lib/request";
import {orderapi} from "../../../lib/api";
import {DateFormat} from "../../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";

export default {
  components: {
    Pagination,
    DetePicker,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.USEP_keywordType) {
        this.keywordType = localStorage.USEP_keywordType;
      }
      if(localStorage.USEP_keyword) {
        this.keyword = localStorage.USEP_keyword;
      }
      if(localStorage.USEP_Number) {
        this.pageNumber = parseInt(localStorage.USEP_Number);
      }
      if(localStorage.USEP_Size) {
        this.pageSize = parseInt(localStorage.USEP_Size);
      }
      if(localStorage.USEP_dateType) {
        this.dateType = localStorage.USEP_dateType;
      }
      if(localStorage.USEP_dateFrom) {
        this.dateFrom = localStorage.USEP_dateFrom;
      }
      if(localStorage.USEP_dateTo) {
        this.dateTo = localStorage.USEP_dateTo;
      }
    }
    this.$store.dispatch('doSetPath', {path1: menuval.LV1_ISSUE, path2: menuval.LV2_ISS_USE, title: "예약필수쿠폰"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.USEP_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.USEP_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.USEP_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.USEP_Size = newsize;
    },
    dateType(newType) {
      localStorage.USEP_dateType = newType;
    },
    dateFrom(newfrom) {
      localStorage.USEP_dateFrom = newfrom;
    },
    dateTo(newto) {
      localStorage.USEP_dateTo = newto;
    }
  },
  data() {
    return {
      viewdlg: false,
      modalmsg: "",
      shownum: 10, //pagination에 보여지는 번호개수
      dateType: "UseDate",
      dateFrom: moment().format("YYYY-MM-01"),
      dateTo: moment().format("YYYY-MM-DD"),
      keywordType: "NoKeyword",
      keyword: "",
      executedByStaffOnly: false,
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * itemNo                 String    쿠폰번호
       * transactionNo          String    트랜잭션 번호
       * brandName              String    브랜드명
       * brandCode              String    브랜드 코드
       * storeName              String    매장명
       * storeCode              String    매장 코드
       * useDate                String    yyyy-MM-ddTHH:mm:ss 사용일
       * useAmount              String    사용금액
       * currencyCode           String    [USD, KRW] 통화코드
       * siteName               String    사용처리 사이트명
       * managerId              String    사용처리 관라자 ID
       * cancelled              Boolean   취소된 트랜잭션인 경우 true
       * cancelledDate          String    UTC yyyy-MM-ddTHH:mm:ss   취소일
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true

      exceldata: [],
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.USEP_keywordType = this.keywordType;
      localStorage.USEP_keyword = this.keyword;
      localStorage.USEP_Number = this.pageNumber;
      localStorage.USEP_Size = this.pageSize;
      localStorage.USEP_dateType = this.dateType;
      localStorage.USEP_dateFrom = this.dateFrom;
      localStorage.USEP_dateTo = this.dateTo;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      // return DateFormat(val, "YYYY-MM-DD HH:mm:00");
      return DateFormat(val, "YYYY-MM-DD HH:mm");
    },
    closeViewDlg() {
      this.viewdlg = false;
    },
    viewDetailMessage(val) {
      this.modalmsg = val;
      this.viewdlg = true;
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var pageNumber = this.pageNumber - 1;
      reqPost(
        orderapi.base,
        orderapi.bookingInfopage,
        this.authToken,
        {
          dateType: this.dateType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          pageNumber: pageNumber,
          pageSize: this.pageSize,
          executedByStaffOnly: this.executedByStaffOnly,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    getExcelData() {
      this.buttonDissabled = true;
      reqPost(
        orderapi.base,
        orderapi.bookingInfolist,
        this.authToken,
        {
          dateType: this.dateType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          executedByStaffOnly: this.executedByStaffOnly,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            } else {
              this.exceldata = [];
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    dataconvert(order) {
      return {
        A: order.brandName,
        B: order.storeName,
        C: order.managerId,
        D: order.itemNo,
        E: this.viewDate(order.useDate),
        F: this.viewDate(order.cancelledDate),
        G: order.useAmount
      };
    },
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "브랜드",
            B: "매장",
            C: "요청자",
            D: "쿠폰번호",
            E: "사용일시",
            F: "사용취소일시",
            G: "사용금액(정상가)",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "사용이력-예약필수쿠폰");
      // export Excel file
      XLSX.writeFile(wb, "사용이력-예약필수쿠폰.xlsx");
      this.buttonDissabled = false;
    },
  },
};
</script>
<style scoped>
.form-group {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
