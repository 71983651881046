<template lang="ko">
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <div style="position: relative;margin-right:10px" v-click-outside="hideStartMPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="startYearMonth" @click="onShowStartMPicker" />
                    <!-- <month-picker v-if="startMpicker" lang="ko" :max-date="new Date(endyear+'-'+endmonth+'-01')" :default-month="startmonth" :default-year="startyear" :input-pre-filled="true" @change="selStartMonth"></month-picker> -->
                    <month-picker v-if="startMpicker" lang="ko" :default-month="startmonth" :default-year="startyear" :input-pre-filled="true" @change="selStartMonth"></month-picker>
                </div>
                <h4 style="margin: 5px 10px">~</h4>
                <div style="position: relative;margin-right:10px" v-click-outside="hideEndMPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="endYearMonth" @click="onShowEndMPicker" />
                    <!-- <month-picker v-if="endMpicker" lang="ko" :min-date="new Date(new Date().setDate(new Date(startyear+'-'+startmonth+'-01')))" :default-month="endmonth" :default-year="endyear" :input-pre-filled="true" @change="selEndMonth"></month-picker> -->
                    <month-picker v-if="endMpicker" lang="ko" :default-month="endmonth" :default-year="endyear" :input-pre-filled="true" @change="selEndMonth"></month-picker>
                </div>
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
                    전월
                </button>
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
                    당월
                </button>
                <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <div class="radio" style="margin: 0px">
            <label><input type="radio" value="order" v-model="sortbase" />거래기준</label>
            <label><input type="radio" value="cancel" v-model="sortbase" />취소기준</label>
            <button type="button" class="btn btn-default pull-right" @click="TotaldownExcel" :disabled="buttonDissabled">
                <i class="fa fa-download"></i>Excel
            </button>
        </div>
    </div>
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th v-for="(vmon, vidx) in viewMonth" :key="vidx">{{rendMonth(vmon)}}</th>
                                <th>계</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(channel, cidx) in salesByChannels" :key="cidx">
                                <!-- <td class="linkcmp" @click="viewChannelInf(channel)">{{channel.channelType}}({{channel.channelCode}})</td> -->
                                <td v-if="channel.channelType != 'Tablenjoy'" class="linkcmp" @click="viewChannelInf(channel)">{{channel.channelCode}}</td>
                                <td v-else-if="channel.channelCode === 'selfSales'" class="linkcmp" @click="viewChannelInf(channel)">자체판매</td>
                                <td v-else class="linkcmp" @click="viewChannelInf(channel)">Tablenjoy</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{getMonthVal(channel, vmon) | numFormat}}</td>
                                <td>{{getTotalVal(channel) | numFormat}}</td>
                            </tr>
                            <tr>
                                <td>계</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{getTotalMonthVal(vmon) | numFormat}}</td>
                                <td>{{getTotalAllVal() | numFormat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px" v-if="viewChannel != null">
        <div class="form-group">
            <span>상세 내역</span>
            <button type="button" class="btn btn-default pull-right" @click="DetaildownExcel" :disabled="buttonDissabled">
                <i class="fa fa-download"></i>Excel
            </button>
        </div>
    </div>
    <div class="box box-solid" v-if="viewChannel != null">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th v-for="(vmon, vidx) in viewMonth" :key="vidx">{{rendMonth(vmon)}}</th>
                                <th>계</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(vdata, didx) in  viewChannel" :key="didx">
                                <td>{{didx+1}}일</td>
                                <td v-for="(vmon, vidx) in viewMonth" :key="vidx">{{getDayVal(vdata, vmon) | numFormat}}</td>
                                <td>{{vdata.total | numFormat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import {
    reqPost
} from "../../../lib/request";
import {
    orderapi
} from "../../../lib/api";
import {
    DateFormat
} from "../../../lib/utility";
import {
    MonthPicker
} from 'vue-month-picker'
import moment from 'moment';
import XLSX from "xlsx";
export default {
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.event)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.event)
            }
        }
    },
    components: {
        MonthPicker,
    },
    watch: {
        startMpicker(spicker) {
            if (spicker) {
                this.startmonth = moment(this.startYearMonth + "-01").month() + 1;
                this.startyear = moment(this.startYearMonth + "-01").year();
            }
        },
        endMpicker(epicker) {
            if (epicker) {
                this.endmonth = moment(this.endYearMonth + "-01").month() + 1;
                this.endyear = moment(this.endYearMonth + "-01").year();
            }
        },
        sortbase() {
            this.viewChannel = null;
        }
    },
    created() {
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_STAT,
            path2: menuval.LV2_STT_DPL,
            title: "거래현황 채널별 (월별)"
        });
    },
    data() {
        return {
            sortbase: "order",
            maxdate: moment(),
            startYearMonth: moment().format('YYYY-MM'),
            startmonth: moment().month() + 1,
            startyear: moment().year(),
            startMpicker: false,
            endYearMonth: moment().format('YYYY-MM'),
            endmonth: moment().month() + 1,
            endyear: moment().year(),
            endMpicker: false,

            salesByChannels: [], //채널별 매출 집계
            /**
             * channelType                              String      [Tablenjoy, B2bIframe, B2bChannel]    채널타입
             * channelCode                              String      B2B 채널코드. channelType 이 B2bIframe, B2bChannel 인 경우 값이 입력됨.
             * salesByMonths                            Array       월별 매출 집계
             * salesByMonths[].salesMonth               String      yyyy-MM  매출 월
             * salesByMonths[].sales.salesAmount        String      매출액
             * salesByMonths[].sales.cancelledAmount    String      취소금액
             * salesByMonths[].sales.netSalesAmount     String      순매출액
             * salesByMonths[].salesByDates             Array       일별 매출 집계
             * salesByMonths[].salesByDates[].dayOfMonth            Number    해당 월의 날짜. 1 부터 시작.
             * salesByMonths[].salesByDates[].sales.salesAmount             String    매출액
             * salesByMonths[].salesByDates[].sales.cancelledAmount         String    취소금액
             * salesByMonths[].salesByDates[].sales.netSalesAmount          String    순매출액
             */
            viewMonth: [], //
            totalsales: null,
            totalcancelled: null,
            viewChannel: null,
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.refreshPage();
    },
    methods: {
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        rendMonth(val) {
            return DateFormat(val + "-01", "YYYY년MM월");
        },
        hideStartMPicker() {
            this.startMpicker = false;
        },
        onShowStartMPicker() {
            this.startMpicker = !this.startMpicker;
        },
        selStartMonth(date) {
            this.startYearMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
        },
        hideEndMPicker() {
            this.endMpicker = false;
        },
        onShowEndMPicker() {
            this.endMpicker = !this.endMpicker;
        },
        selEndMonth(date) {
            this.endYearMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
        },
        onPrvMonth() {
            this.startYearMonth = moment().subtract(1, 'months').format('YYYY-MM');
            this.endYearMonth = moment().subtract(1, 'months').format('YYYY-MM');
        },
        onNowMonth() {
            this.startYearMonth = moment().format('YYYY-MM');
            this.endYearMonth = moment().format('YYYY-MM');
        },
        refreshPage() {
            this.viewChannel = null;
            reqPost(
                orderapi.base,
                orderapi.totalizePurchasedSalesmonth,
                this.authToken, {
                    startYearMonth: this.startYearMonth,
                    endYearMonth: this.endYearMonth
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.getViewMonths(result.data.salesByChannels);
                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getViewMonths(salesByChannels) {
            this.totalsales = {};
            this.totalcancelled = {};
            this.viewMonth = [];
            for (var idx = 0; idx < salesByChannels.length; idx++) {
                for (var midx = 0; midx < salesByChannels[idx].salesByMonths.length; midx++) {
                    var fidx = this.viewMonth.findIndex(vmonth => vmonth == salesByChannels[idx].salesByMonths[midx].salesMonth);
                    if (fidx == -1) {
                        this.viewMonth.push(salesByChannels[idx].salesByMonths[midx].salesMonth);
                        this.totalsales[salesByChannels[idx].salesByMonths[midx].salesMonth] = parseInt(salesByChannels[idx].salesByMonths[midx].sales.salesAmount);
                        this.totalcancelled[salesByChannels[idx].salesByMonths[midx].salesMonth] = parseInt(salesByChannels[idx].salesByMonths[midx].sales.cancelledAmount);
                    } else {
                        this.totalsales[salesByChannels[idx].salesByMonths[midx].salesMonth] += parseInt(salesByChannels[idx].salesByMonths[midx].sales.salesAmount);
                        this.totalcancelled[salesByChannels[idx].salesByMonths[midx].salesMonth] += parseInt(salesByChannels[idx].salesByMonths[midx].sales.cancelledAmount);
                    }
                }
            }
            this.viewMonth.sort((a, b) => {
                return a.order - b.order;
            });
            this.salesByChannels = salesByChannels;
        },
        getMonthVal(channel, vmon) {
            for (var idx = 0; idx < channel.salesByMonths.length; idx++) {
                if (channel.salesByMonths[idx].salesMonth == vmon) {
                    if (this.sortbase == "order") {
                        return channel.salesByMonths[idx].sales.salesAmount;
                    } else {
                        return channel.salesByMonths[idx].sales.cancelledAmount;
                    }
                }
            }
            return 0;
        },
        getTotalMonthVal(vmon) {
            if (this.sortbase == "order") {
                return this.totalsales[vmon];
            } else {
                return this.totalcancelled[vmon];
            }
        },
        getTotalAllVal() {
            var retval = 0;
            for (var idx = 0; idx < this.viewMonth.length; idx++) {
                if (this.sortbase == "order") {
                    retval = retval + parseInt(this.totalsales[this.viewMonth[idx]]);
                } else {
                    retval = retval + parseInt(this.totalcancelled[this.viewMonth[idx]]);
                }
            }
            return retval;
        },
        getTotalVal(channel) {
            var retval = 0;
            for (var idx = 0; idx < channel.salesByMonths.length; idx++) {
                if (this.sortbase == "order") {
                    retval = retval + parseInt(channel.salesByMonths[idx].sales.salesAmount);
                } else {
                    retval = retval + parseInt(channel.salesByMonths[idx].sales.cancelledAmount);
                }
            }
            return retval;
        },
        viewChannelInf(channel) {
            this.viewChannel = [];
            var maxdays = 28;
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                if (maxdays < moment(this.viewMonth[midx] + "-01").endOf('month').date()) {
                    maxdays = moment(this.viewMonth[midx] + "-01").endOf('month').date();
                }
            }
            for (var didx = 0; didx < maxdays; didx++) {
                var t_data = {};
                var t_total = 0;
                for (midx = 0; midx < this.viewMonth.length; midx++) {
                    t_data[this.viewMonth[midx]] = "";
                    for (var sidx = 0; sidx < channel.salesByMonths.length; sidx++) {
                        if (channel.salesByMonths[sidx].salesMonth == this.viewMonth[midx]) {
                            if (channel.salesByMonths[sidx].salesByDates[didx] != undefined) {
                                if (this.sortbase == "order") {
                                    t_data[this.viewMonth[midx]] = channel.salesByMonths[sidx].salesByDates[didx].sales.salesAmount;
                                    t_total = t_total + parseInt(channel.salesByMonths[sidx].salesByDates[didx].sales.salesAmount);
                                } else {
                                    t_data[this.viewMonth[midx]] = channel.salesByMonths[sidx].salesByDates[didx].sales.cancelledAmount;
                                    t_total = t_total + parseInt(channel.salesByMonths[sidx].salesByDates[didx].sales.cancelledAmount);
                                }
                            }
                            break;
                        }
                    }
                }
                t_data["total"] = t_total;
                this.viewChannel.push({
                    ...t_data
                });
            }
        },
        getDayVal(vdata, vmon) {
            return vdata[vmon];
        },
        totalconvert(channel) {
            var celldata = {};
            // celldata[0] = channel.channelType+"("+channel.channelCode+")";
            if (channel.channelType == 'Tablenjoy' && channel.channelCode !== 'selfSales') {
                celldata[0] = "Tablenjoy"
            } else if (channel.channelCode === 'selfSales') {
                celldata[0] = "자체판매";
            } else {
                celldata[0] = channel.channelCode;
            }
            // celldata[0] = channel.channelType == 'Tablenjoy' ? "Tablenjoy" : channel.channelCode;
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                celldata[midx + 1] = this.getMonthVal(channel, this.viewMonth[midx]);
            }
            celldata[this.viewMonth.length + 1] = this.getTotalVal(channel);
            return celldata;
        },
        TotaldownExcel() {
          this.buttonDissabled = true;
            var headrow = {};
            headrow[0] = "구분";
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                headrow[midx + 1] = this.viewMonth[midx];
            }
            headrow[this.viewMonth.length + 1] = "계";
            var totalrow = {};
            totalrow[0] = "계";
            for (midx = 0; midx < this.viewMonth.length; midx++) {
                totalrow[midx + 1] = this.getTotalMonthVal(this.viewMonth[midx]);
            }
            totalrow[this.viewMonth.length + 1] = this.getTotalAllVal();
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    headrow,
                    totalrow,
                ], {
                    skipHeader: true
                }
            );

            XLSX.utils.sheet_add_json(excelheader, this.salesByChannels.map(this.totalconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "";
            if (this.sortbase == "order") {
                subtxt = "채널별-월별(거래기준)";
            } else {
                subtxt = "채널별-월별(취소기준)";
            }
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "거래현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        },
        detailconvert(vdata, didx) {
            var celldata = {};
            celldata[0] = (didx + 1) + "일";
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                celldata[midx + 1] = this.getDayVal(vdata, this.viewMonth[midx]);
            }
            celldata[this.viewMonth.length + 1] = vdata.total;
            return celldata;
        },
        DetaildownExcel() {
          this.buttonDissabled = true;
            var headrow = {};
            headrow[0] = "구분";
            for (var midx = 0; midx < this.viewMonth.length; midx++) {
                headrow[midx + 1] = this.viewMonth[midx];
            }
            headrow[this.viewMonth.length + 1] = "계";
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    headrow,
                ], {
                    skipHeader: true
                }
            );

            XLSX.utils.sheet_add_json(excelheader, this.viewChannel.map(this.detailconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "";
            if (this.sortbase == "order") {
                subtxt = "채널별-월별(상세 거래기준)";
            } else {
                subtxt = "채널별-월별(상세 취소기준)";
            }
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "거래현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        },
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.month-picker__container {
    position: absolute;
    top: 3.5em;
    background-color: #fff;
    z-index: 9999;
}

th {
    text-align: center;
}

td {
    text-align: center;
}
</style>
