<template>
<div class="input-group" style="width:100%;display: inline-flex;flex-wrap: wrap;">
    <input type="text" class="form-control" :value="value" disabled style="flex:1;min-width:200px" />
    <button type="button" v-if="!readonly" v-show="value != '' && value != null" class="btn btn-default" @click="onPreviewClick">미리보기</button>
    <button type="button" v-show="value != '' && value != null" class="btn btn-default" @click="onDownloadClick">다운로드</button>
    <button type="button" v-if="!readonly" class="btn btn-default" @click="onChangeClick">{{value === '' || value === null?"등록":"수정"}}</button>
    <button type="button" v-if="!readonly" v-show="value != '' && value != null" class="btn btn-default" @click="onRemove">삭제</button>
    <input type="file" ref="fileInput" style="display:none;" accept="image/jpeg, image/png" @change="onUploadFiles" />
</div>
</template>

<script>
import {
    imgtype,
    rescode
} from "../lib/consts";
import {
    adminapi
} from "../lib/api";
import {
    getfilename,
    reqPost
} from "../lib/request";
// import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import {
    ContainerClient
} from '@azure/storage-blob';
import axios from 'axios'
import Compressor from "compressorjs";

export default {
    model: {
        prop: 'value',
    },
    props: {
        value: {
            type: String,
            required: true,
            default: "",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        downlabel: {
            type: String,
            default: "",
        },
        imgtype: {
            type: String,
            default: imgtype.ETC,
        },
        change: {
            type: Function,
            default: () => {},
        },
        resizeWidth: {
            type: Number,
            default: 600
        }
    },
    data() {
        return {
            fileInput: null,
            windowRef: null,
            imgwidth: 0,
            imgheight: 0,
        };
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {},
    methods: {
        onChangeClick() {
            this.$refs.fileInput.click();
        },
        compressImage(imageFile) {
            let qualityVal = 0,
                mw = 0;
            if (imageFile.size >= 90000) {
                qualityVal = 0.5;
                mw = 0;
            } else {
                qualityVal = 0.9;
                mw = 600;
            }
            return new Promise((resolve, reject) => {
                let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

                if (this.resizeWidth === 0) {
                    resolve(imageFile);
                } else if (!allowedExtensions.exec(imageFile.name)) {
                    resolve(imageFile);
                } else {
                    new Compressor(imageFile, {
                        maxWidth: mw,
                        quality: qualityVal,
                        success(result) {
                            resolve(result)
                        },
                        error(e) {
                            reject(e)
                        },
                    });
                }
            });
        },
        onUploadFiles(event) {
            let imageFile = event.target.files[0];

            this.compressImage(imageFile).then((compressedFile) => {
                let reader = new FileReader();
                reader.onload = evt => {
                    let img = new Image();
                    img.onload = () => {
                        this.imgwidth = img.width;
                        this.imgheight = img.height;
                    }
                    img.src = evt.target.result;
                }
                reader.onerror = evt => {
                    console.error(evt);
                }
                reader.readAsDataURL(compressedFile);

                if (this.value === '') {
                    this.onRegImage(compressedFile);
                } else {
                    this.onChange(compressedFile);
                }
            }).catch((error) => {
                alert("이미지 리사이징 에러입니다.");
            });
        },
        onRegImage(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            const containerClient = new ContainerClient(result.data.url);
                            var d = new Date();
                            var n = d.getTime();
                            let filename = n + '_' + imageFile.name;
                            // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(uploadedUrl);
                            });
                        }
                    }
                }
            );
        },
        onChange(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            // this.container = result.data.container;
                            // this.expiresIn = result.data.expiresIn;
                            const containerClient = new ContainerClient(result.data.url);
                            // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
                            var d = new Date();
                            var n = d.getTime();
                            let filename = n + '_' + imageFile.name;
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                                // onProgress: ev => console.log(ev)
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(uploadedUrl);
                            });
                        }
                    }
                }
            );
        },
        onRemove() {
            this.$emit('input', "");
        },
        onPreviewClick() {
            this.windowRef = window.open(this.value, "", "width=600,height=400,left=200,top=200");
            this.windowRef.addEventListener('beforeunload', this.closePortal);
        },
        closePortal() {
            if (this.windowRef) {
                this.windowRef.close();
                this.windowRef = null;
            }
        },
        onDownloadClick() {
            axios.get(this.value, {
                    responseType: 'blob'
                })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf'
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    if (this.downlabel == "") {
                        link.download = getfilename(this.value);
                    } else {
                        link.download = this.downlabel;
                    }
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
    },
};
</script>

<style scoped>
button {
    margin: 0px 4px;
}

.input-group {
    display: inline-flex;
}
</style>
