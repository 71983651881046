export default {
  token: null,
  expiresIn : 0,
  expiresAt : 0,
  refresh : null,
  refreshexpiresIn : 0,
  refreshexpiresAt: 0,
  userid:null,
  userRole:null,
  menu_lv1:null,
  menu_lv2:null,
  curpath:null,
  pagetitle:"",
  disabledLayout:false
}
