<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items: center;">
          <div class="checkbox" style="margin-right:10px;">
            <label>
              <input type="checkbox" :value="true" v-model="orderStatus.all" @change="onChangeAll($event)"/>전체
            </label>
            <label>
              <input type="checkbox" :value="true" v-model="orderStatus.purchased" @change="onChangePurchased($event)"/>결제완료
            </label>
            <label>
              <input type="checkbox" :value="true" v-model="orderStatus.cancelRequested"
                     @change="onChangeCancelRequested($event)"/>취소요청
            </label>
            <label>
              <input type="checkbox" :value="true" v-model="orderStatus.cancelled" @change="onChangeCancelled($event)"/>취소완료
            </label>
          </div>
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="refreshPage">
            <option value="NoKeyword"></option>
            <option value="OrderNo">주문번호</option>
            <option value="ItemNo">쿠폰번호</option>
            <option value="BuyerPhoneNumber">구매자 전화번호</option>
            <option value="ReceiverPhoneNumber">수신자 전화번호</option>
            <option value="BuyerPhoneNumber4">구매자 전화번호 끝 4자리</option>
            <option value="ReceiverPhoneNumber4">수신자 전화번호 끝 4자리</option>
            <option value="Username">사용자 ID</option>
            <option value="BuyerName">구매자 이름</option>
            <option value="ReceiverName">수신자 이름</option>
            <option value="B2bOrderNo">B2B주문번호</option>
            <option value="B2bItemNo">B2B발권번호</option>
            <option value="Channel">주문경로</option>
            <option value="GoodsNameContaining">상품명 또는 브랜드/매장명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" style="flex:1" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">주문번호</th>
            <th class="text-center">상품유형</th>
            <th>상품명 또는 브랜드/매장명</th>
            <th class="text-center">판매가</th>
            <th class="text-center">리워드</th>
            <th class="text-center">할인쿠폰</th>
            <th class="text-center">구매수량</th>
            <th class="text-center">구매자</th>
            <th class="text-center">B2B 주문번호</th>
            <th class="text-center">주문상태</th>
            <th class="text-center">주문일</th>
            <th class="text-center">취소일</th>
            <th class="text-center">주문경로</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(order, index) in content" v-bind:key="order.orderNo">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{ order.orderNo }}</td>
            <td class="text-center">{{ goodsTypeVal(order.goodsType) }}</td>
            <td class="linkcmp" @click="goDetailViewPage(order.orderNo)">{{ order.goodsName }}</td>
            <!-- <td class="text-center">{{currencydata(order.salePrice)}}</td> -->
            <td class="text-center">{{ order.salePrice | numFormat }}</td>
            <td class="text-center">{{ order.userPointPayment | numFormat }}</td>
            <td class="text-center">{{ order.discountAmount | numFormat }}</td>
            <td class="text-center">{{ order.itemCount | numFormat }}</td>
            <td class="text-center">{{ order.buyerName }}</td>
            <td class="text-center">{{ order.b2bOrderNo }}</td>
            <td class="text-center">{{ getOrdState(order.orderStatus) }}</td>
            <td class="text-center">{{ viewDate(order.purchaseDate) }}</td>
            <td class="linkcmp" @click="getMemo(order.orderNo)">{{ viewDate(order.cancelledDate) }}</td>
            <td class="text-center">{{ order.channelName }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="useMemo && {'display' : 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">메모
            <div class="modal-body form-horizontal">
              <div class="form-group" style="margin:0px;">

                <textarea class="form-control" rows="6" readonly>{{memo}}</textarea>
                <div class="modal-footer">
                  <div class="form-group text-center">
                    <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeUseMemo">닫기</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {reqPost} from "../../lib/request";
import {orderapi} from "../../lib/api";
import {DateFormat, getGoodsTypeStr} from "../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";
import numeral from 'numeral';

export default {
  components: {
    Pagination,
    DetePicker,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.ORDO_keywordType) {
        this.keywordType = localStorage.ORDO_keywordType;
      }
      if(localStorage.ORDO_keyword) {
        this.keyword = localStorage.ORDO_keyword;
      }
      if(localStorage.ORDO_Number) {
        this.pageNumber = parseInt(localStorage.ORDO_Number);
      }
      if(localStorage.ORDO_Size) {
        this.pageSize = parseInt(localStorage.ORDO_Size);
      }
      if(localStorage.ORDO_dateFrom) {
        this.dateFrom = localStorage.ORDO_dateFrom;
      }
      if(localStorage.ORDO_dateTo) {
        this.dateTo = localStorage.ORDO_dateTo;
      }
      if(localStorage.ORDO_all) {
        this.orderStatus.all = localStorage.ORDO_all == "all";
      }
      if(localStorage.ORDO_purchased) {
        this.orderStatus.purchased = localStorage.ORDO_purchased == "purchased";
      }
      if(localStorage.ORDO_cancelRequested) {
        this.orderStatus.cancelRequested = localStorage.ORDO_cancelRequested == "cancelRequested";
      }
      if(localStorage.ORDO_cancelled) {
        this.orderStatus.cancelled = localStorage.ORDO_cancelled == "cancelled";
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_ORD, path2: menuval.LV1_HOME, title: "주문내역조회"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.ORDO_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.ORDO_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.ORDO_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.ORDO_Size = newsize;
    },
    dateFrom(newfrom) {
      localStorage.ORDO_dateFrom = newfrom;
    },
    dateTo(newto) {
      localStorage.ORDO_dateTo = newto;
    },
    orderStatus(newstatu) {
      if(newstatu.all) {
        localStorage.ORDO_all = "all";
      } else {
        localStorage.ORDO_all = "";
      }

      if(newstatu.purchased) {
        localStorage.ORDO_purchased = "purchased";
      } else {
        localStorage.ORDO_purchased = "";
      }

      if(newstatu.cancelRequested) {
        localStorage.ORDO_cancelRequested = "cancelRequested";
      } else {
        localStorage.ORDO_cancelRequested = "";
      }

      if(newstatu.cancelled) {
        localStorage.ORDO_cancelled = "cancelled";
      } else {
        localStorage.ORDO_cancelled = "";
      }
    }
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      dateFrom: moment().format("YYYY-MM-DD"),//moment().format("YYYY-01-01"),
      dateTo: moment().format("YYYY-MM-DD"),
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * orderNo                          String      주문 번호
       * b2bOrderNo                       String      B2B 주문 번호
       * username                         String      사용자 ID
       * buyerName                        String      구매자 이름
       * buyerPhone                       String      구매자 전화번호
       * purchaseDate                     String      UTC yyyy-MM-ddTHH:mm:ss   구매일자
       * cancelledDate                    String      UTC yyyy-MM-ddTHH:mm:ss   구매취소일자
       * orderStatus                      String      주문상태
       * siteName                         String      주문 사이트명
       * channelName                      String      B2B 채널 주문 경로
       * goodsType                        String      상품 타입[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
       * goodsName                        String      상품명
       * itemCount                        Number      구매 아이템 개수
       * listPrice                        String      정상가
       * salePrice                        String      판매가
       * discountAmount                   String      할인받은 금액
       * paymentTotal                     String      결제금액
       * userCashPayment                  String      사용자 캐시로 결제한 금액
       * userPointPayment                 String      사용자 포인트로 결제한 금액
       * agentPayment                     String      신용카드 등 결제수단을 통해 결제한 금액
       * virtualAccountPayment            Boolean     가상계좌 결제인 경우 true
       * currencyCode                     String      [USD, KRW]통화코드
       * memo                             String      운영자 메모
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      useMemo: false,
      memo: "",
      orderStatus: {
        all: true,//전체선택(다른 주문 상태 값에 상관없이 모든 상태의 주문이 검색됨)
        purchased: false,//결제완료
        cancelRequested: false,//취소요청
        cancelled: false,//취소완료
      },
      exceldata: [],
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.ORDO_keywordType = this.keywordType;
      localStorage.ORDO_keyword = this.keyword;
      localStorage.ORDO_Number = this.pageNumber;
      localStorage.ORDO_Size = this.pageSize;
      localStorage.ORDO_dateFrom = this.dateFrom;
      localStorage.ORDO_dateTo = this.dateTo;
      localStorage.ORDO_all = this.orderStatus.all ? "all" : "";
      localStorage.ORDO_purchased = this.orderStatus.purchased ? "purchased" : "";
      localStorage.ORDO_cancelRequested = this.orderStatus.cancelRequested ? "cancelRequested" : "";
      localStorage.ORDO_cancelled = this.orderStatus.cancelled ? "cancelled" : "";
    } else {
      console.log(localStorage.initval);
      this.orderStatus.all = localStorage.ORDO_all == "all";
    }
    this.refreshPage();
  },
  methods: {
    currencydata(num) {
      return numeral(num).format('$0,0.00');
    },
    numformat(num) {
      return numeral(num).format('0,0');
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var reqdata = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        keywordType: this.keywordType,
        keyword: this.keyword,
        orderStatus: this.orderStatus,
        pageNumber: pageNumber,
        pageSize: this.pageSize
      };
      reqPost(
        orderapi.base,
        orderapi.orderspage,
        this.authToken,
        reqdata,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              console.log(this.content)
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수

            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    }
    ,
    goDetailViewPage(orderNo) {
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
    }
    ,
    goodsTypeVal(val) {
      return getGoodsTypeStr(val);
    }
    ,
    dataconvert(order, idx) {
      let salesType = '';
      if (order.channelType === 'Tablenjoy' && order.siteName !== 'tablenjoy'){
        salesType = '자체판매';
      } else{
        salesType = '';
      }

      return {
        A: idx + 1,
        B: order.orderNo,
        C: this.goodsTypeVal(order.goodsType),
        D: order.goodsName,
        // E: this.currencydata(order.salePrice),
        Z: order.brandName,
        E: (order.salePrice == null || order.salePrice == "") ? 0 : parseInt(order.salePrice),
        F: !isNaN(parseInt(order.userPointPayment)) ? parseInt(order.userPointPayment) : 0,
        Y: !isNaN(parseInt(order.pointTicket)) ? parseInt(order.pointTicket) : 0,
        X: order.discountAmount,
        G: parseInt(order.itemCount),
        H: order.buyerName,
        W: order.buyerPhone,
        I: order.b2bOrderNo,
        J: this.getOrdState(order.orderStatus),
        K: this.viewDate(order.purchaseDate),
        L: this.viewDate(order.cancelledDate),
        M: order.channelName,
        N: order.paymentAgentCode,
        V: salesType,
      };
    }
    ,
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "주문번호",
            C: "상품유형",
            D: "상품명 또는 브랜드/매장명",
            Z: "대표브랜드",
            E: "판매가",
            F: "리워드",
            Y: "포인트권",
            X: "할인쿠폰",
            G: "구매수량",
            H: "구매자",
            W: "구매자번호",
            I: "B2B 주문번호",
            J: "주문상태",
            K: "주문일",
            L: "취소일",
            M: "주문경로",
            N: "결제수단",
            V: '자체판매',
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "주문내역조회");
      // export Excel file
      XLSX.writeFile(wb, "주문내역조회.xlsx");
      this.buttonDissabled = false
    }
    ,
    getExcelData() {
      this.buttonDissabled = true;
      reqPost(
        orderapi.base,
        orderapi.orderslist,
        this.authToken,
        {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          orderStatus: this.orderStatus
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();

            } else {
              this.exceldata = [];
              alert(result.message);
              this.buttonDissabled = false;
            }
          }
        }
      );
    }
    ,
    getOrdState(val) {
      if(val == "Purchased") {
        return "결제완료";
      } else if(val == "CancelRequested") {
        return "취소요청";
      } else if(val == "CancelFailed") {
        return "취소실패";
      } else if(val == "Cancelled") {
        return "취소완료";
      }
    },
    closeUseMemo(){
      this.useMemo = false;
    },
    getMemo(orderNo){
      reqPost(
        orderapi.base,
        orderapi.admorderDetails,
        this.authToken,
        {
          orderNo: orderNo,
        },
        (result) => {
          if(result != null){
            if(result.code == rescode.REQ_SUCCESS){
              console.log(result.data.orderDetails.memo);
              this.memo = result.data.orderDetails.memo;

              this.useMemo = true;
            }
          }
        }
      )
    }
    ,
    onChangeAll(event) {
      if(event.target.checked) {
        localStorage.ORDO_all = "all";
      } else {
        localStorage.ORDO_all = "";
      }
    }
    ,
    onChangePurchased(event) {
      if(event.target.checked) {
        localStorage.ORDO_purchased = "purchased";
      } else {
        localStorage.ORDO_purchased = "";
      }
    }
    ,
    onChangeCancelRequested(event) {
      if(event.target.checked) {
        localStorage.ORDO_cancelRequested = "cancelRequested";
      } else {
        localStorage.ORDO_cancelRequested = "";
      }
    }
    ,
    onChangeCancelled(event) {
      if(event.target.checked) {
        localStorage.ORDO_cancelled = "cancelled";
      } else {
        localStorage.ORDO_cancelled = "";
      }
    }
    ,
  },
};
</script>
<style scoped>
.checkbox {
  margin-bottom: 0px;
  margin-top: 0px;
}

.checkbox > label {
  margin-right: 10px;
}
</style>
