<template>
<div>
    <section class="content">
        <div class="box box-solid">
            <div class="box-body">
                <div class="row" style="margin:0px;margin-bottom:20px">
                    <select class="form-control pull-right" style="width:70px" v-model="pageSize" @change="refreshPage">
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                        <option>35</option>
                        <option>40</option>
                        <option>45</option>
                    </select>
                </div>

                <div class="box box-solid">
                    <div class="box-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>쿠폰코드</th>
                                    <th>쿠폰명</th>
                                    <th>요청자</th>
                                    <th>요청일</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(copun, index) in content" :key="index">
                                    <td>{{ startnum + 1 + index }}</td>
                                    <td>{{copun.couponRegisterId}}</td>
                                    <td class="linkcmp" @click="goDetailPage(copun.couponRegisterId)"> {{ copun.couponName }} </td>
                                    <td> {{ copun.requestedBy }} </td>
                                    <td>{{viewDate(copun.requestedDate)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="box-footer text-center">
                        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
                    </div>
                </div>

            </div>
        </div>


    </section>
</div>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import {
    DateFormat
} from "../../lib/utility";
import routepath from "../../lib/routes";
import ToggleBtn from "../../components/ToggleBtn";
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker";
import XLSX from "xlsx";
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    brandapi,
    goodsapi,
    discoutCopon,
} from "../../lib/api";

export default {
    components: {
        ToggleBtn,
        Pagination,
        DatePicker
    },
    data() {
        return {
            start: '',
            end: '',
            shownum: 10, //pagination에 보여지는 번호개수
            keywordType: "NoKeyword",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [{
                copunCode: 'B358029147',
                type: '벌크형',
                name: '우주패스 발급쿠폰',
                requester: '강지영',
                registeredDate: '2020-05-08',

            }],
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            showmodal: false,
        }
    },
    computed: {
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_DIS,
            path2: menuval.LV1_HOME,
            title: "할인 쿠폰 승인",
        });
    },
    mounted(){
        this.refreshPage(false);
    },
    methods: {
        changeSelect(e) {
            console.log(e.srcElement.value === 'couponNum');
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage();
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqGet(
                discoutCopon.base,
                discoutCopon.newcopun_request +
                "?pageNumber=" +
                pageNumber +
                "&pageSize=" +
                this.pageSize,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.content = data.data.content;
                            this.totalPages = data.data.totalPages; //전체 페이지 수
                            this.totalElements = data.data.totalElements; //전체 아이템 수
                            // this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            // this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        // this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goDetailPage(id) {
            this.$router.push(this.routes.discountAddPage + "?id=" + id + '&approval=false');
        },
    }
}
</script>

<style scoped>
th {
    background-color: #dee2e685;
    text-align: center;
}

td {
    text-align: center;
}
</style>
