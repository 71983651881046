<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
          <div class="radio" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="All" v-model="useFlagType"/>전체
            </label>
            <label>
              <input type="radio" value="Activation" v-model="useFlagType"/>활성
            </label>
            <label>
              <input type="radio" value="Disabled" v-model="useFlagType"/>비활성
            </label>
          </div>
          <div class="input-group" style="flex:1;max-width:400px;min-width:200px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" style="margin-right:10px" @click="goDetailPage(0)">
        <i class="fa fa-plus"></i>상품추가
      </button>
      <button class="btn btn-default" style="margin-right:10px" @click="showRmvDlg">
        <i class="fa fa-fw fa-remove"></i>상품삭제
      </button>
      <button class="btn btn-default" @click="saveOrder()">
        순서 변경
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>
                <input type="checkbox" class="allch" @change="allckech($event)"/>
              </th>
              <th class="text-center">번호</th>
              <th class="text-center">기획전 코드</th>
              <th class="text-center">기획전명</th>
              <th class="text-center">상태</th>
              <th class="text-center">등록일</th>
              <th class="text-center">노출순서</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(hibition, hidx) in content" v-bind:key="hibition.exhibitionId">
              <th>
                <input type="checkbox" class="chitem" :value="hibition.exhibitionId"/>
              </th>
              <td class="text-center">{{ totalElements - (startnum + hidx) }}</td>
              <td class="text-center">{{hibition.exhibitionId}}</td>
              <td class="linkcmp text-center" @click="goDetailPage(hibition.exhibitionId)">{{hibition.exhibitionName}}</td>
              <td class="linkcmp text-center" @click="onChangeuseFlag(hibition.exhibitionId, hibition.useFlag)"><ToggleBtn v-model="hibition.useFlag" /></td>
              <td class="text-center">{{hibition.regDate}}</td>
              <td class="text-center">
                <input type="text"  v-model="hibition.order" >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="removedlg && { display: 'block' }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">상품삭제</div>
          <div class="modal-body form-horizontal">
            <div class="form-group text-center">
              삭제된 정보는 복구할 수 없습니다. 삭제하시겠습니까?
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeRmvDlg">
                닫기
              </button>
              <button type="button" class="btn btn-default" @click="onHibitiondelete">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import ToggleBtn from "../../components/ToggleBtn";
import { reqPost } from "../../lib/request";
import { display } from "../../lib/api";
import { DateFormat } from "../../lib/utility";
import { Drag, Drop } from "vue-drag-drop";
import $ from 'jquery';
export default {
  components: {
    Pagination,
    ToggleBtn,
    Drag,
    Drop,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.STDSP_keyword) {
        this.keyword = localStorage.STDSP_keyword;
      }
      if (localStorage.STDSP_Number) {
        this.pageNumber = parseInt(localStorage.STDSP_Number);
      }
      if (localStorage.STDSP_Size) {
        this.pageSize = parseInt(localStorage.STDSP_Size);
      }
      if (localStorage.STDSP_useFlagType) {
        this.useFlagType = localStorage.STDSP_useFlagType;
      }
    }
    this.$store.dispatch("doSetPath", { path1: menuval.LV1_DSP, path2: menuval.LV1_HOME, title: "기획전"});
  },
  watch: {
    keyword(newkey)
    {
      localStorage.STDSP_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.STDSP_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.STDSP_Size = newsize;
    },
    useFlagType(newflg)
    {
      localStorage.STDSP_useFlagType = newflg;
    },
  },
  data() {
    return {
      removedlg:false,
      shownum: 10, //pagination에 보여지는 번호개수
      useFlagType:"All",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * exhibitionId             Number    기획전 ID
       * exhibitionName           String    기획전 명
       * useFlag                  Boolean   활성화여부
       * regDate                  String    등록일
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.STDSP_keyword = this.keyword;
      localStorage.STDSP_Number = this.pageNumber;
      localStorage.STDSP_Size = this.pageSize;
      localStorage.STDSP_useFlagType = this.useFlagType;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    closeRmvDlg()
    {
      this.removedlg = false;
    },
    showRmvDlg()
    {
      var exhibitionIds = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      if(exhibitionIds.length == 0)
      {
        alert("상품을 선택하세요.");
        return ;
      }
      this.removedlg = true;
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    onChangeOrder(idx) {
      this.content[idx].order = 
      console.log(this.content[idx].order)
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqPost(
        display.base,
        display.exhibition_list,
        this.authToken,
        {
          useFlagType:this.useFlagType,
          keyword:this.keyword,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goDetailPage(exhibitionId) {
      this.$router.push(this.routes.detaildsp+"?exhibitionId="+exhibitionId);
    },
    onHibitiondelete()
    {
      var exhibitionIds = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      reqPost(
        display.base,
        display.exhibition_delete,
        this.authToken,
        {
          exhibitionIds:exhibitionIds,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              $('input:checkbox.chitem').prop('checked', false);
              $('input:checkbox.allch').prop('checked', false);
              this.closeRmvDlg();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onChangeuseFlag(exhibitionId, useFlag)
    {
      reqPost(
        display.base,
        display.exhibition_status,
        this.authToken,
        {
          exhibitionId:exhibitionId,
          useFlag:!useFlag,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    saveOrder()
    {
      var orders = this.content.map((item, index) => {return {exhibitionId:item.exhibitionId, order:item.order}});
      reqPost(
        display.base,
        display.exhibition_order,
        this.authToken,
        {
          orders: orders,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.radio > label {
  margin-right: 10px;
}

</style>
