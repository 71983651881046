<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">매장기본정보</h3>
    </div>
    <div v-if="basicInfo != null" class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">브랜드/호텔명</label>
        <div class="col-sm-10">
          <label class="control-label">{{
              brandName
            }}</label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">매장명</label>
        <div class="col-sm-10" style="display: inline-flex;flex-wrap: wrap;">
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px;">
            <input type="text" class="form-control" v-model="basicInfo.storeName"/>
            <span class="input-group-btn">
                              <button class="btn btn-default" type="button" @click="checkStoreName">중복확인</button>
                          </span>
          </div>
          <label :class="['control-label', storecheckclass]" style="margin-left:10px">{{
              storecheckmsg
            }}</label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">업종</label>
        <div class="col-sm-10">
          <div class="input-group" style="width:100%">
            <select class="form-control" style="width:100%;max-width:400px;min-width:100px;"
                    @change="storeTypeNmChange" v-model="storeTypeNm">
              <option v-for="(item,idx) in storeType" :key="idx" :value="item.storeType">{{
                  item.typeName
                }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">사업자등록번호</label>
        <div class="col-sm-10" style="display:inline-flex;flex-wrap: wrap;">
          <div class="input-group"
               style="display:inline-flex;margin-right:10px;width:100%;max-width:400px;min-width:100px;">
            <input type="text" class="form-control" v-model="basicInfo.registrationNumber"/>
          </div>
          <div style="flex:1">
            <SingleImgUpload v-model="basicInfo.registrationNumberUrl" downlabel="" :imgtype="this.imgtype.STORE"/>
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
          <label class="col-sm-2 control-label">매장홈페이지</label>
          <div class="col-sm-10">
              <input type="text" class="form-control" style="width:100%;max-width:400px;min-width:100px;" v-model="basicInfo.homePageUrl" />
          </div>
      </div> -->
      <div class="form-group">
        <label class="col-sm-2 control-label">주소</label>
        <div class="col-sm-10" style="display:flex;">
          <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
            <input type="text" class="form-control" disabled :value="address.roadAddress"/>
          </div>
          <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
            <input type="text" class="form-control" v-model="address.detailAddress"/>
          </div>
          <AddressSearch style="display:inline-flex" :detailAddress="address.detailAddress" :onChange="onSetAddress"
                         @detailaddrEvechiild="detailaddrEve"/>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">대표전화</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" style="width:100%;max-width:400px;min-width:100px;"
                 v-model="basicInfo.phoneNumber"/>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">좌석수</label>
        <div class="col-sm-10">
                     <span style="display: inline-block; margin-right:5px;">
                         <input type="text" class="form-control" style="max-width:100px;min-width:100px;"
                                @input="seatNumberCheck($event)" v-model="basicInfo.seat" min="0"/>
                     </span>
          <span>석</span>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">개업일</label>
        <div class="col-sm-10">
          <div style="display: inline-flex; flex-wrap: wrap; width: 100%;">
            <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
              <!-- {{
basicInfo.openDate
}} -->
              <input type="text" class="form-control" style="width:auto" readonly :value="basicInfo.openDate"
                     @click="onShowMonthPicker"/>
              <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear"
                            :input-pre-filled="true" @input="selMonth"></month-picker>
            </div>
          </div>
          <!-- <input type="text" class="form-control" style="width:100%;max-width:400px;min-width:100px;" v-model="basicInfo.openDate" /> -->
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">지역 카테고리</label>
        <div class="col-sm-10">
          <div class="input-group" style="display:inline-flex">
            <select class="form-control" style="width: auto;margin-right:10px" v-model="address.mainAreaNum"
                    @change="onselmareanum($event)">
              <option v-for="(mitem, midx) in mainAreas" :key="midx" :value="mitem.areaNum">{{
                  mitem.areaName
                }}
              </option>
            </select>
            <select v-if="selmareainf != null" class="form-control" style="width: auto;margin-right:10px"
                    v-model="address.middleAreaNum" @change="onselmdlnum($event)">
              <option v-for="(mdlitem, mdlidx) in selmareainf.middleAreas" :key="mdlidx" :value="mdlitem.areaNum">{{
                  mdlitem.areaName
                }}
              </option>
            </select>
            <!-- {{
selmareainf
}} -->
            <select v-if="smallaerainf != null" class="form-control" style="width: auto;"
                    v-model="address.smallAreaNum" @change="onselsmlnum($event)">
              <!-- <option v-for="()"></option> -->
              <option v-for="(smlitem, mdlidx) in smallaerainf.smallAreas" :key="mdlidx" :value="smlitem.areaNum">{{
                  smlitem.areaName
                }}
              </option>
            </select>
            <label class="margin-l-10 mt-2" v-if="(this.address.mainExhibition != null && this.address.mainExhibition != '') && (this.address.smallAreaName!=null && this.address.smallAreaName!='')">
              <span>※ 전시 지역 ({{this.address.mainExhibition}} > {{this.address.detailExhibition}})</span>
            </label>
            <label class="margin-l-10 mt-2" v-if="!this.address.mainExhibition && (this.address.smallAreaName!=null && this.address.smallAreaName!='')">
              <span class="text-red">※ 개발팀에 전시 지역 매칭 요청</span>
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">음식 카테고리 <br>(관리자 전용 기능)</label>
        <div class="col-sm-10">
             <span class="margin-r-10" style="display: inline-block;" v-for="(item,idx) in foodCategoryList" v-bind:key="idx">
               <input type="checkbox" class="chitem" :id="`item${item.id}`" :value="item.id" v-model="foodCategory" /> <label :for="`item${item.id}`">{{item.foodName}}</label>
             </span>
        </div>
      </div>
      <hr v-if="kakaoId">
      <!--      v-if="kakaoId"-->
      <div v-if="kakaoId">
        <div class="box-header">
          <h3 class="box-title">카카오톡 예약하기 장소 기본정보</h3>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">예약 카테고리</label>
          <div class="col-sm-10">
            <div class="input-group d-flex" v-for="idx in categoryLength+1" style="margin-bottom: 4px">
              <select class="form-control mr-5" v-model="dep1Selected[idx-1]" @change="dep1Change(dep1Selected[idx-1],idx-1 )" style="width: 30%" >
                <option v-for="(dp1Cate, dp1Idx) in kakaoCategoryList" :value="dp1Cate">
                  {{dp1Cate.categoryName}}
                </option>
              </select>
              <select class="form-control mr-5" v-model="selectedCategoryList[idx-1]" style="width: 30%">
                <option v-for="(dp2Cate, dp2Idx) in dep2ArrList[idx-1]" :value="dp2Cate.categoryCode">
                  {{dp2Cate.categoryName}}
                </option>
              </select>
              <button class="btn btn-default" v-if="categoryLength == 1" @click="removeReserveCategory(idx-1)" > 삭제</button>
              <button class="btn btn-default" v-if="categoryLength == 0" @click="addReserveCategory" ><i class="fa fa-plus"></i> 추가</button>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">장소명</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="placeName" style="max-width:400px;min-width:400px;"/>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">카카오맵 장소 아이디</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="placeId" style="max-width:200px;min-width:200px;"/>
            <span class="text-example">1. Kakao Map 접속 (<a href="https://map.kakao.com" target="_blank"
                                                           style="color: #007bff">https://map.kakao.com</a>)<br>
                                              2. 매장 장소 검색<br>
                                              3. 좌측 검색결과에서 해당 장소에 “상세보기” 클릭<br>
                                              4. 연결된 URL에 기재된 장소아이디 확인하여 입력 (https://place.map.kakao.com/{장소아이디})<br>
                                              ex) https://place.map.kakao.com/14559670 > 카카오맵 장소 아이디에 “14559670” 입력<br>
            </span>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="saveBasicinf">저장</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {imgtype, rescode} from "@/lib/consts";
import {global_daumapi} from "@/lib/daumapi";
import DatePicker from "@/components/DatePicker";
import SingleImgUpload from "@/components/SingleImgUpload";
import AddressSearch from "@/components/AddressSearch";
import {
  MonthPicker
} from 'vue-month-picker';
import moment from "moment/moment";

export default {
  name:'storeRegInfo',
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.event)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.event)
      }
    }
  },
  components:{
    DatePicker,
    MonthPicker,
    SingleImgUpload,
    AddressSearch,
  },
  props:{
    kakaoId:Number
  },
  computed:{
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    },
  },
  created() {
    this.storeCode = this.$route.query.stcode;
    this.brandName = this.$route.query.brName;
  },
  mounted() {
    this.getKakaoCategoryList();
    this.getFoodCategoryList();
    this.getmainAreas();
  },
  data(){
    return{
      defmonth: moment().month() + 1,
      defyear: moment().year(),
      brandName: '',
      monthpicker: false,
      storeCode: '',
      basicInfo: null,
      storeType: [],
      storeTypeNm: {},
      /*
       storeName              String    매장명
       storeType              String    매장 타입
                       [Buffet, Western, Korean, Chinese, Japanese, Fusion, Etc]
       registrationNumber     String    사업자등록번호
       registrationNumberUrl  String    사업자등록번호 url
       phoneNumber            String    전화번호
       homePageUrl            String    홈페이지 URL      */
      address: {
        coordinateSystem: "",
        coordinateX: 0,
        coordinateY: 0,
        detailAddress: "",
        engAddress: "",
        jibunAddress: "",
        mainAreaName: "",
        mainAreaNum: 0,
        middleAreaName: "",
        middleAreaNum: 0,
        roadAddress: "",
        smallAreaName: "",
        smallAreaNum: 0,
        zipCode: "",
        mainExhibition:"",
        detailExhibition:"",
        region:"",
        city:"",
      },
      /*
       roadAddress              String    도로명 주소
       jibunAddress             String    지번 주소
       engAddress               String    영문 주소
       zipCode                  String    우편번호
       mainAreaNum              Number    (대분류) 지역번호
       mainAreaName             String    (대분류) 지역명
       middleAreaNum            Number    (중분류) 지역번호
       middleAreaName           String    (중분류) 지역명
       coordinateX              Number    x 좌표
       coordinateY              Number    y 좌표
       coordinateSystem         String    좌표계. EPSG5181: Daum Map, EPSG4326: Google Map
                               [EPSG5181, EPSG4326]
       */
      mainAreas: [],
      selmareainf: null,
      smallaerainf: null,
      storecheckmsg: "",
      storecheckclass: "",
      /* ------------alimtalkSettings-------------- */
      useOwnAlimtalk: false,
      alimtalkApiKey: "",
      usernameContaining: "",
      /**
       * username            String    관리자 ID
       * fullName            String    관리자 이름
       * phoneNumber         String    전화번호
       * email               String    이메일
       * brandCode           String    브랜드 코드 브랜드/매장 관리자일 경우 필수 입력됨.
       * storeCode           String    매장 코드 매장 관리자일 경우 필수 입력됨
       * userRole            String    [TEMP_USER, B2B_USER, USER, STORE_MANAGER, STORE_OWNER, BRAND_MANAGER, BRAND_ADMIN, AGENT, SALES, MANAGER, ADMIN, SUPER]  현 API 에서는 항상 AGENT 가 입력됨
       * approved            Boolean   등록신청 승인여부
       * requestedDate       String    UTC yyyy-MM-ddTHH:mm:ss   등록 요청일
       * approvedDate        String    UTC yyyy-MM-ddTHH:mm:ss   등록 승인일
       */
      sgoodkeytype: "NoKeyword",
      sgoodkeyval: "", //
      goodlist: [],
      /**
       * goodsId               Number    상품 ID
       * goodsName             String    상품명
       * onSale                Boolean   판매중/판매중지 여부
       * hasSalesPeriod        Boolean   판매기간이 지정되어 있는 경우 true  상시판매인 경우 false
       * saleStartDate         String    yyyy-MM-dd  판매시작일  (hasSalesPeriod이 false인 경우 null)
       * saleEndDate           String    yyyy-MM-dd  판매종료일  (hasSalesPeriod이 false인 경우 null)
       */
      excludedChannels: [],
      hcatunits: [],

      zeroVal: 0,
      foodCategoryList:[],
      foodCategory:[],
      addCategory : false,
      categoryLength: 0,
      kakaoCategoryList:[],
      dep1Selected:[
      ],
      dep2ArrList:[
        [{categoryCode:"2차 선택",
          categoryName:"2차 선택",
          categoryNum:0
        }]
      ],
      selectedCategoryList: [],
      placeName:"",
      placeId:"",
    }
  },
  methods:{
    getmainAreas() {
      reqGet(
        brandapi.base,
        brandapi.storeAreas,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.mainAreas = result.data.mainAreas;
            this.getbasicInfo();
          }
        }
      );
    },
    selMonth(date) {
      this.basicInfo.openDate = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
      // this.monthpicker = false;
    },
    getbasicInfo() {

      reqGet(
        brandapi.base,
        brandapi.tnjbasicInfo + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.basicInfo = result.data.basicInfo;
            if(result.data.kakaoInfo.placeId !=null) {
              this.placeName = result.data.kakaoInfo.placeName
              this.placeId = result.data.kakaoInfo.placeId
              // this.kakaoCategoryList setting

              for (let i=0 ; i < result.data.kakaoInfo.categoryList.length; i++){
                let tmp = result.data.kakaoInfo.categoryList[i]
                for(let j=0 ; j<this.kakaoCategoryList.length;j++){
                  for(let k=0 ; k<this.kakaoCategoryList[j].subCategoryList.length ; k++){
                    if (this.kakaoCategoryList[j].subCategoryList[k].categoryCode == tmp){
                      this.dep2ArrList.splice(i,0,this.kakaoCategoryList[j].subCategoryList)
                      this.dep1Selected.splice(i,0,this.kakaoCategoryList[j])
                      this.selectedCategoryList.splice(i,0,this.kakaoCategoryList[j].subCategoryList[k].categoryCode)
                    }
                  }
                }
              }
              console.log(this.selectedCategoryList)
              if(this.selectedCategoryList.length>1){
                this.categoryLength = 1
              }
            }
            this.$emit('get-kakao-place-id', this.placeId);

            this.getStoreType(result.data.basicInfo.storeType);
            // if(){

            // }
            // console.log(this.basicInfo);
            if (result.data.basicInfo.openDate !== null) {
              let op = result.data.basicInfo.openDate.split('T');
              this.basicInfo.openDate = op[0].substring(0, 7);
            }
            this.address = result.data.address;
            this.detailAddress = this.address.detailAddress;


            var fidx = this.mainAreas.findIndex(mitem => mitem.areaNum == this.address.mainAreaNum);
            this.selmareainf = this.mainAreas[fidx];

            var fidx = this.selmareainf.middleAreas.findIndex(mdlitem => mdlitem.areaNum == this.address.middleAreaNum);
            this.smallaerainf = this.selmareainf.middleAreas[fidx];
            this.foodCategory = result.data.foodCategorys.foodId


          }
        }
      );
    },
    getStoreType(type) {
      reqGet(
        brandapi.base,
        brandapi.storeType,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {

              this.storeType = result.data.types;
              this.storeType.forEach(el => {
                if (el.storeType === type) {
                  this.storeTypeNm = el.storeType;
                }
              });
            }
          }
        }
      );
    },

    storeTypeNmChange(e) {
      this.storeTypeNm = e.target.value;
    },
    onShowMonthPicker() {
      this.monthpicker = !this.monthpicker;
    },
    onselmdlnum(event) {
      var fidx = this.selmareainf.middleAreas.findIndex(mdlitem => mdlitem.areaNum == event.target.value);
      this.smallaerainf = this.selmareainf.middleAreas[fidx];
      this.address.middleAreaName = this.selmareainf.middleAreas[fidx].areaName;
      this.address.smallAreaName = '';
      this.address.smallAreaNum = '';
    },
    onselsmlnum(event) {
      var fidx = this.smallaerainf.smallAreas.findIndex(mdlitem => mdlitem.areaNum == event.target.value);
      this.address.smallAreaName = this.smallaerainf.smallAreas[fidx].areaName;
      this.address.mainExhibition = this.smallaerainf.smallAreas[fidx].exhibition.mainExhibition;
      this.address.detailExhibition = this.smallaerainf.smallAreas[fidx].exhibition.detailExhibition
    },
    checkStoreName() {
      reqGet(
        brandapi.base,
        brandapi.tnjstrexists + "?brandCode=" + this.brandCode + "&storeName=" + encodeURIComponent(this.basicInfo.storeName),
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            if (result.data == false) {
              this.storecheckmsg = "사용할 수 있는 매장명입니다.";
              this.storecheckclass = "blurmsg";
            } else {
              this.storecheckmsg = "사용할 수 없는 매장명입니다.";
              this.storecheckclass = "redmsg";
            }
          }
        }
      );
    },
    detailaddrEve(detailAddress) {
      this.address.detailAddress = detailAddress;
      console.log(this.address)
    },
    onSetAddress(addinfo, detailAddress) {
      this.address.roadAddress = addinfo.address;
      // this.address.jibunAddress = addinfo.autoJibunAddress;
      this.address.jibunAddress = addinfo.jibunAddress ? addinfo.jibunAddress : addinfo.autoJibunAddress;
      this.address.engAddress = addinfo.roadAddressEnglish;
      this.address.zipCode = addinfo.zonecode;
      this.address.detailAddress = detailAddress;
      this.address.region = addinfo.sido
      this.address.city = addinfo.sigungu
      // console.log(address.address)
      global_daumapi.serachAddress(addinfo.address) //jibunAddress
        .then((responseJson) => {
          this.address.coordinateY = responseJson.documents[0].y;
          this.address.coordinateX = responseJson.documents[0].x;
          this.address.coordinateSystem = "EPSG4326";
        }).catch((error) => {
        console.log("Searchaddress error : ", error);
      });
    },
    seatNumberCheck(eve) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if (reg.exec(eve.srcElement._value) !== null) {
        eve.srcElement._value = eve.srcElement._value.replace(/[^0-9]/g, '');
        this.basicInfo.seat.replace(/[^0-9]/g, '');
      }
      ;
      if (isNaN(parseFloat(eve.srcElement._value))) {
        eve.srcElement._value = ''
        this.basicInfo.seat = ''
      }
      ;
    },
    isSelect(checked, idx, val) {
      if (checked === true) {
        this.selectExcludes.push(val)
      }
    },

    hideMonthPicker() {
      this.monthpicker = false;
    },
    onselmareanum(event) {
      var fidx = this.mainAreas.findIndex(mitem => mitem.areaNum == event.target.value);
      this.selmareainf = this.mainAreas[fidx];
      this.address.mainAreaName = this.selmareainf.areaName;
      this.address.smallAreaNum = '';
      this.address.smallAreaName = '';
    },
    saveBasicinf() {
      let chkCati = this.selectedCategoryList.indexOf("2차 선택")
      this.basicInfo.storeType = this.storeTypeNm;

      this.basicInfo.seat = Number(this.basicInfo.seat)
      if (this.address.roadAddress === '') {
        alert('주소를 입력해주세요.');
        return;
      }
      if (this.basicInfo.seat === "" || this.basicInfo.seat === 0) {
        alert('좌석수를 입력해주세요.');
        return;
      }
      if (this.basicInfo.openDate === null) {
        alert('개업일을 입력해주세요.');
        return;
      }
      if(!this.address.smallAreaName){
        alert('지역 카테고리를 선택해주세요.');
        return;
      }
      if(!this.address.mainExhibition){
        alert('개발팀에 전시 지역 매칭 요청해주세요.');
        return;
      }
      if(this.foodCategory.length<=0){
        alert('음식 카테고리를 1개 이상 선택해주세요.');
        return;
      }else if(this.kakaoId && !this.placeName ){
        alert("장소명을 입력해주세요.")
        return false
      } else if(this.kakaoId && chkCati > -1 ){
        alert("예약 카테고리를 선택해주세요.")
        return false
      } else if(this.kakaoId && !this.placeId ){
        alert("카카오맵 장소 아이디를 입력해주세요.")
        return false
      }
      // let tmpData = {
      //   storeCode: this.storeCode,
      //   basicInfo: this.basicInfo,
      //   address: this.address,
      //   foodCategorys : {foodId : this.foodCategory}
      // }
      //
      // console.log(tmpData)
      // return;
      let kakaoInfo = {
        categoryList : this.selectedCategoryList,
        placeName: this.placeName,
        placeId: this.placeId
      }
      reqPost(
        brandapi.base,
        brandapi.basicInfoupdate,
        this.authToken, {
          storeCode: this.storeCode,
          basicInfo: this.basicInfo,
          address: this.address,
          foodCategorys : {foodId : this.foodCategory},
          kakaoInfo: kakaoInfo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.$emit('get-kakao-alert', 'STORE');
              alert("저장되었습니다.");
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getFoodCategoryList(){
      reqGet(
        brandapi.base,
        brandapi.getFoodCategory,
        this.authToken,
        (result) => {
          if (result != null) {
            this.foodCategoryList = result.data
          }
        }
      );
    },
    async getKakaoCategoryList(){
      // this.placeRegisterToggle = true
      // getKakaoStoreLocationInfo

      await reqGet(
        brandapi.base,
        brandapi.getKakaoCategoryList,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.kakaoCategoryList = result.data.mainCategoryList
            this.kakaoCategoryList.unshift({
              categoryName: "1차 선택",
              categoryNum: 0,
              subCategoryList:[{categoryCode:"2차 선택",
                categoryName:"2차 선택",
                categoryNum:0
              }]
            })
            this.dep1Selected[0] = {
              categoryName: "1차 선택",
              categoryNum: 0,
              subCategoryList:[{categoryCode:"2차 선택",
                categoryName:"2차 선택",
                categoryNum:0
              }]

            }
          }else {
            alert(result.message);
          }
        }
      );

    },
    addReserveCategory(){
      this.addCategory = true
      this.categoryLength = 1;
      this.dep1Selected[1] = {
        categoryName: "1차 선택",
        categoryNum: 0,
        subCategoryList:[ {categoryCode:"2차 선택",
          categoryName:"2차 선택",
          categoryNum:0
        }]
      }
      this.dep2ArrList.push(
        [{categoryCode:"2차 선택",
          categoryName:"2차 선택",
          categoryNum:0
        }])
      this.selectedCategoryList.push("2차 선택")
    },
    removeReserveCategory(val){
      this.selectedCategoryList.splice(val,1)
      this.dep1Selected.splice(val,1)
      this.dep2ArrList.splice(val,1)
      this.categoryLength = 0
      this.addCategory = false
    },
    dep1Change(val,idx){
      this.dep2ArrList[idx] = val.subCategoryList
    }



  }
}

</script>

<style>
.margin-r-10 {
  margin-right : 10px;
}
.margin-l-10 {
  margin-left : 10px;
}
.mt-2 {
  margin-top : 4px;
}
.text-example{
  color: #9a9a9a
}
.d-flex{
  display: flex;
}
</style>
