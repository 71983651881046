<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-3 control-label">제목 *</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="exhibitionName" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">전시기간 *</label>
                <div class="col-sm-9">
                    <div class="input-group" style="display: flex;flex-wrap: wrap;">
                        <DatePicker v-model="fromDate" :last="toDate" />
                        <span class="input-group-addon" style="border:none;">-</span>
                        <DatePicker v-model="toDate" :first="fromDate" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">적용 사이트 *</label>
                <div class="col-sm-9 checkbox">
                    <label> <input type="checkbox" :checked="getAllChecked()" @change="onClickAll($event)" />전체 </label>
                    <label> <input type="checkbox" value="PC" v-model="exhibitionChannels" />PC </label>
                    <label> <input type="checkbox" value="MOBILE" v-model="exhibitionChannels" />Mobile </label>
                    <label> <input type="checkbox" value="EZWEL" v-model="exhibitionChannels" />이지웰 </label>
                    <label> <input type="checkbox" value="BENEPIA" v-model="exhibitionChannels" />SK베네피아 </label>
                    <label> <input type="checkbox" value="HAPPY" v-model="exhibitionChannels" />해피머니 </label>
                    <label> <input type="checkbox" value="LIFECARE" v-model="exhibitionChannels" />LIFECARE </label>
                    <label> <input type="checkbox" value="CHALETKOREA" v-model="exhibitionChannels" />샬레코리아 </label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">기획전 배너 (PC) *</label>
                <div class="col-sm-9">
                    <SingleImgUpload v-model="imageUrl" :resizeWidth="0" downlabel="" :imgtype="imgtype.SITE" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">기획전 배너 (Mobile) *</label>
                <div class="col-sm-9">
                    <SingleImgUpload v-model="imageUrlMobile" :resizeWidth="0" downlabel="" :imgtype="imgtype.SITE" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">헤더 구분</label>
                <div class="col-sm-9 radio">
                    <label> <input type="radio" value="IMAGE" v-model="headType" />이미지 </label>
                    <label> <input type="radio" value="HTML" v-model="headType" />HTML </label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">헤더 상세 (PC)</label>
                <div class="col-sm-9" v-show="headType == 'IMAGE'">
                    <SingleImgUpload v-model="headImageUrl" :resizeWidth="0" downlabel="" :imgtype="imgtype.SITE" />
                </div>
                <input type="file" ref="fileInput" style="display:none;" accept="image/jpeg, image/png" @change="onUploadFiles" />
                <div class="col-sm-9" v-show="headType == 'HTML'">
                    <!-- <editor v-model="pcheadText" :options="editorOptions"/> -->
                    <editor api-key="02p3raa9urge93k9p07ljuvfbkovzy2wrad7dprwav8xe682" :init="{
                min_height: 500,
                max_height: 1000,
                menubar: false,
                plugins: 'autolink code link autoresize paste contextmenu image preview',
                toolbar:
                  'undo redo | fontselect fontsizeselect formatselect | forecolor | bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link custom_image | code preview',
                setup: function(editor){
                  editor.ui.registry.addButton('custom_image',{
                    icon: 'image',
                    tooltip: 'insert Image',
                    onAction: function() {
                     onChangeClick()

                    }
                  })
                }
              }" v-model="headHtml" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">헤더 상세 (Mobile)</label>
                <div class="col-sm-9" v-show="headType == 'IMAGE'">
                    <SingleImgUpload v-model="headImageUrlMobile" :resizeWidth="0" downlabel="" :imgtype="imgtype.SITE" />
                </div>
                <div class="col-sm-9" v-show="headType == 'HTML'">
                    <editor api-key="02p3raa9urge93k9p07ljuvfbkovzy2wrad7dprwav8xe682" :init="{
                min_height: 500,
                max_height: 1000,
                menubar: false,
                plugins: 'autolink code link autoresize paste contextmenu image preview',
                toolbar:
                  'undo redo | fontselect fontsizeselect formatselect | forecolor | bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link custom_image | code preview',
                setup: function(editor){
                  editor.ui.registry.addButton('custom_image',{
                    icon: 'image',
                    tooltip: 'insert Image',
                    onAction: function() {
                     onChangeClick()

                    }
                  })
                }
              }" v-model="headHtmlMobile" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">카테고리 기능</label>
                <div class="col-sm-9 radio">
                    <label> <input type="radio" :value="false" v-model="categoryUse" />없음 </label>
                    <label> <input type="radio" :value="true" v-model="categoryUse" />있음 </label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">카테고리</label>
                <div class="col-sm-9">
                    <button type="button" class="btn btn-default pull-right" @click="showCatagory(-1)">
                        등록
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label class="hidden-xs col-sm-3 control-label"></label>
                <div class="col-sm-9">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>카테고리명</th>
                                    <th>순서</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(categ, cidx) in categories" :key="cidx">
                                    <td class="linkcmp" @click="showCatagory(cidx)">{{categ.categoryName}}</td>
                                    <td>
                                        <drop @drop="handleCatagDrop({ index: cidx, type:'catag' }, $event)">
                                            <drag class="btn btn-default btn-social-icon" :transfer-data="{ index: cidx, type:'catag' }"><i class="fa fa-fw fa-arrows-v"></i>
                                            </drag>
                                        </drop>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-social-icon" @click="doRmvCatag(cidx)"><i class="fa fa-fw fa-trash-o"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">추천 기획전</label>
                <div class="col-sm-9">
                    <button type="button" class="btn btn-default pull-right" @click="showFindDSP">
                        등록
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label class="hidden-xs col-sm-3 control-label"></label>
                <div class="col-sm-9">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>기획전 코드</th>
                                    <th>기획전명</th>
                                    <th>노출상태</th>
                                    <th>순서</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(exitem, eidx) in exhibitionRecommends" :key="eidx">
                                    <td>{{exitem.exhibitionRecommend}}</td>
                                    <td>{{exitem.exhibitionName}}</td>
                                    <td v-if="exitem.exhibitionName">활성</td>
                                    <td v-if="!exitem.exhibitionName">비활성</td>
                                    <td>
                                        <drop @drop="handleExhiDrop({ index:eidx, example: 'exhibition' }, $event)">
                                            <drag class="btn btn-default btn-social-icon" :transfer-data="{ index:eidx, example: 'exhibition' }"><i class="fa fa-fw fa-arrows-v"></i>
                                            </drag>
                                        </drop>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-social-icon" @click="doRmvExhibition(eidx)">
                                            <i class="fa fa-fw fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">전시 지역</label>
                <div class="col-sm-9 radio">
                    <label> <input type="radio" :value="false" v-model="area" />전지역 </label>
                    <label> <input type="radio" :value="true" v-model="area" />지역설정 </label>
                </div>
            </div>
            <!-- 지역설정 컴포넌트 -->
            <Area v-if="area" @coordinateX="coordinateX" @coordinateY="coordinateY" @radius="radius" :coordinateYval="coordinateYval" :coordinateXval="coordinateXval" :radiusval="radiusval"></Area>
            <!-- 지역설정 컴포넌트 end -->
            <div class="form-group text-center">
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="goBack">취소</button>
                <button type="button" class="btn btn-default" @click="doRegister">등록</button>
            </div>
        </div>
    </div>
    <div class="modal" :style="catagorydlg && { display: 'block' }">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-body form-horizontal">
                    <div class="form-group">
                        <label class="col-sm-2 control-label">카테고리명</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="newcategories.categoryName" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2 control-label">구분</label>
                        <div class="col-sm-10" style="display: flex; flex-direction: row; flex-wrap: wrap">
                            <div class="radio">
                                <label><input type="radio" value="IMAGE" v-model="newcategories.categoryType" />이미지</label>
                            </div>
                            <SingleImgUpload v-if="newcategories.categoryType == 'IMAGE'" v-model="newcategories.categoryImageUrl" :resizeWidth="1920" downlabel="" :imgtype="imgtype.SITE" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="hidden-xs col-sm-2 control-label"></label>
                        <div class="col-sm-10" style="display: flex; flex-direction: row; flex-wrap: wrap">
                            <div class="radio" style="margin-right: 10px">
                                <label><input type="radio" value="TEXT" v-model="newcategories.categoryType" />색상코드</label>
                            </div>
                            <label v-if="newcategories.categoryType == 'TEXT'" class="control-label" style="margin-right: 10px">배경</label>
                            <input v-if="newcategories.categoryType == 'TEXT'" type="text" class="form-control" style="width: auto; margin-right: 20px" v-model="newcategories.backgroundColor" />
                            <label v-if="newcategories.categoryType == 'TEXT'" class="control-label" style="margin-right: 10px">글자</label>
                            <input v-if="newcategories.categoryType == 'TEXT'" type="text" class="form-control" style="width: auto" v-model="newcategories.fontColor" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-2 control-label">매장</label>
                        <div class="col-sm-10">
                            <button type="button" class="btn btn-default pull-right" @click="showFindstore">매장등록</button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="hidden-xs col-sm-2 control-label"></label>
                        <div class="col-sm-10">
                            <div class="table-responsive p-0">
                                <table class="table table-hover text-nowrap">
                                    <colgroup>
                                        <col width="5%">
                                        <col width="17%">
                                        <col width="17%">
                                        <col width="*">
                                        <col width="5%">
                                        <col width="5%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>매장코드</th>
                                            <th>브랜드명</th>
                                            <th>매장명</th>
                                            <th>상품</th>
                                            <th>순서</th>
                                            <th>삭제</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(prod, pidx) in newcategories.products" :key="pidx">
                                            <td>{{prod.storeCode}}</td>
                                            <td>{{prod.brandName}}</td>
                                            <td>{{prod.storeName}}</td>
                                            <td>
                                                <select class="form-control" v-model="prod.goodsId">
                                                    <option :value="null">선택</option>
                                                    <option v-for="pgood in prod.goodsinfs" :key="pgood.goodsId" :value="pgood.goodsId">{{pgood.goodsName}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <drop @drop="handleNewProdDrop({index:pidx, type: 'newst'}, $event)">
                                                    <drag class="btn btn-default btn-social-icon" :transfer-data="{index:pidx, type: 'newst'}"><i class="fa fa-fw fa-arrows-v"></i>
                                                    </drag>
                                                </drop>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-social-icon" @click="onRmvNewProds(pidx)">
                                                    <i class="fa fa-fw fa-trash-o"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                        <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeCatagory">취소</button>
                        <button type="button" class="btn btn-default" @click="doAddcategories">등록</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :style="findstoredlg && { display: 'block' }">
        <div class="modal-dialog" style="width: 900px;">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">매장검색</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group" style="display:flex;flex-direction:row;flex-wrap: wrap;">
                        <select class="form-control" style="width:auto;" v-model="storekeywordType">
                            <option value="BrandCode">브랜드코드</option>
                            <option value="BrandNameContaining">브랜드명</option>
                            <option value="StoreCode">매장코드</option>
                            <option value="StoreNameContaining">매장명</option>
                            <option value="ManagerID">담당자 ID</option>
                        </select>
                        <div class="input-group" style="flex:1">
                            <input type="text" class="form-control" v-model="storekeyword" />
                            <span class="input-group-btn">
                                <button class="btn btn-default" type="button" @click="searchstore">검색</button>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="table-responsive p-0">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" class="allstch" @change="allstchech($event)" /></th>
                                        <th>매장코드</th>
                                        <th>브랜드명</th>
                                        <th>매장명</th>
                                        <th>운영상태</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="store in findstorelist" :key="store.storeCode">
                                        <td><input type="checkbox" class="stchitem" :value="store.storeCode" /></td>
                                        <td>{{store.storeCode}}</td>
                                        <td>{{store.brandName}}</td>
                                        <td>{{store.basicInfo.storeName}}</td>
                                        <td>{{store.enabled? '활성화' : '비활성화'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeFindstore">
                            닫기
                        </button>
                        <button type="button" class="btn btn-default" @click="onRegAddStore">등록</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :style="finddspdlg && { display: 'block' }">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">기획전 검색</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="기획전명" v-model="findexhibitionName" />
                            <span class="input-group-btn">
                                <button class="btn btn-default" type="button" @click="getExhibitionlist">검색</button>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="table-responsive p-0">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" class="allexhch" @change="allexhcheck($event)" /></th>
                                        <th>기획전 코드</th>
                                        <th>기획전명</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="fitem in findexhibitionlist" :key="fitem.exhibitionId">
                                        <td><input type="checkbox" class="exhchitem" :value="fitem.exhibitionId" /></td>
                                        <td>{{fitem.exhibitionId}}</td>
                                        <td>{{fitem.exhibitionName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeFindDSP">
                            닫기
                        </button>
                        <button type="button" class="btn btn-default" @click="doAddexhibitionRecommends">등록</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval,
    imgtype
} from "../../lib/consts";
import routepath from "../../lib/routes";
import SingleImgUpload from "../../components/SingleImgUpload";
// import Pagination from "../../components/Pagination";
// import ToggleBtn from "../../components/ToggleBtn";
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    display,
    brandapi,
    goodsapi,
    adminapi
} from "../../lib/api";
import {
    DateFormat
} from "../../lib/utility";
import $ from 'jquery';
// import 'codemirror/lib/codemirror.css';
// import '@toast-ui/editor/dist/toastui-editor.css';
// import '@toast-ui/editor/dist/i18n/ko-kr';
// import { Editor } from '@toast-ui/vue-editor';
import {
    Drag,
    Drop
} from "vue-drag-drop";
import Editor from "@tinymce/tinymce-vue";
import Compressor from "compressorjs";
import {
    ContainerClient
} from "@azure/storage-blob";
import DatePick from "vue-date-pick";
import DatePicker from "@/components/DatePicker";
import Area from "./component/Area.vue";

export default {
    components: {
        // Pagination,
        // ToggleBtn,
        SingleImgUpload,
        editor: Editor,
        Drag,
        Drop,
        DatePicker,
        Area,
    },
    created() {
        this.exhibitionId = this.$route.query.exhibitionId;
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_DSP,
            path2: menuval.LV1_HOME,
            title: "",
        });
    },
    props: {
        change: {
            type: Function,
            default: () => {},
        }
    },
    data() {
        return {
            area: false,
            pcheadText: "",
            catagorydlg: false,
            findstoredlg: false,
            finddspdlg: false,
            coordinateXval: 0,
            coordinateYval: 0,
            radiusval: 0,
            editorOptions: {
                minHeight: "200px",
                language: "ko-KR",
                useCommandShortcut: true,
                useDefaultHTMLSanitizer: true,
                usageStatistics: true,
                hideModeSwitch: false,
                toolbarItems: [
                    "heading",
                    "bold",
                    "italic",
                    "strike",
                    "divider",
                    "hr",
                    "quote",
                    "divider",
                    "ul",
                    "ol",
                    "task",
                    "indent",
                    "outdent",
                    "divider",
                    "table",
                    "image",
                    "link",
                    "divider",
                    "code",
                    "codeblock",
                ],
            },

            exhibitionId: 0, //
            exhibitionName: "", //기획전 명
            fromDate: "", //시작일자
            toDate: "", //종료일자
            exhibitionChannels: [], //[PC, MOBILE, EZWEL, HAPPY, BENEPIA] 적용 채널
            imageUrl: "", //기획전 PC 배너 이미지
            imageUrlMobile: "", //기획전 Mobile 배너 이미지
            headType: "IMAGE", //[HTML, IMAGE] 해더 구분
            headHtml: "", //헤더 상세 PC html
            headHtmlMobile: "", //헤더 상세 Mobile html
            headImageUrl: "", //헤더 pc 이미지 url
            headImageUrlMobile: "", //헤더 mobile 이미지 url
            categoryUse: false, //카테고리 사용유무
            categories: [], //
            /**
             * categoryName             String    카테고리 명
             * categoryType             String    [IMAGE, TEXT] 카테고리 타입
             * categoryImageUrl         String    카테고리 이미지 url
             * fontColor                String    카테고리 폰트 색상
             * backgroundColor          String    카테고리 폰트배경 색상
             * order                    Number    카테고리 순서
             * products[].storeCode     String    상점코드
             * products[].goodsId       Number    상품코드
             * products[].order         Number    등록매장순서 */
            exhibitionRecommends: [], //
            /**
             * exhibitionRecommend      Number    추천기회전 ID
             * order                    Number    추천기획전순서
             * */

            storekeywordType: "BrandCode",
            storekeyword: "",
            findstorelist: [],

            newcategories: {
                categoryName: "", //카테고리 명
                categoryType: "IMAGE", //카테고리 타입[IMAGE, TEXT]
                categoryImageUrl: "", //카테고리 이미지 url
                fontColor: "", //카테고리 폰트 색상
                backgroundColor: "", //카테고리 폰트배경 색상
                order: 0, //카테고리 순서
                products: [], //
                /**
                 * storeCode     String    상점코드
                 * goodsId       Number    상품코드
                 * order         Number    등록매장순서 */
            },

            findexhibitionName: "",
            findexhibitionlist: [],
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        imgtype() {
            return imgtype;
        },
    },
    mounted() {
        if (this.exhibitionId != 0) {
            this.refreshPage();
        }

    },
    methods: {
        coordinateX(val) {
            this.coordinateXval = val;
        },
        coordinateY(val) {
            this.coordinateYval = val;
        },
        radius(val) {
            this.radiusval = val;
        },
        allstchech(event) {
            $('input:checkbox.stchitem').prop('checked', event.target.checked);
        },
        allexhcheck(event) {
            $('input:checkbox.exhchitem').prop('checked', event.target.checked);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        refreshPage() {
            reqGet(
                display.base,
                display.exhibition_detail + "?exhibitionId=" + this.exhibitionId,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.exhibitionName = result.data.exhibitionName;
                            this.exhibitionChannels = result.data.exhibitionChannels;
                            this.imageUrl = result.data.imageUrl;
                            this.imageUrlMobile = result.data.imageUrlMobile;
                            this.headType = result.data.headType;
                            this.headHtml = result.data.headHtml;
                            this.headHtmlMobile = result.data.headHtmlMobile;
                            this.headImageUrl = result.data.headImageUrl;
                            this.headImageUrlMobile = result.data.headImageUrlMobile;
                            this.categoryUse = result.data.categoryUse;
                            this.fromDate = result.data.fromDate;
                            this.toDate = result.data.toDate;
                            this.categories = result.data.categories.map((citem) => {
                                return {
                                    categoryName: citem.categoryName,
                                    categoryType: citem.categoryType,
                                    categoryImageUrl: citem.categoryImageUrl,
                                    fontColor: citem.fontColor,
                                    backgroundColor: citem.backgroundColor,
                                    order: citem.order,
                                    products: citem.categoryProducts
                                }
                            });
                            this.exhibitionRecommends = result.data.recommends.map((ritem) => {
                                return {
                                    exhibitionRecommend: ritem.exhibitionRecommend,
                                    exhibitionName: ritem.exhibitionRecommendName,
                                    useFlag: ritem.use_flag
                                }
                            });
                            this.useFlag = result.data.useFlag;
                            this.regDate = result.data.regDate;
                            this.coordinateXval = result.data.coordinateX === null ? 126.57065861202088 : result.data.coordinateX;
                            this.coordinateYval = result.data.coordinateY === null ? 33.45068272027795 : result.data.coordinateY;
                            if(result.data.showType == 'SET'){
                                this.area = true;
                            }else{
                                this.area = false;
                            }
                            this.radiusval = result.data.radius === null ? 0.125 : result.data.radius;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        closeCatagory() {
            this.catagorydlg = false;
        },
        showCatagory(nidx) {
            if (nidx != -1) {
                this.newcategories = {
                    ...this.categories[nidx]
                };
                this.newcategories.order = nidx + 1;
                if (this.newcategories.products.length > 0 && this.newcategories.products[0].goodsinfs === undefined) {
                    var t_products = this.newcategories.products;
                    this.newcategories.products = [];
                    this.getProductsinf(t_products);
                }
                this.catagorydlg = true;
            } else {
                this.newcategories = {
                    categoryName: "", //카테고리 명
                    categoryType: "IMAGE", //카테고리 타입[IMAGE, TEXT]
                    categoryImageUrl: "", //카테고리 이미지 url
                    fontColor: "", //카테고리 폰트 색상
                    backgroundColor: "", //카테고리 폰트배경 색상
                    order: 0, //카테고리 순서
                    products: [], //
                    /**
                     * storeCode     String    상점코드
                     * goodsId       Number    상품코드
                     * order         Number    등록매장순서 */
                };
                this.catagorydlg = true;
            }
        },
        getProductsinf(parray) {
            for (var pidx = 0; pidx < parray.length; pidx++) {
                this.getNowProductPush(parray[pidx]);
            }
        },
        getNowProductPush(tprd) {
            reqGet(
                goodsapi.base,
                goodsapi.storecodeNameslist + "?storeCode=" + tprd.storeCode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            var t_goods = result.data.filter((ritem) => ritem.onSale);
                            var t_product = {
                                ...tprd,
                                goodsinfs: t_goods
                            }
                            this.newcategories.products.push(t_product);
                            this.newcategories.products.sort((a, b) => {
                            return a.order - b.order;
                          });
                        }
                    }
                }
            );
        },
        closeFindstore() {
            $('input:checkbox.stchitem').prop('checked', false);
            $('input:checkbox.allstch').prop('checked', false);
            this.findstorelist = [];
            this.findstoredlg = false;
        },
        showFindstore() {
            this.findstoredlg = true;
        },
        closeFindDSP() {
            $('input:checkbox.exhchitem').prop('checked', false);
            $('input:checkbox.allexhch').prop('checked', false);
            this.findexhibitionlist = [];
            this.finddspdlg = false;
        },
        showFindDSP() {
            this.finddspdlg = true;
        },
        handleExhiDrop(myitem, drag) {
            if (myitem.type != drag.type) {
                return;
            }

            var t_exhibitionRecommends = this.exhibitionRecommends;
            this.exhibitionRecommends = [];
            var pval = t_exhibitionRecommends[myitem.index];
            t_exhibitionRecommends[myitem.index] = t_exhibitionRecommends[drag.index];
            t_exhibitionRecommends[drag.index] = pval;
            this.exhibitionRecommends = t_exhibitionRecommends;
        },
        handleNewProdDrop(myitem, drag) {
            if (myitem.type != drag.type) {
                return;
            }
            var t_products = this.newcategories.products;
            this.newcategories.products = [];
            var tval = t_products[myitem.index];
            t_products[myitem.index] = t_products[drag.index];
            t_products[drag.index] = tval;
            this.newcategories.products = t_products;
        },
        onRmvNewProds(pidx) {
            var t_products = this.newcategories.products;
            this.newcategories.products = [];
            t_products.splice(pidx, 1);
            this.newcategories.products = t_products;
        },
        doRmvCatag(cidx) {
            var t_categories = this.categories;
            this.categories = [];
            t_categories.splice(cidx, 1);
            this.categories = t_categories;
        },
        doRmvExhibition(eidx) {
            var t_exhibitionRecommends = this.exhibitionRecommends;
            this.exhibitionRecommends = [];
            t_exhibitionRecommends.splice(eidx, 1);
            this.exhibitionRecommends = t_exhibitionRecommends;
        },
        handleCatagDrop(myitem, drag) {
            if (myitem.type != drag.type) {
                return;
            }
            var t_categories = this.categories;
            this.categories = [];
            var tval = t_categories[myitem.index];
            t_categories[myitem.index] = t_categories[drag.index];
            t_categories[drag.index] = tval;
            this.categories = t_categories;
        },
        searchstore() {
            reqGet(
                brandapi.base,
                brandapi.basicInfoStorelist + "?keywordType=" + this.storekeywordType + "&keyword=" + this.storekeyword,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.findstorelist = result.data;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        onRegAddStore() {
            var selstores = $('input:checkbox.stchitem:checked').map(function () {
                return this.value;
            }).get();
            var temp_product = this.findstorelist.filter((fstem) => {
                var t_idx = selstores.findIndex((oitem) => oitem == fstem.storeCode);
                return t_idx > -1;
            });
            for (var idx = 0; idx < temp_product.length; idx++) {
                this.getNewProductPush(temp_product[idx]);
            }
            this.closeFindstore();
        },
        getNewProductPush(tprd) {
            reqGet(
                goodsapi.base,
                goodsapi.storecodeNameslist + "?storeCode=" + tprd.storeCode + "&combinedFilter=ForNotCombinedOnly",
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            var t_goods = result.data.filter((ritem) => ritem.onSale);
                            var t_product = {
                                storeCode: tprd.storeCode,
                                brandName: tprd.brandName,
                                storeName: tprd.basicInfo.storeName,
                                goodsinfs: t_goods,
                                goodsId: null
                            }
                            /**
                             * data[].goodsId             Number    상품 ID
                             * data[].goodsName           String    상품명
                             * data[].onSale              Boolean   판매중/판매중지 여부
                             * data[].hasSalesPeriod      Boolean   판매기간이 지정되어 있는 경우 true  상시판매인 경우 false
                             * data[].saleStartDate       String    yyyy-MM-dd  판매시작일  (hasSalesPeriod이 false인 경우 null)
                             * data[].saleEndDate         String    yyyy-MM-dd  판매종료일  (hasSalesPeriod이 false인 경우 null)
                             */
                            this.newcategories.products.push(t_product);
                        }
                    }
                }
            );
        },
        doAddcategories() {
            if (this.newcategories.order == 0) {
                this.categories.push(this.newcategories);
            } else {
                this.categories[this.newcategories.order - 1] = this.newcategories;
            }
            this.newcategories = {
                categoryName: "", //카테고리 명
                categoryType: "IMAGE", //카테고리 타입[IMAGE, TEXT]
                categoryImageUrl: "", //카테고리 이미지 url
                fontColor: "", //카테고리 폰트 색상
                backgroundColor: "", //카테고리 폰트배경 색상
                order: 0, //카테고리 순서
                products: [], //
                /**
                 * storeCode     String    상점코드
                 * goodsId       Number    상품코드
                 * order         Number    등록매장순서 */
            };
            this.closeCatagory();
        },
        getExhibitionlist() {
            reqGet(
                display.base,
                display.exhibition_use_list + "?exhibitionName=" + this.findexhibitionName,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.findexhibitionlist = result.data;
                            /**
                             * exhibitionId           Number    기획전 ID
                             * exhibitionName         String    기획전 명
                             * useFlag                Boolean   기획전 상태
                             */
                        }
                    }
                }
            );
        },
        doAddexhibitionRecommends() {
            var selexh = $('input:checkbox.exhchitem:checked').map(function () {
                return this.value;
            }).get();
            var temp_exh = this.findexhibitionlist.filter((fstem) => {
                var t_idx = selexh.findIndex((oitem) => oitem == fstem.exhibitionId);
                var e_idx = this.exhibitionRecommends.findIndex((oitem) => oitem.exhibitionRecommend == fstem.exhibitionId);
                return t_idx > -1 && e_idx == -1;
            });
            var last_exh = temp_exh.map((item) => {
                return {
                    exhibitionRecommend: item.exhibitionId,
                    exhibitionName: item.exhibitionName,
                    useFlag: item.useFlag
                }
            });
            for (var idx = 0; idx < last_exh.length; idx++) {
                this.exhibitionRecommends.push(last_exh[idx]);
            }
            // this.exhibitionRecommends = this.exhibitionRecommends.concat(temp_exh);
            this.closeFindDSP();
        },
        goBack() {
            this.$router.go(-1);
        },
        doRegister() {
            var t_categories = this.categories.map((citem, cidx) => {
                var t_products = citem.products.map((pitem, pidx) => {
                    return {
                        storeCode: pitem.storeCode,
                        goodsId: pitem.goodsId,
                        order: pidx + 1
                    }
                });

                return {
                    ...citem,
                    order: cidx + 1,
                    products: t_products
                };
            });

            var t_exhibitionRecommends = this.exhibitionRecommends.map((eitem, eidx) => {
                return {
                    exhibitionRecommend: eitem.exhibitionRecommend,
                    order: eidx + 1
                }
            });

            if (this.exhibitionName == "") {
                alert("제목을 입력하세요.");
                return;
            }
            if (this.fromDate == "") {
                alert("전시시작일을 입력하세요.");
                return;
            }
            if (this.toDate == "") {
                alert("전시종료일을 입력하세요.");
                return;
            }
            if (this.exhibitionChannels.length == 0) {
                alert("적용 사이트를 체크하세요.");
                return;
            }
            if (this.imageUrl == "") {
                alert("기획전 배너(PC)를 입력하세요.");
                return;
            }
            if (this.imageUrlMobile == "") {
                alert("기획전 배너(Mobile)를 입력하세요.");
                return;
            }
            let showType = '';
            if (this.area == true) {
                showType = 'SET';
            } else {
                showType = 'ALL';
            }
            if (this.exhibitionId == 0) {
                var rreqdata = {
                    exhibitionName: this.exhibitionName,
                    exhibitionChannels: this.exhibitionChannels,
                    imageUrl: this.imageUrl,
                    imageUrlMobile: this.imageUrlMobile,
                    headType: this.headType,
                    headHtml: this.headHtml,
                    headHtmlMobile: this.headHtmlMobile,
                    headImageUrl: this.headImageUrl,
                    headImageUrlMobile: this.headImageUrlMobile,
                    categoryUse: this.categoryUse,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    categories: t_categories,
                    exhibitionRecommends: t_exhibitionRecommends,
                    showType: showType,
                    coordinateX: this.coordinateXval,
                    coordinateY: this.coordinateYval,
                    radius: this.radiusval,
                };
                reqPost(
                    display.base,
                    display.exhibition_register,
                    this.authToken,
                    rreqdata,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.goBack();
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            } else {
                var reqdata = {
                    exhibitionId: this.exhibitionId,
                    exhibitionName: this.exhibitionName,
                    exhibitionChannels: this.exhibitionChannels,
                    imageUrl: this.imageUrl,
                    imageUrlMobile: this.imageUrlMobile,
                    headType: this.headType,
                    headHtml: this.headHtml,
                    headHtmlMobile: this.headHtmlMobile,
                    headImageUrl: this.headImageUrl,
                    headImageUrlMobile: this.headImageUrlMobile,
                    categoryUse: this.categoryUse,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    categories: t_categories,
                    exhibitionRecommends: t_exhibitionRecommends,
                    showType: showType,
                    coordinateX: this.coordinateXval,
                    coordinateY: this.coordinateYval,
                    radius: this.radiusval,
                };
                reqPost(
                    display.base,
                    display.exhibition_modify,
                    this.authToken,
                    reqdata,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.goBack();
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        onClickAll(event) {
            if (event.target.checked) {
                this.exhibitionChannels = ["PC", "MOBILE", "EZWEL", "HAPPY", "BENEPIA","LIFECARE", "CHALETKOREA"];
            } else {
                this.exhibitionChannels = [];
            }
        },
        getAllChecked() {
            return this.exhibitionChannels.length == 7;
        },
        onChangeClick() {
            this.$refs.fileInput.click();
        },
        onUploadFiles(event) {
            let imageFile = event.target.files[0];

            this.compressImage(imageFile).then((compressedFile) => {
                let reader = new FileReader();
                reader.onload = evt => {
                    let img = new Image();
                    img.onload = () => {
                        this.imgwidth = img.width;
                        this.imgheight = img.height;
                    }
                    img.src = evt.target.result;
                }
                reader.onerror = evt => {
                    console.error(evt);
                }
                reader.readAsDataURL(compressedFile);

                if (this.value === '') {
                    this.onRegImage(compressedFile);
                } else {
                    this.onChange(compressedFile);
                }
            }).catch((error) => {
                alert("이미지 리사이징 에러입니다.");
            });
        },
        compressImage(imageFile) {
            return new Promise((resolve, reject) => {
                let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.resizeWidth = 0;
                if (this.resizeWidth === 0) {
                    resolve(imageFile);
                } else if (!allowedExtensions.exec(imageFile.name)) {
                    resolve(imageFile);
                } else {
                    new Compressor(imageFile, {
                        maxWidth: this.resizeWidth || 600,
                        quality: 0.8,
                        success(result) {
                            resolve(result)
                        },
                        error(e) {
                            reject(e)
                        },
                    });
                }
            });
        },
        onRegImage(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: "SITE",
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            const containerClient = new ContainerClient(result.data.url);
                            var d = new Date();
                            var n = d.getTime();
                            let filename = n + '_' + imageFile.name;
                            // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(uploadedUrl);
                                tinymce.execCommand('mceInsertContent', false, "<img src ='" + uploadedUrl + "'data-mce-src'" + uploadedUrl + "'data-originalFileName='" + uploadedUrl + "'>");

                            });
                        }
                    }
                }
            );
        },
        onChange(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: "SITE",
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            // this.container = result.data.container;
                            // this.expiresIn = result.data.expiresIn;
                            const containerClient = new ContainerClient(result.data.url);
                            // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
                            var d = new Date();
                            var n = d.getTime();
                            let filename = n + '_' + imageFile.name;
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                                // onProgress: ev => console.log(ev)
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(uploadedUrl);
                                tinymce.execCommand('mceInsertContent', false, "<img src ='" + uploadedUrl + "'data-mce-src'" + uploadedUrl + "'data-originalFileName='" + uploadedUrl + "'>");
                            });
                        }
                    }
                }
            );
        },
    },
};
</script>

<style scoped>
th {
    background-color: #dee2e685;
}

td {
    white-space: break-spaces;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.radio>label {
    margin-right: 10px;
}

.checkbox>label {
    margin-right: 10px;
}
</style>
