<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword">전체</option>
            <option value="FullNameContaining">이름</option>
            <option value="Username">아이디</option>
            <option value="Email">이메일</option>
            <option value="PhoneNumber">전화번호</option>
            <option value="PhoneNumber4">전화번호 끝 4자리</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width:70px" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>번호</th>
              <th>아이디</th>
              <th>이름</th>
              <th>등록 요청일</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in content" v-bind:key="user.username" @click="viewDetail(user.username)">
              <td>{{ startnum + 1 + index }}</td>
              <td class="linkcmp">{{user.username}}</td>
              <td class="linkcmp">{{user.fullName}}</td>
              <td>{{viewDate(user.requestedDate)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">관리자 등록 승인</div>
          <div class="modal-body" style="width:60%;margin:auto">
            <div class="form-group row">
              <label for="userid" class="col-sm-3 control-label">아이디</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  :value="viewuserid"
                  id="userid"
                  placeholder="아이디"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="username" class="col-sm-3 control-label">이름</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  :value="viewfullname"
                  id="username"
                  placeholder="이름"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col-sm-3 control-label">이메일</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  :value="viewemail"
                  id="email"
                  placeholder="이메일"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="phonenum" class="col-sm-3 control-label">휴대전화</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="phonenum"
                  :value="viewphone"
                  placeholder="휴대전화"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="phonenum" class="col-sm-3 control-label">역할</label>
              <div class="col-sm-9">
                <select class="form-control" v-model="viewRole">
                  <option value="ADMIN">수퍼 관리자</option>
                  <option value="MANAGER">관리자</option>
                  <option value="SALES">세일즈</option>
                  <option value="AGENT">에이전시</option>
                </select>
                <span class="error invalid-feedback" style="display:block">{{errmsg}}</span>
              </div>
            </div>
            <div class="form-group row">
              <label for="phonenum" class="col-sm-3 control-label">외부접속</label>
              <div class="col-sm-9">
                <label class="control-label" style="margin-right:20px">
                  <input type="radio" value="true" v-model="viewRemote" />허용
                </label>
                <label class="control-label">
                  <input type="radio" value="false" v-model="viewRemote" />불가
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label for="phonenum" class="col-sm-3 control-label">시스템 알람</label>
              <div class="col-sm-9">
                <label class="control-label" style="margin-right:20px">
                  <input type="radio" value="true" v-model="systemNotification" />허용
                </label>
                <label class="control-label">
                  <input type="radio" value="false" v-model="systemNotification" />불가
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-warning pull-left" @click="delstaff">아이디 삭제</button>
            <button type="button" class="btn btn-default" @click="closemodal">취소</button>
            <button type="button" class="btn btn-success" @click="savedata">등록승인</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import { reqGet, reqPost } from "../../../lib/request";
import { adminapi } from "../../../lib/api";
import { DateFormat, formatPhone } from "../../../lib/utility";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      errmsg: "", //오류 메시지
      showmodal: false,
      viewuserid: "", //선택된 관리자 ID
      viewfullname: "", //선택된 관리자 이름
      viewemail: "", //선택된 관리자 이메일
      viewphone: "", //선택된 관리자 휴대폰 번호
      viewRole: "", //선택된 관리자 권한
      viewRemote: false, //선택된 관리자 외부접속 허용
      systemNotification: false //시스템알람
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    myRole() {
      return this.$store.getters.myRole;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.MEMREG_keywordType) {
        this.keywordType = localStorage.MEMREG_keywordType;
      }
      if (localStorage.MEMREG_keyword) {
        this.keyword = localStorage.MEMREG_keyword;
      }
      if (localStorage.MEMREG_Number) {
        this.pageNumber = parseInt(localStorage.MEMREG_Number);
      }
      if (localStorage.MEMREG_Size) {
        this.pageSize = parseInt(localStorage.MEMREG_Size);
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_MEM, path2: menuval.LV2_MEM_TNJ, title: "관리자 등록신청"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.MEMREG_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.MEMREG_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.MEMREG_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.MEMREG_Size = newsize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.MEMREG_keywordType = this.keywordType;
      localStorage.MEMREG_keyword = this.keyword;
      localStorage.MEMREG_Number = this.pageNumber;
      localStorage.MEMREG_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage();
    },
    adminrole(val) {
      if (val == "ADMIN") {
        return "슈퍼관리자";
      } else if (val == "MANAGER") {
        return "관리자";
      } else if (val == "SALES") {
        return "세일즈";
      } else if (val == "AGENT") {
        return "에이전시";
      } else {
        return val;
      }
    },
    delstaff() {
      reqPost(
        adminapi.base,
        adminapi.staffunregister,
        this.authToken,
        {
          username: this.viewuserid,
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.showmodal = false;
              this.refreshPage();
            } else {
              this.errmsg = data.message;
            }
          } else {
            this.errmsg = "아이디 삭제 실패되엇습니다.";
          }
        }
      );
    },
    savedata() {
      if (this.viewRole == "" || this.viewRole == "USER") {
        this.errmsg = "역할을 선택하세요.";
        return;
      }
      reqPost(
        adminapi.base,
        adminapi.tnjgrantRole,
        this.authToken,
        {
          username: this.viewuserid,
          userRole: this.viewRole,
          remoteAccess: this.viewRemote,
          systemNotification: this.systemNotification,
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.showmodal = false;
              this.refreshPage();
            } else {
              this.errmsg = data.message;
            }
          } else {
            this.errmsg = "등록승인 실패되었습니다.";
          }
        }
      );
    },
    closemodal() {
      this.showmodal = false;
    },
    viewDetail(val) {
      if (this.myRole != "ADMIN") return;
      this.viewRemote = true;
      this.errmsg = "";
      reqGet(
        adminapi.base,
        adminapi.staffinfo + "?username=" + val,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.viewuserid = result.data.username;
              this.viewfullname = result.data.fullName;
              this.viewemail = result.data.email;
              this.viewphone = formatPhone(result.data.phoneNumber);
              this.viewRole = result.data.userRole;
              this.systemNotification = result.data.systemNotification;
              this.showmodal = true;
              /*
              approved: true
              approvedDate: null
              brandCode: null
              requestedDate: "2020-01-28T21:35:11"
              storeCode: null
               */
            }
          }
        }
      );
    },
    refreshPage() {
      reqPost(
        adminapi.base,
        adminapi.pendingList,
        this.authToken,
        {
          keywordType: this.keywordType,
          keyword: this.keyword,
          pageNumber: this.pageNumber - 1,
          pageSize: this.pageSize,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              /*
              result.data.size : 페이지 사이즈
              result.data.number : 페이지 번호
              result.data.first : 첫 페이지일 경우 true
              result.data.last : 마지막 페이지일 경우 true
              result.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
  },
};
</script>
<style scoped>
th {
  background-color: #dee2e685;
  text-align: center;
}
td {
  text-align: center;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}
</style>
