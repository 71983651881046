<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                <select class="form-control" style="width:auto;margin-right:10px" v-model="dateType">
                    <option value="RequestTime">신청일</option>
                    <option value="VisitDate">방문일</option>
                </select>
                <DetePicker v-model="dateFrom" :last="dateTo" />
                <h4 style="margin:5px 10px;">~</h4>
                <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px" />
                <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
                    <option value="NoKeyword"></option>
                    <option value="BookingNo">예약번호</option>
                    <option value="PhoneNumber">전화번호</option>
                    <option value="PhoneNumber4">전화번호 끝 4자리</option>
                    <option value="BrandName">브랜드명</option>
                    <option value="StoreName">매장명</option>
                    <option value="VisitorName">예약자명</option>
                </select>
                <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                    <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
        <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="buttonDissabled">
            <i class="fa fa-download"></i>Excel
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th class="text-center">번호</th>
                        <th class="text-center">예약번호</th>
                        <th class="text-center">예약타입</th>
                        <th class="text-center">브랜드</th>
                        <th class="text-center">매장</th>
                        <th class="text-center">예약자</th>
                        <th class="text-center">신청일</th>
                        <th class="text-center">방문일</th>
                        <th class="text-center">예약상태</th>
                        <th class="text-center">예약경로</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(book, index) in content" :key="book.bookingNo">
                        <td class="text-center">{{ totalElements - (startnum + index) }}</td>
                        <td class="linkcmp text-center" @click="goDetailViewPage(book.bookingNo)">{{book.bookingNo}}</td>
                        <td class="text-center">{{ bookTypeVal(book.bookingType) }}</td>
                        <td class="text-center">{{book.brandName}}</td>
                        <td class="text-center">{{book.storeName}}</td>
                        <td class="text-center">{{book.visitorName}}</td>
                        <td class="text-center">{{viewDate(book.bookingRequestDate)}}</td>
                        <td class="text-center">{{viewDate(book.reservationDate)}}</td>
                        <td class="text-center">{{getBookState(book.bookingStatus)}}</td>
                      <td class="text-center">{{parseBookingRoot(book)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {
    reqPost
} from "../../lib/request";
import {
    orderapi
} from "../../lib/api";
import {
    DateFormat,
    getBookStateStr,
    getBookTypeStr
} from "../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";
export default {
    components: {
        Pagination,
        DetePicker,
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.Book_keywordType) {
                this.keywordType = localStorage.Book_keywordType;
            }
            if (localStorage.Book_keyword) {
                this.keyword = localStorage.Book_keyword;
            }
            if (localStorage.Book_Number) {
                this.pageNumber = parseInt(localStorage.Book_Number);
            }
            if (localStorage.Book_Size) {
                this.pageSize = parseInt(localStorage.Book_Size);
            }
            if (localStorage.Book_dateType) {
                this.dateType = localStorage.Book_dateType;
            }
            if (localStorage.Book_dateFrom) {
                this.dateFrom = localStorage.Book_dateFrom;
            }
            if (localStorage.Book_dateTo) {
                this.dateTo = localStorage.Book_dateTo;
            }
        }
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_BOOK,
            path2: menuval.LV1_HOME,
            title: "예약내역조회"
        });
    },
    watch: {
        keywordType(newVal) {
            localStorage.Book_keywordType = newVal;
        },
        keyword(newkey) {
            localStorage.Book_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.Book_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.Book_Size = newsize;
        },
        dateType(newtype) {
            localStorage.Book_dateType = newtype;
        },
        dateFrom(newfrom) {
            localStorage.Book_dateFrom = newfrom;
        },
        dateTo(newto) {
            localStorage.Book_dateTo = newto;
        },
    },
    data() {
        return {
            shownum: 10, //pagination에 보여지는 번호개수
            dateType: "RequestTime",
            dateFrom: moment().format("YYYY-MM-01"),
            dateTo: moment().format("YYYY-MM-DD"),
            keywordType: "NoKeyword",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            /**
             * bookingNo                  String    예약번호
             * visitorName                String    예약자명
             * visitorPhone               String    예약자 전화번호
             * visitorEmail               String    예약자 이메일
             * visitorRequests            String    예약자 요청사항
             * brandCode                  String    브랜드 코드
             * brandName                  String    브랜드명
             * storeCode                  String    스토어 코드
             * storeName                  String    스토어명
             * siteName                   String    예약한 사이트명. 테이블엔조이에서 예약한 경우 tablenjoy
             * bookingRequestDate         String    UTC yyyy-MM-ddTHH:mm:ss 예약신청일
             * reservationDate            String    yyyy-MM-ddTHH:mm:ss 예약일
             * itemCount                  Number    주문 아이템 개수
             * adultHeadcount             Number    성인 예약 인원 수
             * childHeadcount             Number    어린이 예약 인원 수
             * babyHeadcount              Number    유아 예약 인원 수
             * bookingStatus              String    [BookingRequested, BookingRejected, Booked, CancelRequested, Cancelled, Visited]  예약상태
             * memo                       String    메모
             */
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true

            exceldata: [],
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.Book_keywordType = this.keywordType;
            localStorage.Book_keyword = this.keyword;
            localStorage.Book_Number = this.pageNumber;
            localStorage.Book_Size = this.pageSize;
            localStorage.Book_dateType = this.dateType;
            localStorage.Book_dateFrom = this.dateFrom;
            localStorage.Book_dateTo = this.dateTo;
        }
        this.refreshPage();
    },
    methods: {
        bookTypeVal(val) {
            return getBookTypeStr(val);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            var reqdata = {
                dateType: this.dateType,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                keywordType: this.keywordType,
                keyword: this.keyword,
                pageNumber: pageNumber,
                pageSize: this.pageSize
            };
            reqPost(
                orderapi.base,
                orderapi.bookingspage,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                            alert(result.message);
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goDetailViewPage(bookingNo) {
            this.$router.push(this.routes.detailbook + "?bookingNo=" + bookingNo);
        },
        getBookState(str) {
            return getBookStateStr(str);
        },
        getExcelData() {
          this.buttonDissabled = true;
            reqPost(
                orderapi.base,
                orderapi.bookingslist,
                this.authToken, {
                    dateType: this.dateType,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    keywordType: this.keywordType,
                    keyword: this.keyword,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.exceldata = result.data;
                            this.downExcel();
                        } else {
                            this.exceldata = [];
                            this.buttonDissabled = false;
                            alert(result.message);
                        }
                    }
                }
            );
        },
        dataconvert(book) {
            return {
                A: book.bookingNo,
                J: book.b2bBookingNo,
                B: this.bookTypeVal(book.bookingType),
                C: book.brandName,
                D: book.storeName,
                E: book.visitorName,
                F: this.viewDate(book.bookingRequestDate),
                G: this.viewDate(book.reservationDate),
                H: this.getBookState(book.bookingStatus),
                K: this.parseBookingRoot(book),
            };
        },
        downExcel() {
            var excelheader = XLSX.utils.json_to_sheet(
                [{
                    A: "예약번호",
                    J: "외부채널 예약번호",
                    B: "예약타입",
                    C: "브랜드",
                    D: "매장",
                    E: "예약자",
                    F: "신청일",
                    G: "방문일",
                    H: "예약상태",
                    K: "예약경로",
                }, ], {
                    skipHeader: true
                }
            );
            XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "예약내역조회");
            // export Excel file
            XLSX.writeFile(wb, "예약내역조회.xlsx");
            this.buttonDissabled = false;
        },
        parseBookingRoot(val){
          let returnMsg = ''
          if(val.b2bChannelCode == 'KAKAOB'){
            returnMsg = '카카오'
          }else if (val.bookingType == 'ManualBooking'){
            returnMsg = '관리자'
          }else if (val.siteName == 'tablenjoy' || !val.siteName){
            returnMsg = '테이블엔조이'
          }else if (val.siteName != 'tablenjoy'){
            returnMsg = '자체판매'
          }

          return returnMsg
        },
    },
};
</script>

<style scoped>
.form-group {
    margin-left: 0px;
    margin-right: 0px;
}
</style>
