<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <select class="form-control" v-model="periodType" style="width:auto;margin-right:10px">
            <option value="SalesPeriod">판매기간</option>
            <option value="AvailablePeriod">유효기간</option>
          </select>
          <DetePicker v-model="startDate" :last="endDate"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="endDate" :first="startDate" style="margin-right:10px"/>
          <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExceldata" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th class="text-center">번호</th>
              <th class="text-center">상품코드</th>
              <th class="text-center">상품유형</th>
              <th class="text-center">등록경로</th>
              <th>상품명</th>
              <th class="text-center">판매기간</th>
              <th class="text-center">유효기간</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(good, index) in content" v-bind:key="good.goodsId">
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{good.goodsId}}</td>
              <td class="text-center">{{goodsTypeVal(good.goodsType)}}</td>
              <td class="text-center">{{getSiteBrandName(good.siteName)}}</td>
              <td class="linkcmp" @click="goViewInfoPage(good.goodsId, good.goodsType)">{{good.goodsName}}</td>
              <td class="text-center">{{viewDate(good.saleStartDate)}} ~ {{viewDate(good.saleEndDate)}}</td>
              <td class="text-center">{{viewDate(good.availableFromDate)}} ~ {{viewDate(good.availableToDate)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import { reqGet, reqPost } from "../../lib/request";
import { goodsapi, brandapi } from "../../lib/api";
import { DateFormat, getGoodsTypeStr } from "../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";
export default {
  components: {
    Pagination,
    DetePicker,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.VALID_startDate) {
        this.startDate = localStorage.VALID_startDate;
      }
      if (localStorage.VALID_endDate) {
        this.endDate = localStorage.VALID_endDate;
      }
      if (localStorage.VALID_Number) {
        this.pageNumber = parseInt(localStorage.VALID_Number);
      }
      if (localStorage.VALID_Size) {
        this.pageSize = parseInt(localStorage.VALID_Size);
      }
      if (localStorage.VALID_periodType) {
        this.periodType = localStorage.VALID_periodType;
      }
    }
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_PRD, path2:menuval.LV1_HOME, title:"유효기간 만료예정"});
  },
  watch: {
    startDate(newstart) {
      localStorage.VALID_startDate = newstart;
    },
    endDate(newend)
    {
      localStorage.VALID_endDate = newend;
    },
    pageNumber(newnum)
    {
      localStorage.VALID_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.VALID_Size = newsize;
    },
    periodType(newtype)
    {
      localStorage.VALID_periodType = newtype;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      periodType: "SalesPeriod",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * goodsId                Number    상품 ID
       * goodsType              String    상품 타입[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
       * goodsName              String    상품명
       * siteName               String    상품 등록 사이트명
       * saleStartDate          String    yyyy-MM-dd    판매 시작일
       * saleEndDate            String    yyyy-MM-dd    판매 종료일
       * availableFromDate      String    yyyy-MM-dd    유효기간 시작일
       * availableToDate        String    yyyy-MM-dd    유효기간 종료일
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      sitebrands:[],
      exceldata:[],
      excelflag:false,
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.VALID_startDate = this.startDate;
      localStorage.VALID_endDate = this.endDate;
      localStorage.VALID_Number = this.pageNumber;
      localStorage.VALID_Size = this.pageSize;
      localStorage.VALID_periodType = this.periodType;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqGet(
        goodsapi.base,
        goodsapi.goodsperiodpage +
          "?periodType=" +
          this.periodType +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&pageNumber=" +
          pageNumber +
          "&pageSize=" +
          this.pageSize,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.getBrandName(result.data.content);
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goViewInfoPage(goodid, goodsType) {
      // this.$router.push(this.routes.viewprodinf+"?registerId="+goodid);
      this.$router.push(this.routes.editprodinf+"?goodid="+goodid+"&goodsType="+goodsType);
    },
    getBrandName(retcnt)
    {
      if(retcnt.length == 0)
      {
        return ;
      }
      var siteArray = retcnt.map(citem => {return citem.siteName});
      reqPost(
        brandapi.base,
        brandapi.brandsbySites,
        this.authToken,
        {
          siteNames: siteArray,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.sitebrands = result.data.brands;
              if(this.excelflag)
              {
                this.downExcel();
              }
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    goodsTypeVal(val)
    {
      return getGoodsTypeStr(val);
    },
    getSiteBrandName(sitename)
    {
      var sbidx = this.sitebrands.findIndex(sbitem => sbitem.siteName == sitename );
      if(sbidx > -1)
      {
        return this.sitebrands[sbidx].brandName;
      }else{
        return "";
      }
    },
    getExceldata(){
      this.buttonDissabled = true;
      reqGet(
        goodsapi.base,
        goodsapi.goodsperiodlist +
          "?periodType=" +
          this.periodType +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.excelflag = true;
              // this.downExcel();
              this.getBrandName(result.data);
            } else {
              this.content = [];
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    convert(good, idx)
    {
      return {
        A: idx + 1,
        B: good.goodsId,
        C: this.goodsTypeVal(good.goodsType),
        D: this.getSiteBrandName(good.siteName),
        E: good.goodsName,
        F: this.viewDate(good.saleStartDate)+" ~ " + this.viewDate(good.saleEndDate),
        G: this.viewDate(good.availableFromDate) + " ~ " + this.viewDate(good.availableToDate)
      };
    },
    downExcel() {
      this.excelflag = false;
      var tmpheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "상품코드",
            C: "상품유형",
            D: "등록경로",
            E: "상품명",
            F: "판매기간",
            G: "유효기간",
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(tmpheader, this.exceldata.map(this.convert), {
        skipHeader: true,
        origin: "A2",
      });


      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, tmpheader, "유효기간 만료예정");
      // export Excel file
      XLSX.writeFile(wb, "유효기간 만료예정.xlsx");
      this.buttonDissabled = false;
    },
  },
};
</script>
