import moment from 'moment';
const weekname = ["일", "월", "화", "수", "목", "금", "토"];

export const formatPhone = phone => {
  if (phone == null || phone == "") {
    return "";
  }
  let formatNum = '';
  if (phone.length == 11) {
    formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phone.length == 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (phone.indexOf('02') == 0) {
      formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      formatNum = phone.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    }
  }

  return formatNum;
};

export const alignTableCenter = {
  tdClass: "text-center align-middle",
  thClass: "text-center align-middle"
};

export const isPhoneNum = (argtest) => {
  if (argtest.length < 10 || argtest.length > 11) {
    return false;
  }
  var regExp = /^[0-9|.|-|+|\s]+$/;
  if (regExp.test(argtest)) {
    return true;
  } else {
    return false;
  }
};

export const isMobileNum = (argtest) => {
  if (argtest.length != 11) {
    return false;
  }
  var regExp = /^[0-9|.|-|+|\s]+$/
  if (regExp.test(argtest)) {
    return true;
  } else {
    return false;
  }
};

export const DateFormat = (date, frmt, week = false) => {
  if (date == null || date == "") {
    return "";
  }
  var days = moment(date).format(frmt);
  if (week) {
    var wkidx = moment(date).day();
    return days + " (" + weekname[wkidx] + ")";
  } else {
    return days;
  }
}

export const filterHours = (value) => {
  if (value == null || value == "") {
    return "";
  }
  const duration = moment.duration(value);
  return duration.hours();
}

export const filterMinutes = (value) => {
  if (value == null || value == "") {
    return "";
  }
  const duration = moment.duration(value);
  return duration.minutes();
}

export const getPercent = (sales, target) => {
  if (target == null || target == 0) {
    return "";
  } else {
    var pval = sales * 100 / target;
    return pval.toFixed(2) + "%";
  }
}

export const getDiscount = (sales, target) => {
  if (target == null || target == 0) {
    return "";
  } else {
    let discount = (1 - (sales / target)) * 100
    return discount.toFixed(2) + "%";
  }
}

export const getStoreTypeStr = (val) => {
  if (val == "Buffet") {
    return "뷔페";
  } else if (val == "Western") {
    return "양식";
  } else if (val == "Korean") {
    return "한식";
  } else if (val == "Chinese") {
    return "중식";
  } else if (val == "Japanese") {
    return "일식";
  } else if (val == "Italian") {
    return "이탈리안";
  } else if (val == "French") {
    return "프렌치";
  } else if (val == "Asian") {
    return "아시안";
  } else if (val == "Mexican") {
    return "멕시칸";
  } else if (val == "Fusion") {
    return "퓨전";
  } else if (val == "Dessert") {
    return "디저트";
  } else if (val == "Barbecue") {
    return "캠핑&바비큐";
  } else if (val == "Bakery") {
    return "카페&베이커리";
  } else if (val == "Pub") {
    return "펍&바";
  }else if(val == 'HomeParty'){
    return '홈파티';
  }else if(val == 'Takeout'){
    return '테이크아웃';
  }else if (val == "Spa") {
    return "스파";
  } else if (val == "Etc") {
    return "기타";
  }
}

export const getGoodsTypeStr = (val) => {
  if (val == "MobileCoupon") {
    return "모바일쿠폰";
  } else if (val == "BookingCoupon") {
    return "예약필수쿠폰";
  } else if (val == "Booking") {
    return "예약";
  } else if (val == "MoneyCoupon") {
    return "모바일금액권";
  } else if (val == "CashCoupon") {
    return "캐시권";
  } else if (val == "Combined") {
    return "결합 상품";
  } else if (val == 'StoreBooking') {
    return '매장예약';
  } else if (val == 'PointCoupon') {
    return '포인트권';
  }
}

export const getBookTypeStr = (val) => {
  if (val == "ManualBooking") {
    return "수기예약";
  } else if (val == "CouponBooking") {
    return "쿠폰예약";
  } else if (val == "StoreBooking") {
    return "매장예약";
  }
}

export const getAgeGroupStr = (val) => {
  if (val == "Adult") {
    return "성인";
  } else if (val == "Child") {
    return "어린이";
  } else if (val == "Baby") {
    return "유아";
  }
}

export const getMobileCoupon = (val) => {
  if (val == "MoneyCoupon") {//모바일쿠폰
    return true;
  } else if (val == "MobileCoupon") {//모바일금액권
    return true;
  }else if (val == "PointCoupon") {//모바일금액권
    return true;
  }
  return false;
}

export const getPartnerStr = (val) => {
  if (val == "HN") {
    return "하나투어";
  } else if (val == "TG") {
    return "TGIF";
  } else if (val == "EB") {
    return "이브릿지";
  } else if (val == "LS") {
    return "LS 컴퍼니";
  } else if (val == "NTRS") {
    return "NTRS";
  } else if (val == "OB") {
    return "온더보더";
  } else if (val == "KICC") {
    return "KICC";
  }
  return "";
}

export const getBookStateStr = (str) => {
  if (str == "BookingRequested") {
    return "예약대기";
  } else if (str == "BookingRejected") {
    return "예약거절";
  } else if (str == "Booked") {
    return "예약완료";
  } else if (str == "CancelRequested") {
    return "취소요청";
  } else if (str == "Cancelled") {
    return "취소완료";
  } else if (str == "Visited") {
    return "방문완료";
  } else if (str == "NoShow") {
    return "노쇼";
  }
  return "";
}
