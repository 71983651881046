<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-header">
        <h4>카카오톡 예약하기 입점신청</h4>
      </div>
      <div class="box-body form-horizontal">
        <div class="form-group">
          <label class="col-sm-2 control-label">카카오톡 채널</label>
          <div class="col-sm-10 d-flex">
              <!-- <select class="form-control" style="width:100%;max-width:400px;min-width:100px;" v-model="brandCode">
      <option v-for="(bditem, bdidx) in brandCodes" :key="bdidx" :value="bditem.brandCode">{{bditem.brandName}}</option>
    </select> -->
              <input type="text" class="form-control" placeholder="사업자 번호" v-model="businessCode" maxlength="12" style="width: 40%"/>
              <button type="button" class="btn btn-default" style="margin-right: 10px" @click="searchBusinessCode">채널검색</button>
              <select  class="form-control" style="width:100%;max-width:400px;min-width:100px;" v-model="selectedKakaoChannel" @change="initKakaoPartner" >
                <option v-if="!successChk" disabled selected hidden :value="null" >
                  채널을 검색해주세요
                </option>
                <template v-for="(kcitem, kcidx) in kakaoChannelList" >
                  <option v-if="!kcitem.id" disabled selected :value="null">{{kcitem.name}}</option>
                  <option v-else :value="kcitem">{{kcitem.name + " (" + kcitem.searchId + ")"}}</option>
                </template>

              </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">연동신청 매장</label>
          <div class="col-sm-10 " >
                <!-- <select class="form-control" style="width:100%;max-width:400px;min-width:100px;" v-model="brandCode">
        <option v-for="(bditem, bdidx) in brandCodes" :key="bdidx" :value="bditem.brandCode">{{bditem.brandName}}</option>
      </select> -->
                <multiselect v-model="brandCode" :options="optionss" :allow-empty="false" placeholder="브랜드 검색" label="brnm" track-by="brnm" :show-labels="false" @input="searchStoreReserveList" style="width: 30%;"></multiselect>
                <!-- {{ brandCode.brnm }} -->
                <table class="table table-hover text-nowrap mt-2 text-center" style="width: 40%">
                  <thead>
                    <tr>
                      <th>매장</th>
                      <th>매장 운영상태</th>
                      <th>카카오톡 연동여부</th>
                      <th>신청</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(stitem, stidx) in kakaoStoreList" :key="stidx">
                    <td>{{stitem.storeName}}</td>
                    <td>
                        <input  type="button" class="btn" :class="stitem.enabled? 'btn-success':'btn-dark'" :value="stitem.enabled? '활성화' : '비활성화'" />
                    </td>
                    <td>
                      <input v-if="stitem.storePartnerLinkStatus != 'NONE' && stitem.storePartnerLinkStatus != null && stitem.storePartnerLinkStatus != undefined "
                             type="button" class="btn" :class="stitem.storePartnerLinkStatus =='LINKED'? 'btn-success':'btn-dark'"
                             :value="stitem.storePartnerLinkStatus =='LINKED'? '연동중' : '신청중'" />
                    </td>
                    <td>
                      <input v-if="stitem.enabled && (stitem.storePartnerLinkStatus=='NONE' || !stitem.storePartnerLinkStatus ) "
                             type="checkbox" class="chitem" :value="stitem.storeCode" >
                    </td>
                  </tr>
                  </tbody>
                </table>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">파트너명(업체명)</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" maxlength="50" v-model="partnerName" style="width: 90%"/>
            <div class="check-item-length ">{{ partnerName.length }}/50 자</div>
<!--                <button type="button" class="btn btn-default" disabled style="margin-right: 10px"></button>-->
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">사업자명</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" maxlength="20" v-model="businessName" style="width: 90%"/>
            <div class="check-item-length ">{{ businessName.length }}/20 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">대표자명</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" maxlength="50" v-model="representativeName" style="width: 90%"/>
            <div class="check-item-length ">{{ representativeName.length }}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">업태</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" maxlength="50" v-model="businessCategory" style="width: 90%"/>
            <div class="check-item-length ">{{ businessCategory.length }}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">종목</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" maxlength="50" v-model="businessCategoryItem" style="width: 90%"/>
            <div class="check-item-length ">{{ businessCategoryItem.length }}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">주소</label>
          <div class="col-sm-10">
            <div class="d-flex" style="margin-bottom: 4px">
              <input type="text" class="form-control mr-2 " disabled :value="zonecode" style="width: 25%" />
              <AddressSearch style="display:inline-flex" :detailAddress="detailAddress"
                             :onChange="onSetAddress" @detailaddrEvechiild="detailaddrEve" />
            </div>
            <div class="d-flex">
                <input type="text" class="form-control mr-2" disabled :value="roadAddress" style="width: 25%" />
                <input type="text" class="form-control" v-model="detailAddress" style="width: 25%" />
            </div>

          </div>
        </div>
      </div>
      <div class="box-footer text-center">
        <button type="button" class="btn btn-default" @click="goToList">취소</button>
        <button type="button" class="btn btn-default" style="margin-left:10px" @click="regKakaoReserveStore" >입점신청</button>
      </div>
    </div>

  </section>
</template>

<script>
import {
  rescode,
  menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {
  reqGet, reqPost
} from "../../../lib/request";
import {
  brandapi
} from "../../../lib/api";
import {
  DateFormat
} from "../../../lib/utility";
import Multiselect from "vue-multiselect";
import AddressSearch from "@/components/AddressSearch.vue";
import moment from "moment";
import $ from "jquery";
export default {
  components: {
    AddressSearch,
    Multiselect,
    Pagination,
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_KKOR,
      title: "",
    });

  },
  watch: {
    businessCode(val){
      val = val.replace(/[^0-9]/g, '').replace(/^(\d{3,3})(\d{2,2})(\d{0,5})$/, `$1-$2-$3`)
      this.businessCode = val
      this.selectedKakaoChannel = null;
      this.kakaoChannelList = [];
      this.successChk = false;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      exceldata: {
        animals: [{
          name: "cat",
          category: "animal"
        },
          {
            name: "dog",
            category: "animal"
          },
          {
            name: "pig",
            category: "animal"
          },
        ],
        pokemons: [{
          name: "pikachu",
          category: "pokemon"
        },
          {
            name: "Arbok",
            category: "pokemon"
          },
          {
            name: "Eevee",
            category: "pokemon"
          },
        ],
      },
      buttonDissabled : false,
      rejectReason : false,
      brandCodes: [],
      optionss: [],
      brandCode:"",
      roadAddress:"",
      zonecode : "",
      detailAddress:"",
      storeType:null,
      brandNameContaining:"",
      businessCode: "",
      selectedKakaoChannel:null,
      kakaoChannelList:[],
      kakaoStoreList:[],
      partnerName:"",
      businessName:"",
      representativeName:"",
      businessCategory:"",
      businessCategoryItem:"",

      successChk:  false,
      sido: "",
      sigungu: "",

    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
    },
  },
  mounted() {
    this.getBrandList();

  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },

    getBrandList() {
      reqGet(
        brandapi.base,
        brandapi.codequery + "?brandNameContaining=" + this.brandNameContaining,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.brandCodes = result.data.brandCodes;
            let pp = [];
            result.data.brandCodes.forEach(function (item) {
              var oo = {
                brnm: item.brandName,
                brandCode: item.brandCode
              }
              pp.push(oo)
            });
            this.optionss = pp;
            // this.optionss.unshift({
            //   brnm: "브랜드 선택",
            //   brandCode: ""
            // })
            // console.log(this.optionss)
            // if (this.brandCodes.length > 0) {
            //   this.brandCode = this.optionss[0].brandCode;
            // }
            this.getStoreType()
            console.log(this.brandCode);
            /*
            brandCodes[].brandCode    String    브랜드 코드
            brandCodes[].brandName    String    브랜드 명칭
            */
          }
        }
      );
    },
    getStoreType(type) {
      reqGet(
        brandapi.base,
        brandapi.storeType,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {

              this.storeType = result.data.types;
            }else {
              alert(result.message);
            }
          }
        }
      );
    },
    onSetAddress(addinfo, detailAddress) {
      console.log(addinfo)
      this.roadAddress = addinfo.address;
      this.zonecode = addinfo.zonecode;
      this.detailAddress = detailAddress;
    },
    detailaddrEve(detailAddress) {
      this.detailAddress = detailAddress;
    },
    searchBusinessCode(){
      this.kakaoChannelList = []
      this.selectedKakaoChannel = null
      // 예약상품을 노출할 채널을 선택해주세요.
      if(!this.businessCode){
        return
      }
      let chkVal = this.businessCode.replaceAll('-','')
      if(chkVal.length !== 10){
        alert("사업자번호가 올바르지 않습니다.")
        return
      }else{
        this.successChk = true;
        this.kakaoChannelList = []
        reqGet(
          brandapi.base,
          brandapi.kakaoChannelSearch+"?bizNumber="+this.businessCode,
          this.authToken,
          (result) => {
            if (result != null && result.code === rescode.REQ_SUCCESS) {
              if(result.data.content.length <1) {
                this.successChk = false;
                alert("사업자번호에 해당되는 카카오톡 채널이 존재하지 않습니다. 사업자번호가 올바른지 다시 한번 확인해주세요.")

                return
              }
              this.partnerName = ""
              this.kakaoChannelList = result.data.content;
              this.kakaoChannelList.unshift({id:null, searchId:null , name:"예약상품을 노출할 채널을 선택해주세요."})
              console.log(this.kakaoChannelList)
              this.$forceUpdate()
            }else {
              alert("채널 검색 실패");
            }

          }

        );
      }

    },
    initKakaoPartner(){
      if(this.selectedKakaoChannel !=null){
        this.partnerName = this.selectedKakaoChannel.name
      }
    },
    searchStoreReserveList(){
      this.kakaoStoreList = []
      console.log(this.brandCode)
      reqGet(
        brandapi.base,
        brandapi.kakaoReserveStoreList+"?brandCode="+this.brandCode.brandCode,
        this.authToken,
        (result) => {
          if (result != null && result.code === rescode.REQ_SUCCESS) {
            this.kakaoStoreList = result.data;
          }else {
            alert(result.message);
          }
        }
      );
    },

    //
    regKakaoReserveStore(){
      var chkStore = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();

      if(!this.businessCode){
        alert("사업자번호를 입력해주세요.")
        return
      }else if(this.businessCode.length<12){
        alert("사업자번호의 길이를 확인해주세요.")
        return
      }else if(!this.selectedKakaoChannel){
        alert("카카오톡 채널을 선택해주세요.")
        return
      }else if(chkStore.length < 1){
        alert("연동신청 매장을 선택해주세요.")
        return
      } else if(!this.partnerName){
        alert("파트너명(업체명)을 입력해주세요.")
        return
      } else if(this.partnerName.length>50){
        alert("파트너명(업체명)의 길이를 확인해주세요.")
        return
      } else if(!this.businessName){
        alert("사업자명을 입력해주세요.")
        return
      } else if(this.businessName.length>20){
        alert("사업자명의 길이를 확인해주세요.")
        return
      } else if(!this.representativeName){
        alert("대표자명을 입력해주세요.")
        return
      } else if(this.representativeName.length>50){
        alert("대표자명의 길이를 확인해주세요.")
        return
      } else if(!this.businessCategory){
        alert("업태를 입력해주세요.")
        return
      } else if(this.businessCategory.length>50){
        alert("업태의 길이를 확인해주세요.")
        return
      } else if(!this.businessCategoryItem){
        alert("종목을 입력해주세요.")
        return
      } else if(this.businessCategoryItem.length>50){
        alert("종목의 길이를 확인해주세요.")
        return
      } else if(!this.zonecode || !this.roadAddress || !this.detailAddress){
        alert("주소를 입력해주세요.")
        return
      }
      // else if(){
      //   alert("")
      // }

      reqPost(
        brandapi.base,
        brandapi.kakaoReserveStoreReg ,
        this.authToken,
        {
          storeCodes : chkStore, //스토어 코드 리스트
          partnerName : this.partnerName, // 파트너이름
          bizNumber : this.businessCode, // 사업자번호
          bizName : this.businessName, // 사업자명
          representativeName: this.representativeName, // 대표자명
          zipCode: this.zonecode,
          baseAddress: this.roadAddress,
          detailAddress: this.detailAddress,
          businessCategory: this.businessCategory,
          businessCategoryItem: this.businessCategoryItem,
          channelSearchId: this.selectedKakaoChannel.searchId,
          channelName: this.selectedKakaoChannel.name,
          brandCode:this.brandCode.brandCode,
          brandName:this.brandCode.brnm
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              alert(data.message)
              this.$router.push(this.routes.kakaoRequest)
            }else{
              alert(data.message)
            }
          }
        }
      );
    },
    goToList(){
      this.$router.push(this.routes.kakaoRequest)
    }
  },
};
</script>
<style scoped>
.check-item-length {
  border: solid 1px #d2d6de;
  height: 34px;
  align-content: center;
  padding-right: 16px;
  padding-left: 16px;
  text-align: center;
}
.d-flex{
  display: flex;
}


</style>
