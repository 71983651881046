<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
                    <option value="BrandCode">브랜드코드</option>
                    <option value="BrandNameContaining">브랜드명</option>
                    <option value="StoreCode">매장코드</option>
                    <option value="StoreNameContaining">매장명</option>
                    <option value="ManagerID">담당자ID</option>
                </select>
                <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                    <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button class="btn btn-default" @click="goNewStore">
            <i class="fa fa-plus"></i>등록
        </button>
        <button class="btn btn-default" style="margin-left:10px" @click="showRmvdlg">
            <i class="fa fa-fw fa-remove"></i>삭제
        </button>
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
        <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="downExcel" :disabled="buttonDissabled">
            <i class="fa fa-download"></i>Excel
        </button>
        <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="amManagement">
            <i class="fa fa-cogs"></i> 편의시설 관리
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" class="allch" @change="allckech($event)" />
                        </th>
                        <th class="text-center">번호</th>
                        <th class="text-center">코드</th>
                        <th class="text-center">분류</th>
                        <th class="text-center">브랜드명</th>
                        <th class="text-center">매장명</th>
                        <th class="text-center">운영상태</th>
                        <th class="text-center">바코드 노출</th>
                        <th class="text-center">등록일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(store, index) in content" v-bind:key="store.storeCode">
                        <td>
                            <input type="checkbox" class="chitem" :value="store.storeCode" v-model="checkedNames" />
                        </td>
                        <td class="text-center">{{ totalElements - (startnum + index) }}</td>
                        <td class="linkcmp text-center" @click="goEditStore(store)">{{ store.storeCode }}</td>
                        <td class="text-center">{{ getStoreType(store.basicInfo.storeType) }}</td>
                        <td class="text-center">{{ store.brandName }}</td>
                        <td class="text-center">{{ store.basicInfo.storeName }}</td>

                        <td class="linkcmp text-center" style="justify-content: center;" @click="onChangeStatus(store.enabled, store.storeCode, index)">
                            <ToggleBtn :value="store.enabled" />
                        </td>

                        <td class="linkcmp text-center" style="justify-content: center;" @click="onChangeBarcodeStatus(store.barcodeUse, store.storeCode, index)">
                            <ToggleBtn :value="store.barcodeUse" />
                        </td>

                        <td class="text-center">{{ viewDate(store.registeredDate) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>
    <div class="modal" v-if="rmvdlg" :style="{'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">삭제</div>
                <div class="modal-body form-horizontal">
                    한번 삭제된 매장은 복구할 수 없습니다. 삭제하시겠습니까?
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                        <button type="button" class="btn btn-default" @click="closeRmvdlg">취소</button>
                        <button type="button" class="btn btn-default" @click="delstore">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Amenity v-if="AmenityIf" @AmenityClose="AmenityClose"></Amenity>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    brandapi,
    goodsapi
} from "../../../lib/api";
import {
    DateFormat,
    getStoreTypeStr
} from "../../../lib/utility";
import $ from 'jquery'
import XLSX from "xlsx";
import ToggleBtn from "../../../components/ToggleBtn";
import Amenity from './component/Amenity';

export default {
    components: {
        Pagination,
        Amenity,
        ToggleBtn
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.Stlist_keywordType) {
                this.keywordType = localStorage.Stlist_keywordType;
            }
            if (localStorage.Stlist_keyword) {
                this.keyword = localStorage.Stlist_keyword;
            }
            if (localStorage.Stlist_Number) {
                this.pageNumber = parseInt(localStorage.Stlist_Number);
            }
            if (localStorage.Stlist_Size) {
                this.pageSize = parseInt(localStorage.Stlist_Size);
            }
        }
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_BRD,
            path2: menuval.LV2_BRD_STR,
            title: "매장 리스트",
        });
    },
    watch: {
        keywordType(newVal) {
            localStorage.Stlist_keywordType = newVal;
        },
        keyword(newkey) {
            localStorage.Stlist_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.Stlist_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.Stlist_Size = newsize;
        },
    },
    data() {
        return {
            AmenityIf: false,
            shownum: 10, //pagination에 보여지는 번호개수
            keywordType: "StoreNameContaining",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true
            checkedNames: [],
            rmvdlg: false,
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.Stlist_keywordType = this.keywordType;
            localStorage.Stlist_keyword = this.keyword;
            localStorage.Stlist_Number = this.pageNumber;
            localStorage.Stlist_Size = this.pageSize;
        }
        this.refreshPage();
    },
    methods: {
        amManagement() {
            this.AmenityIf = true;
        },
        AmenityClose() {
            this.AmenityIf = false;
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        allckech(event) {
            $('input:checkbox.chitem').prop('checked', event.target.checked);
        },
        closeRmvdlg() {
            this.rmvdlg = false;
        },
        showRmvdlg() {
            this.rmvdlg = true;
        },

        //매장 활성화/비활성화 API
        onChangeStatus(enabled, storeCode, index) {
            var url = brandapi.disable
            if (!enabled) {
                url = brandapi.enable
            }

            reqPost(
                brandapi.base,
                url,
                this.authToken, {
                    storeCode: storeCode
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content[index].enabled = !enabled;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },

        onChangeBarcodeStatus(barcodeUse, storeCode, index) {
          var url = brandapi.barcodeDisable
          if (!barcodeUse) {
            url = brandapi.barcodeEnable
          }

          reqPost(
            brandapi.base,
            url,
            this.authToken, {
              storeCode: storeCode
            },
            (result) => {
              if (result != null) {
                if (result.code == rescode.REQ_SUCCESS) {
                  this.content[index].barcodeUse = !barcodeUse;
                } else {
                  alert(result.message);
                }
              }
            }
          );
        },

        delstore() {
            // var delcodes = ["asdf", "asfasd"];
            var checkarray = $('input:checkbox.chitem:checked').map(function () {
                return this.value;
            }).get();
            reqPost(
                brandapi.base,
                brandapi.storelistdel,
                this.authToken, {
                    storeCodes: checkarray,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.closeRmvdlg();
                            this.refreshPage();
                        } else {
                            alert("삭제 실패되었습니다.");
                        }
                    }
                }
            );
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqGet(
                brandapi.base,
                brandapi.basicInfoStorepage +
                "?keywordType=" +
                this.keywordType +
                "&keyword=" +
                this.keyword +
                "&pageNumber=" +
                pageNumber +
                "&pageSize=" +
                this.pageSize,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                            /*
                            data.data.size : 페이지 사이즈
                            data.data.number : 페이지 번호
                            data.data.first : 첫 페이지일 경우 true
                            data.data.last : 마지막 페이지일 경우 true
                            data.data.empty : 조회된 아이템이 없으면 true
                             */
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goNewStore() {
            this.$router.push(this.routes.regstore);
        },
        goEditStore(stinfo) {
            this.$router.push(this.routes.editstore + "?stcode=" + stinfo.storeCode + "&brCode=" + stinfo.brandCode + "&brName=" + stinfo.brandName);
        },
        getStoreType(val) {
            return getStoreTypeStr(val);
        },
        convert(item) {
            return {
                A: item.storeCode,
                B: item.basicInfo.storeType,
                C: item.brandName,
                D: item.basicInfo.storeName,
                E: DateFormat(item.registeredDate, "YYYY-MM-DD HH:mm:00"),
                F: item.enabled ? 'ON':'OFF',
                G: item.address.mainAreaName,
                H: item.address.middleAreaName,
                J: item.address.smallAreaName
            };
        },
        downExcel() {
          this.buttonDissabled = true;
            reqGet(
                brandapi.base,
                brandapi.basicInfoStorelist +
                "?keywordType=" +
                this.keywordType +
                "&keyword=" +
                this.keyword,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            var pokemonWS = XLSX.utils.json_to_sheet(
                                [{
                                    A: "코드",
                                    B: "분류",
                                    C: "브랜드명",
                                    D: "매장명",
                                    E: "등록일",
                                    F: "운영상태",
                                    G:'시',
                                    H:'도',
                                    j:'군,구,동,명',
                                }, ], {
                                    skipHeader: true
                                }
                            );
                            XLSX.utils.sheet_add_json(pokemonWS, result.data.map(this.convert), {
                                skipHeader: true,
                                origin: "A2",
                            });
                            var wb = XLSX.utils.book_new(); // make Workbook of Excel

                            XLSX.utils.book_append_sheet(wb, pokemonWS, "매장 리스트");
                            // export Excel file
                            XLSX.writeFile(wb, "매장 리스트.xlsx");
                            this.buttonDissabled = false;
                        }
                    }
                }
            );
        },
    },
};
</script>
