<template>
<div>
    <section class="content">
        <div class="box box-solid">
            <div class="box-header">
                <h3 class="box-title">
                    {{ pageType ? '할인 쿠폰 등록':'할인 쿠폰 상세' }}
                </h3>
            </div>

            <div class="box-body form-horizontal">
                <div class="form-group">
                    <label class="col-sm-2 control-label">할인쿠폰이름</label>
                    <div class="col-sm-10">
                        <div class="input-group wid50F">
                            <input type="text" class="form-control" :disabled="disabledApproval" v-model="couponName" />
                            <div v-if="couponName === ''" class="redtext">할인 쿠폰 명을 입력 해주세요.</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">설명</label>
                    <div class="col-sm-10">
                        <div class="input-group wid50F">
                            <input type="text" class="form-control" v-model="description" :disabled="disabledApproval" />
                            <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                        </div>
                    </div>
                </div>
                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">쿠폰유형</label>
                    <div class="radio col-sm-10 text-left">
                        <label v-for="(item,idx) in couponArray" :key="idx" style="margin-right:20px">
                            <input type="radio" :value="item.type" v-model="couponType" @change="couponTypeChange" :disabled="disabledApproval" /> {{ item.name }}
                        </label>
                        <div v-if="coupponInputTextSee">
                            <input type="text" class="form-control wid50F" style="margin-top:10px" v-model="coupponInputText" :disabled="disabledApproval" />
                            <div v-if="coupponInputText === ''" class="redtext">쿠폰 코드를 입력해 주세요.</div>
                        </div>
                    </div>
                </div>

                <div class="form-group text-center" v-if="couponType === 'BULK'">
                    <label class="col-sm-2 control-label">중복발급</label>
                    <div class="radio col-sm-10 text-left">
                        <label style="margin-right:20px">
                            <input type="radio" value="overlapY" v-model="overlap" :disabled="disabledApproval" />중복 발급 가능
                        </label>
                        <label style="margin-right:20px">
                            <input type="radio" value="overlapN" v-model="overlap" :disabled="disabledApproval" /> 중복 발급 불가
                        </label>
                    </div>
                </div>

                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">발급매수</label>
                    <div class="radio col-sm-10 text-left">
                        <label style="margin-right:20px">
                            <input type="radio" value="limitN" v-model="numberIssued" :disabled="disabledApproval" />제한없음
                        </label>
                        <label style="margin-right:20px">
                            <input type="radio" value="limitY" v-model="numberIssued" :disabled="disabledApproval" /> 제한있음
                        </label>
                        <div class="input-group" style="display: inline-flex" v-if="numberIssued === 'limitY'">
                            <input type="number" class="form-control" v-model="issueLimitCount" :disabled="disabledApproval" />매
                        </div>
                        <div v-if="couponType === 'BULK' && numberIssued === 'limitN'" class="redtext">벌크형은 반드시 발급매수를 지정해 주세요.</div>
                    </div>

                </div>

                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">할인조건</label>
                    <div class="radio col-sm-10 text-left">
                        <label style="margin-right:20px">
                            <input type="radio" value="AMOUNT" v-model="saleType" :disabled="disabledApproval" />정액할인
                        </label>
                        <label style="margin-right:20px">
                            <input type="radio" value="PERCENT" v-model="saleType" :disabled="disabledApproval" /> 정률할인
                        </label>
                        <div class="input-group mt-15">
                            <div style="display: inline-flex">
                                <input type="number" class="form-control" v-model="discountNum" :disabled="disabledApproval" /> {{ saleType === 'AMOUNT' ? '원':'%' }}
                            </div>
                            <div class="redtext" v-if="discountNum === ''">
                                {{ saleType === 'AMOUNT' ? '정액할인을 적어주세요.' : '정률할인을 적어주세요.' }}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="form-group text-center" v-if="saleType === 'PERCENT'">
                    <label class="col-sm-2 control-label"></label>
                    <div class="radio col-sm-10 text-left">
                        <label style="margin-right:20px">
                            <input type="radio" value="false" v-model="salePercentType" :disabled="disabledApproval" />제한없음
                        </label>
                        <label style="margin-right:20px">
                            <input type="radio" value="true" v-model="salePercentType" :disabled="disabledApproval" /> 최대
                        </label>
                        <div class="input-group" v-if="salePercentType === 'true'">
                            <div style="display: inline-flex">
                                <input type="text" class="form-control" v-model="salePercentMax" :disabled="disabledApproval" /> 원
                            </div>
                            <div class="redtext" v-if="salePercentMax === null || salePercentMax === ''">
                                최대 금액을 적어주세요.
                            </div>
                        </div>
                    </div>

                </div>

                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">최소결제금액</label>
                    <div class="radio col-sm-10 text-left">
                        <label style="margin-right:20px">
                            <input type="radio" value="pmlimitN" v-model="minType" :disabled="disabledApproval" />제한없음
                        </label>
                        <label style="margin-right:20px">
                            <input type="radio" value="pmlimitY" v-model="minType" :disabled="disabledApproval" /> 제한있음
                        </label>
                        <div class="input-group" v-if="minType === 'pmlimitY'">
                            <div style="display: inline-flex">
                                <input type="number" class="form-control" v-model="minPrice" :disabled="disabledApproval" />
                                <p>원 이상 결제시</p>
                            </div>
                            <div class="redtext" v-if="minPrice === '' || minPrice === null">최소결제금액을 적어주세요.</div>
                        </div>

                    </div>

                </div>

                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">유효기간</label>
                    <div class="radio col-sm-10 text-left">
                        <div>
                            <label style="margin-right:20px">
                                <input type="radio" value="SetDayCount" v-model="availableDateSetting" :disabled="disabledApproval" />발급후
                            </label>
                            <div class="input-group" v-if="availableDateSetting === 'SetDayCount'">
                                <div style="display: inline-flex">
                                    <input type="number" class="form-control" v-model="availableDayCount" :disabled="disabledApproval" />일
                                </div>
                                <div class="redtext" v-if="availableDayCount === ''">
                                    유효기간을 적어주세요.
                                </div>
                            </div>
                        </div>
                        <div>
                            <label style="margin-right:20px">
                                <input type="radio" value="SetFromToDate" v-model="availableDateSetting" :disabled="disabledApproval" />기간지정
                            </label>
                            <div class="input-group" v-if="availableDateSetting === 'SetFromToDate'">
                                <div style="display: inline-flex">
                                    <DatePicker v-model="availableFromDate" v-if="!disabledApproval" />
                                    <input type="text" class="form-control" v-model="availableFromDate" v-else :disabled="disabledApproval" />
                                    <span class="input-group-addon" style="border:none;display:inline-flex">-</span>
                                    <DatePicker v-model="availableToDate" v-if="!disabledApproval" />
                                    <input type="text" class="form-control" v-model="availableToDate" v-else :disabled="disabledApproval" />
                                </div>
                                <div class="redtext" v-if="availableFromDate === '' || availableToDate === ''">
                                    기간을 선택해주세요.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">발급가능기간</label>
                    <div class="radio col-sm-10 text-left">
                        <div class="input-group" style="display: inline-flex">
                            <DatePicker v-model="issueStartDate" />
                            <span class="input-group-addon" style="border:none;display:inline-flex">-</span>
                            <DatePicker v-model="issueEndDate" />
                        </div>
                        <div class="redtext" v-if="issueStartDate === '' || issueEndDate === ''">발급 가능 기간을 선택해주세요.</div>
                    </div>

                </div>

                <div class="form-group text-center">
                    <label class="col-sm-2 control-label">사용조건</label>
                    <div class="radio col-sm-10 text-left">
                        <label v-for="(item,idx) in useTermsArray" :key="idx" style="margin-right:20px">
                            <input type="radio" :value="item.type" v-model="useTerms" @change="changeUseTerms" :disabled="disabledApproval" />{{ item.name }}
                        </label>
                        <div v-if="useTerms !== 'ALL'" class="mt2">

                            <button class="btn btn-default pull-right mb2" @click="goNewBrand" v-if="!disabledApproval">
                                <i class="fa fa-plus"></i>추가
                            </button>

                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center" v-for="(item,idx) in parentThead" :key="idx">
                                            {{ item }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,idx) in brandArr" :key="idx">
                                        <td class="text-center" v-if="useTerms !== 'GOODS'">{{ item.brandCode }}</td>
                                        <td class="text-center" v-if="useTerms === 'STORE'">{{ item.storeCode }}</td>
                                        <td class="text-center" v-if="useTerms !== 'GOODS'">{{ item.brandName }}</td>
                                        <td class="text-center" v-if="useTerms === 'STORE'">{{ item.storeName }}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'">{{ item.goodsId }}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'">{{ item.goodsName }}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'"> {{ item.discountCouponUsable === 'Usable' ? '사용가능':'사용불가' }} </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-social-icon" @click="deleteRow(idx)" v-if="!disabledApproval">
                                                <i class="fa fa-fw fa-trash-o"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- <div class="form-group text-center" v-if="disabledApproval">
                    <label class="col-sm-2 control-label">사이트노출</label>
                    <div class="radio col-sm-10 text-left">
                        <label style="margin-right:20px">
                            <input type="radio" value="active" v-model="siteExposure" />활성화
                        </label>
                        <label style="margin-right:20px">
                            <input type="radio" value="inactive" v-model="siteExposure" /> 비활성화
                        </label>
                    </div>
                </div> -->

                <div v-if="approvalType || disabledApproval" class="form-group text-center">
                    <label class="col-sm-2 control-label">요청자</label>
                    <div class="col-sm-10">
                        <div class="pull-left">
                            {{ requster }}
                        </div>
                    </div>
                </div>
                <div v-if="disabledApproval" class="form-group text-center">
                    <label class="col-sm-2 control-label">승인자</label>
                    <div class="col-sm-10">
                        <div class="pull-left">
                            {{ approver }}
                        </div>
                    </div>
                </div>

            </div>

            <div class="box-footer text-center">
                <button type="button" class="btn btn-default" style="margin-left: 15px" v-if="!approvalType && pageType" @click="discoutCoponAddMethods">
                    등록
                </button>
                <button type="button" class="btn btn-default" style="margin-left: 15px" v-if="approvalType && !pageType" @click="denyMethods">
                    불가
                </button>
                <button type="button" class="btn btn-default" style="margin-left: 15px" v-if="approvalType && !pageType" @click="verifyMethods" :disabled="verifyMethodsBtn">
                    승인
                </button>
                <button type="button" class="btn btn-default" style="margin-left: 15px" v-if="disabledApproval" @click="saveCoupon">저장</button>
            </div>
        </div>
    </section>
    <div class="modal" :style="showmodal && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h3>
                        {{ useTermsTitle }}
                    </h3>
                </div>
                <div class="box-body">
                    <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                        <select class="form-control" v-model="keywordTypeBrand" style="width:auto;margin-right:10px">
                            <option :value="item.value" v-for="(item,idx) in selectType" :key="idx">
                                {{ item.name }}
                            </option>
                        </select>
                        <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                            <input type="text" class="form-control" v-model="keywordBrand" @change="changeRadio" />
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-default" @click="changeRadio">검색</button>
                            </span>
                        </div>
                    </div>
                    <div class="box box-solid">
                        <div :class="[useTerms === 'STORE'? 'box-body table-responsive p-0 dragScroll' : 'box-body table-responsive p-0']">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <input type="checkbox" class="allmch" @change="allmckech($event)" />
                                        </th>
                                        <th v-for="(item,idx) in theadType" :key="idx" class="text-center">
                                            {{ item }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(brand,idx) in contentBrand" :key="idx">
                                        <td class="text-center">
                                            <!-- :disabled='brand.discountCouponUsable !== "Usable" && useTermsTitle === "상품 선택"' -->
                                            <input class="chmitem" type="checkbox" v-model="checkedBoxModel" :value="brand" :disabled="brand.discountCouponUsable === 'Unusable' && useTerms === 'GOODS'" />
                                            <input type="hidden" :value="brand.brandCode" v-if="useTerms !== 'GOODS'" />
                                            <input type="hidden" :value="brand.brandName" v-if="useTerms !== 'GOODS'" />
                                            <input type="hidden" :value="brand.storeCode" v-if="useTerms === 'STORE'" />
                                            <input type="hidden" :value="brand.basicInfo.storeName" v-if="useTerms === 'STORE'" />
                                            <input type="hidden" :value="brand.enabled" v-if="useTerms === 'STORE'" />
                                            <input type="hidden" :value="brand.goodsId" v-if="useTerms === 'GOODS'" />
                                            <input type="hidden" :value="brand.goodsName" v-if="useTerms === 'GOODS'" />
                                            <input type="hidden" :value="brand.discountCouponUsable" v-if="useTerms === 'GOODS'" />
                                        </td>
                                        <td class="text-center" v-if="useTerms !== 'GOODS'">{{brand.brandCode}}</td>
                                        <td class="text-center" v-if="useTerms === 'STORE'">{{brand.storeCode}}</td>
                                        <td class="text-center" v-if="useTerms !== 'GOODS'">{{brand.brandName}}</td>
                                        <td class="text-center" v-if="useTerms === 'STORE'">{{brand.basicInfo.storeName}}</td>
                                        <td class="text-center" v-if="useTerms === 'STORE'">{{brand.enabled ? '활성':'비활성'}}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'">{{brand.goodsId}}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'">{{brand.goodsName}}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'">{{ brand.discountCouponUsable === 'Usable' ? '사용가능':'사용불가능' }}</td>
                                        <td class="text-center" v-if="useTerms === 'GOODS'">{{brand.onSale ? '판매중':'판매종료'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="box-footer text-center" v-if="useTerms !== 'STORE'">
                            <Pagination :curpage="pageNumberB" :totalpages="totalPagesBrand" :visibles="shownum" :click="gotoPage" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="text-align:center !important">
                    <button type="button" class="btn btn-default" @click="closemodal">닫기</button>
                    <button type="button" class="btn btn-success" @click="brandAppend">추가</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</div>
</template>

<script>
import DatePicker from "../../components/DatePicker";
import {
    rescode,
    menuval
} from "../../lib/consts";
import {
    reqGet,
    reqPost
} from "../../lib/request";
import Pagination from "../../components/Pagination";
import routepath from "../../lib/routes";
import {
    brandapi,
    goodsapi,
    discoutCopon,
} from "../../lib/api";

export default {
    components: {
        DatePicker,
        Pagination
    },
    data() {
        return {
            labelChecked: '',
            couponNameEmpyt: false,
            salePercentMax: null,
            salePercentType: 'false',
            siteExposure: 'active',
            approver: '',
            copyCheckedType: '',
            copybrandArr: [],
            requster: '',
            useTermsTitle: '',
            shownum: 10,
            contentBrand: [],
            keywordTypeBrand: "NoKeyword",
            keywordBrand: "",
            totalPagesBrand: 0,
            pageNumberB: 1,
            pageSizeBrand: 20,
            showmodal: false,
            availableFromDate: '',
            availableToDate: '',
            brandArr: [],
            overlap: 'overlapN',
            coupponInputTextSee: false,
            coupponInputText: '',
            minPrice: null,
            availableDayCount: '',
            couponName: '',
            description: '',
            couponType: 'GENERAL',
            couponArray: [{
                    type: 'GENERAL',
                    name: '일반형',
                },
                {
                    type: 'MEMBERSHIP',
                    name: '회원가입형',
                },
                {
                    type: 'INPUT',
                    name: '입력형',
                },
                {
                    type: 'BULK',
                    name: '벌크형',
                }
            ],
            useTermsArray: [{
                    type: 'ALL',
                    name: '전체',
                },
                {
                    type: 'BRAND',
                    name: '브랜드별',
                },
                {
                    type: 'STORE',
                    name: '매장별',
                },
                {
                    type: 'GOODS',
                    name: '상품별',
                }
            ],
            theadType: [],
            selectType: [],
            numberIssued: 'limitN',
            issueLimitCount: 0,
            saleType: 'AMOUNT',
            discountNum: 0,
            minType: 'pmlimitN',
            availableDateSetting: 'SetDayCount',
            issueStartDate: '',
            issueEndDate: '',
            useTerms: 'ALL',
            parentThead: [],
            brandstores: [],
            load: false,
            couponRegisterId: 0,
            basicInfoCode: [],
            checkedBoxModel: [],
            verifyMethodsBtn: false,
            noRestart: true,
        }
    },
    beforeUpdate() {
        if (!this.noRestart) {
            return
        }
        if (this.$route.fullPath.includes('approval') === false && this.requster !== '') {
            this.labelChecked = '';
            this.couponNameEmpyt = false;
            this.salePercentMax = null;
            this.salePercentType = 'false';
            this.siteExposure = 'active';
            this.approver = '';
            this.copyCheckedType = '';
            this.copybrandArr = [];
            this.requster = '';
            this.useTermsTitle = '';
            this.shownum = 10;
            this.contentBrand = [];
            this.keywordTypeBrand = "NoKeyword";
            this.keywordBrand = "";
            this.totalPagesBrand = 0;
            this.pageNumberB = 1;
            this.pageSizeBrand = 20;
            this.showmodal = false;
            this.availableFromDate = '';
            this.availableToDate = '';
            this.brandArr = [];
            this.overlap = 'overlapN';
            this.coupponInputTextSee = false;
            this.coupponInputText = '';
            this.minPrice = null;
            this.availableDayCount = '';
            this.couponName = '';
            this.description = '';
            this.couponType = 'GENERAL';
            this.couponArray = [{
                    type: 'GENERAL',
                    name: '일반형',
                },
                {
                    type: 'MEMBERSHIP',
                    name: '회원가입형',
                },
                {
                    type: 'INPUT',
                    name: '입력형',
                },
                {
                    type: 'BULK',
                    name: '벌크형',
                }
            ];
            // console.log('ss')
            this.useTermsArray = [{
                    type: 'ALL',
                    name: '전체',
                },
                {
                    type: 'BRAND',
                    name: '브랜드별',
                },
                {
                    type: 'STORE',
                    name: '매장별',
                },
                {
                    type: 'GOODS',
                    name: '상품별',
                }
            ];
            this.theadType = [];
            this.selectType = [];
            this.numberIssued = 'limitN';
            this.issueLimitCount = 0;
            this.saleType = 'AMOUNT';
            this.discountNum = 0;
            this.minType = 'pmlimitN';
            this.availableDateSetting = 'SetDayCount';
            this.issueStartDate = '';
            this.issueEndDate = '';
            this.useTerms = 'ALL';
            this.parentThead = [];
            this.brandstores = [];
            this.load = false;
            this.couponRegisterId = 0;
            this.basicInfoCode = [];
            this.checkedBoxModel = [];
            this.verifyMethodsBtn = false;
            this.noRestart = false;
            this.$store.dispatch("doSetPath", {
                path1: menuval.LV1_DIS,
                path2: menuval.LV1_HOME,
                title: '할인 쿠폰 등록',
            });
        }
    },
    created() {

        this.$store.dispatch("doSetPath", {
            path1: this.pageType ? menuval.LV1_DIS : menuval.LV2_DIS,
            path2: this.pageType ? menuval.LV1_HOME : menuval.LV2_DIS,
            title: this.pageType ? '할인 쿠폰 등록' : '할인 쿠폰 상세',
        });

    },
    mounted() {
        if (this.$route.query.approval === 'false') {
            this.loadIssuedData();
        } else if (this.$route.query.approval === 'true') {
            this.loadApproved();
        }
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        pageType() {
            return this.$route.query.id === undefined;
        },
        approvalType() {
            return this.$route.query.approval === 'false';
        },
        disabledApproval() {
            return this.$route.query.approval === 'true';
        }
    },
    methods: {
        denyMethods() {
            reqPost(
                discoutCopon.base,
                discoutCopon.deny,
                this.authToken, {
                    couponRegisterId: this.couponRegisterId,
                    deniedReason: '쿠폰 승인 거부 이유'
                },

                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('쿠폰 승인이 거절 되었습니다.');
                            this.$router.push("discountApprovalPage");
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },

        verifyMethods() {
            this.verifyMethodsBtn = true;
            if (this.couponName === '' || this.issueStartDate === '' || this.issueEndDate === '' || this.discountNum === '') {
                return;
            }

            let stop = false;
            if (this.minType === 'pmlimitY') {
                if (this.minPrice === null || this.minPrice === '') {
                    stop = true;
                }
            }

            if (this.coupponInputTextSee && this.coupponInputText === '') {
                stop = true;
            }

            if (this.couponType === 'BULK' && this.numberIssued === 'limitN') {
                stop = true;
            }

            if (this.availableDateSetting === 'SetDayCount') {
                if (this.availableDayCount === '') {
                    stop = true;
                }
            } else if (this.availableDateSetting === 'SetFromToDate') {
                if (this.availableFromDate === '' || this.availableToDate === '') {
                    stop = true;
                }
            }
            if (this.salePercentType === 'true') {
                if (this.salePercentMax === null || this.salePercentMax === '') {
                    stop = true;
                }
            }

            if (stop === true) {
                this.verifyMethodsBtn = false;
                return;
            }
            let stringbrandCode = [];
            let stringStoreCode = [];
            let stringGoodsCode = [];
            if (this.useTerms === 'BRAND') {
                this.brandArr.forEach(el => {
                    stringbrandCode.push(el.brandCode);
                });
            } else if (this.useTerms === 'STORE') {
                this.brandArr.forEach(el => {
                    stringStoreCode.push(el.storeCode);
                });
            } else if (this.useTerms === 'GOODS') {
                this.brandArr.forEach(el => {
                    stringGoodsCode.push(el.goodsId);
                });
            }

            reqPost(
                discoutCopon.base,
                discoutCopon.verify,
                this.authToken, {
                    basicInfo: {
                        couponName: this.couponName,
                        description: this.description,
                        couponType: this.couponType,
                        duplication: this.overlap === 'overlapY' ? true : false,
                        couponNumber: this.coupponInputText,
                        issueLimit: this.numberIssued === 'limitN' ? false : true,
                        issueLimitCount: this.issueLimitCount,
                        saleType: this.saleType,
                        salePrice: this.saleType === 'AMOUNT' ? String(this.discountNum) : null,
                        salePercent: this.saleType !== 'AMOUNT' ? String(this.discountNum) : null,
                        salePercentType: this.salePercentType === 'true' ? true : false,
                        salePercentMax: this.salePercentMax,
                        minType: this.minType === 'pmlimitN' ? false : true,
                        minPrice: this.minPrice,
                        availableDateSetting: this.availableDateSetting,
                        availableDayCount: Number(this.availableDayCount),
                        availableFromDate: this.availableFromDate,
                        availableToDate: this.availableToDate,
                        issueStartDate: this.issueStartDate,
                        issueEndDate: this.issueEndDate,
                        availableType: this.useTerms,
                        brandCode: this.useTerms === 'BRAND' ? stringbrandCode.toString() : '',
                        storeCode: this.useTerms === 'STORE' ? stringStoreCode.toString() : '',
                        goodsCode: this.useTerms === 'GOODS' ? stringGoodsCode.toString() : '',
                    },
                    couponRegisterId: this.couponRegisterId,
                },

                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('승인되었습니다.');
                            this.verifyMethodsBtn = false;
                            this.$router.push("discountApprovalPage");
                        } else {
                            alert(result.message);
                            this.verifyMethodsBtn = false;
                        }
                    }
                }
            );
        },
        discoutCoponAddMethods() {

            if (this.couponName === '' || this.issueStartDate === '' || this.issueEndDate === '' || this.discountNum === '') {
                return;
            }

            let stop = false;
            if (this.minType === 'pmlimitY') {
                if (this.minPrice === null || this.minPrice === '') {
                    stop = true;
                }
            }

            if (this.coupponInputTextSee && this.coupponInputText === '') {
                stop = true;
            }

            if (this.couponType === 'BULK' && this.numberIssued === 'limitN') {
                stop = true;
            }

            if (this.availableDateSetting === 'SetDayCount') {
                if (this.availableDayCount === '') {
                    stop = true;
                }
            } else if (this.availableDateSetting === 'SetFromToDate') {
                if (this.availableFromDate === '' || this.availableToDate === '') {
                    stop = true;
                }
            }
            if (this.salePercentType === 'true') {
                if (this.salePercentMax === null || this.salePercentMax === '') {
                    stop = true;
                }
            }
            if (stop === true) {
                return;
            }

            let stringbrandCode = [];
            let stringStoreCode = [];
            let stringGoodsCode = [];
            if (this.useTerms === 'BRAND') {
                this.brandArr.forEach(el => {
                    stringbrandCode.push(el.brandCode);
                });
            } else if (this.useTerms === 'STORE') {
                this.brandArr.forEach(el => {
                    stringStoreCode.push(el.storeCode);
                });
            } else if (this.useTerms === 'GOODS') {
                this.brandArr.forEach(el => {
                    stringGoodsCode.push(el.goodsId);
                });
            }
            reqPost(
                discoutCopon.base,
                discoutCopon.new_coupon,
                this.authToken, {
                    basicInfo: {
                        couponName: this.couponName,
                        description: this.description,
                        couponType: this.couponType,
                        duplication: this.overlap === 'overlapY' ? true : false,
                        couponNumber: this.coupponInputText,
                        issueLimit: this.numberIssued === 'limitN' ? false : true,
                        issueLimitCount: this.issueLimitCount,
                        saleType: this.saleType,
                        salePrice: this.saleType === 'AMOUNT' ? String(this.discountNum) : null,
                        salePercent: this.saleType !== 'AMOUNT' ? String(this.discountNum) : null,
                        salePercentType: this.salePercentType === 'true' ? true : false,
                        salePercentMax: this.salePercentMax,
                        minType: this.minType === 'pmlimitN' ? false : true,
                        minPrice: this.minPrice,
                        availableDateSetting: this.availableDateSetting,
                        availableDayCount: Number(this.availableDayCount),
                        availableFromDate: this.availableFromDate,
                        availableToDate: this.availableToDate,
                        issueStartDate: this.issueStartDate,
                        issueEndDate: this.issueEndDate,
                        availableType: this.useTerms,
                        brandCode: this.useTerms === 'BRAND' ? stringbrandCode.toString() : '',
                        storeCode: this.useTerms === 'STORE' ? stringStoreCode.toString() : '',
                        goodsCode: this.useTerms === 'GOODS' ? stringGoodsCode.toString() : '',
                    },
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('등록되었습니다.');
                            this.$router.push('/discountApprovalPage');
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        brandAppend() {
            let nopush = false;
            let brn = '';
            var brandCode = $('input:checkbox.chmitem:checked').next().map(function () {
                return this.value;
            }).get();
            var brandName = $('input:checkbox.chmitem:checked').next().next().map(function () {
                return this.value;
            }).get();
            var storeCode = $('input:checkbox.chmitem:checked').next().next().next().map(function () {
                return this.value;
            }).get();
            var storeName = $('input:checkbox.chmitem:checked').next().next().next().next().map(function () {
                return this.value;
            }).get();
            var enabled = $('input:checkbox.chmitem:checked').next().next().next().next().next().map(function () {
                return this.value;
            }).get();
            var goodsId = $('input:checkbox.chmitem:checked').next().map(function () {
                return this.value;
            }).get();
            var goodsName = $('input:checkbox.chmitem:checked').next().next().map(function () {
                return this.value;
            }).get();
            var goodsDiscountCouponUsable = $('input:checkbox.chmitem:checked').next().next().next().map(function () {
                return this.value;
            }).get();
            // this.brandArr = [];
            if (this.useTerms === 'BRAND') {

                brandCode.forEach((el, idx) => {
                    this.brandArr.forEach((ba, bsidx) => {
                        if (el === ba.brandCode) {
                            nopush = true;
                            brn = brandName[idx];
                            this.checkedBoxModel.forEach((el, idx) => {
                                if (el.brandName === brandName[idx]) {
                                    this.checkedBoxModel.splice(idx, 1)
                                }
                            })
                        }
                    })
                    if (nopush === true) {
                        return;
                    }
                    this.brandArr.push({
                        brandCode: el,
                        brandName: brandName[idx]
                    })
                });
            } else if (this.useTerms === 'STORE') {
                storeCode.forEach((el, idx) => {
                    this.brandArr.forEach((ba, bsidx) => {
                        if (el === ba.storeCode) {
                            nopush = true;
                            brn = storeName[idx];
                            this.checkedBoxModel.forEach((el, idx) => {
                                if (el.storeCode === storeCode[idx]) {
                                    this.checkedBoxModel.splice(idx, 1)
                                }
                            })
                        }
                    });
                    if (nopush === true) {
                        return;
                    }
                    this.brandArr.push({
                        brandCode: brandCode[idx],
                        storeCode: storeCode[idx],
                        brandName: brandName[idx],
                        storeName: storeName[idx],
                        enabled: enabled[idx],
                    })
                });
            } else {
                goodsId.forEach((el, idx) => {
                    this.brandArr.forEach((ba, bsidx) => {
                        if (el === ba.goodsId) {
                            nopush = true;
                            brn = goodsName[idx];
                            this.checkedBoxModel.forEach((el, idx) => {
                                if (el.goodsId === goodsId[idx]) {
                                    this.checkedBoxModel.splice(idx, 1)
                                }
                            })
                        }
                    });
                    if (nopush === true) {
                        return;
                    }
                    console.log(this.brandArr)
                    this.brandArr.push({
                        goodsId: goodsId[idx],
                        goodsName: goodsName[idx],
                        discountCouponUsable: goodsDiscountCouponUsable[idx],
                    })
                });
            }
            if (nopush === true) {
                alert(brn + '중복 입니다.');
                return
            }
            this.keywordTypeBrand = "NoKeyword";
            this.closemodal();

        },

        couponTypeChange(e) {
            if (e.target._value === 'INPUT') {
                this.coupponInputTextSee = true;
            } else {
                this.coupponInputTextSee = false;
            }
        },
        changeRadio() {
            if (this.useTerms === 'BRAND') {
                this.load = false;
                this.brandPage();
            } else if (this.useTerms === 'STORE') {
                this.load = false;
                this.searchbrandstore();
            } else {
                this.load = false;
                this.searchGoods();
            }
        },
        goNewBrand() {
            $('input:checkbox.chmitem').prop('checked', false);
            this.showmodal = true;
            if (this.useTerms === 'BRAND') {
                this.load = false;
                // this.brandPage();
            } else if (this.useTerms === 'STORE') {
                this.load = false;
                // this.contentBrand = [];
            } else {
                this.load = false;
                // this.searchGoods();
            }
            this.setTable();
            //this.changeUseTerms();

        },
        closemodal() {
            this.showmodal = false;
            this.keywordTypeBrand = "NoKeyword";
        },
        gotoPage(pnum) {
            this.pageNumberB = pnum;
            if (this.useTerms === 'BRAND') {
                this.load = false;
                this.brandPage(false);
            } else if (this.useTerms === 'STORE') {
                this.load = false;
                this.searchbrandstore();
            } else {
                this.load = false;
                this.searchGoods(false);
            }
        },
        allmckech() {
            $('input:checkbox.chmitem').prop('checked', event.target.checked);
        },
        changeUseTerms() {

            this.setTable();
            if (!this.load) {
                this.contentBrand = [];
                this.brandArr = [];
            } else {
                this.load = false;
            }

        },
        setTable(){
            this.theadType = [], this.selectType = [], this.parentThead = [];
            this.keywordBrand = '';
            this.keywordTypeBrand = "NoKeyword";
            if (this.useTerms === 'BRAND') {
                this.useTermsTitle = '브랜드 선택';
                this.theadType = ['브랜드코드', '브랜드명'];
                this.parentThead = ['브랜드코드', '브랜드명', '삭제'];
                this.selectType.push({
                    name: '브랜드코드',
                    value: 'BrandCode',
                }, {
                    name: '브랜드명',
                    value: 'BrandNameContaining',
                })
            } else if (this.useTerms === 'STORE') {
                this.useTermsTitle = '매장 선택';
                this.theadType = ['브랜드코드', '매장코드', '브랜드명', '매장명', '운영상태'];
                this.parentThead = ['브랜드코드', '매장코드', '브랜드명', '매장명', '삭제'];
                this.selectType.push({
                    name: '브랜드코드',
                    value: 'BrandCode',
                }, {
                    name: '브랜드명',
                    value: 'BrandNameContaining',
                }, {
                    name: '매장코드',
                    value: 'StoreCode',
                }, {
                    name: '매장명',
                    value: 'StoreNameContaining',
                })
            } else if (this.useTerms === 'GOODS') {
                this.useTermsTitle = '상품 선택';
                this.theadType = ['상품코드', '상품명', '할인쿠폰', '판매상태'];
                this.parentThead = ['상품코드', '상품명', '할인쿠폰 사용여부', '삭제'];
                this.selectType.push({
                    name: '상품코드',
                    value: 'GoodsId',
                }, {
                    name: '상품명',
                    value: 'GoodsNameContaining',
                })
            }
        },
        loadBrandPage() {
            // if (this.load) {
            reqPost(
                brandapi.base,
                brandapi.brandCodesList,
                this.authToken, {
                    brandCodes: this.basicInfoCode,
                },

                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {

                            this.useTerms = 'BRAND';
                            this.changeUseTerms();
                            this.brandArr = result.data.brandCodes;
                            this.copybrandArr = JSON.parse(JSON.stringify(this.brandArr));
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
            // }
        },
        brandPage(flag) {
            var pageNumberB = 0;
            if (!flag) {
                pageNumberB = this.pageNumberB - 1;
            } else {
                this.pageNumberB = 1;
            }

            reqGet(
                brandapi.base,
                brandapi.brandpage +
                "?keywordType=" +
                this.keywordTypeBrand +
                "&keyword=" +
                this.keywordBrand +
                "&pageNumber=" +
                pageNumberB +
                "&pageSize=" +
                this.pageSizeBrand,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.contentBrand = data.data.content;
                            this.totalPagesBrand = data.data.totalPages; //전체 페이지 수
                            this.totalElementsBrand = data.data.totalElements; //전체 아이템 수
                            // this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                            // if (this.load) {
                            //     this.useTerms = 'BRAND';
                            //     this.changeUseTerms();
                            //     let fakeArr = this.basicInfoCode;
                            //     let a = [];
                            //     fakeArr.forEach(el => {

                            //         this.contentBrand.find(v => {
                            //             if (el === v.brandCode) {
                            //                 a.push(v);
                            //                 return a;
                            //             }
                            //         });
                            //         return a;
                            //     });

                            // }
                        } else {
                            this.contentBrand = [];
                            this.totalPagesBrand = 0; //전체 페이지 수
                            this.totalElementsBrand = 0; //전체 아이템 수
                            // this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.contentBrand = [];
                        this.totalPagesBrand = 0; //전체 페이지 수
                        this.totalElementsBrand = 0; //전체 아이템 수
                        // this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        loadStore() {
            reqPost(
                brandapi.base,
                brandapi.admofStores,
                this.authToken, {
                    storeCodes: this.basicInfoCode,
                    filter: {
                        "enabledStoresOnly": null
                    },
                },

                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {

                            this.useTerms = 'STORE';
                            this.changeUseTerms();
                            this.brandArr = [];
                            result.data.byBrands.forEach((el, idx) => {
                                el.stores.forEach((str) => {
                                    this.brandArr.push({
                                        brandCode: el.brandCode,
                                        brandName: el.brandName,
                                        storeCode: str.storeCode,
                                        storeName: str.storeName,
                                        discountCouponUsable: str.discountCouponUsable,
                                    })
                                });
                            });
                            this.copybrandArr = JSON.parse(JSON.stringify(this.brandArr));
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        searchbrandstore(load) {
            this.contentBrand = [];
            reqGet(
                brandapi.base,
                brandapi.basicInfoStorelist + "?keywordType=" + this.keywordTypeBrand + "&keyword=" + this.keywordBrand,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.contentBrand = result.data;
                            // if (this.load) {
                            //     this.useTerms = 'STORE';
                            //     this.changeUseTerms();
                            //     let fakeArr = this.basicInfoCode;
                            //     let a = [];
                            //     fakeArr.forEach(el => {

                            //         this.contentBrand.find(v => {
                            //             if (el === v.storeCode) {
                            //                 a.push(v);
                            //                 return a;
                            //             }
                            //         });
                            //         return a;
                            //     });
                            //     a.forEach((el, idx) => {
                            //         this.brandArr.push({
                            //             brandCode: el.brandCode,
                            //             storeCode: el.storeCode,
                            //             brandName: el.brandName,
                            //             storeName: el.basicInfo.storeName,
                            //             enabled: el.enabled,
                            //         })
                            //     });
                            //     this.copybrandArr = JSON.parse(JSON.stringify(this.brandArr));
                            // }
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );

        },
        //goodsManageList
        loadGoods() {
            reqPost(
                goodsapi.base,
                goodsapi.goodsManageList,
                this.authToken, {
                    goodsIds: this.basicInfoCode,
                },

                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {

                            this.useTerms = 'GOODS';
                            this.changeUseTerms();
                            this.brandArr = [];
                            result.data.goodsList.forEach((goods, idx) => {
                                this.brandArr.push({
                                    goodsId: goods.goodsId,
                                    discountCouponUsable: goods.discountCouponUsable,
                                    goodsName: goods.goodsName,
                                })
                            });
                            this.copybrandArr = JSON.parse(JSON.stringify(this.brandArr));
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        searchGoods(flag) {
            var pageNumberB = 0;
            console.log(this.keywordTypeBrand);
            if (!flag) {
                pageNumberB = this.pageNumberB - 1;
            } else {
                this.pageNumberB = 1;
            }
            reqGet(
                goodsapi.base,
                goodsapi.goodsadmpage +
                "?keywordType=" +
                this.keywordTypeBrand +
                "&keyword=" +
                this.keywordBrand +
                "&pageNumber=" +
                pageNumberB +
                "&pageSize=" +
                this.pageSizeBrand + "&all=" + true + "&onSale=" + true,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.contentBrand = [];
                            this.contentBrand = result.data.content;
                            this.totalPagesBrand = result.data.totalPages; //전체 페이지 수
                            this.totalElementsBrand = result.data.totalElements; //전체 아이템 수
                            // if (this.load) {
                            //     this.useTerms = 'GOODS';
                            //     this.changeUseTerms();
                            //     let fakeArr = this.basicInfoCode;

                            //     let a = [];
                            //     fakeArr.forEach(el => {

                            //         this.contentBrand.find(v => {
                            //             if (Number(el) === v.goodsId) {
                            //                 a.push(v);
                            //                 return a;
                            //             }
                            //         });
                            //         return a;
                            //     });
                            //     console.log(a);
                            //     a.forEach((el, idx) => {
                            //         this.brandArr.push({
                            //             goodsId: el.goodsId,
                            //             onSale: el.onSale,
                            //             goodsName: el.goodsName,
                            //         })
                            //     });
                            //     this.copybrandArr = JSON.parse(JSON.stringify(this.brandArr));
                            // }
                        } else {
                            this.contentBrand = [];
                            this.totalPagesBrand = 0; //전체 페이지 수
                            this.totalElementsBrand = 0; //전체 아이템 수
                            alert(result.message);
                        }
                    } else {
                        this.content = [];
                        this.totalPagesBrand = 0; //전체 페이지 수
                        this.totalElementsBrand = 0; //전체 아이템 수
                    }
                }
            );
        },
        deleteRow(idx) {
            this.brandArr.splice(idx, 1);
        },
        loadIssuedData() {
            reqGet(
                discoutCopon.base,
                discoutCopon.newCoupon_info + "?couponRegisterId=" + this.$route.query.id,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            let bas = result.data.basicInfo;
                            this.couponName = bas.couponName;
                            this.description = bas.description;
                            this.couponType = bas.couponType;
                            this.overlap = bas.duplication === true ? 'overlapY' : 'overlapN';
                            this.coupponInputText = bas.couponNumber;
                            this.numberIssued = bas.issueLimit === false ? 'limitN' : 'limitY';
                            this.issueLimitCount = bas.issueLimitCount;
                            this.saleType = bas.saleType === 'PERCENT' ? 'PERCENT' : 'AMOUNT';
                            this.discountNum = bas.saleType === 'PERCENT' ? Number(bas.salePercent) : Number(bas.salePrice);
                            this.salePercentType = bas.salePercentType === true ? 'true' : 'false';
                            this.salePercentMax = bas.salePercentMax;
                            this.minType = bas.minType === false ? 'pmlimitN' : 'pmlimitY';
                            this.minPrice = Number(bas.minPrice);
                            this.availableDateSetting = bas.availableDateSetting;
                            this.availableDayCount = bas.availableDayCount;
                            this.availableFromDate = bas.availableFromDate;
                            this.availableToDate = bas.availableToDate;
                            this.issueStartDate = bas.issueStartDate;
                            this.issueEndDate = bas.issueEndDate;
                            this.useTerms = bas.availableType;

                            this.couponRegisterId = result.data.couponRegisterId;
                            this.requster = result.data.requestedBy

                            this.basicInfoCode = [];
                            if (result.data.basicInfo.brandCode !== null && result.data.basicInfo.brandCode !== '') {
                                if (result.data.basicInfo.brandCode.includes(',') === false) {
                                    this.basicInfoCode.push(result.data.basicInfo.brandCode);
                                } else {
                                    result.data.basicInfo.brandCode.split(',').forEach(el => {
                                        this.basicInfoCode.push(el)
                                    });
                                }
                                this.load = true;
                                this.loadBrandPage();
                            }
                            if (result.data.basicInfo.storeCode !== null && result.data.basicInfo.storeCode !== '') {
                                this.labelChecked = 'STORE';
                                if (result.data.basicInfo.storeCode.includes(',') === false) {
                                    this.basicInfoCode.push(result.data.basicInfo.storeCode);
                                } else {
                                    result.data.basicInfo.storeCode.split(',').forEach(el => {
                                        this.basicInfoCode.push(el)
                                    });
                                }
                                this.load = true;
                                this.loadStore();
                            }
                            if (result.data.basicInfo.goodsCode !== null && result.data.basicInfo.goodsCode !== '') {
                                this.labelChecked = 'GOODS';
                                if (result.data.basicInfo.goodsCode.includes(',') === false) {
                                    this.basicInfoCode.push(result.data.basicInfo.goodsCode);
                                } else {
                                    result.data.basicInfo.goodsCode.split(',').forEach(el => {
                                        this.basicInfoCode.push(el)
                                    });
                                }

                                this.load = true;
                                this.loadGoods();
                            }

                        } else {
                            alert(result.message);
                        }
                    }
                }
            );

        },
        loadApproved() {
            reqGet(
                discoutCopon.base,
                discoutCopon.coupon_info + "?couponId=" + this.$route.query.id,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            let bas = result.data.basicInfo;
                            this.couponName = bas.couponName;
                            this.description = bas.description;
                            this.couponType = bas.couponType;
                            this.overlap = bas.duplication === true ? 'overlapY' : 'overlapN';
                            this.coupponInputText = bas.couponNumber;
                            this.numberIssued = bas.issueLimit === false ? 'limitN' : 'limitY';
                            this.issueLimitCount = bas.issueLimitCount;
                            this.saleType = bas.saleType === 'PERCENT' ? 'PERCENT' : 'AMOUNT';
                            this.discountNum = bas.saleType === 'PERCENT' ? Number(bas.salePercent) : Number(bas.salePrice);
                            this.salePercentType = bas.salePercentType === true ? 'true' : 'false';
                            this.salePercentMax = bas.salePercentMax;
                            this.minType = bas.minType === false ? 'pmlimitN' : 'pmlimitY';
                            this.minPrice = Number(bas.minPrice);
                            this.availableDateSetting = bas.availableDateSetting;
                            this.availableDayCount = bas.availableDayCount;
                            this.availableFromDate = bas.availableFromDate;
                            this.availableToDate = bas.availableToDate;
                            this.issueStartDate = bas.issueStartDate;
                            this.issueEndDate = bas.issueEndDate;
                            this.useTerms = bas.availableType;

                            this.coupponInputTextSee = bas.couponType === 'INPUT' ? true : false;
                            this.couponRegisterId = result.data.couponId;
                            this.requster = result.data.requestedBy;
                            this.approver = result.data.verifiedBy;

                            if (result.data.basicInfo.brandCode !== null && result.data.basicInfo.brandCode !== '') {
                                if (result.data.basicInfo.brandCode.includes(',') === false) {
                                    this.basicInfoCode.push(result.data.basicInfo.brandCode);
                                } else {
                                    result.data.basicInfo.brandCode.split(',').forEach(el => {
                                        this.basicInfoCode.push(el)
                                    });
                                }
                                this.load = true;
                                this.loadBrandPage();
                            }
                            if (result.data.basicInfo.storeCode !== null && result.data.basicInfo.storeCode !== '') {
                                this.labelChecked = 'STORE';
                                if (result.data.basicInfo.storeCode.includes(',') === false) {
                                    this.basicInfoCode.push(result.data.basicInfo.storeCode);
                                } else {
                                    result.data.basicInfo.storeCode.split(',').forEach(el => {
                                        this.basicInfoCode.push(el)
                                    });
                                }
                                this.load = true;
                                this.loadStore();
                            }
                            if (result.data.basicInfo.goodsCode !== null && result.data.basicInfo.goodsCode !== '') {
                                this.labelChecked = 'GOODS';
                                if (result.data.basicInfo.goodsCode.includes(',') === false) {
                                    this.basicInfoCode.push(result.data.basicInfo.goodsCode);
                                } else {
                                    result.data.basicInfo.goodsCode.split(',').forEach(el => {
                                        this.basicInfoCode.push(el)
                                    });
                                }
                                console.log(this.basicInfoCode)
                                this.load = true;
                                this.loadGoods();
                            }

                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        saveCoupon() {
            if (this.couponName === '' || this.issueStartDate === '' || this.issueEndDate === '' || this.discountNum === '') {
                return;
            }

            let stop = false;
            if (this.minType === 'pmlimitY') {
                if (this.minPrice === null || this.minPrice === '') {
                    stop = true;
                }
            }

            if (this.coupponInputTextSee && this.coupponInputText === '') {
                stop = true;
            }

            if (this.availableDateSetting === 'SetDayCount') {
                if (this.availableDayCount === '') {
                    stop = true;
                }
            } else if (this.availableDateSetting === 'SetFromToDate') {
                if (this.availableFromDate === '' || this.availableToDate === '') {
                    stop = true;
                }
            }
            if (this.salePercentType === 'true') {
                if (this.salePercentMax === null || this.salePercentMax === '') {
                    stop = true;
                }
            }
            if (stop === true) {
                return;
            }
            let stringbrandCode = [];
            let stringStoreCode = [];
            let stringGoodsCode = [];
            if (this.useTerms === 'BRAND') {
                this.brandArr.forEach(el => {
                    stringbrandCode.push(el.brandCode);
                });
            } else if (this.useTerms === 'STORE') {
                this.brandArr.forEach(el => {
                    stringStoreCode.push(el.storeCode);
                });
            } else if (this.useTerms === 'GOODS') {
                this.brandArr.forEach(el => {
                    stringGoodsCode.push(el.goodsId);
                });
            }

            reqPost(
                discoutCopon.base,
                discoutCopon.update,
                this.authToken, {
                    basicInfo: {
                        couponName: this.couponName,
                        description: this.description,
                        couponType: this.couponType,
                        duplication: this.overlap === 'overlapY' ? true : false,
                        couponNumber: this.coupponInputText,
                        issueLimit: this.numberIssued === 'limitN' ? false : true,
                        issueLimitCount: this.issueLimitCount,
                        saleType: this.saleType,
                        salePrice: this.saleType === 'AMOUNT' ? String(this.discountNum) : null,
                        salePercent: this.saleType !== 'AMOUNT' ? String(this.discountNum) : null,
                        salePercentType: this.salePercentType === 'true' ? true : false,
                        salePercentMax: this.salePercentMax,
                        minType: this.minType === 'pmlimitN' ? false : true,
                        minPrice: this.minPrice,
                        availableDateSetting: this.availableDateSetting,
                        availableDayCount: Number(this.availableDayCount),
                        availableFromDate: this.availableFromDate,
                        availableToDate: this.availableToDate,
                        issueStartDate: this.issueStartDate,
                        issueEndDate: this.issueEndDate,
                        availableType: this.useTerms,
                        brandCode: this.useTerms === 'BRAND' ? stringbrandCode.toString() : '',
                        storeCode: this.useTerms === 'STORE' ? stringStoreCode.toString() : '',
                        goodsCode: this.useTerms === 'GOODS' ? stringGoodsCode.toString() : '',
                    },
                    'couponId': this.couponRegisterId,
                },

                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('저장되었습니다.');
                            // this.$router.push("discountApprovalPage");
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        }

    }
}
</script>

<style scoped>
.wid50F {
    width: 50%;
}

th {
    background-color: #dee2e685;
}

.mt2 {
    margin-top: 20px;
}

.mb2 {
    margin-bottom: 20px;
}

.dragScroll {
    max-height: 300px;
    overflow-y: scroll;
}

.redtext {
    color: red;
}

.mt-15 {
    margin-top: 15px;
}
</style>
