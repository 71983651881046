<template>
  <div class="wrapper">
    <DashHeader />
    <Siderbar />
    <div class="content-wrapper">
        <ContentHeader />
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentHeader from '@/layout/ContentHeader.vue';
import DashHeader from '@/layout/DashHeader.vue';
import Siderbar from '@/layout/Siderbar.vue';

export default {
    name: 'Dash',
    components: {
        ContentHeader,
        DashHeader,
        Siderbar
    },
    mounted() {

    },
}
</script>

<style>
.form-horizontal .control-label{
  text-align: left;
}
</style>
