<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
          <DatePicker v-model="salesFrom" :last="salesTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DatePicker v-model="salesTo" :first="salesFrom" style="margin-right:10px"/>
          <button type="button" class="btn btn-default" style="margin-right:10px" @click="onPrvMonth">전월</button>
          <button type="button" class="btn btn-default" style="margin-right:10px" @click="onNowMonth">당월</button>
          <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
        </div>
      </div>
    </div>
    <div class="box box-solid">
      <div class="box-body">
        <line-chart :legend="false" :data="chatdata" />
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default pull-right" @click="downExcel" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>날짜</th>
              <th>사용금액</th>
              <th>목표금액</th>
              <th>달성%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>합계</td>
              <td>{{totalamount.salesAmountTotal | numFormat}}</td>
              <td>{{totalamount.targetAmountTotal | numFormat}}</td>
              <td>{{percent(totalamount.salesAmountTotal, totalamount.targetAmountTotal)}}</td>
            </tr>
            <tr v-for="(data, idx) in content" :key="idx">
              <td>{{viewDate(data.salesDate)}}</td>
              <td>{{data.salesAmount | numFormat}}</td>
              <td>{{data.targetAmount | numFormat}}</td>
              <td>{{percent(data.salesAmount, data.targetAmount)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import { reqPost } from "../../../lib/request";
import { orderapi } from "../../../lib/api";
import { DateFormat, getPercent } from "../../../lib/utility";
import DatePicker from "../../../components/DatePicker";
import moment from 'moment';
import XLSX from "xlsx";
export default {
  components: {
    DatePicker,
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_STAT, path2:menuval.LV2_STT_USE, title:"사용현황 목표대비"});
  },
  data() {
    return {
      chatdata:[
        {data: {'1': 3, '2': 4}},
        {data: {'1': 5, '2': 3}}
      ],
      salesFrom:moment().format("YYYY-MM-01"),
      salesTo:moment().format("YYYY-MM-DD"),
      content:[],
      /**
       * salesDate          String    yyyy-MM-dd 날짜
       * targetAmount       String    목표 금액
       * salesAmount        String    매출액 (사용금액)
       */
      totalamount:{
        targetAmount:0,//목표 금액
        salesAmount:0,//매출액 (구매금액)
        salesAmountTotal:0,//구매금액 총합
        targetAmountTotal:0,
      },
      currencyCode:"",
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD", true);
    },
    refreshPage() {
      reqPost(
        orderapi.base,
        orderapi.totalizeConsumedSales,
        this.authToken,
        {
          salesFrom:this.salesFrom,
          salesTo:this.salesTo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.doCalcuTotal(result.data.sales);
              // console.log(result.data.sales)
              this.currencyCode = result.data.currencyCode;
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    onNowMonth()
    {
      this.salesFrom = moment().format("YYYY-MM-01");
      this.salesTo = moment().format("YYYY-MM-DD");
    },
    onPrvMonth()
    {
      this.salesFrom = moment().subtract(1,'months').format('YYYY-MM-01');
      this.salesTo = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
    },
    percent(sales, target){
      return getPercent(sales, target);
    },
    doCalcuTotal(sales)
    {
      this.totalamount = {
        targetAmount:0,//목표 금액
        salesAmount:0,//매출액 (사용금액)
        salesAmountTotal:0,
        targetAmountTotal:0,
      };
      this.chatdata = [];
      var chatdata1 = {};
      var chatdata2 = {};

      for(var idx=0;idx<sales.length; idx++)
      {
        if(sales[idx].targetAmount != null)
        {
          this.totalamount.targetAmount = parseInt(sales[idx].targetAmount);
          this.totalamount.targetAmountTotal = this.totalamount.targetAmountTotal + parseInt(sales[idx].targetAmount);
        }
        if(sales[idx].salesAmount != null)
        {
          this.totalamount.salesAmount = parseInt(sales[idx].salesAmount);
          this.totalamount.salesAmountTotal = this.totalamount.salesAmountTotal + parseInt(sales[idx].salesAmount);
        }
        chatdata1[sales[idx].salesDate] = this.totalamount.salesAmount;
        chatdata2[sales[idx].salesDate] = this.totalamount.targetAmount;
      }
      this.content = sales;
      this.chatdata.push({data:chatdata1, color:'#5B9BD5'});
      this.chatdata.push({data:chatdata2, color:'#A5A5A5'});
    },
    dataconvert(tdata) {
      return {
        A: this.viewDate(tdata.salesDate),
        B: tdata.salesAmount,
        C: tdata.targetAmount,
        D: this.percent(tdata.salesAmount, tdata.targetAmount),
      };
    },
    downExcel() {
      this.buttonDissabled = true;
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "날짜",
            B: "거래금액",
            C: "목표금액",
            D: "달성율",
          },
          {
            A: "합계",
            B: this.totalamount.salesAmountTotal,
            C: this.totalamount.targetAmountTotal,
            D: this.percent(this.totalamount.salesAmountTotal, this.totalamount.targetAmountTotal),
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(excelheader, this.content.map(this.dataconvert), {
        skipHeader: true,
        origin: "A3",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "목표대비");
      // export Excel file
      XLSX.writeFile(wb, "사용현황조회-목표대비.xlsx");
      this.buttonDissabled = false;
    },
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.form-horizontal .form-group{
  margin-left: 0px;
  margin-right: 0px;
}
th {
  text-align: center;
}
td {
  text-align: center;
}
</style>
