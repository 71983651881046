<template>
    <div>
        <div class="box box-solid">
            <div class="box-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th class="text-center">예약번호</th>
                            <th class="text-center">매장명</th>
                            <th class="text-center">예약자</th>
                            <th class="text-center">전화번호</th>
                            <th class="text-center">방문예정일</th>
                            <th class="text-center">방문예정시간</th>
                            <th class="text-center">인원</th>
                            <th class="text-center">예약상태</th>
                            <th class="text-center">예약경로</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,idx) in items" :key="idx">
                            <td class="linkcmp text-center" @click="goDetailViewPage(item.bookingNo)">{{ item.bookingNo }}</td>
                            <td class="text-center">{{ item.storeName }}</td>
                            <td class="text-center">{{ item.visitorName }}</td>
                            <td class="text-center"> {{ item.visitorPhone }} </td>
                            <td class="text-center">{{ $moment(item.reservationDate).format('YYYY-MM-DD') }}</td>
                            <td class="text-center"> {{ $moment(item.reservationDate).format('HH:mm') }} </td>
                            <td class="text-center">{{ Number(item.adultHeadcount) + Number(item.childHeadcount) + Number(item.babyHeadcount) + '명' }}</td>
                            <td class="text-center">{{ getBookingStatusText(item.bookingStatus) }}</td>
                            <td class="text-center">{{ getBookingPath(item) }}</td>
                        </tr>
                    </tbody>
                </table>
             
            </div>
        </div>
    </div>
</template>

<script>
import { getBookingStatusText } from "../../../lib/consts";
import routepath from "../../../lib/routes";

export default {
    props: ['items'],

    methods:{
        getBookingStatusText,

        goDetailViewPage(bookingNo) {
            window.open(this.routes.detailbook + "?bookingNo=" + bookingNo)
        },
        getBookingPath(item) {
            if(item.b2bChannelCode == "KAKAOB") {
                return "카카오"
            } else if (item.bookingType == "ManualBooking") {
                return "관리자"
            } else if (item.siteName != "tablenjoy") {
                return "자체판매"
            } else {
                return "테이블엔조이"
            }
        },
    },

    computed:{
        routes() {
            return routepath;
        },
    },

    mounted(){
        // console.log(this.items)
    }

}
</script>

<style>

</style>