export default {
    SET_AUTH (state, {token, expiresIn, expiresAt, refresh, refreshexpiresIn, refreshexpiresAt, userid, userRole}) {
        state.token = token;
        state.expiresIn = expiresIn;
        state.expiresAt = expiresAt;
        state.refresh = refresh;
        state.refreshexpiresIn = refreshexpiresIn;
        state.refreshexpiresAt = refreshexpiresAt;
        state.userid = userid;
        state.userRole = userRole;
    },
    SET_PATH (state, {path1, path2, title, curpath}) {
        state.menu_lv1 = path1;
        state.menu_lv2 = path2;
        state.curpath = curpath;
        state.pagetitle = title;
    },
    SET_TOKEN (state, {token, expiresIn, expiresAt}) {
        state.token = token;
        state.expiresIn = expiresIn;
        state.expiresAt = expiresAt;
    },

  disabledLayout (state, payload) {
    state.disabledLayout = payload;
  },
  }
