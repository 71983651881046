<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <DatePicker v-model="salesFrom" :last="salesTo" />
                <h4 style="margin: 5px 10px">~</h4>
                <DatePicker v-model="salesTo" :first="salesFrom" style="margin-right: 10px" />
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
                    전월
                </button>
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
                    당월
                </button>
                <select class="form-control" style="width:auto;margin-right:10px" v-model="channelCode">
                    <option :value="null">전체</option>
                    <option v-for="(cdata, cidx) in channels" :key="cidx" :value="cdata.channelCode">{{cdata.channelName}}</option>
                </select>
                <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button type="button" class="btn btn-default pull-right" @click="TotaldownExcel" :disabled="buttonDissabled">
            <i class="fa fa-download"></i>Excel
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>상품타입</th>
                                <th>상품명</th>
                                <th>옵션명</th>
                                <th>판매가</th>
                                <th>합계</th>
                                <th v-for="(channel, cidx) in viewchannels" :key="cidx">
                                    {{ channel === 'selfSales' ? '자체판매':channel }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="4">총계</td>
                                <td>{{salesTotal.salesAmount | numFormat}}</td>
                                <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getTotalVal(channel) | numFormat}}</th>
                            </tr>
                            <template v-for="(good, gidx) in goodsSales">
                                <tr v-for="(option, oidx) in good.byOptions" :key="'good'+gidx+'option'+oidx">
                                    <td v-if="oidx == 0" :rowspan="good.byOptions.length">{{goodsTypeVal(good.goodsType)}}</td>
                                    <td v-if="oidx == 0" :rowspan="good.byOptions.length">{{good.goodsName}}</td>
                                    <td>{{option.optionName}}</td>
                                    <td>{{option.salePrice | numFormat}}</td>
                                    <td>{{option.salesTotal.salesAmount | numFormat}}</td>
                                    <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getCHVal(option, channel) | numFormat}}</th>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    b2bapi,
    orderapi
} from "../../../lib/api";
import {
    DateFormat,
    getGoodsTypeStr
} from "../../../lib/utility";
import DatePicker from "../../../components/DatePicker";
import moment from 'moment';
import XLSX from "xlsx";
export default {
    components: {
        DatePicker,
    },
    created() {
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_STAT,
            path2: menuval.LV2_STT_USE,
            title: "사용현황 상품별"
        });
    },
    data() {
        return {
            channels: [],
            /**
             * channelCode              String    채널코드
             * channelName              String    채널명
             */
            salesFrom: moment().format("YYYY-MM-01"),
            salesTo: moment().format("YYYY-MM-DD"),
            channelCode: null,

            goodsSales: [], //상품별 매출 집계
            /**
             * goodsName            String    상품명
             * byOptions            Array     옵션별 매출 집계
             * byOptions[].optionName         String    옵션명
             * byOptions[].listPrice          String    정상가
             * byOptions[].salePrice          String    판매가
             * byOptions[].salesTotal         Object    해당 옵션내 채널 매출합계
             * byOptions[].salesTotal.salesAmount           String    매출액
             * byOptions[].byChannels           Array     해당 옵션내 채널별 매출 집계
             * byOptions[].byChannels[].channelCode         String    채널코드
             * byOptions[].byChannels[].channelType         String    [Tablenjoy, B2bIframe, B2bChannel]  채널타입
             * byOptions[].byChannels[].sales               Object    채널 매출 집계
             * byOptions[].byChannels[].sales.salesAmount         String    매출액
             */
            salesTotal: { //총 매출 집계
                salesAmount: "", //총 매출액
            },
            salesTotalByChannels: [], //채널별 총 매출 집계
            /**
             * channelCode            String    채널코드
             * channelType            String    [Tablenjoy, B2bIframe, B2bChannel]    채널타입
             * sales                  Object      채널 총 매출합계
             * sales.salesAmount        String    채널 총 매출액
             */
            currencyCode: "", //[USD, KRW] 통화코드

            viewchannels: [],
          buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.getChannelData();
    },
    methods: {
        goodsTypeVal(val) {
            return getGoodsTypeStr(val);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        onPrvMonth() {
            this.salesFrom = moment().subtract(1, 'months').format('YYYY-MM-01');
            this.salesTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        },
        onNowMonth() {
            this.salesFrom = moment().format("YYYY-MM-01");
            this.salesTo = moment().format("YYYY-MM-DD");
        },
        getChannelData() {
            reqGet(
                b2bapi.base,
                b2bapi.channelList,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                            this.channels = result.data.channels;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refreshPage() {
            reqPost(
                orderapi.base,
                orderapi.totalizeConsumedSalesbyGoods,
                this.authToken, {
                    salesFrom: this.salesFrom,
                    salesTo: this.salesTo,
                    channelCode: this.channelCode
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.getViewChannels(result.data.salesTotalByChannels);
                            this.salesTotal = result.data.salesTotal;
                            this.goodsSales = result.data.goodsSales;
                            this.currencyCode = result.data.currencyCode;
                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getViewChannels(salesTotalByChannels) {
            this.viewchannels = [];
            for (var idx = 0; idx < salesTotalByChannels.length; idx++) {
                var f_idx = this.viewchannels.findIndex(vchnl => vchnl == salesTotalByChannels[idx].channelCode);
                if (f_idx == -1) {
                    this.viewchannels.push(salesTotalByChannels[idx].channelCode);
                }

            }
            this.salesTotalByChannels = salesTotalByChannels;
        },
        getCHVal(option, channel) {
            for (var idx = 0; idx < option.byChannels.length; idx++) {
                if (option.byChannels[idx].channelCode == channel) {
                    return option.byChannels[idx].sales.salesAmount;
                }
            }
            return "";
        },
        getTotalVal(channel) {
            for (var idx = 0; idx < this.salesTotalByChannels.length; idx++) {
                if (this.salesTotalByChannels[idx].channelCode == channel) {
                    return this.salesTotalByChannels[idx].sales.salesAmount;
                }
            }
            return "";
        },
        totalconvert(option) {
            var retval = {};
            retval[0] = option.goodsType;
            retval[1] = option.goodsName;
            retval[2] = option.goodsId;
            retval[3] = option.optionName;
            retval[4] = option.optionId;
            retval[5] = option.salePrice;
            retval[6] = option.salesTotal.salesAmount;
            for (var cidx = 0; cidx < this.viewchannels.length; cidx++) {
                retval[cidx + 6] = this.getCHVal(option, this.viewchannels[cidx]);
            }
            return retval;
        },
        TotaldownExcel() {
          this.buttonDissabled = true;
            var head = {};
            head[0] = "상품명";
            head[1] = "상품명";
            head[2] = "상품코드";
            head[3] = "옵션명";
            head[4] = "옵션코드";
            head[5] = "판매가";
            head[6] = "합계";
            for (var cidx = 0; cidx < this.viewchannels.length; cidx++) {
                head[cidx + 6] = this.viewchannels[cidx] === 'selfSales' ? '자체판매':this.viewchannels[cidx];
            }
            var total = {};
            total[0] = "계";
            total[1] = "";
            total[2] = "";
            total[3] = "";
            total[4] = "";
            total[5] = "";
            total[6] = this.salesTotal.salesAmount;
            for (cidx = 0; cidx < this.viewchannels.length; cidx++) {
                total[cidx + 6] = this.getTotalVal(this.viewchannels[cidx]);
            }
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    head,
                    total,
                ], {
                    skipHeader: true
                }
            );

            var exceldata = [];
            for (cidx = 0; cidx < this.goodsSales.length; cidx++) {
                for (var oidx = 0; oidx < this.goodsSales[cidx].byOptions.length; oidx++) {
                    var t_data = {
                        ...this.goodsSales[cidx].byOptions[oidx],
                        goodsName: this.goodsSales[cidx].goodsName,
                        goodsType: this.goodsTypeVal(this.goodsSales[cidx].goodsType),
                        goodsId: this.goodsSales[cidx].goodsId
                    }
                    exceldata.push(t_data);
                }
            }

            XLSX.utils.sheet_add_json(excelheader, exceldata.map(this.totalconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "상품별";
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "사용현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        },
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

th {
    text-align: center;
}

td {
    text-align: center;
}
</style>
