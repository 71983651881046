<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                <div class="input-group radio" style="margin-right:10px">
                    <label>
                        <input type="radio" :value="null" v-model="completed" />전체
                    </label>
                    <label>
                        <input type="radio" :value="false" v-model="completed" />미처리
                    </label>
                    <label>
                        <input type="radio" :value="true" v-model="completed" />처리완료
                    </label>
                </div>
                <DatePicker v-model="fromDate" :last="toDate" />
                <h4 style="margin:5px 10px;">~</h4>
                <DatePicker v-model="toDate" :first="fromDate" style="margin-right:10px" />
                <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="refreshPage">
                    <option value="NoKeyword"></option>
                    <option value="BrandName">브랜드명</option>
                    <option value="RegisteredBy">작성자 ID</option>
                </select>
                <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                    <input type="text" class="form-control" v-model="keyword" style="flex:1" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th class="text-center">번호</th>
                        <th class="text-center">처리상태</th>
                        <th class="text-center">브랜드</th>
                        <th class="text-center">등록일</th>
                        <th class="text-center">작성자</th>
                        <th class="text-center">조회</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(ques, index) in content" :key="ques.requestNo">
                        <td class="text-center">{{ totalElements - (startnum + index) }}</td>
                        <td class="text-center" v-if="!ques.completed">미처리</td>
                        <td class="text-center" v-if="ques.completed">처리완료</td>
                        <td class="linkcmp text-center" @click="showEditmodal(ques.requestNo)">{{ques.companyName}}</td>
                        <td class="text-center">{{viewDate(ques.registeredDate)}}</td>
                        <td class="text-center">{{ques.representative}}</td>
                        <td class="text-center">{{ques.viewedCount | numFormat}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>
    <div class="modal" v-if="editmodal" :style="{'display': 'block', 'z-index':'2000'}">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">제휴문의신청내역 상세</div>
                <div class="modal-body form-horizontal" style="padding:0px;">
                    <div class="form-group" style="margin:0px;">
                        <div class="col-sm-6" style="padding:0px;">
                            <div class="table-responsive editmodal p-0">
                                <table class="table table-hover text-nowrap">
                                    <colgroup>
                                        <col width="70px">
                                        <col width="*">
                                        <col width="70px">
                                        <col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>브랜드</th>
                                            <td colspan="3">{{viewinf.companyName}}</td>
                                        </tr>
                                        <tr>
                                            <th>업종</th>
                                            <td colspan="3">{{getStoreTypeText}}</td>
                                        </tr>
                                        <tr>
                                            <th>담당자</th>
                                            <td colspan="3">{{viewinf.representative}}</td>
                                        </tr>
                                        <tr>
                                            <th>연락처</th>
                                            <td>{{phoneview(viewinf.phoneNumber)}}</td>
                                            <th>이메일</th>
                                            <td>{{viewinf.email}}</td>
                                        </tr>
                                        <tr>
                                            <th>등록일</th>
                                            <td colspan="3">{{viewDate(viewinf.registeredDate)}}</td>
                                        </tr>
                                        <tr>
                                            <th colspan="4">문의내용</th>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <textarea class="form-control" rows="5" disabled v-model="viewinf.contents"></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-6" style="padding:0px;">
                            <div class="table-responsive editmodal p-0">
                                <table class="table table-hover text-nowrap">
                                    <tbody>
                                        <tr>
                                            <th colspan="4">메모</th>
                                        </tr>
                                        <tr>
                                            <td colspan="4"><textarea class="form-control" rows="8" v-model="answer" :disabled="viewinf.completed"></textarea></td>
                                        </tr>
                                        <tr v-if="viewinf.completed">
                                            <th>답변일</th>
                                            <td>{{viewDate(viewinf.completedDate)}}</td>
                                            <th>답변ID</th>
                                            <td>{{viewinf.completedBy}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="closeEditmodal">닫기</button>
                    <button type="button" v-if="!viewinf.completed" class="btn btn-default" @click="doAnswer" :disabled="buttonDissabled">저장</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";

import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    board,
    brandapi
} from "../../lib/api";
import {
    DateFormat,
    formatPhone,
    getStoreTypeStr
} from "../../lib/utility";
import DatePicker from "../../components/DatePicker";
import moment from 'moment';
export default {
    components: {
        Pagination,
        DatePicker,
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.Ques_keywordType) {
                this.keywordType = localStorage.Ques_keywordType;
            }
            if (localStorage.Ques_keyword) {
                this.keyword = localStorage.Ques_keyword;
            }
            if (localStorage.Ques_Number) {
                this.pageNumber = parseInt(localStorage.Ques_Number);
            }
            if (localStorage.Ques_Size) {
                this.pageSize = parseInt(localStorage.Ques_Size);
            }
            if (localStorage.Ques_fromDate) {
                this.fromDate = localStorage.Ques_fromDate;
            }
            if (localStorage.Ques_toDate) {
                this.toDate = localStorage.Ques_toDate;
            }
        }
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_BOD,
            path2: menuval.LV1_HOME,
            title: "제휴문의"
        });
    },
    watch: {
        keywordType(newVal) {
            localStorage.Ques_keywordType = newVal;
        },
        keyword(newkey) {
            localStorage.Ques_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.Ques_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.Ques_Size = newsize;
        },
        fromDate(nfrom) {
            localStorage.Ques_fromDate = nfrom;
        },
        toDate(nto) {
            localStorage.Ques_toDate = nto;
        },
    },
    data() {
        return {
            shownum: 10, //pagination에 보여지는 번호개수
            keywordType: "NoKeyword",
            keyword: "",
            fromDate: moment().format("YYYY-01-01"),
            toDate: moment().format("YYYY-MM-DD"),
            completed: null,
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            /**
             * requestNo              Number    요청 번호
             * companyName            String    회사명
             * representative         String    담당자
             * title                  String    제목
             * registeredDate         String    UTC yyyy-MM-ddTHH:mm:ss 등록일
             * viewedCount            Number    조회 회수
             * completed              Boolean   true: 처리완료, false: 미처리
             */
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true
            editmodal: false,

            viewinf: {
                requestNo: 0, //요청 번호
                companyName: "", //회사명
                storeType: "", //업종
                representative: "", //담당자
                phoneNumber: "", //전화번호
                email: "", //이메일
                title: "", //제목
                contents: "", //내용
                registeredDate: "", //UTC yyyy-MM-ddTHH:mm:ss 등록일
                viewedCount: 0, //조회 회수
                answer: "", //답변
                completed: false, //처리완료여부. true: 처리완료, false: 미처리
                completedDate: "", //UTC yyyy-MM-ddTHH:mm:ss 처리완료일
            },
            answer: "",
            getStoreTypeText:'',
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.Ques_keywordType = this.keywordType;
            localStorage.Ques_keyword = this.keyword;
            localStorage.Ques_Number = this.pageNumber;
            localStorage.Ques_Size = this.pageSize;
            localStorage.Ques_fromDate = this.fromDate;
            localStorage.Ques_toDate = this.toDate;
        }
        this.refreshPage();
    },
    methods: {
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqPost(
                board.base,
                board.request_page,
                this.authToken, {
                    keywordType: this.keywordType,
                    keyword: this.keyword,
                    completed: this.completed,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    pageNumber: pageNumber,
                    pageSize: this.pageSize
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                            alert(result.message);
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        closeEditmodal() {
            this.editmodal = false;
        },
        showEditmodal(requestNo) {
            reqPost(
                board.base,
                board.request_detail,
                this.authToken, {
                    requestNo: requestNo,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.viewinf = result.data;
                            this.answer = this.viewinf.answer;
                            this.editmodal = true;
                            this.getStoreType(this.viewinf.storeType)
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getStoreType(val) {
            reqGet(
                brandapi.base,
                brandapi.storeType,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            result.data.types.forEach(el => {
                                if (val === el.storeType) {
                                    this.getStoreTypeText = el.typeName;
                                }
                            });
                        }
                    }
                }
            );
        },
        phoneview(val) {
            return formatPhone(val);
        },
        doAnswer() {
          this.buttonDissabled = true;
            if (this.answer == null) {
                this.answer = "";
            }
            var t_answer = this.answer.trim();

            if (t_answer == "") {
                this.buttonDissabled = false;
                alert("답변을 입력하세요.")
                return;
            }
            reqPost(
                board.base,
                board.request_answer,
                this.authToken, {
                    requestNo: this.viewinf.requestNo,
                    answer: this.answer,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.buttonDissabled = false;
                            this.closeEditmodal();
                            this.refreshPage();
                        } else {
                            this.buttonDissabled = false;
                            alert(result.message);
                        }
                    }
                }
            );
        }
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio {
    margin-bottom: 0px;
}

.radio>label {
    margin-right: 10px;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.editmodal th {
    background-color: #dee2e685;
}

.editmodal::-webkit-scrollbar {
    display: none;
}

.modal-body .control-label {
    margin: 0px;
}

.modal .table {
    margin-bottom: 0px;
}
</style>
