export default {
    authToken: state => {
        return state.token;
    },
    expiresIn: state => {
        return state.expiresIn;
    },
    expiresAt: state => {
      return state.expiresAt;
    },
    reToken: state => {
        return state.refresh;
    },
    reExpiresIn: state => {
        return state.refreshexpiresIn;
    },
    reExpiresAt: state => {
      return state.refreshexpiresAt;
    },
    myRole: state =>{
        return state.userRole;
    },
    isAgentRole: state =>{
        return state.userRole === 'AGENT';
    },
    menulv1: state => {
        return state.menu_lv1;
    },
    menulv2: state => {
        return state.menu_lv2;
    },
    curpath: state => {
        return state.curpath;
    },
    title: state => {
        return state.pagetitle;
    },
    authuser: state => {
        return state.userid;
    },
  disabledLayout: state => {
      return state.disabledLayout;
  }
}
