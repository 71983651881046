<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%; align-items:center">
             <div class="radio" style="margin:0px;margin-right:10px">
                  <label>
                      <input type="radio" value="ErrorReply" v-model="processType" />미처리
                  </label>
                  <label>
                      <input type="radio" value="ErrorSuccess" v-model="processType" />처리완료
                  </label>
            </div>
            <div  v-if="processType == 'ErrorSuccess'" >
              <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
                  <input type="text" class="form-control" style="width:auto" readonly :value="SuccessMonth" @click="onShowMonthPicker" />
                  <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
              </div>
            </div>
                <button type="button" class="btn btn-default" @click="refreshPage">검색</button>

            </div>
        </div>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover" style="text-nowrap">
          <thead>
          <tr>
            <th style="width:50px" class="text-center">번호</th>
            <th style="width:200px" class="text-center">주문번호</th>
            <th class="text-center">실패 사유</th>
            <th style="width:150px" class="text-center">요청일시</th>
            <th style="width:150px" class="text-center">처리일시</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(order, index) in content" v-bind:key="order.orderNo">
            <td class="text-center">{{ content.length - index }}</td>
            <td class="linkcmp text-center" @click="goDetailViewPage(order.orderNo)">{{ order.orderNo }}</td>
            <td>{{ order.failMessage }}</td>
            <td class="text-center">{{ viewDate(order.issueDate) }}</td>
            <td v-if="order.status == 'ErrorReply'" class="text-center">
              <button type="button" class="btn btn-default btn-xs" @click="doConfirmModal(order)">처리완료</button>
            </td>
            <td v-else class="text-center">
              {{viewDate(order.consumeDate)}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal" :style="showaddmodal && {'display': 'block'}">
        <div class="modal-dialog new">
            <div class="modal-content">
                <div class="modal-body form-horizontal">
                    <div>
                        <textarea rows="3" type="text" placeholder="(주문상세 페이지 > 메모) 경로에서 확인 가능" class="form-control" v-model="newmemo"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="modalClose">취소</button>
                    <button type="button" class="btn btn-default" @click="submit" :disabled="buttonDissabled">처리완료</button>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {reqPost} from "../../lib/request";
import {orderapi} from "../../lib/api";
import {DateFormat, getGoodsTypeStr} from "../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";
import numeral from 'numeral';
import { MonthPicker} from 'vue-month-picker';

export default {
  directives: {
      clickOutside: {
          bind: function (el, binding, vnode) {
              el.event = function (event) {
                  if (!(el === event.target || el.contains(event.target))) {
                      vnode.context[binding.expression](event)
                  }
              }
              document.body.addEventListener('click', el.event)
          },
          unbind: function (el) {
              document.body.removeEventListener('click', el.event)
          }
      }
  },
  components: {
    MonthPicker
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.PCF_processType) {
        this.processType = localStorage.PCF_processType;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_ORD, path2: menuval.LV1_HOME, title: "결제취소 실패내역"});
  },
  watch: {
    processType(newProcess) {
      localStorage.PCF_processType = newProcess;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      SuccessMonth: "",
      monthpicker: false,
      processType: "ErrorReply",
      showaddmodal: false,
      newmemo:"",
      item: {},
      defmonth: moment().month() + 1,
      defyear: moment().year(),
      content: [],
      buttonDissabled : false,
      /**
       * id                               Number      Transaction 번호
       * orderNo                          String      주문 번호
       * failMessage                      String      실패 사유
       * issueDate                        String      UTC yyyy-MM-ddTHH:mm:ss   발생일자
       * consumeDate                      String      UTC yyyy-MM-ddTHH:mm:ss   처리일시
       * status                           String      상태 [ErrorReply, ErrorSuccess]
       */
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.PCF_processType = this.processType;
          }
    this.refreshPage();
  },
  methods: {
    currencydata(num) {
      return numeral(num).format('$0,0.00');
    },
    numformat(num) {
      return numeral(num).format('0,0');
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    selMonth(date) {
        this.SuccessMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
        // this.monthpicker = false;
    },
    onShowMonthPicker() {
        this.monthpicker = !this.monthpicker;
    },
    hideMonthPicker() {
        this.monthpicker = false;
    },

    doConfirmModal(order) {
      this.showaddmodal = true;
      this.item = order;

      console.log(order);
    },

    modalClose() {
      this.showaddmodal = false;
    },

    submit() {
      this.buttonDissabled = true;
      reqPost(
        orderapi.base,
        orderapi.paymentCancelFailItemConsume,
        this.authToken, {
          id: this.item.id,
          memo: this.newmemo,
          orderNo: this.item.orderNo
        },
        (result) => {
          if(result != null) {
            if(result.code ==rescode.REQ_SUCCESS) {
              this.newmemo = "";
              this.showaddmodal = false;
              this.buttonDissabled = false;
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    refreshPage() {

      var reqdata = {
        salesMonth: this.SuccessMonth,
        cancelType: this.processType
      };
      reqPost(
        orderapi.base,
        orderapi.paymentCancelFailList,
        this.authToken,
        reqdata,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.content = result.data;
              console.log(this.content);

            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    }
    ,
    goDetailViewPage(orderNo) {
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
    }
    ,


  },
};
</script>
<style scoped>
.checkbox {
  margin-bottom: 0px;
  margin-top: 0px;
}

.checkbox > label {
  margin-right: 10px;
}
.radio > label {
  margin-right: 10px;
}
.month-picker__container {
    position: absolute;
    top: 3.5em;
    background-color: #fff;
    z-index: 9999;
}
</style>
