<template lang="ko">
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="settlementMonth" @click="onShowMonthPicker" />
                    <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
                </div>
                <select class="form-control" style="width:auto;margin-right: 10px" v-model="settlementDayOfMonth">
                    <option v-for="stdate in settlementDates" :key="stdate" :value="stdate">{{stdate}}일</option>
                </select>
                <button class="btn btn-default" type="button" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button type="button" class="btn btn-default pull-right" @click="downExcel" :disabled="excelButtonDisabled">
            <i class="fa fa-download"></i>Excel
        </button>
        <button type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="doCompleteList" :disabled="buttonDissabled">
            일괄금액확정
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th rowspan="2">번호</th>
                        <th rowspan="2">브랜드</th>
                        <th rowspan="2">매장명</th>
                        <th colspan="3">사용매출</th>
                        <th colspan="3">취소위약금 매출</th>
                        <th rowspan="2">확정총입금액</th>
                        <th rowspan="2">확정수수료</th>
                        <th rowspan="2">정산기준일</th>
                        <th rowspan="2">정산일</th>
                        <th rowspan="2">정산현황</th>
                        <th rowspan="2">입금완료처리</th>
                        <th rowspan="2">실 입금금액</th>
                        <th rowspan="2"><input type="checkbox" class="allch" @change="allckech($event)" /></th>
                    </tr>
                    <tr>
                        <th>거래금액</th>
                        <th>입금금액</th>
                        <th>수수료</th>
                        <th>위약금</th>
                        <th>입금금액</th>
                        <th>수수료</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, didx) in content" :key="didx">
                        <td>{{didx+1}}</td>
                        <td>{{data.brandName}}</td>
                        <td>{{data.storeName}}</td>
                        <td>{{data.transactionAmount | numFormat}}</td>
                        <td>{{data.salesDeliveryAmount | numFormat}}</td>
                        <td>{{data.salesCommission | numFormat}}</td>

                        <td>{{data.penaltyAmount | numFormat}}</td>
                        <td>{{data.penaltyDeliveryAmount | numFormat}}</td>
                        <td>{{data.penaltyCommission | numFormat}}</td>
                        <td>{{data.totalDeliveryAmount | numFormat}}</td>
                        <td>{{data.totalCommission | numFormat}}</td>

                        <td>{{viewDate(data.salesFrom)}} ~ {{viewDate(data.salesTo)}}</td>
                        <td>{{viewDate(data.settlementDate)}}</td>
                        <td>{{getSttStatus(data.settlementStatus)}}</td>
                        <td v-if="data.settlementStatus == 'AmountConfirmed'"><button type="button" class="btn btn-default btn-xs" @click="showEdit(data)">입금완료</button></td>
                        <td v-else></td>
                        <!-- <td><button type="button" class="btn btn-default btn-xs" @click="showEdit(data)">입금완료</button></td> -->
                        <td>{{data.transferredAmount | numFormat}}</td>
                        <td>
                            <input v-if="data.settlementStatus != 'AmountConfirmed'" type="checkbox" disabled />
                            <input v-if="data.settlementStatus == 'AmountConfirmed'" type="checkbox" class="chitem" :value="data.settlementId" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal" v-if="editdlg" :style="{ display: 'block', 'z-index': '2000' }">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">입금완료</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group">
                        <div class="table-responsive p-0">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>브랜드</th>
                                        <th>매장명</th>
                                        <th>확정입금금액</th>
                                        <th>실 입금금액</th>
                                        <th>담당자</th>
                                        <th>정산기준일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{selData.settlementId}}</td>
                                        <td>{{selData.brandName}}</td>
                                        <td>{{selData.storeName}}</td>
                                        <td>{{selData.totalDeliveryAmount}}</td>
                                        <td><input type="number" class="form-control" v-model="fakeInputa" @keyup="fakeInputaEve(eve,fakeInputa)"/></td>
                                        <td>{{selData.tnjManagerID}}</td>
                                        <td>{{viewDate(selData.salesFrom)}} ~ {{viewDate(selData.salesTo)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="form-group" style="margin:0px;">
                        <label class="col-sm-3 control-label">메모</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="selData.memo" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeEdit">닫기</button>
                    <button type="button" class="btn btn-default" @click="doSttComplate" :disabled="buttonDissabled">입금완료</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    brandapi,
    orderapi
} from "../../../lib/api";
import {
    DateFormat
} from "../../../lib/utility";
import {
    MonthPicker
} from 'vue-month-picker'
import moment from 'moment';
import $ from 'jquery';
import XLSX from "xlsx";
export default {
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.event)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.event)
            }
        }
    },
    components: {
        MonthPicker
    },
    created() {
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_PAY,
            path2: menuval.LV2_PAY_DPS,
            title: "입금완료처리"
        });
    },
    data() {
        return {
            editdlg: false,
            settlementDayOfMonth: 0,
            settlementMonth: "",
            defmonth: moment().month() + 1,
            defyear: moment().year(),
            monthpicker: false,
            settlementDates: [],
            content: [],
            stores: [],
            /**
             * brandCode                String    브랜드 코드
             * brandName                String    브랜드명
             * stores[].storeCode       String    매장 코드
             * stores[].storeName       String    매장명
             * stores[].settlements[].settlementId        Number    정산 ID
             * stores[].settlements[].settlementStatus    String    [SettlementUnavailable, NeedsAmountConfirm, AmountConfirmed, SettlementComplete]  정산 진행 상태. SettlementUnavailable: 아직 정산을 진행할 수 없음, NeedsAmountConfirm: 금액확정이 필요함, AmountConfirmed: 금액확정됨(입금대기), SettlementComplete: 정산완료(입금완료)
             * stores[].settlements[].salesFrom           String    yyyy-MM-dd  매출 시작일
             * stores[].settlements[].salesTo             String    yyyy-MM-dd  매출 종료일
             * stores[].settlements[].settlementDate      String    yyyy-MM-dd  정산일
             * stores[].settlements[].confirmedDate                   String    UTC yyyy-MM-ddTHH:mm:ss 금액 확정일 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].transactionAmount               String    확정 거래금액 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].deliveryAmount                  String    확정 입금금액 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].salesCommission                 String    확정 판매 수수료 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].transferredAmount               String    실 입금금액 (정산이 완료되지 않은 경우 null)
             * stores[].settlements[].completedDate                   String    UTC yyyy-MM-ddTHH:mm:ss   정산 완료일 (입금 완료일, 정산이 완료되지 않은 경우 null)
             * stores[].settlements[].memo                            String    메모
             */
            selData: null,
            fakeInputa:'',
            excelButtonDisabled : false,
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.getdateList();
    },
    methods: {
        fakeInputaEve(eve,val){
            this.fakeInputa = val
        },
        allckech(event) {
            $('input:checkbox.chitem').prop('checked', event.target.checked);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        closeEdit() {
            this.editdlg = false;
        },
        showEdit(sdata) {
            this.selData = sdata;
            this.fakeInputa = this.selData.totalDeliveryAmount
            this.editdlg = true;
        },
        selMonth(date) {
            this.settlementMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
            // this.monthpicker = false;
        },
        onShowMonthPicker() {
            this.monthpicker = !this.monthpicker;
        },
        hideMonthPicker() {
            this.monthpicker = false;
        },
        getdateList() {
            reqGet(
                brandapi.base,
                brandapi.admdateGroup,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.settlementDates = result.data.settlementDates;
                            this.settlementDayOfMonth = this.settlementDates[0];
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refreshPage() {
            reqPost(
                orderapi.base,
                orderapi.tnjquery,
                this.authToken, {
                    settlementMonth: this.settlementMonth,
                    settlementDayOfMonth: this.settlementDayOfMonth,
                },
                async (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            await this.converExcel(result.data.brands);
                            if (this.stores.length > 0) {
                                await this.refreshPageAdd();
                            }

                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refreshPageAdd() {
            reqPost(
                brandapi.base,
                brandapi.tnjbankbooks,
                this.authToken, {
                    storeCodes: this.stores,
                },
                async (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            await this.converExcelAdd(result.data);
                        }
                    }
                }
            );
        },
        converExcelAdd(stroeBankInfo) {
            this.content.forEach((store, index) => {
                let findStoreBank = stroeBankInfo.find(o => o.storeCode == store.storeCode)
                this.content[index].storeBankName = findStoreBank.bankbook.bankName.trim()
                this.content[index].storeAccountNumber = findStoreBank.bankbook.accountNumber.trim()
                this.content[index].storeAccountHolder = findStoreBank.bankbook.accountHolder.trim()
            })
        },
        converExcel(brands) {
            this.content = [];
            for (var bidx = 0; bidx < brands.length; bidx++) {
                for (var sidx = 0; sidx < brands[bidx].stores.length; sidx++) {
                    for (var tidx = 0; tidx < brands[bidx].stores[sidx].settlements.length; tidx++) {
                        this.stores.push(brands[bidx].stores[sidx].storeCode)

                        var t_data = {
                            ...brands[bidx].stores[sidx].settlements[tidx],
                            brandCode: brands[bidx].brandCode,
                            brandName: brands[bidx].brandName,
                            tnjManagerID: brands[bidx].tnjManagerID,
                            storeCode: brands[bidx].stores[sidx].storeCode,
                            storeName: brands[bidx].stores[sidx].storeName,
                        };
                        if (t_data.salesDeliveryAmount == null) {
                            t_data.salesDeliveryAmount = 0;
                        }
                        if (t_data.salesCommission == null) {
                            t_data.salesCommission = 0;
                        }
                        if (t_data.transactionAmount == null) {
                            t_data.transactionAmount = 0;
                        }
                        if (t_data.transferredAmount == null) {
                            t_data.transferredAmount = 0;
                        }
                        this.content.push(t_data);
                    }
                }
            }
        },
        getSttStatus(val) {
            if (val == 'SettlementUnavailable') {
                return "";
            } else if (val == 'NeedsAmountConfirm') {
                return "확정대기";
            } else if (val == 'AmountConfirmed') {
                return "입금대기";
            } else if (val == 'SettlementComplete') {
                return "입금완료";
            } else {
                return "";
            }
        },
        doCompleteList() {
          this.buttonDissabled = true;
            var selidxs = $('input:checkbox.chitem:checked').map(function () {
                return this.value;
            }).get();
            var t_settlements = this.content.filter(citem => {
                var f_idx = selidxs.findIndex(siidx => siidx == citem.settlementId);
                return f_idx > -1;
            });
            var settlements = t_settlements.map((titem) => {
                return {
                    settlementId: titem.settlementId,
                    transferredAmount: titem.totalDeliveryAmount,
                    memo: null,
                }
            });
            this.$store.dispatch('setDisabledLayout');
            reqPost(
                orderapi.base,
                orderapi.complete_list,
                this.authToken, {
                    settlements: settlements
                },
                (result) => {
                    if (result != null) {
                        this.$store.dispatch('setDisabledLayout');
                        if (result.code == rescode.REQ_SUCCESS) {
                            $('input:checkbox.chitem').prop('checked', false);
                            $('input:checkbox.allch').prop('checked', false);
                            this.buttonDissabled = false;
                            this.refreshPage();
                        } else {
                            this.buttonDissabled = false;
                            alert(result.message);
                        }
                    }
                }
            );
        },
        dataconvert(tdata, tidx) {
            return {
                A: tidx + 1,
                B: tdata.brandName,
                C: tdata.brandCode,
                D: tdata.storeName,
                E: tdata.storeCode,
                F: tdata.storeBankName,
                G: tdata.storeAccountNumber,
                H: tdata.storeAccountHolder,
                I: tdata.transactionAmount,
                J: tdata.salesDeliveryAmount,
                K: tdata.salesCommission,
                L: tdata.penaltyAmount,
                M: tdata.penaltyDeliveryAmount,
                N: tdata.penaltyCommission,
                O: tdata.totalDeliveryAmount,
                P: tdata.totalCommission,
                Q: this.viewDate(tdata.salesFrom) + " ~ " + this.viewDate(tdata.salesTo),
                R: this.viewDate(tdata.settlementDate),
                S: this.getSttStatus(tdata.settlementStatus),
            };
        },
        downExcel() {
          this.excelButtonDisabled = true;
            var excelheader = XLSX.utils.json_to_sheet(
                [{
                    A: "번호",
                    B: "브랜드",
                    C: "브랜드코드",
                    D: "매장",
                    E: "매장코드",
                    F: "은행명",
                    G: "계좌번호",
                    H: "예금주",
                    I: "거래금액",
                    J: "입금금액",
                    K: "수수료",
                    L: "위약금",
                    M: "위약금입금금액",
                    N: "위약금수수료",
                    O: "확정총입금금액",
                    P: "확정총수수료",
                    Q: "정산기준일",
                    R: "정산일",
                    S: "정산현황",
                }, ], {
                    skipHeader: true
                }
            );

            XLSX.utils.sheet_add_json(excelheader, this.content.map(this.dataconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "입금완료처리");
            // export Excel file
            XLSX.writeFile(wb, "입금관리-입금완료처리.xlsx");
          this.excelButtonDisabled = false;
        },
        doSttComplate() {
          this.buttonDissabled = true;
            this.$store.dispatch('setDisabledLayout');
            reqPost(
                orderapi.base,
                orderapi.settlement_complete,
                this.authToken, {
                    settlementId: this.selData.settlementId,
                    transferredAmount: this.fakeInputa,
                    memo: this.selData.memo
                },
                (result) => {
                    if (result != null) {
                        this.$store.dispatch('setDisabledLayout');
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.buttonDissabled = false;
                            this.closeEdit();
                            this.refreshPage();
                        } else {
                            this.buttonDissabled = false;
                            alert(result.message);
                        }
                    }
                }
            );
        }
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.modal-body .control-label {
    margin: 0px;
}

.modal .table {
    margin-bottom: 0px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

th {
    text-align: center;
}

td {
    text-align: center;
}

.month-picker__container {
    position: absolute;
    top: 3.5em;
    background-color: #fff;
    z-index: 9999;
}

th {
    background-color: #dee2e685;
    vertical-align: middle !important;
    ;
}
</style>
