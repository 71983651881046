<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label">메인페이지 명 *</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" placeholder="메인페이지 명을 입력해 주세요." v-model="title"/>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">서브 제목 *</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" placeholder="서브 제목을 입력해 주세요." v-model="description"/>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">적용 사이트 *</label>
          <div class="col-sm-9 checkbox">
            <label> <input type="checkbox" value="ALL" :checked="getAllChecked()" @change="onClickAll($event)"/>전체 </label>
            <label> <input type="checkbox" value="PC" v-model="channels"/>PC </label>
            <label> <input type="checkbox" value="MOBILE" v-model="channels"/>Mobile </label>
            <label> <input type="checkbox" value="EZWEL" v-model="channels"/>이지웰 </label>
            <label> <input type="checkbox" value="BENEPIA" v-model="channels"/>SK베네피아 </label>
            <label> <input type="checkbox" value="HAPPY" v-model="channels"/>해피머니 </label>
            <label> <input type="checkbox" value="LIFECARE" v-model="channels" />LIFECARE </label>
            <label> <input type="checkbox" value="CHALETKOREA" v-model="channels" />샬레코리아 </label>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">카테고리 노출방법</label>
          <div class="col-sm-9 radio">
            <label> <input type="radio" value="GOODS" v-model="showType"/>상품기준 </label>
            <label> <input type="radio" value="STORE" v-model="showType"/>매장기준 </label>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">상품관리</label>
          <div class="col-sm-9">
<!--            <button v-if="showType == 'GOODS'" type="button" class="btn btn-default pull-right" style="margin-left:10px" @click="showFindpro">-->
<!--              상품추가-->
<!--            </button>-->
            <button type="button" class="btn btn-default pull-right" style="margin-left:10px" @click="showFindstore">
              추가
            </button>
            <button type="button" class="btn btn-default pull-right" @click="doRmvProduct">
              삭제
            </button>
          </div>
        </div>
        <div class="form-group" >
          <label class="hidden-xs col-sm-3 control-label"></label>
<!--          <div class="col-sm-9" v-if="showType == 'GOODS'">-->
<!--            <div class="table-responsive p-0">-->
<!--              <table class="table table-hover text-nowrap">-->
<!--                <thead>-->
<!--                  <tr>-->
<!--                    <th><input type="checkbox" class="allch" @change="allckech($event)"/></th>-->
<!--                    <th>상품코드</th>-->
<!--                    <th>노출상품</th>-->
<!--                    <th>노출순서</th>-->
<!--                  </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                  <tr v-for="(prod, pidx) in products" :key="pidx">-->
<!--                    <td><input type="checkbox" class="chitem" :value="prod.goodsId"/></td>-->
<!--                    <td>{{prod.goodsId}}</td>-->
<!--                    <td>{{prod.goodsName}}</td>-->
<!--                    <td>-->
<!--                      <drop @drop="handleDrop({ index:pidx }, $event)">-->
<!--                        <drag-->
<!--                          class="btn btn-default btn-social-icon"-->
<!--                          :transfer-data="{ index:pidx }"-->
<!--                          ><i class="fa fa-fw fa-arrows-v"></i>-->
<!--                        </drag>-->
<!--                      </drop>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-sm-9">
            <div class="table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th><input type="checkbox" class="allch" @change="allckech($event)"/></th>
                    <th>매장코드</th>
                    <th>브랜드명</th>
                    <th>매장명</th>
                    <th>노출상품</th>
                    <th>노출순서</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(prod, pidx) in products" :key="pidx">
                    <td><input type="checkbox" class="chitem" :value="prod.storeCode"/></td>
                    <td>{{prod.storeCode}}</td>
                    <td>{{prod.brandName}}</td>
                    <td>{{prod.storeName}}</td>
                    <td style="display: inline-block;">
                      <select class="form-control" style="width:200px;" v-model="prod.goodsId">
                        <option :value="null">상품없음</option>
                        <option v-for="good in prod.goodsinfs" :key="good.goodsId" :value="good.goodsId">{{good.goodsName}}</option>
                      </select>
                    </td>
                    <td>
                      <drop @drop="handleDrop({ index: pidx }, $event)">
                        <drag
                          class="btn btn-default btn-social-icon"
                          :transfer-data="{ index: pidx }"
                          ><i class="fa fa-fw fa-arrows-v"></i>
                        </drag>
                      </drop>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="goBack">뒤로</button>
          <button type="button" class="btn btn-default" @click="doRegister">저장</button>
        </div>
      </div>
    </div>
    <div class="modal" :style="findstoredlg && { display: 'block' }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">매장검색</div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <select class="form-control" style="width:auto;" v-model="storekeywordType">
                <option value="BrandCode">브랜드코드</option>
                <option value="BrandNameContaining">브랜드명</option>
                <option value="StoreCode">매장코드</option>
                <option value="StoreNameContaining">매장명</option>
                <option value="ManagerID">담당자 ID</option>
              </select>
              <div class="input-group" style="flex:1">
                <input type="text" class="form-control" v-model="storekeyword"/>
                <span class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="searchstore">검색</button>
                </span>
              </div>
            </div>
            <div class="form-group">
                <div class="table-responsive p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th><input type="checkbox" class="allstch" @change="allstchech($event)"/></th>
                        <th>매장코드</th>
                        <th>브랜드명</th>
                        <th>매장명</th>
                        <th>운영상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="store in findstorelist" :key="store.storeCode">
                        <td><input type="checkbox" class="stchitem" :value="store.storeCode"/></td>
                        <td>{{store.storeCode}}</td>
                        <td>{{store.brandName}}</td>
                        <td>{{store.basicInfo.storeName}}</td>
                        <td>{{store.enabled ? '활성화' : '비활성화'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeFindstore">
                닫기
              </button>
              <button type="button" class="btn btn-default" @click="onRegAddStore">추가</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :style="findprodlg && { display: 'block' }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">상품검색</div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <select class="form-control" style="width:auto;" v-model="sgoodkeytype">
                <option value="NoKeyword">전체분류</option>
                <option value="GoodsNameContaining">상품명</option>
                <option value="GoodsId">상품코드</option>
              </select>
              <div class="input-group" style="flex:1">
                <input type="text" class="form-control" v-model="sgoodkeyval"/>
                <span class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="searchGoods">검색</button>
                </span>
              </div>
            </div>
            <div class="form-group">
                <div class="table-responsive p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th><input type="checkbox" class="allgdch" @change="allgdchech($event)"/></th>
                        <th>상품코드</th>
                        <th>상품명</th>
                        <th>상품상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="good in goodlist" :key="good.goodsId">
                        <td v-if="good.goodsType !== 'BookingCoupon'"><input type="checkbox" class="gdchitem" :value="good.goodsId"/></td>
                        <td v-if="good.goodsType !== 'BookingCoupon'">{{good.goodsId}}</td>
                        <td v-if="good.goodsType !== 'BookingCoupon'">{{good.goodsName}}</td>
                        <td v-if="good.goodsType !== 'BookingCoupon'">{{good.onSale ? '판매중' : '판매중지'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeFindpro">
                닫기
              </button>
              <button type="button" class="btn btn-default" @click="onRegAddGood">추가</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
// import SingleImgUpload from "../../components/SingleImgUpload";
// import Pagination from "../../components/Pagination";
// import ToggleBtn from "../../components/ToggleBtn";
import { reqGet, reqPost } from "../../lib/request";
import { display, goodsapi, brandapi } from "../../lib/api";
import { DateFormat } from "../../lib/utility";

import { Drag, Drop } from "vue-drag-drop";
import $ from 'jquery';
export default {
  components: {
    // Pagination,
    // ToggleBtn,
    // SingleImgUpload,
    Drag,
    Drop,
  },
  created() {
    this.mainPageId = this.$route.query.mainPageId;
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_DSP,
      path2: menuval.LV1_HOME,
      title: "",
    });
  },
  data() {
    return {
      pcheadText: "",
      findstoredlg: false,
      findprodlg: false,
      mainPageId:0,
      title:"",//메인페이지 명
      description:"",//서브 제목
      showType:"GOODS",//[GOODS, STORE] 노출방법
      useFlag:false,//활성화여부\
      channels:[],//[PC, MOBILE, EZWEL, HAPPY, BENEPIA] 적용사이트
      regDate:"",//yyyy-MM-ddTHH:mm:ss 등록일
      products:[],//
      /**
       * productId            Number    등록상품 ID
       * brandCode            String    브랜드코드
       * brandName            String    브랜드
       * storeCode            String    상점코드
       * storeName            String    상점명
       * goodsId              Number    상품코드
       * goodsName            String    상품명
       * order                Number    등록상품 순서
       */

      storekeywordType:"BrandCode",
      storekeyword:"",
      findstorelist:[],

      sgoodkeytype:"NoKeyword",
      sgoodkeyval:"",//
      goodlist:[],
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    if(this.mainPageId != 0)
    {
      this.refreshPage();
    }
  },
  methods: {
    allstchech(event){
      $('input:checkbox.stchitem').prop('checked', event.target.checked);
    },
    allgdchech(event)
    {
      $('input:checkbox.gdchitem').prop('checked', event.target.checked);
    },
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    refreshPage() {
      reqGet(
        display.base,
        display.main_pagedetail + "?mainPageId=" + this.mainPageId,
        this.authToken,
        (result) => {
          if (result != null)
          {
            if(result.code == rescode.REQ_SUCCESS) {
              this.title = result.data.title;
              this.description = result.data.description;
              this.showType = result.data.showType;
              this.useFlag = result.data.useFlag;
              this.channels = result.data.channels;
              this.regDate = result.data.regDate;

              this.getStoreGoods(result.data.products);
              console.log(display.main_pagedetail + "?mainPageId=" + this.mainPageId,)

            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    closeFindstore() {
      $('input:checkbox.allstch').prop('checked', false);
      this.findstoredlg = false;
    },
    showFindstore() {
      this.storekeyword = "";
      this.findstoredlg = true;
    },
    closeFindpro() {
      $('input:checkbox.allgdch').prop('checked', false);
      this.findprodlg = false;
    },
    showFindpro() {
      this.sgoodkeytype = "NoKeyword";
      this.sgoodkeyval = "";
      this.goodlist = [];
      this.findprodlg = true;
    },
    handleDrop(myitem, drag) {
      var t_array = this.products;
      this.products = [];
      var t_val = t_array[myitem.index];
      t_array[myitem.index] = t_array[drag.index];
      t_array[drag.index] = t_val;
      this.products = t_array;
    },
    goBack(){
      this.$router.go(-1);
    },
    onClickAll(event)
    {
      if(event.target.checked)
      {
        this.channels = ["PC", "MOBILE", "EZWEL", "HAPPY", "BENEPIA","LIFECARE", "CHALETKOREA"];
      }else{
        this.channels = [];
      }
    },
    getAllChecked()
    {
      return this.channels.length == 7;
    },
    getStoreGoods(products)
    {
      for(var idx = 0; idx<products.length; idx++)
      {
        this.getGetProductInfo(products[idx])
      }
    },
    getGetProductInfo(tprd)
    {
      reqGet(
        goodsapi.base,
        goodsapi.storecodeNameslist + "?storeCode=" + tprd.storeCode + "&combinedFilter=ForNotCombinedOnly",
        this.authToken,
        (result) => {
          if (result != null){
            if(result.code == rescode.REQ_SUCCESS)
            {
              var t_goods = result.data.filter((ritem) => ritem.onSale) ;
              var t_product = {...tprd, goodsinfs:t_goods}
              this.products.push(t_product);
              this.products.sort((a, b) => {
                return a.order - b.order;
              });
            }
          }
        }
      );
    },
    searchstore()
    {
      if(this.storekeyword == "")
      {
        alert("검색어를 입력해주세요.");
        return ;
      }
      reqGet(
        brandapi.base,
        brandapi.basicInfoStorelist + "?keywordType=" + this.storekeywordType+"&keyword="+this.storekeyword,
        this.authToken,
        (result) => {
          if (result != null){
            if(result.code == rescode.REQ_SUCCESS)
            {
              this.findstorelist = result.data;
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    onRegAddStore()
    {
      var selstores = $('input:checkbox.stchitem:checked').map(function () {
        return this.value;
      }).get();
      var temp_product = this.findstorelist.filter((fstem)=>{
        var t_idx = selstores.findIndex((oitem) => oitem == fstem.storeCode);
        var p_idx = this.products.findIndex((pitem) => pitem.storeCode == fstem.storeCode);
        return t_idx > -1 && p_idx == -1;
      });
      for(var idx=0; idx<temp_product.length; idx++)
      {
        this.getNewStorePush(temp_product[idx]);
      }
      this.closeFindstore();
    },
    getNewStorePush(tprd)
    {
      reqGet(
        goodsapi.base,
        goodsapi.storecodeNameslist + "?storeCode=" + tprd.storeCode + "&combinedFilter=ForNotCombinedOnly",
        this.authToken,
        (result) => {
          if (result != null){
            if(result.code == rescode.REQ_SUCCESS)
            {
              var t_goods = result.data.filter((ritem) => ritem.onSale) ;
              var t_product = {storeCode:tprd.storeCode, brandName:tprd.brandName, storeName:tprd.basicInfo.storeName, goodsinfs:t_goods, goodsId:null}
              this.products.push(t_product);
            }
          }
        }
      );
    },
    searchGoods(){
      if(this.sgoodkeyval == "")
      {
        alert("검색어를 입력해주세요");
        return ;
      }
      reqGet(
        goodsapi.base,
        goodsapi.codeNameslist + "?keywordType=" + this.sgoodkeytype + "&keyword=" + this.sgoodkeyval + "&combinedFilter=ForNotCombinedOnly",
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              // this.goodlist = result.data.map((ditem) => );
              this.goodlist = result.data;
            } else {
              this.goodlist = [];
              alert(result.message);
            }
          }
        }
      );
    },
    onRegAddGood()
    {
      var selgoods = $('input:checkbox.gdchitem:checked').map(function () {
        return this.value;
      }).get();
      var temp_product = this.goodlist.filter((fgdem)=>{
        var t_idx = selgoods.findIndex((oitem) => oitem == fgdem.goodsId);
        var p_idx = this.products.findIndex((pitem) => pitem.goodsId == fgdem.goodsId);
        return t_idx > -1 && p_idx == -1;
      });
      var t_goods = temp_product.map((titem) => { return {storeCode:null, goodsId:titem.goodsId, goodsName:titem.goodsName}});

      for(var idx=0; idx<t_goods.length; idx++)
      {
        this.products.push(t_goods[idx]);
      }
      this.closeFindpro();
    },
    doRmvProduct()
    {
      var rmvgoods = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      var t_products = this.products;
      this.products = [];
      if(this.showType == 'GOODS')
      {
        t_products = t_products.filter((titem) => {
          var f_idx = rmvgoods.findIndex((ritem) => ritem == titem.goodsId);
          return f_idx == -1;
        })
      }else if(this.showType == 'STORE')
      {
        t_products = t_products.filter((titem) => {
          var f_idx = rmvgoods.findIndex((ritem) => ritem == titem.storeCode);
          return f_idx == -1;
        })
      }
      $('input:checkbox.allch').prop('checked', false);
      $('input:checkbox.chitem').prop('checked', false);
      this.products = t_products;
    },
    doRegister()
    {
      if(this.title == "")
      {
        alert("메인페이지 명을 입력하세요.");
        return ;
      }
      if(this.description == "")
      {
        alert("서브 제목을 입력하세요.");
        return ;
      }
      if(this.channels == "")
      {
        alert("적용 사이트를 체크하세요.");
        return ;
      }

      var t_products = [];

      t_products = this.products.map((pitem, pidx) => { return {storeCode:pitem.storeCode, goodsId:pitem.goodsId, order:pidx+1}});

      if(this.mainPageId == 0)
      {
        var reqdata = {
          title:this.title,
          description:this.description,
          showType:this.showType,
          channels:this.channels,
          products:t_products
        };

        this.$store.dispatch('setDisabledLayout');
        reqPost(
          display.base,
          display.main_pageregister,
          this.authToken,
          reqdata,
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              }else{
                alert(result.message);
              }
            }
          }
        );
      }else{
        reqPost(
          display.base,
          display.main_pagemodify,
          this.authToken,
          {
            mainPageId:this.mainPageId,
            title:this.title,
            description:this.description,
            showType:this.showType,
            channels:this.channels,
            products:t_products
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              }else{
                alert(result.message);
              }
            }
          }
        );
      }
    }
  },
};
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.radio > label {
  margin-right: 10px;
}

.checkbox > label {
  margin-right: 10px;
}
</style>
