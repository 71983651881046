export const adminapi = {
  base: "/users/v1/",
  register: "/admin/auth/register",//가입신청
  login: "/admin/auth/login",//로그인
  refresh: "/admin/auth/refresh",//로그인 Refresh
  mfaotp: "/admin/auth/mfa/otp",//외부접속 OTP 인증번호 요청 (TNJ)
  mfalogin: "/admin/auth/mfa/login",//외부접속 로그인 (TNJ)
  Pswotp: "/admin/auth/findPassword/otp",//비밀번호 찾기 임시 비밀번호 요청
  Pswlogin: "/admin/auth/findPassword/login",//비밀번호 찾기 임시 비밀번호 로그인
  logout: "/admin/logout",//로그아웃 (인증필수)
  changePassword: "/admin/changePassword",//비밀번호 변경 (인증필수)
  changeInfo: "/admin/changeInfo",//나의 정보 변경
  brdgrantRole: "/admin/brd/staff/grantRole",//가입 신청 승인 및 역할 부여 (BRD)
  tnjgrantRole: "/admin/tnj/staff/grantRole",//가입 신청 승인 및 역할 부여 (TNJ)
  staffunregister: "/admin/staff/unregister",//관리자 계정 삭제
  stafflist: "/admin/staff/list",//관리자 리스트 조회
  approvedList: "/admin/tnj/staff/approvedList/page",//승인된 TNJ 관리자 리스트 조회 (TNJ)
  pendingList: "/admin/tnj/staff/pendingList/page",//승인 요청 TNJ 관리자 리스트 조회 (TNJ)
  staffinfo: "/admin/staff/info",//관리자 정보 조회
  myInfo: "/admin/myInfo",//나의 정보 조회
  userlist: "/admin/user/list/page",//가입한 사용자 리스트 조회
  userdetail: "/admin/user/detail",//가입한 사용자 정보 조회
  userunregister: "/admin/user/unregister",//가입한 사용자 계정 삭제
  sas: "/admin/sas",//Azure Storage SAS Token (인증필수)
  sasUpdate: "/admin/sas/forUpdate",//Azure Storage SAS Token for Update
  admininfo: "/admin/info",//관리자 정보 조회
  checkusrname: "/admin/auth/username/exists",//아이디 존재 여부 확인
  agentlist: "/admin/agent/list",//에이전트 리스트 조회 (TNJ)
}

/**********************브랜드관리**************************/
export const brandapi = {
  base: "/brands/v1/",

  enable: "/mng/store/adm/enable",
  disable: "/mng/store/adm/disable",
  barcodeEnable: "/mng/store/adm/enable/barcode",
  barcodeDisable: "/mng/store/adm/disable/barcode",

  // ------------------브랜드 API (관리자용)----------------------
  brandpage: "/mng/brand/tnj/brands/page", //브랜드 리스트 조회 (TNJ)
  brandpageList: "/mng/brand/tnj/brands/list", //브랜드 리스트 조회 (TNJ)
  brandregister: "/mng/brand/tnj/newBrand/register", //브랜드 등록 (TNJ)
  basicupdate: "/mng/brand/tnj/basicInfo/update", //브랜드 기본 정보 수정 (TNJ)
  basicquery: "/mng/brand/tnj/basicInfo/query", //브랜드 기본 정보 조회 (TNJ)
  contractupdate: "/mng/brand/tnj/contractInfo/update", //브랜드 계약 정보 수정 (TNJ)
  contractquery: "/mng/brand/tnj/contractInfo/query", //브랜드 계약 정보 조회 (TNJ)
  codequery: "/mng/brand/tnj/brandCodes/query", //브랜드 코드 리스트 조회 (TNJ)
  storesquery: "/mng/brand/tnj/brandCodes/withStores/query", //브랜드 & 하위 매장 코드 리스트 조회 (TNJ)
  brandexists: "/mng/brand/tnj/brandName/exists", //브랜드 이름 중복 확인 (TNJ)
  siteexists: "/mng/brand/tnj/siteName/exists", //도메인 이름 중복 확인 (TNJ)
  brandsbySites: "/mng/brand/adm/brandsIdentities/bySites", //도메인 이름으로 브랜드 코드/이름 조회
  siteupdate: "/mng/brand/tnj/siteInfo/update", //브랜드 사이트 정보 (고시정보) 수정 (TNJ)
  sitequery: "/mng/brand/tnj/siteInfo/query", //브랜드 사이트 정보 (고시정보) 조회 (TNJ)
  // ------------------매장 API (일반 사용자)----------------------
  storeinfo: "/public/store/info", //매장 정보 조회
  storeCodeNamesinSite: "/public/store/storeCodeNames/inSite", //사이트 내 매장코드/매장명 리스트 조회
  storeCodeNames: "/public/store/storeCodeNames", //매장코드/매장명 리스트 검색
  storeAreas: "/public/store/storeAreas", //(대/중분류) 지역 번호 리스트 조회
  inventory: "/public/store/inventory", //매장 예약 가능 인원 조회
  // ------------------매장 리스트 조회 API (관리자)----------------------
  bizInfoStorelist: "/mng/store/tnj/bizInfoStore/list", //브랜드 하위 매장 리스트 조회 (TNJ)
  basicInfoStorelist: "/mng/store/adm/basicInfoStore/list", //매장 리스트 조회 (TNJ, BRD)
  basicInfoStorepage: "/mng/store/adm/basicInfoStore/page", //매장 리스트 페이지 조회 (TNJ, BRD)
  brdofBrand: "/mng/store/brd/storeCodeNames/ofBrand", //브랜드 내 매장코드/매장명 리스트 검색 (BRD, STR)
  tnjofBrand: "/mng/store/tnj/storeCodeNames/ofBrand", //브랜드 내 매장코드/매장명 리스트 검색 (TNJ)
  admofStores: "/mng/store/adm/storeCodeNames/ofStores", //매장코드/매장명 리스트 검색
  // ------------------매장 기본 정보 (관리자)----------------------
  tnjbasicInfo: "/mng/store/tnj/basicInfo/query", //매장 기본 정보 조회 (TNJ)
  basicInfoupdate: "/mng/store/tnj/basicInfo/update", //매장 기본 정보 변경 (TNJ)
  // ------------------매장 콘텐츠 (관리자)----------------------
  tnjContentsquery: "/mng/store/tnj/siteContents/query", //매장 콘텐츠 정보 조회 (TNJ)
  tnjContentsupdate: "/mng/store/tnj/siteContents/update", //매장 콘텐츠 정보 변경 (TNJ)
  // ------------------매장 운영 정보 (관리자)----------------------
  tnjInfoquery: "/mng/store/tnj/runningInfo/query", //매장 운영 정보 조회 (TNJ)
  tnjInfoupdate: "/mng/store/tnj/runningInfo/update", //매장 운영 정보 변경 (TNJ)
  // ------------------매장 정산 계약 정보 (관리자)----------------------
  tnjsettlement: "/mng/store/tnj/settlement/query", //정산 계약 정보 조회 (TNJ)
  tnjsettlupdate: "/mng/store/tnj/settlement/update", //정산 계약 정보 변경 (TNJ)
  admdateGroup: "/mng/store/adm/settlement/dateGroup/list", //정산 기준일 리스트 조회
  // ------------------매장 입금 계좌 정보 (관리자)----------------------
  tnjbankbook: "/mng/store/tnj/bankbook/query", //입금 통장 정보 조회 (TNJ)
  tnjbankbooks: "/mng/store/tnj/bankbooks/query", //입금 통장 정보 조회 (TNJ)
  tnjbankbookupdate: "/mng/store/tnj/bankbook/update", //입금 통장 정보 변경 (TNJ)
  // ------------------매장 예약 설정 정보 (관리자)----------------------
  tnjbookingSettings: "/mng/store/tnj/bookingSettings/query", //예약 설정 조회 (TNJ)
  tnjbookingsupdate: "/mng/store/tnj/bookingSettings/update", //예약 설정 변경 (TNJ)
  // ------------------매장 주말 설정 정보 (관리자)----------------------
  tnjweekendSettings: "/mng/store/tnj/weekendSettings/query", //주말 설정 조회 (TNJ)
  tnjweekendupdate: "/mng/store/tnj/weekendSettings/update", //주말 설정 변경 (TNJ)
  // ------------------매장 예약 가능 인원 설정 정보 (관리자)----------------------
  tnjinventory: "/mng/store/tnj/inventorySettings/list", //예약 가능 인원 설정 리스트 조회 (TNJ)
  tnjinventoryquery: "/mng/store/tnj/inventorySettings/query", //예약 가능 인원 설정 조회 (TNJ)
  tnjinventorycreate: "/mng/store/tnj/inventorySettings/create", //예약 가능 인원 설정 정보 생성 (TNJ)
  tnjinventoryupdate: "/mng/store/tnj/inventorySettings/update", //예약 가능 인원 설정 정보 변경 (TNJ)
  tnjinventorydelete: "/mng/store/tnj/inventorySettings/delete", //예약 가능 인원 설정 정보 삭제 (TNJ)
  tnjinventorypriority: "/mng/store/tnj/inventorySettings/priority/update", //예약 가능 인원 설정 정보 우선순위 변경 (TNJ)
  // ------------------메인 상품 관리 (관리자)----------------------
  mainGoodsIdquery: "/mng/store/adm/mainGoodsId/query", //메인 상품 조회
  tnjmainIdupdate: "/mng/store/tnj/mainGoodsId/update", //메인 상품 지정 (TNJ)
  tnjmainIddelete: "/mng/store/tnj/mainGoodsId/delete", //메인 상품 삭제 (TNJ)
  // ------------------에이전트 관리 (관리자)----------------------
  tnjagentUsername: "/mng/store/tnj/agentUsername/query", //에이전트 ID 조회
  tnjagentupdate: "/mng/store/tnj/agentUsername/update", //에이전트 ID 지정 (TNJ)
  tnjagentdelete: "/mng/store/tnj/agentUsername/delete", //에이전트 ID 삭제 (TNJ)
  // ------------------알림톡 설정 (TNJ)----------------------
  alimtalkSettings: "/mng/store/tnj/alimtalkSettings/query", //알림톡 설정 조회
  alimtalkupdate: "/mng/store/tnj/alimtalkSettings/update", //알림톡 설정 변경
  newalimtalkSettings: "/mng/store/tnj/siteAlimtalk/query", //스토어 알림톡 정보조회 API(TNJ)

  // ------------------매장 이름 중복 확인 (관리자)----------------------
  tnjstrexists: "/mng/store/tnj/storeName/exists", //매장 이름 중복 확인 (TNJ)
  // ------------------매장 삭제 (관리자)----------------------
  storedel: "/mng/store/adm/delete", //매장 삭제 (BRD, TNJ)
  storelistdel: "/mng/store/adm/list/delete", //여러 매장 삭제 (BRD, TNJ)
  // ------------------신규 매장 등록 요청 (관리자)----------------------
  newStoreinfo: "/mng/store/register/adm/newStore/register/info", //작업 중인 매장 등록 정보 조회 (BRD, TNJ)
  newStoredeny: "/mng/store/register/tnj/newStore/register/deny", //매장 등록 요청 승인 거절 (TNJ)
  newStoreapprove: "/mng/store/register/tnj/newStore/register/approve", //매장 등록 요청 승인 (TNJ)
  newStoreupdate: "/mng/store/register/tnj/newStore/register/updateAndApprove", //매장 등록 요청 수정 & 승인 (TNJ)
  newStoredirect: "/mng/store/register/tnj/newStore/register/directRegister", //매장 바로 등록 (TNJ)
  newStorepage: "/mng/store/register/tnj/newStore/request/page", //매장 등록 요청 리스트 조회 (TNJ)
  // ------------------은행정보 API (PUBLIC)----------------------------
  banklist: "/public/store/bank/list", //은행코드/은행명 리스트 조회
  // ------------------대시보드 API (관리자)-----------------------------
  count_newRequest: "/mng/dashboard/tnj/count/newRequest", //신규 요청 카운트 조회 (TNJ)
  // ------------------채널노출 API (관리자)-----------------------------
  openChannelInfo: "/mng/store/tnj/openChannel/query", //채널노출 조회(TNJ)
  openChannelUpdate: "/mng/store/tnj/openChannel/update", //채널노출 저장(TNJ)

  // ------------------핫플레이스 (관리자)-----------------------------
  hotplaceList: "/mng/store/tnj/hotplace/page", //핫플레이스 리스트 (TNJ)
  hotplaceCreate: "/mng/store/tnj/hotplace/create", //핫플레이스 등록 (TNJ)
  hotplaceQuery: "/mng/store/tnj/hotplace/query", //핫플레이스 조회 (TNJ)
  hotplaceDelete: "/mng/store/tnj/hotplace/delete", //핫플레이스 삭제 (TNJ)
  hotplaceUpdate: "/mng/store/tnj/hotplace/update", //핫플레이스 수정 (TNJ)
  hotplaceEnable: "/mng/store/tnj/hotplace/enable", //핫플레이스 추천노출 활성화 (TNJ)
  hotplaceDisable: "/mng/store/tnj/hotplace/disable", //핫플레이스 추천노출 비활성화 (TNJ)

  // ------------------메뉴 (관리자)-----------------------------
  menuList: "/mng/store/tnj/menu/list", // 메뉴 리스트 조회 (TNJ)
  menuCreate: "/mng/store/tnj/menu/create", // 메뉴 등록(tnj)
  menuUpdate: "/mng/store/tnj/menu/update", //메뉴 수정(tnj)
  menuEnable: "/mng/store/tnj/menu/enable", //메뉴 대표메뉴 활성화 (TNJ)
  menuDisable: "/mng/store/tnj/menu/disable", //메뉴 대표메뉴 비활성화 (TNJ)
  menuQuery: "/mng/store/tnj/menu/query", //메뉴 조회 (TNJ)
  menuDelete: "/mng/store/tnj/menu/delete", //메뉴 삭제 (TNJ)
  menuNumberUpdate:"/mng/store/tnj/menu/sort/number/update",

  // ------------------매장 예약 시간설정 API (관리자)-----------------------------
  timeSettingCreate: "/mng/store/tnj/timeSettings/create", //기본운영시간 재설정 / 임시운영시간 추가 (TNJ)
  timeSettingList: "/mng/store/tnj/timeSettings/list", // 예약시간 리스트 조회 (TNJ)
  timeSettingQuery: "/mng/store/tnj/timeSettings/query", // 예약시간 조회 (TNJ)
  timeSettingUpdate: "/mng/store/tnj/timeSettings/update", //기본운영시간 수정 (TNJ)
  timeSettingDelete: "/mng/store/tnj/timeSettings/delete", //임시운영시간 삭제 (TNJ)
  timeSettingReset: "/mng/store/tnj/timeSettings/reset", //시간설정 초기화 (TNJ)

  // -----------------매장 메뉴 옵션 API (관리자)----------------------------------

  storeOptionCreate: "/mng/store/tnj/option/create", //메뉴 옵션 등록 (TNJ)
  storeOptionList: "/mng/store/tnj/option/list", //메뉴 옵션 리스트 조회 (TNJ)
  storeOptionQuery: "/mng/store/tnj/option/query", //메뉴 옵션 조회 (TNJ)
  storeOptionUpdate: "/mng/store/tnj/option/update", //메뉴 옵션 수정 (TNJ)
  storeOptionDelete: "/mng/store/tnj/option/delete", //메뉴 옵션 삭제 (TNJ)
  storeNumberUpdate: "/mng/store/tnj/menu/option/sort/number/update",
  storeGoodsOptionList: "/mng/store/tnj/goods/list", //24-06 카카오톡 예약하기 신규 메뉴 옵션 리스트 조회 (TNJ)
  storeGoodsNumberUpdate: "/mng/store/tnj/menu/goods/sort/number/update",
  storeGoodsOptionDelete: "/mng/store/tnj/goods/delete", //메뉴 옵션 삭제 (TNJ)
  storeChannelAdditionalQuery:"/mng/store/tnj/kakaoGoods/query",
  regStoreReservationGoods: "/mng/store/tnj/goods/create",
  updateStoreReservationGoods: "/mng/store/tnj/goods/update",
  viewStoreReservationGoods:"/mng/store/tnj/goods/query",
  storeKakaoGoodsUpdate: "/mng/store/adm/kakaoGoods/update",
  queryKakaoSync:"/mng/store/adm/kakao/sync/notification/query",
  updateKakaoSync:"/mng/store/adm/kakao/sync/notification/update",


  // ------------------ 매장 옵션 연결 API (관리자) -------------------------------
  storeTimeOptionList: "/mng/store/tnj/timeOption/list", //옵션연결 리스트 조회 (TNJ)
  storeTimeOptionUpdate: "/mng/store/tnj/timeOption/update", //옵션연결 수정 (TNJ)
  storeTimeOptionQuery: "/mng/store/tnj/timeOption/query", //옵션연결 조회 (TNJ)
  storeTimeOptionDelete: "/mng/store/tnj/timeOption/delete", //옵션연결 삭제 (TNJ)

  // ------------------ 매장 이미지 API (관리자) -------------------------------
  storeImageslist: "/mng/store/tnj/images/list", //상세이미지 리스트 조회 (TNJ)
  storeImagesUpdate: "/mng/store/tnj/images/update", //상세이미지 수정 (TNJ)
  storeImagesTitleExist: "/mng/store/tnj/images/titleExist", //상세이미지 타이틀 중복체크 (TNJ)

  //------------------- 매장 프로모션 API -------------------------------
  storePromotionCreate: "/mng/store/tnj/promotion/create", //프로모션 등록 (TNJ)
  storePromotionList: "/mng/store/tnj/promotion/list", //프로모션 리스트 조회 (TNJ)
  storePromotionPriority: "/mng/store/tnj/promotion/priority", //프로모션 우선순위 변경 (TNJ)
  storePromotionEnable: "/mng/store/tnj/promotion/enable", //프로모션 상태 활성화 (TNJ)
  storePromotionDisable: "/mng/store/tnj/promotion/disable", //프로모션 상태 비활성화 (TNJ)
  storePromotionQuery: "/mng/store/tnj/promotion/query", //프로모션 조회 (TNJ)
  storePromotionUpdate: "/mng/store/tnj/promotion/update", //프로모션 수정 (TNJ)
  storePromotionDelete: "/mng/store/tnj/promotion/delete", //프로모션 삭제 (TNJ)

  //----------------매장 시간대 할인설정 API (관리자)--------------------
  storeSwingTimeList: "/mng/store/tnj/swingTime/list", //할인설정 리스트 조회 (TNJ)
  storeSwingTimeCreate: "/mng/store/tnj/swingTime/create", //할인설정 등록 (TNJ)
  storeSwingTimeQuery: "/mng/store/tnj/swingTime/query", //할인설정 조회 (TNJ)
  storeSwingTimeUpdate: "/mng/store/tnj/swingTime/update", //할인설정 수정 (TNJ)
  storeSwingTimePriority: "/mng/store/tnj/swingTime/priority", //할인설정 우선순위 변경 (TNJ)
  storeSwingTimeDelete: "/mng/store/tnj/swingTime/delete", //할인설정 삭제 (TNJ)

  //----------------편의시설 API (관리자)-------------------------
  storeAmenityCreate: "/mng/store/tnj/amenity/create", //편의시설 등록 (TNJ)
  storeAmenityUpdate: "/mng/store/tnj/amenity/update", //편의시설 수정 (TNJ)
  storeAmenitylist: "/mng/store/tnj/amenity/list", //편의시설 리스트 조회 (TNJ)

  //-------------업종 리스트 조회----------------------------
  storeType: "/public/store/storeType/list",
  //------------------------------------------------------

  //-------------------사이트 컬러, 이미지 조회 수정--------------------------------
  siteColorUpdate: "/mng/brand/tnj/siteColor/update",
  siteColorQuery: "/mng/brand/tnj/siteColor/query",
  siteImagesUpdate: "/mng/brand/tnj/siteImages/update",
  siteImagesQuery: "/mng/brand/tnj/siteImages/query",

  siteGiftMessageQuery: "/mng/brand/tnj/giftMessage/query",
  siteGiftMessageUpdate: "/mng/brand/tnj/giftMessage/update",

  //브랜드 코드 리스트 조회 (tnj)
  brandCodesList: "/mng/brand/tnj/brandCodes/list",

  //예약현황조회 월별(tnj)
  brandMonthly: "/mng/store/tnj/booking/status/monthly",
  brandWeekly: "/mng/store/tnj/booking/status/weekly",
  bookingStop: "/mng/store/tnj/booking/date/stop",
  bookingResume: "/mng/store/tnj/booking/date/resume",
  bookingTimeStop: "/mng/store/tnj/booking/timeSlot/stop",
  bookingTimeResume: "/mng/store/tnj/booking/timeSlot/resume",

  // 매장 플랫폼 포인트 적립요청 (TNJ)
  poinUpdate: "/mng/store/tnj/point/update",
  // 매장 플랫폼 포인트 적립 조회 (TNJ)
  poinQuery: "/mng/store/tnj/point/query",

  siteBookingInfoQuery: "/mng/brand/tnj/siteBookingInfo/query",//브랜드 사이트 예약자 정보요청 조회 (TNJ)
  siteBookingInfoUpdate:'/mng/brand/tnj/siteBookingInfo/update',//브랜드 사이트 예약자 정보요청 수정 (TNJ)
  siteAlimtalk:"/mng/brand/tnj/siteAlimtalk/query",//브랜드 사이트 알림톡 정보 조회(TNJ)
  alimtalkListPage:"/mng/brand/tnj/alimtalk/page", //알림톡 관리 페이징(TNJ)
  alimtalkExcelDown:"/mng/brand/tnj/alimtalk/list",//알림톡 관리 엑셀다운로드(TNJ)
  alimtalkBrandOtp:"/public/brand/alimtalk/otpRequest",//알림톡관리 인증번호 발송(TNJ)
  alimtalkBrandReg: "/mng/brand/tnj/siteAlimtalk/register",//브랜드 알림톡 등록신청(TNJ)
  alimtalkStoreReg: "/mng/store/tnj/siteAlimtalk/register",
  alimtalkBrandUpdate: "/mng/brand/tnj/siteAlimtalk/update",//브랜드 알림톡 등록정보수정 API(TNJ)
  alimtalkStoreUpdate: "/mng/store/tnj/siteAlimtalk/update",
  brandAlimtalkDisable: "/mng/brand/tnj/siteAlimtalk/disable",
  brandAlimtalkApprove: "/mng/brand/tnj/siteAlimtalk/approve",
  storeAlimtalkDisable: "/mng/store/tnj/siteAlimtalk/disable",
  storeAlimtalkApprove: "/mng/store/tnj/siteAlimtalk/approve",
  alimtalkLargeForm:"/public/brand/alimtalk/main/category/list",
  alimtalkMiddleForm:"/public/brand/alimtalk/middle/category/list",
  alimtalkSmallForm:"/public/brand/alimtalk/small/category/list",
  getFoodCategory:"/mng/store/tnj/foodCategory/list",

  // ------------------카카오톡 예약하기 API (관리자용)---------------------------
  kakaoReservePage:"/mng/brand/tnj/partner/page", // /mng/brand/tnj/partner/page // 입점신청 리스트
  kakaoPartnerMngPage:"/mng/store/adm/kakao/partner/page/query", // 파트너 리스트
  kakaoChannelSearch:"/mng/store/adm/kakao/channel/search",  // 사업자번호로 채널 찾기
  kakaoReserveStoreList:"/mng/store/tnj/partner/query/ofBrand", // 매장 리스트
  kakaoReserveStoreReg:"/mng/store/adm/partner/register", // 입점신청
  kakaoReserveStoreShow:"/mng/store/adm/partner/detail/query", // 신청조회
  kakaoChannenlLinkedSearch:"/mng/store/adm/partner/query/ofPartner", // 파트너로 연동매장 리스트 조회
  approveKakaoReserveStore: "/mng/store/adm/kakao/partner/register", // 파트너 등록
  cancelKakaoReserveStore: "/mng/store/adm/partner/cancel", // 신청취소
  rejectKakaoReserveStore:"/mng/store/adm/partner/reject", //신청 반려
  getKakaoPartnerDetail:"/mng/store/adm/kakao/partner/detail/query",
  getKakaoStoreLocationInfo: "/mng/store/adm/basic/kakao/info/query",
  regKakaoLocationInfo: "/mng/store/adm/kakao/store/register",
  getKakaoCategoryList:"/mng/store/adm/kakao/category/list",
  getPartnerListOfKakao:"/mng/store/adm/partner/query/ofKakaoId",
  addKakaoReserveRegStoreList:"/mng/store/adm/kakao/partner/add/store",
  updateParterStatus:"/mng/store/adm/kakao/partner/status/update",
  updatePartnerInfo:"/mng/store/adm/kakao/partner/update",

}

/**********************메세지 API****************************/
export const message = {
  base: "/messages/v1/",
  // ------------------쿠폰 발송 API (관리자용)---------------------------
  sendHistory: "/mng/message/adm/query/coupon/sendHistory",//쿠폰 발송 내역 리스트 조회
  testNumberRegister: "/mng/message/sendable_test_number/register",
  testNumberList: "/mng/message/sendable_test_number/list",
  testNumberDelete: "/mng/message/sendable_test_number/delete"
}

/*********************  취소/환불 처리내역 조회 (TNJ) API ************************************/

/**********************개인화서비스 API****************************/
export const personalization = {
  base: "/personalization/v1/",
  // ------------------리뷰 API (관리자)---------------------------
  reviewListPage: "/adm/review/query/page",//리뷰 리스트 페이지
  reviewDel:'/adm/review/delete',//리뷰 삭제

  //------------------------ 리뷰키워드 API (관리자) ------------------------
  reviewKeywordUpdate:"/adm/review/keyword/update",//리뷰키워드 업데이트
  reviewKeywordList: "/adm/review/keyword/list",//리뷰키워드 리스트
  reviewRegister:'/adm/review/keyword/register',//리뷰키워드 추가

  //------------------------ 리뷰 이미지 ------------------------
  reviewImageUpdate:'/adm/review/image/update',//리뷰 이미지 업데이트
  reviewImageList:'/adm/review/image/list',//리뷰 이미지 리스트

}
/**********************개인화서비스 API END****************************/


/**********************주문, 예약 API**************************/
export const orderapi = {
  base: "/orders/v1/",
  // -------------------주문 API (로그인 일반 사용자)-------------------
  mobileCoupon: "/user/order/prepareOrder/mobileCoupon", //모바일쿠폰 대기주문 생성
  moneyCoupon: "/user/order/prepareOrder/moneyCoupon", //금액권 대기주문 생성
  booking: "/user/order/prepareOrder/booking", //예약 대기주문 생성
  bookingCoupon: "/user/order/prepareOrder/bookingCoupon", //예약권 대기주문 생성
  orderCreateInfo: "/user/order/query/orderCreateInfo", //주문 생성 정보 조회 (주문 결제 완료 후 사용)
  // -------------------취소/환불 API (로그인 일반 사용자)-------------------
  refundAmount: "/user/order/query/refundAmount", //취소/환불 금액 조회
  requestRefund: "/user/order/requestRefund", //취소/환불 신청
  refundHistory: "/mng/order/adm/query/refundHistory",
  // -------------------구매/사용 내역 조회 API (로그인 일반 사용자)-------------------
  orderList: "/user/order/query/orderList", //구매 내역 리스트 조회
  orderDetails: "/user/order/query/orderDetails", //구매 내역 상세 조회
  itemUseInfo: "/user/order/item/query/itemUseInfo", //쿠폰 사용 내역 조회
  // -------------------유효기간 연장 API (로그인 일반 사용자)-------------------
  queryExtendableItems: "/user/order/queryExtendableItems", //연장 가능 쿠폰 조회
  extendExpiryDate: "/user/order/extendExpiryDate", //유효기간 연장
  // -------------------메시지 전송 API (로그인 일반 사용자)-------------------
  resendOrderItems: "/user/order/message/resendOrderItems", //쿠폰 재전송
  // -------------------예약 신청/취소 API (로그인 일반 사용자)-------------------
  request: "/user/booking/request", //예약 신청
  cancel: "/user/booking/cancel", //예약 취소
  // -------------------예약 내역 조회 API (로그인 일반 사용자)-------------------
  bookingList: "/user/booking/query/bookingList", //예약 리스트 조회
  bookingDetails: "/user/booking/query/bookingDetails", //예약 상세 조회
  // -------------------예약 내역 조회 API (관리자)-------------------
  bookingspage: "/mng/booking/adm/query/bookings/page", //예약 리스트 페이지 조회
  bookingslist: "/mng/booking/adm/query/bookings/list", //예약 리스트 조회
  requestspage: "/mng/booking/adm/query/bookingRequests/page", //예약 신청 리스트 페이지 조회
  admbookingDetails: "/mng/booking/adm/query/bookingDetails", //예약 내용 상세 조회
  // -------------------예약 신청 승인/거절 (관리자)-------------------
  approveBookingRequest: "/mng/booking/adm/approveBookingRequest", //예약 신청 승인
  rejectBookingRequest: "/mng/booking/adm/rejectBookingRequest", //예약 신청 거절
  bookingRefundAmount: "/mng/booking/adm/query/refundAmount", //예약취소 신청시 환불되는 금액/방법 조회
  bookingByPaymentCancel: "/mng/booking/tnj/cancelBooking/byPaymentCancel", //결제취소를 통한 예약취소 (TNJ)
  bookingByCreateRefundCs: "/mng/booking/tnj/cancelBooking/byCreateRefundCs", //환불CS 생성을 통한 예약취소 (TNJ)
  bookingByRestoreCoupon: "/mng/booking/tnj/cancelBooking/byRestoreCoupon", //예약권 복원을 통한 예약취소 (TNJ)

  // -------------------예약 방문 처리/조회/취소 (관리자)-------------------
  visitStore: "/mng/booking/adm/visitStore", //예약 방문 처리
  storeVisitInfo: "/mng/booking/adm/query/storeVisitInfo", //예약 방문 처리 내역 조회
  cancelVisitStore: "/mng/booking/adm/cancelVisitStore", //예약 방문 처리 취소
  noShow: "/mng/booking/adm/noShow", //예약 노쇼 처리
  cancelNoShow: "/mng/booking/adm/cancelNoShow", ///mng/booking/adm/cancelNoShow
  noShowRefundAmount: "/mng/booking/adm/query/noShowRefundAmount", //노쇼처리 신청시 환불되는 금액/방법 조회
  noShowByPaymentCancel: "/mng/booking/tnj/noShow/byPaymentCancel", //결제취소를 통한 예약 노쇼처리 (TNJ)
  noShowByCreateRefundCs: "/mng/booking/tnj/noShow/byCreateRefundCs", //환불CS 생성을 통한 예약 노쇼처리 (TNJ)
  noShowByRestoreCoupon: "/mng/booking/tnj/noShow/byRestoreCoupon", //예약권 복원을 통한 예약 노쇼처리 (TNJ)

  // -------------------예약 정보 수정 (관리자)-------------------
  changeVisitor: "/mng/booking/adm/changeVisitor", //예약자(방문자) 정보 수정
  queryAddableBookingItem: "/mng/booking/adm/queryAddableBookingItem", //추가 가능한 예약권(쿠폰) 조회
  addBookingItems: "/mng/booking/adm/addBookingItems", //예약권(쿠폰) 추가
  removeBookingItem: "/mng/booking/adm/removeBookingItem", //예약권(쿠폰) 삭제
  saveMemo: "/mng/booking/adm/saveMemo", //예약 관련 메모 저장
  // -------------------예약 메시지 전송 API (관리자)-------------------
  resendBookingVoucher: "/mng/booking/adm/message/resendBookingVoucher", //예약문자 재전송
  // -------------------주문 API (관리자)-------------------
  orderspage: "/mng/order/adm/query/orders/page", //주문 내역 리스트 페이지 조회
  orderslist: "/mng/order/adm/query/orders/list", //주문 내역 리스트 조회
  admorderDetails: "/mng/order/adm/query/orderDetails", //주문 상세 조회
  admextendExpiryDate: "/mng/order/adm/extendExpiryDate", //유효기간 연장
  admrefundAmount: "/mng/order/adm/query/refundAmount", //주문 취소에 대한 환불 방법 및 예상 금액 조회
  admrequestRefund: "/mng/order/brd/requestRefund", //주문 취소 (BRD)
  cancelByPaymentCancel: "/mng/order/adm/cancelByPaymentCancel", //결제 취소를 이용한 주문 취소 (TNJ)
  cancelByCreateRefundCs: "/mng/order/adm/cancelByCreateRefundCs", //취소요청 CS 생성을 통한 주문 취소 요청 (TNJ)
  cancelByCreateSimpleRefundCs: "/mng/order/adm/cancelByCreateSimpleRefundCs", //단순 계좌이체 환불 취소요청 CS 생성을 통한 주문 취소 요청 (TNJ)
  justSetCancelled: "/mng/order/adm/justSetCancelled", //단순 주문 취소 처리 (TNJ, 결제 취소나 환불을 시스템에서 처리하지 않음. B2B 주문 취소 등으로 관리자에 의해 결제 취소 진행)
  restoreCancelledOrder: "/mng/order/adm/restoreCancelledOrder", //(취소된) 주문 상태 복원
  changeBuyer: "/mng/order/adm/changeBuyer", //구매자 정보 수정
  changeItemReceiver: "/mng/order/adm/changeItemReceiver", //쿠폰 수신자 정보 수정
  admsaveMemo: "/mng/order/adm/saveMemo", //주문 관련 메모 저장
  paymentCancelFailList: "/mng/order/adm/paymentCancelFailItem/list", //주문취소 실패 리스트
  paymentCancelFailItemConsume:"/mng/order/adm/paymentCancelFailItem/consume", //주문취소 실패 수기처리

  // -------------------쿠폰 API (관리자)-------------------
  couponList: "/mng/order/adm/item/query/couponList", //모바일쿠폰, 금액권 쿠폰 조회
  mobileissuedpage: "/mng/order/tnj/item/query/mobileCoupon/issued/page", //모바일쿠폰 발급내역 페이지 조회 (TNJ)
  mobileissuedlist: "/mng/order/tnj/item/query/mobileCoupon/issued/list", //모바일쿠폰 발급내역 리스트 조회 (TNJ)
  moneyissuedpage: "/mng/order/tnj/item/query/moneyCoupon/issued/page", //금액권 발급내역 페이지 조회 (TNJ)pointCoupon
  moneyissuedlist: "/mng/order/tnj/item/query/moneyCoupon/issued/list", //금액권 발급내역 리스트 조회 (TNJ)
  bookingissuedpage: "/mng/order/tnj/item/query/bookingCoupon/issued/page", //예약권 발급내역 페이지 조회(TNJ)
  bookingissuedlist: "/mng/order/tnj/item/query/bookingCoupon/issued/list", //예약권 발급내역 리스트 조회 (TNJ)
  storeBookingIssPage: "/mng/order/tnj/item/query/storeBooking/issued/page", // 매장예약 발급내역 페이지 조회 (TNJ)
  storeBookingIssList: "/mng/order/tnj/item/query/storeBooking/issued/list", // 매장예약 발급내역 리스트 조회 (TNJ)
  mobilecancelledpage: "/mng/order/tnj/item/query/mobileCoupon/cancelled/page", //모바일쿠폰 발급취소내역 페이지 조회 (TNJ)
  mobilecancelledlist: "/mng/order/tnj/item/query/mobileCoupon/cancelled/list", //모바일쿠폰 발급취소내역 리스트 조회 (TNJ)
  moneycancelledpage: "/mng/order/tnj/item/query/moneyCoupon/cancelled/page", //금액권 발급취소내역 페이지 조회 (TNJ)
  moneycancelledlist: "/mng/order/tnj/item/query/moneyCoupon/cancelled/list", //금액권 발급취소내역 리스트 조회 (TNJ)
  bookingcancelledpage:
    "/mng/order/tnj/item/query/bookingCoupon/cancelled/page", //예약권 발급취소내역 리스트 조회 (TNJ)
  bookingcanelldlist: "/mng/order/tnj/item/query/bookingCoupon/cancelled/list", //예약권 발급취소내역 리스트 조회 (TNJ)
  storeCanellPage: "/mng/order/tnj/item/query/storeBooking/cancelled/page", //매장예약 발급취소내역 페이지 조회 (TNJ)
  storeCanellList: "/mng/order/tnj/item/query/storeBooking/cancelled/list", //매장예약 발급취소내역 리스트 조회 (TNJ)
  admconsumeMobileCoupons: "/mng/order/adm/item/consumeMobileCoupons", //모바일쿠폰 사용처리
  tnjconsumeMobileCoupons: "/mng/order/tnj/item/consumeMobileCoupons", //모바일쿠폰 사용처리 (TNJ)
  admuseMoneyCoupon: "/mng/order/adm/item/useMoneyCoupon", //금액권 사용처리
  tnjuseMoneyCoupons: "/mng/order/tnj/item/useMoneyCoupons", //금액권 사용처리 (TNJ)
  tnjconsumeBookingCoupons: "/mng/order/tnj/item/consumeBookingCoupons", //예약권 사용처리(TNJ)
  moneyCouponAmount: "/mng/order/adm/item/query/moneyCouponAmount", //금액권 사용 가능 금액 조회
  cancelItemUseTransaction: "/mng/order/adm/item/cancelItemUseTransaction", //모바일 쿠폰, 금액권 사용처리 취소, 예약권 사용처리 취소
  admitemUseInfo: "/mng/order/adm/item/query/itemUseInfo", //모바일 쿠폰, 금액권 사용처리 내용 조회
  itemsUseInfo: "/mng/order/adm/item/query/itemsUseInfo", //모바일 쿠폰, 금액권 리스트 사용처리 내용 조회
  mobileuseInfopage: "/mng/order/tnj/item/query/mobileCoupon/useInfo/page", //모바일 쿠폰 사용처리/취소 내역 페이지 조회 (TNJ)
  mobileuseInfolist: "/mng/order/tnj/item/query/mobileCoupon/useInfo/list", //모바일 쿠폰 사용처리/취소 내역 리스트 조회 (TNJ)
  moneyuseInfopage: "/mng/order/tnj/item/query/moneyCoupon/useInfo/page", //금액권 사용처리/취소 내역 페이지 조회 (TNJ)
  moneyuseInfolist: "/mng/order/tnj/item/query/moneyCoupon/useInfo/list", //금액권 사용처리/취소 내역 리스트 조회 (TNJ)
  bookingInfopage: "/mng/order/tnj/item/query/bookingCoupon/useInfo/page", //예약권 사용처리/ 취소 내역 리스트 조회(TNJ)
  bookingInfolist: "/mng/order/tnj/item/query/bookingCoupon/useInfo/list", //예약권 사용처리/ 취소 내역 리스트 조회(TNJ)

  reissueItems: "/mng/order/adm/item/reissueItems", //모바일 쿠폰, 금액권 재발급
  lockItems: "/mng/order/adm/item/lockItems", //모바일 쿠폰, 금액권 사용불가 처리
  unlockItem: "/mng/order/adm/item/unlockItem", //모바일 쿠폰, 금액권 사용불가 원복 처리
  itemRestore: "/mng/order/adm/item/restore", //모바일 쿠폰, 금액권 부분취소 원복 처리
  // -------------------쿠폰 메시지 전송 API (관리자)-------------------
  admresendOrderItems: "/mng/order/adm/message/resendOrderItems", //쿠폰 재전송
  // -------------------취소요청/잔액환불신청 CS 처리 API (TNJ)-------------------
  refundCspage: "/mng/cs/tnj/refund/query/refundCs/page", //취소요청/잔액환불신청 리스트 페이지 조회
  refundCsList: "/mng/cs/tnj/refund/query/refundCs/list", //취소요청/잔액환불신청 리스트 페이지 조회
  estimatedRefundAmount: "/mng/cs/tnj/refund/query/estimatedRefundAmount", //환불 및 환불(수수료) 요청금액 조회
  approvedRefundAmount: "/mng/cs/tnj/refund/query/approvedRefundAmount", //환불 및 환불(수수료) 승인 정보 조회
  updateRefundAccount: "/mng/cs/tnj/refund/refundCs/updateRefundAccount", //환불계좌정보 수정
  byAccountTransfer: "/mng/cs/tnj/refund/refundCs/accept/byAccountTransfer", //취소요청/잔액환불신청 환불승인
  byJustSetCancelled: "/mng/cs/tnj/refund/refundCs/accept/byJustSetCancelled", //취소요청/잔액환불신청 단순취소승인
  refundCsreject: "/mng/cs/tnj/refund/refundCs/reject", //취소요청/잔액환불신청 거절
  refundResendMessage: "/mng/cs/tnj/refund/resend/message", // 계좌환불입력 재전송
  // -------------------계좌환불요청 CS 처리 API (TNJ)-------------------
  transferCspage: "/mng/cs/tnj/transfer/query/transferCs/page", //계좌환불요청 리스트 페이지 조회
  transferCslist: "/mng/cs/tnj/transfer/query/transferCs/list", //계좌환불요청 리스트 조회
  transferCscomplete: "/mng/cs/tnj/transfer/transferCs/complete", //계좌환불요청 이체 완료 처리
  requestTransfer: "/mng/cs/tnj/transfer/reward/requestTransfer", //보상 계좌이체 요청
  // -------------------정산 API (관리자)-------------------
  tnjstotalize: "/mng/settlement/tnj/stores/settlements/totalize", //정산금액 집계내역 조회 (TNJ)
  agentstotalize: "/mng/settlement/agent/store/settlements/totalize", //정산금액 집계내역 조회 (TNJ)
  tnjregister: "/mng/settlement/tnj/store/settlement/register", //정산 정보 등록 (TNJ)
  tnjdelete: "/mng/settlement/tnj/store/settlement/delete", //정산 정보 삭제 (TNJ)
  confirmDeliveryAmount:
    "/mng/settlement/tnj/store/settlement/confirmDeliveryAmount", //정산 금액 확정 (TNJ)
  setTransferred: "/mng/settlement/tnj/store/settlement/setTransferred", //정산 완료 처리 (입금 완료) (TNJ)
  tnjaddMemo: "/mng/settlement/tnj/store/settlement/addMemo", //정산 정보 메모 추가 (TNJ)
  tnjquery: "/mng/settlement/tnj/stores/settlements/query", //정산 확정 내역 조회 (TNJ)
  agentquery: "/mng/settlement/agent/stores/settlements/query", //에이전시 정산 확정 내역 조회 (AGENT)
  tnjagentquery: "/mng/settlement/tnj/stores/settlements/ofAgent/query", //에이전시 정산 확정 내역 조회 (TNJ)
  taxAmount_total: "/mng/settlement/tnj/stores/taxAmount/totalize", //세금계산서 발행 금액 집계 (TNJ)
  withCalculatedAmount:
    "/mng/settlement/tnj/stores/settlements/withCalculatedAmount/query", //매출 집계 및 정산 확정 내역 조회 (TNJ)
  confirmAmount: "/mng/settlement/tnj/store/settlement/confirmAmount", //정산 금액 확정 (TNJ)
  reconfirmAmount: "/mng/settlement/tnj/store/settlement/reconfirmAmount", //정산 금액 재확정 (TNJ)
  confirmAmounts: "/mng/settlement/tnj/store/settlement/confirmAmounts", //일괄 정산 금액 확정 (TNJ)
  settlements_logs: "/mng/settlement/tnj/stores/settlements/logs/query", //정산 금액 확정 이력 조회 (TNJ)
  confirmAmount_cancel:
    "/mng/settlement/tnj/store/settlement/confirmAmount/cancel", //정산 금액 확정 취소 (TNJ)
  complete_list: "/mng/settlement/tnj/store/settlements/complete", //일괄 정산 완료 처리 (입금완료) (TNJ)
  settlement_complete: "/mng/settlement/tnj/store/settlement/complete", //정산 완료 처리 (입금완료) (TNJ)
  completedSettlements: "/mng/settlement/tnj/stores/completedSettlements/query", //정산완료(입금완료) 내역 조회 (TNJ)
  // -------------------매출 집계 API (관리자)-------------------
  goalregister: "/mng/sales/tnj/salesGoal/register", //목표 매출 금액 등록 (TNJ)
  salesGoal: "/mng/sales/tnj/salesGoal/query", //목표 금액 조회 (TNJ)
  totalizePurchasedSales: "/mng/sales/tnj/totalizePurchasedSales/byDate", //일별 구매 매출 집계 (TNJ)
  totalizeConsumedSales: "/mng/sales/tnj/totalizeConsumedSales/byDate", //일별 사용 매출 집계 (TNJ)
  totalizePurchasedSaleschnl:
    "/mng/sales/tnj/totalizePurchasedSales/byChannel/daily", //일별/채널별 구매 매출 집계 (TNJ)
  totalizeConsumedSaleschnl:
    "/mng/sales/tnj/totalizeConsumedSales/byChannel/daily", //일별/채널별 사용 매출 집계 (TNJ)
  totalizeConsumedSalesbrand:
    "/mng/sales/tnj/totalizeConsumedSales/byBrand/daily", //일별/브랜드별 사용 매출 집계 (TNJ)
  totalizePurchasedSalesbyBrand:
    "/mng/sales/tnj/totalizePurchasedSales/byBrand/daily", // 일별/브랜드별 구매 매출 집계 (TNJ)
  totalizePurchasedSalesmonth:
    "/mng/sales/tnj/totalizePurchasedSales/byChannel/monthly", //월별/채널별 구매 매출 집계 (TNJ)
  totalizeConsumedSaleschnlmonth:
    "/mng/sales/tnj/totalizeConsumedSales/byChannel/monthly", //월별/채널별 사용 매출 집계 (TNJ)
  totalizePurchasedSalesbrandmonth:
    "/mng/sales/tnj/totalizePurchasedSales/byBrand/monthly", //월별/브랜드별 구매 매출 집계 (TNJ)
  totalizeConsumedSalesbrandmonth:
    "/mng/sales/tnj/totalizeConsumedSales/byBrand/monthly", //월별/브랜드별 사용 매출 집계 (TNJ)
  totalizePurchasedSalesbyGoods:
    "/mng/sales/tnj/totalizePurchasedSales/byGoods", //상품별 구매 매출 집계 (TNJ)
  totalizeConsumedSalesbyGoods: "/mng/sales/tnj/totalizeConsumedSales/byGoods", //상품별 사용 매출 집계 (TNJ)
  // -------------------인지세 관리 API (TNJ)-------------------
  stampDutylist: "/mng/duty/tnj/stampDuty/query/list", //인지세 승인번호 리스트 조회
  stampDutyregister: "/mng/duty/tnj/stampDuty/register", //인지세 승인번호 등록
  stampDutyupdate: "/mng/duty/tnj/stampDuty/update", //인지세 승인번호 수정
  stampDutyissuedTotal: "/mng/duty/tnj/stampDuty/query/issuedTotal", //인지세 발급수량 및 납부금액 조회

  // -------------------증빙서류 신청내역 조회/처리 API (TNJ)----------------
  evidential_page: "/mng/board/tnj/evidentialDocument/query/page", //증빙서류 신청내역 리스트 페이지 조회
  evidential_list: "/mng/board/tnj/evidentialDocument/query/list", //증빙서류 신청내역 리스트 조회
  evidential_accept: "/mng/board/tnj/evidentialDocument/post/accept", //증빙서류 발급 승인
  evidential_reject: "/mng/board/tnj/evidentialDocument/post/reject", //증빙서류 발급 거절
  evidential_detail: "/mng/board/tnj/evidentialDocument/query/detail", //증빙서류 신청내용 상세조회

  // -------------------대시보드 API (관리자)------------------------------
  count_newRequest: "/mng/dashboard/tnj/count/newRequest", //신규 요청 카운트 조회 (TNJ)
  orderNoByCouponNumber: "/mng/order/adm/query/orderNoByCouponNumber",
  dailyPage: "/mng/order/adm/query/discountcoupon/daily/page", //할인쿠폰 사용 주문 일별조회
  discoutCoponQuery: "/mng/order/adm/query/discountcoupon/page", //할인쿠폰 사용 주문 조회

  reserveSummary: "/mng/booking/adm/query/bookings/reserveSummary",

  // -------------------포인트권 API (관리자)------------------------------
  pointCouponissuedpage: "/mng/order/tnj/item/query/pointCoupon/issued/page", //포인트권 발급내역 페이지 조회 (TNJ)pointCoupon
  pointCouponissuedlist: "/mng/order/tnj/item/query/pointCoupon/issued/list", //포인트권 발급내역 리스트 조회 (TNJ)
  pointCouponcancelledpage: "/mng/order/tnj/item/query/pointCoupon/cancelled/page", //포인트권 발급취소내역 페이지 조회 (TNJ)
  pointCouponcancelledlist: "/mng/order/tnj/item/query/pointCoupon/cancelled/list", //포인트권 발급취소내역 리스트 조회 (TNJ)
  pointCouponUseList:"/mng/order/adm/query/use/pointTicket/list",
  totalizePurchasedSalesMonthly:"/mng/sales/tnj/totalizePurchasedSales/byGoods/monthly",
  b2bRefundForm: "/mng/cs/tnj/refund/query/refundCs/bulk/approval/form/list",

};

/**********************상품(모바일쿠폰, 예약, 예약권, 금액권, 캐시권) API**************************/
export const goodsapi = {
  base: "/goods/v1/",
  // -------------------상품 API (일반 사용자)-------------------
  goodslist: "/public/goods/store/list",//매장에 속한 상품 리스트 조회
  goodsinfo: "/public/goods/store/info",//상품 상세 정보 조회 (상품 페이지)
  // -------------------상품 API (관리자)-------------------
  goodsadmpage: "/mng/goods/manage/adm/page",//상품 요약 정보 리스트 페이지 조회
  goodsadmintro: "/mng/goods/manage/adm/intro/query",//상품 소개 정보 조회
  goodsadmupdate: "/mng/goods/manage/adm/intro/update",//상품 소개 정보 변경
  goodscondition: "/mng/goods/manage/adm/condition/query",//상품 사용 조건 조회
  conditionupdate: "/mng/goods/manage/adm/condition/update",//상품 사용 조건 변경
  goodsoptions: "/mng/goods/manage/adm/options/query",//상품 옵션 조회
  optionsupdate: "/mng/goods/manage/adm/options/update",//상품 옵션 정보 변경
  goodsusableHours: "/mng/goods/manage/adm/usableHours/query",//상품 이용 가능 시간 조회
  usableHoursupdate: "/mng/goods/manage/adm/usableHours/update",//상품 이용 가능 시간 변경
  optionsAndUsableHours: "/mng/goods/manage/adm/optionsAndUsableHours/query",//상품 옵션 및 이용 가능 시간 조회
  optionsUsableupdate: "/mng/goods/manage/adm/optionsAndUsableHours/update",//상품 옵션 및 이용 가능 시간 변경
  channelsquery: "/mng/goods/manage/adm/channels/query",//상품 B2B 채널 배포 설정 조회
  channelsupdate: "/mng/goods/manage/adm/channels/update",//상품 B2B 채널 배포 설정 변경
  goodsusage: "/mng/goods/manage/adm/usage/query",//상품 사용 정보 조회
  usageupdate: "/mng/goods/manage/adm/usage/update",//상품 사용 정보 변경
  goodsnotice: "/mng/goods/manage/adm/notice/query",//상품 고시 정보 및 기타 설정 조회
  noticeupdate: "/mng/goods/manage/adm/notice/update",//상품 고시 정보 및 기타 설정 변경
  // -------------------상품 기타 정보 조회 API (관리자)-------------------
  admmainPrice: "/mng/goods/ext/adm/goods/mainPrice",//상품 메인 옵션 가격 정보 조회
  storemainPrices: "/mng/goods/ext/adm/goodsListOfStore/mainPrices",//매장에 속한 상품들의 메인 옵션 가격 정보 조회
  codeNameslist: "/mng/goods/ext/adm/goods/codeNames/list",//상품코드/상품명/판매상태 리스트 조회
  storecodeNameslist: "/mng/goods/ext/adm/goodsListOfStore/codeNames/list",//매장에 속한 상품들의 상품코드/상품명/판매상태 리스트 조회
  goodsperiodlist: "/mng/goods/ext/adm/goods/period/list",//판매/유효 만료 기간 리스트 조회
  goodsperiodpage: "/mng/goods/ext/adm/goods/period/page",//판매/유효 만료 기간 리스트 페이지 조회
  // -------------------상품 등록/승인 API (관리자)-------------------
  regadmpage: "/mng/goods/register/adm/page",//등록 대기(작업중) 상품 리스트 조회 (BRD, TNJ)
  requestedpage: "/mng/goods/register/adm/requested/page",//등록 승인 요청 상품 리스트 조회 (BRD, TNJ)
  regadmget: "/mng/goods/register/adm/get",//등록 대기 상품 정보 조회 (BRD, TNJ)
  regbrdrequest: "/mng/goods/register/brd/request",//등록 대기 상품 등록 요청 (BRD, TNJ)
  regbrdcancel: "/mng/goods/register/brd/cancelRequest",//등록 대기 상품 등록 요청 취소 (BRD, TNJ)
  regtnjdeny: "/mng/goods/register/tnj/deny",//상품 등록 거절 (TNJ)
  regtnjapprove: "/mng/goods/register/tnj/approve",//상품 등록 승인 (TNJ)
  // -------------------상품 운영 API (관리자)-------------------
  optionsmainupdate: "/mng/goods/operation/adm/options/mainOption/update",//메인 옵션 변경
  optionsstopSales: "/mng/goods/operation/adm/options/stopSales",//옵션 판매 중지
  optionsresumeSales: "/mng/goods/operation/adm/options/resumeSales",//옵션 판매 재개
  goodsstopSales: "/mng/goods/operation/adm/goods/stopSales",//상품 판매 중지
  goodsresumeSales: "/mng/goods/operation/adm/goods/resumeSales",//상품 판매 재개
  goodsdelete: "/mng/goods/manage/adm/goods/delete",//상품 삭제
  // -------------------쿠폰번호 커스텀 API (TNJ)-------------------
  couponregister: "/mng/goods/couponCustom/tnj/register",//새로운 쿠폰번호 커스텀 등록
  couponupdate: "/mng/goods/couponCustom/tnj/update",//쿠폰번호 커스텀 수정
  coupondelete: "/mng/goods/couponCustom/tnj/delete",//쿠폰번호 커스텀 삭제
  couponinfo: "/mng/goods/couponCustom/tnj/query/info",//쿠폰번호 커스텀 조회
  couponpage: "/mng/goods/couponCustom/tnj/query/page",//쿠폰번호 커스텀 리스트 페이지 조회
  couponlist: "/mng/goods/couponCustom/tnj/query/list",//쿠폰번호 커스텀 리스트 조회
  // --------------------대시보드 API (관리자)-------------------------
  countnewRequest: "/mng/dashboard/tnj/count/newRequest",//신규 요청 카운트 조회 (TNJ)
  // ----------  결합가능 상품 리스트 페이지 조회 ----------------------
  combinedlist: "/mng/goods/manage/tnj/goodsForCombined/list",
  goodsManageList:"/mng/goods/manage/adm/list",
  // ----------  매모 ----------------------
  productMemoList : "/mng/goods/manage/tnj/memo/query/list", // 상품 메모 리스트 조회
}

/**********************B2B API**************************/
export const b2bapi = {
  base: "/b2b/v1/",
  b2bTestScheduler: "/api/agent/test/scheduler",
  channelList: "/public/channelList",
  channelpubInfo: "/public/channelInfo",//채널 정보 조회
  /* -----------B2B 이미지 API (관리자용)------------ */
  imgstandinfo: "/mng/goods/image/adm/standard/info",//B2B 이미지 조회
  imgusageupdate: "/mng/goods/image/adm/standard/usage/update",//B2B 이미지 수정 - 사용정보
  imgsubupdate: "/mng/goods/image/adm/standard/sub/update",//B2B 이미지 수정 - 서브 이미지
  imgregister: "/mng/goods/image/tnj/channel/register",//채널 전용 이미지 등록 (TNJ)
  standregister: "/mng/goods/image/adm/standard/register",//B2B 이미지 등록
  /* -----------외부코드관리 API (관리자용)-------------*/
  matchingpage: "/mng/partner/tnj/matching/list/page",//외부코드 매칭 리스트 조회 (TNJ)
  matchingdelete: "/mng/partner/tnj/matching/delete",//외부코드 매칭 상품 삭제 (TNJ)
  matchingexcel: "/mng/partner/tnj/matching/list/excel",//외부코드 매칭 Excel 리스트 조회 (TNJ)
  matchingaddGoods: "/mng/partner/tnj/matching/addGoods",//외부코드 매칭 상품 추가 (TNJ)
  ebmatchingInfo: "/mng/partner/tnj/ebridge/matchingInfo",//이브릿지 매칭 정보 조회 (TNJ)
  hnmatchingInfo: "/mng/partner/tnj/hanatour/matchingInfo",//하나투어 매칭 정보 조회 (TNJ)
  lsmatchingInfo: "/mng/partner/tnj/lscompany/matchingInfo",//LS 컴퍼니 매칭 정보 조회 (TNJ)
  ntrsmatchingInfo: "/mng/partner/tnj/ntrs/matchingInfo",//NTRS 매칭 정보 조회 (TNJ)
  obmatchingInfo: "/mng/partner/tnj/ontheborder/matchingInfo",//온더보더 매칭 정보 조회 (TNJ)
  kiccmatchingInfo: "/mng/partner/tnj/kicc/matchingInfo",//KICC 매칭 정보 조회 (TNJ)
  defaultMatchingModalInfo: "/mng/partner/tnj/default/matchingInfo",// 기본 매칭 정보 조회 (TNJ)
  sgmatchingInfo: "/mng/partner/tnj/sg/matchingInfo",//SG 매칭 정보 조회 (TNJ)
  kpcMatchingInfo: "/mng/partner/tnj/kpc/matchingInfo",//KPC 매칭 정보 조회 (TNJ)
  ebmatchingItem: "/mng/partner/tnj/ebridge/matchingItem",//이브릿지 매칭 정보 저장 (TNJ)
  hnmatchingItem: "/mng/partner/tnj/hanatour/matchingItem",//하나투어 매칭 정보 저장 (TNJ)
  lsmatchingItem: "/mng/partner/tnj/lscompany/matchingItem",//LS 컴퍼니 매칭 정보 저장 (TNJ)
  ntrsmatchingItem: "/mng/partner/tnj/ntrs/matchingItem",//NTRS 매칭 정보 저장 (TNJ)
  obmatchingItem: "/mng/partner/tnj/ontheborder/matchingItem",//온더보더 매칭 정보 저장 (TNJ)
  kiccmatchingItem: "/mng/partner/tnj/kicc/matchingItem",//KICC 매칭 정보 저장 (TNJ)
  defaultmatchingItem: "/mng/partner/tnj/default/matchingItem",//기본 매칭 정보 저장 (TNJ)
  sgmatchingItem: "/mng/partner/tnj/sg/matchingItem",//SG 매칭 정보 저장 (TNJ)
  kpcmatchingItem: "/mng/partner/tnj/kpc/matchingItem",//kpc 매칭 정보 저장 (TNJ)
  lslistexcel: "/mng/partner/tnj/lscompany/product",//LS 상품 Excel 리스트 조회 (TNJ)
  /* -------------B2B 상품관리 - 채널배포요청 API (관리자용)---------------- */
  distribution: "/mng/goods/tnj/distribution/status",//채널 배포요청 현황 조회 (TNJ)
  distrequest: "/mng/goods/tnj/distribution/requestList",//채널 배포요청 리스트 조회 (TNJ)
  distexcel: "/mng/goods/tnj/distribution/requestList/excel",//채널 배포요청 리스트 Excel (TNJ)
  distcomplete: "/mng/goods/tnj/distribution/complete",//채널 배포완료 처리 (TNJ)
  channelimginfo: "/mng/goods/image/tnj/channel/info",//채널 전용 이미지 조회 (TNJ)
  batchregister: "/mng/channel/tnj/tmon/goods/batch/register",//티몬 상품 일괄 등록 (TNJ)
  batchupdate: "/mng/channel/tnj/tmon/goods/batch/update",//티몬 상품 일괄 수정 (TNJ)
  batchreRegister: "/mng/channel/tnj/tmon/goods/batch/reRegister",//티몬 상품 일괄 재등록 (TNJ)
  batchpause: "/mng/channel/tnj/tmon/goods/batch/pause",//티몬 상품 일괄 판매중지 (TNJ)
  batchmatchCode: "/mng/goods/tnj/match/channel/batch/matchCode",//상품 일괄 코드 매칭 (TNJ)

  /* ------------B2B 상품관리 - 코드매칭관리 API (관리자용)--------------- */
  goodscategoryList: "/mng/channel/tnj/goods/categoryList",//채널 카테고리 리스트 조회 (TNJ)
  tmonmatchingInfo: "/mng/goods/tnj/match/tmon/matchingInfo",//매칭 상세 정보 조회 (티몬) (TNJ)
  gmarketmatchingInfo: "/mng/goods/tnj/match/gmarket/matchingInfo",//매칭 상세 정보 조회 (G마켓, G9) (TNJ)
  channelmatchingInfo: "/mng/goods/tnj/match/channel/matchingInfo",//매칭 상세 정보 조회 (코드매칭) (TNJ)
  unMatchCode: "/mng/goods/tnj/match/channel/unMatchCode",//코드 매칭 해제 (TNJ)
  tmonmatchpage: "/mng/goods/tnj/match/tmon/matchingList/page",//코드 매칭 리스트 조회 (티몬) (TNJ)
  chnlmatchpage: "/mng/goods/tnj/match/channel/matchingList/page",//코드 매칭 리스트 조회 (티몬 제외 채널) (TNJ)
  tmonmatchexcel: "/mng/goods/tnj/match/tmon/matchingList/excel",//코드 매칭 리스트 Excel (티몬) (TNJ)
  gmkMatchExcel: "/mng/goods/tnj/match/gmk/matchingList/excel",//코드 매칭 리스트 Excel (G마켓) (TNJ)
  chnlmatchexcel: "/mng/goods/tnj/match/channel/matchingList/excel",//코드 매칭 리스트 Excel (티몬 제외 채널) (TNJ)
  tmonexcellist: "/mng/channel/tnj/tmon/excel/settlementList",//티몬 정산 Excel 리스트 조회 (TNJ)
  coupangexcel: "/mng/channel/tnj/coupang/excel/goodsCodeList",//쿠팡 상품 Excel 리스트 조회 (TNJ)

  /* ------------B2B 상품관리 - 채널배포요청 API (관리자용)--------------- */
  tmonregister: "/mng/channel/tnj/tmon/goods/register",//티몬 상품 등록 (TNJ)
  tmonreRegister: "/mng/channel/tnj/tmon/goods/reRegister",//티몬 상품 재등록 (TNJ)
  tmonupdate: "/mng/channel/tnj/tmon/goods/update",//티몬 상품 수정 (TNJ)
  tmonpause: "/mng/channel/tnj/tmon/goods/pause",//티몬 상품 판매중지 (TNJ)
  gmregister: "/mng/channel/tnj/gmarket/goods/register",//G마켓/G9 상품 등록 (TNJ)
  gmupdate: "/mng/channel/tnj/gmarket/goods/update",//G마켓/G9 상품 수정 (TNJ)

  /* ------------B2B 상품관리 - 채널배포 API (관리자용)-------------------- */
  matchCode: "/mng/goods/tnj/match/channel/matchCode",//상품 코드 매칭 (TNJ)

  /* ------------B2B 상품관리 - 채널오픈현황 API (관리자용)---------------- */
  statusListpage: "/mng/goods/tnj/channelSales/statusList/page",//채널 오픈 현황 리스트 조회 (TNJ)
  statusDetail: "/mng/goods/tnj/channelSales/statusDetail",//채널 오픈 현황 상세 조회 (TNJ)
  statusListexcel: "/mng/goods/tnj/channelSales/statusList/excel",//채널 오픈 현황 Excel 리스트 조회 (TNJ)

  /* ------------개발자기능 - B2B 연동 수동처리 API---------------- */
  kakaoTicketsComplete: "/public/development/channel/kakao/tickets/complete",//[카카오 톡스토어] 쿠폰 발송완료 처리
  tmonDealsPause: "/public/development/channel/tmon/deals/pause",//[티몬] 딜 판매 중지
  tmonDealsResume: "/public/development/channel/tmon/deals/resume",//[티몬] 딜 판매 재개
  tmonDealsCount: "/public/development/channel/tmon/deals/count",//[티몬] 딜 카운트 조회
  tmonDeals: "/public/development/channel/tmon/deals",//[티몬] 딜 리스트 조회
  tmonDealsDetail: "/public/development/channel/tmon/deals/detail",//[티몬] 딜 정보 조회
  tmonDealsMatching: "/public/development/channel/tmon/deals/matching",//[티몬] 딜 수동 매칭
  coupangPucharesMatching: "/coupang/orderPut",// 쿠팡 티켓 구매자 정보조회 (연동 매칭)
  naverPucharesMatching: "/naver/orderPut",// 네이버 스마트 스토어 주문실패 연동처리
  lotteOneCouponOrderSearch: "/lotteon/orderPut",// e쿠폰 발송/재발송 목록 조회
  gmarketECouponOrderCreate: "/gmarket/orderPut",// 주문연동 실패시 재연동시도
  auctionECouponOrderCreate: "/auction/orderPut",// 주문연동 실패시 재연동시도

  /* ------------개발자기능 - POS 연동관리 API---------------- */
  posPartnerKeyCreate: "/public/development/partner/pos/partnerKey/create",
  posPartnerKeyList: "/public/development/partner/pos/partnerKey/list",

  partnerKeyCreate: "/public/development/partner/partnerType/create",
  partnerKeyList: "/public/development/partner/partnerType/list",

  /* ------------개발자기능 - 채널연동관리 API---------------- */
  channelUpdate: "/public/development/channel/update",
  ChannelCreate: "/public/development/channel/register",
  channelInfoList: "/public/development/channel/list",
  channelDetail :"/public/development/channel/detail",
/* ------------카카오 예약하기 API---------------- */
  kakaoReservationExcelList: "/mng/channel/tnj/kakao/reservation/settlements/excel/list"
}

/*********************사이트 관리자( 기획전 , 메인페이지 , 프로모션 ) API*********************** */
export const display = {
  base: "/display/v1/",
  /* --------------기획전 (관리자용 API)---------------------------- */
  exhibition_list: "/adm/exhibition/exhibition_list",//관리자 기획전 리스트 조회
  exhibition_delete: "/adm/exhibition/exhibition_delete",//관리자 기획전 삭제
  exhibition_status: "/adm/exhibition/exhibition_status",//관리자 기획전 상태변경
  exhibition_order: "/adm/exhibition/exhibition_order",//관리자 기획전 순서변경
  exhibition_detail: "/adm/exhibition/exhibition_detail",//관리자 기획전 상세 조회
  exhibition_use_list: "/adm/exhibition/exhibition_use_list",//관리자 기획전 리스트 조회
  exhibition_register: "/adm/exhibition/exhibition_register",//관리자 기획전 등록
  exhibition_modify: "/adm/exhibition/exhibition_modify",//관리자 기획전 변경
  /* ---------------메인페이지 (관리자용 API)------------------------- */
  main_pagelist: "/adm/main_page/list",//관리자 메인관리자 리스트 조회
  main_pagestatus: "/adm/main_page/status",//관리자 메인관리자 상태변경
  main_pageorder: "/adm/main_page/order",//관리자 메인관리자 순서변경
  main_pagedelete: "/adm/main_page/delete",//관리자 메인관리자 삭제
  main_pagedetail: "/adm/main_page/detail",//관리자 메인관리자 상세조회
  main_pageregister: "/adm/main_page/register",//관리자 메인관리자 등록
  main_pagemodify: "/adm/main_page/modify",//관리자 메인관리자 수정
  /* ---------------프로모션 (관리자용 API)-------------------------- */
  promotionlist: "/adm/promotion/list",//관리자 프로모션 리스트 조회
  promotiondetail: "/adm/promotion/detail",//관리자 프로모션 상세조회
  promotiondelete: "/adm/promotion/delete",//관리자 프로모션 삭제
  promotionstatus: "/adm/promotion/status",//관리자 프로모션 상태변경
  promotionorder: "/adm/promotion/order",//관리자 프로모션 상태변경
  promotionregister: "/adm/promotion/register",//관리자 프로모션 등록
  promotionmodify: "/adm/promotion/modify",//관리자 프로모션 수정

  /* ---------------  인트로 (관리자용 API) -------------------------- */
  introList:"/adm/intro/intro_list", //관리자 인트로 리스트페이지조회
  introDetail:"/adm/intro/intro_detail",//관리자 인트로 상세 조회
  introRegister:"/adm/intro/intro_register",//관리자 인트로 등록
  introModify:"/adm/intro/intro_modify",//관리자 인트로 수정
  introDelete:"/adm/intro/intro_delete",//관리자 인트로 삭제
  introStatus:"/adm/intro/intro_status",//관리자 인트로 상태변경
}

/*********************사이트 관리자( 기획전 , 메인페이지 , 프로모션 ) API*********************** */
export const board = {
  base: "/board/v1/",
  /* --------------공지사항 API---------------------------- */
  notice_page: "/mng/board/noticeBoard/query/page",//공지사항 리스트 페이지 조회 (관리자)
  notice_delete: "/mng/board/noticeBoard/post/delete",//공지사항 삭제 (관리자)
  notice_detail: "/mng/board/noticeBoard/query/detail",//공지사항 상세 조회 (관리자)
  notice_add: "/mng/board/noticeBoard/post/add",//공지사항 추가 (관리자)
  notice_update: "/mng/board/noticeBoard/post/update",//공지사항 수정 (관리자)
  /* --------------1:1 문의 API---------------------------- */
  cusServ_page: "/mng/board/customerServiceBoard/query/page",//1:1 문의글 리스트 페이지 조회 (관리자)
  cusServ_detail: "/mng/board/customerServiceBoard/query/detail",//1:1 문의글 상세 조회 (관리자)
  cusServ_answer: "/mng/board/customerServiceBoard/question/answer",//1:1 문의글 답변 등록 (관리자)
  /* --------------제휴요청 API----------------------------- */
  request_page: "/mng/board/partnershipRequestBoard/query/page",//제휴 요청 리스트 페이지 조회 (TNJ)
  request_detail: "/mng/board/partnershipRequestBoard/query/detail",//제휴 요청 상세 조회 (TNJ)
  request_answer: "/mng/board/partnershipRequestBoard/request/answer",//제휴 요청 확인 완료(답변 등록) (TNJ)
  /* --------------자주묻는질문 API---------------------------- */
  faq_page: "/mng/board/faq/query/page",//자주묻는질문 리스트 페이지 조회 (관리자)
  faq_delete: "/mng/board/faq/post/delete",//자주묻는질문 삭제 (관리자)
  faq_detail: "/mng/board/faq/query/detail",//자주묻는질문 상세 조회 (관리자)
  faq_add: "/mng/board/faq/post/add",//자주묻는질문 추가 (관리자)
  faq_update: "/mng/board/faq/post/update",//자주묻는질문 수정 (관리자)
  /* --------------전화상담요청 API--------------------------- */
  call_page: "/mng/board/callRequest/query/page",//전화상담요청 내역 리스트 페이지 조회 (관리자)
  call_appendMemo: "/mng/board/callRequest/request/appendMemo",//전화상담요청 메모 추가 (관리자)
  call_complete: "/mng/board/callRequest/request/complete",//전화상담요청 완료 처리 (관리자)
  call_backCount: "/mng/board/callRequest/query/callBackCount",//콜백신청 카운트 조회 (관리자)
  /* --------------하이테이블 공지사항 API--------------------------- */
  ht_notice_page: "/mng/board/noticeAdminBoard/query/page",//공지사항 리스트 페이지 조회 (관리자)
  ht_notice_delete: "/mng/board/noticeAdminBoard/post/delete",//공지사항 삭제 (관리자)
  ht_notice_detail: "/mng/board/noticeAdminBoard/query/detail",//공지사항 상세 조회 (관리자)
  ht_notice_add: "/mng/board/noticeAdminBoard/post/add",//공지사항 추가 (관리자)
  ht_notice_update: "/mng/board/noticeAdminBoard/post/update",//공지사항 수정 (관리자)
}

/*********************결제서비스*********************** */
export const payment = {
  base: "/payments/v1/",
  //------------------포인트 API (관리자)-----------------
  point_save: "/adm/point/point_save",//포인트 적립 (관리자)
  point_list: "/adm/point/point_list",//포인트 리스트 (관리자)
  point_left: "/adm/point/point_left",//잔여 포인트 (관리자)
  validAccountReset:"/adm/coupon/account/validate/reset", //주문별 계좌정보 검증 횟수 초기화(관리자)
}

/*********************할인쿠폰*********************** */

export const discoutCopon = {
  base: "/payments/v1/",
  //------------------포인트 API (관리자)-----------------
  new_coupon: "/adm/coupon/register/newCoupon/create", //신규 할인쿠폰 등록요청 (TNJ)
  newcopun_request: "/adm/coupon/register/newCoupon/request/page", //신규 할인쿠폰 등록요청 리스트 조회 (TNJ)
  newCoupon_info: "/adm/coupon/register/newCoupon/request/info", //신규 할인쿠폰 등록요청 상세조회 (TNJ)
  verify: "/adm/coupon/register/newCoupon/verify", // 신규 할인쿠폰 등록요청 승인 (TNJ)
  deny: "/adm/coupon/register/newCoupon/deny", //신규 할인쿠폰 등록요청 승인 거절 (TNJ),
  coupon_list: "/adm/coupon/page", //할인쿠폰 리스트 조회 (TNJ)
  enable: "/adm/coupon/enable", //할인쿠폰 사이트노출 활성화 (TNJ)
  disable: "/adm/coupon/disable", //할인쿠폰 사이트노출 비활성화 (TNJ)
  itemPage: "/adm/coupon/item/page", //할인쿠폰 발급내역 조회 (TNJ)
  coupon_info: "/adm/coupon/info", //할인쿠폰 상세 조회 (TNJ)
  update: "/adm/coupon/update", //할인쿠폰 수정 (TNJ)
  bydate: "/adm/coupon/issue/byDate", //할인쿠폰 발급현황 조회 (TNJ)
  bydate_detail: "/adm/coupon/issue/detail/byDate", //할인쿠폰 발급현황 상세 조회 (TNJ)
  userCoupon: "/adm/coupon/userCoupon/list", //사용자별 할인쿠폰 리스트 조회 (TNJ)
  discoutCoponList: "/adm/coupon/item/list",
};
