<template>
    <div class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">예약캘린더</h3>
        </div>
        <div class="box-body form-horizontal calbox">
            <div class="form-group">
                <FullCalendar class="demo-app-calendar" ref="fullCalendar" defaultView="dayGridMonth"
                :plugins="calendarPlugins" :header="calendarHeader" :views="calendarViews"
                :buttonText="calendarBuuttonText" :contentHeight="contentHeight" :allDaySlot="weekAllDaySlot"
                :scrollTime="scrollTime" :events="calendarEvents" :slotLabelFormat="slotLabelFormat" :slotLabelInterval="slotLabelInterval"
                @eventRender="eventRender" @eventClick="eventClick" @datesRender="datesRender" />
            </div>
        </div>
        <!-- {{ bookingModalfraim }} -->
        <div v-if="bookingModalfraim">
        <BookingModal :dateData="modalSearchDate" :topBookingBtn="false" @closeModal="hideBookingModal" :calendarType="calendarType" :calendarTime="calendarTime" :timeUnit="timeUnit" />
        </div>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import BookingModal from "../../components/pages/BookingModal.vue";

import {
    brandapi,
    orderapi,
} from "../../lib/api";
import {
    reqGet,
    reqPost
} from "../../lib/request";

import {
    menuval,
    rescode,
    imgtype
} from "../../lib/consts";

export default {
    components: {
        FullCalendar,
        BookingModal,
    },
    computed:{
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    data() {
        return {
            bookingModalfraim:false,
            modalSearchDate: this.$moment().toDate(),
            searchYearMonth: this.$moment().format("YYYY-MM"),
            searchIncludedDate: this.$moment().format("YYYY-MM-DD"),
            scrollTime: this.$moment().format("HH:mm:ss"),
            calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            calendarHeader: {
                left: "today",
                center: "prev,  title  ,next",
                right: "dayGridMonth,timeGridWeek",
            },
            calendarViews: {
                dayGridMonth: {
                    titleFormat: {
                        year: "numeric",
                        month: "long"
                    },
                },
                timeGridWeek: {
                    titleFormat: {
                        year: "numeric",
                        month: "long"
                    },
                },
            },
            calendarBuuttonText: {
                today: "오늘",
                month: "월간",
                week: "주간",
            },
            weekAllDaySlot: false,
            contentHeight: 600,
            calendarEvents: [],
            slotLabelFormat: {
                hour: "numeric",
                hour12: false,
                minute: "2-digit",
                omitZeroMinute: false,
                meridiem: "short",
            },
            slotLabelInterval: "00:30:00",
            calendarType: '',
            calendarTime: this.$moment().format("HH:mm"),
            reservationArrMonth:[],
            reservationArrWeek:[],
            timeUnit:'',
        }
    },
    methods:{
        bookingslistGetData(day,info){
            // bookingslist
            var reqdata = {
                dateFrom: this.$moment(day).format("YYYY-MM-01"),
                dateTo: this.$moment(day).endOf("month").format("YYYY-MM-DD"),
                storeCode: this.$route.query.stcode,
                sortDateType:'VisitDate',
                dateType:'VisitDate',
                keywordType:'NoKeyword',
                keyword:null,
            }
            reqPost(
                orderapi.base,
                orderapi.reserveSummary,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.reservationArrMonth = result.data;
                            this.reservationArrWeek = result.data;
                            if (info.view.type === "dayGridMonth") {
                                this.searchYearMonth = this.$moment(info.view.currentStart).format("YYYY-MM");
                                this.searchMonthlyData();
                            } else {
                                this.searchIncludedDate = this.$moment(info.view.currentStart).format("YYYY-MM-DD");
                                 this.searchWeeklyData();
                            }
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        async datesRender(info) {
            this.bookingslistGetData(info.view.currentStart, info)

        },
        searchWeeklyData(){
            reqGet(
                brandapi.base,
                brandapi.brandWeekly + "?storeCode=" + this.$route.query.stcode + '&includedDate=' + this.searchIncludedDate,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            var bookingWeeklyData = [];
                            result.data.dates.forEach((item) => {
                                item.hours.forEach((hourInfo) => {
                                    //시간이 1자리수 이면 안그려지는 현상 2자리수맞춰주기
                                    var newHourInfoHour = '';
                                    if (hourInfo.hour.toString().length == 1) {
                                        newHourInfoHour = "0" + hourInfo.hour;
                                    } else {
                                        newHourInfoHour = hourInfo.hour
                                    }
                                    // console.log(hourInfo)
                                    var setDateTime = item.date + " " + newHourInfoHour;
                                    // console.log(newHourInfoHour)
                                    // 상태변경 버튼과 인벤토리 정보 구분 시간 15분
                                    var slotDivisionMinutes = 15;
                                    // 예약 단위시간 : M30, M60
                                    var addMinutes = hourInfo.timeUnit == "M30" ? 30 : 60;
                                    let time00Headcount = 0;
                                    let time30Headcount = 0;

                                    this.reservationArrWeek.forEach(week => {
                                        if (Number(week.headCounts) !== 0){
                                            if (this.$moment(week.reservationDate).format("YYYY-MM-DD") === this.$moment(item.date).format("YYYY-MM-DD")) {
                                                week.reservationTimeInfos.forEach(time => {
                                                    if (String(time.reservationTime.substring(0, 2)) === String(newHourInfoHour)){
                                                        if (hourInfo.timeUnit == "M30"){
                                                            if (Number(time.reservationTime.substring(3, 4)) < 3) {
                                                                time00Headcount += time.headCounts;
                                                            } else {
                                                                time30Headcount += time.headCounts;
                                                            }
                                                        }else{
                                                            time00Headcount += time.headCounts;
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    });
                                    if (hourInfo.min00) {
                                        var bookingSlotInfo = this.getWeeklySlotData(item.bookingPaused || (!item.bookingPaused && hourInfo.min00.bookingPaused));
                                        var slotStartDateTime = this.$moment(setDateTime).format("YYYY-MM-DD HH:mm:ss");
                                        var slotDivisionDateTime = this.$moment(slotStartDateTime).add(slotDivisionMinutes, "minutes").format("YYYY-MM-DD HH:mm:ss");
                                        var slotEndDateTime = this.$moment(slotStartDateTime).add(addMinutes, "minutes").format("YYYY-MM-DD HH:mm:ss");

                                        bookingWeeklyData.push({
                                            classNames: ["time-booking-status"],
                                            title: `{"type": "timeBookingStatus", "bookingStatusTitle": "${bookingSlotInfo.bookingStatusTitle}", "bookingPaused": ${bookingSlotInfo.bookingPaused}, "hour": "${hourInfo.hour}", "ahalf": "false", "timeUnit":"${hourInfo.timeUnit}"}`,
                                            start: slotStartDateTime,
                                            end: slotDivisionDateTime,
                                            color: bookingSlotInfo.bookingStatusColor,
                                        }, {
                                            classNames: ["time-inventory"],
                                            title: `{"type": "timeInventory", "bookedHeadcount": "${time00Headcount}", "totalInventory": "${hourInfo.min00.totalInventory}", "timeUnit":"${hourInfo.timeUnit}"}`,
                                            start: slotDivisionDateTime,
                                            end: slotEndDateTime,
                                            color: bookingSlotInfo.inventoryColor,
                                        });
                                    }

                                    if (hourInfo.min30) {
                                        var bookingSlotInfo = this.getWeeklySlotData(item.bookingPaused || (!item.bookingPaused && hourInfo.min30.bookingPaused));
                                        var slotStartDateTime = this.$moment(setDateTime).add(30, "minutes").format("YYYY-MM-DD HH:mm:ss");
                                        var slotDivisionDateTime = this.$moment(slotStartDateTime).add(slotDivisionMinutes, "minutes").format("YYYY-MM-DD HH:mm:ss");
                                        var slotEndDateTime = this.$moment(slotStartDateTime).add(addMinutes, "minutes").format("YYYY-MM-DD HH:mm:ss");

                                        bookingWeeklyData.push({
                                            classNames: ["time-booking-status"],
                                            title: `{"type": "timeBookingStatus", "bookingStatusTitle": "${bookingSlotInfo.bookingStatusTitle}", "bookingPaused": ${bookingSlotInfo.bookingPaused}, "hour": "${hourInfo.hour}", "ahalf": "true","timeUnit":"${hourInfo.timeUnit}"}`,
                                            start: slotStartDateTime,
                                            end: slotDivisionDateTime,
                                            color: bookingSlotInfo.bookingStatusColor,
                                        }, {
                                            classNames: ["time-inventory"],
                                            title: `{"type": "timeInventory", "bookedHeadcount": "${time30Headcount}", "totalInventory": "${hourInfo.min30.totalInventory}","timeUnit":"${hourInfo.timeUnit}"}`,
                                            start: slotDivisionDateTime,
                                            end: slotEndDateTime,
                                            color: bookingSlotInfo.inventoryColor,
                                        });
                                    }
                                });
                            });
                            this.calendarEvents = bookingWeeklyData;
                        }
                    }
                }
            );
        },
        getWeeklySlotData(paused) {
            if (paused) {
                return {
                    bookingPaused: true,
                    bookingStatusTitle: "예약마감",
                    bookingStatusColor: "#EAEAEA",
                    inventoryColor: "#F3F3F3",
                };
            } else {
                return {
                    bookingPaused: false,
                    bookingStatusTitle: "예약오픈",
                    bookingStatusColor: "#FFEAEA",
                    inventoryColor: "#FFF8F8",
                };
            }
        },
        searchMonthlyData() {

            reqGet(
                brandapi.base,
                brandapi.brandMonthly + "?storeCode=" + this.$route.query.stcode + '&yearMonth=' + this.searchYearMonth,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {

                            var bookingMonthlyData = [];
                            // console.log(res.data.data.dates)

                            result.data.dates.forEach((item) => {
                                let bookedHeadcount = 0;
                                // console.log(this.reservationArrMonth)
                                this.reservationArrMonth.forEach((el,idx) => {
                                    if (this.$moment(el.reservationDate).format("YYYY-MM-DD") === this.$moment(item.date).format("YYYY-MM-DD")){
                                        bookedHeadcount = el.headCounts;
                                    }
                                });

                                var inventoryColor = item.date == this.$moment().format("YYYY-MM-DD") ? "rgba( 252, 248, 227, 0.5 )" : "rgba( 255, 255, 255, 0.5 )";
                                var bookingStatusTitle, bookingStatusColor;
                                if (item.bookingPaused) {
                                    bookingStatusTitle = "예약마감";
                                    bookingStatusColor = "#EAEAEA";
                                } else {
                                    bookingStatusTitle = "예약오픈";
                                    bookingStatusColor = "#FFEAEA";
                                }

                                bookingMonthlyData.push({
                                    classNames: ["date-booking-status"],
                                    title: `{"sort": "1", "type": "dateBookingStatus", "bookingStatusTitle": "${bookingStatusTitle}", "bookingPaused": ${item.bookingPaused}}`,
                                    start: item.date,
                                    color: bookingStatusColor,
                                }, {
                                    classNames: ["date-inventory"],
                                    title: `{"sort": "2", "type": "dateInventory", "bookedHeadcount": "${bookedHeadcount}", "totalInventory": "${item.totalInventory}"}`,
                                    start: item.date,
                                    color: inventoryColor,
                                });
                            });
                            this.calendarEvents = bookingMonthlyData;
                        }
                    }
                }
            );
        },
        hideBookingModal(){
            this.bookingModalfraim = false;
        },
        showBookingModal(){
            this.bookingModalfraim = true;
        },
        eventClick(info) {
            var bookingData = JSON.parse(info.event.title);

            // console.log(bookingData.timeUnit)
            // Monthly, Weekly Mode Inventory Click
            if (
                info.event.classNames[0] == "date-inventory" ||
                info.event.classNames[0] == "time-inventory"
            ) {
                this.modalSearchDate = info.event.start;
                this.bookingModalfraim = true;
            }

            // Monthly Mode Status Click
            if (info.event.classNames[0] == "date-booking-status") {
                this.calendarType = 'm';
                var updateDate = this.$moment(info.event.start).format("YYYY-MM-DD");

                if (bookingData.bookingPaused) {
                    this.resumeBookingDate(updateDate);
                } else {
                    this.stopBookingDate(updateDate);
                }
            }
            if (info.event.classNames[0] == "time-inventory") {
                this.calendarType = 'w';
                this.timeUnit = bookingData.timeUnit;
                this.calendarTime = this.$moment(info.event.start).subtract(15, 'minutes').format("HH:mm");
            }else{
                this.calendarType = 'm'
            }
            // Weekly Mode Status Click
            if (info.event.classNames[0] == "time-booking-status") {

                var updateDate = this.$moment(info.event.start).format("YYYY-MM-DD");

                if (bookingData.bookingPaused) {
                    this.resumeBookingTime(updateDate, bookingData.hour, bookingData.ahalf);
                } else {
                    this.stopBookingTime(updateDate, bookingData.hour, bookingData.ahalf);
                }
            }
        },
        resumeBookingTime(resumeDate, resumeHour, resumeAhalf) {
            // bookingTimeResume

            var reqdata = {
                date: resumeDate,
                hour: resumeHour,
                ahalf: resumeAhalf,
                storeCode: this.$route.query.stcode,
            }
            reqPost(
                brandapi.base,
                brandapi.bookingTimeResume,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.searchWeeklyData();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        stopBookingTime(stopDate, stopHour, stopAhalf) {
            // bookingTimeStop

            var reqdata = {
                date: stopDate,
                hour: stopHour,
                ahalf: stopAhalf,
                storeCode: this.$route.query.stcode,
            }
            reqPost(
                brandapi.base,
                brandapi.bookingTimeStop,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.searchWeeklyData();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        resumeBookingDate(resumeDate) {
            //bookingResume

            var reqdata = {
                date: resumeDate,
                storeCode: this.$route.query.stcode,
            }
            reqPost(
                brandapi.base,
                brandapi.bookingResume,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                          this.$emit('get-kakao-alert', 'RESERVATION');
                            this.searchMonthlyData();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        stopBookingDate(stopDate) {
            //bookingStop
            var reqdata = {
                date: stopDate,
                storeCode: this.$route.query.stcode,
            }
            reqPost(
                brandapi.base,
                brandapi.bookingStop,
                this.authToken,
                reqdata,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                          this.$emit('get-kakao-alert', 'RESERVATION');
                            this.searchMonthlyData();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        eventRender(info) {
            var bookingData = JSON.parse(info.event.title);
            // console.log(bookingData)
            switch (bookingData.type) {
                case "dateBookingStatus":
                    info.el.firstChild.innerHTML = `<span class="fc-title">${bookingData.bookingStatusTitle}</span>`;
                    break;
                case "dateInventory":
                    info.el.firstChild.innerHTML = `<span class="fc-title">${bookingData.bookedHeadcount} / ${bookingData.totalInventory}</span>`;
                    break;
                case "timeBookingStatus":
                    info.el.firstChild.innerHTML = `<span class="fc-title">${bookingData.bookingStatusTitle}</span>`;
                    break;
                case "timeInventory":
                    info.el.firstChild.innerHTML = `<span class="fc-title">${bookingData.bookedHeadcount} / ${bookingData.totalInventory}</span>`;
                    break;
            }
        },
      getKakaoAlert(val){
        this.$emit('get-kakao-alert', val);
      },
    }
}
</script>


<style>
.fc-header-toolbar .fc-center {
            display: flex;
            align-items: center;
        }

        .fc-button {
            font-size: 1.1em;
        }

        .fc-header-toolbar .fc-button-primary {
            color: #131113;
            background-color: #fff;
            border-color: #c11314;
        }

        .fc-header-toolbar .fc-button-primary:active {
            color: #fff;
            background-color: #c11314;
            border-color: #c11314;
        }

        .fc-header-toolbar .fc-button-primary:not(:disabled).fc-button-active {
            color: #fff;
            background-color: #c11314;
            border-color: #c11314;
        }

        .fc-content {
            cursor: pointer;
            text-align: center;
        }

        .time-booking-status {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
            border-radius: 12px;
            padding-top: 1px;
            margin-top: 1px;
        }

        .date-booking-status {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
            border-radius: 12px;
            padding: 2px 1px 2px 1px;
            width: 60%;
            margin-left: 19%;
        }

        .fc-day-grid-event.date-booking-status .fc-title,
        .fc-time-grid-event.time-booking-status .fc-title {
            font-size: 1.2em;
            font-weight: 500;
        }

        .fc-day-grid-event.date-inventory .fc-title,
        .fc-time-grid-event.time-inventory .fc-title {
            font-size: 1.3em;
            font-weight: 900;
            text-decoration: underline;
            text-underline-position: under;
        }

        .date-inventory {
            margin-top: 10px;
        }

        .fc-time-grid .fc-slats td {
            height: 3.5em;
            border-bottom: 0;
        }

        span.fc-title {
            color: #000;
        }
        .calbox{
            padding: 0 40px;
        }
</style>`
