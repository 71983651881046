<template>
  <div class="input-group">
    <button type="button" class="btn btn-default" @click="onClickSearch">주소검색</button>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h3>주소 입력</h3>
          </div>
          <div class="box-body">
            <vue-daum-postcode
              v-if="showmodal"
              @complete="onComplete"
              style="height:350px; overflow:scroll;"
            />
            <div class="form-group" style="margin-top:10px;">
              <label class="col-sm-2 control-label" for="address">주소</label>
              <div class="col-sm-10" style="display: flex;flex-direction: row;align-items: center;">
                <input
                  type="text"
                  id="address"
                  class="form-control"
                  style="width:70%;display:inline-flex;"
                  :value="address"
                  disabled
                />
                <input
                  type="text"
                  class="form-control"
                  style="width:30%;display:inline-flex;"
                  :value="zonecode"
                  disabled
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label" for="detailaddr">상세주소</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="detailaddr" v-model="detailaddrChild" />
              </div>
            </div>
          </div>
          <div class="modal-footer" style="text-align:center !important">
            <button type="button" class="btn btn-default" @click="closemodal">취소</button>
            <button type="button" class="btn btn-success" @click="savedata">반영</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import { VueDaumPostcode } from "vue-daum-postcode";
export default {
  components: {
    VueDaumPostcode,
  },
  watch:{
    detailaddr(val){
      this.detailaddrChild = val;
    }
  },
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },
    detailAddress:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      showmodal: false,
      detailaddrChild: "",
      address: "",
      zonecode: "",
      addrinfo:null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onClickSearch() {
      this.showmodal = !this.showmodal;
    },
    closemodal() {
      this.showmodal = false;
    },
    savedata() {
      this.showmodal = false;
      this.onChange(this.addrinfo, this.detailaddrChild);
      this.$emit('detailaddrEvechiild',this.detailaddrChild);
    },
    onComplete(event) {
      let fullRoadAddr = event.address;
      let extraRoadAddr = '';

      //법정동명이 있을 경우 추가
      if(event.bname !== '' && /[동|로|가]$/g.test(event.bname)){
         extraRoadAddr += event.bname; 
      }

      // 건물명이 있고, 공동주택일 경우 추가한다. \
      if(event.buildingName !== '' && event.apartment === 'Y'){ 
        extraRoadAddr += (extraRoadAddr !== '' ? ', ' + event.buildingName : event.buildingName); 
      } 
      // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다. 
      if(extraRoadAddr !== ''){ 
        extraRoadAddr = ' (' + extraRoadAddr + ')'; 
      } 
      // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
      if(fullRoadAddr !== ''){ 
        fullRoadAddr += extraRoadAddr; 
      } 
      // 우편번호와 주소 정보를 해당 필드에 넣는다. 
      this.address = fullRoadAddr;
      this.addrinfo = event;
      this.zonecode = event.zonecode;
      // console.log(fullRoadAddr)

      // this.addrinfo = event;
      // this.address = event.address;
      // this.zonecode = event.zonecode;
    },
  },
};
</script>
<style scoped>
.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>