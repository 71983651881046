<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose">
          <span aria-hidden="true">×</span>
        </button>
        B2B 이미지 생성
      </div>
      <div class="modal-body" id="fullImg">
        <div class="use_info_contents" >
          <div class="b2b_info_img_top">
            <img src="https://hitable2020images.blob.core.windows.net/b2b/b2b_form_info_top.jpg" />
          </div>
          <div class="b2b_info_text" id="pringusage_0">
            ㆍ사용정보
            <template v-for="(uline, uidx) in uselineinf">
              <br :key="'u'+uidx"/><span class="b2b_info_text_detail">{{uline}}</span>
            </template>
          </div>
          <div class="b2b_info_text" id="pringusage_1">
            ㆍ기본정보
            <template v-for="(bline, bidx) in baselineinf">
              <br :key="'b'+bidx"/><span class="b2b_info_text_detail">{{bline}}</span>
            </template>
          </div>
          <div class="b2b_info_text" id="pringusage_2">
            ㆍ유의사항
            <template v-for="(nline, nidx) in noteslineinf">
              <br :key="'n'+nidx"/><span class="b2b_info_text_detail">{{nline}}</span>
            </template>
          </div>
          <div class="b2b_info_text" id="pringusage_3">
            ㆍ취소/환불 안내
            <template v-for="(rline, ridx) in refundlineinf">
              <br :key="'r'+ridx"/><span class="b2b_info_text_detail">{{rline}}</span>
            </template>
          </div>
        </div>
        <div id="printsub_img" class="b2b_img">
          <div class="b2b_contents"  v-for="(subimg, subidx) in subImagesInf" :key="subidx">
            <div class="b2b_img_cate" >
              <div v-if="subimg.title != null && subimg.title != '' || subimg.description != null && subimg.description != ''" class="b2b_img_cate_text">
                <div v-if="subimg.title != null && subimg.title != ''" class="b2b_img_cate_title">
                  <font>{{subimg.title}}</font>
                </div>
                <div v-if="subimg.description != null && subimg.description != ''" class="b2b_img_cate_description" style="padding:0px;text-align: center;">
                  {{subimg.description}}
                </div>
              </div>
              <div class="b2b_img_cate_img">
                <div class="cateimg" v-for="(img, iidx) in subimg.images" :key="iidx" :style="{width:styleImageWidthRatio(subimg.images, iidx)+'%'}">
                  <img :src="img.url"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="modal-footer">
        <div class="text-center">
          <button type="button" class="btn btn-default" @click="printSubimg()">이미지 생성</button>
          <button class="btn btn-default" @click="onClose"> 닫기 </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rescode, imgtype } from "../lib/consts";
import { ContainerClient } from '@azure/storage-blob';
import { reqPost } from "../lib/request";
import { b2bapi, adminapi } from "../lib/api";
import domtoimage from "dom-to-image";
import os from 'os';
import Compressor from "compressorjs";
export default {
  components: {},
  props: {
    fullImgDownChilds:{
      type:String,
      default:""
    },
    usageImages:{
      type:Object,
      default:() => {return {
        titleImageUrl:"https://hitable2020images.blob.core.windows.net/b2b/b2b_form_info_top.jpg",//타이틀 이미지 URL
        useInfoImageUrl:"",//사용정보 이미지 URL
        baseInfoImageUrl:"",//기본정보 이미지 URL
        notesInfoImageUrl:"",//유의사항 이미지 URL
        refundInfoImageUrl:"",//취소환불규정 이미지 URL
      }},
    },
    subImages:{
      type:Object,
      default:() => {return {
        subImageUrl0:"",//서브 이미지 URL 0
        subImageUrl1:"",//서브 이미지 URL 1
        subImageUrl2:"",//서브 이미지 URL 2
        subImageUrl3:"",//서브 이미지 URL 3
        subImageUrl4:"",//서브 이미지 URL 4
        subImageUrl5:"",//서브 이미지 URL 5
        subImageUrl6:"",//서브 이미지 URL 6
        subImageUrl7:"",//서브 이미지 URL 7
        subImageUrl8:"",//서브 이미지 URL 8
        subImageUrl9:"",//서브 이미지 URL 9
      }},
    },
    initsubimgs:{
      type:Boolean,
      default: () => {},
    },
    goodsId:{
      type:String,
      default:""
    },
    usage:{
      type:Object,
      default:() => {return {
        baseInfo:"",
        useInfo:"",
        notes:"",
        returnsAndRefund:""
      }},
    },
    subImagesInf:{
      type:Array,
      default:()=>{return [];}
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
        full:false,
      canvas:null,
      printctx:null,
      printimg:null,
      sumimgpart:[],
      // usageImages:{
      //   titleImageUrl:"https://hitable2020images.blob.core.windows.net/b2b/b2b_form_info_top.jpg",//타이틀 이미지 URL
      //   useInfoImageUrl:"",//사용정보 이미지 URL
      //   baseInfoImageUrl:"",//기본정보 이미지 URL
      //   notesInfoImageUrl:"",//유의사항 이미지 URL
      //   refundInfoImageUrl:"",//취소환불규정 이미지 URL
      // },
      fullImageUrl:"",
      // subImages:{
      //   subImageUrl0:"",//서브 이미지 URL 0
      //   subImageUrl1:"",//서브 이미지 URL 1
      //   subImageUrl2:"",//서브 이미지 URL 2
      //   subImageUrl3:"",//서브 이미지 URL 3
      //   subImageUrl4:"",//서브 이미지 URL 4
      //   subImageUrl5:"",//서브 이미지 URL 5
      //   subImageUrl6:"",//서브 이미지 URL 6
      //   subImageUrl7:"",//서브 이미지 URL 7
      //   subImageUrl8:"",//서브 이미지 URL 8
      //   subImageUrl9:"",//서브 이미지 URL 9
      // },
    };
  },
  computed: {
    uselineinf(){
      return this.usage.useInfo.split(os.EOL);
    },
    baselineinf(){
      return this.usage.baseInfo.split(os.EOL);
    },
    noteslineinf(){
      return this.usage.notes.split(os.EOL);
    },
    refundlineinf(){
      return this.usage.returnsAndRefunds.split(os.EOL);
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    },
  },
  mounted() {},
  methods: {
    checkFullImg(isfull){
        if(isfull){
            this.$emit("checkFullImgF",true)
        }else{
            this.$emit("checkFullImgF",false)
        }
        
    },
    widthBy1024h(image) {
      return 1024 * image.width / image.height;
    },
    totalWidthBy1024h(allimages) {
      return allimages.map(image => this.widthBy1024h(image)).reduce((pre, cur) => pre + cur);
    },
    styleImageWidthRatio(allimages, index) {
      let totalWidthBy1024h = this.totalWidthBy1024h(allimages);
      let imageWidthBy1024h = this.widthBy1024h(allimages[index]);

      let widthRatio = imageWidthBy1024h / totalWidthBy1024h * 100;

      return widthRatio;
    },
    getPrintUsageImageBlob(bolbdata, idx)
    {
      // console.log(this.fullImageUrl)
      // var matches = bolbdata.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
      // // var contentType = matches[1];
      // var contentType = "image/jpeg";
      // var buffer = new Buffer(matches[2], 'base64');
      var requrl = adminapi.sas;
      var reqdata = {
        type: this.imgtype.B2B,
      };
      var orgurl = null;

      if(this.initsubimgs)
      {

        if(idx == 0){
            orgurl =  this.usageImages.useInfoImageUrl ;
        }else if(idx == 1){
            orgurl = this.usageImages.baseInfoImageUrl;
        }else if(idx == 2){
            orgurl = this.usageImages.notesInfoImageUrl;
        }else if(idx == 3){
            orgurl = this.usageImages.refundInfoImageUrl;
        }else if(idx == 4){
            orgurl = this.subImages.subImageUrl0;
        }else if(idx == 5){
            orgurl = this.subImages.subImageUrl1 ;
        }else if(idx == 6){
            orgurl = this.subImages.subImageUrl2 ;
        }else if(idx == 7){
            orgurl = this.subImages.subImageUrl3 ;
        }else if(idx == 8){
            orgurl = this.subImages.subImageUrl4 ;
        }else if(idx == 9){
            orgurl = this.subImages.subImageUrl5 ;
        }else if(idx == 10){
            orgurl = this.subImages.subImageUrl6 ;
        }else if(idx == 11){
            orgurl = this.subImages.subImageUrl7 ;
        }else if(idx == 12){
            orgurl = this.subImages.subImageUrl8 ;
        }else if(idx == 13){
            orgurl = this.subImages.subImageUrl9 ;
        }
      }
      if(orgurl != null)
      {
        requrl = adminapi.sasUpdate;
        reqdata = {
          originUrl: orgurl,
        };
      }else if(this.fullImgDownChilds != null && this.fullImgDownChilds != ""){
        if(idx == 14){
            orgurl = this.fullImgDownChilds;
        }
        requrl = adminapi.sasUpdate;
        reqdata = {
          originUrl: orgurl,
        };
      }
      else{
         var requrl = adminapi.sas;
          var reqdata = {
            type: this.imgtype.B2B,
          };
      }
      var file_idx = idx + 2;
      console.log(requrl)
      reqPost(
        adminapi.base,
        requrl,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              const containerClient = new ContainerClient(result.data.url);
              let filename = "";
              let fullImageName = "";
              if(orgurl != null && orgurl != "")
              {
                filename = result.data.filePath;
                fullImageName = result.data.filePath;
              }else{
                filename = result.data.directory+"/"+this.goodsId+"_"+file_idx+".jpg";
                fullImageName = result.data.directory+"/"+this.goodsId+"_full.jpg";
              }
           
              let blockBlobClient = "";
              if(idx == 14){
                   blockBlobClient = containerClient.getBlockBlobClient(fullImageName);
              }else{
                   blockBlobClient = containerClient.getBlockBlobClient(filename);
              }
              
              // blockBlobClient.upload(buffer, buffer.length, {blobHTTPHeaders: {blobContentType: contentType}})
              blockBlobClient.uploadBrowserData(bolbdata, {
                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                blobHTTPHeaders: {
                  blobContentType: bolbdata.type
                },
              })
              .then((upfile)=>{
                var r_url = upfile._response.request.url;
                let uploadedUrl = r_url.substring(0,r_url.indexOf("?sig="));
                console.log("uploadedUrl", uploadedUrl);
                if(idx == 0){
                  this.usageImages.useInfoImageUrl = uploadedUrl;
                }else if(idx == 1){
                  this.usageImages.baseInfoImageUrl = uploadedUrl;
                }else if(idx == 2){
                  this.usageImages.notesInfoImageUrl = uploadedUrl;
                }else if(idx == 3){
                  this.usageImages.refundInfoImageUrl = uploadedUrl;
                }else if(idx == 4){
                  this.subImages.subImageUrl0 = uploadedUrl;
                }else if(idx == 5){
                  this.subImages.subImageUrl1 = uploadedUrl;
                }else if(idx == 6){
                  this.subImages.subImageUrl2 = uploadedUrl;
                }else if(idx == 7){
                  this.subImages.subImageUrl3 = uploadedUrl;
                }else if(idx == 8){
                  this.subImages.subImageUrl4 = uploadedUrl;
                }else if(idx == 9){
                  this.subImages.subImageUrl5 = uploadedUrl;
                }else if(idx == 10){
                  this.subImages.subImageUrl6 = uploadedUrl;
                }else if(idx == 11){
                  this.subImages.subImageUrl7 = uploadedUrl;
                }else if(idx == 12){
                  this.subImages.subImageUrl8 = uploadedUrl;
                }else if(idx == 13){
                  this.subImages.subImageUrl9 = uploadedUrl;
                }else if(idx == 14){
                    this.fullImageUrl = uploadedUrl
                    this.$emit("fullImgDownValue",uploadedUrl)
                    this.$emit("bbn",this.goodsId+"_full.jpg")
                }
                this.printUsageimg(idx + 1);
                // this.fullImageUrl = this.sumimgpart.fullImageUrl;
                
              }).catch((err)=>{
                console.log(" err : ", err);
              });
            }
          }
        }
      );
    },
    async printUsageimg(idx) {
      if(idx < 4)
      {
        var node = document.getElementById("pringusage_" + idx);
        var options = {
          quality: 1
        };

        try {
          // console.log('printUsageimg')
          let blob = await domtoimage.toBlob(node, options);
          let compressedBlob = await this.compressImage(blob);
          this.getPrintUsageImageBlob(compressedBlob, idx);
        } catch (e) {
          console.log("failed to load image!", e);
        }
      }
      else if(idx == 14){
        var node = document.getElementById("fullImg");
        var options = {
          quality: 1
        };

        try {
          let blob = await domtoimage.toBlob(node, options);
          let compressedBlob = await this.compressImage(blob);
          this.getPrintUsageImageBlob(compressedBlob, idx);
        } catch (e) {
          console.log("failed to load image!", e);
        }
      }else{
        if(idx - 4 < 11)
        {
        //   this.getPrintUsageImageBlob(this.sumimgpartFull[idx - 4], idx)
        // console.log(idx)
          this.getPrintUsageImageBlob(this.sumimgpart[idx - 4], idx)
        }
        else{
          this.registerB2bSubImg();
        }
      }

        
    },
    async printSubimg() {
      var node = document.getElementById("printsub_img");
      if(node == null)
      {
        return ;
      }
      var options = {
        quality: 1
      };
      domtoimage.toJpeg(node, options)
      .then((data) => this.splitSubImageBlob(data))
      .catch(() => {
      });
    },
    splitSubImageBlob(bolbdata)
    {
    //   console.log(bolbdata)
      this.canvas = document.createElement('canvas'), // In memory canvas
      this.printctx = this.canvas.getContext("2d");
      this.printimg = new Image();
      this.printimg.crossOrigin = "Anonymous";
      this.printimg.onload = this.printimgload;
      this.printimg.src = bolbdata;
     
    },
    async printimgload()
    {
          var org_w = this.printimg.width, sp10_h = this.printimg.height / 10;
        for (var i = 0; i < 10; i++) {
            var x = -0, y = 0 - sp10_h * i;
            this.canvas.width = org_w;
            this.canvas.height = sp10_h;
            this.printctx.drawImage(this.printimg, x, y, org_w, sp10_h * 10); // img, x, y, w, h
            let canvasBlob = await this.getCanvasBlob(this.canvas);
            let compressedDataUrl = await this.compressImage(canvasBlob);
            this.sumimgpart.push(compressedDataUrl); // ("image/jpeg") for jpeg
        }

          this.printUsageimg(0);
      
    },
    //사이즈, 이미지 타입지정 함수
    getCanvasBlob(canvas) {
      return new Promise(function(resolve, reject) {
        canvas.toBlob(function(blob) {
          resolve(blob)
        })
      })
    },
    //최종 수정,최종 수정 날짜 생성,size , type
    compressImage(imageFile) {
      return new Promise((resolve,reject) => {
        new Compressor(imageFile, {
          maxWidth: 732,
          quality: 0.8,
          success(result) {
            resolve(result)
          },
          error(e) {
            reject(e)
          },
        });
      });
    },
    registerB2bSubImg()
    {
      var reqdata = {
        goodsId:this.goodsId,
        usageImages: this.usageImages,
        subImages:this.subImages,
        fullImageUrl:this.fullImageUrl,
      };
      reqPost(
        b2bapi.base,
        b2bapi.standregister,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert("저장되었습니다.");
              if(this.fullImageUrl == null){
                  this.checkFullImg(false);
              }else{
                  this.checkFullImg(true);
              }
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    getRenderInf(txt)
    {
      return txt.split(os.EOL);
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 732px;
    font: normal 16px/1.5 "NotoSansKR", "맑은 고딕", "Malgun Gothic", "돋움",
      Dotum, Helvetica, Arial, Sans-serif;
  }
  .modal-body {
    padding: 0px;
  }
}
/*
.modal-body {
  height: 600px;
  overflow-y: scroll;
} */

.b2b_info_img_top {
  width: 100%;
  background: #ffffff;
}
.b2b_info_img_top img {
  width: 100%;
}
.b2b_info_text {
  background: #ffffff;
  padding: 30px 24px 0px 24px;
  font-family: YoonGothic750;
  line-height: 28px;
  letter-spacing: -1px;
  color: #605f5f;
  font-size: 17px;
}
.b2b_info_text_detail {
  padding-left: 15px;
}
.b2b_img_cate {
  background: #fff;
  padding: 30px 66px 0;
}
.b2b_img {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 72px;
}
.b2b_img_cate_text {
  border-top:4px solid #000000;
  border-bottom:1px solid #000000;
}
.b2b_img_cate_title {
  height: 65px;
  font-size: 35px;
  color: #000000;
  font-weight: bold;
  text-align: center;
}
.b2b_img_cate_title font {
  vertical-align: middle;
}
.b2b_img_cate_description {
  background: #ffffff;
  padding: 30px 24px 0px 24px;
  font-family: YoonGothic750;
  line-height: 28px;
  letter-spacing: -1px;
  color: #605f5f;
  font-size: 17px;
}
.b2b_img_cate_img {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 12px;
}

.b2b_img_cate_img .cateimg {
  /* flex: 1; */
  /* padding: 0px 2px; */
  padding: 0px;
  margin: 8px;
}
.cateimg img {
  width: 100%;
}
</style>
