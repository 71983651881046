<template>
<div class="modal" style="display:block">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">리뷰사진
                <div class="modal-body form-horizontal">
                    <div class="form-group" style="margin:0px;">
                        <div>
                            <div class="imglist" v-for="(simg, sidx) in addReviewImg.images" :key="sidx">
                                <drop @drop="sumimagesort(sidx, $event)" v-if="simg.length > sidx ||  addReviewImg.images">
                                    <drag :transfer-data="{ index: sidx, mark:'subimgs' }">
                                        <NewProdImg :value="addReviewImg.images[sidx].imageUrl" :index="sidx" style="margin-right:10px" :change="onRmvSubImg" />
                                    </drag>
                                </drop>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group text-center">
                                <button type="button" class="btn btn-default" style="margin-right:10px" @click="ReviewModalCloseChild">닫기</button>
                                <button type="button" class="btn btn-default" @click="reviewImgUpdate">수정</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import NewProdImg from '../../../components/newProdImg.vue';
import {
    Drag,
    Drop
} from "vue-drag-drop";
import {
    rescode,
    menuval
} from "../../../lib/consts";
import {
    personalization
} from "../../../lib/api";
import {
    reqGet,
    reqPost
} from "../../../lib/request";

export default {
    props: {
        addsubimgid: Number,
    },
    watch: {
        'addsubimgid': function (val) {
            console.log(val)
        },
    },
    data() {
        return {
            keyadd: false,
            showType: false,
            addReviewImg: {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
                /*
                url      String      서브 이미지 URL
                width    Number      서브 이미지 width
                height   Number      서브 이미지 height
                */
            },
        }
    },
    components: {
        NewProdImg,
        Drag,
        Drop
    },
    mounted() {
        this.reviewImgloda();
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    methods: {
        reviewImgUpdate() {
            if (confirm("수정하시겠습니까? 삭제된 리뷰사진은 되돌리 수 없습니다.")) {
                reqPost(
                    personalization.base,
                    personalization.reviewImageUpdate,
                    this.authToken, {
                        'reviewId': this.addsubimgid,
                        "reviewImageList": this.addReviewImg.images
                    },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.ReviewModalCloseChild();
                                this.refreshPage();
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        reviewImgloda() {
            reqGet(
                personalization.base,
                personalization.reviewImageList + '?reviewId=' + this.addsubimgid,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.addReviewImg.images = [];
                            this.addReviewImg.images = result.data;
                        }
                    } else {
                        alert(result.message);
                    }
                }
            );
        },
        ReviewModalCloseChild() {
            this.$emit('ReviewPictureClose')
        },
        onRmvSubImg(idx) {
            this.addReviewImg.images.splice(idx, 1);
        },
        keyaddShow() {
            if (this.keyadd === true) {
                this.keyadd = false;
            } else {
                this.keyadd = true;
            }
        },
        sumimagesort(drop, drag) {
            if (drag !== undefined) {
                if (drag.mark != 'subimgs') {
                    return;
                }
                var t_array = this.addReviewImg.images;
                this.addReviewImg.images = [];
                var b = t_array[drag.index];
                t_array[drag.index] = t_array[drop];
                t_array[drop] = b;
                this.addReviewImg.images = t_array;
            }
        },
    }
}
</script>

<style scoped>
.imglist {
    display: inline-block;
}
</style>
