<template>
<div class="login-page">
  <div class="login-box">
    <div class="login-logo">
      <b>T</b>ABLENJOY <b>M</b>ANAGER
    </div>
      <div class="login-box-body">
        <p class="box-title">로그인</p>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon adduserid"></span>
            <input type="text" class="form-control" v-model="userid" placeholder="아이디">
          </div>
          <span class="text-red">{{userrmsg}}</span>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon addpassword"></span>
            <input type="password" class="form-control" v-model="password" placeholder="비밀번호">
          </div>
          <span class="text-red">{{pswerrmsg}}</span>
        </div>
        <div class="form-group">
          <div class="input-group" style="position:relative">
            <span class="input-group-addon addphone"></span>
            <input type="text" class="form-control" v-model="phonenum" placeholder="전화번호">
            <button type="button" class="btn btn-primary smsbtn" @click="clickSMS">인증번호 요청</button>
          </div>
          <span class="text-red">{{phonemsg}}</span>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon autcode"></span>
            <input type="text" class="form-control" v-model="authcode" placeholder="인증번호">
            <label class="form-control smsbtn" style="width: auto;">{{ timestr }}</label>
          </div>
          <span class="text-red">{{authmsg}}</span>
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-primary btn-block" @click="clickLogin">로그인</button>
          <!-- /.col -->
        </div>
        <div class="form-group text-center">
          <div style="display: inline-block;">
            <label class="chklabel">
              ID 저장
              <input type="checkbox" v-model="remem">
              <span class="markbg"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rescode, cookielbl } from "../../lib/consts";
import { isMobileNum } from "../../lib/utility";
import { reqPost } from "../../lib/request";
import { adminapi } from "../../lib/api";
export default {
  data() {
    return {
      userid: "",
      userrmsg: "",
      password: "",
      pswerrmsg: "",
      phonenum: "",
      phonemsg: "",
      authcode: "",
      authmsg: "",
      counttime:180,
      timestr:"",
      remem: true,
      intval:null
    };
  },
  updated() {},
  created() {
    this.userid = window.localStorage.getItem(cookielbl.remem);
  },
  mounted() {
  },
  methods: {
    clickLogin() {
      if(this.userid != "" && this.password != "" && this.authcode != "")
      {
        reqPost(adminapi.base, adminapi.mfalogin, "", {username:this.userid, password:this.password, otp:this.authcode}, (data)=>{
          if(data != null)
          {
              if(data.code == rescode.REQ_SUCCESS)
              {
                  var val = data.data;
                  // Check local storage to handle refreshes

                  var authdata = {token:val.tokens.access.token, expiresIn:val.tokens.access.expiresIn, expiresAt: val.tokens.access.expiresIn + Math.floor(Date.now()/1000),
                    refresh:val.tokens.refresh.token, refreshexpiresIn:val.tokens.refresh.expiresIn, refreshexpiresAt: val.tokens.refresh.expiresIn + Math.floor(Date.now()/1000),
                    userRole:val.userRole};

                  if (window.localStorage) {
                      window.localStorage.setItem(cookielbl.authinf, JSON.stringify(authdata));
                  }
                  if(this.remem){
                      window.localStorage.setItem(cookielbl.remem, this.userid);
                  }else{
                      window.localStorage.setItem(cookielbl.remem, "");
                  }
                  authdata.userid = this.userid;
                  this.$store.dispatch('dologin', authdata);
              }
              this.isLoginSuccess(data.code, data.message);
          }else{
              this.userrmsg = "로그인 실패 되었습니다.";
          }
        });
      }else{
        if(this.userid == "")
        {
          this.userrmsg = "아이디를 입력하세요.";
        }else if(this.password == ""){
          this.pswerrmsg = "비밀번호를 입력하세요.";
        }else{
            this.authmsg = "인증번호를 입력하세요.";
        }
      }
    },
    clickSMS(){
        if(this.userid == "")
        {
            this.userrmsg = "아이디를 입력하세요.";
            return ;
        }else{
            this.userrmsg = "";
        }

        if(this.phonenum == "")
        {
            this.phonemsg = "전화번호를 입력하세요.";
            return ;
        }else if(!isMobileNum(this.phonenum))
        {
            this.phonemsg = "휴대폰 번호가 아닙니다.";
            return ;
        }else{
            this.phonemsg = "";
        }

        reqPost(adminapi.base, adminapi.mfaotp, "", {username:this.userid, phoneNumber:this.phonenum}, (data)=>{
            if(data != null)
            {//SMS번호가 전송되었다면
                this.phonemsg = data.message;
                if(data.code == rescode.REQ_SUCCESS)
                {
                    this.counttime = 180;
                    this.timestr = "3:00";
                    this.intval = setInterval(this.interFunc, 1000);
                }
            }else{
                this.phonemsg = "OTP 인증번호 요청이 실패되었습니다.";
            }
        });
    },
    isLoginSuccess(val, message) {
      if (val === null) {
        this.userrmsg = "로그인 실패 되었습니다.";
        this.pswerrmsg = "";
        this.authmsg = "";
      } else if (val === rescode.REQ_SUCCESS) {
        this.$router.push("/");
      } else {
        this.userrmsg = message;
        this.pswerrmsg = "";
        this.authmsg = "";
      }
    },
    interFunc(){
        if(this.counttime > 0)
        {
            this.counttime = this.counttime - 1;
            var second = this.counttime % 60;
            var minute = (this.counttime - second) / 60;
            var str_second = second+'';
            this.timestr = minute + ':' + str_second.padStart(2, '0');
        }else{
            clearInterval(this.intval);
            this.setState({counttime: 0, timestr:"", authmsg:"OTP 인증번호를 다시 요청하세요."});
        }
    }
  },
};
</script>

<style scoped>
.login-page{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.box-title{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.plink{
  margin-left: 10px;
  padding: 5px 0px;
  border-bottom: 1px solid #333333;
  color: #333333;
}

.btn{
  background-color: #333333;
  border-radius: 100px;
}

.smsbtn{
  position: absolute;
  right: 0;
  z-index: 100;
}

.chklabel {
  display: block;
  position: relative;
  padding-left: 25px;
  color: #333333;
  cursor: pointer;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chklabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.markbg {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #33333333;
}

.chklabel:hover input ~ .markbg {
  background-color: #ccc;
}

.chklabel input:checked ~ .markbg {
  background-color: #333333;
}

.markbg:after {
  content: "";
  position: absolute;
  display: none;
}

.chklabel input:checked ~ .markbg:after {
  display: block;
}

.chklabel .markbg:after {
  left: 5px;
  top: 3px;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-group-addon, .form-control{
  border: 0px;
}

.form-control{
  padding: 6px 0px;
}

.input-group{
  border-bottom: 1px solid #b2b2b2;
}
.adduserid{
  background-image: url("/static/img/uaserid.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addpassword{
  background-image: url("/static/img/password.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addphone{
  background-image: url("/static/img/phone.png");
  background-repeat: no-repeat;
  background-position: center;
}
.autcode{
  background-image: url("/static/img/authcode.png");
  background-repeat: no-repeat;
  background-position: center;
}
</style>
