<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label">제목</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="title"/>
          </div>
        </div>
        <div class="form-group" v-if="noticeNo > 0">
          <label class="col-sm-3 control-label">작성자</label>
          <div class="col-sm-9 ">{{registeredBy}}</div>
        </div>
        <div class="form-group" v-if="noticeNo > 0">
          <label class="col-sm-3 control-label">등록일</label>
          <div class="col-sm-9">{{viewDate(registeredDate)}}</div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">공지노출여부</label>
          <div class="col-sm-9 radio">
            <label> <input type="radio" :value="true" v-model="published"/>적용 </label>
            <label> <input type="radio" :value="false" v-model="published"/>적용안함 </label>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">공지강조</label>
          <div class="col-sm-9 radio">
            <label> <input type="radio" :value="true" v-model="importantFlag"/>적용 </label>
            <label> <input type="radio" :value="false" v-model="importantFlag"/>적용안함 </label>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">팝업</label>
          <div class="col-sm-9 radio">
            <label> <input type="radio" :value="true" v-model="useFlag" />적용 </label>
            <label> <input type="radio" :value="false" v-model="useFlag" />적용안함 </label>
          </div>
        </div>
        <div class="form-group" v-if="useFlag">
          <label class="col-sm-3 control-label">팝업이미지 (1280 * 614)</label>
          <div class="col-sm-9">
            <SingleImgUpload v-model="imageUrl" :resizeWidth="0" downlabel="" :imgtype="imgtype.ETC" />
          </div>
        </div>
        <input type="file" ref="fileInput" style="display:none;" accept="image/jpeg, image/png" @change="onUploadFiles"/>
        <div class="form-group">
          <label class="hidden-xs col-sm-3 control-label"></label>
          <div class="col-sm-9">
            <!-- <editor v-model="pcheadText" :options="editorOptions"/> -->
            <editor
              api-key="02p3raa9urge93k9p07ljuvfbkovzy2wrad7dprwav8xe682"
              :init="{
                min_height: 500,
                max_height: 1000,
                menubar: false,
                plugins: 'autolink code link autoresize paste contextmenu image preview',
                toolbar:
                  'undo redo | fontselect fontsizeselect formatselect | forecolor | bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link custom_image | code preview',
                setup: function(editor){
                  editor.ui.registry.addButton('custom_image',{
                    icon: 'image',
                    tooltip: 'insert Image',
                    onAction: function() {
                     onChangeClick()

                    }
                  })
                }
              }"

              v-model="contents"
            />
          </div>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="goBack">
            뒤로
          </button>
          <button type="button" class="btn btn-default" @click="doRegister" :disabled="buttonDissabled">저장</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval, imgtype} from "../../lib/consts";
import routepath from "../../lib/routes";
import { reqPost } from "../../lib/request";
import {adminapi, board} from "../../lib/api";
import { DateFormat } from "../../lib/utility";

// import 'codemirror/lib/codemirror.css';
// import '@toast-ui/editor/dist/toastui-editor.css';
// import '@toast-ui/editor/dist/i18n/ko-kr';
// import { Editor } from '@toast-ui/vue-editor';
import Editor from "@tinymce/tinymce-vue";
import SingleImgUpload from "../../components/SingleImgUpload";
import {ContainerClient} from "@azure/storage-blob";
import Compressor from "compressorjs";
export default {
  components: {
    editor: Editor,
    SingleImgUpload
  },
  created() {
    this.noticeNo = this.$route.query.noticeNo;
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BOD,
      path2: menuval.LV1_HOME,
      title: "",
    });
  },
  props:{
    change:{
      type: Function,
      default: ()=>{},
    }
  },
  data() {
    return {
      pcheadText: "",
      editorOptions: {
        minHeight: "200px",
        language: "ko-KR",
        useCommandShortcut: true,
        useDefaultHTMLSanitizer: true,
        usageStatistics: true,
        hideModeSwitch: false,
        toolbarItems: [
          "heading",
          "bold",
          "italic",
          "strike",
          "divider",
          "hr",
          "quote",
          "divider",
          "ul",
          "ol",
          "task",
          "indent",
          "outdent",
          "divider",
          "table",
          "image",
          "link",
          "divider",
          "code",
          "codeblock",
        ],
      },
      noticeNo:0,
      title:"",//제목
      contents:"",//내용
      published:false,//발행여부: true: 발행됨, false: 발행안됨
      useFlag:false,//팝업여부 : true : 팝업허용 , false: 팝업안됌
      imageUrl:"",
      importantFlag:false,
      registeredDate:"",//UTC yyyy-MM-ddTHH:mm:ss 등록일
      registeredBy:"",//등록자

      //IMAGE
      fileInput: null,
      windowRef: null,
      imgwidth:0,
      imgheight:0,
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    },
  },
  mounted() {
    if(this.noticeNo != 0)
    {
      this.refreshPage();
    }
    tinymce.activeEditor.getBody().setAttribute('contenteditable', true)
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    refreshPage() {
      reqPost(
        board.base,
        board.ht_notice_detail,
        this.authToken,
        {
          noticeNo:this.noticeNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.title = result.data.title;//제목
              this.contents = result.data.contents;//내용
              this.published = result.data.published;//발행여부: true: 발행됨, false: 발행안됨
              this.useFlag = result.data.useFlag;//팝업여부 : true 팝업허
              this.registeredDate = result.data.registeredDate;//UTC yyyy-MM-ddTHH:mm:ss 등록일
              this.registeredBy = result.data.registeredBy;//등록자
              this.imageUrl = result.data.imageUrl;
              this.importantFlag = result.data.importantFlag;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    goBack(){
      this.$router.go(-1);
    },
    doRegister()
    {
      this.buttonDissabled = true;
      if(this.noticeNo == 0)
      {
        this.$store.dispatch('setDisabledLayout');
        reqPost(
          board.base,
          board.ht_notice_add,
          this.authToken,
          {
            title:this.title,
            contents:this.contents,
            published:this.published,
            useFlag:this.useFlag,
            imageUrl:this.imageUrl,
            importantFlag:this.importantFlag
          },
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.buttonDissabled = false;
                this.goBack();
              } else {
                this.buttonDissabled = false;
                alert(result.message);
              }
            }
          }
        );
      }else{
        this.$store.dispatch('setDisabledLayout');
        reqPost(
          board.base,
          board.ht_notice_update,
          this.authToken,
          {
            noticeNo:this.noticeNo,
            title:this.title,
            contents:this.contents,
            published:this.published,
            useFlag:this.useFlag,
            imageUrl:this.imageUrl,
            importantFlag:this.importantFlag
          },
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');

              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    onChangeClick(){
      this.$refs.fileInput.click();
    },
    onUploadFiles(event){
      let imageFile = event.target.files[0];

      this.compressImage(imageFile).then( (compressedFile) => {
        let reader = new FileReader();
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            this.imgwidth = img.width;
            this.imgheight = img.height;
          }
          img.src = evt.target.result;
        }
        reader.onerror = evt => {
          console.error(evt);
        }
        reader.readAsDataURL(compressedFile);

          this.onRegImage(compressedFile);

      }).catch((error) => {
        alert("이미지 리사이징 에러입니다.");
      });
    },
    compressImage(imageFile) {
      return new Promise((resolve, reject) => {
        let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if (this.resizeWidth === 0) {
          resolve(imageFile);
        } else if (!allowedExtensions.exec(imageFile.name)) {
          resolve(imageFile);
        } else {
          new Compressor(imageFile, {
            maxWidth: this.resizeWidth || 600,
            quality: 0.8,
            success(result) {
              resolve(result)
            },
            error(e) {
              reject(e)
            },
          });
        }
      });
    },
    onRegImage(imageFile){
      reqPost(
        adminapi.base,
        adminapi.sas,
        this.authToken,
        {
          type: "ETC",
        },
        (result) => {
          if (result != null) {
            if (result.code === rescode.REQ_SUCCESS) {
              const containerClient = new ContainerClient(result.data.url);
              var d = new Date();
              var n = d.getTime();
              let filename = n+'_'+imageFile.name;
              // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
              // const file = files[0];
              const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory+"/"+filename);
              blockBlobClient.uploadBrowserData(imageFile, {
                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                blobHTTPHeaders: {
                  blobContentType: imageFile.type
                },
              }).then((upfile)=>{
                var r_url = upfile._response.request.url;
                let uploadedUrl = r_url.substring(0,r_url.indexOf("?sig="));
                this.$emit('input', uploadedUrl);
                this.change(uploadedUrl);
                tinymce.execCommand('mceInsertContent', false,"<img src ='"+uploadedUrl+"'data-mce-src'"+uploadedUrl+"'data-originalFileName='"+uploadedUrl+"'>");

              });
            }
          }
        }
      );
    },
  },
};
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.radio > label {
  margin-right: 10px;
}

.checkbox > label {
  margin-right: 10px;
}
</style>
