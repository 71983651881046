<template>
<div>
    <section class="content">
        <div class="box box-solid">
            <div class="box-body">
                <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                    <DatePicker v-model="salesFrom" :last="salesTo" />
                    <h4 style="margin: 5px 10px">~</h4>
                    <DatePicker v-model="salesTo" :first="salesFrom" style="margin-right: 10px" />
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
                        전월
                    </button>
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
                        당월
                    </button>
                    <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                </div>
            </div>
        </div>

        <div class="box box-solid">
            <div class="box-body form-horizontal">
                <div class="form-group">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>쿠폰명</th>
                                    <th>생성</th>
                                    <th>발급</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(issuVal, issuidx) in issuData" :key="issuidx">
                                    <td class="linkcmp" @click="setviewData(issuVal.couponId,issuVal.couponName)">
                                        {{issuVal.couponName}}
                                    </td>
                                    <td>
                                        {{ issuVal.registerCount | numFormat }}
                                    </td>
                                    <td>
                                        {{ issuVal.issueCount | numFormat }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin:0px;margin-bottom:20px" v-if="moreContent">
            <div class="form-group">
                <span>상세 내역</span>
                <!-- <button type="button" class="btn btn-default pull-right" @click="DetaildownExcel">
                    <i class="fa fa-download"></i>Excel
                </button> -->
            </div>
        </div>

        <div class="box box-solid" v-if="moreContent">
            <div class="box-body form-horizontal">
                <div class="form-group">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>날짜</th>
                                    <th>생성</th>
                                    <th>발급</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(coupon, didx) in moreSetData" :key="didx">
                                    <td> {{ viewDate(coupon.issueDate) }} </td>
                                    <td> {{ coupon.registerCount | numFormat }} </td>
                                    <td> {{ coupon.issueCount | numFormat }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import {
    DateFormat
} from "../../lib/utility";
import routepath from "../../lib/routes";
import ToggleBtn from "../../components/ToggleBtn";
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker";
import XLSX from "xlsx";
import moment from 'moment';
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    discoutCopon,
} from "../../lib/api";

export default {
    components: {
        ToggleBtn,
        Pagination,
        DatePicker
    },
    data() {
        return {
            detailCouponName: '',
            moreContent: false,
            salesFrom: moment().format("YYYY-MM-01"),
            salesTo: moment().format("YYYY-MM-DD"),
            issuData: [{
                create: '500',
                issuance: '300',
                name: 'T 우주패스 발급쿠폰',
            }],
            moreSetData: [],
            moreSetDataCouponName: '',
        }
    },
    computed: {

    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_STAT,
            path2: menuval.LV2_STS_ISS,
            title: "할인쿠폰 발급현황조회",
        });
    },
    mounted() {
        this.refreshPage();
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    methods: {
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD", true);
        },
        onPrvMonth() {
            this.salesFrom = moment().subtract(1, 'months').format('YYYY-MM-01');
            this.salesTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        },
        onNowMonth() {
            this.salesFrom = moment().format("YYYY-MM-01");
            this.salesTo = moment().format("YYYY-MM-DD");
        },
        refreshPage() {

            reqPost(
                discoutCopon.base,
                discoutCopon.bydate,
                this.authToken, {
                    salesFrom: this.salesFrom,
                    salesTo: this.salesTo
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.moreContent = false;
                            this.moreSetData = [];
                            this.issuData = result.data.itemLists;

                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        setviewData(couponId, couponName) {
            this.moreSetDataCouponName = couponName;
            reqPost(
                discoutCopon.base,
                discoutCopon.bydate_detail,
                this.authToken, {
                    salesFrom: this.salesFrom,
                    salesTo: this.salesTo,
                    couponId: couponId,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.moreContent = true;
                            this.moreSetData = [];
                            this.moreSetData = result.data.itemInfoList;
                            this.detailCouponName = result.data.couponName;

                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
    }
}
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

th {
    text-align: center;
}

td {
    text-align: center;
}
</style>
