<template>
  <div class="root">
    <div class="input-group" style="display: inline-flex;">
      <input type="text" class="form-control" style="width:auto" v-model="newval"/>
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" @click="addNewItem">추가</button>
      </span>
    </div>
    <div class="row" style="margin:0px;">
      <span v-for="(aitem, aidx) in value" :key="aidx" class="badge bg-light-blue" style="margin:5px">
        {{aitem}}
        <button type="button" class="btn btn-xs" style="background: transparent;border: none;" @click="removeItem(aidx)">
          <i class="fa fa-fw fa-remove"></i>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: Array,
      default:null,
    },
  },
  data() {
    return {
      newval: "",
    };
  },
  // computed: {},
  // mounted() {},
  methods: {
    removeItem(aidx){
      var nvalue = this.value.filter((item, idx)=> idx != aidx);
      this.$emit('input', nvalue);
    },
    addNewItem(){
      var nvalue = this.value.concat(this.newval);
      this.newval = "";
      this.$emit('input', nvalue);
    }
  },
};
</script>
<style scoped>
</style> 