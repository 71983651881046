<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <div class="checkbox" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="ALL" v-model="saletype"/>전체
            </label>
            <label>
              <input type="radio" value="ONSALE" v-model="saletype"/>판매중
            </label>
            <label>
              <input type="radio" value="STOP" v-model="saletype"/>일시중지
            </label>
          </div>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsId">상품코드</option>
            <option value="GoodsNameContaining">상품명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="showaddmodal">
        <i class="fa fa-plus"></i>커스텀추가
      </button>
      <button class="btn btn-default" style="margin-left:15px" @click="delcuscoupon">
        <i class="fa fa-fw fa-remove"></i>삭제
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="downExcelDate" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>
                <input type="checkbox" class="allch" @change="allckech($event)"/>
              </th>
              <th class="text-center">번호</th>
              <th class="text-center">상품코드</th>
              <th>상품명</th>
              <th class="text-center">판매상태</th>
              <th class="text-center">타입</th>
              <th class="text-center">매칭개수</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(coupon, index) in content" v-bind:key="coupon.couponCustomId">
              <th>
                <input type="checkbox" class="chitem" :value="coupon.couponCustomId"/>
              </th>
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{coupon.goodsId}}</td>
              <td class="linkcmp" @click="showeditmodal(coupon)">{{coupon.goodsName}}</td>
              <td class="text-center">{{getSaleState(coupon.onSale)}}</td>
              <td class="text-center">{{getMatchtype(coupon.customByOption)}}</td>
              <td class="text-center">{{coupon.matchedOptionCount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="chmodal && {'display': 'block', 'z-index':'3000'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">상품선택</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="col-sm-5">
                <select class="form-control" v-model="fgoodkeytype">
                  <option value="NoKeyword"></option>
                  <option value="GoodsId">상품코드</option>
                  <option value="GoodsNameContaining">상품명</option>
                  <!-- <option value="SiteName">등록경로</option> -->
                </select>
              </div>
              <div class="col-sm-7">
                <div class="input-group">
                  <input type="email" class="form-control" v-model="fgoodkeyval"/>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" @click="searchgoods">검색</button>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-responsive chmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>상품코드</th>
                    <th>상품명</th>
                    <th>등록</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(good, gidx) in goodlist" :key="gidx">
                    <td>{{good.goodsId}}</td>
                    <td>{{good.goodsName}}</td>
                    <td>
                      <button type="button" class="btn btn-default" @click="solcoupongood(good)">선택</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closechmodal">닫기</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" :style="addmodal && {'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-2 control-label">위치</label>
              <div class="col-sm-10 radio">
                  <label>
                    <input type="radio" value="Prefix" v-model="editdata.customPosition"/>앞
                  </label>
                  <label>
                    <input type="radio" value="Suffix" v-model="editdata.customPosition"/>뒤
                  </label>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">타입</label>
              <div class="col-sm-10" style="display:inline-flex;">
                <div class="radio">
                  <label>
                    <input type="radio" :value="true" v-model="editdata.customByOption"/>매칭
                  </label>
                  <label>
                    <input type="radio" :value="false" v-model="editdata.customByOption"/>고정
                  </label>
                </div>
                <div class="input-group" v-if="!editdata.customByOption">
                  <input type="number" class="form-control" v-model="editdata.customCode"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">매칭정보</label>
              <div class="col-sm-10">
                <h5>상품정보</h5>
                <div class="table-responsive addmodal p-0">
                  <table class="table table-hover text-nowrap" style="margin:0px">
                    <thead>
                      <tr>
                        <th>상품상태</th>
                        <th>상품코드</th>
                        <th>상품명</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="selGood == null">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <button type="button" class="btn btn-default" @click="showchmodal">선택</button>
                        </td>
                      </tr>
                      <tr v-if="selGood != null">
                        <td>{{getSaleState(selGood.onSale)}}</td>
                        <td>{{selGood.goodsId}}</td>
                        <td>{{selGood.goodsName}}</td>
                        <td>
                          <button type="button" class="btn btn-default" style="margin-right:10px" @click="showchmodal">선택</button>
                          <button type="button" class="btn btn-default btn-social-icon" @click="rmvgoodsid">
                            <i class="fa fa-fw fa-trash-o"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-group" >
              <label class="hidden-xs col-sm-2 control-label"></label>
              <div class="col-sm-10" v-if="editdata.customByOption">
                <h5>옵션정보</h5>
                <div class="table-responsive addmodal p-0">
                  <table class="table table-hover text-nowrap" style="margin:0px">
                    <thead>
                      <tr>
                        <th>옵션상태</th>
                        <th>옵션코드</th>
                        <th>옵션명</th>
                        <th>정상가</th>
                        <th>판매가</th>
                        <th>커스텀 코드</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(option, oidx) in editoptions" :key="oidx">
                        <td>{{getSaleState(option.onSale)}}</td>
                        <td>{{option.optionId}}</td>
                        <td>{{option.optionName}}</td>
                        <td>{{option.listPrice | numFormat}}</td>
                        <td>{{option.salePrice | numFormat}}</td>
                        <td>
                          <input type="text" class="form-control" v-model="option.customCode"/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeaddmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateCuscoupon">저장</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import { reqGet, reqPost } from "../../../lib/request";
import { goodsapi } from "../../../lib/api";
import { DateFormat, getPartnerStr } from "../../../lib/utility";
import $ from 'jquery';
import XLSX from "xlsx";
export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.OPCPN_keywordType) {
        this.keywordType = localStorage.OPCPN_keywordType;
      }
      if (localStorage.OPCPN_keyword) {
        this.keyword = localStorage.OPCPN_keyword;
      }
      if (localStorage.OPCPN_Number) {
        this.pageNumber = parseInt(localStorage.OPCPN_Number);
      }
      if (localStorage.OPCPN_Size) {
        this.pageSize = parseInt(localStorage.OPCPN_Size);
      }
      if (localStorage.OPCPN_saletype) {
        this.saletype = localStorage.OPCPN_saletype;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_OPR, title: "쿠폰번호 커스텀"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.OPCPN_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.OPCPN_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.OPCPN_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.OPCPN_Size = newsize;
    },
    saletype(newsale)
    {
      localStorage.OPCPN_saletype = newsale;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      saletype:"ALL",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      addmodal: false,
      chmodal: false,

      editdata:{
        goodsId:0,//상품 코드
        customPosition:"Prefix",//커스텀 위치 Prefix: 앞, Suffix: 뒤
        customByOption:false,//옵션별 매칭 여부 true: 옵션별 매칭, false: 고정
        customCode:"",//고정 타입 매칭 코드 (customByOption가 false일 경우 사용)
        optionCustoms:[],//옵션별 코드 매칭 정보 (customByOption가 true일 경우 사용)
        /*
        optionCustoms[].optionId      :   Number      옵션 코드
        optionCustoms[].customCode    :   String      옵션 매칭 코드
        */
      },
      editoptions:[],
      fgoodkeytype:"NoKeyword",
      fgoodkeyval:"",//
      goodlist:[],
      selGood:null,
      exceldata:[],
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.OPCPN_keywordType = this.keywordType;
      localStorage.OPCPN_keyword = this.keyword;
      localStorage.OPCPN_Number = this.pageNumber;
      localStorage.OPCPN_Size = this.pageSize;
      localStorage.OPCPN_saletype = this.saletype;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var onSale = null;
      if(this.saletype == 'ALL')
      {
        onSale = "";
      }else if(this.saletype == 'ONSALE')
      {
        onSale = true;
      }else if(this.saletype == 'STOP')
      {
        onSale = false;
      }

      reqPost(
        goodsapi.base,
        goodsapi.couponpage,
        this.authToken,
        {
          keywordType: this.keywordType,
          keyword:this.keyword,
          onSale:onSale,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            }else{
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          }else{
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    downExcelDate() {
      this.buttonDissabled = true;
      var onSale = null;
      if(this.saletype == 'ALL')
      {
        onSale = "";
      }else if(this.saletype == 'ONSALE')
      {
        onSale = true;
      }else if(this.saletype == 'STOP')
      {
        onSale = false;
      }

      reqPost(
        goodsapi.base,
        goodsapi.couponlist,
        this.authToken,
        {
          keywordType: this.keywordType,
          keyword:this.keyword,
          onSale:onSale,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            }else{
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    convert(coupon, index) {
      return {
        A: index + 1,
        B: coupon.goodsId,
        C: coupon.goodsName,
        D: this.getSaleState(coupon.onSale),
        E: this.getMatchtype(coupon.customByOption),
        F: coupon.matchedOptionCount
      };
    },
    downExcel() {
      var excelhd = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "상품코드",
            C: "상품명",
            D: "판매상태",
            E: "타입",
            F: "매칭개수"
          },
        ],
        { skipHeader: true }
      );
      XLSX.utils.sheet_add_json(excelhd, this.exceldata.map(this.convert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelhd, "쿠폰번호 커스텀");
      // export Excel file
      XLSX.writeFile(wb, "쿠폰번호 커스텀.xlsx");
      this.buttonDissabled = false;
    },
    closeaddmodal() {
      this.addmodal = false;
    },
    showaddmodal() {
      this.editoptions = [];
      this.editdata = {
        couponCustomId:0,
        goodsId:0,//상품 코드
        customPosition:"Prefix",//커스텀 위치 Prefix: 앞, Suffix: 뒤
        customByOption:false,//옵션별 매칭 여부 true: 옵션별 매칭, false: 고정
        customCode:"",//고정 타입 매칭 코드 (customByOption가 false일 경우 사용)
      };
      this.selGood = null;
      this.addmodal = true;
    },
    rmvgoodsid()
    {
      this.editoptions = [];
      this.selGood = null;
    },
    showeditmodal(cusitem) {
      reqPost(
        goodsapi.base,
        goodsapi.couponinfo,
        this.authToken,
        {
          couponCustomId: cusitem.couponCustomId,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.editdata = {
                couponCustomId:cusitem.couponCustomId,
                customPosition:result.data.customPosition,//커스텀 위치 Prefix: 앞, Suffix: 뒤
                customByOption:result.data.customByOption,//옵션별 매칭 여부 true: 옵션별 매칭, false: 고정
                customCode:result.data.customCode,//고정 타입 매칭 코드 (customByOption가 false일 경우 사용)
              };
              this.editoptions = result.data.optionCustoms;
              this.selGood = {
                goodsId:result.data.goodsId,//상품 코드
                goodsName:result.data.goodsName,//상품명
                onSale:result.data.onSale,//상품 판매중 여부
              };
              this.addmodal = true;
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    closechmodal() {
      this.chmodal = false;
    },
    showchmodal() {
      this.searchgoods();
      this.chmodal = true;
    },
    getSaleState(val)
    {
      if(val)
      {
        return "판매중";
      }else{
        return "일시중지";
      }
    },
    getMatchtype(val)
    {
      if(val)
      {
        return "매칭";
      }else{
        return "고정";
      }
    },
    partner(val)
    {
      return getPartnerStr(val);
    },
    searchgoods()
    {
      reqGet(
        goodsapi.base,
        goodsapi.codeNameslist + "?keywordType=" + this.fgoodkeytype + "&keyword=" + this.fgoodkeyval,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.goodlist = result.data;
            } else {
              this.goodlist = [];
              alert(result.message);
            }
          }
        }
      );
    },
    getOptions(goodsId)
    {
      reqGet(
        goodsapi.base,
        goodsapi.goodsoptions + "?goodsId=" + goodsId,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.editoptions = result.data.options.map((oitem) => {return {...oitem, customCode:""}});
            } else {
              this.editoptions = [];
              alert(result.message);
            }
          }
        }
      );
    },
    solcoupongood(good)
    {
      this.selGood = good;
      this.getOptions(this.selGood.goodsId);
      this.closechmodal();
    },
    updateCuscoupon()
    {
      if(this.selGood == null)
      {
        alert("상품을 선택하세요.");
        return ;
      }
      var optionCustoms = this.editoptions.map((eitem) => {return {optionId:eitem.optionId, customCode:eitem.customCode}});
      if(this.editdata.goodsId == 0)
      {
        reqPost(
          goodsapi.base,
          goodsapi.couponregister,
          this.authToken,
          {
            goodsId: this.selGood.goodsId,
            customPosition:this.editdata.customPosition,
            customByOption:this.editdata.customByOption,
            customCode:this.editdata.customCode,
            optionCustoms:optionCustoms,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
                this.closeaddmodal();
              }else{
                alert(result.message);
              }
            }
          }
        );
      }else{
        reqPost(
          goodsapi.base,
          goodsapi.couponupdate,
          this.authToken,
          {
            couponCustomId: this.editdata.couponCustomId,
            goodsId: this.selGood.goodsId,
            customPosition:this.editdata.customPosition,
            customByOption:this.editdata.customByOption,
            customCode:this.editdata.customCode,
            optionCustoms:optionCustoms,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
                this.closeaddmodal();
              }else{
                alert(result.message);
              }
            }
          }
        );
      }
    },
    delcuscoupon()
    {
      var checkarray = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      reqPost(
          goodsapi.base,
          goodsapi.coupondelete,
          this.authToken,
          {
            couponCustomIds: checkarray,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                $('input:checkbox.allch').prop('checked', false);
                this.refreshPage();
              }else{
                alert(result.message);
              }
            }
          }
        );
    }
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.chmodal {
  height: 250px;
  overflow: scroll;
}

.chmodal th {
  background-color: #dee2e685;
}

.chmodal::-webkit-scrollbar {
  display: none;
}

.addmodal th {
  background-color: #dee2e685;
}

.addmodal::-webkit-scrollbar {
  display: none;
}

.modal-body .control-label{
  margin:0px;
}
</style>
