<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover dataTable text-nowrap">
          <colgroup>
            <col width="150px">
            <col width="100px">
            <col width="250px">
            <col width="100px">
            <col width="150px">
            <col width="*">
          </colgroup>
          <tbody>
            <tr>
              <th>브랜드명</th>
              <td colspan="5">
                <div class="input-group" >
                  <input type="text" class="form-control" v-model="brandName" placeholder="브랜드명" style="width: auto"/>
                  <button type="button" class="btn btn-default" @click="checkBrandName" style="margin-right: 10px">중복확인</button>
                  <label :class="brandchkclass">{{ brandcheckmsg }}</label>
                </div>
              </td>
            </tr>
            <tr>
              <th>분류</th>
              <td colspan="2">
                <div class="input-group">
                  <select class="form-control" v-model="brandType">
                    <option value="HOTEL">HOTEL</option>
                    <option value="FAMILY">FAMILY</option>
                    <option value="BUFFET">BUFFET</option>
                    <option value="SPECIAL">SPECIAL</option>
                    <option value="RESTAURANT">RESTAURANT</option>
                    <option value="SPA">SPA</option>
                  </select>
                </div>
              </td>
              <th>법인명</th>
              <td colspan="2">
                <div class="from-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="corporationName"
                    placeholder="법인명"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>업체 담당자</th>
              <td colspan="5">
                <table class="table table-hover text-nowrap">
                  <colgroup>
                    <col width="100px">
                    <col width="30%">
                    <col width="30%">
                    <col width="30%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>담당형태</th>
                      <th>이름</th>
                      <th>휴대폰 번호</th>
                      <th>이메일주소</th>
                    </tr>
                    <tr>
                      <th>영업담당자1</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="salesPerson1Name"
                          style="min-width:100px"
                          placeholder="영업담당자1 이름"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="salesPerson1Phone"
                          style="min-width:100px"
                          placeholder="영업담당자1 휴대폰"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="salesPerson1Email"
                          style="min-width:100px"
                          placeholder="영업담당자1 이메일"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>영업담당자2</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="salesPerson2Name"
                          style="min-width:100px"
                          placeholder="영업담당자2 이름"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="salesPerson2Phone"
                          style="min-width:100px"
                          placeholder="영업담당자2 휴대폰"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="salesPerson2Email"
                          style="min-width:100px"
                          placeholder="영업담당자2 이메일"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>정산담당자</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="accountantName"
                          style="min-width:100px"
                          placeholder="정산담당자 이름"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="accountantPhone"
                          style="min-width:100px"
                          placeholder="정산담당자 휴대폰"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="accountantEmail"
                          style="min-width:100px"
                          placeholder="정산담당자 이메일"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>사업자등록번호</th>
              <td colspan="2">
                  <input type="text" class="form-control" v-model="registrationNumber" />
              </td>
              <td colspan="3">
                <SingleImgUpload v-model="registrationNumberUrl" :downlabel="registrationname" :imgtype="imgtype.BRAND"/>
              </td>
            </tr>
            <tr>
              <th>통신판매업신고번호</th>
              <td colspan="2">
                  <input type="text" class="form-control" v-model="commBizNumber" />
              </td>
              <td colspan="3">
                <SingleImgUpload v-model="commBizNumberUrl" :downlabel="commBizNumbername" :imgtype="imgtype.BRAND"/>
              </td>
            </tr>
            <tr>
              <th>사이트 네임</th>
              <!-- <td colspan="2">https://partners.tablenjoy.com/</td> -->
              <td colspan="2">
                <input type="text" class="form-control" v-model="domain" @change="chkDomain" />
              </td>
              <td >
                <button type="button" class="btn btn-default" @click="checkDomain" style="margin-right: 10px">중복확인</button>
                <label :class="domaincheckclass">{{ domaincheckmsg }}</label>
              </td>
            </tr>
            <tr>
              <th>세일즈 담당자</th>
              <th>이름</th>
              <td>{{salesname}}</td>
              <th>이메일</th>
              <td>{{salesemail}}</td>
              <td>
                <button type="button" class="btn btn-default" @click="showSelMng">지정</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
      <button type="button" class="btn btn-default" @click="goBack">취소</button>
      <button type="button" class="btn btn-default"  style="margin-left:15px" @click="regNewBrand">저장</button>
    </div>
    </div>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">담당자 아이디 조회</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label for="inputEmail3" class="col-sm-2 control-label">아이디</label>
              <div class="col-sm-10">
                <div class="input-group">
                  <input type="email" class="form-control" id="inputEmail3" v-model="searchkey">
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" @click="searchadmin">검색</button>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-responsive regmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>선택</th>
                    <th>아이디</th>
                    <th>이름</th>
                    <th>이메일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in adminlist" v-bind:key="user.username">
                    <td>
                      <input type="radio" :value="user.username" name="seladmin" @click="selectadmin(user.username, user.fullName, user.fullName)"/>
                    </td>
                    <td>{{ user.username }}</td>
                    <td>{{ user.fullName }}</td>
                    <td>{{ user.fullName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closemodal">취소</button>
            <button type="button" class="btn btn-default" @click="applyadmin">저장</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </section>
</template>

<script>
import { rescode, menuval, imgtype } from "../../lib/consts";
import SingleImgUpload from "../../components/SingleImgUpload";
import { reqGet, reqPost } from "../../lib/request";
import { adminapi, brandapi } from "../../lib/api";
export default {
  components: {
    SingleImgUpload,
  },
  data() {
    return {
      tvalue: true,
      showmodal: false,
      brandName: "", //브랜드명
      brandcheckmsg: "",
      brandchkclass: "",
      brandType: "HOTEL", //분류명
      domain: "", //테이블엔조이 시스템에서 사용할 도메인명
      domaincheckmsg:"",
      domaincheckclass:"",
      domainregchk:false,
      corporationName: "", //법인명
      salesPerson1Name: "", //영업 담당자 1 이름
      salesPerson1Phone: "", //영업 담당자 1 전화번호
      salesPerson1Email: "", //영업 담당자 1 이메일
      salesPerson2Name: "", //영업 담당자 2 이름
      salesPerson2Phone: "", //영업 담당자 2 전화번호
      salesPerson2Email: "", //영업 담당자 2 이메일
      accountantName: "", //정산 담당자 이름
      accountantPhone: "", //정산 담당자 전화번호
      accountantEmail: "", //정산 담당자 이메일
      registrationNumber: "", //사업자 등록번호
      registrationNumberUrl: "", //사업자 등록번호 이미지 url
      registrationname: "registrationname.jpg",
      commBizNumber: "", //통신판매업 신고번호
      commBizNumberUrl: "", //통신판매업 신고번호 이미지 url
      commBizNumbername: "commBizNumbername.jpg",
      tnjManagerID: "", //테이블엔조이 담당자 ID
      salesname: "", //담당자 이름
      salesemail: "", //이메일

      tmptnjManagerID: "", //테이블엔조이 담당자 ID
      tmpsalesname: "", //담당자 이름
      tmpsalesemail: "", //이메일

      adminlist: [],
      searchkey: "",
      pageNumber: 1,
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    };
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV1_HOME,
      title: "브랜드 등록",
    });
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    },
    domain(){
      let regexpChk = /[^a-zA-Z0-9-]/g;
      let chkItem = regexpChk.test(this.domain)
      if( chkItem === true){
        this.domainregchk = false
      }
      return this.domain
    }
  },
  methods: {
    closemodal(){
      this.showmodal = false;
      this.tmptnjManagerID = ""; //테이블엔조이 담당자 ID
      this.tmpsalesname = ""; //담당자 이름
      this.tmpsalesemail = ""; //이메일
    },
    showSelMng(){
      this.showmodal = true;
    },
    searchadmin() {
      reqPost(
        adminapi.base,
        adminapi.approvedList,
        this.authToken,
        {
          keywordType: "Username",
          keyword: this.searchkey,
          pageNumber: this.pageNumber - 1,
          pageSize: 10,
        },
        (pagelist) => {
          if (pagelist != null) {
            if (pagelist.code == rescode.REQ_SUCCESS) {
              this.adminlist = pagelist.data.content;
              this.totalPages = pagelist.data.totalPages; //전체 페이지 수
              this.totalElements = pagelist.data.totalElements; //전체 아이템 수
              this.numberOfElements = pagelist.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.adminlist = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          } else {
            this.adminlist = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    selectadmin(admid, admname, admemail) {
      this.tmptnjManagerID = admid;
      this.tmpsalesname = admname;
      this.tmpsalesemail = admemail;
    },
    applyadmin() {
      this.showmodal = false;
      this.tnjManagerID = this.tmptnjManagerID;
      this.salesname = this.tmpsalesname;
      this.salesemail = this.tmpsalesemail;
    },
    checkBrandName() {
      if(this.brandName == null || this.brandName == "")
      {
        this.brandcheckmsg = "브랜드명을 입력하세요.";
        this.brandchkclass = "redmsg";
      }else{
        reqGet(
          brandapi.base,
          brandapi.brandexists + "?brandName=" + this.brandName,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              if (result.data == false) {
                this.brandcheckmsg = "사용할 수 있는 브랜드명입니다.";
                this.brandchkclass = "blurmsg";
              } else {
                this.brandcheckmsg = "사용할 수 없는 브랜드명입니다.";
                this.brandchkclass = "redmsg";
              }
            }
          }
        );
      }
    },
    checkDomain(){
      this.domainregchk = false
      if(this.domain == "")
      {
        alert("사이트 네임을 입력하세요.");
        return ;
      }
      let regexpChk = /[^a-zA-Z0-9-]/g;
      let chkItem = regexpChk.test(this.domain)
      if( chkItem === true){
        this.domaincheckmsg = "하이픈(-) 외 특수문자는 사용 불가합니다.";
        this.domaincheckclass = "redmsg";
        this.domainregchk = false
        return false;
      }else{
        reqGet(
          brandapi.base,
          brandapi.siteexists + "?domain=" + this.domain,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              if (result.data == false) {
                this.domaincheckmsg = "사용할 수 있는 사이트 네임명입니다.";
                this.domaincheckclass = "blurmsg";
                this.domainregchk = true
              } else {
                this.domaincheckmsg = "사용할 수 없는 사이트 네임명입니다.";
                this.domaincheckclass = "redmsg";

              }
            }
          }
        );
      }
    },
    chkDomain(){
      this.domainregchk = false;
    },
    regNewBrand(){
      if(this.brandName == "")
      {
        alert("브랜드명을 입력하세요.");
        return ;
      }
      if(this.brandType == "")
      {
        alert("브랜드 타입을 선택하세요.");
        return ;
      }
      if(this.corporationName == "")
      {
        alert("법인명을 입력하세요.");
        return ;
      }
      if(this.registrationNumber == "")
      {
        alert("사업자 등록번호를 입력하세요.");
        return ;
      }
      if(this.registrationNumberUrl == "")
      {
        alert("사업자 등록번호 이미지 url을 입력하세요.");
        return ;
      }
      // if(this.commBizNumber == "")
      // {
      //   alert("통신판매업 신고번호를 입력하세요.");
      //   return ;
      // }
      // if(this.commBizNumberUrl == "")
      // {
      //   alert("통신판매업 신고번호 이미지 url을 입력하세요.");
      //   return ;
      // }

      if(this.domain == "")
      {
        alert("테이블엔조이 시스템에서 사용할 사이트 네임명을 입력하세요.");
        return ;
      }
      let regexpChk = /[^a-zA-Z0-9-]/g;
      let chkItem = regexpChk.test(this.domain)
      if( chkItem === true){
        alert("도메인명은 하이픈(-) 외 특수문자는 사용 불가합니다.");
        return;
      }

      if(!this.domainregchk){
        alert("사이트 네임 중복확인은 필수입니다.");
        return;
      }

      this.$store.dispatch('setDisabledLayout');
      reqPost(
        brandapi.base,
        brandapi.brandregister,
        this.authToken,
        {
          brandName: this.brandName,
          brandType: this.brandType,
          domain:this.domain,
          logoUrl: this.logoUrl,
          salesPerson1Name: this.salesPerson1Name,
          salesPerson1Phone: this.salesPerson1Phone,
          salesPerson1Email: this.salesPerson1Email,
          salesPerson2Name: this.salesPerson2Name,
          salesPerson2Phone: this.salesPerson2Phone,
          salesPerson2Email: this.salesPerson2Email,
          accountantName: this.accountantName,
          accountantPhone: this.accountantPhone,
          accountantEmail: this.accountantEmail,
          tnjManagerID: this.tnjManagerID,
          corporationName: this.corporationName,
          registrationNumber: this.registrationNumber,
          registrationNumberUrl: this.registrationNumberUrl,
          commBizNumber: this.commBizNumber,
          commBizNumberUrl: this.commBizNumberUrl,
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.goBack();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    goBack()
    {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer{
  text-align: center;
}

.regmodal{
  height: 200px;
  overflow: scroll;
}

.redmsg {
  color: #ff0000;
}

.blurmsg {
  color: #0000ff;
}
</style>
