<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
          <DatePicker v-model="salesFrom" :last="salesTo"/>
          <h4 style="margin: 5px 10px">~</h4>
          <DatePicker v-model="salesTo" :first="salesFrom" style="margin-right: 10px"/>
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
            전월
          </button>
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
            당월
          </button>
          <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default pull-right" @click="TotaldownExcel" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <div class="table-responsive p-0" v-if="salesByChannels.length > 0">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th>구분</th>
                  <th>{{salesByChannels[0].salesOfTargetPeriod.salesFrom}} ~ {{salesByChannels[0].salesOfTargetPeriod.salesTo}}</th>
                  <th>점유율</th>
                  <th>{{salesByChannels[0].salesOfOneYearAgo.salesFrom}} ~ {{salesByChannels[0].salesOfOneYearAgo.salesTo}}</th>
                  <th>전년대비</th>
                  <th>{{salesByChannels[0].salesOfPrevPeriod.salesFrom}} ~ {{salesByChannels[0].salesOfPrevPeriod.salesTo}}</th>
                  <th>전월대비</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(chval, chidx) in salesByChannels" :key="chidx">
                  <td class="linkcmp" @click="setviewData(chval)" v-if="chval.channelCode !== 'selfSales'">{{chval.channelCode}}</td>
                  <td class="linkcmp" @click="setviewData(chval)" v-else>자체판매</td>
                  <td>{{chval.salesOfTargetPeriod.sales.salesAmount | numFormat}}</td>
                  <td>{{chval.salesStatistics.salesPercentage | numFormat}}%</td>
                  <td>{{chval.salesOfOneYearAgo.sales.salesAmount | numFormat}}</td>
                  <td>{{chval.salesStatistics.salesGrowthRateFromOneYearAgo | numFormat}}%</td>
                  <td>{{chval.salesOfPrevPeriod.sales.salesAmount | numFormat}}</td>
                  <td>{{chval.salesStatistics.salesGrowthRateFromPrevPeriod | numFormat}}%</td>
                </tr>
                <tr>
                  <td>계</td>
                  <td>{{salesTotal.salesOfTargetPeriod.sales.salesAmount | numFormat}}</td>
                  <td>{{salesTotal.salesStatistics.salesPercentage | numFormat}}%</td>
                  <td>{{salesTotal.salesOfOneYearAgo.sales.salesAmount | numFormat}}</td>
                  <td>{{salesTotal.salesStatistics.salesGrowthRateFromOneYearAgo | numFormat}}%</td>
                  <td>{{salesTotal.salesOfPrevPeriod.sales.salesAmount | numFormat}}</td>
                  <td>{{salesTotal.salesStatistics.salesGrowthRateFromPrevPeriod | numFormat}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px" v-if="viewChannel != null">
      <div class="form-group">
        <span>상세 내역</span>
        <button type="button" class="btn btn-default pull-right" @click="DetaildownExcel" :disabled="buttonDissabled">
          <i class="fa fa-download"></i>Excel
        </button>
      </div>
    </div>
    <div class="box box-solid" v-if="viewChannel != null">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th>구분</th>
                  <th>{{viewChannel.salesOfTargetPeriod.salesFrom}} ~ {{viewChannel.salesOfTargetPeriod.salesTo}}</th>
                  <th>점유율</th>
                  <th>{{viewChannel.salesOfOneYearAgo.salesFrom}} ~ {{viewChannel.salesOfOneYearAgo.salesTo}}</th>
                  <th>전년대비</th>
                  <th>{{viewChannel.salesOfPrevPeriod.salesFrom}} ~ {{viewChannel.salesOfPrevPeriod.salesTo}}</th>
                  <th>전월대비</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dayinf, didx) in viewChannel.salesByDates" :key="didx">
                  <td> {{ viewDate2(dayinf.dayOfTargetPeriod.salesDate) }} </td>
                  <td>{{dayinf.dayOfTargetPeriod.sales.salesAmount | numFormat}}</td>
                  <td>{{dayinf.salesStatistics.salesPercentage | numFormat}}%</td>
                  <td>{{dayinf.dayOfOneYearAgo.sales.salesAmount | numFormat}}</td>
                  <td>{{dayinf.salesStatistics.salesGrowthRateFromOneYearAgo | numFormat}}%</td>
                  <td>{{dayinf.dayOfPrevPeriod.sales.salesAmount | numFormat}}</td>
                  <td>{{dayinf.salesStatistics.salesGrowthRateFromPrevPeriod | numFormat}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import { reqPost } from "../../../lib/request";
import { orderapi } from "../../../lib/api";
import { DateFormat } from "../../../lib/utility";
import DatePicker from "../../../components/DatePicker";
import moment from 'moment';
import XLSX from "xlsx";
export default {
  components: {
    DatePicker,
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_STAT, path2:menuval.LV2_STT_USE, title:"사용현황 채널별 (기간)"});
  },
  data() {
    return {
      salesFrom:moment().format("YYYY-MM-01"),
      salesTo:moment().format("YYYY-MM-DD"),
      salesByChannels:[],
      /**
       * channelType                              String      [Tablenjoy, B2bIframe, B2bChannel]    채널타입
       * channelCode                              String      B2B 채널코드. channelType 이 B2bIframe, B2bChannel 인 경우 값이 입력됨.
       * salesStatistics                          Object      기간 통계
       * salesStatistics.salesPercentage                      String      매출 점유율
       * salesStatistics.salesGrowthRateFromPrevPeriod        String    이전 기간 대비 매출 성장률
       * salesStatistics.salesGrowthRateFromOneYearAgo        String    전년 대비 매출 성장률
       * salesOfTargetPeriod                      Object      조회 대상 기간 매출 집계
       * salesOfTargetPeriod.salesFrom                        String    yyyy-MM-dd    매출 집계 시작일
       * salesOfTargetPeriod.salesTo                          String    yyyy-MM-dd    매출 집계 종료일
       * salesOfTargetPeriod.sales.salesAmount                String    매출액
       * salesOfPrevPeriod                        Object      이전 기간 매출 집계
       * salesOfPrevPeriod.salesFrom                          String    yyyy-MM-dd    매출 집계 시작일
       * salesOfPrevPeriod.salesTo                            String    yyyy-MM-dd    매출 집계 종료일
       * salesOfPrevPeriod.sales.salesAmount                  String    매출액
       * salesOfOneYearAgo                        Object      전년 매출 집계
       * salesOfOneYearAgo.salesFrom                          String    yyyy-MM-dd 매출 집계 시작일
       * salesOfOneYearAgo.salesTo                            String    yyyy-MM-dd 매출 집계 종료일
       * salesOfOneYearAgo.sales.salesAmount                  String    매출액
       * salesByDates                             Array       일별 매출 집계
       * salesByDates[].dayOffsetFromStartDay                 Number    조회 시작일 기준 날짜 Offset. 0 부터 시작
       * salesByDates[].salesStatistics                       Object    일별 통계
       * salesByDates[].salesStatistics.salesPercentage       String    매출 점유율
       * salesByDates[].salesStatistics.salesGrowthRateFromPrevPeriod       String    이전 기간 대비 매출 성장률
       * salesByDates[].salesStatistics.salesGrowthRateFromOneYearAgo       String    전년 대비 매출 성장률
       * salesByDates[].dayOfTargetPeriod                     Object    조회 대상 기간 일별 매출 집계
       * salesByDates[].dayOfTargetPeriod.salesDate                         String    yyyy-MM-dd 날짜
       * salesByDates[].dayOfTargetPeriod.sales.salesAmount                 String    매출액
       * salesByDates[].dayOfPrevPeriod                       Object    이전 기간 일별 매출 집계
       * salesByDates[].dayOfPrevPeriod.salesDate                           String    yyyy-MM-dd 날짜
       * salesByDates[].dayOfPrevPeriod.sales.salesAmount                   String    매출액
       * salesByDates[].dayOfOneYearAgo                       Object    전년 일별 매출 집계
       * salesByDates[].dayOfOneYearAgo.salesDate                           String    yyyy-MM-dd 날짜
       * salesByDates[].dayOfOneYearAgo.sales.salesAmount                   String    매출액
       */
      salesTotal:{
        salesStatistics:{//기간 통계
          salesPercentage:"",//매출 점유율
          salesGrowthRateFromPrevPeriod:"",//이전 기간 대비 매출 성장률
          salesGrowthRateFromOneYearAgo:"",//전년 대비 매출 성장률
        },
        salesOfTargetPeriod:{//조회 대상 기간 매출 집계
          salesFrom:"",//yyyy-MM-dd 매출 집계 시작일
          salesTo:"",//yyyy-MM-dd 매출 집계 종료일
          sales:{
            salesAmount:"",//매출액
          }
        },
        salesOfPrevPeriod:{//이전 기간 매출 집계
          salesFrom:"",//yyyy-MM-dd 매출 집계 시작일
          salesTo:"",//yyyy-MM-dd 매출 집계 종료일
          sales:{
            salesAmount:"",//매출액
          }
        },
        salesOfOneYearAgo:{//전년 매출 집계
          salesFrom:"",//yyyy-MM-dd 매출 집계 시작일
          salesTo:"",//yyyy-MM-dd 매출 집계 종료일
          sales:{
            salesAmount:"",//매출액
          }
        },
        salesByDates:[],//일별 매출 집계
        /**
         * dayOffsetFromStartDay              Number    조회 시작일 기준 날짜 Offset. 0 부터 시작
         * salesStatistics                    Object    일별 통계
         * salesStatistics.salesPercentage                    String    매출 점유율
         * salesStatistics.salesGrowthRateFromPrevPeriod      String    이전 기간 대비 매출 성장률
         * salesStatistics.salesGrowthRateFromOneYearAgo      String    전년 대비 매출 성장률
         * dayOfTargetPeriod                  Object    조회 대상 기간 일별 매출 집계
         * dayOfTargetPeriod.salesDate                        String    yyyy-MM-dd 날짜
         * dayOfTargetPeriod.sales.salesAmount                String    매출액
         * dayOfPrevPeriod                    Object    이전 기간 일별 매출 집계
         * dayOfPrevPeriod.salesDate                          String    yyyy-MM-dd 날짜
         * dayOfPrevPeriod.sales.salesAmount                  String    매출액
         * dayOfOneYearAgo                    Object    전년 일별 매출 집계
         * dayOfOneYearAgo.salesDate                          String    yyyy-MM-dd 날짜
         * dayOfOneYearAgo.sales.salesAmount                  String    매출액
         */
      },
      viewChannel:null,
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    viewDate2(val) {
      return DateFormat(val, "YYYY-MM-DD", true);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    onPrvMonth()
    {
      this.salesFrom = moment().subtract(1,'months').format('YYYY-MM-01');
      this.salesTo = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
    },
    onNowMonth()
    {
      this.salesFrom = moment().format("YYYY-MM-01");
      this.salesTo = moment().format("YYYY-MM-DD");
    },
    refreshPage() {
      reqPost(
        orderapi.base,
        orderapi.totalizeConsumedSaleschnl,
        this.authToken,
        {
          salesFrom:this.salesFrom,
          salesTo:this.salesTo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.viewChannel = null;
              this.salesByChannels = result.data.salesByChannels;
              this.salesTotal = result.data.salesTotal;
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    setviewData(chval)
    {
      this.viewChannel = chval;
    },
    totalconvert(chval) {
      return {
        0: chval.channelCode === "selfSales" ? '자체판매':chval.channelCode,
        1: chval.salesOfTargetPeriod.sales.salesAmount,
        2: chval.salesStatistics.salesPercentage,
        3: chval.salesOfOneYearAgo.sales.salesAmount,
        4: chval.salesStatistics.salesGrowthRateFromOneYearAgo,
        5: chval.salesOfPrevPeriod.sales.salesAmount,
        6: chval.salesStatistics.salesGrowthRateFromPrevPeriod,
      };
    },
    TotaldownExcel() {
      this.buttonDissabled = true;
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            0: "구분",
            1: this.salesByChannels[0].salesOfTargetPeriod.salesFrom + " ~ " + this.salesByChannels[0].salesOfTargetPeriod.salesTo,
            2: "점유율",
            3: this.salesByChannels[0].salesOfOneYearAgo.salesFrom + " ~ " + this.salesByChannels[0].salesOfOneYearAgo.salesTo,
            4: "전년대비",
            5: this.salesByChannels[0].salesOfPrevPeriod.salesFrom + " ~ " + this.salesByChannels[0].salesOfPrevPeriod.salesTo,
            6: "전월대비",
          },
          {
            0: "계",
            1: this.salesTotal.salesOfTargetPeriod.sales.salesAmount,
            2: this.salesTotal.salesStatistics.salesPercentage,
            3: this.salesTotal.salesOfOneYearAgo.sales.salesAmount,
            4: this.salesTotal.salesStatistics.salesGrowthRateFromOneYearAgo,
            5: this.salesTotal.salesOfPrevPeriod.sales.salesAmount,
            6: this.salesTotal.salesStatistics.salesGrowthRateFromPrevPeriod,
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(excelheader, this.salesByChannels.map(this.totalconvert), {
        skipHeader: true,
        origin: "A3",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      var subtxt = "채널별-기간";
      XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
      // export Excel file
      XLSX.writeFile(wb, "사용현황조회-"+subtxt+".xlsx");
      this.buttonDissabled = false;
    },
    detailconvert(dayinf) {
      return {
        0: this.viewDate2(dayinf.dayOfTargetPeriod.salesDate),
        1: dayinf.dayOfTargetPeriod.sales.salesAmount,
        2: dayinf.salesStatistics.salesPercentage,
        3: dayinf.dayOfOneYearAgo.sales.salesAmount,
        4: dayinf.salesStatistics.salesGrowthRateFromOneYearAgo,
        5: dayinf.dayOfPrevPeriod.sales.salesAmount,
        6: dayinf.salesStatistics.salesGrowthRateFromPrevPeriod,
      };
    },
    DetaildownExcel() {
      this.buttonDissabled = true;
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            0: "구분",
            1: this.salesByChannels[0].salesOfTargetPeriod.salesFrom + " ~ " + this.salesByChannels[0].salesOfTargetPeriod.salesTo,
            2: "점유율",
            3: this.salesByChannels[0].salesOfOneYearAgo.salesFrom + " ~ " + this.salesByChannels[0].salesOfOneYearAgo.salesTo,
            4: "전년대비",
            5: this.salesByChannels[0].salesOfPrevPeriod.salesFrom + " ~ " + this.salesByChannels[0].salesOfPrevPeriod.salesTo,
            6: "전월대비",
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(excelheader, this.viewChannel.salesByDates.map(this.detailconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      var subtxt = "채널별-기간(상세)";
      XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
      // export Excel file
      XLSX.writeFile(wb, "사용현황조회-"+subtxt+".xlsx");
      this.buttonDissabled = false;
    },
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
th {
  text-align: center;
}
td {
  text-align: center;
}
</style>
