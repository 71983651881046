<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <div class="input-group radio">
            <label>
              <input type="radio" :value="null" v-model="completed"/>전체
            </label>
            <label>
              <input type="radio" :value="false" v-model="completed"/>미처리
            </label>
            <label>
              <input type="radio" :value="true" v-model="completed"/>처리완료
            </label>
          </div>
          <DatePicker v-model="fromDate" :last="toDate"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DatePicker v-model="toDate" :first="fromDate" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="PhoneNumber">전화번호</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" style="flex:1" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th class="text-center">번호</th>
              <th class="text-center">연락번호</th>
              <th class="text-center">희망시간</th>
              <th class="text-center">신청일</th>
              <th class="text-center">처리자</th>
              <th class="text-center">조회</th>
              <th class="text-center">상담내역</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(phone, index) in content" :key="phone.requestNo">
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{phoneview(phone.phoneNumber)}}</td>
              <td class="text-center">{{phone.preferableTimeFrom}} ~ {{phone.preferableTimeTo}}</td>
              <td class="text-center">{{viewDate(phone.registeredDate)}}</td>
              <td class="text-center">{{phone.completedBy}}</td>
              <td class="text-center">
                <button v-if="phone.requestStatus == 'Requested'" type="button" class="btn btn-default btn-xs" @click="doComplete(phone.requestNo)">상담완료</button>
                <button v-if="phone.requestStatus == 'Requested'" type="button" class="btn btn-default btn-xs" @click="doReject(phone.requestNo)">상담불가</button>
                <span v-if="phone.requestStatus == 'Failed'">상담불가</span>
                <span v-if="phone.requestStatus == 'Completed'">상담완료</span>
              </td>
              <td class="text-center">
                <button type="button" class="btn btn-social-icon btn-contact" @click="showEdit(phone.requestNo, phone.memo)"></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="editdlg && {'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">메모입력</div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="margin:0px;">
              <textarea class="form-control" rows="6" v-model="hismemo" disabled></textarea>
            </div>
            <div class="form-group" style="margin:0px;">
              <textarea class="form-control" rows="2" v-model="memo"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeEdit">취소</button>
            <button type="button" class="btn btn-default" @click="doAppendMemo">확인</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import { reqPost } from "../../lib/request";
import { board } from "../../lib/api";
import { DateFormat, formatPhone } from "../../lib/utility";
import DatePicker from "../../components/DatePicker";
import moment from 'moment';
export default {
  components: {
    Pagination,
    DatePicker,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.Tele_keywordType) {
        this.keywordType = localStorage.Tele_keywordType;
      }
      if (localStorage.Tele_keyword) {
        this.keyword = localStorage.Tele_keyword;
      }
      if (localStorage.Tele_Number) {
        this.pageNumber = parseInt(localStorage.Tele_Number);
      }
      if (localStorage.Tele_Size) {
        this.pageSize = parseInt(localStorage.Tele_Size);
      }
      if (localStorage.Tele_fromDate) {
        this.fromDate = localStorage.Tele_fromDate;
      }
      if (localStorage.Tele_toDate) {
        this.toDate = localStorage.Tele_toDate;
      }
    }
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_BOD, path2:menuval.LV1_HOME, title:"전화상담 접수관리"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.Tele_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.Tele_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.Tele_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.Tele_Size = newsize;
    },
    fromDate(nfrom)
    {
      localStorage.Tele_fromDate = nfrom;
    },
    toDate(nto)
    {
      localStorage.Tele_toDate = nto;
    }
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      fromDate:moment().format("YYYY-01-01"),
      toDate:moment().format("YYYY-MM-DD"),
      completed:null,
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * requestNo              Number    요청 번호
       * requestStatus          String    [Requested, Failed, Completed]  요청 처리 상태  Requested:요청함, Failed:전화연결실패, Completed:처리완료
       * phoneNumber            String    전화번호
       * preferableTimeFrom     String    HH:mm:ss  전화연결 희망 시작 시간 (null일 경우 ASAP 연결 희망)
       * preferableTimeTo       String    HH:mm:ss  전화연결 희망 끝 시간 (null일 경우 ASAP 연결 희망)
       * registeredBy           String    신청인 ID
       * registeredDate         String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * completed              Boolean   요청처리 완료 여부
       * completedBy            String    요청처리 관리자 ID
       * completedDate          String    UTC yyyy-MM-ddTHH:mm:ss 요청처리 완료일
       * memo                   String    관리자 메모
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      editdlg: false,
      hismemo:"",
      selrequestNo:0,
      memo:"",
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.Tele_keywordType = this.keywordType;
      localStorage.Tele_keyword = this.keyword;
      localStorage.Tele_Number = this.pageNumber;
      localStorage.Tele_Size = this.pageSize;
      localStorage.Tele_fromDate = this.fromDate;
      localStorage.Tele_toDate = this.toDate;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqPost(
        board.base,
        board.call_page,
        this.authToken,
        {
          keywordType:this.keywordType,
          keyword:this.keyword,
          completed:this.completed,
          fromDate:this.fromDate,
          toDate:this.toDate,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    downExcel() {},
    closeEdit() {
      this.editdlg = false;
    },
    showEdit(requestNo, memo) {
      this.hismemo = memo;
      this.selrequestNo = requestNo;
      this.memo = "";
      this.editdlg = true;
    },
    phoneview(val){
      return formatPhone(val);
    },
    doReject(requestNo)
    {
      reqPost(
        board.base,
        board.call_complete,
        this.authToken,
        {
          requestNo:requestNo,
          failed:true
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doComplete(requestNo)
    {
      reqPost(
        board.base,
        board.call_complete,
        this.authToken,
        {
          requestNo:requestNo,
          failed:false
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doAppendMemo()
    {
      reqPost(
        board.base,
        board.call_appendMemo,
        this.authToken,
        {
          requestNo:this.selrequestNo,
          memo:this.memo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeEdit();
              this.memo = "";
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio{
  margin-bottom: 0px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.modal-body .control-label{
  margin:0px;
}

.btn-contact{
  background-image: url("/static/img/icon/ic_contact_able.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.modal .table{
  margin-bottom: 0px;
}
</style>
