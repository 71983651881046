import {adminapi} from '../lib/api'
import {reqPost} from '../lib/request'
import {cookielbl} from '../lib/consts'
import router from '../router'
export default {
    doSetPath({ commit }, payload){
        commit('SET_PATH', {...payload, curpath:router.currentRoute.path.substring(1)});
    },
    doResetToken({ commit }, payload){
        commit('SET_TOKEN', {...payload});
    },
    dologin({ commit }, payload) {
        commit('SET_AUTH', payload);
    },
    dologout({ commit, state }, callback) {
        reqPost(adminapi.base, adminapi.logout, state.token, {}, ()=>{

            commit('SET_AUTH', {token:null, expiresIn:0, expiresAt:0, refresh:null, refreshexpiresIn:0, refreshexpiresAt:0, userid:null, userRole:null});
            // window.localStorage.setItem(cookielbl.authinf, null);
            window.localStorage.removeItem(cookielbl.authinf);
            callback(true);
        });
    },
    clearcookie({ commit }, callback) {
        callback(true);
    },

  setDisabledLayout({ commit,getters}) {
    commit('disabledLayout', !getters.disabledLayout);
  },

}
