 <template>
     <section class="content">
         <div class="box box-solid">
             <div class="box-body">
                 <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                     <select class="form-control" style="width:auto;margin-right:10px" v-model="dateType">
                         <option value="IssuedDate">발행일</option>
                         <!-- <option value="ConsumedDate">사용일</option> -->
                         <option value="CancelledDate">취소일</option>
                     </select>
                     <DetePicker v-model="dateFrom" :last="dateTo" />
                     <h4 style="margin:5px 10px;">~</h4>
                     <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px" />
                     <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
                        <option value="NoKeyword"></option>
                        <option value="OrderNo">주문번호</option>
                        <option value="B2bOrderNo">B2B 주문번호</option>
                        <option value="ItemNo">쿠폰번호</option>
                        <option value="BuyerPhoneNumber">구매자 전화번호</option>
                        <option value="ReceiverPhoneNumber">수신자 전화번호</option>
                        <option value="BuyerPhoneNumber4">구매자 전화번호 끝 4자리</option>
                        <option value="ReceiverPhoneNumber4">수신자 전화번호 끝 4자리</option>
                        <option value="Username">사용자 ID</option>
                        <option value="BuyerName">구매자 이름</option>
                        <option value="ReceiverName">수신자 이름</option>
                        <option value="B2bOrderNo">B2B주문번호</option>
                        <option value="Channel">주문경로</option>
                        <option value="GoodsNameContaining">상품명</option>

                     </select>
                     <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                         <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                         <span class="input-group-btn">
                             <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                         </span>
                     </div>
                 </div>
             </div>
         </div>
         <div class="row" style="margin:0px;margin-bottom:20px">
             <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
                 <option>5</option>
                 <option>10</option>
                 <option>15</option>
                 <option>20</option>
                 <option>25</option>
                 <option>30</option>
                 <option>35</option>
                 <option>40</option>
                 <option>45</option>
             </select>
             <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="buttonDissabled">
                 <i class="fa fa-download"></i>Excel
             </button>
         </div>
         <div class="box box-solid">
             <div class="box-body table-responsive p-0">
                 <table class="table table-hover text-nowrap">
                     <thead>
                         <tr>
                             <th class="text-center">번호</th>
                             <th class="text-center">주문번호</th>
                             <th>쿠폰번호</th>
                             <th>상품명</th>
                             <th>옵션명</th>
                             <th class="text-center">정상가</th>
                             <th class="text-center">판매가</th>
                             <th class="text-center">구매자</th>
                             <th class="text-center">발행일</th>
                             <th class="text-center">취소일</th>
                         </tr>
                     </thead>
                     <tbody>
                         <tr v-for="(citem, index) in content" v-bind:key="citem.itemNo + '_' + index">
                             <td class="text-center">{{ totalElements - (startnum + index) }}</td>
                             <td class="linkcmp text-center" @click="goDetailViewPage(citem.orderInfo.orderNo)">
                                 {{ citem.orderInfo.orderNo }}
                             </td>
                             <td>{{ citem.itemNo }}</td>
                             <td>{{ citem.goodsName }}</td>
                             <td>{{ citem.optionName }}</td>
                             <td class="text-center">{{ citem.listPrice | numFormat }}</td>
                             <td class="text-center">{{ citem.salePrice | numFormat }}</td>
                             <td class="text-center">{{ citem.orderInfo.buyerName }}</td>
                             <td class="text-center">{{ viewDate(citem.issueDate) }}</td>
                             <td class="text-center">{{ viewDate(citem.cancelledDate) }}</td>
                         </tr>
                     </tbody>
                 </table>
             </div>
             <div class="box-footer text-center">
                 <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
             </div>
         </div>
     </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import DetePicker from "../../../components/DatePicker";
import { reqPost } from "../../../lib/request";
import { orderapi } from "../../../lib/api";
import { DateFormat } from "../../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";

export default {
    components: {
        Pagination,
        DetePicker,
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.CNCLC_keywordType) {
                this.keywordType = localStorage.CNCLC_keywordType;
            }
            if (localStorage.CNCLC_keyword) {
                this.keyword = localStorage.CNCLC_keyword;
            }
            if (localStorage.CNCLC_Number) {
                this.pageNumber = parseInt(localStorage.CNCLC_Number);
            }
            if (localStorage.CNCLC_Size) {
                this.pageSize = parseInt(localStorage.CNCLC_Size);
            }
            if (localStorage.CNCLC_dateType) {
                this.dateType = localStorage.CNCLC_dateType;
            }
            if (localStorage.CNCLC_dateFrom) {
                this.dateFrom = localStorage.CNCLC_dateFrom;
            }
            if (localStorage.CNCLC_dateTo) {
                this.dateTo = localStorage.CNCLC_dateTo;
            }
        }
        this.$store.dispatch('doSetPath', { path1: menuval.LV1_ISSUE, path2: menuval.LV2_ISS_CCL, title: "매장예약" });
    },
    watch: {
        keywordType(newVal) {
            localStorage.CNCLC_keywordType = newVal;
        },
        keyword(newkey) {
            localStorage.CNCLC_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.CNCLC_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.CNCLC_Size = newsize;
        },
        dateType(newType) {
            localStorage.CNCLC_dateType = newType;
        },
        dateFrom(newfrom) {
            localStorage.CNCLC_dateFrom = newfrom;
        },
        dateTo(newto) {
            localStorage.CNCLC_dateTo = newto;
        }
    },
    data() {
        return {
            shownum: 10, //pagination에 보여지는 번호개수
            dateType: "IssuedDate",
            dateFrom: moment().format("YYYY-MM-01"),
            dateTo: moment().format("YYYY-MM-DD"),
            keywordType: "NoKeyword",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            /**
             * itemNo                   String  쿠폰번호
             * itemStatus               String  [Available, Invalidated, CancelRequested, Cancelled, BookingConnected, Locked, Consumed]
             *            Available: 사용가능, CancelRequested: 취소요청됨, Cancelled: 취소됨, BookingConnected: 예약에 연결됨, Locked: 사용불가처리됨, Consumed: 사용완료
             * goodsType                String  [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
             *            MobileCoupon: 예약권, BookingCoupon: 예약권, Booking: 예약, MoneyCoupon: 금액권, CashCoupon: 캐시권
             * goodsId                  Number  상품 ID
             * goodsName                String  상품명
             * optionId                 Number  옵션 ID
             * optionName               String  옵션명
             * issueDate                String  UTC yyyy-MM-ddTHH:mm:ss   발행일
             * cancelledDate            String  UTC yyyy-MM-ddTHH:mm:ss   취소일
             * receiverName             String  수신자 이름
             * receiverPhone            String  수신자 전화번호
             * listPrice                String  정상가
             * salePrice                String  판매가
             * currencyCode             String  [USD, KRW] 통화코드
             * orderInfo.orderNo        String  주문번호
             * orderInfo.username       String  사용자 ID
             * orderInfo.siteName       String  구매 사이트명
             * orderInfo.buyerName      String  구매자 이름
             * orderInfo.buyerPhone     String  구매자 전화번호
             * orderInfo.purchaseDate   String  UTC yyyy-MM-ddTHH:mm:ss  구매일자
             * orderInfo.orderStatus    String  [Purchased, CancelRequested, CancelFailed, Cancelled] 주문상태
             *            Purchased: 주문완료,CancelRequested: 주문취소 요청됨, CancelFailed: 주문취소 실패, Cancelled: 주문취소됨
             */
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true

            exceldata: [],
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.CNCLC_keywordType = this.keywordType;
            localStorage.CNCLC_keyword = this.keyword;
            localStorage.CNCLC_Number = this.pageNumber;
            localStorage.CNCLC_Size = this.pageSize;
            localStorage.CNCLC_dateType = this.dateType;
            localStorage.CNCLC_dateFrom = this.dateFrom;
            localStorage.CNCLC_dateTo = this.dateTo;
        }
        this.refreshPage();
    },
    methods: {
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }

            var pageNumber = this.pageNumber - 1;
            reqPost(
                orderapi.base,
                orderapi.storeCanellPage,
                this.authToken,
                {
                    dateType: this.dateType,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    keywordType: this.keywordType,
                    keyword: this.keyword,
                    pageNumber: pageNumber,
                    pageSize: this.pageSize
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                            alert(result.message);
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goDetailViewPage(orderNo) {
            this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
        },
        getExcelData() {
          this.buttonDissabled = true;
            reqPost(
                orderapi.base,
                orderapi.storeCanellList,
                this.authToken,
                {
                    dateType: this.dateType,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    keywordType: this.keywordType,
                    keyword: this.keyword,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.exceldata = result.data;
                            this.downExcel();
                        } else {
                            this.exceldata = [];
                          this.buttonDissabled = false;
                            alert(result.message);
                        }
                    }
                }
            );
        },
        dataconvert(order) {
            let salesType = '';
            if (order.orderInfo.channelType === 'Tablenjoy' && order.orderInfo.siteName !== 'tablenjoy') {
                salesType = '자체판매';
            } else {
                salesType = '';
            }
            return {
                A: order.orderInfo.orderNo,
                B: order.itemNo,
                C: order.goodsName,
                D: order.optionName,
                E: order.listPrice,
                F: order.salePrice,
                G: order.orderInfo.buyerName,
                H: this.viewDate(order.issueDate),
                I: this.viewDate(order.cancelledDate),
                J: salesType,
                K: !order.orderInfo.b2bChannelCode ? '' : order.orderInfo.b2bChannelCode,
            };
        },
        downExcel() {
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    {
                        A: "주문번호",
                        B: "쿠폰번호",
                        C: "상품명",
                        D: "옵션명",
                        E: "정상가",
                        F: "판매가",
                        G: "구매자",
                        H: "발행일",
                        I: "취소일",
                        J: '자체판매',
                        K: '주문경로',
                    },
                ],
                { skipHeader: true }
            );
            XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "발급취소내역-매장예약");
            // export Excel file
            XLSX.writeFile(wb, "발급취소내역-매장예약.xlsx");
            this.buttonDissabled = false;
        },
    },
};
</script>
<style scoped>
.form-group {
    margin-left: 0px;
    margin-right: 0px;
}
</style>
