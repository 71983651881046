<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
          <div class="radio" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="" v-model="requestType"/>전체
            </label>
            <label>
              <input type="radio" value="Register" v-model="requestType"/>신규등록
            </label>
            <label>
              <input type="radio" value="Update" v-model="requestType"/>정보수정
            </label>
            <label>
              <input type="radio" value="Pause" v-model="requestType"/>판매종료
            </label>
            <label>
              <input type="radio" value="Resume" v-model="requestType"/>판매재개
            </label>
          </div>
          <select class="form-control" v-model="channelCode" @change="getchannelInfo" style="width:auto;margin-right:10px">
            <option v-for="chitem in channels" :key="chitem.channelCode" :value="chitem.channelCode">{{chitem.channelName}}</option>
          </select>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsId">상품코드</option>
            <option value="GoodsNameContaining">상품명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default" @click="dofinish">배포완료</button>
      <button v-if="channelCode == 'TMON'" type="button" class="btn btn-default" style="margin-left:10px" @click="openPauseModal">판매중지</button>
      <select v-if="channelpubInfo.goodsDistributeBatch" class="form-control pull-right" style="width: 150px;" v-model="dplymethod" @change="selectmethod">
        <template v-if="channelCode != 'TMON'">
          <option value="match">코드매칭</option>
        </template>
        <template v-if="channelCode == 'TMON'">
          <option value="create">등록</option>
          <option value="modify">수정</option>
          <option value="recreate">재등록</option>
          <option value="pause">판매중지</option>
        </template>
        <option value="down">양식 다운로드</option>
      </select>
      <button v-if="channelpubInfo.channelImageManage" type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="showimgmodal">
        채널 전용 이미지 생성
      </button>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="distributionExcel">
        <i class="fa fa-download"></i> Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>
                <input type="checkbox" class="allch" @change="allckech($event)"/>
              </th>
              <th class="text-center">상품코드</th>
              <th>상품명</th>
              <th class="text-center">상품상태</th>
              <th class="text-center">요청타입</th>
              <th class="text-center">요청일</th>
              <th class="text-center">메모</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dist in distributions" v-bind:key="dist.goodsId">
              <td>
                <input type="checkbox" class="chitem" :value="dist.goodsId"/>
              </td>
              <td class="text-center">{{dist.goodsId}}</td>
              <td class="linkcmp" @click="gochannelprodreg(dist.goodsId)">{{dist.goodsName}}</td>
              <td class="text-center">{{getSaleState(dist.onSale)}}</td>
              <td class="text-center">{{getReqType(dist.requestType)}}</td>
              <td class="text-center">{{viewDate(dist.requestDate)}}</td>
              <td class="text-center">
                  <button type="button" class="btn btn-default" @click="openMemo(dist.goodsId)" >상세</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal" :style="dplymodal && {'display': 'block', 'z-index':'3000'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closedplymodal"><span aria-hidden="true">×</span></button>
            <h4 class="modal-title">일괄배포</h4>
          </div>
          <div class="modal-body">
            <div class="input-group">
              <input type="text" class="form-control" v-model="selfilename" disabled/>
              <span class="input-group-btn">
                <button class="btn btn-default" type="button" @click="selectExcel">파일선택</button>
              </span>
              <input type="file" ref="fileInput" style="display:none;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12" @change="onUploadFiles" title="파일선택" alt="파일선택"/>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" :value="true" v-model="distributionComplete">자동 배포완료 처리
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="doDistribution">{{getDplyName()}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="pausemodal" :style="{'display': 'block', 'z-index':'3000'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body form-horizontal">
            <h4>판매중지 처리 하시겠습니까?</h4>
            <div class="form-group" style="margin:0px">
              <label><input type="checkbox" :value="true" v-model="distributionComplete" style="margin-right:5px"/>자동 배포완료 처리</label>
            </div>
          </div>
          <div class="modal-footer" style="text-align: center;">
            <button type="button" class="btn btn-default" @click="closePauseModal">취소</button>
            <button type="button" class="btn btn-default" @click="batchPause">확인</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="pauseresultmodal" :style="{'display': 'block', 'z-index':'3000'}">
      <div class="modal-dialog min-dialog">
        <div class="modal-content">
          <div class="modal-body form-horizontal">
            <h4>판매중지 완료</h4>
            <div v-for="(ritem, ridx) in pauseResultList" :key="ridx"><label>{{ ritem.goodsId }} –
              {{ ritem.resultMessage }}</label></div>
          </div>
          <div class="modal-footer" style="text-align: center;">
            <button type="button" class="btn btn-default" @click="closePauseResultModal">확인</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="downexcel" :style="{'display': 'block', 'z-index':'3000'}">
      <div class="modal-dialog excelmodal">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4 class="modal-title">일괄배포 완료 [ 성공 {{getSuccGoodsLen()}}건 / {{getGoodsLen()}}건 ]</h4>
          </div>
          <div class="modal-body">
            <button class="btn btn-block btn-default btn-xs" type="button" @click="resultexcel">배포결과 Excel 다운로드</button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closedownexcelmodal">확인</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="imgmodal" :style="{'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeimgmodal"><span aria-hidden="true">×</span></button>
            <h4 class="modal-title">채널 전용 이미지 생성</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>취소/환불 규정</h5>
            <div class="form-group">
              <label class="col-sm-2 control-label"><input type="checkbox" :value="true" v-model="mobileCoupon.checkval"/>쿠폰</label>
              <div class="col-sm-10">
                <button type="button" v-if="mobileCoupon.refundInfoImageUrl != ''" class="btn btn-default btn-xs pull-right" @click="onPreviewClick(mobileCoupon.refundInfoImageUrl)">채널 전용 이미지 보기</button>
                <textarea class="form-control" rows="3" placeholder="쿠폰 취소/환불 규정" v-model="mobileCoupon.refundInfoContents"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label"><input type="checkbox" :value="true" v-model="moneyCoupon.checkval"/>금액권</label>
              <div class="col-sm-10">
                <button type="button" v-if="moneyCoupon.refundInfoImageUrl != ''" class="btn btn-default btn-xs pull-right" @click="onPreviewClick(moneyCoupon.refundInfoImageUrl)">채널 전용 이미지 보기</button>
                <textarea class="form-control" rows="3" placeholder="쿠폰 취소/환불 규정" v-model="moneyCoupon.refundInfoContents"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label"><input type="checkbox" :value="true" v-model="bookingCoupon.checkval"/>예약필수쿠폰</label>
              <div class="col-sm-10">
                <button type="button" v-if="bookingCoupon.refundInfoImageUrl != ''" class="btn btn-default btn-xs pull-right" @click="onPreviewClick(bookingCoupon.refundInfoImageUrl)">채널 전용 이미지 보기</button>
                <textarea class="form-control" rows="3" placeholder="쿠폰 취소/환불 규정" v-model="bookingCoupon.refundInfoContents"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="printuage(0)">이미지 생성</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" :style="print_usage && {'display': 'block', 'z-index':'3000'}">
      <div class="modal-dialog" style="width: 732px;">
        <div class="modal-content" >
          <div class="modal-body" style="padding:10px 0px;">
            <div class="use_info_contents" >
              <div class="b2b_info_text" id="pringusage_0">
                ㆍ취소/환불 안내
                <template v-for="(uline, uidx) in mobilelineinf">
                  <br :key="'u'+uidx"/><span class="b2b_info_text_detail">{{uline}}</span>
                </template>
              </div>
              <div class="b2b_info_text" id="pringusage_1">
                ㆍ취소/환불 안내
                <template v-for="(bline, bidx) in moneylineinf">
                  <br :key="'b'+bidx"/><span class="b2b_info_text_detail">{{bline}}</span>
                </template>
              </div>
              <div class="b2b_info_text" id="pringusage_2">
                ㆍ취소/환불 안내
                <template v-for="(nline, nidx) in bookinglineinf">
                  <br :key="'n'+nidx"/><span class="b2b_info_text_detail">{{nline}}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="memoToggle" :style="{'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">상품 메모</div>
          <div class="modal-body form-horizontal" >
            <textarea class="form-control" rows="10" disabled v-model="productMemo" ></textarea>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeMemo">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval, imgtype } from "../../../lib/consts";
import routepath from "../../../lib/routes";
// import Pagination from "../../../components/Pagination";
import { reqGet, reqPost, post } from "../../../lib/request";
// import { brandapi, b2bapi } from "../../../lib/api";
import {b2bapi, adminapi, goodsapi} from "../../../lib/api";
import { DateFormat } from "../../../lib/utility";
import $ from 'jquery';
import XLSX from "xlsx";
import os from 'os';
import { ContainerClient } from '@azure/storage-blob';
import domtoimage from "dom-to-image";
export default {
  components: {
  },
  created() {
    this.channelCode = this.$route.query.channelCode;
    this.requestType = this.$route.query.requestType;
    if(localStorage.initval == "1")
    {
      if (localStorage.B2BDPC_keywordType) {
        this.keywordType = localStorage.B2BDPC_keywordType;
      }
      if (localStorage.B2BDPC_keyword) {
        this.keyword = localStorage.B2BDPC_keyword;
      }
      if (localStorage.B2BDPC_Number) {
        this.pageNumber = parseInt(localStorage.B2BDPC_Number);
      }
      if (localStorage.B2BDPC_Size) {
        this.pageSize = parseInt(localStorage.B2BDPC_Size);
      }
    }
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_PRD, path2:menuval.LV2_PRD_B2B, title:""});
  },
  watch: {
    keywordType(newVal) {
      localStorage.B2BDPC_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.B2BDPC_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.B2BDPC_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.B2BDPC_Size = newsize;
    },
  },
  data() {
    return {
      fileInput: null,
      selfilename:"",
      goodsItems:[],

      shownum: 10, //pagination에 보여지는 번호개수
      channelCode:"",
      keywordType: "NoKeyword",
      keyword: "",
      requestType:"",
      content: [],
      dplymethod:"",
      runningCheck: false,
      modalCloseable: true,
      imgmodal: false,
      dplymodal: false,
      pausemodal: false,
      pauseresultmodal: false,
      pauseResultList: [],
      downexcel: false,
      channels:[],
      distributions:[],
      distributionsExcel:[],
      channelinf:null,
      mobileCoupon:{
        checkval:false,
        goodsType:"MobileCoupon",
        refundInfoContents:"",//취소/환불 규정 정보
        refundInfoImageUrl:"",//취소/환불 규정 이미지 URL
      },
      moneyCoupon:{
        checkval:false,
        goodsType:"MoneyCoupon",
        refundInfoContents:"",//취소/환불 규정 정보
        refundInfoImageUrl:"",//취소/환불 규정 이미지 URL
      },
      bookingCoupon:{
        checkval:false,
        goodsType:"BookingCoupon",
        refundInfoContents:"",//취소/환불 규정 정보
        refundInfoImageUrl:"",//취소/환불 규정 이미지 URL
      },
      print_usage:false,
      channelpubInfo:{
        channelCode:"",//채널코드
        channelName:"",//채널명
        channelType:"",//채널타입[Tablenjoy, B2bIframe, B2bChannel]
        mypageCancelable:false,//마이페이지 취소가능 여부
        goodsSalesManage:false,//상품 판매관리 여부
        goodsDistributeManage:false,//상품 배포관리 여부
        channelImageManage:false,//채널 이미지관리 여부
        goodsDistributeBatch:false,//상품 일괄배포 가능 여부
        goodsInterworking:false,//관리자 상품연동 여부
        channelCallCenter:"",//채널 고객센터 번호
      },
      distributionComplete:false,
      windowRef:null,
      memoToggle:false,
      productMemo:"",
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
    mobilelineinf(){
      return this.mobileCoupon.refundInfoContents.split(os.EOL);
    },
    moneylineinf()
    {
      return this.moneyCoupon.refundInfoContents.split(os.EOL);
    },
    bookinglineinf()
    {
      return this.bookingCoupon.refundInfoContents.split(os.EOL);
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.B2BDPC_keywordType = this.keywordType;
      localStorage.B2BDPC_keyword = this.keyword;
      localStorage.B2BDPC_Number = this.pageNumber;
      localStorage.B2BDPC_Size = this.pageSize;
    }
    this.getChannelData();
    this.getchannelInfo();
  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    viewDateTime(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage();
    },
    refreshPage() {
      reqGet(
        b2bapi.base,
        b2bapi.distrequest + "?channelCode=" + this.channelCode +
        "&keywordType=" + this.keywordType +
        "&keyword=" + this.keyword +
        "&requestType=" + this.requestType,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.distributions = result.data.distributions;
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    getchannelInfo()
    {
      reqGet(
        b2bapi.base,
        b2bapi.channelpubInfo + "?channelCode=" + this.channelCode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.channelpubInfo = result.data;
              this.refreshPage();
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    exportExcel() {},
    closeimgmodal() {
      this.imgmodal = false;
    },
    showimgmodal() {
      reqGet(
        b2bapi.base,
        b2bapi.channelimginfo + "?channelCode=" + this.channelCode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.analyzeChannelImg(result.data.contents);
              this.imgmodal = true;
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    closedplymodal() {
        if (this.modalCloseable === true) {
            this.dplymodal = false;
        }
    },
    doDistribution()
    {
      // 작업 진행 중 여부 체크
      if (this.runningCheck) return false;

      this.runningCheck = true;
      this.modalCloseable = false;

      if(this.dplymethod == "match")
      {
        reqPost(
          b2bapi.base,
          b2bapi.batchmatchCode,
          this.authToken,
          {
            channelCode:this.channelCode,
            distributionComplete: this.distributionComplete,
            matchItems: this.goodsItems
          },
          (result) => {
            this.modalCloseable = true;
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.goodsItems = result.data.matchItems;
                this.closedplymodal();
                this.downexcel = true;
                this.runningCheck = false;
              }else{
                alert(result.message);
              }
            }
          }
        );
      }else{
        var url = "";
        switch (this.dplymethod) {
            case "create":
                url = b2bapi.tmonregister;
                break;
            case "modify":
                url = b2bapi.tmonupdate;
                break;
            case "recreate":
                url = b2bapi.tmonreRegister;
                break;
            case "pause":
                url = b2bapi.tmonpause;
                break;
            default:
                alert("존재하지 않는 작업 타입입니다.");
                return false;
        }

        var authToken = this.authToken;
        var distributionComplete = this.distributionComplete;
        var goodsItemCount = this.goodsItems.length;

        const sleep = () => {
            return new Promise(resolve => setTimeout(resolve, 1000));
        };

        const batchWorker = async () => {
            for (let f = 0; f < goodsItemCount; f++) {
                var reqData = {
                    goodsId: this.goodsItems[f].goodsId,
                    channelGoodsName: this.goodsItems[f].channelGoodsName,
                    promotion: this.goodsItems[f].promotion,
                    categoryCode: this.goodsItems[f].categoryCode,
                    displayStartDate: this.goodsItems[f].displayStartDate,
                    displayEndDate: this.goodsItems[f].displayEndDate,
                    expirationStartDate: this.goodsItems[f].expirationStartDate,
                    expirationEndDate: this.goodsItems[f].expirationEndDate,
                    vendorPolicyNo: this.goodsItems[f].vendorPolicyNo,
                    refundImageType: this.goodsItems[f].refundImageType,
                    distributionComplete: distributionComplete,
                    mainImageUrl: this.goodsItems[f].mainImageUrl,
                    homeRecommendedImage: this.goodsItems[f].homeRecommendedImage
                };
                const result = await post(b2bapi.base, url, authToken, reqData);

                if (result != null) {
                    this.goodsItems[f].resultMessage = result.message;
                }
                await sleep();
            }
        };

        batchWorker().then(() => {
            this.modalCloseable = true;
            this.closedplymodal();
            this.downexcel = true;
            this.runningCheck = false;
        });
      }
    },
    closedownexcelmodal() {
      this.downexcel = false;
    },
    selectmethod() {
      if(this.dplymethod == "down")
      {
        // [{channelCode, channelName},{"ST11", "11번가"},{"AKMALL","AK몰"},{"CJMALL","CJ오쇼핑"},{"G9", "G9"},{"GMK", "G마켓"},
        // {"SSG", "SSG닷컴"}, {"LOTTEON","롯데ON"},{"LIM", "롯데아이몰"},{"AUCTION", "옥션"},{"WMP","위메프"},{"COUPANG","쿠팡"},{"KLOOK","클룩"},
        // {"TMON","티몬"},{"HHMALL", "현대H몰"}
        if(this.channelCode == "TMON")
        {
          this.downTMONTemplate();
        }else if(this.channelpubInfo.goodsInterworking == false){
          this.downOtheremplate();
        }
      }else{
        //"create", "modify", "recreate", "pause"
        this.selfilename = "";
        this.dplymodal = true;
      }
    },
    downOtheremplate() {
      var tmpheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "상품코드",
            B: "옵션코드",
            C: "채널상품코드",
            D: "채널판매코드",
          },
          {
            A: "관리자에 등록된 상품코드",
            B: "관리자에 등록된 옵션코드",
            C: "B2B 상품코드",
            D: "B2B 상품 판매코드 (쿠팡 필수)",
          },
        ],
        { skipHeader: true }
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, tmpheader, "코드매칭 양식");
      // export Excel file
      XLSX.writeFile(wb, "코드매칭 양식.xlsx");
    },
    downTMONTemplate() {
      var tmpheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "상품코드",
            B: "상품명",
            C: "꾸밈문구",
            D: "카테고리코드",
            E: "판매기간 시작일",
            F: "판매기간 종료일",
            G: "유효기간 시작일",
            H: "유효기간 종료일",
            I: "수수료 정책",
            J: "취소환불 타입",
            K: "채널 대표 이미지 URL",
            L: "홈 추천 이미지 URL"
          },
          {
            A: "관리자에 등록된 상품코드",
            B: "티몬에 등록할 상품명 (최대 60자)",
            C: "티몬에 등록할 꾸밈문구 (최대 20자)",
            D: "티몬 카테고리코드",
            E: "0000-00-00 형식",
            F: "0000-00-00 형식",
            G: "0000-00-00 형식",
            H: "0000-00-00 형식",
            I: "티몬 수수료 정책 코드",
            J: "쿠폰/금액권/예약필수쿠폰 중 택1",
            K: "채널 대표 이미지 URL",
            L: "홈 추천 이미지 URL"
          },
          {
            A: "등록 / 재등록",
            B: "등록 / 재등록",
            C: "등록 / 재등록",
            D: "등록 / 재등록",
            E: "등록 / 재등록",
            F: "등록 / 재등록",
            G: "등록 / 재등록",
            H: "등록 / 재등록",
            I: "등록 / 재등록",
            J: "등록 / 재등록",
            K: "등록 / 재등록",
            L: "등록 / 재등록"
          },
          {
            A: "수정",
            B: "수정",
            C: "수정",
            D: "",
            E: "",
            F: "",
            G: "",
            H: "",
            I: "",
            J: "",
          },
          {
            A: "판매중지",
            B: "",
            C: "",
            D: "",
            E: "",
            F: "",
            G: "",
            H: "",
            I: "",
            J: "",
          },
        ],
        { skipHeader: true }
      );

      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, tmpheader, "티몬채널 양식");
      // export Excel file
      XLSX.writeFile(wb, "티몬채널 양식.xlsx");
    },
    gochannelprodreg(goodsid){
      if(this.channelpubInfo.goodsInterworking == false)
      {
        this.$router.push(this.routes.channelprodreg+"?goodsId="+goodsid+"&channelCode="+this.channelCode+"&goodsInterworking="+this.channelpubInfo.goodsInterworking);
      }else{
        if(this.channelCode == "TMON")
        {
          this.$router.push(this.routes.channelprodreg+"?goodsId="+goodsid+"&channelCode="+this.channelCode+"&goodsInterworking="+this.channelpubInfo.goodsInterworking);
        }else if(this.channelCode == "GMK" || this.channelCode == "G9")
        {
          this.$router.push(this.routes.channelprodreg+"?goodsId="+goodsid+"&channelCode="+this.channelCode+"&goodsInterworking="+this.channelpubInfo.goodsInterworking);
        }else{
          alert("잘못된 접근입니다.");
        }
      }
    },
    getChannelData()
    {
      reqGet(
        b2bapi.base,
        b2bapi.channelList + "?manageType=GoodsDistribute",
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.channels = result.data.channels;
          }
        }
      );
    },
    dofinish()
    {
      var checkarray = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();

      if (checkarray.length < 1) {
          alert("상품을 선택해 주세요.");
          return false;
      }

      reqPost(
        b2bapi.base,
        b2bapi.distcomplete,
        this.authToken,
        {
          channelCode: this.channelCode,
          goodsIds: checkarray
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    openPauseModal() {
        var checkarray = $('input:checkbox.chitem:checked').map(function () {
            return this.value;
        }).get();

        if (checkarray.length > 0) {
            this.pausemodal = true;
        } else {
            alert("판매중지할 상품을 선택해 주세요.");
        }
    },
    closePauseModal() {
        this.pausemodal = false;
    },
    batchPause() {
        var goodsItemList = $('input:checkbox.chitem:checked').map(function () {
            return {goodsId: this.value};
        }).get();

        reqPost(
            b2bapi.base,
            b2bapi.batchpause,
            this.authToken,
            {
                distributionComplete: this.distributionComplete,
                goodsItems: goodsItemList
            },
            (result) => {
                if (result != null) {
                    if (result.code == rescode.REQ_SUCCESS) {
                        this.pauseResultList = result.data.goodsItems;
                        this.pausemodal = false;
                        this.pauseresultmodal = true;
                        this.refreshPage();
                    } else {
                        alert(result.message);
                    }
                }
            }
        );
    },
    closePauseResultModal() {
        this.pauseresultmodal = false;
    },
    getSaleState(val)
    {
      if(val)
      {
        return "판매중";
      }else{
        return "일시중지";
      }
    },
    getReqType(val)
    {
      if(val == "Register")
      {
        return "신규등록";
      }else if(val == "Update")
      {
        return "정보수정";
      }else if(val == "Pause")
      {
        return "판매종료";
      }else if(val == "Resume")
      {
        return "판매재개";
      }
    },
    analyzeChannelImg(channellist)
    {
      /**
       * goodsType              String      상품종류[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
       * refundInfoContents     String      취소/환불 규정 정보
       * refundInfoImageUrl     String      취소/환불 규정 이미지 URL
       */
      this.mobileCoupon = {
        checkval:false,
        goodsType:"MobileCoupon",
        refundInfoContents:"",//취소/환불 규정 정보
        refundInfoImageUrl:"",//취소/환불 규정 이미지 URL
      };
      this.moneyCoupon = {
        checkval:false,
        goodsType:"MoneyCoupon",
        refundInfoContents:"",//취소/환불 규정 정보
        refundInfoImageUrl:"",//취소/환불 규정 이미지 URL
      };
      this.bookingCoupon = {
        checkval:false,
        goodsType:"BookingCoupon",
        refundInfoContents:"",//취소/환불 규정 정보
        refundInfoImageUrl:"",//취소/환불 규정 이미지 URL
      };

      var tidx = channellist.findIndex(citem => citem.goodsType == "MobileCoupon");
      if(tidx > -1)
      {
        this.mobileCoupon.refundInfoContents = channellist[tidx].refundInfoContents;
        this.mobileCoupon.refundInfoImageUrl = channellist[tidx].refundInfoImageUrl;
      }
      tidx = channellist.findIndex(citem => citem.goodsType == "MoneyCoupon");
      if(tidx > -1)
      {
        this.moneyCoupon.refundInfoContents = channellist[tidx].refundInfoContents;
        this.moneyCoupon.refundInfoImageUrl = channellist[tidx].refundInfoImageUrl;
      }
      tidx = channellist.findIndex(citem => citem.goodsType == "BookingCoupon");
      if(tidx > -1)
      {
        this.bookingCoupon.refundInfoContents = channellist[tidx].refundInfoContents;
        this.bookingCoupon.refundInfoImageUrl = channellist[tidx].refundInfoImageUrl;
      }
    },
    getPringUageBlob(bolbdata, idx)
    {
      var matches = bolbdata.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
      var contentType = matches[1];
      var buffer = new Buffer(matches[2], 'base64');
      var requrl = adminapi.sas;
      var reqdata = {
        type: this.imgtype.B2B,
      };
      var orgurl = "";
      var ftype = "";
      if(idx == 0){
        orgurl = this.mobileCoupon.refundInfoImageUrl;
        ftype = "MobileCoupon";
      }else if(idx == 1){
        orgurl = this.moneyCoupon.refundInfoImageUrl;
        ftype = "MoneyCoupon";
      }else if(idx == 2){
        orgurl = this.bookingCoupon.refundInfoImageUrl;
        ftype = "BookingCoupon";
      }

      if(orgurl != "" && orgurl != null)
      {
        requrl = adminapi.sasUpdate;
        reqdata = {
          originUrl: orgurl,
        };
      }
      reqPost(
        adminapi.base,
        requrl,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              const containerClient = new ContainerClient(result.data.url);
              var filename = "";
              if(orgurl != null && orgurl != "")
              {
                filename = result.data.filePath;
              }else{
                filename = result.data.directory + "/" + this.channelCode+"_refund_"+ftype+".jpg";
              }
              const blockBlobClient = containerClient.getBlockBlobClient(filename);
              blockBlobClient.upload(buffer, buffer.length, {blobHTTPHeaders: {blobContentType: contentType}})
              .then((upfile)=>{
                var r_url = upfile._response.request.url;
                let uploadedUrl = r_url.substring(0,r_url.indexOf("?sig="));
                if(idx == 0){
                  this.mobileCoupon.refundInfoImageUrl = uploadedUrl;
                }else if(idx == 1){
                  this.moneyCoupon.refundInfoImageUrl = uploadedUrl;
                }else if(idx == 2){
                  this.bookingCoupon.refundInfoImageUrl = uploadedUrl;
                }

                if(idx < 2)
                {
                  this.printuage(idx + 1);
                }else{
                  this.print_usage = false;
                  this.updateImage();
                }
              }).catch((err)=>{
                console.log(" err : ", err);
              });
            }
          }
        }
      );
    },
    async printuage(idx) {
      this.print_usage = true;
      if(idx == 0 && !this.mobileCoupon.checkval)
      {
        this.printuage(idx + 1);
        return;
      }
      if(idx == 1 && !this.moneyCoupon.checkval)
      {
        this.printuage(idx + 1);
        return;
      }
      if(idx == 2 && !this.bookingCoupon.checkval)
      {
        this.printuage(idx + 1);
        return;
      }
      if(idx == 3)
      {
        this.updateImage();
        return;
      }
      var node = document.getElementById('pringusage_'+idx);
      if(node == null)
      {
        this.print_usage = false;
        if(idx > 0)
        {
          this.updateB2bUsageImg();
        }
        return ;
      }
      var options = {
        quality: 1
      };

      domtoimage.toJpeg(node, options)
      .then((data)=>this.getPringUageBlob(data, idx))
      .catch(() => {
        this.print_usage = false;
      });
    },
    updateImage()
    {
      var contents = [];
      if(this.mobileCoupon.checkval)
      {
        contents.push(this.mobileCoupon);
      }
      if(this.moneyCoupon.checkval)
      {
        contents.push(this.moneyCoupon);
      }
      if(this.bookingCoupon.checkval)
      {
        contents.push(this.bookingCoupon);
      }
      reqPost(
        b2bapi.base,
        b2bapi.imgregister,
        this.authToken,
        {
          channelCode: this.channelCode,
          contents: contents
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.print_usage = false;
              this.refreshPage();
              this.closeimgmodal();
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    onUploadFiles(e){
      const files = e.target.files;
      if (!files.length) {
        return ;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert("The upload format is incorrect. Please upload xls or xlsx format");
      }
      const fileReader = new FileReader();
      this.selfilename = files[0].name;
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          // const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
            cellDates:true
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {header:"A", raw: true}); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = [];  // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.exceltoInData(excellist);
        } catch (e) {
          return alert("Read failure!");
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    exceltoInData(excellist)
    {
      var t_goodsItems = excellist.filter(item => Number(item.A)===item.A);
      if(this.dplymethod == "create" || this.dplymethod == "recreate" || this.dplymethod == "modify" || this.dplymethod == "pause")

      {
        this.goodsItems = t_goodsItems.map((titem) => {
          var reftype = "MobileCoupon";
          if(titem.J == "금액권" || titem.J == "모바일금액권")
          {

            reftype = "MoneyCoupon";
          }else if(titem.J == "예약권")
          {
            reftype = "BookingCoupon";
          }
          return {
           goodsId:titem.A,//상품 ID
           channelGoodsName:titem.B,//채널 상품명
           promotion:titem.C,//꾸밈문구
           categoryCode:titem.D,//카테고리 코드
           displayStartDate:DateFormat(titem.E, "YYYY-MM-DD"),//판매기간 시작일
           displayEndDate:DateFormat(titem.F, "YYYY-MM-DD"),//판매기간 종료일
           expirationStartDate:DateFormat(titem.G, "YYYY-MM-DD"),//유효기간 시작일
           expirationEndDate:DateFormat(titem.H, "YYYY-MM-DD"),//유효기간 종료일
           vendorPolicyNo:titem.I,// 수수료 정책
           refundImageType:reftype,//취소환불 타입[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
           mainImageUrl: titem.K, // 티몬 채널 대표 이미지 URL
           homeRecommendedImage: titem.L, // 티몬 홈 추천 이미지 URL
           resultMessage:"",//
          }
        });
      }else if(this.dplymethod == "match")
      {
        this.goodsItems = t_goodsItems.map((titem) => {
          var titemD = titem.D === undefined ? "" : titem.D;
          return {
           goodsId:titem.A,//상품코드
           optionId:titem.B,//옵션코드
           matchItemCode:titem.C,//채널상품코드
           salesLinkCode:titemD,//채널판매코드
           resultMessage:"",//
          }
        });
      }
    },
    selectExcel()
    {
      this.$refs.fileInput.click();
    },
    getDplyName()
    {
      if(this.dplymethod == "match")
        return "코드매칭";
      else if(this.dplymethod == "create")
        return "등록";
      else if(this.dplymethod == "modify")
        return "수정";
      else if(this.dplymethod == "recreate")
        return "재등록";
      else if(this.dplymethod == "pause")
        return "판매중지";
      else
        return "";
    },
    getGoodsLen()
    {
      return this.goodsItems.length;
    },
    getSuccGoodsLen()
    {
      var t_goods = this.goodsItems.filter(item => item.resultMessage== "성공");
      return t_goods.length;
    },
    resultexcel()
    {
      if(this.channelCode == "TMON")
      {
        this.downTMONResult();
      }else{
        this.downOtherResult();
      }
    },
    tmonconvert(item)
    {
      var reftype = "쿠폰";
      if(item.refundImageType == "MoneyCoupon")
      {
        reftype = "금액권";
      }else if(item.refundImageType == "BookingCoupon")
      {
        reftype = "예약권";
      }

      return {
        A: item.goodsId,
        B: item.channelGoodsName,
        C: item.promotion,
        D: item.categoryCode,
        E: item.displayStartDate,
        F: item.displayEndDate,
        G: item.expirationStartDate,
        H: item.expirationEndDate,
        I: item.vendorPolicyNo,
        J: reftype,
        K: item.mainImageUrl,
        L: item.homeRecommendedImage,
        M: item.resultMessage,
      }
    },
    downTMONResult() {
      var tmpheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "상품코드",
            B: "상품명",
            C: "꾸밈문구",
            D: "카테고리코드",
            E: "판매기간 시작일",
            F: "판매기간 종료일",
            G: "유효기간 시작일",
            H: "유효기간 종료일",
            I: "수수료 정책",
            J: "취소환불 타입",
            K: "채널 대표 이미지 URL",
            L: "홈 추천 이미지 URL",
            M: "배포결과",
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(tmpheader, this.goodsItems.map(this.tmonconvert), {
        skipHeader: true,
        origin: "A2",
      });

      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, tmpheader, "티몬채널 배포결과");
      // export Excel file
      XLSX.writeFile(wb, "티몬채널 배포결과.xlsx");
    },
    otherconvert(item)
    {
      return {
        A: item.goodsId,
        B: item.optionId,
        C: item.matchItemCode,
        D: item.salesLinkCode,
        E: item.resultMessage,
      }
    },
    downOtherResult() {
      var tmpheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "상품코드",
            B: "옵션코드",
            C: "채널상품코드",
            D: "채널판매코드",
            E: "배포결과",
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(tmpheader, this.goodsItems.map(this.otherconvert), {
        skipHeader: true,
        origin: "A2",
      });

      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, tmpheader, "코드매칭 배포결과");
      // export Excel file
      XLSX.writeFile(wb, "코드매칭 배포결과.xlsx");
    },
    onPreviewClick(url) {
      this.windowRef = window.open(url, "", "width=600,height=400,left=200,top=200");
      this.windowRef.addEventListener('beforeunload', this.closePortal);
    },
    closePortal() {
      if(this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
      }
    },
    distributionconvert() {
      var convertData = [];
      for (var idx = 0; idx < this.distributionsExcel.length; idx++) {
        var cellData = {};
        cellData[0] = this.distributionsExcel[idx].deleted ? "삭제" : "";
        cellData[1] = this.distributionsExcel[idx].goodsOnSale ? "판매중" : "일시중지";
        cellData[2] = this.distributionsExcel[idx].optionOnSale ? "판매중" : "일시중지";
        cellData[3] = this.distributionsExcel[idx].goodsId;
        cellData[4] = this.distributionsExcel[idx].optionId;
        cellData[5] = this.distributionsExcel[idx].goodsName;
        cellData[6] = this.distributionsExcel[idx].optionName;
        cellData[7] = this.distributionsExcel[idx].matchItemCode;
        cellData[8] = this.distributionsExcel[idx].salesLinkCode;
        cellData[9] = this.distributionsExcel[idx].vendorPolicyNo;
        cellData[10] = this.getReqType(this.distributionsExcel[idx].requestType);
        convertData.push(cellData);
      }
      this.distributionsExcel = convertData;
    },
    downDistributionExcel() {
      var dtrheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "삭제여부",
            B: "상품상태",
            C: "옵션상태",
            D: "상품코드",
            E: "옵션코드",
            F: "상품명",
            G: "옵션명",
            H: "채널상품코드",
            I: "채널판매코드",
            J: "수수료 정책",
            K: "요청타입",
          },
        ],
        {skipHeader: true}
      );

      this.distributionconvert();
      XLSX.utils.sheet_add_json(dtrheader, this.distributionsExcel, {
        skipHeader: true,
        origin: "A2",
      });

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dtrheader, "채널배포요청");
      XLSX.writeFile(wb, "채널배포요청.xlsx");
    },
    distributionExcel() {
      reqGet(
        b2bapi.base,
        b2bapi.distexcel + "?channelCode=" + this.channelCode +
        "&keywordType=" + this.keywordType +
        "&keyword=" + this.keyword +
        "&requestType=" + this.requestType,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.distributionsExcel = result.data.distributions;
              this.downDistributionExcel();
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    openMemo(val){
      this.memoToggle = true;

      reqGet(
        goodsapi.base,
        goodsapi.productMemoList+`?goodsId=${val}`,
        this.authToken,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              let tmpArr = result.data.memoList
              console.log(tmpArr)

              if(tmpArr.length>0){
                tmpArr.forEach(item=>{
                  let tmpString = `• ${ this.viewDateTime(item.updateDateTime)} (${item.userName})\n ${item.memo}\n\n`
                  console.log(tmpString)
                  this.productMemo += tmpString
                })
              }

            } else {

              alert(result.message);
            }
          }
        }
      );
    },
    closeMemo(){
      this.productMemo = ""
      this.memoToggle = false;
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.radio > label {
  margin-right: 15px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}


.dplymodal {
  height: 250px;
  overflow: scroll;
}

.dplymodal th {
  background-color: #dee2e685;
}

.dplymodal::-webkit-scrollbar {
  display: none;
}

.imgmodal th {
  background-color: #dee2e685;
}

.imgmodal::-webkit-scrollbar {
  display: none;
}

.modal-body .control-label{
  margin:0px;
}

.b2b_info_text {
  background: #ffffff;
  padding: 30px 24px 0px 24px;
  font-family: YoonGothic750;
  line-height: 28px;
  letter-spacing: -1px;
  color: #605f5f;
  font-size: 17px;
}

.b2b_info_text_detail {
  padding-left: 15px;
}

@media (min-width: 768px){
.modal .form-group{
  display: flex;
    align-items: center;
}

.excelmodal{
  width: 300px;
}
}
</style>
