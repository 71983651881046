<template>
  <div class="box box-solid">
    <div class="box-header ">
      <h3 class="box-title">대표상품</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">대표상품</label>
        <div class="col-sm-10 table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>상품코드</th>
              <th>상품명</th>
              <th>정상가</th>
              <th>판매가</th>
              <th>할인율</th>
              <th>상태</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                {{ newGoodsId }}
              </td>
              <td>
                {{ goodsName }}
              </td>
              <td>
                {{ mainOptionListPrice }}
              </td>
              <td>
                {{ mainOptionSalePrice }}
              </td>
              <td>
                {{ discount(mainOptionSalePrice, mainOptionListPrice) }}
              </td>
              <td>
                {{ getMainGoodState() }}
              </td>
              <td>
                <button v-if="newGoodsId == null" type="button" class="btn btn-default" @click="showSelectProd">선택
                </button>
                <button v-if="newGoodsId != null" type="button" class="btn btn-default btn-social-icon"
                        style="margin-left:10px" @click="delMaingoodid">
                  <i class="fa fa-fw fa-trash-o"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="saveMaingoodid">저장</button>
      </div>
    </div>
    <div class="modal" :style="showselgoodlg && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">대표상품선택</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="col-sm-4">
                <select class="form-control" v-model="sgoodkeytype">
                  <option value="NoKeyword">전체분류</option>
                  <option value="GoodsNameContaining">상품명</option>
                  <option value="GoodsId">상품코드</option>
                </select>
              </div>
              <div class="col-sm-8">
                <div class="input-group">
                  <input type="email" class="form-control" v-model="sgoodkeyval"/>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" @click="searchGoods">검색</button>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-responsive tbl_h200 p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>상품코드</th>
                  <th>상품명</th>
                  <th>등록</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="good in goodlist" :key="good.goodsId">
                  <td>
                    {{ good.goodsId }}
                  </td>
                  <td>
                    {{ good.goodsName }}
                  </td>
                  <td>
                    <button class="btn btn-default" type="button" @click="onSelMainGood(good.goodsId)">등록</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeSelectProd">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDiscount} from "@/lib/utility";
import moment from "moment/moment";
import {reqGet, reqPost} from "@/lib/request";
import {brandapi, goodsapi} from "@/lib/api";
import {rescode} from "@/lib/consts";

export default {
  data() {
    return {
      showselgoodlg: false,
      sgoodkeytype: "NoKeyword",
      sgoodkeyval: "", //
      goodlist: [],
      goodsId: null,
      newGoodsId: null,
      goodsName: "",
      onSale: false,
      hasSalesPeriod: false,
      saleStartDate: null,
      saleEndDate: null,
      mainOptionListPrice: "",
      mainOptionSalePrice: "",
      currencyCode: "",
      storeCode: "",
    }
  },
  mounted() {
    this.getmaingoodsid();
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  methods: {
    closeSelectProd() {
      this.showselgoodlg = false;
    },
    onSelMainGood(goodsId) {
      reqGet(
        goodsapi.base,
        goodsapi.admmainPrice + "?goodsId=" + goodsId,
        this.authToken,
        (goodinf) => {
          if (goodinf != null && goodinf.code == rescode.REQ_SUCCESS) {
            this.newGoodsId = goodinf.data.goods.goodsId;
            this.goodsName = goodinf.data.goods.goodsName;
            this.onSale = goodinf.data.goods.onSale;
            this.hasSalesPeriod = goodinf.data.goods.hasSalesPeriod;
            this.saleStartDate = goodinf.data.goods.saleStartDate;
            this.saleEndDate = goodinf.data.goods.saleEndDate;
            this.mainOptionListPrice = goodinf.data.goods.mainOptionListPrice;
            this.mainOptionSalePrice = goodinf.data.goods.mainOptionSalePrice;
            this.currencyCode = goodinf.data.goods.currencyCode;
            this.closeSelectProd();
          }
        }
      );
    },
    getmaingoodsid() {
      reqGet(
        brandapi.base,
        brandapi.mainGoodsIdquery + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.goodsId = result.data.goodsId;
            this.newGoodsId = result.data.goodsId;
            if (this.goodsId != null) {
              reqGet(
                goodsapi.base,
                goodsapi.admmainPrice + "?goodsId=" + this.goodsId,
                this.authToken,
                (goodinf) => {
                  if (goodinf != null && goodinf.code == rescode.REQ_SUCCESS) {
                    this.goodsName = goodinf.data.goods.goodsName;
                    this.onSale = goodinf.data.goods.onSale;
                    this.hasSalesPeriod = goodinf.data.goods.hasSalesPeriod;
                    this.saleStartDate = goodinf.data.goods.saleStartDate;
                    this.saleEndDate = goodinf.data.goods.saleEndDate;
                    this.mainOptionListPrice = goodinf.data.goods.mainOptionListPrice;
                    this.mainOptionSalePrice = goodinf.data.goods.mainOptionSalePrice;
                    this.currencyCode = goodinf.data.goods.currencyCode;
                  }
                });
            }
          }
        }
      );
    },
    searchGoods() {
      reqGet(
        goodsapi.base,
        goodsapi.codeNameslist + "?keywordType=" + this.sgoodkeytype + "&keyword=" + this.sgoodkeyval + "&combinedFilter=ForNotCombinedOnly",
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.goodlist = result.data;
              console.log(this.goodlist)
            } else {
              this.goodlist = [];
              alert(result.message);
            }
          }
        }
      );
    },
    discount(sales, target) {
      return getDiscount(sales, target);
    },
    showSelectProd() {
      this.showselgoodlg = true;
    },
    getMainGoodState() {
      if (this.newGoodsId == null)
        return "";
      if (this.onSale == false) {
        return "판매중지";
      } else {
        if (this.hasSalesPeriod == true) {
          if (moment().isBefore(this.saleStartDate)) {
            return "판매대기";
          } else if (moment().isAfter(this.saleEndDate)) {
            return "판매기간만료";
          } else {
            return "판매중";
          }
        } else {
          return "판매중";
        }
      }
    },

    delMaingoodid() {
      this.newGoodsId = null;
      this.goodsName = "";
      this.onSale = false;
      this.hasSalesPeriod = false;
      this.saleStartDate = null;
      this.saleEndDate = null;
      this.mainOptionListPrice = "";
      this.mainOptionSalePrice = "";
      this.currencyCode = null;
    },

    saveMaingoodid() {
      if (this.newGoodsId == null) {
        if (this.goodsId != null) {
          reqPost(
            brandapi.base,
            brandapi.tnjmainIddelete,
            this.authToken, {
              storeCode: this.storeCode,
              goodsId: this.goodsId,
            },
            (result) => {
              if (result != null) {
                if (result.code == rescode.REQ_SUCCESS) {
                  this.goodsId = null;
                  alert("삭제되었습니다.");
                } else {
                  alert(result.message);
                }
              }
            }
          );
        }
      } else {
        reqPost(
          brandapi.base,
          brandapi.tnjmainIdupdate,
          this.authToken, {
            storeCode: this.storeCode,
            goodsId: this.newGoodsId,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                alert("설정되었습니다.");
                this.goodsId = this.newGoodsId;
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },


  }
}
</script>

<style>
th {
  background-color: #dee2e685;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.tbl_h200 {
  height: 200px;
  overflow: scroll;
}

.nav-tabs li a {
  cursor: pointer;
}

.col-inline {
  display: inline-flex;
  align-items: center;
}

.btn-selweek {
  background-color: #5B9BD5;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .sm-inline {
    display: flex;
  }
}

.redmsg {
  color: #ff0000;
}

.blurmsg {
  color: #0000ff;
}

.nnos input,
.nnos select {
  display: inline-block;
}

.refInputDiv {
  display: inline-block;
  width: 172px;
  margin-left: 10px;
}

.srdivbox {
  margin-top: 10px;
}

.refTitle {
  min-width: 120px;
  display: inline-block;
}

.mt-1 {
  margin-top: 10px;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}

.mr-2 {
  margin-right: 10px;
}

.waring {
  color: red;
  margin-top: 5px;
}
</style>
