<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">매장 콘텐츠</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">대표이미지</label>
        <div class="col-sm-10">
          <SingleThumbnail v-model="mainImageurl" :imgtype="imgtype.STORE" :change="onMainImage" :imgFile="{}"/>
        </div>
      </div>
      <div v-if="!!kakaoId" class="form-group">
        <label class="col-sm-2 control-label">장소 대표 이미지<br>(카카오톡 예약하기 전용)</label>
        <div class="col-sm-10">
          <SingleThumbnail v-model="kakaoImageUrl" :imgtype="imgtype.STORE" :change="onKakaoImage" :imgFile="{}"/>
          <label class="col-sm-12 control-label">
            • 권장 사이즈 800x800px / 파일당 최대 20MB / jpeg, jpg, png, gif<br>
            • 상호명, 간판, 로고, 매장 사진 등 장소 관련 이미지로 등록해 주세요.<br>
            • 미등록 시 매장 기본정보에 등록된 대표이미지로 대체되어 등록됩니다.
          </label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">상세이미지<br>(아이프레임전용)</label>
        <div class="col-sm-10" style="display: inline-flex;">
          <SingleThumbnail v-for="(image, i) in subImages" :key="i" :value="subImages[i].url" :imgFile="{}"
                           :imgtype="imgtype.STORE" style="margin-right:10px"
                           :change="(newval, w, h)=>{onChangeSubImg(i, newval, w, h)}"/>
          <SingleThumbnail value="" downlabel="" :imgtype="this.imgtype.STORE" :change="onNewSubImg" :imgFile="{}"/>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">메뉴판이미지</label>
        <div class="col-sm-10" style="display: inline-flex;">
          <SingleThumbnail v-for="(image, i) in menupanImages" :key="i" :value="menupanImages[i].url" :imgFile="{}"
                           :imgtype="imgtype.STORE" style="margin-right:10px"
                           :change="(newval, w, h)=>{onChangeMenupanImg(i, newval, w, h)}"/>
          <SingleThumbnail value="" downlabel="" :imgtype="this.imgtype.STORE" :change="onNewMenupanImg" :imgFile="{}"/>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">매장 한줄 소개</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" maxlength="40" v-model="mainIntro" @keyup="mainIntroLength($event)">
          <span class="mainIntroNum" v-if="mainIntro">{{
              mainIntro.length
            }} / 40 자</span>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">매장 상세 소개</label>
        <div class="col-sm-10">
          <textarea class="form-control" rows="5" maxlength="2000" v-model="storeIntroduction"
                    @keyup="storeIntroductionLength"></textarea>
          <span class="mainIntroNum" v-if="storeIntroduction">{{
              storeIntroduction.length
            }} / 2000 자</span>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">이용안내</label>
        <div class="col-sm-10">
          <textarea class="form-control" rows="5" maxlength="2000" v-model="useInformation"></textarea>
          <span v-if="useInformation" class="mainIntroNum" > {{useInformation.length}} / 2000 자</span>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">유의사항</label>
        <div class="col-sm-10">
          <textarea class="form-control" rows="5" maxlength="2000" v-model="notice"></textarea>
          <span class="mainIntroNum" v-if="notice">{{
              notice.length
            }} / 2000 자</span>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="savecontents">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {rescode, imgtype} from "@/lib/consts";
import SingleThumbnail from "@/components/SingleThumbnail";


export default {
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  data() {
    return {
      storeCode: "",
      storeIntroduction: "", //매장 소개
      mainIntro: "", //매장 한줄 소개
      notice: "", //매장 유의사항
      mainImageurl: "", //메인 이미지 url
      mainImagewidth: 0, //메인 이미지 width
      mainImageheight: 0, //메인 이미지 height
      subImages: [], //서브 이미지 리스트, [{url:"", width:0, height:0}]
      menupanImages: [],
      promotionVideoUrl: "", //프로모션 동영상 url
      useInformation:"", // 이용안내
      kakaoId:"",
      kakaoImageUrl:"",
      kakaoImagewidth: 0, //메인 이미지 width
      kakaoImageheight: 0,
    }
  },
  components: {
    SingleThumbnail,
  },
  computed: {
    imgtype() {
      return imgtype;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.getsiteContents();
  },
  methods: {
    mainIntroLength(eve) {
      this.mainIntroNum = eve.srcElement._value.length
    },
    storeIntroductionLength(eve) {
      this.storeIntroductionNum = eve.srcElement._value.length
    },
    onChangeSubImg(index, newval, w, h) {
      if (newval != "") {
        this.subImages[index].url = newval;
        // console.log(w,h)
        this.subImages[index].width = w;
        this.subImages[index].height = h;
      } else {
        this.subImages.splice(index, 1);
      }
    },
    onChangeMenupanImg(index, newval, w, h) {
      if (newval != "") {
        this.menupanImages[index].url = newval;
        // console.log(w,h)
        this.menupanImages[index].width = w;
        this.menupanImages[index].height = h;
      } else {
        this.menupanImages.splice(index, 1);
      }
    },
    onNewMenupanImg(newval, w, h) {
      var newimg = {
        url: newval,
        width: w,
        height: h
      };
      this.menupanImages.push(newimg);
      // console.log(newimg)
    },
    getsiteContents() {
      reqGet(
        brandapi.base,
        brandapi.tnjContentsquery + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.storeIntroduction = result.data.siteContents.storeIntroduction; //매장 소개
            this.mainIntro = result.data.siteContents.mainIntro;
            this.notice = result.data.siteContents.notice;
            this.mainImageurl = result.data.siteContents.mainImage.url; //메인 이미지 url
            this.mainImagewidth = result.data.siteContents.mainImage.width; //메인 이미지 width
            this.mainImageheight = result.data.siteContents.mainImage.height; //메인 이미지 height
            this.subImages = result.data.siteContents.subImages; //서브 이미지 리스트, [{url:"", width:0, height:0}]
            this.menupanImages = result.data.siteContents.menupanImages;
            this.kakaoId = result.data.siteContents.kakaoId;
            this.kakaoImageUrl = result.data.siteContents.kakaoImageUrl;
            this.kakaomageheight = 800
            this.kakaoImagewidth = 800
            this.useInformation = result.data.siteContents.useInformation
            //this.promotionVideoUrl = result.data.siteContents.promotionVideoUrl;
            console.log('this.kakaoImageUrl')
            console.log(this.kakaoImageUrl)
            this.$emit('get-kakao-id', this.kakaoId);
          }
        }
      );
    },
    onNewSubImg(newval, w, h) {
      var newimg = {
        url: newval,
        width: w,
        height: h
      };
      this.subImages.push(newimg);
      // console.log(newimg)
    },

    onMainImage(newval, w, h) {
      this.mainImageurl = newval; //메인 이미지 url
      this.mainImagewidth = w; //메인 이미지 width
      this.mainImageheight = h; //메인 이미지 height
    },
    onKakaoImage(newval, w, h) {
      this.kakaoImageUrl = newval; //메인 이미지 url
      this.kakaoImagewidth = w; //메인 이미지 width
      this.kakaomageheight = h; //메인 이미지 height
    },
    savecontents() {
      var mainImage = {
        url: this.mainImageurl,
        width: this.mainImagewidth,
        height: this.mainImageheight
      };

      var kakaoImage = {
        url: this.kakaoImageUrl,
        width: this.kakaoImagewidth,
        height: this.kakaomageheight
      };

      reqPost(
        brandapi.base,
        brandapi.tnjContentsupdate,
        this.authToken, {
          siteContents: {
            mainIntro: this.mainIntro,
            storeIntroduction: this.storeIntroduction,
            notice: this.notice,
            mainImage: mainImage,
            subImages: this.subImages,
            menupanImages: this.menupanImages,
            kakaoImageUrl: this.kakaoImageUrl,
            useInformation: this.useInformation
            // promotionVideoUrl: this.promotionVideoUrl,
          },
          storeCode: this.storeCode,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.$emit('get-kakao-alert', 'STORE');
              alert("저장되었습니다.");
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
  }
}
</script>
