<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <b>T</b>ABLENJOY <b>M</b>ANAGER
      </div>
      <div class="login-box-body">
        <p class="box-title">로그인</p>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon adduserid"></span>
            <input type="text" class="form-control" v-model="userid" placeholder="아이디">
          </div>
          <span class="text-red">{{userrmsg}}</span>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon addpassword"></span>
            <input type="password" class="form-control" v-model="password" placeholder="비밀번호" @keydown.enter.prevent="clickLogin">
          </div>
          <span class="text-red">{{pswerrmsg}}</span>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block" @click="clickLogin">로그인</button>
        </div>
        <div class="form-group row">
          <div class="col-sm-4 clear-right">
            <label class="chklabel">
              ID 저장
              <input type="checkbox" v-model="remem">
              <span class="markbg"></span>
            </label>
          </div>
          <div class="col-sm-8 text-right clear-left">
            <router-link to="/findpsw" class="plink">비밀번호 찾기</router-link>
            <router-link to="/signup" class="plink" style="margin-left: 10px;">관리자 가입 신청</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {rescode, cookielbl} from '../../lib/consts'
import { reqPost } from "../../lib/request";
import { adminapi } from "../../lib/api";
import routepath from '../../lib/routes';
export default {
  data() {
    return {
      userid: '',
      userrmsg:"",
      password: '',
      pswerrmsg:"",
      remem:true
    }
  },
  updated() {
  },
  created() {
    this.userid = window.localStorage.getItem(cookielbl.remem);
  },
  methods: {
    clickLogin() {
      if(this.userid != "" && this.password != "")
      {
        reqPost(adminapi.base, adminapi.login, "", {username:this.userid, password:this.password}, (data)=>{
          console.log(data);
          if(data != null)
          {
              if(data.code == rescode.REQ_SUCCESS)
              {
                  var val = data.data;
                  // Check local storage to handle refreshes
                  var authdata = {token:val.tokens.access.token, expiresIn:val.tokens.access.expiresIn, expiresAt: val.tokens.access.expiresIn + Math.floor(Date.now()/1000),
                    refresh:val.tokens.refresh.token, refreshexpiresIn:val.tokens.refresh.expiresIn, refreshexpiresAt: val.tokens.refresh.expiresIn + Math.floor(Date.now()/1000),
                    userRole:val.userRole};

                  if (window.localStorage) {
                      window.localStorage.setItem(cookielbl.authinf, JSON.stringify(authdata));
                  }
                  if(this.remem){
                      window.localStorage.setItem(cookielbl.remem, this.userid);
                  }else{
                      window.localStorage.setItem(cookielbl.remem, "");
                  }
                  authdata.userid = this.userid;
                  this.$store.dispatch('dologin', authdata);

                  this.isLoginSuccess(data.code, data.message, data.data.userRole === 'AGENT');
              } else {
                this.userrmsg = data.message;
              }
          }else{
              this.userrmsg = "로그인 실패 되었습니다.";
          }
        });
      }else{
        if(this.userid == "")
        {
          this.userrmsg = "아이디를 입력하세요.";
        }else{
          this.pswerrmsg = "비밀번호를 입력하세요.";
        }
      }
    },
    isLoginSuccess(val, message, isAgent){
      if(val === rescode.REQ_SUCCESS){
        if (isAgent) {
          this.$router.push(routepath.agentstoresettlement);
        } else {
          this.$router.push('/');
          // window.location.href = '/';
        }
      }else{
        this.userrmsg = message;
        this.pswerrmsg = "";
      }
    }
  }
}
</script>

<style scoped>
.login-page{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.box-title{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.plink{
  padding: 5px 0px;
  border-bottom: 1px solid #333333;
  color: #333333;
}

.adduserid{
  background-image: url("/static/img/uaserid.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addpassword{
  background-image: url("/static/img/password.png");
  background-repeat: no-repeat;
  background-position: center;
}

.btn{
  background-color: #333333;
  border-radius: 100px;
}

.clear-right{
  padding-right: 0px;
}

.clear-left{
  padding-left: 0px;
}

.chklabel {
  display: block;
  position: relative;
  padding-left: 25px;
  color: #333333;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chklabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.markbg {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #33333333;
}

.chklabel:hover input ~ .markbg {
  background-color: #ccc;
}

.chklabel input:checked ~ .markbg {
  background-color: #333333;
}

.markbg:after {
  content: "";
  position: absolute;
  display: none;
}

.chklabel input:checked ~ .markbg:after {
  display: block;
}

.chklabel .markbg:after {
  left: 5px;
  top: 3px;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-group-addon, .form-control{
  border: 0px;
}

.form-control{
  padding: 6px 0px;
}

.input-group{
  border-bottom: 1px solid #b2b2b2;
}
</style>
