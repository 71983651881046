<template>
  <section class="content">

  </section>
</template>

<script>
export default {
  components: {},
  created() {
    
  },
  watch: {},
  data() {
  },
  computed: {
  },
  mounted() {
    // this.$router.push(localStorage.reloadurl);
  },
  beforeUpdate()
  {
      
  },
  methods: {
  },
};
</script>