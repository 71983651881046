<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-header">
        <h4>카카오톡 예약하기 입점정보</h4>
      </div>
      <div class="box-body form-horizontal">
        <div class="form-group">
          <label class="col-sm-2 control-label">사업자번호</label>
          <div class="col-sm-10">
            <div class="input-group" style="width:100%">
              {{businessCode}}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">카카오톡 채널</label>
          <div class="col-sm-10">
            <div class="input-group" style="width:100%">
              {{channelName}} ({{channelId}})
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">연동신청 매장</label>
          <div class="col-sm-10">
            <div class="input-group" style="width:100%">
                <table class="table table-hover text-nowrap mt-2 text-center" style="width: 40%">
                  <thead>
                  <tr>
                    <th>브랜드</th>
                    <th>매장</th>
                    <th>매장 운영상태</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(stitem, stidx) in kakaoStoreList" :key="stidx">
                    <td>{{stitem.brandName}}</td>
                    <td>{{stitem.storeName}}</td>
                    <td>
                      <label>
                        <button class="btn btn-sm" :class="stitem.enabled? 'btn-success':'btn-custom-dark'" >
                        {{ stitem.enabled ? '활성화' : '비활성화' }}
                        </button>
                      </label>
                    </td>
<!--                    <td>-->
<!--                      <input v-if="stitem.storePartnerLinkStatus != 'NONE' " type="button" class="btn" :class="stitem.storePartnerLinkStatus =='LINKED'? 'btn-success':'btn-dark'"-->
<!--                             :value="stitem.storePartnerLinkStatus =='LINKED'? '연동중' : '신청중'" />-->
<!--                    </td>-->
                  </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">파트너명(업체명)</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" v-model="partnerName" style="width: 90%"/>
            <div class="check-item-length ">{{ partnerName.length }}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">사업자명</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" v-model="businessName" style="width: 90%"/>
            <div class="check-item-length ">{{ businessName.length }}/20 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">대표자명</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" v-model="representativeName" style="width: 90%"/>
            <div class="check-item-length ">{{ representativeName.length }}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">업태</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" v-model="businessCategory" style="width: 90%"/>
            <div class="check-item-length ">{{businessCategory.length}}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">종목</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control" v-model="businessCategoryItem" style="width: 90%"/>
            <div class="check-item-length ">{{businessCategoryItem.length}}/50 자</div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">주소</label>
          <div class="col-sm-10">
            <div class="d-flex" style="margin-bottom: 4px">
              <input type="text" class="form-control mr-2" disabled :value="zonecode" style="width: 25%" />
              <AddressSearch style="display:inline-flex" :detailAddress="detailAddress"
                             :onChange="onSetAddress" @detailaddrEvechiild="detailaddrEve" />
            </div>
            <div class="d-flex">
                <input type="text" class="form-control mr-2" disabled :value="roadAddress" style="width: 25%" />
                <input type="text" class="form-control" v-model="detailAddress" style="width: 25%" />
            </div>

          </div>
        </div>
      </div>
      <div v-if="kakaoStatus !== 'Cancel' && kakaoStatus !== 'Approve'" class="box-footer text-center">
        <button type="button" v-if="kakaoStatus === 'RegisterRequest'" class="btn btn-default" @click="openModal" >입점반려</button>
        <button type="button" v-if="kakaoStatus === 'RegisterRequest' || kakaoStatus === 'Reject'" class="btn btn-default" style="margin-left:10px" @click="cancelRequest" >신청취소</button>
        <button type="button" v-if="kakaoStatus === 'RegisterRequest' || kakaoStatus === 'Reject'" class="btn btn-default" style="margin-left:10px" @click="regRequest">파트너 등록</button>
      </div>
    </div>
    <div class="modal"  v-if="rejectToggle" style="display: block; ">
      <div class="modal-dialog modal-xl" style="width: 50%;">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">심사반려 사유</div>
          <div class="modal-body form-horizontal">
            <textarea class="form-control" style="width: 100%" v-model="rejectReason" rows="7">

            </textarea>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default margin-r-5" @click="closeModal">닫기</button>
              <button type="button" class="btn btn-default margin-r-5" @click="rejectRequest">입점반려</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  rescode,
  menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {
  reqGet, reqPost
} from "../../../lib/request";
import {
  brandapi
} from "../../../lib/api";
import {
  DateFormat
} from "../../../lib/utility";
import Multiselect from "vue-multiselect";
import AddressSearch from "@/components/AddressSearch.vue";
export default {
  components: {
    AddressSearch,
    Multiselect,
    Pagination,
  },
  created() {
    if (localStorage.initval == "1") {
      if (localStorage.BrdMng_keywordType) {
        this.keywordType = localStorage.BrdMng_keywordType;
      }
      if (localStorage.BrdMng_keyword) {
        this.keyword = localStorage.BrdMng_keyword;
      }
      if (localStorage.BrdMng_Number) {
        this.pageNumber = parseInt(localStorage.BrdMng_Number);
      }
      if (localStorage.BrdMng_Size) {
        this.pageSize = parseInt(localStorage.BrdMng_Size);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_KKOR,
      title: "",
    });
    this.partnerId = this.$route.query.partnerId;
  },
  watch: {
    keywordType(newVal) {
      localStorage.BrdMng_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.BrdMng_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.BrdMng_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.BrdMng_Size = newsize;
    },
  },
  data() {
    return {
      partnerId: "",
      buttonDissabled : false,
      rejectToggle : false,
      brandCodes: [],
      optionss: [],
      brandCode:"",
      roadAddress:"",
      zonecode : "",
      detailAddress:"",
      storeType:null,
      brandNameContaining:"",
      businessCode: "",
      selectedKakaoChannel:"",
      kakaoChannelList:[],
      kakaoStoreList:[],
      partnerName:"",
      businessName:"",
      representativeName:"",
      businessCategory:"",
      businessCategoryItem:"",
      rejectReason:"",
      channelName:"",
      kakaoStatus : "",
      channelId:"",
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
    },
  },
  mounted() {
    this.getInfo();
    this.getStoreList();
  },
  methods: {
    onSetAddress(addinfo, detailAddress) {
      this.roadAddress = addinfo.address;
      this.zonecode = addinfo.zonecode;
      this.detailAddress = detailAddress;
    },
    detailaddrEve(detailAddress) {
      this.detailAddress = detailAddress;
    },
    getInfo(){
      reqGet(
        brandapi.base,
        brandapi.kakaoReserveStoreShow + "?partnerId=" + this.partnerId,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.brandCodes = result.data;
            this.businessName = result.data.bizName;
            this.businessCode = result.data.bizNumber;
            this.channelName = result.data.channelName;
            this.channelId  =  result.data.channelId;
            this.partnerName = result.data.partnerName;
            this.representativeName= result.data.representativeName;
            this.businessCategory = result.data.businessCategory;
            this.businessCategoryItem = result.data.businessCategoryItem;
            this.zonecode = result.data.zipCode;
            this.roadAddress = result.data.baseAddress;
            this.detailAddress = result.data.detailAddress;
            this.kakaoStatus  = result.data.status;
          }else {
            alert(result.message);
          }
        }
      );
    },
    getStoreList(){
      reqGet(
        brandapi.base,
        brandapi.kakaoChannenlLinkedSearch + "?partnerId=" + this.partnerId,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.kakaoStoreList = result.data
          }else {
            alert(result.message);
          }
        }
      );
    },
    openModal(){
      this.rejectToggle = true;
    },
    closeModal(){
      this.rejectToggle = false;
    },
    cancelRequest(){
      if(confirm("입점신청을 취소하시겠습니까?")){
        reqGet(
          brandapi.base,
          brandapi.cancelKakaoReserveStore + "?partnerId=" + this.partnerId,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              alert(result.message)
              this.$router.push(this.routes.kakaoRequest)

            }else {
              alert(result.message);
            }
          }
        );
      }
    },
    regRequest(){
      if(!this.businessName || this.partnerName.length>50){
        alert("파트너명(업체명)을 입력해주세요.")
        return
      } else if(!this.businessName || this.partnerName.length>20){
        alert("사업자명을 입력해주세요.")
        return
      } else if(!this.representativeName || this.partnerName.length>50){
        alert("대표자명을 입력해주세요.")
        return
      } else if(!this.businessCategory || this.partnerName.length>50){
        alert("업태를 입력해주세요.")
        return
      } else if(!this.businessCategoryItem || this.partnerName.length>50){
        alert("종목을 입력해주세요.")
        return
      } else if(!this.zonecode || !this.roadAddress || !this.detailAddress){
        alert("주소를 입력해주세요.")
        return
      }

      //Todo : 임시작업본 api 수정본 나오면 추가 작업 /mng/store/adm/kakao/partner/register

      const regData = {
        id: this.partnerId,
        storeCodes : this.kakaoStoreList, //스토어 코드 리스트
        partnerName : this.partnerName, // 파트너이름
        bizNumber : this.businessCode, // 사업자번호
        bizName : this.businessName, // 사업자명
        representativeName: this.representativeName, // 대표자명
        zipCode: this.zonecode,
        baseAddress: this.roadAddress,
        detailAddress: this.detailAddress,
        businessCategory: this.businessCategory,
        businessCategoryItem: this.businessCategoryItem,
        channelSearchId: this.selectedKakaoChannel.id,
        channelName: this.selectedKakaoChannel.name,
      }

      reqPost(
        brandapi.base,
        brandapi.approveKakaoReserveStore,
        this.authToken,
        regData,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            alert(result.message)
            this.$router.push(this.routes.kakaoRequest)

          }else {
            alert(result.message);
          }
        }
      )

    },
    rejectRequest(){
      reqPost(
        brandapi.base,
        brandapi.rejectKakaoReserveStore ,
        this.authToken,
        {
          id: this.partnerId,
          rejectReason: this.rejectReason

        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              alert(data.message)
              this.$router.push(this.routes.kakaoRequest)
            }
          }
        }
      );
    },
  },
};
</script>
<style scoped>

.check-item-length {
  border: solid 1px #d2d6de;
  height: 34px;
  align-content: center;
  padding-right: 16px;
  padding-left: 16px;
  text-align: center;
}
.d-flex{
  display: flex;
}

.btn-custom-dark{
  background-color: #6c757d; color: white;
}

</style>
