import VueRouter from 'vue-router';
import routepath from '../lib/routes';
import Dash from '../views/Dash.vue';
import Login from '../views/auth/Login.vue';
import Refresh from '../views/refresh/refresh.vue';
import LoginOTP from '../views/auth/LoginOTP.vue';
import Findpsw from '../views/auth/Findpsw.vue';
import Signup from '../views/auth/Signup.vue';
import Home from '../views/home/Home.vue';
import EditMngInf from '../views/home/EditMngInf.vue';
import Resetpsw from '../views/home/Resetpsw.vue';
import Ordhis from '../views/ordhis/Ordhis.vue';
import DetailOrderView from '../views/ordhis/DetailOrderView';
import OrdCancel from '../views/ordhis/Cancel.vue';
import OrdRefund from '../views/ordhis/Refund.vue';
import PayCancelFail from '../views/ordhis/CancelFail.vue';
import Issue from '../views/issue/issue/Issue.vue';
import Price from '../views/issue/issue/Price.vue';
import Point from '../views/issue/issue/Point.vue';
import Reservation from '../views/issue/issue/Reservation.vue';
import StoreReservation from "../views/issue/issue/StoreReservation.vue";
import IssueCancel from '../views/issue/cancel/Cancel.vue';
import IssueCancelPrice from '../views/issue/cancel/Price.vue';
import IssueCancelreservation from '../views/issue/cancel/reservation.vue';
import IssueCancelStoreReservation from "../views/issue/cancel/storeReservation.vue";
import IssueCancelPoint from '../views/issue/cancel/Point.vue';
import IssueUsehis from '../views/issue/usehis/Usehis.vue';
import IssueUsehisPrice from '../views/issue/usehis/Price.vue';
import IssueReservation from '../views/issue/usehis/Reservation.vue';
import IssueStamp from '../views/issue/Stamp.vue';
import ProdNewReg from '../views/product/reg/New';
import ProdReqInspec from '../views/product/reg/Reqinspec';
import ViewProdInfo from '../views/product/reg/ViewProdInfo';
import ProdEditInfo from '../views/product/reg/Editinfo';
import EditProdInf from '../views/product/reg/EditProdInfo';
import ViewB2bimg from '../views/product/reg/ViewB2bimg';
import ViewB2bimgFull from '../views/product/reg/ViewB2bimgFull';

import OperEditState from '../views/product/oper/EditState';
import OperCoupon from '../views/product/oper/CouponNum';
import OperOption from '../views/product/oper/OptionMng';
import OperOutCode from '../views/product/oper/OutCode';
import B2bDeploychnl from '../views/product/b2b/DeployChannel';
import DpChnnlDetail from '../views/product/b2b/DpChnnlDetail';
import ChannelProdReg from '../views/product/b2b/ChannelProdReg';
import B2bCodeMatch from '../views/product/b2b/CodeMatch';
import B2bChannelStt from '../views/product/b2b/ChannelState';
import ProdValidity from '../views/product/Validity';
import Book from '../views/book/Book';
import DetailBookPage from '../views/book/DetailBookPage';
import BrandMng from '../views/brand/BrandMng';
import RegBrand from '../views/brand/RegBrand';
import EditBrand from '../views/brand/EditBrand';
import StoreList from '../views/brand/store/StoreList';
import EditStore from '../views/brand/store/EditStore';
import RegStore from '../views/brand/store/RegStore';
import StoreReqInspec from '../views/brand/store/ReqInspec';
import Review from '../views/brand/Review';
import ReqCheckStore from '../views/brand/store/ReqCheckStore';
import GenMem from '../views/member/GenMem';
import MemDetail from '../views/member/MemDetail';
import ManagerList from '../views/member/mng/ManagerList';
import RegManager from '../views/member/mng/RegManager';
import SiteDsp from '../views/site/SiteDSP';
import DetailDSPPage from '../views/site/DetailDSPPage';
import SiteMain from '../views/site/SiteMain';
import DetailMainPage from '../views/site/DetailMainPage';
import SitePromotion from '../views/site/Promotion';
import EditPromotion from '../views/site/EditPromotion';
import SiteIntro from '../views/site/Intro';
import SiteHotplace from '../views/site/Hotplace';
import EditHotplace from '../views/site/EditHotplace';
import StoreCalcuHis from '../views/calculation/StoreHis';
import BillIssue from '../views/calculation/Issue';
import DepositPrice from '../views/calculation/deposit/Deposit';
import DepositFinish from '../views/calculation/deposit/Finishe';
import DepositHistory from '../views/calculation/deposit/History';
import DealTarget from '../views/stat/deal/Target';
import DealChannel from '../views/stat/deal/Channel';
import DealMonth from '../views/stat/deal/ChannelMonth';
import DealProd from '../views/stat/deal/Production';
import DealBrand from '../views/stat/deal/Brand';
import DealBrandMonth from '../views/stat/deal/BrandMonth';
import UseTarget from '../views/stat/use/Target';
import UseChannel from '../views/stat/use/Channel';
import UseChannelMonth from '../views/stat/use/ChannelMonth';
import UseBrand from '../views/stat/use/Brand';
import UseBrandMonth from '../views/stat/use/BrandMonth';
import UseProduction from '../views/stat/use/Production';
import TargetPrice from '../views/stat/TargetPrice';
import Notice from '../views/bod/Notice';
import EditNotice from '../views/bod/EditNotice';
import CusCenter from '../views/bod/CusCenter';
import Ques from '../views/bod/Ques';
import Faq from '../views/bod/Faq';
import EditFaq from '../views/bod/EditFaq';
import ReqDocu from '../views/bod/ReqDocu';
import Telephone from '../views/bod/Telephone';
import B2bInterworking from '../views/dev/B2bInterworking';
import Pos from '../views/dev/Pos';
import AgentStoreSettlement from "../views/agent/AgentStoreSettlement";
import NoticeAdmin from "@/views/bod/NoticeAdmin";
import EditNoticeAdmin from "@/views/bod/EditNoticeAdmin";
import moment from 'moment';
import DiscountAddPage from '../views/discountCoupon/discountAddPage';
import DiscountApprovalPage from '../views/discountCoupon/discountApprovalPage';
import DiscountListPage from '../views/discountCoupon/discountListPage';
import discountIssuanceStatus from '../views/discountStatus/discountIssuanceStatus';
import discountUseStatus from '../views/discountStatus/discountUseStatus';
import AlimTalkDev from "@/views/dev/AlimTalkDev";
import ChannelDev from "@/views/dev/ChannelDev.vue";
import alimtalkMng from "@/views/brand/AlimtalkMng.vue";
import kakaoRequest from "@/views/brand/kakaoReservation/KakaoRequestMng.vue"
import kakaoPartnerMng from "@/views/brand/kakaoReservation/partnerMng.vue"
import reqKakaoRequest from "@/views/brand/kakaoReservation/ReqKakaoReservationRequest.vue"
import kakaoPartnerInfoDetail from "@/views/brand/kakaoReservation/KakaoPartnerInfoDetail.vue"
import kakaoReservationRequestDetail from "@/views/brand/kakaoReservation/KakaoReservationRequestDetail.vue";


const routes = [
  {
    //로그인
    path: routepath.login,
    component: Login,
  },
  {
    //로그인
    path: routepath.loginotp,
    component: LoginOTP,
  },
  {
    //회원가입
    path: routepath.signup,
    component: Signup,
  },
  {
    //비밀번호 찾기
    path: routepath.findpsw,
    component: Findpsw,
  },
  {
    //홈
    path: "/",
    component: Dash,
    meta: { requiresAuth: true },
    children: [
      {
        path: routepath.home,
        alias: "",
        component: Home,
        meta: { description: "홈" },
      },
      {
        path: routepath.refresh,
        alias: "",
        component: Refresh,
        meta: { description: "리도르" },
      },
      {
        path: routepath.resetpsw,
        component: Resetpsw,
        meta: { description: "비밀번호 변경" },
      },
      {
        //
        path: routepath.editmnginf,
        component: EditMngInf,
        meta: { description: "관리자 정보 변경" },
      },
      {
        //주문관리
        path: routepath.ordhis,
        component: Ordhis,
        meta: { description: "주문내역조회" },
      },
      {
        path: routepath.detailorder,
        component: DetailOrderView,
        meta: { description: "주문내역상세보기" },
      },
      {
        path: routepath.ordcnlhis,
        alias: "",
        component: OrdCancel,
        meta: { description: "취소요청조회" },
      },
      {
        path: routepath.refundhis,
        component: OrdRefund,
        meta: { description: "환불신청조회" },
      },
      {
        path: routepath.paycnlfailhis,
        component: PayCancelFail,
        meta: { description: "결제취소 실패내역" },
      },
      {
        //발급관리
        path: routepath.isscupon,
        component: Issue,
        meta: { description: "발급내역 / 모바일 쿠폰" },
      },
      {
        path: routepath.issprice,
        alias: "",
        component: Price,
        meta: { description: "발급내역 / 모바일 금액권" },
      },
      {
        path: routepath.isspoint,
        alias: "",
        component: Point,
        meta: { description: "발급내역 / 포인트권" },
      },
      {
        path: routepath.issreservation,
        alias: "",
        component: Reservation,
        meta: { description: "발급내역 / 예약필수쿠폰" },
      },
      {
        path: routepath.storereservation,
        alias: "",
        component: StoreReservation,
        meta: { description: "발급내역 / 매장예약" },
      },
      {
        path: routepath.isscnlcupon,
        component: IssueCancel,
        meta: { description: "발급취소내역 / 모바일쿠폰" },
      },
      {
        path: routepath.isscnlprice,
        alias: "",
        component: IssueCancelPrice,
        meta: { description: "발급내역 / 모바일 금액권" },
      },
      {
        path: routepath.isscnlreservation,
        alias: "",
        component: IssueCancelreservation,
        meta: { description: "발급취소내역 / 예약필수쿠폰" },
      }, //IssueCancelStoreReservation
      {
        path: routepath.storecnlreservation,
        alias: "",
        component: IssueCancelStoreReservation,
        meta: { description: "발급취소내역 / 매장예약" },
      },
      {
        path: routepath.isscnlpoint,
        alias: "",
        component: IssueCancelPoint,
        meta: { description: "발급취소내역 / 포인트권" },
      },
      {
        path: routepath.issusecupon,
        component: IssueUsehis,
        meta: { description: "사용이력 / 모바일쿠폰" },
      },
      {
        path: routepath.issuseprice,
        alias: "",
        component: IssueUsehisPrice,
        meta: { description: "사용이력 / 모바일 금액권" },
      },
      {
        path: routepath.issusereservation,
        alias: "",
        component: IssueReservation,
        meta: { description: "사용이력 / 예약필수쿠폰" },
      },
      {
        path: routepath.issstamp,
        component: IssueStamp,
        meta: { description: "인지세관리" },
      },
      {
        path: routepath.prdnewreg,
        component: ProdNewReg,
        meta: { description: "신규상품등록" },
      },
      {
        path: routepath.prdreqinspec,
        component: ProdReqInspec,
        meta: { description: "상품검수신청" },
      },
      {
        path: routepath.viewprodinf,
        component: ViewProdInfo,
        meta: { description: "상품검수 상세보기" },
      },
      {
        path: routepath.prdmdfinfo,
        component: ProdEditInfo,
        meta: { description: "상품정보수정" },
      },
      {
        path: routepath.editprodinf,
        component: EditProdInf,
        meta: { description: "상품정보수정" },
      },
      {
        path: routepath.viewb2bimg,
        component: ViewB2bimg,
        meta: { description: "B2B이미지 미리보기" },
      },
      {
        path: routepath.ViewB2bimgFull,
        component: ViewB2bimgFull,
        meta: { description: "B2B전체이미지 미리보기" },
      },
      {
        path: routepath.prdopermdf,
        component: OperEditState,
        meta: { description: "판매상태변경" },
      },
      {
        path: routepath.prdoption,
        component: OperOption,
        meta: { description: "대표옵션관리" },
      },
      {
        path: routepath.prdoutcode,
        component: OperOutCode,
        meta: { description: "외부코드관리" },
      },
      {
        path: routepath.prdcupon,
        component: OperCoupon,
        meta: { description: "쿠폰번호 커스텀" },
      },
      {
        path: routepath.b2bdeploy,
        component: B2bDeploychnl,
        meta: { description: "채널배포요청" },
      },
      {
        path: routepath.dpchnnldetail,
        component: DpChnnlDetail,
        meta: { description: "채널배포요청 상세피이지" },
      },
      {
        path: routepath.channelprodreg,
        component: ChannelProdReg,
        meta: { description: "채널 상품등록" },
      },
      {
        path: routepath.b2bcode,
        component: B2bCodeMatch,
        meta: { description: "코드매칭관리" },
      },
      {
        path: routepath.b2bchannel,
        component: B2bChannelStt,
        meta: { description: "채널오픈현황" },
      },
      {
        path: routepath.prdvalidity,
        component: ProdValidity,
        meta: { description: "유효기간 만료예정" },
      },
      {
        path: routepath.book,
        component: Book,
        meta: { description: "예약내역조회" },
      },
      {
        path: routepath.detailbook,
        component: DetailBookPage,
        meta: { description: "예약내역조회" },
      },
      {
        path: routepath.brndmng,
        component: BrandMng,
        meta: { description: "브랜드관리" },
      },
      {
        path: routepath.regbrand,
        component: RegBrand,
        meta: { description: "브랜드등록" },
      },
      {
        path: routepath.editbrand,
        component: EditBrand,
        meta: { description: "브랜드수정" },
      },
      {
        path: routepath.storelist,
        component: StoreList,
        meta: { description: "매장리스트" },
      },
      {
        path: routepath.editstore,
        component: EditStore,
        meta: { description: "매장정보수정" },
      },
      {
        path: routepath.regstore,
        component: RegStore,
        meta: { description: "매장등록" },
      },
      {
        path: routepath.storeinspec,
        component: StoreReqInspec,
        meta: { description: "검수신청" },
      },
      {
        path: routepath.review,
        component: Review,
        meta: { description: "리뷰관리" },
      },
      {
        path: routepath.discountAddPage,
        component: DiscountAddPage,
        meta: { description: "할인쿠폰등록" },
      },
      ,
      {
        path: routepath.discountApprovalPage,
        component: DiscountApprovalPage,
        meta: { description: "할인쿠폰승인" },
      },
      {
        path: routepath.discountListPage,
        component: DiscountListPage,
        meta: { description: "할인쿠폰리스트" },
      },
      {
        path: routepath.reqcheckstore,
        component: ReqCheckStore,
        meta: { description: "매장등록신청검수" },
      },
      {
        path: routepath.genmem,
        component: GenMem,
        meta: { description: "일반회원" },
      },
      {
        path: routepath.memdetail,
        component: MemDetail,
        meta: { description: "회원상세정보" },
      },
      {
        path: routepath.mnglist,
        component: ManagerList,
        meta: { description: "관리자 리스트" },
      },
      {
        path: routepath.regmng,
        component: RegManager,
        meta: { description: "관리자 등록신청" },
      },
      {
        path: routepath.dsp,
        component: SiteDsp,
        meta: { description: "기획전" },
      },
      {
        path: routepath.detaildsp,
        component: DetailDSPPage,
        meta: { description: "기획전상세보기" },
      },
      {
        path: routepath.dspmain,
        component: SiteMain,
        meta: { description: "메인페이지" },
      },
      {
        path: routepath.detailmain,
        component: DetailMainPage,
        meta: { description: "메인 상세페이지" },
      },
      {
        path: routepath.promotion,
        component: SitePromotion,
        meta: { description: "프로모션" },
      },
      {
        path: routepath.editpromotion,
        component: EditPromotion,
        meta: { description: "프로모션" },
      },
      {
        path: routepath.intro,
        component: SiteIntro,
        meta: { description: "인트로" },
      },
      {
        path: routepath.hotplace,
        component: SiteHotplace,
        meta: { description: "핫플레이스" },
      },
      {
        path: routepath.editHotplace,
        component: EditHotplace,
        meta: { description: "핫플레이스" },
      },
      {
        path: routepath.storepayhis,
        component: StoreCalcuHis,
        meta: { description: "매장별 정산내역" },
      },
      {
        path: routepath.billissue,
        component: BillIssue,
        meta: { description: "세금계산서 발행" },
      },
      {
        path: routepath.deposit,
        component: DepositPrice,
        meta: { description: "금액확정" },
      },
      {
        path: routepath.depfinish,
        component: DepositFinish,
        meta: { description: "입금완료처리" },
      },
      {
        path: routepath.dephis,
        component: DepositHistory,
        meta: { description: "입금내역조회" },
      },
      {
        path: routepath.agentstoresettlement,
        component: AgentStoreSettlement,
        meta: { description: "매장별 정산내역" },
      },
      {
        path: routepath.dealtargt,
        component: DealTarget,
        meta: { description: "거래현황조회 목표대비" },
      },
      {
        path: routepath.dealchannel,
        component: DealChannel,
        meta: { description: "거래현황조회 채널별 (기간)" },
      },
      {
        path: routepath.dealchannelmonth,
        component: DealMonth,
        meta: { description: "거래현황조회 채널별 (월별)" },
      },
      {
        path: routepath.dealprod,
        component: DealProd,
        meta: { description: "거래현황조회 상품별" },
      },
      {
        path: routepath.dealbrand,
        component: DealBrand,
        meta: { description: "거래현황조회 브랜드별 (기간)" },
      },
      {
        path: routepath.dealbrandmonth,
        component: DealBrandMonth,
        meta: { description: "거래현황조회 브랜드별 (월별)" },
      },
      {
        path: routepath.usetarget,
        component: UseTarget,
        meta: { description: "사용현황조회 목표대비" },
      },
      {
        path: routepath.usechannel,
        component: UseChannel,
        meta: { description: "사용현황조회 채널별 (기간)" },
      },
      {
        path: routepath.usechannelmonth,
        component: UseChannelMonth,
        meta: { description: "사용현황조회 채널별 (월별)" },
      },
      {
        path: routepath.usebrand,
        component: UseBrand,
        meta: { description: "사용현황조회 브랜드별 (기간)" },
      },
      {
        path: routepath.usebrandmonth,
        component: UseBrandMonth,
        meta: { description: "사용현황조회 브랜드별 (월별)" },
      },
      {
        path: routepath.useprod,
        component: UseProduction,
        meta: { description: "사용현황조회 상품별" },
      },
      {
        path: routepath.discountIssuanceStatus,
        component: discountIssuanceStatus,
        meta: { description: "할인쿠폰 발급현황조회" },
      },
      {
        path: routepath.discountUseStatus,
        component: discountUseStatus,
        meta: { description: "할인쿠폰 사용현황조회" },
      },
      {
        path: routepath.statprice,
        component: TargetPrice,
        meta: { description: "목표금액관리" },
      },
      {
        path: routepath.notice,
        component: Notice,
        meta: { description: "공지사항" },
      },
      {
        path: routepath.editnotice,
        component: EditNotice,
        meta: { description: "공지사항 수정" },
      },
      {
        path: routepath.cuscenter,
        component: CusCenter,
        meta: { description: "고객센터" },
      },
      {
        path: routepath.ques,
        component: Ques,
        meta: { description: "제휴문의" },
      },
      {
        path: routepath.faq,
        component: Faq,
        meta: { description: "자주묻는 질문" },
      },
      {
        path: routepath.editfaq,
        component: EditFaq,
        meta: { description: "자주묻는 질문 수정" },
      },
      {
        path: routepath.reqdocu,
        component: ReqDocu,
        meta: { description: "증빙서류신청" },
      },
      {
        path: routepath.telephone,
        component: Telephone,
        meta: { description: "전화상담 접수관리" },
      },
      {
        path: routepath.noticeadmin,
        component: NoticeAdmin,
        meta: { description: "하이테이블 공지사항" },
      },
      {
        path: routepath.editnoticeadmin,
        component: EditNoticeAdmin,
        meta: { description: "하이테이블 공지사항 수정" },
      },
      {
        path: routepath.b2binterworking,
        component: B2bInterworking,
        meta: { description: "B2B 연동 수동처리" },
      },
      {
        path: routepath.pos,
        component: Pos,
        meta: { description: "POS 연동관리" },
      },
      {
        path: routepath.channelDev,
        component: ChannelDev,
        meta: { description: "채널 연동관리" },
      },
      {
        path: routepath.alimTalkDev,
        component: AlimTalkDev,
        meta: { description: "개발서버 알림톡 발송관리" },
      },
      {
        path: routepath.alimtalkMng,
        component: alimtalkMng,
        meta: { description: "알림톡 관리" },
      },
      {
        path: routepath.kakaoRequest,
        component: kakaoRequest,
        meta: { description: "입점매장 관리" },
      },
      {
        path: routepath.kakaoPartnerMng,
        component: kakaoPartnerMng,
        meta: { description: "파트너 관리" },
      },
      {
        path: routepath.reqKakaoRequest,
        component: reqKakaoRequest,
        meta: { description: "카카오톡 예약하기 입점신청" },
      },
      {
        path: routepath.kakaoReservationRequestDetail,
        component: kakaoReservationRequestDetail,
        meta: { description: "카카오톡 예약하기 입점상세" },
      },
      {
        path: routepath.kakaoPartnerInfoDetail,
        component: kakaoPartnerInfoDetail,
        meta: { description: "파트너 관리 상세" },
      },

    ],
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(function (to, from, next) {
    // console.log(from.meta.description);
    // console.log(from.meta.description);
    if(to.meta.description !== from.meta.description){
        if(from.meta.description == undefined){

        }else{
            localStorage.CusCen_toDate = moment().format("YYYY-MM-DD");
        }
    }
    next();
  });
// router.beforeEach(((to, from, next) => {
//   if (store.state.userRole === 'AGENT') {
//     if( to.path === '/' + routepath.login ||
//         to.path === '/' + routepath.loginotp ||
//         to.path === '/' + routepath.signup ||
//         to.path === '/' + routepath.findpsw ||
//         to.path === '/' + routepath.refresh ||
//         to.path === '/' + routepath.resetpsw ||
//         to.path === '/' + routepath.editmnginf ||
//         to.path === '/' + routepath.agentstoresettlement) {
//       next();
//     } else {
//       next(routepath.agentstoresettlement);
//     }
//   } else {
//     next();
//   }
// }));

export default router;
