export const rescode = {
    REQ_SUCCESS:"OK",//요청이 성공하였습니다.
    BAD_PARAMETER:"BAD_PARAMETER",//요청 파라미터가 잘못되었습니다.
    NOT_FOUND:"NOT_FOUND",//리소스를 찾지 못했습니다.
    UNAUTHORIZED:"UNAUTHORIZED",//권한이 없습니다.
    UNAUTHENTICATED:"UNAUTHENTICATED",//인증되지 않았습니다.
    UNAPPROVED:"UNAPPROVED",//승인되지 않았습니다.
    DUPLICATE:"DUPLICATE",//중복되었습니다.
    INVALID_ACCESS:"INVALID_ACCESS",//유효하지 않은 접근입니다.
    BUSY:"BUSY",//서비스가 지연되고 있습니다.
    SERVER_ERROR:"SERVER_ERROR",//서버 에러입니다.
    EXTERNAL_SERVER_ERROR:"EXTERNAL_SERVER_ERROR",//외부 서버 에러입니다.
}

export const cookielbl = {
    authinf:"authinf",
    remem:"rememusername"
}

export const imgtype = {
    BRAND:"BRAND",
    STORE:"STORE",
    SITE:"SITE",
    GOODS:"GOODS",
    REVIEW:"REVIEW",
    USER:"USER",
    B2B:"B2B",
    ETC:"ETC",
    DEV:"DEV"
}

export const menuval = {
    LV1_HOME:0,
    LV1_ORD:1,
    LV1_ISSUE:2,
    LV1_PRD:3,
    LV1_BOOK:4,
    LV1_BRD:5,
    LV1_MEM:6,
    LV1_DSP:7,
    LV1_PAY:8,
    LV1_STAT:9,
    LV1_BOD:10,
    LV1_AGENT:11,
    LV1_DEV:12,
    //발급관리
    LV2_ISS_ISS:110,//발급내역
    LV2_ISS_CCL:120,//취소내역
    LV2_ISS_USE:130,//사용내역
    //상품관리
    LV2_PRD_REG:140,//등록관리
    LV2_PRD_OPR:150,//운영관리
    LV2_PRD_B2B:160,//B2B 상품관리
    //제휴사 관리
    LV2_BRD_STR:170,//매장관리
    LV2_BRD_KKOR:171,//카카오톡예약하기
    //회원관리
    LV2_MEM_TNJ:180,//TNJ관리자
    //정산관리
    LV2_PAY_DPS:190,//입금관리
    //통계관리
    LV2_STT_DPL:200,//거래현황
    LV2_STT_USE:210,//사용현황
    LV1_DIS:230,
    LV2_DIS:260,
    LV2_STS_ISS:240,//할인쿠폰 발급현황조회
}

//예약상태
export const _bookingStatus = {
  BookingRequested: '예약신청',
  BookingRejected: '예약불가',
  Booked: '예약완료',
  CancelRequested: '취소신청',
  Cancelled: '취소완료',
  Visited: '방문완료',
  NoShow: '노쇼'
}

export const getBookingStatusText = (bookingStatus) => {
  return _bookingStatus[bookingStatus]
}
