<template>
<div>
    <!-- {{ value }}
    {{ fakeArray }} -->
    <div v-for="(rib,ribidx) in fakeArray" :key="ribidx" class="positon-r mouseC">
        <drop @drop="sumimagesort(ribidx, $event)">
            <drag :transfer-data="{ index: ribidx, mark:'subimgs' }">
                <div class="dropboxDiv sub">
                    <div class="thumbnail" v-on:dragover="allowDrop" v-on:drop="ondrop($event,ribidx)" @dragenter="onDragenter" v-bind:style="rib.url !== '' && [{'background-image':'url('+rib.url+')'}, {'background-size': 'cover'}]" @mouseover="mouseovers(ribidx)" @mouseleave="mouseleaves(ribidx)">
                        <div :class="'dropbox dropbox'+ribidx" @click="onChangeClick(ribidx)"></div>
                        <span v-show="rib.url == ''" style="font-size:30px;cursor: pointer;" @click="onChangeClick(ribidx)"><i class="fa fa-fw fa-plus"></i></span>
                        <input type="file" :ref="'fileInput' + ribidx" style="display:none;" accept="image/jpeg, image/png" @change="onUploadFiles" />
                        <div v-show="rib.url !== '' && mouseover[ribidx]" class="maskbg"></div>
                        <button type="button" v-show="rib.url !== '' && mouseover[ribidx]" class="btn btn-close" @click="onRemove(rib.url,ribidx,rib)"><i class="fa fa-fw fa-trash-o"></i></button>
                        <button type="button" v-show="rib.url !== ''" class="btn btn-download" @click="onDownloadClick(rib.url)"><i class="fa fa-fw fa-download"></i></button>
                    </div>
                </div>
            </drag>
        </drop>
    </div>
</div>
</template>

<script>
import {
    adminapi,
} from "../lib/api";
import {
    Drag,
    Drop
} from "vue-drag-drop";
import {
    ContainerClient
} from '@azure/storage-blob';
import {
    getfilename,
    reqPost
} from "../lib/request";
import {
    imgtype,
    rescode
} from "../lib/consts";
import axios from 'axios'
import Compressor from "compressorjs";

export default {
    components: {
        Drag,
        Drop
    },
    model: {
        prop: 'value',
    },
    props: {
        value: {
            type: [String, Array],
            required: true,
            default: "",
        },
        imgtype: {
            type: String,
            default: imgtype.GOODS,
        },
        index: {
            type: Number,
            default: 0,
        },
        change: {
            type: Function,
            default: () => {},
        },
        downlabel: {
            type: String,
            // required: true,
            default: "",
        },

    },
    data() {
        return {
            fileInput: null,
            mouseover: [false, false, false],
            imgwidth: 0,
            imgheight: 0,
            reqImgBackgroundChild: this.value ? this.value : '',
            fakeArray: this.value.length !== 0 ? this.value : [{
                "width": 0,
                "height": 0,
                "url": '',
            }],
            addidx: 0,
        };
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    watch: {

    },
    mounted() {
        console.log(this.reqImgBackgroundChild)
    },
    methods: {
        mouseovers(idx) {
            this.$set(this.mouseover, idx, true)
        },
        mouseleaves(idx) {
            this.$set(this.mouseover, idx, false)
        },
        sumimagesort(drop, drag) {
            if (drag !== undefined) {
                if (drag.mark != 'subimgs') {
                    return;
                }
                var t_array = this.fakeArray;
                this.fakeArray = [];
                var b = t_array[drag.index];
                t_array[drag.index] = t_array[drop];
                t_array[drop] = b;
                this.fakeArray = t_array;
                // this.$emit('getsubFileData', t_array)
            }
        },
        allowDrop(event) {
            event.preventDefault();
        },
        drag(event) { //드래그 시작시 호출 할 함수
            event.dataTransfer.setData("Text", event.target.id);
            const targetEl = document.getElementById('holder');
        },
        onDragenter(event) {
            event.preventDefault()
        },
        onDragover(event) {
            event.preventDefault()
        },
        ondrop(event, idx) {
            event.preventDefault();

            var data = event.dataTransfer.getData("Text");

            if (event.currentTarget._prevClass === 'dropImgBox') {} else {
                if (data === "") {
                    return;
                }
                let add = this.fakeArray.filter(function (item) {
                    return item.url !== '';
                });
                if (add.length === 3) {
                    return
                }
                if (this.fakeArray.length === 3) {
                    this.fakeArray.shift();
                }
                let url2 = document.getElementById(data).src;
                let width2 = document.getElementById(data).width;
                let height2 = document.getElementById(data).height;
                // this.$emit('newimgadd', '', url2, width2, height2);
                this.fakeArray.push({
                    "width": width2,
                    "height": height2,
                    "url": url2,
                    "idx": this.addidx,
                })
                console.log(this.fakeArray)
                this.addidx++;
                console.log(this.addidx)
            }
        },
        getAdminsas() {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.url = result.data.url;
                            this.container = result.data.container;
                            this.directory = result.data.directory;
                            this.expiresIn = result.data.expiresIn;
                        }
                    }
                }
            );
        },
        onChangeClick(idx) {
            this.$refs['fileInput' + idx][0].click();
        },
        compressImage(imageFile) {
            let qualityVal = 0,mw = 0;
            if (imageFile.size >= 90000) {
                qualityVal = 0.5;
                mw = 0;
            } else {
                qualityVal = 0.9;
                mw = 600;
            }

            return new Promise((resolve, reject) => {
                new Compressor(imageFile, {
                    maxWidth: mw,
                    quality: qualityVal,
                    success(result) {
                        resolve(result)
                    },
                    error(e) {
                        reject(e)
                    },
                });
            });
        },
        onUploadFiles(event, drag) {
            let imageFile = '';
            if (drag) {
                imageFile = event;
            } else {
                imageFile = event.target.files[0];
            }

            this.compressImage(imageFile).then((compressedFile) => {
                let reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = evt => {
                    let img = new Image();
                    img.onload = () => {
                        this.imgwidth = img.width;
                        this.imgheight = img.height;
                    }
                    img.src = evt.target.result;
                }

                reader.onerror = evt => {
                    console.error(evt);
                }

                if (this.fakeArray[0].url === '') {
                    this.onRegisterImage(compressedFile);
                } else {
                    this.onChangeImage(compressedFile);
                }
            }).catch((error) => {
                alert("이미지 리사이징 에러입니다.");
            });
            this.$refs['fileInput' + 0][0].value = ''
            // this.$refs.fileInput.value = ''
        },
        onRegisterImage(imageFile) {
            // console.log()
            if (this.fakeArray.length === 3) {
                this.fakeArray.shift()
            }
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            const containerClient = new ContainerClient(result.data.url);
                            var d = new Date();
                            var n = d.getTime();
                            let filename = n + '_' + imageFile.name;
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                            }).then((upfile) => {
                                let vm = this;
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                // this.$emit('newimgadd', '', uploadedUrl, vm.imgwidth, vm.imgheight);
                                this.fakeArray.push({
                                    "width": vm.imgwidth,
                                    "height": vm.imgheight,
                                    "url": uploadedUrl,
                                    "idx": 'uploadedUrl',
                                })

                            });
                        }
                    }
                }
            );
        },
        onChangeImage(imageFile) {
            if (this.fakeArray.length === 3) {
                this.fakeArray.shift()
            }
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            const containerClient = new ContainerClient(result.data.url);
                            var d = new Date();
                            var n = d.getTime();
                            let filename = n + '_' + imageFile.name;
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let vm = this;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                // vm.$emit('input', uploadedUrl);
                                // this.$emit('newimgadd', '', uploadedUrl, vm.imgwidth, vm.imgheight);
                                this.fakeArray.push({
                                    "width": vm.imgwidth,
                                    "height": vm.imgheight,
                                    "url": uploadedUrl,
                                    "idx": 'uploadedUrl',
                                })
                            });
                        }
                    }
                }
            );
        },
        onDownloadClick(url) {
            axios.get(url, {
                    responseType: 'blob'
                })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf'
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    if (this.downlabel == "") {
                        link.download = getfilename(url) + ".jpg";
                    } else {
                        link.download = this.downlabel;
                    }
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        onRemove(url, iidx, rib) {
            // this.$emit('input', "");
            // 
            let vm = this;
            if (vm.fakeArray[iidx].idx !== 'uploadedUrl') {
                let fakeArrayIdxArr = vm.fakeArray.filter(function (item) {
                    return item.idx !== vm.fakeArray[iidx].idx;
                });
                this.fakeArray = fakeArrayIdxArr;
            } else {
                let fakeArrayIdxArr = vm.fakeArray.filter(function (item) {
                    return item.url !== vm.fakeArray[iidx].url;
                });
                this.fakeArray = fakeArrayIdxArr;
            }
            let add = this.fakeArray.filter(function (item) {
                return item.url !== '';
            });
            if (add.length === 2) {
                this.fakeArray.unshift({
                    "width": 0,
                    "height": 0,
                    "url": '',
                    "idx": null,
                })
            }

        },
        initFakeData() {
            this.fakeArray = [{
                "width": 0,
                "height": 0,
                "url": '',
                "idx": null,
            }]
        },
        edtImgData(val) {
            this.fakeArray = JSON.parse(JSON.stringify(val));
            // console.log(this.fakeArray)
            let add = this.fakeArray.filter(function (item) {
                return item.url !== '';
            });
            if (add.length < 3) {
                let newArr = [];
                this.fakeArray.forEach((el, idxs) => {
                    newArr.push({
                        "width": el.width,
                        "height": el.height,
                        "url": el.url,
                        "idx": this.addidx,
                    })
                    this.addidx++;
                });
                this.fakeArray = newArr;
                this.fakeArray.unshift({
                    "width": 0,
                    "height": 0,
                    "url": '',
                    "idx": null,
                })
            } else {
                let newArr = [];
                this.fakeArray.forEach((el, idxs) => {
                    newArr.push({
                        "width": el.width,
                        "height": el.height,
                        "url": el.url,
                        "idx": this.addidx,
                    })
                    this.addidx++;
                });
                this.fakeArray = newArr;
            }

        },
        uploadImgArr() {
            let add = this.fakeArray.filter(function (item) {
                return item.url !== '';
            });
            this.change(add);
        }
    },
};
</script>

<style scoped>
.thumbnail {
    width: 160px;
    height: 120px;
    justify-content: center;
    margin: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px;
    background-color: #E8E8E8;
}

.thumbnail span {
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maskbg {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #00000070;
}

.btn {
    position: absolute;
    background-color: #FFFFFFA0;
    border-radius: 100px;
    padding: 6px;
    height: 32px;
}

.btn-close {
    top: 0px;
    right: 0px;
}

.btn-preview {
    left: 0px;
    bottom: 0px;
}

.btn-download {
    right: 0px;
    bottom: 0px;
}

.dropbox {
    width: 100%;
    height: 100%;
    position: absolute;
    /* display: none; */
}

.positon-r.mouseC {
    display: inline-block;
    vertical-align: middle;
}
</style>
