<template>
  <section class="content">
    <div class="box box-solid" v-if="orderDetails != null">
      <div class="box-body form-horizontal">
        <!--orderDetails.orderItems[0].goodsType-->

        <h4>주문정보</h4>
        <div class="form-group" style="margin:0px">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <colgroup>
                <col width="100px">
                <col width="*">
                <col width="100px">
                <col width="*">
              </colgroup>
              <tbody>
              <tr>
                <th>주문번호</th>
                <td>{{ orderDetails.orderNo }}</td>
                <th>B2B 주문번호</th>
                <td>{{ orderDetails.b2bOrderNo }}</td>
              </tr>
              <tr>
                <th>구매자명</th>
                <td>{{ orderDetails.buyerName }}</td>
                <th>구매자 번호</th>
                <td>{{ phoneformat(orderDetails.buyerPhone) }}</td>
              </tr>
              <tr>
                <th>아이디</th>
                <td>
                  <span>{{ splitText(orderDetails.username, 'userName') }}</span>
                  <button v-if="orderDetails.username && orderDetails.username !== ''" type="button" class="btn btn-default ml-2"
                          @click="copyText(orderDetails.username)">복사
                  </button>
                </td>
                <th>이메일</th>
                <td>
                  <span>{{ splitText(orderDetails.buyerEmail, 'buyerEmail') }}</span>
                  <button v-if="orderDetails.buyerEmail && orderDetails.buyerEmail !== ''" type="button" class="btn btn-default ml-2"
                          @click="copyText(orderDetails.buyerEmail)">복사
                  </button>
                </td>
              </tr>
              <tr>
                <th>주문일</th>
                <td>{{ viewDateTime2(orderDetails.purchaseDate) }}</td>
                <th>취소일</th>
                <td>{{ viewDate(orderDetails.cancelledDate) }}</td>
              </tr>
              <tr>
                <!-- {{ orderDetails }} -->
                <th>결제정보</th>

                <!-- <td>({{orderDetails.paymentTotal}}) – ({{getTotaldiscount()}}) – ({{orderDetails.userCashPayment}}) – ({{orderDetails.userPointPayment}}) = ({{orderDetails.netPaymentTotal}})</td> -->

                <td style="position: relative;">
                  ({{ orderDetails.salePriceTotal | numFormat }}) –
                  <span v-if="orderDetails.discountCouponAmount !== null && orderDetails.discountCouponAmount !== '0'"
                        @click="discountsBoxDiv" class="spanPar">
                                        <a class="linez">({{ orderDetails.discountCouponAmount | numFormat }}) </a> –
                                    </span>
                  <span v-else>(0) –</span>
                  <div class="discountsBoxDiv" id="discountsBoxDiv">
                    <div>쿠폰명 : {{
                        orderDetails.discountCouponName !== undefined ? orderDetails.discountCouponName : ''
                      }}
                    </div>
                    <div>할인금액 : {{ orderDetails.discountCouponAmount | numFormat }}원</div>
                    <div>쿠폰 코드 : {{ orderDetails.discountCouponNumber }}</div>
                  </div>
                  <span v-if="getTotaldiscount() !== 0">({{ getTotaldiscount() }}) – </span>
                  <!-- {{ orderDetails }} -->
                  <span
                    v-if="orderDetails.userCashPayment !== null">({{
                      orderDetails.userCashPayment | numFormat
                    }}) –</span>
                  <span v-else> (0) – </span>
                  <span
                    v-if="orderDetails.userPointPayment !== null">({{
                      orderDetails.userPointPayment | numFormat
                    }}) – </span>
                  <span v-else> (0) – </span>
                  <!--여기 : 포인트권 추가-->
                  <span
                    v-if="orderDetails.pointTicket !== null && orderDetails.pointTicket !== '0'" class="linkcmp"
                    id="pointTicketTooltip"
                    @click="pointCouponBoxDiv"
                  >({{ orderDetails.pointTicket | numFormat }})
                  </span>
                  <span v-else>(0)</span>
                  <div class="pointCouponBoxDiv" id="pointCouponBoxDiv">
                    <div v-for="(item,idx) in pointCouponUseListData" :key="idx">
                      {{item.itemNo}} ({{item.useAmount | numFormat}} 원)
                    </div>
                  </div>
                  <!--  베네피아 이벤트 할인 금액으로 인해 salePriceTotal => paymentTotal 로 변경 23.12.12 kevin -->
                  =({{orderDetails.salePriceTotal - orderDetails.discountCouponAmount - orderDetails.userCashPayment - orderDetails.userPointPayment - orderDetails.pointTicket
                - (orderDetails.channelName ==='BENEPIA' && (orderDetails.paymentTotal != orderDetails.salePriceTotal) ? orderDetails.paymentTotal : 0)| numFormat}})
                  <!--                  =({{orderDetails.salePriceTotal - orderDetails.discountCouponAmount - orderDetails.userCashPayment - orderDetails.userPointPayment - orderDetails.pointTicket}})-->
                  <!--                  = ({{ orderDetails.paymentTotal | numFormat }})-->
                </td>
                <!-- 결제수단 추가 2021-08-19 -->
                <th>결제수단</th>
                <td v-if="orderDetails.paymentAgentCode == 'HAPPY' ">해피캐시</td>
                <td v-else-if="orderDetails.paymentAgentCode == 'INNOPAY'">이노페이 {{!orderDetails.virtualAccountPayment? ``: `(무통장입금)`}}</td>
                <td v-else-if="orderDetails.paymentAgentCode == 'KCP'">KCP 결제</td>
                <td v-else-if="orderDetails.paymentAgentCode == 'BENEPIA'">BENEPIA
                  <button
                    v-if="orderDetails.orderStatus != 'Cancelled' && orderDetails.orderStatus != 'CancelRequested'"
                    type="button" class="btn btn-default" @click="onAllowProcess(true)">강제취소
                  </button>
                </td>
                <td v-else-if="orderDetails.paymentAgentCode == 'ZEROPAY'">ZEROPAY</td>
                <td v-else-if="orderDetails.paymentAgentCode == 'APPCARD'">KCP 결제</td>
                <td v-else-if="orderDetails.paymentAgentCode !== null">
                  <span v-if="orderDetails.paymentAgentCode.includes('NAVERPAY')">NAVERPAY</span>
                  <span v-if="orderDetails.paymentAgentCode.includes('KAKAOPAY')">KAKAOPAY</span>
                </td>
                <td v-else></td>
                <!-- 결제수단 추가 2021-08-19 -->
              </tr>
              <tr>
                <th>주문상태</th>
                <td>
                  <span style="margin-right:10px">{{ getOrdState(orderDetails.orderStatus) }}</span>
                  <!-- <button v-if="goodsType != 'Booking' && orderDetails.orderStatus == 'Cancelled'" type="button" class="btn btn-default" @click="showMemodlg" >주문상태복원</button> -->
                  <button v-if="orderDetails.channelType == 'B2bChannel' && orderDetails.orderStatus == 'Cancelled' && orderDetails.channelName != 'KAKAOB'"
                          type="button" class="btn btn-default" @click="showMemodlg">주문상태복원
                  </button>
                  <button v-else-if="orderDetails.channelType == 'B2bChannel' && this.checkOrderItemStatus()"
                          type="button" class="btn btn-default"  @click="showItemRestoreMemoModal()" >취소완료 쿠폰상태복원
                  </button>
                </td>
                <th>판매경로</th>
                <td>
                  {{ sleftext() }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-default" style="margin:0px 5px" @click="resetAccountChkCount">입력 횟수 초기화</button>
          <button type="button" class="btn btn-default" style="margin:0px 5px"
                  v-if="goodsType != 'Booking' && orderDetails.orderStatus != 'Cancelled' && orderDetails.orderStatus != 'CancelRequested' && goodsType !== 'StoreBooking'"
                  @click="getrefundAmount" :disabled="buttonDissabled">구매취소
          </button>
          <button type="button" class="btn btn-default" style="margin:0px 5px" @click="showReward">보상신청</button>
        </div>
        <h4>쿠폰정보</h4>
        <div class="form-group" style="margin:0px">
          <!-- {{ goodsType }} -->
          <button v-if="goodsType == 'MobileCoupon' || goodsType == 'MoneyCoupon' || goodsType == 'BookingCoupon'"
                  type="button" class="btn btn-default" style="margin-right:10px" v-show="hasIssuedMobileCoupon"처
                  @click="showUseProcess">사용처리
          </button>
          <button type="button" class="btn btn-default" style="margin-right:10px" v-if="goodsType != 'Booking'"
                  v-show="hasIssuedMobileCoupon" @click="onResendOrder(false)" :disabled="buttonDissabled">재발송
          </button>
          <button type="button" class="btn btn-default" style="margin-right:10px" v-if="goodsType != 'Booking'"
                  v-show="!hasIssuedMobileCoupon && goodsType !== 'StoreBooking'" @click="onResendOrder(true)" :disabled="buttonDissabled">재발송
          </button>
          <button type="button" class="btn btn-default" style="margin-right:10px" v-show="hasIssuedMobileCoupon"
                  v-if="goodsType != 'Booking' && goodsType !== 'StoreBooking'" @click="onReissueItems" :disabled="buttonDissabled">쿠폰재발급
          </button>
          <button type="button" class="btn btn-default" style="margin-right:10px" v-show="hasIssuedMobileCoupon"
                  v-if="goodsType != 'Booking' && goodsType !== 'StoreBooking'" @click="onLockItems" :disabled="buttonDissabled">사용불가
          </button>
          <button type="button" class="btn btn-default pull-right" v-show="hasIssuedMobileCoupon"
                  v-if="goodsType != 'Booking' && goodsType !== 'StoreBooking'" @click="onChangeItemRcv">수신자 정보수정
          </button>

        </div>
        <div class="form-group" style="margin:0px;margin-top:10px">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>
                  <input type="checkbox" class="allmch" @change="allmckech($event)"/>
                </th>
                <th>쿠폰번호</th>
                <th class="text-center">상품구분</th>
                <th>상품명</th>
                <th>옵션명</th>
                <th>결합ID</th>
                <th>할인금액</th>
                <th class="text-center">판매가</th>
                <th class="text-center" v-if="goodsType === 'StoreBooking'">실판매가</th>
                <th class="text-center" v-if="goodsType !== 'StoreBooking'">유효기간</th>
                <th class="text-center">발급일</th>
                <th class="text-center">쿠폰상태</th>
                <th class="text-center">사용일</th>
                <th class="text-center">취소일</th>
                <th class="text-center">예약번호</th>
                <th class="text-center">B2B 상세주문번호</th>
                <th class="text-center">B2B 발권번호</th>
                <th class="text-center" v-if="goodsType !== 'StoreBooking'">수신자</th>
                <th class="text-center" v-if="goodsType !== 'StoreBooking'">수신자번호</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(ditem, didx) in orderItems" :key="ditem.itemNo" class="trtrop">
                <td>
                  <!-- {{ ditem.goodsId }} -->
                  <input type="checkbox" :class="ditem.itemStatus == 'Available' && 'chmitem'"
                         :disabled="ditem.itemStatus != 'Available'" :value="ditem.itemNo"/>
                  <input type="hidden" class="inputGoodsType" :value="ditem.goodsType">
                  <input type="hidden" class="nob2b" :value="ditem.nob2b">
                  <input type="hidden" class="goodsType" :value="ditem.goodsType">
                  <input type="hidden" class="goodsId" :value="ditem.goodsId">
                  <input type="hidden" class="combineId" :value="ditem.itemCombine.combineId"
                         v-if="ditem.itemCombine !== null">
                  <input type="hidden" class="refundable" :value="ditem.refundable" v-if="ditem.itemCombine !== null">
                  <input type="hidden" class="usedAmount" :value="ditem.usedAmount" v-if="ditem.itemCombine !== null">
                  <!-- {{ ditem }} -->
                </td>
                <td>{{ ditem.itemNo }}</td>
                <td class="text-center">{{ goodsTypeVal(ditem.goodsType) }}</td>
                <td class="linkcmp" @click="goDetailPage(ditem.goodsId, ditem.goodsType)"
                    v-if="ditem.goodsType !== 'StoreBooking'">{{ ditem.goodsName }}
                </td>
                <td v-else>{{ ditem.goodsName }}</td>

                <td>{{ ditem.optionName }}</td>
                <td v-if="ditem.itemCombine !== null" @click="combindeBoxDiv(didx+ditem.itemCombine.combineId)">
                  <div class="combindeBoxDiv" :id="didx+ditem.itemCombine.combineId">
                    <p> 결합 상품명: {{ ditem.itemCombine.goodsName }} </p>
                    <p> 결합 옵션명: {{ ditem.itemCombine.optionName }} </p>
                    <!-- {{ ditem }} -->
                  </div>
                  <div class="tdClickCl" v-if="ditem.itemCombine !== null">{{ ditem.itemCombine.combineId }}</div>
                </td>
                <td v-else></td>
                <td class="text-center">
                  <span v-if="ditem.discounts.length !== 0 ">{{ ditem.discounts[0].discountAmount }}</span>
                  <span v-else></span>
                </td>
                <!-- 판매가일경우 매장예약일경우 시간대할인이들어가기떄문에 실판매가 판매가다름 매장예약인지아닌지로 구분해줘야함 -->
                <td class="text-center" v-if="goodsType === 'StoreBooking'">{{ ditem.originalSalePrice | numFormat }}</td>
                <td class="text-center" v-else>{{ ditem.salePrice | numFormat }}</td>
                <td class="text-center" v-if="goodsType === 'StoreBooking'">{{ ditem.salePrice | numFormat }}</td>
                <td class="text-center" v-if="goodsType !== 'StoreBooking'">{{ viewDate(ditem.availableFrom) }} ~ {{ viewDate(ditem.availableTo) }}</td>
                <!-- <td v-else></td> -->
                <td class="text-center">{{ viewDate(ditem.issueDate) }}</td>
                <!--사용불가일때 lock 이 걸림 고로 사용 완료일땐 뭔가 여기서 액션이 있어야함 사용완료 : Consumed-->
                <td class="text-center"v-if="ditem.itemStatus != 'Locked'">{{ getCouponStr(ditem.itemStatus) }}</td>
                <td class="text-center  linkcmp" v-if="ditem.itemStatus == 'Locked'" @click="unlockCoupon(orderNo, ditem.itemNo)">{{ getCouponStr(ditem.itemStatus) }}</td>
                <td v-if="ditem.consumedDate != null && ditem.consumedDate != ''" class="linkcmp text-center"
                    @click="getUseHisData(ditem.itemNo)">{{ viewDate(ditem.consumedDate) }}
                </td>
                <td class="text-center" v-else></td>
                <td class="text-center">{{ viewDate(ditem.cancelledDate) }}</td>
                <td class="text-center" v-if="ditem.bookingNo == null || ditem.bookingNo == ''">{{ ditem.bookingNo }}
                </td>
                <td v-else class="linkcmp text-center" @click="goBookDetail(ditem.bookingNo)">{{ ditem.bookingNo }}</td>
                <td class="text-center">{{ ditem.b2bOrderDetailNo }}</td>
                <td class="text-center">{{ ditem.b2bItemNo }}</td>
                <td class="text-center" v-if="goodsType != 'Booking' && goodsType !== 'StoreBooking'">
                  <!-- {{ ditem.itemCombine.combineId }} -->
                  <input type="text" class="form-control" v-model="ditem.receiverName"
                         :readonly="hasIssuedMobileCoupon === false || ditem.nob2b || ditem.rcvNameNot"
                         :style="ditem.rcvName && {'background-color':'#FFF2CC'}" @input="editrcvName(didx)"/>
                </td>
                <td class="text-center" v-if="goodsType == 'Booking' && goodsType !== 'StoreBooking'">
                  {{ ditem.receiverName }}
                </td>
                <td class="text-center" v-if="goodsType != 'Booking' && goodsType !== 'StoreBooking'">
                  <input type="text" class="form-control" v-model="ditem.receiverPhone"
                         :readonly="hasIssuedMobileCoupon === false || ditem.nob2b || ditem.rcvNameNot"
                         :style="ditem.rcvName && {'background-color':'#FFF2CC'}" @input="editrcvName(didx)"/>
                </td>
                <td class="text-center" v-if="goodsType == 'Booking' && goodsType !== 'StoreBooking'">
                  {{ ditem.receiverPhone }}
                </td>
                <!-- <td>
    {{ ditem.nob2b }}
  </td> -->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-group" style="margin:0px">
          <button type="button" class="btn btn-default pull-right" v-show="hasIssuedMobileCoupon"
                  v-if="goodsType != 'Booking' && goodsType !== 'StoreBooking'" @click="onExtendExpiry" :disabled="buttonDissabled">유효기간연장
          </button>
        </div>
        <h4 style="margin-top:10px">발송내역</h4>
        <div class="form-group" style="margin:0px;margin-top:10px">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <tbody>
              <tr>
                <th style="width:100px">발송내역</th>
                <td>
                  <textarea class="form-control" rows="5" readonly v-html="getMsgHisStr()"></textarea>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h4 style="margin-top:10px">메모</h4>
        <div class="form-group" style="margin:0px">
          <textarea class="form-control" rows="5" v-model="orderDetails.memo" readonly></textarea>
        </div>
        <div class="form-group" style="margin:0px;display: inline-flex;width: 100%;">
          <input type="text" class="form-control" v-model="newmemo"/>
          <button type="button" class="btn btn-default pull-right" @click="onSaveMemo">메모저장</button>
        </div>
        <h4 style="margin-top:10px">환불내역</h4>
        <div class="form-group" style="margin:0px;margin-top:10px">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <tbody>
              <tr>
                <th style="width:100px">환불내역</th>
                <td>
                  <textarea class="form-control" rows="5" readonly v-html="getRefHtml()"></textarea>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="buycanceldlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">구매취소</div>
          <div class="modal-body form-horizontal">
            <div class="form-group text-center">
              <div class="radio">
                <label style="margin-right:20px">
                  <input type="radio" value="Payment" :disabled="!avaArray.includes('PaymentCancel')"
                         v-model="cancelmethod"/>결제취소
                </label>
                <label style="margin-right:20px">
                  <input type="radio" value="RefundCs"
                         :disabled="!avaArray.includes('CreateRefundCs')|| !refundData.needsRefundAccountInfo"
                         v-model="cancelmethod"/>계좌이체환불
                </label>
                <label v-if="this.orderDetails.channelType ==='B2bChannel' && this.orderDetails.channelName ==='NAVER'"  style="margin-right:20px">
                  <input type="radio" value="JustSet" :disabled="avaArray.includes('JustSetCancelled')"  v-model="cancelmethod"/>단순취소처리(B2B 주문건)
                </label>
                <label v-else style="margin-right:20px">
                  <input type="radio" value="JustSet" :disabled="!avaArray.includes('JustSetCancelled')"  v-model="cancelmethod"/>단순취소처리(B2B 주문건)
                </label>
                <label v-if="this.orderDetails.channelType ==='B2bChannel'"  style="margin-right:20px">
                  <input type="radio" value="SimpleRefundCs"  v-model="cancelmethod"/>단순 계좌이체 환불(B2B 주문건)
                </label>
              </div>
            </div>
            <div class="form-group" style="margin:0px;" v-if="cancelmethod !== 'JustSet'">
              <label class="col-xs-6 control-label" style="padding-top: 0px;">환불 가능 금액:</label>
              <div class="col-xs-6" style="margin:0px">{{ refundData.refundableAmountTotal | numFormat }} 원</div>
            </div>
            <div class="form-group" v-if="cancelmethod !== 'JustSet'">
              <label class="col-xs-6 control-label" style="padding-top:0px;">환불 수수료 부과 안함:</label>
              <div class="col-xs-6">
                <input  type="checkbox" style="margin-left:0px" :disabled="!refundData.hasRefundFeeOrPenalty"
                       :value="false" v-model="refundFeeRequired"/>
              </div>
            </div>
            <div style="border:1px solid; margin-bottom:10px;" v-if="cancelmethod !== 'JustSet'">
              <div class="form-group" style="margin:0px;">
                <label class="col-xs-6 control-label" style="padding-top: 0px;">환불 수수료:</label>
                <div class="col-xs-6" v-if="!refundFeeRequired">{{ refundData.refundFeeTotal | numFormat }}</div>
                <div class="col-xs-6" v-else>0</div>
              </div>
              <div class="form-group" style="margin:0px;">
                <label class="col-xs-6 control-label" style="padding-top: 0px;">환불 금액:</label>
                <div class="col-xs-6" v-if="!refundFeeRequired">{{ refundData.refundAmountTotal | numFormat }}</div>
                <div class="col-xs-6" v-else>{{ refundData.refundableAmountTotal | numFormat }}</div>
              </div>
              <div class="form-group" style="margin:0px;" v-if="refundData.paymentRefundDetails != null">
                <label class="col-xs-6 control-label" style="padding-top: 0px;">- Agent:</label>
                <div class="col-xs-6" v-if="!refundFeeRequired">
                  {{ refundData.paymentRefundDetails.payment | numFormat }}
                </div>
                <div class="col-xs-6" v-else>{{ refundData.paymentRefundDetailsWhenNoFee.payment | numFormat }}</div>

              </div>
              <div class="form-group" style="margin:0px;" v-if="refundData.paymentRefundDetails != null">
                <label class="col-xs-6 control-label" style="padding-top: 0px;">- Cash:</label>
                <div class="col-xs-6" v-if="!refundFeeRequired">{{ refundData.paymentRefundDetails.cash | numFormat }}
                </div>
                <div class="col-xs-6" v-else>{{ refundData.paymentRefundDetailsWhenNoFee.cash | numFormat }}</div>
              </div>
              <div class="form-group" style="margin:0px;" v-if="refundData.paymentRefundDetails != null">
                <label class="col-xs-6 control-label" style="padding-top: 0px;">- Reward:</label>
                <div class="col-xs-6" v-if="!refundFeeRequired">
                  {{ refundData.paymentRefundDetails.point | numFormat }}
                </div>
                <div class="col-xs-6" v-else>{{ refundData.paymentRefundDetailsWhenNoFee.point | numFormat }}</div>
              </div>
              <!--여기 : 포인트권 추가시 작업-->
              <div class="form-group" style="margin:0px;" v-if="refundData.paymentRefundDetails != null">
                <label class="col-xs-6 control-label" style="padding-top: 0px;">- PointTicket:</label>
                <div class="col-xs-6" v-if="!refundFeeRequired">
                  {{ refundData.paymentRefundDetails.pointTicket | numFormat }}
                </div>
                <div class="col-xs-6" v-else>{{ refundData.paymentRefundDetailsWhenNoFee.pointTicket | numFormat }}</div>
              </div>
            </div>
            <div style="border:1px; margin-bottom:10px;" v-if="cancelmethod === 'RefundCs'">
              <div class="form-group">
                <div class="col-xs-6" style="margin-left:40%;">
                  <input type="checkbox"
                         :value="false" v-model="refundDirectInput"/>
                  <label class="control-label" style="margin-left: 5px;">계좌번호 직접 입력하기</label>
                </div>
              </div>

            </div>
            <div style="border:1px solid; margin-bottom:10px;" v-if="cancelmethod == 'RefundCs' && this.refundDirectInput">
              <div class="form-group">
                <label class="col-sm-6 control-label" v-if="cancelmethod == 'Payment'">결제 취소 환불 계좌 정보</label>
                <label class="col-sm-6 control-label" v-if="cancelmethod == 'RefundCs'">환불 계좌 정보</label>
                <div class="hidden-xs col-sm-6" style="margin:0px"></div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">은행</label>
                <div class="col-xs-6">
                  <select class="form-control" style="width:auto;" v-model="refundAccount.bankCode"
                          @change="onChangeBank">
                    <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{ bank.bankName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">계좌번호</label>
                <div class="col-xs-6">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="refundAccount.accountNumber"/>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-xs-6 control-label">예금주명</label>
                <div class="col-xs-6">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="refundAccount.accountHolder"/>
                  </div>
                </div>
              </div>


            </div>

            <div style="border:1px; margin-bottom:10px;" v-if="cancelmethod === 'SimpleRefundCs'">
              <div class="form-group">
                <div class="col-xs-6" style="margin-left:40%;">
                  <input type="checkbox"
                         :value="false" v-model="refundSimpleDirectInput"/>
                  <label class="control-label" style="margin-left: 5px;">계좌번호 직접 입력하기</label>
                </div>
              </div>

            </div>
            <div style="border:1px solid; margin-bottom:10px;" v-if="cancelmethod == 'SimpleRefundCs'&& this.refundSimpleDirectInput">

              <div class="form-group">
                <label class="col-sm-6 control-label" v-if="cancelmethod == 'Payment'">결제 취소 환불 계좌 정보</label>
                <label class="col-sm-6 control-label" v-if="cancelmethod == 'SimpleRefundCs'">환불 계좌 정보</label>
                <div class="hidden-xs col-sm-6" style="margin:0px"></div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">은행</label>
                <div class="col-xs-6">
                  <select class="form-control" style="width:auto;" v-model="refundAccount.bankCode"
                          @change="onChangeBank">
                    <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{ bank.bankName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">계좌번호</label>
                <div class="col-xs-6">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="refundAccount.accountNumber"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">예금주명</label>
                <div class="col-xs-6">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="refundAccount.accountHolder"/>
                  </div>
                </div>
              </div>
            </div>
            <div style="border:1px solid; margin-bottom:10px;"
                 v-if="refundData.needsRefundAccountInfo && cancelmethod == 'Payment'">
              <div class="form-group">
                <label class="col-sm-6 control-label" v-if="cancelmethod == 'Payment'">결제 취소 환불 계좌 정보</label>
                <label class="col-sm-6 control-label" v-if="cancelmethod == 'RefundCs'">환불 계좌 정보</label>
                <div class="hidden-xs col-sm-6" style="margin:0px"></div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">은행</label>
                <div class="col-xs-6">
                  <select class="form-control" style="width:auto;" v-model="refundAccount.bankCode"
                          @change="onChangeBank">
                    <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{ bank.bankName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">계좌번호</label>
                <div class="col-xs-6">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="refundAccount.accountNumber"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-xs-6 control-label">예금주명</label>
                <div class="col-xs-6">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="refundAccount.accountHolder"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="(cancelmethod == 'SimpleRefundCs' && !this.refundSimpleDirectInput) || (cancelmethod == 'RefundCs' && !this.refundDirectInput) " class="form-group">
              <label class="col-xs-3 control-label">발송번호 : </label>
              <div class="col-xs-9 input-group">
                <input type="text" class="form-control" v-model="requestPhoneNumber"/>
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-3 control-label">환불사유 : </label>
              <div class="col-xs-9 input-group">
                <input type="text" class="form-control" v-model="requestReason"/>
              </div>
            </div>
            <div class="form-group text-center" style="margin-left:0px;margin-right:0px;">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closebuycancel">취소
              </button>
              <button type="button" class="btn btn-default" @click="onAllowProcess(false)">승인</button>
            </div>
            <h5 class="refund-summary" style="margin-top: 40px">환불요약</h5>
            <div class="form-group refund-summary" style="margin-left:0px;margin-right:0px">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <colgroup>
                    <col width="100px">
                    <col width="25%">
                    <col width="100px">
                    <col width="25%">
                    <col width="100px">
                    <col width="25%">
                    <col width="100px">
                    <col width="25%">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>정상가</th>
                    <td colspan="3">{{ refundData.listPriceTotal | numFormat }}</td>
                    <th>사용금액</th>
                    <td colspan="3">{{ refundData.usedAmountTotal | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>판매가</th>
                    <td colspan="3">{{ refundData.salePriceTotal | numFormat }}</td>
                    <th>환불가능금액</th>
                    <td colspan="3">{{ refundData.refundableAmountTotal | numFormat }}</td>
                  </tr>
                  <tr>
                    <!-- <th>할인금액</th>
                    <td colspan="3">{{refundData.discountAmountTotal | numFormat}}</td> -->
                    <th>할인쿠폰</th>
                    <td colspan="3">{{ refundData.discountCouponAmount | numFormat }} ({{
                        refundData.discountCouponName
                      }})
                    </td>
                    <th>환불수수료</th>
                    <td colspan="3" v-if="!refundFeeRequired">{{ refundData.refundFeeTotal | numFormat }}</td>
                    <td colspan="3" v-else>0</td>
                  </tr>
                  <tr>
                    <th rowspan="4">결제금액</th>
                    <td rowspan="4">{{ refundData.paymentTotal | numFormat }}</td>
                    <th>Agent</th>
                    <td v-if="refundData.paymentDetails != null">{{ refundData.paymentDetails.payment | numFormat }}
                    </td>
                    <td v-if="refundData.paymentDetails == null">0</td>
                    <th rowspan="4">환불금액</th>
                    <td rowspan="4" v-if="!refundFeeRequired">{{ refundData.refundAmountTotal | numFormat }}</td>
                    <td rowspan="4" v-else>{{ refundData.refundableAmountTotal | numFormat }}</td>
                    <th>Agent</th>
                    <td v-if="refundData.paymentRefundDetails != null">
                      <!-- 환불금액 -->
                      <span v-if="refundFeeRequired">{{ refundData.paymentRefundDetailsWhenNoFee.payment | numFormat }}</span>
                      <span v-else>{{ refundData.paymentRefundDetails.payment | numFormat }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Cash</th>
                    <td v-if="refundData.paymentDetails != null">{{ refundData.paymentDetails.cash | numFormat }}</td>
                    <td v-if="refundData.paymentDetails == null">0</td>
                    <th>Cash</th>
                    <td v-if="refundData.paymentRefundDetails != null">
                      <!-- 환불금액 -->
                      <span v-if="refundFeeRequired">{{ refundData.paymentRefundDetailsWhenNoFee.cash | numFormat }}</span>
                      <span v-else>{{ refundData.paymentRefundDetails.cash | numFormat }}</span>
                    </td>
                    <td v-if="refundData.paymentRefundDetails == null">0</td>
                  </tr>
                  <tr>
                    <th>Reward</th>
                    <td v-if="refundData.paymentDetails != null">{{ refundData.paymentDetails.point | numFormat }}</td>
                    <td v-if="refundData.paymentDetails == null">0</td>
                    <th>Reward</th>
                    <td v-if="refundData.paymentRefundDetails != null">
                      <!-- 환불금액 -->
                      <span v-if="refundFeeRequired">{{ refundData.paymentRefundDetailsWhenNoFee.point | numFormat }}</span>
                      <span v-else>{{ refundData.paymentRefundDetails.point | numFormat }}</span>
                    </td>
                    <td v-if="refundData.paymentRefundDetails == null">0</td>
                  </tr>
                  <!--여기 : 포인트권 추가시 작업-->
                  <tr>
                    <th>PointTicket</th>
                    <td v-if="refundData.paymentDetails != null">{{ refundData.paymentDetails.pointTicket | numFormat }}</td>
                    <td v-if="refundData.paymentDetails == null">0</td>
                    <th>PointTicket</th>
                    <td v-if="refundData.paymentRefundDetails != null">
                      <!-- 환불금액 -->
                      <span v-if="refundFeeRequired">{{ refundData.paymentRefundDetailsWhenNoFee.pointTicket | numFormat }}</span>
                      <span v-else>{{ refundData.paymentRefundDetails.pointTicket | numFormat }}</span>
                    </td>
                    <td v-if="refundData.paymentRefundDetails == null">0</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h5 class="refund-summary">환불상세</h5>
            <div class="form-group refund-summary" style="margin-left:0px;margin-right:0px">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th>쿠폰번호</th>
                    <th>정상가</th>
                    <th>판매가</th>
                    <th>할인금액</th>
                    <th>결제금액</th>
                    <th>사용금액</th>
                    <th>환불가능금액</th>
                    <th>환불수수료</th>
                    <th>환불금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="ditem in refundData.itemRefundDetails" :key="ditem.itemNo">
                    <td>{{ ditem.itemNo }}</td>
                    <td>{{ ditem.listPrice | numFormat }}</td>
                    <td>{{ ditem.salePrice | numFormat }}</td>
                    <td>{{ ditem.discountAmount | numFormat }}</td>
                    <td>{{ ditem.paymentAmount | numFormat }}</td>
                    <td>{{ ditem.usedAmount | numFormat }}</td>
                    <td>{{ ditem.refundableAmount | numFormat }}</td>
                    <td v-if="!refundFeeRequired">{{ ditem.refundFee | numFormat }}</td>
                    <td v-else>0</td>
                    <td v-if="!refundFeeRequired">{{ ditem.refundAmount | numFormat }}</td>
                    <td v-else>{{ ditem.refundableAmount | numFormat }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="rewarddlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">보상신청</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label class="col-sm-4 control-label">은행</label>
              <div class="col-sm-8">
                <select class="form-control" v-model="rewardAccount.bankCode" @change="onChangeRewardBank">
                  <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{ bank.bankName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">계좌번호</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" v-model="rewardAccount.accountNumber"/>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">예금주명</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" v-model="rewardAccount.accountHolder"/>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">금액</label>
              <div class="col-sm-8">
                <input type="number" class="form-control" v-model="rewardAmount"/>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">보상사유</label>
              <div class="col-sm-8">
                <textarea class="form-control" rows="5" v-model="rewardmessage"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">입금예정일</label>
              <div class="col-sm-8" style="display:inline-flex;">
                <DetePicker style="margin-right:10px;" :first="today" v-model="rewardDate"/>
                <div class="input-group">
                  <input type="time" class="form-control" style="width:auto;" v-model="rewardTime"/>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeReward">취소</button>
              <button type="button" class="btn btn-default" @click="doRewardProcess" :disabled="buttonDissabled">보상신청</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="useprocessdlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          {{ inputGoodsTypeName[0] }}
          <div class="modal-header border-bottom-0" v-if="inputGoodsTypeName[0] == 'MobileCoupon'">사용처리 (모바일쿠폰)</div>
          <div class="modal-header border-bottom-0" v-if="inputGoodsTypeName[0] == 'MoneyCoupon'">사용처리 (금액권)</div>
          <div class="modal-header border-bottom-0" v-if="inputGoodsTypeName[0] == 'BookingCoupon'">사용처리 (예약필수쿠폰)</div>
          <div class="modal-header border-bottom-0" v-if="inputGoodsTypeName[0] == 'PointCoupon'">사용처리 (포인트권)</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th>쿠폰번호</th>
                    <th>상품명</th>
                    <th>옵션명</th>
                    <th>세부옵션명</th>
                    <th v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">정상가</th>
                    <th v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">기존사용금액</th>
                    <th v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">사용가능금액</th>
                    <th v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">사용금액</th>
                    <th v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">정상가</th>
                    <th v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">기존사용금액</th>
                    <th v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">사용가능금액</th>
                    <th v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">사용금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(sitem, sidx) in selorderItems" :key="sitem.itemNo">

                    <td>{{ sitem.itemNo }}</td>
                    <td>{{ sitem.goodsName }}</td>
                    <td v-if="sitem.itemCombine !== null">{{ sitem.itemCombine.optionName }}</td>
                    <td v-else>{{ sitem.optionName }}</td>
                    <td v-if="sitem.itemCombine !== null">{{ sitem.optionName }}</td>
                    <td v-else>{{ sitem.optionName }}</td>
                    <td v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">{{ sitem.listPrice }}
                    </td>
                    <td v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">
                      {{ sitem.usedAmount }}
                    </td>
                    <td v-if="inputGoodsTypeName[0] == 'MoneyCoupon' && isGoodsCompinde == true">
                      {{ sitem.usableAmount }}
                    </td>

                    <td v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">{{ sitem.listPrice }}</td>
                    <td v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">{{ sitem.usedAmount }}
                    </td>
                    <td v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false">{{ sitem.usableAmount }}
                    </td>
                    <td
                      v-if="inputGoodsType[0] == 'MoneyCoupon' && isGoodsCompinde == false || inputGoodsTypeName[0] == 'MoneyCoupon'">
                      <!-- <input type="number" class="form-control" v-model="sitem.paymentAmount" :max="sitem.usableAmount" @input="changePayAmount($event, sidx)"/> -->
                      <input type="number" class="form-control" value="0" :max="sitem.usableAmount"
                             @input="changePayAmount($event, sidx)"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">사용매장</label>
              <div class="col-sm-3">
                <select class="form-control" style="width:auto;" v-model="consumeStoreCode">
                  <option v-for="(bsitem, bsidx) in brandStoreName" :key="bsidx" :value="bsitem.storeCode">
                    {{ bsitem.storeName }}
                  </option>
                </select>
              </div>
              <div class="col-sm-7" style="display:inline-flex;flex-wrap: wrap;">
                <label class="control-label" style="margin-right:10px;">사용시간</label>
                <DetePicker style="margin-right:10px;" v-model="consumeUseData"/>
                <div class="input-group">
                  <input type="time" class="form-control" style="width:auto;" v-model="consumeUseTime"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">메모</label>
              <div class="col-sm-10">
                <textarea class="form-control" rows="5" v-model="consumememo"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeUseProcess">취소
              </button>
              <button type="button" class="btn btn-default" @click="doProcessUse" :disabled="buttonDissabled">사용처리</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="usehisdlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'MobileCoupon'">사용내역조회 (모바일쿠폰)</div>
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'MoneyCoupon'">사용내역조회 (금액권)</div>
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'BookingCoupon'">사용내역조회 (예약필수쿠폰)</div>
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'PointCoupon'">사용내역조회 (포인트권)</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th v-if="transgoodsType != 'PointCoupon'">브랜드</th>
                    <th v-if="transgoodsType == 'PointCoupon'">주문번호</th>
                    <th v-if="transgoodsType != 'PointCoupon'">매장</th>
                    <th>사용일자</th>
                    <th v-if="transgoodsType == 'MoneyCoupon' || transgoodsType == 'PointCoupon'">사용금액</th>
                    <th>취소 처리일</th>
                    <th v-if="transgoodsType != 'PointCoupon'">
                      <input type="checkbox" class="allch" @change="allckech($event)"/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="trans in transactions" :key="trans.transactionNo">
                    <td v-if="transgoodsType != 'PointCoupon'">{{ trans.brandName }}</td>
                    <td v-else class="linkcmp"><a target="_blank" :href="`${routes.detailorder + '?orderNo='+ trans.orderNumber }`" >{{ trans.orderNumber }}</a></td>
                    <td v-if="transgoodsType != 'PointCoupon'">{{ trans.storeName }}</td>
                    <td>{{ viewDateTime(trans.useDate) }}</td>
                    <td v-if="transgoodsType == 'MoneyCoupon' || transgoodsType == 'PointCoupon'">{{ trans.useAmount | numFormat }}</td>
                    <td>{{ viewDateTime(trans.cancelledDate) }}</td>
                    <td v-if="transgoodsType != 'PointCoupon'">
                      <input v-if="trans.cancelled" type="checkbox" :value="trans.transactionNo" :disabled="true"/>
                      <input v-else type="checkbox" class="chitem" :value="trans.transactionNo"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group" v-if="transgoodsType != 'PointCoupon'">
              <label class="col-sm-3 control-label">취소 처리일</label>
              <div class="col-sm-9" style="display:inline-flex;flex-wrap: wrap;">
                <DetePicker style="margin-right:10px;" v-model="cancelUseData"/>
                <div class="input-group">
                  <input type="time" class="form-control" style="width:auto;" v-model="cancelUseTime"/>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="transgoodsType != 'PointCoupon'">
              <label class="col-sm-3 control-label">취소사유</label>
              <div class="col-sm-9">
                <textarea class="form-control" rows="5" v-model="transmemo"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeUseHis">취소</button>
              <button v-if="transgoodsType != 'PointCoupon'" type="button" class="btn btn-default" @click="doHisUseCancel">사용취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="memodlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">주문상태복원</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <textarea class="form-control" rows="5" v-model="othermemo"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeMemodlg">취소</button>
              <button type="button" class="btn btn-default" @click="restoreRequest">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="itemRestoreModal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">쿠폰상태복원</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <textarea placeholder="쿠폰상태복원사유" class="form-control" rows="5" v-model="itemRestoreMemo"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeItemRestoreModal">취소</button>
              <button type="button" class="btn btn-default" @click="itemRestoreRequest">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 재발송 팝업 -->
    <div class="modal resendModal" v-if="resenddlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">재발송</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="radio">
                <label style="margin-right:20px"><input type="radio" class="pr-2" value="false"
                                                        v-model="useSms">알림톡</label>
                <label><input type="radio" class="pr-2" value="true" v-model="useSms">SMS전송</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="resendClick">전송</button>
              <button type="button" class="btn btn-default" @click="closeResenddlg">취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:none;">{{ gide }}</div>
    <div style="display:none;">{{ gide2 }}</div>
  </section>
</template>

<script>

import {
  rescode,
  menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
// import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {
  reqPost,
  reqGet
} from "../../lib/request";
import {
  orderapi,
  brandapi,
  goodsapi,
  message,
  b2bapi, payment
} from "../../lib/api";
import {
  DateFormat,
  formatPhone,
  getGoodsTypeStr
} from "../../lib/utility";
import $ from 'jquery';
// import VueTimepicker from "vue2-timepicker";
// import "vue2-timepicker/dist/VueTimepicker.css";
import moment from 'moment';

export default {
  components: {
    // Pagination,
    DetePicker,
    // VueTimepicker,
  },
  created() {
    this.orderNo = this.$route.query.orderNo;
    // this.goodsType = this.$route.query.goodsType;
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_ORD,
      path2: menuval.LV1_HOME,
      title: "",
    });
    this.getCancelledItems();
  },
  data() {
    return {
      avaArray: [],
      gide2: [],
      resenddlg: false,
      cancellCombindId: [],
      gideTrUE: false,
      orderNo: "",
      goodsType: "",
      buycanceldlg: false, //구매취소
      cancelmethod: "Payment",
      useSms: false,
      rewarddlg: false, //보상신청
      useprocessdlg: false, //사용처리
      usehisdlg: false, //사용내역조회
      usehisdlg2 : false,
      memodlg: false,
      itemRestoreModal: false,
      today: moment().format("YYYY-MM-DD"),
      orderDetails: null,
      /**
       * orderNo                            String    주문번호
       * b2bOrderNo                         String    B2B 주문번호
       * username                           String    사용자 ID
       * buyerName                          String    구매자 이름
       * buyerPhone                         String    구매자 전화번호
       * purchaseDate                       String    UTC yyyy-MM-ddTHH:mm:ss 구매일자
       * cancelledDate                      String    UTC yyyy-MM-ddTHH:mm:ss 주문취소일자
       * orderStatus                        String    주문상태 [Purchased, CancelRequested, CancelFailed, Cancelled]
       * orderCancellable                   Boolean   주문 취소 가능 여부
       * listPriceTotal                     String    정상가
       * salePriceTotal                     String    판매가
       * paymentTotal                       String    결제금액
       * discounts                          Array     주문에 적용된 할인쿠폰 정보
       * discounts[].offerName              String    할인쿠폰 이름
       * discounts[].discountAmount         String    할인금액
       * netPaymentTotal                    String    실제 결제금액
       * userCashPayment                    String    사용자 캐시로 결제한 금액
       * userPointPayment                   String    사용자 포인트로 결제한 금액
       * agentPayment                       String    신용카드 등의 결제수단을 이용해서 결제한 금액
       * virtualAccountPayment              Boolean   가상계좌 결제인 경우 true
       * siteName                           String    주문 사이트명
       * channelName                        String    B2B 채널 주문 경로
       * selfSales                          Boolean   자체 사이트에서 판매된 주문인 경우 true
       * memo                               String    운영자 메모
       * orderItems[].itemNo                String    아이템 번호
       * orderItems[].b2bOrderDetailNo      String    B2B 주문 Detail 번호
       * orderItems[].itemStatus            String    아이템 상태
       * orderItems[].goodsType             String    상품 타입 [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
       * orderItems[].goodsId               Number    상품 ID
       * orderItems[].goodsName             String    상품명
       * orderItems[].optionId              Number    옵션 ID
       * orderItems[].optionName            String    옵션명
       * orderItems[].listPrice             String    정상가
       * orderItems[].salePrice             String    판매가
       * orderItems[].usedAmount            String    사용한 금액
       * orderItems[].usableAmount          String    사용 가능 금액
       * orderItems[].paymentAmount         String    결제금액
       * orderItems[].discounts             Array     주문에 적용된 할인쿠폰 정보
       * orderItems[].discounts[].offerName         String    할인쿠폰 이름
       * orderItems[].discounts[].discountAmount    String    할인금액
       * orderItems[].issueDate             String    UTC yyyy-MM-ddTHH:mm:ss 쿠폰 발급일자
       * orderItems[].availableFrom         String    yyyy-MM-dd  쿠폰 유효시간 시작일
       * orderItems[].availableTo           String    yyyy-MM-dd  쿠폰 유효기간 종료일
       * orderItems[].cancellable           Boolean   취소 가능 여부
       * orderItems[].cancellableTo         String    yyyy-MM-dd  취소 가능 종료일
       * orderItems[].cancelledDate         String    UTC yyyy-MM-ddTHH:mm:ss 쿠폰 취소일자
       * orderItems[].receiverName          String    수신자 이름
       * orderItems[].receiverPhone         String    수신자 전화번호
       * orderItems[].consumedDate          String    yyyy-MM-ddTHH:mm:ss 쿠폰 사용일자
       * orderItems[].bookingNo             String    예약번호
       */
      messagehis: [],
      refundHistory: [],
      /**
       * transactionNo          String    사용처리 트랜잭션 번호
       * sendDate               String    UTC yyyy-MM-ddTHH:mm:ss 발송일
       * phoneNumber            String    발송 전화번호
       * autoSend               Boolean   자동발송인 경우 true, 아닌 경우 false
       * sendType               String    [ALIMTALK,MMS] 발송타입 (ALIMTALK=알림톡, MMS=MMS)
       * requestedBy            String    발송처리한 관리자 ID
       */
      orderItems: [],
      refundAccount: {
        bankCode: "", //환급 받을 은행 코드
        bankName: "", //환급 받을 은행명
        accountNumber: "", //환급받을 계좌번호
        accountHolder: "", //환급받을 계좌 소유자 이름
      },
      banklist: [],
      /**
       * bankCode       String    은행 코드
       * bankName       String    은행명
       */
      refundFeeRequired: false,
      refundSimpleDirectInput: false,
      refundDirectInput: false,
      requestReason: "",
      requestPhoneNumber: "",
      orderCancel: false,
      itemsToCancel: [],
      refundData: {
        listPriceTotal: "", //주문 아이템 정상가격 합계
        salePriceTotal: "", //주문 아이템 판매가격 합계
        discountAmountTotal: "", //할인받은 금액. B2B 주문일경우 null
        paymentTotal: "", //고객이 결제한 금액. B2B 주문일경우 null
        paymentDetails: null, //결제수단별 결제금액. B2B 주문일경우 null
        /**
         * cash         String    캐시 결제 금액
         * point        String    포인트 결제 금액
         * payment      String    PG 또는 Iframe 제휴협체를 통해 결제한 금액
         * */
        usedAmountTotal: "", //사용된 금액
        refundableAmountTotal: "", //환불가능금액. B2B 주문일경우 null
        refundAmountTotal: "", //환불금액. B2B 주문일경우 null
        refundFeeTotal: "", //환불 수수료. B2B 주문일경우 null
        refundAmountByCsManager: "", //
        refundFeeByCsManager: "", //
        hasRefundFeeOrPenalty: false, //환불 수수료 부과 대상일 경우
        currencyCode: "", //[USD, KRW]통화코드
        itemRefundDetails: [],
        /**
         * itemNo             String    취소/환불 대상 아이템 번호
         * listPrice          String    정상 가격
         * salePrice          String    판매 가격
         * discountAmount     String    할인받은 금액. B2B 주문일경우 null
         * paymentAmount      String    고객이 결제한 금액. B2B 주문일경우 null
         * usedAmount         String    사용된 금액
         * refundableAmount   String    환불가능금액. B2B 주문일경우 null
         * refundFee          String    환불 수수료. B2B 주문일경우 null
         * refundAmount       String    환불금액. B2B 주문일경우 null
         * cancellable        Boolean   취소 가능한 경우 true
         * */
        paymentRefundDetails: null, //결제수단별 환불금액. B2B 주문일경우 null
        /**
         * cash               String    캐시 결제 금액에서 환불될 금액
         * point              String    포인트 결제 금액에서 환불될 금액
         * payment            String    PG 또는 Iframe 제휴협체를 통해 결제한 금액에서 환불될 금액
         */
        orderCancel: false, //전체 주문 취소인 경우 true
        // paymentCancellation:{
        //   cancellable:false,//결제취소가 가능한 경우 true
        //   needsAccountTransfer:false,//결제취소할 경우 환불계좌 입력이 필요한 경우 true
        // },
        justSetCancellable: false, //단순취소가 가능한 경우 true
        offerNosToRestore: [], //취소/환불 시 복원될 할인쿠폰 번호 리스트
      },
      consumeUseData: moment().format("YYYY-MM-DD"),
      consumeUseTime: "00:00",
      cancelUseData: "",
      cancelUseTime: "",
      consumeStoreCode: "",
      consumecurrencyCode: "KRW",
      consumememo: "",
      othermemo: "",
      itemRestoreMemo:"",
      itemRestoreNos:"",
      transitemNo: "",
      transgoodsType: "", //상품 타입
      transactions: [], //
      /**
       * transactionNo        String    사용처리 트랜잭션 번호
       * brandName            String    사용처리한 브랜드명
       * brandCode            String    사용처리한 브랜드 코드
       * storeName            String    사용처리한 매장명
       * storeCode            String    사용처리한 매장코드
       * useDate              String    사용한 날짜
       * useAmount            String    사용한 금액 (금액권일 경우에만 사용)
       * currencyCode         String    [USD, KRW]사용한 금액 통화 코드
       * siteName             String    사용처리한 사이트명
       * managerId            String    사용처리한 관리자 ID
       * cancelled            Boolean   취소된 트랜잭션인 경우 true
       * cancelledDate        String    UTC yyyy-MM-ddTHH:mm:ss 취소 처리일
       */
      transmemo: "",
      rewardAccount: {
        bankCode: "", //은행 코드
        bankName: "", //은행명
        accountHolder: "", //예금주
        accountNumber: "", //계좌 번호
      },
      rewardAmount: 0,
      rewardmessage: "",
      rewardDate: "",
      rewardTime: "",
      selorderItems: [],
      brandStoreName: [],
      newmemo: "",
      inputGoodsTypeName: "",
      gide: [],
      isGoodsCompinde: false,
      pointTicketPopover : false,
      pointCouponUseListData : [],
      pointCouponUseListHtmls: '',
      buttonDissabled : false,
      b2bOrderCancellationEnabled:false,
      restoreItems:[],
      cancelledItems:[]
    };
  },

  computed: {

    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    hasIssuedMobileCoupon() {
      for (let item of this.orderItems) {
        if(item.b2bPartnerOrderNo !== null){
          return false;
        }
        if (item.issueMobileCoupon == null || item.issueMobileCoupon === true) {
          return true;
        }
      }

      return false;
    }
  },
  mounted() {
    this.getMessageHis();
    this.getBankList();
    this.refreshPage();
    this.getRefundHistory();
    this.getChannelDetail();

  },
  methods: {
    splitText(text, type) {
      if (text && text !== '') {
        let textChange = text;
        if (text.length >= 20 && type === 'userName') {
          textChange = text.substr(0, 20).concat('...');
        } else if (text.length >= 40 && type === 'buyerEmail') {
          textChange = text.substr(0, 40).concat('...');
        }
        return textChange;
      }
    },
    copyText(text) {
      const $textarea = document.createElement("textarea");

      document.body.appendChild($textarea);

      $textarea.value = text;
      $textarea.select();

      document.execCommand('copy');
      document.body.removeChild($textarea);
    },
    sleftext() {
      let returnText = '';
      // this.orderDetails.channelType,this.orderDetails.selfSales
      if (this.orderDetails.channelType == 'Tablenjoy' && !this.orderDetails.selfSales ) {
        returnText = '테이블엔조이'
      }else if (this.orderDetails.channelType == 'Tablenjoy' && this.orderDetails.selfSales ) {
        returnText = `자체판매 (${this.orderDetails.siteName})`
      }else if (this.orderDetails.channelType != 'Tablenjoy' ) {
        if (this.orderDetails.channelName == 'KAKAOB' ) {
          returnText = '카카오'
        }else{
          returnText =  this.orderDetails.channelName
        }
      }
      return returnText;
    },
    resendClick() {
      var itemNos = $('input:checkbox.chmitem:checked').map(function () {
        return this.value;
      }).get(); //재발급 대상 아이템 번호 리스트
      this.$store.dispatch('setDisabledLayout');
      console.log(itemNos);
      reqPost(
        orderapi.base,
        orderapi.admresendOrderItems,
        this.authToken, {
          orderNo: this.orderNo,
          itemNos: itemNos,
          useSms: this.useSms,
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              this.closeResenddlg();
              this.getMessageHis();
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    closeResenddlg() {
      this.resenddlg = false;
    },
    combindeBoxDiv(combineId) {
      if ($("#" + combineId).hasClass('isShow') == true) {
        $("#" + combineId).removeClass('isShow');
      } else {
        $(".combindeBoxDiv").removeClass('isShow');
        $("#" + combineId).addClass('isShow');
      }
    },
    discountsBoxDiv() {
      if ($("#discountsBoxDiv").hasClass('isShow') == true) {
        $("#discountsBoxDiv").removeClass('isShow');
      } else {
        $(".discountsBoxDiv").removeClass('isShow');
        $("#discountsBoxDiv").addClass('isShow');
      }
    },
    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    allmckech(event) {
      $('input:checkbox.chmitem').prop('checked', event.target.checked);
      var ss = $('input:checkbox.chmitem').next().next().next().next().map(function () {
        return this.value;
      }).get();
      this.gide2 = ss;
      this.gideTrUE = true;
      // console.log(this.gide)
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    viewDateTime(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    viewDateTime2(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    refreshPage() {
      $('input:checkbox.chitem').prop('checked', false);
      $('input:checkbox.chmitem').prop('checked', false);
      reqPost(
        orderapi.base,
        orderapi.admorderDetails,
        this.authToken, {
          orderNo: this.orderNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              // console.log(result.data.orderDetails)
              this.orderDetails = result.data.orderDetails;
              if (this.orderDetails.orderItems[0].itemCombine !== null) {
                this.isGoodsCompinde = true;
              } else {
                this.isGoodsCompinde = false;
              }
              if(this.orderDetails.pointTicket !=null && this.orderDetails.pointTicket !='0' &&this.orderDetails.pointTicket != undefined){
                this.getPointCouponUseHistory();
              }
              this.orderItems = this.orderDetails.orderItems.map((oitem) => {
                return {
                  ...oitem,
                  rcvName: false,
                  rcvPhone: false,
                  nob2b: false,
                  rcvNameNot: false,
                }
              });
              console.log(this.orderItems)
              if (this.orderDetails.orderItems[0].itemCombine !== null) {
                this.orderItems = this.orderDetails.orderItems.map((oitem) => {
                  return {
                    ...oitem,
                    rcvName: false,
                    rcvPhone: false,
                    nob2b: false,
                    rcvNameNot: true,
                  }
                });
                let elflag = false;
                let newComChildArr = [];
                this.orderDetails.orderItems.forEach((element, idx) => {
                  newComChildArr.push(this.orderItems[idx].itemCombine.combineId);
                });

                this.orderDetails.orderItems.forEach((element, idx) => {
                  // console.log(newComChildArr.indexOf(newComChildArr[idx]))
                  elflag = false;
                  const result1 = this.orderDetails.orderItems.find(item => item.itemCombine.combineId === element.itemCombine.combineId);

                  // 같은 콤바인드 아이디이면 undefined 가 아닐떄
                  if (result1 !== undefined) {
                    if (elflag == false) {
                      this.orderItems[newComChildArr.indexOf(newComChildArr[idx])].rcvNameNot = false;

                    } else {
                      this.orderItems[idx].rcvNameNot = true;
                    }
                    elflag = true;
                  }
                });
              }
              // console.log(newComChildArr)
              for (let o = 0; o < this.orderDetails.orderItems.length; o++) {
                if (this.orderDetails.orderItems[o].b2bItemNo == null) {
                  this.orderItems[o].nob2b = false;
                } else {
                  this.orderItems[o].nob2b = true;
                }
                // console.log(this.orderItems[o].rcvNameNot)
              }

              if (this.orderItems.length > 0) {
                let vm = this;
                if (vm.orderItems[0].goodsId > 0) {
                  this.getAvailableStore(this.orderItems[0].goodsId);
                }
                this.goodsType = this.orderItems[0].goodsType;

              }
              console.log(this.orderItems)
              this.requestPhoneNumber = this.orderDetails.buyerPhone;
            } else {
              this.orderDetails = null;
              alert(result.message);
            }
          }
        }
      );
    },
    closebuycancel() {
      this.buycanceldlg = false;
    },
    closeReward() {
      this.rewarddlg = false;
    },
    showReward() {
      this.rewardAccount.bankCode = "";
      this.rewardAccount.bankName = "";
      this.rewardAccount.accountNumber = "";
      this.rewardAccount.accountHolder = "";
      this.rewardAmount = "";
      this.rewardmessage = "";
      this.rewardDate = "";
      this.rewardTime = "";
      this.rewarddlg = true;
    },
    closeUseProcess() {
      this.useprocessdlg = false;
    },
    closeMemodlg() {
      this.othermemo = "";
      this.memodlg = false;
    },
    closeItemRestoreModal() {
      this.itemRestoreMemo = "";
      this.itemRestoreModal = false;
      this.itemRestoreNos = "";
    },
    showMemodlg() {
      this.othermemo = "";
      this.memodlg = true;
    },

    checkCancelledItems() {
      this.orderDetails.orderItems.forEach(item => {
        if (item.itemStatus === 'Cancelled' && Number(item.usedAmount) === 0 && item.b2bItemNo === null && this.checkB2bOrder()) {
          this.restoreItems.push(item.itemNo);
        }
      });
      console.log(this.restoreItems)

    },

    showItemRestoreMemoModal() {
      this.restoreItems.length=0;
      this.checkCancelledItems();
      this.itemRestoreMemo = "";
      this.itemRestoreNos =this.restoreItems;
      this.itemRestoreModal = true;
    },
    checkB2bOrder() {
      return this.orderDetails.channelType === 'B2bChannel' && this.orderDetails.orderStatus === 'Purchased' && this.b2bOrderCancellationEnabled;
    },
    showPointTicketPopover(val){
      console.log(val)
      if(!val){
        this.pointTicketPopover = false
      }else{
        this.pointTicketPopover = true
      }
    },
    getCancelledItems() {
      setTimeout(()=> {
        this.orderDetails.orderItems.forEach(orderItem=>{
          if (orderItem.itemStatus === 'Cancelled' && orderItem.b2bItemNo === null ) {
            this.cancelledItems.push(orderItem);
          }
        });
      },200);
    },
    getChannelDetail() {
      setTimeout(()=> {
        let returnText = null;
        if (this.orderDetails.channelType != 'Tablenjoy' ) {
          returnText = this.orderDetails.channelName
        }

        if(returnText !== null){
          reqGet(
            b2bapi.base,
            b2bapi.channelDetail + "?channelCode=" + returnText,
            this.authToken,
            (result) => {
              if (result != null && result.code === rescode.REQ_SUCCESS) {
                this.b2bOrderCancellationEnabled = result.data.b2bOrderCancellationEnabled;
              }
            }
          );
        }
      },200);
    },
    showUseProcess() {
      var checkarray = $('input:checkbox.chmitem:checked').map(function () {
        return this.value;
      }).get();
      this.selorderItems = this.orderItems.filter((titem) => {
        var t_idx = checkarray.findIndex((citem) => citem == titem.itemNo);
        return t_idx > -1;
      });
      if (this.selorderItems.length == 0) {
        alert("사용처리할 쿠폰을 선택하세요.");
        return;
      }
      // console.log(this.value)
      var inputGoodsType = $('input:checkbox.chmitem:checked').next().map(function () {
        return this.value;
      }).get();
      this.inputGoodsType = inputGoodsType;
      var nob2b = $('input:checkbox.chmitem:checked').next().next().map(function () {
        return this.value;
      }).get();

      var goodsType = $('input:checkbox.chmitem:checked').next().next().next().map(function () {
        return this.value;
      }).get();

      var goodsId = $('input:checkbox.chmitem:checked').next().next().next().next().map(function () {
        return this.value;
      }).get();

      this.gide = goodsId;
      if (this.isGoodsCompinde == true) {
        let noOpo = [];
        this.gide.forEach(function (el, index, array) {
          if (array[index + 1] !== undefined) {
            if (el !== array[index + 1]) {
              noOpo.push('no')
            }
          }
        });
        if (noOpo.includes('no')) {
          alert('불가능한 옵션이 포함되어 있습니다.');
          noOpo = [];
          return;
        }
        this.getAvailableStore(this.gide[0]);
        this.inputGoodsTypeName = inputGoodsType;
        // alert(inputGoodsTypeName)
        if (inputGoodsType.length > 1) {
          if (inputGoodsType.indexOf('MobileCoupon') !== -1) {
            if (inputGoodsType.indexOf('MoneyCoupon') !== -1) {
              alert('상품 유형에 맞게 선택하세요.')
              return;
            }
          }
        }
      }

      this.consumeStoreCode = "";
      this.consumeUseData = "";
      this.consumeUseTime = "";
      this.consumememo = "";
      this.useprocessdlg = true;
    },
    closeUseHis() {
      this.usehisdlg = false;
      this.usehisdlg2 = false;
    },
    phoneformat(val) {
      return formatPhone(val);
    },
    getTotaldiscount() {
      var retval = 0;
      for (var idx = 0; idx < this.orderDetails.discounts.length; idx++) {
        retval = retval + this.orderDetails.discounts[idx].discountAmount;
      }
      return retval;
    },
    getOrdState(val) {
      if (val == "Purchased") {
        return "결제완료";
      } else if (val == "CancelRequested") {
        return "취소요청";
      } else if (val == "CancelFailed") {
        return "취소실패";
      } else if (val == "Cancelled") {
        return "취소완료";
      }
    },
    goodsTypeVal(val) {
      return getGoodsTypeStr(val);
    },
    editrcvPhone(didx) {
      this.orderItems[didx].rcvPhone = true;
    },
    editrcvName(didx) {
      this.orderItems[didx].rcvName = true;
    },
    restoreRequest() {
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.restoreCancelledOrder,
        this.authToken, {
          orderNo: this.orderNo,
          memo: this.othermemo
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeMemodlg();
              this.refreshPage();
              this.cancelledItems.length = 0;
            } else {
              this.orderDetails = null;
              alert(result.message);
            }
          }
        }
      );
    },
    itemRestoreRequest() {

      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.itemRestore,
        this.authToken, {
          orderNo: this.orderNo,
          itemNoList: this.itemRestoreNos,
          memo: this.itemRestoreMemo
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeItemRestoreModal();
              this.cancelledItems.length = 0;
              this.refreshPage();
            } else {
              alert(result.message);
              this.closeItemRestoreModal();
            }
          }
        }
      );
    },
    getMsgHisStr() {
      var retval = "";
      for (var idx = 0; idx < this.messagehis.length; idx++) {
        if (retval != "") {
          retval = retval + "\n";
        }
        retval = retval + this.viewDateTime(this.messagehis[idx].sendDate) + " / " + this.messagehis[idx].phoneNumber + " / ";
        if (this.messagehis[idx].autoSend) {
          retval = retval + "자동발송" + " / ";
        } else {
          retval = retval + "재발송" + " / ";
        }

        if (this.messagehis[idx].sendType == 'ALIMTALK') {
          retval = retval + "알림톡";
        } else if (this.messagehis[idx].sendType == 'MMS') {
          retval = retval + "MMS";
        }

        if (this.messagehis[idx].requestedBy != null && this.messagehis[idx].requestedBy != "") {
          retval = retval + "-" + this.messagehis[idx].requestedBy;
        }
      }
      return retval;
    },
    getRefHtml() {
      var retval = "";

      function getType(type) {
        let trType = "";
        switch (type){
          case "PARTNER":
            trType = "파트너 or 장소 or 매장";
            break;
          case "PURCHASER":
            trType = "구매자";
            break;
          case  "ETC":
            trType = "기타";
            break;
        }
        return trType;
      }
      function getReasonType(part) {
        let trTypeReason = "";
        switch (part){
          case "PURCHASER_REQUEST":
            trTypeReason = "구매자요청";
            break;
          case "RE_BOOKING":
            trTypeReason = "재예약 진행";
            break;
          case "HEALTH_REASON":
            trTypeReason = "건강상의 이유"
            break;
          case "THIRD_PARTY_LOWEST_PRICE":
            trTypeReason = "타사 최저가 이용"
            break;
          case "SIMPLE_REMORSE":
            trTypeReason = "단순 변심"
            break;
          case "STORE_SUSPENSION":
            trTypeReason ="장소 운휴"
            break;
          case "BOOKING_CLOSED":
            trTypeReason = "예약 마감"
            break;
          case "SHORTAGE_OF_PURCHASERS":
            trTypeReason ="모객 부족"
            break;
          case "NATURAL_DISASTER":
            trTypeReason ="천재 지변"
            break;
          case "INFECTIOUS_DISEASE_SAFETY_RULES":
            trTypeReason ="감염병 안전쉭 준수"
            break;
          case "AUTO_CANCEL":
            trTypeReason ="자동 취소"
            break;
          default:
            trTypeReason = part;
          break;
        }


        return trTypeReason;
      }

      for (var idx = 0; idx < this.refundHistory.length; idx++) {
        if (retval != "") {
          retval += "\n";
        }
        if (this.refundHistory[idx].transactionName) {
          let trName = "";
          switch (this.refundHistory[idx].transactionName) {
            case "ORDER_ITEMS_PAYMENT_REFUNDED":
              trName = "결제취소 - ";
              break;
            case "ORDER_ITEMS_JUST_SET_CANCELLED":
              trName = "단순취소처리 - ";
              break;
            case "ORDER_ITEMS_REFUND_CS_CREATED":
              trName = "환불CS접수 - ";
              break;
            case "REFUND_CS_ACCEPT_REQUEST_BY_ACCOUNT_TRANSFER":
              trName = "환불CS승인(계좌환불) - ";
              break;
            case "REFUND_CS_ACCEPT_REQUEST_BY_JUST_SET_CANCELLED":
              trName = "환불CS승인(단순취소처리) -";
              break;
            case "REFUND_CS_REJECT_REQUEST":
              trName = "환불CS거절 - ";
              break;
            case "TRANSFER_CS_COMPLETE":
              trName = "계좌이체완료 - ";
              break;
            case "REWARD_CS_REQUEST_TRANSFER":
              trName = "보상신청 - ";
              break;
            case "B2B_ORDER_ITEMS_CANCELLED":
              trName = "API 취소처리 - ";
              break;
            case "KAKAO_BOOKING_CANCEL":
              trName = "API 결제취소 - ";
              break;
          }
          retval += trName;
        }
        let newMoney = Number(this.refundHistory[idx].refundAmount);

        if (this.refundHistory[idx].refundAmount) {
          retval += "환불금액: " + this.refundHistory[idx].refundAmount;
        }

        if (this.refundHistory[idx].refundDetails) {
          retval += " (";

          let hasPrevDetail = false;

          if (this.refundHistory[idx].refundDetails.accountRefund) {
            retval += "계좌이체: " + this.refundHistory[idx].refundDetails.accountRefund;
            hasPrevDetail = true;
          }

          if (this.refundHistory[idx].refundDetails.agentRefund) {
            if (hasPrevDetail) {
              retval += " ,";
            }
            retval += "  Agent: " + this.refundHistory[idx].refundDetails.agentRefund;
            hasPrevDetail = true;
          }

          if (this.refundHistory[idx].refundDetails.cashRefund) {
            if (hasPrevDetail) {
              retval += " ,";
            }
            retval += "  Cash: " + this.refundHistory[idx].refundDetails.cashRefund;
            hasPrevDetail = true;
          }

          if (this.refundHistory[idx].refundDetails.pointRefund) {
            if (hasPrevDetail) {
              retval += " ,";
            }
            retval += "  Reward: " + this.refundHistory[idx].refundDetails.pointRefund + " ";
          }

          if (this.refundHistory[idx].refundDetails.pointTicketRefund) {
            if (hasPrevDetail) {
              retval += " ,";
            }
            retval += "  포인트권: " + this.refundHistory[idx].refundDetails.pointTicketRefund + " ";
          }

          retval += ") ";
        }

        if (this.refundHistory[idx].refundFee) {
          retval += "  환불수수료: " + this.refundHistory[idx].refundFee + " ";
        }
        if (this.refundHistory[idx].bookingPenalty) {
          retval += "  위약금: " + this.refundHistory[idx].bookingPenalty + " ";
        }
        if (this.refundHistory[idx].memo) {
          if(this.refundHistory[idx].memo != null && this.orderDetails.channelName == 'KAKAOB'){
            const parts = this.refundHistory[idx].memo.split("//");
            let type = getType(parts[0]);
            let typeReason =  getReasonType(parts[1]);
            let memo = "";

            if(parts[2]!==undefined){
               memo = parts[2]
            }

            retval += "  환불사유: " + type +" "+ typeReason +" "+ memo+ " ";

          }else {
            retval += "  환불사유: " + this.refundHistory[idx].memo + " ";
          }
        } else {
          retval += "  환불사유: " + "" + " ";
        }

        retval += " by " + this.refundHistory[idx].executedBy + " at ";
        retval += " " + this.viewDateTime(this.refundHistory[idx].transactionTime);

      }
      return retval;
    },
    getRefundHistory() {
      reqPost(
        orderapi.base,
        orderapi.refundHistory,
        this.authToken, {
          orderNo: this.orderNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refundHistory = result.data.logs;
              console.log(this.refundHistory)
            } else {
              this.refundHistory = [];
              alert(result.message);
            }
          }
        }
      );
    },
    getMessageHis() {
      reqPost(
        message.base,
        message.sendHistory,
        this.authToken, {
          orderNo: this.orderNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.messagehis = result.data.transactions;
            } else {
              this.messagehis = [];
              alert(result.message);
            }
          }
        }
      );
    },
    getBankList() {
      reqGet(
        brandapi.base,
        brandapi.banklist,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.banklist = result.data.banks;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getCouponState(val) {
      if (val == "Available") {
        return "사용가능";
      } else if (val == "CancelRequested") {
        return "취소요청";
      } else if (val == "CancelFailed") {
        return "취소실패";
      } else if (val == "Cancelled") {
        return "취소완료";
      }
    },
    onChangeBank() {
      var tidx = this.banklist.findIndex(bitem => bitem.bankCode == this.refundAccount.bankCode);
      if (tidx > -1) {
        this.refundAccount.bankName = this.banklist[tidx].bankName;
      }
    },
    onChangeRewardBank() {
      var tidx = this.banklist.findIndex(bitem => bitem.bankCode == this.rewardAccount.bankCode);
      if (tidx > -1) {
        this.rewardAccount.bankName = this.banklist[tidx].bankName;
      }
    },
    getrefundAmount() {
      this.buttonDissabled = true;
      this.itemsToCancel = $('input:checkbox.chmitem:checked').map(function () {
        return this.value;
      }).get();
      this.orderCancel = false;
      if (this.itemsToCancel.length == 0) {
        this.orderCancel = true;
      }

      reqPost(
        orderapi.base,
        orderapi.admrefundAmount,
        this.authToken, {
          orderNo: this.orderNo,
          orderCancel: this.orderCancel,
          itemsToCancel: this.itemsToCancel,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refundData = result.data;
              console.log(this.refundData)
              this.avaArray = [];
              let refundDataArr = ['PaymentCancel', 'CreateRefundCs', 'JustSetCancelled'];
              this.refundData.availableRefundMethods.forEach(ava => {
                refundDataArr.forEach((el, idx) => {
                  if (el === ava) {
                    this.avaArray.push(el)
                  }
                });
              });
              if (this.avaArray[0] === "PaymentCancel") {
                this.cancelmethod = 'Payment';
              } else if (this.avaArray[0] === 'CreateRefundCs') {
                this.cancelmethod = 'RefundCs';
              } else {
                this.cancelmethod = 'JustSet';
              }
              this.refundFeeRequired = false;
              this.requestReason = "";
              this.refundAccount.bankCode = "";
              this.refundAccount.bankName = "";
              this.refundAccount.accountNumber = "";
              this.refundAccount.accountHolder = "";
              this.buycanceldlg = true;
              this.buttonDissabled = false;
              // console.log(this.refundData.)
            } else {
              this.refundData = null;
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    onAllowProcess(ignoreCancellable) {
      var target = {
        orderNo: this.orderNo,
        orderCancel: this.orderCancel,
        itemsToCancel: this.itemsToCancel
      };
      if (this.cancelmethod === "Payment" || this.cancelmethod === "RefundCs" || this.cancelmethod === "SimpleRefundCs") {
        var url = orderapi.cancelByPaymentCancel;
        if (this.cancelmethod === "RefundCs" || this.cancelmethod === "SimpleRefundCs" && !ignoreCancellable) {
          if(this.cancelmethod === "SimpleRefundCs" && !this.refundSimpleDirectInput) {
            url = orderapi.cancelByCreateSimpleRefundCs;
          }else if(this.cancelmethod === "RefundCs" && !this.refundDirectInput){
            url = orderapi.cancelByCreateSimpleRefundCs;
          } else {
            url = orderapi.cancelByCreateRefundCs;
          }
        }
        var reqdata = {};
        if(this.cancelmethod === "SimpleRefundCs" && !this.refundSimpleDirectInput){
          const msg = "유효하지 않은 핸드폰 번호입니다. 다시 한번 확인 해주세요.";
          const regex = /^01(0|1|[6-9])[0-9]{3,4}[0-9]{4}$/;

          if (!regex.test(this.requestPhoneNumber)) {
            alert(msg);
            return false;
          }
          reqdata = {
            target: target,
            requestPhoneNumber:this.requestPhoneNumber,
            ignoreAllFee: this.refundFeeRequired,
            requestReason: this.requestReason,
            cancellationDate: null,
            ignoreCancellable: ignoreCancellable,
          };
        } else if(this.cancelmethod === "SimpleRefundCs" && this.refundSimpleDirectInput){
          reqdata = {
            target: target,
            requestPhoneNumber:this.requestPhoneNumber,
            refundAccount: this.refundAccount,
            ignoreAllFee: this.refundFeeRequired,
            requestReason: this.requestReason,
            cancellationDate: null,
            ignoreCancellable: ignoreCancellable,
            accountDirectly:this.refundSimpleDirectInput
          };
        }else if(this.cancelmethod === "RefundCs" && !this.refundDirectInput){
          const msg = "유효하지 않은 핸드폰 번호입니다. 다시 한번 확인 해주세요.";
          const regex = /^01(0|1|[6-9])[0-9]{3,4}[0-9]{4}$/;

          if (!regex.test(this.requestPhoneNumber)) {
            alert(msg);
            return false;
          }
          reqdata = {
            target: target,
            requestPhoneNumber:this.requestPhoneNumber,
            ignoreAllFee: this.refundFeeRequired,
            requestReason: this.requestReason,
            cancellationDate: null,
            ignoreCancellable: ignoreCancellable,
          };


        } else {
          reqdata = {
            target: target,
            refundAccount: this.refundAccount,
            ignoreAllFee: this.refundFeeRequired,
            requestReason: this.requestReason,
            cancellationDate: null,
            ignoreCancellable: ignoreCancellable,
          };
        }

        if (ignoreCancellable) {
          reqdata.target.orderCancel = true;
          reqdata.ignoreAllFee = true;
        }

        if (!this.refundData.needsRefundAccountInfo && this.cancelmethod == 'Payment') {
          reqdata.refundAccount = null;
        }

        this.$store.dispatch('setDisabledLayout');
        reqPost(
          orderapi.base,
          url,
          this.authToken,
          reqdata,
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
                this.closebuycancel();
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else if (this.cancelmethod == "JustSet") //단순취소처리
      {
        this.$store.dispatch('setDisabledLayout');
        reqPost(
          orderapi.base,
          orderapi.justSetCancelled,
          this.authToken, {
            target: target,
            requestReason: this.requestReason,
          },
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
                this.closebuycancel();
                this.getCancelledItems();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    unlockCoupon(orderNo, itemNo){

      if (confirm("쿠폰상태를 사용가능으로 원복하시겠습니까?")) {
        var request = {
          orderNo: orderNo,
          itemNo: itemNo,
        };
        reqPost(
          orderapi.base,
          orderapi.unlockItem,
          this.authToken,
          request,
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
              } else {
                alert(result.message);
              }
            }
          }
        )
      }else {
        console.log("false")
      }
    },
    doProcessUse() {
      this.buttonDissabled = true;
      if (this.consumeStoreCode == "") {
        this.buttonDissabled = false;
        alert("사용매장을 선택하세요.");
        return;
      }

      var useDate = null;
      if (this.consumeUseData != "" && this.consumeUseTime != "") {
        useDate = DateFormat(this.consumeUseData + " " + this.consumeUseTime + ":00", "YYYY-MM-DDTHH:mm:00");
      }

      var consumeorders = [];
      var itemNos = null;
      var orders = null;
      if (this.inputGoodsTypeName[0] == "MobileCoupon" || this.inputGoodsType[0] == "MobileCoupon") {
        itemNos = this.selorderItems.map((sitem) => {
          return sitem.itemNo
        });
        orders = {
          orderNo: this.orderNo,
          itemNos: itemNos
        };
        consumeorders.push(orders);
        var reqdata = {
          useDate: useDate,
          orders: consumeorders,
          /**
           * orders[].orderNo       String    주문 번호
           * orders[].itemNos[]     Array     쿠폰 번호 리스트
           */
          storeCode: this.consumeStoreCode,
          memo: this.consumememo,
        };

        this.$store.dispatch('setDisabledLayout');
        reqPost(
          orderapi.base,
          orderapi.tnjconsumeMobileCoupons,
          this.authToken,
          reqdata,
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.consumeUseTime = "00:00";
                this.consumeUseData = moment().format("YYYY-MM-DD");
                this.consumememo = "";
                this.buttonDissabled = false;
                this.refreshPage();
                this.closeUseProcess();
              } else {
                this.buttonDissabled = false;
                alert(result.message);
              }
            }
          }
        );
      } else if (this.inputGoodsTypeName[0] == "MoneyCoupon" || this.inputGoodsType[0] == "MoneyCoupon") {
        itemNos = this.selorderItems.map((sitem) => {
          return {
            itemNo: sitem.itemNo,
            useAmount: sitem.paymentAmount
          }
        });
        orders = {
          orderNo: this.orderNo,
          items: itemNos
        };
        consumeorders.push(orders);
        var reqdata1 = {
          useDate: useDate,
          orders: consumeorders,
          /**
           * orders[].orderNo             String    주문 번호
           * orders[].items[].itemNo      String    아이템 번호
           * orders[].items[].useAmount   String    사용할 금액
           */
          storeCode: this.consumeStoreCode,
          memo: this.consumememo,
          currencyCode: this.consumecurrencyCode,
        };
        this.$store.dispatch('setDisabledLayout');
        reqPost(
          orderapi.base,
          orderapi.tnjuseMoneyCoupons,
          this.authToken,
          reqdata1,
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.consumeUseTime = "00:00";
                this.consumeUseData = moment().format("YYYY-MM-DD");
                this.buttonDissabled = false;
                this.refreshPage();
                this.closeUseProcess();
              } else {
                this.buttonDissabled = false;
                alert(result.message);
              }
            }
          }
        );
      } else if (this.inputGoodsTypeName[0] == "BookingCoupon" || this.inputGoodsType[0] == "BookingCoupon") {
        itemNos = this.selorderItems.map((sitem) => {
          return sitem.itemNo
        });
        orders = {
          orderNo: this.orderNo,
          itemNos: itemNos
        };
        consumeorders.push(orders);
        var reqdata = {
          useDate: useDate,
          orders: consumeorders,
          /**
           * orders[].orderNo       String    주문 번호
           * orders[].itemNos[]     Array     쿠폰 번호 리스트
           */
          storeCode: this.consumeStoreCode,
          memo: this.consumememo,
        };

        this.$store.dispatch('setDisabledLayout');
        reqPost(
          orderapi.base,
          orderapi.tnjconsumeBookingCoupons,
          this.authToken,
          reqdata,
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.consumeUseTime = "00:00";
                this.consumeUseData = moment().format("YYYY-MM-DD");
                this.consumememo = "";
                this.buttonDissabled = false;
                this.refreshPage();
                this.closeUseProcess();
              } else {
                this.buttonDissabled = false;
                alert(result.message);
              }
            }
          }
        );
      }
    },
    getUseHisData(itemNo) {
      if (this.goodsType != 'MobileCoupon' && this.goodsType != 'MoneyCoupon' && this.goodsType != 'BookingCoupon' && this.goodsType != 'PointCoupon') {
        return;
      }
      reqPost(
        orderapi.base,
        orderapi.admitemUseInfo,
        this.authToken, {
          itemNo: itemNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              if (result.data != null) {
                this.transitemNo = result.data.itemNo;
                this.transgoodsType = result.data.goodsType;
                this.transactions = result.data.transactions;
                this.usehisdlg = true;
              }
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getPointUseHisData(itemNo){
      reqPost(
        orderapi.base,
        orderapi.admitemUseInfo,
        this.authToken, {
          itemNo: itemNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              if (result.data != null) {
                this.transitemNo = result.data.itemNo;
                this.transgoodsType = result.data.goodsType;
                this.transactions = result.data.transactions;
                this.usehisdlg2 = true;
              }
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doHisUseCancel() {
      var checkarray = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      var t_transactions = this.transactions.filter((titem) => {
        var t_idx = checkarray.findIndex((citem) => citem == titem.transactionNo);
        return t_idx > -1;
      });
      var transactions = t_transactions.map((titem) => {
        return {
          itemNo: this.transitemNo,
          transactionNo: titem.transactionNo
        }
      });
      /**
       * itemNo           String    아이템 번호
       * transactionNo    String    사용처리 취소할 트랜잭션 번호
       */

      var cancelledDate = null;
      if (this.cancelUseData != "" && this.cancelUseTime != "") {
        cancelledDate = DateFormat(this.cancelUseData + " " + this.cancelUseTime + ":00", "YYYY-MM-DDTHH:mm:00");
      }
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.cancelItemUseTransaction,
        this.authToken, {
          memo: this.transmemo,
          transactions: transactions,
          cancelledDate: cancelledDate
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.cancelUseData = "";
              this.cancelUseTime = "";
              this.refreshPage();
              this.closeUseHis();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onResendOrder(noLs) {
      this.buttonDissabled = true;
      var checkarray = $('input:checkbox.chmitem:checked').map(function () {
        return this.value;
      }).get();
      this.selorderItems = this.orderItems.filter((titem) => {
        var t_idx = checkarray.findIndex((citem) => citem == titem.itemNo);
        return t_idx > -1;
      });
      if (this.selorderItems.length == 0) {
        this.buttonDissabled = false;
        alert("재발송할 쿠폰을 선택하세요.");
        return;
      }
      if (noLs) {
        this.resenddlg = false;
        this.useSms = false;
        this.resendClick();
      } else {
        this.buttonDissabled = false;
        this.useSms = false;
        this.resenddlg = true;
      }
    },
    onReissueItems() {
      this.buttonDissabled = true;
      let chkUsed
      // 여기

      var nob2b = $('input:checkbox.chmitem:checked').next().next().map(function () {
        return this.value;
      }).get();
      if (nob2b.indexOf('true') !== -1) {
        this.buttonDissabled = false;
        alert('불가능한 옵션이 포함되어 있습니다.')
        return;
      }
      var itemNos = $('input:checkbox.chmitem:checked').map(function () {
        return this.value;
      }).get(); //재발급 대상 아이템 번호 리스트

      // 포인트권 사용일 없을떄 쿠폰 재발급 가능하게 하기위한 사전작업
      let tmpFilterdArr = []
      let reChk = true
      itemNos.forEach((item)=>{
        let tmpArr = this.orderItems.filter((items)=>{return items.itemNo == item})
        tmpFilterdArr.push(tmpArr[0])
      })
      tmpFilterdArr.forEach((item)=>{
        if(item.consumedDate !=null){
          reChk = false;
        }
      });
      if(!reChk){
        this.buttonDissabled = false;
        alert("사용된 쿠폰이 포함되어 있어 불가합니다.")
        return;
      }


      var reqdata = {
        orderNo: this.orderNo,
        itemNos: itemNos,
        memo: "", //this.memo,
      }
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.reissueItems,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    onLockItems() {
      this.buttonDissabled = true;
      var nob2b = $('input:checkbox.chmitem:checked').next().next().map(function () {
        return this.value;
      }).get();
      if (nob2b.indexOf('true') !== -1) {
        this.buttonDissabled = false;
        alert('불가능한 옵션이 포함되어 있습니다.')
        return;
      }
      var itemNos = $('input:checkbox.chmitem:checked').map(function () {
        return this.value;
      }).get(); //사용불가처리 대상 아이템 번호 리스트
      var reqdata = {
        orderNo: this.orderNo,
        itemNos: itemNos,
      };
      reqPost(
        orderapi.base,
        orderapi.lockItems,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      )
    },
    goDetailPage(goodid, goodsType) {
      this.$router.push(this.routes.editprodinf + "?goodid=" + goodid + "&goodsType=" + goodsType);
    },
    goBookDetail(bookingNo) {
      this.$router.push(this.routes.detailbook + "?bookingNo=" + bookingNo);
    },
    goDetailOrder(orderNo) {
      this.usehisdlg = false;
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
      location.reload()
    },
    onExtendExpiry() {
      this.buttonDissabled = true;
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.admextendExpiryDate,
        this.authToken, {
          orderNo: this.orderNo,
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              alert(result.message);
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    onSaveMemo() {
      reqPost(
        orderapi.base,
        orderapi.admsaveMemo,
        this.authToken, {
          orderNo: this.orderNo,
          memo: this.newmemo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.newmemo = "";
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getCouponStr(str) {
      if (str == "Available") {
        return "사용가능";
      } else if (str == "BookingConnected") {
        return "예약";
      } else if (str == "CancelRequested") {
        return "취소요청";
      } else if (str == "Cancelled") {
        return "취소완료";
      } else if (str == "Locked") {
        return "사용불가";
      } else if (str == "Consumed") {
        return "사용완료";
      } else if (str == "Invalidated") {
        return "폐기";
      }
    },
    onChangeItemRcv() {

      var itemss = "";
      let data = {};
      if (this.isGoodsCompinde == false) {
        let t_orderItems = this.orderItems.filter(item => item.rcvName || item.rcvPhone);
        itemss = t_orderItems.map((item) => {
          return {
            itemNo: item.itemNo,
            receiverName: item.receiverName,
            phoneNumber: item.receiverPhone
          }
        });
        data = {
          orderNo: this.orderNo,
          items: itemss,
          memo: this.orderDetails.memo
        }
      } else {

        let thiscombineIdArr = [];
        let combineId = $('input:checkbox.chmitem:checked').next().next().next().next().next().map(function () {
          return this.value;
        }).get();
        thiscombineIdArr = combineId;
        const results = thiscombineIdArr.filter((item, index, arr) => (arr.lastIndexOf(item) == arr.indexOf(item)));
        let chkboxs = $('input:checkbox.chmitem:checked')

        if (chkboxs.length == 0) {
          alert('수신자 정보를 수정할 쿠폰을 선택해주세요.');
          return;
        }
        // console.log(results)
        if (results.length >= 2) {
          alert('결합상품은 결합ID별로 수신자 정보수정이 가능합니다.')
          return
        }
        // console.log(sss)
        let it2 = [];

        let newComChildArr = [];
        this.orderItems.forEach((element, idx) => {
          newComChildArr.push(this.orderItems[idx].itemCombine.combineId);
        });

        //

        this.orderItems.forEach((element, idx) => {
          if (Number(combineId[0]) === this.orderItems[idx].itemCombine.combineId) {
            console.log(this.orderItems[idx].b2bItemNo)
            if (this.orderItems[idx].b2bItemNo == null) {
              it2.push({
                itemNo: this.orderItems[idx].itemNo,
                receiverName: this.orderItems[newComChildArr.indexOf(newComChildArr[idx])].receiverName,
                phoneNumber: this.orderItems[newComChildArr.indexOf(newComChildArr[idx])].receiverPhone
              })
            } else {
              it2 = [];
              alert('ls컴퍼니 상품은 수신자 변경이 불가합니다.');
              return;
            }

          }
        });
        // console.log(this.orderItems)
        // console.log(JSON.stringify(it2))
        itemss = JSON.stringify(it2);

        data = {
          orderNo: this.orderNo,
          items: JSON.parse(itemss),
          memo: this.orderDetails.memo
        }
      }

      // console.log(this.orderItems)
      /**
       * itemNo           String    쿠폰 번호
       * receiverName     String    수신자 이름
       * phoneNumber      String    수신자 전화번호
       */
      reqPost(
        orderapi.base,
        orderapi.changeItemReceiver,
        this.authToken,
        data,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doRewardProcess() {
      this.buttonDissabled = true;
      if (this.rewardDate == "") {
        this.buttonDissabled = false;
        alert("입금예정일을 입력하세요");
        return;
      }
      if (this.rewardTime == "") {
        this.buttonDissabled = false;
        alert("입금예정 시간을 입력하세요");
        return;
      }
      var scheduledDate = DateFormat(this.rewardDate + " " + this.rewardTime + ":00", "YYYY-MM-DDTHH:mm:00");
      var reqdata = {
        orderNo: this.orderNo,
        memo: null,
        rewardAccount: this.rewardAccount,
        rewardAmount: this.rewardAmount,
        currencyCode: "KRW",
        scheduledDate: scheduledDate,
        message: this.rewardmessage
      };
      reqPost(
        orderapi.base,
        orderapi.requestTransfer,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              this.refreshPage();
              this.closeReward();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    getAvailableStore(goodsId) {
      reqGet(
        goodsapi.base,
        goodsapi.goodscondition + "?goodsId=" + goodsId,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              var t_storeCodes = [];
              for (var idx = 0; idx < result.data.serviceArea.brands.length; idx++) {
                for (var jdx = 0; jdx < result.data.serviceArea.brands[idx].storeCodes.length; jdx++) {
                  var t_sidx = t_storeCodes.findIndex(sCode => sCode == result.data.serviceArea.brands[idx].storeCodes[jdx]);
                  if (t_sidx == -1) {
                    t_storeCodes.push(result.data.serviceArea.brands[idx].storeCodes[jdx]);
                  }
                }
              }
              this.getBrandStoreName(t_storeCodes);
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getBrandStoreName(storeCodes) {
      reqPost(
        brandapi.base,
        brandapi.storeCodeNames,
        this.authToken, {
          storeCodes: storeCodes,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.brandStoreName = [];
              for (var idx = 0; idx < result.data.byBrands.length; idx++) {
                for (var jdx = 0; jdx < result.data.byBrands[idx].stores.length; jdx++) {
                  var t_bsidx = this.brandStoreName.findIndex((bsitem) => bsitem.storeCode == result.data.byBrands[idx].stores[jdx].storeCode);
                  if (t_bsidx == -1) {
                    this.brandStoreName.push({
                      ...result.data.byBrands[idx].stores[jdx],
                      storeName: result.data.byBrands[idx].brandName + "-" + result.data.byBrands[idx].stores[jdx].storeName
                    });
                  }
                }
              }
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    changePayAmount(evt, index) {
      console.log(evt.target.value)
      this.selorderItems[index].paymentAmount = evt.target.value;
      if (this.selorderItems[index].usableAmount < parseInt(evt.target.value)) {
        alert('사용금액이 사용가능 금액보다 큽니다.');
        evt.target.value = 0;
        return;
        // this.selorderItems[index].paymentAmount = this.selorderItems[index].usableAmount;
      }
    },
    getPointCouponUseHistory() {
      this.pointCouponUseListHtmls = '<div style="font-size: 14px;">'
      reqPost(
        orderapi.base,
        orderapi.pointCouponUseList,
        this.authToken, {
          orderNo: this.orderNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.pointCouponUseListData = result.data.logs;
              this.pointCouponUseListData.forEach((item)=>{
                this.pointCouponUseListHtmls += `<sapn>${item.itemNo} (${item.useAmount} 원) <br>`
              })
              this.pointCouponUseListHtmls += '</span></div>'
              console.log(this.pointCouponUseListData)
            } else {
              this.pointCouponUseListData = [];
              alert(result.message);
            }
          }
        }
      );
    },
    pointCouponBoxDiv() {
      if ($("#pointCouponBoxDiv").hasClass('isShow') == true) {
        $("#pointCouponBoxDiv").removeClass('isShow');
      } else {
        $(".pointCouponBoxDiv").removeClass('isShow');
        $("#pointCouponBoxDiv").addClass('isShow');
      }
    },
    checkOrderItemStatus() {
      let isTrue = true;
      this.cancelledItems.forEach(orderItem => {
        if (Number(orderItem.usedAmount) !== 0 ) {
          isTrue = false;
        }
      });
      if(this.cancelledItems.length ===0){
        isTrue = false;
      }
      if(!this.checkB2bOrder()){
        isTrue = false;
      }
      return isTrue;
    },

    resetAccountChkCount(){
      console.log(this.orderDetails.orderNo)
      reqGet(
        payment.base,
        payment.validAccountReset + "?orderNumber=" + this.orderDetails.orderNo,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert(result.message);
            } else {
              alert(result.message);
            }
          }
        }
      );
    }

  },

};

</script>

<style scoped>
.modal.resendModal .modal-dialog {
  max-width: 460px;
}

th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.refund-summary {
  color: #8a8a8a;
}

.combindeBoxDiv {
  display: none;
  position: absolute;
  background: #fff;
  top: 34px;
  z-index: 999;
  border: 1px solid #dedede;
}

.discountsBoxDiv {
  display: none;
  position: absolute;
  background: #fff;
  top: 31px;
  z-index: 999;
  border: 1px solid #dedede;
}

.discountsBoxDiv.isShow {
  display: block;
}

.combindeBoxDiv.isShow {
  display: block;
}

.trtrop {
  position: relative;
}

.tdClickCl {
  color: #337ab7;
  text-decoration: underline;
  cursor: pointer;
}

.spanPar {
  position: relative;
}

a.linez {
  text-decoration-line: underline;
  cursor: pointer;
}

.ml-2 {
  margin-left: 20px;
}

.pointCouponBoxDiv {
  display: none;
  position: absolute;
  background: #fff;
  top: 31px;
  left: 120px;
  z-index: 999;
  border: 1px solid #dedede;
}

.pointCouponBoxDiv.isShow {
  display: block;
}

</style>
