<template>
  <div class="toogle"  @click="onChange">
    <i v-show="value" class="fa fa-fw fa-toggle-on"></i>
    <i v-show="!value" class="fa fa-fw fa-toggle-off"></i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      // required: true,
      default: false,
    },
  },
  data() {
    return {
      
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onChange(){
      this.$emit('change');
    },
  },
};
</script>
<style scoped>
.toogle{
    cursor:pointer;
}
.fa-toggle-on{
    color: blueviolet;
}
.fa-fw{
    font-size: 20px;
}
</style>