<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-header">
        <h4>파트너 운영 정보</h4>
      </div>
      <div class="box-body form-horizontal">
        <div class="form-group">
          <label class="col-sm-1 control-label">사업자번호</label>
          <div class="col-sm-11">
              {{bizNumber}}
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1 control-label">카카오톡 채널</label>
          <div class="col-sm-11">
              {{kakaoChannelName}}({{kakaoChannelId}})
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1 control-label">파트너 상태</label>
          <div class="col-sm-11">
            <span class="margin-r-5">{{parseOpen(partnerStatus) }}</span>
            <button class="btn btn-default" v-if="partnerStatus == 'PRE_OPEN' || partnerStatus == 'NORMAL'" @click="changePartnerStatus(partnerStatus)" > {{partnerStatus == 'PRE_OPEN'? '노출처리':'미노출처리' }} </button>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1 control-label">운영 상태</label>
          <div class="col-sm-11">
                {{parseOperation(operationStatus)}}
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-1 control-label">연동 신청 매장</label>
          <div class="col-sm-11">
            <div class="input-group" style="width:40%">
              <button class="btn btn-default pull-right" style="margin-bottom: 8px" @click="openStoreSearch"><i class="fa fa-plus"></i> 매장추가 </button>
              <table class="table table-hover text-nowrap mt-2 text-center" style="width: 100%">
                <thead>
                <tr>
                  <th>브랜드</th>
                  <th>매장</th>
                  <th>매장 운영상태</th>
                  <th>장소정보</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(stitem, stidx) in kakaoStoreList" :key="stidx">
                  <td>{{stitem.brandName}}</td>
                  <td>{{stitem.storeName}}</td>
                  <td>
                    <input  type="button" class="btn" :class="stitem.enabled? 'btn-success':'btn-dark'" :value="stitem.enabled? '활성화' : '비활성화'" />
                  </td>
                  <td>
                    <input v-if="stitem.storePartnerLinkStatus === 'LINKED'" type="button" class="btn btn-success"  value="연동중" />
                    <input v-else type="button" class="btn btn-default" @click="openPlaceRegister(stitem)"  value="등록" />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box-solid">
      <div class="box box-solid">
        <div class="box-header">
          <h4>파트너 기본 정보</h4>
        </div>
        <div class="box-body form-horizontal">
          <div class="form-group">
            <label class="col-sm-1 control-label">카카오톡 채널 수정</label>
            <div class="col-sm-11 d-block">
              <div class="d-flex">
                <input type="text" class="form-control" v-model="businessCode" maxlength="12" placeholder="사업자 번호" style="width: 25%"/>
                <button type="button" class="btn btn-default"  style="margin-right: 10px" @click="searchBusinessCode">채널검색</button>
                <select class="form-control" style="width:40%;max-width:400px;min-width:100px;" v-model="selectedKakaoChannel" @change="initKakaoPartner" >
                  <!-- <option disabled selected hidden :value="null" >
                    채널을 검색해주세요
                  </option>
                  <option v-for="(kcitem, kcidx) in kakaoChannelList" :key="kcidx" :value="kcitem">{{kcitem.name}}</option> -->
                <option v-if="!successChk" disabled selected hidden :value="null" >
                  채널을 검색해주세요
                </option>
                <template v-for="(kcitem, kcidx) in kakaoChannelList" >
                  <option v-if="!kcitem.id" disabled selected :value="null">{{kcitem.name}}</option>
                  <option v-else :value="kcitem">{{kcitem.name + " (" + kcitem.searchId + ")"}}</option>
                </template>
                </select>
              </div>
              <div style="margin-top: 8px">
                <span style="color: red" >※ 사업자번호 및 채널 수정이 필요한 경우 입력</span>
              </div>

            </div>

          </div>
          <div class="form-group">
            <label class="col-sm-1 control-label">파트너명(업체명)</label>
            <div class="col-sm-11 d-flex">
              <input type="text" class="form-control" maxlength="50" v-model="name" style="width: 90%"/>
              <div class="check-item-length ">{{ name.length }}/50 자</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-1 control-label">사업자명</label>
            <div class="col-sm-11 d-flex">
              <input type="text" class="form-control" maxlength="20" v-model="bizInfo.name" style="width: 90%"/>
              <div class="check-item-length ">{{ bizInfo.name.length }}/20 자</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-1 control-label">대표자명</label>
            <div class="col-sm-11 d-flex">
              <input type="text" class="form-control" maxlength="50" v-model="bizInfo.representativeName" style="width: 90%"/>
              <div class="check-item-length ">{{ bizInfo.representativeName.length }}/50 자</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-1 control-label">업태</label>
            <div class="col-sm-11 d-flex">
              <input type="text" class="form-control" maxlength="50" v-model="bizInfo.businessCategory" style="width: 90%"/>
              <div class="check-item-length ">{{ bizInfo.businessCategory.length }}/50 자</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-1 control-label">종목</label>
            <div class="col-sm-11 d-flex">
              <input type="text" class="form-control" maxlength="50" v-model="bizInfo.businessCategoryItem" style="width: 90%"/>
              <div class="check-item-length ">{{ bizInfo.businessCategoryItem.length }}/50 자</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-1 control-label">주소</label>
            <div class="col-sm-11">
              <div class="d-flex" style="margin-bottom: 4px">
                <input type="text" class="form-control mr-2" disabled :value="bizInfo.zipcode" style="width: 25%" />
                <AddressSearch style="display:inline-flex" :detailAddress="bizInfo.detailAddress"
                               :onChange="onSetAddress" @detailaddrEvechiild="detailaddrEve" />
              </div>
              <div class="d-flex" >
                  <input type="text" class="form-control mr-2" disabled :value="bizInfo.baseAddress" style="width: 25%" />
                  <input type="text" class="form-control" v-model="bizInfo.detailAddress" style="width: 25%" />
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer text-center">
          <button type="button" class="btn btn-default" style="margin-left:10px" @click="updatePartner">파트너 수정</button>
        </div>
      </div>
    </div>
    <div class="modal"  v-if="searchStoreToggle" style="display: block; ">
      <div class="modal-dialog modal-xl" style="width: 50%;">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">매장검색</div>
          <div class="modal-body form-horizontal">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%; margin-bottom: 4px;">
              <select class="form-control" style="width:auto;margin-right:10px " v-model="storekeywordType" >
                <option value="BrandCode">브랜드코드</option>
                <option value="BrandNameContaining">브랜드명</option>
                <option value="StoreCode">매장코드</option>
                <option value="StoreNameContaining">매장명</option>
                <option value="ManagerID">담당자 ID</option>
              </select>
              <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                <input type="text" class="form-control" v-model="storekeyword"/>
                <span class="input-group-btn">
                  <button type="button" class="btn btn-default" @click="searchstore">검색</button>
                </span>
              </div>
            </div>
            <table v-if="findstorelist.length>0" class="table table-hover text-nowrap mt-2 text-center" style="width: 40%">
              <thead>
              <tr>
                <th>
                  <input type="checkbox" class="allch" @change="allckech($event)"/>
                </th>
                <th>매장코드</th>
                <th>브랜드명</th>
                <th>매장명</th>
                <th>운영상태</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in findstorelist" :keys=idx>
                <td>
                  <input type="checkbox" class="chitem" :value="item.storeCode"/>
                </td>
                <td>{{ item.storeCode }}</td>
                <td>{{ item.brandName }}</td>
                <td>{{ item.basicInfo.storeName }}</td>
                <td>{{ item.enabled? '활성화' : '비활성화' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default margin-r-5" @click="closeStoreSearch">닫기</button>
              <button type="button" class="btn btn-default margin-r-5" @click="addStoreSearch">추가</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal"  v-if="placeRegisterToggle" style="display: block; ">
      <div class="modal-dialog modal-xl" style="width: 50%;">
        <div class="modal-content">
          <div class="modal-header border-bottom-0"><label style="margin-top: 6px">장소등록</label> <button type="button" class="btn btn-default pull-right"  style="margin-right: 10px" @click="goToEdit">매장정보 수정하기</button></div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label class="col-sm-2 control-label">매장 운영상태</label>
              <div class="col-sm-10 d-flex">
                <span class="margin-r-5">{{ locationInfo.enable ? "활성화" : "비활성화" }}</span>
                <span class="text-danger" v-if="!locationInfo.enable">활성화 상태 매장만 등록 가능합니다.<br>
                      [매장리스트 > 운영상태]를 변경해 주세요.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">정산 수수료</label>
              <div class="col-sm-10">
                <span class="text-danger" v-if="!locationInfo.commissionRate">정산 수수료(카카오톡 예약하기) 정보가 없습니다.<br>
                                          [매장상세 > 계약정보]에서 등록해 주세요.
                </span>
                <span v-else>{{ locationInfo.basis === 'ListPrice' ? '정상가/' : locationInfo.basis === 'SalePrice' ? '판매가/' : '' }}{{ getCommissionRate() }}</span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">예약 카테고리</label>
              <div class="col-sm-10">
                <div class="input-group d-flex" v-for="idx in categoryLength+1" style="margin-bottom: 4px">
                  <select class="form-control mr-5" v-model="dep1Selected[idx-1]" @change="dep1Change(dep1Selected[idx-1],idx-1 )" style="width: 30%" >
                    <option v-for="(dp1Cate, dp1Idx) in kakaoCategoryList" :value="dp1Cate">
                      {{dp1Cate.categoryName}}
                    </option>
                  </select>
                  <select class="form-control mr-5" v-model="selectedCategoryList[idx-1]" style="width: 30%">
                    <option v-for="(dp2Cate, dp2Idx) in dep2ArrList[idx-1]" :value="dp2Cate.categoryCode">
                      {{dp2Cate.categoryName}}
                    </option>
                  </select>
                  <button class="btn btn-default" v-if="categoryLength == 1" @click="removeReserveCategory(idx-1)" > 삭제</button>
                  <button class="btn btn-default" v-if="categoryLength == 0" @click="addReserveCategory" ><i class="fa fa-plus"></i> 추가</button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">장소 대표 이미지</label>
              <div class="col-sm-10">
                <SingleThumbnail v-model="mainImageurl" :imgtype="imgtype.STORE" :change="onMainImage" :imgFile="{}"/>
                <span class="text-example">• 권장 사이즈 800x800px / 파일당 최대 20MB / jpeg, jpg, png, gif<br>
                                            • 상호명, 간판, 로고, 매장 사진 등 장소 관련 이미지로 등록해 주세요.<br>
                                            • 미등록 시 매장 기본정보에 등록된 대표이미지로 대체되어 등록됩니다.<br>

                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">장소명</label>
              <div class="col-sm-10">

                <input type="text" class="form-control" v-model="placeName" style="width: 40%"/>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">주소</label>
              <div class="col-sm-10" >
                <span>{{ `(${locationInfo.zipCode}) ${locationInfo.address} ${locationInfo.detailAddress}` }}</span>
                <div class="d-flex">
                  <label class="mt-2 margin-r-5">카카오맵 장소 아이디</label>
                  <input type="text" class="form-control" v-model="placeId" style="width: auto"/>
                </div>
                <span class="text-example">1. Kakao Map 접속 (<a href="https://map.kakao.com" target="_blank" style="color: #007bff">https://map.kakao.com</a>)<br>
                                            2. 매장 장소 검색<br>
                                            3. 좌측 검색결과에서 해당 장소에 “상세보기” 클릭<br>
                                            4. 연결된 URL에 기재된 장소아이디 확인하여 입력 (https://place.map.kakao.com/{장소아이디})<br>
                                            ex) https://place.map.kakao.com/14559670 > 카카오맵 장소 아이디에 “14559670” 입력<br>
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">대표전화</label>
              <div class="col-sm-10 d-flex">
                <span class="margin-r-5">{{ locationInfo.phoneNumber }}</span>
                <span class="text-danger" v-if="!locationInfo.phoneNumber">매장 대표전화 정보가 없습니다.<br>
                                          [매장상세 > 매장 등록 정보]에서 등록해 주세요.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">사업자 등록번호</label>
              <div class="col-sm-10">
                {{ locationInfo.registrationNumber }}
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">일반예약 승인</label>
              <div class="col-sm-10 d-flex">
                <div class="input-group radio">
                  <label>
                    <input type="radio" :value="true" v-model="locationInfo.autoBooking" disabled/>사용
                  </label>
                  <label>
                    <input type="radio" :value="false" v-model="locationInfo.autoBooking" disabled/>미사용
                  </label>
                </div>
                <span class="text-danger" v-if="!locationInfo.autoBooking">실시간 승인만 가능합니다.<br>
                                          [매장상세 > 예약 기본 설정]에서 수정해 주세요.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">결제예약 승인</label>
              <div class="col-sm-10 d-flex">
                <div class="input-group radio ">
                  <label>
                    <input type="radio" :value="true" v-model="locationInfo.paidAutoBooking" disabled/>사용
                  </label>
                  <label>
                    <input type="radio" :value="false" v-model="locationInfo.paidAutoBooking" disabled/>미사용
                  </label>
                </div>
                <span class="text-danger" v-if="!locationInfo.paidAutoBooking">실시간 승인만 가능합니다.<br>
                                          [매장상세 > 예약 기본 설정]에서 수정해 주세요.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">익일 자동 방문완료</label>
              <div class="col-sm-10 d-flex">
                <div class="input-group radio">
                  <label>
                    <input type="radio" :value="true" v-model="locationInfo.dayAutoBooked" disabled/>사용
                  </label>
                  <label>
                    <input type="radio" :value="false" v-model="locationInfo.dayAutoBooked" disabled/>미사용
                  </label>
                </div>
                <span class="text-danger" v-if="!locationInfo.dayAutoBooked">자동 방문완료 처리는 필수입니다.<br>
                                          [매장상세 > 예약 기본 설정]에서 수정해 주세요.
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">메뉴</label>
              <div class="col-sm-10">
                <table class="table table-hover text-nowrap mt-2 text-center" style="width: 40%" v-if="locationInfo.menuInfo.length>0">
                  <thead>
                  <tr>
                    <th>메뉴명</th>
                    <th>정상가</th>
                    <th>대표메뉴</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, idx) in locationInfo.menuInfo" :key="idx">
                    <td>{{ item.menuName }}</td>
                    <td>{{ item.listPrice }}</td>
                    <td>{{ item.mainMenu? 'O' : '' }}</td>
                  </tr>
                  </tbody>
                </table>
                <span class="text-danger" v-if="locationInfo.menuInfo.length==0">등록된 메뉴가 없습니다.<br>
                                          [매장상세 > 메뉴관리]에서 메뉴를 1개이상 등록해 주세요.
                </span>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default margin-r-5" @click="closePlaceRegister">닫기</button>
              <button type="button" class="btn btn-default margin-r-5" @click="addLocation" >장소등록</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import {
  rescode,
  menuval, imgtype
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {
  reqGet, reqPost
} from "../../../lib/request";
import {
  brandapi
} from "../../../lib/api";
import {
  DateFormat
} from "../../../lib/utility";
import AddressSearch from "@/components/AddressSearch.vue";
import DetePicker from "@/components/DatePicker.vue";
import $ from "jquery";
import SingleThumbnail from "@/components/SingleThumbnail.vue";
export default {
  components: {
    SingleThumbnail,
    DetePicker,
    AddressSearch,
    Pagination,
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_KKOR,
      title: "",
    });
    this.partnerId = this.$route.query.partnerId;
    this.kakaoId =  this.$route.query.partnerId;
  },
  watch: {
    businessCode(val){
      val = val.replace(/[^0-9]/g, '').replace(/^(\d{3,3})(\d{2,2})(\d{0,5})$/, `$1-$2-$3`)
      this.businessCode = val
    },
  },
  data() {
    return {
      name:"",
      buttonDissabled : false,
      searchStoreToggle : false,
      placeRegisterToggle : false,
      roadAddress:"",
      zonecode : "",
      detailAddress:"",
      partnerId: "",
      rejectToggle : false,
      brandCodes: [],
      optionss: [],
      storeType:null,
      brandNameContaining:"",
      businessCode: "",
      bizNumber:"",
      kakaoChannelList:[],
      kakaoStoreList:[],
      partnerName:"",
      businessName:"",
      representativeName:"",
      businessCategory:"",
      businessCategoryItem:"",
      kakaoChannelId: "",
      kakaoChannelName: "",
      kakaoChannelSearchId:"",
      createdAt: "",
      updateAt: "",
      bizInfo : {
        name:"",
        representativeName:"",
        zipcode:"",
        baseAddress:"",
        detailAddress:"",
        businessCategory:"",
        businessCategoryItem:"",
        editable:true,
      },
      partnerStatus:"",
      operationStatus:"",
      mainImageurl:"",
      image: {},
      locationInfo:{
        storeCode	: "", //	String	스토어코드
        enable	: false, //	Boolean	활성상태
        basis	: "", //	String	정산 기준가
        commissionRate	: "", //	String	정산수수료
        brandName	: "", //	String	브랜드명
        storeName	: "", //	String	스토어명
        address	: "", //	String	주소
        detailAddress	: "", //	String	상세주소
        zipCode	: "", //	String	우편번호
        phoneNumber	: "", //	String	전화번호
        registrationNumber	: "", //	String	사업자번호
        autoBooking	: false, //	Boolean	일반예약 자동 예약 승인 여부
        paidAutoBooking	: false, //	Boolean	결제예약 자동 예약 승인 여부
        dayAutoBooked	: false, //	Boolean	익일자동 방문완료 처리 여부
        menuInfo	: [], //	arr	메뉴정보
                        //       menuInfo[].listPrice	:””, //	String	정상가
                        //       menuInfo[].menuName	:””, //	String	메뉴명
                        //   menuInfo[].mainMenu	:””, //	Boolean	메인메뉴여부
      },
      addCategory : false,
      categoryLength: 0,
      kakaoCategoryList:[],
      dep1Selected:[
      ],
      dep2ArrList:[
        [{categoryCode:"2차 선택",
          categoryName:"2차 선택",
          categoryNum:0
        }]
      ],
      selectedCategoryList: ["2차 선택"],
      successChk:false,
      selectedKakaoChannel:null,
      storekeywordType: "BrandCode",
      storekeyword: "",
      findstorelist: [],
      regLocationInfo : null,
      placeId: null, //string
      placeName:"",
      kakaoId:"",
      brandCode:"",
      brandName: "",




    };
  },
  computed: {
    routes() {
      return routepath;
    },
    imgtype() {
      return imgtype;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
    },
  },
  mounted() {
    this.getInfo();
    this.getKakaoCategoryList();
    this.getStoreInfo();
  },
  methods: {
    getCommissionRate() {
      return this.locationInfo.commissionRate ? this.locationInfo.commissionRate + " %" : "";
    },
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    openPlaceRegister(val){
      this.placeRegisterToggle = true
      this.regLocationInfo = val
      reqGet(
        brandapi.base,
        brandapi.getKakaoStoreLocationInfo + "?storeCode=" + val.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            console.log(result.data)
            this.locationInfo = result.data
            this.placeName = `${this.regLocationInfo.brandName} ${this.regLocationInfo.storeName}`
          }else {
            alert(result.message);
          }
        }
      );

    },
    getKakaoCategoryList(){
      // this.placeRegisterToggle = true
      // getKakaoStoreLocationInfo

      reqGet(
        brandapi.base,
        brandapi.getKakaoCategoryList,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.kakaoCategoryList = result.data.mainCategoryList
            this.kakaoCategoryList.unshift({
              categoryName: "1차 선택",
              categoryNum: 0,
              subCategoryList:[{categoryCode:"2차 선택",
                categoryName:"2차 선택",
                categoryNum:0
              }]
            })
            this.dep1Selected[0] = {
              categoryName: "1차 선택",
              categoryNum: 0,
              subCategoryList:[{categoryCode:"2차 선택",
                categoryName:"2차 선택",
                categoryNum:0
              }]

            }
          }else {
            alert(result.message);
          }
        }
      );

    },
    addReserveCategory(){
      this.addCategory = true
      this.categoryLength = 1;
      this.dep1Selected[1] = {
        categoryName: "1차 선택",
        categoryNum: 0,
        subCategoryList:[ {categoryCode:"2차 선택",
          categoryName:"2차 선택",
          categoryNum:0
        }]
      }
      this.dep2ArrList.push(
        [{categoryCode:"2차 선택",
        categoryName:"2차 선택",
        categoryNum:0
      }])
      this.selectedCategoryList.push("2차 선택")
    },
    removeReserveCategory(val){
      this.selectedCategoryList.splice(val,1)
      this.dep1Selected.splice(val,1)
      this.dep2ArrList.splice(val,1)
      this.categoryLength = 0
      this.addCategory = false
    },
    closePlaceRegister(){
      this.placeRegisterToggle = false
    },
    openStoreSearch(){
      this.searchStoreToggle = true
    },
    closeStoreSearch(){
      this.findstorelist = []
      this.searchStoreToggle = false
    },
    addStoreSearch(){
      var chkStore = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      console.log(chkStore)
      reqPost(
        brandapi.base,
        brandapi.addKakaoReserveRegStoreList ,
        this.authToken,
        {
          kakaoId : this.kakaoId,
          storeCodes: chkStore
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.getStoreInfo();
              alert(data.message)
            }else{
              alert(data.message)
            }
          }
        }
      );
       this.searchStoreToggle = false

    },
    onSetAddress(addinfo, detailAddress) {
      this.bizInfo.baseAddress = addinfo.address;
      this.bizInfo.zipcode = addinfo.zonecode;
      this.bizInfo.detailAddress = detailAddress;
    },
    detailaddrEve(detailAddress) {
      this.bizInfo.detailAddress = detailAddress;
    },
    //
    getInfo(){
      reqGet(
        brandapi.base,
        brandapi.getKakaoPartnerDetail + "?kakaoId=" + this.kakaoId,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            console.log(result.data)
            this.name = result.data.name
            //bizInfo null값문제
            this.bizInfo = result.data.bizInfo
            this.bizNumber = result.data.bizNumber
            this.kakaoChannelId = result.data.channelId
            this.kakaoChannelName = result.data.channelName
            this.kakaoChannelSearchId = result.data.channelSearchId
            this.kakaoChannelId = result.data.channelSearchId
            this.createdAt = result.data.createdAt
            this.updateAt = result.data.updateAt
            this.partnerStatus = result.data.status
            this.operationStatus = result.data.operationStatus


          }else {
            alert(result.message);
          }
        }
      );
    },
    getStoreInfo(){
      reqGet(
        brandapi.base,
        brandapi.getPartnerListOfKakao + "?kakaoId=" + this.kakaoId,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.kakaoStoreList = result.data
            console.log(this.kakaoStoreList)
          }else {
            alert(result.message);
          }
        }
      );
    },

    updatePartner(){
      // let tmpStoreList = []
      // this.kakaoStoreList.forEach(item=>{
      //   tmpStoreList.push(item.storeCode)
      // })
      // console.log({
      //   kakaoId : this.kakaoId,
      //   partnerName	: this.name,
      //   bizNumber	: !this.businessCode ? this.bizNumber : this.businessCode,
      //   bizName	: this.bizInfo.name,
      //   representativeName	: this.bizInfo.representativeName,
      //   zipCode	: this.bizInfo.zipcode,
      //   baseAddress	: this.bizInfo.baseAddress,
      //   detailAddress	: this.bizInfo.detailAddress,
      //   businessCategory	: this.bizInfo.businessCategory,
      //   businessCategoryItem	: this.bizInfo.businessCategoryItem,
      //   channelSearchId	: this.kakaoChannelId, // ?
      //   channelName	: this.kakaoChannelName, // ?
      //   storeCodes:tmpStoreList,
      // })
      if(this.successChk){
        if(!this.businessCode){
          alert("사업자번호를 입력해주세요.")
          return
        }
        if(!this.selectedKakaoChannel){
          alert("카카오톡 채널을 선택해주세요.")
          return
        }
      }else if(!this.name){
        alert("파트너명(업체명)을 입력해주세요.")
        return
      } else if(this.name.length>50){
        alert("파트너명(업체명)의 길이를 확인해주세요.")
        return
      } else if(!this.bizInfo.name){
        alert("사업자명을 입력해주세요.")
        return
      } else if(this.bizInfo.name.length>20){
        alert("사업자명의 길이를 확인해주세요.")
        return
      } else if(!this.bizInfo.representativeName){
        alert("대표자명을 입력해주세요.")
        return
      }else if(this.bizInfo.representativeName.length>50){
        alert("대표자명의 길이를 확인해주세요.")
        return
      } else if(!this.bizInfo.businessCategory){
        alert("업태를 입력해주세요.")
        return
      }else if(this.bizInfo.businessCategory.length>50){
        alert("업태의 길이를 확인해주세요.")
        return
      }  else if(!this.bizInfo.businessCategoryItem){
        alert("종목을 입력해주세요.")
        return
      }else if(this.bizInfo.businessCategoryItem.length>50){
        alert("종목의 길이를 확인해주세요.")
        return
      } else if(!this.bizInfo.zipcode || !this.bizInfo.baseAddress || !this.bizInfo.detailAddress){
        alert("주소를 입력해주세요.")
        return
      }

      if(confirm("파트너 정보를 수정하시겠습니까?")){
        reqPost(
          brandapi.base,
          brandapi.updatePartnerInfo ,
          this.authToken,
          {
            kakaoId : this.kakaoId,
            partnerName	: this.name,
            bizNumber	: !this.businessCode ? this.bizNumber : this.businessCode,
            bizName	: this.bizInfo.name,
            representativeName	: this.bizInfo.representativeName,
            zipCode	: this.bizInfo.zipcode,
            baseAddress	: this.bizInfo.baseAddress,
            detailAddress	: this.bizInfo.detailAddress,
            businessCategory	: this.bizInfo.businessCategory,
            businessCategoryItem	: this.bizInfo.businessCategoryItem,
            channelSearchId	: this.kakaoChannelSearchId, // ?
            channelName	: this.kakaoChannelName, // ?
            status:this.partnerStatus
          },
          (data) => {
            if (data != null) {
              if (data.code == rescode.REQ_SUCCESS) {
                alert("수정되었습니다.");
                this.getInfo();
              }else{
                alert(data.message)
              }
            }
          }
        );

      }


    },

    addLocation(){
      let chkCati = this.selectedCategoryList.indexOf("2차 선택")
      if(!this.locationInfo.enable){
        alert("매장 운영상태를 활성화 해주세요.")
        return
      } else if(!this.locationInfo.basis){
        alert("정산 수수료(카카오톡 예약하기) 정보가 없습니다.")
        return
      }else if(chkCati > -1){
        alert("예약 카테고리를 선택해주세요.")
        return
      } else if(!this.placeName){
        alert("장소명을 입력해주세요.")
        return
      } else if(!this.placeId){
        alert("카카오맵 장소 아이디를 입력해주세요.")
        return
      } else if(!this.locationInfo.phoneNumber){
        alert("매장 대표전화 정보가 없습니다.")
        return
      } else if(!this.locationInfo.autoBooking){
        alert("일반예약 승인은 실시간만 가능합니다.")
        return
      } else if(!this.locationInfo.paidAutoBooking){
        alert("결제예약 승인은 실시간만 가능합니다.")
        return
      } else if(!this.locationInfo.dayAutoBooked){
        alert("익일 자동 방문완료 처리는 필수입니다.")
        return
      } else if(this.locationInfo.menuInfo.length ==0){
        alert("등록된 메뉴가 없습니다.")
        return
      }


      reqPost(
        brandapi.base,
        brandapi.regKakaoLocationInfo ,
        this.authToken,
        {
          storeCode:this.locationInfo.storeCode, //string
          categoryList:this.selectedCategoryList, //arr
          imageUrl: this.mainImageurl, //string
          placeId: Number(this.placeId), //string
          placeName:this.placeName, //string
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.selectedCategoryList = []
              this.dep1Selected = []
              this.dep1Selected[0] = {
                categoryName: "1차 선택",
                categoryNum: 0,
                subCategoryList:[ {categoryCode:"2차 선택",
                  categoryName:"2차 선택",
                  categoryNum:0
                }]
              }
              this.categoryLength = 0
              this.addCategory = false
              this.placeId = null
              this.placeName = ""
              this.placeRegisterToggle = false
              alert("장소등록이 완료되었습니다.")
              this.$router.go(0);
            } else {
              alert(data.message)
            }

          }
        }
      );
    },
    onMainImage(newval, w, h) {
      this.mainImageurl = newval; //메인 이미지 url
      this.image = {
        "url": newval,
        "width": w,
        "height": h,
        "description": null
      }
    },
    parseOpen(val){
      switch (val){
        case "NORMAL" : return "노출"; break;
        case "PRE_OPEN" : return "미노출"; break;
        case "RESTRICT" : return "노출제한"; break;
        default: return ""; break;
      }
    },
    parseOperation(val){
      switch (val){
        case "NORMAL" : return "운영중"; break;
        case "PRE_OPEN" : return "미노출"; break;
        case "RESTRICT" : return "운영제한"; break;
        case "DELETE" : return "삭제"; break;
        default: return ""; break;
      }
    },
    changePartnerStatus(val){
      if(val == 'NORMAL' || val == 'PRE_OPEN'){
        reqPost(
            brandapi.base,
            brandapi.updateParterStatus ,
            this.authToken,
            {
              kakaoId:this.kakaoId,
              status: val== 'NORMAL'? 'PRE_OPEN' : 'NORMAL'
            },
            (data) => {
              if (data != null) {
                if (data.code == rescode.REQ_SUCCESS) {
                  alert(data.message)
                  this.getInfo()
                } else {
                  alert(data.message)
                }

              }
            }
        );
      }else {
        alert('에러입니다. 관리자에게 문의해주세요.')
      }

    },
    searchBusinessCode(){
      this.kakaoChannelList = []
      this.selectedKakaoChannel = null
      // 예약상품을 노출할 채널을 선택해주세요.
      if(!this.businessCode){
        return
      }
      let chkVal = this.businessCode.replaceAll('-','')
      if(chkVal.length !== 10){
        alert("사업자번호가 올바르지 않습니다.")
        return
      }else{
        this.successChk = true;
        this.kakaoChannelList = []
        reqGet(
          brandapi.base,
          brandapi.kakaoChannelSearch+"?bizNumber="+this.businessCode,
          this.authToken,
          (result) => {
            if (result != null && result.code === rescode.REQ_SUCCESS) {
              if(result.data.content.length <1) {
                this.successChk = false;
                alert("사업자번호에 해당되는 카카오톡 채널이 존재하지 않습니다. 사업자번호가 올바른지 다시 한번 확인해주세요.")

                return
              }
              this.partnerName = ""
              this.kakaoChannelList = result.data.content;
              this.kakaoChannelList.unshift({id:null, searchId:null , name:"예약상품을 노출할 채널을 선택해주세요."})
              console.log(this.kakaoChannelList)
              this.$forceUpdate()
            }else {
              alert("채널 검색 실패");
            }

          }

        );
      }

    },
    searchstore() {
      reqGet(
        brandapi.base,
        brandapi.basicInfoStorelist + "?keywordType=" + this.storekeywordType + "&keyword=" + this.storekeyword,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.findstorelist = result.data;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    initKakaoPartner(){
      if(this.selectedKakaoChannel !=null){
        this.name = this.selectedKakaoChannel.name
      }
    },
    goToEdit(){
      this.$router.push(this.routes.editstore+`?stcode=${this.regLocationInfo.storeCode}&brCode=${this.regLocationInfo.brandCode}&brName=${this.regLocationInfo.brandName}`)
    },
    dep1Change(val,idx){
      this.dep2ArrList[idx] = val.subCategoryList
    }
  },
};
</script>
<style scoped>
.check-item-length {
  border: solid 1px #d2d6de;
  height: 34px;
  align-content: center;
  padding-right: 16px;
  padding-left: 16px;
  text-align: center;
}
.d-flex{
  display: flex;
}

.text-example{
  color: #9a9a9a
}
.mr-5 {
  margin-right: 10px;
}
</style>
