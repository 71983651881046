<template>
  <div class="root" @mouseover="mouseover = true" @mouseleave="mouseover = false">
    <div class="header">
      <h4>{{title}}</h4>
      <div>{{imgdes}}</div>
    </div>
    <div class="contents">
      <div v-for="(image, index) in images" :key="index" class="item" :style="{width: styleImageWidthRatio(image)+'%'}">
        <img :src="image.url" alt="itemimg" />
      </div>
    </div>
    <div v-if="!readonly" v-show="mouseover" class="maskbg"></div>
    <button type="button" v-if="!readonly" v-show="mouseover" @click="onrmvProd" class="btn btn-close">
      <i class="fa fa-fw fa-trash-o"></i>
    </button>
    <button type="button" v-if="!readonly" v-show="mouseover" @click="oneditProd" class="btn btn-edit">
      <i class="fa fa-fw fa-pencil"></i>
    </button>
    <button type="button" v-if="!readonly" v-show="mouseover" @click="sortTopsub" class="btn btn-top">
      <i class="fa fa-fw fa-angle-double-up"></i>
    </button>
    <button type="button" v-if="!readonly" v-show="mouseover" @click="sortlowestsub" class="btn btn-lowest">
      <i class="fa fa-fw fa-angle-double-down"></i>
    </button>
    <button type="button" v-if="!readonly" v-show="mouseover" @click="onSortUp" class="btn btn-up">
      <i class="fa fa-fw fa-caret-up"></i>
    </button>
    <button type="button" v-if="!readonly" v-show="mouseover" @click="onSortDown" class="btn btn-down">
      <i class="fa fa-fw fa-caret-down"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    images:{
      type:Array,
      // default:[]
    },
    title: {
      type: String,
      default: "",
    },
    imgdes: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mouseover: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    widthBy1024h(image) {
      return 1024 * image.width / image.height;
    },
    totalWidthBy1024h() {
      return this.images.map(image => this.widthBy1024h(image)).reduce((pre, cur) => pre + cur);
    },
    styleImageWidthRatio(image) {
      let totalWidthBy1024h = this.totalWidthBy1024h();
      let imageWidthBy1024h = this.widthBy1024h(image);

      let widthRatio = imageWidthBy1024h / totalWidthBy1024h * 100;

      return widthRatio;
    },
    oneditProd(){
      this.$emit('editProd');
    },
    onrmvProd(){
      this.$emit('remove');
    },
    onSortDown()
    {
      this.$emit('sortdown');
    },
    onSortUp()
    {
      this.$emit('sortup');
    },
    sortTopsub(){
      this.$emit('sortTopsub');
    },
    sortlowestsub(){
      this.$emit('sortlowestsub');
    }
  },
};
</script>
<style scoped>
.root {
  /* width: 500px; */
  margin: 0px auto;
  cursor: pointer;
  position: relative;
  padding: 0px;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .root {
    width: 500px;
  }
}
@media (max-width: 768px) {
  .root {
    width: 100%;
  }
}
.maskbg {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: #00000070;
}
.btn {
  position: absolute;
  background-color: #ffffffa0;
  border-radius: 100px;
  padding: 6px;
  height: 32px;
}

.btn-close {
  top: 0px;
  right: 105px;
}

.btn-edit {
  top: 0px;
  right: 70px;
}
.btn-up {
  top: 0px;
  right: 35px;
}
.btn-top{
  top: 0px;
  right: 175px;
}
.btn-lowest{
  top:0;
  right: 140px;;
}
.btn-down {
  top: 0px;
  right: 0px;
}
.header h4{
    font-weight: bold;
}
.contents {
  display: flex;
  flex-direction: row;
}
.item {
  /* flex: 1; */
  /* margin: 5px; */
  position: relative;
  padding: 0px;
  background-color: #e8e8e8;
}
.item span {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item img {
  width: 100%;
}

</style>
