<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
          <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
            <input type="text" class="form-control" style="width:auto" readonly :value="salesMonth" @click="onShowMonthPicker"/>
            <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
          </div>
          <div class="checkbox" style="margin-right:10px">
            <label><input type="checkbox" :value="true" v-model="filter.all"/>전체</label>
            <label><input type="checkbox" :value="true" v-model="filter.lastDayOfPrevMonth"/>전월말일</label>
            <label><input type="checkbox" :value="true" v-model="filter.startDayOfThisMonth"/>익월일일</label>
            <label><input type="checkbox" :value="true" v-model="filter.dontIssueTaxInvoice"/>미발행</label>
          </div>
          <button class="btn btn-default" type="button" @click="refreshPage">검색</button>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="downExcel" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>번호</th>
              <th>브랜드</th>
              <th>매장명</th>
              <th>사업자등록번호</th>
              <th>수수료</th>
              <th>공급가액</th>
              <th>세액</th>
              <th>발행기준</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tax, tidx) in content" :key="tidx">
              <td>{{tidx + 1}}</td>
              <td>{{tax.brandName}}</td>
              <td>{{tax.storeName}}</td>
              <td>{{tax.registrationNumber}}</td>
              <td>{{tax.commission | numFormat}}</td>
              <td>{{tax.valueOfSupply | numFormat}}</td>
              <td>{{tax.valueAddedTax | numFormat}}</td>
              <!-- {{ tax.issueTaxInvoice }} -->
              <td v-if="tax.issueTaxInvoice">{{getTaxStr(tax.taxInvoiceIssueDate)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import { reqPost } from "../../lib/request";
import { orderapi } from "../../lib/api";
import { DateFormat } from "../../lib/utility";
import { MonthPicker } from 'vue-month-picker'
import moment from 'moment';
import XLSX from "xlsx";
export default {
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.event)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.event)
      }
    }
  },
  components: {
    MonthPicker,
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_PAY, path2:menuval.LV1_HOME, title:"세금계산서 발행"});
  },
  data() {
    return {
      salesMonth:"",
      defmonth: moment().month() + 1,
      defyear: moment().year(),
      monthpicker:false,
      filter:{
        all:true,
        lastDayOfPrevMonth:false,
        startDayOfThisMonth:false,
        dontIssueTaxInvoice:false,
      },
      content: [],
      /**
       * brandCode              String    브랜드 코드
       * brandName              String    브랜드 코드
       * issueTaxInvoice        Boolean   세금계산서 발행 여부
       * taxInvoiceIssueDate    String    [LastDayOfPrevMonth, StartDayOfThisMonth] [발행기준일]  LastDayOfPrevMonth: 전월말일  StartDayOfThisMonth: 익월일일
       * shouldIssueAt1stDay    Boolean   1일 발행 여부
       * stores[].storeCode     String    매장 코드
       * stores[].storeName     String    매장명
       * stores[].registrationNumber      String    사업자등록번호
       * stores[].commission         String    판매 수수료
       * stores[].valueOfSupply           String    공급가액
       * stores[].valueAddedTax           String    세액
       */
      exceldata:[],
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    refreshPage()
    {
      reqPost(
        orderapi.base,
        orderapi.taxAmount_total,
        this.authToken,
        {
          salesMonth:this.salesMonth,
          filter:this.filter,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.converExcel(result.data.brands);
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    selMonth(date)
    {
      this.salesMonth = date.year+"-"+date.monthIndex.toString().padStart(2, 0);
      // this.monthpicker = false;
    },
    onShowMonthPicker()
    {
      this.monthpicker = !this.monthpicker;
    },
    hideMonthPicker()
    {
      this.monthpicker = false;
    },
    converExcel(brands)
    {
      this.content = [];
      for(var bidx=0;bidx<brands.length; bidx++)
      {
        for(var sidx=0; sidx<brands[bidx].stores.length; sidx++)
        {
          var t_data = {
            brandName:brands[bidx].brandName,
            storeName:brands[bidx].stores[sidx].storeName,
            registrationNumber:brands[bidx].stores[sidx].registrationNumber,
            commission:brands[bidx].stores[sidx].commission,
            valueOfSupply:brands[bidx].stores[sidx].valueOfSupply,
            valueAddedTax:brands[bidx].stores[sidx].valueAddedTax,
            taxInvoiceIssueDate:brands[bidx].taxInvoiceIssueDate,
            issueTaxInvoice:brands[bidx].issueTaxInvoice,
          };
          this.content.push(t_data);
        }
      }
    },
    getTaxStr(str)
    {
      if(str == 'LastDayOfPrevMonth')
      {
        return "전월말일";
      }else if(str == 'StartDayOfThisMonth')
      {
        return "익월일일";
      }else{
        return "";
      }
    },
    dataconvert(tdata, tidx) {
      return {
        A: tidx+1,
        B: tdata.brandName,
        C: tdata.storeName,
        D: tdata.registrationNumber,
        E: tdata.commission,
        F: tdata.valueOfSupply,
        G: tdata.valueAddedTax,
        H: tdata.issueTaxInvoice ? this.getTaxStr(tdata.taxInvoiceIssueDate) : "",
      };
    },
    downExcel() {
      this.buttonDissabled = true;
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "브랜드",
            C: "매장명",
            D: "사업자등록번호",
            E: "수수료",
            F: "공급가액",
            G: "세액",
            H: "발행기준",
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(excelheader, this.content.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "세금계산서 발행");
      // export Excel file
      XLSX.writeFile(wb, "세금계산서 발행.xlsx");
      this.buttonDissabled = false;
    },
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

th {
  text-align: center;
}
td {
  text-align: center;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}
</style>
