<template>
<div class="thumbnail" v-bind:style="value != '' && [{'background-image':'url('+value+')'}, {'background-size': 'cover'}]" @mouseover="mouseover = true" @mouseleave="mouseover = false">
    <span v-show="value == ''" style="font-size:30px;cursor: pointer;" @click="onChangeClick"><i class="fa fa-fw fa-plus"></i></span>
    <input type="file" ref="fileInput" style="display:none;" accept="image/jpeg, image/png" @change="onUploadFiles" />
    <div v-show="value != '' && mouseover" class="maskbg"></div>
    <button type="button" v-show="value != '' && mouseover" class="btn btn-close" @click="onRemove"><i class="fa fa-fw fa-trash-o"></i></button>
    <button type="button" v-show="value != ''" class="btn btn-download" @click="onDownloadClick"><i class="fa fa-fw fa-download"></i></button>
    <button type="button" v-show="value != ''" class="btn btn-preview" @click="onPreviewClick"><i class="fa fa-fw fa-search"></i></button>
</div>
</template>

<script>
import {
    adminapi,
} from "../lib/api";
import {
    ContainerClient
} from '@azure/storage-blob';
import {
    getfilename,
    reqPost
} from "../lib/request";
import {
    imgtype,
    rescode
} from "../lib/consts";
import axios from 'axios'
import Compressor from "compressorjs";

export default {
    components: {},
    model: {
        prop: 'value',
    },
    props: {
        value: {
            type: String,
            required: true,
            default: "",
        },
        imgtype: {
            type: String,
            default: imgtype.GOODS,
        },
        index: {
            type: Number,
            default: 0,
        },
        change: {
            type: Function,
            default: () => {},
        },
        downlabel: {
            type: String,
            // required: true,
            default: "",
        },
    },
    data() {
        return {
            fileInput: null,
            mouseover: false,
            imgwidth: 0,
            imgheight: 0,
        };
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    watch: {
    },
    mounted() {},
    methods: {
        getAdminsas() {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.url = result.data.url;
                            this.container = result.data.container;
                            this.directory = result.data.directory;
                            this.expiresIn = result.data.expiresIn;
                        }
                    }
                }
            );
        },
        onChangeClick() {
            this.$refs.fileInput.click();
        },
        compressImage(imageFile) {
            return new Promise((resolve, reject) => {
                new Compressor(imageFile, {
                    maxWidth: 600,
                    quality: 0.8,
                    success(result) {
                        resolve(result)
                    },
                    error(e) {
                        reject(e)
                    },
                });
            });
        },
        onUploadFiles(event, drag) {
            let imageFile = '';
            if (drag) {
                imageFile = event;
            } else {
                imageFile = event.target.files[0];
            }

            this.compressImage(imageFile).then((compressedFile) => {
                let reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = evt => {
                    let img = new Image();
                    img.onload = () => {
                        this.imgwidth = img.width;
                        this.imgheight = img.height;
                    }
                    img.src = evt.target.result;
                }

                reader.onerror = evt => {
                    console.error(evt);
                }

                if (this.value === '') {
                    this.onRegisterImage(compressedFile);
                } else {
                    this.onChangeImage(compressedFile);
                }
            }).catch((error) => {
                alert("이미지 리사이징 에러입니다.");
            });
        },
        onRegisterImage(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            // this.container = result.data.container;
                            // this.expiresIn = result.data.expiresIn;
                            const containerClient = new ContainerClient(result.data.url);
                            // const file = files[0];
                            var d = new Date();
                            var n = d.getTime();
                            // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
                            let filename = n + '_' + imageFile.name;
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                                // onProgress: ev => console.log(ev)
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(this.index, uploadedUrl, this.imgwidth, this.imgheight);
                            });
                        }
                    }
                }
            );
        },
        onPreviewClick() {
            console.log(this.value)
            this.windowRef = window.open(this.value, "", "width=600,height=400,left=200,top=200");
            this.windowRef.addEventListener('beforeunload', this.closePortal);
        },
        closePortal() {
            if (this.windowRef) {
                this.windowRef.close();
                this.windowRef = null;
            }
        },
        onChangeImage(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            // this.container = result.data.container;
                            // this.expiresIn = result.data.expiresIn;
                            const containerClient = new ContainerClient(result.data.url);
                            var d = new Date();
                            var n = d.getTime();
                            // var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
                            let filename = n + '_' + imageFile.name;
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                                // onProgress: ev => console.log(ev)
                            }).then((upfile) => {
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                console.log(this.index)
                                this.change(this.index, uploadedUrl, this.imgwidth, this.imgheight);
                            });
                        }
                    }
                }
            );
        },
        onDownloadClick() {
            axios.get(this.value, {
                    responseType: 'blob'
                })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf'
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    if (this.downlabel == "") {
                        link.download = getfilename(this.value);
                    } else {
                        link.download = this.downlabel;
                    }
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        onRemove() {
            this.$emit('input', "");
            this.change(this.index, "", 0, 0);
        }
    },
};
</script>

<style scoped>
.thumbnail {
    width: 160px;
    height: 120px;
    justify-content: center;
    margin: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px;
    background-color: #E8E8E8;
}

.thumbnail span {
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maskbg {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #00000070;
}

.btn {
    position: absolute;
    background-color: #FFFFFFA0;
    border-radius: 100px;
    padding: 6px;
    height: 32px;
}

.btn-close {
    top: 0px;
    right: 0px;
}

.btn-preview {
    left: 0px;
    bottom: 0px;
}

.btn-download {
    right: 0px;
    bottom: 0px;
}
</style>
