<template>
  <div class="col-4">

    <div class="toast-header text-bold">
      예약 연동정보 변경 안내
    </div>
    <div class="toast-body">
      <span>외부 예약 채널과 연동중인 정보가 변경되었습니다.</span><br/>
      <span @click.stop="clicked" style="cursor: pointer; text-underline: white; text-decoration: underline">변경 정보 확인하기 ></span>
    </div>
<!--    <button-->
<!--      class="action"-->
<!--    >CLICK ME</button>-->
  </div>
</template>

<script>
export default {
  name:'toastification',
  props: {
    value: {

    },
  },
  data() {
    return {

    };
  },
  computed: {},
  mounted() {},
  methods: {
    clicked() {
      console.log("aa")
      this.$emit('myClick');
    }
  }
};
</script>

<style scoped>
.toast-header{
  padding-top : 4px;
  padding-bottom : 6px;
}
.toast-body{
  padding-top : 6px;
  padding-bottom : 8px;

}

</style>
