<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <b>T</b>ABLENJOY <b>M</b>ANAGER
      </div>
      <div class="login-box-body">
        <p class="box-title">비밀번호 찾기</p>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon adduserid"></span>
            <input type="text" class="form-control" v-model="userid" placeholder="아이디">
          </div>
          <span class="text-red">{{userrmsg}}</span>
        </div>
        <div class="form-group">
          <div class="input-group" style="position:relative">
            <span class="input-group-addon addphone"></span>
            <input type="text" class="form-control" v-model="phonenum" placeholder="전화번호">
            <button type="button" class="btn btn-primary smsbtn" @click="ReqTmppsw">임시 비밀번호 요청</button>
          </div>
          <span class="text-red">{{phonemsg}}</span>
        </div>
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon addpassword"></span>
            <input type="password" class="form-control" v-model="password" placeholder="임시 비밀번호">
          </div>
          <span class="text-red">{{pswerrmsg}}</span>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-primary btn-block" @click="clickLogin">로그인</button>
          <span >로그인후 반드시 비밀번호를 변경해주세요.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rescode, cookielbl } from "../../lib/consts";
import { isMobileNum } from "../../lib/utility";
import { reqPost } from "../../lib/request";
import { adminapi } from "../../lib/api";
import routes from "../../lib/routes";
export default {
  data() {
    return {
      userid: "",
      userrmsg: "",
      password: "",
      pswerrmsg: "",
      phonenum: "",
      phonemsg: "",
    };
  },
  updated() {},
  created() {
    this.userid = window.localStorage.getItem(cookielbl.remem);
  },
  mounted() {
  },
  methods: {
    clickLogin() {
      if(this.userid != "" && this.password != "")
      {
        reqPost(adminapi.base, adminapi.Pswlogin, "", {username:this.userid, otp:this.password}, (data)=>{
          if(data != null)
          {
              if(data.code == rescode.REQ_SUCCESS)
              {
                  var val = data.data;
                  // Check local storage to handle refreshes

                  var authdata = {token:val.tokens.access.token, expiresIn:val.tokens.access.expiresIn, expiresAt: val.tokens.access.expiresIn +  Math.floor(Date.now()/1000),
                    refresh:val.tokens.refresh.token, refreshexpiresIn:val.tokens.refresh.expiresIn, refreshexpiresAt: val.tokens.refresh.expiresIn +  Math.floor(Date.now()/1000),
                    userRole:val.userRole};

                  if (window.localStorage) {
                      window.localStorage.setItem(cookielbl.authinf, JSON.stringify(authdata));
                  }
                  if(this.remem){
                      window.localStorage.setItem(cookielbl.remem, this.userid);
                  }else{
                      window.localStorage.setItem(cookielbl.remem, "");
                  }
                  authdata.userid = this.userid;
                  this.$store.dispatch('dologin', authdata);
              }
              this.isLoginSuccess(data.code, data.message);
          }else{
              this.userrmsg = "로그인 실패 되었습니다.";
          }
        });
      }else{
        if(this.userid == "")
        {
          this.userrmsg = "아이디를 입력하세요.";
        }else{
          this.pswerrmsg = "임시 비밀번호를 입력하세요.";
        }
      }
    },
    ReqTmppsw(){
        if(this.userid == "")
        {
            this.userrmsg = "아이디를 입력하세요.";
            return ;
        }else{
            this.userrmsg = "";
        }

        if(this.phonenum == "")
        {
            this.phonemsg = "전화번호를 입력하세요.";
            return ;
        }else if(!isMobileNum(this.phonenum))
        {
            this.phonemsg = "휴대폰 번호가 아닙니다.";
            return ;
        }else{
            this.phonemsg = "";
        }

        reqPost(adminapi.base, adminapi.Pswotp, "", {username:this.userid, phoneNumber:this.phonenum}, (data)=>{
            if(data != null)
            {//SMS번호가 전송되었다면
                this.phonemsg = data.message;
            }else{
                this.phonemsg = "임시 비밀번호 요청이 실패되었습니다.";
            }
        });
    },
    isLoginSuccess(val, message) {
      if (val === null) {
        this.userrmsg = "로그인 실패 되었습니다.";
        this.pswerrmsg = "";
        this.authmsg = "";
      } else if (val === rescode.REQ_SUCCESS) {
        // this.$router.push("/"+routes.resetpsw);
        window.location.href = '/'+routes.resetpsw;
      } else {
        this.userrmsg = message;
        this.pswerrmsg = "";
        this.authmsg = "";
      }
    }
  },
};
</script>

<style scoped>
.login-page{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.box-title{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.adduserid{
  background-image: url("/static/img/uaserid.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addphone{
  background-image: url("/static/img/phone.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addpassword{
  background-image: url("/static/img/password.png");
  background-repeat: no-repeat;
  background-position: center;
}
.smsbtn{
  position: absolute;
  right: 0;
  z-index: 100;
}
.btn{
  background-color: #333333;
  border-radius: 100px;
}

.input-group-addon, .form-control{
  border: 0px;
}

.form-control{
  padding: 6px 0px;
}

.input-group{
  border-bottom: 1px solid #b2b2b2;
}
</style>
