// import {rescode} from './consts'
import config from '../config'
import axios from 'axios'

export const reqPost = async (base, url, token, body, callback) => {
  await axios.post(config.server + base + config.sitename + url, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": " Bearer " + token,
    },
  }).then(function (response) {
    callback(response.data);
  }).catch(function (error) {
      if (error.response) {
        callback(error.response.data);
      } else {
        // Something happened in setting up the request that triggered an Error
        callback(null);
      }
    })
};

export const post = async (base, url, token, body) => {
    try {
        const res = await axios.post(config.server + base + config.sitename + url, body, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": " Bearer " + token,
            },
        });

        return res?.data;
    } catch (error) {
        return error?.response?.data || null;
    }
};

export const reqGet = async (base, url, token, callback) => {
  axios.get(config.server + base + config.sitename + url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": " Bearer " + token,
    },
  }).then(function (response) {
    callback(response.data);
  })
    .catch(function (error) {
      if (error.response) {
        callback(error.response.data);
      } else {
        // Something happened in setting up the request that triggered an Error
        callback(null);
      }
    })
};
export const reqGetb2bTestScheduler = async (base, url, token, callback) => {
  axios.get(config.server + base + url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": " Bearer " + token,
    },
  })
    .then(function (response) {
    setTimeout(()=>{
      callback(response.data);
    },200);
  })
    .catch(function (error) {
      console.log(error)
      if (error.response) {
        callback(error.response.data);
      } else {
        // Something happened in setting up the request that triggered an Error
        callback(null);
      }
    })
};
export const getMyIP = async (callback) => {
  try {
    // const response = await fetch("https://api.ipify.org?format=json");
    const response = await fetch("https://ipinfo.io/json");
    const jsonObj = await response.json();
    const ipAddr = jsonObj.ip;
    callback(ipAddr);
  } catch (err) {
    callback("0.0.0.0");
  }
}

// export const getMyIP_old = async (callback) => {
//   console.log("request getMyIP");
//   fetch("https://api.ipify.org?format=json").then(response => response.json())
//   .then(response => {
//     console.log("getMyIP result")
//     callback(response.ip);
//   }).catch(function () {
//     callback("0.0.0.0");
//   })
// };

export const getfilename = function (url) {
  return url.substring(url.lastIndexOf('/')+1);
}
