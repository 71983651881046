<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th class="text-center">채널명</th>
              <th class="text-center">신규등록</th>
              <th class="text-center">정보수정</th>
              <th class="text-center">판매종료</th>
              <th class="text-center">판매재개</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dstate in distributionStates" v-bind:key="dstate.channelCode">
              <td class="linkcmp text-center" @click="godetail(dstate.channelCode, '')">{{dstate.channelName}}</td>
              <td class="linkcmp text-center" @click="godetail(dstate.channelCode, 'Register')">{{dstate.registerCount | numFormat}}</td>
              <td class="linkcmp text-center" @click="godetail(dstate.channelCode, 'Update')">{{dstate.updateCount | numFormat}}</td>
              <td class="linkcmp text-center" @click="godetail(dstate.channelCode, 'Pause')">{{dstate.pauseCount | numFormat}}</td>
              <td class="linkcmp text-center" @click="godetail(dstate.channelCode, 'Resume')">{{dstate.resumeCount | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import { reqGet } from "../../../lib/request";
import { b2bapi } from "../../../lib/api";
export default {
  components: {
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_PRD, path2:menuval.LV2_PRD_B2B, title:"채널 배포요청 현황"});
  },
  data() {
    return {
      distributionStates:[],
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    refreshPage() {
      reqGet(
        b2bapi.base,
        b2bapi.distribution,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.distributionStates = result.data.distributionStates;
            } else {
              this.distributionStates = [];
            }
          }
        }
      );
    },
    godetail(chcode, requestType){
      this.$router.push(this.routes.dpchnnldetail+"?channelCode="+chcode+"&requestType="+requestType);
    }
  },
};
</script>

<style scoped>

</style>