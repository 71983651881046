<template>
<aside class="main-sidebar">
    <section class="sidebar">
        <ul class="sidebar-menu">
            <li :class="['treeview', menulv1 == menuval.LV1_ORD && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu1_bg"></i>
                    <!-- <i class="fa fa-dashboard"></i> -->
                    <span>주문관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_ORD && 'menu-open']">
                    <li :class="[curpath === routes.ordhis && 'active']">
                        <Menulink :to="routes.ordhis">
                            <!-- <router-link :to="routes.ordhis" replace> -->
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            주문내역조회
                            <!-- </router-link> -->
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.ordcnlhis && 'active']">
                        <Menulink :to="routes.ordcnlhis">
                            <!-- <i class="fa fa-file-picture-o"></i> -->
                            취소요청조회
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.refundhis && 'active']">
                        <Menulink :to="routes.refundhis">
                            <!-- <i class="fa fa-file-pdf-o"></i> -->
                            환불신청조회
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.paycnlfailhis && 'active']">
                        <Menulink :to="routes.paycnlfailhis">
                            <!-- <i class="fa fa-file-pdf-o"></i> -->
                            결제취소 실패내역
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_ISSUE && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu2_bg"></i>
                    <span>발급관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_ISSUE && 'menu-open']">
                    <li :class="['treeview', menulv2 == menuval.LV2_ISS_ISS && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            발급내역
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_ISS_ISS && 'menu-open']">
                            <li :class="[curpath === routes.isscupon && 'active']">
                                <Menulink :to="routes.isscupon">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    모바일 쿠폰
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.issprice && 'active']">
                                <Menulink :to="routes.issprice">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    모바일 금액권
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.issreservation && 'active']">
                                <Menulink :to="routes.issreservation">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    예약필수쿠폰
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.storereservation && 'active']">
                                <Menulink :to="routes.storereservation">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    매장예약
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.isspoint && 'active']">
                                <Menulink :to="routes.isspoint">
                                  <!-- <i class="fa fa-file-picture-o"></i> -->
                                  포인트권
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_ISS_CCL && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            발급취소내역
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_ISS_CCL && 'menu-open']">
                            <li :class="[curpath === routes.isscnlcupon && 'active']">
                                <Menulink :to="routes.isscnlcupon">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    모바일 쿠폰
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.isscnlprice && 'active']">
                                <Menulink :to="routes.isscnlprice">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    모바일 금액권
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.isscnlreservation && 'active']">
                                <Menulink :to="routes.isscnlreservation">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    예약필수쿠폰
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.storecnlreservation && 'active']">
                                <Menulink :to="routes.storecnlreservation">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    매장예약
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.isscnlpoint && 'active']">
                                <Menulink :to="routes.isscnlpoint">
                                  <!-- <i class="fa fa-file-picture-o"></i> -->
                                  포인트권
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_ISS_USE && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            사용이력
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_ISS_USE && 'menu-open']">
                            <li :class="[curpath === routes.issusecupon && 'active']">
                                <Menulink :to="routes.issusecupon">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    모바일 쿠폰
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.issuseprice && 'active']">
                                <Menulink :to="routes.issuseprice">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    모바일 금액권
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.issusereservation && 'active']">
                                <Menulink :to="routes.issusereservation">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    예약필수쿠폰
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="[curpath === routes.issstamp && 'active']">
                        <Menulink :to="routes.issstamp">
                            <!-- <i class="fa fa-file-picture-o"></i> -->
                            인지세관리
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_PRD && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu3_bg"></i>
                    <span>상품관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_PRD && 'menu-open']">
                    <li :class="['treeview', menulv2 == menuval.LV2_PRD_REG && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            등록관리
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_PRD_REG && 'menu-open']">
                            <li :class="[curpath === routes.prdnewreg && 'active']">
                                <Menulink :to="routes.prdnewreg">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    신규상품등록
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.prdreqinspec && 'active']">
                                <Menulink :to="routes.prdreqinspec">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    상품검수신청
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.prdmdfinfo && 'active']">
                                <Menulink :to="routes.prdmdfinfo">
                                    <!-- <i class="fa fa-file-picture-o"></i> -->
                                    상품정보수정
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_PRD_OPR && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            운영관리
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_PRD_OPR && 'menu-open']">
                            <li :class="[curpath === routes.prdopermdf && 'active']">
                                <Menulink :to="routes.prdopermdf">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    판매상태변경
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.prdoption && 'active']">
                                <Menulink :to="routes.prdoption">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    대표옵션관리
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.prdoutcode && 'active']">
                                <Menulink :to="routes.prdoutcode">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    외부코드관리
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.prdcupon && 'active']">
                                <Menulink :to="routes.prdcupon">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    쿠폰번호 커스텀
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_PRD_B2B && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            B2B 상품관리
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_PRD_B2B && 'menu-open']">
                            <li :class="[curpath === routes.b2bdeploy && 'active']">
                                <Menulink :to="routes.b2bdeploy">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    채널배포요청
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.b2bcode && 'active']">
                                <Menulink :to="routes.b2bcode">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    코드매칭관리
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.b2bchannel && 'active']">
                                <Menulink :to="routes.b2bchannel">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    채널오픈현황
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="[curpath === routes.prdvalidity && 'active']">
                        <Menulink :to="routes.prdvalidity">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            유효기간 만료예정
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_BOOK && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu4_bg"></i>
                    <span>예약관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_BOOK && 'menu-open']">
                    <li :class="[curpath === routes.book && 'active']">
                        <Menulink :to="routes.book">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            예약내역조회
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_BRD && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu5_bg"></i>
                    <span>제휴사 관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_BRD && 'menu-open']">
                    <li :class="[curpath === routes.brndmng && 'active']">
                        <Menulink :to="routes.brndmng">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            브랜드관리
                        </Menulink>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_BRD_STR && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            매장관리
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_BRD_STR && 'menu-open']">
                            <li :class="[curpath === routes.storelist && 'active']">
                                <Menulink :to="routes.storelist">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    매장리스트
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.storeinspec && 'active']">
                                <Menulink :to="routes.storeinspec">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    검수신청
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="[curpath === routes.review && 'active']">
                        <Menulink :to="routes.review">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            리뷰관리
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.alimtalkMng && 'active']">
                        <Menulink :to="routes.alimtalkMng">
                          <!-- <i class="fa fa-file-word-o"></i> -->
                          알림톡관리
                        </Menulink>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_BRD_KKOR && 'active']">
                      <a href="#">
                        카카오톡 예약하기
                        <span class="pull-right-container pull-right">
                                  <i class="fa fa-angle-left fa-fw"></i>
                              </span>
                      </a>
                      <ul :class="['treeview-menu', menulv2 == menuval.LV2_BRD_KKOR && 'menu-open']">
                        <li :class="[curpath === routes.kakaoRequest && 'active']">
                          <Menulink :to="routes.kakaoRequest">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            입점신청 관리
                          </Menulink>
                        </li>
                        <li :class="[curpath === routes.kakaoPartnerMng && 'active']">
                          <Menulink :to="routes.kakaoPartnerMng">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            파트너 관리
                          </Menulink>
                        </li>
                      </ul>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_DIS && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu5_bg"></i>
                    <span>할인쿠폰 관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_DIS || menulv1 == menuval.LV2_DIS && 'menu-open' ]">
                    <li :class="[curpath === routes.discountAddPage && menulv1 == menuval.LV1_DIS && 'active']">
                        <Menulink :to="routes.discountAddPage">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            할인쿠폰등록
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.discountApprovalPage && 'active']">
                        <Menulink :to="routes.discountApprovalPage">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            할인쿠폰승인
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.discountListPage && 'active' ]">
                        <Menulink :to="routes.discountListPage">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            할인쿠폰리스트
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_MEM && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu6_bg"></i>
                    <span>회원관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_MEM && 'menu-open']">
                    <li :class="[curpath === routes.genmem && 'active']">
                        <Menulink :to="routes.genmem">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            일반회원
                        </Menulink>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_MEM_TNJ && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            TNJ 관리자
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_MEM_TNJ && 'menu-open']">
                            <li :class="[curpath === routes.mnglist && 'active']">
                                <Menulink :to="routes.mnglist">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    관리자 리스트
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.regmng && 'active']">
                                <Menulink :to="routes.regmng">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    관리자 등록신청
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_DSP && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu7_bg"></i>
                    <span>사이트관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_DSP && 'menu-open']">
                    <li :class="[curpath === routes.dsp && 'active']">
                        <Menulink :to="routes.dsp">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            기획전
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.dspmain && 'active']">
                        <Menulink :to="routes.dspmain">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            메인페이지
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.promotion && 'active']">
                        <Menulink :to="routes.promotion">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            프로모션
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.intro && 'active']">
                        <Menulink :to="routes.intro">
                            인트로
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.hotplace && 'active']">
                        <Menulink :to="routes.hotplace">
                            핫플레이스
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_PAY && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu8_bg"></i>
                    <span>정산관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_PAY && 'menu-open']">
                    <li :class="[curpath === routes.storepayhis && 'active']">
                        <Menulink :to="routes.storepayhis">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            매장별 정산내역
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.billissue && 'active']">
                        <Menulink :to="routes.billissue">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            세금계산서 발행
                        </Menulink>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_PAY_DPS && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            입금관리
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_PAY_DPS && 'menu-open']">
                            <li :class="[curpath === routes.deposit && 'active']">
                                <Menulink :to="routes.deposit">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    금액확정
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.depfinish && 'active']">
                                <Menulink :to="routes.depfinish">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    입금완료처리
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.dephis && 'active']">
                                <Menulink :to="routes.dephis">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    입금내역조회
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_AGENT && 'active']">
                <a href="#">
                    <i class="fa menu menu11_bg"></i>
                    <span>에이전시</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_AGENT && 'menu-open']">
                    <li :class="[curpath === routes.agentstoresettlement && 'active']">
                        <Menulink :to="routes.agentstoresettlement">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            매장별 정산내역
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_STAT && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu9_bg"></i>
                    <span>통계관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_STAT && 'menu-open']">
                    <li :class="['treeview', menulv2 == menuval.LV2_STT_DPL && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            거래현황조회
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_STT_DPL && 'menu-open']">
                            <li :class="[curpath === routes.dealtargt && 'active']">
                                <Menulink :to="routes.dealtargt">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    목표대비
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.dealchannel && 'active']">
                                <Menulink :to="routes.dealchannel">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    채널별 (기간)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.dealchannelmonth && 'active']">
                                <Menulink :to="routes.dealchannelmonth">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    채널별 (월별)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.dealprod && 'active']">
                                <Menulink :to="routes.dealprod">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    상품별
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.dealbrand && 'active']">
                                <Menulink :to="routes.dealbrand">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    브랜드별 (기간)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.dealbrandmonth && 'active']">
                                <Menulink :to="routes.dealbrandmonth">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    브랜드별 (월별)
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_STT_USE && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            사용현황조회
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_STT_USE && 'menu-open']">
                            <li :class="[curpath === routes.usetarget && 'active']">
                                <Menulink :to="routes.usetarget">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    목표대비
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.usechannel && 'active']">
                                <Menulink :to="routes.usechannel">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    채널별 (기간)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.usechannelmonth && 'active']">
                                <Menulink :to="routes.usechannelmonth">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    채널별 (월별)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.usebrand && 'active']">
                                <Menulink :to="routes.usebrand">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    브랜드별 (기간)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.usebrandmonth && 'active']">
                                <Menulink :to="routes.usebrandmonth">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    브랜드별 (월별)
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.useprod && 'active']">
                                <Menulink :to="routes.useprod">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    상품별
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="['treeview', menulv2 == menuval.LV2_STS_ISS && 'active']">
                        <a href="#">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            할인쿠폰현황조회
                            <span class="pull-right-container pull-right">
                                <i class="fa fa-angle-left fa-fw"></i>
                            </span>
                        </a>
                        <ul :class="['treeview-menu', menulv2 == menuval.LV2_STS_ISS && 'menu-open']">
                            <li :class="[curpath === routes.discountIssuanceStatus && 'active']">
                                <Menulink :to="routes.discountIssuanceStatus">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    발급현황조회
                                </Menulink>
                            </li>
                            <li :class="[curpath === routes.discountUseStatus && 'active']">
                                <Menulink :to="routes.discountUseStatus">
                                    <!-- <i class="fa fa-file-word-o"></i> -->
                                    사용현황조회
                                </Menulink>
                            </li>
                        </ul>
                    </li>
                    <li :class="[curpath === routes.statprice && 'active']">
                        <Menulink :to="routes.statprice">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            목표금액관리
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_BOD && 'active']" v-if="isHostStaff">
                <a href="#">
                    <i class="fa menu menu10_bg"></i>
                    <span>게시판관리</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_BOD && 'menu-open']">
                    <li :class="[curpath === routes.notice && 'active']">
                        <Menulink :to="routes.notice">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            공지사항
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.cuscenter && 'active']">
                        <Menulink :to="routes.cuscenter">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            고객센터
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.ques && 'active']">
                        <Menulink :to="routes.ques">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            제휴문의
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.faq && 'active']">
                        <Menulink :to="routes.faq">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            자주묻는 질문
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.reqdocu && 'active']">
                        <Menulink :to="routes.reqdocu">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            증빙서류신청
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.telephone && 'active']">
                        <Menulink :to="routes.telephone">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            전화상담 접수관리
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.noticeadmin && 'active']">
                        <Menulink :to="routes.noticeadmin">
                            <!-- <i class="fa fa-file-word-o"></i> -->
                            하이테이블 공지사항
                        </Menulink>
                    </li>
                </ul>
            </li>
            <li :class="['treeview', menulv1 == menuval.LV1_DEV && 'active']" v-if="isDeveloper">
                <a href="#">
                    <i class="fa menu fa-cogs"></i>
                    <span>개발자기능</span>
                    <span class="pull-right-container pull-right">
                        <i class="fa fa-angle-left fa-fw"></i>
                    </span>
                </a>
                <ul :class="['treeview-menu', menulv1 == menuval.LV1_DEV && 'menu-open']">
                    <li :class="[curpath === routes.b2binterworking && 'active']">
                        <Menulink :to="routes.b2binterworking">
                            B2B 연동 수동처리
                        </Menulink>
                    </li>
                    <li :class="[curpath === routes.pos && 'active']">
                        <Menulink :to="routes.pos">
                            POS 연동관리
                        </Menulink>
                    </li>
                  <li :class="[curpath === routes.channelDev && 'active']">
                    <Menulink :to="routes.channelDev">
                      채널 연동관리
                    </Menulink>
                  </li>
                  <li :class="[curpath === routes.alimTalkDev && 'active']">
                    <Menulink :to="routes.alimTalkDev">
                      개발서버 알림톡 발송관리
                    </Menulink>
                  </li>
                </ul>
            </li>
        </ul>
    </section>
</aside>
</template>

<script>
import routepath from "../lib/routes";
import Menulink from "../components/Menulink";
import {
    menuval
} from "../lib/consts";
import alimtalkMng from "@/views/brand/AlimtalkMng.vue";
export default {
    name: "Siderbar",
    components: {
        Menulink,
    },
    props: {},
    computed: {
      alimtalkMng() {
        return alimtalkMng
      },
        routes() {
            return routepath;
        },
        menulv1() {
            return this.$store.getters.menulv1;
        },
        menulv2() {
            return this.$store.getters.menulv2;
        },
        menuval() {
            return menuval;
        },
        curpath() {
            return this.$store.getters.curpath;
        },
        isAgent() {
            return this.$store.getters.isAgentRole;
        },
        isHostStaff() {
            return !this.$store.getters.isAgentRole;
        },
        isDeveloper() {
          console.log(this.$store.getters.authuser);
            return (this.$store.getters.authuser === 'ella' ||
                this.$store.getters.authuser === 'hank' ||
                this.$store.getters.authuser === 'tjdwls2775' ||
                this.$store.getters.authuser === 'liam' ||
                this.$store.getters.authuser === 'buddy');
        }
    },
};
</script>

<style scoped>
/* override default */
.sidebar-menu>li>a {
    padding: 12px 15px 12px 15px;
    /* padding: 15px; */
}

.sidebar-menu li.active>a>.fa-angle-left,
.sidebar-menu li.active>a>.pull-right-container>.fa-angle-left {
    animation-name: rotate;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-90deg);
    }
}

.menu {
    background-repeat: no-repeat;
    background-position: left;
    background-size: 15px 15px;
    height: 15px;
    position: relative;
    top: 2px;
}

.menu1_bg {
    background-image: url("/static/img/icon/ic_01.png");
}

.menu2_bg {
    background-image: url("/static/img/icon/ic_02.png");
}

.menu3_bg {
    background-image: url("/static/img/icon/ic_03.png");
}

.menu4_bg {
    background-image: url("/static/img/icon/ic_04.png");
}

.menu5_bg {
    background-image: url("/static/img/icon/ic_05.png");
}

.menu6_bg {
    background-image: url("/static/img/icon/ic_06.png");
}

.menu7_bg {
    background-image: url("/static/img/icon/ic_07.png");
}

.menu8_bg {
    background-image: url("/static/img/icon/ic_08.png");
}

.menu9_bg {
    background-image: url("/static/img/icon/ic_09.png");
}

.menu10_bg {
    background-image: url("/static/img/icon/ic_10.png");
}

.menu11_bg {
    background-image: url("/static/img/icon/ic_11.png");
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span {
    padding: 15px 5px 15px 20px;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
    top: 44px;
}
</style>
