<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <select class="form-control" style="width:auto;margin-right:10px" v-model="dateType">
            <option value="IssuedDate">발행일</option>
            <option value="ConsumedDate">사용일</option>
<!--            <option value="ConsumedDisable">사용불가</option>-->
          </select>
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="OrderNo">주문번호</option>
            <option value="B2bOrderNo">B2B 주문번호</option>
            <option value="ItemNo">쿠폰번호</option>
            <option value="BuyerPhoneNumber">구매자 전화번호</option>
            <option value="ReceiverPhoneNumber">수신자 전화번호</option>
            <option value="BuyerPhoneNumber4">구매자 전화번호 끝 4자리</option>
            <option value="ReceiverPhoneNumber4">수신자 전화번호 끝 4자리</option>
            <option value="Username">사용자 ID</option>
            <option value="BuyerName">구매자 이름</option>
            <option value="ReceiverName">수신자 이름</option>
            <option value="B2bOrderNo">B2B주문번호</option>
            <option value="Channel">주문경로</option>
            <option value="GoodsNameContaining">상품명</option>

          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData('noCompany')" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>미사용리스트
      </button>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">주문번호</th>
            <th>쿠폰번호</th>
            <th>상품명</th>
            <th>옵션명</th>
            <th class="text-center">정상가</th>
            <th class="text-center">판매가</th>
            <th class="text-center">구매자</th>
            <th class="text-center">발행일</th>
            <th class="text-center">잔액</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(money, index) in content" v-bind:key="money.itemNo+'_'+index">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="linkcmp text-center" @click="goDetailViewPage(money.orderInfo.orderNo)">
              {{ money.orderInfo.orderNo }}
            </td>
            <td class="linkcmp" @click="getPointUseHisData(money.itemNo)">{{ money.itemNo }}</td>
            <td>{{ money.goodsName }}</td>
            <td>{{ money.optionName }}</td>
            <td class="text-center">{{ money.listPrice | numFormat }}</td>
            <td class="text-center">{{ money.salePrice | numFormat }}</td>
            <td class="text-center">{{ money.orderInfo.buyerName }}</td>
            <td class="text-center">{{ viewDate(money.issueDate) }}</td>
            <td class="text-center" v-if="money.usableAmount > 0">{{ money.usableAmount | numFormat }}</td>
            <td class="text-center" v-if="money.usableAmount == 0">사용완료</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="usehisdlg && {'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">사용내역조회 ({{ goodsTypeVal(transgoodsType) }})</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th>브랜드</th>
                    <th>매장</th>
                    <th>사용일자</th>
                    <th>사용금액</th>
                    <th>사용취소여부</th>
                    <th>취소 처리일</th>
                    <th>
                      <input type="checkbox" class="allch" @change="allckech($event)"/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="trans in transactions" :key="trans.transactionNo">
                    <td>{{ trans.brandName }}</td>
                    <td>{{ trans.storeName }}</td>
                    <td>{{ viewDate(trans.useDate) }}</td>
                    <td>{{ trans.useAmount | numFormat }}</td>
                    <td>{{ getCancelStr(trans.cancelled) }}</td>
                    <td>{{ viewDate(trans.cancelledDate) }}</td>
                    <td>
                      <input type="checkbox" class="chitem" :value="trans.transactionNo"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div class="form-group">
              <label class="col-sm-3 control-label">취소 처리일</label>
              <div class="col-sm-9" style="display:inline-flex;flex-wrap: wrap;">
                <DetePicker style="margin-right:10px;" v-model="canceldate"/>
                <div class="input-group">
                  <input type="time" class="form-control" style="width:auto;" v-model="cancelTime"/>
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <label class="col-sm-3 control-label">취소사유</label>
              <div class="col-sm-9">
                <textarea class="form-control" rows="5" v-model="transmemo"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeUseHis">닫기</button>
              <button type="button" class="btn btn-default" @click="doCancelProcess">사용취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--여기 포인트권 추가 팝업에 대한 작업 필요-->
    <div class="modal" :style="usehisdlg2 && {'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'MobileCoupon'">사용내역조회 (모바일쿠폰)</div>
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'MoneyCoupon'">사용내역조회 (금액권)</div>
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'BookingCoupon'">사용내역조회 (예약필수쿠폰)</div>
          <div class="modal-header border-bottom-0" v-if="transgoodsType == 'PointCoupon'">사용내역조회 (포인트권)</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th class="text-center">주문번호</th>
                    <th class="text-center">사용일자</th>
                    <th class="text-center">사용금액</th>
                    <th class="text-center">취소 처리일</th>
                  </tr>
                  </thead>
                  <tbody class="text-center">
                  <tr v-for="trans in transactions" :key="trans.transactionNo">
                    <td class="text-center linkcmp" @click="goDetailOrder(trans.orderNumber)" >{{ trans.orderNumber }}</td>
                    <td class="text-center">{{ viewDate(trans.useDate) }}</td>
                    <td class="text-center">{{ trans.useAmount | numFormat }}</td>
                    <td class="text-center">{{ viewDate(trans.cancelledDate) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeUseHis">취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import {rescode, menuval} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import DetePicker from "../../../components/DatePicker";
import {reqPost} from "../../../lib/request";
import {orderapi} from "../../../lib/api";
import {DateFormat, getGoodsTypeStr} from "../../../lib/utility";
import $ from 'jquery';
import XLSX from "xlsx";
import moment from 'moment';

export default {
  components: {
    Pagination,
    DetePicker,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.ISSP_keywordType) {
        this.keywordType = localStorage.ISSP_keywordType;
      }
      if(localStorage.ISSP_keyword) {
        this.keyword = localStorage.ISSP_keyword;
      }
      if(localStorage.ISSP_Number) {
        this.pageNumber = parseInt(localStorage.ISSP_Number);
      }
      if(localStorage.ISSP_Size) {
        this.pageSize = parseInt(localStorage.ISSP_Size);
      }
      if(localStorage.ISSP_dateType) {
        this.dateType = localStorage.ISSP_dateType;
      }
      if(localStorage.ISSP_dateFrom) {
        this.dateFrom = localStorage.ISSP_dateFrom;
      }
      if(localStorage.ISSP_dateTo) {
        this.dateTo = localStorage.ISSP_dateTo;
      }
    }
    this.$store.dispatch('doSetPath', {path1: menuval.LV1_ISSUE, path2: menuval.LV2_ISS_ISS, title: "포인트권"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.ISSP_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.ISSP_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.ISSP_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.ISSP_Size = newsize;
    },
    dateType(newType) {
      localStorage.ISSP_dateType = newType;
    },
    dateFrom(newfrom) {
      localStorage.ISSP_dateFrom = newfrom;
    },
    dateTo(newto) {
      localStorage.ISSP_dateTo = newto;
    }
  },
  data() {
    return {
      excelBtnType: '', // 미사용리스트 체크
      shownum: 10, //pagination에 보여지는 번호개수
      dateType: "IssuedDate",
      dateFrom: moment().format("YYYY-MM-01"),
      dateTo: moment().format("YYYY-MM-DD"),
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * itemNo               String    쿠폰번호
       * itemStatus           String    [Available, Invalidated, CancelRequested, Cancelled, BookingConnected, Locked, Consumed]
       *          Available: 사용가능, CancelRequested: 취소요청됨, Cancelled: 취소됨, BookingConnected: 예약에 연결됨, Locked: 사용불가처리됨, Consumed: 사용완료
       * goodsType            String    [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
       *          MobileCoupon: 모바일쿠폰, BookingCoupon: 예약권, Booking: 예약, MoneyCoupon: 금액권, CashCoupon: 캐시권
       * goodsId              Number    상품 ID
       * goodsName            String    상품명
       * optionId             Number    옵션 ID
       * optionName           String    옵션명
       * issueDate            String    UTC yyyy-MM-ddTHH:mm:ss 발행일
       * receiverName         String    수신자 이름
       * receiverPhone        String    수신자 전화번호
       * listPrice            String    정상가
       * salePrice            String    판매가
       * usedAmount           String    사용한 금액
       * usableAmount         String    사용가능 금액
       * currencyCode         String    [USD, KRW] 통화코드
       * orderInfo.orderNo          String    주문번호
       * orderInfo.username         String    사용자 ID
       * orderInfo.siteName         String    구매 사이트명
       * orderInfo.buyerName        String    구매자 이름
       * orderInfo.buyerPhone       String    구매자 전화번호
       * orderInfo.purchaseDate     String    UTC yyyy-MM-ddTHH:mm:ss 구매일자
       * orderInfo.orderStatus      String    [Purchased, CancelRequested, CancelFailed, Cancelled] 주문상태
       *            Purchased: 주문완료, CancelRequested: 주문취소 요청됨, CancelFailed: 주문취소 실패, Cancelled: 주문취소됨
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      usehisdlg: false, //사용내역조회
      usehisdlg2: false,
      transitemNo: "",
      transgoodsType: "",//상품 타입
      transactions: [],//
      /**
       * transactionNo          String      사용처리 트랜잭션 번호
       * brandName              String      사용처리한 브랜드명
       * brandCode              String      사용처리한 브랜드 코드
       * storeName              String      사용처리한 매장명
       * storeCode              String      사용처리한 매장코드
       * useDate                String      사용한 날짜
       * useAmount              String      사용한 금액 (금액권일 경우에만 사용)
       * currencyCode           String      [USD, KRW]  사용한 금액 통화 코드
       * siteName               String      사용처리한 사이트명
       * managerId              String      사용처리한 관리자 ID
       * cancelled              Boolean     취소된 트랜잭션인 경우 true
       * cancelledDate          String      UTC yyyy-MM-ddTHH:mm:ss 취소 처리일
       */
      transmemo: "",
      exceldata: [],
      canceldate: moment().format("YYYY-01-01"),
      cancelTime: "00:00",
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.ISSP_keywordType = this.keywordType;
      localStorage.ISSP_keyword = this.keyword;
      localStorage.ISSP_Number = this.pageNumber;
      localStorage.ISSP_Size = this.pageSize;
      localStorage.ISSP_dateType = this.dateType;
      localStorage.ISSP_dateFrom = this.dateFrom;
      localStorage.ISSP_dateTo = this.dateTo;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },

    goDetailOrder(orderNo) {
      this.usehisdlg2 = false;
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
    },

    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      reqPost(
        orderapi.base,
        orderapi.pointCouponissuedpage,
        this.authToken,
        {
          dateType: this.dateType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          pageNumber: pageNumber,
          pageSize: this.pageSize
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goDetailViewPage(orderNo) {
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
    },
    closeUseHis() {
      this.usehisdlg = false;
      this.usehisdlg2 = false;
    },

    getPointUseHisData(itemNo){
      reqPost(
        orderapi.base,
        orderapi.admitemUseInfo,
        this.authToken,
        {
          itemNo: itemNo,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              if(result.data != null) {
                this.transitemNo = result.data.itemNo;
                this.transgoodsType = result.data.goodsType;
                this.transactions = result.data.transactions;
              } else {
                this.transgoodsType = "PointCoupon";
                this.transactions = [];
              }
              this.usehisdlg2 = true;
            } else {
              this.transgoodsType = "PointCoupon";
              this.transactions = [];
              alert(result.message);
            }
          }
        }
      );
    },
    goodsTypeVal(val) {
      return getGoodsTypeStr(val);
    },
    doCancelProcess() {
      var checkarray = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();
      var transactions = checkarray.map((citem) => {
        return {itemNo: this.transitemNo, transactionNo: citem}
      });
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.cancelItemUseTransaction,
        this.authToken,
        {
          transactions: transactions,
          memo: this.transmemo
        },
        (result) => {
          if(result != null) {
            this.$store.dispatch('setDisabledLayout');
            if(result.code == rescode.REQ_SUCCESS) {
              this.transmemo = "";
              this.closeUseHis();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    dataconvert(order) {
      let salesType = '';
      if (order.orderInfo.channelType === 'Tablenjoy' && order.orderInfo.siteName !== 'tablenjoy') {
        salesType = '자체판매';
      } else {
        salesType = '';
      }
      return {
        A: order.orderInfo.orderNo,
        B: order.itemNo,
        C: order.goodsName,
        D: order.optionName,
        E: order.listPrice,
        F: order.salePrice,
        G: order.orderInfo.buyerName,
        H: this.viewDate(order.issueDate),
        I: order.usableAmount,
        J: order.receiverPhone,
        M: order.orderInfo.b2bChannelCode,
        N: order.availableTo,
        O: salesType,
        P: order.itemStatus === "Locked" ? "O":"",
      };
    },
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "주문번호",
            B: "쿠폰번호",
            C: "상품명",
            D: "옵션명",
            E: "정상가",
            F: "판매가",
            G: "구매자",
            H: "발행일",
            I: "잔액",
            J: "수신자 전화번호",
            M: "구매경로",
            N: "유효기간",
            O: '자체판매',
            P: '사용불가',
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "발급내역-포인트권");
      // export Excel file
      XLSX.writeFile(wb, "발급내역-포인트권.xlsx");
      this.buttonDissabled = false;
    },
    getExcelData(excelBtnType) {
      this.buttonDissabled = true;
      reqPost(
        orderapi.base,
        orderapi.pointCouponissuedlist,
        this.authToken,
        {
          dateType: this.dateType,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              let ret2 = [];
              if(excelBtnType === 'company'){
                result.data.some((item,index)=>{
                  if(item.consumedDate == null){
                    var itemNoName = item.itemNo.split('');
                    var receiver = item.orderInfo.buyerName.split('');
                    let strLength;
                    itemNoName.forEach(function(i) {
                      strLength = itemNoName[i].length;
                      if(strLength < 5){
                        var textitemNo = itemNoName.join().replace(/,/gi,'');
                        var tin = textitemNo.replace(/(?<=.{3})./gi, "*");
                        item.itemNo = tin;
                        // console.log(item.itemNo)
                      }
                      if(strLength < 2){
                        var textrn = receiver.join().replace(/,/gi,'');
                        var tin2 = textrn.replace(/(?<=.{1})./gi, "*");
                        console.log(textrn)
                        item.orderInfo.buyerName = tin2;
                      }
                    });
                    ret2.push(item);
                    this.exceldata = ret2;
                    console.log(ret2)
                  }
                })
                this.downExcel();
              }else if(excelBtnType === 'noCompany'){
                result.data.some((item)=>{
                  if(item.consumedDate == null){
                    ret2.push(item);
                    this.exceldata = ret2;
                  }
                })
                this.downExcel();
              }else{
                this.exceldata = result.data;
                this.downExcel();
              }
            } else {
              this.exceldata = [];
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    getCancelStr(val) {
      return val ? "사용취소" : "";
    },
  },
};
</script>

<style scoped>

th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

</style>
