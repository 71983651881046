<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">입금정보</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">예금주</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input type="text" class="form-control" v-model="accountHolder"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">은행명</label>
        <div class="col-sm-10">
          <select class="form-control" style="width: auto;" v-model="bankCode">
            <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{ bank.bankName }}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">계좌번호</label>
        <div class="col-sm-10">
          <div class="input-group">
            <input type="text" class="form-control" v-model="accountNumber"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">입금계좌사본</label>
        <div class="col-sm-10">
          <SingleImgUpload v-model="bankbookCopyUrl" downlabel="입금계좌사본" :imgtype="this.imgtype.STORE"/>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="saveBankBook">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import {imgtype, rescode} from "@/lib/consts";
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import SingleImgUpload from "@/components/SingleImgUpload";

export default {
  data() {
      return{
        accountHolder: "", //예금주
        accountNumber: "", //계좌번호
        bankCode: "", //은행코드
        bankbookCopyUrl: "", //통장사본 URL
        banklist: [],
        storeCode:'',
      }
  },
  components:{
    SingleImgUpload,
  },
  computed:{
    imgtype() {
      return imgtype;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted(){
    this.getBankList();
    this.getbankbook();
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  methods:{
    getBankList() {
      reqGet(
        brandapi.base,
        brandapi.banklist,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.banklist = result.data.banks;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },

    getbankbook() {
      reqGet(
        brandapi.base,
        brandapi.tnjbankbook + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.accountHolder = result.data.bankbook.accountHolder;
            this.accountNumber = result.data.bankbook.accountNumber;
            this.bankCode = result.data.bankbook.bankCode;
            this.bankbookCopyUrl = result.data.bankbook.bankbookCopyUrl;
            if (this.bankbookCopyUrl == null) {
              this.bankbookCopyUrl = "";
            }
          }
        }
      );
    },

    saveBankBook() {
      var bankbook = {
        accountHolder: this.accountHolder,
        accountNumber: this.accountNumber,
        bankCode: this.bankCode,
        bankbookCopyUrl: this.bankbookCopyUrl,
      };
      reqPost(
        brandapi.base,
        brandapi.tnjbankbookupdate,
        this.authToken, {
          storeCode: this.storeCode,
          bankbook: bankbook,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert("저장되었습니다.");
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
  }

}
</script>

<style>

</style>
