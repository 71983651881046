<template>
<div class="modal" style="display:block">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">편의시설 관리
                <div class="modal-body form-horizontal">
                    <div class="form-group" style="margin:0px;">
                        <div class="row">
                            <button class="btn btn-default" @click="keyaddShow">편의시설 추가</button>
                        </div>
                        <div>
                            <div v-if="keyadd" class="keyadd row">
                                <div class="row mt-2">
                                    <div class="col-sm-2 titleBold">아이콘</div>
                                    <div class="col-sm-10">
                                        <SingleThumbnail v-if="iconadd.image != null" v-model="iconadd.image.url" :imgtype="imgtype.GOODS" :imgFile="{}" :change="iconaddchange" />
                                        <!-- {{ iconadd }} -->
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-2 titleBold">편의시설</div>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" v-model="amWordInput" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-2 titleBold">활성여부</div>
                                    <div class="col-sm-10">
                                        <label> <input class="mr-2" type="radio" :value='true' v-model="showType" />활성 </label>
                                        <label> <input class="mr-2" type="radio" :value="false" v-model="showType" />비활성 </label>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <button type="button" class="btn btn-default" @click="addIconArray">추가</button>
                                </div>
                            </div>
                            <div class="table-responsive mt-3 row">
                                <table class="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">관리코드</th>
                                            <th class="text-center">아이콘</th>
                                            <th class="text-center">편의시설</th>
                                            <th class="text-center">등록일</th>
                                            <th class="text-center">활성여부</th>
                                            <th class="text-center">수정</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iimg, iidx) in icon" :key="iidx">
                                            <td class="text-center">{{ iidx + 1 }}</td>
                                            <td class="text-center">
                                                <TableSigleImg v-model="iimg.image" :imgtype="imgtype.GOODS" :imgFile="{}" :index="iidx" style="margin: auto;" :change="onIconImage" />
                                            </td>
                                            <td class="text-center"><input type="text" class="iconinput" v-model="iimg.amenityName" ref="iconamenityName" @keyup="iconamenityName($event,iidx,iimg.amenityName)"></td>
                                            <td class="text-center">{{ viewDate(iimg.registeredDate) }}</td>
                                            <td class="text-center">
                                                <label>
                                                    {{ iimg.radio }}
                                                    <input type="radio" :v-model="iimg.enabled" :name="iimgradio + iidx" :value="true" :checked="iimg.enabled === true" @change="activeState($event,iidx)">
                                                    활성
                                                </label>
                                                <label>
                                                    <input type="radio" :v-model="iimg.enabled" :name="iimgradio + iidx" :value="false" :checked="iimg.enabled === false" @change="activeState($event,iidx)">
                                                    비활성
                                                </label>

                                            </td>
                                            <td class="text-center">
                                                <button class="noedt" ref="edtbtn" type="button" @click="amEdit(iimg.image,iimg.amenityName,iimg.enabled,iimg.id,iidx)">수정</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group text-center">
                                <button type="button" class="btn btn-default" style="margin-right:10px" @click="AmenityCloseChild">닫기</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    menuval,
    rescode,
    imgtype
} from "../../../../lib/consts";
import SingleThumbnail from "../../../../components/SingleThumbnail";
import TableSigleImg from "../../../../components/TableSigleImg"
import NewProdImg from '../../../../components/newProdImg.vue';
import {
    Drag,
    Drop
} from "vue-drag-drop";
import {
    reqGet,
    reqPost
} from "../../../../lib/request";
import {
    DateFormat
} from "../../../../lib/utility";
import {
    brandapi,
    goodsapi
} from "../../../../lib/api";

export default {

    data() {
        return {
            copyicon: [],
            keyadd: false,
            showType: false,
            iconadd: {
                image: {}
            },
            iimgradio: [],
            icon: [{
                image: "", //메인 이미지 URL
                width: 0, //메인 이미지 width
                height: 0, //메인 이미지 height
                amenityName: "",
                id: 0,
                registeredDate: "",
                enabled: false,
            }],
            amWordInput: "",

        }
    },
    components: {
        SingleThumbnail,
        NewProdImg,
        TableSigleImg,
        Drag,
        Drop
    },
    computed: {
        imgtype() {
            return imgtype;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.refresh();
    },
    methods: {
        iconamenityName(eve, idx, val) {
            this.edtBtnenble(idx);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        edtBtnenble(idx) {
            let iconamenityName = this.$refs.iconamenityName;
            let edtbtn = this.$refs.edtbtn;
            if (this.copyicon[idx].enabled !== this.icon[idx].enabled || this.copyicon[idx].amenityName !== iconamenityName[idx].value || this.copyicon[idx].image !== this.icon[idx].image) {
                edtbtn[idx].classList.add('active')
            } else {
                edtbtn[idx].classList.remove('active')
            }
        },
        activeState(val, idx) {
            if (val.target._value === 'true') {
                this.icon[idx].enabled = true;
            } else {
                this.icon[idx].enabled = false;
            }
            this.edtBtnenble(idx);
            // console.log(this.icon);
        },
        AmenityCloseChild() {
            this.$emit('AmenityClose')
        },
        keyaddShow() {
            if (this.keyadd === true) {
                this.keyadd = false;
            } else {
                this.keyadd = true;
            }
        },
        onIconImage(newval, w, h, i) {
            this.icon[i].image = newval;
            this.icon[i].width = w;
            this.icon[i].height = h;
            this.edtBtnenble(i);
        },
        iconaddchange(newval, w, h) {
            this.iconadd.image.url = newval;
            this.iconadd.image.width = w;
            this.iconadd.image.height = h;
        },
        addIconArray() {
            if (this.amWordInput === '') {
                alert('편의시설 명칭 을 적어주세요.');
                return;
            }
            reqPost(
                brandapi.base,
                brandapi.storeAmenityCreate,
                this.authToken, {
                    amenity: {
                        image: this.iconadd.image.url,
                        amenityName: this.amWordInput,
                        enabled: this.showType,
                    }
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('추가되었습니다.');
                            this.iconadd.image.url = '';
                            this.amWordInput = '';
                            this.refresh();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refresh() {
            reqGet(
                brandapi.base,
                brandapi.storeAmenitylist,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.icon = JSON.parse(JSON.stringify(result.data));
                            this.copyicon = JSON.parse(JSON.stringify(result.data));
                            // console.log(this.icon)
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        amEdit(url, amenityName, enabled, id, idx) {
            // console.log(url)
            if (url === '') {
                alert('아이콘 이미지를 추가해주세요.');
                return;
            }
            if (amenityName === '') {
                alert('편의시설 명칭 을 적어주세요.');
                return;
            }
            reqPost(
                brandapi.base,
                brandapi.storeAmenityUpdate,
                this.authToken, {
                    amenity: {
                        image: url,
                        amenityName: amenityName,
                        enabled: enabled,
                    },
                    id: id
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            let edtbtn = this.$refs.edtbtn;
                            alert('수정되었습니다.');
                            edtbtn[idx].classList.remove('active')
                            this.refresh();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
    }
}
</script>

<style scoped>
.modal {
    overflow: auto;
}

.mt-2 {
    margin-top: 20px;
}

.titleBold {
    font-weight: bold;
}

.mr-2 {
    margin-right: 10px !important;
}

.keyadd {
    border: 1px solid #dedede;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}

td {
    vertical-align: middle !important;
    font-size: 15px !important;
}

th {
    background: #eee;
    height: 50px;
    vertical-align: middle !important;
    text-align: center;
}

.noedt {
    background: #eee;
    border: 1px solid #dedede;
    padding: 10px;
    color: #b9b9b9;
}

.noedt.active {
    background: #3c8dbc;
    color: #fff;
    border: 1px solid #337ab7;
}

.iconinput {
    height: 41px;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
}

td>label {
    margin-right: 5px;
}
.mt-3{
    margin-top: 25px;
}
</style>
