<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">매장등록신청검수</h3>
        </div>
        <div class="box-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-2 control-label">매장명</label>
                <div class="col-sm-10">
                    <div class="input-group" style="display: inline-flex">
                        <input type="text" class="form-control" disabled :value="storeName" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">주소</label>
                <div class="col-sm-10" style="display: flex">
                    <div class="input-group" style="display: inline-flex; margin-right: 10px; flex: 1">
                        <input type="text" class="form-control" disabled :value="roadAddress" />
                        <input type="text" class="form-control" disabled :value="detailAddress" style="margin-left:10px;" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">업종</label>
                <div class="col-sm-10">
                    <div class="input-group" style="display: inline-flex">
                        <select class="form-control" style="width: auto" disabled v-model="storeTypeNm.typeName">
                            <option v-for="(item) in storeType" :key="item.typeName" :value="item.typeName">{{ item.typeName }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">사업자등록증</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" disabled :value="registrationNumberUrl" />
                </div>
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="goBack">취소</button>
            <button type="button" class="btn btn-default" style="margin-left: 30px" @click="cancelreview">거절</button>
            <button type="button" class="btn btn-default" style="margin-left: 10px" @click="allowstore">승인</button>
        </div>
    </div>
    <div class="modal" :style="showmodal && { display: 'block' }">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">불가 사유안내</div>
                <div class="modal-body form-horizontal">
                    <textarea class="form-control" rows="5" v-model="reason"></textarea>
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                        <button type="button" class="btn btn-default" @click="closemodal">취소</button>
                        <button type="button" class="btn btn-default" @click="denyrequest">확인</button>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval,
    imgtype
} from "../../../lib/consts";
// import SingleImgUpload from "../../../components/SingleImgUpload";
// import AddressSearch from "../../../components/AddressSearch";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    brandapi
} from "../../../lib/api";
export default {
    components: {
        // SingleImgUpload,
        // AddressSearch,
    },
    data() {
        return {
            storeRegisterId: null,
            showmodal: false,
            reason: "",
            storeName: "", //매장명
            registrationNumberUrl: "", //사업자등록번호 사본 URL
            roadAddress: "",
            detailAddress: "",
            storeType:[],
            storeTypeNm:{},
            region: "",
            city: "",
        };
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        imgtype() {
            return imgtype;
        }
    },
    created() {
        this.storeRegisterId = this.$route.query.storeRegisterId;
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_BRD,
            path2: menuval.LV2_BRD_STR,
            title: "",
        });
    },
    mounted() {
        this.refreshPage();
    },
    methods: {
        getStoreType(type) {
            reqGet(
                brandapi.base,
                brandapi.storeType,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {

                          this.storeType = result.data.types;
                          this.storeType.forEach(el => {
                            if(el.storeType === type){
                              this.storeTypeNm = el;
                            }
                          });
                          console.log(this.storeTypeNm)
                        }
                    }
                }
            );
        },
        cancelreview() {
            this.showmodal = true;
        },
        closemodal() {
            this.showmodal = false;
        },
        allowstore() {
            reqPost(
                brandapi.base,
                brandapi.newStoreapprove,
                this.authToken, {
                    storeRegisterId: this.storeRegisterId,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        denyrequest() {
            reqPost(
                brandapi.base,
                brandapi.newStoredeny,
                this.authToken, {
                    storeRegisterId: this.storeRegisterId,
                    reason: this.reason
                },
                (result) => {
                    this.closemodal();
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.$router.go(-1);
                            this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refreshPage() {
            reqGet(
                brandapi.base,
                brandapi.newStoreinfo + "?storeRegisterId=" + this.storeRegisterId,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.storeName = result.data.basicInfo.storeName; //    String    매장명
                            //this.storeType = result.data.basicInfo.storeType; //    String    매장 타입  [Buffet, Western, Korean, Chinese, Japanese, Fusion, Etc]
                            this.getStoreType(result.data.basicInfo.storeType);

                            // data.basicInfo.registrationNumber       String      사업자등록번호
                            this.registrationNumberUrl = result.data.basicInfo.registrationNumberUrl; //    String      사업자등록번호 사본 URL
                            // data.address.roadAddress                String      도로명 주소
                            this.roadAddress = result.data.address.roadAddress; //               String      지번 주소
                            this.detailAddress = result.data.address.detailAddress;
                            this.region = result.data.address.region;
                            this.city = result.data.address.city;
                          // data.address.engAddress                 String      영문 주소
                            // data.address.zipCode                    String      우편번호
                            // data.address.coordinateX                Number      x 좌표
                            // data.address.coordinateY                Number      y 좌표
                            // data.address.coordinateSystem           String      좌표계. EPSG5181: Daum Map, EPSG4326: Google Map
                            //                             [EPSG5181, EPSG4326]
                        }
                    }
                }
            );
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
</style>
