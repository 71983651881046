<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body ">
        <div class="col-sm-4">
          <ul class="nav nav-pills nav-stacked">
            <li >
              <a href="#">
                상품 등록요청
                <span class="pull-right">{{ goodnewRequests.goodsRegisterRequest | numFormat }}</span>
              </a>
            </li>
            <li >
              <a href="#">
                취소요청
                <span class="pull-right">{{ ordnewRequests.orderCancelRequest | numFormat }}</span>
              </a>
            </li>
            <li >
              <a href="#">
                예약 신청
                <span class="pull-right">{{ ordnewRequests.bookingRequest | numFormat }}</span>
              </a>
            </li>
            <li >
              <a href="#">
                증빙서류 신청
                <span class="pull-right">{{ ordnewRequests.evidentialDocumentRequest | numFormat }}</span>
              </a>
            </li>
            <li >
              <a href="#">
                콜백신청
                <span class="pull-right">{{ callBackCount | numFormat }}</span>
              </a>
            </li>
            <li >
              <a href="#">
                매장등록신청
                <span class="pull-right">{{ brdnewRequests.storeRegisterRequest | numFormat }}</span>
              </a>
            </li>
            <li >
              <a href="#">
                알림톡 등록 신청
                <span class="pull-right">{{brdnewRequests.alimtalkRegisterRequest | numFormat}}</span>
              </a>
            </li>
            <li >
              <a href="#">
                결제취소 실패
                <span class="pull-right">{{ ordnewRequests.paymentCancelFail | numFormat }}</span>
              </a>
            </li>
          </ul>
        </div>

        <div class="col-sm-8">

            <div class="box-header">
              <h3 class="box-title">고객 게시판</h3>
              <div class="box-tools pull-right">
                <a @click="goCusCenter" href="#" class="btn btn-box-tool">더보기 +</a>
              </div>
            </div>
            <div class="box-body">
              <table class="table">
                <tbody>
                  <tr v-for="(cus, cidx) in cusboard" :key="cidx">
                    <td>{{cus.title}}</td>
                    <td>{{cus.registeredBy}}</td>
                    <td v-if="!cus.completed" class="text-right py-0 align-middle">미답변</td>
                    <td v-else class="text-right py-0 align-middle"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="box-header">
              <h3 class="box-title">제휴문의</h3>
              <div class="box-tools pull-right">
                <a @click="goQues" href="#" class="btn btn-box-tool">더보기 +</a>
              </div>
            </div>
            <div class="box-body">
              <table class="table">
                <tbody>
                  <tr v-for="(ques, qidx) in quesinfs" :key="qidx">
                    <td>{{ques.companyName}}</td>
                    <td>{{ques.representative}}</td>
                    <td v-if="!ques.completed" class="text-right py-0 align-middle">회신요청</td>
                    <td v-else class="text-right py-0 align-middle"></td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="box box-solid">
          <div class="box-header">
            <div class="box-tools pull-right">
              <a @click="goDealtargt" href="#" class="btn btn-box-tool">더보기 +</a>
            </div>
          </div>
          <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th>날짜</th>
                  <th>거래내역</th>
                  <th>목표</th>
                  <th>달성 (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dayinf in sales" v-bind:key="dayinf.salesDate">
                  <td>{{ viewDay(dayinf.salesDate)}}</td>
                  <td>{{ dayinf.salesAmount | numFormat }}</td>
                  <td>{{ dayinf.targetAmount | numFormat }}</td>
                  <td>{{ percent(dayinf.salesAmount, dayinf.targetAmount) }}</td>
                </tr>
                <tr>
                  <td>합계</td>
                  <td>{{ totalsales | numFormat }}</td>
                  <td>{{ totaltarget | numFormat }}</td>
                  <td>{{ percent(totalsales, totaltarget) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import routepath from '../../lib/routes'
import { rescode, menuval } from "../../lib/consts";
import { orderapi, goodsapi, brandapi, board } from "../../lib/api";
import { reqPost, reqGet } from "../../lib/request";
import moment from 'moment';
import {DateFormat, getPercent} from "../../lib/utility";
export default {
  computed: {
    routes(){
      return routepath
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  data() {
    return {
      sales:[],
      /**
       * salesDate            String    yyyy-MM-dd 날짜
       * targetAmount         String    목표 금액
       * salesAmount          String    매출액 (구매금액)
       * cancelledAmount      String    취소금액
       * netSalesAmount       String    순매출액
       */
      currencyCode:"",
      totalsales:0,
      totaltarget:0,
      goodnewRequests:{
        goodsRegisterRequest:0,//신규 상품등록 신청 개수
      },
      ordnewRequests:{
        orderCancelRequest:0,//신규 취소요청 개수
        bookingRequest:0,//신규 예약신청 개수
        evidentialDocumentRequest:0,//신규 증빙서류 신청 개수
        paymentCancelFail:0, // 결제취소 실패 개수
      },
      brdnewRequests:{
        storeRegisterRequest:0,//신규 매장등록 신청 개수
        alimtalkRequest:0,
      },
      callBackCount:0,//콜백 신청개수
      cusboard:[],
      /**
       * questionNo           Number    문의 번호
       * title                String    제목
       * registeredDate       String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * registeredBy         String    등록자. 익명으로 글을 쓴 경우 null
       * viewedCount          Number    조회 회수
       * secreteQuestion      Boolean   비밀글 여부
       * completed            Boolean   true: 처리완료, false: 미처리
       * deleted              Boolean   삭제 여부
       * subPosts             Array     하위 문의 사항
       * subPosts[].questionNo        Number    문의 번호
       * subPosts[].title             String    제목
       * subPosts[].registeredDate    String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * subPosts[].registeredBy      String    등록자. 익명으로 글을 쓴 경우 null
       * subPosts[].viewedCount       Number    조회 회수
       * subPosts[].secreteQuestion   Boolean   비밀글 여부
       * subPosts[].completed         Boolean   true: 처리완료, false: 미처리
       * subPosts[].deleted           Boolean   삭제 여부
       */
      quesinfs:[],
      /**
       * requestNo            Number    요청 번호
       * companyName          String    회사명
       * representative       String    담당자
       * title                String    제목
       * registeredDate       String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * viewedCount          Number    조회 회수
       * completed            Boolean   true: 처리완료, false: 미처리
       */
    }
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_HOME, path2:menuval.LV1_HOME, title:""});
  },
  mounted() {
    this.getGoodNewReqCount();//상품 등록요청 얻기
    this.getOrderNewReqCount();//신규 취소요청/예약신청/증빙서류신청 건수
    this.getBrandNewReqCount();//신규 매장등록 요청건수
    this.getcallBackCount();//콜백 신청개수
    this.getCusboradinf();
    this.getQuesInf();
    this.refreshPage();
  },
  methods: {
    viewDay(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    percent(sales, target){
      return getPercent(sales, target);
    },
    getTotalinf(){
      this.totalsales = 0;
      this.totaltarget = 0;
      for(var i = 0; i<this.sales.length; i++)
      {
        if(this.sales[i].salesAmount != null)
        {
          this.totalsales += parseInt(this.sales[i].salesAmount);
        }

        if(this.sales[i].targetAmount != null)
        {
          this.totaltarget += parseInt(this.sales[i].targetAmount);
        }
      }
    },
    refreshPage() {

      var towkday = moment().weekday();
      if(towkday == 0)
      {
        towkday = 7;
      }
      towkday = towkday - 1;
      reqPost(
        orderapi.base,
        orderapi.totalizePurchasedSales,
        this.authToken,
        {
          salesFrom:moment().subtract(towkday, 'd').format("YYYY-MM-DD"),
          salesTo:moment().add(6-towkday, 'd').format("YYYY-MM-DD")
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.sales = result.data.sales;//
              this.getTotalinf();
              this.currencyCode = result.data.currencyCode;
              /*
              result.data.sales[].salesDate   String yyyy-MM-dd     //날짜
              result.data.sales[].targetAmount  String              //목표 금액
              result.data.sales[].salesAmount   String              //매출액 (구매금액)
              result.data.sales[].cancelledAmount   String          //취소금액
              result.data.sales[].netSalesAmount    String          //순매출액
              result.data.currencyCode              String  [USD, KRW]    //통화코드
              */
            }
          }
        }
      );
    },
    goCusCenter()
    {
      this.$router.push(this.routes.cuscenter);
    },
    goQues()
    {
      this.$router.push(this.routes.ques);
    },
    goDealtargt()
    {
      this.$router.push(this.routes.dealtargt);
    },
    getGoodNewReqCount()
    {
      reqGet(
        goodsapi.base,
        goodsapi.countnewRequest,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.goodnewRequests = result.data.newRequests;
            }
          }
        });
    },
    getOrderNewReqCount()
    {
      reqGet(
        orderapi.base,
        orderapi.count_newRequest,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.ordnewRequests = result.data.newRequests;
            }
          }
        });
    },
    getBrandNewReqCount()
    {
      reqGet(
        brandapi.base,
        brandapi.count_newRequest,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              console.log(result.data.newRequests)
              this.brdnewRequests = result.data.newRequests;
              //
            }
          }
        });
    },
    getcallBackCount()
    {
      reqGet(
        board.base,
        board.call_backCount,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.callBackCount = result.data.callBackCount;
            }
          }
        });
    },
    getCusboradinf() {
      reqPost(
        board.base,
        board.cusServ_page,
        this.authToken,
        {
          keywordType:"NoKeyword",
          completed:false,
          fromDate:moment().subtract(1, 'months').format("YYYY-MM-DD"),
          toDate:moment().format("YYYY-MM-DD"),
          pageNumber:0,
          pageSize:3
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.cusboard = result.data.content;
            } else {
              this.cusboard = [];
            }
          }
        }
      );
    },
    getQuesInf() {
      reqPost(
        board.base,
        board.request_page,
        this.authToken,
        {
          keywordType:"NoKeyword",
          completed:false,
          fromDate:moment().subtract(1, 'months').format("YYYY-MM-DD"),
          toDate:moment().format("YYYY-MM-DD"),
          pageNumber:0,
          pageSize:3
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.quesinfs = result.data.content;
            } else {
              this.quesinfs = [];
            }
          }
        }
      );
    },
  },
}
</script>
<style scoped>
.btn-box-tool{
  vertical-align: -webkit-baseline-middle;
}
</style>
