<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label">제목</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" v-model="title"/>
          </div>
        </div>
        <div class="form-group" v-if="faqNo > 0">
          <label class="col-sm-3 control-label">작성자</label>
          <div class="col-sm-9 ">{{registeredBy}}</div>
        </div>
        <div class="form-group" v-if="faqNo > 0">
          <label class="col-sm-3 control-label">등록일</label>
          <div class="col-sm-9">{{viewDate(registeredDate)}}</div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">공지</label>
          <div class="col-sm-9 radio">
            <label> <input type="radio" :value="true" v-model="published"/>적용 </label>
            <label> <input type="radio" :value="false" v-model="published"/>적용안함 </label>
          </div>
        </div>
        <div class="form-group">
          <label class="hidden-xs col-sm-3 control-label"></label>
          <div class="col-sm-9">
            <!-- <editor v-model="pcheadText" :options="editorOptions"/> -->
            <editor
              api-key="02p3raa9urge93k9p07ljuvfbkovzy2wrad7dprwav8xe682"
              :init="{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'help | code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',

              }"
              v-model="contents"
            />
          </div>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="goBack">
            뒤로
          </button>
          <button type="button" class="btn btn-default" @click="doRegister">저장</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import { reqPost } from "../../lib/request";
import { board } from "../../lib/api";
import { DateFormat } from "../../lib/utility";

// import 'codemirror/lib/codemirror.css';
// import '@toast-ui/editor/dist/toastui-editor.css';
// import '@toast-ui/editor/dist/i18n/ko-kr';
// import { Editor } from '@toast-ui/vue-editor';
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    editor: Editor,
  },
  created() {
    this.faqNo = this.$route.query.faqNo;
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BOD,
      path2: menuval.LV1_HOME,
      title: "",
    });
  },
  data() {
    return {
      pcheadText: "",
      editorOptions: {
        minHeight: "200px",
        language: "ko-KR",
        useCommandShortcut: true,
        useDefaultHTMLSanitizer: true,
        usageStatistics: true,
        hideModeSwitch: false,
        toolbarItems: [
          "heading",
          "bold",
          "italic",
          "strike",
          "divider",
          "hr",
          "quote",
          "divider",
          "ul",
          "ol",
          "task",
          "indent",
          "outdent",
          "divider",
          "table",
          "image",
          "link",
          "divider",
          "code",
          "codeblock",
        ],
      },
      faqNo:0,//
      title:"",//제목
      contents:"",//내용
      published:false,//발행여부: true: 발행됨, false: 발행안됨

      registeredDate:"",//UTC yyyy-MM-ddTHH:mm:ss 등록일
      registeredBy:"",//등록자
      viewedCount:0,//조회 회수
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    if(this.faqNo != 0)
    {
      this.refreshPage();
    }
    tinymce.activeEditor.getBody().setAttribute('contenteditable', true)
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    refreshPage() {
      reqPost(
        board.base,
        board.faq_detail,
        this.authToken,
        {
          faqNo:this.faqNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.title = result.data.title;//제목
              this.contents = result.data.contents;//내용
              this.published = result.data.published;//발행여부: true: 발행됨, false: 발행안됨
              this.registeredDate = result.data.registeredDate;//UTC yyyy-MM-ddTHH:mm:ss 등록일
              this.registeredBy = result.data.registeredBy;//등록자
              this.viewedCount = result.data.viewedCount;//조회 회수
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    goBack(){
      this.$router.go(-1);
    },
    doRegister()
    {
      this.$store.dispatch('setDisabledLayout');
      if(this.faqNo == 0)
      {
        reqPost(
          board.base,
          board.faq_add,
          this.authToken,
          {
            title:this.title,
            contents:this.contents,
            published:this.published,
          },
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }else{
        reqPost(
          board.base,
          board.faq_update,
          this.authToken,
          {
            faqNo:this.faqNo,
            title:this.title,
            contents:this.contents,
            published:this.published,
          },
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    }
  },
};
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.radio > label {
  margin-right: 10px;
}

.checkbox > label {
  margin-right: 10px;
}
</style>
