<template>
<div>
    <section class="content">
        <div class="box box-solid">
            <div class="box-body">
                <div class="row" style="margin:0px;margin-bottom:20px">
                    <select class="form-control pull-right" style="width:70px" v-model="pageSize" @change="refreshPage">
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                        <option>35</option>
                        <option>40</option>
                        <option>45</option>
                    </select>
                </div>

                <div class="box box-solid">
                    <div class="box-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>쿠폰코드</th>
                                    <th>쿠폰유형</th>
                                    <th>쿠폰명</th>
                                    <th>요청자</th>
                                    <th>사이트노출</th>
                                    <th>생성일</th>
                                    <th>발급내역</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="(copun, index) in content" :key="index">
                                    <td>{{ startnum + 1 + index }}</td>
                                    <td>{{copun.couponId}}</td>
                                    <td> {{ copun.basicInfo.couponType | changeText }} </td>
                                    <td class="linkcmp" @click="goDetailPage(copun.couponId)"> {{ copun.basicInfo.couponName }} </td>
                                    <td> {{ copun.requestedBy }} </td>
                                    <td class="linkcmp" @click="onChangeuseFlag(copun.couponId, copun.enabled)">
                                        <ToggleBtn v-model="copun.enabled" />
                                    </td>
                                    <td>{{viewDate(copun.registeredDate)}}</td>
                                    <td>
                                        <button class="btn btn-defalt" @click="issuedView(copun.couponId)">보기</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="box-footer text-center">
                        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
                    </div>
                </div>

            </div>
        </div>

        <div class="modal" :style="showmodal && {'display': 'block'}">
            <div class="modal-dialog">
                <div class="modal-content">

                    <div class="modal-header border-bottom-0">
                        <h3>
                            발급내역보기
                        </h3>
                    </div>
                    <div class="box-body">
                        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                            <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="changeSelect">
                                <option value="NoKeyword"></option>
                                <option value="IssueDate">발급일</option>
                                <option value="UseDate">사용일</option>
                                <option value="CouponNumber">쿠폰번호</option>
                            </select>
                            <div class="input-group" style="display: inline-flex" v-if="keywordType !== 'CouponNumber'">
                                <DatePicker v-model="start" />
                                <span class="input-group-addon" style="border:none;display:inline-flex">-</span>
                                <DatePicker v-model="end" />
                                <span class="input-group-btn">
                                    <button type="button" class="btn btn-default" @click="issuedHistory">검색</button>
                                </span>
                            </div>
                            <div class="input-group" style="flex:1;max-width:400px;min-width:100px" v-else>
                                <input type="text" class="form-control" v-model="keyword" @change="issuedHistory" />
                                <span class="input-group-btn">
                                    <button type="button" class="btn btn-default" @click="issuedHistory">검색</button>
                                </span>
                            </div>
                        </div>
                        <div class="row" style="margin:0px;margin-bottom:20px">
                            <select class="form-control pull-right" style="width: 70px;" v-model="pageSize2" @change="issuedHistory">
                                <option>5</option>
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                                <option>35</option>
                                <option>40</option>
                                <option>45</option>
                            </select>
                            <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="downExcel">
                                <i class="fa fa-download"></i>Excel
                            </button>
                        </div>
                        <div class="box box-solid">
                            <div class="box-body table-responsive p-0">
                                <table class="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>번호</th>
                                            <th>쿠폰코드</th>
                                            <th>쿠폰명</th>
                                            <th>유효기간</th>
                                            <th>ID</th>
                                            <th>발급일</th>
                                            <th>사용일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(copun, index) in content2" :key="index">
                                            <td> {{  totalElements2 - (startnum2 + index)  }}</td>
                                            <td> {{ copun.couponNumber }} </td>
                                            <td> {{ copun.couponName }} </td>
                                            <td> {{ copun.availableFromDate === null ? copun.availableToDate : copun.availableFromDate + '~' + copun.availableToDate}} </td>
                                            <td> {{ copun.userName }} </td>
                                            <td> {{ viewDate(copun.issueDate) }} </td>
                                            <td class="linkcmp" v-if="copun.useDate"  @click="gode(copun.couponNumber)"> {{ viewDate(copun.useDate) }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="box-footer text-center">
                                <Pagination :curpage="pageNumber2" :totalpages="totalPages2" :visibles="shownum2" :click="gotoPage2" />
                            </div>
                        </div>
                        <div class="modal-footer" style="text-align:center !important">
                            <button type="button" class="btn btn-default" @click="closemodal">닫기</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </section>
</div>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import {
    DateFormat
} from "../../lib/utility";
import routepath from "../../lib/routes";
import ToggleBtn from "../../components/ToggleBtn";
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker";
import XLSX from "xlsx";
import {
    orderapi,
    discoutCopon,
} from "../../lib/api";
import {
    reqGet,
    reqPost
} from "../../lib/request";

export default {
    components: {
        ToggleBtn,
        Pagination,
        DatePicker
    },
    filters: {
        changeText(val) {
            if (val === 'GENERAL') {
                return '일반형';
            } else if (val === 'BULK') {
                return '벌크형'
            } else if (val === 'MEMBERSHIP') {
                return '회원가입형'
            } else if (val === 'INPUT') {
                return '입력형'
            }
        }
    },
    data() {
        return {
            start: '',
            end: '',
            shownum: 10, //pagination에 보여지는 번호개수
            shownum2: 10,
            keywordType: "NoKeyword",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageNumber2: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            pageSize2: 20,
            content: [],
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            showmodal: false,
            totalPages2: 0, //전체 페이지 수
            totalElements2: 0, //전체 아이템 수
            numberOfElements2: 0, //현재 조회된 아이템 수
            showmodal: false,
            content2: [{
                index: '1',
                copunNum: '111',
                copunName: '쿠폰이름',
                validity: '유효기간',
                id: '유저아이디',
                issueDay: '발행일',
                useDay: '사용일',
            }],
            excelData:[],
            id: '',
        }
    },
    computed: {
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
        startnum2() {
            return (this.pageNumber2 - 1) * this.pageSize2;
        },
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_DIS,
            path2: menuval.LV2_DIS,
            title: "할인 쿠폰 리스트",
        });
    },

    mounted() {
        this.refreshPage(false);
    },
    methods: {
        gode(couponnum) {
            // let cop = couponnum.replace("", "");
            reqPost(
                orderapi.base,
                orderapi.orderNoByCouponNumber,
                this.authToken, {
                    discountCouponNumber: couponnum,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.$router.push(this.routes.detailorder + "?orderNo=" + result.data.orderNo);
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );

        },
        changeSelect(e) {
            console.log(e.srcElement.value === 'couponNum');
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage();
        },
        gotoPage2(pnum) {
            this.pageNumber2 = pnum;
            this.issuedHistory();
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqGet(
                discoutCopon.base,
                discoutCopon.coupon_list +
                "?pageNumber=" +
                pageNumber +
                "&pageSize=" +
                this.pageSize2,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.content = data.data.content;
                            this.totalPages = data.data.totalPages; //전체 페이지 수
                            this.totalElements = data.data.totalElements; //전체 아이템 수
                            // this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            // this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        // this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        onChangeuseFlag(copunCode, useFlag) {
            let apiNm = '';
            if (!useFlag) {
                apiNm = discoutCopon.enable;
            } else {
                apiNm = discoutCopon.disable;
            }
            reqPost(
                discoutCopon.base,
                apiNm,
                this.authToken, {
                    couponId: copunCode,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );

        },
        goDetailPage(id) {
            this.$router.push(this.routes.discountAddPage + "?id=" + id + '&approval=true');
        },
        issuedView(id) {
            this.showmodal = true;
            this.issuedHistory(false, id);
            this.GetexcelData();
        },
        closemodal() {
            this.showmodal = false;
        },
        issuedHistory(flag, id) {
            var pageNumber2 = 0;
            if (!flag) {
                pageNumber2 = this.pageNumber2 - 1;
            } else {
                this.pageNumber2 = 1;
            }
            if (id !== undefined) {
                this.id = id;
            }
            let date = '';
            if(this.keywordType === 'IssueDate' || this.keywordType ===  'UseDate'){
                date = '&dateFrom=' + this.start + "&dateTo=" + this.end;
            }else{
                date = '';
            }
            reqGet(
                discoutCopon.base,
                discoutCopon.itemPage +
                "?couponId=" +
                this.id +
                "&keywordType=" +
                this.keywordType +
                "&keyword=" +
                this.keyword +
                date +
                "&pageNumber=" +
                pageNumber2 +
                "&pageSize=" +
                this.pageSize2,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.content2 = data.data.content;
                            this.totalPages2 = data.data.totalPages; //전체 페이지 수
                            this.totalElements2 = data.data.totalElements; //전체 아이템 수
                            // this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content2 = [];
                            this.totalPages2 = 0; //전체 페이지 수
                            this.totalElements2 = 0; //전체 아이템 수
                            // this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.content2 = [];
                        this.totalPages2 = 0; //전체 페이지 수
                        this.totalElements2 = 0; //전체 아이템 수
                        // this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        GetexcelData(){
            //discoutCoponList
            reqGet(
                discoutCopon.base,
                discoutCopon.discoutCoponList +
                "?couponId=" +
                this.id +
                "&keywordType=" +
                this.keywordType +
                "&keyword=" +
                this.keyword,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.excelData = data.data;
                        } else {
                            this.excelData = [];
                            this.excelData = [];
                        }
                    } else {
                        this.excelData = [];
                    }
                }
            );
        },
        downExcel() {
            var pokemonWS = XLSX.utils.json_to_sheet(
                [{
                    A: "번호",
                    B: "쿠폰번호",
                    C: "쿠폰명",
                    D: "유효기간",
                    E: "ID",
                    F: "발급일",
                    G: "사용일",
                }, ], {
                    skipHeader: true
                }
            );
            XLSX.utils.sheet_add_json(pokemonWS, this.excelData.map(this.convert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, pokemonWS, "발급내역");
            // export Excel file
            XLSX.writeFile(wb, "발급내역.xlsx");
        },
        convert(item) {
            return {
                A: item.itemNo,
                B: item.couponNumber,
                C: item.couponName,
                D: item.availableFromDate === null ? item.availableToDate : item.availableFromDate + '~' + item.availableToDate,
                E: item.userName,
                F: this.viewDate(item.issueDate),
                G: this.viewDate(item.useDate),
            };
        },
    }
}
</script>

<style scoped>
th {
    background-color: #dee2e685;
    text-align: center;
}

td {
    text-align: center;
}
</style>
