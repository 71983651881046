<template>
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <h1>
          {{ title }}
          <a v-show="path==='prdcupon'"
             class="fa fa-check-circle-o aFontStyles"
             id="customCoupon"
             data-html="true"
             data-toggle="popover"
             role="button"
             data-trigger="click"
             data-placement="right"
             title="<h4 class='popTitle'>브랜드별 커스텀 정보</h4>"
             :data-content="customContentHtml"
          >
          </a>

        </h1>
        <!-- <ol class="breadcrumb">
          <li>
            <a href="javascript:;">
              <i class="fa fa-home"></i>Home</a>
          </li>
          <li class="active">{{$route.name.toUpperCase()}}</li>
        </ol> -->
    </section>

    <!-- /.content-header -->
</template>

<script>
export default {
    name: 'ContentHeader',
    components: {},
    props: {},
    computed: {
        title(){
            return this.$store.getters.title;
        },
        path(){
          return this.$store.getters.curpath;
        }
    },
    mounted() {
      this.getCustomTable();

    },
    data(){
      return {
        customCode : [
          {brandName:'브랜드명',brandCode:'브랜드코드',position:'위치',type:'타입'},
          {brandName:'서울랜드F&B',brandCode:'B765527100',position:'앞',type:'고정, 1200'},
          {brandName:'호박식당',brandCode:'B438227463',position:'앞',type:'고정, 1200'},
          {brandName:'한와담',brandCode:'B663069842',position:'앞',type:'고정, 1200'},
          {brandName:'한와담 이재',brandCode:'B012072910',position:'앞',type:'고정, 1200'},
          {brandName:'에이치애비뉴',brandCode:'B192637001',position:'앞',type:'고정, 1200'},
          {brandName:'양파이',brandCode:'B566967607',position:'앞',type:'고정, 1200'},
          {brandName:'초록생돈',brandCode:'B193569342',position:'앞',type:'고정, 1200'},
          {brandName:'크래머리',brandCode:'B152715262',position:'앞',type:'고정, 1200'},
          {brandName:'대게나라',brandCode:'B3192783',position:'앞',type:'고정, 2300'},
          // 추가 브랜드 커스텀시 여기만 추가하면 됩니다.-kevin-
        ],
        customContentHtml:'<table class="table table-bordered"><tbody>'
      }
    },
  beforeUpdate() {
    $(function(){
      // Enables popover
      $("[data-toggle=popover]").popover();
    });
    if(this.path!='prdcupon'){
      $("[data-toggle=popover]").popover("destroy");
    }
  },
  methods:{
      getCustomTable(){
        let strTrst = '<tr>'
        let strTred = '</tr>'
        let strTdst = '<td>'
        let strTdstct = '<td class="text-center">'
        let strTded = '</td>'
        this.customCode.forEach((item,idx)=>{
          if(idx==0){
            strTdst = '<td style="font-weight: bold">'
            strTdstct = '<td style="font-weight: bold" class="text-center">'
          }else{
            strTdst = '<td>'
            strTdstct = '<td class="text-center">'
          }
          let lines = strTdst + item.brandName + strTded + strTdst + item.brandCode + strTded + strTdstct + item.position + strTded + strTdstct + item.type + strTded
          this.customContentHtml +=  strTrst + lines + strTred
        })
        this.customContentHtml += '</tbody></table>'

      }
    }
}
</script>
<style >
.aFontStyles{
  color: black;
  text-decoration: none;
}
a:hover { color: black; text-decoration: none;}
.popover{
  max-width:500px;
  min-width:450px;
}
.popover-content {
  max-height:900px;
}
.popTitle{
  font-weight: bold;
  margin-top:0px;
  margin-bottom:0px;
}

</style>
