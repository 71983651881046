<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <colgroup>
                    <col width="200px">
                    <col width="200px">
                    <col width="45%">
                    <col width="100px">
                    <col width="45%">
                </colgroup>
                <tbody>
                    <tr>
                        <th>아이디</th>
                        <td colspan="4">{{ viewid }}</td>
                    </tr>
                    <tr>
                        <th>이름</th>
                        <td colspan="4">{{ fullname }}</td>
                    </tr>
                    <tr>
                        <th>휴대폰</th>
                        <td colspan="4">{{ phoneview(phonenum) }}</td>
                    </tr>
                    <tr>
                        <th>이메일</th>
                        <td colspan="4">{{ email }}</td>
                    </tr>
                    <tr>
                        <th>마케팅 수신동의</th>
                        <th>이메일 수신동의</th>
                        <td>{{ isEmailMarketing }}</td>
                        <th>SMS 수신동의</th>
                        <td>{{ isSmsMarketing }}</td>
                    </tr>
                    <tr>
                        <th>개인정보 유효기간</th>
                        <td colspan="4">
                            <div class="radio">
                                <label> <input type="radio" :value="1" v-model="userDataRetentionPeriod" />1년 </label>
                                <label> <input type="radio" :value="3" v-model="userDataRetentionPeriod" />3년</label>
                                <label> <input type="radio" :value="5" v-model="userDataRetentionPeriod" />5년 </label>
                                <label> <input type="radio" :value="0" v-model="userDataRetentionPeriod" />탈퇴시 </label>
                            </div>
                        </td>
                    </tr>
                <tr>
                  <th>앱카드 로그인</th>
                  <td colspan="4">{{ appCardUse? '사용중' : '미사용'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">리워드 적립</h3>
        </div>
        <div class="box-body form-horizontal">
            <div class="row">
                <div class="col-sm-5">
                    <div class="form-group row">
                        <label for="addvolum" class="col-sm-2 control-label text-right">적립금액</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" id="addvolum" v-model="addvolum" placeholder="적립금액" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <div class="form-group row">
                        <label class="col-sm-2 control-label text-right">내용</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="content" placeholder="내용" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-2">
                    <button type="button" class="btn btn-default" @click="doSave_point">적립하기</button>
                </div>
            </div>
        </div>
    </div>
    <div class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">리워드 내역</h3>
        </div>
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th colspan="3">잔여 리워드</th>
                        <th colspan="3">{{leftpoint | numFormat}}</th>
                    </tr>
                    <tr>
                        <th>번호</th>
                        <th>상태</th>
                        <th>리워드</th>
                        <th>내역</th>
                        <th>일자</th>
                        <th>만료일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pdata, pidx) in pointhis" :key="pidx">
                        <td>{{pidx+1}}</td>
                        <td>{{getPTypeStr(pdata.historyType)}}</td>
                        <td v-if="pdata.historyType === 'Reward' || pdata.historyType === 'UsedCancel'">{{pdata.point | numFormat}}</td>
                        <td v-else>-{{pdata.point | numFormat}}</td>
                        <td>{{pdata.description}}</td>
                        <td>{{viewDate(pdata.registerDate)}}</td>
                        <td>{{pdata.expireDate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">할인쿠폰 발급내역</h3>
        </div>
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>쿠폰번호</th>
                        <th>쿠폰명</th>
                        <th>발급일</th>
                        <th>유효기간</th>
                        <th>사용일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(udata, pidx) in userCouponArr" :key="pidx">
                        <td> {{ pidx+1 }} </td>
                        <td> {{ udata.couponNumber }} </td>
                        <td> {{ udata.couponName }} </td>
                        <td> {{ viewDate(udata.issueDate) }} </td>
                        <td>
                            <span> {{ udata.availableFromDate }} </span>
                            ~
                            <span> {{ udata.availableToDate !== null ? udata.availableToDate:'' }} </span>
                        </td>
                        <td> {{ viewDate(udata.useDate) }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="col-12 text-center">
        <button type="button" class="btn btn-default" style="margin-right:10px" @click="goBack">취소</button>
        <button type="button" class="btn btn-success" @click="clickDel">회원삭제</button>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    adminapi,
    payment,
    discoutCopon
} from "../../lib/api";
import {
    formatPhone,
    DateFormat
} from "../../lib/utility";
export default {
    data() {
        return {
            userCouponArr:[],
            viewid: "", //관리자 ID
            fullname: "", //관리자 이름
            phonenum: "", //휴대폰 번호
            email: "", //관리자 이메일
            consents: [], //수신동의
            addvolum: "0", //적립금액
            content: "", //적립 내용
            leftpoint: 0,
            pointhis: [],
            /**
             * historyType        String             [Reward, Used, UsedCancel, RewardCancel, Expired] 포인트구분
             * point              Number             포인트
             * description        String             적립내역
             * registerDate       LocalDateTime      yyyy-MM-ddTHH:mm:ss   적용일
             * expireDate         LocalDate          yyyy-MM-dd   만료일
             */
            userDataRetentionPeriod: 0, //개인정보 유효기간
            appCardUse: false,
        };
    },
    created() {
        this.viewid = this.$route.query.viewid;
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_MEM,
            path2: menuval.LV1_HOME,
            title: "회원상세정보",
        });
    },
    mounted() {
        this.refreshPage();
        this.userCouponLoad();
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        isEmailMarketing() {
            return this.consents.findIndex((element) => element == "ConsentToEmailMarketing") < 0 ? "거부" : "동의";
        },
        isSmsMarketing() {
            return this.consents.findIndex((element) => element == "ConsentToSmsMarketing") < 0 ? "거부" : "동의";
        }
    },
    methods: {
        userCouponLoad() {
            reqGet(
                discoutCopon.base,
                discoutCopon.userCoupon + "?userName=" + this.viewid,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.userCouponArr = result.data.list
                        } else {

                        }
                    } else {

                    }
                }
            );
        },
        cancel() {
            this.$router.push("/" + this.routes.genmem);
        },
        clickDel() {
            reqPost(
                adminapi.base,
                adminapi.userunregister,
                this.authToken, {
                    username: this.viewid
                },
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            // this.$router.push("/"+this.routes.genmem);
                            this.$router.go(-1);
                        }
                    }
                }
            );
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        phoneview(val) {
            return formatPhone(val);
        },
        refreshPage() {
            reqGet(
                adminapi.base,
                adminapi.userdetail + "?username=" + this.viewid,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.fullname = result.data.fullName; //관리자 이름
                            this.phonenum = result.data.phoneNumber; //전화번호
                            this.email = result.data.email; //이메일
                            this.consents = result.data.consents; // Array [ConsentToTermsOfUse, ConsentToPrivacyPolicy, ConsentToDisclosureTo3rdParty, ConsentToEmailMarketing, ConsentToSmsMarketing]
                            /*
                            사용자 약관 동의 항목 리스트
                            ConsentToTermsOfUse:이용약관 동의
                            ConsentToPrivacyPolicy: 개인정보 수집 및 이용에 대한 동의
                            ConsentToDisclosureTo3rdParty: 개인정보 3자제공 동의
                            ConsentToEmailMarketing: 이메일 마케팅 수신 동의
                            ConsentToSmsMarketing: SMS 마케팅 수신 동의
                             */
                            /*
                            data.username     String  관리자 ID
                            data.consentDate  String  UTC yyyy-MM-ddTHH:mm:ss 사용자 약관 동의 일자
                            data.registeredDate String  UTC yyyy-MM-ddTHH:mm:ss 등록일
                             */
                            this.userDataRetentionPeriod = result.data.userDataRetentionPeriod;
                            this.appCardUse = result.data.appCardUse;
                            this.getPointLeft();
                            this.getPointHis();
                        } else {
                            this.fullname = ""; //관리자 이름
                            this.phonenum = ""; //전화번호
                            this.email = ""; //이메일
                            this.consents = [];
                        }
                    } else {
                        this.fullname = ""; //관리자 이름
                        this.phonenum = ""; //전화번호
                        this.email = ""; //이메일
                        this.consents = [];
                    }
                }
            );
        },
        goBack() {
            this.$router.go(-1);
        },
        doSave_point() {
            this.$store.dispatch('setDisabledLayout');
            reqPost(
                payment.base,
                payment.point_save,
                this.authToken, {
                    userName: this.viewid, //유저아이디
                    description: this.content, //처리구분
                    point: this.addvolum, //적립포인트
                    // orderNumber:"",//등록 주문번호
                    // registerIP:"",//등록 IP
                },
                (result) => {
                    if (result != null) {
                        this.$store.dispatch('setDisabledLayout');
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = "";
                            this.addvolum = 0;
                            this.refreshPage();
                        } else {
                            alert(result.data.errors[0].message);
                        }
                    }
                }
            );
        },
        getPointLeft() {
            reqGet(
                payment.base,
                payment.point_left + "?userName=" + this.viewid,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.leftpoint = result.data.point; //관리자 이름
                        }
                    }
                }
            );
        },
        getPointHis() {
            reqGet(
                payment.base,
                payment.point_list + "?userName=" + this.viewid,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.pointhis = result.data; //관리자 이름
                        }
                    }
                }
            );
        },
        getPTypeStr(val) {
            if (val == "Reward") {
                return "적립";
            } else if (val == "Used") {
                return "사용";
            } else if (val == "RewardCancel") {
                return "적립취소";
            } else if (val == "UsedCancel") {
                return "사용취소";
            } else if (val == "Expired") {
                return "소멸";
            }
            return val;
        }
    }
};
</script>

<style scoped>
th {
    background-color: #dee2e685;
}

.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}
</style>
