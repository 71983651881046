<template>
<div>
    <section class="content">
        <div class="box box-solid">
            <div class="box-body">
                <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                    <DatePicker v-model="dateFrom" :last="dateTo" />
                    <h4 style="margin: 5px 10px">~</h4>
                    <DatePicker v-model="dateTo" :first="dateFrom" style="margin-right: 10px" />
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
                        전월
                    </button>
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
                        당월
                    </button>
                    <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                </div>
            </div>
        </div>
        <div class="row" style="margin:0px;margin-bottom:20px">
            <div class="radio" style="margin: 0px">
                <label><input type="radio" value="UsedCoupon" v-model="sortbase" />사용기준</label>
                <label><input type="radio" value="CancelledCoupon" v-model="sortbase" />취소기준</label>
            </div>
        </div>
        <div class="box box-solid">
            <div class="box-body form-horizontal">
                <div class="form-group">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>쿠폰명</th>
                                    <th>매수</th>
                                    <th>금액</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(issuVal, issuidx) in issuData" :key="issuidx">
                                    <td class="linkcmp" @click="setviewData(issuVal.discountCouponName,false)">
                                        {{issuVal.discountCouponName}}
                                    </td>
                                    <td>
                                        {{ issuVal.discountCouponCount | numFormat }}
                                    </td>
                                    <td>
                                        {{ issuVal.discountCouponAmount | numFormat }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="box-footer text-center">
                        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin:0px;margin-bottom:20px" v-if="moreContent">
            <div class="form-group">
                <span>상세 내역</span>
                <!-- <button type="button" class="btn btn-default pull-right" @click="DetaildownExcel">
                    <i class="fa fa-download"></i>Excel
                </button> -->
            </div>
        </div>

        <div class="box box-solid" v-if="moreContent">
            <div class="box-body form-horizontal">
                <div class="form-group">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>날짜</th>
                                    <th>매수</th>
                                    <th>금액</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mitem, didx) in moreSetData" :key="didx">
                                    <td>
                                        {{ mitem.discountCouponUseDate }}
                                    </td>
                                    <td>
                                        {{ mitem.discountCouponCount | numFormat }}
                                    </td>
                                    <td>
                                        {{ mitem.discountCouponAmount | numFormat }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="box-footer text-center">
                        <Pagination :curpage="pageNumber2" :totalpages="totalPages2" :visibles="shownum2" :click="gotoPage2" />
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import {
    DateFormat
} from "../../lib/utility";
import routepath from "../../lib/routes";
import ToggleBtn from "../../components/ToggleBtn";
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker";
import XLSX from "xlsx";
import moment from 'moment';
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    orderapi,
} from "../../lib/api";

export default {
    components: {
        ToggleBtn,
        Pagination,
        DatePicker
    },
    data() {
        return {
            shownum: 10,
            shownum2: 10,
            moreContent: false,
            dateFrom: moment().format("YYYY-MM-01"),
            dateTo: moment().format("YYYY-MM-DD"),
            issuData: [{
                create: '500',
                issuance: '300',
                name: 'T 우주패스 발급쿠폰',
            }],
            moreSetData: [],
            sortbase: "UsedCoupon",
            pageNumber: 1, //현재 페이지 번호
            pageNumbe2: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            pageSize2: 20, //페지당 개수
            content: [],
            totalPages: 0, //전체 페이지 수
            totalPages2: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            totalElements2: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            numberOfElements2: 0, //현재 조회된 아이템 수
            keywordType: "NoKeyword",
            keyword: "",
            pageNumber2: 1, //현재 페이지 번호
        }
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_STAT,
            path2: menuval.LV2_STS_ISS,
            title: "할인쿠폰 사용현황조회",
        });
    },
    mounted() {
        this.refreshPage(false);
    },
    methods: {
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage();
        },
        gotoPage2(pnum) {
            this.pageNumber2 = pnum;
            this.setviewData();
        },
        onPrvMonth() {
            this.dateFrom = moment().subtract(1, 'months').format('YYYY-MM-01');
            this.dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        },
        onNowMonth() {
            this.dateFrom = moment().format("YYYY-MM-01");
            this.dateTo = moment().format("YYYY-MM-DD");
        },
        refreshPage(flag) {
            console.log(this.authToken)
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqGet(
                orderapi.base,
                orderapi.discoutCoponQuery +
                "?dateFrom=" +
                this.dateFrom +
                "&dateTo=" +
                this.dateTo +
                "&searchType=" +
                this.sortbase +
                "&pageNumber=" +
                pageNumber +
                "&pageSize=" +
                this.pageSize,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.issuData = data.data.content;
                            this.totalPages = data.data.totalPages; //전체 페이지 수
                            this.totalElements = data.data.totalElements; //전체 아이템 수
                            // this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.issuData = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            // this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.issuData = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        // this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        setviewData(discountCouponName,flag) {
            var pageNumber2 = 0;
            if (!flag) {
                pageNumber2 = this.pageNumber2 - 1;
            } else {
                this.pageNumber2 = 1;
            }
            reqGet(
                orderapi.base,
                orderapi.dailyPage +
                "?dateFrom=" +
                this.dateFrom +
                "&dateTo=" +
                this.dateTo +
                "&searchType=" +
                this.sortbase +
                "&pageNumber=" +
                pageNumber2 +
                "&pageSize=" +
                this.pageSize2 +
                "&discountCouponName=" +
                encodeURIComponent(discountCouponName),
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.moreContent = true;
                            this.moreSetData = [];
                            this.moreSetData = data.data.content;
                            this.totalPages2 = data.data.totalPages; //전체 페이지 수
                            this.totalElements2 = data.data.totalElements; //전체 아이템 수
                            // this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.moreSetData = [];
                            this.totalPages2 = 0; //전체 페이지 수
                            this.totalElements2 = 0; //전체 아이템 수
                            // this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.moreSetData = [];
                        this.totalPages2 = 0; //전체 페이지 수
                        this.totalElements2 = 0; //전체 아이템 수
                        // this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
    }
}
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

th {
    text-align: center;
}

td {
    text-align: center;
}
</style>
