<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <div class="input-group radio">
            <label>
              <input type="radio" value="All" v-model="partnerStatus"/>전체
            </label>
            <label>
              <input type="radio" value="RegisterRequest" v-model="partnerStatus"/>입점신청
            </label>
            <label>
              <input type="radio" value="Reject" v-model="partnerStatus"/>심사반려
            </label>
            <label>
              <input type="radio" value="Approve" v-model="partnerStatus"/>입점완료
            </label>
            <label>
              <input type="radio" value="Cancel" v-model="partnerStatus"/>신청취소
            </label>
          </div>
          <DetePicker v-model="dateFrom" :last="dateTo" />
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px" />
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px " @change="refreshPage">
            <option value="NoKeyword">검색타입</option>
            <option value="ChannelNameContaining">채널 이름</option>
            <option value="ChannelId">채널 검색 아이디</option>
            <option value="BrandCode">브랜드코드</option>
            <option value="BrandNameContaining">브랜드명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
            <span class="input-group-btn">
                <button type="button" class="btn btn-default" @click="refreshPage" style="margin-bottom: 6px">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="goReqKakaoReserve">
        입점신청
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">채널이름</th>
            <th class="text-center">채널 아이디</th>
            <th class="text-center">브랜드</th>
            <th class="text-center">연동신청 매장</th>
            <th class="text-center">신청자</th>
            <th class="text-center">신청일시</th>
            <th class="text-center">상태</th>
            <th class="text-center">완료일시</th>
            <th class="text-center">신청정보</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(brand, index) in content" v-bind:key="brand.brandCode">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{brand.channelName}}</td>
            <td class="text-center">{{brand.channelId}}</td>
            <td class="text-center">{{brand.brandName}}</td>
            <td class="text-center item-">
              <span v-for="(item, idx) in brand.storeNames" :key="idx">{{item}} <br> </span>
            </td>
            <td class="text-center">{{registerBy(brand.registerBy)}}</td>
            <td class="text-center">{{viewDate(brand.registerRequestDate)}}</td>
            <td v-if="brand.registerStatus =='Reject' || brand.rejectReason" class="linkcmp text-center" @click="openRejectReason(brand.rejectReason)">{{ convertRegisterStatus(brand.registerStatus) }}</td><!--이거 convert-->
            <td v-else class="text-center" >{{ convertRegisterStatus(brand.registerStatus) }}</td><!--이거 convert-->
            <td class="text-center">{{viewDate(brand.approveDate)}}</td>
            <td class="text-center">
              <button class="btn btn-default" @click="goKakaoReserveDetail(brand.id)" >상세</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
      </div>
    </div>
    <div class="modal"  v-if="rejectReasonToggle" style="display: block; ">
      <div class="modal-dialog modal-xl" style="width: 50%;">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">심사반려 사유</div>
          <div class="modal-body form-horizontal">
            <textarea disabled style="width: 100%" rows="7" v-model="rejectReason">

            </textarea>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default margin-r-5" @click="closeRejectReason">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  rescode,
  menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {
  reqGet,reqPost
} from "../../../lib/request";
import {
  brandapi
} from "../../../lib/api";
import {
  DateFormat
} from "../../../lib/utility";
import XLSX from "xlsx";
import DetePicker from "@/components/DatePicker.vue";
import moment from "moment/moment";
export default {
  components: {
    DetePicker,
    Pagination,
  },
  created() {
    if (localStorage.initval == "1") {
      if (localStorage.BrdMng_keywordType) {
        this.keywordType = localStorage.BrdMng_keywordType;
      }
      if (localStorage.BrdMng_keyword) {
        this.keyword = localStorage.BrdMng_keyword;
      }
      if (localStorage.BrdMng_Number) {
        this.pageNumber = parseInt(localStorage.BrdMng_Number);
      }
      if (localStorage.BrdMng_Size) {
        this.pageSize = parseInt(localStorage.BrdMng_Size);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_KKOR,
      title: "입점신청 관리",
    });
  },
  watch: {
    keywordType(newVal) {
      localStorage.BrdMng_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.BrdMng_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.BrdMng_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.BrdMng_Size = newsize;
    },
  },
  data() {
    return {
      dateFrom: moment().subtract(1,'months').format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      partnerStatus: 'All',
      exceldata: {
        animals: [{
          name: "cat",
          category: "animal"
        },
          {
            name: "dog",
            category: "animal"
          },
          {
            name: "pig",
            category: "animal"
          },
        ],
        pokemons: [{
          name: "pikachu",
          category: "pokemon"
        },
          {
            name: "Arbok",
            category: "pokemon"
          },
          {
            name: "Eevee",
            category: "pokemon"
          },
        ],
      },
      buttonDissabled : false,
      rejectReasonToggle : false,
      rejectReason: ''
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if (localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.BrdMng_keywordType = this.keywordType;
      localStorage.BrdMng_keyword = this.keyword;
      localStorage.BrdMng_Number = this.pageNumber;
      localStorage.BrdMng_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if (!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      const dateFrom = moment(this.dateFrom).format("YYYY-MM-DD")
      const dateTo = moment(this.dateTo).format("YYYY-MM-DD")
      reqPost(
        brandapi.base,
        brandapi.kakaoReservePage ,
        this.authToken,
        {
          keywordType:this.keywordType,
          keyword:this.keyword,
          dateFrom: dateFrom,
          dateTo: dateTo,
          partnerStatus:this.partnerStatus,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.content = data.data.content;
              this.totalPages = data.data.totalPages; //전체 페이지 수
              this.totalElements = data.data.totalElements; //전체 아이템 수
              this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          } else {
            alert(data.message)
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },

    editbrand(bcode) {
      this.$router.push(this.routes.editbrand + "?bcode=" + bcode);
    },
    gostoreinspec(bcode) {
      localStorage.ReqIns_keywordType = "BrandCode";
      localStorage.ReqIns_keyword = bcode;
      this.$router.push(this.routes.storeinspec);
    },
    openRejectReason(val){
      this.rejectReasonToggle = true
      this.rejectReason = val
    },
    closeRejectReason(){
      this.rejectReason = ''
      this.rejectReasonToggle = false
    },
    goReqKakaoReserve() {
      this.$router.push(this.routes.reqKakaoRequest);
    },
    goKakaoReserveDetail(id) {
      this.$router.push(this.routes.kakaoReservationRequestDetail+`?partnerId=${id}`);
    },
    convertRegisterStatus(val){
      let convertWord = '';
      switch (val){
        case 'RegisterRequest': convertWord = '입점신청'; break;
        case 'Reject': convertWord = '심사반려'; break;
        case 'Approve': convertWord = '입점완료'; break;
        case 'Cancel': convertWord = '신청취소'; break;
        default: convertWord = ''
      }
      return convertWord
    },
    registerBy(val) {
      let register = val.split("/");
      return register[0]+"/"+register[1];
    }


  },
};
</script>
