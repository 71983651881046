<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">메뉴관리</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="table-responsive p-0" style="margin-top:10px">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">메뉴코드</th>
            <th class="text-center">메뉴명</th>
            <th class="text-center">정상가</th>
            <th class="text-center">기준 인원</th>
            <th class="text-center">대표메뉴</th>
            <th class="text-center">등록일</th>
            <th class="text-center">우선 순위</th>
            <th class="text-center">삭제</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(mitem, midx) in list" :key="midx">
            <td class="text-center">{{ mitem.menuCode }}</td>
            <td class="linkcmp text-center" @click="menunameClickEve(midx,mitem.menuCode)"> {{ mitem.menuName }}</td>
            <td class="text-center"> {{ mitem.listPrice | numFormat }}</td>
            <td class="text-center">{{ mitem.headCount }}</td>
            <td class="linkcmp text-center" style="display: inline-flex;width:100%;justify-content: center;"
                @click="onChangeStatus(mitem.mainMenu, midx,mitem.menuCode)">
              <ToggleBtn :value="mitem.mainMenu"/>
            </td>
            <td class="text-center"> {{ viewDate(mitem.registeredDate) }}</td>
            <td class="text-center">
              <i class="fa fa-fw fa-arrow-up optionUpDownBtn" @click="menuUpDown(mitem, midx, list, 'up')"></i>
              <i class="fa fa-fw fa-arrow-down optionUpDownBtn" @click="menuUpDown(mitem, midx, list, 'down')"></i>
            </td>
            <td class="text-center">
              <button type="button" class="btn btn-social-icon" @click="delmenulist(midx,mitem.menuCode)">
                <i class="fa fa-fw fa-trash-o"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="right-btn">
        <button type="button" class="btn btn-default right-btn" @click="menuListAdd">메뉴 만들기</button>
      </div>
    </div>
    <!-- 메뉴 모달 -->
    <div class="modal" :style="showmenumodal && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">{{ menuModalName }}</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="divbox">
                <div class="col-sm-2">메뉴명 *</div>
                <div class="col-sm-9">
                  <input type="text" class="form-control" v-model="Mmenuname" maxlength="30">
                </div>
              </div>
              <div class="mt-2 divbox">
                <div class="col-sm-2">메뉴설명</div>
                <div class="col-sm-9">
                  <input type="text" class="form-control" v-model="Mmenuinfo">
                </div>
              </div>
              <div class="mt-2 divbox">
                <div class="col-sm-2">정상가 *</div>
                <div class="col-sm-9">
                  <input type="text" class="form-control" v-model="Mmenuprice" style="max-width: 200px;"
                         @input="e=>Mmenuprice=changeNum(e.target.value)">
                  <span>원</span>
                </div>
              </div>
              <div class="mt-2 divbox">
                <div class="col-sm-2">기준인원 *</div>
                <div class="col-sm-9">
                  <input type="text" class="form-control" v-model="Mmenuperson" style="max-width:200px;"
                         @input="e=>Mmenuperson=changeNum(e.target.value)">
                  <span>명</span>
                </div>
              </div>
              <div class="mt-2 divbox">
                <div class="col-sm-2">메뉴 이미지</div>
                <div class="col-sm-9">
                  <div v-for="(simg, sidx) in menuUnitDto.menuImages" :key="sidx" class="imglist">
                    <drop @drop="sumimagesort(sidx, $event)" v-if="simg.length > sidx ||  simg">
                      <drag :transfer-data="{ index: sidx, mark:'subimgs' }">
                        <NewProdImg :value="simg.images.url" :index="sidx" style="margin-right:10px"
                                    :change="onRmvSubImg"/>
                      </drag>
                    </drop>

                  </div>
                  <div class="imglist">
                    <NewProdImg :index="menuUnitDto.menuImages.length" value style="margin-right:10px"
                                :change="onmenuAdminList" ref="prodimg"/>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer text-center">
            <button type="button" class="btn btn-default" @click="closeMenumodal">닫기</button>
            <button type="button" class="btn btn-default" @click="saveMenuList('save')"
                    v-if="this.menuModalName === '메뉴 만들기'">저장
            </button>
            <button type="button" class="btn btn-default" @click="saveMenuList('edit')" v-else>수정</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 메뉴 모달 end -->

  </div>
</template>

<script>
import {
  brandapi
} from "../../../../lib/api";
import {
  reqGet,
  reqPost
} from "../../../../lib/request";
import ToggleBtn from "../../../../components/ToggleBtn";
import NewProdImg from '../../../../components/newProdImg.vue';
import {
  DateFormat
} from "../../../../lib/utility";
import {
  rescode,
  menuval
} from "../../../../lib/consts";
import {
  Drag,
  Drop
} from "vue-drag-drop";

export default {
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  components: {
    ToggleBtn,
    NewProdImg,
    Drag,
    Drop
  },
  data() {
    return {
      showmenumodal: false,
      menuModalName: "",
      Mmenuname: "",
      Mmenuprice: 0,
      Mmenuinfo: "",
      Mmenuperson: "",
      prodimgIdx: 0,
      list: [],
      menuCopyMenuCode: "",
      menuUnitDto: {
        menuName: "",
        description: "",
        listPrice: 0,
        headCount: 0,

        menuImages: [],

      }
    }
  },
  mounted() {
    this.refresh();
  },
  watch: {
    Mmenuprice(val) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if (reg.exec(val) !== null) this.Mmenuprice = val.replace(/[^0-9]/g, '');

      // .... 만 입력하게 될 경우 체크
      if (isNaN(parseFloat(val))) this.Mmenuprice = '';
    }
  },
  methods: {
    menuUpDown(item, idx, menuSetList, type) {
      if (Number(idx) === 0 && type === 'up') {
        return;
      }
      if (Number(idx) === (menuSetList.length - 1) && type === 'down') {
        return;
      }
      let newMenuSetList1 = [];
      for (const idx2 in menuSetList) {
        newMenuSetList1.push({
          menuId: menuSetList[idx2].menuCode,
          sortNumber: menuSetList[idx2].sortNumber,
        })
      }
      let newMenuSetList = JSON.parse(JSON.stringify(newMenuSetList1));
      if (type === 'up') {
        newMenuSetList.splice(Number(idx) - 1, 0, newMenuSetList[idx])
        newMenuSetList.splice(Number(idx) + 1, 1)
      } else {
        newMenuSetList.splice(Number(idx), 2, newMenuSetList[idx + 1], newMenuSetList[idx])
      }

      let number = 0;
      for (const listIdx in newMenuSetList) {
        newMenuSetList[listIdx].sortNumber = number += 1
      }
      console.log(newMenuSetList)
      reqPost(
        brandapi.base,
        brandapi.menuNumberUpdate,
        this.authToken, {
          storeCode: this.$route.query.stcode,
          sortNumberList: newMenuSetList,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.$emit('get-kakao-alert', 'STORE');
              this.refresh();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    refresh() {
      reqGet(
        brandapi.base,
        brandapi.menuList + "?storeCode=" + this.$route.query.stcode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              for (let i in result.data) {
                this.list = result.data[i]
              }
              // console.log(this.list)
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    menuListAdd() {
      this.showmenumodal = true;
      this.menuModalName = "메뉴 만들기";
      this.Mmenuname = '';
      this.Mmenuprice = 0;
      this.Mmenuinfo = '';
      this.Mmenuperson = '';
      this.menuUnitDto.menuImages = [];
    },
    delmenulist(idx, menuCode) {
      if (confirm("삭제하시겠습니까? 예약관리에 해당메뉴로 생성된 옵션 및 예약시간에 연결된 옵션 모두 삭제됩니다.")) {

        reqPost(
          brandapi.base,
          brandapi.menuDelete,
          this.authToken, {
            menuCode: menuCode,
            storeCode: this.$route.query.stcode
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.$emit('get-kakao-alert', 'STORE');
                this.refresh();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    closeMenumodal() {
      this.Mmenuname = '';
      this.Mmenuprice = 0;
      this.Mmenuinfo = '';
      this.Mmenuperson = '';
      this.menuUnitDto.menuImages = [];
      this.showmenumodal = false;
    },
    saveMenuList(type) {
      if (this.Mmenuname === "") {
        alert('메뉴명을 입력해주세요.');
        return;
      }
      if (this.Mmenuprice === "") {
        alert('정상가를 입력해주세요.');
        return;
      }
      if (this.Mmenuperson === "" || Number(this.Mmenuperson) === 0) {
        alert('기준인원을 입력해주세요.');
        return;
      }
      let menuparmaturl = "",
        menuparmat = {},
        alertText = "";
      let menuUnitDtos = this.menuUnitDto;
      menuUnitDtos.menuName = this.Mmenuname;
      menuUnitDtos.description = this.Mmenuinfo;
      if (Number(this.Mmenuprice) !== 0) {
        menuUnitDtos.listPrice = this.Mmenuprice.replace(/,/gi, '');
      }
      menuUnitDtos.headCount = this.Mmenuperson;
      if (type === 'save') {

        menuparmaturl = brandapi.menuCreate;
        menuparmat = {
          menuUnitDto: menuUnitDtos,
          storeCode: this.$route.query.stcode
        }
        alertText = '메뉴가 등록 되었습니다.';
        this.showmenumodal = false;
      } else {

        menuparmaturl = brandapi.menuUpdate;
        menuparmat = {
          menuUnitDto: menuUnitDtos,
          storeCode: this.$route.query.stcode,
          menuCode: this.menuCopyMenuCode,
        }
        alertText = '메뉴가 수정 되었습니다.';
        this.showmenumodal = false;
      }
      reqPost(
        brandapi.base,
        menuparmaturl,
        this.authToken,
        menuparmat,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.$emit('get-kakao-alert', 'STORE');
              alert(alertText);
              this.refresh();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onChangeStatus(mainMenu, midx, menuCode) {
      var url = '';
      if (!mainMenu) {
        url = brandapi.menuEnable;
      } else {
        url = brandapi.menuDisable;
      }
      reqPost(
        brandapi.base,
        url,
        this.authToken, {
          storeCode: this.$route.query.stcode,
          menuCode: menuCode,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.list[midx].mainMenu = !mainMenu;
              this.$emit('get-kakao-alert', 'STORE');
              this.refresh();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onmenuAdminList(idx, url, width, height) {
      let description = this.Mmenuinfo;
      this.prodimgIdx = idx;
      let menuUnitDtoLastIdx = '';
      if (this.menuUnitDto.menuImages.length !== 0) {
        menuUnitDtoLastIdx = this.menuUnitDto.menuImages[this.menuUnitDto.menuImages.length - 1].displayOrder;
      } else {
        menuUnitDtoLastIdx = idx;
      }
      this.menuUnitDto.menuImages.push({
        displayOrder: menuUnitDtoLastIdx + 1,
        images: {
          url,
          width,
          height,
          description,
        }
      });
      console.log(this.menuUnitDto.menuImages)
    },
    onRmvSubImg(idx) {
      this.menuUnitDto.menuImages.splice(idx, 1);
    },
    sumimagesort(drop, drag) {
      if (drag !== undefined) {
        if (drag.mark != 'subimgs') {
          return;
        }
        var t_array = this.menuUnitDto.menuImages;
        this.menuUnitDto.menuImages = [];

        var b = t_array[drag.index];
        t_array[drag.index] = t_array[drop];

        let newTrp = JSON.parse(JSON.stringify(t_array[drop].displayOrder)); //드래그해서 움직일 tr에 displayOrder
        let existingTr = JSON.parse(JSON.stringify(b.displayOrder)); //기존위치에 tr에 displayOrder
        console.log(existingTr);
        console.log(newTrp);

        b.displayOrder = newTrp; // displayOrder 변경
        t_array[drag.index].displayOrder = existingTr; //드래그 한 tr displayOrder 변경

        t_array[drop] = b;
        this.menuUnitDto.menuImages = t_array;
        console.log(this.menuUnitDto.menuImages)
      }
    },
    menunameClickEve(idx, menuCode) {

      reqGet(
        brandapi.base,
        brandapi.menuQuery + "?storeCode=" + this.$route.query.stcode + "&menuCode=" + menuCode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.showmenumodal = true;
              this.menuModalName = "메뉴 상세";
              this.Mmenuname = result.data.menuName;
              this.Mmenuprice = result.data.listPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              this.Mmenuinfo = result.data.description;
              this.Mmenuperson = result.data.headCount;
              this.menuUnitDto.menuImages = result.data.menuImages;
              console.log(this.menuUnitDto.menuImages)
              this.menuCopyMenuCode = menuCode;
            } else {
              alert(result.message);
            }
          }
        }
      );

    },
    bindNumber(event) {
      this.Mmenuprice = event.target.value;
    },
    changeNum: function (value) {
      return value = this.comma(this.uncomma(value));
    },
    comma(str) {
      str = String(str);
      return str.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, '');
    }

  }
}
</script>

<style scoped>
.mt-2 {
  margin-top: 20px;
}

.divbox {
  min-height: 34px;
  display: block;
}

.divbox > div > input {
  display: inline-block;
}

.imglist {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
}

.imglistdiv {
  display: inline-block;
  vertical-align: middle;
}

.right-btn {
  text-align: right;
}

th {
  background-color: #dee2e685;
}

.optionUpDownBtn {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
}
</style>
