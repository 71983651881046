<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
                <div class="radio" style="margin:0px;margin-right:10px">
                    <label>
                        <input type="radio" value="All" v-model="useFlagType" />전체
                    </label>
                    <label>
                        <input type="radio" value="Activation" v-model="useFlagType" />활성
                    </label>
                    <label>
                        <input type="radio" value="Disabled" v-model="useFlagType" />비활성
                    </label>
                </div>
                <div class="input-group" style="flex:1;max-width:400px;min-width:200px">
                    <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin:0px;margin-bottom:20px">
        <button class="btn btn-default" style="margin-right:10px" @click="addIntroModal()">
            <i class="fa fa-plus"></i>등록
        </button>
        <button class="btn btn-default" style="margin-right:10px" @click="showRmvDlg">
            <i class="fa fa-fw fa-remove"></i>삭제
        </button>
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
    </div>

    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" class="allch" @change="allckech($event)" />
                        </th>
                        <th class="text-center">번호</th>
                        <th class="text-center">인트로명</th>
                        <th class="text-center">상태</th>
                        <th class="text-center">등록일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(intro, pidx) in content" v-bind:key="intro.introId">
                        <th>
                            <input type="checkbox" class="chitem" :value="intro.introId" />
                        </th>
                        <td class="text-center">{{ totalElements - (startnum + pidx) }}</td>
                        <td class="linkcmp text-center" @click="goDetailPage(intro.introId)">{{intro.title}}</td>
                        <td class="linkcmp text-center" @click="onChangeuseFlag(intro.introId, intro.useFlag)">
                            <ToggleBtn v-model="intro.useFlag" />
                        </td>
                        <td class="text-center">{{viewDate(intro.regDate)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>

    <!-- 등록,수정 모달 -->
    <div class="modal" :style="addIntro && { display: 'block' }">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- <div class="modal-header border-bottom-0">등록</div> -->
                <div class="modal-body form-horizontal">
                    <div class="row">
                        <label class="col-sm-3 control-label">인트로명</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="introTitle">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-3 control-label">인트로 이미지</label>
                        <div class="col-sm-9">
                            <SingleImgUpload v-model="introimgUrl" downlabel="" :imgtype="imgtype.SITE" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right: 10px" @click="addIntroModalClose">
                            닫기
                        </button>
                        <button type="button" class="btn btn-default" v-if="!introEdt" @click="addIntroClickEve">등록</button>
                        <button type="button" class="btn btn-default" v-else @click="edtIntroClickEve">수정</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  등록,수정 end -->

    <div class="modal" :style="removedlg && { display: 'block' }">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">상품삭제</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group text-center">
                        삭제된 정보는 복구할 수 없습니다. 삭제하시겠습니까?
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeRmvDlg">
                            닫기
                        </button>
                        <button type="button" class="btn btn-default" @click="onintrosiondelete">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval,
    imgtype
} from "../../lib/consts";
import Pagination from "../../components/Pagination";
import ToggleBtn from "../../components/ToggleBtn";
import {
    DateFormat
} from "../../lib/utility";
import {
    display
} from "../../lib/api";
import $ from 'jquery';
import SingleImgUpload from "../../components/SingleImgUpload";
import {
    reqGet,
    reqPost
} from "../../lib/request";

export default {
    components: {
        Pagination,
        ToggleBtn,
        SingleImgUpload,
    },
    watch: {
        keyword(newkey) {
            localStorage.INTRO_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.INTRO_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.INTRO_Size = newsize;
        },
        useFlagType(ntype) {
            localStorage.INTRO_useFlagType = ntype;
        }
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.INTRO_keyword = this.keyword;
            localStorage.INTRO_Number = this.pageNumber;
            localStorage.INTRO_Size = this.pageSize;
            localStorage.INTRO_useFlagType = this.useFlagType;
        }
        this.refreshPage();
    },
    data() {
        return {
            introId: 0,
            introTitle: "",
            introEdt: false,
            introimgUrl: "",
            addIntro: false,
            removedlg: false,
            shownum: 10, //pagination에 보여지는 번호개수
            useFlagType: "All",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            /**
             * introtionId          Number    프로모션 ID
             * title                String    프로모션 명
             * useFlag              Boolean   활성화여부
             * regDate              String    등록일
             */
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true
        }
    },
    computed: {
        imgtype() {
            return imgtype;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.INTRO_keyword) {
                this.keyword = localStorage.INTRO_keyword;
            }
            if (localStorage.INTRO_Number) {
                this.pageNumber = parseInt(localStorage.INTRO_Number);
            }
            if (localStorage.INTRO_Size) {
                this.pageSize = parseInt(localStorage.INTRO_Size);
            }
            if (localStorage.INTRO_useFlagType) {
                this.useFlagType = localStorage.INTRO_useFlagType;
            }
        }
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_DSP,
            path2: menuval.LV1_HOME,
            title: "인트로"
        });
    },
    methods: {
        addIntroClickEve() {
            reqPost(
                display.base,
                display.introRegister,
                this.authToken, {
                    title: this.introTitle,
                    imageUrl: this.introimgUrl,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('등록되었습니다.')
                            this.refreshPage();
                            this.addIntroModalClose();
                        }
                    }
                }
            );
        },
        edtIntroClickEve() {
            reqPost(
                display.base,
                display.introModify,
                this.authToken, {
                    title: this.introTitle,
                    imageUrl: this.introimgUrl,
                    introId: this.introId
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('수정되었습니다.')
                            this.refreshPage();
                            this.addIntroModalClose();
                        }
                    }
                }
            );
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        addIntroModalClose() {
            this.addIntro = false;
        },
        addIntroModal() {
            this.addIntro = true;
            this.introEdt = false;
            this.introimgUrl = '';
            this.introTitle = '';
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        showRmvDlg() {
            var introtionIds = $('input:checkbox.chitem:checked').map(function () {
                return this.value;
            }).get();
            if (introtionIds.length == 0) {
                alert("인트로를 선택하세요.");
                return;
            }
            this.removedlg = true;
        },
        allckech(event) {
            $('input:checkbox.chitem').prop('checked', event.target.checked);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqPost(
                display.base,
                display.introList,
                this.authToken, {
                    useFlagType: this.useFlagType,
                    keyword: this.keyword,
                    pageNumber: pageNumber,
                    pageSize: this.pageSize
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                            alert(result.message);
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goDetailPage(introId) {
            this.addIntro = true;
            this.introEdt = true;
            this.introId = introId;
            reqGet(
                display.base,
                display.introDetail + "?introId=" + introId,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.introimgUrl = result.data.imageUrl;
                            this.introTitle = result.data.title;
                        } else {
                            this.introimgUrl = '';
                            this.introTitle = '';
                            alert(result.message);
                        }
                    } else {
                        this.introimgUrl = '';
                        this.introTitle = '';
                    }
                }
            );
        },
        onChangeuseFlag(introId, useFlag) {
            reqPost(
                display.base,
                display.introStatus,
                this.authToken, {
                    introId: introId,
                    useFlag: !useFlag,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        closeRmvDlg() {
            this.removedlg = false;
        },
        onintrosiondelete() {
            var introIds = $('input:checkbox.chitem:checked').map(function () {
                return this.value;
            }).get();
            reqPost(
                display.base,
                display.introDelete,
                this.authToken, {
                    introIds: introIds,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            $('input:checkbox.chitem').prop('checked', false);
                            $('input:checkbox.allch').prop('checked', false);
                            this.closeRmvDlg();
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
    }
}
</script>

<style scoped>
.mt-2 {
    margin-top: 20px;
}
</style>
