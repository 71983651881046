<template>
  <a class="link"  @click="gopage">
    <slot></slot>
  </a>
</template>

<script>
import routepath from "../lib/routes";
export default {
  components: {},
  props: {
    to: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    routes() {
      return routepath;
    },
  },
  mounted() {
  },
  methods: {
    gopage()
    {
        localStorage.initval = "0";
        // localStorage.reloadurl = this.to;
        var tolen = this.to.length;
        var curpath = this.$router.currentRoute.fullPath.substring(1);
        var lenpath = curpath.substring(0, tolen);
        if(lenpath == this.to)
        {
          if(curpath == this.to)
          {
            this.$router.push(this.to+"?refreh");
          }else{
            this.$router.push(this.to);
          }
        }else{
          this.$router.push(this.to).catch(()=>{
            // this.$router.push(this.routes.refresh);
            this.$router.go(this.$router.currentRoute);
          });
        }
    }
  },
};
</script>
<style scoped>
.link{
    cursor: pointer;
}
</style> 