<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <h3 class="box-title">상품 기본 정보</h3>
        <div class="form-group">
          <label class="col-sm-2 control-label">상품코드</label>
          <div class="col-sm-10 inline-col linkcmp" @click="godetail(goodsBaseInfo.goodsId, goodsBaseInfo.goodsType)">
            <span class="form-control" style="border:none">{{ goodsBaseInfo.goodsId }}</span>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">상품상태</label>
          <div class="col-sm-10">
            <span class="form-control"
                  style="border:none">{{ getSaleState(goodsBaseInfo.deleted, goodsBaseInfo.onSale) }}</span>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">상품유형</label>
          <div class="col-sm-10">
            <span class="form-control" style="border:none">{{ goodsTypeVal(goodsBaseInfo.goodsType) }}</span>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">상품명</label>
          <div class="col-sm-10">
            <span class="form-control" style="border:none">{{ goodsBaseInfo.goodsName }}</span>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">상품소개</label>
          <div class="col-sm-10">
            <span class="form-control" style="border:none">{{ goodsBaseInfo.intro }}</span>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">상세설명</label>
          <div class="col-sm-10">
            <button type="button" class="btn btn-default" v-if="channelCode == 'COUPANG'" @click="showB2bsubimg">
              B2B 이미지 보기
            </button>

            <button type="button" class="btn btn-default" v-else @click="showHtmlview">
              HTML 보기
            </button>
            <!-- 2021-07-28 쿠팡버튼 추가 -->
            <button type="button" class="btn btn-default" style="margin-left:10px" @click="showB2bprint" v-if="channelCode == 'COUPANG'">B2B이미지 생성하기</button>

            <button type="button" @click="fullImgDownFullImg" class="btn btn-default" style="margin-left:10px" v-if="channelCode == 'COUPANG' && showDown">다운로드</button>
            <!-- 2021-07-28 쿠팡버튼 추가 -->

          </div>
        </div>
        <h3 class="box-title"
            v-if="goodsInterworking && (channelCode == 'GMK' || channelCode == 'G9' || channelCode == 'TMON')">채널 추가
          정보</h3>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">채널 상품 판매코드</label>
          <div class="col-sm-10">
            <span class="form-control" style="border:none">{{ channelMatchGoodsInfo.salesLinkCode }}</span>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">채널 상품 관리코드</label>
          <div class="col-sm-10">
            <span class="form-control" style="border:none">{{ channelMatchGoodsInfo.matchGoodsCode }}</span>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">채널 상품상태</label>
          <div class="col-sm-10 inline-col" v-if="channelMatchGoodsInfo.matchOnSale != null">
            <span class="form-control"
                  style="border:none;width: auto;">{{ getSaleState(false, channelMatchGoodsInfo.matchOnSale) }}</span>
            <button type="button" v-if="channelMatchGoodsInfo.matchOnSale" class="btn btn-default" @click="dogoodPause">
              판매중지
            </button>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && (channelCode == 'TMON')">
          <label for="prodtitle" class="col-sm-2 control-label">채널 대표 이미지</label>
          <div class="col-sm-10">
            <div class="input-group">
              <SingleThumbnail v-model="channelMatchGoodsInfo.mainImageUrl" :imgtype="imgtype.B2B"/>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && (channelCode == 'TMON')">
          <label for="prodtitle" class="col-sm-2 control-label">티몬 홈 추천 이미지</label>
          <div class="col-sm-10">
            <div class="input-group">
              <SingleThumbnail v-model="channelMatchGoodsInfo.homeRecommendedImage" :imgtype="imgtype.B2B"/>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">채널 상품명</label>
          <div class="col-sm-10">
            <div class="input-group">
              <input type="text" class="form-control" v-model="channelMatchGoodsInfo.channelGoodsName"/>
              <span class="input-group-addon"><span
                style="color:red">{{ strlen(channelMatchGoodsInfo.channelGoodsName) }}</span>/60 자</span>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">꾸밈문구</label>
          <div class="col-sm-10">
            <div class="input-group">
              <input type="text" class="form-control" v-model="channelMatchGoodsInfo.promotion"/>
              <span class="input-group-addon"><span
                style="color:red">{{ strlen(channelMatchGoodsInfo.promotion) }}</span>/20 자</span>
            </div>
          </div>
        </div>
        <div class="form-group"
             v-if="goodsInterworking && (channelCode == 'GMK' || channelCode == 'G9' || channelCode == 'TMON')">
          <label class="col-sm-2 control-label">카테고리 *</label>
          <div class="col-sm-10 inline-col">
            <div class="input-group">
              <select class="form-control" v-model="channelMatchGoodsInfo.categoryCode">
                <option v-for="(citem, cidx) in categories" :key="cidx" :value="citem.categoryCode">
                  [{{ citem.categoryCode }}]{{ citem.categoryName }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <label>※ 등록/재등록 작업 시 반영됩니다.</label>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">판매기간</label>

          <div class="col-sm-10 inline-col">
            <div style="z-index: 2;" class="input-group">
              <DetePicker v-model="channelMatchGoodsInfo.displayStartDate"/>
              <span class="input-group-addon">~</span>
              <DetePicker v-model="channelMatchGoodsInfo.displayEndDate"/>

              <!--              <InputMask mask="9999-99-99" class="form-control" v-model="channelMatchGoodsInfo.displayStartDate"/>-->
              <!--              <span class="input-group-addon">~</span>-->
              <!--              <InputMask mask="9999-99-99" class="form-control" v-model="channelMatchGoodsInfo.displayEndDate"/>-->
            </div>
            <div class="input-group">
              <label>※ 등록/재등록 작업 시 반영됩니다.</label>
            </div>
          </div>
        </div>


        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">유효기간</label>

          <div class="col-sm-10 inline-col">
            <div style="z-index: 1;" class="input-group">
              <DetePicker v-model="channelMatchGoodsInfo.expirationStartDate"/>
              <span class="input-group-addon">~</span>
              <DetePicker v-model="channelMatchGoodsInfo.expirationEndDate"/>

              <!--              <InputMask mask="9999-99-99" class="form-control" v-model="channelMatchGoodsInfo.expirationStartDate"/> -->
              <!--              <span class="input-group-addon">~</span>-->
              <!--              <InputMask mask="9999-99-99" class="form-control" v-model="channelMatchGoodsInfo.expirationEndDate"/>-->
            </div>
            <div class="input-group">
              <label>※ 등록/재등록 작업 시 반영됩니다.</label>
            </div>
          </div>

        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">수수료 정책</label>
          <div class="col-sm-10 inline-col vendorPolicy">
            <div class="input-group" >
              <select class="form-control" v-model="channelMatchGoodsInfo.vendorPolicyNo">
                <option v-for="(item, i) in vendorPolicy" :key="i" :value="item.policyCode">
                  [{{ item.policyCode }}]{{ item.policyName }}
                </option>
              </select>
            </div>
            <div class="input-group ">
              <label class="policy-label"> ※ 등록/재등록 작업시에만 반영됩니다.</label>
            </div>
          </div>
        </div>


        <div class="form-group" v-if="goodsInterworking && channelCode == 'TMON'">
          <label class="col-sm-2 control-label">취소환불 타입</label>
          <div class="col-sm-10 inline-col radio">
            <label>
              <input type="radio" value="MobileCoupon" v-model="channelMatchGoodsInfo.refundImageType"/>쿠폰
            </label>
            <label>
              <input type="radio" value="MoneyCoupon" v-model="channelMatchGoodsInfo.refundImageType"/>금액권
            </label>
            <label>
              <input type="radio" value="BookingCoupon" v-model="channelMatchGoodsInfo.refundImageType"/>예약필수쿠폰
            </label>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && (channelCode == 'GMK' || channelCode == 'G9')">
          <label for="prodtitle" class="col-sm-2 control-label">채널 대표 이미지 *</label>
          <div class="col-sm-10">
            <div class="input-group">
              <SingleThumbnail v-model="channelMatchGoodsInfo.mainImageUrl" :imgtype="imgtype.B2B"/>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="goodsInterworking && channelCode == 'GMK' ">
          <label for="" class="col-sm-2 control-label">최대 구매 허용수량</label>
          <div class="col-sm-10">
            <div class="input-group">
              <label>
                기간제한 - 구매자 1명이
              </label>
              <div class="input-group" style="display:inline-flex; margin-left:4px;">
                <input type="text" class="form-control" style="width: 60px; margin-right:4px;" id="orderLimitPeriod" ref="orderLimitPeriod" max="210"
                       v-model="channelMatchGoodsInfo.orderLimitPeriod" @blur="chkorderLimitPeriod"
                       oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g,'$1');"
                />
                <label class="nowrap">일 동안 최대</label>
                <input type="text" class="form-control" style="width: 60px; margin-right:4px;" id="orderLimitCount" ref="orderLimitCount" max="999"
                       v-model="channelMatchGoodsInfo.orderLimitCount" @blur="chkorderLimitCount"
                       oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g,'$1');"
                />
                <label class="nowrap">개 까지 구매가능</label>
              </div>
            </div>
            <label style="margin-top: 2px">
              ※ 미등록 시 1일 최대수량 고정 - 5개(잔액관리O) / 10개(잔액관리X)
            </label>
          </div>
        </div>
        <h3 class="box-title">매칭 정보</h3>
        <div class="form-group" style="margin-left:0px;margin-right:0px" v-if="channelCode != 'TMON'">
          <button type="button" class="btn btn-default pull-right" @click="showmatchdlg">매칭해제</button>
        </div>
        <div class="form-group" style="margin-left:0px;margin-right:0px">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>
                  <input type="checkbox" class="allch" @change="allckech($event)"/>
                </th>
                <th>옵션코드</th>
                <th>옵션상태</th>
                <th>옵션명</th>
                <th>정상가</th>
                <th>판매가</th>
                <th v-if="goodsInterworking && (channelCode == 'GMK' || channelCode == 'G9')">채널 상품명</th>
                <th v-if="goodsInterworking && channelCode == 'TMON'">채널 옵션상태</th>
                <th>채널상품코드</th>
                <th v-if="goodsInterworking == false">채널판매코드</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="chmitem in channelMatchItems" v-bind:key="chmitem.optionId">
                <td>
                  <input type="checkbox" class="chitem" :value="chmitem.optionId"/>
                </td>
                <td>{{ chmitem.optionId }}</td>
                <td :class="{redlabel: getSaleState(chmitem.deleted, chmitem.onSale) == '일시중지'}">{{ getSaleState(chmitem.deleted, chmitem.onSale) }}</td>
                <td>{{ chmitem.optionName }}</td>
                <td>{{ chmitem.listPrice | numFormat }}</td>
                <td>{{ chmitem.salePrice | numFormat }}<br/><span
                  style="color:red">{{ percent(chmitem.listPrice - chmitem.salePrice, chmitem.listPrice) }}</span></td>
                <td v-if="goodsInterworking && (channelCode == 'GMK' || channelCode == 'G9')"
                    style="display:inline-flex; width: 100%;">
                  <input type="text" class="form-control" style="width:100%" v-model="chmitem.matchItemName"/>
                  <label><span style="color:red">{{ byteSize(chmitem.matchItemName) }}</span> byte</label>
                </td>
                <td v-if="goodsInterworking && channelCode == 'TMON'">{{ getSaleState(false, chmitem.matchOnSale) }}</td>
                <td v-if="goodsInterworking">{{ chmitem.matchItemCode }}</td>
                <td v-if="goodsInterworking == false">
                  <input type="text" class="form-control" style="width:auto" v-model="chmitem.matchItemCode"/>
                </td>
                <td v-if="goodsInterworking == false">
                  <input type="text" class="form-control" style="width:auto" v-model="chmitem.salesLinkCode"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="box-footer text-center" v-if="goodsInterworking">
        <button
          v-if="channelCode == 'GMK' || channelCode == 'G9' || (channelCode == 'TMON' && channelMatchGoodsInfo.matchOnSale == null)"
          type="button" class="btn btn-default" style="margin-left:6px" @click="setProcess('register')" :disabled="loadingToggle">등록
        </button>
        <button
          v-if="channelCode == 'GMK' || channelCode == 'G9' || (channelCode == 'TMON' && channelMatchGoodsInfo.matchOnSale == true)"
          type="button" class="btn btn-default" style="margin-left:6px" @click="setProcess('update')" :disabled="loadingToggle">수정
        </button>
        <button v-if="channelCode == 'TMON' && channelMatchGoodsInfo.matchOnSale != null" type="button"
                class="btn btn-default" style="margin-left:6px" @click="setProcess('reregister')" >재등록
        </button>
      </div>
      <div class="box-footer text-center" v-if="goodsInterworking == false">
        <button type="button" class="btn btn-default" style="margin-left:6px" @click="doComplete" :disabled="loadingToggle">배포완료
        </button>
        <button type="button" class="btn btn-default" style="margin-left:6px" @click="setProcess('matchCode')">코드매칭
        </button>
      </div>
    </div>
    <div class="modal" v-if="confrmdlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body form-horizontal">
            <h4>{{ getActionName() }}하시겠습니까?</h4>
            <div class="form-group" style="margin:0px">
              <label><input type="checkbox" :value="true" v-model="distributionComplete" style="margin-right:5px"/>자동
                배포완료 처리</label>
            </div>
            <div class="form-group" style="margin:0px"
                 v-if="goodsInterworking && (channelCode == 'GMK' || channelCode == 'G9') && clickaction == 'update'">
              <label><input type="checkbox" :value="true" v-model="mainImageUrlupdate" style="margin-right:5px"/>대표 이미지
                수정</label>
            </div>
          </div>
          <div class="modal-footer" style="text-align: center;">
            <button type="button" class="btn btn-default" @click="closeconfrmdlg">취소</button>
            <button type="button" class="btn btn-default" @click="doProcess">확인</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="matchdlg" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body form-horizontal">
            <h4>선택된 옵션에 매칭된 채널 정보를 모두 삭제 하시겠습니까?</h4>
            <label>※ 매칭 해제 하려는 상품이 해당 채널에 판매중지 상태인지 다시 한번 확인해 주세요!!!</label>
          </div>
          <div class="modal-footer" style="text-align: center;">
            <button type="button" class="btn btn-default" @click="closematchdlg">취소</button>
            <button type="button" class="btn btn-default" @click="doMatchProcess">확인</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="resultdlg" :style="{'display': 'block'}">
      <div class="modal-dialog min-dialog">
        <div class="modal-content">
          <div class="modal-body form-horizontal">
            <h4>등록 완료 [성공 {{ successCount }}건 / {{ getResultsLen() }}건]</h4>
            <div v-for="(ritem, ridx) in resultList" :key="ridx"><label>{{ ritem.optionId }} –
              {{ ritem.resultMessage }}</label></div>
          </div>
          <div class="modal-footer" style="text-align: center;">
            <button type="button" class="btn btn-default" @click="closeResultdlg">확인</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" :style="htmlview && { display: 'block' }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeHtmlview">
              <span aria-hidden="true">×</span>
            </button>
            HTML 보기
          </div>
          <div class="modal-body form-horizontal">
            &#60;div style=&#34;margin:0 auto;&#34;&#62;
            <template v-if="usageImages.titleImageUrl != '' && usageImages.titleImageUrl != null">
              <br /> &#60;img src=&#34;{{usageImages.titleImageUrl}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="usageImages.useInfoImageUrl != '' && usageImages.useInfoImageUrl != null">
              <br /> &#60;img src=&#34;{{usageImages.useInfoImageUrl}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="usageImages.baseInfoImageUrl != '' && usageImages.baseInfoImageUrl != null">
              <br />&#60;img src=&#34;{{usageImages.baseInfoImageUrl}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="usageImages.notesInfoImageUrl != '' && usageImages.notesInfoImageUrl != null">
              <br />&#60;img src=&#34;{{usageImages.notesInfoImageUrl}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="usageImages.refundInfoImageUrl != '' && usageImages.refundInfoImageUrl != null">
              <br />&#60;img src=&#34;{{usageImages.refundInfoImageUrl}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl0 != '' && subImages.subImageUrl0 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl0}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl1 != '' && subImages.subImageUrl1 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl1}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl2 != '' && subImages.subImageUrl2 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl2}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl3 != '' && subImages.subImageUrl3 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl3}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl4 != '' && subImages.subImageUrl4 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl4}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl5 != '' && subImages.subImageUrl5 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl5}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl6 != '' && subImages.subImageUrl6 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl6}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl7 != '' && subImages.subImageUrl7 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl7}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl8 != '' && subImages.subImageUrl8 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl8}}&#34;&#62;&#60;br&#62;
            </template>
            <template v-if="subImages.subImageUrl9 != '' && subImages.subImageUrl9 != null">
              <br />&#60;img src=&#34;{{subImages.subImageUrl9}}&#34;&#62;&#60;br&#62;
            </template>
            <br />&#60;&#47;div&#62;
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="b2bprint" style="display: block">
      <B2BPullPrinter @bbn="bbn" @fullImgDownValue="fullImgDownValue" :fullImgDownChilds="fullImgDownChilds" :subImages="subImages" :usageImages="usageImages" @checkFullImgF="checkFullImg" :goodsId="goodsBaseInfo.goodsId.toString()" :initsubimgs="initsubimgs" :onClose="closeB2bprint" :usage="usage" :subImagesInf="introduction.subImages" />
    </div>
    <div style="display:none;">{{ fullImgDownChilds }}</div>
  </section>
</template>

<script>

import B2BPullPrinter from "../../../components/B2BPullPrinter";
import {rescode, menuval, imgtype} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import DetePicker from "../../../components/DatePicker";
import SingleThumbnail from "../../../components/SingleThumbnail";
import axios from 'axios';
import {reqGet, reqPost, post,getfilename} from "../../../lib/request";
import {b2bapi,goodsapi} from "../../../lib/api";
import {DateFormat, getGoodsTypeStr, getPercent} from "../../../lib/utility";
import InputMask from "vue-input-mask";
import $ from 'jquery';
import DatePick from "vue-date-pick";

export default {
  components: {
    DetePicker,
    InputMask,
    SingleThumbnail,
    B2BPullPrinter,
  },
  created() {
    // this.registerId = this.$route.query.registerId;
    this.goodsId = this.$route.query.goodsId;
    this.channelCode = this.$route.query.channelCode;
    this.goodsInterworking = this.$route.query.goodsInterworking == "true";
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_PRD,
      path2: menuval.LV2_PRD_B2B,
      title: "",
    });

    console.log(process.env.NODE_ENV)
    if(process.env.NODE_ENV === 'production') {
      this.vendorPolicy.push(
        {
          "policyCode":"22",
          "policyName":"(기본)수수료 4.4%"
        },
        {
          "policyCode":"102",
          "policyName":"수수료 6%"
        },
        {
          "policyCode":"106",
          "policyName":"수수료 8%"
        },
        {
          "policyCode":"110",
          "policyName":"수수료 10%"
        },
      );
    } else {
      this.vendorPolicy.push(
        {
          "policyCode":"659",
          "policyName":"수수료 기본"
        },
        {
          "policyCode":"855",
          "policyName":"수수료 4.4%"
        },
        {
          "policyCode":"843",
          "policyName":"수수료 6%"
        },
        {
          "policyCode":"847",
          "policyName":"수수료 8%"
        },
        {
          "policyCode":"851",
          "policyName":"수수료 10%"
        });
    }
  },

  data() {
    return {
      btnNameg:"",
      fullImgDownChilds:"",
      showDown:false,
      checkFullImgs: false,
      initsubimgs:false,
      registrationname: "registrationname.jpg",
      fullImgDown:"",
      introduction:{
        goodsName:"",//상품명
        goodsTag: null,
        intro:"",//상품소개
        mainImage:{
          url:"",//메인 이미지 URL
          width:0,//메인 이미지 width
          height:0,//메인 이미지 height
        },
        subImages:[],
        forCombined: false,
        /*
        title             String      서브 이미지 제목
        description       String      서브 이미지 설명
        displayOrder      Number      서브 이미지 배열 순서
        images[].url      String      서브 이미지 URL
        images[].width    Number      서브 이미지 width
        images[].height   Number      서브 이미지 height
        */
      },
      usage:{
        baseInfo:"",//기본정보
        useInfo:"",//사용정보
        notes:"",//유의사항
        returnsAndRefunds:"",//취소/환불 규정
      },
      b2bprint:false,
      goodsId: "",
      channelCode: "",
      categories: [],
      vendorPolicy:[

      ],
      goodsInterworking: false,
      goodsBaseInfo: {
        goodsId: 0,//상품 ID
        onSale: false,//상품 판매 여부
        deleted: false,//상품 삭제 여부
        goodsType: "MobileCoupon",//상품 유형[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
        goodsName: "",//상품명
        intro: "",//상품소개
      },
      channelMatchGoodsInfo: {
        matchGoodsCode: "",//채널 상품 관리코드
        salesLinkCode: "",//채널 상품 판매코드
        matchOnSale: null,//채널 상품상태, null : 미 등록 상태, true : 판매중, false : 판매중지
        channelGoodsName: "",//채널 상품명
        promotion: "",//꾸밈문구
        categoryCode: "",//카테고리 코드
        mainImageUrl: "",//채널 대표 이미지
        homeRecommendedImage:null,// 티몬전용 홈추천이미지 (null허용 없을경우 mainImage로 대체됨)
        displayStartDate: "",//판매기간 시작일
        displayEndDate: "",//판매기간 종료일
        expirationStartDate: "",//유효기간 시작일
        expirationEndDate: "",//유효기간 종료일
        refundImageType: "MobileCoupon",//취소환불 타입[MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
        vendorPolicyNo:null,// 수수료 정책(쿠팡)
        orderLimitPeriod : null,
        orderLimitCount : null,
      },
      channelMatchItems: [],
      /**
       * optionId               Number        옵션 ID
       * onSale                 Boolean       옵션 판매 여부
       * deleted                Boolean       옵션 삭제 여부
       * optionName             String        옵션명
       * listPrice              String        정상가
       * salePrice              String        판매가
       * matchItemName          String        채널 상품명
       * matchItemCode          String        채널상품코드
       * salesLinkCode          String        채널판매코드
       */
      distributionComplete: true,
      mainImageUrlupdate: true,
      confrmdlg: false,
      clickaction: "",
      matchdlg: false,
      successCount: 0,
      resultList: [],
      resultdlg: false,
      htmlview: false,
      usageImages: {
        titleImageUrl:"https://hitable2020images.blob.core.windows.net/b2b/b2b_form_info_top.jpg",//타이틀 이미지 URL
        useInfoImageUrl: null, //사용정보 이미지 URL
        baseInfoImageUrl: null, //기본정보 이미지 URL
        notesInfoImageUrl: null, //유의사항 이미지 URL
        refundInfoImageUrl: null, //취소환불규정 이미지 URL
      },
      subImages: {
        subImageUrl0: "", //서브 이미지 URL 0
        subImageUrl1: "", //서브 이미지 URL 1
        subImageUrl2: "", //서브 이미지 URL 2
        subImageUrl3: "", //서브 이미지 URL 3
        subImageUrl4: "", //서브 이미지 URL 4
        subImageUrl5: "", //서브 이미지 URL 5
        subImageUrl6: "", //서브 이미지 URL 6
        subImageUrl7: "", //서브 이미지 URL 7
        subImageUrl8: "", //서브 이미지 URL 8
        subImageUrl9: "", //서브 이미지 URL 9
      },
      periodMinAlert : false,
      periodMaxAlert : false,
      countMinAlert : false,
      countMaxAlert : false,
      loadingToggle : false
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    }
  },
  mounted() {
    this.refreshPage();
    if(this.goodsInterworking) {
      this.getcategoryList();
    }
  },
  methods: {
    bbn(btnName){
      console.log(btnName)
      this.btnNameg = btnName;
    },
    fullImgDownValue(fullImgDownValues){
      this.fullImgDownChilds = fullImgDownValues;

      console.log(fullImgDownValues)
      if(this.fullImgDownChilds == "" && this.fullImgDownChilds == null){
        this.showDown = false;
      }else{
        this.showDown = true;
      }
    },
    checkFullImg(checkFullImgs){
      if(checkFullImgs == true){
        // alert(true);
        this.showDown = true;
        this.checkFullImgs = checkFullImgs;
        this.getB2bImage();
      }else{
        // alert(false);
        this.showDown = false;
      }
    },
    fullImgDownFullImg(){
      axios.get(this.fullImgDown, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          if(this.btnNameg == "")
          {
            link.download = getfilename(this.goodsId+"_full.jpg");
          }else{
            link.download = getfilename(this.goodsId+"_full.jpg");
          }
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    closeB2bprint() {
      this.b2bprint = false;
    },
    showB2bprint() {
      this.b2bprint = true;
    },
    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    closeResultdlg() {
      this.resultdlg = false;
    },
    showB2bsubimg() {
      this.$router.push(this.routes.viewb2bimg+"?goodid="+this.goodsId);
    },
    closeHtmlview() {
      this.htmlview = false;
    },
    showHtmlview() {
      this.htmlview = true;
    },
    getcategoryList() {
      reqGet(
        b2bapi.base,
        b2bapi.goodscategoryList + "?channelCode=" + this.channelCode,
        this.authToken,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.categories = result.data.categories;
            } else {
              this.categories = [];
            }
          }
        }
      );
    },
    refreshPage() {
      this.getB2bImage();
      //서브이미지
      reqGet(
        goodsapi.base,
        goodsapi.goodsadmintro + "?goodsId=" + this.goodsId,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.introduction = result.data.introduction;
              // this.orgsubImages = this.introduction.subImages.map((item) => {return item});
              // this.onSale = result.data.onSale;
              // console.log(this.introduction)
            }
          }
        }
      );
      //used
      reqGet(
        goodsapi.base,
        goodsapi.goodsusage + "?goodsId=" + this.goodsId,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.usage = result.data.usage;
              this.orgusage = {...result.data.usage};
            }
          }
        }
      );
      if(this.goodsInterworking == false) {
        reqGet(
          b2bapi.base,
          b2bapi.channelmatchingInfo + "?goodsId=" + this.goodsId + "&channelCode=" + this.channelCode,
          this.authToken,
          (result) => {
            if(result != null) {
              if(result.code == rescode.REQ_SUCCESS) {
                this.goodsBaseInfo = result.data.goodsBaseInfo;
                this.channelMatchItems = result.data.channelMatchItems;
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else {
        if(this.channelCode == 'GMK' || this.channelCode == 'G9') {
          reqGet(
            b2bapi.base,
            b2bapi.gmarketmatchingInfo + "?goodsId=" + this.goodsId + "&channelCode=" + this.channelCode,
            this.authToken,
            (result) => {
              if(result != null) {
                if(result.code == rescode.REQ_SUCCESS) {
                  this.goodsBaseInfo = result.data.goodsBaseInfo;
                  this.channelMatchGoodsInfo = result.data.channelMatchGoodsInfo;
                  this.channelMatchItems = result.data.channelMatchItems;

                  var vm = this;
                  $.each(this.channelMatchItems, function(i, v){
                    if(v.matchItemCode == null) {
                      v.matchItemName = vm.goodsBaseInfo.goodsName + " " + v.optionName;
                    }
                  });


                  if(this.channelMatchGoodsInfo.mainImageUrl == null) {
                    this.channelMatchGoodsInfo.mainImageUrl = "";
                  }
                } else {
                  alert(result.message);
                }
              }
            }
          );
        } else if(this.channelCode == 'TMON') {
          reqGet(
            b2bapi.base,
            b2bapi.tmonmatchingInfo + "?goodsId=" + this.goodsId,
            this.authToken,
            (result) => {
              if(result != null) {
                if(result.code == rescode.REQ_SUCCESS) {
                  console.log(result)
                  this.goodsBaseInfo = result.data.goodsBaseInfo;
                  this.channelMatchGoodsInfo = result.data.channelMatchGoodsInfo;
                  this.channelMatchItems = result.data.channelMatchItems;
                } else {
                  alert(result.message);
                }
              }
            }
          );
        }
      }
    },
    godetail(goodsId, goodsType) {
      // this.$router.push(this.routes.editprodinf + "?goodid=" + goodsId + "&goodsType=" + goodsType);
      window.open(this.routes.editprodinf + "?goodid=" + goodsId + "&goodsType=" + goodsType);
    },
    goodsTypeVal(val) {
      return getGoodsTypeStr(val);
    },
    percent(sales, target) {
      return getPercent(sales, target);
    },
    closeconfrmdlg() {
      this.confrmdlg = false;
    },
    closematchdlg() {
      this.matchdlg = false;
    },
    showmatchdlg() {
      var optionIds = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();

      if(optionIds.length == 0) {
        alert("옵션을 선택해 주세요");
        return;
      }

      // var t_matchItems = this.channelMatchItems.filter((mitem)=>{
      //   var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
      //   return t_idx > -1 && (mitem.matchItemCode == "" || mitem.matchItemCode == null);
      // });

      // if(t_matchItems.length == 0)
      // {
      //   alert("매칭해제 불가 옵션이 포함되어있습니다");
      //   return ;
      // }
      this.matchdlg = true;
    },
    doMatchProcess() {
      var optionIds = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();
      reqPost(
        b2bapi.base,
        b2bapi.unMatchCode,
        this.authToken,
        {
          channelCode: this.channelCode,
          goodsId: this.goodsId,
          distributionComplete: this.distributionComplete,
          optionIds: optionIds,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.closematchdlg();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getSaleState(del, val) {
      if(del) {
        return "삭제";
      }
      if(val) {
        return "판매중";
      } else {
        if (val != null) {
          return "일시중지";
        } else {
          return "";
        }
      }
    },
    getActionName() {
      if(this.clickaction == "register") {
        return "등록";
      } else if(this.clickaction == "update") {
        return "수정";
      } else if(this.clickaction == "reregister") {
        return "재등록";
      } else if(this.clickaction == "pause") {
        return "판매중지";
      } else if(this.clickaction == "matchCode") {
        return "코드매칭";
      }

    },
    dogoodPause() {
      this.clickaction = "pause";
      this.confrmdlg = true;
    },
    doComplete() {
      this.loadingToggle = true;
      reqPost(
        b2bapi.base,
        b2bapi.distcomplete,
        this.authToken,
        {
          channelCode: this.channelCode,
          goodsIds: [this.goodsId]
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.loadingToggle = false;
              this.$router.go(-1);
            } else {
              alert(result.message);
            }
          } else {
            alert("실패");
            return false;
          }
        }
      );
    },
    chkorderLimitPeriod(){

      if(this.channelMatchGoodsInfo.orderLimitPeriod != null && this.channelMatchGoodsInfo.orderLimitPeriod != '' && Number(this.channelMatchGoodsInfo.orderLimitPeriod)<1){
        // if(!this.periodMinAlert){
        // this.periodMinAlert = true
        // }
        alert("1이상 입력해 주세요.")
        this.channelMatchGoodsInfo.orderLimitPeriod = 1
        this.$refs.orderLimitPeriod.focus()
        return false
      }else if(Number(this.channelMatchGoodsInfo.orderLimitPeriod)>210){
        // if(!this.periodMaxAlert){
        // this.periodMaxAlert = true
        // }
        alert("210를 넘을 수 없습니다.")
        this.channelMatchGoodsInfo.orderLimitPeriod = 210
        this.$refs.orderLimitPeriod.focus()
        return false
      }else
        return true

    },
    chkorderLimitCount(){
      if(this.channelMatchGoodsInfo.orderLimitCount != null && this.channelMatchGoodsInfo.orderLimitCount != '' && Number(this.channelMatchGoodsInfo.orderLimitCount)<1){
        // if(!this.countMinAlert){
        //   this.countMinAlert = true
        // }
        alert("1이상 입력해 주세요.")
        this.channelMatchGoodsInfo.orderLimitCount = 1
        this.$refs.orderLimitCount.focus()
        return false
      }else if ( Number(this.channelMatchGoodsInfo.orderLimitCount)>999){
        // if(!this.countMaxAlert ){
        //   this.countMaxAlert = true
        // }
        alert("999를 넘을 수 없습니다.")
        this.channelMatchGoodsInfo.orderLimitCount = 999
        this.$refs.orderLimitCount.focus()
        return false
      }else
        return true

    },
    setProcess(val) {

      var optionIds = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();
      if(optionIds.length < 1) {
        alert("옵션을 선택해 주세요");
        return;
      }
      if(this.channelCode == 'TMON'){
        if(this.channelMatchGoodsInfo.refundImageType == null) {
          alert("취소환불 타입을 선택해주세요");
          return;
        }
        if(this.channelMatchGoodsInfo.mainImageUrl == null || this.channelMatchGoodsInfo.mainImageUrl == ""){
          alert("채널 대표 이미지를 등록해 주세요.");
          return;
        }
      }


      if (this.channelCode === 'GMK' || this.channelCode === 'G9') {
        this.chkorderLimitPeriod();
        this.chkorderLimitCount();
        var registerCheck = true;
        var updateCheck = true;
        this.channelMatchItems.filter((mitem) => {
          var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
          if (t_idx > -1) {
            if (val === "register" && mitem.matchItemCode) registerCheck = false;
            if (val === "update" && !mitem.matchItemCode) updateCheck = false;
          }
        });

        switch (val) {
          case "register":
            if (!registerCheck) {
              alert("이미 등록된 상품이 포함되어 있습니다.");
              return;
            }
            break;
          case "update":
            if (!updateCheck) {
              alert("등록되지 않은 상품이 포함되어 있습니다.");
              return;
            }
            break;
        }
      }

      // if(this.goodsInterworking && (this.channelCode == 'GMK' || this.channelCode == 'G9'))
      // {
      //   var t_matchItems = [];
      //   if(val == "register")
      //   {
      //     t_matchItems = this.channelMatchItems.filter((mitem)=>{
      //       var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
      //       return t_idx > -1 && (mitem.matchItemCode == "" || mitem.matchItemName == "" || mitem.matchItemName == null);
      //     });

      //     if(t_matchItems.length == 0)
      //     {
      //       alert("채널 상품명을 입력하세요.");
      //       return ;
      //     }
      //   }
      //   else if(val == "update")
      //   {
      //     t_matchItems = this.channelMatchItems.filter((mitem)=>{
      //       var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
      //       return t_idx > -1 && (mitem.matchItemCode == "" || mitem.matchItemName == "" || mitem.matchItemName == null);
      //     });

      //     if(t_matchItems.length == 0)
      //     {
      //       alert("채널 상품명을 입력하세요.");
      //       return ;
      //     }
      //   }
      // }

      this.clickaction = val;
      this.confrmdlg = true;
    },
    doProcess() {
      this.confrmdlg = false;
      if(this.clickaction == "register") {
        this.goodsregister();
      } else if(this.clickaction == "update") {
        this.goodsupdate();
      } else if(this.clickaction == "reregister") {
        this.goodsreregister();
      } else if(this.clickaction == "pause") {
        this.goodspasuse();
      } else if(this.clickaction == "matchCode") {
        this.goodsmatchCode();
      }
    },
    goodsregister() {
      this.loadingToggle = true;
      var optionIds = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();

      if (this.channelCode == 'GMK' || this.channelCode == 'G9') {
        var t_matchItems = this.channelMatchItems.filter((mitem) => {
          var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
          return t_idx > -1;
        });

        var mainImageUrl = this.channelMatchGoodsInfo.mainImageUrl;
        if (this.mainImageUrlupdate == false) {
          mainImageUrl = "";
        }

        var itemCount = t_matchItems.length;
        var resultCount = 0;
        var resultMessageList = [];

        const sleep = () => {
          return new Promise(resolve => setTimeout(resolve, 1000));
        };

        const batchWorker = async () => {
          for (let f = 0; f < itemCount; f++) {
            var reqData = {
              channelCode: this.channelCode,
              goodsId: this.goodsId,
              optionId: t_matchItems[f].optionId,
              channelGoodsName: t_matchItems[f].matchItemName,
              distributionComplete: this.distributionComplete,
              categoryCode: this.channelMatchGoodsInfo.categoryCode,
              mainImageUrl: mainImageUrl,
              orderLimitPeriod:this.channelMatchGoodsInfo.orderLimitPeriod,
              orderLimitCount:this.channelMatchGoodsInfo.orderLimitCount,

            };
            const result = await post(b2bapi.base, b2bapi.gmregister, this.authToken, reqData);

            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                resultCount += 1;
              }
              resultMessageList.push({optionId: t_matchItems[f].optionId, resultMessage: result.message});
            }
            await sleep();
          }
        };

        batchWorker().then(() => {
          this.successCount = resultCount;
          this.resultList = resultMessageList;
          this.resultdlg = true;
          this.loadingToggle = false;
          this.refreshPage();
        });
      } else if(this.channelCode == 'TMON') {
        reqPost(
          b2bapi.base,
          b2bapi.tmonregister,
          this.authToken,
          {
            goodsId: this.goodsId,
            distributionComplete: this.distributionComplete,
            channelGoodsName: this.channelMatchGoodsInfo.channelGoodsName,
            promotion: this.channelMatchGoodsInfo.promotion,
            categoryCode: this.channelMatchGoodsInfo.categoryCode,
            displayStartDate: this.channelMatchGoodsInfo.displayStartDate,
            displayEndDate: this.channelMatchGoodsInfo.displayEndDate,
            expirationStartDate: this.channelMatchGoodsInfo.expirationStartDate,
            expirationEndDate: this.channelMatchGoodsInfo.expirationEndDate,
            refundImageType: this.channelMatchGoodsInfo.refundImageType,
            vendorPolicyNo: this.channelMatchGoodsInfo.vendorPolicyNo,
            optionIds: optionIds,
            mainImageUrl: this.channelMatchGoodsInfo.mainImageUrl,
            homeRecommendedImage: this.channelMatchGoodsInfo.homeRecommendedImage
          },
          (result) => {
            if(result != null) {
              if(result.code == rescode.REQ_SUCCESS) {
                alert(result.message);
                this.refreshPage();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    goodsupdate() {
      this.loadingToggle = true;
      this.clickaction = "update";

      var optionIds = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();

      if (this.channelCode == 'GMK' || this.channelCode == 'G9') {
        var t_matchItems = this.channelMatchItems.filter((mitem) => {
          var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
          return t_idx > -1;
        });

        var mainImageUrl = this.channelMatchGoodsInfo.mainImageUrl;
        if (this.mainImageUrlupdate == false) {
          mainImageUrl = "";
        }

        var itemCount = t_matchItems.length;
        var resultCount = 0;
        var resultMessageList = [];

        const sleep = () => {
          return new Promise(resolve => setTimeout(resolve, 1000));
        };

        const batchWorker = async () => {
          for (let f = 0; f < itemCount; f++) {
            var reqData = {
              channelCode: this.channelCode,
              goodsId: this.goodsId,
              optionId: t_matchItems[f].optionId,
              channelGoodsName: t_matchItems[f].matchItemName,
              distributionComplete: this.distributionComplete,
              mainImageUrl: mainImageUrl,
              orderLimitPeriod:this.channelMatchGoodsInfo.orderLimitPeriod,
              orderLimitCount:this.channelMatchGoodsInfo.orderLimitCount,
            };
            const result = await post(b2bapi.base, b2bapi.gmupdate, this.authToken, reqData);

            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                resultCount += 1;
              }
              resultMessageList.push({optionId: t_matchItems[f].optionId, resultMessage: result.message});
            }
            await sleep();
          }
        };

        batchWorker().then(() => {
          this.successCount = resultCount;
          this.resultList = resultMessageList;
          this.resultdlg = true;
          this.loadingToggle = false;
          this.refreshPage();
        });
      } else if(this.channelCode == 'TMON') {
        reqPost(
          b2bapi.base,
          b2bapi.tmonupdate,
          this.authToken,
          {
            goodsId: this.goodsId,
            distributionComplete: this.distributionComplete,
            channelGoodsName: this.channelMatchGoodsInfo.channelGoodsName,
            promotion: this.channelMatchGoodsInfo.promotion,
            refundImageType: this.channelMatchGoodsInfo.refundImageType,
            optionIds: optionIds,
            mainImageUrl: this.channelMatchGoodsInfo.mainImageUrl,
            homeRecommendedImage: this.channelMatchGoodsInfo.homeRecommendedImage

          },
          (result) => {
            if(result != null) {
              if(result.code == rescode.REQ_SUCCESS) {
                alert(result.message);
                this.refreshPage();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    goodspasuse() {
      reqPost(
        b2bapi.base,
        b2bapi.tmonpause,
        this.authToken,
        {
          goodsId: this.goodsId,
          distributionComplete: this.distributionComplete,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              alert(result.message);
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    goodsmatchCode() {
      var optionIds = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();

      var t_matchItems = this.channelMatchItems.filter((mitem) => {
        var t_idx = optionIds.findIndex((oitem) => oitem == mitem.optionId);
        return t_idx > -1;
      });
      var optionInfos = t_matchItems.map((citem) => {
        return {optionId: citem.optionId, matchItemCode: citem.matchItemCode, salesLinkCode: citem.salesLinkCode}
      });
      /**
       * optionId         Number    true    옵션 ID
       * matchItemCode    String    true    채널상품코드
       * salesLinkCode    String    true    채널판매코드
       */
      if(optionInfos.length == 0) {
        alert("옵션코드를 선택하세요.")
        return "";
      }

      for(var idx = 0; idx < optionInfos.length; idx++) {
        if(optionInfos[idx].matchItemCode == "" || optionInfos[idx].matchItemCode == null) {
          alert("채널상품코드 입력하세요.");
          return;
        }
      }
      var reqdata = {
        channelCode: this.channelCode,
        goodsId: this.goodsId,
        distributionComplete: this.distributionComplete,
        optionInfos: optionInfos,
      };
      reqPost(
        b2bapi.base,
        b2bapi.matchCode,
        this.authToken,
        reqdata,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.successCount = result.data.successCount;
              this.resultList = result.data.resultList;
              this.resultdlg = true;
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    goodsreregister() {
      this.loadingToggle = true;
      var optionIds = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();

      reqPost(
        b2bapi.base,
        b2bapi.tmonreRegister,
        this.authToken,
        {
          goodsId: this.goodsId,
          distributionComplete: this.distributionComplete,
          channelGoodsName: this.channelMatchGoodsInfo.channelGoodsName,
          promotion: this.channelMatchGoodsInfo.promotion,
          categoryCode: this.channelMatchGoodsInfo.categoryCode,
          displayStartDate: this.channelMatchGoodsInfo.displayStartDate,
          displayEndDate: this.channelMatchGoodsInfo.displayEndDate,
          expirationStartDate: this.channelMatchGoodsInfo.expirationStartDate,
          expirationEndDate: this.channelMatchGoodsInfo.expirationEndDate,
          refundImageType: this.channelMatchGoodsInfo.refundImageType,
          vendorPolicyNo: this.channelMatchGoodsInfo.vendorPolicyNo,
          optionIds: optionIds,
          mainImageUrl:this.channelMatchGoodsInfo.mainImageUrl,
          homeRecommendedImage: this.channelMatchGoodsInfo.homeRecommendedImage
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              alert(result.message);
              this.loadingToggle = false;
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    byteSize(str) {
      if(str == null) {
        return 0;
      } else {
        return str.split('').map(s => s.charCodeAt(0)).reduce((prev, c) => (prev + ((c === 10) ? 2 : ((c >> 7) ? 2 : 1))), 0); // 계산식에 관한 설명은 위 블로그에 있습니다.
      }
    },
    strlen(str) {
      if(str == null) {
        return 0;
      } else {
        return str.length;
      }
    },
    getResultsLen() {
      return this.resultList.length;
    },
    getB2bImage() {
      reqGet(
        b2bapi.base,
        b2bapi.imgstandinfo + "?goodsId=" + this.goodsId,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.usageImages = result.data.usageImages;
            this.subImages = result.data.subImages;
            // this.fullImageUrl = result.data.fullImageUrl;
            this.fullImgDown = result.data.fullImageUrl;
            this.fullImgDownChilds = result.data.fullImageUrl;
            // console.log(this.fullImgDown)
            this.initsubimgs = true;
            if(this.fullImgDown == null || this.fullImgDown == ""){
              this.showDown = false;
            }else{
              this.showDown = true;
            }
          }else{
            this.initsubimgs = false;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.redlabel{
  color: red;
}
.radio > label {
  margin-right: 10px;
}
.modal {
  background-color: #00000047;
  overflow: scroll;
}
.policy-label {
  color:red;
}
.vendorPolicy{
  z-index: 0;
}
.nowrap{
  white-space: nowrap;
  margin-top: auto;
  margin-right:4px;
}

@media (min-width: 768px) {
  .inline-col {
    display: inline-flex;
    align-items: center;
  }

  .min-dialog {
    width: 350px;
    margin: 30px auto;
  }
}

</style>
