<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsId">상품코드</option>
            <option value="GoodsNameContaining">상품명</option>
            <option value="SiteName">등록경로</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">상품코드</th>
            <th class="text-center">상품유형</th>
            <th>상품명</th>
            <th class="text-center">등록경로</th>
            <th class="text-center">요청자</th>
            <th class="text-center">요청일</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(gitem, index) in content" v-bind:key="gitem.brandCode">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{ gitem.registerId }}</td>
            <td class="text-center">{{ goodsTypeVal(gitem.goodsType) }}</td>
            <td class="linkcmp" @click="goViewInfoPage(gitem)">{{ gitem.goodsName }}</td>
            <td class="text-center">{{ getSiteBrandName(gitem.siteName) }}</td>
            <td class="text-center">{{ gitem.requestedBy }}</td>
            <td class="text-center">{{ viewDate(gitem.requestedDate) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {reqGet, reqPost} from "../../../lib/request";
import {goodsapi, brandapi} from "../../../lib/api";
import {DateFormat, getGoodsTypeStr} from "../../../lib/utility";

export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.REGREQ_keywordType) {
        this.keywordType = localStorage.REGREQ_keywordType;
      }
      if(localStorage.REGREQ_keyword) {
        this.keyword = localStorage.REGREQ_keyword;
      }
      if(localStorage.REGREQ_Number) {
        this.pageNumber = parseInt(localStorage.REGREQ_Number);
      }
      if(localStorage.REGREQ_Size) {
        this.pageSize = parseInt(localStorage.REGREQ_Size);
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_REG, title: "상품검사신청"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.REGREQ_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.REGREQ_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.REGREQ_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.REGREQ_Size = newsize;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /*
      registerId        Number        등록 ID
      goodsType         String        상품 종류
                        [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
      goodsName         String        상품명
      mainImageUrl      String        메인 이미지 url
      siteName          String        등록 요청 사이트명
      editedBy          String        작성인 ID
      editedDate        String        작성일자
      requestedBy       String        등록 요청인 ID
      requestedDate     String        등록 요청일자
      status            String        항상 RegisterRequested)
                        [NoRequest, RegisterRequested, RegisterDenied, RegisterApproved]
      */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      sitebrands: [],//
      /*
      brandCode     String    브랜드 코드
      brandName     String    브랜드 네임
      siteName      String    사이트명(도메인)
      */
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.REGREQ_keywordType = this.keywordType;
      localStorage.REGREQ_keyword = this.keyword;
      localStorage.REGREQ_Number = this.pageNumber;
      localStorage.REGREQ_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqGet(
        goodsapi.base,
        goodsapi.requestedpage +
        "?keywordType=" +
        this.keywordType +
        "&keyword=" +
        this.keyword +
        "&pageNumber=" +
        pageNumber +
        "&pageSize=" +
        this.pageSize,
        this.authToken,
        (result) => {
          this.content = [];
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.getBrandName(result.data.content);
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              /*
              result.data.size : 페이지 사이즈
              result.data.number : 페이지 번호
              result.data.first : 첫 페이지일 경우 true
              result.data.last : 마지막 페이지일 경우 true
              result.data.empty : 조회된 아이템이 없으면 true
               */
              console.log(result.data);
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    getBrandName(retcnt) {
      if(retcnt.length == 0) {
        return;
      }
      var siteArray = retcnt.map(citem => {
        return citem.siteName
      });
      reqPost(
        brandapi.base,
        brandapi.brandsbySites,
        this.authToken,
        {
          siteNames: siteArray,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.sitebrands = result.data.brands;
              this.content = retcnt;
            }
          }
        }
      );
    },
    getSiteBrandName(sitename) {
      var sbidx = this.sitebrands.findIndex(sbitem => sbitem.siteName == sitename);
      if(sbidx > -1) {
        return this.sitebrands[sbidx].brandName;
      } else {
        return "";
      }
    },
    goViewInfoPage(gitem) {
      this.$router.push(this.routes.viewprodinf + "?registerId=" + gitem.registerId);
    },
    goodsTypeVal(val) {
      return getGoodsTypeStr(val);
    },
  },
};
</script>
