<template>
    <section class="content">
        <div class="box box-solid">
            <ul class="nav nav-tabs">
                <li :class="tabindex == 0 && 'active'">
                    <a class="linkcmp" @click="onClickTab(0)">기본정보</a>
                </li>
                <li :class="tabindex == 1 && 'active'">
                    <a class="linkcmp" @click="onClickTab(1)">계약정보</a>
                </li>
                <li :class="tabindex == 2 && 'active'">
                    <a class="linkcmp" @click="onClickTab(2)">사이트정보</a>
                </li>
                <li :class="tabindex == 3 && 'active'">
                  <a class="linkcmp" @click="onClickTab(3)">부가기능</a>
                </li>
            </ul>
            <div class="box-body">
                <div v-if="tabindex == 0" class="table-responsive p-0">
                    <table class="table table-hover dataTable text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="100px">
                            <col width="250px">
                            <col width="100px">
                            <col width="150px">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>브랜드명</th>
                                <td colspan="5">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="brandName" style="width: auto"
                                            placeholder="브랜드명" />
                                        <button type="button" class="btn btn-default" @click="checkBrandName"
                                            style="margin-right: 10px">
                                            중복확인
                                        </button>
                                        <label :class="brandchkclass">{{ brandcheckmsg }}</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>로고</th>
                                <td colspan="5">
                                    <SingleImgUpload v-model="logoUrl" :downlabel="brandName + logoName"
                                        :imgtype="imgtype.IMG_BRAND" />
                                </td>
                            </tr>
                            <tr>
                                <th>분류</th>
                                <td colspan="2">
                                    <div class="input-group">
                                        <select class="form-control" v-model="brandType">
                                            <option value="HOTEL">HOTEL</option>
                                            <option value="FAMILY">FAMILY</option>
                                            <option value="BUFFET">BUFFET</option>
                                            <option value="SPECIAL">SPECIAL</option>
                                            <option value="RESTAURANT">RESTAURANT</option>
                                            <option value="SPA">SPA</option>
                                        </select>
                                    </div>
                                </td>
                                <th>법인명</th>
                                <td colspan="2">
                                    <div class="from-group">
                                        <input type="text" class="form-control" v-model="corporationName"
                                            placeholder="법인명" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>업체 담당자</th>
                                <td colspan="5">
                                    <table class="table table-hover text-nowrap">
                                        <colgroup>
                                            <col width="100px">
                                            <col width="30%">
                                            <col width="30%">
                                            <col width="30%">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>담당형태</th>
                                                <th>이름</th>
                                                <th>휴대폰 번호</th>
                                                <th>이메일주소</th>
                                            </tr>
                                            <tr>
                                                <th>영업담당자1</th>
                                                <td>
                                                    <input type="text" class="form-control" v-model="salesPerson1Name"
                                                        style="min-width: 100px" placeholder="영업담당자1 이름" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="salesPerson1Phone"
                                                        style="min-width: 100px" placeholder="영업담당자1 휴대폰" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="salesPerson1Email"
                                                        style="min-width: 100px" placeholder="영업담당자1 이메일" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>영업담당자2</th>
                                                <td>
                                                    <input type="text" class="form-control" v-model="salesPerson2Name"
                                                        style="min-width: 100px" placeholder="영업담당자2 이름" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="salesPerson2Phone"
                                                        style="min-width: 100px" placeholder="영업담당자2 휴대폰" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="salesPerson2Email"
                                                        style="min-width: 100px" placeholder="영업담당자2 이메일" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>정산담당자</th>
                                                <td>
                                                    <input type="text" class="form-control" v-model="accountantName"
                                                        style="min-width: 100px" placeholder="정산담당자 이름" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="accountantPhone"
                                                        style="min-width: 100px" placeholder="정산담당자 휴대폰" />
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" v-model="accountantEmail"
                                                        style="min-width: 100px" placeholder="정산담당자 이메일" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th>사업자등록번호</th>
                                <td colspan="2">
                                    <input type="text" class="form-control" v-model="registrationNumber" />
                                </td>
                                <td colspan="3">
                                    <SingleImgUpload v-model="registrationNumberUrl" :downlabel="registrationname"
                                        :imgtype="this.imgtype.BRAND" />
                                </td>
                            </tr>
                            <tr>
                                <th>통신판매업신고번호</th>
                                <td colspan="2">
                                    <input type="text" class="form-control" v-model="commBizNumber" />
                                </td>
                                <td colspan="3">
                                    <SingleImgUpload v-model="commBizNumberUrl" :downlabel="commBizNumbername"
                                        :imgtype="this.imgtype.BRAND" />
                                </td>
                            </tr>
                            <tr>
                                <th>사이트 네임</th>
                                <td colspan="5">{{ domain }}</td>
                            </tr>
<!--                            <tr>-->
<!--                                <th>판매/예약모듈</th>-->
<!--                                <td colspan="3">-->
<!--                                    {{ urlStoreUsed }}-->
<!--                                    <button class="btn btn-default" @click="copyToClipboard(urlStoreUsed)">복사</button>-->
<!--                                </td>-->
<!--                            </tr>-->
                            <tr>
                              <th>클라우드 사이트</th>
                              <td colspan="3">
                                <label class="mr-2">
                                  <input type="radio" name="useSite" id="useSiteTrue" v-model="useSite" :value="true"> <label for="useSiteTrue" :class="useSite?'mr-1 fw-700':'mr-1 fw-500' ">사용</label>
                                  <input type="radio" name="useSite" id="useSiteFalse" v-model="useSite" :value="false"> <label for="useSiteFalse" :class=" !useSite?'mr-1 fw-700':'mr-1 fw-500'">미사용</label>
                                </label>
                                <span class="ml-4" v-if="useSite">{{ urlStoreUsed }}</span>
                                <button class="btn btn-default" v-if="useSite" @click="copyToClipboard(urlStoreUsed)">복사</button>

                              </td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <th>이름</th>
                                <td>{{ salesname }}</td>
                                <th>이메일</th>
                                <td>{{ salesemail }}</td>
                                <td>
                                    <button type="button" class="btn btn-default" style="margin-right: 10px"
                                        @click="showSelMng">
                                        수정
                                    </button>
                                    <button type="button" class="btn btn-default" @click="removeManger">
                                        삭제
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="box-footer text-center">
                        <!-- <button type="button" class="btn btn-default" @click="goBack">취소</button> -->
                        <button type="button" class="btn btn-default" style="margin-left: 15px" @click="updateData">
                            저장
                        </button>
                    </div>
                </div>
                <div v-if="tabindex == 1" class="table-responsive p-0">
                    <table class="table table-hover dataTable text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="150px">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th rowspan="2">정산 수수료</th>
                                <th>정산비중</th>
                                <td>
                                    <div class="form-group radio" style="display: inline-flex; margin-bottom: 0px">
                                        <label><input type="radio" value="ListPrice"
                                                v-model="settlementBasePrice" />정상가</label>
                                        <label><input type="radio" value="SalePrice"
                                                v-model="settlementBasePrice" />판매가</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>비율</th>
                                <td>
                                    <div class="input-group">
                                        <input type="number" class="form-control" v-model="commissionRate" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>세금계산서 발행</th>
                                <td colspan="2">
                                    <div class="form-group radio" style="display: inline-flex; margin-bottom: 0px">
                                        <label>
                                            <input type="radio" :value="true" v-model="issueTaxInvoice" />
                                            발행
                                        </label>
                                        <label>
                                            <input type="radio" :value="false" v-model="issueTaxInvoice" />
                                            미발행
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="issueTaxInvoice">
                                <th>발행기준</th>
                                <td colspan="2">
                                    <div class="form-group radio" style="display: inline-flex; margin-bottom: 0px">
                                        <label>
                                            <input type="radio" value="LastDayOfPrevMonth" v-model="taxInvoiceIssueDate" />
                                            전월말일
                                        </label>
                                        <label>
                                            <input type="radio" value="StartDayOfThisMonth" v-model="taxInvoiceIssueDate" />
                                            당월일일
                                        </label>
                                        <label style="padding-left: 0px">(<input type="checkbox"
                                                v-model="shouldIssueAt1stDay" />1일 발행 )</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="box-footer text-center">
                        <!-- <button type="button" class="btn btn-default" @click="goBack">취소</button> -->
                        <button type="button" class="btn btn-default" style="margin-left: 15px" @click="updateData">
                            저장
                        </button>
                    </div>
                </div>
                <div v-if="tabindex == 2" class="table-responsive p-0">
                    <table class="table table-hover dataTable text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="50%">
                            <col width="150px">
                            <col width="50%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>대표자</th>
                                <td colspan="3">
                                    <div class="form-group" style="margin-bottom: 0px">
                                        <input type="text" class="form-control" v-model="representative" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colspan="3">
                                    <div class="form-group" style="display: inline-flex; margin-bottom: 0px;width:100%">
                                        <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
                                            <input type="text" class="form-control" disabled :value="address.roadAddress" />
                                        </div>
                                        <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
                                            <input type="text" class="form-control" v-model="address.detailAddress" />
                                        </div>
                                        <AddressSearch style="display:inline-flex" :detailAddress="address.detailAddress"
                                            :onChange="onSetAddress" @detailaddrEvechiild="detailaddrEve" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>개인정보관리 책임자</th>
                                <td>
                                    <div class="form-group" style="margin-bottom: 0px;min-width: 250px;">
                                        <input type="text" class="form-control" v-model="privacyPolicyManager" />
                                    </div>
                                </td>
                                <th>이메일주소</th>
                                <td>
                                    <div class="form-group" style="margin-bottom: 0px;min-width: 250px;">
                                        <input type="text" class="form-control" v-model="privacyPolicyManagerEmail" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>이용약관</th>
                                <td colspan="3">
                                    <textarea class="form-control" rows="5" v-model="termsOfService"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>개인정보 취급방침</th>
                                <td colspan="3">
                                    <textarea class="form-control" rows="5" v-model="privacyStatement"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>실 사용 도메인</th>
                                <td colspan="3">
                                    <input type="text" class="form-control" v-model="domainUrl"
                                        placeholder="예:tablenjoy.com">
                                </td>
                            </tr>
                            <tr>
                                <th>고객센터 번호</th>
                                <td colspan="3">
                                    <input type="text" class="form-control" v-model="centerNumber" placeholder="">
                                </td>
                            </tr>
                            <tr>
                                <th>브랜드 소개</th>
                                <td colspan="3">
                                    <input type="text" class="form-control" v-model="description" placeholder="" />
                                </td>
                            </tr>
                            <tr>
                              <th>결제 시 포인트 노출 설정</th>
                              <td colspan="3">
                                <label class="mr-2">
                                  <input type="radio" name="showPoint" id="showPointTrue" v-model="showPoint" :value="true"> <label for="showPointTrue" :class="showPoint?'mr-1 fw-700':'mr-1 fw-500' ">사용</label>
                                  <input type="radio" name="showPoint" id="showPointFalse" v-model="showPoint" :value="false"> <label for="showPointFalse" :class=" !showPoint?'mr-1 fw-700':'mr-1 fw-500'">미사용</label>
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <th>매장 부가 정보 노출 설정</th>
                              <td colspan="3">
                                <label class="mr-2">
                                  <input type="radio" name="showInfo" id="showInfoTrue" v-model="showInfo" :value="true"> <label for="showInfoTrue" :class="showInfo?'mr-1 fw-700':'mr-1 fw-500' ">노출</label>
                                  <input type="radio" name="showInfo" id="showInfoFalse" v-model="showInfo" :value="false"> <label for="showInfoFalse" :class=" !showInfo?'mr-1 fw-700':'mr-1 fw-500'">미노출</label>
                                </label>
                                <br>
                                제어항목 : 매장상세 > [상단] 찜/지도 버튼,[하단] 이용자반응/이용자리뷰사진/위치정보/영업정보 구역
                              </td>
                            </tr>

                        </tbody>
                    </table>
                    <div class="box-footer text-center mb-6">
                        <!-- <button type="button" class="btn btn-default" @click="goBack">취소</button> -->
                        <button type="button" class="btn btn-default" style="margin-left: 15px" @click="updateData">
                            저장
                        </button>
                    </div>
                    <table class="table dataTable text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="50%">
                            <col width="150px">
                            <col width="50%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>배너관리</th>
                                <td colspan="3">
                                    <div style="display: inline-flex;">
                                        <div>
                                            <button @click="showstoreEditEVe" class="btn btn-default disblock"
                                                type="button">
                                                {{ brandImages.images.length === 0 ? '이미지 추가' : '이미지 변경' }}
                                            </button>
                                            <div v-for="(simg, sidx) in imgPreview" :key="sidx" class="readOnly_imgDivBox">
                                                <img :src="simg.url" />
                                            </div>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="box-footer text-center mb-6">
                        <button type="button" class="btn btn-default" style="margin-left: 15px" @click="siteImagesUpdate">
                            저장
                        </button>
                    </div>
                    <table class="table dataTable text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="50%">
                            <col width="150px">
                            <col width="50%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>사이트 컬러</th>
                                <td colspan="3">
                                    <div class="form-group"
                                        style="justify-content: space-between; margin-bottom: 0px; display: flex;max-width: 800px; align-items: center; min-height: 300px;">
                                        <div class="warp-div-color">
                                            <div v-for="(coloritem, idx) in colorArr" :key="idx" class="colorDiv">
                                                <span> {{ coloritem.name }} </span>
                                                <div>
                                                    <div :style="{ 'background-color': '' + coloritem.colorName.hex + '' }"
                                                        class="colorbox1" v-if="nullColor"></div>
                                                    <div v-else class="nullColor"></div>
                                                    <button type="button" @click="colorChange(idx + 1)"
                                                        :class="colorIndex == idx + 1 ? 'colorchoiseon' : ''">컬러 선택</button>
                                                </div>
                                            </div>
                                            <button type="button" class="setBtn" @click="setColor">초기화</button>

                                        </div>
                                        <div class="showcolor" v-if="showColor">
                                            <Chrome v-model="colors1" />
                                            <!-- <Swatches v-model="colors1"></Swatches> -->
                                            <button class="mr-1 choiseBtn" type="button" @click="showColorClick">확인</button>
                                            <button type="button" @click="hideColor">취소</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="box-footer text-center">
                        <button type="button" class="btn btn-default" style="margin-left: 15px" @click="siteColorUpdate">
                            저장
                        </button>
                    </div>

                    <div class="box-header" style="padding-left: 0;">
                        <h3 class="box-title">매장 콘텐츠</h3>
                    </div>
                    <table class="table dataTable text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="50%">
                            <col width="150px">
                            <col width="50%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>예약자명</th>
                                <td colspan="3">
                                    <div class="form-group" style="margin-bottom: 0px">
                                        <input type="text" class="form-control" v-model="siteBookingName" maxlength="18" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰번호</th>
                                <td colspan="3">
                                    <div class="form-group" style="margin-bottom: 0px">
                                        <input type="text" class="form-control" v-model="sitePhoneNumber" maxlength="18"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일주소</th>
                                <td colspan="3">
                                    <div class="form-group" style="margin-bottom: 0px">
                                        <input type="text" class="form-control" v-model="siteEmail" maxlength="18"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    요청사항<br/>
                                    (<input type="checkbox"
                                                    v-model="memoStatus" /> 필수 )
                                </th>
                                <td colspan="3">
                                    <div class="form-group" style="margin-bottom: 0px">
                                        <input type="text" class="form-control" maxlength="18" v-model="memo" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="box-footer text-center">
                        <button type="button" class="btn btn-default" style="margin-left: 15px" @click="siteBookingInfoUpdate">
                            저장
                        </button>
                    </div>
                  <table width="100%">
                    <colgroup>
                      <col width="150px">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>추천 선물 메시지</th>
                      <td colspan="3">
                        <div class="form-group" style="margin-bottom: 0px">
                          <div class="col-sm-10">
                            <GiftMessageListBadgeMake v-model="giftMessages"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="box-footer text-center">
                    <button type="button" class="btn btn-default" style="margin-left: 15px" @click="siteGiftMessageUpdate">
                      저장
                    </button>
                  </div>
                </div>

                <div v-if="tabindex == 3" class="table-responsive p-0">
                    <table class="table table-hover dataTable text-nowrap">
                        <colgroup>
                          <col width="150px">
                          <col width="150px">
                          <col width="50%">
                          <col width="150px">
                          <col width="50%">
                        </colgroup>
                        <tbody>
                        <tr v-if="labelCheck&& alimtalkData.kakaoId">
                          <th>알림톡 발송</th>
                          <td colspan="3">
                            <div class="form-group" style="margin-bottom: 0px">
                              {{alimtalkData.status =='RegisterRequested' ? '등록신청':alimtalkData.status =='Use' ?'사용중':'사용중지'}}
                              <button type="button" v-if="alimtalkData.status =='RegisterRequested'" class="btn btn-default" @click="brandAlimtalkApprove">등록완료</button>
                              <button type="button" v-else-if="alimtalkData.status =='Use'" class="btn btn-default" @click="brandAlimtalkDisable">사용중지</button>
                              <button type="button" v-else class="btn btn-default margin-r-5" @click="brandAlimtalkExistReApply">등록신청</button>
                              <span v-if="alimtalkData.status =='UnUse'" class="text-red text-bold"> * 신규 카카오톡 채널로 등록신청 시 개발팀에 기존 정보 삭제 요청</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>카카오톡 채널 ID</th>
                          <td colspan="3">
                            <div class="form-group" style="margin-bottom: 0px">
                              <input v-if="!labelCheck || !alimtalkData.kakaoId" type="text" class="form-control" v-model="alimtalkData.kakaoId" />
                              <label v-else>{{`${alimtalkData.kakaoId} (${alimtalkData.kakaoName})`}}</label>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="alimtalkData.sendKey!==''&& alimtalkData.sendKey!==null && alimtalkData.sendKey!==undefined">
                          <th>발신 프로필 키</th>
                          <td colspan="3">
                            <div class="form-group" style="margin-bottom: 0px">
                              {{alimtalkData.sendKey}}
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!labelCheck">
                          <th>관리자 휴대폰 번호</th>
                          <td colspan="3">
                            <div class="form-group" style="display: inline-flex; margin-bottom: 0px;width:100%">
                              <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;" v-if="!labelCheck">
                                <input type="text" class="form-control" v-model="alimtalkData.adminNumber" />
                                <button type="button" class="btn btn-default" @click="otpRequest()">인증번호발송</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!labelCheck ">
                            <th>인증번호</th>
                            <td colspan="3">
                                <div class="form-group " style="margin-bottom: 0px; min-width: 250px;">
                                    <input type="text" class="form-control" v-model="alimtalkData.token" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                          <th>카테고리</th>
                          <td colspan="3">
                            <div v-if="!labelCheck" class="input-group d-inline" style="margin-bottom: 0px">
                              <select class="form-control mr-2" style="width: 200px;" v-model="alimtalkLargeForm" @change="getAlimtalkMiddleForm(alimtalkLargeForm)">
                                <option v-for="item in alimtalkLargeFormList" :key="item.mainCategoryCode"
                                        :value="item.mainCategoryCode">{{item.mainCategory}}</option>
                              </select>
                              <select class="form-control mr-2" style="width: 200px;" v-model="alimtalkMiddleForm" @change="getAlimtalkSmallForm(alimtalkMiddleForm)">
                                <option v-for="item in alimtalkMiddleFormList" :key="item.middleCategoryCode"
                                        :value="item.middleCategoryCode">{{item.middleCategory}}</option>
                              </select>
                              <select class="form-control mr-2" style="width: 200px;" v-model="alimtalkSmallForm">
                                <option v-for="item in alimtalkSmallFormList" :key="item.smallCategoryCode"
                                        :value="item.smallCategoryCode">{{item.smallCategory}}</option>
                              </select>
                            </div>
                            <div v-else class="form-group" style="margin-bottom: 0px">
                              <label>{{ alimtalkData.categoryLabels.mainCategoryName +' > '+alimtalkData.categoryLabels.middleCategoryName +' > '+ alimtalkData.categoryLabels.smallCategoryName }}</label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                            <th rowspan="4">발신번호 등록 신청</th>
                            <th colspan="1">신청여부</th>
                            <td colspan="2">
                                <label>
                                  <template v-if="!labelCheck">
                                    <input  type="checkbox"  v-model="alimtalkData.smsRegister" > 신청
                                  </template>
                                  <template v-else>
                                    <label v-if="alimtalkData.smsRegister==true"> 신청</label>
                                    <label v-if="alimtalkData.smsRegister==false" > 미신청</label>
                                  </template>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th>발신 번호</th>
                            <td colspan="3">
                              <template v-if="!labelCheck ">
                                <input  type="text" class="form-control" v-model="alimtalkData.smsSendNumber" >
                              </template>
                              <template v-else>
                                <label> {{alimtalkData.smsSendNumber}}</label>
                              </template>
                            </td>
                        </tr>
                        <tr>
                            <th>등록신청서</th>
                            <td colspan="3">
                              <SingleImgUpload v-model="alimtalkData.smsRegisterDocumentUrl" :imgtype="this.imgtype.BRAND" />
                            </td>
                        </tr>
                        <tr>
                            <th>통신사이용증명원</th>
                            <td colspan="3">
                              <SingleImgUpload v-model="alimtalkData.smsCertificateDocumentUrl" :imgtype="this.imgtype.BRAND" />
                            </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="box-footer text-center">
                        <button v-if="!labelCheck" type="button" class="btn btn-default" style="margin-left: 15px" @click="regAlimtalk()">
                          등록신청
                        </button>
                        <button v-if="labelCheck" type="button" class="btn btn-default" style="margin-left: 15px" @click="updateAlimtalk()">
                          저장
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal" :style="showmodal && { display: 'block' }">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">담당자 아이디 조회</div>
                    <div class="modal-body form-horizontal">
                        <div class="form-group">
                            <label class="col-sm-2 control-label">아이디</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <input type="email" class="form-control" v-model="searchkey" />
                                    <span class="input-group-btn">
                                        <button class="btn btn-default" type="button" @click="searchadmin">
                                            검색
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive regmodal p-0">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>선택</th>
                                        <th>아이디</th>
                                        <th>이름</th>
                                        <th>이메일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in adminlist" v-bind:key="user.username">
                                        <td>
                                            <input type="radio" :value="user.username" name="seladmin"
                                                @click="selectadmin(user.username, user.fullName, user.email)" />
                                        </td>
                                        <td>{{ user.username }}</td>
                                        <td>{{ user.fullName }}</td>
                                        <td>{{ user.email }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" @click="closemodal">취소</button>
                        <button type="button" class="btn btn-default" @click="applyadmin">저장</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>

        <div class="modal" :style="showstoreEdit && { 'display': 'block' }" id="newww" ref="scrollEl">
            <div class="modal-dialog new">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">{{ itemModalName }}</div>
                    <div class="modal-body form-horizontal">
                        <div class="row mt-2">
                            <label class="col-sm-2">이미지 ＊</label>
                            <div class="col-sm-10">
                                <div :class="sctopClass ? 'ssio' : 'ssio on'">
                                    <div class="nenenen">
                                        <stroeImgUploda :imgtype="imgtype.BRAND" :imgFile2="{}" :trueImg2="''" value
                                            :index="0" style="margin-right:10px" :change="onstoreImages" ref="prodimg" />
                                    </div>
                                </div>
                                <p class="mt-2"><strong>미리보기</strong></p>
                                <mainImgDispaly ref="prodaddimgs" @movemouse="movemouse"
                                    @descriptionChange="descriptionChange" v-for="(simg, sidx) in detailImgArray.mainimg"
                                    :key="sidx" :index="sidx" :title="simg.title" :imgdes="simg.images"
                                    :images="simg.images" @remove="emvsubimg(sidx)" />

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" @click="showstoreEditClose">닫기</button>
                        <button type="button" class="btn btn-default" @click="saveChangeBtn">{{ btnName }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal" :style="usehisdlg && {'display': 'block'}">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">매장별 도메인 보기</div>
                <div style="text-align:right; margin-top:15px;">
                    <button type="button" class="btn btn-default" style="margin-right: 10px" @click="urlStoreExcel">
                        <i class="fa fa-download"></i>Excel
                    </button>
                </div>
                <div class="modal-body form-horizontal">
                    <div class="form-group">
                        <div class="table-responsive p-0">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th style="text-align:center;">매장명</th>
                                        <th style="text-align:center;">URL</th>
                                        <th style="text-align:center;">복사</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="stores in urlStoreList" :key="stores.storeCode">
                                        <td>{{ stores.storeName }}</td>
                                        <td>{{ stores.storeUrl }}</td>
                                        <td style="text-align:center;"><button class="btn btn-default" @click="copyToClipboard(urlStoreList.storeUrl)">복사</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeUseHis">닫기</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    </section>
</template>

<script>
import {
    rescode,
    menuval,
    imgtype
} from "../../lib/consts";
import SingleImgUpload from "../../components/SingleImgUpload";
import stroeImgUploda from "../../components/stroeImgUploda";
import mainImgDispaly from '../../components/mainImgDispaly';

import AddressSearch from "../../components/AddressSearch";
import {
    Chrome,
    Swatches,
    Compact
} from 'vue-color';

import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    adminapi,
    brandapi
} from "../../lib/api";
import XLSX from "xlsx";

import SingleThumbnail from "../../components/SingleThumbnail";
import ListBadgeMake from "@/components/ListBadgeMake";
import GiftMessageListBadgeMake from "@/components/GiftMessageListBadgeMake";

export default {
    components: {
      GiftMessageListBadgeMake,
        SingleImgUpload,
        AddressSearch,
        SingleThumbnail,
        Chrome,
        Swatches,
        Compact,
        stroeImgUploda,
        mainImgDispaly,
        ListBadgeMake,
    },
    data() {
        return {
            giftMessages:[],
            memoStatus:false,
            memo:'',
            sitePhoneNumber:'',
            siteEmail:'',
            siteBookingName:'',
            showstoreEdit: false,
            itemModalName: '',
            sctopClass: true,
            btnName: '',
            nullColor: true,
            brandImages: {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
            },
            detailImgArray: {
                mainimg: [],
            },
            showColor: false,
            colorIndex: '0',
            colors1: {
                hex: '#194d33',
                name: '',
                hsl: {
                    h: 150,
                    s: 0.5,
                    l: 0.2,
                    a: 1
                },
                hsv: {
                    h: 150,
                    s: 0.5,
                    l: 0.2,
                    a: 1
                },
                rgba: {
                    r: 25,
                    g: 77,
                    b: 51,
                    a: 1
                },
                a: 1
            },
            colorArr: [{
                name: '메인컬러',
                colorName: {
                    name: 'mainColor',
                    hex: '#EC632A',
                    hsl: {},
                    hsv: {},
                    rgba: {},
                    a: 1
                },
            },
            {
                name: '서브컬러',
                colorName: {
                    name: 'subColor',
                    hex: '#7C726E',
                    hsl: {},
                    hsv: {},
                    rgba: {},
                    a: 1
                },
            },
            {
                name: '안내컬러',
                colorName: {
                    name: 'guideColor',
                    hex: '#26a69a',
                    hsl: {},
                    hsv: {},
                    rgba: {},
                    a: 1
                },
            },
            {
                name: '에러컬러',
                colorName: {
                    name: 'errorColor',
                    hex: '#D84315',
                    hsl: {},
                    hsv: {},
                    rgba: {},
                    a: 1
                },
            },
            ],
            storeUrl: '',

            brandCode: "", //조회할 브랜드 코드
            tabindex: 0,
            showmodal: false,
            brandName: "", //브랜드명
            brandcheckmsg: "",
            brandchkclass: "",
            logoUrl: "", //로고 이미지 URL
            logoName: "brandlogo.jpg", //로고 이미지 URL
            brandType: "", //분류명
            domain: "", //테이블엔조이 시스템에서 사용할 도메인명
            corporationName: "", //법인명
            salesPerson1Name: "", //영업 담당자 1 이름
            salesPerson1Phone: "", //영업 담당자 1 전화번호
            salesPerson1Email: "", //영업 담당자 1 이메일
            salesPerson2Name: "", //영업 담당자 2 이름
            salesPerson2Phone: "", //영업 담당자 2 전화번호
            salesPerson2Email: "", //영업 담당자 2 이메일
            accountantName: "", //정산 담당자 이름
            accountantPhone: "", //정산 담당자 전화번호
            accountantEmail: "", //정산 담당자 이메일
            registrationNumber: "", //사업자 등록번호
            registrationNumberUrl: "", //사업자 등록번호 이미지 url
            registrationname: "registrationname.jpg",
            commBizNumber: "", //통신판매업 신고번호
            commBizNumberUrl: "", //통신판매업 신고번호 이미지 url
            commBizNumbername: "commBizNumbername.jpg",
            tnjManagerID: "", //테이블엔조이 담당자 ID
            salesname: "", //담당자 이름
            salesemail: "", //이메일

            tmptnjManagerID: "", //테이블엔조이 담당자 ID
            tmpsalesname: "", //담당자 이름
            tmpsalesemail: "", //이메일
            adminlist: [],
            searchkey: "",
            pageNumber: 1,
            totalPages: 0,
            totalElements: 0,
            numberOfElements: 0,
            useSite:false, // 클라우드사이트 사용 여부

            /* ---------contractInfo--------- */
            settlementBasePrice: "ListPrice",
            commissionRate: "",
            issueTaxInvoice: false,
            taxInvoiceIssueDate: "",
            shouldIssueAt1stDay: false,
            /* ---------siteInfo------------- */
            representative: "", //대표자 성함
            address: {
                detailAddress: "",
                roadAddress: "",
            }, //주소
            privacyPolicyManager: "", //개인정보관리자
            privacyPolicyManagerEmail: "", //개인정보관리자 이메일
            termsOfService: "", //이용약관
            privacyStatement: "", //개인정보처리방침
            domainUrl: '',
            centerNumber: '',
            description: '',
            showPoint: false,
            showInfo: false,
            usehisdlg: false,
            urlStoreList: [],
            urlStoreUsed: '',
            imgPreview: [],
            originalimg: [],
            alimtalkData:{
                kakaoId: "",
                kakaoName:"",
                sendKey: "",
                status: "",
                smsSendNumber: "",
                smsRegisterDocumentUrl: "",
                smsCertificateDocumentUrl: "",
                smsRegister:false,
                adminNumber:"",
                token:"",
                categoryCode:"",
                categoryLabels: {},
            },
            labelCheck : false,
            alimtalkLargeForm:'',
            alimtalkLargeFormList:[],
            alimtalkMiddleForm:"",
            alimtalkMiddleFormList:[],
            alimtalkSmallForm:"",
            alimtalkSmallFormList:[],
        };
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        imgtype() {
            return imgtype;
        }
    },
    created() {
        this.brandCode = this.$route.query.bcode;
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_BRD,
            path2: menuval.LV1_HOME,
            title: "",
        });
      //this.refreshPage();
    },
    mounted() {
        this.refreshPage();
    },
    methods: {
        showstoreEditEVe() {
            this.showstoreEdit = true;
            this.itemModalName = "항목상세";
            this.titleWarningText = '';
            this.btnName = this.brandImages.images.length === 0 ? '추가' : '변경';
        },
        saveChangeBtn() {
            //메인이미지 순서도 바꾸기 위해서 다시
            this.imgPreview = [];
            this.detailImgArray.mainimg.forEach(el => {
                this.imgPreview.push({
                    height: el.images[0].height,
                    url: el.images[0].url,
                    width: el.images[0].width,
                    description: el.images[0].description,
                })
            });
            this.originalimg = [];
            this.originalimg = JSON.parse(JSON.stringify(this.detailImgArray.mainimg));
            console.log(this.originalimg)
            this.showstoreEdit = false;
        },
        showstoreEditClose() {
            this.detailImgArray.mainimg = [];
            this.detailImgArray.mainimg = JSON.parse(JSON.stringify(this.originalimg));
            this.showstoreEdit = false;
        },
        emvsubimg(ridx) {
            this.detailImgArray.mainimg.splice(ridx, 1);
        },
        movemouse(drop, drag) {
            if (drag !== undefined) {
                if (drag.mark != 'subimgs') {
                    return;
                }
                var t_array = this.detailImgArray.mainimg;
                this.detailImgArray.mainimg = [];
                var b = t_array[drag.index];
                t_array[drag.index] = t_array[drop];
                t_array[drop] = b;
                this.detailImgArray.mainimg = t_array;

            }
        },
        descriptionChange(idx, descriptionkk) {
            this.detailImgArray.mainimg[idx].images[0].description = descriptionkk;
        },
        siteBookingInfoQuery(){
            reqGet(
                brandapi.base,
                brandapi.siteBookingInfoQuery + "?brandCode=" + this.brandCode,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        this.siteBookingName = result.data.siteBookingUnitDto.siteBookingName;
                        this.sitePhoneNumber = result.data.siteBookingUnitDto.sitePhoneNumber;
                        this.siteEmail = result.data.siteBookingUnitDto.siteEmail;
                        this.memoStatus = result.data.siteBookingUnitDto.memoStatus;
                        this.memo = result.data.siteBookingUnitDto.memo;
                    }
                }
            );
        },
        siteImagesQuery() {
            reqGet(
                brandapi.base,
                brandapi.siteImagesQuery + "?brandCode=" + this.brandCode,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        this.brandImages.images = [];
                        this.detailImgArray.mainimg = [];
                        result.data.siteImages.brandImages.forEach((element, idx) => {
                            this.detailImgArray.mainimg.push({
                                images: [element],
                            });
                        });
                        this.originalimg = [];
                        this.imgPreview = [];
                        this.originalimg = JSON.parse(JSON.stringify(this.detailImgArray.mainimg));
                        this.detailImgArray.mainimg.forEach(el => {
                            this.imgPreview.push({
                                url: el.images[0].url,
                            })
                            this.brandImages.images.push({
                                height: el.images[0].height,
                                url: el.images[0].url,
                                width: el.images[0].width,
                                description: el.images[0].description,
                            })
                        });
                    }
                }
            );
        },
        siteBookingInfoUpdate(){
            reqPost(
                brandapi.base,
                brandapi.siteBookingInfoUpdate,
                this.authToken, {
                brandCode: this.brandCode,
                siteBookingUnitDto: {
                    siteBookingName: this.siteBookingName == "" ? null : this.siteBookingName,
                    sitePhoneNumber: this.sitePhoneNumber == "" ? null : this.sitePhoneNumber,
                    siteEmail: this.siteEmail == "" ? null : this.siteEmail,
                    memoStatus: this.memoStatus,
                    memo: this.memo == "" ? null : this.memo,
                },
            },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert("저장되었습니다.");
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        siteImagesUpdate() {
            this.brandImages.images = [];
            this.detailImgArray.mainimg.forEach(el => {
                this.brandImages.images.push({
                    height: el.images[0].height,
                    url: el.images[0].url,
                    width: el.images[0].width,
                    description: el.images[0].description,
                })
            });
            if (this.brandImages.images.length === 0) {
                alert('이미지를 추가해주세요.');
                return;
            }
            reqPost(
                brandapi.base,
                brandapi.siteImagesUpdate,
                this.authToken, {
                brandCode: this.brandCode,
                siteImages: {
                    brandImages: this.brandImages.images
                },
            },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert("저장되었습니다.");
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        siteGiftMessageQuery() {
          reqGet(
            brandapi.base,
            brandapi.siteGiftMessageQuery + "?brandCode=" + this.brandCode,
            this.authToken,
            (result) =>{
              if(result.code == rescode.REQ_SUCCESS) {
                this.giftMessages = result.data.brandGiftMessageUnitDto.giftMessages;
              }
            }

          );
        },
        siteColorQuery() {
            reqGet(
                brandapi.base,
                brandapi.siteColorQuery + "?brandCode=" + this.brandCode,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        if (result.data.siteColorUnitDto.errorColor === null) {
                            this.setColor();
                        }
                        const siteuu = result.data.siteColorUnitDto;
                        this.colorArr.forEach((el, idx) => {
                            if (siteuu[el.colorName.name] !== null) {
                                el.colorName.hex = siteuu[el.colorName.name];
                            }
                        });

                        let newArrr = [];
                        this.colorArr.forEach(el => {
                            newArrr.push(this.hexToRgb(el.colorName.hex, false))
                        });

                        this.colorArr.forEach((el, idx) => {
                            el.colorName.rgba = newArrr[idx];
                            el.colorName.hsl = this.rgb2hsl(this.hexToRgb(el.colorName.hex, true));
                        });
                    }
                }
            );
        },
        hexToRgb(hexValue, hslchange) {
            const rgx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            const hex = hexValue.replace(rgx, (m, r, g, b) => r + r + g + g + b + b);
            const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            const r = parseInt(rgb[1], 16);
            const g = parseInt(rgb[2], 16);
            const b = parseInt(rgb[3], 16);
            let rebarr;
            if (hslchange) {
                rebarr = [r, g, b];
            } else {
                rebarr = {
                    r: r,
                    g: g,
                    b: b,
                    a: 1,
                };
            }
            return rebarr;
        },
        rgb2hsl(color) {
            var r = color[0] / 255;
            var g = color[1] / 255;
            var b = color[2] / 255;
            var max = Math.max(r, g, b),
                min = Math.min(r, g, b);
            var h, s, l = (max + min) / 2;

            if (max == min) {
                h = s = 0; // achromatic
            } else {
                var d = max - min;
                s = (l > 0.5 ? d / (2 - max - min) : d / (max + min));

                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                }

                h /= 6;
            }
            return {
                h: h * 360,
                s: s * 100,
                l: l * 100,
                a: 1
            };
        },
        siteGiftMessageUpdate() {
            reqPost(
              brandapi.base,
              brandapi.siteGiftMessageUpdate,
              this.authToken, {
                brandCode: this.brandCode,
                brandGiftMessageUnitDto: {
                  giftMessages : this.giftMessages,
                },
              },
              (result) => {
                if (result != null) {
                  if (result.code == rescode.REQ_SUCCESS) {
                    alert("저장되었습니다.");
                  } else {
                    alert(result.message);
                  }
                }
              }
            )
        },
        siteColorUpdate() {
            let nullColorpoint = true;
            let forarr = [];
            this.colorArr.forEach(el => {
                if (el.colorName.hex === null) {
                    forarr.push(1)
                    nullColorpoint = false;
                }
            });
            if (!nullColorpoint && forarr.length !== 4) {
                alert('컬러를 선택해주세요.')
            } else {
                reqPost(
                    brandapi.base,
                    brandapi.siteColorUpdate,
                    this.authToken, {
                    brandCode: this.brandCode,
                    siteColorUnitDto: {
                        mainColor: this.colorArr[0].colorName.hex,
                        subColor: this.colorArr[1].colorName.hex,
                        guideColor: this.colorArr[2].colorName.hex,
                        errorColor: this.colorArr[3].colorName.hex
                    },
                },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                alert("저장되었습니다.");
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        setColor() {
            this.hideColor();
            this.colorArr.forEach(el => {
                el.colorName.hex = null;
            });
            this.nullColor = false;

        },
        hideColor() {
            this.showColor = false;
            this.colorIndex = '0';
        },
        showColorClick() {
            this.showColor = false;
            // console.log(this.colorIndex)
            switch (this.colorIndex) {
                case 1:
                    this.colors1.name = 'mainColor';
                    this.colorArr[0].colorName = this.colors1;
                    break;
                case 2:
                    this.colors1.name = 'subColor';
                    this.colorArr[1].colorName = this.colors1;
                    break;
                case 3:
                    this.colors1.name = 'guideColor';
                    this.colorArr[2].colorName = this.colors1;
                    break;
                case 4:
                    this.colors1.name = 'errorColor';
                    this.colorArr[3].colorName = this.colors1;
                    break;
            }

            this.colorIndex = '0';
        },
        colorChange(idx) {
            this.nullColor = true;
            switch (idx) {
                case 1:
                    this.colors1 = this.colorArr[0].colorName;
                    break;
                case 2:
                    this.colors1 = this.colorArr[1].colorName;
                    break;
                case 3:
                    this.colors1 = this.colorArr[2].colorName;
                    break;
                case 4:
                    this.colors1 = this.colorArr[3].colorName;
                    break;
            }
            this.colorIndex = idx;
            this.showColor = true;
        },
        // onNewBrandImages(newval, w, h) {
        //     var newimg = {
        //         url: newval,
        //         width: w,
        //         height: h
        //     };
        //     let vm = this;
        //     vm.brandImages.push(newimg);
        // },
        // onChangeBrandImages(index, newval, w, h) {
        //     if (newval != "") {
        //         this.brandImages[index].url = newval;
        //         // console.log(w,h)
        //         this.brandImages[index].width = w;
        //         this.brandImages[index].height = h;
        //     } else {
        //         this.brandImages.splice(index, 1);
        //     }
        // },
        onstoreImages(file) {
            if (file.length > 0) {
                file.forEach((el, idx) => {
                    let _url = el.azureUrl ? el.azureUrl : el.url;
                    this.brandImages.images = [{
                        'width': el.width,
                        'height': el.height,
                        'url': _url,
                        'description': el.description
                    }]
                    this.onAddsubImages();
                });

            } else {
                if (!Array.isArray(file)) {
                    let _url = file.azureUrl ? file.azureUrl : file.url;
                    this.brandImages.images = [{
                        'width': file.width,
                        'height': file.height,
                        'url': _url,
                        'description': file.description
                    }]
                    this.onAddsubImages();
                } else {
                    this.brandImages.images = null
                }

            }
        },
        onAddsubImages() { // 상세이미지 추가
            this.brandImages.displayOrder = this.detailImgArray.mainimg.length + 1;
            var new_images = this.brandImages.images.map((item) => {
                return item
            });

            if (new_images.length == 0) {
                alert("이미지를 추가하세요.");
                return;
            }
            this.detailImgArray.mainimg.push({
                ...this.brandImages,
                images: new_images
            });
        },
        closemodal() {
            this.showmodal = false;
            this.tmptnjManagerID = ""; //테이블엔조이 담당자 ID
            this.tmpsalesname = ""; //담당자 이름
            this.tmpsalesemail = ""; //이메일
        },
        onSetAddress(addinfo, detailAddress) {
            this.address.roadAddress = addinfo.address;
            this.address.detailAddress = detailAddress;
        },
        detailaddrEve(detailAddress) {
            this.address.detailAddress = detailAddress;
        },
        goBack() {
            this.$router.go(-1);
        },
        updateData() {
            if (this.tabindex == 0) {
                reqPost(
                    brandapi.base,
                    brandapi.basicupdate,
                    this.authToken, {
                    brandCode: this.brandCode,
                    brandName: this.brandName,
                    brandType: this.brandType,
                    logoUrl: this.logoUrl,
                    salesPerson1Name: this.salesPerson1Name,
                    salesPerson1Phone: this.salesPerson1Phone,
                    salesPerson1Email: this.salesPerson1Email,
                    salesPerson2Name: this.salesPerson2Name,
                    salesPerson2Phone: this.salesPerson2Phone,
                    salesPerson2Email: this.salesPerson2Email,
                    accountantName: this.accountantName,
                    accountantPhone: this.accountantPhone,
                    accountantEmail: this.accountantEmail,
                    tnjManagerID: this.tnjManagerID,
                    corporationName: this.corporationName,
                    registrationNumber: this.registrationNumber,
                    registrationNumberUrl: this.registrationNumberUrl,
                    commBizNumber: this.commBizNumber,
                    commBizNumberUrl: this.commBizNumberUrl,
                    useSite: this.useSite,

                },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                alert("저장되었습니다.");
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            } else if (this.tabindex == 1) {
                var contractInfo = {
                    settlementBasePrice: this.settlementBasePrice,
                    commissionRate: this.commissionRate,
                    issueTaxInvoice: this.issueTaxInvoice,
                    taxInvoiceIssueDate: this.taxInvoiceIssueDate,
                    shouldIssueAt1stDay: this.shouldIssueAt1stDay
                }
                reqPost(
                    brandapi.base,
                    brandapi.contractupdate,
                    this.authToken, {
                    brandCode: this.brandCode,
                    contractInfo: contractInfo
                },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                alert("저장되었습니다.");
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            } else if (this.tabindex == 2) {
                var siteInfo = {
                    representative: this.representative, //대표자 성함
                    address: this.address.roadAddress, //주소
                    detailAddress: this.address.detailAddress, //주소
                    privacyPolicyManager: this.privacyPolicyManager, //개인정보관리자
                    privacyPolicyManagerEmail: this.privacyPolicyManagerEmail, //개인정보관리자 이메일
                    termsOfService: this.termsOfService, //이용약관
                    privacyStatement: this.privacyStatement, //개인정보처리방침
                    domainUrl: this.domainUrl, //루트도메인
                    centerNumber: this.centerNumber,
                    description: this.description,
                    showPoint: this.showPoint,
                    showInfo: this.showInfo
                    // brandImages: this.brandImages,
                };
                reqPost(
                    brandapi.base,
                    brandapi.siteupdate,
                    this.authToken, {
                    brandCode: this.brandCode,
                    siteInfo: siteInfo
                },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                alert("저장되었습니다.");
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }else if (this.tabindex == 3) {

            }
        },
        showSelMng() {
            this.showmodal = true;
        },
        onClickTab(vidx) {
            if (this.tabindex != vidx) {
                this.tabindex = vidx;
                this.refreshPage();
            }
        },
        removeManger() {
            this.tnjManagerID = ""; //테이블엔조이 담당자 ID
            this.salesname = ""; //담당자 이름
            this.salesemail = ""; //이메일
        },
        selectadmin(admid, admname, admemail) {
            this.tmptnjManagerID = admid;
            this.tmpsalesname = admname;
            this.tmpsalesemail = admemail;
        },
        applyadmin() {
            this.showmodal = false;
            this.tnjManagerID = this.tmptnjManagerID;
            this.salesname = this.tmpsalesname;
            this.salesemail = this.tmpsalesemail;
        },
        searchadmin() {
            reqPost(
                adminapi.base,
                adminapi.approvedList,
                this.authToken, {
                keywordType: "Username",
                keyword: this.searchkey,
                pageNumber: this.pageNumber - 1,
                pageSize: 10,
            },
                (pagelist) => {
                    if (pagelist != null) {
                        if (pagelist.code == rescode.REQ_SUCCESS) {
                            this.adminlist = pagelist.data.content;
                            this.totalPages = pagelist.data.totalPages; //전체 페이지 수
                            this.totalElements = pagelist.data.totalElements; //전체 아이템 수
                            this.numberOfElements = pagelist.data.numberOfElements; //현재 조회된 아이템 수
                            /*
                            data.data.size : 페이지 사이즈
                            data.data.number : 페이지 번호
                            data.data.first : 첫 페이지일 경우 true
                            data.data.last : 마지막 페이지일 경우 true
                            data.data.empty : 조회된 아이템이 없으면 true
                             */
                        } else {
                            this.adminlist = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.adminlist = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        checkBrandName() {
            reqGet(
                brandapi.base,
                brandapi.brandexists + "?brandName=" + this.brandName,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        if (result.data == false) {
                            this.brandcheckmsg = "사용할 수 있는 브랜드명입니다.";
                            this.brandchkclass = "blurmsg";
                        } else {
                            this.brandcheckmsg = "사용할 수 없는 브랜드명입니다.";
                            this.brandchkclass = "redmsg";
                        }
                    }
                }
            );
        },
        closeUseHis() {
            this.usehisdlg = false;
        },
        getUseHisData(type) {
            reqGet(
                brandapi.base,
                brandapi.bizInfoStorelist + "?brandCode=" + this.brandCode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            let storeInfo = [];
                            let storeUrl = "";
                            if (process.env.NODE_ENV === "production") {
                                storeUrl = "https://" + this.domain + ".bookinghere.co.kr";
                            } else {
                                storeUrl = "https://" + this.domain + ".bookinghere.co.kr";
                            }
                            result.data.forEach(function (stores) {
                                storeInfo.push({
                                    storeCode: stores.storeCode,
                                    storeName: stores.storeName,
                                    storeUrl: storeUrl
                                })
                            })
                            this.urlStoreList = storeInfo;
                            //this.urlStoreUsed = this.urlStoreList[0].storeUrl;
                            this.urlStoreUsed = storeUrl;
                            this.usehisdlg = true;
                        }
                    }
                }
            );
        },

        copyToClipboard(url) {
            var t = document.createElement("textarea");
            document.body.appendChild(t);
            t.value = url;
            t.select();
            document.execCommand('copy');
            document.body.removeChild(t);
        },

        urlStoreConvert(gdata, gidx) {
            return {
                A: gdata.storeName,
                B: gdata.storeUrl,
            };
        },

        urlStoreExcel() {
            var excelheader = XLSX.utils.json_to_sheet(
                [{
                    A: "매장명",
                    B: "URL",
                },], {
                skipHeader: true
            }
            );

            XLSX.utils.sheet_add_json(excelheader, this.urlStoreList.map(this.urlStoreConvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "매장별 도메인");
            // export Excel file
            XLSX.writeFile(wb, "매장별 도메인.xlsx");
        },


        refreshPage() {
            if (this.tabindex == 0) {
                reqGet(
                    brandapi.base,
                    brandapi.basicquery + "?brandCode=" + this.brandCode,
                    this.authToken,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.brandName = result.data.brandName; //브랜드 명칭
                                if (result.data.logoUrl != null) {
                                    this.logoUrl = result.data.logoUrl; //로고 이미지 URL
                                } else {
                                    this.logoUrl = ""; //로고 이미지 URL
                                }
                                this.brandType = result.data.brandType; //브랜드 타입 [HOTEL, FAMILY, BUFFET, SPECIAL, RESTAURANT, SPA]
                                this.domain = result.data.domain; //테이블엔조이 시스템에서 사용하는 도메인명
                                this.corporationName = result.data.corporationName; //법인명
                                this.salesPerson1Name = result.data.salesPerson1Name; //영업 담당자 1 이름
                                this.salesPerson1Phone = result.data.salesPerson1Phone; //영업 담당자 1 전화번호
                                this.salesPerson1Email = result.data.salesPerson1Email; //영업 담당자 1 이메일
                                this.salesPerson2Name = result.data.salesPerson2Name; //영업 담당자 2 이름
                                this.salesPerson2Phone = result.data.salesPerson2Phone; //영업 담당자 2 전화번호
                                this.salesPerson2Email = result.data.salesPerson2Email; //영업 담당자 2 이메일
                                this.accountantName = result.data.accountantName; //정산 담당자 이름
                                this.accountantPhone = result.data.accountantPhone; //정산 담당자 전화번호
                                this.accountantEmail = result.data.accountantEmail; //정산 담당자 이메일
                                this.registrationNumber = result.data.registrationNumber; //사업자 등록번호
                                this.registrationNumberUrl = result.data.registrationNumberUrl; //사업자 등록번호 이미지 url
                                this.commBizNumber = result.data.commBizNumber; //통신판매업 신고번호
                                this.commBizNumberUrl = result.data.commBizNumberUrl; //통신판매업 신고번호 이미지 url
                                this.useSite = result.data.useSite
                                if (result.data.tnjManagerID != null) {
                                    reqGet(
                                        adminapi.base,
                                        adminapi.staffinfo + "?username=" + result.data.tnjManagerID,
                                        this.authToken,
                                        (admininf) => {
                                            if (admininf != null) {
                                                if (admininf.code == rescode.REQ_SUCCESS) {
                                                    this.tnjManagerID = result.data.tnjManagerID; //테이블엔조이 담당자 ID
                                                    this.salesname = admininf.data.fullName;
                                                    // this.viewfullname = admininf.data.fullName;
                                                    this.salesemail = admininf.data.email;
                                                }
                                            }
                                        }
                                    );
                                    this.getUseHisData();
                                } else {
                                    this.tnjManagerID = ""; //테이블엔조이 담당자 ID
                                    this.salesname = "";
                                    // this.viewfullname = admininf.data.fullName;
                                    this.salesemail = "";
                                }
                                /*
              result.data.brandCode   String    //브랜드 코드
              result.data.registeredDate      String  UTC yyyy-MM-ddTHH:mm:ss     //등록일
              result.data.pendingStoreCount   Number    //등록 대기 매장 수
              */
                            } else {
                                this.brandName = ""; //브랜드 명칭
                            }
                        } else {
                            this.brandName = ""; //브랜드 명칭
                        }
                    }
                );
            } else if (this.tabindex == 1) { //
                reqGet(
                    brandapi.base,
                    brandapi.contractquery + "?brandCode=" + this.brandCode,
                    this.authToken,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.settlementBasePrice = result.data.contractInfo.settlementBasePrice; //정산 기준 가격
                                this.commissionRate = result.data.contractInfo.commissionRate; //수수료율 (퍼센티지)
                                this.issueTaxInvoice = result.data.contractInfo.issueTaxInvoice; //세금 계산서 발행 여부
                                this.taxInvoiceIssueDate = result.data.contractInfo.taxInvoiceIssueDate; //세금 계산서 발행 시기
                                this.shouldIssueAt1stDay = result.data.contractInfo.shouldIssueAt1stDay; //1일 발행 여부
                                if (this.shouldIssueAt1stDay == null) {
                                    this.shouldIssueAt1stDay = false;
                                }
                            } else {
                                // this.settlementBasePrice = "";
                                // this.commissionRate = "";
                                // this.issueTaxInvoice = false;
                                // this.taxInvoiceIssueDate = "";
                                // this.shouldIssueAt1stDay = false;
                                alert(result.message);
                            }
                        }
                    }
                );
            } else if (this.tabindex == 2) {
                reqGet(
                    brandapi.base,
                    brandapi.sitequery + "?brandCode=" + this.brandCode,
                    this.authToken,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.representative = result.data.siteInfo.representative; //대표자 성함
                                this.address.roadAddress = result.data.siteInfo.address; //주소
                                this.address.detailAddress = result.data.siteInfo.detailAddress; //주소
                                this.privacyPolicyManager = result.data.siteInfo.privacyPolicyManager; //개인정보관리자
                                this.privacyPolicyManagerEmail = result.data.siteInfo.privacyPolicyManagerEmail; //개인정보관리자 이메일
                                this.termsOfService = result.data.siteInfo.termsOfService; //이용약관
                                this.privacyStatement = result.data.siteInfo.privacyStatement; //개인정보처리방침
                                this.domainUrl = result.data.siteInfo.domainUrl; //루트도메인
                                this.centerNumber = result.data.siteInfo.centerNumber;
                                this.description = result.data.siteInfo.description;
                                this.showPoint = result.data.siteInfo.showPoint;
                                this.showInfo = result.data.siteInfo.showInfo;
                                // this.brandImages = result.data.siteInfo.brandImages; //메인 노출이미지
                            } else {
                                this.representative = ""; //대표자 성함
                                this.address.roadAddress = ""; //주소
                                this.address.detailAddress = ""; //주소
                                this.privacyPolicyManager = ""; //개인정보관리자
                                this.privacyPolicyManagerEmail = ""; //개인정보관리자 이메일
                                this.termsOfService = ""; //이용약관
                                this.privacyStatement = ""; //개인정보처리방침
                                this.domainUrl = ''; //루트도메인
                                this.centerNumber = '';
                                this.description = '';
                                this.showPoint = false;
                                this.showInfo = false;
                            }
                        } else {
                            this.representative = ""; //대표자 성함
                            this.address.roadAddress = ""; //주소
                            this.address.detailAddress = ""; //주소
                            this.privacyPolicyManager = ""; //개인정보관리자
                            this.privacyPolicyManagerEmail = ""; //개인정보관리자 이메일
                            this.termsOfService = ""; //이용약관
                            this.privacyStatement = ""; //개인정보처리방침
                            this.domainUrl = ''; //루트도메인
                            this.centerNumber = '';
                            this.description = '';
                            this.showPoint = false;
                            this.showInfo = false;
                        }
                        this.siteColorQuery();
                        this.siteImagesQuery();
                        this.siteBookingInfoQuery();
                        this.siteGiftMessageQuery();
                    }
                );
            }else if(this.tabindex == 3){
              reqGet(
                brandapi.base,
                brandapi.siteAlimtalk + "?brandCode=" + this.brandCode,
                this.authToken,
                (result) => {
                  console.log(result)
                  if (result != null) {
                    if (result.code == rescode.REQ_SUCCESS) {

                      this.alimtalkData.kakaoId = result.data.kakaoId
                      this.alimtalkData.kakaoName = result.data.kakaoName
                      this.alimtalkData.sendKey = result.data.sendKey
                      this.alimtalkData.status = result.data.status
                      this.alimtalkData.smsSendNumber = result.data.smsSendNumber
                      this.alimtalkData.smsRegisterDocumentUrl = result.data.smsRegisterDocumentUrl
                      this.alimtalkData.smsCertificateDocumentUrl = result.data.smsCertificateDocumentUrl
                      this.alimtalkData.smsRegister = result.data.smsRegister
                      this.alimtalkData.categoryCode = result.data.categoryData.categoryCode
                      this.alimtalkData.categoryLabels = result.data.categoryData
                      if(!this.alimtalkData.kakaoId && !this.alimtalkData.sendKey && !this.alimtalkData.smsSendNumber && !this.alimtalkData.smsRegister){
                        this.labelCheck = false
                      }else{
                        this.labelCheck = true
                      }
                    }
                  }
                }
              );
              this.getAlimtalkLargeForm();
            }
        },
        otpRequest(){
          // this.alimtalkData.kakaoId
          // this.alimtalkData.smsSendNumber
          let alertWord = ''
          if(!this.alimtalkData.kakaoId){
            alertWord += '카카오톡 채널 ID를 입력해 주세요.\n'
          }else if(!this.alimtalkData.kakaoId.startsWith('@',0)){
            alertWord += '카카오톡 채널 ID 형식이 올바르지 않습니다. 첫 문자는 @로 시작되어야 합니다. \n'
          }
          if(!this.alimtalkData.adminNumber){
            alertWord += '휴대폰번호를 입력해 주세요. \n'
          }

          if(!this.alimtalkData.kakaoId ||(this.alimtalkData.kakaoId && !this.alimtalkData.kakaoId.startsWith('@',0)) || !this.alimtalkData.adminNumber){
            alert(alertWord);
            return false;
          }else{
            reqPost(
              brandapi.base,
              brandapi.alimtalkBrandOtp,
              this.authToken, {
                kakaoId: this.alimtalkData.kakaoId,
                phoneNumber: this.alimtalkData.adminNumber,
              },
              (result) => {
                if (result != null) {
                  if (result.code == rescode.REQ_SUCCESS) {
                    alert("인증번호가 발급되었습니다.");
                    console.log(result)
                  } else {
                    alert(result.message);
                  }
                }
              }
            );
          }
        },
        regAlimtalk(){
          let alertWord = ''
          let alertWordAdd = ''
          if(!this.alimtalkData.kakaoId){
            alertWord += '카카오톡 채널 ID를 입력해 주세요.\n'
          }
          if(this.alimtalkData.kakaoId!=null && this.alimtalkData.kakaoId!=undefined && this.alimtalkData.kakaoId!='' && !this.alimtalkData.kakaoId.startsWith('@',0)){
            alertWord += '카카오톡 채널 ID 형식이 올바르지 않습니다. 첫 문자는 @로 시작되어야 합니다. \n'
          }
          if(!this.alimtalkData.adminNumber){
            alertWord += '휴대폰번호를 입력해 주세요. \n'
          }
          if(!this.alimtalkData.token){
            alertWord += '인증번호를 입력해 주세요. \n'
          }
          if(!this.alimtalkSmallForm) {
            alertWord += '카테고리를 입력해 주세요 \n'
          }
          let smsSendToggle = true
          let smsDocToggle = true
          let smsCertToggle = true
          if(this.alimtalkData.smsRegister){
            if(!this.alimtalkData.smsSendNumber){
              alertWordAdd += '발신 번호를 입력해 주세요. \n'
              smsSendToggle = false
            }
            if(!this.alimtalkData.smsRegisterDocumentUrl){
              alertWordAdd += '등록신청서를 입력해 주세요. \n'
              smsDocToggle = false
            }
            if(!this.alimtalkData.smsCertificateDocumentUrl){
              alertWordAdd += '통신사이용증명원을 입력해 주세요. \n'
              smsCertToggle = false
            }

          }

          if(!this.alimtalkData.kakaoId || !this.alimtalkData.kakaoId.startsWith('@',0) || !this.alimtalkData.adminNumber || !this.alimtalkData.adminNumber || !this.alimtalkData.token|| !this.alimtalkSmallForm){
            if(this.alimtalkData.smsRegister ){
              alert(alertWord + alertWordAdd);
            }else{
              alert(alertWord);
            }
            return false;
          } else if(this.alimtalkData.smsRegister && (!smsSendToggle || !smsDocToggle || !smsCertToggle) ){
            alert(alertWord + alertWordAdd);
            return false;
          }else{
            reqPost(
              brandapi.base,
              brandapi.alimtalkBrandReg,
              this.authToken, {
                type : "SITE",
                kakaoId : this.alimtalkData.kakaoId,
                token : this.alimtalkData.token,
                phoneNumber : this.alimtalkData.adminNumber,
                smsRegister : this.alimtalkData.smsRegister,
                smsSendNumber : this.alimtalkData.smsSendNumber,
                smsRegisterDocumentUrl : this.alimtalkData.smsRegisterDocumentUrl,
                smsCertificateDocumentUrl : this.alimtalkData.smsCertificateDocumentUrl,
                brandCode : this.brandCode,
                categoryCode: this.alimtalkSmallForm,
                registerType: 'NEW'
              },
              (result) => {
                if (result != null) {
                  if (result.code == rescode.REQ_SUCCESS) {
                    this.refreshPage();
                  } else {
                    alert(result.message);
                  }
                }
              }
            );
          }
        },
        updateAlimtalk(){
          reqPost(
            brandapi.base,
            brandapi.alimtalkBrandUpdate,
            this.authToken, {
              smsRegisterDocumentUrl : this.alimtalkData.smsRegisterDocumentUrl,
              smsCertificateDocumentUrl : this.alimtalkData.smsCertificateDocumentUrl,
              brandCode : this.brandCode
            },
            (result) => {
              if (result != null) {
                if (result.code == rescode.REQ_SUCCESS) {
                  this.refreshPage();
                  console.log(result)
                } else {
                  alert(result.message);
                }
              }
            }
          );
        },
      brandAlimtalkDisable(){
        reqPost(
          brandapi.base,
          brandapi.brandAlimtalkDisable,
          this.authToken, {
            brandCode : this.brandCode
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
                console.log(result)
              } else {
                alert(result.message);
              }
            }
          }
        );
      },
      brandAlimtalkApprove(){
        reqPost(
          brandapi.base,
          brandapi.brandAlimtalkApprove,
          this.authToken, {
            brandCode : this.brandCode
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
                console.log(result)
              } else {
                alert(result.message);
              }
            }
          }
        );
      },
      brandAlimtalkExistReApply(){
        reqPost(
          brandapi.base,
          brandapi.alimtalkBrandReg,
          this.authToken, {
            type : "SITE",
            kakaoId : this.alimtalkData.kakaoId,
            phoneNumber : this.alimtalkData.adminNumber,
            smsRegister : this.alimtalkData.smsRegister,
            smsSendNumber : this.alimtalkData.smsSendNumber,
            smsRegisterDocumentUrl : this.alimtalkData.smsRegisterDocumentUrl,
            smsCertificateDocumentUrl : this.alimtalkData.smsCertificateDocumentUrl,
            brandCode : this.brandCode,
            categoryCode: this.alimtalkData.categoryCode,
            registerType: 'EXISTREAPPLY'
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refreshPage();
              } else {
                alert(result.message);
              }
            }
          }
        );

      },
      getAlimtalkLargeForm(){
        reqGet(
          brandapi.base,
          brandapi.alimtalkLargeForm ,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              console.log(result.data)
              this.alimtalkLargeFormList = result.data.mainCategoryList
              this.alimtalkLargeFormList.unshift({mainCategoryCode:'',mainCategory:'대분류선택'})
            }
          }
        );
      },
      getAlimtalkMiddleForm(item){
        this.alimtalkMiddleFormList=[]
        this.alimtalkMiddleForm=""
        this.alimtalkSmallFormList=[]
        this.alimtalkSmallForm=""
        reqGet(
          brandapi.base,
          brandapi.alimtalkMiddleForm + "?mainCategoryCode=" + item,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              console.log(result.data)
              this.alimtalkMiddleFormList = result.data.middleCategoryList
              this.alimtalkMiddleFormList.unshift({middleCategoryCode:'',middleCategory:'중분류선택'})
            }
          }
        );
      },
      getAlimtalkSmallForm(item){
        this.alimtalkSmallFormList=[]
        this.alimtalkSmallForm=""
        reqGet(
          brandapi.base,
          brandapi.alimtalkSmallForm + "?middleCategoryCode=" + item,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              console.log(result.data)
              this.alimtalkSmallFormList = result.data.smallCategoryList
              this.alimtalkSmallFormList.unshift({smallCategoryCode:'',smallCategory:'소분류선택'})
            }
          }
        );
      },

    },
};
</script>

<style scoped>
th {
    background-color: #dee2e685;
}

.radio>label {
    margin-right: 10px;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.regmodal {
    height: 200px;
    overflow: scroll;
}

.nav-tabs li a {
    cursor: pointer;
}

.redmsg {
    color: #ff0000;
}

.blurmsg {
    color: #0000ff;
}

.spanbaseline span {
    display: inline-block;
    margin-right: 18px;
}

.spanbaseline span:nth-child(2) {
    width: 93%;
    margin: 0;
}

.colorbox1 {
    min-height: 29px;
    width: 29px;
}

.colorDiv {
    display: flex;
    justify-content: space-between;
    width: 59%;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 20px;
    margin-top: 20px;
    max-width: 209px;
}

.colorDiv>div>div {
    margin-right: 10px;
}

.colorDiv>div {
    display: flex;
}

.warp-div-color {
    width: 100%;
}

.showcolor>button {
    background: none;
    border: 1px solid #ababab;
    margin-top: 22px;
    padding: 5px 17px;
}

.showcolor {
    text-align: center;
}

.colorDiv>div>button {
    background: none;
    border: 1px solid #646464;
    color: #646464;
    padding: 3px 13px;
}

.colorchoiseon {
    background: #959595 !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    font-weight: 600;
}

.mr-1 {
    margin-right: 10px;
}

.setBtn {
    background: none;
    border: 1px solid #ababab;
    margin-top: 22px;
    padding: 5px 17px;
    margin-left: 20px;
}

.showcolor>button.choiseBtn {
    background: #337ab7;
    color: #fff;
    border: 1px solid #337ab7;
}

.mb-6 {
    margin-bottom: 60px;
}

.nullColor {
    background: url('/static/img/x.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 21px;
    height: 26px;
    background-size: contain;
}

.readOnly_imgDivBox img {
    display: inline-block;
    object-fit: cover;
    height: 250px;
    width: 250px;
}

.readOnly_imgDivBox {
    display: inline-block;
    max-width: 250px;
    max-height: 250px;
    margin-right: 10px;
}

button.btn.btn-default.disblock {
    display: block;
    margin-bottom: 10px;
}
.fw-500{
  font-weight: 500;
}
.fw-700{
  font-weight: 700;
}

</style>
