<template>

<div class="thumbnail" v-on:dragover="allowDrop" v-on:drop="ondrop($event,0)" @dragenter="onDragenter" v-bind:style="reqImgBackgroundChild !== '' && [{'background-image':'url('+reqImgBackgroundChild+')'}, {'background-size': 'cover'}]">
    <div class="dropboxMain" @click="onChangeClick"></div>
    <span v-show="reqImgBackgroundChild == ''" style="font-size:30px;cursor: pointer;" @click="onChangeClick"><i class="fa fa-fw fa-plus"></i></span>
    <span v-show="reqImgBackgroundChild !== ''" style="font-size:30px;cursor: pointer;" @click="onChangeClick"></span>
    <input v-if="!readonly" type="file" ref="fileInput" style="display:none;" accept="image/jpeg, image/png" @change="onUploadFiles" />
    <button type="button" v-if="!readonly" v-show="reqImgBackgroundChild !== ''" class="btn btn-close" @click="onRemoveClick()"><i class="fa fa-fw fa-close"></i></button>
    <button type="button" v-show="reqImgBackgroundChild !== ''" class="btn btn-preview" @click="onPreviewClick"><i class="fa fa-fw fa-search"></i></button>
    <button type="button" v-show="reqImgBackgroundChild !== ''" class="btn btn-download" @click="onDownloadClick"><i class="fa fa-fw fa-download"></i></button>
</div>
</template>

<script>
import {
    imgtype,
    rescode
} from "../lib/consts";
import {
    adminapi
} from "../lib/api";
import {
    getfilename,
    reqPost
} from "../lib/request";
import {
    ContainerClient
} from '@azure/storage-blob';
import axios from 'axios'
import Compressor from 'compressorjs';

export default {
    model: {
        prop: 'value',
    },
    props: {
        newsubimguse: {
            type: Boolean,
            default: false,
        },
        imgFile: {
            type: [Object, File],
            required: true,
            default: function () {
                return {
                    name: '',
                    _progress: 0
                }
            }
        },
        trueImg: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            // required: true,
            default: "",
        },
        downlabel: {
            type: String,
            // required: true,
            default: "",
        },
        readonly: {
            type: Boolean,
            // required: true,
            default: false,
        },
        imgtype: {
            type: String,
            default: imgtype.ETC,
        },
        change: {
            type: Function,
            default: () => {},
        },
        resizeWidth: {
            type: Number,
            default: 600
        }
    },
    data() {
        return {
            fileInput: null,
            windowRef: null,
            imgwidth: 0,
            imgheight: 0,
            qualityVal: 0,
            mw: 0,
            fakeArray: [{
                "width": 0,
                "height": 0,
                "url": '',
            }],
            reqImgBackgroundChild: this.value ? this.value : '',
        };
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {

    },
    watch: {
        value(val) {
            this.reqImgBackgroundChild = val;
        },
        trueImg(val) {
            // console.log(val)
            this.onUploadFiles(this.imgFile, true)
        }
    },
    methods: {
        allowDrop(event) {
            event.preventDefault();
        },
        drag(event) { //드래그 시작시 호출 할 함수
            event.dataTransfer.setData("Text", event.target.id);
            const targetEl = document.getElementById('holder');
        },
        onDragenter(event) {
            event.preventDefault()
        },
        onDragover(event) {
            event.preventDefault()
        },
        ondrop(event, idx) {
            event.preventDefault();
            var data = event.dataTransfer.getData("Text");
            let url2 = document.getElementById(data).src;
            // if (!this.newsubimguse) { // 대표이미지일경우 단일로만 이미지가 선택되어야하기떄문에 분기
            //     if (document.querySelector('.dropboxMain').childNodes.length == 1) {
            //         document.querySelector('.dropImgBox').appendChild(event.target.childNodes[0]);
            //     }
            // }
            if (event.currentTarget._prevClass !== 'dropImgBox') {
                if (this.newsubimguse) { //서브이지추가

                } else {
                    this.reqImgBackground = document.getElementById(data).src;
                    // event.target.appendChild(document.getElementById(data));

                    let width2 = document.getElementById(data).width;
                    let height2 = document.getElementById(data).height;
                    this.$emit('input', url2);
                    this.change(url2, width2, height2);
                    this.reqImgBackgroundChild = url2;
                    this.fakeArray = [{
                        "width": width2,
                        "height": height2,
                        "url": url2,
                    }]
                }
            }
        },
        onChangeClick() {
            if (this.readonly == false) {
                this.$refs.fileInput.click();
            }
        },
        compressImage(imageFile) {
            // console.log(imageFile.size)
            if (imageFile.size >= 90000) {
                this.qualityVal = 0.5;
                this.mw = 0;
            } else {
                this.qualityVal = 0.9;
                // this.mw = this.resizeWidth || 600;
                this.mw = 600;
            }
            return new Promise((resolve, reject) => {
                let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

                if (this.resizeWidth === 0) {
                    resolve(imageFile);
                } else if (!allowedExtensions.exec(imageFile.name)) {
                    resolve(imageFile);
                } else {

                    new Compressor(imageFile, {
                        maxWidth: this.mw,
                        quality: this.qualityVal,
                        success(result) {
                            resolve(result)
                        },
                        error(e) {
                            reject(e)
                        },
                    });
                }
            });
        },
        onUploadFiles(event, drag) {
            // console.log(event.target.files[0])
            let imageFile = '';
            if (drag) {
                imageFile = event;
            } else {
                imageFile = event.target.files[0];
            }

            this.compressImage(imageFile).then((compressedFile) => {
                let reader = new FileReader();
                reader.onload = evt => {
                    let img = new Image();
                    img.onload = () => {
                        this.imgwidth = img.width;
                        this.imgheight = img.height;
                    }
                    img.src = evt.target.result;
                }
                reader.onerror = evt => {
                    console.error(evt);
                }
                reader.readAsDataURL(compressedFile);
                // console.log(this.reqImgBackgroundChild.url)
                if (this.reqImgBackgroundChild === '' || this.reqImgBackgroundChild.url === undefined) {
                    this.onRegisterImage(compressedFile);
                } else {
                    this.onChangeImage(compressedFile);
                }
            }).catch((error) => {
                alert("이미지 리사이징 에러입니다.");
            });
            this.$refs.fileInput.value = ''
        },
        onRegisterImage(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            // console.log(result.data.url, result.data.directory);

                            const containerClient = new ContainerClient(result.data.url);
                            var ext = imageFile.name.substr(imageFile.name.lastIndexOf('.') + 1);
                            var d = new Date();
                            let filename = d.getTime() + "." + ext;
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                                // onProgress: ev => console.log(ev)
                            }).then((upfile) => {
                                let vm = this;
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(uploadedUrl, vm.imgwidth, vm.imgheight);
                                vm.reqImgBackgroundChild = uploadedUrl;
                            });
                        }
                    }
                }
            );
        },
        onChangeImage(imageFile) {
            reqPost(
                adminapi.base,
                adminapi.sas,
                this.authToken, {
                    type: this.imgtype,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            // console.log(result.data.url, result.data.directory);

                            // this.container = result.data.container;
                            // this.expiresIn = result.data.expiresIn;
                            const containerClient = new ContainerClient(result.data.url);
                            var ext = imageFile.name.substr(imageFile.name.lastIndexOf('.') + 1);
                            var d = new Date();
                            let filename = d.getTime() + "." + ext;
                            // const file = files[0];
                            const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
                            blockBlobClient.uploadBrowserData(imageFile, {
                                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                                blobHTTPHeaders: {
                                    blobContentType: imageFile.type
                                },
                                // onProgress: ev => console.log(ev)
                            }).then((upfile) => {
                                let vm = this;
                                var r_url = upfile._response.request.url;
                                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                                this.$emit('input', uploadedUrl);
                                this.change(uploadedUrl, vm.imgwidth, vm.imgheight);
                                vm.reqImgBackgroundChild = uploadedUrl;
                            });
                        }
                    }
                }
            );
        },
        onRemoveClick() {
            this.$emit('input', "");
            this.change("", 0, 0);
            let vm = this;
            vm.reqImgBackgroundChild = '';
            // // this.$emit('imgDragListUpdate', this.reqImgBackgroundChild, this.fakeArray);
            // if (document.querySelector('.dropboxMain').childNodes[0] !== undefined) { //반영할 이미지가 매장이미지일경우 다시 갓다놓기
            //     document.querySelector('.dropImgBox').appendChild(document.querySelector('.dropboxMain').childNodes[0]);
            // }
            // let veList = document.querySelector('.dropboxMain');
            // veList.removeChild(veList.childNodes[0]);
            // console.log(document.querySelector('.dropboxMain').childNodes);

        },
        onPreviewClick() {
            // console.log(this.reqImgBackgroundChild)
            this.windowRef = window.open(this.reqImgBackgroundChild, "", "width=600,height=400,left=200,top=200");
            this.windowRef.addEventListener('beforeunload', this.closePortal);
        },
        closePortal() {
            if (this.windowRef) {
                this.windowRef.close();
                this.windowRef = null;
            }
        },
        onDownloadClick() {
            axios.get(this.reqImgBackgroundChild, {
                    responseType: 'blob'
                })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf'
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    if (this.downlabel == "") {
                        if (!link.download.includes('jpg')) {
                            link.download = getfilename(this.reqImgBackgroundChild) + ".jpg";
                        } else {
                            link.download = getfilename(this.reqImgBackgroundChild);
                        }
                        // console.log(getfilename(this.reqImgBackgroundChild))
                    } else {
                        link.download = this.downlabel;
                    }
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
    },
};
</script>

<style scoped>
.thumbnail {
    width: 160px;
    height: 120px;
    justify-content: center;
    margin: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px;
    background-color: #E8E8E8;
}

.thumbnail span {
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    position: absolute;
    background-color: #FFFFFFA0;
}

.btn-close {
    top: 0px;
    right: 0px;
}

.btn-preview {
    left: 0px;
    bottom: 0px;
}

.btn-download {
    right: 0px;
    bottom: 0px;
}

.dropboxMain {
    width: 100%;
    height: 100%;
    position: absolute;
    /* display: none; */
}
</style>
