<template>
  <div class="box box-solid">
    <div class="box-body">
      <div class="table-responsive p-0" style="margin-top:10px">
        <table class="table table-hover text-nowrap">
          <colgroup>
            <col width="200px">
            <col width="100%">
          </colgroup>
          <tbody>
<!--          <tr>-->
<!--            <th>알림톡 발송</th>-->
<!--            <td>-->
<!--              <label style="margin-right:10px;">-->
<!--                <input type="radio" :value="true" v-model="useOwnAlimtalk" @change="onuseOwnAlimtalk"/>-->
<!--                사용-->
<!--              </label>-->
<!--              <label>-->
<!--                <input type="radio" :value="false" v-model="useOwnAlimtalk" @change="onuseOwnAlimtalk"/>-->
<!--                미사용-->
<!--              </label>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr v-if="useOwnAlimtalk">-->
<!--            <th>API Key</th>-->
<!--            <td>-->
<!--              <input type="text" class="form-control" v-model="alimtalkApiKey"/>-->
<!--            </td>-->
<!--          </tr>-->
          <tr>
            <th>CID</th>
            <td>
              <label style="margin-right:10px;">
                <input type="radio" :value="true" v-model="useCidAlimtalk"/>
                사용
              </label>
              <label>
                <input type="radio" :value="false" v-model="useCidAlimtalk"/>
                미사용
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center" style="margin-bottom: 20px;">
        <button type="button" class="btn btn-default" @click="savealimtalkinfo">저장</button>
      </div>

      <div class="table-responsive p-0" style="margin-top: 16px">
        <table class="table table-hover dataTable text-nowrap">
          <colgroup>
            <col width="150px">
            <col width="150px">
            <col width="50%">
            <col width="150px">
            <col width="50%">
          </colgroup>
          <tbody>
          <tr v-if="labelCheck&& storealimtalkData.kakaoId">
            <th>알림톡 발송</th>
            <td colspan="3">
              <div class="form-group" style="margin-bottom: 0px">
                {{storealimtalkData.status =='RegisterRequested' ? '등록신청':storealimtalkData.status =='Use' ? '사용중':'사용중지'}}
                <button type="button" v-if="storealimtalkData.status =='RegisterRequested'" class="btn btn-default" @click="storeAlimtalkApprove">등록완료</button>
                <button type="button" v-else-if="storealimtalkData.status =='Use'" class="btn btn-default" @click="storeAlimtalkDisable">사용중지</button>
                <button type="button" v-else class="btn btn-default margin-r-5" @click="storeAlimtalkExistReApply">등록신청</button>
                <span v-if="storealimtalkData.status =='UnUse'" class="text-red text-bold"> * 신규 카카오톡 채널로 등록신청 시 개발팀에 기존 정보 삭제 요청</span>
              </div>
            </td>
          </tr>
          <tr>
            <th>카카오톡 채널 ID</th>
            <td colspan="2">
              <div class="form-group" style="margin-bottom: 0px">
                <input v-if="!labelCheck" type="text" class="form-control" v-model="storealimtalkData.kakaoId" />
                <label v-else> {{`${storealimtalkData.kakaoId} (${storealimtalkData.kakaoName})`}}</label>
              </div>
            </td>
          </tr>
          <tr v-if="!labelCheck">
            <th>관리자 휴대폰 번호</th>
            <td colspan="2">
              <div class="form-group" style="display: inline-flex; margin-bottom: 0px;width:100%">
                <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
                  <input type="text" class="form-control col-2" v-model="storealimtalkData.adminNumber" />
                  <button type="button" class="btn btn-default" @click="otpRequest">인증번호 발송</button>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="!labelCheck">
            <th>인증번호</th>
            <td colspan="2">
              <div class="form-group" style="margin-bottom: 0px;min-width: 250px;">
                <input v-model="storealimtalkData.token" type="text" class="form-control" />
              </div>
            </td>
          </tr>
          <tr>
            <th>카테고리</th>
            <td colspan="3">
              <div v-if="!labelCheck" class="input-group d-inline" style="margin-bottom: 0px">
                <select class="form-control mr-2" style="width: 200px;" v-model="alimtalkLargeForm" @change="getAlimtalkMiddleForm(alimtalkLargeForm)">
                  <option v-for="item in alimtalkLargeFormList" :key="item.mainCategoryCode"
                          :value="item.mainCategoryCode">{{item.mainCategory}}</option>
                </select>
                <select class="form-control mr-2" style="width: 200px;" v-model="alimtalkMiddleForm" @change="getAlimtalkSmallForm(alimtalkMiddleForm)">
                  <option v-for="item in alimtalkMiddleFormList" :key="item.middleCategoryCode"
                          :value="item.middleCategoryCode">{{item.middleCategory}}</option>
                </select>
                <select class="form-control mr-2" style="width: 200px;" v-model="alimtalkSmallForm">
                  <option v-for="item in alimtalkSmallFormList" :key="item.smallCategoryCode"
                          :value="item.smallCategoryCode">{{item.smallCategory}}</option>
                </select>
              </div>
              <div v-else class="form-group" style="margin-bottom: 0px">
                <label>{{ storealimtalkData.categoryLabels.mainCategoryName +' > '+storealimtalkData.categoryLabels.middleCategoryName +' > '+ storealimtalkData.categoryLabels.smallCategoryName }}</label>
              </div>
            </td>
          </tr>
          <tr v-if="storealimtalkData.sendKey!==''&& storealimtalkData.sendKey!==null && storealimtalkData.sendKey!==undefined">
            <th>발신 프로필 키</th>
            <td colspan="3">
              <div class="form-group" style="margin-bottom: 0px">
                {{storealimtalkData.sendKey}}
              </div>
            </td>
          </tr>
          <tr>
            <th rowspan="4">발신번호 등록 신청</th>
            <th colspan="1">신청여부</th>
            <td colspan="1">
              <label v-if="!labelCheck">
                <input type="checkbox" v-model="storealimtalkData.smsRegister" > 신청
              </label>
              <label v-else>
                {{ storealimtalkData.smsRegister == true ? '신청' : '미신청' }}
              </label>
            </td>
          </tr>
          <tr>
            <th>발신번호</th>
            <td colspan="2">
              <template v-if="!labelCheck ">
                <input  type="text" class="form-control" v-model="storealimtalkData.smsSendNumber" >
              </template>
              <template v-else>
                <label> {{storealimtalkData.smsSendNumber}}</label>
              </template>
            </td>
          </tr>
          <tr>
            <th>등록신청서</th>
            <td colspan="3">
              <SingleImgUpload v-model="storealimtalkData.smsRegisterDocumentUrl" :imgtype="imgtype.STORE"/>
            </td>
          </tr>
          <tr>
            <th>통신사이용증명원</th>
            <td colspan="3">
              <SingleImgUpload v-model="storealimtalkData.smsCertificateDocumentUrl" :imgtype="imgtype.STORE"/>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="box-footer text-center">
          <button v-if="!labelCheck" type="button" class="btn btn-default" style="margin-left: 15px" @click="regAlimtalk()">
            등록신청
          </button>
          <button v-if="labelCheck" type="button" class="btn btn-default" style="margin-left: 15px" @click="updateAlimtalk()">
            저장
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
 import {brandapi} from "@/lib/api";
import {imgtype, rescode} from "@/lib/consts";
import SingleImgUpload from "@/components/SingleImgUpload.vue";

export default {
  components: {SingleImgUpload},
   data(){
     return{
       useOwnAlimtalk: false,
       alimtalkApiKey: "",
       usernameContaining: "",
       useCidAlimtalk: false,
       storeCode:'',
       storealimtalkData:{
         kakaoId: "",
         kakaoName:"",
         sendKey: "",
         status: "",
         smsSendNumber: false,
         smsRegisterDocumentUrl: "",
         smsCertificateDocumentUrl: "",
         smsRegister:false,
         token:"",
         categoryCode:"",
         categoryLabels: {}
       },
       labelCheck : false,
       alimtalkLargeForm:'',
       alimtalkLargeFormList:[],
       alimtalkMiddleForm:"",
       alimtalkMiddleFormList:[],
       alimtalkSmallForm:"",
       alimtalkSmallFormList:[],
     }
   },
   created() {
     this.storeCode = this.$route.query.stcode;
     this.getAlimtalkLargeForm()
   },
   mounted(){
     // this.getalimtalkinf();
     this.getNewalimtalinfo();
   },
   computed:{
     authToken() {
       return this.$store.getters.authToken;
     },
     imgtype() {
       return imgtype;
     }
   },
   methods:{
     onuseOwnAlimtalk() {
       if (this.useOwnAlimtalk == false) {
         this.alimtalkApiKey = null;
       }
     },
     savealimtalkinfo() {
       reqPost(
         brandapi.base,
         brandapi.alimtalkupdate,
         this.authToken, {
           storeCode: this.storeCode,
           useOwnAlimtalk: this.useOwnAlimtalk,
           alimtalkApiKey: this.alimtalkApiKey,
           useCidAlimtalk: this.useCidAlimtalk,
         },
         (result) => {
           if (result != null) {
             if (result.code == rescode.REQ_SUCCESS) {
               alert("저장되었습니다.");
             } else {
               alert(result.message);
             }
           }
         }
       );
     },
     getalimtalkinf() {
       reqGet(
         brandapi.base,
         brandapi.alimtalkSettings + "?storeCode=" + this.storeCode,
         this.authToken,
         (result) => {
           if (result != null && result.code == rescode.REQ_SUCCESS) {
             this.useOwnAlimtalk = result.data.useOwnAlimtalk;
             this.alimtalkApiKey = result.data.alimtalkApiKey;
             this.useCidAlimtalk = result.data.useCidAlimtalk;
           }
         }
       );
     },
     getNewalimtalinfo(){
       reqGet(
         brandapi.base,
         brandapi.newalimtalkSettings + "?storeCode=" + this.storeCode,
         this.authToken,
         (result) => {
           if (result != null && result.code == rescode.REQ_SUCCESS) {
             this.storealimtalkData.kakaoId = result.data.kakaoId
             this.storealimtalkData.kakaoName = result.data.kakaoName
             this.storealimtalkData.sendKey = result.data.sendKey
             this.storealimtalkData.status = result.data.status
             this.storealimtalkData.smsSendNumber = result.data.smsSendNumber
             this.storealimtalkData.smsRegisterDocumentUrl = result.data.smsRegisterDocumentUrl
             this.storealimtalkData.smsCertificateDocumentUrl = result.data.smsCertificateDocumentUrl
             this.storealimtalkData.smsRegister = result.data.smsRegister
             this.storealimtalkData.categoryCode = result.data.categoryData.categoryCode
             this.storealimtalkData.categoryLabels = result.data.categoryData

           }
           if (!this.storealimtalkData.kakaoId && !this.storealimtalkData.smsSendNumber && !this.storealimtalkData.smsRegister){
             this.labelCheck = false
           }else{
             this.labelCheck = true
           }
         }
       );
     },
     regAlimtalk(){
       let alertWord = ''
       let alertWordAdd = ''
       if(!this.storealimtalkData.kakaoId){
         alertWord += '카카오톡 채널 ID를 입력해 주세요.\n'
       }
       if(this.storealimtalkData.kakaoId!=null && this.storealimtalkData.kakaoId!=undefined && this.storealimtalkData.kakaoId!='' && !this.storealimtalkData.kakaoId.startsWith('@',0)){
         alertWord += '카카오톡 채널 ID 형식이 올바르지 않습니다. 첫 문자는 @로 시작되어야 합니다. \n'
       }
       if(!this.storealimtalkData.adminNumber){
         alertWord += '휴대폰번호를 입력해 주세요. \n'
       }
       if(!this.storealimtalkData.token){
         alertWord += '인증번호를 입력해 주세요. \n'
       }
       if(!this.alimtalkSmallForm) {
         alertWord += '카테고리를 입력해 주세요 \n'
       }
       let smsSendToggle = true
       let smsDocToggle = true
       let smsCertToggle = true
       if(this.storealimtalkData.smsRegister){
         if(!this.storealimtalkData.smsSendNumber){
           alertWordAdd += '발신 번호를 입력해 주세요. \n'
           smsSendToggle = false
         }
         if(!this.storealimtalkData.smsRegisterDocumentUrl){
           alertWordAdd += '등록신청서를 입력해 주세요. \n'
           smsDocToggle = false
         }
         if(!this.storealimtalkData.smsCertificateDocumentUrl){
           alertWordAdd += '통신사이용증명원을 입력해 주세요. \n'
           smsCertToggle = false
         }
       }

       if(!this.storealimtalkData.kakaoId || !this.storealimtalkData.kakaoId.startsWith('@',0) || !this.storealimtalkData.adminNumber || !this.storealimtalkData.adminNumber || !this.storealimtalkData.token ||!this.alimtalkSmallForm){
         if(this.storealimtalkData.smsRegister ){
           alert(alertWord + alertWordAdd);
         }else{
           alert(alertWord);
         }
         return false;
       }else if(this.storealimtalkData.smsRegister && (!smsSendToggle || !smsDocToggle || !smsCertToggle) ){
         alert(alertWord + alertWordAdd);
         return false;
       }else{
         reqPost(
           brandapi.base,
           brandapi.alimtalkStoreReg,
           this.authToken, {
             type : "STORE",
             kakaoId : this.storealimtalkData.kakaoId,
             token : this.storealimtalkData.token,
             phoneNumber : this.storealimtalkData.adminNumber,
             smsRegister : this.storealimtalkData.smsRegister,
             smsSendNumber : this.storealimtalkData.smsSendNumber,
             smsRegisterDocumentUrl : this.storealimtalkData.smsRegisterDocumentUrl,
             smsCertificateDocumentUrl : this.storealimtalkData.smsCertificateDocumentUrl,
             categoryCode: this.alimtalkSmallForm,
             storeCode : this.storeCode,
             registerType: 'NEW'

           },
           (result) => {
             if (result != null) {
               if (result.code == rescode.REQ_SUCCESS) {
                 this.getNewalimtalinfo();
               } else {
                 alert(result.message);
               }
             }
           }
         );
       }

     },
     updateAlimtalk(){
       reqPost(
         brandapi.base,
         brandapi.alimtalkStoreUpdate,
         this.authToken, {
           smsRegisterDocumentUrl : this.storealimtalkData.smsRegisterDocumentUrl,
           smsCertificateDocumentUrl : this.storealimtalkData.smsCertificateDocumentUrl,
           storeCode : this.storeCode
         },
         (result) => {
           if (result != null) {
             if (result.code == rescode.REQ_SUCCESS) {
               this.getNewalimtalinfo();
             } else {
               alert(result.message);
             }
           }
         }
       );
     },
     storeAlimtalkDisable(){
       reqPost(
         brandapi.base,
         brandapi.storeAlimtalkDisable,
         this.authToken, {
           storeCode : this.storeCode
         },
         (result) => {
           if (result != null) {
             if (result.code == rescode.REQ_SUCCESS) {
               this.getNewalimtalinfo();
             } else {
               alert(result.message);
             }
           }
         }
       );
     },
     storeAlimtalkApprove(){
       reqPost(
         brandapi.base,
         brandapi.storeAlimtalkApprove,
         this.authToken, {
           storeCode : this.storeCode
         },
         (result) => {
           if (result != null) {
             if (result.code == rescode.REQ_SUCCESS) {
               this.getNewalimtalinfo();
             } else {
               alert(result.message);
             }
           }
         }
       );
     },
     otpRequest(){
       let alertWord = ''
       if(!this.storealimtalkData.kakaoId){
         alertWord += '카카오톡 채널 ID를 입력해 주세요.\n'
       }else if(!this.storealimtalkData.kakaoId.startsWith('@',0)){
         alertWord += '카카오톡 채널 ID 형식이 올바르지 않습니다. 첫 문자는 @로 시작되어야 합니다. \n'
       }
       if(!this.storealimtalkData.adminNumber){
         alertWord += '휴대폰번호를 입력해 주세요. \n'
       }

       if(!this.storealimtalkData.kakaoId || (this.storealimtalkData.kakaoId && !this.storealimtalkData.kakaoId.startsWith('@',0)) || !this.storealimtalkData.adminNumber){
         alert(alertWord);
         return false;
       }else{
         reqPost(
           brandapi.base,
           brandapi.alimtalkBrandOtp,
           this.authToken, {
             kakaoId: this.storealimtalkData.kakaoId,
             phoneNumber: this.storealimtalkData.adminNumber,
           },
           (result) => {
             if (result != null) {
               if (result.code == rescode.REQ_SUCCESS) {
                 alert("인증번호가 발급되었습니다.");
                 console.log(result)
               } else {
                 alert(result.message);
               }
             }
           }
         );
       }
     },
     storeAlimtalkExistReApply(){
       reqPost(
         brandapi.base,
         brandapi.alimtalkStoreReg,
         this.authToken, {
           type : "STORE",
           kakaoId : this.storealimtalkData.kakaoId,

           phoneNumber : this.storealimtalkData.adminNumber,
           smsRegister : this.storealimtalkData.smsRegister,
           smsSendNumber : this.storealimtalkData.smsSendNumber,
           smsRegisterDocumentUrl : this.storealimtalkData.smsRegisterDocumentUrl,
           smsCertificateDocumentUrl : this.storealimtalkData.smsCertificateDocumentUrl,
           categoryCode: this.storealimtalkData.categoryCode,
           storeCode : this.storeCode,
           registerType: 'EXISTREAPPLY'
         },
         (result) => {
           if (result != null) {
             if (result.code == rescode.REQ_SUCCESS) {
               this.getNewalimtalinfo();
             } else {
               alert(result.message);
             }
           }
         }
       );

     },
     getAlimtalkLargeForm(){
       reqGet(
         brandapi.base,
         brandapi.alimtalkLargeForm ,
         this.authToken,
         (result) => {
           if (result != null && result.code == rescode.REQ_SUCCESS) {
             console.log(result.data)
             this.alimtalkLargeFormList = result.data.mainCategoryList
             this.alimtalkLargeFormList.unshift({mainCategoryCode:'',mainCategory:'대분류선택'})
           }
         }
       );
     },
     getAlimtalkMiddleForm(item){
       this.alimtalkMiddleFormList=[]
       this.alimtalkMiddleForm=""
       this.alimtalkSmallFormList=[]
       this.alimtalkSmallForm=""
       reqGet(
         brandapi.base,
         brandapi.alimtalkMiddleForm + "?mainCategoryCode=" + item,
         this.authToken,
         (result) => {
           if (result != null && result.code == rescode.REQ_SUCCESS) {
             console.log(result.data)
             this.alimtalkMiddleFormList = result.data.middleCategoryList
             this.alimtalkMiddleFormList.unshift({middleCategoryCode:'',middleCategory:'중분류선택'})
           }
         }
       );
     },
     getAlimtalkSmallForm(item){
       this.alimtalkSmallFormList=[]
       this.alimtalkSmallForm=""
       reqGet(
         brandapi.base,
         brandapi.alimtalkSmallForm + "?middleCategoryCode=" + item,
         this.authToken,
         (result) => {
           if (result != null && result.code == rescode.REQ_SUCCESS) {
             console.log(result.data)
             this.alimtalkSmallFormList = result.data.smallCategoryList
             this.alimtalkSmallFormList.unshift({smallCategoryCode:'',smallCategory:'소분류선택'})
           }
         }
       );
     },


   }
 }
</script>

<style>

</style>
