<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
          <div class="checkbox" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="ALL" v-model="saletype"/>전체
            </label>
            <label>
              <input type="radio" value="ONSALE" v-model="saletype"/>판매중
            </label>
            <label>
              <input type="radio" value="STOP" v-model="saletype"/>일시중지
            </label>
          </div>
          <select class="form-control" v-model="partnerType" style="width:auto;margin-right:10px">
            <option :value="item.value" v-for="(item,idx) in partnerList" :key = "idx" >
              {{item.name}}</option>
          </select>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsNameContaining">상품명</option>
            <option value="GoodsId">상품코드</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="showaddmodal">
        <i class="fa fa-plus"></i>상품추가
      </button>
      <button class="btn btn-default" style="margin-left:15px" @click="goRemoveGoods">
        <i class="fa fa-fw fa-remove"></i>상품삭제
      </button>
      <button class="btn btn-default" style="margin-left:25px" v-if="partnerType == 'LS'" @click="lsExcelData" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>LS상품리스트
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="downExcelData" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th>
              <input type="checkbox" class="allch" @change="allckech($event)"/>
            </th>
            <th class="text-center">번호</th>
            <th class="text-center">상품코드</th>
            <th>상품명</th>
            <th class="text-center">매칭개수</th>
            <th class="text-center">판매상태</th>
            <th class="text-center">연동사</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(match, index) in content" v-bind:key="index">
            <th>
              <input type="checkbox" class="chitem" :value="match.matchId"/>
            </th>
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{ match.goodsId }}</td>
            <td class="linkcmp" @click="showEditingModal(match.partnerType, match.matchId)">{{ match.goodsName }}</td>
            <td class="text-center" v-if="match.deleted">삭제</td>
            <td class="text-center" v-if="!match.deleted">{{ match.matchingCount }}</td>
            <td class="text-center">{{ getSaleState(match.onSale) }}</td>
            <td class="text-center">{{ getPartnerType(match.partnerType) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="addmodal && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>상품추가</h4>
          </div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="col-sm-5">
                <select class="form-control" v-model="addkeywordType">
                  <option value="NoKeyword"></option>
                  <option value="GoodsNameContaining">상품명</option>
                  <option value="GoodsId">상품코드</option>
                  <option value="SiteName">등록경로</option>
                </select>
              </div>
              <div class="col-sm-7">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="addkeyword"/>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" @click="searchAddGoods">검색</button>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-responsive addmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox" class="allgd" @change="allGoodch($event)"/>
                  </th>
                  <th>상품코드</th>
                  <th>상품명</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(good, didx) in searchgoods" :key="didx">
                  <td>
                    <input type="checkbox" class="gditem" :value="good.goodsId"/>
                  </td>
                  <td>{{ good.goodsId }}</td>
                  <td>{{ good.goodsName }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group" style="margin:0px">
              <select class="form-control" v-model="partnerType">
                <option :value="item.value" v-for="(item,idx) in partnerList" :key = "idx" >
                  {{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeaddmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="addGoodClick">등록</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="ebmodal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : 이브릿지</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>발급 코드 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeebmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateEBData">저장</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="hnmodal" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : 하나투어</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>포스 매칭</h5>
            <label>매장 정보</label>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>매장명</th>
                  <th>매장코드</th>
                  <th>하나투어 관광지코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(store, sidx) in matchinf.storeList" :key="sidx">
                  <td>{{ store.storeName }}</td>
                  <td>{{ store.storeCode }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="store.storeMatchCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <label>옵션 정보</label>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>하나투어 상품코드</th>
                  <th>하나투어 권종타입</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                  <td>
                    <div class="radio">
                      <label>
                        <input type="radio" value="A" v-model="match.matchManType"/>성인
                      </label>
                      <label>
                        <input type="radio" value="B" v-model="match.matchManType"/>청소년
                      </label>
                      <label>
                        <input type="radio" value="C" v-model="match.matchManType"/>소인
                      </label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closehnmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateHNData">저장</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="lsmodal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : LS 컴퍼니</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>정산 매장</h5>
            <div class="input-group" style="width:100%;display: inline-flex;flex-wrap: wrap;">
              <input type="text" class="form-control" :value="lsStoreCodeName" readonly style="flex:1;min-width:200px"/>
              <button type="button" class="btn btn-default" @click="showFindLsStore">지정</button>
            </div>
            <h5 style="margin-top: 20px">발급 코드 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closelsmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateLSData">저장</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="findstoredlg" :style="{'display': 'block'}">
      <div class="modal-dialog" style="width: 900px;">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">매장검색</div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <select class="form-control" style="width:auto;" v-model="storekeywordType">
                <option value="BrandCode">브랜드코드</option>
                <option value="BrandNameContaining">브랜드명</option>
                <option value="StoreCode">매장코드</option>
                <option value="StoreNameContaining">매장명</option>
                <option value="ManagerID">담당자 ID</option>
              </select>
              <div class="input-group" style="flex:1">
                <input type="text" class="form-control" v-model="storekeyword" @keyup.enter="searchLsStore"/>
                <span class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="searchLsStore">검색</button>
                </span>
              </div>
            </div>
            <div class="form-group">
              <div class="table-responsive ebmodal p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th>매장코드</th>
                    <th>브랜드명</th>
                    <th>매장명</th>
                    <th>운영상태</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="store in findstorelist" :key="store.storeCode">
                    <td>{{ store.storeCode }}</td>
                    <td>{{ store.brandName }}</td>
                    <td class="linkcmp" @click="onSetLsStore(store.storeCode, store.basicInfo.storeName, store.brandName)">{{ store.basicInfo.storeName }}</td>
                    <td>{{ store.enabled ? '활성화' : '비활성화' }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeFindLsStore">
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="ntrsmodal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : NTRS</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>키오스크(포스) 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closentrsmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateNtrsData">저장</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="obmodal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : 온더보더</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>포스 상품 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeobmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateObData">저장</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="defaultMatchModal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : {{ getPartnerType(this.partnerType) }}</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>포스 상품 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closedefaultmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateDefaultData">저장</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="kiccmodal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : KICC</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>포스 상품 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closekiccmodal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateKiccData">저장</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="sgModal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : 다인힐</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>포스 상품 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in matchinf.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeSgModal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateSgData">저장</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="kpcModal" :style="{'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h4>연동사 : 한국선불카드</h4>
          </div>
          <div class="modal-body form-horizontal">
            <h5>상품 매칭</h5>
            <div class="table-responsive ebmodal p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>옵션상태</th>
                  <th>옵션코드</th>
                  <th>옵션명</th>
                  <th>정상가</th>
                  <th>판매가</th>
                  <th>외부 상품코드</th>
                  <th>망 취소 가능 여부</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(match, midx) in kpcInfo.matchingList" :key="midx">
                  <td>{{ getSaleState(match.onSale) }}</td>
                  <td>{{ match.optionId }}</td>
                  <td>{{ match.optionName }}</td>
                  <td>{{ match.listPrice | numFormat }}</td>
                  <td>{{ match.salePrice | numFormat }}</td>
                  <td>
                    <input type="text" class="form-control" v-model="match.matchItemCode"/>
                  </td>
                  <td style="text-align:center" >
                    <div class="checkbox-wrapper-2">
                      <input type="checkbox" class="sc-gJwTLC ikxBAC" :value="false" v-model="match.isSystemCancel">
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeKpcModal">닫기</button>
            <button type="button" class="btn btn-default" @click="updateKpcData">저장</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {reqGet, reqPost} from "../../../lib/request";
import {goodsapi, b2bapi, brandapi} from "../../../lib/api";
import {DateFormat, getPartnerStr} from "../../../lib/utility";
import $ from 'jquery';
import XLSX from "xlsx";

export default {
  components: {
    Pagination,
  },
  created() {
    this.getPartnerList(false);

    if (localStorage.initval == "1") {
      if (localStorage.OPOUT_keywordType) {
        this.keywordType = localStorage.OPOUT_keywordType;
      }
      if (localStorage.OPOUT_keyword) {
        this.keyword = localStorage.OPOUT_keyword;
      }
      if (localStorage.OPOUT_Number) {
        this.pageNumber = parseInt(localStorage.OPOUT_Number);
      }
      if (localStorage.OPOUT_Size) {
        this.pageSize = parseInt(localStorage.OPOUT_Size);
      }
      if (localStorage.OPOUT_saletype) {
        this.saletype = localStorage.OPOUT_saletype;
      }
      if (localStorage.OPOUT_partnerType) {
        this.partnerType = localStorage.OPOUT_partnerType;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_OPR, title: "외부코드관리"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.OPOUT_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.OPOUT_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.OPOUT_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.OPOUT_Size = newsize;
    },
    saletype(newsale) {
      localStorage.OPOUT_saletype = newsale;
    },
    partnerType(newptype) {
      localStorage.OPOUT_partnerType = newptype;
    }
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      partnerType: "",
      keywordType: "NoKeyword",
      keyword: "",
      saletype: "ALL",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /*
      matchId           Number    매칭 ID
      goodsId           Number    상품 ID
      goodsName         String    상품명
      optionCount       Number    옵션 개수
      matchingCount     Number    매칭 개수
      onSale            Boolean   판매 여부
      deleted           Boolean   삭제 여부
      partnerType       String    연동사 코드[HN, TG, EB, LS]
      */
      exceldata: [],
      lsExceldata: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      addmodal: false,
      hnmodal: false,
      ebmodal: false,
      lsmodal: false,
      ntrsmodal: false,
      obmodal: false,
      kiccmodal: false,
      sgModal: false,
      kpcModal: false,
      defaultMatchModal:false,
      viewmatchId: null,
      matchinf: null,
      kpcInfo:null,

      addkeywordType: "NoKeyword",//추가
      addkeyword: "",//추가
      addpartner: "HN",//추가
      searchgoods: [],

      findstoredlg: false,
      storekeywordType: "BrandNameContaining",
      storekeyword: "",
      findstorelist: [],
      lsStoreCode: "",
      lsStoreName: "",
      lsBrandName: "",
      isSystemCancel:false,
      buttonDissabled : false,
      agentType:"",
      selectPartnerType:"",
      partnerList:[],
      defaultPartnerList:[]
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
    lsStoreCodeName() {
      if( this.lsStoreCode) {
        return `(${this.lsStoreCode}) ${this.lsBrandName} - ${this.lsStoreName}`;
      } else {
        return "";
      }
    }
  },
  mounted() {
    if (localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.OPOUT_keywordType = this.keywordType;
      localStorage.OPOUT_keyword = this.keyword;
      localStorage.OPOUT_Number = this.pageNumber;
      localStorage.OPOUT_Size = this.pageSize;
      localStorage.OPOUT_saletype = this.saletype;
      localStorage.OPOUT_partnerType = this.partnerType;
    }
    this.refreshPage();
  },
  methods: {
    getPartnerList(productAdd) {
      this.partnerList.length = 0;
      reqGet(
        b2bapi.base,
        b2bapi.partnerKeyList,
        this.authToken,
        (result) => {
          if (result != null && result.code === rescode.REQ_SUCCESS) {
            result.data.partnerTypeList.forEach(partnerInfo=> {
              this.partnerList.push({name:partnerInfo.partnerName+ " "+"("+partnerInfo.partnerType+")", value:partnerInfo.partnerType});
              this.defaultPartnerList.push({name:partnerInfo.partnerName, value:partnerInfo.partnerType});
            });
            if(!productAdd){
              this.partnerType = this.partnerList[0].value;
            }
          }
        }
      );
    },
    getPartnerType(partnerType){
      let partnerName = "";
      this.defaultPartnerList.forEach(partner => {
        if (partner.value === partnerType) {
          partnerName = partner.name;
        }
      })
      return partnerName;
    },

    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    allGoodch(event) {
      $('input:checkbox.gditem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    downExcelData() {
      this.buttonDissabled = true;
      var onSale = null;
      if (this.saletype == 'ALL') {
        onSale = "";
      } else if (this.saletype == 'ONSALE') {
        onSale = true;
      } else if (this.saletype == 'STOP') {
        onSale = false;
      }
      reqGet(
        b2bapi.base,
        b2bapi.matchingexcel +
        "?partnerType=" +
        this.partnerType +
        "&keywordType=" +
        this.keywordType +
        "&keyword=" +
        this.keyword + "&onSale=" + onSale,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    convert(match, index) {
      return {
        A: index + 1,
        B: match.goodsId,
        C: match.goodsName,
        D: match.deleted ? "삭제" : match.optionName,
        E: this.getSaleState(match.onSale),
        F: this.getPartnerType(match.partnerType),
      };
    },
    downExcel() {
      var excelhd = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "상품코드",
            C: "상품명",
            D: "옵션명",
            E: "판매상태",
            F: "연동사",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelhd, this.exceldata.map(this.convert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelhd, "외부코드관리");
      // export Excel file
      XLSX.writeFile(wb, "외부코드관리.xlsx");
      this.buttonDissabled = false;
    },
    lsExcelData() {
      this.buttonDissabled = true;
      reqGet(
        b2bapi.base,
        b2bapi.lslistexcel,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.lsExceldata = result.data;
              this.lsDownExcel();
            }else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    lsConvert() {
      var t_data = [];
      for (var idx = 0; idx < this.lsExceldata.length; idx++) {
        for(var idxx = 0; idxx < this.lsExceldata[idx].option.length; idxx++) {
          var celldata = {};
          celldata[0] = idx +1;
          celldata[1] = this.lsExceldata[idx].product_name;
          celldata[2] = this.lsExceldata[idx].option[idxx].optionId;
          celldata[3] = this.lsExceldata[idx].option[idxx].name;
          celldata[4] = this.lsExceldata[idx].option[idxx].normalPrice;
          celldata[5] = this.lsExceldata[idx].option[idxx].salePrice;
          celldata[6] = this.lsExceldata[idx].option[idxx].classify;
          celldata[7] = this.lsExceldata[idx].option[idxx].expireType;
          celldata[8] = this.lsExceldata[idx].option[idxx].expireStartDate;
          celldata[9] = this.lsExceldata[idx].option[idxx].expireEndDate;
          celldata[10] = this.lsExceldata[idx].option[idxx].expireDay;
          celldata[11] = this.lsExceldata[idx].option[idxx].startDate;
          celldata[12] = this.lsExceldata[idx].option[idxx].endDate;
          t_data.push(celldata);
        }
      }
      this.lsExceldata = t_data;
    },
    lsDownExcel() {
      var headrow = {};
      headrow[0] = "번호";
      headrow[1] = "업체명";
      headrow[2] = "외부연동코드";
      headrow[3] = "옵션명";
      headrow[4] = "정상가";
      headrow[5] = "판매가";
      headrow[6] = "상품 분류";
      headrow[7] = "유효기간 타입";
      headrow[8] = "유효기간 시작일";
      headrow[9] = "유효기간 종료일";
      headrow[10] = "유효기간 설정일";
      headrow[11] = "상품 판매 시작일";
      headrow[12] = "상품 판매 종료일";
      var excelhd = XLSX.utils.json_to_sheet(
        [headrow],
        {skipHeader: true}
      );
      this.lsConvert();
      XLSX.utils.sheet_add_json(excelhd, this.lsExceldata, {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelhd, "LS상품리스트");
      // export Excel file
      XLSX.writeFile(wb, "LS상품리스트.xlsx");
      this.buttonDissabled = false;
    },
    refreshPage(flag) {

      var pageNumber = 0;
      if (!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var onSale = null;
      if (this.saletype == 'ALL') {
        onSale = "";
      } else if (this.saletype == 'ONSALE') {
        onSale = true;
      } else if (this.saletype == 'STOP') {
        onSale = false;
      }

      setTimeout(()=>{
        reqGet(
          b2bapi.base,
          b2bapi.matchingpage +
          "?partnerType=" +
          this.partnerType +
          "&keywordType=" +
          this.keywordType +
          "&keyword=" +
          this.keyword +
          "&pageNumber=" +
          pageNumber +
          "&pageSize=" +
          this.pageSize + "&onSale=" + onSale,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.content = result.data.content;
                this.totalPages = result.data.totalPages; //전체 페이지 수
                this.totalElements = result.data.totalElements; //전체 아이템 수
                this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              } else {
                this.content = [];
                this.totalPages = 0; //전체 페이지 수
                this.totalElements = 0; //전체 아이템 수
                this.numberOfElements = 0; //현재 조회된 아이템 수
                alert(result.message);
              }
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          }
        );

      },300);
    },
    closeaddmodal() {
      this.addmodal = false;
    },
    showaddmodal() {
      this.addmodal = true;
      this.searchAddGoods();
      this.getPartnerList(true);
    },
    showEditingModal(partnerType, matchId) {
      this.viewmatchId = matchId;
      if (partnerType === "EB") {
        reqGet(
          b2bapi.base,
          b2bapi.ebmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.ebmodal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else if (partnerType === "HN") {
        reqGet(
          b2bapi.base,
          b2bapi.hnmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.hnmodal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else if (partnerType === "LS") {
        this.clearLsStore();

        reqGet(
          b2bapi.base,
          b2bapi.lsmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;

                if( result.data.storeCode) {
                  reqPost(
                    brandapi.base,
                    brandapi.admofStores,
                    this.authToken,
                    {
                      storeCodes: [result.data.storeCode],
                    },
                    (result) => {
                      if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                          this.setLsStore(result.data.byBrands[0].stores[0].storeCode, result.data.byBrands[0].stores[0].storeName, result.data.byBrands[0].brandName);
                          this.lsmodal = true;
                        }
                      }
                    }
                  );
                } else {
                  this.lsmodal = true;
                }
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else if (partnerType === "NTRS") {
        reqGet(
          b2bapi.base,
          b2bapi.ntrsmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.ntrsmodal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else if (partnerType === "OB") {
        reqGet(
          b2bapi.base,
          b2bapi.obmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.obmodal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      }else if (partnerType === "KICC") {
        reqGet(
          b2bapi.base,
          b2bapi.kiccmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.kiccmodal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      }else if (partnerType === "SG") {
        reqGet(
          b2bapi.base,
          b2bapi.sgmatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.sgModal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      }else if (partnerType === "KPC") {
        reqGet(
          b2bapi.base,
          b2bapi.kpcMatchingInfo + "?matchId=" + matchId,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                result.data.matchingList.forEach(option => {
                  if(option.isSystemCancel === null){
                    option.isSystemCancel = false;
                  }
                });
                this.kpcInfo = result.data;
                this.kpcModal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else{
        reqGet(
          b2bapi.base,
          b2bapi.defaultMatchingModalInfo + "?matchId=" + matchId + "&partnerType="+partnerType,
          this.authToken,
          (result) => {
            if (result != null) {
              if (result.code === rescode.REQ_SUCCESS) {
                this.matchinf = result.data;
                this.defaultMatchModal = true;
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    closeebmodal() {
      this.ebmodal = false;
    },
    closehnmodal() {
      this.hnmodal = false;
    },
    closelsmodal() {
      this.lsmodal = false;
    },
    closentrsmodal() {
      this.ntrsmodal = false;
    },
    closeobmodal() {
      this.obmodal = false;
    },
    closekiccmodal() {
      this.kiccmodal = false;
    },
    closedefaultmodal() {
      this.defaultMatchModal = false;
    },

    closeSgModal() {
      this.sgModal = false;
    },
    closeKpcModal() {
      this.kpcModal = false;
    },
    getSaleState(val) {
      if (val) {
        return "판매중";
      } else {
        return "일시중지";
      }
    },
    partner(val) {
      return getPartnerStr(val);
    },
    searchAddGoods() {
      reqGet(
        goodsapi.base,
        goodsapi.codeNameslist +
        "?keywordType=" +
        this.addkeywordType +
        "&keyword=" +
        this.addkeyword,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.searchgoods = result.data;
            } else {
              this.searchgoods = [];
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    goRemoveGoods() {
      var checkarray = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      $('input:checkbox.chitem').prop('checked', false);
      for (var didx = 0; didx < checkarray.length; didx++) {
        var t_content = this.content;
        t_content = t_content.filter(citem => citem.matchId != checkarray[didx]);
        this.content = t_content;
        reqPost(
          b2bapi.base,
          b2bapi.matchingdelete,
          this.authToken,
          {
            matchId: checkarray[didx],
          },
          () => {
          }
        );
      }
    },
    addGoodClick() {
      var goodsIdList = $('input:checkbox.gditem:checked').map(function () {
        return this.value;
      }).get();
      reqPost(
        b2bapi.base,
        b2bapi.matchingaddGoods,
        this.authToken,
        {
          partnerType: this.partnerType,
          goodsIdList: goodsIdList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.searchgoods = [];
              this.closeaddmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateHNData() {
      reqPost(
        b2bapi.base,
        b2bapi.hnmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          storeList: this.matchinf.storeList,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closehnmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateEBData() {
      reqPost(
        b2bapi.base,
        b2bapi.ebmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeebmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateLSData() {
      reqPost(
        b2bapi.base,
        b2bapi.lsmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          storeCode: this.lsStoreCode,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closelsmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateNtrsData() {
      reqPost(
        b2bapi.base,
        b2bapi.ntrsmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
                this.closentrsmodal();
                this.refreshPage();
            } else {
                alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateObData() {
      reqPost(
        b2bapi.base,
        b2bapi.obmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeobmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateKiccData() {
      reqPost(
        b2bapi.base,
        b2bapi.kiccmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closekiccmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateDefaultData() {
      reqPost(
        b2bapi.base,
        b2bapi.defaultmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          partnerType: this.partnerType,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closedefaultmodal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateSgData() {
      reqPost(
        b2bapi.base,
        b2bapi.sgmatchingItem,
        this.authToken,
        {
          matchId: this.matchinf.matchId,
          matchingList: this.matchinf.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeSgModal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    updateKpcData() {
      reqPost(
        b2bapi.base,
        b2bapi.kpcmatchingItem,
        this.authToken,
        {
          matchId: this.kpcInfo.matchId,
          matchingList: this.kpcInfo.matchingList
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeKpcModal();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          } else {
            this.searchgoods = [];
          }
        }
      );
    },
    closeFindLsStore() {
      this.findstorelist = [];
      this.findstoredlg = false;
    },
    showFindLsStore() {
      this.storekeywordType = "BrandNameContaining";
      this.storekeyword = "";
      this.findstoredlg = true;
    },
    onSetLsStore(storeCode, storeName, brandName) {
      this.setLsStore(storeCode, storeName, brandName);

      this.closeFindLsStore();
    },
    searchLsStore() {
      reqGet(
        brandapi.base,
        brandapi.basicInfoStorelist + "?keywordType=" + this.storekeywordType + "&keyword=" + this.storekeyword,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.findstorelist = result.data;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    setLsStore(storeCode, storeName, brandName) {
      this.lsStoreCode = storeCode;
      this.lsStoreName = storeName;
      this.lsBrandName = brandName;
    },
    clearLsStore() {
      this.lsStoreCode = "";
      this.lsStoreName = "";
      this.lsBrandName = "";
    },
    searchPartnerGoods() {
      var pageNumber = 0;
      if (!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var onSale = null;
      if (this.saletype == 'ALL') {
        onSale = "";
      } else if (this.saletype == 'ONSALE') {
        onSale = true;
      } else if (this.saletype == 'STOP') {
        onSale = false;
      }

      reqGet(
        b2bapi.base,
        b2bapi.matchingpage +
        "?partnerType=" +
        this.partnerType +
        "&keywordType=" +
        this.keywordType +
        "&keyword=" +
        this.keyword +
        "&pageNumber=" +
        pageNumber +
        "&pageSize=" +
        this.pageSize + "&onSale=" + onSale,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.addmodal {
  height: 250px;
  overflow: scroll;
}

.addmodal th {
  background-color: #dee2e685;
}

.addmodal::-webkit-scrollbar {
  display: none;
}

.ebmodal th {
  background-color: #dee2e685;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}


.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #6e79d6;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #535db3;
}

</style>
