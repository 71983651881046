<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items: center;">
          <div class="radio" style="margin-right:10px">
            <label>
              <input type="radio" value="ALL" v-model="salekey"/>전체
            </label>
            <label>
              <input type="radio" value="SALE" v-model="salekey"/>판매중
            </label>
            <label>
              <input type="radio" value="STOP" v-model="salekey"/>일시중지
            </label>
          </div>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsId">상품코드</option>
            <option value="GoodsNameContaining">상품명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
              <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th class="text-center">상품코드</th>
              <th class="text-center">상품상태</th>
              <th>상품명</th>
              <th class="text-center">옵션코드</th>
              <th class="text-center">옵션상태</th>
              <th>옵션명</th>
              <th class="text-center">정상가</th>
              <th class="text-center">판매가</th>
              <th class="text-center">오픈현황</th>
              <th class="text-center">메모</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(channel, cidx) in content" v-bind:key="cidx">
              <td class="text-center">{{channel.goodsId}}</td>
              <td class="text-center">{{getState(channel.goodsOnSale)}}</td>
              <td>{{channel.goodsName}}</td>
              <td class="text-center">{{channel.optionId}}</td>
              <td class="text-center">{{getState(channel.optionOnSale)}}</td>
              <td>{{channel.optionName}}</td>
              <td class="text-center">{{channel.listPrice | numFormat}}</td>
              <td class="text-center">{{channel.salePrice | numFormat}}</td>
              <td class="linkcmp text-center" @click="showaddmodal(channel.optionId)">{{channel.salesCount}}/{{channel.channelCount}}</td>
              <td class="text-center">
                <button type="button" class="btn btn-default" @click="openMemo(channel.goodsId)" >상세</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" :style="addmodal && {'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeaddmodal"><span aria-hidden="true">×</span></button>
            <h4 class="modal-title">채널별 오픈현황</h4>
          </div>
          <div class="modal-body form-horizontal">
            <div class="form-group table-responsive p-0" style="margin:0px">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>채널명</th>
                    <th>채널 판매 페이지</th>
                    <th>요청일</th>
                    <th>처리일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(channel, cidx) in channeldata" v-bind:key="cidx">
                    <td>{{channel.channelName}}</td>
                    <td><a :href="channel.channelSalesUrl" target="brank">{{channel.channelSalesUrl}}</a></td>
                    <td>{{viewDate(channel.requestDate)}}</td>
                    <td>{{viewDate(channel.releaseDate)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="memoToggle" :style="{'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">상품 메모</div>
          <div class="modal-body form-horizontal" >
            <textarea class="form-control" rows="10" disabled v-model="productMemo" ></textarea>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeMemo">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import { reqGet } from "../../../lib/request";
import {b2bapi, goodsapi} from "../../../lib/api";
import { DateFormat } from "../../../lib/utility";
import XLSX from "xlsx";
export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.B2BCHN_keywordType) {
        this.keywordType = localStorage.B2BCHN_keywordType;
      }
      if (localStorage.B2BCHN_keyword) {
        this.keyword = localStorage.B2BCHN_keyword;
      }
      if (localStorage.B2BCHN_Number) {
        this.pageNumber = parseInt(localStorage.B2BCHN_Number);
      }
      if (localStorage.B2BCHN_Size) {
        this.pageSize = parseInt(localStorage.B2BCHN_Size);
      }
      if (localStorage.B2BCHN_salekey) {
        this.salekey = localStorage.B2BCHN_salekey;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_B2B, title: "채널오픈현황"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.B2BCHN_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.B2BCHN_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.B2BCHN_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.B2BCHN_Size = newsize;
    },
    salekey(newfrom)
    {
      localStorage.B2BCHN_salekey = newfrom;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      salekey:"ALL",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * goodsId                      Number      상품 ID
       * goodsOnSale                  Boolean     상품 판매 여부
       * goodsName                    String      상품명
       * optionId                     Number      옵션 ID
       * optionOnSale                 Boolean     옵션 판매 여부
       * optionName                   String      옵션명
       * listPrice                    String      정상가
       * salePrice                    String      판매가
       * channelCount                 Number      배포요청 채널 개수
       * salesCount                   Number      매칭된 채널 개수
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      addmodal: false,
      channeldata:[],
      exceldata:[],
      buttonDissabled : false,
      memoToggle:false,
      productMemo:"",
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.B2BCHN_keywordType = this.keywordType;
      localStorage.B2BCHN_keyword = this.keyword;
      localStorage.B2BCHN_Number = this.pageNumber;
      localStorage.B2BCHN_Size = this.pageSize;
      localStorage.B2BCHN_salekey = this.salekey;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    viewDateTime(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var onSale = "";
      if(this.salekey == "SALE")
      {
        onSale = true;
      }else if(this.salekey == "STOP")
      {
        onSale = false;
      }
      reqGet(
        b2bapi.base,
        b2bapi.statusListpage +
          "?keywordType=" +
          this.keywordType +
          "&keyword=" +
          this.keyword +
          "&onSale=" +
          onSale +
          "&pageNumber=" +
          pageNumber +
          "&pageSize=" +
          this.pageSize,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    getExcelData() {
      this.buttonDissabled = true;
      var onSale = "";
      if(this.salekey == "SALE")
      {
        onSale = true;
      }else if(this.salekey == "STOP")
      {
        onSale = false;
      }
      reqGet(
        b2bapi.base,
        b2bapi.statusListexcel +
          "?keywordType=" +
          this.keywordType +
          "&keyword=" +
          this.keyword +
          "&onSale=" +
          onSale,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            } else {
              this.exceldata = [];
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    mergeData()
    {
      var t_data = [];
      for(var idx = 0; idx<this.exceldata.length; idx++)
      {
        var celldata = {};
        celldata[0] = this.exceldata[idx].goodsId;
        celldata[1] = this.getState(this.exceldata[idx].goodsOnSale);
        celldata[2] = this.exceldata[idx].goodsName;
        celldata[3] = this.exceldata[idx].optionId;
        celldata[4] = this.getState(this.exceldata[idx].optionOnSale);
        celldata[5] = this.exceldata[idx].optionName;
        celldata[6] = this.exceldata[idx].listPrice;
        celldata[7] = this.exceldata[idx].salePrice;
        for(var tidx = 0; tidx<this.exceldata[idx].salesChannelList.length; tidx++)
        {
          celldata[8+tidx] = this.exceldata[idx].salesChannelList[tidx].channelSalesUrl;

        }
        t_data.push(celldata);
      }
      this.exceldata = t_data;
    },
    downExcel() {
      var headrow = {};
      headrow[0] = "상품코드";
      headrow[1] = "상품상태";
      headrow[2] = "상품명";
      headrow[3] = "옵션코드";
      headrow[4] = "옵션상태";
      headrow[5] = "옵션명";
      headrow[6] = "정상가";
      headrow[7] = "판매가";
      if(this.exceldata.length > 0)
      {
        for(var tidx = 0; tidx<this.exceldata[0].salesChannelList.length; tidx++)
        {
          headrow[8+tidx] = this.exceldata[0].salesChannelList[tidx].channelName;
        }
      }
      var tmpheader = XLSX.utils.json_to_sheet(
        [
          headrow,
        ],
        { skipHeader: true }
      );
      this.mergeData();
      XLSX.utils.sheet_add_json(tmpheader, this.exceldata, {
        skipHeader: true,
        origin: "A2",
      });

      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, tmpheader, "채널오픈현황");
      // export Excel file
      XLSX.writeFile(wb, "채널오픈현황.xlsx");
      this.buttonDissabled = false;
    },
    closeaddmodal() {
      this.addmodal = false;
    },
    showaddmodal(optionId) {
      reqGet(
        b2bapi.base,
        b2bapi.statusDetail + "?optionId=" + optionId,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.channeldata = result.data;
              this.addmodal = true;
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    getState(val)
    {
      return val?"판매중":"일시중지";
    },
    openMemo(val){
      this.memoToggle = true;

      reqGet(
        goodsapi.base,
        goodsapi.productMemoList+`?goodsId=${val}`,
        this.authToken,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              let tmpArr = result.data.memoList
              console.log(tmpArr)

              if(tmpArr.length>0){
                tmpArr.forEach(item=>{
                  let tmpString = `• ${ this.viewDateTime(item.updateDateTime)} (${item.userName})\n ${item.memo}\n\n`
                  console.log(tmpString)
                  this.productMemo += tmpString
                })

              }

            } else {

              alert(result.message);
            }
          }
        }
      );
    },
    closeMemo(){
      this.productMemo = ""
      this.memoToggle = false;
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.radio{
  margin-bottom: 0px;
  margin-top:0px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.addmodal th {
  background-color: #dee2e685;
}

.addmodal::-webkit-scrollbar {
  display: none;
}

.modal-body .control-label{
  margin:0px;
}
</style>
