<template>
  <section class="content">
    <div class="box box-solid box-primary">
<!--      <div class="box-header">-->
<!--        <h3 class="box-title">수신번호 추가</h3>-->
<!--      </div>-->
      <div class="box-body form">
        <div class="form-group">
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="phoneNumber"
                     placeholder="전화번호(하이픈 제외)">
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" v-model="comment"
                     placeholder="코멘트">
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-default" @click="registerNumber">추가</button>
            </div>
          </div>
          <span class="help-block">
                        <b>번호등록시 개발서버에서 알림톡 수신이가능합니다.</b><br>
                    </span>
        </div>
      </div>
    </div>

    <div class="box box-solid box-primary">
      <div class="box-body form-horizontal">
        <div class="table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <colgroup>
              <col width="30%">
              <col width="60%">
              <col width="10%">
            </colgroup>
            <thead>
            <tr>
              <th>휴대폰 번호</th>
              <th>코멘트</th>
              <th>삭제</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item, idx) in phoneNumberList" :key="idx" >
              <td>{{item.phoneNumber}}</td>
              <td>{{item.comment}}</td>
              <td>
                <button type="button" class="btn btn-default" @click="deleteNumber(item.id, $event)"><i class="fa fa-fw fa-trash-o"></i></button>
              </td>
            </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>


  </section>
</template>

<script>
import {menuval, rescode} from "@/lib/consts";
import {reqGet, reqPost} from "@/lib/request";
import {message} from "@/lib/api";

export default {
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_DEV,
      path2: menuval.LV1_HOME,
      title: "알림톡 발송번호 관리",
    });
  },
  data(){
    return {
      phoneNumber: "",
      comment: "",
      phoneNumberList: [],
    };
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    }
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    refreshPage() {
      this.getPhoneNumberList();
    },
    getPhoneNumberList(){
      reqGet(
        message.base,
        message.testNumberList,
        this.authToken,
        (result) => {
          if (result != null && result.code === rescode.REQ_SUCCESS) {
            this.phoneNumberList = result.data.testNumberList;
          }
        }

      );
    },
    registerNumber(){
      let requestData;
      requestData = {
        phoneNumber: this.phoneNumber,
        comment: this.comment
      };
      reqPost(
        message.base,
        message.testNumberRegister,
        this.authToken,
        requestData,
        (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
          this.refreshPage();
        }

      );
    },
    deleteNumber(idid){
      let requestData;
      requestData = {
        id: idid
      };
      reqPost(
        message.base,
        message.testNumberDelete,
        this.authToken,
        requestData,
        (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
          this.refreshPage();
        }
      );

    }

  }
}
</script>

<style scoped>

</style>
