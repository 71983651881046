<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">영업정보</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">영업시간</label>
        <div class="col-sm-10">
          <div v-for="(opentime, i) in openingHours" :key="i" class="row" style="margin:0px">
            <OpenHours :value="opentime" :change="(editval)=>{onChangeOpentime(i, editval)}"/>
          </div>
          <button type="button" class="btn btn-default" @click="addOpenTime">
            <i class="fa fa-fw fa-plus"></i>시간 추가
          </button>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">Break-Time</label>
        <div class="col-sm-10">
          <div v-for="(brktime, ib) in breaktime" :key="ib" class="row" style="margin:0px">
            <BreakTime :value="brktime" :change="(editval)=>{onChangeBreakTime(ib, editval)}"/>
          </div>
          <button type="button" class="btn btn-default" @click="addBreakTime">
            <i class="fa fa-fw fa-plus"></i>시간 추가
          </button>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">주말설정 *</label>
        <div class="col-sm-10">
          <div class="row">
            <label class="col-sm-2 control-label">주말시작</label>
            <div class="col-sm-10">
              <WeekTime :weekval="startDayOfWeek" :hourval="startHourOfStartDay" :onWeek="resetStartWeek"
                        :onHour="resetStartHour"/>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 control-label">주말종료</label>
            <div class="col-sm-10">
              <WeekTime :weekval="endDayOfWeek" :hourval="endHourOfEndDay" :onWeek="resetEndWeek"
                        :onHour="resetEndHour"/>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 control-label">공휴일포함여부</label>
            <div class="col-sm-10 radio" style="display:inline-flex;">
              <label>
                <input type="radio" :value="true" v-model="includeNationalHoliday"/>
                주말에 포함
              </label>
              <label>
                <input type="radio" :value="false" v-model="includeNationalHoliday"/>
                주말에 포함하지 않음
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="form-group">
          <label class="col-sm-2 control-label">주차안내</label>
          <div class="col-sm-10">
              <div class="radio">
                  <label>
                      <input type="radio" :value="true" v-model="parkingAvailable" />
                      불가능
                  </label>
                  <label>
                      <input type="radio" :value="false" v-model="parkingAvailable" />
                      가능
                  </label>
              </div>
          </div>
      </div> -->
      <div class="form-group">
        <label class="col-sm-2 control-label">편의시설</label>
        <div class="col-sm-10">
          <label v-for="(item,idx) in amenity" :key="idx" class="mr-2">
            <input type="checkbox" v-model="item.checked" :value="item.id" class="amenity" :checked="item.checked">
            {{
              item.amenityName
            }}
          </label>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="saverunningInfo">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {rescode} from "@/lib/consts";
import OpenHours from "@/components/OpenHours";
import BreakTime from "@/components/BreakTime";
import WeekTime from "@/components/WeekTime";


export default {
  data() {
    return {
      amenityId: [],
      amenity: [],
      openingHours: [], //
      breaktime: [], //
      /*
      {
        dayOfWeekType   String          Everyday: 매일, Weekday: 주중, Weekend: 주말, Custom: 직접 지정
                        [Everyday, Weekday, Weekend, Custom]
        monday          Boolean       dayOfWeekType 이 Custom 일 경우 사용: 월요일을 지정할 때 true 로 설정
        tuesday         Boolean       dayOfWeekType 이 Custom 일 경우 사용: 화요일을 지정할 때 true 로 설정
        wednesday       Boolean       dayOfWeekType 이 Custom 일 경우 사용: 수요일을 지정할 때 true 로 설정
        thursday        Boolean       dayOfWeekType 이 Custom 일 경우 사용: 목요일을 지정할 때 true 로 설정
        friday          Boolean       dayOfWeekType 이 Custom 일 경우 사용: 금요일을 지정할 때 true 로 설정
        saturday        Boolean       dayOfWeekType 이 Custom 일 경우 사용: 토요일을 지정할 때 true 로 설정
        sunday          Boolean       dayOfWeekType 이 Custom 일 경우 사용: 일요일을 지정할 때 true 로 설정
        openingHourType String        Open24Hours: 24시간 영업, RunningTime: 영업 시간 지정, BreakTime: 휴식 시간 지정
                        [Open24Hours, RunningTime, BreakTime]
        timeFrom        String        openingHourType 이 Custom 일 경우 영업 시작 시간을 지정
                        HH:mm:ss
        timeTo          String        openingHourType 이 Custom 일 경우 영업 종료 시간을 지정
                        HH:mm:ss
        comment         String        부가 설명
      }*/
      startDayOfWeek: 0, //주말 시작일 ISO-8601 standard: 1(Monday) ~ 7(Sunday)
      startHourOfStartDay: null, //주말 시작일의 시작 시간. null 일 경우 하루종일을 의미
      endDayOfWeek: 0, //주말 종료일  ISO-8601 standard: 1(Monday) ~ 7(Sunday)
      endHourOfEndDay: null, //주말 종료일의 끝나는 시간. null 일 경우 하루종일을 의미
      includeNationalHoliday: false, //공휴일을 주말에 포함할 지 여부
      // parkingAvailable: false, //주차 가능 여부
      parkingDetails: "", //주차 상세 안내
      amenitiesDetails: [], //편의 시설 안내
      storeCode:'',
    }
  },
  components:{
    OpenHours,
    BreakTime,
    WeekTime,
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  computed:{
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted(){
    this.getrunningInfo();
  },
  methods: {
    addOpenTime() {
      var newOpen = {
        dayOfWeekType: "Everyday",
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        openingHourType: "Open24Hours",
        timeFrom: null,
        timeTo: null,
        comment: ""
      };
      this.openingHours.push(newOpen);
    },
    async getrunningInfo() {
      reqGet(
        brandapi.base,
        brandapi.tnjInfoquery + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.openingHours = result.data.openingHours.filter(open => open.openingHourType != "BreakTime");
              this.breaktime = result.data.openingHours.filter(open => open.openingHourType == "BreakTime");

              // this.openingHours = result.data.openingHours;
              this.startDayOfWeek = result.data.weekendSettings.startDayOfWeek; //주말 시작일 ISO-8601 standard: 1(Monday) ~ 7(Sunday)
              if (this.startDayOfWeek == null)
                this.startDayOfWeek = 6;
              this.startHourOfStartDay = result.data.weekendSettings.startHourOfStartDay; //주말 시작일의 시작 시간. null 일 경우 하루종일을 의미
              this.endDayOfWeek = result.data.weekendSettings.endDayOfWeek; //주말 종료일  ISO-8601 standard: 1(Monday) ~ 7(Sunday)
              if (this.endDayOfWeek == null)
                this.endDayOfWeek = 7;
              this.endHourOfEndDay = result.data.weekendSettings.endHourOfEndDay; //주말 종료일의 끝나는 시간. null 일 경우 하루종일을 의미
              this.includeNationalHoliday = result.data.weekendSettings.includeNationalHoliday; //공휴일을 주말에 포함할 지 여부
              if (this.includeNationalHoliday == null)
                this.includeNationalHoliday = false;
              this.amenityId = result.data.amenitiesInfo.amenityId; //편의 시설 안내

              this.getamenity();

            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getamenity() {
      reqGet(
        brandapi.base,
        brandapi.storeAmenitylist,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.amenity = [];
              let newAmenity = [];
              result.data.forEach(el => {
                if (el.enabled) {
                  newAmenity.push(el)
                }
              });
              let vm = this;
              vm.amenity = newAmenity;
              let nenen = [];
              this.amenity.forEach(el => {
                let newam = {
                  checked: false,
                  id: el.id,
                  enabled: el.enabled,
                  registeredDate: el.registeredDate,
                  amenityName: el.amenityName,
                }
                nenen.push(newam);
              });
              vm.amenityId.forEach((el, idx) => {
                vm.amenity.forEach((am, adx) => {

                  if (el === am.id) {
                    nenen[adx].checked = true;
                  }
                });
              });
              this.amenity = nenen;
              // console.log(nenen)
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onChangeOpentime(index, editval) {
      if (editval == null) {
        this.openingHours.splice(index, 1);
      } else {
        this.openingHours[index] = {
          ...editval
        };
      }
    },
    onChangeBreakTime(index, editval) {
      if (editval == null) {
        this.breaktime.splice(index, 1);
      } else {
        this.breaktime[index] = {
          ...editval
        };
      }
    },
    addBreakTime() {
      var newOpen = {
        dayOfWeekType: "Everyday",
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        openingHourType: "BreakTime",
        timeFrom: null,
        timeTo: null,
        comment: ""
      };
      this.breaktime.push(newOpen);
    },
    saverunningInfo() {
      var amenity = $('input:checkbox.amenity:checked').map(function () {
        return this.value;
      }).get();
      if (amenity.length === 0) {
        alert('편의시설을 선택해주세요.');
        return;
      }
      var openingHours = this.openingHours.concat(this.breaktime);
      var weekendSettings = {
        startDayOfWeek: this.startDayOfWeek,
        startHourOfStartDay: this.startHourOfStartDay,
        endDayOfWeek: this.endDayOfWeek,
        endHourOfEndDay: this.endHourOfEndDay,
        includeNationalHoliday: this.includeNationalHoliday
      };
      var amenitiesInfo = {
        amenityId: amenity,
      };

      var reqdata = {
        storeCode: this.storeCode,
        openingHours: openingHours,
        weekendSettings: weekendSettings,
        amenitiesInfo: amenitiesInfo
      };
      reqPost(
        brandapi.base,
        brandapi.tnjInfoupdate,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.$emit('get-kakao-alert', 'STORE');
              alert("저장되었습니다.");
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    resetStartWeek(val) {
      this.startDayOfWeek = val;
    },
    resetStartHour(val) {
      this.startHourOfStartDay = val;
    },
    resetEndWeek(val) {
      this.endDayOfWeek = val;
    },
    resetEndHour(val) {
      this.endHourOfEndDay = val;
    },


  }
}
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.tbl_h200 {
  height: 200px;
  overflow: scroll;
}

.nav-tabs li a {
  cursor: pointer;
}

.col-inline {
  display: inline-flex;
  align-items: center;
}

.btn-selweek {
  background-color: #5B9BD5;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .sm-inline {
    display: flex;
  }
}

.redmsg {
  color: #ff0000;
}

.blurmsg {
  color: #0000ff;
}

.nnos input,
.nnos select {
  display: inline-block;
}

.refInputDiv {
  display: inline-block;
  width: 172px;
  margin-left: 10px;
}

.srdivbox {
  margin-top: 10px;
}

.refTitle {
  min-width: 120px;
  display: inline-block;
}

.mt-1 {
  margin-top: 10px;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}

.mr-2 {
  margin-right: 10px;
}

.waring {
  color: red;
  margin-top: 5px;
}
</style>
