<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">에이전시등록</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">에이전시</label>
        <div class="col-sm-10 table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>아이디</th>
              <th>에이전시명</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{
                  agentUsername_n
                }}
              </td>
              <td>{{
                  agentfullname
                }}
              </td>
              <td>
                <button v-if="agentUsername_n == null" type="button" class="btn btn-default" @click="showSelectAid">
                  선택
                </button>
                <button v-if="agentUsername_n != null" type="button" class="btn btn-default btn-social-icon"
                        style="margin-left:10px" @click="delAgentInf">
                  <i class="fa fa-fw fa-trash-o"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="saveAgentInf">저장</button>
      </div>
    </div>
    <div class="modal" :style="showselAgentdlg && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">에이전시 아이디 조회</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label for="agentid" class="col-sm-4 control-label">아이디</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input type="email" class="form-control" v-model="usernameContaining"/>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button" @click="searchAgent">검색</button>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-responsive tbl_h200 p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th></th>
                  <th>아이디</th>
                  <th>이름</th>
                  <th>이메일</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(admin, aidx) in adminslist" :key="aidx">
                  <td>
                    <input type="radio" :value="admin.username" v-model="seladminuser"
                           @click="onSelAdmin(admin.fullName)"/>
                  </td>
                  <td>
                    {{ admin.username }}
                  </td>
                  <td>
                    {{ admin.fullName }}
                  </td>
                  <td>
                    {{ admin.email }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeSelAidDialog">닫기</button>
            <button type="button" class="btn btn-default" @click="onSelAgent">지정</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {reqGet, reqPost} from "@/lib/request";
import {adminapi, brandapi} from "@/lib/api";
import {rescode} from "@/lib/consts";

export default {
  data() {
    return {
      /* ------------agentUserInf--------------- */
      agentUsername: null,
      agentUsername_n: null,
      agentfullname: "",
      adminslist: [],
      seladminuser: "",
      seladminname: "",
      storeCode: "",
      showselAgentdlg: false,
      usernameContaining: "",
    }
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  mounted() {
    this.getagentUserName();
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  methods: {
    closeSelAidDialog() {
      this.showselAgentdlg = false;
    },
    searchAgent() {
      reqGet(
        adminapi.base,
        adminapi.agentlist + "?usernameContaining=" + this.usernameContaining,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.adminslist = result.data.admins;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getagentUserName() {
      reqGet(
        brandapi.base,
        brandapi.tnjagentUsername + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.agentUsername = result.data.agentUsername;
            this.agentUsername_n = result.data.agentUsername;
            if (this.agentUsername != null) {
              reqGet(
                adminapi.base,
                adminapi.staffinfo + "?username=" + this.agentUsername,
                this.authToken,
                (admininf) => {
                  if (admininf != null && admininf.code == rescode.REQ_SUCCESS) {
                    this.agentfullname = admininf.data.fullName;
                  }
                });
            }
          }
        }
      );
    },
    showSelectAid() {
      this.showselAgentdlg = true;
    },
    delAgentInf() {
      this.agentUsername_n = null;
      this.agentfullname = null;
    },
    onSelAdmin(fullname) {
      this.seladminname = fullname;
    },
    onSelAgent() {
      this.agentUsername_n = this.seladminuser;
      this.agentfullname = this.seladminname;
      this.closeSelAidDialog();
    },
    saveAgentInf() {
      if (this.agentUsername_n == null) {
        if (this.agentUsername != null) {
          reqPost(
            brandapi.base,
            brandapi.tnjagentdelete,
            this.authToken, {
              storeCode: this.storeCode,
              agentUsername: this.agentUsername,
            },
            (result) => {
              if (result != null) {
                if (result.code == rescode.REQ_SUCCESS) {
                  this.agentUsername = null;
                  alert("삭제되었습니다.");
                } else {
                  alert(result.message);
                }
              }
            }
          );
        }
      } else {
        reqPost(
          brandapi.base,
          brandapi.tnjagentupdate,
          this.authToken, {
            storeCode: this.storeCode,
            agentUsername: this.agentUsername_n,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.agentUsername = this.agentUsername_n;
                alert("설정되었습니다.");
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
  }

}
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.tbl_h200 {
  height: 200px;
  overflow: scroll;
}

.nav-tabs li a {
  cursor: pointer;
}

.col-inline {
  display: inline-flex;
  align-items: center;
}

.btn-selweek {
  background-color: #5B9BD5;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .sm-inline {
    display: flex;
  }
}

.redmsg {
  color: #ff0000;
}

.blurmsg {
  color: #0000ff;
}

.nnos input,
.nnos select {
  display: inline-block;
}

.refInputDiv {
  display: inline-block;
  width: 172px;
  margin-left: 10px;
}

.srdivbox {
  margin-top: 10px;
}

.refTitle {
  min-width: 120px;
  display: inline-block;
}

.mt-1 {
  margin-top: 10px;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}

.mr-2 {
  margin-right: 10px;
}

.waring {
  color: red;
  margin-top: 5px;
}
</style>
