<template>
  <div class="box box-solid">
    <div class="box-header">
      <h3 class="box-title">계약정보</h3>
    </div>
    <div class="box-body form-horizontal">
      <div class="form-group">
        <label class="col-sm-2 control-label">정산 수수료(TNJ)</label>
        <div class="col-sm-10">

          <div class="row sm-inline" style="margin:0px">
            <div class="table-responsive p-0" style="margin-top:0px">
              <table class="table table-hover text-nowrap" style="margin-bottom: 10px;">
                <thead>
                <tr>
                  <th>정산기준</th>
                  <th>수수료</th>
                  <th>적용시작일</th>
                  <th>요청자</th>
                  <th v-if="lastDefaultCommission != null">
                    <button type="button" class="btn btn-default" @click="showContractHis(true)">지난내역보기</button>
                  </th>
                  <th>
                    <button type="button" class="btn btn-default" style="margin-right:10px"
                            @click="toggleChangingDefaultCommission">
                      {{ changingDefaultCommissionButtonLabel }}
                    </button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="lastDefaultCommission != null">
                  <td>{{
                      getBasicPrice(lastDefaultCommission)
                    }}
                  </td>
                  <td>{{
                      getcmssRate(lastDefaultCommission)
                    }}
                  </td>
                  <td>{{
                      geteffDate(lastDefaultCommission)
                    }}
                  </td>
                  <td>{{
                      getrequester(lastDefaultCommission)
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row sm-inline" style="margin:0px">
            <div class="row sm-inline" style="margin:0px; padding:3px 5px; background-color: #FFF2CC"
                 v-if="changingDefaultCommission">
              <div class="input-group" style="display:inline-flex">
                <div class="radio">
                  <label>
                    <input type="radio" value="ListPrice" v-model="addingDefaultCommissionBasis"/>
                    정상가
                  </label>
                  <label>
                    <input type="radio" value="SalePrice" v-model="addingDefaultCommissionBasis"/>
                    판매가
                  </label>
                </div>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" style="margin:0px; text-align: right; width: 100px;"
                       v-model="addingDefaultCommissionRate"/>
                <span class="input-group-addon"
                      style="border:none;width: fit-content; background-color: transparent">%</span>
              </div>
              <DatePicker style="margin-left: 10px;" v-if="addingDefaultCommissionEffDate != null"
                          v-model="addingDefaultCommissionEffDate"/>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group" style="margin-top: 20px">
        <label class="col-sm-2 control-label">정산 수수료(자체판매)</label>
        <div class="col-sm-10">

          <div class="row sm-inline" style="margin:0px">
            <div class="table-responsive p-0" style="margin-top:0px">
              <table class="table table-hover text-nowrap" style="margin-bottom: 10px;">
                <thead>
                <tr>
                  <th>정산기준</th>
                  <th>수수료</th>
                  <th>적용시작일</th>
                  <th>요청자</th>
                  <th v-if="lastSelfSalesCommission != null">
                    <button type="button" class="btn btn-default" @click="showContractHis(false)">지난내역보기</button>
                  </th>
                  <th>
                    <button type="button" class="btn btn-default" style="margin-right:10px"
                            @click="toggleChangingSelfSalesCommission">
                      {{ changingSelfSalesCommissionButtonLabel }}
                    </button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="lastSelfSalesCommission != null">
                  <td>
                    {{ getBasicPrice(lastSelfSalesCommission) }}
                  </td>
                  <td>
                    {{ getcmssRate(lastSelfSalesCommission) }}
                  </td>
                  <td>
                    {{ geteffDate(lastSelfSalesCommission) }}
                  </td>
                  <td>
                    {{ getrequester(lastSelfSalesCommission) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row sm-inline" style="margin:0px">
            <div class="row sm-inline" style="margin:0px; padding:3px 5px; background-color: #FFF2CC"
                 v-if="changingSelfSalesCommission">
              <div class="input-group" style="display:inline-flex">
                <div class="radio">
                  <label>
                    <input type="radio" value="ListPrice" v-model="addingSelfSalesCommissionBasis"/>
                    정상가
                  </label>
                  <label>
                    <input type="radio" value="SalePrice" v-model="addingSelfSalesCommissionBasis"/>
                    판매가
                  </label>
                </div>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" style="margin:0px; text-align: right; width: 100px;"
                       v-model="addingSelfSalesCommissionRate"/>
                <span class="input-group-addon"
                      style="border:none;width: fit-content; background-color: transparent">%</span>
              </div>
              <DatePicker style="margin-left: 10px;" v-if="addingSelfSalesCommissionEffDate != null"
                          v-model="addingSelfSalesCommissionEffDate"/>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group" style="margin-top: 20px">
        <label class="col-sm-2 control-label">정산 수수료(B2B)</label>
        <div class="col-sm-10">

          <div class="row sm-inline" style="margin:0px">
            <div class="table-responsive p-0" style="margin-top:0px">
              <table class="table table-hover text-nowrap" style="margin-bottom: 10px;">
                <thead>
                <tr>
                  <th>정산기준</th>
                  <th>수수료</th>
                  <th>적용시작일</th>
                  <th>요청자</th>
                  <th v-if="lastB2bSalesCommission != null">
                    <button type="button" class="btn btn-default" @click="showContractHis('b2b')">지난내역보기</button>
                  </th>
                  <th>
                    <button type="button" class="btn btn-default" style="margin-right:10px"
                            @click="toggleChangingB2bSalesCommission">
                      {{ changingB2bSalesCommissionButtonLabel }}
                    </button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="lastB2bSalesCommission != null">
                  <td>
                    {{ getBasicPrice(lastB2bSalesCommission) }}
                  </td>
                  <td>
                    {{ getcmssRate(lastB2bSalesCommission) }}
                  </td>
                  <td>
                    {{ geteffDate(lastB2bSalesCommission) }}
                  </td>
                  <td>
                    {{ getrequester(lastB2bSalesCommission) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row sm-inline" style="margin:0px">
            <div class="row sm-inline" style="margin:0px; padding:3px 5px; background-color: #FFF2CC"
                 v-if="changingB2bSalesCommission">
              <div class="input-group" style="display:inline-flex">
                <div class="radio">
                  <label>
                    <input type="radio" value="ListPrice" v-model="addingB2bSalesCommissionBasis"/>
                    정상가
                  </label>
                  <label>
                    <input type="radio" value="SalePrice" v-model="addingB2bSalesCommissionBasis"/>
                    판매가
                  </label>
                </div>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" style="margin:0px; text-align: right; width: 100px;"
                       v-model="addingB2bSalesCommissionRate"/>
                <span class="input-group-addon"
                      style="border:none;width: fit-content; background-color: transparent">%</span>
              </div>
              <DatePicker style="margin-left: 10px;" v-if="addingB2bSalesCommissionEffDate != null"
                          v-model="addingB2bSalesCommissionEffDate"/>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group" style="margin-top: 20px">
        <label class="col-sm-2 control-label">정산 수수료(아이프레임)</label>
        <div class="col-sm-10">

          <div class="row sm-inline" style="margin:0px">
            <div class="table-responsive p-0" style="margin-top:0px">
              <table class="table table-hover text-nowrap" style="margin-bottom: 10px;">
                <thead>
                <tr>
                  <th>정산기준</th>
                  <th>수수료</th>
                  <th>적용시작일</th>
                  <th>요청자</th>
                  <th v-if="lastIframeSalesCommission != null">
                    <button type="button" class="btn btn-default" @click="showContractHis('iframe')">지난내역보기</button>
                  </th>
                  <th>
                    <button type="button" class="btn btn-default" style="margin-right:10px"
                            @click="toggleChangingIframeSalesCommission">
                      {{ changingIframeSalesCommissionButtonLabel }}
                    </button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="lastIframeSalesCommission != null">
                  <td>
                    {{ getBasicPrice(lastIframeSalesCommission) }}
                  </td>
                  <td>
                    {{ getcmssRate(lastIframeSalesCommission) }}
                  </td>
                  <td>
                    {{ geteffDate(lastIframeSalesCommission) }}
                  </td>
                  <td>
                    {{ getrequester(lastIframeSalesCommission) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row sm-inline" style="margin:0px">
            <div class="row sm-inline" style="margin:0px; padding:3px 5px; background-color: #FFF2CC"
                 v-if="changingIframeSalesCommission">
              <div class="input-group" style="display:inline-flex">
                <div class="radio">
                  <label>
                    <input type="radio" value="ListPrice" v-model="addingIframeSalesCommissionBasis"/>
                    정상가
                  </label>
                  <label>
                    <input type="radio" value="SalePrice" v-model="addingIframeSalesCommissionBasis"/>
                    판매가
                  </label>
                </div>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" style="margin:0px; text-align: right; width: 100px;"
                       v-model="addingIframeSalesCommissionRate"/>
                <span class="input-group-addon"
                      style="border:none;width: fit-content; background-color: transparent">%</span>
              </div>
              <DatePicker style="margin-left: 10px;" v-if="addingIframeSalesCommissionEffDate != null"
                          v-model="addingIframeSalesCommissionEffDate"/>
            </div>
          </div>

        </div>
      </div>
      <div class="form-group" style="margin-top: 20px">
        <label class="col-sm-2 control-label">정산 수수료(카카오 예약하기)</label>
        <div class="col-sm-10">

          <div class="row sm-inline" style="margin:0px">
            <div class="table-responsive p-0" style="margin-top:0px">
              <table class="table table-hover text-nowrap" style="margin-bottom: 10px;">
                <thead>
                <tr>
                  <th>정산기준</th>
                  <th>수수료</th>
                  <th>적용시작일</th>
                  <th>요청자</th>
                  <th v-if="lastKakaoSalesCommission != null">
                    <button type="button" class="btn btn-default" @click="showContractHis('kakao')">지난내역보기</button>
                  </th>
                  <th>
                    <button type="button" class="btn btn-default" style="margin-right:10px"
                            @click="toggleChangingKakaoSalesCommission">
                      {{ changingKakaoSalesCommissionButtonLabel }}
                    </button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="lastKakaoSalesCommission != null">
                  <td>
                    {{ getBasicPrice(lastKakaoSalesCommission) }}
                  </td>
                  <td>
                    {{ getcmssRate(lastKakaoSalesCommission) }}
                  </td>
                  <td>
                    {{ geteffDate(lastKakaoSalesCommission) }}
                  </td>
                  <td>
                    {{ getrequester(lastKakaoSalesCommission) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row sm-inline" style="margin:0px">
            <div class="row sm-inline" style="margin:0px; padding:3px 5px; background-color: #FFF2CC"
                 v-if="changingKakaoSalesCommission">
              <div class="input-group" style="display:inline-flex">
                <div class="radio">
                  <label>
                    <input type="radio" value="ListPrice" v-model="addingKakaoSalesCommissionBasis"/>
                    정상가
                  </label>
                  <label>
                    <input type="radio" value="SalePrice" v-model="addingKakaoSalesCommissionBasis"/>
                    판매가
                  </label>
                </div>
              </div>
              <div class="input-group">
                <input type="text" class="form-control" style="margin:0px; text-align: right; width: 100px;"
                       v-model="addingKakaoSalesCommissionRate"/>
                <span class="input-group-addon"
                      style="border:none;width: fit-content; background-color: transparent">%</span>
              </div>
              <DatePicker style="margin-left: 10px;" v-if="addingKakaoSalesCommissionEffDate != null"
                          v-model="addingKakaoSalesCommissionEffDate"/>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group" style="margin-top: 50px">
        <label class="col-sm-2 control-label">정산 횟수</label>
        <div class="col-sm-10">
          <select class="form-control" style="width: auto;" v-model="countsett">
            <option value=1>1회</option>
            <option value=2>2회</option>
            <option value=3>3회</option>
            <option value=4>4회</option>
            <option value=5>5회</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 control-label">정산기준일</label>
        <div class="col-sm-10">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <tbody>
              <tr>
                <td>
                  <label class="control-label">시작일</label>
                </td>
                <td>
                  <label class="control-label">1일</label>
                </td>
                <td></td>
              </tr>
              <tr v-for="(dsett, dsidx) in settlements" :key="dsidx">
                <td v-if="dsidx < countsett">
                  <label class="control-label">{{ dsidx + 1 }}차정산일</label>
                </td>
                <td v-if="dsidx < countsett">
                  <select v-if="dsidx+1 < countsett" class="form-control" style="width: auto;"
                          :value="dsett.salesEndDate" @change="onChangesalesEndDate(dsidx, $event)">
                    <!-- <option value="1">1일</option> -->
                    <option value="2">2일</option>
                    <option value="3">3일</option>
                    <option value="4">4일</option>
                    <option value="5">5일</option>
                    <option value="6">6일</option>
                    <option value="7">7일</option>
                    <option value="8">8일</option>
                    <option value="9">9일</option>
                    <option value="10">10일</option>
                    <option value="11">11일</option>
                    <option value="12">12일</option>
                    <option value="13">13일</option>
                    <option value="14">14일</option>
                    <option value="15">15일</option>
                    <option value="16">16일</option>
                    <option value="17">17일</option>
                    <option value="18">18일</option>
                    <option value="19">19일</option>
                    <option value="20">20일</option>
                    <option value="21">21일</option>
                    <option value="22">22일</option>
                    <option value="23">23일</option>
                    <option value="24">24일</option>
                    <option value="25">25일</option>
                    <option value="26">26일</option>
                    <option value="27">27일</option>
                    <option value="28">28일</option>
                    <option value="29">29일</option>
                    <option value="30">30일</option>
                    <!-- <option value="31">말일</option> -->
                  </select>
                  <label v-if="dsidx+1 == countsett" class="control-label">말일</label>
                </td>
                <td v-if="dsidx < countsett">
                  <div class="input-group" style="display:inline-flex">
                    <div class="radio">
                      <label>
                        <input type="radio" :name="'dsett' + dsidx" value="ThisMonth"
                               v-model="dsett.settlementMonth"/>
                        당월
                      </label>
                      <label>
                        <input type="radio" :name="'dsett' + dsidx" value="NextMonth"
                               v-model="dsett.settlementMonth"/>
                        익월
                      </label>
                      <label>
                        <input type="radio" :name="'dsett' + dsidx" value="MonthAfterNext"
                               v-model="dsett.settlementMonth"/>
                        익익월
                      </label>
                    </div>
                    <select class="form-control" style="width: auto; margin-left:20px" :value="dsett.settlementDate"
                            @change="onChangesettlementDate(dsidx, $event)">
                      <option value="1">1일</option>
                      <option value="2">2일</option>
                      <option value="3">3일</option>
                      <option value="4">4일</option>
                      <option value="5">5일</option>
                      <option value="6">6일</option>
                      <option value="7">7일</option>
                      <option value="8">8일</option>
                      <option value="9">9일</option>
                      <option value="10">10일</option>
                      <option value="11">11일</option>
                      <option value="12">12일</option>
                      <option value="13">13일</option>
                      <option value="14">14일</option>
                      <option value="15">15일</option>
                      <option value="16">16일</option>
                      <option value="17">17일</option>
                      <option value="18">18일</option>
                      <option value="19">19일</option>
                      <option value="20">20일</option>
                      <option value="21">21일</option>
                      <option value="22">22일</option>
                      <option value="23">23일</option>
                      <option value="24">24일</option>
                      <option value="25">25일</option>
                      <option value="26">26일</option>
                      <option value="27">27일</option>
                      <option value="28">28일</option>
                      <option value="29">29일</option>
                      <option value="30">30일</option>
                      <option value="31">말일</option>
                    </select>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="savesettlement">저장</button>
      </div>
    </div>

    <div class="modal" :style="showcnthisdialog && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">지난내역보기</div>
          <div class="modal-body form-horizontal">
            <div class="table-responsive tbl_h200 p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  <th>번호</th>
                  <th>정산기준</th>
                  <th>적용 수수료</th>
                  <th>적용기간</th>
                  <th>요청자</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(cmmss, cmmssidx) in modalTargetCommissions" :key="cmmssidx">
                  <td>
                    {{ cmmssidx + 1 }}
                  </td>
                  <td>
                    {{ getBasicPrice(cmmss) }}
                  </td>
                  <td>
                    {{ getcmssRate(cmmss) }}
                  </td>
                  <td>
                    {{ geteffDate(cmmss) }}
                  </td>
                  <td>
                    {{ getrequester(cmmss) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeCntHisDialog">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateFormat} from "@/lib/utility";
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {rescode} from "@/lib/consts";
import moment from "moment/moment";
import DatePicker from "@/components/DatePicker.vue";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      showcnthisdialog: false,
      countsett: 1,
      settlements: [ //정산 기준 정보
        {
          salesEndDate: 1,
          settlementMonth: "ThisMonth",
          settlementDate: 31
        },
        {
          salesEndDate: 1,
          settlementMonth: "ThisMonth",
          settlementDate: 31
        },
        {
          salesEndDate: 1,
          settlementMonth: "ThisMonth",
          settlementDate: 31
        },
        {
          salesEndDate: 1,
          settlementMonth: "ThisMonth",
          settlementDate: 31
        },
        {
          salesEndDate: 31,
          settlementMonth: "ThisMonth",
          settlementDate: 31
        }
      ],
      /*
        salesEndDate      Number    정산 대상 판매 종료일. 말일의 경우 항상 31을 입력
        settlementMonth   String    ThisMonth: 당월, NextMonth: 익월, MonthAfterNext: 익익월
                          [ThisMonth, NextMonth, MonthAfterNext]
        settlementDate    Number    정산일
      */
      defaultCommissions: [], //기본 정산 수수료 정보 내역
      selfSalesCommissions: [], //매장 자체판매 정산 수수료 정보 내역
      b2bSalesCommissions: [], // b2b 정산 수수료 정보 내역
      iframeSalesCommissions: [], // iframe 정산 수수료 정보 내역
      kakaoSalesCommissions: [], // kakao 정산 수수료 정보 내역
      /*
      basis               String      정산 기준가
                  [ListPrice, SalePrice]
      commissionRate      String      수수료율
      effectiveDate       String      적용일
      requester           String      요청자
      */
      lastDefaultCommission: null, //가장 최신의 기본 정산 수수료 정보
      addingDefaultCommissionBasis: "ListPrice",
      addingDefaultCommissionRate: "0",
      addingDefaultCommissionEffDate: null,

      changingDefaultCommission: false,

      lastSelfSalesCommission: null, //가장 최신의 매장 자체 판매 정산 수수료 정보
      addingSelfSalesCommissionBasis: "ListPrice",
      addingSelfSalesCommissionRate: "0",
      addingSelfSalesCommissionEffDate: null,

      changingSelfSalesCommission: false,

      lastB2bSalesCommission: null, //가장 최신의 b2b 판매 정산 수수료 정보
      addingB2bSalesCommissionBasis: "ListPrice",
      addingB2bSalesCommissionRate: "0",
      addingB2bSalesCommissionEffDate: null,

      changingB2bSalesCommission: false,

      lastIframeSalesCommission: null, //가장 최신의 iframe 판매 정산 수수료 정보
      addingIframeSalesCommissionBasis: "ListPrice",
      addingIframeSalesCommissionRate: "0",
      addingIframeSalesCommissionEffDate: null,

      changingIframeSalesCommission: false,

      lastKakaoSalesCommission: null, //가장 최신의 매장 자체 판매 정산 수수료 정보

      addingKakaoSalesCommissionBasis: "ListPrice",
      addingKakaoSalesCommissionRate: "0",
      addingKakaoSalesCommissionEffDate: null,

      changingKakaoSalesCommission: false,

      modalTargetCommissions: null,
      storeCode: "",

      /*
       basis               String      정산 기준가
                   [ListPrice, SalePrice]
       commissionRate      String      수수료율
       effectiveDate       String      적용일
       requester           String      요청자
       */
    }
  },
  mounted() {
    this.getsettlementInf();
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  computed: {
    changingSelfSalesCommissionButtonLabel() {
      return this.changingSelfSalesCommission ? "취소" : this.lastSelfSalesCommission != null ? "변경" : "등록";
    },
    changingB2bSalesCommissionButtonLabel() {
      return this.changingB2bSalesCommission ? "취소" : this.lastB2bSalesCommission != null ? "변경" : "등록";
    },
    changingDefaultCommissionButtonLabel() {
      return this.changingDefaultCommission ? "취소" : this.lastDefaultCommission != null ? "변경" : "등록";
    },
    changingIframeSalesCommissionButtonLabel() {
      return this.changingIframeSalesCommission ? "취소" : this.lastIframeSalesCommission != null ? "변경" : "등록";
    },
    changingKakaoSalesCommissionButtonLabel() {
      return this.changingKakaoSalesCommission ? "취소" : this.lastKakaoSalesCommission != null ? "변경" : "등록";
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  methods: {
    closeCntHisDialog() {
      this.modalTargetCommissions = null;
      this.showcnthisdialog = false;
    },
    showContractHis(defaultCommission) {
      if (defaultCommission) {
        this.modalTargetCommissions = this.defaultCommissions;
      } else {
        this.modalTargetCommissions = this.selfSalesCommissions;
      }
      if (defaultCommission == 'b2b') {
        this.modalTargetCommissions = this.b2bSalesCommissions;
      } else if (defaultCommission == 'iframe') {
        this.modalTargetCommissions = this.iframeSalesCommissions;
      }else if (defaultCommission == 'kakao') {
        this.modalTargetCommissions = this.kakaoSalesCommissions;
      }

      this.showcnthisdialog = true;
    },
    toggleChangingDefaultCommission() {
      this.changingDefaultCommission = !this.changingDefaultCommission;
    },
    getBasicPrice(val) {
      if (val == null)
        return "";
      if (val.basis == "ListPrice") {
        return "정상가";
      } else if (val.basis == "SalePrice") {
        return "판매가";
      } else {
        return "";
      }
    },
    getcmssRate(val) {
      if (val == null)
        return "";
      return val.commissionRate + "%";
    },
    geteffDate(val) {
      if (val == null)
        return "";
      return DateFormat(val.effectiveDate, "YYYY-MM-DD", false);
    },
    getrequester(val) {
      if (val == null)
        return "";
      return val.requester;
    },
    onChangesalesEndDate(index, evnet) {
      this.settlements[index].salesEndDate = evnet.target.value;
    },
    onChangesettlementDate(index, evnet) {
      this.settlements[index].settlementDate = evnet.target.value;
    },
    toggleChangingSelfSalesCommission() {
      this.changingSelfSalesCommission = !this.changingSelfSalesCommission;
    },
    toggleChangingB2bSalesCommission() {
      this.changingB2bSalesCommission = !this.changingB2bSalesCommission;
    },
    toggleChangingIframeSalesCommission() {
      this.changingIframeSalesCommission = !this.changingIframeSalesCommission;
    },
    toggleChangingKakaoSalesCommission() {
      this.changingKakaoSalesCommission = !this.changingKakaoSalesCommission;
    },

    savesettlement() {
      let addedDefaultCommission = null;
      if (this.changingDefaultCommission) {
        addedDefaultCommission = {
          basis: this.addingDefaultCommissionBasis,
          commissionRate: this.addingDefaultCommissionRate,
          effectiveDate: this.addingDefaultCommissionEffDate
        };
      }

      let addedSelfSalesCommission = null;
      if (this.changingSelfSalesCommission) {
        addedSelfSalesCommission = {
          basis: this.addingSelfSalesCommissionBasis,
          commissionRate: this.addingSelfSalesCommissionRate,
          effectiveDate: this.addingSelfSalesCommissionEffDate
        };
      }

      let addedB2bSalesCommission = null;
      if (this.changingB2bSalesCommission) {
        addedB2bSalesCommission = {
          basis: this.addingB2bSalesCommissionBasis,
          commissionRate: this.addingB2bSalesCommissionRate,
          effectiveDate: this.addingB2bSalesCommissionEffDate
        };
      }

      let addedIframeSalesCommission = null;
      if (this.changingIframeSalesCommission) {
        addedIframeSalesCommission = {
          basis: this.addingIframeSalesCommissionBasis,
          commissionRate: this.addingIframeSalesCommissionRate,
          effectiveDate: this.addingIframeSalesCommissionEffDate
        };
      }

      let addedKakaoSalesCommission = null;
      if (this.changingKakaoSalesCommission) {
        addedKakaoSalesCommission = {
          basis: this.addingKakaoSalesCommissionBasis,
          commissionRate: this.addingKakaoSalesCommissionRate,
          effectiveDate: this.addingKakaoSalesCommissionEffDate
        };
      }

      var tmpsettlements = this.settlements.filter((stt, idx) => idx < this.countsett);
      if (tmpsettlements.length == 0) {
        alert("정산기준일을 설정하세요.");
        return;
      }
      tmpsettlements[this.countsett - 1].salesEndDate = 31;
      reqPost(
        brandapi.base,
        brandapi.tnjsettlupdate,
        this.authToken, {
          storeCode: this.storeCode,
          addedDefaultCommission: addedDefaultCommission,
          addedSelfSalesCommission: addedSelfSalesCommission,
          addedB2bSalesCommission: addedB2bSalesCommission,
          addedIframeSalesCommission: addedIframeSalesCommission,
          addedKakaoSalesCommission: addedKakaoSalesCommission,
          settlements: tmpsettlements,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.changingDefaultCommission = false;
              this.changingSelfSalesCommission = false;
              this.changingB2bSalesCommission = false;
              this.changingIframeSalesCommission = false;
              this.changingKakaoSalesCommission = false;
              this.getsettlementInf();
              alert("저장되었습니다.");
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getsettlementInf() {
      reqGet(
        brandapi.base,
        brandapi.tnjsettlement + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.countsett = result.data.settlements.length;
            console.log(this.countsett);

            for (var idx = 0; idx < this.countsett; idx++) {
              // this.settlements[idx] = result.data.settlements[idx];
              this.settlements.splice(idx, 1, result.data.settlements[idx])
            }
            console.log(this.settlements)
            this.defaultCommissions = result.data.defaultCommissions;
            this.lastDefaultCommission = result.data.lastDefaultCommission;
            if (this.lastDefaultCommission != null) {
              this.addingDefaultCommissionBasis = this.lastDefaultCommission.basis;
              this.addingDefaultCommissionRate = this.lastDefaultCommission.commissionRate;
              this.addingDefaultCommissionEffDate = moment().format("YYYY-MM-DD"); //this.lastCommission.effectiveDate;
            } else {
              this.addingDefaultCommissionEffDate = moment().format("YYYY-MM-DD");
            }

            this.selfSalesCommissions = result.data.selfSalesCommissions;
            this.lastSelfSalesCommission = result.data.lastSelfSalesCommission;
            if (this.lastSelfSalesCommission != null) {
              this.addingSelfSalesCommissionBasis = this.lastSelfSalesCommission.basis;
              this.addingSelfSalesCommissionRate = this.lastSelfSalesCommission.commissionRate;
              this.addingSelfSalesCommissionEffDate = moment().format("YYYY-MM-DD"); //this.lastCommission.effectiveDate;
            } else {
              this.addingSelfSalesCommissionEffDate = moment().format("YYYY-MM-DD");
            }
            this.b2bSalesCommissions = result.data.b2bSalesCommissions;
            this.lastB2bSalesCommission = result.data.lastB2bSalesCommission;
            if (this.lastB2bSalesCommission != null) {
              this.addingB2bSalesCommissionBasis = this.lastB2bSalesCommission.basis;
              this.addingB2bSalesCommissionRate = this.lastB2bSalesCommission.commissionRate;
              this.addingB2bSalesCommissionEffDate = moment().format("YYYY-MM-DD"); //this.lastCommission.effectiveDate;
            } else {
              this.addingB2bSalesCommissionEffDate = moment().format("YYYY-MM-DD");
            }
            this.iframeSalesCommissions = result.data.iframeSalesCommissions;
            this.lastIframeSalesCommission = result.data.lastIframeSalesCommission;
            if (this.lastIframeSalesCommission != null) {
              this.addingIframeSalesCommissionBasis = this.lastIframeSalesCommission.basis;
              this.addingIframeSalesCommissionRate = this.lastIframeSalesCommission.commissionRate;
              this.addingIframeSalesCommissionEffDate = moment().format("YYYY-MM-DD"); //this.lastCommission.effectiveDate;
            } else {
              this.addingIframeSalesCommissionEffDate = moment().format("YYYY-MM-DD");
            }
            this.kakaoSalesCommissions = result.data.kakaoSalesCommissions;
            this.lastKakaoSalesCommission = result.data.lastKakaoSalesCommission;
            if (this.lastKakaoSalesCommission != null) {
              this.addingKakaoSalesCommissionBasis = this.lastKakaoSalesCommission.basis;
              this.addingKakaoSalesCommissionRate = this.lastKakaoSalesCommission.commissionRate;
              this.addingKakaoSalesCommissionEffDate = moment().format("YYYY-MM-DD"); //this.lastCommission.effectiveDate;
            } else {
              this.addingKakaoSalesCommissionEffDate = moment().format("YYYY-MM-DD");
            }

          }
        }
      );
    },


  }

}
</script>

<style scoped>

</style>
