<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
          <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
            <input type="text" class="form-control" style="width:auto" readonly :value="salesMonth" @click="onShowMonthPicker"/>
            <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
          </div>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="brandNameContaining"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default pull-right" @click="downExcel" :disabled="buttonDissabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th rowspan="2">번호</th>
              <th rowspan="2">브랜드</th>
              <th rowspan="2">매장명</th>
              <th rowspan="2">확정입금금액 합계</th>
              <th rowspan="2">실입금금액 합계</th>
              <th rowspan="2">차액</th>
              <th :colspan="menudates.length">정산일별 입금 금액</th>
            </tr>
            <tr >
              <th v-for="(mdate, midx) in menudates" :key="'mdate'+midx">{{mdate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in content" :key="idx">
              <td>{{idx + 1}}</td>
              <td>{{item.brandName}}</td>
              <td class="linkcmp" @click="showEdit(item)">{{item.storeName}}</td>
              <td>{{item.deliveryAmountTotal | numFormat}}</td>
              <td>{{item.transferredAmountTotal | numFormat}}</td>
              <td>{{item.differenceAmountTotal | numFormat}}</td>
              <td v-for="(mdate, midx) in menudates" :key="'mdate'+idx+'0'+midx">{{getDateAmount(item, mdate) | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal" :style="editdlg && { display: 'block', 'z-index': '2000' }">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">매장별 입금내역</div>
          <div class="modal-body form-horizontal">
            <h4>{{viewstorename}}</h4>
            <div class="form-group">
                <div class="table-responsive p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>대상기간</th>
                        <th>입금일</th>
                        <th>확정입금금액</th>
                        <th>실입금금액</th>
                        <th>차액</th>
                        <th>메모</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in viewdata" :key="index">
                        <!-- {{ item }} -->
                        <td>{{index + 1}}</td>
                        <td>{{item.salesFrom}} ~ {{item.salesTo}}</td>
                        <td>{{viewDate(item.completedDate)}}</td>
                        <td>{{item.totalDeliveryAmount | numFormat}}</td>
                        <td>{{item.transferredAmount | numFormat}}</td>
                        <td>{{item.differenceAmount | numFormat}}</td>
                        <td>{{item.memo}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div class="modal-footer text-center">
              <button type="button" class="btn btn-default" @click="closeEdit">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../../lib/consts";
import routepath from "../../../lib/routes";
import { reqPost } from "../../../lib/request";
import { orderapi } from "../../../lib/api";
import { DateFormat } from "../../../lib/utility";
import { MonthPicker } from 'vue-month-picker'
import moment from 'moment';
import XLSX from "xlsx";
export default {
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.event)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.event)
      }
    }
  },
  components: {
    MonthPicker
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_PAY, path2:menuval.LV2_PAY_DPS, title:"입금내역조회"});
  },
  data() {
    return {
      editdlg: false,
      brandNameContaining:"",
      salesMonth:"",
      defmonth: moment().month() + 1,
      defyear: moment().year(),
      monthpicker:false,
      content:[],
      /**
       * brandCode                            String    브랜드 코드
       * brandName                            String    브랜드명
       * stores[].storeCode                   String    매장 코드
       * stores[].storeName                   String    매장명
       * stores[].transactionAmountTotal      String    확정 거래금액 합계
       * stores[].deliveryAmountTotal         String    확정 입금금액 합계
       * stores[].salesCommissionTotal        String    확정 판매수수료 합계
       * stores[].transferredAmountTotal      String    실 입금금액 합계
       * stores[].differenceAmountTotal       String    확정 입금금액과 실 입금금액 차액 합계
       * stores[].settlements[].settlementId        Number    정산 ID
       * stores[].settlements[].salesFrom           String    yyyy-MM-dd  매출 시작일
       * stores[].settlements[].salesTo             String    yyyy-MM-dd  매출 종료일
       * stores[].settlements[].settlementDate      String    yyyy-MM-dd  정산일
       * stores[].settlements[].transactionAmount               String    확정 거래금액 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].deliveryAmount                  String    확정 입금금액 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].salesCommission                 String    확정 판매 수수료 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].transferredAmount               String    실 입금금액 (정산이 완료되지 않은 경우 null)
       * stores[].settlements[].differenceAmount                String    확정 입금금액과 실 입금금액 차액
       * stores[].settlements[].completedDate                   String    UTC yyyy-MM-ddTHH:mm:ss   정산 완료일 (입금 완료일, 정산이 완료되지 않은 경우 null)
       * stores[].settlements[].memo                            String    메모
       */
      menudates:[],
      viewdata:[],
      viewstorename:"",
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    closeEdit() {
      this.editdlg = false;
    },
    showEdit(storedata) {
      this.viewdata = storedata.settlements;
      this.viewstorename = storedata.brandName+" "+storedata.storeName;
      this.editdlg = true;
    },
    selMonth(date)
    {
      this.salesMonth = date.year+"-"+date.monthIndex.toString().padStart(2, 0);
      // this.monthpicker = false;
    },
    onShowMonthPicker()
    {
      this.monthpicker = !this.monthpicker;
    },
    hideMonthPicker()
    {
      this.monthpicker = false;
    },
    refreshPage() {

      var reqdata = {
          year:moment(this.salesMonth+"-01").year(),
          month:(moment(this.salesMonth+"-01").month() + 1),
          brandNameContaining:this.brandNameContaining,
        };
      reqPost(
        orderapi.base,
        orderapi.completedSettlements,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.converExcel(result.data.brands);
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    converExcel(brands)
    {
      this.content = [];
      this.menudates = [];
      for(var bidx=0; bidx<brands.length; bidx++)
      {
        for(var sidx=0; sidx<brands[bidx].stores.length; sidx++)
        {
          for(var tidx=0; tidx<brands[bidx].stores[sidx].settlements.length; tidx++)
          {
            var f_idx = this.menudates.findIndex(mdate => mdate == brands[bidx].stores[sidx].settlements[tidx].settlementDate );
            if(f_idx == -1)
            {
              this.menudates.push(brands[bidx].stores[sidx].settlements[tidx].settlementDate);
            }
          }
          this.menudates.sort(function(a, b){return a - b});
          var t_data = {
            ...brands[bidx].stores[sidx],
            brandCode:brands[bidx].brandCode,
            brandName:brands[bidx].brandName,
          };
          this.content.push(t_data);
        }
      }
    },
    getDateAmount(item, mdate)
    {
      for(var idx = 0; idx<item.settlements.length; idx++)
      {
        if(item.settlements[idx].settlementDate == mdate)
        {
          return item.settlements[idx].transferredAmount;
        }
      }
      return "";
    },
    dataconvert(tdata, tidx) {
      return {
        A: tidx+1,
        B: tdata.brandName,
        C: tdata.storeName,
        D: tdata.deliveryAmountTotal,
        E: tdata.transferredAmountTotal,
        F: tdata.differenceAmountTotal,
        G: this.getDateExcelAmount(tdata.settlements),
      };
    },
    downExcel() {
      this.buttonDissabled = true;
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "브랜드명",
            C: "매장명",
            D: "확정입금금액 합계",
            E: "실입금금액 합계",
            F: "차액",
            G: "정산일별 입금 금액",
          },
        ],
        { skipHeader: true }
      );

      XLSX.utils.sheet_add_json(excelheader, this.content.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "입금내역조회");
      // export Excel file
      XLSX.writeFile(wb, "입금관리-입금내역조회.xlsx");
      this.buttonDissabled = false;
    },
    getDateExcelAmount(settlements)
    {
      var retval = "";
      for(var idx= 0; idx<settlements.length; idx++)
      {
        if(retval != "")
        {
          retval = retval + ",";
        }
        retval = retval + settlements[idx].settlementDate + " : " + settlements[idx].transferredAmount;
      }
      return retval;
    }
  },
};
</script>

<style scoped>

th {
  text-align: center;
}
td {
  text-align: center;
}

.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.modal-body .control-label {
  margin: 0px;
}

.modal .table {
  margin-bottom: 0px;
}

.form-horizontal .form-group{
  margin-left: 0px;
  margin-right: 0px;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}
</style>
