<template>
  <div class="root">
    <date-pick v-model="data"
      :isDateDisabled="isFutureDate"
      nextMonthCaption="다음"
      prevMonthCaption="이전"
      :startWeekOnSunday="true"
      :weekdays= "['월', '화', '수', '목', '금', '토', '일']"
      :months="['1월', '2월', '3월', '4월','5월', '6월', '7월', '8월','9월', '10월', '11월', '12월']"
      :editable="readonly">
      <template v-slot:default="{toggle}">
        <div class="input-group" style="display:inline-flex;" @click="toggle">
          <input type="text" class="form-control" v-model="data"/>
          <span class="input-group-addon" style="width: fit-content;display:inline-table;padding:9px;">
            <i class="fa fa-fw fa-calendar"></i>
          </span>
        </div>
      </template>
    </date-pick>
  </div>
</template>

<script>
import moment from 'moment';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
export default {
  components: {
    DatePick 
  },
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    readonly:{
      type:Boolean,
      default:true
    },
    first: {
      type: String,
      default: "",
    },
    last: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data:"",
      pre:"",
      next:"",
    };
  },
  watch: {
    data(newval){
      this.$emit('input', newval);
    },
    value(newval){
      this.data = newval;
    },
    first(newfirst)
    {
      this.pre = newfirst;
    },
    last(newlast)
    {
      this.next = newlast;
    },
  },
  mounted() {
    this.data = this.value;
    this.pre = this.first;
    this.next = this.last;
  },
  computed: {},
  methods: {
    isFutureDate(date) {
      if(this.pre != "" && date < moment(this.pre))
      {
        return true;
      }
      if(this.next != "" && date > moment(this.next))
      {
        return true;
      }
      return false;
    }
  },
};
</script>
<style scoped>
.root {
  display: inline-flex;
  /* z-index: 99 !important; */
}
</style> 