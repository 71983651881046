<template>
<div>
    <div class="row">
        <div class="col-sm-12">
            <strong class="strongText">예약 받을 상품 선택</strong>
            <div class="table-responsive p-0 mt-2">
                <table class="table table-hover text-nowrap rightline" >
                    <colgroup>
                      <col width="5%">
                      <col width="5%">
                      <col width="*">
                      <col width="5%">
                      <col width="*">
                      <col width="10%">
                      <col width="10%">
                      <col width="9%">
                      <col width="5%">
                      <col width="5%">
                      <col width="5*">
                    </colgroup>
                    <thead >
                        <tr>
                            <th colspan="10" class="text-center">기본운영</th>
                        </tr>
                        <tr>
                            <th class="text-center">적용 요일</th>
                            <th class="text-center">예약가능시간</th>
                            <th class="text-center">상품명</th>
                            <th class="text-center">유형</th>
                            <th class="text-center">옵션명</th>
                            <th class="text-center">정상가</th>
                            <th class="text-center">판매가</th>
                            <th class="text-center">기준 인원</th>
                            <th class="text-center">권종</th>
                            <th class="text-center">수정</th>
                        </tr>
                    </thead>
                    <tbody v-for="(item,idx) in defaultArray" :key="idx">
                        <tr>
                            <td class="text-center wid5" v-if="item.targetDaysType !== 'Custom'" :rowspan="item.timeOption.length + 1">{{ item.targetDaysType | arrayDayFiter }}</td>
                            <td class="text-center wid5" v-if="item.targetDaysType === 'Custom'" :rowspan="item.timeOption.length + 1">
                                {{ item | daysFilter }}
                            </td>
                        </tr>
                        <tr v-for="(titem,tidx) in item.timeOption" :key="tidx">
                            <td class="text-center wid5">
                                {{ titem.startTime.substr(0,5) +'~'+ titem.endTime.substr(0,5)}}
                            </td>
                            <td colspan="7" class="text-center" style="padding:0px;">
                                <div v-if="titem.menuGoodsList.length !== 0">
                                    <div v-for="(mitem,midx) in titem.menuGoodsList" :key="midx" class="liTopline oItem" >
                                        <div class="wid25" > {{ mitem.goodsName }} </div>
                                        <div class="wid10" > {{ mitem.goodsType | opnameM}} </div>
                                        <div style="width: 65%;" >
                                          <template v-for="(oitem,oidx) in mitem.menuOptionList">
                                            <div class="oItem" :class="oidx !=0 ? 'liTopline' : ''">
                                                <div style="width: 36%; overflow:hidden" > {{ oitem.optionName }} </div>
                                                <div style="width: 20%;" > {{ oitem.listPrice | numFormat }} </div>
                                                <div style="width: 20%;" >
                                                  {{ oitem.salePrice | numFormat }}
                                                  <div v-if="percent(uncomma(oitem.listPrice) - uncomma(oitem.salePrice), uncomma(oitem.listPrice)) !== '0.00%'">
                                                    <span style="min-width: 150px;" v-if="Number(oitem.salePrice) !== 0">
                                                        ({{percent(uncomma(oitem.listPrice) - uncomma(oitem.salePrice), uncomma(oitem.listPrice))}})
                                                    </span>
                                                  </div>
                                                </div>
                                                <div style="width: 14%;" > {{ oitem.headCount }} </div>
                                                <div style="width: 10%;" > {{ oitem.ageGroup | getTypeTextReturn }} </div>
                                            </div>
                                          </template>
                                        </div>


<!--                                            <span class="delBtnPd ">-->
<!--                                                <button class="btn btn-default" type="button" @click="deleteOption(titem.timeOptionId,mitem.optionCode)">-->
<!--                                                    <i class="fa fa-fw fa-trash-o"></i>-->
<!--                                                </button>-->
<!--                                            </span>-->
                                    </div>
                                </div>
                                <div v-else class="optionSelectdiv">
                                    <button class="btn btn-default optionSelect" @click="optionSelect(titem.timeOptionId)" type="button">옵션선택</button>
                                </div>
                            </td>
                            <td class="text-center wid5" v-if="titem.menuGoodsList.length !== 0">
                                <button class="btn btn-default" type="button" @click="optionEdt(titem.timeOptionId)">수정</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 특정기간운영 테이블 -->
            <div class="table-responsive rightline p-0" v-if="defaultArray.length !== 0">
                <table class="table table-hover text-nowrap">
                    <colgroup>
                      <col width="5%">
                      <col width="5%">
                      <col width="*">
                      <col width="5%">
                      <col width="*">
                      <col width="10%">
                      <col width="10%">
                      <col width="9%">
                      <col width="5%">
                      <col width="5%">
                      <col width="5*">
                    </colgroup>
                    <thead>
                        <tr>
                            <th colspan="10" class="text-center">특정 기간 운영</th>
                        </tr>
                        <tr>
                          <th class="text-center">적용 요일</th>
                          <th class="text-center">예약가능시간</th>
                          <th class="text-center">상품명</th>
                          <th class="text-center">유형</th>
                          <th class="text-center">옵션명</th>
                          <th class="text-center">정상가</th>
                          <th class="text-center">판매가</th>
                          <th class="text-center">기준 인원</th>
                          <th class="text-center">권종</th>
                          <th class="text-center">수정</th>
                        </tr>
                    </thead>
                    <tbody v-for="(item,idx) in temporaryArr" :key="idx">
                        <tr>
                            <td :rowspan="item.timeOption.length + 1" class="text-center ">{{ item.startDate +'~'+ item.endDate }}</td>
                        </tr>
                        <tr v-for="(titem,tidx) in item.timeOption" :key="tidx">
                            <td class="text-center ">
                                {{ titem.startTime.substr(0,5) +'~'+ titem.endTime.substr(0,5)}}
                            </td>
                            <td colspan="7" class="text-center" style="padding:0px;">
                              <div v-if="titem.menuGoodsList.length !== 0">
                                <div v-for="(mitem,midx) in titem.menuGoodsList" :key="midx" class="liTopline oItem" >
                                  <div class="wid25" > {{ mitem.goodsName }} </div>
                                  <div class="wid10" > {{ mitem.goodsType | opnameM}} </div>
                                  <div style="width: 65%;" >
                                    <template v-for="(oitem,oidx) in mitem.menuOptionList">
                                      <div class="oItem" :class="oidx !=0 ? 'liTopline' : ''">
                                        <div style="width: 36%; overflow:hidden" > {{ oitem.optionName }} </div>
                                        <div style="width: 20%;" > {{ oitem.listPrice | numFormat }} </div>
                                        <div style="width: 20%;" >
                                          {{ oitem.salePrice | numFormat }}
                                          <div v-if="percent(uncomma(oitem.listPrice) - uncomma(oitem.salePrice), uncomma(oitem.listPrice)) !== '0.00%'">
                                                    <span style="min-width: 150px;" v-if="Number(oitem.salePrice) !== 0">
                                                        ({{percent(uncomma(oitem.listPrice) - uncomma(oitem.salePrice), uncomma(oitem.listPrice))}})
                                                    </span>
                                          </div>
                                        </div>
                                        <div style="width: 14%;" > {{ oitem.headCount }} </div>
                                        <div style="width: 10%;" > {{ oitem.ageGroup | getTypeTextReturn }} </div>
                                      </div>
                                    </template>
                                  </div>


                                  <!--                                            <span class="delBtnPd ">-->
                                  <!--                                                <button class="btn btn-default" type="button" @click="deleteOption(titem.timeOptionId,mitem.optionCode)">-->
                                  <!--                                                    <i class="fa fa-fw fa-trash-o"></i>-->
                                  <!--                                                </button>-->
                                  <!--                                            </span>-->
                                </div>
                              </div>
                                <div v-else class="optionSelectdiv">
                                  <button class="btn btn-default optionSelect" @click="optionSelect(titem.timeOptionId)" type="button">옵션선택</button>
                                </div>
                            </td>
                            <td class="text-center wid5" v-if="titem.menuGoodsList.length !== 0">
                              <button class="btn btn-default" type="button" @click="optionEdt(titem.timeOptionId)">수정</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal" :style="optionModal && {'display': 'block'}">
        <div class="modal-dialog" style="width: 60%">
            <div class="modal-content" >
                <div class="modal-header border-bottom-0"> {{ optionModalName }} </div>
                <div class="modal-body form-horizontal">
                    <div class="table-responsive p-0">
                        <table class="table table-hover">
                            <colgroup>
                              <col width="5%">
                              <col width="25%">
                              <col width="7%">
                              <col width="25%">
                              <col width="12%">
                              <col width="12%">
                              <col width="7%">
                              <col width="7%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="text-center">상품명</th>
                                    <th class="text-center">유형</th>
                                    <th class="text-center">옵션명</th>
                                    <th class="text-center">정상가</th>
                                    <th class="text-center">판매가</th>
                                    <th class="text-center">기준인원</th>
                                    <th class="text-center">권종</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(opitm,opidx) in optionsetList" :key="opidx">
                                    <td class="text-center">
                                        <input type="checkbox" v-model="checkBoxArr" :value="opitm.goodsCode" class="checkdocd">
                                    </td>
                                    <td class="text-center" style="overflow: hidden"> {{opitm.goodsName}} </td>
                                    <td class="text-center"> {{ opitm.options[0].menuOptionType | opnameM }} </td>
                                    <td colspan="5" class="text-center" style="padding:0px;">
                                        <template v-for="(mitem,midx) in opitm.options">
                                            <div :class="midx !=0 ? 'liTopline' : ''" class="oItem">
                                                <div style="width: 40%; overflow: hidden" class="text-center" > {{ mitem.optionName }} </div>
                                                <div style="width: 19%;" class="text-center"> {{ mitem.listPrice | numFormat }} </div>
                                                <div style="width: 19%;" class="text-center">
                                                    {{ mitem.salePrice | numFormat }}
                                                    <div v-if="percent(uncomma(mitem.listPrice) - uncomma(mitem.salePrice), uncomma(mitem.listPrice)) !== '0.00%'">
                                                        <span v-if="Number(opitm.salePrice) !== 0">
                                                            ({{percent(uncomma(mitem.listPrice) - uncomma(mitem.salePrice), uncomma(mitem.listPrice))}})
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style="width: 12%;" class="text-center"> {{ mitem.headCount }} </div>
                                                <div style="width: 10%;" class="text-center"> {{ mitem.ageGroup | getTypeTextReturn  }} </div>
                                            </div>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-default" @click="optionModalClose">취소</button>
                    <button type="button" class="btn btn-default" @click="optionModalSave">저장</button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    getGoodsTypeStr,
    getAgeGroupStr,
    getPercent,
    getMobileCoupon,
    getStoreTypeStr
} from "../../../../lib/utility";
import {
    reqGet,
    reqPost
} from "../../../../lib/request";

import {
    brandapi
} from "../../../../lib/api";
import {
    rescode,
    menuval
} from "../../../../lib/consts";

export default {
    data() {
        return {
            optionModal: false,
            defaultArray: [],
            temporaryArr: [],
            optionsetList: [],
            optionModalName: "",
            checkBoxArr: [],
            timeOptionId: 0,
        }
    },
    created() {
        this.refresh();
    },
    filters: {
        opnameM(val) {
            if (val == 'DepositBooking') {
                return '예약금'
            } else {
                return '메뉴'
            }
        },
        getTypeTextReturn(type) {
            if (type == 'Adult') {
                return '성인';
            } else if (type == 'Child') {
                return '소인';
            } else {
                return '유아';
            }
        },
        arrayDayFiter(vars) {
            // console.log(vars)
            if (vars === 'Weekday') {
                return '주중';
            } else if (vars === 'Weekend') {
                return '주말';
            } else if (vars === 'Everyday') {
                return '매일';
            } else {
                return '사용자 지정';
            }
        },
        daysFilter(val) {
            let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            let daysKr = ['월', '화', '수', '목', '금', '토', '일'];
            let returnDay = [];
            days.forEach((el, idx) => {
                if (val[el] === true) {
                    returnDay.push(daysKr[idx])
                }
            });
            // console.log(returnDay.toString())
            return returnDay.toString();
        },
        engChangeKr(val) {
            switch (val) {
                case "M30":
                    return "30분";
                case "M60":
                    return "1시간";
                case "M60":
                    return "1시간";
                case "Everyday":
                    return "매일";
                case "Weekday":
                    return "주중";
                case "Weekend":
                    return "주말";
                case "Custom":
                    return "사용자 지정";
                default:
                    break;
            }
        }
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    methods: {
        changeNum: function (value) {
            return value = this.comma(this.uncomma(value));
        },
        comma(str) {
            str = String(str);
            return str.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        },
        uncomma(str) {
            str = String(str);
            return str.replace(/[^\d]+/g, '');
        },
        percent(nval, oval) {
            return getPercent(nval, oval);
        },
        deleteOption(timeOptionId, optionId) {
            reqPost(
                brandapi.base,
                brandapi.storeTimeOptionDelete,
                this.authToken, {
                    optionId: optionId,
                    timeOptionId: timeOptionId,
                    storeCode: this.$route.query.stcode
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('삭제되었습니다.');
                          // this.$emit('get-kakao-alert', 'RESERVATION');
                            this.refresh();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        optionEdt(timeOptionId) {
            this.optionSelect(timeOptionId);
            reqGet(
                brandapi.base,
                brandapi.storeTimeOptionQuery + "?storeCode=" + this.$route.query.stcode + "&timeOptionId=" + timeOptionId,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.optionsetList = result.data
                            let rd = result.data;
                            rd.menuGoodsList.forEach((el, idx) => {
                                this.checkBoxArr.push(el.goodsCode)
                                // console.log(this.checkBoxArr)
                            });
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );

        },
        optionSelect(timeId) {
            this.optionModalName = "예약상품 선택";
            this.optionModal = true;
            this.checkBoxArr = [];
            this.timeId = timeId;
            reqGet(
                brandapi.base,
                brandapi.storeOptionList + "?storeCode=" + this.$route.query.stcode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.optionsetList = result.data.list;
                            // console.log(this.optionsetList)
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        optionModalSave() {
            // console.log(this.checkBoxArr.length);
            // if (this.checkBoxArr.length === 0) {
            //     alert('옵션을 선택해주세요.');
            //     return;
            // }
            reqPost(
                brandapi.base,
                brandapi.storeTimeOptionUpdate,
                this.authToken, {
                    goodsIds: this.checkBoxArr,
                    timeOptionId: this.timeId,
                    storeCode: this.$route.query.stcode
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('저장되었습니다.');
                            // this.$emit('get-kakao-alert', 'RESERVATION');
                            this.refresh();
                            this.optionModalClose();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        optionModalClose() {
            this.optionModal = false;
        },
        refresh() {
            this.defaultArray = [];
            this.temporaryArr = [];
            reqGet(
                brandapi.base,
                brandapi.storeTimeOptionList + "?storeCode=" + this.$route.query.stcode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.defaultArray = result.data.list;
                            // console.log(this.defaultArray)
                            result.data.list.forEach((el, idx) => {
                                if (el.operatedTime === "Default") {
                                    this.defaultArray.push(el);
                                } else {
                                    this.temporaryArr.push(el);
                                }
                                // console.log(this.temporaryArr)
                            });
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
    },
}
</script>

<style scoped>
.liTopline {
  border-top: 1px solid #f4f4f4 !important;
}

th {
    background-color: #dee2e685;
    vertical-align: middle !important;
}

td {
    vertical-align: middle !important;
}

.right-btn {
    text-align: right;
}

.strongText {
    font-size: 18px;
}

.mt-2 {
    margin-top: 20px;
}

ul.newUlUi {
    padding: 0;
    list-style: none;
}

ul.newUlUi li span,
.optionSelectdiv {
    padding: 8px;
    line-height: 1.42857143;
    width: 24%;
    display: inline-block;
    text-align: center;
    max-width: 250.06px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}

.newthWidth {
    width: 9%;
}

ul.newUlUi>div>li {

    width: 59%;
}
.rightline tr td:first-child {border-right: 1px solid #f4f4f4;}
.delBtnPd {
    padding: 13px 8px 0px 8px !important;
}

.wid5 {
    width: 5%;
}
.wid10 {
  width: 10%;
}
.wid25 {
    width: 25%;
}
.wid100 {
  width: 100%;
}

.oItem{
  display: flex;
  width: 100%;
  padding-top:8px;
  padding-bottom: 8px;
  align-items: center;
}

.max140 {
    max-width: 140px !important;
}


</style>
