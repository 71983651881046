<template lang="ko">
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="settlementMonth" @click="onShowMonthPicker" />
                    <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
                </div>
                <select class="form-control" style="width:auto;margin-right: 10px" v-model="settlementDayOfMonth">
                    <option v-for="stdate in settlementDates" :key="stdate" :value="stdate">{{
    stdate
  }}일</option>
                 </select>
                 <button class="btn btn-default" type="button" @click="refreshPage">검색</button>
             </div>
         </div>
     </div>
     <div class="row" style="margin:0px;margin-bottom:20px">
         <button type="button" class="btn btn-default pull-right" @click="downExcel" :disabled="excelButtonDisabled">
             <i class="fa fa-download"></i>Excel
         </button>
         <button type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="doConfirmAmounts" :disabled="buttonDissabled">
             일괄금액확정
         </button>
     </div>
     <div class="box box-solid">
         <div class="box-body table-responsive p-0">
             <table class="table table-hover text-nowrap">
                 <thead>
                     <tr>
                         <th rowspan="2">번호</th>
                         <th rowspan="2">브랜드</th>
                         <th rowspan="2">매장명</th>
                         <th colspan="3">사용매출</th>
                         <th colspan="3">취소위약금 매출</th>
                         <th rowspan="2">총입금금액</th>
                         <th rowspan="2">총수수료</th>
                         <th rowspan="2">정상기준일</th>
                         <th rowspan="2">정산일</th>
                         <th rowspan="2">정산현황</th>
                         <th rowspan="2">금액확정처리</th>
                         <th rowspan="2">확정입금금액</th>
                         <th rowspan="2">확정수수료</th>
                         <th rowspan="2"><input type="checkbox" class="allch" @change="allckech($event)" /></th>
                     </tr>
                     <tr>
                         <th>거래금액</th>
                         <th>입금금액</th>
                         <th>수수료</th>
                         <th>위약금</th>
                         <th>입금금액</th>
                         <th>수수료</th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr v-for="(settlement, sidx) in content" :key="sidx">
                         <td>{{
    sidx + 1
  }}</td>
                         <td>{{
    settlement.brandName
  }}</td>
                         <td>{{
    settlement.storeName
  }}</td>
                         <td>{{
    settlement.calculatedTransactionAmount | numFormat
  }}</td>
                         <td>{{
    settlement.calculatedSalesDeliveryAmount | numFormat
  }}</td>
                         <td>{{
    settlement.calculatedSalesCommission | numFormat
  }}</td>

                         <td>{{
    settlement.calculatedPenaltyAmount | numFormat
  }}</td>
                         <td>{{
    settlement.calculatedPenaltyDeliveryAmount | numFormat
  }}</td>
                         <td>{{
    settlement.calculatedPenaltyCommission | numFormat
  }}</td>
                         <td>{{
    settlement.calculatedTotalDeliveryAmount | numFormat
  }}</td>
                         <td>{{
    settlement.calculatedTotalCommission | numFormat
  }}</td>

                         <td :class="[settlement.settingsUpdated && 'red']">{{
    viewDate(settlement.salesFrom)
  }} ~ {{ viewDate(settlement.salesTo) }}</td><!-- 정산기준일 -->
                        <td :class="[settlement.settingsUpdated && 'red']">{{
    settlement.settlementDate
  }}</td><!-- 정산일 -->

                         <td>{{
    getSttStatus(settlement.settlementStatus)
  }}</td>

                         <!-- 금액확정 -->
                         <td v-if="settlement.settlementStatus == 'NeedsAmountConfirm'">
                             <button type="button" class="btn btn-default btn-xs" @click="doConfirmAmount(sidx)">금액확정</button>

                         </td>
                         <td v-else-if="settlement.settlementStatus == 'AmountConfirmed'">
                             <button type="button" class="btn btn-default btn-xs" @click="doReconfirmAmount(sidx)">금액재확정</button>
                         </td>
                         <td v-else></td>
                         <!-- 금액확정end -->
                         <td :class="[settlement.settlementId != null && 'linkcmp', settlement.totalDeliveryAmount != settlement.calculatedTotalDeliveryAmount && 'red']" @click="showEdit(settlement.settlementId)">
                             {{
    settlement.totalDeliveryAmount | numFormat
  }}
                         </td>

                         <td>{{
    settlement.totalCommission | numFormat
  }}</td>
                         <td>
                             <input v-if="settlement.settlementStatus != 'NeedsAmountConfirm'" type="checkbox" disabled />
                             <input v-if="settlement.settlementStatus == 'NeedsAmountConfirm'" type="checkbox" class="chitem" :value="sidx" />
                         </td>
                     </tr>
                 </tbody>
             </table>
         </div>
     </div>
     <div class="modal" :style="editdlg && { display: 'block', 'z-index': '2000' }">
         <div class="modal-dialog modal-lg">
             <div class="modal-content">
                 <div class="modal-header border-bottom-0">금액확정이력조회</div>
                 <div class="modal-body form-horizontal">
                     <div class="form-group">
                         <div class="table-responsive p-0">
                             <table class="table table-hover text-nowrap">
                                 <thead>
                                     <tr>
                                         <th>날짜</th>
                                         <th>구분</th>
                                         <th>확정금액</th>
                                         <th>확정수수료</th>
                                         <th>수정자</th>
                                     </tr>
                                 </thead>
                                 <tbody>
                                     <tr v-for="(ldata, lidx) in logs" :key="lidx">
                                         <td>{{
    viewDateTime(ldata.transactionTime)
  }}</td>
                                         <td>{{
    getTransactionName(ldata.transactionName)
  }}</td>
                                         <td>{{
    Number(ldata.salesDeliveryAmount) + Number(ldata.penaltyDeliveryAmount)
  }}</td>
                                         <td>{{
    Number(ldata.salesCommission) + Number(ldata.penaltyCommission)
  }}</td>
                                         <td>{{
    ldata.executedBy
  }}</td>
                                     </tr>
                                 </tbody>
                             </table>
                         </div>
                     </div>
                 </div>
                 <div class="modal-footer">
                     <div class="form-group text-center">
                         <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeEdit">닫기</button>
                         <button type="button" class="btn btn-default" @click="doConfirmAmount_cancel">확정취소</button>
                     </div>
                 </div>
             </div>
         </div>
     </div>
 </section>



</template>

<script>
import {
  rescode,
  menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import {
  reqGet,
  reqPost
} from "../../../lib/request";
import {
  brandapi,
  orderapi
} from "../../../lib/api";
import {
  DateFormat
} from "../../../lib/utility";
import {
  MonthPicker
} from 'vue-month-picker'
import moment from 'moment';
import $ from 'jquery';
import XLSX from "xlsx";

export default {
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.event)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.event)
      }
    }
  },
  components: {
    // Pagination,
    MonthPicker
  },
  created() {
    this.$store.dispatch('doSetPath', {
      path1: menuval.LV1_PAY,
      path2: menuval.LV2_PAY_DPS,
      title: "금액확정"
    });
  },
  data() {
    return {
      editdlg: false,
      settlementMonth: "",
      defmonth: moment().month() + 1,
      defyear: moment().year(),
      monthpicker: false,
      settlementDayOfMonth: 0,
      settlementDates: [],
      content: [],
      /**
       * brandCode                String    브랜드 코드
       * brandName                String    브랜드명
       * stores[].storeCode       String    매장 코드
       * stores[].storeName       String    매장명
       * stores[].settlements[].settlementId        Number    정산 ID
       * stores[].settlements[].settlementStatus    String    [SettlementUnavailable, NeedsAmountConfirm, AmountConfirmed, SettlementComplete]  정산 진행 상태. SettlementUnavailable: 아직 정산을 진행할 수 없음, NeedsAmountConfirm: 금액확정이 필요함, AmountConfirmed: 금액확정됨(입금대기), SettlementComplete: 정산완료(입금완료)
       * stores[].settlements[].salesFrom           String    yyyy-MM-dd  매출 시작일
       * stores[].settlements[].salesTo             String    yyyy-MM-dd  매출 종료일
       * stores[].settlements[].settlementDate      String    yyyy-MM-dd  정산일
       * stores[].settlements[].calculatedTransactionAmount     String    거래금액
       * stores[].settlements[].calculatedSalesDeliveryAmount        String    입금금액
       * stores[].settlements[].calculatedSalesCommission       String    판매 수수료
       * stores[].settlements[].confirmedDate                   String    UTC yyyy-MM-ddTHH:mm:ss 금액 확정일 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].transactionAmount               String    확정 거래금액 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].deliveryAmount                  String    확정 입금금액 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].salesCommission                 String    확정 판매 수수료 (금액 확정이 이루어지지 않은 경우 null)
       * stores[].settlements[].transferredAmount               String    실 입금금액 (정산이 완료되지 않은 경우 null)
       * stores[].settlements[].completedDate                   String    UTC yyyy-MM-ddTHH:mm:ss   정산 완료일 (입금 완료일, 정산이 완료되지 않은 경우 null)
       * stores[].settlements[].memo                            String    메모
       */
      selsettlementId: null,
      logs: [],
      /**
       * transactionNo                Number    트랜잭션 번호
       * transactionName              String    [ConfirmAmount, ReconfirmAmount, CancelConfirmAmount, CompleteSettlement] [트랜잭션 명] ConfirmAmount: 금액확정, ReconfirmAmount: 금액재확정, CancelConfirmAmount: 금액확정 취소, CompleteSettlement: 정산완료(입금완료)
       * transactionTime              String    트랜잭션 발생 시간
       * executedBy                   String    트랜잭션 처리 관리자 ID
       * transactionAmount            String    확정 거래금액
       * deliveryAmount               String    확정 입금금액
       * salesCommission              String    확정 수수료
       * transferredAmount            String    실 입금금액
       */
      stores: [],
      excelButtonDisabled : false,
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.getdateList();
  },
  methods: {
    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    viewDateTime(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    refreshPage() {
      reqPost(
        orderapi.base,
        orderapi.withCalculatedAmount,
        this.authToken, {
          settlementMonth: this.settlementMonth,
          settlementDayOfMonth: this.settlementDayOfMonth,
        },
        async (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              await this.converExcel(result.data.brands);
              if (this.stores.length > 0) {
                await this.refreshPageAdd();
              }
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    converExcelAdd(stroeBankInfo) {
      this.content.forEach((store, index) => {
        let findStoreBank = stroeBankInfo.find(o => o.storeCode == store.storeCode)
        this.content[index].storeBankName = findStoreBank.bankbook.bankName
        this.content[index].storeAccountNumber = findStoreBank.bankbook.accountNumber
        this.content[index].storeAccountHolder = findStoreBank.bankbook.accountHolder
      })
    },
    refreshPageAdd() {
      reqPost(
        brandapi.base,
        brandapi.tnjbankbooks,
        this.authToken, {
          storeCodes: this.stores,
        },
        async (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              await this.converExcelAdd(result.data);
            }
          }
        }
      );
    },
    closeEdit() {
      this.editdlg = false;
    },
    showEdit(settlementId) {
      if (settlementId == null) {
        return;
      }
      this.selsettlementId = settlementId;
      reqPost(
        orderapi.base,
        orderapi.settlements_logs,
        this.authToken, {
          settlementId: settlementId,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.logs = result.data.logs;
              this.editdlg = true;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doConfirmAmount_cancel() {
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.confirmAmount_cancel,
        this.authToken, {
          settlementId: this.selsettlementId,
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeEdit();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    selMonth(date) {
      this.settlementMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
      // this.monthpicker = false;
    },
    onShowMonthPicker() {
      this.monthpicker = !this.monthpicker;
    },
    hideMonthPicker() {
      this.monthpicker = false;
    },
    getdateList() {
      reqGet(
        brandapi.base,
        brandapi.admdateGroup,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.settlementDates = result.data.settlementDates;
              this.settlementDayOfMonth = this.settlementDates[0];
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    converExcel(brands) {
      this.content = [];
      for (var bidx = 0; bidx < brands.length; bidx++) {
        for (var sidx = 0; sidx < brands[bidx].stores.length; sidx++) {
          for (var tidx = 0; tidx < brands[bidx].stores[sidx].settlements.length; tidx++) {
            this.stores.push(brands[bidx].stores[sidx].storeCode)

            var t_data = {
              ...brands[bidx].stores[sidx].settlements[tidx],
              brandCode: brands[bidx].brandCode,
              brandName: brands[bidx].brandName,
              storeCode: brands[bidx].stores[sidx].storeCode,
              storeName: brands[bidx].stores[sidx].storeName,
            };
            if (t_data.salesDeliveryAmount == null) {
              t_data.salesDeliveryAmount = 0;
            }
            if (t_data.totalCommission == null) {
              t_data.totalCommission = 0;
            }
            if (t_data.transactionAmount == null) {
              t_data.transactionAmount = 0;
            }
            if (t_data.transferredAmount == null) {
              t_data.transferredAmount = 0;
            }
            this.content.push(t_data);
          }
        }
      }
    },
    getSttStatus(val) {
      if (val == 'SettlementUnavailable') {
        return "";
      } else if (val == 'NeedsAmountConfirm') {
        return "확정대기";
      } else if (val == 'AmountConfirmed') {
        return "입금대기";
      } else if (val == 'SettlementComplete') {
        return "입금완료";
      } else {
        return "";
      }
    },
    doConfirmAmount(sidx) { //금액확정버튼

      var seldata = this.content[sidx];
      this.$store.dispatch('setDisabledLayout');

      var params = {
        brandCode: seldata.brandCode,
        storeCode: seldata.storeCode,
        salesFrom: seldata.salesFrom,
        salesTo: seldata.salesTo,
        settlementDate: seldata.settlementDate,
        transactionAmount: seldata.calculatedTransactionAmount, //transactionAmount,
        salesDeliveryAmount: seldata.calculatedSalesDeliveryAmount, //deliveryAmount,
        penaltyAmount: seldata.calculatedPenaltyAmount,
        penaltyDeliveryAmount: seldata.calculatedPenaltyDeliveryAmount,
        penaltyCommission: seldata.calculatedPenaltyCommission,
        salesCommission: seldata.calculatedSalesCommission, //salesCommission,
        currencyCode: "KRW"
      }
      console.log(params);

      reqPost(
        orderapi.base,
        orderapi.confirmAmount,
        this.authToken,
        params,
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doReconfirmAmount(sidx) {
      var seldata = this.content[sidx];
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.reconfirmAmount,
        this.authToken, {
          settlementId: seldata.settlementId,
          transactionAmount: seldata.calculatedTransactionAmount,
          salesDeliveryAmount: seldata.calculatedSalesDeliveryAmount,
          penaltyAmount: seldata.calculatedPenaltyAmount,
          penaltyDeliveryAmount: seldata.calculatedPenaltyDeliveryAmount,
          penaltyCommission: seldata.calculatedPenaltyCommission,
          salesCommission: seldata.calculatedSalesCommission,
          currencyCode: "KRW",
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doConfirmAmounts() {
      this.buttonDissabled = true;
      // var selidxs =
      //   $('input:checkbox.chitem:checked').map(function() {
      //     return this.value;
      //   }).get();
      //
      //
      // var t_settlements = this.content.filter(citem => {
      //   var f_idx = selidxs.findIndex(siidx => siidx == citem.settlementId);
      //   return f_idx > -1;
      // });
      // ※ 아직 금액확정이 되지 않은 정산 건에 대해서는 null 입력됨

      var vm = this;
      var t_settlements = []
      $('input:checkbox.chitem:checked').each(function () {
        t_settlements.push(vm.content[this.value]);
      });

      var settlements = t_settlements.map((titem) => {
        return {
          brandCode: titem.brandCode,
          storeCode: titem.storeCode,
          salesFrom: titem.salesFrom,
          salesTo: titem.salesTo,
          settlementDate: titem.settlementDate,
          transactionAmount: titem.calculatedTransactionAmount,
          salesDeliveryAmount: titem.calculatedSalesDeliveryAmount,
          salesCommission: titem.calculatedSalesCommission,
          penaltyAmount: titem.calculatedPenaltyAmount,
          penaltyDeliveryAmount: titem.calculatedPenaltyDeliveryAmount,
          penaltyCommission: titem.calculatedPenaltyCommission,
          currencyCode: "KRW",

        }
      });
      var params = {
        settlements: settlements
      }
      console.log(params);
      reqPost(
        orderapi.base,
        orderapi.confirmAmounts,
        this.authToken,
        params,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              $('input:checkbox.chitem').prop('checked', false);
              $('input:checkbox.allch').prop('checked', false);
              this.buttonDissabled = false;
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    dataconvert(tdata, tidx) {
      return {
        A: tidx + 1,
        B: tdata.brandName,
        C: tdata.brandCode,
        D: tdata.storeName,
        E: tdata.storeCode,
        F: tdata.calculatedTransactionAmount,
        G: tdata.calculatedSalesDeliveryAmount,
        H: tdata.calculatedSalesCommission,
        I: tdata.calculatedPenaltyAmount,
        J: tdata.calculatedPenaltyDeliveryAmount,
        K: tdata.calculatedPenaltyCommission,
        L: tdata.calculatedTotalDeliveryAmount,
        M: tdata.calculatedTotalCommission,
        N: this.viewDate(tdata.salesFrom) + " ~ " + this.viewDate(tdata.salesTo),
        O: this.viewDate(tdata.settlementDate),
        P: this.getSttStatus(tdata.settlementStatus),
        Q: tdata.storeBankName,
        R: tdata.storeAccountNumber,
        S: tdata.storeAccountHolder,
      };
    },
    downExcel() {
      this.excelButtonDisabled = true;
      var excelheader = XLSX.utils.json_to_sheet(
        [{
          A: "번호",
          B: "브랜드",
          C: "브랜드코드",
          D: "매장",
          E: "매장코드",
          F: "거래금액",
          G: "입금금액",
          H: "수수료",
          I: "위약금",
          J: "위약금입금금액",
          K: "위약금입금수수료",
          L: "총입금액",
          M: "총수수료",
          N: "정산기준일",
          O: "정산일",
          P: "정산현황",
          Q: '은행명',
          R: '계좌번호',
          S: '예금주',
        },], {
          skipHeader: true
        }
      );

      XLSX.utils.sheet_add_json(excelheader, this.content.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "금액확정");
      // export Excel file
      XLSX.writeFile(wb, "입금관리-금액확정.xlsx");
      this.excelButtonDisabled = false;
    },
    getTransactionName(val) {
      if (val == 'ConfirmAmount') {
        return "금액확정";
      } else if (val == 'ReconfirmAmount') {
        return "금액재확정";
      } else if (val == 'CancelConfirmAmount') {
        return "금액확정 취소";
      } else if (val == 'CompleteSettlement') {
        return "입금완료";
      }
      return "";
    },
  },
};
</script>

<style scoped>
th {
  text-align: center;
}

td {
  text-align: center;
}

.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.modal-body .control-label {
  margin: 0px;
}

.modal .table {
  margin-bottom: 0px;
}

.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}

.red {
  color: red
}

th {
  background-color: #dee2e685;
  vertical-align: middle !important;;
}
</style>
