<template>
<div class="newboxdor">
    <drop @drop="sumimagesort(index, $event)">
        <drag :transfer-data="{ index: index, mark:'subimgs' }">
            <div class="root" @mouseover="mouseover = true" @mouseleave="mouseover = false">
                <div class="header">
                    <h4>{{title}}</h4>
                    <!-- <div>{{imgdes}}</div> -->
                </div>
                <div class="contents">
                    <div v-for="(image, index) in images" :key="index" class="item" :style="{width: styleImageWidthRatio(image)+'%'}">
                        <img :src="image.url" alt="itemimg" />
                    </div>
                </div>
                <div v-if="!readonly" v-show="mouseover" class="maskbg"></div>
                <button type="button" v-if="!readonly" v-show="mouseover" @click="onrmvProd" class="btn btn-close">
                    <i class="fa fa-fw fa-trash-o"></i>
                </button>
            </div>
            <textarea rows="3" placeholder="이미지 설명" v-model="description" @change="textChange"></textarea>
        </drag>
    </drop>
</div>
</template>

<script>
import {
    Drag,
    Drop
} from "vue-drag-drop";

export default {
    watch: {
        imgdes(val) {
            console.log(val)
            this.description = val;
        }
    },
    components: {
        Drag,
        Drop,
    },
    props: {
        images: {
            type: Array,
            // default:[]
        },
        title: {
            type: String,
            default: "",
        },
        imgdes: {
            type: String,
            default: "",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            mouseover: false,
            description: '',
        };
    },
    computed: {},
    mounted() {
        this.description = this.imgdes;
    },
    methods: {
        sumimagesort(drop, drag) {
            this.$emit('movemouse', drop, drag)
        },
        textChange() {
            this.$emit('descriptionChange', this.index, this.description)
        },
        widthBy1024h(image) {
            return 1024 * image.width / image.height;
        },
        totalWidthBy1024h() {
            return this.images.map(image => this.widthBy1024h(image)).reduce((pre, cur) => pre + cur);
        },
        styleImageWidthRatio(image) {
            let totalWidthBy1024h = this.totalWidthBy1024h();
            let imageWidthBy1024h = this.widthBy1024h(image);

            let widthRatio = imageWidthBy1024h / totalWidthBy1024h * 100;

            return widthRatio;
        },
        oneditProd() {
            this.$emit('editProd');
        },
        onrmvProd() {
            this.$emit('remove');
        },
        onSortDown() {
            this.$emit('sortdown');
        },
        onSortUp() {
            this.$emit('sortup');
        },
    },
};
</script>

<style scoped>
.root {
    /* width: 500px; */
    margin: 0px auto;
    cursor: move;
    position: relative;
    padding: 0px;
    padding-top: 5px;
}

@media (min-width: 768px) {
    /* .root {
        width: 500px;
    } */
}

@media (max-width: 768px) {
    .root {
        width: 100%;
    }
}

.newboxdor {
    display: inline-block;
    width: 49%;
    vertical-align: middle;
}
.newboxdor:nth-child(2n-1) {
    margin-right: 10px;
}
.newboxdor .item img {
    height: 269px;
    /* margin-bottom: 10px; */
    display: inline-block;
    -o-object-fit: contain;
    object-fit: cover;
    background: #f5f5f5;
    border: 1px solid #dedede;
    /* padding: 12px !important; */
    cursor: move;
}

.newboxdor textarea {
    width: 100%;
    margin-top: 10px;
    border-color: #dedede;
    padding: 0.6rem;
    line-height: 1.5;
}

.maskbg {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #00000070;
}

.btn {
    position: absolute;
    background-color: #ffffffa0;
    border-radius: 100px;
    padding: 6px;
    height: 32px;
}

.btn-close {
    top: 5px;
    right: 5px;
}

.btn-edit {
    top: 0px;
    right: 70px;
}

.btn-up {
    top: 0px;
    right: 35px;
}

.btn-down {
    top: 0px;
    right: 0px;
}

.header h4 {
    font-weight: bold;
}

.contents {
    display: flex;
    flex-direction: row;
}

.item {
    /* flex: 1; */
    /* margin: 5px; */
    position: relative;
    padding: 0px;
    background-color: #e8e8e8;
}

.item span {
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item img {
    width: 100%;
}
</style>
