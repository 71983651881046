<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <button type="button" class="btn btn-default" style="margin-right:10px" @click="refreshPage">검색</button>
          <button type="button" class="btn btn-default" @click="print">인쇄하기</button>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button type="button" class="btn btn-default" @click="showManager">
        승인번호관리
      </button>
    </div>
    <div class="box box-solid">
      <!-- <div class="box-header">

        <h3 class="box-title"></h3>
        <div class="box-tools pull-right"></div>
      </div> -->
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>인지세 승인번호</th>
              <th>권종</th>
              <th>신청통수</th>
              <th>발행통수</th>
              <th>취소통수</th>
              <th>사용통수</th>
              <th>미사용통수</th>
              <th>납부금액</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(citem, cindex) in content">
              <tr v-for="(crow, rindex) in citem.contents" :key="(cindex+1)*100+rindex">
                <td :rowspan="citem.contents.length" v-if="rindex == 0">{{citem.stamp}}</td>
                <td>{{crow.taxAmount | numFormat}}</td>
                <td>{{crow.requestedCount | numFormat}}</td>
                <td>{{crow.issuedCount | numFormat}}</td>
                <td>{{crow.cancelledCount | numFormat}}</td>
                <td>{{crow.issuedCount - crow.cancelledCount | numFormat}}</td>
                <td>{{crow.availableCount | numFormat}}</td>
                <td>{{(crow.issuedCount - crow.cancelledCount)*crow.taxAmount | numFormat}}</td>
              </tr>
            </template>
            <tr v-for="(arow, rindex) in allsum" :key="(rindex+1)*10000">
                <td :rowspan="allsum.length" v-if="rindex == 0">계</td>
                <td>{{arow.taxAmount | numFormat}}</td>
                <td>{{arow.requestedCount | numFormat}}</td>
                <td>{{arow.issuedCount | numFormat}}</td>
                <td>{{arow.cancelledCount | numFormat}}</td>
                <td>{{arow.netIssuedCount | numFormat}}</td>
                <td>{{arow.availableCount | numFormat}}</td>
                <td>{{arow.paymentTotal | numFormat}}</td>
              </tr>
            <tr>
              <th colspan="5">납부예상금액</th>
              <td colspan="3">{{total | numFormat}} 원</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="display:none">
      <div id="print_area">
        <div class="form-group text-center"><h4>테이블엔조이 인지세 발행 내역</h4></div>
        <div class="form-group pull-right">기간: {{viewDate(dateFrom)}} ~ {{viewDate(dateTo)}}</div>
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>인지세 승인번호</th>
              <th>권종</th>
              <th>신청통수</th>
              <th>발행통수</th>
              <th>취소통수</th>
              <th>사용통수</th>
              <th>미사용통수</th>
              <th>납부금액</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(citem, cindex) in content">
              <tr v-for="(crow, rindex) in citem.contents" :key="(cindex+1)*100+rindex">
                <td :rowspan="citem.contents.length" v-if="rindex == 0">{{citem.stamp}}</td>
                <td>{{crow.taxAmount | numFormat}}</td>
                <td>{{crow.requestedCount | numFormat}}</td>
                <td>{{crow.issuedCount | numFormat}}</td>
                <td>{{crow.cancelledCount | numFormat}}</td>
                <td>{{crow.issuedCount - crow.cancelledCount | numFormat}}</td>
                <td>{{crow.requestedCount - (crow.issuedCount - crow.cancelledCount) | numFormat}}</td>
                <td>{{(crow.issuedCount - crow.cancelledCount)*crow.taxAmount | numFormat}}</td>
              </tr>
            </template>
            <tr v-for="(arow, rindex) in allsum" :key="(rindex+1)*10000">
                <td :rowspan="allsum.length" v-if="rindex == 0">계</td>
                <td>{{arow.taxAmount | numFormat}}</td>
                <td>{{arow.requestedCount | numFormat}}</td>
                <td>{{arow.issuedCount | numFormat}}</td>
                <td>{{arow.cancelledCount | numFormat}}</td>
                <td>{{arow.netIssuedCount | numFormat}}</td>
                <td>{{arow.availableCount | numFormat}}</td>
                <td>{{arow.paymentTotal | numFormat}}</td>
              </tr>
            <tr>
              <th colspan="5">납부예상금액</th>
              <td colspan="3">{{total | numFormat}} 원</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal" :style="managerdlg && {'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">승인번호관리</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>인지세 승인번호</th>
                      <th>권종(세액)</th>
                      <th>신청통수</th>
                      <th>등록일</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input type="text" class="form-control" v-model="stampDutyNumber"/></td>
                      <td><input type="text" class="form-control" v-model="taxAmount"/></td>
                      <td><input type="number" class="form-control" v-model="requestedCount"/></td>
                      <td></td>
                      <td v-if="stampDutyId == 0"><button type="button" class="btn btn-default" @click="onRegister">등록</button></td>
                      <td v-if="stampDutyId > 0"><button type="button" class="btn btn-default" @click="onUpdate">수정</button></td>
                    </tr>
                    <tr v-for="stamp in stamplist" :key="stamp.stampDutyId">
                      <td>{{stamp.stampDutyNumber}}</td>
                      <td>{{stamp.taxAmount}}</td>
                      <td>{{stamp.requestedCount | numFormat}}</td>
                      <td>{{viewDate(stamp.registeredDate)}}</td>
                      <td><button type="button" class="btn btn-default" @click="onSelStamp(stamp)">선택</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeManager">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import DetePicker from "../../components/DatePicker";
import { reqPost, reqGet } from "../../lib/request";
import { orderapi } from "../../lib/api";
import { DateFormat } from "../../lib/utility";
import moment from 'moment';
export default {
  components: {
    DetePicker,
  },
  created() {
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_ISSUE, path2:menuval.LV1_HOME, title:"인지세관리"});
  },
  data() {
    return {
      dateFrom:moment().format("YYYY-MM-01"),
      dateTo:moment().format("YYYY-MM-DD"),
      managerdlg:false,//승인번호관리
      content:[],
      /**
       * stampDutyNumber        String    인지세 승인번호
       * taxAmount              String    세액(권종)
       * requestedCount         Number    신청수
       * issuedCount            Number    발행수
       * cancelledCount         Number    취소수
       * netIssuedCount         Number    순수 발행수
       * availableCount         Number    발행가능수량
       * paymentTotal           String    납부금액
       * dateFrom               String    yyyy-MM-dd  조회 시작일
       * dateTo                 String    yyyy-MM-dd  조회 종료일
       */

      allsum:[],
      total:0,
      stamplist:[],
      /**
       * stampDutyId              Number    인지세 ID
       * stampDutyNumber          String    인지세 승인번호
       * taxAmount                String    세액(권종)
       * requestedCount           Number    신청수
       * registeredDate           String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       */
      stampDutyId:0,//인지세 ID
      stampDutyNumber:"",//인지세 승인번호
      taxAmount:"",//세액(권종)
      requestedCount:0,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage();
    },
    refreshPage() {
      reqPost(
        orderapi.base,
        orderapi.stampDutyissuedTotal,
        this.authToken,
        {
          dateFrom: this.dateFrom,
          dateTo:this.dateTo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.processContents(result.data);
            } else {
              this.content = [];
              alert(result.message);
            }
          }
        }
      );
    },
    processContents(t_content)
    {
      this.content = [];
      this.allsum = [];
      this.total = 0;
      for(var sidx = 0; sidx< t_content.length; sidx++)
      {
        var t_stampDutyNumber = t_content[sidx].stampDutyNumber;
        var t_idx = this.content.findIndex(mitem => mitem.stamp == t_stampDutyNumber);
        if(t_idx == -1)
        {
          this.content.push({stamp:t_stampDutyNumber, contents:[{...t_content[sidx]}]});
        }else{
          this.content[t_idx].contents.push({...t_content[sidx]});
        }

        var t_taxAmount = t_content[sidx].taxAmount;
        t_idx = this.allsum.findIndex(mitem => mitem.taxAmount == t_taxAmount);
        if(t_idx == -1)
        {
          this.allsum.push({...t_content[sidx], netIssuedCount:t_content[sidx].issuedCount - t_content[sidx].cancelledCount, availableCount:t_content[sidx].availableCount, paymentTotal:(t_content[sidx].issuedCount - t_content[sidx].cancelledCount)*t_content[sidx].taxAmount});
        }else{
          this.allsum[t_idx].requestedCount = parseInt(this.allsum[t_idx].requestedCount) + parseInt(t_content[sidx].requestedCount);
          this.allsum[t_idx].issuedCount = parseInt(this.allsum[t_idx].issuedCount) + parseInt(t_content[sidx].issuedCount);
          this.allsum[t_idx].cancelledCount = parseInt(this.allsum[t_idx].cancelledCount) + parseInt(t_content[sidx].cancelledCount);
          this.allsum[t_idx].netIssuedCount = parseInt(this.allsum[t_idx].netIssuedCount) + parseInt(t_content[sidx].issuedCount - t_content[sidx].cancelledCount);
          this.allsum[t_idx].availableCount = parseInt(this.allsum[t_idx].availableCount) + parseInt(t_content[sidx].availableCount);
          this.allsum[t_idx].paymentTotal = parseInt(this.allsum[t_idx].paymentTotal) + parseInt((t_content[sidx].issuedCount - t_content[sidx].cancelledCount)*t_content[sidx].taxAmount);
        }

        this.total = this.total + parseInt((t_content[sidx].issuedCount - t_content[sidx].cancelledCount)*t_content[sidx].taxAmount);
      }
    },
    closeManager(){
      this.managerdlg = false;
    },
    showManager(){
      this.stampDutyNumber = "";
      this.taxAmount = "";
      this.requestedCount = "";
      reqGet(
        orderapi.base,
        orderapi.stampDutylist,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.stamplist = result.data;
              this.managerdlg = true;
            }else{
              this.stamplist = [];
              alert(result.message);
            }
          }
        }
      );
    },
    print () {
      //vue-html-to-paper
      const options = {
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
          // './path/to/custom.css' // <- inject here
        ]
      };
      this.$htmlToPaper('print_area', options);
    },
    onSelStamp(stamp)
    {
      this.stampDutyId = stamp.stampDutyId;//인지세 ID
      this.stampDutyNumber = stamp.stampDutyNumber;//인지세 승인번호
      this.taxAmount = stamp.taxAmount;//세액(권종)
      this.requestedCount = stamp.requestedCount;//신청수
    },
    onRegister()
    {
      reqPost(
        orderapi.base,
        orderapi.stampDutyregister,
        this.authToken,
        {
          stampDutyNumber: this.stampDutyNumber,
          taxAmount:this.taxAmount,
          requestedCount:this.requestedCount,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.stampDutyNumber = "";
              this.taxAmount = "";
              this.requestedCount = 0;
              this.showManager();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onUpdate()
    {
      reqPost(
        orderapi.base,
        orderapi.stampDutyupdate,
        this.authToken,
        {
          stampDutyId:this.stampDutyId,
          stampDutyNumber: this.stampDutyNumber,
          taxAmount:this.taxAmount,
          requestedCount:this.requestedCount,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.stampDutyId = 0;
              this.stampDutyNumber = "";
              this.taxAmount = "";
              this.requestedCount = 0;
              this.showManager();
            } else {
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>
<style scoped>
th {
  background-color: #dee2e685;
  text-align: center;
}
td {
  text-align: center;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
