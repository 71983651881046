<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-header">
            <h3 class="box-title">매장등록정보</h3>
        </div>
        <div class="box-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-2 control-label">브랜드 명</label>
                <div class="col-sm-10">
                    <div class="input-group" style="width:100%">
                        <!-- <select class="form-control" style="width:100%;max-width:400px;min-width:100px;" v-model="brandCode">
                <option v-for="(bditem, bdidx) in brandCodes" :key="bdidx" :value="bditem.brandCode">{{bditem.brandName}}</option>
              </select> -->
                        <multiselect v-model="brandCode" :options="optionss" placeholder="검색" label="brnm" track-by="brnm" :show-labels="false"></multiselect>
                        <!-- {{ brandCode.brnm }} -->
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">매장명</label>
                <div class="col-sm-10" style="display: inline-flex;flex-wrap: wrap;">
                    <div class="input-group" style="flex:1;max-width:400px;min-width:100px;">
                        <input type="text" class="form-control" v-model="storeName" />
                        <span class="input-group-btn">
                            <button class="btn btn-default" type="button" @click="checkStoreName">중복확인</button>
                        </span>
                    </div>
                    <label :class="['control-label', storecheckclass]" style="margin-left:10px">{{ storecheckmsg }}</label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">주소</label>
                <div class="col-sm-10" style="display:flex;">
                    <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
                        <input type="text" class="form-control" disabled :value="roadAddress" />
                    </div>
                    <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
                        <input type="text" class="form-control" v-model="detailAddress" />
                    </div>
                    <AddressSearch style="display:inline-flex" :detailAddress="detailAddress" :onChange="onSetAddress" @detailaddrEvechiild="detailaddrEve" />
                </div>
                <!-- <div class="col-sm-10" style="display:flex;">
                    <div class="input-group" style="display:inline-flex;margin-right:10px;flex:1;">
                        <input type="text" class="form-control" disabled :value="roadAddress" />
                    </div>
                    <AddressSearch style="display:inline-flex" :onChange="onSetAddress" />
                </div> -->
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">업종</label>
                <div class="col-sm-10">
                    <div class="input-group" style="width:100%">
                        <select class="form-control" style="width:100%;max-width:400px;min-width:100px;" @change="storeTypeNmChange" v-model="storeTypeNm">
                            <option v-for="(item,idx) in storeType" :key="idx" :value="item.storeType">{{ item.typeName }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-2 control-label">사업자등록증</label>
                <div class="col-sm-10" style="display:inline-flex;flex-wrap: wrap;">
                    <div class="input-group" style="display:inline-flex;margin-right:10px;width:100%;max-width:400px;min-width:100px;">
                        <input type="text" class="form-control" v-model="registrationNumber" />
                    </div>
                    <div style="flex:1">
                        <SingleImgUpload v-model="registrationNumberUrl" downlabel="사업자등록증.jpg" :imgtype="imgtype.STORE" />
                    </div>
                </div>
            </div>
        </div>
        <div class="box-footer text-center">
            <button type="button" class="btn btn-default" @click="goBack">취소</button>
            <button type="button" class="btn btn-default" style="margin-left:10px" @click="directRegister">저장</button>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval,
    imgtype
} from "../../../lib/consts";
import SingleImgUpload from "../../../components/SingleImgUpload";
import AddressSearch from "../../../components/AddressSearch";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    brandapi
} from "../../../lib/api";
import {
    global_daumapi
} from "../../../lib/daumapi";
import Multiselect from 'vue-multiselect'

export default {
    components: {
        SingleImgUpload,
        AddressSearch,
        Multiselect,
    },
    data() {
        return {
            detailAddress: "",
            brandCode: "",
            storeName: "",
            storecheckmsg: "",
            storecheckclass: "",
            storeType: [],
            registrationNumber: "",
            registrationNumberUrl: "",
            roadAddress: "",
            jibunAddress: "",
            engAddress: "",
            zipCode: "",
            coordinateX: "",
            coordinateY: "",
            coordinateSystem: "EPSG4326",
            brandNameContaining: "",
            brandCodes: [],
            optionss: [],
            storeTypeNm: {},
            sido: "",
            sigungu: "",
            selectedStoreType : ""

        };
    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_BRD,
            path2: menuval.LV2_BRD_STR,
            title: "",
        });
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        imgtype() {
            return imgtype;
        }
    },
    mounted() {
        this.getBrandList();
    },
    methods: {
        storeTypeNmChange(e){
            this.storeTypeNm = e.target.value;
        },
        getStoreType(type) {
            reqGet(
                brandapi.base,
                brandapi.storeType,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {

                            this.storeType = result.data.types;
                        }
                    }
                }
            );
        },
        detailaddrEve(detailAddress) {
            this.detailAddress = detailAddress;
        },
        onSetAddress(addinfo, detailAddress) {
            this.sido = addinfo.sido
            this.sigungu = addinfo.sigungu
            this.detailAddress = detailAddress;
            this.roadAddress = addinfo.roadAddress;
            this.jibunAddress = addinfo.jibunAddress ? addinfo.jibunAddress : addinfo.autoJibunAddress;
            this.engAddress = addinfo.roadAddressEnglish;
            this.zipCode = addinfo.zonecode;
            global_daumapi.serachAddress(addinfo.roadAddress) //jibunAddress
                .then((responseJson) => {
                    this.coordinateY = responseJson.documents[0].y;
                    this.coordinateX = responseJson.documents[0].x;
                    console.log(responseJson.documents[0].y)
                    this.coordinateSystem = "EPSG4326";
                }).catch((error) => {
                    console.log("Searchaddress error : ", error);
                });
        },
        checkStoreName() {
            if (this.storeName == null || this.storeName == "") {
                this.storecheckmsg = "매장명을 입력하세요.";
                this.storecheckclass = "redmsg";
            } else {
                // console.log(this.brandCode)
                if (this.brandCode.brnm === undefined) {
                    alert('브랜드를 선택해주세요');
                    return;
                }
                reqGet(
                    brandapi.base,
                    brandapi.tnjstrexists + "?brandCode=" + this.brandCode.brandCode + "&storeName=" + encodeURIComponent(this.storeName),
                    this.authToken,
                    (result) => {
                        if (result != null && result.code == rescode.REQ_SUCCESS) {
                            if (result.data == false) {
                                this.storecheckmsg = "사용할 수 있는 매장명입니다.";
                                this.storecheckclass = "blurmsg";
                            } else {
                                this.storecheckmsg = "사용할 수 없는 매장명입니다.";
                                this.storecheckclass = "redmsg";
                            }
                        }
                    }
                );
            }
        },
        getBrandList() {
            reqGet(
                brandapi.base,
                brandapi.codequery + "?brandNameContaining=" + this.brandNameContaining,
                this.authToken,
                (result) => {
                    if (result != null && result.code == rescode.REQ_SUCCESS) {
                        this.brandCodes = result.data.brandCodes;
                        let pp = [];
                        result.data.brandCodes.forEach(function (item) {
                            var oo = {
                                brnm: item.brandName,
                                brandCode: item.brandCode
                            }
                            pp.push(oo)
                        });
                        this.optionss = pp;
                        // console.log(this.optionss)
                        if (this.brandCodes.length > 0) {
                            this.brandCode = this.optionss[0].brandCode;
                        }
                        this.getStoreType()
                        /*
                        brandCodes[].brandCode    String    브랜드 코드
                        brandCodes[].brandName    String    브랜드 명칭
                        */
                    }
                }
            );
        },
        directRegister() {

            this.selectedStoreType = this.storeTypeNm;
            if (this.storeName == null || this.storeName.trim() == "") {
                this.storecheckmsg = "매장명을 입력하세요.";
                this.storecheckclass = "redmsg";
                return;
            }

            if (this.roadAddress == null || this.roadAddress.trim() == "") {
                alert("주소를 입력하세요.");
                return;
            }

            var basicInfo = {
                storeName: this.storeName,
                storeType: this.selectedStoreType,
                registrationNumber: this.registrationNumber,
                registrationNumberUrl: this.registrationNumberUrl
            };

            var address = {
                roadAddress: this.roadAddress,
                jibunAddress: this.jibunAddress,
                detailAddress: this.detailAddress,
                engAddress: this.engAddress,
                zipCode: this.zipCode,
                coordinateX: this.coordinateX,
                coordinateY: this.coordinateY,
                coordinateSystem: this.coordinateSystem,
                region:this.sido,
                city:this.sigungu,
            };

            reqPost(
                brandapi.base,
                brandapi.newStoredirect,
                this.authToken, {
                    brandCode: this.brandCode.brandCode,
                    basicInfo: basicInfo,
                    address: address
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // alert("등록되었습니다.");
                            this.$router.push('/storelist')
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style><style scoped>
.redmsg {
    color: #ff0000;
}

.blurmsg {
    color: #0000ff;
}
</style>
