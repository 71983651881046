<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <div class="input-group radio" style="margin-right:10px">
            <label>
              <input type="radio" value="All" v-model="alimtalkStatus"/>전체
            </label>
            <label>
              <input type="radio" value="RegisterRequested" v-model="alimtalkStatus"/>등록신청
            </label>
            <label>
              <input type="radio" value="Use" v-model="alimtalkStatus"/>사용중
            </label>
            <label>
              <input type="radio" value="UnUse" v-model="alimtalkStatus"/>사용중지
            </label>
          </div>
          <select class="form-control"  style="width:auto;margin-right:10px; margin-top: 3px;" v-model="keywordType">
            <option value="NoKeyword">검색타입</option>
            <option value="BrandCode">브랜드코드</option>
            <option value="BrandNameContaining">브랜드명</option>
            <option value="StoreCode">매장코드</option>
            <option value="StoreNameContaining">매장명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" style="margin-top: 3px;" v-model="keyword" @keyup.enter="refreshPage()"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage()" @keyup.enter="refreshPage()" >검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <div class="pull-right">
        <button type="button" class="btn btn-default " style="margin-right:10px" @click="downExcel()" :disabled="buttonDissabled">
          <i class="fa fa-download"></i>Excel
        </button>
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage()">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="15">15</option>
          <option :value="20">20</option>
          <option :value="25">25</option>
          <option :value="30">30</option>
          <option :value="35">35</option>
          <option :value="40">40</option>
          <option :value="45">45</option>
        </select>
      </div>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">신청 대상</th>
            <th class="text-center">브랜드코드</th>
            <th class="text-center">브랜드명</th>
            <th class="text-center">매장코드</th>
            <th class="text-center">매장명</th>
            <th class="text-center">상태</th>
            <th class="text-center">신청일시</th>
            <th class="text-center">작업</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in content" v-bind:key="index">

            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{item.alimtalkSendType=='SITE'?'사이트':item.alimtalkSendType=='STORE'? '매장' : ''}}</td>
            <td class="linkcmp text-center" @click="goEditbrand(item.brandCode)">{{item.brandCode}}</td>
            <td class="text-center">{{ item.brandName }}</td>
            <td v-if="!item.storeCode" class=" text-center" ></td>
            <td v-else class="linkcmp text-center" @click="goEditStore(item.storeCode,item.brandCode,item.brandName)">{{item.storeCode}}</td>
            <td class="text-center">{{item.storeName}}</td>
            <td class="text-center">{{item.alimtalkStatusType == 'Use'? '사용중':item.alimtalkStatusType == 'RegisterRequested'?'등록신청' :'사용중지'}}</td>
            <td class="text-center">{{viewDate(item.registerDate)}}</td>
            <td v-if="item.alimtalkSendType == 'SITE'" class="text-center">
              <button v-if="item.alimtalkStatusType == 'Use'" class="btn btn-default"  @click="brandAlimtalkDisable(item.brandCode)" >사용중지</button>
              <button v-else-if="item.alimtalkStatusType == 'RegisterRequested'" class="btn btn-default" @click="brandAlimtalkApprove(item.brandCode)"  >등록완료</button>
              <button v-else class="btn btn-default" @click="reapplyBrandAlimTalk(item)" >등록신청</button>
            </td>
            <td v-if="item.alimtalkSendType == 'STORE'" class="text-center">
              <button v-if="item.alimtalkStatusType == 'Use'" class="btn btn-default"  @click="storeAlimtalkDisable(item.storeCode)" >사용중지</button>
              <button v-else-if="item.alimtalkStatusType == 'RegisterRequested'" class="btn btn-default" @click="storeAlimtalkApprove(item.storeCode)">등록완료</button>
              <button v-else class="btn btn-default" @click="reapplyStoreAlimTalk(item)">등록신청</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
      </div>
    </div>
    <div class="modal" >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">미리보기</div>
          <div class="modal-body form-horizontal">
            <textarea class="form-control" rows="5" ></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" >취소</button>
            <button type="button" class="btn btn-default" >확인</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>


  </section>
</template>

<script>
import {
  rescode,
  menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import {
  reqGet, reqPost
} from "../../lib/request";
import {
  brandapi
} from "../../lib/api";
import XLSX from "xlsx";
import { DateFormat } from "../../lib/utility";


export default {
  components: {
    menuval,
    rescode,
    Pagination,

  },
  created() {
    if (localStorage.initval == "1") {
      if(localStorage.Alimlist_Status){
        this.alimtalkStatus = localStorage.Alimlist_Status;
      }
      if (localStorage.Alimlist_keywordType) {
        this.keywordType = localStorage.Alimlist_keywordType;
      }
      if (localStorage.Alimlist_keyword) {
        this.keyword = localStorage.Alimlist_keyword;
      }
      if (localStorage.Alimlist_Number) {
        this.pageNumber = parseInt(localStorage.Alimlist_Number);
      }
      if (localStorage.Alimlist_Size) {
        this.pageSize = parseInt(localStorage.Alimlist_Size);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV1_HOME,
      title: "알림톡관리",
    });

  },

  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      excelData:[],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      alimtalkStatus:"RegisterRequested",
      buttonDissabled : false,
    }
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  watch: {
    alimtalkStatus(newVal) {
      localStorage.Alimlist_Status = newVal;
    },
    keywordType(newVal) {
      localStorage.Alimlist_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.Alimlist_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.Alimlist_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.Alimlist_Size = newsize;
    },
  },
  mounted() {
    if (localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.Alimlist_Status = this.alimtalkStatus
      localStorage.Alimlist_keywordType = this.keywordType;
      localStorage.Alimlist_keyword = this.keyword;
      localStorage.Alimlist_Number = this.pageNumber;
      localStorage.Alimlist_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag){
      var pageNumber = 0;
      if (!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      reqPost(
        brandapi.base,
        brandapi.alimtalkListPage,
        this.authToken,
        {
          keywordType : this.keywordType,
          alimtalkStatus : this.alimtalkStatus,
          keyword : this.keyword,
          pageNumber : pageNumber,
          pageSize : this.pageSize
        },
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              console.log(result.data)


              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    downExcel() {
      this.buttonDissabled = true;
      //alimtalkExcelDown
      reqPost(
        brandapi.base,
        brandapi.alimtalkExcelDown,
        this.authToken,
        {
          keywordType : this.keywordType,
          alimtalkStatus : this.alimtalkStatus,
          keyword : this.keyword,
        },
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {

              this.tmpExcel = result.data;
              this.tmpExcel.forEach((item,idx)=>{
                item.idx = idx
              })
              this.excelData = this.tmpExcel.reverse()
              console.log(this.excelData)
              var alimTalkWS = XLSX.utils.json_to_sheet(
                [{
                  A: "번호",
                  B: "신청대상",
                  C: "브랜드코드",
                  D: "브랜드명",
                  E: "매장코드",
                  F: "매장명",
                  G: "상태",
                  H: "신청일시",
                  I: "사용일시",
                  J: "중지일시",
                }, ], {
                  skipHeader: true
                }
              );
              XLSX.utils.sheet_add_json(alimTalkWS, this.excelData.map(this.convert), {
                skipHeader: true,
                origin: "A2",
              });
              var wb = XLSX.utils.book_new(); // make Workbook of Excel

              XLSX.utils.book_append_sheet(wb, alimTalkWS, "알림톡관리");
              // export Excel file
              XLSX.writeFile(wb, "알림톡리스트.xlsx");
              this.buttonDissabled = false;

          }else{
            this.buttonDissabled = false;
            alert('오류입니다');
          }
        }).then(()=>{

      });


    },
    convert(item) {
      return {
        A: item.idx+1,
        B: item.alimtalkSendType=='SITE'?'사이트':item.alimtalkSendType=='STORE'? '매장':'',
        C: item.brandCode,
        D: item.brandName,
        E: item.storeCode,
        F: item.storeName,
        G: item.alimtalkStatusType=='Use'?'사용중':item.alimtalkStatusType=='UnUse'?'사용중지':item.alimtalkStatusType=='RegisterRequested'?'등록신청':'',
        H: this.viewDate(item.registerDate),
        I: this.viewDate(item.useDate),
        J: this.viewDate(item.unUseDate),
      };
    },
    goEditStore(stcode,brcode,brName) {
      this.$router.push(this.routes.editstore + "?stcode=" + stcode + "&brCode=" + brcode + "&brName=" + brName);
    },
    goEditbrand(brandCode) {
      this.$router.push(this.routes.editbrand + "?bcode=" + brandCode);
    },
    brandAlimtalkDisable(brandCode){
      reqPost(
        brandapi.base,
        brandapi.brandAlimtalkDisable,
        this.authToken, {
          brandCode : brandCode
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    brandAlimtalkApprove(brandCode){
      reqPost(
        brandapi.base,
        brandapi.brandAlimtalkApprove,
        this.authToken, {
          brandCode : brandCode,
          type:'SITE',

        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    reapplyBrandAlimTalk(item){
      reqPost(
        brandapi.base,
        brandapi.alimtalkBrandReg,
        this.authToken, {
          type : "SITE",
          kakaoId : item.kakaoId,
          phoneNumber : item.adminNumber,
          smsRegister : item.smsRegister,
          smsSendNumber : item.smsSendNumber,
          smsRegisterDocumentUrl : item.smsRegisterDocumentUrl,
          smsCertificateDocumentUrl : item.smsCertificateDocumentUrl,
          categoryCode: item.categoryCode,
          brandCode : item.brandCode,
          registerType: 'EXISTREAPPLY'
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    storeAlimtalkDisable(storeCode){
      reqPost(
        brandapi.base,
        brandapi.storeAlimtalkDisable,
        this.authToken, {
          storeCode : storeCode
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    storeAlimtalkApprove(storeCode){
      reqPost(
        brandapi.base,
        brandapi.storeAlimtalkApprove,
        this.authToken, {
          storeCode : storeCode
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    reapplyStoreAlimTalk(item){
      reqPost(
        brandapi.base,
        brandapi.alimtalkStoreReg,
        this.authToken, {
          type : "STORE",
          kakaoId : item.kakaoId,
          phoneNumber : item.adminNumber,
          smsRegister : item.smsRegister,
          smsSendNumber : item.smsSendNumber,
          smsRegisterDocumentUrl : item.smsRegisterDocumentUrl,
          smsCertificateDocumentUrl : item.smsCertificateDocumentUrl,
          categoryCode: item.categoryCode,
          storeCode : item.storeCode,
          registerType: 'EXISTREAPPLY'
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },

  }
}
</script>

<style scoped>

</style>
