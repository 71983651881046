<template>
  <ul class="pagination">
    <li class="paginate_button">
      <a class="linkcmp" @click="click(1)"
      v-if="String(totalpagelength) !== '1'"
        ><i class="fa fa-angle-double-left"
      /></a>
    </li>
    <li class="paginate_button">
      <a class="linkcmp" @click="prevBlock"><i class="fa fa-angle-left" /></a>
    </li>

    <li
      v-for="(num, index) in pagearray"
      v-bind:key="index"
      :class="['paginate_button', curpage == num && 'active']"
    >
      <a class="linkcmp" @click="click(num)">{{ num }}</a>
    </li>

    <li class="paginate_button">
      <a class="linkcmp" @click="nextBlock"><i class="fa fa-angle-right" /></a>
    </li>

    <li class="paginate_button" v-if="String(totalpagelength) !== '1'">
      <a class="linkcmp" @click="click(totalpages)"
        ><i class="fa fa-angle-double-right"
      /></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    curpage: {
      type: Number,
      default: 0,
    },
    totalpages: {
      type: Number,
      default: 0,
      required: true,
    },
    visibles: {
      type: Number,
      default: 3,
      validator(value) {
        return value > 2 && value <= 20;
      },
    },
    click: {
      type: Function,
      required: true,
    },
  },
  computed: {
    startpage(){
      var tmpval = this.curpage % 10;
      if (tmpval == 0) {
        tmpval = 10;
      }
      return this.curpage - tmpval + 1;
    },
    endpage(){
      var tmpval = this.startpage + 9;
      if (tmpval > this.totalpages) {
        tmpval = this.totalpages;
      }
      return tmpval;
    },
    pagearray() {
      if (this.endpage - this.startpage + 1 > 0) {
        return Array(this.endpage - this.startpage + 1)
          .fill()
          .map((_, idx) => this.startpage + idx);
      } else {
        return [];
      }
    },
  },
  data(){
    return{
      totalpagelength: '',
    }
  },
  mounted() {
    console.log(this.totalpages);
  },
  watch:{
    totalpages(val){
      console.log(val)
      this.totalpagelength = val;
    },
  },
  methods: {
    prevBlock() {
      if (this.totalpagelength === 1) {
        return;
      }
      if (this.startpage == 1) {
        this.click(1);
      } else {
        this.click(this.startpage - 10);
      }
    },
    nextBlock() {
      if (this.totalpagelength === 1){
        return;
      }
      if (this.endpage < this.totalpages) {
        this.click(this.startpage + 10);
      } else {
        this.click(this.totalpages);
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  margin: 2px 0px;
}
</style>