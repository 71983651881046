<template>
  <div class="form-group">
    <label class="col-sm-2 control-label" v-if="!noShowLabel">이용 매장 *</label>
    <div :class="[noShowLabel ? 'col-sm-12' : 'col-sm-10']">
      <div class="table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <tbody>
          <tr>
            <td style="display:inline-flex;width:100%">
              <div style="flex:1">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="brandkeychild" placeholder="브랜드명을 입력하세요." @input="pointbrandkeychildUpdate"/>
                  <span class="input-group-btn">
                                        <button type="button" class="btn btn-default btn-flat" @click="pointCouponsearchbrandstore">검색</button>
                                    </span>
                </div>
                <div class="table-responsive p-0" style="height:200px;overflow-y: scroll;width:100%">
                  <table class="table table-hover text-nowrap">
                    <thead>
                    <tr>
                      <th>브랜드명</th>
                      <th>매장명</th>
                      <th>업종</th>
                      <th>운영상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="brandinf in brandstores">
                      <template v-for="storeinf in brandinf.stores">
                        <tr :key="brandinf.brandCode+'_'+storeinf.storeCode" v-if="isNotSelStore(brandinf.brandCode, storeinf.storeCode)" @click="onTselStore(brandinf.brandCode, brandinf.brandName, storeinf.storeCode, storeinf.storeName, storeinf.storeType)" :style="isTselStore(brandinf.brandCode, storeinf.storeCode) && {'background-color': '#D3E4F4'}">
                          <td>{{ brandinf.brandName }}</td>
                          <td>{{ storeinf.storeName }}</td>
                          <td>{{ getStoreType(storeinf.storeType) }}</td>
                          <td>{{ storeinf.enabled ? '활성화' : '비활성화' }}</td>
                        </tr>
                      </template>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style="display:flex;flex-direction: column;height: 240px;justify-content: center;padding:0px 10px">
                <button type="button" class="btn btn-default" @click="addMyServicestore">
                  추가
                  <i class="fa fa-fw fa-caret-right"></i>
                </button>
                <button type="button" class="btn btn-default" @click="rmvMyServicestore">
                  <i class="fa fa-fw fa-caret-left"></i>제거
                </button>
              </div>
              <div style="flex:1">
                <span>[ 사용가능 매장 : 총 {{ getTotalStore }} 개 ]</span>
                <div class="table-responsive p-0" style="height:200px;overflow-y: scroll;width:100%">
                  <table class="table table-hover text-nowrap">
                    <thead>
                    <tr>
                      <th>브랜드명</th>
                      <th>매장명</th>
                      <th>업종</th>
                    </tr>
                    </thead>
                    <tbody>
<!--                                                                {{ serviceCondition.useStoreArea.brands }}-->
                    <template v-for="brandinf in serviceCondition.useStoreArea.brands">
                      <template v-for="storeinf in brandinf.stores">
                        <tr :key="brandinf.brandCode+'_'+storeinf.storeCode" @click="onTMselStore(brandinf.brandCode, brandinf.brandName, storeinf.storeCode, storeinf.storeName, storeinf.storeType)" :style="isTMselStore(brandinf.brandCode, storeinf.storeCode) && {'background-color': '#D3E4F4'}">
                          <td>{{ brandinf.brandName }}</td>
                          <td>{{ storeinf.storeName }}</td>
                          <td>{{ getStoreType(storeinf.storeType) }}</td>
                        </tr>
                      </template>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  brandapi,
  b2bapi,
  goodsapi
} from "../../../../lib/api";
import {
  getGoodsTypeStr,
  getMobileCoupon,
  getPercent,
  getStoreTypeStr
} from "../../../../lib/utility";
import {
  reqGet,
  reqPost
} from "../../../../lib/request";
import {
  menuval,
  rescode,
  imgtype
} from "../../../../lib/consts";
export default {
  computed: {
    getTotalStore() {
      var retval = 0;
      for (var idx = 0; idx < this.serviceCondition.useStoreArea.brands.length; idx++) {
        if (typeof this.serviceCondition.useStoreArea.brands[idx].stores != 'undefined' && this.serviceCondition.useStoreArea.brands[idx].stores != null) {
          retval = retval + this.serviceCondition.useStoreArea.brands[idx].stores.length;
        }
      }
      return retval;
    },
    authToken() {
      return this.$store.getters.authToken;
    },

  },
  watch: {
  },
  data() {
    return {
      brandkeychild: this.brandkey,
      tselstorescc: this.tselstores,
      tmselstorescc: this.tmselstores,
    }
  },
  props: {
    noShowLabel:{
      type:Boolean,
      default:true,
    },
    brandkey: {
      type: String,
      default: "",
    },
    goodsType: {
      type: String,
      default: "",
    },
    tmselstores: {
      type: Array,
      default: [],
    },
    serviceCondition: {
      type: Object,
      default: [],
    },
    brandstores: {
      type: Array,
      default: [],
    },
    tselstores: {
      type: Array,
      default: [],
    }
  },
  mounted(){
    console.log('this.serviceCondition.useStoreArea.brands');
    console.log(this.serviceCondition.useStoreArea.brands);
  },
  methods: {
    pointbrandkeychildUpdate(e){
      //  console.log(this.brandkey)
      this.$emit('pointbrandkeyUpdatechilde', e.target.value)
    },
    pointCouponsearchbrandstore() {
      reqGet(
        brandapi.base,
        brandapi.storesquery + "?brandNameContaining=" + this.brandkey,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {

            // this.brandstores = result.data.brands;
            this.$emit('pointbrandstoresUpdatechilde', result.data.brands);
          }
        }
      );
    },
    isNotSelStore(brandCode, storeCode) {
      var bidx = this.serviceCondition.useStoreArea.brands.findIndex(bitem => bitem.brandCode == brandCode);
      if (bidx == -1) {
        return true;
      }
      var bitem = this.serviceCondition.useStoreArea.brands[bidx];
      if (typeof bitem.stores === 'undefined' || bitem.stores === null) {
        return true;
      }
      var sidx = bitem.stores.findIndex(sitem => sitem.storeCode == storeCode);
      if (sidx == -1) {
        return true;
      }
      return false;
    },
    onTselStore(brandCode, brandName, storeCode, storeName, storeType) {
      var tidx = this.tselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
      if (tidx == -1) {
        var nval = {
          brandCode,
          brandName,
          storeCode,
          storeName,
          storeType
        };
        this.tselstorescc.push(nval);
        this.$emit('pointtselstoreschilde',this.tselstorescc)
      } else {
        this.tselstorescc.splice(tidx, 1);
        this.$emit('pointtselstoreschilde',this.tselstorescc)
      }
    },
    isTselStore(brandCode, storeCode) {
      var tidx = this.tselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
      if (tidx > -1) {
        return true;
      }
      return false;
    },
    getStoreType(val) {
      return getStoreTypeStr(val);
    },
    addMyServicestore() {
      for (var idx = 0; idx < this.tselstores.length; idx++) {
        var bidx = this.serviceCondition.useStoreArea.brands.findIndex(bitem => bitem.brandCode == this.tselstores[idx].brandCode);
        if (bidx == -1) {
          var nbval = {
            brandCode: this.tselstores[idx].brandCode,
            brandName: this.tselstores[idx].brandName,
            storeCodes: [],
            stores: [{
              storeCode: this.tselstores[idx].storeCode,
              storeName: this.tselstores[idx].storeName,
              storeType: this.tselstores[idx].storeType,
            }]
          };
          this.serviceCondition.useStoreArea.brands.push(nbval);
        } else {
          this.serviceCondition.useStoreArea.brands[bidx].stores.push({
            storeCode: this.tselstores[idx].storeCode,
            storeName: this.tselstores[idx].storeName,
            storeType: this.tselstores[idx].storeType,
          });
        }
      }
      this.tselstorescc = [];
      this.$emit('pointtselstoreschilde',this.tselstorescc)
    },
    rmvMyServicestore() {
      for (var idx = 0; idx < this.tmselstores.length; idx++) {
        var bidx = this.serviceCondition.useStoreArea.brands.findIndex(bitem => bitem.brandCode == this.tmselstores[idx].brandCode);
        if (bidx > -1) {
          var bitm = this.serviceCondition.useStoreArea.brands[bidx];
          var sidx = bitm.stores.findIndex(sitem => sitem.storeCode == this.tmselstores[idx].storeCode);
          if (sidx > -1) {
            this.serviceCondition.useStoreArea.brands[bidx].stores.splice(sidx, 1);
          }
        }
      }
      this.tmselstorescc = [];
      this.$emit('pointtmselstoreschild',this.tmselstorescc);
    },
    onTMselStore(brandCode, brandName, storeCode, storeName, storeType) {
      var tidx = this.tmselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
      if (tidx == -1) {
        var nval = {
          brandCode,
          brandName,
          storeCode,
          storeName,
          storeType
        };
        this.tmselstorescc.push(nval);
        this.$emit('pointtmselstoreschild',this.tmselstorescc);
      } else {
        this.tmselstorescc.splice(tidx, 1);
        this.$emit('pointtmselstoreschild',this.tmselstorescc);
      }
    },
    isTMselStore(brandCode, storeCode) {
      var tidx = this.tmselstores.findIndex(titem => titem.brandCode == brandCode && titem.storeCode == storeCode);
      if (tidx > -1) {
        return true;
      }
      return false;
    },
  }
}
</script>

<style>

</style>
