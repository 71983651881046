<template>
<div class="form-group">
    <label class="col-sm-3 control-label"></label>
    <div class="col-sm-9">
        <div class="areafirstDiv">
            <div>
                <span>X 좌표</span>
                <input disabled type="number" v-model.number="center.lng" step="0.0001">
            </div>
            <div>
                <span>y 좌표</span>
                <input disabled type="number" v-model.number="center.lat" step="0.0001">
            </div>
            <div>
                <span>반경</span>
                <input disabled v-model="unit">
                <span>km</span>
            </div>
        </div>
        <div class="mt-2">
            <div class="map_wrap">
                <vue-daum-map :appKey="appKey" :center.sync="center" :level.sync="level" :mapTypeId="mapTypeId" :libraries="libraries" @load="onLoad" @center_changed="onMapEvent('center_changed', $event)" @zoom_start="onMapEvent('zoom_start', $event)" @zoom_changed="circlesCenter('zoom_changed', $event)" @bounds_changed="onMapEvent('bounds_changed', $event)" @click="onmapClick('click', $event)" @dblclick="onMapEvent('dblclick', $event)" @rightclick="onrightclick('rightclick', $event)" @mousemove="onMouseMove('mousemove', $event)" @dragstart="onMapEvent('dragstart', $event)" @drag="circlesCenter('drag', $event)" @dragend="onMapEvent('dragend', $event)" @idle="onMapEvent('idle', $event)" @tilesloaded="onMapEvent('tilesloaded', $event)" @maptypeid_changed="onMapEvent('maptypeid_changed', $event)" style="width:100%;height:600px;">
                </vue-daum-map>
                <div id="menu_wrap" class="bg_white">
                    <div class="option">
                        <div>
                            <form @submit.prevent="searchPlaces('')">
                                키워드 : <input type="text" value="제주도 카카오" id="keyword" size="15">
                                <button type="submit">검색하기</button>
                            </form>
                        </div>
                    </div>
                    <div id="placesList">
                        <li class="item" v-for="(places, cidx) in placeslistItem" :key="cidx">
                            <span :class="'markerbg marker_'+ (cidx + 1)"></span>
                            <div class="info" @click="infoClickEve(places.x,places.y)">
                                <h5>{{ places.place_name }}</h5>
                                <span v-if="places.road_address_name">{{ places.address_name }}</span>
                                <span v-if="places.road_address_name" class="jibun gray"></span>
                                <span v-else>places.address_name</span>
                                <span class="tel">places.phone</span>
                            </div>
                        </li>
                    </div>
                    <div id="pagination"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import config from '../../../config';
import VueDaumMap from 'vue-daum-map';

export default {
    components: {
        VueDaumMap
    },
    props: {
        coordinateX: {
            type: Number,
            default: 0,
        },
        coordinateXval: {
            type: Number,
            default: 0,
        },
        coordinateY: {
            type: Number,
            default: 0,
        },
        coordinateYval: {
            type: Number,
            default: 0,
        },
        radius: {
            type: Number,
            default: 0,
        },
        radiusval: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        coordinateXval(val) {
            this.center.lng = val;
        },
        coordinateYval(val) {
            this.center.lat = val;
        },
        radiusval(val) {
            this.unit = val;
        },
        center(val) {
            this.$emit('coordinateY', val.lat);
            this.$emit('coordinateX', val.lng);
        },
        unit(val) {
            this.$emit('radius', val)
        }
    },
    created() {

    },
    mounted() {

    },
    data() {
        return {
            appKey: config.appKey,
            center: {
                lat: 33.450701,
                lng: 126.570667
            },
            level: 2,
            mapTypeId: VueDaumMap.MapTypeId.NORMAL,
            libraries: ['services'],
            mapObject: null,
            drawingFlag: false,
            centerPosition: {
                La: 126.89712380697262,
                Ma: 37.482470044923765
            },
            // saveB:{La: 126.57169367227243, Ma: 33.450713347980106},
            drawingLine: false,
            drawingCircle: false,
            drawingOverlay: false,
            map: null,
            circles: [],
            circle: "",
            unit: "",
            placeslistItem: [],
            noSearch: '',
        }
    },
    mounted() {

    },
    methods: {
        setLevel(level) {
            switch (level) {
                case 0:
                    return 2;
                case 1:
                    return 5;
                case 2:
                    return 6;
                case 4:
                    return 7;
                case 8:
                    return 8;
                case 16:
                    return 9;
                case 32:
                    return 10;
                case 64:
                    return 11;
                case 128:
                    return 12;
                case 256:
                    return 13;
                case 512:
                    return 14;
            }

        },
        infoClickEve(lng, lat) {
            let bounds = new kakao.maps.LatLngBounds();
            let placePosition = new kakao.maps.LatLng(lat, lng);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가
            bounds.extend(placePosition);

            this.map.setBounds(bounds);

            this.circlesCenter();
        },
        onLoad(map) {
            this.mapObject = map;
            this.map = map;

            this.map.setCenter(new kakao.maps.LatLng(this.coordinateYval, this.coordinateXval));

            //중심 좌표
            let center = new kakao.maps.LatLng(this.centerPosition.Ma, this.centerPosition.La);
            let centerEdt = new kakao.maps.LatLng(this.coordinateYval, this.coordinateXval);
            console.log(this.coordinateYval, this.coordinateXval)
            this.map.setMinLevel(2);
            if (this.$route.query.exhibitionId !== '0') {
                this.center.lat = this.coordinateYval; //경도
                this.center.lng = this.coordinateXval; //위도
                this.daumMapProperty(centerEdt);
                this.circlesCenter();
                this.noSearch = 'no';
                this.searchPlaces('no');
                this.unit = this.radiusval;
                this.map.setLevel(this.setLevel(this.unit));
            } else {
                this.center.lat = this.centerPosition.Ma; //경도
                this.center.lng = this.centerPosition.La; //위도
                this.daumMapProperty(center);
                this.searchPlaces('');
                this.noSearch = '';
            }

        },
        searchPlaces(noSearch) {
            if (noSearch == '') {
                var keyword = document.getElementById('keyword').value;

                if (!keyword.replace(/^\s+|\s+$/g, '')) {
                    alert('키워드를 입력해주세요!');
                    return false;
                }
                let ps = new kakao.maps.services.Places();
                // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
                ps.keywordSearch(keyword, this.placesSearchCB);
            }
        },
        placesSearchCB(data, status, pagination) {
            if (status === kakao.maps.services.Status.OK) {

                // 정상적으로 검색이 완료됐으면
                // 검색 목록 표출
                this.displayPlaces(data);

                //페이징
                this.displayPagination(pagination);

            } else if (status === kakao.maps.services.Status.ZERO_RESULT) {

                alert('검색 결과가 존재하지 않습니다.');
                return;

            } else if (status === kakao.maps.services.Status.ERROR) {

                alert('검색 결과 중 오류가 발생했습니다.');
                return;

            }
        },
        displayPlaces(places) {
            // console.log(places)
            let listEl = document.getElementById('placesList');
            let fragment = document.createDocumentFragment();
            let bounds = new kakao.maps.LatLngBounds();
            let placePosition = new kakao.maps.LatLng(places[0].y, places[0].x);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가
            bounds.extend(placePosition);

            // 검색 결과 목록에 추가된 항목들을 제거합니다
            // this.removeAllChildNods(listEl);
            this.placeslistItem = [];
            let itemEl;
            for (var i = 0; i < places.length; i++) {
                // itemEl = this.getListItem(i, places[i]);
                this.placeslistItem.push(places[i])
                // fragment.appendChild(itemEl);
            }

            // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다

            this.map.setBounds(bounds);

            this.circlesCenter();

            listEl.appendChild(fragment);

            // this.removeCircles();
        },
        daumMapProperty(centerPosition, rClickPosition) {
            this.map.setCenter(new kakao.maps.LatLng(this.center.lat, this.center.lng));
            var level = this.map.getLevel();
            // 원 객체를 생성합니다
            this.circle = new kakao.maps.Circle({
                center: centerPosition, // 원의 중심좌표입니다
                radius: Math.pow(2, level - 3) * 250, // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
                strokeWeight: 1, // 선의 두께입니다
                strokeColor: '#00a0e9', // 선의 색깔입니다
                strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며s 0에 가까울수록 투명합니다
                strokeStyle: 'solid', // 선의 스타일입니다
                fillColor: '#00a0e9', // 채우기 색깔입니다
                fillOpacity: 0.2 // 채우기 불투명도입니다 
            });
            // console.log(polyline.getLength())
            let radius = Math.round(this.circle.getRadius()) // 원의 반경 정보를 얻어옵니다

            // 원을 지도에 표시합니다
            this.circle.setMap(this.map);

            let radiusObj = {
                'circle': this.circle,
            };

            this.circles.push(radiusObj);
            this.unit = this.lengthConversion(radius);
            this.noSearch = '';
        },
        onmapClick(event, params) {

        },
        onMouseMove(event, params) {

        },
        onrightclick(event, params) {

        },
        onMapEvent() {

        },
        getTimeHTML(distance) {

        },
        lengthConversion(length) {

            var result = length;

            if (length >= 1000) {
                result = Math.floor(length / 1000)
            } else {
                result = (length / 1000)
            }

            return result;

        },
        removeCircles() {
            for (var i = 0; i < this.circles.length; i++) {
                this.circles[i].circle.setMap(null);
                this.circles[i].polyline.setMap(null);
                this.circles[i].overlay.setMap(null);
            }
            this.circles = [];
        },
        circlesCenter() {
            var level = this.map.getLevel();
            // if(level < 3){
            //   alert('100m 이하는 등록 할 수 없습니다.');
            //   this.map.setLevel(3);
            //   return;
            // }

            this.circle.setRadius(Math.pow(2, level - 3) * 250);
            // console.log(this.circle.getRadius());

            var position = this.map.getCenter();
            this.circle.setPosition(position);

            //원의 반지름
            this.unit = this.lengthConversion(this.circle.getRadius());

            // console.log(this.lengthConversion(this.circle.getRadius()))
            if (this.$route.query.exhibitionId == '0') {
                this.center.lat = position.Ma;
                this.center.lng = position.La;
            }

        },
        removeAllChildNods(el) {
            while (el.hasChildNodes()) {
                el.removeChild(el.lastChild);
            }
        },
        displayPagination(pagination) {
            var paginationEl = document.getElementById('pagination'),
                fragment = document.createDocumentFragment(),
                i;

            // 기존에 추가된 페이지번호를 삭제합니다
            while (paginationEl.hasChildNodes()) {
                paginationEl.removeChild(paginationEl.lastChild);
            }

            for (i = 1; i <= pagination.last; i++) {
                var el = document.createElement('a');
                el.href = "#";
                el.innerHTML = i;

                if (i === pagination.current) {
                    el.className = 'on';
                } else {
                    el.onclick = (function (i) {
                        return function () {
                            pagination.gotoPage(i);
                        }
                    })(i);
                }

                fragment.appendChild(el);
            }
            paginationEl.appendChild(fragment);
        }
    },
}
</script>

<style scoped>
.areafirstDiv {
    display: flex;
    justify-content: space-between;
}

.areafirstDiv>div>span:first-child {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 600;
}

.areafirstDiv>div>input {
    background: #eee;
    border: 1px solid #d2d6de;
    padding: 6px 12px;
}

.areafirstDiv>div>span:last-child {
    margin-left: 10px;
    font-size: 13px;
    margin-right: 50px;
    font-weight: 600;
}

.mt-2 {
    margin-top: 20px;
}
</style><style>
.map_wrap,
.map_wrap * {
    margin: 0;
    padding: 0;
    font-family: 'Malgun Gothic', dotum, '돋움', sans-serif;
    font-size: 12px;
}

.map_wrap a,
.map_wrap a:hover,
.map_wrap a:active {
    color: #000;
    text-decoration: none;
}

.map_wrap {
    position: relative;
    width: 100%;
    /* height: 500px; */
}

#menu_wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    margin: 10px 0 30px 10px;
    padding: 5px;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
    font-size: 12px;
    border-radius: 10px;
}

.bg_white {
    background: #fff;
}

#menu_wrap hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #5F5F5F;
    margin: 3px 0;
}

#menu_wrap .option {
    text-align: center;
}

#menu_wrap .option p {
    margin: 10px 0;
}

#menu_wrap .option button {
    margin-left: 5px;
}

#placesList li {
    list-style: none;
}

#placesList .item {
    position: relative;
    border-bottom: 1px solid #888;
    overflow: hidden;
    cursor: pointer;
    min-height: 65px;
}

#placesList .item span {
    display: block;
    margin-top: 4px;
}

#placesList .item h5,
#placesList .item .info {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#placesList .item .info {
    padding: 10px 0 10px 55px;
}

#placesList .info .gray {
    color: #8a8a8a;
}

#placesList .info .jibun {
    padding-left: 26px;
    background: url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/places_jibun.png) no-repeat;
}

#placesList .info .tel {
    color: #009900;
}

#placesList .item .markerbg {
    float: left;
    position: absolute;
    width: 36px;
    height: 37px;
    margin: 10px 0 0 10px;
    background: url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png) no-repeat;
}

#placesList .item .marker_1 {
    background-position: 0 -10px;
}

#placesList .item .marker_2 {
    background-position: 0 -56px;
}

#placesList .item .marker_3 {
    background-position: 0 -102px
}

#placesList .item .marker_4 {
    background-position: 0 -148px;
}

#placesList .item .marker_5 {
    background-position: 0 -194px;
}

#placesList .item .marker_6 {
    background-position: 0 -240px;
}

#placesList .item .marker_7 {
    background-position: 0 -286px;
}

#placesList .item .marker_8 {
    background-position: 0 -332px;
}

#placesList .item .marker_9 {
    background-position: 0 -378px;
}

#placesList .item .marker_10 {
    background-position: 0 -423px;
}

#placesList .item .marker_11 {
    background-position: 0 -470px;
}

#placesList .item .marker_12 {
    background-position: 0 -516px;
}

#placesList .item .marker_13 {
    background-position: 0 -562px;
}

#placesList .item .marker_14 {
    background-position: 0 -608px;
}

#placesList .item .marker_15 {
    background-position: 0 -654px;
}

#pagination {
    margin: 10px auto;
    text-align: center;
}

#pagination a {
    display: inline-block;
    margin-right: 10px;
}

#pagination .on {
    font-weight: bold;
    cursor: default;
    color: #777;
}
</style>
