<template>
<div class="modal" style="display:block">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">키워드 관리
                <div class="modal-body form-horizontal">
                    <div class="form-group" style="margin:0px;">
                        <div class="row">
                            <button class="btn btn-default" @click="keyaddShow">키워드 추가</button>
                        </div>
                        <div>
                            <div v-if="keyadd" class="keyadd row">
                                <div class="row mt-2">
                                    <div class="col-sm-2 titleBold">아이콘</div>
                                    <div class="col-sm-10">
                                        <SingleThumbnail v-if="iconadd.image != null" v-model="iconadd.image.keywordImage" :imgtype="imgtype.GOODS" :imgFile="{}" :change="iconaddchange" />
                                        <!-- {{ iconadd }} -->
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-2 titleBold">키워드</div>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" v-model="keywordInput" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-2 titleBold">활성여부</div>
                                    <div class="col-sm-10">
                                        <label class="mr-2"> <input class="mr-2" type="radio" :value='false' v-model="showType" />활성 </label>
                                        <label> <input class="mr-2" type="radio" :value="true" v-model="showType" />비활성 </label>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <button type="button" class="btn btn-default" @click="addIconArray">추가</button>
                                </div>
                            </div>
                            <div class="box-body table-responsive p-0 row">
                                <table class="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">관리코드</th>
                                            <th class="text-center">아이콘</th>
                                            <th class="text-center">키워드</th>
                                            <th class="text-center">등록일</th>
                                            <th class="text-center">활성여부</th>
                                            <th class="text-center">노출순서</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iimg, iidx) in icon.image" :key="iidx">
                                            <td class="text-center">{{ iimg.id }}</td>
                                            <td class="text-center">
                                                <TableSigleImg v-model="iimg.keywordImage" :imgtype="imgtype.GOODS" :imgFile="{}" :index="iidx" style="margin: auto;" :change="onIconImage" />
                                            </td>
                                            <td class="text-center">{{ iimg.keyword }}</td>
                                            <td class="text-center">{{ iimg.regDate.substring(0,10) }}</td>
                                            <td class="text-center">
                                                <label>
                                                    <!-- {{ iimg.useFlag }} -->
                                                    <input type="radio" :v-model="iimg.useFlag" :name="iimguseFlag + iidx" :value="true" :checked="iimg.useFlag === true" @change="activeState($event,iidx)">
                                                    활성
                                                </label>
                                                <label>
                                                    <input type="radio" :v-model="iimg.useFlag" :name="iimguseFlag + iidx" :value="false" :checked="iimg.useFlag === false" @change="activeState($event,iidx)">
                                                    비활성
                                                </label>

                                            </td>
                                            <td class="text-center">
                                                <drop @drop="handleDrop({ index:iidx }, $event)">
                                                    <drag class="btn btn-default btn-social-icon" :transfer-data="{ index:iidx }"><i class="fa fa-fw fa-arrows-v"></i>
                                                    </drag>
                                                </drop>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group text-center">
                                <button type="button" class="btn btn-default" style="margin-right:10px" @click="ReviewModalCloseChild">닫기</button>
                                <button type="button" class="btn btn-default" @click="reviewEdit">수정</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    menuval,
    rescode,
    imgtype
} from "../../../lib/consts";

import {
    reqGet,
    reqPost
} from "../../../lib/request";

import {
    personalization
} from "../../../lib/api";

import SingleThumbnail from "../../../components/SingleThumbnail";
import TableSigleImg from "../../../components/TableSigleImg"
import NewProdImg from '../../../components/newProdImg.vue';
import {
    Drag,
    Drop
} from "vue-drag-drop";

export default {
    data() {
        return {
            keyadd: false,
            showType: false,
            iconadd: {
                image: {}
            },
            iimguseFlag: [],
            icon: {
                image: []
            },
            keywordInput: "",

        }
    },
    components: {
        SingleThumbnail,
        NewProdImg,
        TableSigleImg,
        Drag,
        Drop
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        imgtype() {
            return imgtype;
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        activeState(val, idx) {
            if (val.target._value === 'true') {
                this.icon.image[idx].useFlag = true;
            } else {
                this.icon.image[idx].useFlag = false;
            }
            console.log(this.icon.image);
        },
        ReviewModalCloseChild() {
            this.$emit('ReviewModalClose')
        },
        keyaddShow() {
            if (this.keyadd === true) {
                this.keyadd = false;
            } else {
                this.keyadd = true;
            }
        },
        onIconImage(newval, w, h, i) {
            this.icon.image[i].keywordImage = newval;
            this.icon.image[i].width = w;
            this.icon.image[i].height = h;
        },
        iconaddchange(newval, w, h) {
            this.iconadd.image.keywordImage = newval;
            this.iconadd.image.width = w;
            this.iconadd.image.height = h;
        },
        addIconArray() {
            if (Object.keys(this.iconadd.image).length === 0) {
                alert('아이콘을 넣어주세요');
                return;
            }
            if (this.keywordInput === "") {
                alert('키워드를 넣어주세요.');
                return;
            }
            let imgs = {
                keywordImage: this.iconadd.image.keywordImage, //메인 이미지 keywordImage
                // width: this.iconadd.image.width, //메인 이미지 width
                // height: this.iconadd.image.height, //메인 이미지 height
                keyword: this.keywordInput,
                useFlag: this.showType,
            }
            // this.icon.image.push(imgs);

            reqPost(
                personalization.base,
                personalization.reviewRegister,
                this.authToken, imgs,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.iconadd.image = {};
                            this.keywordInput = "";
                            alert('추가되었습니다.');
                            this.refresh();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refresh() {
            reqGet(
                personalization.base,
                personalization.reviewKeywordList,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.icon.image = result.data;
                        }
                    }
                }
            );
        },
        handleDrop(myitem, drag) {
            var t_array = this.icon.image;
            this.icon.image = [];
            var t_val = t_array[myitem.index];
            t_array[myitem.index] = t_array[drag.index];
            t_array[drag.index] = t_val;
            this.icon.image = t_array;
        },
        reviewEdit() {
            let newArr = [];
            this.icon.image.forEach((el,idx) => {
                newArr.push({
                    keywordImage: el.keywordImage,
                    useFlag: el.useFlag,
                    keywordId: el.id,
                    order: idx + 1,
                })
            });

            reqPost(
                personalization.base,
                personalization.reviewKeywordUpdate,
                this.authToken, {
                    "updateList": newArr
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('수정되었습니다.');
                            this.refresh();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        }
    }
}
</script>

<style scoped>
.modal {
    overflow: auto;
}

.mt-2 {
    margin-top: 20px;
}

.titleBold {
    font-weight: bold;
}

.mr-2 {
    margin-right: 10px !important;
}

.keyadd {
    border: 1px solid #dedede;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}
</style>
