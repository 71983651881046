<template>
  <div id="app">
    <div id="disabled_layout" :class="{on : disabledLayout}"></div>
    <router-view/>
  </div>
</template>

<script>
import {rescode, cookielbl} from './lib/consts'
import {reqPost} from "./lib/request";
import {adminapi} from "./lib/api";

export default {
  components: {},

  created() {
    window.addEventListener('beforeunload', this.handler);
    document.addEventListener('keydown', this.logKey);
  },
  data() {
    return {
      disabledLayout: false,
      validNavigation: false,
      timecount: null,
    }
  },
  mounted() {
    this.timecount = setInterval(this.checkToken, 600000);
    // this.timecount = setInterval(this.checkToken, 60000);
  },

  watch: {
    //테스트22
    dislayout(val){
      this.disabledLayout = val;
    }
  },
  computed: {
    dislayout() {
      return this.$store.getters.disabledLayout;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    expiresIn() {
      return this.$store.getters.expiresIn;
    },
    reToken() {
      return this.$store.getters.reToken;
    },
    reExpiresIn() {
      return this.$store.getters.reExpiresIn;
    },
    reExpiresAt() {
      return this.$store.getters.reExpiresAt;
    },
    myRole() {
      return this.$store.getters.myRole;
    }
  },
  methods: {
    setDisabled() {


    },

    handler() {
      if(!this.validNavigation) {
        if(this.timecount)
          clearInterval(this.timecount);
        this.$store.dispatch('clearcookie');
      }
    },
    logKey(e) {
      if(e.keyCode == 116) {
        this.validNavigation = true;
      }
    },
    checkToken() {
      if(this.reExpiresAt > Math.floor(Date.now()/1000)) {
        reqPost(adminapi.base, adminapi.refresh, this.authToken, {refreshToken: this.reToken}, (result) => {
          // console.log(`result = ${result.code}`)
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              let expiresAt = result.data.access.expiresIn + Math.floor(Date.now()/1000);

              // var authdata = {token: result.data.access.token, expiresIn: result.data.access.expiresIn, expiresAt: expiresAt};

              var authdata = {token:result.data.access.token, expiresIn:result.data.access.expiresIn, expiresAt: expiresAt,
                refresh:this.reToken, refreshexpiresIn:this.reExpiresIn, refreshexpiresAt: this.reExpiresAt,
                userRole:this.myRole};

              if (window.localStorage) {
                window.localStorage.setItem(cookielbl.authinf, JSON.stringify(authdata));
              }

              this.$store.dispatch('doResetToken', authdata);
            }
          }
        });
      }
    }
  }
}
</script>
<style>

#disabled_layout {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: absolute;
  /*background-color: #000;*/
  display: none;
}

#disabled_layout.on {
  display: block;
}

.tox-notifications-container {
  visibility: hidden ;
}

.tox-notification {
    display: none!important;
}
.modal {overflow: auto;}
.vc-alpha{
  display: none;
}

</style>
