<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group" style="margin:0px">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <colgroup>
                            <col width="150px">
                            <col width="45%">
                            <col width="150px">
                            <col width="45%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>예약번호</th>
                                <td colspan="3">{{bookingDetails.bookingNo}}</td>
                            </tr>
                            <tr v-if="bookingDetails.b2bChannelCode=='KAKAOB'">
                              <th>외부채널 예약번호</th>
                              <td class="linkcmp" colspan="3" @click="goDetailPage(bookingDetails.orderNo)">{{bookingDetails.b2bBookingNo}}</td>
                            </tr>
                            <tr>
                                <th>예약자명</th>
                                <td colspan="3">{{bookingDetails.visitorName}}
                                    <button type="text" v-if="(bookingDetails.bookingStatus == 'BookingRequested' || bookingDetails.bookingStatus == 'Booked') && bookingDetails.b2bChannelCode !='KAKAOB' " class="btn btn-default" style="margin-left:20px" @click="showEditInf">예약자 정보 수정</button>
                                </td>
                            </tr>
                            <tr>
                                <th>예약자 전화번호</th>
                                <td colspan="3">{{bookingDetails.visitorPhone}}</td>
                            </tr>
                            <tr>
                                <th>브랜드</th>
                                <td>{{bookingDetails.brandName}}</td>
                                <th>매장</th>
                                <td>{{bookingDetails.storeName}}</td>
                            </tr>
                            <tr>
                                <th>방문예정일</th>
                                <td colspan="3">{{viewDate(bookingDetails.reservationDate)}}</td>
                            </tr>
                            <tr>
                                <th>방문인원</th>
                                <td colspan="3">{{bookingDetails.adultHeadcount+bookingDetails.childHeadcount+bookingDetails.babyHeadcount}}명 ({{getHeadcount()}})</td>
                            </tr>
                            <tr>
                                <th>요청사항</th>
                                <td colspan="3">{{bookingDetails.visitorRequests}}</td>
                            </tr>
                            <tr>
                                <th>예약상태</th>
                                <td colspan="3">{{getBookState(bookingDetails.bookingStatus)}}
                                    <button type="button" class="btn btn-default" style="margin-left:20px" v-if="bookingDetails.bookingStatus == 'BookingRequested'" @click="doApproveBookingRequest">예약승인</button>
                                    <button type="button" class="btn btn-default" style="margin-left:20px" v-if="bookingDetails.bookingStatus == 'BookingRequested'" @click="doRejectBookingRequest">예약불가</button>
                                    <button type="button" class="btn btn-default pull-right" @click="doResendBookingVoucher">예약 문자 재발송</button></td>
                            </tr>
                            <tr>
                                <th>예약신청일</th>
                                <td colspan="3">{{viewDate(bookingDetails.bookingRequestDate)}} <span v-if="bookingDetails.bookingCancelledDate != null"> (예약취소 : {{viewDate(bookingDetails.bookingCancelledDate)}})</span></td>
                            </tr>
                            <tr>
                              <th>예약경로</th>
                              <td colspan="3">{{parseBookingRoot(bookingDetails)}} ({{bookingDetails.freeBooking ? "수기예약" : bookingDetails.bookedOnPurchase ? "즉시예약" : "나중예약"}})</td>
                            </tr>
                            <tr v-if="bookingDetails.depositBooking === true">
                                <th>타임세일 할인율</th>
                                <td colspan="3">{{ Number(bookingDetails.timeSaleRate) }}% </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="form-group">
          <button type="button" class="btn btn-default pull-right" @click="showAddcoupon">쿠폰 추가</button>
        </div> -->
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th class="text-center">번호</th>
                                <th class="text-center">쿠폰번호</th>
                                <th class="text-center" v-if="bookingDetails.b2bChannelCode=='KAKAOB'">외부채널 예약상세번호</th>
                                <th class="text-center">예약타입</th>
                                <th class="text-center">상품명</th>
                                <th class="text-center">옵션명</th>
                                <th class="text-center">상태</th>
                                <th v-if="bookingType !== 'StoreBooking'"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(oitem, oidx) in bookingDetails.orderItems" :key="oitem.itemNo">
                                <td class="text-center">{{oidx + 1}}</td>
                                <td class="linkcmp text-center" @click="goDetailPage(oitem.orderNo)">{{oitem.itemNo}}</td>
                                <td class="text-center" v-if="bookingDetails.b2bChannelCode=='KAKAOB'">{{oitem.b2bOrderDetailNo}}</td>
                                <td class="text-center">{{ bookTypeVal(bookingType) }}</td>
                                <td class="text-center">{{oitem.goodsName}}</td>
                                <td class="text-center">{{ oitem.optionName }}</td>
                                <td class="text-center">{{getItemStatuStr(oitem.itemStatus)}} </td>
                                <td v-if="bookingType !== 'StoreBooking'"><button type="button" class="btn btn-social-icon" @click="doRemoveBookingItem(oitem.itemNo)"><i class="fa fa-fw fa-trash-o"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <colgroup>
                            <col width="100px">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>메모</th>
                                <td>
                                    <textarea class="form-control" rows="5" v-model="bookingDetails.memo" readonly></textarea>
                                    <div class="from-group" style="display:inline-flex;width: 100%;margin-top:10px">
                                        <input type="text" class="form-control" style="flex:1" v-model="memo" />
                                        <button type="button" class="btn btn-default" @click="doSaveMemo">메모저장</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group text-center">
                <button type="button" v-if="bookingDetails.bookingStatus == 'BookingRequested' || bookingDetails.bookingStatus == 'Booked' || bookingDetails.bookingStatus == 'CancelRequested'" class="btn btn-default" style="margin-right:10px" @click="doCancelBooking">예약취소</button>
                <button type="button" v-if="bookingDetails.bookingStatus == 'Booked'" class="btn btn-default" style="margin-right:10px" @click="doVisitStore">방문완료</button>
                <button type="button" v-if="bookingDetails.bookingStatus == 'Booked'" class="btn btn-default" style="margin-right:10px" @click="doNoShowBooking">노쇼</button>
                <button type="button" v-if="bookingDetails.bookingStatus == 'Visited'" class="btn btn-default" style="margin-right:10px" @click="doCancelVisitStore">방문완료취소</button>
                <button type="button" v-if="bookingDetails.bookingStatus == 'NoShow'" class="btn btn-default" @click="doCancelNoShow">노쇼취소</button>
            </div>
        </div>
    </div>
    <div class="modal" :style="editinfdlg && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">예약자 정보 수정</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group">
                        <label class="col-xs-3 control-label">예약자명</label>
                        <div class="col-xs-9">
                            <input type="text" class="form-control" v-model="visitorName" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-xs-3 control-label">예약자 전화번호</label>
                        <div class="col-xs-9">
                            <input type="text" class="form-control" v-model="visitorPhone" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-xs-3 control-label">예약자 이메일</label>
                        <div class="col-xs-9">
                            <input type="text" class="form-control" v-model="visitorEmail" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-xs-3 control-label">요청사항</label>
                        <div class="col-xs-9">
                            <textarea class="form-control" rows="5" v-model="visitorRequests"></textarea>
                        </div>
                    </div>
                    <div class="form-group text-center" style="margin-left:0px;margin-right:0px;">
                        <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeEditInf">취소</button>
                        <button type="button" class="btn btn-default" @click="dochangeVisitor">저장</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :style="addcoupondlg && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">쿠폰 추가</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group">
                        <label class="col-sm-3 control-label">쿠폰번호</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="itemNo" />
                                <span class="input-group-btn">
                                    <button class="btn btn-default" type="button" @click="doQueryAddableBookingItem">조회</button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="table-responsive p-0">
                            <table class="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>쿠폰번호</th>
                                        <th>상품명</th>
                                        <th>옵션명</th>
                                        <th>쿠폰상태</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="nitem in newIteminfs" :key="nitem.itemNo">
                                        <td>{{nitem.itemNo}}</td>
                                        <td>{{nitem.goodsName}}</td>
                                        <td>{{nitem.optionName}}</td>
                                        <td>사용가능</td>
                                        <td><button type="button" class="btn btn-social-icon" @click="doRemoveNewItem(nitem.itemNo)"><i class="fa fa-fw fa-trash-o"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeAddcoupon">취소</button>
                        <button type="button" class="btn btn-default" @click="doAddBookingItems">추가</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 재발송 팝업 -->
    <div class="modal resendModal" v-if="resenddlg" :style="{'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">재발송</div>
                <div class="modal-body form-horizontal">
                    <div class="radio">
                        <label style="margin-right:20px"><input type="radio" class="pr-2" value="false" v-model="useSms">알림톡</label>
                        <label><input type="radio" class="pr-2" value="true" v-model="useSms">SMS전송</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                        <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeResenddlg">취소</button>
                        <button type="button" class="btn btn-default" @click="resendClick">전송</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" v-if="newcancelBook" :style="{'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">{{ newcancelBookTitle }}</div>
                <div class="modal-body form-horizontal">
                    <div class="row" style="margin:0px;" v-if="btnType === 'cancel' && bookingDetails.b2bChannelCode!='KAKAOB'">
                        <label class="col-xs-2 control-label" style="padding-top: 0px;">취소시간:</label>
                        <div class="col-xs-10 displayll" style="margin:0px">
                            <div>
                                <DetePicker style="margin-right:10px;" v-model="consumeUseData" />
                            </div>
                            <div class="input-group">
                                <input type="time" class="form-control" style="width:auto;" v-model="consumeUseTime" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="radio">
                            <label style="margin-right:20px">
                                <input type="radio" value="Payment" :disabled="!avaArray.includes('PaymentCancel')" v-model="cancelmethod" />결제취소
                            </label>
                            <label style="margin-right:20px">
                                <!--  || !refundData.needsRefundAccountInfo 포인트권 트랜젝션으로 인한 결제취소 불가 현상으로 인해 제거 231213 kevin-->
                                <input type="radio" value="RefundCs" id="RefundCs" :disabled="!avaArray.includes('CreateRefundCs') || bookingDetails.b2bChannelCode=='KAKAOB'" v-model="cancelmethod" />계좌이체환불
                            </label>
                            <label style="margin-right:20px">
                                <input type="radio" value="RestoreCoupon" :disabled="!avaArray.includes('RestoreCoupon') ||bookingDetails.b2bChannelCode=='KAKAOB' " v-model="cancelmethod" />쿠폰상태복원
                            </label>
                        </div>
                    </div>
                    <div class="form-group" v-if="cancelmethod !== 'RestoreCoupon'">
                        <label class="col-xs-2 control-label" style="padding-top:0px;">위약금 부과 안함:</label>
                        <div class="col-xs-10">
                            <input type="checkbox" style="margin-left:0px" :disabled="!refundData.refundInfo.hasRefundFeeOrPenalty" :value="false" v-model="bookingPenalty" />
                        </div>
                    </div>
                    <div style="border:1px solid; margin-bottom:10px;" v-if="cancelmethod !== 'RestoreCoupon'">
                        <div class="form-group" style="margin:0px;">
                            <label class="col-xs-2 control-label" style="padding-top: 0px;">위약금:</label>
                            <div class="col-xs-10" v-if="!bookingPenalty">{{refundData.refundInfo.bookingPenaltyTotal | numFormat}}</div>
                            <div class="col-xs-10" v-else>0</div>
                        </div>
                        <div class="form-group" style="margin:0px;">
                            <label class="col-xs-2 control-label" style="padding-top: 0px;">환불 금액:</label>
                            <div class="col-xs-10" v-if="!bookingPenalty">{{refundData.refundInfo.refundAmountTotal | numFormat}}</div>
                            <div class="col-xs-10" v-else>{{refundData.refundInfo.refundableAmountTotal | numFormat}}</div>
                        </div>
                        <div class="form-group" style="margin:0px;" v-if="refundData.refundInfo.paymentRefundDetails != null">
                            <div v-if="refundData.refundInfo.paymentRefundDetails.payment !== null">
                                <label class="col-xs-2 control-label" style="padding-top: 0px;">- Agent:</label>
                                <div class="col-xs-10">
                                    <span v-if="!bookingPenalty">{{refundData.refundInfo.paymentRefundDetails.payment | numFormat}}</span>
                                    <span v-else>{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.payment | numFormat}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" style="margin:0px;" v-if="refundData.refundInfo.paymentRefundDetails != null">
                            <div v-if="refundData.refundInfo.paymentRefundDetails.cash !== null">
                                <label class="col-xs-2 control-label" style="padding-top: 0px;">- Cash:</label>
                                <div class="col-xs-10">
                                    <span v-if="!bookingPenalty">{{refundData.refundInfo.paymentRefundDetails.cash | numFormat}}</span>
                                    <span v-else>{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.cash | numFormat}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" style="margin:0px;" v-if="refundData.refundInfo.paymentRefundDetails != null">
                            <div v-if="refundData.refundInfo.paymentRefundDetails.point !== null">
                                <label class="col-xs-2 control-label" style="padding-top: 0px;">- Reward:</label>
                                <div class="col-xs-10">
                                    <span v-if="!bookingPenalty">{{refundData.refundInfo.paymentRefundDetails.point | numFormat}}</span>
                                    <span v-else>{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.point | numFormat}}</span>
                                </div>
                            </div>
                        </div>
                      <div class="form-group" style="margin:0px;" v-if="refundData.refundInfo.paymentRefundDetails != null">
                        <div v-if="refundData.refundInfo.paymentRefundDetails.pointTicket !== null">
                          <label class="col-xs-2 control-label" style="padding-top: 0px;">- pointTicket:</label>
                          <div class="col-xs-10">
                            <span v-if="!bookingPenalty">{{refundData.refundInfo.paymentRefundDetails.pointTicket | numFormat}}</span>
                            <span v-else>{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.pointTicket | numFormat}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="border:1px solid; margin-bottom:10px;" v-if="cancelmethod == 'RefundCs'">
                        <div class="form-group">
                            <label class="col-sm-6 control-label" v-if="cancelmethod == 'Payment'">결제 취소 환불 계좌 정보</label>
                            <label class="col-sm-6 control-label" v-if="cancelmethod == 'RefundCs'">환불 계좌 정보</label>
                            <div class="hidden-xs col-sm-6" style="margin:0px"></div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-6 control-label">은행</label>
                            <div class="col-xs-6">
                                <select class="form-control" style="width:auto;" v-model="refundAccount.bankCode" @change="onChangeBank">
                                    <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{bank.bankName}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-6 control-label">계좌번호</label>
                            <div class="col-xs-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="refundAccount.accountNumber" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-xs-6 control-label">예금주명</label>
                            <div class="col-xs-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="refundAccount.accountHolder" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-xs-3 control-label">환불사유 : </label>
                        <div class="col-xs-9 input-group" style="display: flex">
                          <select class="form-control mr-3" v-model="type" style="min-width: 200px; max-width: 200px">
                            <template v-for="item in typeList">
                              <option :value="item.value" :hidden="!item.value" >
                                {{item.label}}
                              </option>
                            </template>
                          </select>
                          <select class="form-control " v-model="reasonType" >
                            <template v-for="item in reasonTypeList">
                              <option :value="item.value" :hidden="!item.value" >
                                {{item.label}}
                              </option>
                            </template>
                          </select>
                        </div>
                        <label class="col-xs-3 control-label"></label>
                        <div class="col-xs-9 input-group margin-top-5">
                            <input type="text" class="form-control" v-model="requestReason" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                        <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeNewBookdlg">취소</button>
                        <button type="button" class="btn btn-default" @click="approveBookReq">승인</button>
                    </div>
                </div>
                <h5 class="refund-summary" style="margin-top: 40px">환불요약</h5>
                <div class="form-group refund-summary" style="margin-left:0px;margin-right:0px">
                    <div class="table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                            <colgroup>
                                <col width="100px">
                                <col width="25%">
                                <col width="100px">
                                <col width="25%">
                                <col width="100px">
                                <col width="25%">
                                <col width="100px">
                                <col width="25%">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>정상가</th>
                                    <td colspan="3">{{refundData.refundInfo.listPriceTotal | numFormat}}</td>
                                    <th>사용금액</th>
                                    <td colspan="3">{{refundData.refundInfo.usedAmountTotal | numFormat}}</td>
                                </tr>
                                <tr>
                                    <th>판매가</th>
                                    <td colspan="3">{{refundData.refundInfo.salePriceTotal | numFormat}}</td>
                                    <th>환불가능금액</th>
                                    <td colspan="3">{{refundData.refundInfo.refundableAmountTotal | numFormat}}</td>
                                </tr>
                                <tr>
                                    <!-- <th>할인금액</th>
                                        <td colspan="3">{{refundData.discountAmountTotal | numFormat}}</td> -->
                                    <th>할인쿠폰</th>
                                    <td colspan="3">{{refundData.refundInfo.discountCouponAmount | numFormat}} ({{ refundData.refundInfo.discountCouponName }})</td>
                                    <th>위약금</th>
                                    <td colspan="3" v-if="!bookingPenalty">{{refundData.refundInfo.bookingPenaltyTotal | numFormat}}</td>
                                    <td colspan="3" v-else>0</td>
                                </tr>
                                <tr>
                                    <th rowspan="4">결제금액</th>
                                    <td rowspan="4">{{refundData.refundInfo.paymentTotal | numFormat}}</td>
                                    <th>Agent</th>
                                    <td v-if="refundData.refundInfo.paymentDetails != null">{{refundData.refundInfo.paymentDetails.payment | numFormat}}</td>
                                    <td v-if="refundData.refundInfo.paymentDetails == null">0</td>
                                    <th rowspan="4">환불금액</th>
                                    <td rowspan="4" v-if="!bookingPenalty">{{refundData.refundInfo.refundAmountTotal | numFormat}}</td>
                                    <td rowspan="4" v-else>{{refundData.refundInfo.refundableAmountTotal | numFormat}}</td>
                                    <th>Agent</th>
                                    <td v-if="refundData.refundInfo.paymentRefundDetails != null">
                                        <!-- 환불금액 -->
                                        <span v-if="bookingPenalty">{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.payment | numFormat}}</span>
                                        <span v-else>{{refundData.refundInfo.paymentRefundDetails.payment | numFormat}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Cash</th>
                                    <td v-if="refundData.refundInfo.paymentDetails != null">{{refundData.refundInfo.paymentDetails.cash | numFormat}}</td>
                                    <td v-if="refundData.refundInfo.paymentDetails == null">0</td>
                                    <th>Cash</th>
                                    <td v-if="refundData.refundInfo.paymentRefundDetails != null">
                                        <!-- 환불금액 -->
                                        <span v-if="bookingPenalty">{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.cash | numFormat}}</span>
                                        <span v-else>{{refundData.refundInfo.paymentRefundDetails.cash | numFormat}}</span>
                                    </td>
                                    <td v-if="refundData.refundInfo.paymentRefundDetails == null">0</td>
                                </tr>
                                <tr>
                                    <th>Reward</th>
                                    <td v-if="refundData.refundInfo.paymentDetails != null">{{refundData.refundInfo.paymentDetails.point | numFormat}}</td>
                                    <td v-if="refundData.refundInfo.paymentDetails == null">0</td>
                                    <th>Reward</th>
                                    <td v-if="refundData.refundInfo.paymentRefundDetails != null">
                                        <!-- 환불금액 -->
                                        <span v-if="bookingPenalty">{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.point | numFormat}}</span>
                                        <span v-else>{{refundData.refundInfo.paymentRefundDetails.point | numFormat}}</span>
                                    </td>
                                    <td v-if="refundData.refundInfo.paymentRefundDetails == null">0</td>
                                </tr>
                                <tr>
                                  <th>pointTicket</th>
                                  <td v-if="refundData.refundInfo.paymentDetails != null">{{refundData.refundInfo.paymentDetails.pointTicket | numFormat}}</td>
                                  <td v-if="refundData.refundInfo.paymentDetails == null">0</td>
                                  <th>pointTicket</th>
                                  <td v-if="refundData.refundInfo.paymentRefundDetails != null">
                                    <!-- 환불금액 -->
                                    <span v-if="bookingPenalty">{{refundData.refundInfo.paymentRefundDetailsWhenNoFee.pointTicket | numFormat}}</span>
                                    <span v-else>{{refundData.refundInfo.paymentRefundDetails.pointTicket | numFormat}}</span>
                                  </td>
                                  <td v-if="refundData.refundInfo.paymentRefundDetails == null">0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- {{bookingDetails.boookedOnPurchase}} -->
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
// import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {
    reqPost,
    reqGet
} from "../../lib/request";
import {
    orderapi,
    brandapi
} from "../../lib/api";
import {
    DateFormat,
    getBookStateStr,
    getBookTypeStr
} from "../../lib/utility";
import moment from 'moment';
let today = new Date();
// import VueTimepicker from "vue2-timepicker";
// import "vue2-timepicker/dist/VueTimepicker.css";
export default {
    components: {
        // Pagination,
        DetePicker,
        // VueTimepicker,
    },
    created() {
        this.bookingNo = this.$route.query.bookingNo;
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_BOOK,
            path2: menuval.LV1_HOME,
            title: ""
        });
        console.log(new Date().toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1").slice(0, 5))
    },
    watch: {
        consumeUseData(val) {
            this.getbookingRefundAmount(val);
        },
    },
    data() {
        return {
            hasRefundFeeOrPenalty: false,
            bookingType: '',
            newcancelBookTitle: '',
            requestReason: '',
            bookingPenalty: false,
            refundAccount: {
                bankCode: "", //환급 받을 은행 코드
                bankName: "", //환급 받을 은행명
                accountNumber: "", //환급받을 계좌번호
                accountHolder: "", //환급받을 계좌 소유자 이름
            },
            refundData: {
                "refundable": true,
                "bookingPenaltyRate": "",
                "preferredRefundMethod": "PaymentCancel",
                "availableRefundMethods": ["PaymentCancel"],
                "needsRefundAccountInfo": false,
                "refundInfo": {
                    "orderNo": "",
                    "listPriceTotal": "",
                    "salePriceTotal": "",
                    "discountAmountTotal": "",
                    "paymentTotal": "",
                    "usedAmountTotal": "",
                    "refundableAmountTotal": "",
                    "refundAmountTotal": "",
                    "refundFeeTotal": "",
                    "bookingPenaltyTotal": '',
                    "hasRefundFeeOrPenalty": false,
                    "paymentDetails": {
                        "cash": "",
                        "point": "",
                        "payment": ""
                    },
                    "paymentRefundDetails": {
                        "cash": "",
                        "point": "",
                        "payment": ""
                    },
                    "currencyCode": "KRW",
                    "itemRefundDetails": [{
                        "itemNo": "",
                        "listPrice": "",
                        "salePrice": "",
                        "discountAmount": "",
                        "paymentAmount": "",
                        "usedAmount": "",
                        "refundableAmount": "",
                        "refundFee": "",
                        "bookingPenalty": null,
                        "refundAmount": "",
                        "cancellable": true,
                        "refundable": true
                    }],
                    "offerNosToRestore": [],
                    "orderCancel": true
                }
            },
            refundDatas: {
                "refundInfo": {
                    "orderNo": "",
                    "listPriceTotal": "",
                    "salePriceTotal": "",
                    "discountAmountTotal": "",
                    "paymentTotal": "",
                    "usedAmountTotal": "",
                    "refundableAmountTotal": "",
                    "refundAmountTotal": "",
                    "refundFeeTotal": "",
                    "bookingPenaltyTotal": '',
                    "hasRefundFeeOrPenalty": false,
                    "paymentDetails": {
                        "cash": "",
                        "point": "",
                        "payment": ""
                    },
                    "paymentRefundDetails": {
                        "cash": "",
                        "point": "",
                        "payment": ""
                    },
                    "currencyCode": "KRW",
                    "itemRefundDetails": [{
                        "itemNo": "",
                        "listPrice": "",
                        "salePrice": "",
                        "discountAmount": "",
                        "paymentAmount": "",
                        "usedAmount": "",
                        "refundableAmount": "",
                        "refundFee": "",
                        "bookingPenalty": null,
                        "refundAmount": "",
                        "cancellable": true,
                        "refundable": true
                    }],
                    "offerNosToRestore": [],
                    "orderCancel": true
                }
            },
            cancelmethod: "Payment",
            avaArray: [],
            consumeUseData: moment().format("YYYY-MM-DD"),
            consumeUseTime: new Date().toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1").slice(0, 5),
            btnType: "cancel",
            newcancelBook: false,
            resenddlg: false,
            useSms: false,
            bookingNo: "",
            editinfdlg: false, //예약자 정보 수정
            addcoupondlg: false, //쿠폰 추가
            bookingDetails: {
                bookingNo: "", //예약번호
                bookingStatus: "", //[BookingRequested, BookingRejected, Booked, CancelRequested, Cancelled, Visited] 예약상태
                bookedOnPurchase: false,
                noShow: false, //노쇼 여부
                visitorName: "", //방문자 이름
                visitorPhone: "", //방문자 전화번호
                visitorEmail: "", //방문자 이메일
                visitorRequests: "", //방문자 요청사항
                brandCode: "", //브랜드 코드
                brandName: "", //브랜드명
                storeCode: "", //매장 코드
                storeName: "", //매장명
                siteName: "", //예약한 사이트명. 테이블에조이에서 예약한 경우 tablenjoy
                reservationDate: "", //yyyy-MM-ddTHH:mm:ss 예약일 (방문예정일)
                bookingRequestDate: "", //UTC yyyy-MM-ddTHH:mm:ss   예약 신청일
                bookingCancelledDate: "", //UTC yyyy-MM-ddTHH:mm:ss   예약취소일
                adultHeadcount: 0, //성인 예약 인원 수
                childHeadcount: 0, //어린이 예약 인원 수
                babyHeadcount: 0, //유아 예약 인원 수
                memo: "", //메모
                freeBooking: false,
                boookedOnPurchase: false,
                orderItems: [],
                /**
                 * itemNo             String  아이템 번호
                 * itemStatus         String  [Available, Invalidated, CancelRequested, Cancelled, BookingConnected, Locked, Consumed] 아이템 상태
                 *            Available: 사용가능, CancelRequested: 취소요청됨, Cancelled: 취소됨, BookingConnected: 예약에 연결됨, Locked: 사용불가처리됨, Consumed: 사용완료
                 * goodsType          String  [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]   상품 타입
                 * goodsId            Number  상품 ID
                 * goodsName          String  상품명
                 * optionId           Number  옵션 ID
                 * optionName         String  옵션명
                 * */
            },
            visitorName: "",
            visitorPhone: "",
            visitorEmail: "",
            visitorRequests: "",
            memo: "",
            itemNo: "",
            newIteminfs: [],
            banklist: [],
            /**
             * itemNo                 String    예약권 쿠폰 번호
             * goodsType              String    [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon] 상품 타입
             * goodsId                Number    상품 ID
             * goodsName              String    상품명
             * optionId               Number    옵션 ID
             * optionName             String    옵션명
             * itemStatus             String    [Available, Invalidated, CancelRequested, Cancelled, BookingConnected, Locked, Consumed]  쿠폰 상태
             *                      Available: 사용가능, CancelRequested: 취소요청됨, Cancelled: 취소됨, BookingConnected: 예약에 연결됨, Locked: 사용불가처리됨, Consumed: 사용완료
             */
          type :null,
          reasonType:null,
          typeList:[
            {
              label: "귀책자",
              value: null
            },
            {
              label: "매장",
              value: "PARTNER"
            },{
              label: "구매자",
              value: "PURCHASER"
            },{
              label: "기타",
              value: "ETC"
            },
          ],
          reasonTypeList:[
            {
              label: "취소사유",
              value: null
            },
            {
              label: "구매자 요청",
              value: "PURCHASER_REQUEST"
            },
            {
              label: "재예약 진행",
              value: "RE_BOOKING"
            },
            {
              label: "건강상의 이유",
              value: "HEALTH_REASON"
            },{
              label: "타사 최저가 이용",
              value: "THIRD_PARTY_LOWEST_PRICE"
            },
            {
              label: "단순 변심",
              value: "SIMPLE_REMORSE"
            },
            {
              label: "장소 운휴",
              value: "STORE_SUSPENSION"
            },{
              label: "예약 마감",
              value: "BOOKING_CLOSED"
            },
            {
              label: "모객 부족",
              value: "SHORTAGE_OF_PURCHASERS"
            },
            {
              label: "천재지변",
              value: "NATURAL_DISASTER"
            },
            {
              label: "감염병 안전수칙 준수",
              value: "INFECTIOUS_DISEASE_SAFETY_RULES"
            },
          ],
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.refreshPage();
        this.getBankList();
    },
    methods: {
        getbookingRefundAmount(val) {
            let useDate = DateFormat(val + " " + this.consumeUseTime + ":00", "YYYY-MM-DDTHH:mm:00");
            reqPost(
                orderapi.base,
                orderapi.bookingRefundAmount,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    cancellationDate: useDate,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refundData = result.data;
                            console.log(this.refundData)
                            if (this.refundData.refundInfo === null) {
                                this.refundData.refundInfo = this.refundDatas.refundInfo;
                            }
                            this.avaArray = [];
                            let refundDataArr = ['PaymentCancel', 'CreateRefundCs', 'RestoreCoupon'];
                            this.refundData.availableRefundMethods.forEach(ava => {
                                refundDataArr.forEach((el, idx) => {
                                    if (el === ava) {
                                        this.avaArray.push(el)
                                    }
                                });
                            });

                            if (this.avaArray[0] === "RestoreCoupon") {
                                this.cancelmethod = 'RestoreCoupon';
                            } else if (this.avaArray[0] === 'CreateRefundCs') {
                                this.cancelmethod = 'RefundCs';
                            } else {
                                this.cancelmethod = 'Payment';
                            }

                            console.log(this.avaArray);
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        onChangeBank() {
            var tidx = this.banklist.findIndex(bitem => bitem.bankCode == this.refundAccount.bankCode);
            if (tidx > -1) {
                this.refundAccount.bankName = this.banklist[tidx].bankName;
            }
        },
        approveBookReq() {
            let url = '';
            let parm = {}
            // if(this.bookingPenalty === true){
            //     this.bookingPenalty = false;
            // }else{
            //     this.bookingPenalty = true;
            // }
            if (this.btnType === 'cancel') {
                if(this.bookingDetails.b2bChannelCode=='KAKAOB'&&(!this.type || !this.reasonType)){
                  alert("취소 사유는 필수 선택 입니다")
                  return
                }
                let useDate = DateFormat(this.consumeUseData + " " + this.consumeUseTime + ":00", "YYYY-MM-DDTHH:mm:00");
                if (this.cancelmethod === 'Payment') {
                    url = orderapi.bookingByPaymentCancel;
                    parm = {
                        bookingNo: this.bookingNo,
                        noPenalty: this.bookingPenalty,
                        memo: this.requestReason,
                        type: this.type,
                        reasonType: this.reasonType,
                        cancellationDate: useDate
                    }
                } else if (this.cancelmethod === 'RefundCs') {
                    url = orderapi.bookingByCreateRefundCs;
                    parm = {
                        bookingNo: this.bookingNo,
                        noPenalty: this.bookingPenalty,
                      type: this.type,
                      reasonType: this.reasonType,
                        memo: this.requestReason,
                        cancellationDate: useDate,
                        refundAccount: this.refundAccount
                    }
                } else if (this.cancelmethod === 'RestoreCoupon') {
                    url = orderapi.bookingByRestoreCoupon;
                    parm = {
                        bookingNo: this.bookingNo,
                        noPenalty: this.bookingPenalty,
                        type: this.type,
                        reasonType: this.reasonType,
                        memo: this.requestReason,
                        cancellationDate: useDate
                    }
                }
            } else {
                if(this.bookingDetails.b2bChannelCode=='KAKAOB'&&(!this.type || !this.reasonType)){
                  alert("취소 사유는 필수 선택 입니다")
                  return
                }
                if (this.cancelmethod === 'Payment') {
                    url = orderapi.noShowByPaymentCancel;
                    parm = {
                        bookingNo: this.bookingNo,
                        noPenalty: this.bookingPenalty,
                        type: this.type,
                        reasonType: this.reasonType,
                        memo: this.requestReason,
                    }
                } else if (this.cancelmethod === 'RefundCs') {
                    url = orderapi.noShowByCreateRefundCs;
                    parm = {
                        bookingNo: this.bookingNo,
                        noPenalty: this.bookingPenalty,
                        type: this.type,
                        reasonType: this.reasonType,
                        memo: this.requestReason,
                        refundAccount: this.refundAccount
                    }
                } else if (this.cancelmethod === 'RestoreCoupon') {
                    url = orderapi.noShowByRestoreCoupon;
                    parm = {
                        bookingNo: this.bookingNo,
                        noPenalty: this.bookingPenalty,
                        type: this.type,
                        reasonType: this.reasonType,
                        memo: this.requestReason,
                    }
                }
            }
            reqPost(
                orderapi.base,
                url,
                this.authToken,
                parm,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.newcancelBook = false;
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getBankList() {
            reqGet(
                brandapi.base,
                brandapi.banklist,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.banklist = result.data.banks;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        closeNewBookdlg() {
            this.newcancelBook = false;
        },
        bookTypeVal(val) {
            return getBookTypeStr(val);
        },
        closeResenddlg() {
            this.resenddlg = false;
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm");
        },
        refreshPage() {
            reqPost(
                orderapi.base,
                orderapi.admbookingDetails,
                this.authToken, {
                    bookingNo: this.bookingNo,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            console.log(result.data.bookingDetails)
                            this.bookingDetails = result.data.bookingDetails;
                            this.bookingType = this.bookingDetails.bookingType
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        closeEditInf() {
            this.editinfdlg = false;
        },
        showEditInf() {
            this.visitorName = this.bookingDetails.visitorName; //방문자 이름
            this.visitorPhone = this.bookingDetails.visitorPhone; //방문자 전화번호
            this.visitorEmail = this.bookingDetails.visitorEmail; //방문자 이메일
            this.visitorRequests = this.bookingDetails.visitorRequests; //방문자 요청사항
            this.editinfdlg = true;
        },
        closeAddcoupon() {
            this.addcoupondlg = false;
        },
        showAddcoupon() {
            this.itemNo = "";
            this.newIteminfs = [];
            this.addcoupondlg = true;
        },
        getHeadcount() {
            var retval = "";
            if (this.bookingDetails.adultHeadcount > 0) {
                retval = "성인 : " + this.bookingDetails.adultHeadcount + "명";
            }
            if (this.bookingDetails.childHeadcount > 0) {
                if (retval != "") {
                    retval = retval + ", ";
                }
                retval = retval + "어린이 : " + this.bookingDetails.childHeadcount + "명";
            }
            if (this.bookingDetails.babyHeadcount > 0) {
                if (retval != "") {
                    retval = retval + ", ";
                }
                retval = retval + "유아 : " + this.bookingDetails.babyHeadcount + "명";
            }
            return retval;
        },
        getBookState(str) {
            return getBookStateStr(str);
        },
        resendClick() {
            reqPost(
                orderapi.base,
                orderapi.resendBookingVoucher,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    useSms: this.useSms,
                },
                (result) => {
                    if (result != null) {
                        this.resenddlg = false;
                        if (result.code != rescode.REQ_SUCCESS) {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        dochangeVisitor() {
            reqPost(
                orderapi.base,
                orderapi.changeVisitor,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    visitorName: this.visitorName,
                    visitorPhone: this.visitorPhone,
                    visitorEmail: this.visitorEmail,
                    visitorRequests: this.visitorRequests,
                    memo: null
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.visitorName = "";
                            this.visitorPhone = "";
                            this.visitorEmail = "";
                            this.visitorRequests = "";
                            this.closeEditInf();
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doApproveBookingRequest() {
            reqPost(
                orderapi.base,
                orderapi.approveBookingRequest,
                this.authToken, {
                    bookingNos: [this.bookingNo],
                    memo: "예약 승인함."
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doRejectBookingRequest() {
            reqPost(
                orderapi.base,
                orderapi.rejectBookingRequest,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    memo: "예약 승인함."
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doResendBookingVoucher() {
            this.useSms = false;
            this.resenddlg = true;
        },
        doRemoveBookingItem(bookingItemNo) {
            reqPost(
                orderapi.base,
                orderapi.removeBookingItem,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    bookingItemNo: bookingItemNo
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        goDetailPage(orderNo) {
            this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
        },
        doSaveMemo() {
            reqPost(
                orderapi.base,
                orderapi.saveMemo,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    memo: this.memo
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.memo = "";
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doQueryAddableBookingItem() {
            reqPost(
                orderapi.base,
                orderapi.queryAddableBookingItem,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    itemNo: this.itemNo
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            var neItemInf = result.data;
                            if (neItemInf.itemStatus == "Available") {
                                this.newIteminfs.push(neItemInf);
                            } else {
                                alert("사용가능하지 않은 쿠폰입니다.");
                            }
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doRemoveNewItem(itemNo) {
            this.newIteminfs = this.newIteminfs.filter((nitem) => {
                return nitem.itemNo != itemNo;
            });
        },
        doAddBookingItems() {
            var bookingItemNos = this.newIteminfs.map((nitem) => {
                return nitem.itemNo
            });
            reqPost(
                orderapi.base,
                orderapi.addBookingItems,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    bookingItemNos: bookingItemNos
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.closeAddcoupon();
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        goBack() {
            this.$router.go(-1);
        },
        doCancelBooking() {
            this.consumeUseData = moment().format("YYYY-MM-DD");
            this.newcancelBook = true;
            this.newcancelBookTitle = '예약취소';
            this.bookingPenalty = false;
            this.btnType = 'cancel';
            this.refundData = {
                "refundable": true,
                "bookingPenaltyRate": "",
                "preferredRefundMethod": "PaymentCancel",
                "availableRefundMethods": ["PaymentCancel"],
                "needsRefundAccountInfo": false,
                "refundInfo": {
                    "orderNo": "",
                    "listPriceTotal": "",
                    "salePriceTotal": "",
                    "discountAmountTotal": "",
                    "paymentTotal": "",
                    "usedAmountTotal": "",
                    "refundableAmountTotal": "",
                    "refundAmountTotal": "",
                    "refundFeeTotal": "",
                    "bookingPenaltyTotal": '',
                    "hasRefundFeeOrPenalty": false,
                    "paymentDetails": {
                        "cash": "",
                        "point": "",
                        "payment": ""
                    },
                    "paymentRefundDetails": {
                        "cash": "",
                        "point": "",
                        "payment": ""
                    },
                    "currencyCode": "KRW",
                    "itemRefundDetails": [{
                        "itemNo": "",
                        "listPrice": "",
                        "salePrice": "",
                        "discountAmount": "",
                        "paymentAmount": "",
                        "usedAmount": "",
                        "refundableAmount": "",
                        "refundFee": "",
                        "bookingPenalty": null,
                        "refundAmount": "",
                        "cancellable": true,
                        "refundable": true
                    }],
                    "offerNosToRestore": [],
                    "orderCancel": true
                }
            }
            // var today = new Date();

            this.getbookingRefundAmount(this.consumeUseData);
        },
        doNoShowBooking() {
            this.newcancelBook = true;
            this.bookingPenalty = false;
            this.newcancelBookTitle = '노쇼처리';
            this.btnType = 'noshow';
            reqPost(
                orderapi.base,
                orderapi.noShowRefundAmount,
                this.authToken, {
                    bookingNo: this.bookingNo,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refundData = result.data;
                            console.log(this.refundData)
                            this.avaArray = [];
                            let refundDataArr = ['PaymentCancel', 'CreateRefundCs', 'RestoreCoupon'];
                            this.refundData.availableRefundMethods.forEach(ava => {
                                refundDataArr.forEach((el, idx) => {
                                    if (el === ava) {
                                        this.avaArray.push(el)
                                    }
                                });
                            });
                            if (this.avaArray[0] === "RestoreCoupon") {
                                this.cancelmethod = 'RestoreCoupon';
                            } else if (this.avaArray[0] === 'CreateRefundCs') {
                                this.cancelmethod = 'RefundCs';
                            } else {
                                this.cancelmethod = 'Payment';
                            }
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doVisitStore() {
            var tmporderItems = this.bookingDetails.orderItems.filter((oitem) => {
              return oitem.itemStatus !== 'Consumed'
            });
            var orderItems = tmporderItems.map((oitem) => {
                return oitem.itemNo
            });
            reqPost(
                orderapi.base,
                orderapi.visitStore,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    orderItems: orderItems,
                    memo: null,
                    b2bStoreBooking: this.bookingDetails.b2bChannelCode=='KAKAOB'? true : false,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.refreshPage();
                            this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doCancelVisitStore() {
            reqPost(
                orderapi.base,
                orderapi.storeVisitInfo,
                this.authToken, {
                    bookingNo: this.bookingNo,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.CancelVisitStore(result.data.transactionNo);
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        CancelVisitStore(transactionNo) {
            reqPost(
                orderapi.base,
                orderapi.cancelVisitStore,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    transactionNo: transactionNo,
                    memo: null
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.refreshPage();
                            this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        doCancelNoShow() {
            reqPost(
                orderapi.base,
                orderapi.storeVisitInfo,
                this.authToken, {
                    bookingNo: this.bookingNo,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.CancelNoShow(result.data.transactionNo);
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        CancelNoShow(transactionNo) {
            reqPost(
                orderapi.base,
                orderapi.cancelNoShow,
                this.authToken, {
                    bookingNo: this.bookingNo,
                    transactionNo: transactionNo,
                    memo: null
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.refreshPage();
                            this.goBack();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getItemStatuStr(item) {
            if (item == "Consumed") {
              return this.viewDate(this.bookingDetails.reservationDate) + ", " + this.bookingDetails.brandName + " " + this.bookingDetails.storeName;
            } else {
              return "미사용";
            }

            // if (this.bookingDetails.bookingStatus == "Visited") {
            //     return this.viewDate(this.bookingDetails.reservationDate) + ", " + this.bookingDetails.brandName + " " + this.bookingDetails.storeName;
            // } else {
            //     return "미사용";
            // }
            // if(val == "Available")
            // {
            //   return "사용가능";
            // }else if(val == "CancelRequested")
            // {
            //   return "취소요청됨";
            // }else if(val == "Cancelled")
            // {
            //   return "취소됨";
            // }else if(val == "BookingConnected")
            // {
            //   return "예약에 연결됨";
            // }else if(val == "Locked")
            // {
            //   return "사용불가처리됨";
            // }else if(val == "Consumed")
            // {
            //   return "사용완료";
            // }else{
            //   return "";
            // }
        },
        parseBookingRoot(val){
          let returnMsg = ''
          if(val.b2bChannelCode == 'KAKAOB'){
            returnMsg = '카카오'
          }else if (val.bookingType == 'ManualBooking'){
            returnMsg = '관리자'
          }else if (val.siteName == 'tablenjoy' || !val.siteName ){
            returnMsg = '테이블엔조이'
          }else if (val.siteName != 'tablenjoy'){
            returnMsg = '자체판매'
          }

          return returnMsg
        },
    },
};
</script>

<style scoped>
.modal.resendModal .modal-dialog {
    max-width: 460px;
}

th {
    background-color: #dee2e685;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.displayll {
    display: flex;
}

.margin-top-5{
  margin-top: 5px;
}

.mr-3{
  margin-right: 6px;
}

</style><style>
.vdpOuterWrap.vdpFloating {
    z-index: 9 !important;
}
</style>
