<template>
  <div class="box box-solid">
    <!----------------------------------  탭 ---------------------------------------------->
    <div class="resmTab">
      <ul>
        <li @click="tabClick(0)" :class="tab == 0 && 'on'">예약상품</li>
        <li @click="tabClick(1)" :class="tab == 1 && 'on'">시간설정</li>
        <li @click="tabClick(2)" :class="tab == 2 && 'on'">예약상품연결</li>
      </ul>
    </div>
    <!----------------------------------  탭 end ---------------------------------------------->

    <div class="box-body form-horizontal">
      <!----------------------------------  옵션설정 ---------------------------------------------->
      <div v-if="tab === 0">
        <OptionSettings @get-kakao-alert="getKakaoAlert" :kakao-id="kakaoId" :place-id="placeId"/>
      </div>
      <!----------------------------------  옵션설정 end ---------------------------------------------->

      <!----------------------------------  시간설정 ---------------------------------------------->
      <div v-if="tab === 1">
        <TimeSet :kakao-id="kakaoId"/>
      </div>
      <!----------------------------------  시간설정 end ---------------------------------------------->

      <!----------------------------------  옵션연결 ---------------------------------------------->
      <div v-if="tab === 2">
        <OptionConnection  :kakao-id="kakaoId" />
      </div>
      <!----------------------------------  옵션연결 end ---------------------------------------------->



    </div>
  </div>
</template>

<script>
import {
  getGoodsTypeStr,
  getAgeGroupStr,
  getPercent,
  getMobileCoupon,
  getStoreTypeStr
} from "../../../../lib/utility";
import TimeSet from './TimeSet.vue';
import OptionConnection from './OptionConnection';
import OptionSettings from './optionSettings';
import {
  brandapi
} from "../../../../lib/api";
import {
  reqGet,
  reqPost
} from "../../../../lib/request";
import {
  rescode,
  menuval
} from "../../../../lib/consts";
import {
  Drag,
  Drop
} from "vue-drag-drop";

export default {
  components: {
    TimeSet,
    OptionConnection,
    Drag,
    Drop,
    OptionSettings,
  },
  props:{
    kakaoId:Number,
    placeId:Number
  },
  data() {
    return {
      edtss: false,
      optionCode: '',
      tab: 0,
      menutRadio: false,
      optionMakeModal: false,
      optionModal: false,
      optionModalName: "",
      menuList: [],

      mOpName: "",
      mNprice: "",
      mPrice: 0,
      mPersonnel: "",
      clickMenuCode: "",
      ageGroup: 'Adult',
    }
  },
  watch: {
    menutRadio(val) {
      if (val) {
        if (this.optionModalName === '옵션 수정') {
          if (this.edtss === true) {

          } else {
            this.mPrice = 0;
          }
        }
        this.mPersonnel = 1;
      } else {
        if (this.optionModalName === '옵션 수정') {
          if (this.edtss === true) {

          } else {
            this.mPrice = 0;
          }
        }
        let mme = this.menuList.find((menu) => {
          return menu.chevron === true;
        })
        // console.log(mme)
        if (mme === undefined && this.menutRadio === false) {
          this.mPersonnel = "";
        } else {
          this.mPersonnel = this.mPersonnelCopy;
        }

      }
      this.edtss = false;
    }
  },
  filters: {
    opnameM(val) {
      if (val == 'DepositBooking') {
        return '예약금'
      } else {
        return '메뉴'
      }
    },
    getTypeTextReturn(type) {
      if (type == 'Adult') {
        return '성인';
      } else if (type == 'Child') {
        return '소인';
      } else {
        return '유아';
      }
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
  },
  methods: {
    tabClick(tabidx) {
      if (tabidx === 0) {
        this.tab = 0;
      } else if (tabidx === 1) {
        this.tab = 1;
      } else {
        this.tab = 2;
      }
    },
    getKakaoAlert(val){
      this.$emit('get-kakao-alert', val);
    }

  }
}
</script>

<style scoped>
.resmTab ul li {
  display: inline-block;
  list-style: none;
  padding: 10px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.resmTab > ul {
  padding: 0;
  border-bottom: 1.9px solid #c5c5c5;
}

.resmTab ul li.on {
  border: 1.9px solid #c5c5c5;
  border-bottom: 1.9px solid #fff;
}

.resmTab {
  padding: 10px;
}

th {
  background-color: #dee2e685;
}

td {
  vertical-align: middle !important;
}

.right-btn {
  text-align: right;
}

.chevron {
  display: none;
}

.chevron.on {
  display: block;
}

input.newTitle {
  display: inline-block;
}

.text-center {
  text-align: center !important;
}

.tbl_h200 {
  height: 222px;
  overflow-y: scroll;
}

.optionUpDownBtn {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
}
</style>
