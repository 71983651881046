<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <DatePicker v-model="salesFrom" :last="salesTo" />
                <h4 style="margin: 5px 10px">~</h4>
                <DatePicker v-model="salesTo" :first="salesFrom" style="margin-right: 10px" />
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onPrvMonth">
                    전월
                </button>
                <button type="button" class="btn btn-default" style="margin-right: 10px" @click="onNowMonth">
                    당월
                </button>
                <select class="form-control" style="width:auto;margin-right:10px" v-model="channelCode">
                    <option :value="null">전체</option>
                    <option v-for="(cdata, cidx) in channels" :key="cidx" :value="cdata.channelCode">{{cdata.channelName}}</option>
                </select>
                <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <div class="radio" style="margin: 0px">
            <!-- <label><input type="radio" value="order" v-model="sortbase" />거래기준</label>
            <label><input type="radio" value="cancel" v-model="sortbase" />취소기준</label> -->
            <button type="button" class="btn btn-default pull-right" @click="TotaldownExcel" :disabled="buttonDissabled">
                <i class="fa fa-download"></i>Excel
            </button>
        </div>
    </div>
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>점유율</th>
                                <th>합계</th>
                                <th v-for="(channel, cidx) in viewchannels" :key="cidx">
                                    {{channel === "selfSales" ? '자체판매':channel}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr>
                                <td colspan="2">총계</td>
                                <td v-if="sortbase == 'order'">{{salesTotal.salesAmount | numFormat}}</td>
                                <td v-else>{{salesTotal.cancelledAmount | numFormat}}</td>
                                <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getTotalVal(channel) | numFormat}}</th>
                            </tr> -->
                            <template >
                                <tr v-for="(brand, gidx) in brandsSales" v-bind:key="brand.brandName">
                                    <td class="linkcmp" @click="setviewData(brand)">{{brand.brandName}}</td>
                                    <td v-if="sortbase == 'order'">{{brand.salesPercentage | numFormat('0.00')}}%</td>
                                    <td v-else>{{brand.cancelPercentage | numFormat('0.00')}}%</td>
                                    <td v-if="sortbase == 'order'">{{brand.salesTotal.salesAmount | numFormat}}</td>
                                    <td v-else>{{brand.salesTotal.cancelledAmount | numFormat}}</td>
                                    <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getCHVal(brand, channel) | numFormat}}</th>
                                </tr>
                            </template>
                            <tr>
                                <td>총계</td>
                                <td>{{salesPercentage | numFormat('0.00')}}%</td>
                                <td v-if="sortbase == 'order'">{{salesTotal.salesAmount | numFormat}}</td>
                                <td v-else>{{salesTotal.cancelledAmount | numFormat}}</td>
                                <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getTotalVal(channel) | numFormat}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px" v-if="viewBrand != null">
      <div class="form-group" >
        <span>상세 내역</span>
        <button type="button" class="btn btn-default pull-right" @click="DetaildownExcel" :disabled="buttonDissabled">
          <i class="fa fa-download"></i>Excel
        </button>
      </div>
    </div>
    <div class="box box-solid" v-if="viewBrand != null">
      <div class="box-body form-horizontal">
        <div class="form-group">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                    <th>구분</th>
                    <th>점유율</th>
                    <th>합계</th>
                    <th v-for="(channel, cidx) in viewchannels" :key="cidx">
                        {{channel === "selfSales" ? '자체판매':channel}}
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dayinf, didx) in viewBrand.byDaily" :key="didx">
                  <td>{{ viewDate2(dayinf.date) }}</td>
                  <td v-if="sortbase == 'order'">{{dayinf.salesPercentage | numFormat('0.00') }}%</td>
                  <td v-if="sortbase == 'cancel'">{{dayinf.cancelPercentage | numFormat('0.00')}}%</td>
                  <td v-if="sortbase == 'order'">{{dayinf.salesTotal.salesAmount | numFormat}}</td>
                  <td v-if="sortbase == 'cancel'">{{dayinf.salesTotal.cancelledAmount | numFormat}}</td>
                  <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getCHVal2(dayinf,channel) | numFormat }} </th>
                </tr>
                <tr>
                    <td>총계</td>
                    <td>100.00%</td>
                    <td v-if="sortbase == 'order'">{{viewBrand.salesTotal.salesAmount | numFormat}}</td>
                    <td v-else>{{viewBrand.salesTotal.cancelledAmount | numFormat}}</td>
                    <th v-for="(channel, cidx) in viewchannels" :key="cidx">{{getCHVal(viewBrand,channel) | numFormat}}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import {
    reqGet,
    reqPost
} from "../../../lib/request";
import {
    b2bapi,
    orderapi
} from "../../../lib/api";
import {
    DateFormat,
    getGoodsTypeStr
} from "../../../lib/utility";
import DatePicker from "../../../components/DatePicker";
import moment from 'moment';
import XLSX from "xlsx";
export default {
    components: {
        DatePicker,
    },
    created() {
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_STAT,
            path2: menuval.LV2_STT_DPL,
            title: "거래현황 브랜드별 (기간)"
        });
    },
    data() {
        return {
            channels: [],
            /**
             * channelCode              String    채널코드
             * channelName              String    채널명
             */
            salesFrom: moment().format("YYYY-MM-01"),
            salesTo: moment().format("YYYY-MM-DD"),
            channelCode: null,
            sortbase: "order",

            brandsSales: [], //브랜드별 매출 집계

            salesTotal: { //총 매출 집계
                salesAmount: "", //총 매출액
                cancelledAmount: "", //총 취소금액
                netSalesAmount: "", //총 순매출액
            },
            salesTotalByChannels: [], //채널별 총 매출 집계
            /**
             * channelCode            String    채널코드
             * channelType            String    [Tablenjoy, B2bIframe, B2bChannel]    채널타입
             * sales                  Object      채널 총 매출합계
             * sales.salesAmount        String    채널 총 매출액
             * sales.cancelledAmount    String    채널 총 취소금액
             * sales.netSalesAmount     String    채널 총 순매출액
             */
            currencyCode: "", //[USD, KRW] 통화코드

            viewchannels: [],
            viewBrand: null,
            salesPercentage: "",
          buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.getChannelData();
    },
    methods: {
        goodsTypeVal(val) {
            return getGoodsTypeStr(val);
        },
        viewDate2(val) {
      return DateFormat(val, "YYYY-MM-DD", true);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        onPrvMonth() {
            this.salesFrom = moment().subtract(1, 'months').format('YYYY-MM-01');
            this.salesTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        },
        onNowMonth() {
            this.salesFrom = moment().format("YYYY-MM-01");
            this.salesTo = moment().format("YYYY-MM-DD");
        },
        getChannelData() {
            reqGet(
                b2bapi.base,
                b2bapi.channelList,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                            this.channels = result.data.channels;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refreshPage() {
            this.viewBrand = null;
            reqPost(
                orderapi.base,
                orderapi.totalizePurchasedSalesbyBrand,
                this.authToken, {
                    salesFrom: this.salesFrom,
                    salesTo: this.salesTo,
                    channelCode: this.channelCode
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.getViewChannels(result.data.salesTotalByChannels);
                            this.salesTotal = result.data.salesTotal;
                            this.brandsSales = result.data.brandsSales;
                            this.currencyCode = result.data.currencyCode;
                            this.salesPercentage = result.data.salesPercentage;
                        } else {
                            this.content = [];
                            alert(result.message);
                        }
                    }
                }
            );
        },
        getViewChannels(salesTotalByChannels) {
            this.viewchannels = [];
            for (var idx = 0; idx < salesTotalByChannels.length; idx++) {
                var f_idx = this.viewchannels.findIndex(vchnl => vchnl == salesTotalByChannels[idx].channelCode);
                if (f_idx == -1) {
                    this.viewchannels.push(salesTotalByChannels[idx].channelCode);
                }

            }
            this.salesTotalByChannels = salesTotalByChannels;
        },
        getCHVal(brand, channel) {
            for (var idx = 0; idx < brand.byChannels.length; idx++) {
                if (brand.byChannels[idx].channelCode == channel) {
                    if (this.sortbase == "order") {
                        return brand.byChannels[idx].sales.salesAmount;
                    } else {
                        return brand.byChannels[idx].sales.cancelledAmount;
                    }
                }
            }
            return "";
        },
        getCHVal2(byDaily, channel) {
            for(var idx = 0; idx< byDaily.optionSalesByChannels.length; idx++) {
                if(byDaily.optionSalesByChannels[idx].channelCode == channel) {
                    if(this.sortbase == "order") {
                        return byDaily.optionSalesByChannels[idx].sales.salesAmount;
                    } else {
                        return byDaily.optionSalesByChannels[idx].sales.cancelledAmount;
                    }
                }
            }

        },
        getTotalVal(channel) {
            for (var idx = 0; idx < this.salesTotalByChannels.length; idx++) {
                if (this.salesTotalByChannels[idx].channelCode == channel) {
                    if (this.sortbase == "order") {
                        return this.salesTotalByChannels[idx].sales.salesAmount;
                    } else {
                        return this.salesTotalByChannels[idx].sales.cancelledAmount;
                    }
                }
            }
            return "";
        },
        setviewData(brand)
        {
            this.viewBrand = brand;
        },
        totalconvert(option) {
            console.log(option)
            var retval = {};
            retval[0] = option.brandName;
            retval[1] = this.sortbase == 'order'?option.salesPercentage:option.cancelPercentage;
            retval[2] = this.sortbase == 'order'?option.salesTotal.salesAmount:option.salesTotal.cancelledAmount;
            for (var cidx = 0; cidx < this.viewchannels.length; cidx++) {
                retval[cidx + 3] = this.getCHVal(option, this.viewchannels[cidx]);
            }
            return retval;
        },
        TotaldownExcel() {
          this.buttonDissabled = true;
            var head = {};
            head[0] = "구분";
            head[1] = "점유율";
            head[2] = "합계";
            for (var cidx = 0; cidx < this.viewchannels.length; cidx++) {
                head[cidx + 3] = this.viewchannels[cidx] === "selfSales" ? '자체판매':this.viewchannels[cidx];
            }
            var total = {};
            total[0] = "총계";
            total[1] = this.salesPercentage;
            total[2] = this.salesTotal.salesAmount;
            for (cidx = 0; cidx < this.viewchannels.length; cidx++) {
                total[cidx + 3] = this.getTotalVal(this.viewchannels[cidx]);
            }
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    head,
                    total,
                ], {
                    skipHeader: true
                }
            );

            var exceldata = [];
            for (cidx = 0; cidx < this.brandsSales.length; cidx++) {
                var t_data = {
                    ...this.brandsSales[cidx],
                }
                exceldata.push(t_data);

            }

            XLSX.utils.sheet_add_json(excelheader, exceldata.map(this.totalconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "";
            if (this.sortbase == "order") {
                subtxt = "브랜드별(거래기준)";
            } else {
                subtxt = "브랜드별(취소기준)";
            }
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "거래현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        },
        detailconvert(option) {
            console.log(option)
            var retval = {};
            retval[0] = this.viewDate2(option.date);
            retval[1] = this.sortbase == 'order'?option.salesPercentage:option.cancelPercentage;
            retval[2] = this.sortbase == 'order'?option.salesTotal.salesAmount:option.salesTotal.cancelledAmount;
            for (var cidx = 0; cidx < this.viewchannels.length; cidx++) {
                retval[cidx + 2] = this.getCHVal2(option, this.viewchannels[cidx]);
            }
            return retval;
        },
        DetaildownExcel() {
          this.buttonDissabled = true;
            var head = {};
            head[0] = "구분";
            head[1] = "점유율";
            head[2] = "합계";
            for (var cidx = 0; cidx < this.viewchannels.length; cidx++) {
                head[cidx + 3] = this.viewchannels[cidx] === "selfSales" ? '자체판매':this.viewchannels[cidx];
            }
            var total = {};
            total[0] = "총계";
            total[1] = "100";
            total[2] = this.sortbase == "order" ? this.viewBrand.salesTotal.salesAmount : this.viewBrand.salesTotal.cancelledAmount;
            for (cidx = 0; cidx < this.viewchannels.length; cidx++) {
                total[cidx + 3] = this.getCHVal(this.viewBrand, this.viewchannels[cidx]);
            }
            var excelheader = XLSX.utils.json_to_sheet(
                [
                    head,
                    total,
                ], {
                    skipHeader: true
                }
            );
            var exceldata = [];
            for (cidx = 0; cidx < this.viewBrand.byDaily.length; cidx++) {
                var t_data = {
                    ...this.viewBrand.byDaily[cidx],
                }
                exceldata.push(t_data);

            }

            XLSX.utils.sheet_add_json(excelheader, exceldata.map(this.detailconvert), {
                skipHeader: true,
                origin: "A3",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel
            var subtxt = "";
            if (this.sortbase == "order") {
                subtxt = "브랜드별(상세 거래기준)";
            } else {
                subtxt = "브랜드별(상세 취소기준)";
            }
            XLSX.utils.book_append_sheet(wb, excelheader, subtxt);
            // export Excel file
            XLSX.writeFile(wb, "거래현황조회-" + subtxt + ".xlsx");
          this.buttonDissabled = false;
        }
    },
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

th {
    text-align: center;
}

td {
    text-align: center;
}
</style>
