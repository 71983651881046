<template>
  <section class="content">
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="openExcelDownload">
        정산내역
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">파트너 아이디</th>
            <th class="text-center">파트너명</th>
            <th class="text-center">채널 이름</th>
            <th class="text-center">채널 아이디</th>
            <th class="text-center">생성일시</th>
            <th class="text-center">수정일시</th>
            <th class="text-center">파트너 상태</th>
            <th class="text-center">운영 상태</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(brand, index) in content" v-bind:key="brand.brandCode">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="linkcmp text-center" @click="goToPartner(brand.id)">{{brand.id}}</td>
            <td class="text-center">{{brand.name}}</td>
            <td class="text-center">{{brand.channelName}}</td>
            <td class="text-center">{{brand.channelId}}</td>
            <td class="text-center">{{viewDate(brand.createdAt)}}</td>
            <td class="text-center">{{viewDate(brand.updateAt)}}</td>
            <td class="text-center">{{convertStatus(brand.status)}}</td>
            <td class="text-center">{{convertOperationStatus(brand.operationStatus)}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
      </div>
      <div class="modal" :style="showmodal && {'display': 'block'}">
        <div class="modal-dialog " style="width: 20%">
          <div class="modal-content">
            <div class="modal-header border-bottom-0" style="display: flex;">
              <label>정산내역 조회 (정산 기준일)</label>
              <i class="fa fa-fw fa-close" style="margin-left: auto; cursor: pointer" @click="closeExcelDownload"></i>
            </div>
            <div class="modal-body " style="display: flex">
                <DetePicker v-model="dateFrom" :last="dateTo" />
                <h4 style="margin:5px 10px;">~</h4>
                <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px" />
            </div>
            <div class="modal-footer">
              <div class="text-center">
                <button type="button" class="btn btn-default" @click="getExcelData">Excel 다운로드</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  rescode,
  menuval
} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {
  reqGet, reqPost
} from "../../../lib/request";
import {
  b2bapi,
  brandapi, orderapi
} from "../../../lib/api";
import {
  DateFormat
} from "../../../lib/utility";
import XLSX from "xlsx";
import DetePicker from "@/components/DatePicker.vue";
import moment from "moment";
export default {
  components: {
    DetePicker,
    Pagination,
  },
  created() {
    if (localStorage.initval == "1") {
      if (localStorage.BrdMng_keywordType) {
        this.keywordType = localStorage.BrdMng_keywordType;
      }
      if (localStorage.BrdMng_keyword) {
        this.keyword = localStorage.BrdMng_keyword;
      }
      if (localStorage.BrdMng_Number) {
        this.pageNumber = parseInt(localStorage.BrdMng_Number);
      }
      if (localStorage.BrdMng_Size) {
        this.pageSize = parseInt(localStorage.BrdMng_Size);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BRD,
      path2: menuval.LV2_BRD_KKOR,
      title: "파트너 관리",
    });
  },
  watch: {
    keywordType(newVal) {
      localStorage.BrdMng_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.BrdMng_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.BrdMng_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.BrdMng_Size = newsize;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      exceldata: {
        animals: [{
          name: "cat",
          category: "animal"
        },
          {
            name: "dog",
            category: "animal"
          },
          {
            name: "pig",
            category: "animal"
          },
        ],
        pokemons: [{
          name: "pikachu",
          category: "pokemon"
        },
          {
            name: "Arbok",
            category: "pokemon"
          },
          {
            name: "Eevee",
            category: "pokemon"
          },
        ],
      },
      buttonDissabled : false,
      dateFrom: moment().subtract(1,'months').format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      showmodal: false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if (localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.BrdMng_keywordType = this.keywordType;
      localStorage.BrdMng_keyword = this.keyword;
      localStorage.BrdMng_Number = this.pageNumber;
      localStorage.BrdMng_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    viewDateYmd(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if (!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqGet(
        brandapi.base,
        brandapi.kakaoPartnerMngPage +
        "?pageNumber=" +
        pageNumber +
        "&pageSize=" +
        this.pageSize,
        this.authToken,
        (data) => {
          if (data != null) {
            if (data.code == rescode.REQ_SUCCESS) {
              this.content = data.data.content;
              this.totalPages = data.data.totalPages; //전체 페이지 수
              this.totalElements = data.data.totalElements; //전체 아이템 수
              this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              alert(data.message)
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
            }
          } else {
            alert(data.message)
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goNewBrand() {
      this.$router.push(this.routes.regbrand);
    },

    goToPartner(partnerId) {
      this.$router.push(this.routes.kakaoPartnerInfoDetail + "?partnerId=" + partnerId);
    },
    gostoreinspec(bcode) {
      localStorage.ReqIns_keywordType = "BrandCode";
      localStorage.ReqIns_keyword = bcode;
      this.$router.push(this.routes.storeinspec);
    },
    convertStatus(val){
      let convertWord = '';
      switch (val){
        case 'PRE_OPEN': convertWord = '미노출'; break;
        case 'NORMAL': convertWord = '노출'; break;
        case 'RESTRICT': convertWord = '노출제한'; break;
        default: convertWord = ''
      }
      return convertWord
    },
    convertOperationStatus(val){
      let convertWord = '';
      switch (val){
        case 'PRE_OPEN': convertWord = '미노출'; break;
        case 'NORMAL': convertWord = '노출'; break;
        case 'RESTRICT': convertWord = '운영제한'; break;
        case 'DELETE': convertWord = '삭제'; break;
        default: convertWord = ''
      }
      return convertWord
    },
    openExcelDownload(){
      this.showmodal = true
    },
    closeExcelDownload(){
      this.showmodal = false
    },
    getExcelData() {
      this.excelButtonDisabled = true;

      if (this.dateFrom === "" || this.dateTo === "") {
        alert("조회기간은 필수입니다.");
        return;
      }

      const dateFrom = moment(this.dateFrom).format("YYYYMMDD")
      const dateTo = moment(this.dateTo).format("YYYYMMDD")
      let tmp =  moment(dateTo).diff(dateFrom,"days")

      if (tmp > 90) {
        alert("조회기간은 최대 3개월입니다.");
        return
      }

      reqGet(
        b2bapi.base,
        b2bapi.kakaoReservationExcelList+ "?startDate="+dateFrom+"&endDate="+dateTo,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              console.log(this.exceldata)
              this.downExcel();
            } else {
              this.exceldata = [];
              this.excelButtonDisabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    dataconvert(docu, idx) {

      return {
        A: docu.partnerId,
        B: docu.partnerName,
        C: docu.storeId,
        D: docu.storeName,
        E: docu.orderId,
        F: docu.orderItemId,
        G: docu.settlementId,
        H: docu.paymentItemTypeName,
        I: docu.itemName,
        J: this.viewDateYmd(docu.payoutBaseDate),
        K: this.viewDateYmd(docu.payoutDueDate),
        L: this.viewDateYmd(docu.actualPayoutDate),
        M: docu.payoutTypeName,
        N: docu.totalSalesAmount,
        O: docu.couponAmount,
        P: docu.paymentMethod,
        Q: docu.paymentFeeRate,
        R: docu.paymentAmount,
        S: docu.couponSharingAmount,
        T: docu.paymentFeeAmount,
        U: docu.pointAmount,
        V: docu.pointFeeAmount,
        W: docu.payoutTargetAmount,
        X: docu.totalFeeAmount,
        Y: docu.actualPayoutAmount,
      };
    },
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "파트너ID",
            B: "파트너명",
            C: "장소ID",
            D: "장소명",
            E: "예약번호",
            F: "예약상세번호",
            G: "정산ID",
            H: "결제구분",
            I: "상품명",
            J: "정산기준일",
            K: "정산예정일",
            L: "정산완료일",
            M: "정산구분",
            N: "판매가",
            O: "할인금액(쿠폰)",
            P: "주결제수단",
            Q: "수수료율",
            R: "주결제금액",
            S: "쿠폰할인(카카오부담)",
            T: "주결제수수료",
            U: "포인트결제금액",
            V: "포인트결제수수료",
            W: "정산대상금액",
            X: "총수수료",
            Y: "정산예정금액",
          },
        ],
        { skipHeader: true }
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "카카오톡예약하기정산내역");
      // export Excel file
      XLSX.writeFile(wb, "카카오톡예약하기정산내역.xlsx");
      this.excelButtonDisabled = false;
    },
  },
};
</script>
