<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <DatePicker v-model="fromDate" :last="toDate"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DatePicker v-model="toDate" :first="fromDate" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="OrderNo">주문번호</option>
            <option value="B2bOrderNo">B2B 주문번호</option>
            <option value="GoodsNameContaining">상품명</option>
            <option value="Email">이메일</option>
            <option value="RequesterName">이름(사업자명)</option>
            <option value="RequestNo">신청번호</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button class="btn btn-default" type="button" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData" :disabled="excelButtonDisabled">
        <i class="fa fa-download"></i>Excel
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>
                <input type="checkbox" class="allch" @change="allckech($event)"/>
              </th>
              <th class="text-center">번호</th>
              <th class="text-center">주문번호</th>
              <th class="text-center">B2B 주문번호</th>
              <th class="text-center">상품명</th>
              <th class="text-center">서류종류</th>
              <th class="text-center">이름 (사업자명)</th>
              <th class="text-center">이메일주소</th>
              <th class="text-center">신청번호</th>
              <th class="text-center">서류용도</th>
              <th class="text-center">처리상태</th>
              <th class="text-center">첨부파일</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(docu, index) in content" :key="docu.requestNo">
              <th>
                <input type="checkbox" class="chitem" :value="docu.requestNo"/>
              </th>
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{docu.orderNo}}</td>
              <td class="text-center">{{docu.b2bOrderNo}}</td>
              <td class="text-center">{{docu.goodsName}}</td>
              <td class="linkcmp text-center" @click="showInfoView(docu.requestNo)">{{viewDocType(docu.documentType)}}</td>
              <td class="text-center">{{docu.requesterName}}</td>
              <td class="text-center">{{docu.requesterEmail}}</td>
              <td class="text-center">{{phoneview(docu.receiptPhone)}}</td>
              <td class="text-center">{{viewRecPur(docu.receiptPurpose)}}</td>
              <td class="text-center">
                <button v-if="docu.result == 'Requested'" type="button" class="btn btn-default btn-xs" style="margin-right:5px" @click="doAccept(docu.requestNo)">발급완료</button>
                <button v-if="docu.result == 'Requested'" type="button" class="btn btn-default btn-xs" @click="showEdit(docu.requestNo)">발급불가</button>
                <span v-if="docu.result == 'Rejected'">발급불가</span>
                <span v-if="docu.result == 'Accepted'">발급완료</span>
              </td>
              <td class="text-center">
                <button v-if="docu.bizRegCertificateUrl != null && docu.bizRegCertificateUrl != ''" type="button" class="btn btn-default btn-xs" @click="doDownLoad(docu.bizRegCertificateUrl)" :disabled="downButtonDisabled">다운로드</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" v-if="infodlg" :style="{'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">신청내역</div>
          <div class="modal-body form-horizontal" style="padding:0px;">
            <div class="form-group" style="margin:0px;">
              <div class="table-responsive infodlg p-0">
                <table class="table table-hover text-nowrap">
                  <colgroup>
                    <col width="100px">
                    <col width="50%">
                    <col width="100px">
                    <col width="50%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>주문번호</th>
                      <td>{{viewinf.orderNo}}</td>
                      <th>처리자</th>
                      <td>{{viewinf.completedBy}}</td>
                    </tr>
                    <tr>
                      <th>신청일</th>
                      <td>{{viewDate(viewinf.requestedDate)}}</td>
                      <th>처리일</th>
                      <td>{{viewDate(viewinf.completedDate)}}</td>
                    </tr>
                    <tr>
                      <th>발급불가사유</th>
                      <td colspan="3">{{viewinf.rejectReason}}</td>
                    </tr>
                    <tr>
                      <th>신청 쿠폰 리스트</th>
                      <td colspan="3">
                        <div class="table-responsive editmodal p-0">
                          <table class="table table-hover text-nowrap">
                            <thead>
                              <tr>
                                <th>쿠폰번호</th>
                                <th>상품명</th>
                                <th>옵션명</th>
                                <th>쿠폰상태</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="oitem in viewinf.orderItems" :key="oitem.itemNo">
                                <td>{{oitem.itemNo}}</td>
                                <td>{{oitem.goodsName}}</td>
                                <td>{{oitem.optionName}}</td>
                                <td>{{getItemStatuStr(oitem.itemStatus)}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeInfoView">닫기</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :style="editdlg && {'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">발급불가 사유안내</div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="margin:0px;">
              <textarea class="form-control" rows="5" v-model="reason"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" style="margin-right:10px" @click="closeEdit">닫기</button>
            <button type="button" class="btn btn-default" @click="doReject">확인</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import { getfilename, reqPost } from "../../lib/request";
import { orderapi } from "../../lib/api";
import { DateFormat, formatPhone } from "../../lib/utility";
import DatePicker from "../../components/DatePicker";
import moment from 'moment';
import axios from 'axios'
import $ from 'jquery';
import XLSX from "xlsx";
export default {
  components: {
    Pagination,
    DatePicker,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.ReqDocu_keywordType) {
        this.keywordType = localStorage.ReqDocu_keywordType;
      }
      if (localStorage.ReqDocu_keyword) {
        this.keyword = localStorage.ReqDocu_keyword;
      }
      if (localStorage.ReqDocu_Number) {
        this.pageNumber = parseInt(localStorage.ReqDocu_Number);
      }
      if (localStorage.ReqDocu_Size) {
        this.pageSize = parseInt(localStorage.ReqDocu_Size);
      }
      if (localStorage.ReqDocu_fromDate) {
        this.fromDate = localStorage.ReqDocu_fromDate;
      }
      if (localStorage.ReqDocu_toDate) {
        this.toDate = localStorage.ReqDocu_toDate;
      }
    }
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_BOD, path2:menuval.LV1_HOME, title:"증빙서류신청"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.ReqDocu_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.ReqDocu_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.ReqDocu_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.ReqDocu_Size = newsize;
    },
    fromDate(nfrom)
    {
      localStorage.ReqDocu_fromDate = nfrom;
    },
    toDate(nto)
    {
      localStorage.ReqDocu_toDate = nto;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      fromDate:moment().format("YYYY-01-01"),
      toDate:moment().format("YYYY-MM-DD"),
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * requestNo              Number    요청 번호
       * orderNo                String    주문 번호
       * b2bOrderNo             String    B2B 주문 번호
       * goodsName              String    상품명
       * documentType           String    [CashReceipt, TaxBill] CashReceipt: 현금영수증, TaxBill: 세금계산서
       * receiptPurpose         String    [IncomeDeduction, ProofOfExpenditure] IncomeDeduction: 소득공제, ProofOfExpenditure: 사업자지출증빙
       * requesterName          String    요청자 이름 또는 사업자명
       * requesterEmail         String    요청자 이메일
       * requesterPhone         String    요청자 전화번호
       * receiptPhone           String    현금영수증 전화번호
       * result                 String    [Requested, Rejected, Accepted] 처리결과 Requested: 신청됨, Rejected: 거절됨, Accepted: 승인됨
       * bizRegCertificateUrl   String  사업자등록증 이미지 URL
       * requestedDate          String  UTC yyyy-MM-ddTHH:mm:ss 요청일자
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      infodlg: false,
      editdlg: false,

      viewinf:{
        requestNo:0,//요청 번호
        orderNo:"",//주문 번호
        b2bOrderNo:"",//B2B 주문 번호
        goodsName:"",//상품명
        orderItems:[],//
        //itemNo            String    쿠폰번호
        //goodsName         String    상품명
        //optionName        String    옵션명
        //itemStatus        String    [Available, Invalidated, CancelRequested, Cancelled, BookingConnected, Locked, Consumed] 쿠폰상태 Available: 사용가능, CancelRequested: 취소요청, Cancelled: 취소됨, BookingConnected: 예약중, Locked: 사용불가처리됨, Consumed: 사용완료
        documentType:"",//  [CashReceipt, TaxBill]  CashReceipt: 현금영수증, TaxBill: 세금계산서
        receiptPurpose:"",//[IncomeDeduction, ProofOfExpenditure] IncomeDeduction: 소득공제,  ProofOfExpenditure: 사업자지출증빙
        requesterName:"",//요청자 이름 또는 사업자명
        requesterEmail:"",//요청자 이메일
        requesterPhone:"",//요청자 전화번호
        receiptPhone:"",//현금영수증 전화번호
        result:"",//[Requested, Rejected, Accepted] 처리결과 Requested: 신청됨, Rejected: 거절됨, Accepted: 승인됨
        bizRegCertificateUrl:"",//사업자등록증 이미지 URL
        requestedDate:"",// UTC yyyy-MM-ddTHH:mm:ss 요청일자
        completedBy:"",//요청 처리 관리자 ID
        rejectReason:"",//거절사유
        completedDate:"",//UTC yyyy-MM-ddTHH:mm:ss 요청 처리 완료일
      },
      reason:"",
      selrequestNo:0,
      exceldata:[],
      excelButtonDisabled : false,
      downButtonDisabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.ReqDocu_keywordType = this.keywordType;
      localStorage.ReqDocu_keyword = this.keyword;
      localStorage.ReqDocu_Number = this.pageNumber;
      localStorage.ReqDocu_Size = this.pageSize;
      localStorage.ReqDocu_fromDate = this.fromDate;
      localStorage.ReqDocu_toDate = this.toDate;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqPost(
        orderapi.base,
        orderapi.evidential_page,
        this.authToken,
        {
          keywordType:this.keywordType,
          keyword:this.keyword,
          fromDate:this.fromDate,
          toDate:this.toDate,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    closeInfoView() {
      this.infodlg = false;
    },
    showInfoView(requestNo) {
      reqPost(
        orderapi.base,
        orderapi.evidential_detail,
        this.authToken,
        {
          requestNo:requestNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.viewinf = result.data;
              this.infodlg = true;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    closeEdit() {
      this.editdlg = false;
    },
    showEdit(requestNo) {
      this.reason = "";
      this.selrequestNo = requestNo;
      this.editdlg = true;
    },
    phoneview(val){
      return formatPhone(val);
    },
    viewDocType(val)
    {
      if(val == "CashReceipt")
      {
        return "현금영수증";
      }else if(val == "TaxBill")
      {
        return "세금계산서";
      }else{
        return "";
      }
    },
    viewRecPur(val)
    {
      if(val == "IncomeDeduction")
      {
        return "소득공제";
      }else if(val == "ProofOfExpenditure")
      {
        return "사업자지출증빙";
      }else{
        return "";
      }
    },
    getItemStatuStr(val)
    {
      if(val == "Available")
      {
        return "사용가능";
      }else if(val == "CancelRequested")
      {
        return "취소요청됨";
      }else if(val == "Cancelled")
      {
        return "취소됨";
      }else if(val == "BookingConnected")
      {
        return "예약에 연결됨";
      }else if(val == "Locked")
      {
        return "사용불가처리됨";
      }else if(val == "Consumed")
      {
        return "사용완료";
      }else{
        return "";
      }
    },
    doAccept(requestNo)
    {
      reqPost(
        orderapi.base,
        orderapi.evidential_accept,
        this.authToken,
        {
          requestNo:requestNo,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doReject()
    {
      reqPost(
        orderapi.base,
        orderapi.evidential_reject,
        this.authToken,
        {
          requestNo:this.selrequestNo,
          reason:this.reason
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.closeEdit();
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    doDownLoad(url)
    {
      this.downButtonDisabled = true;
      axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = getfilename(url);
        link.click()
        URL.revokeObjectURL(link.href)
        this.downButtonDisabled = false;
      }).catch(()=>{
        console.error
        this.downButtonDisabled = false;
      })
    },
    getExcelData()
    {
      this.excelButtonDisabled = true;
      reqPost(
        orderapi.base,
        orderapi.evidential_list,
        this.authToken,
        {
          keywordType:this.keywordType,
          keyword:this.keyword,
          fromDate:this.fromDate,
          toDate:this.toDate
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            } else {
              this.exceldata = [];
              this.excelButtonDisabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    dataconvert(docu, idx) {
      var reqstate = "";
      if(docu.result == 'Rejected')
      {
        reqstate = "발급불가";
      }
      if(docu.result == 'Accepted')
      {
        reqstate = "발급완료";
      }
      return {
        A: idx + 1,
        B: docu.orderNo,
        C: docu.b2bOrderNo,
        D: docu.goodsName,
        E: this.viewDocType(docu.documentType),
        F: docu.requesterName,
        G: docu.requesterEmail,
        H: this.phoneview(docu.requesterPhone),
        I: this.viewRecPur(docu.receiptPurpose),
        J: reqstate,
        K: docu.bizRegCertificateUrl,
      };
    },
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "주문번호",
            C: "B2B 주문번호",
            D: "상품명",
            E: "서류종류",
            F: "이름 (사업자명)",
            G: "이메일주소",
            H: "신청번호",
            I: "서류용도",
            J: "처리상태",
            K: "첨부파일",
          },
        ],
        { skipHeader: true }
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "증빙서류신청");
      // export Excel file
      XLSX.writeFile(wb, "증빙서류신청.xlsx");
      this.excelButtonDisabled = false;
    },
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.infodlg th {
  background-color: #dee2e685;
}

.infodlg::-webkit-scrollbar {
  display: none;
}

.modal-body .control-label{
  margin:0px;
}
.modal .table{
  margin-bottom: 0px;
}
</style>
