<template>
  <section class="content" >
    <div class="mainbord">
        
        <img v-if="usageImages.titleImageUrl != '' && usageImages.titleImageUrl != null" :src="usageImages.titleImageUrl"/>
        <img v-if="usageImages.useInfoImageUrl != '' && usageImages.useInfoImageUrl != null" :src="usageImages.useInfoImageUrl"/>
        <img v-if="usageImages.baseInfoImageUrl != '' && usageImages.baseInfoImageUrl != null" :src="usageImages.baseInfoImageUrl"/>
        <img v-if="usageImages.notesInfoImageUrl != '' && usageImages.notesInfoImageUrl != null" :src="usageImages.notesInfoImageUrl"/>
        <img v-if="usageImages.refundInfoImageUrl != '' && usageImages.refundInfoImageUrl != null" :src="usageImages.refundInfoImageUrl"/>
        <img v-if="subImages.subImageUrl0 != '' && subImages.subImageUrl0 != null" :src="subImages.subImageUrl0"/>
        <img v-if="subImages.subImageUrl1 != '' && subImages.subImageUrl1 != null" :src="subImages.subImageUrl1"/>
        <img v-if="subImages.subImageUrl2 != '' && subImages.subImageUrl2 != null" :src="subImages.subImageUrl2"/>
        <img v-if="subImages.subImageUrl3 != '' && subImages.subImageUrl3 != null" :src="subImages.subImageUrl3"/>
        <img v-if="subImages.subImageUrl4 != '' && subImages.subImageUrl4 != null" :src="subImages.subImageUrl4"/>
        <img v-if="subImages.subImageUrl5 != '' && subImages.subImageUrl5 != null" :src="subImages.subImageUrl5"/>
        <img v-if="subImages.subImageUrl6 != '' && subImages.subImageUrl6 != null" :src="subImages.subImageUrl6"/>
        <img v-if="subImages.subImageUrl7 != '' && subImages.subImageUrl7 != null" :src="subImages.subImageUrl7"/>
        <img v-if="subImages.subImageUrl8 != '' && subImages.subImageUrl8 != null" :src="subImages.subImageUrl8"/>
        <img v-if="subImages.subImageUrl9 != '' && subImages.subImageUrl9 != null" :src="subImages.subImageUrl9"/>
    </div>
  </section>
</template>

<script>
import { rescode, menuval, imgtype } from "../../../lib/consts";
import { reqGet } from "../../../lib/request";
import { b2bapi } from "../../../lib/api";
export default {
  components: {
  },
  created() {
    this.goodsId = this.$route.query.goodid;
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_PRD,
      path2: menuval.LV2_PRD_REG,
      title: "",
    });
  },
  data() {
    return {
      goodsId: 0,
      usageImages: {
        titleImageUrl:"https://hitable2020images.blob.core.windows.net/b2b/b2b_form_info_top.jpg",//타이틀 이미지 URL
        useInfoImageUrl: null, //사용정보 이미지 URL
        baseInfoImageUrl: null, //기본정보 이미지 URL
        notesInfoImageUrl: null, //유의사항 이미지 URL
        refundInfoImageUrl: null, //취소환불규정 이미지 URL
      },
      initsubimgs:false,
      subImages: {
        subImageUrl0: "", //서브 이미지 URL 0
        subImageUrl1: "", //서브 이미지 URL 1
        subImageUrl2: "", //서브 이미지 URL 2
        subImageUrl3: "", //서브 이미지 URL 3
        subImageUrl4: "", //서브 이미지 URL 4
        subImageUrl5: "", //서브 이미지 URL 5
        subImageUrl6: "", //서브 이미지 URL 6
        subImageUrl7: "", //서브 이미지 URL 7
        subImageUrl8: "", //서브 이미지 URL 8
        subImageUrl9: "", //서브 이미지 URL 9
      },
    };
  },
  mounted() {
    this.refreshPage();
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype() {
      return imgtype;
    },
  },
  methods: {
    refreshPage() {
      reqGet(
        b2bapi.base,
        b2bapi.imgstandinfo + "?goodsId=" + this.goodsId,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            console.log(result.data)
            this.usageImages = result.data.usageImages;
            this.subImages = result.data.subImages;
            this.initsubimgs = true;
          }else{
            this.initsubimgs = false;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.mainbord{
  text-align: center;
  margin: auto;
  width: 732px;
  background-color: white;
}
</style>