<template>
<div>
    <div class="row">
        <div class="col-sm-7">
            <strong class="strongText">기본 운영</strong>
            <p class="right-btn">
                <button class="btn btn-default" type="button" @click="resetTime()" style="margin-right: 10px"> 초기화</button>
                <button class="btn btn-default" type="button" @click="bsicOperation(true)" v-if="operation && this.targetDaysType == ''">설정하기</button>
                <button class="btn btn-default" type="button" @click="bsicOperation(false)" v-else>재설정</button>
            </p>
            <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th class="text-center">예약 단위 시간</th>
                            <th class="text-center">적용 요일</th>
                            <th class="text-center">예약 가능 시간</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody v-if="targetDaysType.length == 0">
                        <tr>
                            <td class="text-center empty" colspan="3">평소 운영하는 시간을 설정해 주세요.</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr v-for="(acitem, acidx) in bookingOperation" :key="acidx">
                            <td class="text-center" :rowspan="targetDaysType.length" v-if="acidx == 0">{{ bookingOperation[0].timeUnit | engChangeKr }}</td>
                            <td class="text-center">
                                <p v-if="acitem.targetDaysType !== 'Custom'">
                                    {{ acitem.targetDaysType |  engChangeKr}}
                                </p>
                                <p>
                                    {{ acitem | daysFilter }}
                                </p>
                            </td>
                            <td class="text-center">
                                <p v-for="(nn, nnidx) in acitem.timeOption" :key="nnidx">
                                    {{ nn.startTime.substr(0,5) + '~' + nn.endTime.substr(0,5)  }}
                                </p>
                            </td>
                            <td class="text-center" :rowspan="targetDaysType.length" v-if="acidx == 0">
                                <button class="btn btn-default" type="button" @click="timeEdit(acitem.timeId,acitem.timeUnit)">시간수정</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- 특정 기간 운영 -->
    <div class="row mt-6">
        <div class="col-sm-7">
            <strong class="strongText">특정 기간 운영</strong>
            <p class="right-btn">
                <button class="btn btn-default" type="button" @click="addSpecific">추가하기</button>
            </p>
            <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th class="text-center">적용 기간</th>
                            <th class="text-center">예약 가능 시간</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="bookingTemporary.length == 0">
                        <tr>
                            <td colspan="2" class="text-center empty">특별한 날 임시로 운영하는 시간을 설정해주세요.</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr v-for="(acitem, acidx) in bookingTemporary" :key="acidx">
                            <td class="text-center">
                                {{ acitem.startDate + ' - ' + acitem.endDate}}
                            </td>
                            <td class="text-center">
                                <p v-for="(nn, nnidx) in acitem.timeOption" :key="nnidx">
                                    {{ nn.startTime.substr(0,5) + '~' + nn.endTime.substr(0,5)  }}
                                </p>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-default" type="button" @click="delTemporary(acitem.timeId)">삭제</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- 특정 기간 운영 end -->

    <!-- 모달 -->
    <div class="modal" :style="bsicOModal && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0" v-if="operatedTime == 'Default'">기본 운영</div>
                <div class="modal-header border-bottom-0" v-else>특정 기간 운영</div>
                <div class="modal-body form-horizontal">
                    <!-- 시간 선택 박스 -->
                    <div class="row" v-if="operatedTime == 'Default'">
                        <div class="col-sm-2 bold">
                            예약 단위 시간
                        </div>
                        <div class="col-sm-10" v-if="edtOrAdd">
                            <label>
                                <input type="radio" v-model="thisRadio" :value="true" @change="changeInput">
                                <span class="ml05">30분</span>
                            </label>
                            <label class="ml-1">
                                <input type="radio" v-model="thisRadio" :value="false" @change="changeInput">
                                <span class="ml05">1시간</span>
                            </label>
                        </div>
                        <div class="col-sm-10" v-else>
                            <span> {{ unitTimeText | engChangeKr }} </span>
                        </div>
                    </div>
                    <!-- 시간 선택 박스 end -->

                    <!-- 날짜 지정 박스 -->
                    <div class="row mt-2" v-if="operatedTime == 'Default'">
                        <div class="col-sm-2 bold">
                            적용 요일
                        </div>
                        <div class="col-sm-10" v-if="edtOrAdd">
                            <input type="radio" id="radioValues1" name="number" v-model="radioValues" :value="true" @change="thisdayChange">
                            <label class="ml05" for="radioValues1">매일</label>

                            <input class="ml-1" type="radio" id="radioValues2" name="number" v-model="radioValues" value="2r" @change="thisdayChange">
                            <label class="ml05" for="radioValues2">주중/주말</label>

                            <input class="ml-1" id="radioValues3" type="radio" name="number" v-model="radioValues" value="3r" @change="thisdayChange">
                            <label class="ml05" for="radioValues3">사용자지정</label>
                        </div>
                        <div class="col-sm-10" v-else>
                            <!-- {{ applyDayText }} -->
                            <span> {{ applyDayText | arrayDayFiter }} </span>
                        </div>
                    </div>
                    <div class="row mt-2" v-else>
                        <div class="col-sm-2 bold">
                            적용 기간
                        </div>
                        <div class="col-sm-10">
                            <DatePicker v-model="startDate" />
                            <span class="" style="border:none;display:inline-flex">-</span>
                            <DatePicker v-model="endDate" />
                        </div>
                    </div>
                    <!-- 날짜 지정 박스 end -->

                    <!-- 기본 운영 테이블 -->
                    <div class="row mt-2" v-for="(bitem, bidx, divBoxIndex) in divBox" :key="bidx">

                        <div class="col-sm-2 bold" v-if="divBoxIndex == 0">예약가능시간</div>
                        <div class="col-sm-2" v-else></div>
                        <div class="col-sm-10 first">
                            <div v-if="week">
                                {{ weekName[divBoxIndex] }}
                            </div>
                            <div>
                                <div :class="'btn-group mb-1 daycheckClass' + divBoxIndex" v-if="radioValues == '3r'">
                                    <button type="button" class="btn btn-default" :id="'monday' + divBoxIndex" @click="clickDay('monday',divBoxIndex)">월</button>
                                    <button type="button" class="btn btn-default" :id="'tuesday' + divBoxIndex" @click="clickDay('tuesday',divBoxIndex)">화</button>
                                    <button type="button" class="btn btn-default" :id="'wednesday' + divBoxIndex" @click="clickDay('wednesday',divBoxIndex)">수</button>
                                    <button type="button" class="btn btn-default" :id="'thursday' + divBoxIndex" @click="clickDay('thursday',divBoxIndex)">목</button>
                                    <button type="button" class="btn btn-default" :id="'friday' + divBoxIndex" @click="clickDay('friday',divBoxIndex)">금</button>
                                    <button type="button" class="btn btn-default" :id="'saturday' + divBoxIndex" @click="clickDay('saturday',divBoxIndex)">토</button>
                                    <button type="button" class="btn btn-default" :id="'sunday' + divBoxIndex" @click="clickDay('sunday',divBoxIndex)">일</button>
                                </div>
                            </div>
                            <div v-for="(aitem, aidx) in bitem" :key="aidx" :id="'inputGroup'+ divBoxIndex + aidx" :class="[aidx==0? '' :'mt1']">

                                <input class="form-control maxwidth" type="text" maxlength="5" v-model="aitem.startTime" placeholder="00:00" @keyup="keyPress($event,aitem.startTime,aidx,'startTime',divBoxIndex,divBox,bidx)">
                                <span>~</span>
                                <input class="form-control maxwidth" type="text" maxlength="5" v-model="aitem.endTime" placeholder="00:00" @keyup="keyPress($event,aitem.endTime,aidx,'end',divBoxIndex,divBox,bidx)">
                                <button type="button" class="btn btn-default ml-1" v-if="aidx !== 0" @click="inputDelete(divBoxIndex,aidx,divBox)">삭제</button>
                                <div :class="{'changeInput onValid' :  validationCheck(aitem.startTime,aitem.endTime,aidx,divBox,undefined,bidx) !== 0,
                                'changeInput' :  validationCheck(aitem.startTime,aitem.endTime,aidx,divBox,undefined,bidx) == 0}" v-if="validationCheck(aitem.startTime,aitem.endTime,aidx,divBox,undefined,bidx) !== 0">
                                    <!-- {{ validationCheckText  }} -->
                                    <div v-if="validationCheck(aitem.startTime,aitem.endTime,aidx,divBox,undefined,bidx) == 1" class="waring">
                                        운영 시간은 겹칠 수 없습니다.
                                    </div>
                                    <div v-else-if="validationCheck(aitem.startTime,aitem.endTime,aidx,divBox,undefined,bidx) == 2" class="waring">
                                        예약 받는 시간 간격을 선택해 주세요.
                                    </div>
                                    <div v-else-if="validationCheck(aitem.startTime,aitem.endTime,aidx,divBox,undefined,bidx) == 3" class="waring">
                                        마지막 시간은 시작 시간보다 빠를 수 없습니다.
                                    </div>
                                </div>

                            </div>
                            <button type="button" class="btn btn-default mt-1" @click="timeAdd(divBoxIndex,divBox)">시간추가</button>
                        </div>
                    </div>

                    <!-- 기본 운영 테이블 end -->
                    <div class="row mt-1">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-10">
                            <button type="button" class="btn btn-default" @click="dayAdd()" v-if="radioValues == '3r' && this.edtOrAdd == true">요일추가</button>
                        </div>
                    </div>

                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-default" @click="timeModalClose">취소</button>
                    <button type="button" class="btn btn-default" @click="timeModalSave">저장</button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    brandapi
} from "../../../../lib/api";
import DatePicker from "../../../../components/DatePicker";
import {
    reqGet,
    reqPost
} from "../../../../lib/request";
import {
    rescode,
    menuval
} from "../../../../lib/consts";

export default {
    filters: {
        arrayDayFiter(vars) {
            if (vars[0] === 'Weekday') {
                return '주중/주말';
            } else if (vars[0] === 'Everyday') {
                return '매일';
            } else {
                return '사용자 지정';
            }
        },
        daysFilter(val) {
            let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            let daysKr = ['월', '화', '수', '목', '금', '토', '일'];
            let returnDay = [];
            days.forEach((el, idx) => {
                if (val[el] === true) {
                    returnDay.push(daysKr[idx])
                }
            });
            // console.log(returnDay.toString())
            return returnDay.toString();
        },
        engChangeKr(val) {
            switch (val) {
                case "M30":
                    return "30분";
                case "M60":
                    return "1시간";
                case "M60":
                    return "1시간";
                case "Everyday":
                    return "매일";
                case "Weekday":
                    return "주중";
                case "Weekend":
                    return "주말";
                case "Custom":
                    return "사용자 지정";
                default:
                    break;
            }
        }
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    data: () => ({
        edt: false,
        operatedTime: "Default",
        loopCheck: false,
        warning: [],
        timeId: 0,
        timeIds: [],
        startDate: "",
        endDate: "",
        specificOModal: false,
        applyDayText: [],
        unitTimeText: '',
        bookingOperation: {},
        bookingTemporary: {},
        dayClass: [],
        dayGroup: [],
        operation: true,
        bsicOModal: false,
        edtOrAdd: true,
        divBox: {
            value: [{
                startTime: "",
                endTime: "",

            }],
        },
        day: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        },
        timeSettings: [],
        weekName: ['주중', '주말'],
        week: false,
        dayinput1: false,
        dayinput2: true,
        dayinput3: true,
        radioValues: true,
        thisRadio: false,
        targetDaysType: [],
        thisTableDay: "",
        placeholder: "00:00",
        divArray: "",
        startend: "",
        validationCheckText: "",
        bidx: 'value',
        timeSetEdt: [],
        list: [],
    }),
    components: {
        DatePicker
    },
    updated() {
        if (this.list.length !== 0 && this.bsicOModal && !this.edtOrAdd && !this.edt) {
            let days = ['monday', 'thursday', 'friday', 'saturday', 'sunday', 'tuesday', 'wednesday']
            this.list.forEach((el, idx1) => {
                days.forEach(day => {
                    if (el[day] === true) {
                        this.clickDay(day, idx1);
                    }
                });
            })
            this.edt = true;
        }
    },
    mounted() {
        this.refresh();

    },

    methods: {
        delTemporary(timeId) {
            if (confirm("삭제하시겠습니까?")) {
                reqPost(
                    brandapi.base,
                    brandapi.timeSettingDelete,
                    this.authToken, {
                        timeId: timeId,
                        storeCode: this.$route.query.stcode
                    },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.refresh();
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },

        resetTime() {
            if(confirm("시간설정을 초기화하시겠습니까?")) {
                reqGet(
                    brandapi.base,
                    brandapi.timeSettingReset + "?storeCode=" + this.$route.query.stcode,
                    this.authToken,
                    (result) => {
                        if(result != null) {
                            if(result.code == rescode.REQ_SUCCESS) {
                                this.refresh();
                                this.operation = true;
                                this.targetDaysType = [];
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        refresh() {
            reqGet(
                brandapi.base,
                brandapi.timeSettingList + "?storeCode=" + this.$route.query.stcode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.bookingOperation = result.data.list;
                            let defaults = [],
                                temporary = [];
                            result.data.list.forEach((el, idx) => {
                                this.targetDaysType.push(el.targetDaysType);
                                this.bookingOperation[idx].targetDaysType = el.targetDaysType;
                                if (el.operatedTime === "Default") {
                                    defaults.push(el);
                                } else {
                                    temporary.push(el);
                                }
                            });
                            this.bookingOperation = defaults;
                            this.bookingTemporary = temporary;
                            // console.log(this.bookingTemporary)
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        validationCheck(startTime, end, aidx, arrayType, noLoop, bidx) {
            if (this.bidx !== bidx) { //주중,사용자지정일경우 인풋 검사시 오브젝트 별로 진행
                return;
            }
            let isture = 0;
            let iddx = '';

            if (this.bsicOModal) { // 무한루프 방지
                if (noLoop !== undefined) {
                    return;
                }
            } else {
                if (noLoop !== "second") {
                    return;
                }
            }
            for (let i in arrayType) {
                if (aidx == 0) {
                    iddx = "";
                } else {
                    iddx = aidx;
                }
                if (i == bidx) { //bidx는 인풋 키업 이벤트시 해당 오브젝트 키값
                    this.divArray = arrayType[i]
                }
            }
            this.divArray.find((eld, idx) => {
                //1시간 단위
                let arrayStart = Number(eld.startTime.substr(0, 2)),
                    arrayStartMin = Number(eld.startTime.substr(3, 5)),
                    arrayEnd = Number(eld.endTime.substr(0, 2)),
                    arrayEndMin = Number(eld.endTime.substr(3, 5)),
                    nowStart = Number(startTime.substr(0, 2)),
                    nowStartMin = Number(startTime.substr(3, 5)),
                    nowEnd = Number(end.substr(0, 2)),
                    nowEndMin = Number(end.substr(3, 5));

                if (startTime.length === 5) {
                    if (aidx !== idx) {
                        if (nowStart == arrayStart && nowEndMin === nowStartMin) {
                            if (this.thisRadio == true) { //30분일경우
                                if (arrayStartMin === nowStartMin) {
                                    return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
                                }
                            } else {
                                return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
                            }
                        } else if (arrayEnd > nowStart && nowStart >= arrayStart) {
                            if (this.thisRadio == true) {
                                return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
                            } else {
                                return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
                            }
                        } else if (arrayEnd === nowStart && nowStartMin === arrayEndMin) {
                            return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
                        } else if (arrayStart === 0 || arrayEnd === 0) {
                            return;
                        } else {
                            return isture = 0; // this.validationCheckText = "";
                        }
                    } else if (aidx === idx) {
                        if (arrayEnd == 0) {
                            return isture = 2; // this.validationCheckText = "예약 받는 시간 간격을 선택해 주세요.";
                        } else if (arrayStart > nowEnd) {
                            return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
                        }
                    }
                }
                if (end.length === 5) {
                    if (aidx == idx) {
                        if (nowEnd == arrayStart && nowEndMin === nowStartMin) {
                            //return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
                        } else if (arrayStart > nowEnd) {
                            return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
                        } else if (nowEnd === arrayStart) {
                            if (this.thisRadio == true) {
                                if (nowEndMin < nowStartMin) {
                                    return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
                                }
                            }
                        } else {
                            return isture = 0; // this.validationCheckText = "";
                        }
                    } else {
                        if (arrayStart > nowEnd) {
                            return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
                        }
                    }
                }
                return isture;
            });
            return isture;
        },
        timeEdit(timeId, timeUnit) {
            this.edt = false;
            this.bsicOModal = true;
            this.operatedTime = "Default";
            this.timeId = timeId;
            reqGet(
                brandapi.base,
                brandapi.timeSettingQuery + "?storeCode=" + this.$route.query.stcode + "&timeId=" + timeId,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            //edtOrAdd -> false 일경우 수정
                            this.edtOrAdd = false;
                            this.timeSetEdt = result.data;
                            this.unitTimeText = timeUnit;
                            this.applyDayText = [];

                            result.data.list.forEach(el => {
                                this.applyDayText.push(el.targetDaysType);
                                if (el.timeUnit === "M30") {
                                    this.thisRadio = true;
                                } else {
                                    this.thisRadio = false;
                                }
                            });
                            if (result.data.list[0].targetDaysType === 'Everyday') {
                                this.radioValues = true;
                            } else if (result.data.list[0].targetDaysType === 'Custom') {
                                this.radioValues = '3r';
                            } else {
                                this.radioValues = '2r';
                            }
                            this.timeEditNumber(result.data.list)
                            this.list = result.data.list;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        addSpecific() {
            if (this.targetDaysType == '') {
                alert('기본 운영 설정 후 추가 가능합니다.');
                return
            }
            this.operatedTime = "Temporary";
            this.thisdayChange();
            this.bsicOModal = true;
            this.thisRadio = this.bookingOperation[0].timeUnit === 'M60' ? false : true;
            console.log(this.thisRadio)

        },
        clickDayCheck(dayIf, day, divBoxIndex) {
            let isww = false;
            let secondLoop = true;
            if (this.dayClass.length == 0) {
                document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default btn-selweek';
                this.dayClass = [];
                this.dayClass.push(dayIf);
                isww = true;
            } else {
                this.dayClass.forEach((el, idx) => {
                    if (el.day == day && el.divBoxIndex == divBoxIndex && el.onActive == String(day + divBoxIndex)) { // 이미 체크된 요일 체크 풀기
                        if (el.onActive === String(day + divBoxIndex)) {
                            this.dayClass.splice(idx, 1);
                        }
                        document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default';
                        // console.log('같음')
                        isww = true;
                        secondLoop = false;
                    }
                });
                //배열안에 이미 있는 요일 체크
                if (secondLoop) {
                    let ss = this.dayClass.find((item) => {
                        return item.day === day;
                    })
                    if (ss == undefined) {
                        document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default btn-selweek';
                        this.dayClass.push(dayIf);
                        isww = true;
                    } else {
                        isww = false;
                        return;
                    }
                }
            }
            return isww;
        },
        sameDaySelectCheck(day, divBoxIndex) {
            let dayIf = {
                dayIf: true,
                day: day,
                divBoxIndex: divBoxIndex,
                onActive: day + divBoxIndex
            }
            if (this.clickDayCheck(dayIf, day, divBoxIndex)) {
                let days = {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                }

                switch (day) {
                    case 'monday':
                        days.monday = !days.monday;
                        break;
                    case 'tuesday':
                        days.tuesday = !days.tuesday;
                        break;
                    case 'wednesday':
                        days.wednesday = !days.wednesday;
                        break;
                    case 'thursday':
                        days.thursday = !days.thursday;
                        break;
                    case 'friday':
                        days.friday = !days.friday;
                        break;
                    case 'saturday':
                        days.saturday = !days.saturday;
                        break;
                    case 'sunday':
                        days.sunday = !days.sunday;
                        break;
                }
                if (this.dayGroup.length == 0) {
                    this.dayGroup.push(days)
                } else {
                    this.dayGroup.forEach((el, idx) => {
                        if (idx !== divBoxIndex) {
                            //있는경우 추가 방지
                            if (this.dayGroup[divBoxIndex] === undefined) {
                                this.dayGroup.push(days)
                            }
                        } else {
                            if (el[day] == true) {
                                el[day] = false;
                            } else {
                                el[day] = true;
                            }
                        }
                    });
                }
                // console.log(this.dayGroup)
            } else {
                return;
            }

        },
        clickDay(day, divBoxIndex) {
            this.sameDaySelectCheck(day, divBoxIndex);
        },
        timeModalClose() {
            this.dayGroup = [];
            this.dayClass = [];
            this.radioValues = true;
            this.thisRadio = true;
            this.thisdayChange();
            this.bsicOModal = false;
        },
        timeModalSave() {
            let noisdays = [],
                targetDaysType = "",
                targetDaysType2 = "",
                unitTime = "",
                divArray = null;
            // 시간 저장시 벨리데이션
            if (this.operatedTime !== 'Default') {
                if (this.startDate === '' || this.endDate === '') {
                    alert('적용 기간을 지정해주세요.');
                    return;
                }
            }
            let nu = 0;
            for (let i in this.divBox) {
                if (nu == 0) {
                    if (i === 'value0') {
                        nu = 0;
                    } else {
                        nu = "";
                    }
                }
                if (i == 'value' + nu) {
                    divArray = this.divBox[i]
                }
                nu++
                for (let i = 0; i < divArray.length; i++) {
                    if (divArray[i].startTime == "" || divArray[i].endTime == "") {
                        alert('예약 받는 시간 간격을 선택해 주세요.');
                        return
                    }

                }
            }

            let warning = document.querySelector('.first .waring');
            if (warning !== null) {
                alert(warning.textContent);
                return
            }

            this.dayGroup.forEach((el, idx) => {
                for (let s in el) {
                    noisdays.push(el[s])
                }
            });
            if (!noisdays.includes(true) && this.radioValues === "3r") {
                alert('요일을 체크하십시요.');
                return;
            }
            //시간 벨리데이셔 end

            this.bsicOModal = false;
            this.operation = true;

            if (this.radioValues == true) {
                targetDaysType = 'Everyday';

            } else if (this.radioValues == '2r') {
                targetDaysType = ['Weekday', 'Weekend'];
            } else {
                targetDaysType = "Custom"
            }
            // 배열 재조합 백엔드에 보낼때 사용하는 배열
            this.timeSettings = [];
            let count = 0;
            let start = null;
            let end = null;
            if (this.operatedTime == 'Default') {
                start = null;
                end = null;
            } else {
                start = this.startDate;
                end = this.endDate;
            }
            let url = "";
            let id = null;
            this.timeIds = [];
            this.bookingOperation.forEach(el => {
                this.timeIds.push(el.timeId);
            });
            console.log(this.timeIds);
            if (this.edtOrAdd == false) {
                if (this.operatedTime !== 'Default') {
                    url = brandapi.timeSettingCreate; // 등록
                    id = null;
                } else {
                    url = brandapi.timeSettingUpdate; //수정
                    id = this.timeIds;
                }
            } else {
                url = brandapi.timeSettingCreate; // 등록
                id = null;
            }
            for (let i in this.divBox) {
                this.timeSettings.push({
                    startDate: start,
                    endDate: end,
                    id: id === null ? null : id[count],
                    timeUnit: this.thisRadio ? "M30" : "M60",
                    targetDaysType: targetDaysType.constructor === Array ? targetDaysType[count] : targetDaysType,
                    operatedTime: this.operatedTime, // 기본일경우 Default
                    timeOption: this.divBox[i],
                    monday: this.dayGroup.length === 0 ? false : this.dayGroup[count].monday,
                    tuesday: this.dayGroup.length === 0 ? false : this.dayGroup[count].tuesday,
                    wednesday: this.dayGroup.length === 0 ? false : this.dayGroup[count].wednesday,
                    thursday: this.dayGroup.length === 0 ? false : this.dayGroup[count].thursday,
                    friday: this.dayGroup.length === 0 ? false : this.dayGroup[count].friday,
                    saturday: this.dayGroup.length === 0 ? false : this.dayGroup[count].saturday,
                    sunday: this.dayGroup.length === 0 ? false : this.dayGroup[count].sunday,
                })

                count++;
            }

            reqPost(
                brandapi.base,
                url,
                this.authToken, {
                    timeSettings: this.timeSettings,
                    storeCode: this.$route.query.stcode
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            alert('저장되었습니다.');

                            this.refresh();
                            this.timeModalClose();
                            //재설정버튼
                            this.operation = false;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
            // console.log(this.timeSettings)

        },
        bsicOperation(click) {
            //edtOrAdd -> 등록일경우 true
            // this.edt = false;
            this.edtOrAdd = true;
            this.operatedTime = "Default";
            this.radioValues = true;
            this.thisRadio = false;
            this.thisdayChange();
            this.bsicOModal = true;
        },
        timeEditNumber(list) {
            // console.log('수정')

            this.thisdayChange();
            if (this.radioValues === true) {
                this.divBox.value = [];
                list.forEach(el => {
                    el.timeOption.forEach(time => {
                        let pp = {
                            startTime: time.startTime.substr(0, 5),
                            endTime: time.endTime.substr(0, 5)
                        };
                        this.divBox.value.push(pp);
                    });
                });
            } else if (this.radioValues === "2r") {
                list.forEach((el, idx) => {
                    if (idx === 0) {
                        this.divBox.value = [];
                        el.timeOption.forEach(time => {
                            let pp = {
                                startTime: time.startTime.substr(0, 5),
                                endTime: time.endTime.substr(0, 5)
                            };
                            this.divBox.value.push(pp);
                        })
                    } else if (idx === 1) {
                        this.divBox.value1 = [];
                        el.timeOption.forEach(time => {
                            let pp = {
                                startTime: time.startTime.substr(0, 5),
                                endTime: time.endTime.substr(0, 5)
                            };
                            this.divBox.value1.push(pp);
                        })
                    }
                });
            } else {
                this.divBox = {};
                list.forEach((el, idx1) => {
                    let newarry = [];
                    el.timeOption.forEach((time, idx) => {
                        let pp = {
                            startTime: time.startTime.substr(0, 5),
                            endTime: time.endTime.substr(0, 5)
                        }
                        newarry.push(pp);
                    });
                    this.$set(this.divBox, "value" + idx1, newarry);

                });

                // console.log(this.divBox)
            }
            // this.edt = true;
        },
        timeAdd(idx, arrayObject) { //시간추가
            if (this.radioValues === true) {
                let pp = {
                    startTime: "",
                    endTime: ""
                };
                arrayObject.value.push(pp);
            } else if (this.radioValues === "2r") {
                if (idx == 0) {
                    let pp = {
                        startTime: "",
                        endTime: ""
                    };
                    arrayObject.value.push(pp);
                } else {
                    let pp = {
                        startTime: "",
                        endTime: ""
                    };
                    arrayObject.value1.push(pp);
                }
            } else {
                for (let i in arrayObject) {
                    let pp = {
                        startTime: "",
                        endTime: ""
                    };
                    let iddx = '';
                    if (idx == 0) {
                        if (this.edtOrAdd == false) { //수정
                            iddx = 0;
                        } else { //등록
                            iddx = "";
                        }
                    } else {
                        iddx = idx;
                    }
                    if (i == 'value' + iddx) {
                        arrayObject[i].push(pp)
                    }
                }
            }

        },
        dayAdd() { //요일 추가
            let pp = [{
                startTime: "",
                endTime: ""
            }];
            let idx = Object.keys(this.divBox).length;
            this.$set(this.divBox, "value" + idx, pp);
        },
        slicemethods(startend, aidx, type, e, divBoxIndex, arrayObject, val, onlyNum) { // 자동으로 따옴표 붙혀주기 0또는 3 구분
            // console.log(e)
            let iddx = '';
            for (let i in arrayObject) {
                if (divBoxIndex == 0) {
                    iddx = "";
                } else {
                    iddx = divBoxIndex;
                }
                if (i == 'value' + iddx) {
                    this.divArray = arrayObject[i]
                }
            }

            if (type == "Backspace") {
                if (startend === "startTime") {
                    if (e.key !== 'Backspace') {
                        if (this.thisRadio == true) {
                            this.divArray[aidx].startTime += ":"
                        } else {
                            this.divArray[aidx].startTime += ":00"
                        }
                    } else {
                        this.divArray[aidx].startTime = this.divArray[aidx].startTime.slice(0, -1);
                    }
                } else {
                    if (e.key !== 'Backspace') {
                        if (this.thisRadio == true) {
                            this.divArray[aidx].endTime += ":"
                        } else {
                            this.divArray[aidx].endTime += ":00"
                        }
                    } else {
                        this.divArray[aidx].endTime = this.divArray[aidx].endTime.slice(0, -1);
                    }
                }
            } else if (type == 'first') {
                if (startend === "startTime") {
                    if (Number(val) >= 3) {
                        this.divArray[aidx].startTime = '';
                        if (this.thisRadio == true) {
                            this.divArray[aidx].startTime += '0' + val + ":"
                        } else {
                            this.divArray[aidx].startTime += '0' + val + ":00"
                        }
                    }
                } else {
                    if (Number(val) >= 3) {
                        this.divArray[aidx].endTime = '';
                        if (this.thisRadio == true) {
                            this.divArray[aidx].endTime += '0' + val + ":"
                        } else {
                            this.divArray[aidx].endTime += '0' + val + ":00"
                        }
                    }
                }
            } else if (type == 'mid') {
                if (this.thisRadio == false) {
                    if (startend === "startTime" && e.code !== "Backspace") {
                        this.divArray[aidx].startTime += "00"
                    } else if (e.code !== "Backspace") {
                        this.divArray[aidx].endTime += "00"
                    }
                }
            } else {
                if (startend === "startTime") {
                    if (this.divArray[aidx] !== undefined && Number(val) !== 0) {
                        this.divArray[aidx].startTime = this.divArray[aidx].startTime.slice(0, -1);
                    }
                    if (this.divArray[aidx].startTime.length === 4 && this.thisRadio == false) {
                        this.divArray[aidx].startTime = this.divArray[aidx].startTime.substring(2, -3);
                    }
                } else {
                    if (this.divArray[aidx] !== undefined && Number(val) !== 0) {
                        this.divArray[aidx].endTime = this.divArray[aidx].endTime.slice(0, -1);
                    }
                    if (this.divArray[aidx].endTime.length === 4 && this.thisRadio == false) {
                        this.divArray[aidx].endTime = this.divArray[aidx].endTime.substring(2, -3);
                    }
                }
            }
        },
        bigNumber(type, aidx, startend, val, divBoxIndex, arrayObject) { // 1시간 일경우 23시 30분일 경우 23시 30분까지
            let iddx = '';
            for (let i in arrayObject) {
                if (divBoxIndex == 0) {
                    iddx = "";
                } else {
                    iddx = divBoxIndex;
                }
                if (i == 'value' + iddx) {
                    this.divArray = arrayObject[i]
                }
            }
            if (Number(val.substr(0, 2)) > 23) {
                if (startend === "startTime") {
                    this.divArray[aidx].startTime = this.divArray[aidx].startTime.slice(0, -2);
                } else {
                    this.divArray[aidx].endTime = this.divArray[aidx].endTime.slice(0, -2);
                }
            }
        },

        moveArray(val, aidx, divBoxIndex) { // 순서 맞추기
            this.divArray.sort(function (a, b) {
                if (a.startTime === "") return;
                if (a.startTime > b.startTime) return 1;
                if (a.startTime === b.startTime) return 0;
                if (a.startTime < b.startTime) return -1;
            });
        },
        keyPress(e, val, aidx, startend, divBoxIndex, arrayObject, bidx) { // 인풋박스 입력시 밸리데이션
            this.startend = startend;
            let iddx = '';
            this.bidx = bidx;
            for (let i in arrayObject) {
                if (divBoxIndex == 0) {
                    iddx = "";
                } else {
                    iddx = divBoxIndex;
                }
                if (i == 'value' + iddx) {
                    this.divArray = arrayObject[i]
                }
            }
            //숫자 : 만 입력 가능
            const regExp = /[^-\:0-9]/g;
            const ele = e.target;

            if (regExp.test(ele.value)) {
                ele.value = ele.value.replace(regExp, '');
                if (startend === "startTime") {
                    this.$set(this.divArray[aidx], 'startTime', ele.value);
                } else {
                    this.$set(this.divArray[aidx], 'endTime', ele.value);
                }
                return
            } else {
                val = ele.value;
                if (startend === "startTime") {
                    this.$set(this.divArray[aidx], 'startTime', ele.value);
                } else {
                    this.$set(this.divArray[aidx], 'endTime', ele.value);
                }
            }
            //숫자 : 만 입력 가능 end
            if (val.length === 1) {
                this.slicemethods(startend, aidx, 'first', e, divBoxIndex, arrayObject, val.substr(-1), e.key);
            }
            if (val.length === 2) {
                this.slicemethods(startend, aidx, 'Backspace', e, divBoxIndex, arrayObject, val.substr(-1), e.key);
            }
            if (val.length === 3) {
                this.slicemethods(startend, aidx, 'mid', e, divBoxIndex, arrayObject, val.substr(-1), e.key);
                //빠르게 입력시 오탈자 막기
                if (!val.includes(':')) {
                    if (startend === "startTime") {
                        this.$set(this.divArray[aidx], 'startTime', val.substr(3, 5));
                    } else {
                        this.$set(this.divArray[aidx], 'endTime', val.substr(3, 5));
                    }
                }
            } else if (val.length === 4) {
                if (this.radioValues == true) {
                    console.log(ele.value.substr(-1))
                    if (ele.value.substr(-1) == 3 || ele.value.substr(-1) == 0) {

                    } else {
                        if (startend === "startTime") {
                            this.$set(this.divArray[aidx], 'startTime', val.replace(/,$/, ''));
                        } else {
                            this.$set(this.divArray[aidx], 'endTime', val.replace(/,$/, ''));
                        }
                    }
                }
            } else if (val.length === 5) {
                this.moveArray(val, aidx, divBoxIndex);
                this.loopCheck = true;
            } else {
                this.loopCheck = false;
            }

            if (this.thisRadio == true) { // 30분
                if (val.length === 4) {
                    if (Number(e.key) !== 0 && Number(e.key) !== 3) {
                        this.slicemethods(startend, aidx, null, null, divBoxIndex, arrayObject, val.substr(-1), e.key);
                    }
                } else {
                    if (val.length === 5) {
                        if (Number(e.key) !== 0) {
                            this.slicemethods(startend, aidx, null, null, divBoxIndex, arrayObject, val.substr(-1), e.key);
                        }
                    }
                }
                this.bigNumber('30', aidx, startend, val, arrayObject); // 23시 30분까지만 써지게 하기
            } else { // 1시간
                if (val.length === 4 || val.length === 5) {
                    if (Number(e.key) !== 0) {
                        this.slicemethods(startend, aidx, null, null, divBoxIndex, arrayObject, val.substr(-1), e.key);
                    } else {

                    }
                }
                this.bigNumber('60', aidx, startend, val, arrayObject); // 23시 까지만 써지게 하기
            }
        },
        changeInput() {

            this.thisdayChange();
        },
        inputDelete(divBoxIndex, aidx, arrayObject) {
            let iddx = '';
            for (let i in arrayObject) {
                if (divBoxIndex == 0) {
                    iddx = 0;
                } else {
                    iddx = divBoxIndex;
                }
                if (i == 'value' + iddx) {
                    this.divArray = arrayObject[i]
                }
            }
            this.divArray.splice(aidx, 1);
            // console.log(this.divArray)
        },
        thisdayChange() {
            this.dayClass = [];
            this.dayGroup = [];
            if (this.radioValues == true) {
                this.divBox = {
                    value: [{
                        startTime: "",
                        endTime: ""
                    }],
                };
                this.week = false;

            } else if (this.radioValues == '2r') {
                this.divBox = {
                    value: [{
                        startTime: "",
                        endTime: ""
                    }],
                    value1: [{
                        startTime: "",
                        endTime: ""
                    }],
                };
                this.week = true;

            } else {
                this.divBox = {
                    value: [{
                        startTime: "",
                        endTime: ""
                    }],
                };
                this.week = false;
            }
        }
    },
}
</script>

<style scoped>
th {
    background-color: #dee2e685;
}

td {
    vertical-align: middle !important;
}

.right-btn {
    text-align: right;
}

.strongText {
    font-size: 18px;
}

.mt-6 {
    margin-top: 60px;
}

.changeInput {
    color: red;
    margin-top: 5px;
}

.btn-selweek {
    background-color: #5B9BD5;
    color: #FFFFFF;
}

.maxwidth {
    max-width: 200px;
    display: inline-block;
    vertical-align: middle;
}

.ml-1 {
    margin-left: 10px;

}

.mt-1,
.mt1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.bold {
    font-weight: 900;
}

.ml05 {
    margin-left: 5px;
}

.empty {
    color: #878787;
    font-size: 15px;
}

.mb-1 {
    margin-bottom: 10px;
}

.modal {
    overflow: auto;
}
</style>
