<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <div class="input-group radio">
            <label>
              <input type="radio" :value="null" v-model="publishedOnly"/>전체
            </label>
            <label>
              <input type="radio" :value="true" v-model="publishedOnly"/>발행된 글
            </label>
            <label>
              <input type="radio" :value="false" v-model="publishedOnly"/>발행되지 않은 글
            </label>
          </div>
          <select class="form-control" v-model="keywordType" @change="refreshPage" style="width:auto; margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="TitleContaining">제목</option>
            <option value="RegisteredBy">작성자 ID</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="goEditPage(0)">
        <i class="fa fa-plus"></i>등록
      </button>
      <button class="btn btn-default" style="margin-left:15px" @click="doRmvNotice">
        <i class="fa fa-fw fa-remove"></i>삭제
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>
                <input type="checkbox" class="allch" @change="allckech($event)"/>
              </th>
              <th class="text-center">번호</th>
              <th class="text-center">게시번호</th>
              <th class="text-center">상태</th>
              <th>제목</th>
              <th class="text-center">등록일</th>
              <th class="text-center">작성자</th>
              <th class="text-center">조회</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(notice, index) in content" :key="notice.noticeNo">
              <th>
                <input type="checkbox" class="chitem" :value="notice.noticeNo"/>
              </th>
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{notice.noticeNo}}</td>
              <td class="text-center" v-if="notice.published" style=""><div class="speakico"></div></td>
              <td class="text-center" v-if="!notice.published"></td>
              <td class="linkcmp" @click="goEditPage(notice.noticeNo)">{{notice.title}}</td>
              <td class="text-center">{{viewDate(notice.registeredDate)}}</td>
              <td class="text-center">{{notice.registeredBy}}</td>
              <td class="text-center">{{notice.viewedCount | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import { reqPost } from "../../lib/request";
import { board } from "../../lib/api";
import { DateFormat } from "../../lib/utility";
import $ from 'jquery';
export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.Notice_keywordType) {
        this.keywordType = localStorage.Notice_keywordType;
      }
      if (localStorage.Notice_keyword) {
        this.keyword = localStorage.Notice_keyword;
      }
      if (localStorage.Notice_Number) {
        this.pageNumber = parseInt(localStorage.Notice_Number);
      }
      if (localStorage.Notice_Size) {
        this.pageSize = parseInt(localStorage.Notice_Size);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_BOD,
      path2: menuval.LV1_HOME,
      title: "공지사항",
    });
  },
  watch: {
    keywordType(newVal) {
      localStorage.Notice_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.Notice_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.Notice_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.Notice_Size = newsize;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      publishedOnly:null,
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * noticeNo             Number    공지 번호
       * title                String    제목
       * registeredDate       String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * registeredBy         String    등록자
       * viewedCount          Number    조회 회수
       * published            Boolean   발행여부: true: 발행됨, false: 발행안됨
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.Notice_keywordType = this.keywordType;
      localStorage.Notice_keyword = this.keyword;
      localStorage.Notice_Number = this.pageNumber;
      localStorage.Notice_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }
      reqPost(
        board.base,
        board.notice_page,
        this.authToken,
        {
          keywordType:this.keywordType,
          keyword:this.keyword,
          publishedOnly:this.publishedOnly,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goEditPage(noticeNo) {
      this.$router.push(this.routes.editnotice+"?noticeNo="+noticeNo);
    },
    doRmvNotice()
    {
      var noticeNos = $('input:checkbox.chitem:checked').map(function () {
        return this.value;
      }).get();
      reqPost(
        board.base,
        board.notice_delete,
        this.authToken,
        {
          noticeNos:noticeNos,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              $('input:checkbox.chitem').prop('checked', false);
              $('input:checkbox.allch').prop('checked', false);
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.radio{
  margin-bottom: 0px;
}

.speakico{
  background-image: url("/static/img/icon/ic_speaker.png");
  background-repeat: no-repeat;
  background-position: center;
  width:10px;
  height:10px;
  margin: auto;
}
</style>
