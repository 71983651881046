<template>
  <div class="box box-solid">
    <div class="box-body">
      <div class="table-responsive p-0" style="margin-top:10px">
        <table class="table table-hover text-nowrap">
          <colgroup>
            <col width="200px">
            <col width="100%">
          </colgroup>
          <tbody>
          <tr>
            <th>채널노출</th>
            <td>
              <label v-for="(chitem, chidx) in openChannelList" :key="chidx">
                <input type="checkbox" :value="chitem.value" :checked="openChannelIs(chitem.value)"
                       @change="openChannelChange(chitem.value, $event)"/>
                {{ chitem.name }}
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-default" @click="openChannelUpdate">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {rescode} from "@/lib/consts";

export default {
  data() {
    return {
      excludedChannels: [],
      storeCode: '',
      openChannelList: [{
        name: "베네피아",
        value: "BENEPIA"
      },
        {
          name: "이지웰",
          value: "EZWEL"
        },
        {
          name: "테이블엔조이",
          value: "WWW"
        },
        {
          name: "LIFECARE",
          value: "LIFECARE"
        },
        {
          name: "샬레코리아",
          value: "CHALETKOREA"
        },
        {
          name: "자체판매",
          value: "CLOUD"
        }
      ],
    }
  },
  mounted() {
    this.openChannelInfo();
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  methods:{
    openChannelIs(chcode) {
      console.log(this.excludedChannels)
      console.log(this.excludedChannels.findIndex(item => item == chcode))
      if (this.excludedChannels.findIndex(item => item == chcode) == -1) {
        return true;
      } else {
        return false;
      }
    },
    openChannelChange(chcode, event) {
      if (event.target.checked) {
        var idx = this.excludedChannels.findIndex(item => item == chcode);
        if (idx != -1) {
          this.excludedChannels.splice(idx, 1);
        }
      } else {
        this.excludedChannels.push(chcode);
      }
    },
    openChannelUpdate() {

      reqPost(
        brandapi.base,
        brandapi.openChannelUpdate,
        this.authToken, {
          storeCode: this.storeCode,
          excludedChannels: this.excludedChannels
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert("저장되었습니다.");
            } else {
              alert(result.message);
            }
          }
        }
      )
    },
    openChannelInfo() {
      reqGet(
        brandapi.base,
        brandapi.openChannelInfo + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            console.log(result)
            this.excludedChannels = result.data.excludedChannels
          }
        }
      );
    },


  }


}
</script>
