<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
                    <option value="NoKeyword"></option>
                    <option value="BrandNameContaining">브랜드명</option>
                    <option value="BrandCode">브랜드코드</option>
                    <option value="ManagerID">담당자ID</option>
                </select>
                <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                    <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button class="btn btn-default" @click="goNewBrand">
            <i class="fa fa-plus"></i>등록
        </button>
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
        <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="downExcel" :disabled="buttonDissabled">
            <i class="fa fa-download"></i>Excel
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th class="text-center">번호</th>
                        <th class="text-center">코드</th>
                        <th class="text-center">분류</th>
                        <th class="text-center">브랜드명</th>
                        <th class="text-center">검수신청매장수</th>
                        <th class="text-center">등록일</th>
                        <th class="text-center">담당자</th>
                        <th class="text-center">보기</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(brand, index) in content" v-bind:key="brand.brandCode">
                        <td class="text-center">{{ totalElements - (startnum + index) }}</td>
                        <td class="text-center">{{brand.brandCode}}</td>
                        <td class="text-center">{{brand.brandType}}</td>
                        <td class="text-center">{{brand.brandName}}</td>
                        <td class="linkcmp text-center" @click="gostoreinspec(brand.brandCode, $event)">{{brand.pendingStoreCount}}</td>
                        <td class="text-center">{{viewDate(brand.registeredDate)}}</td>
                        <td class="text-center">{{brand.tnjManagerID}}</td>
                        <td class="text-center">
                            <button class="btn btn-default" @click="editbrand(brand.brandCode, $event)">수정</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import {
    reqGet
} from "../../lib/request";
import {
    brandapi
} from "../../lib/api";
import {
    DateFormat
} from "../../lib/utility";
import XLSX from "xlsx";
export default {
    components: {
        Pagination,
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.BrdMng_keywordType) {
                this.keywordType = localStorage.BrdMng_keywordType;
            }
            if (localStorage.BrdMng_keyword) {
                this.keyword = localStorage.BrdMng_keyword;
            }
            if (localStorage.BrdMng_Number) {
                this.pageNumber = parseInt(localStorage.BrdMng_Number);
            }
            if (localStorage.BrdMng_Size) {
                this.pageSize = parseInt(localStorage.BrdMng_Size);
            }
        }
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_BRD,
            path2: menuval.LV1_HOME,
            title: "브랜드관리",
        });
    },
    watch: {
        keywordType(newVal) {
            localStorage.BrdMng_keywordType = newVal;
        },
        keyword(newkey) {
            localStorage.BrdMng_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.BrdMng_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.BrdMng_Size = newsize;
        },
    },
    data() {
        return {
            shownum: 10, //pagination에 보여지는 번호개수
            keywordType: "NoKeyword",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true
            exceldata: {
                animals: [{
                        name: "cat",
                        category: "animal"
                    },
                    {
                        name: "dog",
                        category: "animal"
                    },
                    {
                        name: "pig",
                        category: "animal"
                    },
                ],
                pokemons: [{
                        name: "pikachu",
                        category: "pokemon"
                    },
                    {
                        name: "Arbok",
                        category: "pokemon"
                    },
                    {
                        name: "Eevee",
                        category: "pokemon"
                    },
                ],
            },
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.BrdMng_keywordType = this.keywordType;
            localStorage.BrdMng_keyword = this.keyword;
            localStorage.BrdMng_Number = this.pageNumber;
            localStorage.BrdMng_Size = this.pageSize;
        }
        this.refreshPage();
    },
    methods: {
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD HH:mm:00");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqGet(
                brandapi.base,
                brandapi.brandpage +
                "?keywordType=" +
                this.keywordType +
                "&keyword=" +
                this.keyword +
                "&pageNumber=" +
                pageNumber +
                "&pageSize=" +
                this.pageSize,
                this.authToken,
                (data) => {
                    if (data != null) {
                        if (data.code == rescode.REQ_SUCCESS) {
                            this.content = data.data.content;
                            this.totalPages = data.data.totalPages; //전체 페이지 수
                            this.totalElements = data.data.totalElements; //전체 아이템 수
                            this.numberOfElements = data.data.numberOfElements; //현재 조회된 아이템 수
                            /*
                            data.data.size : 페이지 사이즈
                            data.data.number : 페이지 번호
                            data.data.first : 첫 페이지일 경우 true
                            data.data.last : 마지막 페이지일 경우 true
                            data.data.empty : 조회된 아이템이 없으면 true
                             */
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goNewBrand() {
            this.$router.push(this.routes.regbrand);
        },
        convert(item) {
            return {
                A: item.brandCode,
                B: item.brandType,
                C: item.brandName,
                D: item.pendingStoreCount,
                E: DateFormat(item.registeredDate, "YYYY-MM-DD HH:mm:00"),
                F: item.tnjManagerID,
                G: item.salesPerson1Name,
                H: item.salesPerson1Phone,
                J: item.salesPerson1Email,
                I: item.salesPerson2Name,
                M: item.salesPerson2Phone,
                L: item.salesPerson2Email,
                N: item.accountantName,
                P: item.accountantPhone,
                Q: item.accountantEmail,
            };
        },
        downExcel() {
            //brandpageList
            this.buttonDissabled = true;
            reqGet(
                brandapi.base,
                brandapi.brandpageList,
                // "?keywordType=" +
                // this.keywordType +
                // "&keyword=" +
                // this.keyword,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            var pokemonWS = XLSX.utils.json_to_sheet(
                                [{
                                    A: "코드",
                                    B: "분류",
                                    C: "브랜드명",
                                    D: "검수신청매장수",
                                    E: "등록일",
                                    F: "담당자",
                                    G: "영업담당자1 이름",
                                    H: "휴대폰 번호",
                                    J: "이메일주소",
                                    I: "영업담당자2 이름",
                                    M: "휴대폰 번호",
                                    L: "이메일주소",
                                    N: "정산담당자1 이름",
                                    P: "휴대폰 번호",
                                    Q: "이메일주소",
                                }, ], {
                                    skipHeader: true
                                }
                            );
                            XLSX.utils.sheet_add_json(pokemonWS, result.data.map(this.convert), {
                                skipHeader: true,
                                origin: "A2",
                            });
                            var wb = XLSX.utils.book_new(); // make Workbook of Excel

                            XLSX.utils.book_append_sheet(wb, pokemonWS, "브랜드관리");
                            // export Excel file
                            XLSX.writeFile(wb, "브랜드관리.xlsx");
                            this.buttonDissabled = false;

                        }
                    }
                }
            );

        },
        editbrand(bcode) {
            this.$router.push(this.routes.editbrand + "?bcode=" + bcode);
        },
        gostoreinspec(bcode) {
            localStorage.ReqIns_keywordType = "BrandCode";
            localStorage.ReqIns_keyword = bcode;
            this.$router.push(this.routes.storeinspec);
        },
    },
};
</script>
