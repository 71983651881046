<template>
  <section class="content">
    <div class="box box-solid box-primary">
      <div class="box-header text-right">
                <span style="display:flex;justify-content:space-between; flex-direction: row; align-items:center;"><h3
                  class="box-title text-left">연동 채널 리스트</h3>
                <button type="button" style="background: #cccccccc;  color:black;" class="btn btn-default text-center"
                        @click="createModal">생성</button></span>
      </div>
      <div class="box-body form-horizontal width100F">
        <div class="table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>채널명</th>
              <th>채널코드</th>
              <th>API연동키</th>
              <th>&nbsp &nbsp &nbsp수정</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in channelList" :key="idx">
              <td>{{ item.channelName }}</td>
              <td>{{ item.channelCode }}</td>
              <td>{{ item.agentKey }}</td>
              <td>
                <button type="button" class="btn btn-default text-center" @click="updateModal(item.channelCode)">수정
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal" v-if="channelCreateModal" :style="{'display': 'block'}">
        <div class="modal-dialog">
          <div class="modal-content" style="width:900px">
            <div class="modal-body form-horizontal table-responsive p-0">
              <div class="form-group">
                <label class="col-sm-5 control-label">* 채널명</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" v-model="channelName"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-5 control-label">* 채널코드</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" placeholder="채널코드는 대문자로 작성" v-model="channelCode"/>
                    <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-5 control-label">채널 고객센터 번호</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" v-model="channelCallCenter"/>
                    <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-5 control-label">채널 판매 URL</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" v-model="channelSalesUrl"/>
                    <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                  </div>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">채널 유형</label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="B2bChannel" v-model="channelType" name="channelType"/>채널
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="B2bIframe" v-model="channelType"
                           name="channelType"/> 아이프레임
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="Tablenjoy" v-model="channelType"
                           name="channelType"/> 자사
                  </label>
                </div>
              </div>


              <div class="form-group ">
                <label class="col-sm-5 control-label">채널 판매관리 여부<br> <span
                  class="font-size">[상품상세 < (탭)판매채널] 체크박스 노출 관리</span></label>


                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="goodsSalesManage"
                           name="goodsSalesManage"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="goodsSalesManage"
                           name="goodsSalesManage"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">채널 배포관리 여부<br>
                  <span class="font-size">[B2B 상품관리] 채널 노출 관리</span>
                </label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="goodsDistributeManage"
                           name="goodsDistributeManage"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="goodsDistributeManage"
                           name="goodsDistributeManage"/> NO
                  </label>
                </div>
              </div>


              <div class="form-group text-center">
                <label class="col-sm-5 control-label" style=" white-space: nowrap;">일괄 배포완료 지원 여부<br>
                  <span class="font-size">[B2B 상품관리 > 채널배포요청 > 채널별 리스트]</span><br>
                  <span class="font-size"> 엑셀 일괄작업 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="goodsDistributeBatch"
                           name="goodsDistributeBatch"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="goodsDistributeBatch"
                           name="goodsDistributeBatch"/> NO
                  </label>
                </div>
              </div>


              <div class="form-group text-center">
                <label class="col-sm-5 control-label">상품연동 개발 여부<br>
                  <span class="font-size">[B2B 상품관리 > 채널배포요청/코드매칭관리]</span><br>
                  <span class="font-size"> 연동 유형 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="goodsInterworking"
                           name="goodsInterworking"/>YES (관리자 연동개발)
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="goodsInterworking"
                           name="goodsInterworking"/> NO (코드매칭)
                  </label>
                </div>
              </div>



              <div class="form-group text-center">
                <label class="col-sm-5 control-label">채널 전용 취소/환불 사용 여부<br> <span class="font-size">[B2B 상품관리 > 채널배포요청/코드매칭관리]</span><br>
                  <span class="font-size">"채널 전용 이미지 생성" 버튼 노출 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px;">
                    <input type="radio" value="true" v-model="channelImageManage"
                           name="channelImageManage"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channelImageManage"
                           name="channelImageManage"/> NO
                  </label>
                </div>
              </div>


              <div class="form-group text-center">
                <label class="col-sm-5 control-label">결합상품 판매 여부<br> <span
                  class="font-size">[B2B 상품관리 > 채널배포요청/코드매칭관리]</span><br>
                  <span class="font-size">결합상품 노출 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="goodsCombinedSales"
                           name="goodsCombinedSales"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="goodsCombinedSales"
                           name="goodsCombinedSales"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">주문취소 연동 여부<br> <span
                  class="font-size">[주문관리 > 주문내역조회 > 주문상세]</span><br>
                  <span class="font-size">주문상태복원 버튼 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="b2bOrderCancellationEnabled"
                           name="b2bOrderCancellationEnabled"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="b2bOrderCancellationEnabled"
                           name="b2bOrderCancellationEnabled"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">마이페이지 취소가능 여부</label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="mypageCancelable"
                           name="mypageCancelable"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="mypageCancelable"
                           name="mypageCancelable"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">API 연동키 생성 여부</label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="agentKey"
                           name="agentKey"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="agentKey"
                           name="agentKey"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">API 허용 IP</label>
                <div class="radio col-sm-7 text-left">
                  <textarea placeholder="여러개 등록 시 엔터로 구분" v-model="apiAllowList"></textarea>
                </div>
              </div>

              <div class="form-group text-center">
          <span >
          <button type="button" class="btn btn-default" style="margin:0px 5px" @click="modalClose">취소</button>
          <button type="button" class="btn btn-default"
                  style="margin:0px 5px; background-color: green; opacity: 0.8; color:white"
                  @click="channelCreate">생성</button>
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--여기 부턴 수정 모달-->
      <div class="modal" v-if="channelUpdateModal" :style="{'display': 'block'}">
        <div class="modal-dialog">
          <div class="modal-content" style="width:900px">
            <div class="modal-body form-horizontal table-responsive p-0">
              <div class="form-group">
                <label class="col-sm-5 control-label">* 채널명</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" v-model="channel.channelName"/>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-5 control-label">* 채널코드</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" placeholder="채널코드는 대문자로 작성" v-model="channel.channelCode"/>
                    <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-5 control-label">채널 고객센터 번호</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" v-model="channel.channelCallCenter"/>
                    <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-5 control-label">채널 판매 URL</label>
                <div class="col-sm-7">
                  <div class="input-group width100F">
                    <input type="text" class="form-control" v-model="channel.channelSalesUrl"/>
                    <!-- <div v-if="description === ''" class="redtext">설명을 입력 해주세요.</div> -->
                  </div>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">채널 유형</label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="B2bChannel" v-model="channel.channelType" name="channelType"/>채널
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="B2bIframe" v-model="channel.channelType"
                           name="channelType"/> 아이프레임
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="Tablenjoy" v-model="channel.channelType"
                           name="channelType"/> 자사
                  </label>
                </div>
              </div>

              <div class="form-group text-center">

                <label class="col-sm-5 control-label">채널 판매관리 여부<br>     <span class="font-size">[상품상세 < (탭)판매채널] 체크박스 노출 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.goodsSalesManage"
                           name="goodsSalesManage"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.goodsSalesManage"
                           name="goodsSalesManage"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">채널 배포관리 여부<br>
                  <span class="font-size">[B2B 상품관리] 채널 노출 관리</span>
                </label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.goodsDistributeManage"
                           name="goodsDistributeManage"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.goodsDistributeManage"
                           name="goodsDistributeManage"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">일괄 배포완료 지원 여부<br>
                  <span class="font-size">[B2B 상품관리 > 채널배포요청 > 채널별 리스트]</span><br>
                  <span class="font-size"> 엑셀 일괄작업 관리</span>
                </label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.goodsDistributeBatch"
                           name="goodsDistributeBatch"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.goodsDistributeBatch"
                           name="goodsDistributeBatch"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">상품연동 개발 여부<br>
                  <span class="font-size">[B2B 상품관리 > 채널배포요청/코드매칭관리]</span><br>
                  <span class="font-size"> 연동 유형 관리</span>
                </label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.goodsInterworking"
                           name="goodsInterworking"/>YES (관리자 연동개발)
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.goodsInterworking"
                           name="goodsInterworking"/> NO (코드매칭)
                  </label>
                </div>
              </div>



              <div class="form-group text-center">
                <label class="col-sm-5 control-label">채널 전용 취소/환불 사용 여부<br>
                  <span class="font-size">[B2B 상품관리 > 채널배포요청/코드매칭관리]</span><br>
                  <span class="font-size">"채널 전용 이미지 생성" 버튼 노출 관리</span>
                </label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.channelImageManage"
                           name="channelImageManage"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.channelImageManage"
                           name="channelImageManage"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">결합상품 판매 여부<br>
                  <span class="font-size">[B2B 상품관리 > 채널배포요청/코드매칭관리]</span><br>
                  <span class="font-size">결합상품 노출 관리</span>
                </label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.goodsCombinedSales"
                           name="goodsCombinedSales"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.goodsCombinedSales"
                           name="goodsCombinedSales"/> NO
                  </label>
                </div>
              </div>
              <div class="form-group text-center">
                <label class="col-sm-5 control-label">주문취소 연동 여부<br> <span
                  class="font-size">[주문관리 > 주문내역조회 > 주문상세]</span><br>
                  <span class="font-size">주문상태복원 버튼 관리</span></label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.b2bOrderCancellationEnabled"
                           name="b2bOrderCancellationEnabled"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.b2bOrderCancellationEnabled"
                           name="b2bOrderCancellationEnabled"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">마이페이지 취소가능 여부</label>
                <div class="radio col-sm-7 text-left">
                  <label style="margin-right:20px">
                    <input type="radio" value="true" v-model="channel.mypageCancelable"
                           name="mypageCancelable"/>YES
                  </label>
                  <label style="margin-right:20px">
                    <input type="radio" value="false" v-model="channel.mypageCancelable"
                           name="mypageCancelable"/> NO
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">API 연동키 생성 여부</label>
                <div class="radio col-sm-7 text-left">
                  <label style=" margin-right:20px" v-if="channel.isAgentKey == false">
                    <input type="radio" value="true" v-model="agentKey"
                           name="agentKey"/>YES
                  </label>
                  <label style="margin-right:20px" v-if="channel.isAgentKey == false">
                    <input type="radio" value="false" v-model="agentKey"
                           name="agentKey"/> NO
                  </label>
                  <label v-if="channel.isAgentKey == true">
                    <input type="text" class="agent-info" disabled v-model="channel.agentKey"
                           v-if="channel.isAgentKey == true"
                           name="agentKey"/>
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <label class="col-sm-5 control-label">API 허용 IP</label>
                <div class="radio col-sm-7 text-left">
                  <textarea placeholder="여러개 등록 시 엔터로 구분" v-model="channel.ipAddressList"></textarea>
                </div>
              </div>


              <div class="form-group text-center">
                <span >
                <button type="button" class="btn btn-default" style="margin:0px 5px" @click="modalClose">취소</button>
                <button type="button" class="btn btn-default"
                        style="margin:0px 5px; background-color: green; opacity: 0.8; color:white"
                        @click="channelUpdate">수정</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </section>

</template>

<script>
import {rescode, menuval} from "../../lib/consts";
import {reqGet, reqPost} from "../../lib/request";
import {b2bapi, brandapi, discoutCopon} from "../../lib/api";
import DetePicker from "@/components/DatePicker.vue";
import DatePicker from "@/components/DatePicker.vue";

export default {
  components: {DatePicker, DetePicker},
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_DEV,
      path2: menuval.LV1_HOME,
      title: "채널 연동관리",
    });
  },
  data() {
    return {
      channelList: [],
      channel: {},
      channelName: '',
      channelCreateModal: false,
      channelUpdateModal: false,
      channelCode: '',
      channelCallCenter: '',
      channelSalesUrl: '',
      channelType: '',
      goodsSalesManage: '',
      goodsDistributeManage: '',
      goodsDistributeBatch: null,
      goodsInterworking: null,
      channelImageManage: null,
      goodsCombinedSales: null,
      b2bOrderCancellationEnabled: null,
      mypageCancelable: null,
      agentKey: null,
      apiAllowList: ''
    };
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    }
  },
  mounted() {
    this.refreshPage();
  },
  methods: {
    refreshPage() {
      this.getChannelList();
    },
    getChannelList() {
      reqGet(
        b2bapi.base,
        b2bapi.channelInfoList,
        this.authToken,
        (result) => {
          if (result != null && result.code === rescode.REQ_SUCCESS) {
            this.channelList = result.data.channelInfoList;
          }
        }
      );
    },
    getChannelDetail(channelCode) {
      reqGet(
        b2bapi.base,
        b2bapi.channelDetail + "?channelCode=" + channelCode,
        this.authToken,
        (result) => {
          if (result != null && result.code === rescode.REQ_SUCCESS) {
            this.channel = result.data;
            if (this.channel.ipAddressList != null) {
              this.channel.ipAddressList = this.channel.ipAddressList.replace(/,(\s*)/g, '\n');
            }
            this.agentKey = this.channel.isAgentKey;
          }
        }
      );
    },
    createModal() {
      this.channelCreateModal = true;
    },
    updateModal(channelCode) {
      this.channelUpdateModal = true;
      this.getChannelDetail(channelCode);

    },
    modalClose() {
      this.channelCreateModal = false;
      this.channelUpdateModal = false;
      this.channel = {};
      this.channelName = '',
        this.channelCreateModal = false,
        this.channelUpdateModal = false,
        this.channelCode = '',
        this.channelCallCenter = '',
        this.channelSalesUrl = '',
        this.channelType = '',
        this.goodsSalesManage = '',
        this.goodsDistributeManage = '' ,
        this.goodsDistributeBatch = null ,
        this.goodsInterworking = null,
        this.channelImageManage = null ,
        this.goodsCombinedSales = null ,
        this.mypageCancelable = null ,
        this.agentKey = null

    },
    channelCreate() {
      if (this.channelName == null || this.channelName == '') {
        alert("채널명을 입력해주세요");
        return;
      }
      if (this.channelCode == null || this.channelCode == '') {
        alert("채널코드를 입력해주세요");
        return;
      }
      if (this.goodsSalesManage == null) {
        alert("채널 판매관리 여부를 선택하세요.");
        return;
      }
      if (this.goodsDistributeManage == null) {
        alert("채널 배포관리 여부를 선택하세요.")
        return;
      }
      if (this.goodsDistributeBatch == null) {
        alert("일괄 배포완료 지원여부를 선택하세요.")
        return;
      }

      if (this.goodsInterworking == null) {
        alert("상품연동 개발여부를 선택하세요.");
        return;
      }
      if (this.channelImageManage == null) {
        alert("채널 전용/취소 환불여부를 선택하세요.");
        return;
      }
      if (this.goodsCombinedSales == null) {
        alert("결합상품 판매여부를 선택하세요.");
        return;
      }
      if (this.mypageCancelable == null) {
        alert("마이페이지 취소가능 여부를 선택하세요.");
        return;
      }
      if (this.agentKey == null) {
        alert("API 연동키 생성 여부를 선택하세요");
        return;
      }

      reqPost(
        b2bapi.base,
        b2bapi.ChannelCreate,
        this.authToken, {
          channelCode: this.channelCode,
          channelName: this.channelName,
          channelCallCenter: this.channelCallCenter,
          channelSalesUrl: this.channelSalesUrl,
          channelType: this.channelType,
          goodsSalesManage: this.goodsSalesManage,
          goodsDistributeManage: this.goodsDistributeManage,
          goodsDistributeBatch: this.goodsDistributeBatch,
          goodsInterworking: this.goodsInterworking,
          channelImageManage: this.channelImageManage,
          goodsCombinedSales: this.goodsCombinedSales,
          mypageCancelable: this.mypageCancelable,
          isAgentKey: this.agentKey,
          ipAddressList: this.apiAllowList,
          b2bOrderCancellationEnabled: this.b2bOrderCancellationEnabled
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert('저장되었습니다.');
              this.modalClose();
              this.getChannelList();
              // this.$router.push("discountApprovalPage");
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    channelUpdate() {
      if (this.channel.channelName == null || this.channel.channelName == '') {
        alert("채널명을 입력해주세요");
        return;
      }
      if (this.channel.channelCode == null || this.channel.channelCode == '') {
        alert("채널코드를 입력해주세요");
        return;
      }
      if (this.channel.goodsDistributeBatch == null) {
        alert("일괄 배포완료 지원여부를 선택하세요.")
        return;
      }
      if (this.channel.goodsSalesManage == null) {
        alert("채널 판매관리 여부를 선택하세요.");
        return;
      }
      if (this.channel.goodsDistributeManage == null) {
        alert("채널 배포관리 여부를 선택하세요.")
        return;
      }
      if (this.channel.goodsInterworking == null) {
        alert("상품연동 개발여부를 선택하세요.");
        return;
      }
      if (this.channel.channelImageManage == null) {
        alert("채널 전용/취소 환불여부를 선택하세요.");
        return;
      }
      if (this.channel.goodsCombinedSales == null) {
        alert("결합상품 판매여부를 선택하세요.");
        return;
      }
      if (this.channel.mypageCancelable == null) {
        alert("마이페이지 취소가능 여부를 선택하세요.");
        return;
      }

      if (this.channel.isAgentKey == true) {
        this.channel.isAgentKey = false;
      } else if (this.channel.isAgentKey == false && this.isAgentKey == true) {
        this.channel.isAgentKey = true;
      } else {
        this.channel.isAgentKey = false;
      }

      reqPost(
        b2bapi.base,
        b2bapi.channelUpdate,
        this.authToken, {
          channelId: this.channel.channelId,
          channelCode: this.channel.channelCode,
          channelName: this.channel.channelName,
          channelCallCenter: this.channel.channelCallCenter,
          channelSalesUrl: this.channel.channelSalesUrl,
          channelType: this.channel.channelType,
          goodsSalesManage: this.channel.goodsSalesManage,
          goodsDistributeManage: this.channel.goodsDistributeManage,
          goodsDistributeBatch: this.channel.goodsDistributeBatch,
          goodsInterworking: this.channel.goodsInterworking,
          channelImageManage: this.channel.channelImageManage,
          goodsCombinedSales: this.channel.goodsCombinedSales,
          mypageCancelable: this.channel.mypageCancelable,
          isAgentKey: this.channel.isAgentKey,
          ipAddressList: this.channel.ipAddressList,
          b2bOrderCancellationEnabled:this.channel.b2bOrderCancellationEnabled
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.modalClose();
              this.getChannelList();
              alert('수정 되었습니다.');

              // this.$router.push("discountApprovalPage");
            } else {
              alert(result.message);
            }
          }
        }
      );

    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.radio > label {
  margin-right: 10px;
}

.box-body {
  width: 2000px;

}

.width30F {
  width: 20%;
}

.width100F {
  width: 100%;
}

.font-size {
  font-size: 12px;
  color: gray;
}

.agent-info {
  display: flex;
  border: none;
  border-right: 0px;
  border-top: 0px;
  boder-left: 0px;
  boder-bottom: 0px;
  background-color: white;
}

textarea {
  height: 7.5em;
  width: 100%
}
</style>
