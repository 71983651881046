<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
                <div class="radio" style="margin:0px;margin-right:10px">
                    <label>
                        <input type="radio" value="all" v-model="onCommend" @change="refreshPage" />전체
                    </label>
                    <label>
                        <input type="radio" value="true" v-model="onCommend" @change="refreshPage" />활성
                    </label>
                    <label>
                        <input type="radio" value="false" v-model="onCommend" @change="refreshPage" />비활성
                    </label>
                </div>
                <div class="input-group" style="flex:1;max-width:400px;min-width:200px">
                    <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button class="btn btn-default" style="margin-right:10px" @click="goDetailPage()">
            <i class="fa fa-plus"></i>등록
        </button>
        <button class="btn btn-default" style="margin-right:10px" @click="showRmvDlg">
            <i class="fa fa-fw fa-remove"></i>삭제
        </button>
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" class="allch" @change="allckech($event)" />
                        </th>
                        <th class="text-center">번호</th>
                        <th class="text-center">지정방식</th>
                        <th class="text-center">핫플레이스명</th>
                        <th class="text-center">추천노출여부</th>
                        <th class="text-center">등록일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(hot) in content" v-bind:key="hot.id">
                        <th>
                            <input type="checkbox" class="chitem" :value="hot.id" />
                        </th>
                        <td class="text-center">{{ hot.id }}</td>
                        <td class="text-center">{{ hot.hotPlaceType !== 'Coodinate' ? "구역" : "좌표" }}</td>
                        <td class="linkcmp text-center" @click="goDetailPage(hot.id)">{{hot.hotplaceName}}</td>
                        <td class="linkcmp text-center" @click="onChangeuseFlag(hot.id, hot.recommend)">
                            <ToggleBtn v-model="hot.recommend" />
                        </td>
                        <td class="text-center">{{viewDate(hot.registeredDate)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>
    <div class="modal" :style="removedlg && { display: 'block' }">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">삭제</div>
                <div class="modal-body form-horizontal">
                    <div class="form-group text-center">
                        삭제하시겠습니까?
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-default" style="margin-right: 10px" @click="closeRmvDlg">
                            닫기
                        </button>
                        <button type="button" class="btn btn-default" @click="onHotplacedelete">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import ToggleBtn from "../../components/ToggleBtn";
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    brandapi
} from "../../lib/api";
import {
    DateFormat
} from "../../lib/utility";
import $ from 'jquery';
import {
    Drag,
    Drop
} from "vue-drag-drop";
export default {
    components: {
        Pagination,
        ToggleBtn,
        Drag,
        Drop,
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.STPRM_keyword) {
                this.keyword = localStorage.STPRM_keyword;
            }
            if (localStorage.STPRM_Number) {
                this.pageNumber = parseInt(localStorage.STPRM_Number);
            }
            if (localStorage.STPRM_Size) {
                this.pageSize = parseInt(localStorage.STPRM_Size);
            }
            if (localStorage.STPRM_onCommend) {
                this.onCommend = localStorage.STPRM_onCommend;
            }
        }
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_DSP,
            path2: menuval.LV1_HOME,
            title: "핫플레이스"
        });
    },
    watch: {
        keyword(newkey) {
            localStorage.STPRM_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.STPRM_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.STPRM_Size = newsize;
        },
        onCommend(ntype) {
            localStorage.STPRM_onCommend = ntype;
        }
    },
    data() {
        return {
            removedlg: false,
            shownum: 10, //pagination에 보여지는 번호개수
            onCommend: "all",
            keyword: "",
            pageNumber: 1, //현재 페이지 번호
            pageSize: 20, //페지당 개수
            content: [],
            /**
             * promotionId          Number    프로모션 ID
             * title                String    프로모션 명
             * useFlag              Boolean   활성화여부
             * regDate              String    등록일
             */
            totalPages: 0, //전체 페이지 수
            totalElements: 0, //전체 아이템 수
            numberOfElements: 0, //현재 조회된 아이템 수
            first: true, //첫 페이지일 경우 true
            last: false, //마지막 페이지일 경우 true
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.STPRM_keyword = this.keyword;
            localStorage.STPRM_Number = this.pageNumber;
            localStorage.STPRM_Size = this.pageSize;
            localStorage.STPRM_onCommend = this.onCommend;
        }
        this.refreshPage();
    },
    methods: {
        allckech(event) {
            $('input:checkbox.chitem').prop('checked', event.target.checked);
        },
        closeRmvDlg() {
            this.removedlg = false;
        },
        showRmvDlg() {
            var promotionIds = $('input:checkbox.chitem:checked').map(function () {
                return this.value;
            }).get();
            if (promotionIds.length == 0) {
                alert("상품을 선택하세요.");
                return;
            }
            this.removedlg = true;
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }

            var all = this.onCommend == 'all';
            var onCommend = this.onCommend == 'true';

            reqGet(
                brandapi.base,
                brandapi.hotplaceList + "?keyword=" + this.keyword + "&all=" + all + "&onCommend=" + onCommend  +  "&pageNumber=" + pageNumber + "&pageSize=" + this.pageSize,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                            alert(result.message);
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        goDetailPage(id) {
            this.$router.push(this.routes.editHotplace + "?id=" + id);
        },
        onChangeuseFlag(id, useFlag) {
            let enun = "";
            if (!useFlag) {
                enun = brandapi.hotplaceEnable
            } else {
                enun = brandapi.hotplaceDisable
            }
            reqPost(
                brandapi.base,
                enun,
                this.authToken, {
                    id: id,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        handleDrop(myitem, drag) {
            var t_contents = [...this.content];
            var t_val = t_contents[myitem.index - 1];
            t_contents[myitem.index - 1] = {
                ...t_contents[drag.index - 1]
            };
            t_contents[drag.index - 1] = {
                ...t_val
            };
            this.content = [...t_contents];
        },
        saveOrder() {
            var orders = this.content.map((item, index) => {
                return {
                    promotionId: item.promotionId,
                    order: item.order
                }
            });
            reqPost(
                display.base,
                display.promotionorder,
                this.authToken, {
                    orders: orders,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        onHotplacedelete() {
            var id = $('input:checkbox.chitem:checked').map(function () {
                return this.value;
            }).get();
            reqPost(
                brandapi.base,
                brandapi.hotplaceDelete,
                this.authToken, {
                    id: id,
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            $('input:checkbox.chitem').prop('checked', false);
                            $('input:checkbox.allch').prop('checked', false);
                            this.refreshPage();
                            this.removedlg = false ;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        }
    },
};
</script>

<style scoped>
.row {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
}

.radio>label {
    margin-right: 10px;
}
</style>
