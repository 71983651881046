<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
          <div class="checkbox" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="ALL" v-model="saletype"/>전체
            </label>
            <label>
              <input type="radio" value="ONSALE" v-model="saletype"/>판매중
            </label>
            <label>
              <input type="radio" value="STOP" v-model="saletype"/>일시중지
            </label>
          </div>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsNameContaining">상품명</option>
            <option value="GoodsId">상품코드</option>
            <option value="SiteName">등록경로</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="goRemoveGoods">
        <i class="fa fa-plus"></i>삭제
      </button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th>
              <input type="checkbox" class="allch" @change="allckech($event)"/>
            </th>
            <th class="text-center">번호</th>
            <th class="text-center">상품코드</th>
            <th class="text-center">상품유형</th>
            <th class="text-center">등록경로</th>
            <th>상품명</th>
            <th class="text-center">판매매수</th>
            <th class="text-center">판매상태</th>
            <th class="text-center">종료일</th>
            <th class="text-center">등록일</th>
            <th class="text-center">보기</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(good, index) in content" :key="good.goodsId">
            <td>
              <input type="checkbox" class="chitem" :value="good.goodsId"/>
            </td>
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="text-center">{{ good.goodsId }}</td>
            <!-- {{ good.forCombined }} -->
            <td class="text-center">{{ goodsTypeVal(good.goodsType) }} <span v-if="good.forCombined">(결합)</span></td>
            <td class="text-center">{{ getSiteBrandName(good.siteName) }}</td>
            <td class="linkcmp" @click="editbrand(good.goodsId, good.goodsType)">{{ good.goodsName }}</td>
            <td class="text-center">{{ good.soldCount }}</td>
            <td class="text-center">{{ getSaleState(good.onSale) }}</td>
            <td class="text-center">{{ viewDate(good.saleEndDate) }}</td>
            <td class="text-center">{{ viewDate(good.registeredDate) }}</td>
            <td class="text-center">
              <button class="btn btn-default" @click="goCopyNewProd(good.goodsId, good.goodsType)">복사</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {reqGet, reqPost} from "../../../lib/request";
import {goodsapi, brandapi} from "../../../lib/api";
import {DateFormat, getGoodsTypeStr} from "../../../lib/utility";
import $ from 'jquery';

export default {
  components: {
    Pagination,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.PRDREG_keywordType) {
        this.keywordType = localStorage.PRDREG_keywordType;
      }
      if(localStorage.PRDREG_keyword) {
        this.keyword = localStorage.PRDREG_keyword;
      }
      if(localStorage.PRDREG_saletype) {
        this.saletype = localStorage.PRDREG_saletype;
      }
      if(localStorage.PRDREG_Number) {
        this.pageNumber = parseInt(localStorage.PRDREG_Number);
      }
      if(localStorage.PRDREG_Size) {
        this.pageSize = parseInt(localStorage.PRDREG_Size);
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_REG, title: "상품정보수정"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.PRDREG_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.PRDREG_keyword = newkey;
    },
    saletype(newsale) {
      localStorage.PRDREG_saletype = newsale;
    },
    pageNumber(newnum) {
      localStorage.PRDREG_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.PRDREG_Size = newsize;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      saletype: "ALL",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /*
      goodsId                 Number      상품 ID
      goodsType               String      상품 타입
                              [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon]
      goodsName               String      상품명
      siteName                String      상품 등록 사이트명
      soldCount               Number      판매수량
      onSale                  Boolean     판매 상태. 판매중인 경우 true
      mainImageUrl            String      메인 이미지 url
      mainOption.optionId     Number      메인 옵션 ID
      mainOption.optionName   String      메인 옵션명
      mainOption.listPrice    String      정상가
      mainOption.salePrice    String      판매가
      mainOption.currencyCode String      통화코드
                              [USD, KRW]
      registeredDate          String      상품 등록일
                              UTC yyyy-MM-ddTHH:mm:ss
      saleEndDate             String      판매 종료일
                              yyyy-MM-dd
      */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      sitebrands: [],//
      /*
      brandCode     String    브랜드 코드
      brandName     String    브랜드 네임
      siteName      String    사이트명(도메인)
      */
      checkedgoodids: [],
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.PRDREG_keywordType = this.keywordType;
      localStorage.PRDREG_keyword = this.keyword;
      localStorage.PRDREG_saletype = this.saletype;
      localStorage.PRDREG_Number = this.pageNumber;
      localStorage.PRDREG_Size = this.pageSize;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event) {
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    getBrandName(retcnt) {
      if(retcnt.length == 0) {
        return;
      }
      var siteArray = retcnt.map(citem => {
        return citem.siteName
      });
      reqPost(
        brandapi.base,
        brandapi.brandsbySites,
        this.authToken,
        {
          siteNames: siteArray,
        },
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.sitebrands = result.data.brands;
              this.content = retcnt;
            }
          }
        }
      );
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var all = this.saletype == 'ALL';
      var onSale = this.saletype == 'ONSALE';
      reqGet(
        goodsapi.base,
        goodsapi.goodsadmpage +
        "?keywordType=" +
        this.keywordType +
        "&keyword=" +
        this.keyword +
        "&pageNumber=" +
        pageNumber +
        "&pageSize=" +
        this.pageSize + "&all=" + all + "&onSale=" + onSale,
        this.authToken,
        (result) => {
          this.content = [];
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.getBrandName(result.data.content)
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goRemoveGoods() {
      var checkarray = $('input:checkbox.chitem:checked').map(function() {
        return this.value;
      }).get();
      for(var didx = 0; didx < checkarray.length; didx++) {
        var t_content = this.content;
        t_content = t_content.filter(citem => citem.goodsId != checkarray[didx]);
        this.content = t_content;
        reqPost(
          goodsapi.base,
          goodsapi.goodsdelete,
          this.authToken,
          {
            goodsId: checkarray[didx],
          },
          () => {

          }
        );
      }
    },
    goCopyNewProd(goodsId,goodsType) {
      this.$router.push(this.routes.prdnewreg + "?goodsId=" + goodsId + "&goodsType=" + goodsType + "&copy=" + "copy");
    },
    editbrand(goodid, goodsType) {
      this.$router.push(this.routes.editprodinf + "?goodid=" + goodid + "&goodsType=" + goodsType);
    },
    getSiteBrandName(sitename) {
      var sbidx = this.sitebrands.findIndex(sbitem => sbitem.siteName == sitename);
      if(sbidx > -1) {
        return this.sitebrands[sbidx].brandName;
      } else {
        return "";
      }
    },
    goodsTypeVal(val) {
      return getGoodsTypeStr(val);
    },
    getSaleState(val) {
      if(val) {
        return "판매중";
      } else {
        return "일시중지";
      }
    }
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.checkbox > label {
  margin-right: 10px;
}
</style>
