<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <label for="notProceess" class="mt-2 mr-2" :style="!unprocessedStatus?'font-weight : 500': 'font-weight : 700'"><input type="checkbox" id="notProceess" class="mr-2 mb-2" v-model="unprocessedStatus"> 미처리 상태</label>
          <DetePicker v-model="dateFrom" :last="dateTo"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px" @change="refreshPage">
            <option value="NoKeyword"></option>
            <option value="OrderNo">주문번호</option>
            <option value="B2bOrderNo">B2B 주문번호</option>
            <option value="ItemNo">쿠폰번호</option>
            <option value="BuyerPhoneNumber">구매자 전화번호</option>
            <option value="ReceiverPhoneNumber">수신자 전화번호</option>
            <option value="BuyerPhoneNumber4">구매자 전화번호 끝 4자리</option>
            <option value="ReceiverPhoneNumber4">수신자 전화번호 끝 4자리</option>
            <option value="Username">사용자 ID</option>
            <option value="BuyerName">구매자 이름</option>
            <option value="ReceiverName">수신자 이름</option>
            <option value="B2bOrderNo">B2B주문번호</option>
            <option value="Channel">주문경로</option>
            <option value="GoodsNameContaining">상품명</option>

          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="getExcelData"
              :disabled="excelButtonDisabled">
        <i class="fa fa-download"></i>Excel
      </button>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="b2bExcelDownload()">
        <i class="fa fa-download"></i>B2B 일괄승인양식
      </button>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="openB2BCancel()">
        B2B 일괄승인
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">번호</th>
            <th class="text-center">주문번호</th>
            <th class="text-center">B2B 주문번호</th>
            <th>상품명</th>
            <th class="text-center">구매자</th>
            <th class="text-center">구매수</th>
            <th class="text-center">취소수</th>
            <th class="text-center">취소구분</th>
            <th class="text-center">취소형태</th>
            <th class="text-center">주문경로</th>
            <th class="text-center">B2B결제금액</th>
            <th class="text-center">주문일</th>
            <th class="text-center">신청일</th>
            <th class="text-center">처리일</th>
            <th class="text-center">상태</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(refund, index) in content" v-bind:key="index">
            <td class="text-center">{{ totalElements - (startnum + index) }}</td>
            <td class="linkcmp text-center" @click="goDetailViewPage(refund.orderNo)">{{ refund.orderNo }}</td>
            <td class="text-center">{{ refund.b2bOrderNo }}</td>
            <td class="text-center">{{ refund.goodsName }}</td>
            <td class="text-center">{{ refund.buyerName }}</td>
            <td class="text-center">{{ refund.orderItemCount | numFormat }}</td>
            <td class="text-center">{{ refund.refundItemCount | numFormat }}</td>
            <td class="text-center" v-if="refund.orderItemCount == refund.refundItemCount">전체</td>
            <td class="text-center" v-if="refund.orderItemCount != refund.refundItemCount">부분</td>
            <td class="text-center" v-if="refund.justSetCancellable">취소</td>
            <td class="linkcmp text-center" @click="viewCancelInfo(refund.refundCsNo, refund.buyerPhoneNumber, refund.orderNo)"
                v-if="refund.refundAccount !== null &&!refund.justSetCancellable && refund.refundFeeRequired && refund.completedDate === null">
              환불(수수료)
            </td>
            <td class="linkcmp text-center" @click="viewCancelInfo(refund.refundCsNo, refund.buyerPhoneNumber, refund.orderNo)"
                style="color:red;"
                v-if="refund.refundAccount === null && !refund.justSetCancellable && refund.refundFeeRequired && refund.completedDate === null">
              환불(수수료)
            </td>
            <td class=" text-center"
                v-if="!refund.justSetCancellable && refund.refundFeeRequired && refund.completedDate !== null">환불(수수료)
            </td>
            <td class="linkcmp text-center" @click="viewCancelInfo(refund.refundCsNo, refund.buyerPhoneNumber, refund.orderNo)"
                style="color:red;"
                v-if="refund.refundAccount === null  && !refund.justSetCancellable && !refund.refundFeeRequired && refund.completedDate === null">환불
            </td>
            <td class="linkcmp text-center" @click="viewCancelInfo(refund.refundCsNo, refund.buyerPhoneNumber, refund.orderNo)"
                v-if="refund.refundAccount !== null && !refund.justSetCancellable && !refund.refundFeeRequired && refund.completedDate === null">환불
            </td>
            <td class="text-center"
                v-if="!refund.justSetCancellable && !refund.refundFeeRequired && refund.completedDate !== null">환불
            </td>
            <td class="text-center">{{ refund.b2bChannelCode }}</td>
            <td class="text-center">{{ refund.paymentAmountInput | numFormat }}</td>
            <td class="text-center">{{ viewDate(refund.purchaseDate) }}</td>
            <td class="text-center">{{ viewDate(refund.requestedDate) }}</td>
            <td class="text-center">{{ viewDate(refund.completedDate) }}</td>
            <td class="text-center" v-if="refund.refundCsStatus == 'RequestCancelled'">신청취소</td>
            <td class="text-center" v-if="refund.refundCsStatus == 'RequestDenied'">거절</td>
            <td class="linkcmp text-center"
                v-if="refund.refundCsStatus == 'RequestAccepted' && refund.csProcessedMethod == 'AccountTransfer'"
                @click="viewApprovedRefund(refund.refundCsNo)">환불승인
            </td>
            <td class="text-center"
                v-if="refund.refundCsStatus == 'RequestAccepted' && refund.csProcessedMethod == 'CancelB2bOrder'">취소승인
            </td>
            <td class="text-center" v-if="refund.refundCsStatus == 'Requested' && refund.justSetCancellable == true">
              <select class="form-control" style="width:auto;" @change="showAllowreward(refund.refundCsNo, $event)">
                <option value="">처리</option>
                <option value="cancel">취소승인</option>
                <option value="allow">환불승인</option>
                <option value="unable">불가</option>
              </select>
            </td>
            <td class="text-center" v-if="refund.refundCsStatus == 'Requested' && refund.justSetCancellable == false">
              <select class="form-control" style="width:auto;" @change="showAllowreward(refund.refundCsNo, $event)">
                <option value="">처리</option>
                <option value="allow">환불승인</option>
                <option value="unable">불가</option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage"/>
      </div>
    </div>
    <div class="modal" v-if="cancelinfdlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">취소환불신청정보요약</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <colgroup>
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>정상가</th>
                    <td colspan="3">{{ estimatedinf.listPriceTotal | numFormat }}</td>
                    <th>사용금액</th>
                    <td colspan="3">{{ estimatedinf.usedAmount | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>판매가</th>
                    <td colspan="3">{{ estimatedinf.salePriceTotal | numFormat }}</td>
                    <th>환불가능금액</th>
                    <td colspan="3">{{ estimatedinf.refundableAmount | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>할인금액</th>
                    <td colspan="3">{{ estimatedinf.discountAmount | numFormat }}</td>
                    <!--  여기 위약금이 없음-->
                    <th>환불수수료</th>
                    <td colspan="3"
                        v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee != null">
                      {{
                        parseInt(estimatedinf.refundInfoWithoutFee.estimatedRefundFee) + parseInt(estimatedinf.refundInfoWithoutFee.estimatedBookingPenalty)  | numFormat
                      }}
                    </td>
                    <td colspan="3"
                        v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee == null">
                      {{ 0 }}
                    </td>
                    <td colspan="3"
                        v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee != null">
                      {{
                        parseInt(estimatedinf.refundInfoWithFee.estimatedRefundFee) + parseInt(estimatedinf.refundInfoWithFee.estimatedBookingPenalty) | numFormat
                      }}
                    </td>
                    <td colspan="3"
                        v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee == null">
                      {{ 0 }}
                    </td>
                  </tr>
                  <tr>
                    <th rowspan="4">결제금액</th>
                    <td rowspan="4">{{ estimatedinf.paymentTotal | numFormat }}</td>
                    <th>Agent</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.agentPayment | numFormat }}
                    </td>
                    <td v-else></td>
                    <th rowspan="4">환불금액</th>
                    <td rowspan="4"
                        v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee != null">
                      {{ estimatedinf.refundInfoWithoutFee.estimatedRefundAmount | numFormat }}
                    </td>
                    <td rowspan="4"
                        v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee == null">
                      {{ 0 }}
                    </td>
                    <td rowspan="4"
                        v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee != null">
                      {{ estimatedinf.refundInfoWithFee.estimatedRefundAmount | numFormat }}
                    </td>
                    <td rowspan="4"
                        v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee == null">
                      {{ 0 }}
                    </td>
                    <th>Agent</th>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee != null">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.agentPayment | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee == null">
                      {{ 0 }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee != null">
                      {{ estimatedinf.refundInfoWithFee.paymentRefundDetails.agentPayment | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee == null">
                      {{ 0 }}
                    </td>
                  </tr>
                  <tr>
                    <th>Cash</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.userCash | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>Cash</th>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee != null">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.userCash | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee == null">
                      {{ 0 }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee != null">
                      {{ estimatedinf.refundInfoWithFee.paymentRefundDetails.userCash | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee == null">
                      {{ 0 }}
                    </td>
                  </tr>
                  <tr>
                    <th>Reward</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.userPoint | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>Reward</th>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee != null">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.userPoint | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee == null">
                      {{ 0 }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee != null">
                      {{ estimatedinf.refundInfoWithFee.paymentRefundDetails.userPoint | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee == null">
                      {{ 0 }}
                    </td>
                  </tr>
                  <tr>
                    <th>PointTicket</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.pointTicket | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>PointTicket</th>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee != null">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.pointTicket | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && estimatedinf.refundInfoWithoutFee == null">
                      {{ 0 }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee != null">
                      {{ estimatedinf.refundInfoWithFee.paymentRefundDetails.pointTicket | numFormat }}
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == true && estimatedinf.refundInfoWithFee == null">
                      {{ 0 }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group" v-if="estimatedinf.refundAccount != null">
              <label class="col-sm-4 control-label">환불계좌:</label>
              <div class="hidden-xs col-sm-8"></div>
            </div>
            <div class="form-group" v-if="estimatedinf.refundAccount != null">
              <div class="hidden-xs col-sm-4"></div>
              <div class="col-sm-8">
                <div class="boxarea">
                  {{ estimatedinf.refundAccount.bankName }}
                </div>
              </div>
            </div>
            <div class="form-group" v-if="estimatedinf.refundAccount != null">
              <div class="hidden-xs col-sm-4"></div>
              <div class="col-sm-8">
                <div class="boxarea">{{ estimatedinf.refundAccount.accountNumber }}</div>
              </div>
            </div>
            <div class="form-group" v-if="estimatedinf.refundAccount != null">
              <div class="hidden-xs col-sm-4"></div>
              <div class="col-sm-8">
                <div class="boxarea">{{ estimatedinf.refundAccount.accountHolder }}</div>
              </div>
            </div>
            <div class="form-group" v-if="estimatedinf.refundAccount != null">
              <div class="boxarea" style="width: 100%; background-color: rgba(45,45,45,0.1);">
                <div><span class="text-bold"> 최초 등록 :</span> {{estimatedinf.accountCreateBy}} ( {{ viewDate(estimatedinf.accountCreateDateTime) }} )</div>
                <div><span class="text-bold"> 최종 수정 :</span> {{ estimatedinf.accountUpdateBy }} ( {{ viewDate(estimatedinf.accountUpdateDateTime) }} )</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-group text-center">
              <button type="button" class="btn btn-default" @click="resetAccountChkCount">입력 횟수 초기화</button>
              <button type="button" class="btn btn-default" @click="openResendMessage">문자 재발송</button>
              <button type="button" class="btn btn-default" @click="closeCancelinf">닫기</button>
            </div>
          </div>
          <!-- 재발송 팝업-->
          <div class="modal resendModal" v-if="resenddlg" :style="{'display': 'block'}">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header border-bottom-0">재발송</div>
                <div class="modal-body form-horizontal">
                  <div class="form-group">
                    <div class="radio">
                      <label style="margin-right:20px"><input type="radio" class="pr-2" value="false"
                                                              v-model="useSms">알림톡</label>
                      <label><input type="radio" class="pr-2" value="true" v-model="useSms">SMS전송</label>
                    </div>
                    <br>
                    <div class="form-group">
                      <label class="control-label" style="float:left;">발송번호 : </label>
                      <div class="input-group" style="float:left; margin-left:10px; width:70%;">
                        <input type="text" class="form-control" placeholder="번호 입력시 '-' 은 제외 숫자만 입력해 주세요."
                               v-model="requestPhoneNumber"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div class="text-center">
                    <button type="button" class="btn btn-default" style="margin-right:10px" @click="resendClick">전송
                    </button>
                    <button type="button" class="btn btn-default" @click="closeResenddlg">취소</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="approvedlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">환불승인요약</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <colgroup>
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>정상가</th>
                    <td colspan="3">{{ approvedinf.listPriceTotal | numFormat }}</td>
                    <th>사용금액</th>
                    <td colspan="3">{{ approvedinf.usedAmount | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>판매가</th>
                    <td colspan="3">{{ approvedinf.salePriceTotal | numFormat }}</td>
                    <th>환불가능금액</th>
                    <td colspan="3">{{ approvedinf.refundableAmount | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>할인금액</th>
                    <td colspan="3">{{ approvedinf.discountAmount | numFormat }}</td>
                    <!--여기 위약금-예약취소  -->
                    <th>
                      <span
                        v-if="Number(approvedinf.refundInfo.approvedBookingPenalty) > 0 && approvedinf.refundInfo.approvedBookingPenalty !== null">
                          위약금
                      </span>
                      <span v-else>
                          환불수수료
                      </span>
                    </th>
                    <td colspan="3">
                      <span
                        v-if="Number(approvedinf.refundInfo.approvedBookingPenalty) > 0 && approvedinf.refundInfo.approvedBookingPenalty !== null">
                          {{ approvedinf.refundInfo.approvedBookingPenalty | numFormat }}
                      </span>
                      <span v-else>
                          {{ approvedinf.refundInfo.approvedRefundFee | numFormat }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th rowspan="4">결제금액</th>
                    <td rowspan="4">
                      <span v-if="approvedinf.paymentTotal">
                          {{ approvedinf.paymentTotal | numFormat }}
                      </span>
                      <span v-else>
                          {{ approvedinf.paymentAmountInput | numFormat }}
                      </span>
                    </td>
                    <th>Agent</th>
                    <td v-if="approvedinf.paymentDetails != null">
                      {{ approvedinf.paymentDetails.agentPayment | numFormat }}
                    </td>
                    <td v-else></td>
                    <th rowspan="4">환불금액</th>
                    <td rowspan="4">{{ approvedinf.refundInfo.approvedRefundAmount | numFormat }}</td>
                    <th>Agent</th>
                    <td>{{ approvedinf.refundInfo.paymentRefundDetails.agentPayment | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>Cash</th>
                    <td v-if="approvedinf.paymentDetails != null">
                      {{ approvedinf.paymentDetails.userCash | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>Cash</th>
                    <td>{{ approvedinf.refundInfo.paymentRefundDetails.userCash | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>Reward</th>
                    <td v-if="approvedinf.paymentDetails != null">
                      {{ approvedinf.paymentDetails.userPoint | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>Reward</th>
                    <td>{{ approvedinf.refundInfo.paymentRefundDetails.userPoint | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>PointTicket</th>
                    <td v-if="approvedinf.paymentDetails != null">
                      {{ approvedinf.paymentDetails.pointTicket | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>PointTicket</th>
                    <td>{{ approvedinf.refundInfo.paymentRefundDetails.pointTicket | numFormat }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">환불계좌:</label>
              <div class="hidden-xs col-sm-8"></div>
            </div>
            <div class="form-group">
              <div class="hidden-xs col-sm-4"></div>
              <div class="col-sm-8">
                <div class="boxarea">{{ approvedinf.refundAccount.bankName }}</div>
              </div>
            </div>
            <div class="form-group">
              <div class="hidden-xs col-sm-4"></div>
              <div class="col-sm-8">
                <div class="boxarea">{{ approvedinf.refundAccount.accountNumber }}</div>
              </div>
            </div>
            <div class="form-group">
              <div class="hidden-xs col-sm-4"></div>
              <div class="col-sm-8">
                <div class="boxarea">{{ approvedinf.refundAccount.accountHolder }}</div>
              </div>
            </div>
            <div class="form-group" v-if="approvedinf.refundAccount != null">
              <div class="boxarea" style="width: 100%; background-color: rgba(45,45,45,0.1);">
                <div><span class="text-bold"> 최초 등록 :</span> {{approvedinf.accountCreateBy}} ( {{ viewDate(approvedinf.accountCreateDateTime) }} )</div>
                <div><span class="text-bold"> 최종 수정 :</span> {{ approvedinf.accountUpdateBy }} ( {{ viewDate(approvedinf.accountUpdateDateTime) }} )</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeApproveinf">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 환불승인처리 -->
    <div class="modal" v-if="allowrewarddlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">환불승인처리</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <colgroup>
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                    <col width="12.5%">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>정상가</th>
                    <td colspan="3">{{ estimatedinf.listPriceTotal | numFormat }}</td>
                    <th>사용금액</th>
                    <td colspan="3">{{ estimatedinf.usedAmount | numFormat }}</td>
                  </tr>
                  <tr>
                    <th>판매가</th>
                    <td colspan="3">{{ estimatedinf.salePriceTotal | numFormat }}</td>
                    <th>환불가능금액</th>
                    <td colspan="3" v-if="estimatedinf.paymentTotal != null">
                      {{ estimatedinf.refundableAmount | numFormat }}
                    </td>
                    <td colspan="3" v-if="estimatedinf.paymentTotal == null">{{ getrefundable() | numFormat }}</td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal != null">
                    <!-- b2b주문이아닌 테이블엔조이결제일경우 -->
                    <th>할인금액</th>
                    <td colspan="3">{{ estimatedinf.discountAmount | numFormat }}</td>
                    <th>
                      <span v-if="estimatedinf.bookingPenaltyRate == null">환불수수료</span>
                      <span v-else>위약금</span>
                    </th>
                    <td colspan="3" v-if="estimatedinf.refundFeeRequired">
                      <label style="margin-right:10px">
                        <input type="checkbox" :value="true" v-model="refundRequest.ignoreAllFee"/>
                        <span v-if="estimatedinf.bookingPenaltyRate == null">환불수수료부과 안함</span>
                        <span v-else>위약금부과 안함</span>
                      </label>
                      <!-- ignoreAllFee가 true이면 수수료 부과 안함 -->
                      <template v-if="refundRequest.ignoreAllFee == true">
                        {{
                          Number(estimatedinf.refundInfoWithoutFee.estimatedBookingPenalty) + Number(estimatedinf.refundInfoWithoutFee.estimatedRefundFee) | numFormat
                        }}
                      </template>
                      <template v-if="refundRequest.ignoreAllFee == false">
                        {{
                          Number(estimatedinf.refundInfoWithFee.estimatedBookingPenalty) + Number(estimatedinf.refundInfoWithFee.estimatedRefundFee)   | numFormat
                        }}
                        <!-- {{ estimatedinf.refundInfoWithFee.estimatedBookingPenalty | numFormat }} -->
                      </template>
                    </td>
                    <td colspan="3" v-if="estimatedinf.refundFeeRequired == false">
                      <label style="margin-right:10px">
                        <input type="checkbox" :value="true" v-model="refundRequest.ignoreAllFee" checked disabled/>환불수수료부과
                        안함
                      </label>
                      {{ estimatedinf.refundInfoWithoutFee.estimatedRefundFee | numFormat }}
                    </td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal != null">
                    <th rowspan="4">결제금액</th>
                    <td rowspan="4">{{ estimatedinf.paymentTotal | numFormat }}</td>
                    <th>Agent</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.agentPayment | numFormat }}
                    </td>
                    <td v-else></td>
                    <th rowspan="4">환불금액</th>
                    <td rowspan="4" v-if="estimatedinf.refundFeeRequired">
                                            <span v-if="!refundRequest.ignoreAllFee">
                                                {{ estimatedinf.refundInfoWithFee.estimatedRefundAmount | numFormat }}
                                            </span>
                      <span v-else>
                                                {{
                          estimatedinf.refundInfoWithoutFee.estimatedRefundAmount | numFormat
                        }}
                                            </span>
                    </td>
                    <td rowspan="4" v-if="!estimatedinf.refundFeeRequired && !refundRequest.ignoreAllFee">
                      {{ estimatedinf.refundInfoWithoutFee.estimatedRefundAmount | numFormat }}
                    </td>
                    <th>Agent</th>
                    <td v-if="estimatedinf.refundFeeRequired == true">
                                            <span v-if="!refundRequest.ignoreAllFee">
                                                {{
                                                estimatedinf.refundInfoWithFee.paymentRefundDetails.agentPayment | numFormat
                                              }}
                                            </span>
                      <span v-else>
                                                {{
                          estimatedinf.refundInfoWithoutFee.paymentRefundDetails.agentPayment | numFormat
                        }}
                                            </span>
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && refundRequest.ignoreAllFee == false">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.agentPayment | numFormat }}
                    </td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal != null">
                    <th>Cash</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.userCash | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>Cash</th>
                    <td v-if="estimatedinf.refundFeeRequired == true">
                                            <span v-if="!refundRequest.ignoreAllFee">
                                                {{
                                                estimatedinf.refundInfoWithFee.paymentRefundDetails.userCash | numFormat
                                              }}
                                            </span>
                      <span v-else>
                                                {{
                          estimatedinf.refundInfoWithoutFee.paymentRefundDetails.userCash | numFormat
                        }}
                                            </span>
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && refundRequest.ignoreAllFee == false">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.userCash | numFormat }}
                    </td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal != null">
                    <th>Reward</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.userPoint | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>Reward</th>
                    <td v-if="estimatedinf.refundFeeRequired == true">
                                            <span v-if="!refundRequest.ignoreAllFee">
                                                {{
                                                estimatedinf.refundInfoWithFee.paymentRefundDetails.userPoint | numFormat
                                              }}
                                            </span>
                      <span v-else>
                                                {{
                          estimatedinf.refundInfoWithoutFee.paymentRefundDetails.userPoint | numFormat
                        }}
                                            </span>
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && refundRequest.ignoreAllFee == false">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.userPoint | numFormat }}
                    </td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal != null">
                    <th>PointTicket</th>
                    <td v-if="estimatedinf.paymentDetails != null">
                      {{ estimatedinf.paymentDetails.pointTicket | numFormat }}
                    </td>
                    <td v-else></td>
                    <th>PointTicket</th>
                    <td v-if="estimatedinf.refundFeeRequired == true">
                                            <span v-if="!refundRequest.ignoreAllFee">
                                                {{
                                                estimatedinf.refundInfoWithFee.paymentRefundDetails.pointTicket | numFormat
                                              }}
                                            </span>
                      <span v-else>
                                                {{
                          estimatedinf.refundInfoWithoutFee.paymentRefundDetails.pointTicket | numFormat
                        }}
                                            </span>
                    </td>
                    <td v-if="estimatedinf.refundFeeRequired == false && refundRequest.ignoreAllFee == false">
                      {{ estimatedinf.refundInfoWithoutFee.paymentRefundDetails.pointTicket | numFormat }}
                    </td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal == null">
                    <th rowspan="2">결제금액</th>
                    <td colspan="3" rowspan="2">
                      <input type="number" class="form-control" v-model="paymentAmountInput"/>
                    </td>
                    <th>
                      <span v-if="estimatedinf.bookingPenaltyRate == null">환불수수료</span>
                      <span v-else>위약금</span>
                    </th>
                    <td colspan="3">
                      <label style="margin-right:10px">
                        <input type="checkbox" :value="true" v-model="refundRequest.ignoreAllFee"/>
                        <span v-if="estimatedinf.bookingPenaltyRate == null">환불수수료부과 안함</span>
                        <span v-else>위약금부과 안함</span>
                      </label>{{ getrefundfee(estimatedinf.bookingPenaltyRate) | numFormat }}
                    </td>
                  </tr>
                  <tr v-if="estimatedinf.paymentTotal == null">
                    <th>환불금액</th>
                    <td colspan="3">
                      {{
                        getrefundamount(estimatedinf.bookingPenaltyRate, getrefundfee(estimatedinf.bookingPenaltyRate)) | numFormat
                      }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-3 control-label">환불 예정일</label>
              <div class="col-xs-9" style="display:inline-flex">
                <DetePicker style="margin-right:10px;" v-model="refunddate"/>
                <!-- <div class="input-group">
      <input type="time" class="form-control" style="width:auto;" v-model="refundtime"/>
    </div> -->
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-3 control-label">은행</label>
              <div class="col-xs-9">
                <select class="form-control" style="width:auto;" v-model="refundAccount.bankCode"
                        @change="onChangeBank">
                  <option v-for="bank in banklist" :key="bank.bankCode" :value="bank.bankCode">{{ bank.bankName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-3 control-label">계좌번호</label>
              <div class="col-xs-9">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="refundAccount.accountNumber"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-3 control-label">예금주명</label>
              <div class="col-xs-9">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="refundAccount.accountHolder"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-xs-3 control-label">메모</label>
              <div class="col-xs-9">
                <input type="text" class="form-control" v-model="refundRequest.message"/>
              </div>
            </div>
            <div class="form-group" v-if="estimatedinf.refundAccount != null">
              <div class="boxarea" style="width: 100%; background-color: rgba(45,45,45,0.1);">
                <div><span class="text-bold"> 최초 등록 :</span> {{estimatedinf.accountCreateBy}} ( {{ viewDate(estimatedinf.accountCreateDateTime) }} )</div>
                <div><span class="text-bold"> 최종 수정 :</span> {{ estimatedinf.accountUpdateBy }} ( {{ viewDate(estimatedinf.accountUpdateDateTime) }} )</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeAllowreward">취소</button>
              <button type="button" class="btn btn-default" @click="doRefundRequest" :disabled="buttonDissabled">환불승인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 환불승인처리 end -->
    <div class="modal" v-if="cancelreqdlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">취소승인</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label class="col-xs-3 control-label">메모</label>
              <div class="col-xs-9" style="display:inline-flex">
                <textarea class="form-control" rows="5" v-model="refundmemo"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeCancelReqDlg">닫기</button>
              <button type="button" class="btn btn-default" @click="DoJustSetCancelled" :disabled="buttonDissabled">
                취소승인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="rejectdlg" :style="{'display': 'block'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">불가</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label class="col-xs-3 control-label">거절 사유</label>
              <div class="col-xs-9" style="display:inline-flex">
                <textarea class="form-control" rows="5" v-model="refundcomment"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closerejectdlg">닫기</button>
              <button type="button" class="btn btn-default" @click="Doreject" :disabled="buttonDissabled">불가</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="b2bCancelModal" style="display: block; ">
      <div class="modal-dialog modal-xl" style="width: 98%;">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">B2B 일괄승인</div>
          <div class="modal-body form-horizontal" style="max-height: 700px; overflow-y: auto; overflow-x: auto">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th class="text-center">취소요청번호</th>
                  <th class="text-center">주문번호</th>
                  <th class="text-center">상품명</th>
                  <th class="text-center">구매수</th>
                  <th class="text-center">취소수</th>
                  <th class="text-center">취소구분</th>
                  <th class="text-center">취소형태</th>
                  <th class="text-center">판매가</th>
                  <th class="text-center">B2B 결제금액</th>
                  <th class="text-center">사용금액</th>
                  <th class="text-center">환불수수료 부과</th>
                  <th class="text-center">환불금액</th>
                  <th class="text-center">환불예정일</th>
                  <th class="text-center">처리 가능여부</th>
                  <th class="text-center">처리 결과</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="(items,i) in b2bExcelUploadData" :key="i">
                  <td>{{ items.refundCsNo }}</td>
                  <td>{{ items.orderNo }}</td>
                  <td>{{ items.goodsName }}</td>
                  <td>{{ items.orderItemCount }}</td>
                  <td>{{ items.refundItemCount }}</td>
                  <td>{{ items.refundGubun }}</td>
                  <td>{{ items.refundType }}</td>
                  <td>{{ items.sellPrice }}</td>
                  <td>{{ items.b2bsellPrice }}</td>
                  <td>{{ items.usedAmount }}</td>
                  <td>{{ items.refundFeeRequired }}</td>
                  <td>{{items.refundableAmount}}</td> <!--여기 금액 바뀌어야함-->
                  <td>{{ items.requestedExpectDate }}</td>
                  <td :class="!items.refundableYN? 'text-danger':'text-success'">{{ !items.refundableYN ? '불가' : '가능' }}</td>
                  <td :class="!items.resultCancel? 'text-danger':'text-success'">{{ items.resultCancel==false ? '실패' : items.resultCancel==true?'완료':'' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default margin-r-5" @click="closeB2BCancel">닫기</button>
              <input type="file" ref="fileInput" style="display:none;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12"  title="파일선택" alt="파일선택" @change="onUploadFiles"/>
              <button v-if="b2bExcelUploadData.length===0" type="button" class="btn btn-default" @click="selectExcel" >파일 업로드</button>
              <button v-else type="button" class="btn btn-default" @click="b2bCancelProcess" >일괄처리</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  rescode,
  menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import DetePicker from "../../components/DatePicker";
import {
  reqPost,
  reqGet, post
} from "../../lib/request";
import {
  orderapi,
  brandapi, b2bapi, payment
} from "../../lib/api";
import {
  DateFormat
} from "../../lib/utility";
import moment from 'moment';
import XLSX from "xlsx";
import numeral from "numeral";

export default {
  components: {
    Pagination,
    DetePicker,
  },
  created() {
    if (localStorage.initval == "1") {
      if (localStorage.ORDC_keywordType) {
        this.keywordType = localStorage.ORDC_keywordType;
      }
      if (localStorage.ORDC_keyword) {
        this.keyword = localStorage.ORDC_keyword;
      }
      if (localStorage.ORDC_Number) {
        this.pageNumber = parseInt(localStorage.ORDC_Number);
      }
      if (localStorage.ORDC_Size) {
        this.pageSize = parseInt(localStorage.ORDC_Size);
      }
      if (localStorage.ORDC_dateFrom) {
        this.dateFrom = localStorage.ORDC_dateFrom;
      }
      if (localStorage.ORDC_dateTo) {
        this.dateTo = localStorage.ORDC_dateTo;
      }
      if (localStorage.ORDC_unprocessedStatus) {
        this.unprocessedStatus = JSON.parse(localStorage.ORDC_unprocessedStatus);
      }
    }
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_ORD,
      path2: menuval.LV1_HOME,
      title: "취소요청조회"
    });
  },
  watch: {
    keywordType(newVal) {
      localStorage.ORDC_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.ORDC_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.ORDC_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.ORDC_Size = newsize;
    },
    dateFrom(newfrom) {
      localStorage.ORDC_dateFrom = newfrom;
    },
    dateTo(newto) {
      localStorage.ORDC_dateTo = newto;
    },
    unprocessedStatus(newBool) {
      localStorage.ORDC_unprocessedStatus = newBool;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      dateFrom: moment().format("YYYY-MM-DD"), //moment().format("YYYY-01-01"),
      dateTo: moment().format("YYYY-MM-DD"),
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * refundCsNo               Number    CS 접수 번호
       * orderNo                  String    주문 번호
       * b2bOrderNo               String    B2B 주문 번호
       * goodsName                String    상품명
       * goodsType                String    [MobileCoupon, BookingCoupon, Booking, MoneyCoupon, CashCoupon] 상품타입
       * siteName                 String    테이블엔조이 사이트에서 구매한 경우 구매 사이트명
       * b2bChannelCode           String    B2B 채널에서 구매한 경우 B2B 채널코드
       * username                 String    사용자 ID
       * buyerName                String    구매자 이름
       * buyerPhoneNumber         String    구매자 전화번호
       * orderItemCount           Number    주문 아이템 수
       * refundItemCount          Number    취소/환불 요청 아이템 수
       * listPriceTotal           String    취소/환불 요청 아이템 정상가격 합계
       * salePriceTotal           String    취소/환불 요청 아이템 판매가격 합계
       * usedAmount               String    사용한 금액
       * refundableAmount         String    환불 가능 금액. B2B 주문일경우 null
       * estimatedRefundAmount    String    시스템 추정 환불 금액. B2B 주문일경우 null
       * estimatedRefundFee       String    시스템 추정 환불 수수료. B2B 주문일경우 null
       * currencyCode             String    [USD, KRW] 통화코드
       * refundFeeRequired        Boolean   주문 관리자에 의해 환불(수수료)로 CS 생성된 경우 true
       * justSetCancellable       Boolean   단순 취소 처리가 가능한 경우 true
       * purchaseDate             String    UTC yyyy-MM-ddTHH:mm:ss 구매일자
       * requestedDate            String    UTC yyyy-MM-ddTHH:mm:ss 취소 요청 일자
       * completedDate            String    UTC yyyy-MM-ddTHH:mm:ss CS 처리 완료 일자
       * refundCsStatus           String    [Requested, RequestCancelled, RequestDenied, RequestAccepted] CS 처리 상태 Requested: 취소요청됨,RequestCancelled: 취소요청 철회함,RequestDenied: 취소요청 거절됨,RequestAccepted: 취소요청 승인됨
       * csProcessedMethod        String    [AccountTransfer, CancelB2bOrder]환불 처리 방법 AccountTransfer: 계좌이체 환불 처리, CancelB2bOrder: B2B 주문 단순취소 처리 (refundCsStatus가 RequestAccepted일 경우 값이 입력됨)
       * refundAccount            Object    고객 또는 주문 관리자에 의해 최초 입력된 환불계좌 정보. 입력이 없는 경우 null
       * refundAccount.bankCode   String    은행코드
       * refundAccount.bankName   String    은행명
       * refundAccount.accountHolder    String    예금주
       * refundAccount.accountNumber    String    계좌번호
       * updatedRefundAccount     Object    CS 관리자에 의해 수정된 환불계좌 정보. 수정이 없는 경우 null
       * updatedRefundAccount.bankCode  String    은행코드
       * updatedRefundAccount.bankName  String    은행명
       * updatedRefundAccount.accountHolder String  예금주
       * updatedRefundAccount.accountNumber String  계좌번호
       * requestReason            String    취소 요청 사유
       * csMemo                   String    CS 담당자 메모
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true

      cancelinfdlg: false, //취소환불신청정보요약
      approvedlg: false, //환불승인요약
      allowrewarddlg: false, //환불승인처리
      cancelreqdlg: false, //취소승인 메모
      rejectdlg: false, //불가 메모

      estimatedinf: null,
      /**
       * refundCsNo                 Number    CS 접수 번호
       * listPriceTotal             String    취소/환불 요청 아이템 정상가격 합계
       * salePriceTotal             String    취소/환불 요청 아이템 판매가격 합계
       * discountAmount             String    할인 금액
       * paymentTotal               String    결제금액 합계. B2B 주문일경우 null
       * usedAmount                 String    사용한 금액
       * refundableAmount           String    환불 가능 금액. B2B 주문일경우 null
       * currencyCode               String    [USD, KRW] 통화코드
       * paymentDetails             Object    잔여 결제 상세 정보. B2B 주문일경우 null
       * paymentDetails.agentPayment    String  PG 또는 결제 Agent 업체를 통해 결제한 금액(이전에 환불된 금액을 제외한 잔여금액)
       * paymentDetails.userCash        String  사용자 캐시를 이용하여 결제한 금액(이전에 환불된 금액을 제외한 잔여금액)
       * paymentDetails.userPoint       String  사용자 포인트를 이용하여 결제한 금액(이전에 환불된 금액을 제외한 잔여금액)
       * refundInfoWithoutFee     Object  환불수수료가 부과되지 않을 경우 환불금액 정보. B2B 주문일경우 null
       * refundInfoWithoutFee.estimatedRefundFee      String  시스템 추정 환불 수수료
       * refundInfoWithoutFee.estimatedRefundAmount   String  시스템 추정 환불 금액
       * refundInfoWithoutFee.paymentRefundDetails.agentPayment   String  PG 또는 결제 Agent 업체를 통해 결제된 금액에서 환불될 금액(계좌이체를 통해 수동 환불 처리)
       * refundInfoWithoutFee.paymentRefundDetails.userCash       String  사용자 캐시를 이용하여 결제한 금액에서 환불될 금액(시스템을 통해 자동 환불)
       * refundInfoWithoutFee.paymentRefundDetails.userPoint      String  사용자 포인트를 이용하여 결제한 금액에서 환불될 금액(시스템을 통해 자동 환불)
       * refundInfoWithFee        Object  환불수수료가 부과될 경우 환불금액 정보. 환불수수료가 없거나 B2B 주문일경우 null
       * refundInfoWithFee.estimatedRefundFee       String    시스템 추정 환불 수수료
       * refundInfoWithFee.estimatedRefundAmount    String    시스템 추정 환불 금액
       * refundInfoWithFee.paymentRefundDetails.agentPayment    String  PG 또는 결제 Agent 업체를 통해 결제된 금액에서 환불될 금액(계좌이체를 통해 수동 환불 처리)
       * refundInfoWithFee.paymentRefundDetails.userCash        String  사용자 캐시를 이용하여 결제한 금액에서 환불될 금액(시스템을 통해 자동 환불)
       * refundInfoWithFee.paymentRefundDetails.userPoint       String  사용자 포인트를 이용하여 결제한 금액에서 환불될 금액(시스템을 통해 자동 환불)
       * refundAccount                  Object  환불계좌 정보. 입력이 없는 경우 null
       * refundAccount.bankCode         String  은행코드
       * refundAccount.bankName         String  은행명
       * refundAccount.accountHolder    String  예금주
       * refundAccount.accountNumber    String  계좌번호
       * refundFeeRequired              Boolean 주문 관리자에 의해 환불(수수료)로 CS 생성된 경우 true
       */
      approvedinf: null,
      /**
       * refundCsNo         Number    CS 접수 번호
       * listPriceTotal     String    취소/환불 요청 아이템 정상가격 합계
       * salePriceTotal     String    취소/환불 요청 아이템 판매가격 합계
       * discountAmount     String    할인 금액
       * paymentTotal       String    결제금액 합계. B2B 주문일경우 null
       * usedAmount         String    사용한 금액
       * refundableAmount   String    환불 가능 금액. B2B 주문일경우 null
       * currencyCode       String    [USD, KRW] 통화코드
       * paymentDetails     Object    잔여 결제 상세 정보. B2B 주문일경우 null
       * paymentDetails.agentPayment      String    PG 또는 결제 Agent 업체를 통해 결제한 금액 (이전에 환불된 금액을 제외한 잔여금액)
       * paymentDetails.userCash          String    사용자 캐시를 이용하여 결제한 금액 (이전에 환불된 금액을 제외한 잔여금액)
       * paymentDetails.userPoint         String    사용자 포인트를 이용하여 결제한 금액 (이전에 환불된 금액을 제외한 잔여금액)
       * paymentAmountInput     String    환불승인시 관리자가 입력한 고객 결제금액
       * refundInfo         Object    환불승인 정보
       * refundInfo.approvedRefundFee           String    승인된 환불 수수료
       * refundInfo.approvedRefundAmount        String    승인된 환불 금액
       * refundInfo.paymentRefundDetails.agentPayment     String  계좌이체를 통해 수동 환불된 금액
       * refundInfo.paymentRefundDetails.userCash         String  사용자 캐시에서 환불된 금액 (시스템을 통해 자동 환불)
       * refundInfo.paymentRefundDetails.userPoint        String  사용자 포인트에서 환불된 금액 (시스템을 통해 자동 환불)
       * refundAccount      Object    환불계좌 정보
       * refundAccount.bankCode         String  은행코드
       * refundAccount.bankName         String  은행명
       * refundAccount.accountHolder    String  예금주
       * refundAccount.accountNumber    String  계좌번호
       */
      stateaction: "",
      refundrefundCsNo: "",
      paymentAmountInput: 0,
      refundRequest: {
        ignoreAllFee: true,
        scheduledDate: "",
        message: "",
      },
      refundAccount: {
        bankCode: "",
        bankName: "",
        accountHolder: "",
        accountNumber: "",
      },
      refundcomment: "",
      refundmemo: "",
      refunddate: moment().format("YYYY-MM-DD"),
      refundtime: "00:00",
      banklist: [],
      /**
       * bankCode       String    은행 코드
       * bankName       String    은행명
       */
      exceldata: [],
      excelButtonDisabled: false,
      buttonDissabled: false,
      resenddlg: false,
      requestPhoneNumber: "",
      useSms: false,
      unprocessedStatus:false,
      b2bCancelModal: false,
      b2bExceldata: [],
      b2bExcelUploadData: [],
      tmpB2bExcelUploadData: [],
      refreshFlag: false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if (localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.ORDC_keywordType = this.keywordType;
      localStorage.ORDC_keyword = this.keyword;
      localStorage.ORDC_Number = this.pageNumber;
      localStorage.ORDC_Size = this.pageSize;
      localStorage.ORDC_dateFrom = this.dateFrom;
      localStorage.ORDC_dateTo = this.dateTo;
      localStorage.ORDC_unprocessedStatus = this.unprocessedStatus;


    }
    this.getBankList();
    this.refreshPage();
  },
  methods: {
    numformatMethods(num) {
      return numeral(num).format('0,0');
    },
    getExcelData() {
      reqPost(
        orderapi.base,
        orderapi.refundCsList,
        this.authToken,
        {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.exceldata = result.data;
              this.downExcel();
            } else {
              this.exceldata = [];
              alert(result.message);
            }
          }
        }
      );
    },
    downExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "번호",
            B: "주문번호",
            C: "B2B 주문번호",
            D: "상품명",
            E: "구매자",
            F: "구매자 번호",
            G: "은행명",
            H: "계좌번호",
            I: "예금주",
            J: "구매수",
            K: "취소수",
            L: "취소구분",
            M: "취소형태",
            N: "주문경로",
            T: "판매가",
            O: "B2B결제금액",
            P: "주문일",
            Q: "신청일",
            R: "처리일",
            S: "상태",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelheader, this.exceldata.map(this.dataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "취소요청조회");
      // export Excel file
      XLSX.writeFile(wb, "취소요청조회.xlsx");
    },
    dataconvert(order, idx) {
      return {
        A: idx + 1,
        B: order.orderNo,
        C: order.b2bOrderNo,
        D: order.goodsName,
        E: order.buyerName,
        F: order.buyerPhoneNumber,
        G: !order.refundAccount ? '': order.refundAccount.bankName,
        H: !order.refundAccount ? '': order.refundAccount.accountNumber,
        I: !order.refundAccount ? '': order.refundAccount.accountHolder,
        J: this.numformatMethods(order.orderItemCount),
        K: this.numformatMethods(order.refundItemCount),
        L: order.orderItemCount === order.refundItemCount ? '전체' : '부분',
        M: this.justSetCancellableText(order.justSetCancellable, order.refundFeeRequired, order.completedDate),
        N: order.b2bChannelCode,
        T: this.numformatMethods(order.salePriceTotal),
        O: this.numformatMethods(order.paymentAmountInput),
        P: this.viewDate(order.purchaseDate),
        Q: this.viewDate(order.requestedDate),
        R: this.viewDate(order.completedDate),
        S: this.refundCsStatusText(order.refundCsStatus, order.csProcessedMethod),
      };
    },
    refundCsStatusText(refundCsStatus, csProcessedMethod) {
      if (refundCsStatus == 'RequestCancelled') {
        return '신청취소';
      } else if (refundCsStatus == 'RequestDenied') {
        return '거절';
      } else if (refundCsStatus == 'RequestAccepted' && csProcessedMethod == 'AccountTransfer') {
        return '환불승인';
      } else if (refundCsStatus == 'RequestAccepted' && csProcessedMethod == 'CancelB2bOrder') {
        return '취소승인';
      } else if (refundCsStatus == 'Requested') {
        return '';
      }
    },
    justSetCancellableText(justSetCancellable, refundFeeRequired, completedDate) {
      if (!justSetCancellable && refundFeeRequired && completedDate === null) {
        return '환불(수수료)';
      } else if (!justSetCancellable && refundFeeRequired && completedDate !== null) {
        return '환불(수수료)';
      } else if (!justSetCancellable && !refundFeeRequired) {
        return '환불';
      } else if (justSetCancellable){
        return '취소';
      }
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if (!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      reqPost(
        orderapi.base,
        orderapi.refundCspage,
        this.authToken, {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          orderStatus: this.orderStatus,
          pageNumber: pageNumber,
          pageSize: this.pageSize,
          unprocessedStatus: this.unprocessedStatus
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    goDetailViewPage(orderNo) {
      this.$router.push(this.routes.detailorder + "?orderNo=" + orderNo);
    },
    closeCancelinf() {
      this.cancelinfdlg = false;
    },
    openResendMessage() {
      this.resenddlg = true;
    },
    closeResenddlg() {
      this.useSms = false;
      this.resenddlg = false;
    },
    showCancelinf() {
      this.cancelinfdlg = true;
    },
    closeApproveinf() {
      this.approvedlg = false;
    },
    closeCancelReqDlg() {
      this.cancelreqdlg = false;
    },
    closerejectdlg() {
      this.rejectdlg = false;
    },
    closeAllowreward() {
      this.allowrewarddlg = false;
    },
    resendClick() {

      const msg = "유효하지 않은 핸드폰 번호입니다. 다시 한번 확인 해주세요.";
      const regex = /^01(0|1|[6-9])[0-9]{3,4}[0-9]{4}$/;

      if (!regex.test(this.requestPhoneNumber)) {
        alert(msg);
        return false;
      }

      reqPost(
        orderapi.base,
        orderapi.refundResendMessage,
        this.authToken, {
          refundCsNo: this.refundrefundCsNo,
          phoneNumber: this.requestPhoneNumber,
          useSms: this.useSms
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.requestPhoneNumber = "";
              alert("발송완료");
              this.useSms = false;
              this.closeResenddlg();
              this.closeCancelinf();
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    doRefundRequest() {
      this.buttonDissabled = true;
      // 240607 취소요청조회와는 별개로 도는 작업 진행

      //체크박스는 체크가 true 인데 백엔드에서 반대로 받고있음 순서 바꿔서 보내야함
      this.refundRequest.scheduledDate = moment(this.refunddate).format("YYYY-MM-DD");
      var reqdata = {
        refundCsNo: this.refundrefundCsNo,
        paymentAmountInput: this.paymentAmountInput,
        refundRequest: this.refundRequest,
        refundAccount: this.refundAccount,
        memo: ""
      };
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.byAccountTransfer,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              this.closeAllowreward();
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    showAllowreward(refundCsNo, event) {
      this.stateaction = event.target.value;
      this.refundrefundCsNo = refundCsNo;
      this.refundcomment = "";
      this.refundAccount.bankCode = "";
      this.refundAccount.bankName = "";
      this.refundAccount.accountHolder = "";
      this.refundAccount.accountNumber = "";
      this.refundRequest.message = "";
      this.refunddate = "";
      this.refundmemo = "";
      if (this.stateaction == "cancel") { //취소승인
        this.cancelreqdlg = true;
      } else if (this.stateaction == "allow") { //환불승인
        this.getestimatedRefund();
      } else if (this.stateaction == "unable") { //불가
        this.rejectdlg = true;
      }
    },
    viewCancelInfo(refundCsNo, buyerPhoneNumber, orderNo) {
      this.requestPhoneNumber = buyerPhoneNumber;
      this.refundrefundCsNo = refundCsNo;
      reqPost(
        orderapi.base,
        orderapi.estimatedRefundAmount,
        this.authToken, {
          refundCsNo: refundCsNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.estimatedinf = result.data;
              this.estimatedinf.orderNo = orderNo
              this.cancelinfdlg = true;
            } else {
              this.estimatedinf = null;
              alert(result.message);
            }
          }
        }
      );
    },
    viewApprovedRefund(refundCsNo) {
      reqPost(
        orderapi.base,
        orderapi.approvedRefundAmount,
        this.authToken, {
          refundCsNo: refundCsNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.approvedinf = result.data;
              this.approvedlg = true;
            } else {
              this.approvedinf = null;
              alert(result.message);
            }
          }
        }
      );
    },
    DoJustSetCancelled() {
      this.buttonDissabled = true;
      var reqdata = {
        refundCsNo: this.refundrefundCsNo,
        memo: this.refundmemo
      };
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.byJustSetCancelled,
        this.authToken,
        reqdata,
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.refundmemo = "";
              this.buttonDissabled = false;
              this.closeCancelReqDlg();
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    getestimatedRefund() {
      this.paymentAmountInput = '';
      reqPost(
        orderapi.base,
        orderapi.estimatedRefundAmount,
        this.authToken, {
          refundCsNo: this.refundrefundCsNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.estimatedinf = result.data;
              if (this.estimatedinf.refundAccount != null) {
                this.refundAccount = this.estimatedinf.refundAccount;
              }
              this.refundRequest.ignoreAllFee = false;
              console.log(this.refundRequest.ignoreAllFee)
              if(this.estimatedinf.refundFeeRequired == false){
                this.refundRequest.ignoreAllFee = true
              }
              this.allowrewarddlg = true;
            } else {
              this.estimatedinf = null;
              alert(result.message);
            }
          }
        }
      );
    },
    Doreject() {
      this.buttonDissabled = true;
      this.$store.dispatch('setDisabledLayout');
      reqPost(
        orderapi.base,
        orderapi.refundCsreject,
        this.authToken, {
          refundCsNo: this.refundrefundCsNo,
          rejectComment: this.refundcomment,
        },
        (result) => {
          if (result != null) {
            this.$store.dispatch('setDisabledLayout');
            if (result.code == rescode.REQ_SUCCESS) {
              this.refundcomment = "";
              this.refundmemo = "";
              this.buttonDissabled = false;
              this.closerejectdlg();
              this.refreshPage();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    getBankList() {
      reqGet(
        brandapi.base,
        brandapi.banklist,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.banklist = result.data.banks;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onChangeBank() {
      var tidx = this.banklist.findIndex(bitem => bitem.bankCode == this.refundAccount.bankCode);
      if (tidx > -1) {
        this.refundAccount.bankName = this.banklist[tidx].bankName;
      }
    },
    getrefundable() { //환불가능금액
      // return Math.floor((정상가 – 사용금액) x 결제금액 / 정상가);
      // console.log(Math.floor((parseInt(this.estimatedinf.listPriceTotal) - parseInt(this.estimatedinf.usedAmount)) * parseInt(this.paymentAmountInput) / parseInt(this.estimatedinf.listPriceTotal)))
      return Math.floor((parseInt(this.estimatedinf.listPriceTotal) - parseInt(this.estimatedinf.usedAmount)) * parseInt(this.paymentAmountInput) / parseInt(this.estimatedinf.listPriceTotal));
    },
    getrefundfee(bookingPenaltyRate) { //환불수수료

      if (bookingPenaltyRate === null) {
        if (!this.refundRequest.ignoreAllFee) {
          return Math.floor(this.getrefundable() * 0.1);
        } else {
          return 0;
        }
      } else if (bookingPenaltyRate !== undefined && bookingPenaltyRate !== null) {
        if (!this.refundRequest.ignoreAllFee) {
          console.log(this.getrefundable() * ((100 - Number(bookingPenaltyRate)) / 100))
          return Math.floor(this.getrefundable() * ((Number(bookingPenaltyRate)) / 100));
        } else {
          return 0;
        }
      }
    },
    getrefundamount(bookingPenaltyRate, ppl) { //환불금액
      if (bookingPenaltyRate === null) {
        // return this.getrefundable() - this.getrefundfee();
        if (!this.refundRequest.ignoreAllFee) {
          return this.getrefundable() - ppl;
        } else {
          return this.getrefundable();
        }
      } else if (bookingPenaltyRate !== undefined && bookingPenaltyRate !== null) {
        if (!this.refundRequest.ignoreAllFee) {
          return this.getrefundable() - ppl;
        } else {
          return this.getrefundable();
        }
      }
    },
    openB2BCancel(){
      this.b2bCancelModal = true;
    },
    closeB2BCancel(){
      this.b2bExcelUploadData=[];
      this.tmpB2bExcelUploadData=[];
      this.b2bCancelModal = false;
      if(this.refreshFlag){
        this.refreshFlag = false;
        this.refreshPage();
      }
    },
    selectExcel() {
      this.$refs.fileInput.click();
    },
    b2bExcelDownload(){
      this.excelButtonDisabled = true
      this.b2bExceldata = []
      reqPost(
        orderapi.base,
        orderapi.b2bRefundForm,
        this.authToken, {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          keywordType: this.keywordType,
          keyword: this.keyword,
          unprocessedStatus: this.unprocessedStatus
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              let resultData = result.data
              console.log(resultData)
              resultData.forEach((item , idx)=>{
                if(item.channelType ==='B2bChannel' && item.refundCsStatus =='Requested' ){
                  this.b2bExceldata.push(item)
                }
              })
              // refundCsStatus
              // this.b2bExceldata = result.data.content;

              console.log(this.b2bExceldata)
              this.downB2BExcel();
            } else {
              this.b2bExceldata = [];
              this.excelButtonDisabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    downB2BExcel() {
      var excelheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "취소요청번호",
            B: "주문번호",
            C: "B2B 주문번호",
            D: "상품명",
            E: "구매자",
            F: "구매자 번호",
            G: "은행명",
            H: "계좌번호",
            I: "예금주",
            J: "구매수",
            K: "취소수",
            L: "취소구분",
            M: "취소형태",
            N: "주문경로",
            O: "판매가",
            P: "B2B 결제금액",
            Q: "사용금액",
            R: "환불수수료 부과",
            S: "주문일",
            T: "신청일",
            U: "환불 예정일",
            V: "메모",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(excelheader, this.b2bExceldata.map(this.b2bDataconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, excelheader, "B2B 일괄승인양식");
      // export Excel file
      XLSX.writeFile(wb, "B2B 일괄승인양식.xlsx");
      this.excelButtonDisabled = false;
    },
    b2bDataconvert(order, idx) {
      console.log(order)
      return {
        A: order.refundCsNo,
        B: order.orderNo,
        C: order.b2bOrderNo,
        D: order.goodsName,
        E: order.buyerName,
        F: order.buyerPhoneNumber,
        G: !order.refundAccount ? '': order.refundAccount.bankName,
        H: !order.refundAccount ? '': order.refundAccount.accountNumber,
        I: !order.refundAccount ? '': order.refundAccount.accountHolder,
        J: this.numformatMethods(order.orderItemCount),
        K: this.numformatMethods(order.refundItemCount),
        L: order.orderItemCount === order.refundItemCount ? '전체' : '부분',
        M: this.justSetCancellableText(order.justSetCancellable, order.refundFeeRequired, order.completedDate),
        N: order.b2bChannelCode,
        O: this.numformatMethods(order.salePriceTotal),
        P: '0',
        Q: order.usedAmount,
        R: !order.refundFeeRequired? 'N' : 'Y',
        S: this.viewDate(order.purchaseDate),
        T: this.viewDate(order.requestedDate),
        U: '',
        V: '',
      };
    },
    async onUploadFiles(e){
      this.b2bExceldata = []
      this.b2bExcelUploadData = []
      this.tmpB2bExcelUploadData = []
      const files = e.target.files;
      if (!files.length) {
        return ;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert("양식이 올바르지 않습니다.");
      }
      const fileReader = new FileReader();
      this.selfilename = files[0].name;
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          // const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
            cellDates: true,
            dateNF: 'YYYY-MM-DD'
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {header:"A", raw: false}); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet

          const excellist = [];  // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.exceltoInData(excellist);
          let tmpExcelsData = this.tmpB2bExcelUploadData.splice(1,this.tmpB2bExcelUploadData.length-1).filter(item=>!!item.refundCsNo);
          console.log(tmpExcelsData)

          let tmpItems = async () => {
            for ( let idx = 0; idx < tmpExcelsData.length; idx++) {
              let item = tmpExcelsData[idx];
              const result = await post(orderapi.base, orderapi.estimatedRefundAmount, this.authToken, {refundCsNo: item.refundCsNo});
              if (result != null) {
                item.refundAccount = !result.data.refundAccount ? null : result.data.refundAccount
                let refundValue = 0;
                let paneltyValue = 0;
                if (!result.data.bookingPenaltyRate) {
                  refundValue = Math.floor((parseInt(result.data.listPriceTotal.replace(/,/g, "")) - parseInt(item.usedAmount.replace(/,/g, ""))) * parseInt(item.b2bsellPrice.replace(/,/g, "")) / parseInt(result.data.listPriceTotal.replace(/,/g, "")));
                  paneltyValue = Math.floor(refundValue * 0.1)
                  if (item.refundFeeRequired === "Y") {
                    item.refundableAmount = refundValue - paneltyValue
                  } else {
                    item.refundableAmount = refundValue
                  }
                } else {
                  refundValue = Math.floor((parseInt(result.data.listPriceTotal.replace(/,/g, "")) - parseInt(item.usedAmount.replace(/,/g, ""))) * parseInt(item.b2bsellPrice.replace(/,/g, "")) / parseInt(result.data.listPriceTotal.replace(/,/g, "")))
                  paneltyValue = Math.floor(((parseInt(result.data.listPriceTotal.replace(/,/g, "")) - parseInt(item.usedAmount.replace(/,/g, ""))) * parseInt(item.b2bsellPrice.replace(/,/g, "")) / parseInt(result.data.listPriceTotal.replace(/,/g, ""))) * ((Number(result.data.bookingPenaltyRate)) / 100));
                  if (item.refundFeeRequired === "Y") {
                    item.refundableAmount = refundValue - paneltyValue
                  } else {
                    item.refundableAmount = refundValue
                  }
                }
                if (result.data.paymentTotal !== null || !item.b2bChannelCode || result.data.refundCsStatus !== 'Requested' || item.b2bsellPrice == '0' || !item.refundCsNo || !item.refundFeeRequired || !item.csMemo || !item.requestedExpectDate) {
                  item.refundableYN = false;
                } else {
                  item.refundableYN = true;
                }
                this.b2bExcelUploadData.push(item)
                this.$forceUpdate()
              }
            }
            let promised = new Promise((resolve) =>{setTimeout(()=>resolve("처리가 완료되었습니다."), 1000)} );
            promised.then(
              result=>alert(result)
            )
          }
          tmpItems()

        } catch (e) {
          return alert("양식이 올바르지 않습니다.");
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    exceltoInData(excellist) {
      this.tmpB2bExcelUploadData = excellist.map((titem) => {
        return {
          // date:DateFormat(titem.B, "YYYY-MM-DD"),//날짜
          refundCsNo: titem.A,
          orderNo: titem.B,
          b2bOrderNo: titem.C,
          goodsName: titem.D,
          buyerName: titem.E,
          buyerPhoneNumber: titem.F,
          refundBankName: titem.G,
          refundBankNumber: titem.H,
          refundBankHolder: titem.I,
          orderItemCount: titem.J,
          refundItemCount: titem.K,
          refundGubun: titem.L,
          refundType: titem.M,
          b2bChannelCode: titem.N,
          sellPrice: titem.O,
          b2bsellPrice: titem.P,
          usedAmount: titem.Q,
          refundFeeRequired: titem.R,
          purchaseDate:DateFormat(titem.S, "YYYY-MM-DD HH:mm:ss"),
          requestedDate:DateFormat(titem.T, "YYYY-MM-DD HH:mm:ss"),
          requestedExpectDate:DateFormat(titem.U, "YYYY-MM-DD"),
          csMemo: titem.V,
        }
      });
      this.excelview = true;
    },
    async b2bCancelProcess(){
      let tmpExcelsData = this.b2bExcelUploadData;
      let tmpItems = async () => {
        for ( let idx = 0; idx < tmpExcelsData.length; idx++) {
          let item = tmpExcelsData[idx];
          if(item.refundableYN) {
            let reqData = {
              paymentAmountInput: +item.b2bsellPrice.replace(/,/g, ""),
              refundAccount: item.refundAccount,
              refundCsNo: +item.refundCsNo,
              refundRequest: {
                ignoreAllFee: item.refundFeeRequired === 'N' ? true : false, //위약금 여부
                message: item.csMemo, //엑셀상 메모
                scheduledDate: moment(item.requestedExpectDate).format("YYYY-MM-DD") //환불 예정일
              }
            }
            const result = await post(orderapi.base, orderapi.byAccountTransfer, this.authToken, reqData);
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.buttonDissabled = false;
                this.b2bExcelUploadData[idx].resultCancel = true
                this.$forceUpdate();
              } else {
                this.b2bExcelUploadData[idx].resultCancel = false
                this.buttonDissabled = false;
                this.$forceUpdate();
                // alert(result.message);
              }
              this.refreshFlag = true;
            }
          }
        }
      }
      tmpItems();
      // tmpExcelsData.forEach((item,idx) => {
      //   if(item.refundableYN){
      //     let reqData = {
      //       paymentAmountInput: +item.b2bsellPrice.replace(/,/g, ""),
      //       refundAccount:item.refundAccount,
      //       refundCsNo: +item.refundCsNo,
      //       refundRequest:{
      //         ignoreAllFee:item.refundFeeRequired==='N'? true : false, //위약금 여부
      //         message:item.csMemo, //엑셀상 메모
      //         scheduledDate:moment(item.requestedExpectDate).format("YYYY-MM-DD") //환불 예정일
      //       }
      //     }
      //     reqPost(
      //       orderapi.base,
      //       orderapi.byAccountTransfer,
      //       this.authToken,
      //       reqData,
      //       (result) => {
      //         if (result != null) {
      //           if (result.code == rescode.REQ_SUCCESS) {
      //             this.buttonDissabled = false;
      //             this.b2bExcelUploadData[idx].resultCancel = true
      //             this.$forceUpdate();
      //           } else {
      //             this.b2bExcelUploadData[idx].resultCancel = false
      //             this.buttonDissabled = false;
      //             this.$forceUpdate();
      //             // alert(result.message);
      //           }
      //           this.refreshFlag = true;
      //         }
      //       }
      //     );
      //   }
      //
      // });
    },
    resetAccountChkCount(){
      console.log(this.estimatedinf.orderNo)
      reqGet(
        payment.base,
        payment.validAccountReset + "?orderNumber=" + this.estimatedinf.orderNo,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert(result.message);
            } else {
              alert(result.message);
            }
          }
        }
      );
    }

  },
};
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.boxarea {
  border: 1px solid;
  border-color: #000000;
  padding: 5px 10px;
  width: 300px;
}
</style>
