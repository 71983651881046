<template>
  <div class="thumbnail"
       v-bind:style="value != '' && [{'background-image':'url('+value+')'}, {'background-size': 'cover'}]">
    <!-- <div class="thumbnail" > -->
    <span v-show="value == ''" style="font-size:30px;cursor: pointer;" @click="onChangeClick"><i
      class="fa fa-fw fa-plus"></i></span>
    <!-- <img v-show="value != ''" :src="value" @click="onChangeClick" alt="thumbnail" > -->
    <span v-show="value != ''" style="font-size:30px;cursor: pointer;" @click="onChangeClick"></span>
    <input
      v-if="!readonly"
      type="file"
      ref="fileInput"
      style="display:none;"
      accept="image/jpeg, image/png"
      @change="onUploadFiles"
    />
    <button type="button" v-if="!readonly" v-show="value != ''" class="btn btn-close" @click="onRemoveClick"><i
      class="fa fa-fw fa-close"></i></button>
    <button type="button" v-show="value != ''" class="btn btn-preview" @click="onPreviewClick"><i
      class="fa fa-fw fa-search"></i></button>
    <button type="button" v-show="value != ''" class="btn btn-download" @click="onDownloadClick"><i
      class="fa fa-fw fa-download"></i></button>
  </div>
</template>

<script>
import {imgtype, rescode} from "../lib/consts";
import {adminapi} from "../lib/api";
import {getfilename, reqPost} from "../lib/request";
import {ContainerClient} from '@azure/storage-blob';
import axios from 'axios'
import Compressor from 'compressorjs';

export default {
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
    downlabel: {
      type: String,
      // required: true,
      default: "",
    },
    readonly: {
      type: Boolean,
      // required: true,
      default: false,
    },
    imgtype: {
      type: String,
      default: imgtype.ETC,
    },
    change: {
      type: Function,
      default: () => {
      },
    },
    resizeWidth: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      fileInput: null,
      windowRef: null,
      imgwidth: 0,
      imgheight: 0,
      qualityVal:0,
      mw:0,
    };
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
  },
  methods: {
    onChangeClick() {
      if (this.readonly == false) {
        this.$refs.fileInput.click();
      }
    },
    compressImage(imageFile) {
      // console.log(imageFile.size)
      if(imageFile.size >= 90000){
        this.qualityVal = 0.5;
        this.mw = 0;
      }else{
        this.qualityVal = 0.9;
        // this.mw = this.resizeWidth || 600;
        this.mw = 600;
      }
      return new Promise((resolve,reject) => {
        let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if (this.resizeWidth === 0) {
          resolve(imageFile);
        } else if (!allowedExtensions.exec(imageFile.name)) {
          resolve(imageFile);
        } else {
          
          new Compressor(imageFile, {
            maxWidth: this.mw,
            quality: this.qualityVal,
            success(result) {
              resolve(result)
            },
            error(e) {
              reject(e)
            },
          });
        }
      });
    },
    onUploadFiles(event) {
      let imageFile = event.target.files[0];

      this.compressImage(imageFile).then( (compressedFile) => {
        let reader = new FileReader();
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            this.imgwidth = img.width;
            this.imgheight = img.height;
          }
          img.src = evt.target.result;
        }
        reader.onerror = evt => {
          console.error(evt);
        }
        reader.readAsDataURL(compressedFile);

        if (this.value === '') {
          this.onRegisterImage(compressedFile);
        } else {
          this.onChangeImage(compressedFile);
        }
      }).catch((error) => {
        alert("이미지 리사이징 에러입니다.");
      });
      this.$refs.fileInput.value = ''
    },
    onRegisterImage(imageFile) {
      reqPost(
        adminapi.base,
        adminapi.sas,
        this.authToken,
        {
          type: this.imgtype,
        },
        (result) => {
          if (result != null) {
            if (result.code === rescode.REQ_SUCCESS) {
              // console.log(result.data.url, result.data.directory);

              const containerClient = new ContainerClient(result.data.url);
              var ext = imageFile.name.substr(imageFile.name.lastIndexOf('.') + 1);
              var d = new Date();
              let filename = d.getTime() + "." + ext;
              // const file = files[0];
              const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
              blockBlobClient.uploadBrowserData(imageFile, {
                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                blobHTTPHeaders: {
                  blobContentType: imageFile.type
                },
                // onProgress: ev => console.log(ev)
              }).then((upfile) => {
                var r_url = upfile._response.request.url;
                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                this.$emit('input', uploadedUrl);
                this.change(uploadedUrl, this.imgwidth, this.imgheight);
              });
            }
          }
        }
      );
    },
    onChangeImage(imageFile) {
      reqPost(
        adminapi.base,
        adminapi.sas,
        this.authToken,
        {
          type: this.imgtype,
        },
        (result) => {
          if (result != null) {
            if (result.code === rescode.REQ_SUCCESS) {
              // console.log(result.data.url, result.data.directory);

              // this.container = result.data.container;
              // this.expiresIn = result.data.expiresIn;
              const containerClient = new ContainerClient(result.data.url);
              var ext = imageFile.name.substr(imageFile.name.lastIndexOf('.') + 1);
              var d = new Date();
              let filename = d.getTime() + "." + ext;
              // const file = files[0];
              const blockBlobClient = containerClient.getBlockBlobClient(result.data.directory + "/" + filename);
              blockBlobClient.uploadBrowserData(imageFile, {
                blockSize: 40 * 1024 * 1024, // 파일 사이즈 제한
                blobHTTPHeaders: {
                  blobContentType: imageFile.type
                },
                // onProgress: ev => console.log(ev)
              }).then((upfile) => {
                var r_url = upfile._response.request.url;
                let uploadedUrl = r_url.substring(0, r_url.indexOf("?sig="));
                this.$emit('input', uploadedUrl);
                this.change(uploadedUrl, this.imgwidth, this.imgheight);
              });
            }
          }
        }
      );
    },
    onRemoveClick() {
      this.$emit('input', "");
      this.change("", 0, 0);
    },
    onPreviewClick() {
      this.windowRef = window.open(this.value, "", "width=600,height=400,left=200,top=200");
      this.windowRef.addEventListener('beforeunload', this.closePortal);
    },
    closePortal() {
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
      }
    },
    onDownloadClick() {
      axios.get(this.value, {responseType: 'blob'})
        .then(response => {
          const blob = new Blob([response.data], {type: 'application/pdf'})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          if (this.downlabel == "") {
            if(!link.download.includes('jpg')){
              link.download = getfilename(this.value) + ".jpg";
            }else{
              link.download = getfilename(this.value);
            }
            // console.log(getfilename(this.value))
          } else {
            link.download = this.downlabel;
          }
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
  },
};
</script>
<style scoped>
.thumbnail {
  width: 160px;
  height: 120px;
  justify-content: center;
  margin: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px;
  background-color: #E8E8E8;
}

.thumbnail span {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  position: absolute;
  background-color: #FFFFFFA0;
}

.btn-close {
  top: 0px;
  right: 0px;
}

.btn-preview {
  left: 0px;
  bottom: 0px;
}

.btn-download {
  right: 0px;
  bottom: 0px;
}
</style>
