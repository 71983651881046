<template>
  <div class="box box-solid">
    <div class="box-body">
      <div class="table-responsive p-0" style="margin-top:10px">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th rowspan="2">시간대 명칭</th>
            <th rowspan="2">기간</th>
            <th rowspan="2">적용 요일</th>
            <th rowspan="2">예약 가능 시간</th>
            <th rowspan="2">예약 단위 시간</th>
            <th colspan="3">예약 가능 인원</th>
            <th rowspan="2">우선 순위</th>
            <th rowspan="2">수정</th>
            <th rowspan="2">삭제</th>
          </tr>
          <tr>
            <th>최소</th>
            <th>최대</th>
            <th>총</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(invnt, iidx) in inventorylist" :key="iidx">
            <td>
              {{ invnt.title }}
            </td>
            <td>
              {{ getinventoryDate(invnt) }}
            </td>
            <td>
              {{ gettargetType(invnt) }}
            </td>
            <td>
              {{ invnt.startTime }} ~ {{ invnt.endTime }}
            </td>
            <td>
              {{ getinventoryUnit(invnt.inventoryTimeUnit) }}분
            </td>
            <td>
              {{ invnt.minimumBookHeadcount }}
            </td>
            <td>
              {{ invnt.maximumBookHeadcount }}
            </td>
            <td>
              {{ invnt.totalHeadcount }}
            </td>
            <td>
              <drop @drop="handleDrop(invnt, $event)">
                <drag class="btn btn-default btn-social-icon" :transfer-data="{ item: invnt, index: iidx}">
                  <i class="fa fa-fw fa-arrows-v"></i>
                </drag>
              </drop>
            </td>
            <td>
              <button type="button" class="btn btn-default" @click="showinventoryDlg(invnt)">수정</button>
            </td>
            <td>
              <button type="button" class="btn btn-social-icon" @click="delinventoryinf(invnt)">
                <i class="fa fa-fw fa-trash-o"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <button type="button" class="btn btn-default pull-right" @click="showinventoryDlg(null)">시간대만들기</button>
    </div>

    <div class="modal" :style="showinventoryinfo && {'display': 'block'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">시간대 만들기</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <label class="col-sm-4 control-label">시간대 명칭</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" v-model="editinventory.title"/>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">시간대 운영 기간</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <div class="radio">
                    <label>
                      <input type="radio" :value="false" v-model="useinventorydate" @change="onuseinventorydate"/>
                      상시
                    </label>
                    <label>
                      <input type="radio" :value="true" v-model="useinventorydate" @change="onuseinventorydate"/>
                      기간설정
                    </label>
                  </div>
                </div>
                <div v-if="useinventorydate" class="input-group" style="display: inline-flex;flex-wrap: wrap;">
                  <DatePicker v-model="editinventory.startDate"/>
                  <span class="input-group-addon" style="border:none;display:inline-flex">-</span>
                  <DatePicker v-model="editinventory.endDate"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">적용 요일</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <div class="radio">
                    <label>
                      <input type="radio" value="Everyday" v-model="editinventory.targetDaysType"/>
                      매일
                    </label>
                    <label>
                      <input type="radio" value="Weekday" v-model="editinventory.targetDaysType"/>
                      주중
                    </label>
                    <label>
                      <input type="radio" value="Weekend" v-model="editinventory.targetDaysType"/>
                      주말
                    </label>
                    <label>
                      <input type="radio" value="Custom" v-model="editinventory.targetDaysType"/>
                      사용자지정
                    </label>
                  </div>
                </div>
                <div v-if="editinventory.targetDaysType == 'Custom'" class="input-group" style="display:inline-flex">
                  <div class="btn-group">
                    <button type="button" :class="['btn', 'btn-default', editinventory.monday && 'btn-selweek']"
                            @click="ClickinventoryMon">월
                    </button>
                    <button type="button" :class="['btn', 'btn-default', editinventory.tuesday && 'btn-selweek']"
                            @click="ClickinventoryTue">화
                    </button>
                    <button type="button" :class="['btn', 'btn-default', editinventory.wednesday && 'btn-selweek']"
                            @click="ClickinventoryWed">수
                    </button>
                    <button type="button" :class="['btn', 'btn-default', editinventory.thursday && 'btn-selweek']"
                            @click="ClickinventoryThu">목
                    </button>
                    <button type="button" :class="['btn', 'btn-default', editinventory.friday && 'btn-selweek']"
                            @click="ClickinventoryFri">금
                    </button>
                    <button type="button" :class="['btn', 'btn-default', editinventory.saturday && 'btn-selweek']"
                            @click="ClickinventorySat">토
                    </button>
                    <button type="button" :class="['btn', 'btn-default', editinventory.sunday && 'btn-selweek']"
                            @click="ClickinventorySun">일
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">예약 단위 시간</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <div class="radio">
                    <label>
                      <input type="radio" value="M60" v-model="editinventory.inventoryTimeUnit"
                             @change="changeinventoryTime"/>
                      1시간
                    </label>
                    <label>
                      <input type="radio" value="M30" v-model="editinventory.inventoryTimeUnit"
                             @change="changeinventoryTime"/>
                      30분
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">예약 가능 시간</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <vue-timepicker format="HH:mm" :minute-interval="getinventoryUnit(editinventory.inventoryTimeUnit)"
                                  v-model="editinventory.startTime" @input="changeinventoryTime"
                                  :hour-range="[[0, getHoursVal(editinventory.endTime)]]"></vue-timepicker>
                  <span style="padding: 6px;">~</span>
                  <vue-timepicker format="HH:mm" :minute-interval="getinventoryUnit(editinventory.inventoryTimeUnit)"
                                  v-model="editinventory.endTime" @input="changeinventoryTime"
                                  :hour-range="[[getHoursVal(editinventory.startTime), 23]]"></vue-timepicker>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">최소 예약 가능 인원</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <input type="text" class="form-control" style="width:80px"
                         v-model="editinventory.minimumBookHeadcount"/>
                </div>
                <label>명</label>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">최대 예약 가능 인원</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <input type="text" class="form-control" style="width:80px"
                         v-model="editinventory.maximumBookHeadcount"/>
                </div>
                <label>명</label>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-4 control-label">총 예약 가능 인원</label>
              <div class="col-sm-8">
                <div class="input-group" style="display:inline-flex">
                  <input ref="atTimeBtn" :disabled="editinventory.useInventoryAtTimeUnits == true ? true : false"
                         type="text" class="form-control" style="width:80px" v-model="editinventory.totalHeadcount"
                         @change="totalHeadcountHeadcount"/>
                </div>
                <label>명</label>
              </div>
            </div>
            <div class="form-group" style="margin-left:0px;margin-right:0px">
              <label class="control-label">
                시간대별 총 예약 가능 인원 설정
                <input type="checkbox" v-model="editinventory.useInventoryAtTimeUnits" @change="changeinventoryTime"/>
              </label>
            </div>
            <div v-if="editinventory.useInventoryAtTimeUnits" class="form-group"
                 style="margin-left:0px;margin-right:0px">
              <div class="table-responsive tbl_h200 p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                  <tr>
                    <th>시간</th>
                    <th>예약 가능 인원 수</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(timeunit, tidx) in headcountByTimeUnits" :key="tidx">
                    <td>
                      {{ formatHeadTime(timeunit) }}
                    </td>
                    <td>
                      <div class="input-group" style="display:inline-flex">
                        <input type="text" class="form-control" style="width:80px" v-model="timeunit.headcount"
                               @change="changeinventoryTime"/>
                      </div>
                      <label>명</label>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeinventoryDlg">취소</button>
            <button type="button" class="btn btn-default" @click="saveinventoryInfo">저장</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {rescode} from "@/lib/consts";
import {filterHours} from "@/lib/utility";
import {
  Drag,
  Drop
} from "vue-drag-drop";
import {
  MonthPicker
} from 'vue-month-picker';
import DatePicker from "@/components/DatePicker";
import VueTimepicker from "vue2-timepicker";

export default {
  components: {
    Drag,
    Drop,
    MonthPicker,
    DatePicker,
    VueTimepicker,
  },
  created() {
    this.storeCode = this.$route.query.stcode;
  },
  mounted() {
    this.getinventorySettings();
  },
  computed: {
    headcountByTimeUnits() {
      let starthours = this.editinventory.startTime.substring(0, 5);
      let endHour = this.editinventory.endTime.substring(0, 5);
      let vm = this;
      vm.newHeadCountArray = [];
      //인원수 새로운 배열에 담기
      vm.mOptions.forEach(function (option, idx) {
        if (vm.editinventory.inventoryTimeUnit === "M60") {
          //배열안에 시간이 시작시간보다 크거나 같을떄 끝나는 시간보다는 작은시간 옵션 ahalf 가 false 이면 1시간 배열 넣기
          if (option.value >= starthours && option.value <= endHour && option.ahalf == false) {
            let ahalf = option.value.substring(3, 5) === "30" ? true : false
            vm.newHeadCountArray.push({
              hour: option.value.substring(0, 2),
              ahalf: ahalf,
              headcount: option.headcount
            });
          }
        } else {
          //30분일경우 배열에 넣기
          if (option.value >= starthours && option.value <= endHour) {
            let ahalf = option.value.substring(3, 5) === "30" ? true : false
            vm.newHeadCountArray.push({
              hour: option.value.substring(0, 2),
              ahalf: ahalf,
              headcount: option.headcount
            });
          }
        }
      });
      this.editinventory.headcountAtTimeUnits = this.newHeadCountArray;
      return vm.newHeadCountArray;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  data() {
    return {
      //모달
      showinventoryinfo: false,
      //모달끝

      hcatunitsOriginal: [],
      newHeadCountArray: [],
      mOptions: [{
        value: '00:00',
        hour: '00',
        ahalf: false,
        headcount: "0"
      },
        {
          value: '00:30',
          hour: '00',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '01:00',
          hour: '01',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '01:30',
          hour: '01',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '02:00',
          hour: '02',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '02:30',
          hour: '02',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '03:00',
          hour: '03',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '03:30',
          hour: '03',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '04:00',
          hour: '04',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '04:30',
          hour: '04',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '05:00',
          hour: '05',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '05:30',
          hour: '05',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '06:00',
          hour: '06',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '06:30',
          hour: '06',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '07:00',
          hour: '07',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '07:30',
          hour: '07',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '08:00',
          hour: '08',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '08:30',
          hour: '08',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '09:00',
          hour: '09',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '09:30',
          hour: '09',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '10:00',
          hour: '10',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '10:30',
          hour: '10',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '11:00',
          hour: '11',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '11:30',
          hour: '11',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '12:00',
          hour: '12',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '12:30',
          hour: '12',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '13:00',
          hour: '13',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '13:30',
          hour: '13',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '14:00',
          hour: '14',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '14:30',
          hour: '14',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '15:00',
          hour: '15',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '15:30',
          hour: '15',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '16:00',
          hour: '16',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '16:30',
          hour: '16',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '17:00',
          hour: '17',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '17:30',
          hour: '17',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '18:00',
          hour: '18',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '18:30',
          hour: '18',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '19:00',
          hour: '19',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '19:30',
          hour: '19',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '20:00',
          hour: '20',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '20:30',
          hour: '20',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '21:00',
          hour: '21',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '21:30',
          hour: '21',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '22:00',
          hour: '22',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '22:30',
          hour: '22',
          ahalf: true,
          headcount: "0"
        },
        {
          value: '23:00',
          hour: '23',
          ahalf: false,
          headcount: "0"
        },
        {
          value: '23:30',
          hour: '23',
          ahalf: true,
          headcount: "0"
        },
      ],
      inventorylist: [], //
      storeCode: '',
      editinventory: {
        title: "", //String      설정명
        startDate: "", //String  yyyy-MM-dd    예약 설정 적용 시작일. null 일 경우 시작일 제한 없음
        endDate: "", //String   yyyy-MM-dd     예약 설정 적용 종료일. null 일 경우 종료일 제한 없음
        targetDaysType: "Everyday", //String   [Everyday, Weekday, Weekend, Custom]    Everyday: 매일, Weekday: 주중, Weekend: 주말, Custom: 요일 직접 지정
        monday: false, //Boolean   targetDaysType 이 Custom 일 경우 월요일을 포함할 지 여부
        tuesday: false, //Boolean    targetDaysType 이 Custom 일 경우 화요일을 포함할 지 여부
        wednesday: false, //Boolean    targetDaysType 이 Custom 일 경우 수요일을 포함할 지 여부
        thursday: false, //Boolean     targetDaysType 이 Custom 일 경우 목요일을 포함할 지 여부
        friday: false, //Boolean     targetDaysType 이 Custom 일 경우 금요일을 포함할 지 여부
        saturday: false, //Boolean     targetDaysType 이 Custom 일 경우 토요일을 포함할 지 여부
        sunday: false, //Boolean    targetDaysType 이 Custom 일 경우 일요일을 포함할 지 여부
        inventoryTimeUnit: "M30", //String    [M30, M60]    M30: 예약 단위 시간이 30분, M60: 예약 단위 시간이 1 시간
        startTime: "00:00", //String  HH:mm:ss    예약 가능 시작 시간대
        endTime: "23:30", //String    HH:mm:ss    예약 가능 종료 시간대
        minimumBookHeadcount: 0, //Number    최소 예약 가능 인원
        maximumBookHeadcount: 0, //Number    최대 예약 가능 인원
        totalHeadcount: 0, //Number      총 예약 가능 인원
        useInventoryAtTimeUnits: false, //Boolean    시간대별로 총 예약 가능 인원 설정 여부
        headcountAtTimeUnits: [], //array
        newHeadCount: [],
        /*
        hour:0,//Number  시
        ahalf:false,//Boolean     30분일 경우 true
        headcount:0,//Number      해당 시간 대 총 예약 가능 인원
        */
      },
      useinventorydate: false,
      editsettingsId: null,
    }
  },
  methods: {
    getinventorySettings() {
      reqGet(
        brandapi.base,
        brandapi.tnjinventory + "?storeCode=" + this.storeCode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.inventorylist = result.data.list; //
          }
        }
      );
    },
    closeinventoryDlg() {
      this.showinventoryinfo = false;
    },
    getinventoryDate(invnt) {
      if (invnt.startDate == null && invnt.endDate == null) {
        return "상시";
      } else {
        var retval = "";
        if (invnt.startDate != null) {
          retval = invnt.startDate;
        }
        retval = retval + " ~ ";
        if (invnt.endDate != null) {
          retval = retval + invnt.endDate;
        }
        return retval;
      }
    },
    gettargetType(invnt) {
      if (invnt.targetDaysType == "Everyday") {
        return "매일";
      } else if (invnt.targetDaysType == "Weekday") {
        return "주중";
      } else if (invnt.targetDaysType == "Weekend") {
        return "주말";
      } else {
        var retval = "";
        if (invnt.monday) {
          if (retval == "") {
            retval = "월";
          } else {
            retval = retval + ", 월";
          }
        }
        if (invnt.tuesday) {
          if (retval == "") {
            retval = "화";
          } else {
            retval = retval + ", 화";
          }
        }
        if (invnt.wednesday) {
          if (retval == "") {
            retval = "수";
          } else {
            retval = retval + ", 수";
          }
        }
        if (invnt.thursday) {
          if (retval == "") {
            retval = "목";
          } else {
            retval = retval + ", 목";
          }
        }

        if (invnt.friday) {
          if (retval == "") {
            retval = "금";
          } else {
            retval = retval + ", 금";
          }
        }
        if (invnt.saturday) {
          if (retval == "") {
            retval = "토";
          } else {
            retval = retval + ", 토";
          }
        }

        if (invnt.sunday) {
          if (retval == "") {
            retval = "일";
          } else {
            retval = retval + ", 일";
          }
        }
        return retval;
      }
    },

    showinventoryDlg(showitem) {
      if (showitem == null) {
        this.editinventory = {
          title: "", //String      설정명
          startDate: null, //String  yyyy-MM-dd    예약 설정 적용 시작일. null 일 경우 시작일 제한 없음
          endDate: null, //String   yyyy-MM-dd     예약 설정 적용 종료일. null 일 경우 종료일 제한 없음
          targetDaysType: "Everyday", //String   [Everyday, Weekday, Weekend, Custom]    Everyday: 매일, Weekday: 주중, Weekend: 주말, Custom: 요일 직접 지정
          monday: false, //Boolean   targetDaysType 이 Custom 일 경우 월요일을 포함할 지 여부
          tuesday: false, //Boolean    targetDaysType 이 Custom 일 경우 화요일을 포함할 지 여부
          wednesday: false, //Boolean    targetDaysType 이 Custom 일 경우 수요일을 포함할 지 여부
          thursday: false, //Boolean     targetDaysType 이 Custom 일 경우 목요일을 포함할 지 여부
          friday: false, //Boolean     targetDaysType 이 Custom 일 경우 금요일을 포함할 지 여부
          saturday: false, //Boolean     targetDaysType 이 Custom 일 경우 토요일을 포함할 지 여부
          sunday: false, //Boolean    targetDaysType 이 Custom 일 경우 일요일을 포함할 지 여부
          inventoryTimeUnit: "M30", //String    [M30, M60]    M30: 예약 단위 시간이 30분, M60: 예약 단위 시간이 1 시간
          startTime: "00:00", //String  HH:mm:ss    예약 가능 시작 시간대
          endTime: "23:30", //String    HH:mm:ss    예약 가능 종료 시간대
          minimumBookHeadcount: 0, //Number    최소 예약 가능 인원
          maximumBookHeadcount: 0, //Number    최대 예약 가능 인원
          totalHeadcount: 0, //Number      총 예약 가능 인원
          useInventoryAtTimeUnits: false, //Boolean    시간대별로 총 예약 가능 인원 설정 여부
          headcountAtTimeUnits: [], //array
          newHeadCount: [],
        };
        this.useinventorydate = false;
        this.editsettingsId = null;
        this.showinventoryinfo = true;
      } else {
        this.editsettingsId = showitem.settingsId;
        reqGet(
          brandapi.base,
          brandapi.tnjinventoryquery + "?settingsId=" + showitem.settingsId,
          this.authToken,
          (result) => {
            if (result != null && result.code == rescode.REQ_SUCCESS) {
              this.editinventory = result.data.inventorySettings;
              this.editinventory.newHeadCount = result.data.inventorySettings.headcountAtTimeUnits;
              // this.hcatunits = ;

              this.hcatunits = JSON.parse(JSON.stringify(result.data.inventorySettings.headcountAtTimeUnits));
              this.hcatunitsOriginal = JSON.parse(JSON.stringify(result.data.inventorySettings.headcountAtTimeUnits));
              let vm = this;
              this.mOptions.forEach((el, idx) => {
                vm.hcatunits.find((fifi) => {
                  if (Number(fifi.hour) === Number(el.hour) && fifi.ahalf == false && fifi.ahalf == el.ahalf) {
                    el.headcount = fifi.headcount;
                  } else if (Number(fifi.hour) === Number(el.hour) && fifi.ahalf == el.ahalf) {
                    el.headcount = fifi.headcount;
                  }
                })
              });

              // console.log(this.mOptions)
              if (this.editinventory.startDate != null || this.editinventory.startDate != null) {
                this.useinventorydate = true;
              } else {
                this.useinventorydate = false;
              }
              this.showinventoryinfo = true;
            }
          }
        );
      }
    },

    delinventoryinf(invent) {
      reqPost(
        brandapi.base,
        brandapi.tnjinventorydelete,
        this.authToken, {
          storeCode: this.storeCode,
          settingsId: invent.settingsId,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert("삭제되었습니다.");
              this.$emit('get-kakao-alert', 'RESERVATION');
              this.getinventorySettings();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getinventoryUnit(val) {
      if (val == "M30")
        return 30;
      else
        return 60;
    },
    getHoursVal(val) {
      return filterHours(val);
    },
    formatHeadTime(head) {
      if (head.ahalf) {
        return head.hour + ":30";
      } else {
        return head.hour + ":00";
      }
    },
    onuseinventorydate() {
      if (this.useinventorydate == false) {
        this.editinventory.startDate = null;
        this.editinventory.endDate = null;
      }
    },
    handleDrop(myitem, drag) {
      var priorities = [];
      priorities.push({
        settingsId: drag.item.settingsId,
        priority: myitem.priority
      });
      priorities.push({
        settingsId: myitem.settingsId,
        priority: drag.item.priority
      });
      reqPost(
        brandapi.base,
        brandapi.tnjinventorypriority,
        this.authToken, {
          storeCode: this.storeCode,
          priorities: priorities,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.$emit('get-kakao-alert', 'RESERVATION');
              this.getinventorySettings();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },

    ClickinventoryMon() {
      this.editinventory.monday = !this.editinventory.monday;
    },
    ClickinventoryTue() {
      this.editinventory.tuesday = !this.editinventory.tuesday;
    },
    ClickinventoryWed() {
      this.editinventory.wednesday = !this.editinventory.wednesday;
    },
    ClickinventoryThu() {
      this.editinventory.thursday = !this.editinventory.thursday;
    },
    ClickinventoryFri() {
      this.editinventory.friday = !this.editinventory.friday;
    },
    ClickinventorySat() {
      this.editinventory.saturday = !this.editinventory.saturday;
    },
    ClickinventorySun() {
      this.editinventory.sunday = !this.editinventory.sunday;
    },
    totalHeadcountHeadcount() {
      this.mOptions.forEach((el, idx) => {
        el.headcount = this.editinventory.totalHeadcount;
      });
    },
    changeinventoryTime() {

      if (this.editinventory.useInventoryAtTimeUnits === true) {
        console.log(this.editinventory.headcountAtTimeUnits)
        if (this.editinventory.headcountAtTimeUnits.length == 0) {
          this.mOptions.forEach((el, idx) => {
            el.headcount = this.editinventory.totalHeadcount;
          });
        }
        let vm = this;
        this.mOptions.forEach((el, idx) => {
          vm.headcountByTimeUnits.find((fifi) => {
            if (Number(fifi.hour) === Number(el.hour) && fifi.ahalf == false && fifi.ahalf == el.ahalf) {
              el.headcount = fifi.headcount;
            } else if (Number(fifi.hour) === Number(el.hour) && fifi.ahalf == el.ahalf) {
              el.headcount = fifi.headcount;
            }
          })
        });
      }
    },

    saveinventoryInfo() {
      if (Number(this.editinventory.totalHeadcount) < Number(this.editinventory.minimumBookHeadcount)) {
        alert('최소 예약 가능 인원은 총 예약 가능 인원보다 클 수 없습니다.');
        this.editinventory.minimumBookHeadcount = 0;
        return;
      }
      if (Number(this.editinventory.totalHeadcount) < Number(this.editinventory.maximumBookHeadcount)) {
        alert('최대 예약 가능 인원은 총 예약 가능 인원보다 클 수 없습니다.');
        this.editinventory.maximumBookHeadcount = 0;
        return;
      }
      let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      days.forEach(day => {
        if (this.editinventory[day] == null) {
          this.editinventory[day] = false;
        }
        // console.log(this.editinventory[day])
      });
      if (this.editsettingsId == null) { //시간대만들기 클릭 시
        if (this.editinventory.inventoryTimeUnit === "M60") {
          if (this.editinventory.startTime.substring(3, 5) === "30" ? true : false || this.editinventory.endTime.substring(3, 5) === "30" ? true : false) {
            alert('예약 가능 시간을 확인 해주세요.')
            return;
          }
        }
        reqPost(
          brandapi.base,
          brandapi.tnjinventorycreate,
          this.authToken, {
            storeCode: this.storeCode,
            inventorySettings: this.editinventory,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.$emit('get-kakao-alert', 'RESERVATION');
                this.closeinventoryDlg();
                alert("저장되었습니다.");
                this.getinventorySettings();
              } else {
                alert(result.message);
              }
            }
          }
        );
      } else { //수정 저장
        if (this.editinventory.inventoryTimeUnit === "M60") {
          if (this.editinventory.startTime.substring(3, 5) === "30" ? true : false || this.editinventory.endTime.substring(3, 5) === "30" ? true : false) {
            alert('예약 가능 시간을 확인 해주세요.')
            return;
          }
        }
        reqPost(
          brandapi.base,
          brandapi.tnjinventoryupdate,
          this.authToken, {
            storeCode: this.storeCode,
            settingsId: this.editsettingsId,
            inventorySettings: this.editinventory,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.closeinventoryDlg();
                alert("저장되었습니다.");
                this.$emit('get-kakao-alert', 'RESERVATION');
                this.getinventorySettings();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },


  }

}
</script>

<style>
th {
  background-color: #dee2e685;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.tbl_h200 {
  height: 200px;
  overflow: scroll;
}

.nav-tabs li a {
  cursor: pointer;
}

.col-inline {
  display: inline-flex;
  align-items: center;
}

.btn-selweek {
  background-color: #5B9BD5;
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .sm-inline {
    display: flex;
  }
}

.redmsg {
  color: #ff0000;
}

.blurmsg {
  color: #0000ff;
}

.nnos input,
.nnos select {
  display: inline-block;
}

.refInputDiv {
  display: inline-block;
  width: 172px;
  margin-left: 10px;
}

.srdivbox {
  margin-top: 10px;
}

.refTitle {
  min-width: 120px;
  display: inline-block;
}

.mt-1 {
  margin-top: 10px;
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
  background-color: #fff;
  z-index: 9999;
}

.mr-2 {
  margin-right: 10px;
}

.waring {
  color: red;
  margin-top: 5px;
}
</style>
