<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body form-horizontal">
            <div class="form-group">
                <label class="col-sm-3 control-label">핫플레이스명</label>
                <div class="col-sm-9 radio">
                    <input type="text" class="form-control" v-model="hotplaceName" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">추천 노출 여부</label>
                <div class="col-sm-9 radio">
                    <label> <input type="radio" :value="false" v-model="exposure" />비노출 </label>
                    <label> <input type="radio" :value="true" v-model="exposure" />노출 </label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">지역정보</label>
                <div class="col-sm-9 ">
                    <input type="text" class="form-control" v-model="areaInfo" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label">지정방식</label>
                <div class="col-sm-9 radio">
                    <label> <input type="radio" :value="false" v-model="appoint" @change="changeAppoint" />좌표 </label>
                    <label> <input type="radio" :value="true" v-model="appoint" @change="changeAppoint" />구역 </label>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 control-label"></label>
                <div class="col-sm-9">
                    <div class="areafirstDiv">
                        <div>
                            <span>X 좌표</span>
                            <input disabled type="number" v-model.:number="appoint ? center.lng : centerCopy.lng" step="0.0001">
                        </div>
                        <div>
                            <span>y 좌표</span>
                            <input disabled type="number" v-model.:number="appoint ? center.lat : centerCopy.lat" step="0.0001">
                        </div>
                        <div v-if="appoint">
                            <span>반경</span>
                            <input disabled v-model="unit">
                            <span>m</span>
                        </div>
                        <div v-else></div>
                    </div>
                    <div class="mt-2">
                        <div class="map_wrap">
                            <vue-daum-map :appKey="appKey" :center.sync="center" :level.sync="level" :mapTypeId="mapTypeId" :libraries="libraries" @load="onLoad" @center_changed="centerChanged('center_changed', $event)" @zoom_start="onMapEvent('zoom_start', $event)" @zoom_changed="circlesCenter('zoom_changed', $event)" @bounds_changed="onMapEvent('bounds_changed', $event)" @click="onmapClick('click', $event,false)" @dblclick="onMapEvent('dblclick', $event)" @mouseover="displayInfowindow(marker, title)" @rightclick="onrightclick('rightclick', $event)" @mousemove="onMouseMove('mousemove', $event)" @dragstart="onMapEvent('dragstart', $event)" @drag="circlesCenter('drag', $event)" @dragend="onMapEvent('dragend', $event)" @idle="onMapEvent('idle', $event)" @tilesloaded="onMapEvent('tilesloaded', $event)" @maptypeid_changed="onMapEvent('maptypeid_changed', $event)" style="width:100%;height:600px;">
                            </vue-daum-map>
                            <div id="menu_wrap" class="bg_white">
                                <div class="option">
                                    <div>
                                        <form @submit.prevent="searchPlaces(false)">
                                            키워드 : <input type="text" value="제주도 카카오" id="keyword" size="15">
                                            <button type="submit">검색하기</button>
                                        </form>
                                    </div>
                                </div>
                                <div id="placesList">
                                    <li class="item" v-for="(places, cidx) in placeslistItem" :key="cidx">
                                        <span :class="'markerbg marker_'+ (cidx + 1)"></span>
                                        <div class="info" @click="infoClickEve(places.x,places.y)">
                                            <h5>{{ places.place_name }}</h5>
                                            <span v-if="places.road_address_name">{{ places.address_name }}</span>
                                            <span v-if="places.road_address_name" class="jibun gray"></span>
                                            <span v-else>places.address_name</span>
                                            <span class="tel">places.phone</span>
                                        </div>
                                    </li>
                                </div>
                                <div id="pagination"></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center mt-2">
                        <button type="button" class="btn btn-default" @click="golistpage" style="margin-right:10px">취소</button>
                        <button type="button" class="btn btn-default" @click="addHotPlace" v-if="!editWhether">등록</button>
                        <button type="button" class="btn btn-default" @click="editHotPlace" v-else>수정</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import routepath from "../../lib/routes";
import config from '../../config';
import VueDaumMap from 'vue-daum-map';
import {
    reqGet,
    reqPost
} from "../../lib/request";
import {
    brandapi
} from "../../lib/api";

export default {
    components: {
        VueDaumMap
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        routes() {
            return routepath;
        },
    },
    data() {
        return {
            areaInfo: '',
            editWhether: false,
            appoint: false,
            exposure: false,
            hotplaceName: "",
            appKey: config.appKey,
            center: {
                lat: 33.450701,
                lng: 126.570667
            },
            centerCopy: {
                lat: 33.450701,
                lng: 126.570667
            },
            level: 2,
            mapTypeId: VueDaumMap.MapTypeId.NORMAL,
            libraries: ['services'],
            mapObject: null,
            drawingFlag: false,
            centerPosition: {
                La: 126.89712380697262,
                Ma: 37.482470044923765
            },
            marker: "",
            // saveB:{La: 126.57169367227243, Ma: 33.450713347980106},
            drawingLine: false,
            drawingCircle: false,
            drawingOverlay: false,
            map: null,
            circles: [],
            circle: "",
            unit: "",
            placeslistItem: [],
            markers: [],
        }
    },
    beforeMount() {

    },
    created() {
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_DSP,
            path2: menuval.LV1_HOME,
            title: "핫플레이스"
        });
    },
    mounted() {

    },
    watch: {
        // appoint(val) {
        //     this.onLoad(this.map)
        // }
    },
    methods: {
        changeAppoint() {
            this.onloadData(this.map);
        },
        golistpage() {
            this.$router.push(this.routes.hotplace);
        },
        callbackMapFunction(map) {
            if (this.$route.query.id !== 'undefined') {
                reqGet(
                    brandapi.base,
                    brandapi.hotplaceQuery + "?id=" + this.$route.query.id,
                    this.authToken,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                // return false;
                                this.hotplaceName = result.data.hotplaceName;
                                this.exposure = result.data.recommend;
                                //Coodinate = 좌표
                                if (result.data.hotPlaceType !== 'Coodinate') {
                                    this.appoint = true;
                                } else {
                                    this.appoint = false;
                                }
                                this.center.lng = result.data.coordinateX;
                                this.unit = result.data.distance;
                                this.center.lat = result.data.coordinateY;
                                this.centerCopy.lng = result.data.coordinateX;
                                this.centerCopy.lat = result.data.coordinateY;
                                this.areaInfo = result.data.areaInfo;
                                this.onloadData(map);
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
                this.editWhether = true;
            } else {
                this.editWhether = false;
                this.onloadData(map);
            }
        },
        editHotPlace() {
            //hotplaceUpdate
            let hotplaces = {
                "hotplaces": {
                    hotplaceName: this.hotplaceName,
                    recommend: this.exposure,
                    hotPlaceType: this.appoint ? 'District' : 'Coodinate',
                    coordinateX: this.appoint ? this.center.lng : this.centerCopy.lng,
                    coordinateY: this.appoint ? this.center.lat : this.centerCopy.lat,
                    distance: this.appoint ? this.unit : '',
                    areaInfo: this.areaInfo,
                },
                "id": this.$route.query.id,
            }
            reqPost(
                brandapi.base,
                brandapi.hotplaceUpdate,
                this.authToken,
                hotplaces,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.refreshPage();
                            alert('수정이 완료 되었습니다.');
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        addHotPlace() {
            //Coodinate 좌표 false
            //District 구역 true
            let hotplaces = {
                "hotplaces": {
                    hotplaceName: this.hotplaceName,
                    recommend: this.exposure,
                    hotPlaceType: this.appoint ? 'District' : 'Coodinate',
                    coordinateX: this.appoint ? this.center.lng : this.centerCopy.lng,
                    coordinateY: this.appoint ? this.center.lat : this.centerCopy.lat,
                    distance: this.appoint ? this.unit : '',
                    areaInfo: this.areaInfo,
                }
            }
            reqPost(
                brandapi.base,
                brandapi.hotplaceCreate,
                this.authToken,
                hotplaces,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            // this.refreshPage();
                            alert('등록이 완료 되었습니다.');
                            this.$router.push(this.routes.hotplace);
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        infoClickEve(lng, lat) {
            let bounds = new kakao.maps.LatLngBounds();
            let placePosition = new kakao.maps.LatLng(lat, lng);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가
            bounds.extend(placePosition);

            this.map.setBounds(bounds);

            this.circlesCenter();
            if (!this.appoint) {
                let markerplacePosition = new kakao.maps.LatLng(lat, lng);
                this.marker = this.addMarker(markerplacePosition, 0);
                this.centerCopy.lng = lng;
                this.centerCopy.lat = lat;
            }
        },
        setLevel(level) {
            switch (level) {
                case 125:
                    return 2;
                case 250:
                    return 3;
                case 500:
                    return 4;
                case 800:
                    return 5;
                case 1000:
                    return 5;
                case 2000:
                    return 6;
                case 4000:
                    return 7;
                case 8000:
                    return 8;
                case 16000:
                    return 9;
                case 32000:
                    return 10;
                case 64000:
                    return 11;
                case 128000:
                    return 12;
                case 256000:
                    return 13;
                case 512000:
                    return 14;
            }

        },
        onloadData(map) {
            this.mapObject = map;
            this.map = map;
            //중심 좌표
            this.map.setCenter(new kakao.maps.LatLng(this.center.lat, this.center.lng));
            let spval = false;
            let center = new kakao.maps.LatLng(this.center.lat, this.center.lng);
            if (this.editWhether) { //수정일경우
                this.keyword = null;
                //ma = y la = x
                center = new kakao.maps.LatLng(this.center.lat, this.center.lng);
                spval = true;
            } else { //등록일경우
                center = new kakao.maps.LatLng(this.centerPosition.La, this.centerPosition.Ma)
                // console.log(this.centerPosition.Ma)
                this.center.lat = this.centerPosition.Ma; //경도
                this.center.lng = this.centerPosition.La; //위도
                spval = false;
            }
            // console.log(this.setLevel(this.unit))
            this.map.setMinLevel(2);
            this.map.setLevel(this.setLevel(this.unit));

            if (!this.appoint) {
                this.removeCircles()
                this.searchPlaces(spval);
                if (this.editWhether) {
                    this.onmapClick(null, center, true)
                }
            } else {
                this.daumMapProperty(center);
                this.searchPlaces(spval);
            }
        },
        onLoad(map) {
            this.callbackMapFunction(map);
        },
        searchPlaces(spval) {
            if (!spval) {
                var keyword = document.getElementById('keyword').value;
                let ps = new kakao.maps.services.Places();
                if (!keyword.replace(/^\s+|\s+$/g, '')) {
                    alert('키워드를 입력해주세요!');
                    return false;
                }
                // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
                ps.keywordSearch(keyword, this.placesSearchCB);
            }
            if (this.appoint) {
                this.circlesCenter();
            }
        },
        placesSearchCB(data, status, pagination) {
            if (status === kakao.maps.services.Status.OK) {

                // 정상적으로 검색이 완료됐으면
                // 검색 목록 표출
                this.displayPlaces(data);

                //페이징
                this.displayPagination(pagination);

            } else if (status === kakao.maps.services.Status.ZERO_RESULT) {

                alert('검색 결과가 존재하지 않습니다.');
                return;

            } else if (status === kakao.maps.services.Status.ERROR) {

                alert('검색 결과 중 오류가 발생했습니다.');
                return;

            }
        },
        displayPlaces(places) {
            // console.log(places)
            let listEl = document.getElementById('placesList');
            let fragment = document.createDocumentFragment();
            let bounds = new kakao.maps.LatLngBounds();
            let placePosition = new kakao.maps.LatLng(places[0].y, places[0].x);
            // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
            // LatLngBounds 객체에 좌표를 추가
            bounds.extend(placePosition);

            // 검색 결과 목록에 추가된 항목들을 제거합니다
            // this.removeAllChildNods(listEl);
            this.placeslistItem = [];
            let itemEl;
            let infowindow = new kakao.maps.InfoWindow({
                zIndex: 1
            });
            this.removeMarker();
            // kakao.maps.event.addListener(map, 'click', function (mouseEvent) {
            //     console.log(marker.getPosition())
            // });
            for (var i = 0; i < places.length; i++) {
                this.placeslistItem.push(places[i]);
            }

            // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다

            this.map.setBounds(bounds);
            if (this.appoint) {
                this.circlesCenter();
            }

            listEl.appendChild(fragment);

            // this.removeCircles();
        },
        displayInfowindow(marker, title) {
            let infowindow = new kakao.maps.InfoWindow({
                zIndex: 1
            });
            let content = '<div style="padding:5px;z-index:1;">' + title + '</div>';

            infowindow.setContent(content);
            infowindow.open(map, marker);
        },
        addMarker(position, idx, title) {
            var imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png', // 마커 이미지 url, 스프라이트 이미지를 씁니다
                imageSize = new kakao.maps.Size(24, 35), // 마커 이미지의 크기
                imgOptions = {
                    offset: new kakao.maps.Point(20, 30)
                },
                markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imgOptions),
                marker = new kakao.maps.Marker({
                    position: position, // 마커의 위치
                    image: markerImage
                });

            marker.setMap(this.map); // 지도 위에 마커를 표출합니다
            this.markers.push(marker); // 배열에 생성된 마커를 추가합니다

            return marker;
        },
        removeMarker() {
            for (var i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = [];
        },
        daumMapProperty(centerPosition, rClickPosition) {

            var level = this.map.getLevel();
            // 원 객체를 생성합니다
            this.circle = new kakao.maps.Circle({
                center: centerPosition, // 원의 중심좌표입니다
                radius: Math.pow(2, level - 3) * 250, // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
                strokeWeight: 1, // 선의 두께입니다
                strokeColor: '#00a0e9', // 선의 색깔입니다
                strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
                strokeStyle: 'solid', // 선의 스타일입니다
                fillColor: '#00a0e9', // 채우기 색깔입니다
                fillOpacity: 0.2 // 채우기 불투명도입니다 
            });
            // console.log(polyline.getLength())
            let radius = Math.round(this.circle.getRadius()) // 원의 반경 정보를 얻어옵니다

            // 원을 지도에 표시합니다
            this.circle.setMap(this.map);

            let radiusObj = {
                'circle': this.circle,
            };

            this.circles.push(radiusObj);
            this.unit = radius;
        },
        onmapClick(event, params, edit) {

            this.removeMarker();
            let markerplacePosition = null;
            if (edit) {
                markerplacePosition = new kakao.maps.LatLng(params.Ma, params.La);
            } else {
                markerplacePosition = new kakao.maps.LatLng(params[0].latLng.Ma, params[0].latLng.La);
            }
            if (!this.appoint) {
                this.marker = this.addMarker(markerplacePosition, 0);
                if (edit) {
                    this.centerCopy.lng = params.La;
                    this.centerCopy.lat = params.Ma;
                } else {
                    this.centerCopy.lng = params[0].latLng.La;
                    this.centerCopy.lat = params[0].latLng.Ma;
                }
            }
        },
        onMouseMove(event, params) {

        },
        onrightclick(event, params) {

        },
        onMapEvent(event, params) {

        },
        centerChanged(event, params) {

        },
        getTimeHTML(distance) {

        },
        lengthConversion(length) {

            var result = length;

            if (length >= 1000) {
                result = Math.floor(length / 1000)
            } else {
                result = (length / 1000)
            }

            return result;

        },
        removeCircles() {
            for (var i = 0; i < this.circles.length; i++) {
                this.circles[i].circle.setMap(null);
            }
            this.circles = [];
        },
        circlesCenter() {
            this.removeMarker();
            if (this.appoint) {
                var level = this.map.getLevel();
                // if(level < 3){
                //   alert('100m 이하는 등록 할 수 없습니다.');
                //   this.map.setLevel(3);
                //   return;
                // }
                if (this.circle == "") {
                    return;
                }
                this.circle.setRadius(Math.pow(2, level - 3) * 250);
                // console.log(this.circle.getRadius());

                var position = this.map.getCenter();
                this.circle.setPosition(position);

                //원의 반지름
                this.unit = this.circle.getRadius();

                if (this.editWhether) {

                } else {
                    this.center.lat = position.Ma;
                    this.center.lng = position.La;
                }

            }

        },
        removeAllChildNods(el) {
            while (el.hasChildNodes()) {
                el.removeChild(el.lastChild);
            }
        },
        displayPagination(pagination) {
            var paginationEl = document.getElementById('pagination'),
                fragment = document.createDocumentFragment(),
                i;

            // 기존에 추가된 페이지번호를 삭제합니다
            while (paginationEl.hasChildNodes()) {
                paginationEl.removeChild(paginationEl.lastChild);
            }

            for (i = 1; i <= pagination.last; i++) {
                var el = document.createElement('a');
                el.href = "#";
                el.innerHTML = i;

                if (i === pagination.current) {
                    el.className = 'on';
                } else {
                    el.onclick = (function (i) {
                        return function () {
                            pagination.gotoPage(i);
                        }
                    })(i);
                }

                fragment.appendChild(el);
            }
            paginationEl.appendChild(fragment);
        }
    },
}
</script>

<style scoped>
.areafirstDiv {
    display: flex;
    justify-content: space-between;
}

.areafirstDiv>div>span:first-child {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 600;
}

.areafirstDiv>div>input {
    background: #eee;
    border: 1px solid #d2d6de;
    padding: 6px 12px;
}

.areafirstDiv>div>span:last-child {
    margin-left: 10px;
    font-size: 13px;
    margin-right: 50px;
    font-weight: 600;
}

.mt-2 {
    margin-top: 20px;
}
</style>
