<template lang="ko">
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display: inline-flex;flex-wrap: wrap;width: 100%;">
                <div style="position: relative;margin-right:10px" v-click-outside="hideMonthPicker">
                    <input type="text" class="form-control" style="width:auto" readonly :value="settlementMonth" @click="onShowMonthPicker" />
                    <month-picker v-show="monthpicker" lang="ko" :default-month="defmonth" :default-year="defyear" :input-pre-filled="true" @change="selMonth"></month-picker>
                </div>
                <select class="form-control" style="width:auto;margin-right: 10px" v-model="selectedAgent" v-if="isHostStaff">
                    <option v-for="agent in agentList" :key="agent.username" :value="agent">{{ agent.username }} ({{agent.fullName}})</option>
                </select>
                <button class="btn btn-default" type="button" @click="refreshPage">검색</button>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
        <button type="button" class="btn btn-default pull-right" @click="downExcel">
            <i class="fa fa-download"></i>Excel
        </button>
    </div>
    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th rowspan="2">번호</th>
                        <th rowspan="2">브랜드</th>
                        <th rowspan="2">매장명</th>
                        <th colspan="3">사용매출</th>
                        <th colspan="3">취소위약금 매출</th>
                        <th rowspan="2">총입금금액</th>
                        <th rowspan="2">총수수료</th>
                        <th rowspan="2">정상기준일</th>
                        <th rowspan="2">정산일</th>
                        <th rowspan="2">정산현황</th>
                    </tr>
                    <tr>
                        <th>거래금액</th>
                        <th>입금금액</th>
                        <th>수수료</th>
                        <th>위약금</th>
                        <th>입금금액</th>
                        <th>수수료</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, didx) in content" :key="didx">
                        <td>{{didx+1}}</td>
                        <td>{{data.brandName}}</td>
                        <td>{{data.storeName}}</td>
                        <td class="linkcmp text-center" @click="showDetails(data.salesFrom, data.salesTo, data.storeCode,'sales')">
                            {{data.transactionAmount | numFormat}}
                        </td>

                        <td>{{data.salesDeliveryAmount | numFormat}}</td>
                        <td>{{data.salesCommission | numFormat}}</td>

                        <td class='linkcmp text-center' @click="showDetails(data.salesFrom, data.salesTo, data.storeCode,'penalty')">{{data.penaltyAmount | numFormat}}</td>
                        <td>{{data.penaltyDeliveryAmount | numFormat}}</td>
                        <td>{{data.penaltyCommission | numFormat}}</td>
                        <td>{{data.totalDeliveryAmount | numFormat}}</td>
                        <td>{{data.totalCommission | numFormat}}</td>

                        <td>{{viewDate(data.salesFrom)}} ~ {{viewDate(data.salesTo)}}</td>
                        <td>{{viewDate(data.settlementDate)}}</td>
                        <td>{{getSttStatus(data.settlementStatus)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal" :style="detailsdlg && { display: 'block', 'z-index': '2000' }">
        <div class="modal-dialog modal-lg">
            <div class="box box-solid">
                <div class="box-header">
                    <!-- 거래금액 -->
                    <div class="input-group radio" v-if="radioType">
                        <label><input type="radio" value="goodSales" v-model="viewtype" />상품기준 보기</label>
                        <label><input type="radio" value="orderSales" v-model="viewtype" />사용일기준 보기</label>
                    </div>
                    <!-- 위약금 -->
                    <div class="input-group radio" v-else>
                        <label><input type="radio" value="good" v-model="viewtype" />상품기준 보기</label>
                        <label><input type="radio" value="order" v-model="viewtype" />사용일기준 보기</label>
                    </div>

                    <button v-if="viewtype == 'good' || viewtype === 'goodSales'" type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="goodExcel(viewtype)" :disabled="buttonDissabled">
                        <i class="fa fa-download"></i>Excel
                    </button>
                    <button v-if="viewtype == 'order' || viewtype === 'orderSales'" type="button" class="btn btn-default pull-right" style="margin-right: 10px" @click="orderExcel(viewtype)" :disabled="buttonDissabled">
                        <i class="fa fa-download"></i>Excel
                    </button>

                    <h3 class="box-title"></h3>
                    <div class="box-tools pull-right"></div>
                </div>
                <div class="box-body">
                    <div class="from-group" style="margin: 0px">
                        <div class="table-responsive p-0" style="height:200px">
                            <!-- 거래금액 -->
                            <table class="table table-hover text-nowrap" v-if="viewtype === 'goodSales'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">상품구분</th>
                                        <th class="text-center">상품명</th>
                                        <th class="text-center">옵션명</th>
                                        <th class="text-center">사용건수</th>
                                        <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th>
                                        <th class="text-center">입금가</th>
                                        <th class="text-center">마진</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(option, oidx) in goodsdata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                        <td class="text-center">{{ goodsTypeVal(option.goodsType) }}</td>
                                        <td class="text-center">{{option.goodsName}}</td>
                                        <td class="text-center">{{option.optionName }}</td>
                                        <td class="text-center">{{option.transactionCount | numFormat}}</td>
                                        <td class="text-center">{{ option.originalSalesAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesDeliveryAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesCommissionTotal | numFormat}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-hover text-nowrap" v-if="viewtype === 'orderSales'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">사용일시</th>
                                        <th class="text-center">상품구분</th>
                                        <th>상품명</th>
                                        <th>옵션명</th>
                                        <th class="text-center">정상가</th>
                                        <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th>
                                        <th class="text-center">마진</th>
                                        <th class="text-center">구매자</th>
                                        <th class="text-center">자체판매</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, oidx) in usedata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                        <td class="text-center">{{viewDate(order.useDate)}}</td>
                                        <td>{{goodsTypeVal(order.goodsType) }}</td>
                                        <td>{{order.goodsName }}</td>
                                        <td>{{order.optionName }}</td>
                                        <td class="text-center">{{order.listPrice | numFormat}}</td>
                                        <td class="text-center">{{order.originalSalePrice | numFormat}}</td>
                                        <td class="text-center">{{order.salePrice | numFormat}}</td>
                                        <td class="text-center">{{order.salesCommission | numFormat}}</td>
                                        <td class="text-center">{{order.buyerName }}</td>
                                        <td class="text-center">{{order.selfSales? "Y":"" }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- 위약금 -->
                            <table class="table table-hover text-nowrap" v-if="viewtype == 'good'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">상품구분</th>
                                        <th class="text-center">상품명</th>
                                        <th class="text-center">옵션명</th>
                                        <th class="text-center">취소건수</th>
                                        <!-- <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th> -->
                                        <th class="text-center">취소위약금</th>
                                        <th class="text-center">취소위약금입금가</th>
                                        <th class="text-center">취소위약금수수료</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(option, oidx) in goodsdata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                         <td class="text-center">{{ goodsTypeVal(option.goodsType) }}</td>
                                        <td class="text-center">{{option.goodsName}}</td>
                                        <td class="text-center">{{option.optionName }}</td>
                                        <td class="text-center">{{option.penaltyCount | numFormat}}</td>
                                        <!-- <td class="text-center">{{option.originalSalesAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.salesAmountTotal | numFormat}}</td> -->
                                        <td class="text-center">{{option.penaltyTotal | numFormat}}</td>
                                        <td class="text-center">{{option.penaltyDeliveryAmountTotal | numFormat}}</td>
                                        <td class="text-center">{{option.penaltyCommissionTotal | numFormat}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-hover text-nowrap" v-if="viewtype == 'order'">
                                <thead>
                                    <tr>
                                        <th class="text-center">번호</th>
                                        <th class="text-center">사용일시</th>
                                        <th class="text-center">상품구분</th>
                                        <th>상품명</th>
                                        <th>옵션명</th>
                                        <!-- <th class="text-center">정상가</th>
                                        <th class="text-center">판매가</th>
                                        <th class="text-center">실판매가</th> -->
                                        <th class="text-center">취소위약금</th>
                                        <th class="text-center">취소위약금입금가</th>
                                        <th class="text-center">취소위약금수수료</th>
                                        <th class="text-center">구매자</th>
                                        <th class="text-center">자체판매</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, oidx) in usedata" :key="oidx">
                                        <td class="text-center">{{ oidx + 1 }}</td>
                                        <td class="text-center">{{viewDate(order.useDate)}}</td>
                                        <td>{{goodsTypeVal(order.goodsType) }}</td>
                                        <td>{{order.goodsName }}</td>
                                        <td>{{order.optionName }}</td>
                                        <!-- <td class="text-center">{{order.listPrice | numFormat}}</td>
                                        <td class="text-center">{{order.originalSalePrice | numFormat}}</td>
                                        <td class="text-center">{{order.salePrice | numFormat}}</td> -->
                                        <td class="text-center">{{order.penalty | numFormat}}</td>
                                        <td class="text-center">{{order.penaltyDeliveryPrice | numFormat}}</td>
                                        <td class="text-center">{{order.penaltyCommission | numFormat}}</td>
                                        <td class="text-center">{{order.buyerName }}</td>
                                        <td class="text-center">{{order.selfSales? "Y":"" }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="box-footer text-center">
                    <button type="button" class="btn btn-default" @click="closeDetails()">닫기</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "@/lib/consts";
import routepath from "../../lib/routes";
import {
    reqGet,
    reqPost
} from "@/lib/request";
import {
    adminapi,
    brandapi,
    orderapi
} from "@/lib/api";
import {
    DateFormat,
    getGoodsTypeStr
} from "@/lib/utility";
import {
    MonthPicker
} from 'vue-month-picker'
import moment from 'moment';
import $ from 'jquery';
import XLSX from "xlsx";

export default {
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event)
                    }
                }
                document.body.addEventListener('click', el.event)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.event)
            }
        }
    },
    components: {
        MonthPicker
    },
    created() {
        this.$store.dispatch('doSetPath', {
            path1: menuval.LV1_AGENT,
            path2: menuval.LV1_HOME,
            title: "매장별 정산내역"
        });
    },
    data() {
        return {
            radioType: false,
            detailsdlg: false,
            // settlementDayOfMonth: 0,
            settlementMonth: "",
            defmonth: moment().month() + 1,
            defyear: moment().year(),
            monthpicker: false,
            settlementDates: [],
            content: [],
            stores: [],
            /**
             * brandCode                String    브랜드 코드
             * brandName                String    브랜드명
             * stores[].storeCode       String    매장 코드
             * stores[].storeName       String    매장명
             * stores[].settlements[].settlementId        Number    정산 ID
             * stores[].settlements[].settlementStatus    String    [SettlementUnavailable, NeedsAmountConfirm, AmountConfirmed, SettlementComplete]  정산 진행 상태. SettlementUnavailable: 아직 정산을 진행할 수 없음, NeedsAmountConfirm: 금액확정이 필요함, AmountConfirmed: 금액확정됨(입금대기), SettlementComplete: 정산완료(입금완료)
             * stores[].settlements[].salesFrom           String    yyyy-MM-dd  매출 시작일
             * stores[].settlements[].salesTo             String    yyyy-MM-dd  매출 종료일
             * stores[].settlements[].settlementDate      String    yyyy-MM-dd  정산일
             * stores[].settlements[].confirmedDate                   String    UTC yyyy-MM-ddTHH:mm:ss 금액 확정일 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].transactionAmount               String    확정 거래금액 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].deliveryAmount                  String    확정 입금금액 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].salesCommission                 String    확정 판매 수수료 (금액 확정이 이루어지지 않은 경우 null)
             * stores[].settlements[].transferredAmount               String    실 입금금액 (정산이 완료되지 않은 경우 null)
             * stores[].settlements[].completedDate                   String    UTC yyyy-MM-ddTHH:mm:ss   정산 완료일 (입금 완료일, 정산이 완료되지 않은 경우 null)
             * stores[].settlements[].memo                            String    메모
             */
            // selData: null,
            selectedAgent: null,
            agentList: [],

            usedata: [],
            goodsdata: [],
            viewtype: "good",
            buttonDissabled : false,
        };
    },
    computed: {
        routes() {
            return routepath;
        },
        authToken() {
            return this.$store.getters.authToken;
        },
        isAgent() {
            return this.$store.getters.isAgentRole;
        },
        isHostStaff() {
            return !this.$store.getters.isAgentRole;
        }
    },
    mounted() {
        if (this.isHostStaff) {
            this.getAgentList();
        }
    },
    methods: {
        goodsTypeVal(val) {
            return getGoodsTypeStr(val);
        },
        allckech(event) {
            $('input:checkbox.chitem').prop('checked', event.target.checked);
        },
        viewDate(val) {
            return DateFormat(val, "YYYY-MM-DD");
        },
        closeDetails() {
            this.detailsdlg = false;
        },
        showDetails(salesFrom, salesTo, storeCode, type) {
            this.usedata = [];
            this.goodsdata = [];
            if (type === 'sales') {
                this.viewtype = "goodSales";
                this.radioType = true;
            } else {
                this.viewtype = "good";
                this.radioType = false;
            }

            reqPost(
                orderapi.base,
                orderapi.agentstotalize,
                this.authToken, {
                    salesFrom: salesFrom,
                    salesTo: salesTo,
                    storeCode: storeCode
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {
                            for (let goodsIdx = 0; goodsIdx < result.data.goodsTotals.length; ++goodsIdx) {
                                let goods = result.data.goodsTotals[goodsIdx];

                                for (let optionIdx = 0; optionIdx < goods.options.length; ++optionIdx) {
                                    let option = goods.options[optionIdx];

                                    let t_data = {
                                        goodsName: goods.goodsName,
                                        goodsType: goods.goodsType,
                                        optionName: option.optionName,
                                        originalSalesAmountTotal: option.originalSalesAmountTotal,
                                        penaltyCount: option.penaltyCount,
                                        transactionCount: option.transactionCount,
                                        salesAmountTotal: option.salesAmountTotal,
                                        salesCommissionTotal: option.salesCommissionTotal,
                                        salesDeliveryAmountTotal: option.salesDeliveryAmountTotal,
                                        penaltyTotal: option.penaltyTotal,
                                        penaltyDeliveryAmountTotal: option.penaltyDeliveryAmountTotal,
                                        penaltyCommissionTotal: option.penaltyCommissionTotal,
                                    };
                                    this.goodsdata.push(t_data);
                                }
                            }

                            for (let dateIdx = 0; dateIdx < result.data.dates.length; ++dateIdx) {
                                const dateData = result.data.dates[dateIdx];

                                for (let goodsTypeIdx = 0; goodsTypeIdx < dateData.goodsTypes.length; ++goodsTypeIdx) {
                                    let goodsType = dateData.goodsTypes[goodsTypeIdx];

                                    // for (let goodsIdx = 0; goodsIdx < goodsType.goods.length; ++goodsIdx) {
                                    //   let goods = goodsType.goods[goodsIdx];
                                    //
                                    //   for (let optionIdx = 0; optionIdx < goods.options.length; ++optionIdx) {
                                    //     let option = goods.options[optionIdx];
                                    //
                                    //     let t_data = {
                                    //       goodsName: goods.goodsName,
                                    //       optionName: option.optionName,
                                    //       transactionCount: option.transactionCount,
                                    //       listPriceTotal: option.listPriceTotal,
                                    //       salesAmountTotal: option.salesAmountTotal,
                                    //       deliveryAmountTotal: option.deliveryAmountTotal,
                                    //       salesCommissionTotal: option.salesCommissionTotal,
                                    //     };
                                    //     this.goodsdata.push(t_data);
                                    //   }
                                    // }

                                    for (let orderIdx = 0; orderIdx < goodsType.orders.length; ++orderIdx) {
                                        let order = goodsType.orders[orderIdx];

                                        for (let orderItemIdx = 0; orderItemIdx < order.orderItems.length; ++orderItemIdx) {
                                            let orderItem = order.orderItems[orderItemIdx];

                                            let t_data = {
                                                useDate: orderItem.useDate,
                                                itemNo: orderItem.itemNo,
                                                goodsType: orderItem.goodsType,
                                                originalSalePrice: orderItem.originalSalePrice,
                                                penalty: orderItem.penalty,
                                                penaltyDeliveryPrice: orderItem.penaltyDeliveryPrice,
                                                penaltyCommission: orderItem.penaltyCommission,
                                                goodsName: orderItem.goodsName,
                                                optionName: orderItem.optionName,
                                                listPrice: orderItem.listPrice,
                                                salePrice: orderItem.salePrice,
                                                salesCommission: orderItem.salesCommission,
                                                buyerName: orderItem.buyerName,
                                                selfSales: orderItem.selfSales,
                                            };

                                            this.usedata.push(t_data);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            );

            // this.selData = sdata;
            this.detailsdlg = true;
        },
        selMonth(date) {
            this.settlementMonth = date.year + "-" + date.monthIndex.toString().padStart(2, 0);
            // this.monthpicker = false;
        },
        onShowMonthPicker() {
            this.monthpicker = !this.monthpicker;
        },
        hideMonthPicker() {
            this.monthpicker = false;
        },
        getAgentList() {
            reqGet(
                adminapi.base,
                adminapi.agentlist,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.agentList = result.data.admins;
                            this.selectedAgent = this.agentList[0];
                            this.refreshPage();
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        refreshPage() {
            if (this.isAgent) {
                reqPost(
                    orderapi.base,
                    orderapi.agentquery,
                    this.authToken, {
                        settlementMonth: this.settlementMonth
                        // settlementDayOfMonth: this.settlementDayOfMonth,
                    },
                    async (result) => {
                        if (result != null) {
                            if (result.code === rescode.REQ_SUCCESS) {
                                await this.converExcel(result.data.brands);
                                if (this.stores.length > 0) {
                                    await this.refreshPageAdd();
                                }

                            } else {
                                this.content = [];
                                alert(result.message);
                            }
                        }
                    }
                );
            } else {
                if (this.selectedAgent == null || this.selectedAgent.username == null) {
                    alert("에이전시를 선택하세요.");
                } else {
                    reqPost(
                        orderapi.base,
                        orderapi.tnjagentquery,
                        this.authToken, {
                            settlementMonth: this.settlementMonth,
                            username: this.selectedAgent.username,
                        },
                        async (result) => {
                            if (result != null) {
                                if (result.code === rescode.REQ_SUCCESS) {
                                    await this.converExcel(result.data.brands);
                                    if (this.stores.length > 0) {
                                        await this.refreshPageAdd();
                                    }

                                } else {
                                    this.content = [];
                                    alert(result.message);
                                }
                            }
                        }
                    );
                }
            }
        },
        refreshPageAdd() {
            reqPost(
                brandapi.base,
                brandapi.tnjbankbooks,
                this.authToken, {
                    storeCodes: this.stores,
                },
                async (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            await this.converExcelAdd(result.data);
                        }
                    }
                }
            );
        },
        converExcelAdd(stroeBankInfo) {
            this.content.forEach((store, index) => {
                let findStoreBank = stroeBankInfo.find(o => o.storeCode == store.storeCode)
                this.content[index].storeBank = findStoreBank.bankbook.bankName.trim() + " / " + findStoreBank.bankbook.accountNumber.trim() + " / " + findStoreBank.bankbook.accountHolder.trim()
            })
        },
        converExcel(brands) {
            this.content = [];
            for (var bidx = 0; bidx < brands.length; bidx++) {
                for (var sidx = 0; sidx < brands[bidx].stores.length; sidx++) {
                    for (var tidx = 0; tidx < brands[bidx].stores[sidx].settlements.length; tidx++) {
                        this.stores.push(brands[bidx].stores[sidx].storeCode)

                        var t_data = {
                            ...brands[bidx].stores[sidx].settlements[tidx],
                            brandCode: brands[bidx].brandCode,
                            brandName: brands[bidx].brandName,
                            tnjManagerID: brands[bidx].tnjManagerID,
                            storeCode: brands[bidx].stores[sidx].storeCode,
                            storeName: brands[bidx].stores[sidx].storeName,
                        };
                        if (t_data.salesDeliveryAmount == null) {
                            t_data.salesDeliveryAmount = 0;
                        }
                        if (t_data.salesCommission == null) {
                            t_data.salesCommission = 0;
                        }
                        if (t_data.transactionAmount == null) {
                            t_data.transactionAmount = 0;
                        }
                        if (t_data.transferredAmount == null) {
                            t_data.transferredAmount = 0;
                        }
                        this.content.push(t_data);
                    }
                }
            }
        },
        goodconvert(gdata, gidx) {
            if (this.viewtype == 'goodSales') {
                return {
                    A: gidx + 1,
                    B: this.goodsTypeVal(gdata.goodsType),
                    C: gdata.goodsName,
                    D: gdata.optionName,
                    E: gdata.transactionCount,
                    F: gdata.originalSalesAmountTotal,
                    G: gdata.salesAmountTotal,
                    H: gdata.salesDeliveryAmountTotal,
                    J: gdata.salesCommissionTotal,
                };
            } else {
                return {
                    A: gidx + 1,
                    B: this.goodsTypeVal(gdata.goodsType),
                    C: gdata.goodsName,
                    D: gdata.optionName,
                    E: gdata.penaltyCount,
                    // F: gdata.originalSalesAmountTotal,
                    // G: gdata.salesAmountTotal,
                    H: gdata.penaltyTotal,
                    J: gdata.penaltyDeliveryAmountTotal,
                    K: gdata.penaltyCommissionTotal,
                };
            }

        },
        goodExcel(viewtype) {
          this.buttonDissabled = true;
            var excelheader = null;
            if (viewtype == 'goodSales') {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        B: "상품구분",
                        C: "상품명",
                        D: "옵션명",
                        E: "사용건수",
                        F: "판매가",
                        G: "실판매가",
                        H: "입금가",
                        J: "마진",
                    }, ], {
                        skipHeader: true
                    }
                );
            } else {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        B: "상품구분",
                        C: "상품명",
                        D: "옵션명",
                        E: "취소건수",
                        // F: "판매가",
                        // G: "실판매가",
                        H: "취소위약금",
                        J: "취소위약금입금가",
                        K: "취소위약금수수료",
                    }, ], {
                        skipHeader: true
                    }
                );

            }

            XLSX.utils.sheet_add_json(excelheader, this.goodsdata.map(this.goodconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "상품기준 매장별 정산내역 ");
            // export Excel file
            XLSX.writeFile(wb, "상품기준 매장별 정산내역.xlsx");
          this.buttonDissabled = false;
        },
        orderconvert(odata, oidx) {
            if (this.viewtype === 'orderSales') {
                return {
                    A: oidx + 1,
                    B: this.viewDate(odata.useDate),
                    C: this.goodsTypeVal(odata.goodsType),
                    D: odata.goodsName,
                    E: odata.optionName,
                    F: odata.listPrice,
                    G: odata.originalSalePrice,
                    H: odata.salePrice,
                    J: odata.salesCommission,
                    I: odata.buyerName,
                    K: odata.selfSales ? "Y" : "",
                };
            } else {
                return {
                    A: oidx + 1,
                    B: this.viewDate(odata.useDate),
                    C: this.goodsTypeVal(odata.goodsType),
                    D: odata.goodsName,
                    E: odata.optionName,
                    // F: odata.listPrice,
                    // G: odata.originalSalePrice,
                    // H: odata.salePrice,
                    J: odata.penalty,
                    I: odata.penaltyDeliveryPrice,
                    K: odata.penaltyCommission,
                    L: odata.buyerName,
                    M: odata.selfSales ? "Y" : "",
                };
            }

        },
        orderExcel(viewtype) {
          this.buttonDissabled = true;
            var excelheader = null;
            if (viewtype === 'orderSales') {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        B: "사용일시",
                        C: "상품구분",
                        D: "상품명",
                        E: "옵션명",
                        F: "정상가",
                        G: "판매가",
                        H: "실판매가",
                        J: "마진",
                        I: "구매자",
                        K: "자체판매",
                    }, ], {
                        skipHeader: true
                    }
                );
            } else {
                excelheader = XLSX.utils.json_to_sheet(
                    [{
                        A: "번호",
                        B: "사용일시",
                        C: "상품구분",
                        D: "상품명",
                        E: "옵션명",
                        // F: "정상가",
                        // G: "판매가",
                        // H: "실판매가",
                        J: "취소위약금",
                        I: "취소위약금입금가",
                        K: "취소위약금수수료",
                        L: "구매자",
                        M: "자체판매",
                    }, ], {
                        skipHeader: true
                    }
                );
            }

            XLSX.utils.sheet_add_json(excelheader, this.usedata.map(this.orderconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "사용일기준 매장별 정산내역 ");
            // export Excel file
            XLSX.writeFile(wb, "사용일기준 매장별 정산내역.xlsx");
          this.buttonDissabled = false;
        },
        getSttStatus(val) {
            if (val == 'SettlementUnavailable') {
                return "";
            } else if (val == 'NeedsAmountConfirm') {
                return "확정대기";
            } else if (val == 'AmountConfirmed') {
                return "입금대기";
            } else if (val == 'SettlementComplete') {
                return "입금완료";
            } else {
                return "";
            }
        },
        dataconvert(tdata, tidx) {
            return {
                A: tidx + 1,
                B: tdata.brandName,
                C: tdata.brandCode,
                D: tdata.storeName,
                E: tdata.storeCode,
                F: tdata.transactionAmount,
                G: tdata.salesDeliveryAmount,
                H: tdata.salesCommission,
                I: tdata.penaltyAmount,
                J: tdata.penaltyDeliveryAmount,
                K: tdata.penaltyCommission,
                L: tdata.totalDeliveryAmount,
                M: tdata.totalCommission,
                N: this.viewDate(tdata.salesFrom) + " ~ " + this.viewDate(tdata.salesTo),
                O: this.viewDate(tdata.settlementDate),
                P: this.getSttStatus(tdata.settlementStatus),
            };
        },
        downExcel() {
            var excelheader = XLSX.utils.json_to_sheet(
                [{
                    A: "번호",
                    B: "브랜드",
                    C: "브랜드코드",
                    D: "매장",
                    E: "매장코드",
                    F: "거래금액",
                    G: "입금금액",
                    H: "수수료",
                    I: "위약금",
                    J: "위약금입금금액",
                    K: "위약금수수료",
                    L: "총입금금액",
                    M: "총수수료",
                    N: "정산기준일",
                    O: "정산일",
                    P: "정산현황",
                }, ], {
                    skipHeader: true
                }
            );

            XLSX.utils.sheet_add_json(excelheader, this.content.map(this.dataconvert), {
                skipHeader: true,
                origin: "A2",
            });
            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            XLSX.utils.book_append_sheet(wb, excelheader, "입금완료처리");
            // export Excel file
            XLSX.writeFile(wb, "입금관리-입금완료처리.xlsx");
        }
    }
};
</script>

<style scoped>
.checkbox>label {
    margin-right: 10px;
}

.radio>label {
    margin-right: 10px;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.modal-body .control-label {
    margin: 0px;
}

.modal .table {
    margin-bottom: 0px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

th {
    text-align: center;
}

th {
    background-color: #dee2e685;
    vertical-align: middle !important;
    ;
}

td {
    text-align: center;
}

.month-picker__container {
    position: absolute;
    top: 3.5em;
    background-color: #fff;
    z-index: 9999;
}
</style>
