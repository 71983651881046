<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <b>T</b>ABLENJOY <b>M</b>ANAGER
      </div>
      <div class="login-box-body">
        <p class="box-title">관리자 가입 신청</p>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon adduserid"></span>
              <input type="text" class="form-control" v-model="userid" placeholder="아이디">
              <button type="button" class="btn btn-primary smsbtn" @click="checkUserId">중복확인</button>
            </div>
            <span class="text-red">{{userrmsg}}</span>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon addpassword"></span>
              <input type="password" class="form-control" v-model="password" placeholder="비밀번호">
            </div>
            <span class="text-red">{{pswerrmsg}}</span>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon addpassword"></span>
              <input type="password" class="form-control" v-model="repassword" placeholder="비밀번호 확인">
            </div>
            <span class="text-red">{{repswerrmsg}}</span>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon addname"></span>
              <input type="text" class="form-control" v-model="username" placeholder="이름">
            </div>
            <span class="text-red">{{namemsg}}</span>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon addemail"></span>
              <input type="text" class="form-control" v-model="email" placeholder="이메일">
            </div>
            <span class="text-red">{{emailmsg}}</span>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon addphone"></span>
              <div class="col-sm-3">
                <select class="form-control" v-model="phonehead">
                  <option>010</option>
                  <option>011</option>
                </select>
              </div>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="phonenum"
                  placeholder="휴대전화"
                  style="width:auto"
                />
              </div>
            </div>
            <span class="text-red">{{phonemsg}}</span>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-primary btn-block" @click="clickSignup">가입신청</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rescode } from "../../lib/consts";
import { isMobileNum } from "../../lib/utility";
import { reqPost, reqGet } from "../../lib/request";
import { adminapi } from "../../lib/api";
import routes from "../../lib/routes";
export default {
  data() {
    return {
      userid: "",
      userrmsg: "",
      password: "",
      pswerrmsg: "",
      repassword: "",
      repswerrmsg: "",
      username: "",
      namemsg: "",
      email: "",
      emailmsg: "",
      phonenum: "",
      phonemsg: "",
      phonehead: "010",
    };
  },
  updated() {},
  created() {},
  mounted() {},
  methods: {
    clickSignup() {
      if (this.userid == "") {
        this.userrmsg = "아이디를 입력하세요.";
        return;
      } else {
        this.userrmsg = "";
      }

      if (this.password == "") {
        this.pswerrmsg = "비밀번호를 입력하세요.";
        return;
      } else {
        this.pswerrmsg = "";
      }

      if (this.password != this.repassword) {
        this.repswerrmsg = "비밀번호가 일치 하지 않습니다.";
        return;
      } else {
        this.repswerrmsg = "";
      }

      if (this.username == "") {
        this.namemsg = "이름을 입력하세요.";
        return;
      } else {
        this.namemsg = "";
      }

      if (this.email == "") {
        this.emailmsg = "이메일을 입력해주세요.";
        return;
      } else {
        this.emailmsg = "";
      }

      if (this.phonenum == "") {
        this.phonemsg = "휴대전화를 입려해주세요.";
        return;
      } else if (!isMobileNum(this.phonehead + this.phonenum)) {
        this.phonemsg = "휴대폰 번호가 아닙니다.";
        return;
      } else {
        this.phonemsg = "";
      }

      reqPost(
        adminapi.base,
        adminapi.register,
        "",
        {
          username: this.userid,
          password: this.password,
          fullName: this.username,
          email: this.email,
          phoneNumber: this.phonehead + this.phonenum,
          storeCode: null,
        },
        (data) => {
          if (data != null) {
            this.userrmsg = data.message;
            if (data.code == rescode.REQ_SUCCESS) {
              this.$router.push(routes.login);
            }
          } else {
            this.userrmsg = "로그인 실패 되었습니다.";
          }
        }
      );
    },
    checkUserId() {
      if (this.userid == "") {
        this.userrmsg = "아이디를 입력하세요.";
        return;
      } else {
        this.userrmsg = "";
        reqGet(
          adminapi.base,
          adminapi.checkusrname + "?username=" + this.userid,
          null,
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                if(result.data)
                {
                  alert("사용할 수 없는 아이디입니다.");
                }else{
                  alert("사용할 수 있는 아이디입니다.");
                }
              }
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.login-page{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.box-title{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.adduserid{
  background-image: url("/static/img/uaserid.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addphone{
  background-image: url("/static/img/phone.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addpassword{
  background-image: url("/static/img/password.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addname{
  background-image: url("/static/img/username.png");
  background-repeat: no-repeat;
  background-position: center;
}
.addemail{
  background-image: url("/static/img/email.png");
  background-repeat: no-repeat;
  background-position: center;
}
.smsbtn{
  position: absolute;
  right: 0;
  z-index: 100;
}
.btn{
  background-color: #333333;
  border-radius: 100px;
}

.input-group-addon, .form-control{
  border: 0px;
}

.form-control{
  padding: 6px 0px;
}

.input-group{
  border-bottom: 1px solid #b2b2b2;
}
</style>