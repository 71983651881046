<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;align-items:center">
          <div class="radio" style="margin:0px;margin-right:10px">
            <label>
              <input type="radio" value="A" v-model="onSale"/>전체
            </label>
            <label>
              <input type="radio" value="T" v-model="onSale"/>판매중
            </label>
            <label>
              <input type="radio" value="F" v-model="onSale"/>일시중지
            </label>
          </div>
          <select class="form-control" v-model="channelCode" @change="getchannelInfo"
                  style="width:auto;margin-right:10px">
            <option v-for="chitem in channels" :key="chitem.channelCode" :value="chitem.channelCode">
              {{ chitem.channelName }}
            </option>
          </select>
          <select class="form-control" v-model="keywordType" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="GoodsId">상품코드</option>
            <option value="GoodsNameContaining">상품명</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <button class="btn btn-default" @click="tmonMatchExcel" v-if="channelCode == 'TMON'">티몬 매칭리스트</button>
      <button class="btn btn-default" @click="showtmondlg" v-if="channelCode == 'TMON'">티몬 정산데이터 조회</button>
      <button class="btn btn-default" @click="gmkMatchExcel" v-if="channelCode == 'GMK'">G마켓 매칭리스트</button>
      <button class="btn btn-default" @click="coupangModalOpen" v-if="channelCode == 'COUPANG'"><i class="fa fa-download"></i> 쿠팡 상품리스트</button>
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
      <button type="button" class="btn btn-default pull-right" style="margin-right:10px" @click="goodsMatchExcel">
        <i class="fa fa-download"></i> 상품 매칭리스트
      </button>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
          <tr>
            <th class="text-center">상품코드</th>
            <th>상품명</th>
            <th class="text-center">상품상태</th>
            <th class="text-center" v-if="channelCode == 'TMON'">채널 상품코드</th>
            <th class="text-center" v-if="channelCode == 'TMON'">채널 상품상태</th>
            <th class="text-center" v-if="channelCode == 'TMON'">채널 판매기간</th>
            <th class="text-center">메모</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="mitem in content" v-bind:key="mitem.goodsId">
            <td class="text-center">{{ mitem.goodsId }}</td>
            <td class="linkcmp" @click="godetailpage(mitem.goodsId)">{{ mitem.goodsName }}</td>
            <td class="text-center">{{ getSaleState(mitem.deleted, mitem.onSale) }}</td>
            <td class="text-center" v-if="channelCode == 'TMON'">{{ mitem.matchGoodsCode }}</td>
            <td class="text-center" v-if="channelCode == 'TMON'">{{ getSaleState(false, mitem.matchOnSale) }}</td>
            <td class="text-center" v-if="channelCode == 'TMON'">{{ viewDate(mitem.displayStartDate) }} ~
              {{ viewDate(mitem.displayEndDate) }}
            </td>
            <td class="text-center">
              <button type="button" class="btn btn-default" @click="openMemo(mitem.goodsId)" >상세</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" v-if="tmondlg" :style="{'display': 'block'}" >
      <div class="modal-dialog" style="width: 20%">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closetmondlg"><span
              aria-hidden="true">×</span></button>
            <h4 class="modal-title">티몬 정산데이터 조회</h4>
          </div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="display:inline-flex;margin:0px">
              <DatePicker v-model="startDate" />
              <!-- <InputMask mask="9999-99-99" class="form-control" v-model="startDate"/> -->
              <span style="margin:0px 10px">~</span>
              <DatePicker v-model="endDate" />
              <!-- <InputMask mask="9999-99-99" class="form-control" v-model="endDate"/> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="downexceldata">Excel 다운로드</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="coupangModal" :style="{ 'display': 'block' }" >
      <div class="modal-dialog" style="width: 20%">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeCoupangModal"><span
              aria-hidden="true">×</span></button>
            <h4 class="modal-title">쿠팡 상품리스트</h4>
          </div>
          <div class="modal-body form-horizontal">
            <div class="form-group" style="display:inline-flex;margin:0px">
              <DatePicker v-model="coupangStartDate" />
              <!-- <InputMask mask="9999-99-99" class="form-control" v-model="startDate"/> -->
              <span style="margin:0px 10px">~</span>
              <DatePicker v-model="coupangEndDate" />
              <!-- <InputMask mask="9999-99-99" class="form-control" v-model="endDate"/> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="downexceldata" :disabled="buttonDissabled">Excel 다운로드</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal" v-if="memoToggle" :style="{ 'display': 'block' }">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">상품 메모</div>
          <div class="modal-body form-horizontal" >
            <textarea class="form-control" rows="10" disabled v-model="productMemo"></textarea>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeMemo">닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../../lib/consts";
import routepath from "../../../lib/routes";
import Pagination from "../../../components/Pagination";
import {reqGet} from "../../../lib/request";
import {b2bapi, goodsapi} from "../../../lib/api";
import {DateFormat, getGoodsTypeStr} from "../../../lib/utility";
import DatePicker from "../../../components/DatePicker";
import InputMask from "vue-input-mask";
import XLSX from "xlsx";

export default {
  components: {
    Pagination,
    InputMask,
    DatePicker,
  },
  created() {
    if(localStorage.initval == "1") {
      if(localStorage.B2BCMT_keywordType) {
        this.keywordType = localStorage.B2BCMT_keywordType;
      }
      if(localStorage.B2BCMT_keyword) {
        this.keyword = localStorage.B2BCMT_keyword;
      }
      if(localStorage.B2BCMT_Number) {
        this.pageNumber = parseInt(localStorage.B2BCMT_Number);
      }
      if(localStorage.B2BCMT_Size) {
        this.pageSize = parseInt(localStorage.B2BCMT_Size);
      }
      if(localStorage.B2BCMT_channelCode) {
        this.channelCode = localStorage.B2BCMT_channelCode;
      }
      if(localStorage.B2BCMT_onSale) {
        this.onSale = localStorage.B2BCMT_onSale;
      }
    }
    this.$store.dispatch("doSetPath", {path1: menuval.LV1_PRD, path2: menuval.LV2_PRD_B2B, title: "코드매칭관리"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.B2BCMT_keywordType = newVal;
    },
    keyword(newkey) {
      localStorage.B2BCMT_keyword = newkey;
    },
    pageNumber(newnum) {
      localStorage.B2BCMT_Number = newnum;
    },
    pageSize(newsize) {
      localStorage.B2BCMT_Size = newsize;
    },
    channelCode(newfrom) {
      console.log(newfrom);
      localStorage.B2BCMT_channelCode = newfrom;
    },
    onSale(newsale) {
      localStorage.B2BCMT_onSale = newsale;
    },
  },
  data() {
    return {
      coupangModal:false,
      shownum: 10, //pagination에 보여지는 번호개수
      channelCode: "",
      keywordType: "NoKeyword",
      keyword: "",
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * goodsId              Number        상품 ID
       * goodsName            String        상품명
       * onSale               Boolean       판매 여부
       * deleted              Boolean       삭제 여부
       * matchGoodsCode       String        채널상품코드
       * matchOnSale          Boolean       채널판매상태
       * displayStartDate     String        채널 판매기간 시작일
       * displayEndDate       String        채널 판매기간 종료일
       */

      /**
       * goodsId              Number        상품 ID
       * goodsName            String        상품명
       * onSale               Boolean       판매 여부
       * deleted              Boolean       삭제 여부
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      tmondlg: false,
      channelpubInfo: {
        channelCode: "",//채널코드
        channelName: "",//채널명
        channelType: "",//채널타입[Tablenjoy, B2bIframe, B2bChannel]
        mypageCancelable: false,//마이페이지 취소가능 여부
        goodsSalesManage: false,//상품 판매관리 여부
        goodsDistributeManage: false,//상품 배포관리 여부
        channelImageManage: false,//채널 이미지관리 여부
        goodsDistributeBatch: false,//상품 일괄배포 가능 여부
        goodsInterworking: false,//관리자 상품연동 여부
        channelCallCenter: "",//채널 고객센터 번호
      },
      channels: [],
      onSale: "A",
      startDate: "",
      endDate: "",
      coupangEndDate:'',
      coupangStartDate:'',
      tmonexcel: [],
      /**
       * b2bOrderNo                 String    B2B 주문번호
       * b2bDetailOrderNo           String    B2B 주문상세번호
       * goodsName                  String    상품명
       * optionName                 String    옵션명
       * salesDateTime              String    매출일시
       * payDueDate                 String    정산예정일자
       * settleDealDetailType       String    상세거래구분코드
       * settleDealType             String    거래구분코드
       * sellAmount                 String    판매금액
       * payBaseAmount              String    정산기준금액
       * payRate                    String    정산율
       * vendorAmount               String    정산파트너금액
       * tmonAmount                 String    정산티몬금액
       * txSeqNo                    String    거래일련번호
       * partnerNo                  String    파트너번호
       */
      coupageNumber: 1,
      coupangexcel: [],
      /**
       * productId              String    상품코드
       * travelItemId           String    옵션코드
       * productName            String    상품명
       * travelItemName         String    옵션명
       * productSaleStatus      String    상품판매상태
       * travelItemSaleStatus   String    옵션판매상태
       * useStart               String    유효기간 시작일
       * useEnd                 String    유효기간 종료일
       * travelItemPrice        String    판매가
       */
      tmonMatchExcelList: [],
      gmkMatchExcelList: [],
      goodsMatchExcelList: [],
      buttonDissabled : false,
      memoToggle:false,
      productMemo:"",
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0") {
      localStorage.initval = "1";
      localStorage.B2BCMT_keywordType = this.keywordType;
      localStorage.B2BCMT_keyword = this.keyword;
      localStorage.B2BCMT_Number = this.pageNumber;
      localStorage.B2BCMT_Size = this.pageSize;
      localStorage.B2BCMT_channelCode = this.channelCode;
      localStorage.B2BCMT_onSale = this.onSale;
    }
    this.getChannelData();
  },
  methods: {
    coupangModalOpen(){
      this.coupangModal = true;
    },
    closeCoupangModal(){
      this.coupangModal = false;
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD");
    },
    viewDateTime(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:ss");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      var url = b2bapi.chnlmatchpage;
      if(this.channelCode == 'TMON') {
        url = b2bapi.tmonmatchpage;
      }
      var onSale = "";
      if(this.onSale == "T") {
        onSale = true;
      } else if(this.onSale == "F") {
        onSale = false;
      }
      reqGet(
        b2bapi.base,
        url +
        "?channelCode=" + this.channelCode + "&keywordType=" +
        this.keywordType +
        "&keyword=" +
        this.keyword +
        "&onSale=" +
        onSale +
        "&pageNumber=" +
        pageNumber +
        "&pageSize=" +
        this.pageSize,
        this.authToken,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
              /*
              data.data.size : 페이지 사이즈
              data.data.number : 페이지 번호
              data.data.first : 첫 페이지일 경우 true
              data.data.last : 마지막 페이지일 경우 true
              data.data.empty : 조회된 아이템이 없으면 true
               */
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    downExcel() {
    },
    closetmondlg() {
      this.tmondlg = false;
    },
    showtmondlg() {
      this.tmondlg = true;
    },
    downexceldata() {
      this.buttonDissabled = true;
      if(this.channelCode == 'TMON') {
        reqGet(
          b2bapi.base,
          b2bapi.tmonexcellist + "?startDate=" + this.startDate + "&endDate=" + this.endDate,
          this.authToken,
          (result) => {
            if(result != null) {
              if(result.code == rescode.REQ_SUCCESS) {
                this.tmonexcel = result.data;
                this.downTMONExcel();
              } else {
                this.buttonDissabled = false;
                alert(result.message);
              }
            }
          }
        );
      } else if(this.channelCode == 'COUPANG') {
        if(this.$moment(this.coupangStartDate, "YYYY-MM-DD").add(6, 'days') < this.$moment(this.coupangEndDate, "YYYY-MM-DD")){
          alert('최대 7일까지 엑셀 다운가능합니다.');
          return;
        }
        reqGet(
          b2bapi.base,
          b2bapi.coupangexcel + "?totalPageAllowed=false"
          + '&startDate=' + this.coupangStartDate
          + '&endDate=' + this.coupangEndDate
          ,
          this.authToken,
          (result) => {
            if(result != null) {
              if(result.code == rescode.REQ_SUCCESS) {
                this.coupangexcel = result.data;
                this.downCoupangExcel();
              } else {
                this.buttonDissabled = false;
                alert(result.message);
              }
            }
          }
        );
      }
    },
    goodsMatchExcel() {
      this.buttonDissabled = true;
        var onSale = "";
        if (this.onSale === "T") onSale = true;
        if (this.onSale === "F") onSale = false;

        reqGet(
            b2bapi.base,
            b2bapi.chnlmatchexcel + "?keywordType=" + this.keywordType + "&keyword=" + this.keyword + "&onSale=" + onSale,
            this.authToken,
            (result) => {
                if (result != null) {
                    if (result.code == rescode.REQ_SUCCESS) {
                        this.goodsMatchExcelList = result.data;
                        this.downGOODSMatchExcel();
                    } else {
                        this.buttonDissabled = false;
                        alert(result.message);
                    }
                }
            }
        );
    },
    gmkMatchExcel() {
      this.buttonDissabled = true;
      var onSale = "";
      if (this.onSale === "T") onSale = true;
      if (this.onSale === "F") onSale = false;

      reqGet(
        b2bapi.base,
        b2bapi.gmkMatchExcel + "?keywordType=" + this.keywordType + "&keyword=" + this.keyword + "&onSale=" + onSale,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.gmkMatchExcelList = result.data;
              this.downGmkMatchExcel();
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    },
    downGmkMatchExcel() {
      var gmkHeader = XLSX.utils.json_to_sheet(
        [
          {
            A: "채널 상품코드",
            B: "채널 상품명",
            C: "카테고리",
            D: "상품상태",
            E: "옵션상태",
            F: "상품코드",
            G: "옵션코드",
            H: "상품유형",
            I: "상품명",
            J: "옵션명",
            K: "정상가",
            L: "판매가",
            M: "판매기간",
            N: "유효기간",
            O: "수량제한",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(gmkHeader, this.gmkMatchExcelList.map(this.gmkMatchConvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, gmkHeader, "GMK_MATCHING_LIST");
      // export Excel file
      XLSX.writeFile(wb, "GMK_MATCHING_LIST.xlsx");
      this.buttonDissabled = false;
    },

    gmkMatchConvert(item) {
      return {
        A: item.channelGoodsCode,
        B: item.channelGoodsName,
        C: item.categoryCodeName,
        D: item.goodsOnSale ? "판매중" : "일시중지",
        E: item.optionOnSale? "판매중" : "일시중지",
        F: item.goodsId ,
        G: item.optionId ,
        H: item.goodsType ,
        I: item.goodsName ,
        J: item.optionName ,
        K: item.listPrice ,
        L: item.salePrice ,
        M: item.saleDate ,
        N: item.availableDate,
        O: !item.orderLimitPeriod && !item.orderLimitPeriod? '' : `${!item.orderLimitPeriod? 0: item.orderLimitPeriod}일 동안 최대 ${!item.orderLimitCount? 0: item.orderLimitCount}개`
      };
    },
    tmonMatchExcel() {
        var onSale = "";
        if (this.onSale === "T") onSale = true;
        if (this.onSale === "F") onSale = false;

        reqGet(
            b2bapi.base,
            b2bapi.tmonmatchexcel + "?keywordType=" + this.keywordType + "&keyword=" + this.keyword + "&onSale=" + onSale,
            this.authToken,
            (result) => {
                if (result != null) {
                    if (result.code == rescode.REQ_SUCCESS) {
                        this.tmonMatchExcelList = result.data;
                        this.downTMONMatchExcel();
                    } else {
                        alert(result.message);
                    }
                }
            }
        );
      },
      tmonconvert(item) {
      return {
        A: item.b2bOrderNo,
        B: item.b2bDetailOrderNo,
        C: item.goodsName,
        D: item.optionName,
        E: DateFormat(item.salesDateTime, "YYYY-MM-DD HH:mm:00"),
        F: DateFormat(item.payDueDate, "YYYY-MM-DD"),
        G: item.settleDealDetailType,
        H: item.settleDealType,
        I: item.sellAmount,
        J: item.payBaseAmount,
        K: item.payRate,
        L: item.vendorAmount,
        M: item.tmonAmount,
        N: item.txSeqNo,
        O: item.partnerNo,
      };
    },
    downTMONExcel() {
      var tmonheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "B2B 주문번호",
            B: "B2B 주문상세번호",
            C: "상품명",
            D: "옵션명",
            E: "매출일시",
            F: "정산예정일자",
            G: "상세거래구분코드",
            H: "거래구분코드",
            I: "판매금액",
            J: "정산기준금액",
            K: "정산율",
            L: "정산파트너금액",
            M: "정산티몬금액",
            N: "거래일련번호",
            O: "파트너번호",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(tmonheader, this.tmonexcel.map(this.tmonconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, tmonheader, "티몬 정산데이터");
      // export Excel file
      XLSX.writeFile(wb, "티몬 정산데이터.xlsx");
      this.buttonDissabled = false;
    },
    coupangconvert(item) {
      return {
        A: item.productId,
        B: item.productName,
        C: item.qcStatus,
        D: item.saleStatus,
        E: item.itemName,
        F: item.vendorItemId,
        G: item.itemOnSale,
        H: item.itemDeleted
      };
    },
    downCoupangExcel() {
      var coupangheader = XLSX.utils.json_to_sheet(
        [
          {
            A: "바우처ID",
            B: "상품명",
            C: "상품승인상태",
            D: "상품판매상태",
            E: "옵션명",
            F: "옵션코드",
            G: "옵션판매상태",
            H: "옵션삭제여부",
          },
        ],
        {skipHeader: true}
      );
      XLSX.utils.sheet_add_json(coupangheader, this.coupangexcel.map(this.coupangconvert), {
        skipHeader: true,
        origin: "A2",
      });
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      XLSX.utils.book_append_sheet(wb, coupangheader, "쿠팡 상품리스트");
      // export Excel file
      XLSX.writeFile(wb, "쿠팡 상품리스트.xlsx");
      this.buttonDissabled = false;
    },
    tmonmatchconvert(item) {
        return {
            A: item.goodsId,
            B: item.channelGoodsName,
            C: item.promotion,
            D: item.categoryCode,
            E: DateFormat(item.displayStartDate, "YYYY-MM-DD"),
            F: DateFormat(item.displayEndDate, "YYYY-MM-DD"),
            G: DateFormat(item.expirationStartDate, "YYYY-MM-DD"),
            H: DateFormat(item.expirationEndDate, "YYYY-MM-DD"),
            I: item.vendorPolicyNo,
            J: getGoodsTypeStr(item.refundImageType),
            K: item.mainImageUrl,
            L: item.homeRecommendedImage,
            M: item.matchOnSale ? "판매중" : "판매중지",
            N: item.categoryCodeName
        };
    },
    downTMONMatchExcel() {
        var tmonheader = XLSX.utils.json_to_sheet(
            [
                {
                    A: "상품코드",
                    B: "상품명",
                    C: "꾸밈문구",
                    D: "카테고리코드",
                    E: "판매기간 시작일",
                    F: "판매기간 종료일",
                    G: "유효기간 시작일",
                    H: "유효기간 종료일",
                    I: "수수료 정책",
                    J: "취소환불 타입",
                    K: "채널 대표 이미지 URL",
                    L: "홈 추천 이미지 URL",
                    M: "채널 상품상태",
                    N: "카테고리 명",
                },
            ],
            {skipHeader: true}
        );
        XLSX.utils.sheet_add_json(tmonheader, this.tmonMatchExcelList.map(this.tmonmatchconvert), {
            skipHeader: true,
            origin: "A2",
        });
        var wb = XLSX.utils.book_new(); // make Workbook of Excel

        XLSX.utils.book_append_sheet(wb, tmonheader, "티몬 매칭리스트");
        // export Excel file
        XLSX.writeFile(wb, "티몬 매칭리스트.xlsx");
        this.buttonDissabled = false;
    },
    goodsmatchconvert() {
        var t_data = [];
        for (var idx = 0; idx < this.goodsMatchExcelList.length; idx++) {
            var celldata = {};
            celldata[0] = this.goodsMatchExcelList[idx].goodsOnSale ? "판매중" : "일시중지";
            celldata[1] = this.goodsMatchExcelList[idx].optionOnSale ? "판매중" : "일시중지";
            celldata[2] = this.goodsMatchExcelList[idx].goodsId;
            celldata[3] = this.goodsMatchExcelList[idx].optionId;
            celldata[4] = this.goodsMatchExcelList[idx].goodsType;
            celldata[5] = this.goodsMatchExcelList[idx].goodsName;
            celldata[6] = this.goodsMatchExcelList[idx].optionName;
            celldata[7] = this.goodsMatchExcelList[idx].listPrice;
            celldata[8] = this.goodsMatchExcelList[idx].salePrice;
            celldata[9] = this.goodsMatchExcelList[idx].deliveryPrice;
            celldata[10] = this.goodsMatchExcelList[idx].saleDate;
            celldata[11] = this.goodsMatchExcelList[idx].availableDate;
            for (var tidx = 0; tidx < this.goodsMatchExcelList[idx].matchChannelList.length; tidx++) {
                celldata[12+tidx] = this.goodsMatchExcelList[idx].matchChannelList[tidx].matchItemCode;
            }
            t_data.push(celldata);
        }
        this.goodsMatchExcelList = t_data;
    },
    downGOODSMatchExcel() {
        var headrow = {};
        headrow[0] = "상품상태";
        headrow[1] = "옵션상태";
        headrow[2] = "상품코드";
        headrow[3] = "옵션코드";
        headrow[4] = "상품유형";
        headrow[5] = "상품명";
        headrow[6] = "옵션명";
        headrow[7] = "정상가";
        headrow[8] = "판매가";
        headrow[9] = "입금가";
        headrow[10] = "판매기간";
        headrow[11] = "유효기간";
        if (this.goodsMatchExcelList.length > 0) {
            for (var tidx = 0; tidx < this.goodsMatchExcelList[0].matchChannelList.length; tidx++) {
                headrow[12+tidx] = this.goodsMatchExcelList[0].matchChannelList[tidx].channelName;
            }
        }

        var tmonheader = XLSX.utils.json_to_sheet(
            [headrow],
            {skipHeader: true}
        );
        this.goodsmatchconvert();
        XLSX.utils.sheet_add_json(tmonheader, this.goodsMatchExcelList, {
            skipHeader: true,
            origin: "A2",
        });
        var wb = XLSX.utils.book_new(); // make Workbook of Excel

        XLSX.utils.book_append_sheet(wb, tmonheader, "상품 매칭리스트");
        // export Excel file
        XLSX.writeFile(wb, "상품 매칭리스트.xlsx");
        this.buttonDissabled = false;
    },
    getChannelData() {
      reqGet(
        b2bapi.base,
        b2bapi.channelList + "?manageType=GoodsDistribute",
        this.authToken,
        (result) => {
          if(result != null && result.code == rescode.REQ_SUCCESS) {
            this.channels = result.data.channels;

            if(localStorage.B2BCMT_channelCode) {
              this.channelCode = localStorage.B2BCMT_channelCode;
            }else{
              this.channelCode = this.channels[0].channelCode;

            }

            this.getchannelInfo();
          }
        }
      );
    },
    getSaleState(del, sel) {
      if(del) {
        return "삭제";
      }
      if(sel) {
        return "판매중";
      }
      return "일시중지";
    },
    godetailpage(goodsid) {
      if(this.channelpubInfo.goodsInterworking == false) {
        this.$router.push(this.routes.channelprodreg + "?goodsId=" + goodsid + "&channelCode=" + this.channelCode + "&goodsInterworking=" + this.channelpubInfo.goodsInterworking);
      } else {
        if(this.channelCode == "TMON") {
          this.$router.push(this.routes.channelprodreg + "?goodsId=" + goodsid + "&channelCode=" + this.channelCode + "&goodsInterworking=" + this.channelpubInfo.goodsInterworking);
        } else if(this.channelCode == "GMK" || this.channelCode == "G9") {
          this.$router.push(this.routes.channelprodreg + "?goodsId=" + goodsid + "&channelCode=" + this.channelCode + "&goodsInterworking=" + this.channelpubInfo.goodsInterworking);
        } else {
          alert("잘못된 접근입니다.");
        }
      }
    },
    getchannelInfo() {
      reqGet(
        b2bapi.base,
        b2bapi.channelpubInfo + "?channelCode=" + this.channelCode,
        this.authToken,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              this.channelpubInfo = result.data;
              this.refreshPage();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    openMemo(val){
      this.memoToggle = true;

      reqGet(
        goodsapi.base,
        goodsapi.productMemoList+`?goodsId=${val}`,
        this.authToken,
        (result) => {
          if(result != null) {
            if(result.code == rescode.REQ_SUCCESS) {
              let tmpArr = result.data.memoList
              console.log(tmpArr)

              if(tmpArr.length>0){
                tmpArr.forEach(item=>{
                  let tmpString = `• ${ this.viewDateTime(item.updateDateTime)} (${item.userName})\n ${item.memo}\n\n`
                  console.log(tmpString)
                  this.productMemo += tmpString
                })
              }

            } else {

              alert(result.message);
            }
          }
        }
      );
    },
    closeMemo(){
      this.productMemo = ""
      this.memoToggle = false;
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.radio > label {
  margin-right: 10px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.modal-body .control-label {
  margin: 0px;
}

@media (min-width: 768px) {
  /*.modal-dialog {
    width: 350px;
    margin: 30px auto;
  }*/
}
</style>
