<template>
  <div class="root">
    <div class="input-group">
      <label>{{label}}</label>
      <span class="input-group-btn">
        <button type="button" class="btn btn-xs" style="background: transparent;border: none;" @click="close(index)">
          <i class="fa fa-fw fa-remove"></i>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      default: "",
    },
    index:{
      type:Number,
      default: 0,
    },
    close:{
      type:Function,
      default:()=>{}
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.root {
  border-radius: 10px;
  margin: 5px 0px;
  margin-right: 10px;
  border: 1px solid #d2d6de;
  padding: 5px 10px;
  padding-right: 0px;
}
label {
  margin: 0px;
}
</style> 