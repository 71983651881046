<template>
  <div>
    <h3>
      시간대 할인 설정
    </h3>
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="">
          <div class="table-responsive p-0">
            <table class="table table-hover text-nowrap text-center">
              <thead>
              <tr>
                <th>시간대 명칭</th>
                <th>운영 기간</th>
                <th>적용 요일</th>
                <th>우선 순위</th>
                <th>수정</th>
                <th>삭제</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in swingTimeList" :key="idx">
                <td> {{ item.title }}</td>
                <td> {{ item | dateType }}</td>
                <td> {{ item | dayOfType }}</td>
                <td>
                  <drop @drop="handleDrop(item, $event)">
                    <drag class="btn btn-default btn-social-icon"
                          :transfer-data="{ item: item, index: idx }">
                      <i class="fa fa-fw fa-arrows-v"></i>
                    </drag>
                  </drop>
                </td>
                <td>
                  <button type="button" class="btn btn-default"
                          @click="timeZoneEdit(item.settingsId)">수정
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-social-icon"
                          @click="delSwingTime(item.settingsId)">
                    <i class="fa fa-fw fa-trash-o"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="right-btn">
            <button type="button" class="btn btn-default " @click="timeZoneMakeModalOpen">시간대 만들기</button>
          </div>
        </div>
      </div>
      <!-- 시간대 만들기 모달 -->
      <div class="modal" :style="timeZoneMakeModal && { 'display': 'block' }">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0"> {{ timeZoneMakeModalName }}</div>
            <div class="modal-body form-horizontal">

              <div class="row">
                <div class="col-sm-2 bold">
                  시간대 명칭
                </div>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="title">
                </div>
              </div>

              <!-- 시간 선택 박스 -->
              <div class="row mt-2">
                <div class="col-sm-2 bold">
                  시간대 운영 기간
                </div>
                <div class="col-sm-10">
                  <label>
                    <input type="radio" v-model="thisRadio" :value="true" @change="periodInput">
                    <span class="ml05">상시</span>
                  </label>
                  <label class="ml-1">
                    <input type="radio" v-model="thisRadio" :value="false" @change="periodInput">
                    <span class="ml05">기간설정</span>
                  </label>
                </div>
              </div>
              <div class="row" v-if="!thisRadio">
                <div class="col-sm-2"></div>
                <div class="col-sm-10">
                  <div>
                    <DatePicker v-model="temp.startDate"/>
                    <span class="" style="border:none;display:inline-flex">-</span>
                    <DatePicker v-model="temp.endDate"/>
                  </div>
                </div>
              </div>
              <!-- 시간 선택 박스 end -->

              <!-- 날짜 지정 박스 -->
              <div class="row mt-2">
                <div class="col-sm-2 bold">
                  적용 요일
                </div>
                <div class="col-sm-10">
                  <input type="radio" id="radioValues1" name="number" v-model="radioValues"
                         :value="true" @change="thisdayChange">
                  <label class="ml05" for="radioValues1">매일</label>

                  <input class="ml-1" type="radio" id="radioValues2" name="number"
                         v-model="radioValues" value="2r" @change="thisdayChange">
                  <label class="ml05" for="radioValues2">주중</label>

                  <input class="ml-1" type="radio" id="radioValues3" name="number"
                         v-model="radioValues" value="3r" @change="thisdayChange">
                  <label class="ml05" for="radioValues3">주말</label>

                  <input class="ml-1" id="radioValues4" type="radio" name="number"
                         v-model="radioValues" value="4r" @change="thisdayChange">
                  <label class="ml05" for="radioValues4">사용자지정</label>
                </div>
              </div>
              <!-- 날짜 지정 박스 end -->

              <!-- 기본 운영 테이블 -->
              <div class="row mt-2" v-for="(bitem, bidx, divBoxIndex) in divBox" :key="bidx">

                <div class="col-sm-2 bold" v-if="divBoxIndex == 0">예약가능시간</div>
                <div class="col-sm-2" v-else></div>
                <div class="col-sm-10 first">
                  <!-- <div v-if="week">
              {{ weekName[divBoxIndex] }}
          </div> -->
                  <div>
                    <div :class="'btn-group mb-1 daycheckClass' + divBoxIndex"
                         v-if="radioValues == '4r'">
                      <button type="button" class="btn btn-default" :id="'monday' + divBoxIndex"
                              @click="clickDay('monday', divBoxIndex)">월
                      </button>
                      <button type="button" class="btn btn-default" :id="'tuesday' + divBoxIndex"
                              @click="clickDay('tuesday', divBoxIndex)">화
                      </button>
                      <button type="button" class="btn btn-default"
                              :id="'wednesday' + divBoxIndex"
                              @click="clickDay('wednesday', divBoxIndex)">수
                      </button>
                      <button type="button" class="btn btn-default" :id="'thursday' + divBoxIndex"
                              @click="clickDay('thursday', divBoxIndex)">목
                      </button>
                      <button type="button" class="btn btn-default" :id="'friday' + divBoxIndex"
                              @click="clickDay('friday', divBoxIndex)">금
                      </button>
                      <button type="button" class="btn btn-default" :id="'saturday' + divBoxIndex"
                              @click="clickDay('saturday', divBoxIndex)">토
                      </button>
                      <button type="button" class="btn btn-default" :id="'sunday' + divBoxIndex"
                              @click="clickDay('sunday', divBoxIndex)">일
                      </button>
                    </div>
                  </div>
                  <div v-for="(aitem, aidx) in bitem" :key="aidx"
                       :id="'inputGroup' + divBoxIndex + aidx" :class="[aidx == 0 ? '' : 'mt1']">

                    <input class="form-control maxwidth" type="text" maxlength="5"
                           v-model="aitem.startTime" placeholder="00:00"
                           @keyup="keyPress($event, aitem.startTime, aidx, 'startTime', divBoxIndex, divBox, bidx)">
                    <span>~</span>
                    <input class="form-control maxwidth" type="text" maxlength="5"
                           v-model="aitem.endTime" placeholder="00:00"
                           @keyup="keyPress($event, aitem.endTime, aidx, 'end', divBoxIndex, divBox, bidx)">
                    <input class="form-control maxwidth2" type="text" maxlength="3"
                           v-model="aitem.percent" @input="onlyNum($event, aidx, aitem.percent)">
                    <span>%</span>
                    <button type="button" class="btn btn-default ml-1" v-if="aidx !== 0"
                            @click="inputDelete(divBoxIndex, aidx, divBox)">삭제
                    </button>
                    <div :class="{
                                            'changeInput onValid': validationCheck(aitem.startTime, aitem.endTime, aidx, divBox, undefined, bidx) !== 0,
                                            'changeInput': validationCheck(aitem.startTime, aitem.endTime, aidx, divBox, undefined, bidx) == 0
                                        }"
                         v-if="validationCheck(aitem.startTime, aitem.endTime, aidx, divBox, undefined, bidx) !== 0">
                      <!-- {{ validationCheckText  }} -->
                      <div v-if="validationCheck(aitem.startTime, aitem.endTime, aidx, divBox, undefined, bidx) == 1"
                           class="waring">
                        운영 시간은 겹칠 수 없습니다.
                      </div>
                      <div
                        v-else-if="validationCheck(aitem.startTime, aitem.endTime, aidx, divBox, undefined, bidx) == 2"
                        class="waring">
                        예약 받는 시간 간격을 선택해 주세요.
                      </div>
                      <div
                        v-else-if="validationCheck(aitem.startTime, aitem.endTime, aidx, divBox, undefined, bidx) == 3"
                        class="waring">
                        마지막 시간은 시작 시간보다 빠를 수 없습니다.
                      </div>
                    </div>

                  </div>
                  <button type="button" class="btn btn-default mt-1"
                          @click="timeAdd(divBoxIndex, divBox)">시간추가
                  </button>
                </div>
              </div>

              <!-- 기본 운영 테이블 end -->

            </div>
            <div class="modal-footer text-center">
              <button type="button" class="btn btn-default" @click="timeZoneMakeModalClose">취소</button>
              <button type="button" class="btn btn-default" @click="timeZoneMakeModalSave">저장</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 시간대 만들기 모달 end -->
    </div>

    <h3>
      플랫폼 예약 리워드 적립 설정
    </h3>
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="col-sm-5" style="padding:0%;">
          <div class="box-body form-horizontal">
            <table class="table table-hover text-nowrap text-center">
              <thead>
              <tr>
                <th>적립률</th>
                <th>적용시작일</th>
                <th>요청자</th>
                <th v-if="Object.keys(lastDefaultPoint).length === 0">
                  <button type="button" v-if="!isAccruaInput" class="btn btn-default"
                          @click="addAccrualRateInput">등록
                  </button>
                  <button type="button" v-else class="btn btn-default"
                          @click="closeAddccr">취소
                  </button>
                </th>
                <th v-else>
                  <button type="button" class="btn btn-default "
                          @click="historyView">지난내역보기
                  </button>
                  <button type="button" v-if="!isAccruaInput" class="btn btn-default mr-2"
                          @click="addAccrualRateInput">변경
                  </button>
                  <button type="button" v-else class="btn btn-default mr-2"
                          @click="closeAddccr">취소
                  </button>
                </th>
              </tr>
              </thead>
              <tbody v-if="Object.keys(lastDefaultPoint).length !== 0">
              <tr>
                <td>{{ lastDefaultPoint.commissionRate }}</td>
                <td>{{ lastDefaultPoint.effectiveDate }}</td>
                <td>{{ lastDefaultPoint.requester }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="box-body form-horizontal" v-if="isAccruaInput">
          <div class="form-group">
            <div class="col-sm-12">
              <div class="row sm-inline" style="margin:0px">
                <div class="row sm-inline" style="margin:0px; padding:3px 5px;">

                  <div class="input-group" style="display: inline-flex;flex-wrap: wrap;">
                    <input type="text" class="form-control"
                           style="margin:0px; text-align: right; width: 100px;"
                           v-model="commissionRate"/>
                    <span class="input-group-addon"
                          style="border:none;width: fit-content; background-color: transparent">%</span>
                  </div>
                  <DatePicker style="margin-left: 10px;" v-model="effectiveDate"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center col-sm-12">
          <button type="button" class="btn btn-default" @click="saveAccrualRate">저장</button>
        </div>

        <!-- 2022-11-21-추가 -->
        <div class="modal" :style="showcnthisdialog && { 'display': 'block' }">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header border-bottom-0">지난내역보기</div>
              <div class="modal-body form-horizontal">
                <div class="table-responsive tbl_h200 p-0">
                  <table class="table table-hover text-nowrap">
                    <thead>
                    <tr>
                      <th>번호</th>
                      <th>적립률</th>
                      <th>적용기간</th>
                      <th>요청자</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, idx) in defaultPoints" :key="idx">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.commissionRate }}</td>
                      <td>{{ item.effectiveDate }}</td>
                      <td>{{ item.requester }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" @click="closeCntHisDialog">닫기</button>
              </div>
            </div>
          </div>
        </div>
        <!-- 2022-11-21추가 -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  Drag,
  Drop
} from "vue-drag-drop";
import DatePicker from "../../../../components/DatePicker";
import {
  brandapi
} from "../../../../lib/api";
import {
  reqGet,
  reqPost
} from "../../../../lib/request";
import {
  rescode,
  menuval
} from "../../../../lib/consts";

export default {
  components: {
    DatePicker,
    Drag,
    Drop
  },
  filters: {
    dateType(val) {
      if (val.startDate === null) {
        return '상시'
      } else {
        return val.startDate + ' - ' + val.endDate
      }
    },
    dayOfType(val) {
      if (val.targetDaysType === 'Custom') {
        let day = [];
        if (val.monday) {
          day.push('월');
        }
        if (val.tuesday) {
          day.push('화')
        }
        if (val.wednesday) {
          day.push('수')
        }
        if (val.thursday) {
          day.push('목')
        }
        if (val.friday) {
          day.push('금')
        }
        if (val.saturday) {
          day.push('토')
        }
        if (val.sunday) {
          day.push('일')
        }
        return day.join();
      } else if (val.targetDaysType === 'Weekday') {
        return '주중';
      } else if (val.targetDaysType === 'Weekend') {
        return '주말';
      } else {
        return '매일'
      }
    },
  },
  data() {
    return {
      isAccruaInput: false,
      testp: [],
      effectiveDate: null,
      commissionRate: '',
      title: "",
      thisRadio: true,
      timeZoneMakeModal: false,
      timeZoneMakeModalName: "",
      divArray: "",
      temp: {
        startDate: null,
        endDate: null,
      },
      divBox: {
        value: [{
          startTime: "",
          endTime: "",
          percent: 0,
        }],
      },
      storeSwingTimeQuery: {},
      day: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      timeSettings: {},
      bidx: 'value',
      dayClass: [],
      startend: "",
      dayGroup: [],
      radioValues: true,
      settingsId: 0,
      swingTimeList: [],
      edt: false,
      edtOrAdd: true,
      lastDefaultPoint: {},
      defaultPoints: [],
      showcnthisdialog: false,
      requester: '',
    }
  },
  computed: {
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  mounted() {
    this.refresh();
    this.loadPointData();

  },
  updated() {
    if (this.storeSwingTimeQuery.length !== 0 && this.timeZoneMakeModal && this.edt === true) {
      let days = ['monday', 'thursday', 'friday', 'saturday', 'sunday', 'tuesday', 'wednesday']
      days.forEach((day, idx1) => {
        if(document.getElementById(String(day + 0)) === null){
          return;
        }
        if (this.storeSwingTimeQuery[day] === true) {
          document.getElementById(String(day + 0)).className = 'btn btn-default btn-selweek';
        } else {
          if (document.getElementById(String(day + 0)) !== null) {
            document.getElementById(String(day + 0)).className = 'btn btn-default';
          }
        }
      });

    }
  },
  methods: {
    loadPointData() {
      reqGet(
        brandapi.base,
        brandapi.poinQuery + "?storeCode=" + this.$route.query.stcode,
        this.authToken,
        (result) => {
          if (result != null && result.code == rescode.REQ_SUCCESS) {
            this.lastDefaultPoint = {};
            this.lastDefaultPoint = result.data.lastDefaultPoint === null ? {} : result.data.lastDefaultPoint;

            this.defaultPoints = result.data.defaultPoints;
            console.log(this.defaultPoints)

            this.commissionRate = this.lastDefaultPoint.commissionRate;
            this.effectiveDate = this.lastDefaultPoint.effectiveDate;
            this.requester = this.lastDefaultPoint.requester;
          }
        }
      );
    },
    closeCntHisDialog() {
      this.showcnthisdialog = false;
    },
    historyView() {
      this.showcnthisdialog = true;
    },
    addAccrualRateInput() {
      this.isAccruaInput = true;
    },
    closeAddccr() {
      this.isAccruaInput = false;
    },
    saveAccrualRate() {
      reqPost(
        brandapi.base,
        brandapi.poinUpdate,
        this.authToken, {
          commissionRate: this.commissionRate,
          effectiveDate: this.effectiveDate,
          pointRateType: "Default",
          storeCode: this.$route.query.stcode,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert('저장되었습니다.');
              this.loadPointData();
              this.isAccruaInput = false;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    delSwingTime(settingsId) {
      if (confirm("삭제하시겠습니까? 삭제된 시간대 할인은 되돌리 수 없습니다.")) {
        reqPost(
          brandapi.base,
          brandapi.storeSwingTimeDelete,
          this.authToken, {
            settingsId: settingsId,
            storeCode: this.$route.query.stcode
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.refresh();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    handleDrop(myitem, drag) {
      var priorities = [];
      priorities.push({
        settingsId: drag.item.settingsId,
        priority: myitem.priority
      });
      priorities.push({
        settingsId: myitem.settingsId,
        priority: drag.item.priority
      });
      reqPost(
        brandapi.base,
        brandapi.storeSwingTimePriority,
        this.authToken, {
          storeCode: this.$route.query.stcode,
          priorities: priorities,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.refresh();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    refresh() {
      reqGet(
        brandapi.base,
        brandapi.storeSwingTimeList + "?storeCode=" + this.$route.query.stcode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.swingTimeList = result.data.list;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    onlyNum(val, aidx, thispercent) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if (reg.exec(thispercent) !== null) this.divBox.value[aidx].percent = thispercent.replace(/[^0-9]/g, '');
      // console.log(val.currentTarget._value.replace(/[^0-9]/g, ''))
      // .... 만 입력하게 될 경우 체크
      if (isNaN(parseFloat(thispercent))) this.divBox.value[aidx].percent = '';
      if (Number(thispercent) > 100) {
        this.divBox.value[aidx].percent = 100;
        return;
      }
    },
    timeZoneMakeModalSave() {
      let noisdays = [],
        targetDaysType = "",
        targetDaysType2 = "",
        unitTime = "",
        divArray = null;
      // 시간 저장시 벨리데이션

      let nu = 0;
      for (let i in this.divBox) {
        if (nu == 0) {
          if (i === 'value0') {
            nu = 0;
          } else {
            nu = "";
          }
        }
        if (i == 'value' + nu) {
          divArray = this.divBox[i]
        }
        nu = 0;
        nu++
      }
      for (let i = 0; i < divArray.length; i++) {
        if (divArray[i].startTime == "" || divArray[i].endTime == "") {
          alert('예약 받는 시간 간격을 선택해 주세요.');
          return
        }

      }

      let warning = document.querySelector('.first .waring');
      if (warning !== null) {
        alert(warning.textContent);
        return
      }

      this.dayGroup.forEach((el, idx) => {
        for (let s in el) {
          noisdays.push(el[s])
        }
      });
      if (!noisdays.includes(true) && this.radioValues === "4r") {
        alert('요일을 체크하십시요.');
        return;
      }

      if (this.radioValues == true) {
        targetDaysType = 'Everyday';

      } else if (this.radioValues == '2r') {
        targetDaysType = 'Weekday'
      } else if (this.radioValues == '3r') {
        targetDaysType = 'Weekend'
      } else {
        targetDaysType = "Custom"
      }
      // 배열 재조합 백엔드에 보낼때 사용하는 배열
      this.timeSettings = {};
      let url = "";
      let settingsId = null;
      if (this.edtOrAdd == false) {
        url = brandapi.storeSwingTimeUpdate; //수정
        settingsId = this.settingsId;
      } else {
        url = brandapi.storeSwingTimeCreate; // 등록
        settingsId = null;
      }
      this.timeSettings = {
        startDate: this.temp.startDate,
        endDate: this.temp.endDate,
        title: this.title,
        targetDaysType: targetDaysType,
        operatedTime: this.operatedTime, // 기본일경우 Default
        swingTimes: [],
        monday: this.dayGroup.length === 0 || this.dayGroup[0].monday == null ? false : this.dayGroup[0].monday,
        tuesday: this.dayGroup.length === 0 || this.dayGroup[0].tuesday == null ? false : this.dayGroup[0].tuesday,
        wednesday: this.dayGroup.length === 0 || this.dayGroup[0].wednesday == null ? false : this.dayGroup[0].wednesday,
        thursday: this.dayGroup.length === 0 || this.dayGroup[0].thursday == null ? false : this.dayGroup[0].thursday,
        friday: this.dayGroup.length === 0 || this.dayGroup[0].friday == null ? false : this.dayGroup[0].friday,
        saturday: this.dayGroup.length === 0 || this.dayGroup[0].saturday == null ? false : this.dayGroup[0].saturday,
        sunday: this.dayGroup.length === 0 || this.dayGroup[0].sunday == null ? false : this.dayGroup[0].sunday,
      }
      let newarraysss = [];
      for (let i in this.divBox) {
        this.timeSettings.swingTimes = this.divBox[i]
      }
      if (this.thisRadio === true) {
        this.timeSettings.startDate = null;
        this.timeSettings.endDate = null;
      }
      reqPost(
        brandapi.base,
        url,
        this.authToken, {
          settingsId: settingsId,
          swingtimeSettings: this.timeSettings,
          storeCode: this.$route.query.stcode
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              alert('저장되었습니다.');
              this.refresh();
              this.timeZoneMakeModalClose();

            } else {
              alert(result.message);
            }
          }
        }
      );

    },
    timeZoneMakeModalOpen() {
      this.timeZoneMakeModalName = "시간대 만들기";
      this.timeZoneMakeModal = true;
      this.edtOrAdd = true;
      this.edt = false;
      this.title = '';
      this.dayGroup = [];
      this.dayClass = [];
      this.radioValues = true;
      this.thisRadio = true;
      this.thisdayChange();
    },
    timeZoneMakeModalClose() {
      this.timeZoneMakeModal = false;
      this.dayGroup = [];
      this.dayClass = [];
      this.thisdayChange();
    },
    timeZoneEdit(settingsId) {
      this.timeZoneMakeModalName = "시간대 수정";
      this.edtOrAdd = false
      this.timeZoneMakeModal = true;
      this.edt = true;
      this.settingsId = settingsId;
      reqGet(
        brandapi.base,
        brandapi.storeSwingTimeQuery + "?storeCode=" + this.$route.query.stcode + "&settingId=" + settingsId,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {

              this.storeSwingTimeQuery = result.data;

              this.title = result.data.title;
              if (result.data.startDate !== null) {
                this.thisRadio = false;
              } else {
                this.thisRadio = true;
              }
              this.temp.startDate = result.data.startDate
              this.temp.endDate = result.data.endDate
              if (result.data.targetDaysType === 'Custom') {
                this.radioValues = '4r';
              } else if (result.data.targetDaysType === 'Weekday') {
                this.radioValues = '2r';
              } else if (result.data.targetDaysType === 'Weekend') {
                this.radioValues = '3r';
              } else if (result.data.targetDaysType === 'Everyday') {
                this.radioValues = true;
              }
              this.dayGroup = [];
              let days = {
                monday: result.data.monday === null ? false : result.data.monday,
                tuesday: result.data.tuesday === null ? false : result.data.tuesday,
                wednesday: result.data.wednesday === null ? false : result.data.wednesday,
                thursday: result.data.thursday === null ? false : result.data.thursday,
                friday: result.data.friday === null ? false : result.data.friday,
                saturday: result.data.saturday === null ? false : result.data.saturday,
                sunday: result.data.sunday === null ? false : result.data.sunday,
              }

              this.dayGroup.push(days)
              this.timeEditNumber(result.data);

              console.log(this.dayGroup)
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    timeEditNumber(list) {
      // console.log('수정')

      this.thisdayChange();
      // if (this.radioValues !== "4r") {
      this.divBox.value = [];
      list.swingTimes.forEach(el => {
        // el.swingTimes.forEach(time => {
        let pp = {
          startTime: el.startTime.substr(0, 5),
          endTime: el.endTime.substr(0, 5),
          percent: el.percent,
        };
        this.divBox.value.push(pp);
        // });
      });
      // }
      // this.edt = true;
    },
    periodInput() {

    },
    thisdayChange() {
      this.dayClass = [];
      this.divBox = {
        value: [{
          startTime: "",
          endTime: "",
          percent: 0,
        }],
      };
    },
    moveArray(val, aidx, divBoxIndex) { // 순서 맞추기
      this.divArray.sort(function (a, b) {
        if (a.startTime === "") return;
        if (a.startTime > b.startTime) return 1;
        if (a.startTime === b.startTime) return 0;
        if (a.startTime < b.startTime) return -1;
      });
    },
    slicemethods(startend, aidx, type, e, divBoxIndex, arrayObject, val, onlyNum) { // 자동으로 따옴표 붙혀주기 0또는 3 구분
      let iddx = '';
      for (let i in arrayObject) {
        if (divBoxIndex == 0) {
          iddx = "";
        } else {
          iddx = divBoxIndex;
        }
        if (i == 'value' + iddx) {
          this.divArray = arrayObject[i]
        }
      }
      if (type == "Backspace") {
        if (startend === "startTime") {
          if (e.key !== 'Backspace') {
            this.divArray[aidx].startTime += ":"
          } else {
            this.divArray[aidx].startTime = this.divArray[aidx].startTime.slice(0, -1);
          }
        } else {
          if (e.key !== 'Backspace') {
            this.divArray[aidx].endTime += ":"
          } else {
            this.divArray[aidx].endTime = this.divArray[aidx].endTime.slice(0, -1);
          }
        }
      } else if (type == 'first') {
        if (startend === "startTime") {
          if (Number(val) >= 3) {
            this.divArray[aidx].startTime = '';
            this.divArray[aidx].startTime += '0' + val + ":"
          }
        } else {
          if (Number(val) >= 3) {
            this.divArray[aidx].endTime = '';
            this.divArray[aidx].endTime += '0' + val + ":"
          }
        }
      } else {
        if (startend === "startTime") {
          if (this.divArray[aidx] !== undefined) {
            this.divArray[aidx].startTime = this.divArray[aidx].startTime.slice(0, -1);
          }
        } else {
          if (this.divArray[aidx] !== undefined) {
            this.divArray[aidx].endTime = this.divArray[aidx].endTime.slice(0, -1);
          }
        }
      }
    },
    bigNumber(type, aidx, startend, val, divBoxIndex, arrayObject) { // 1시간 일경우 23시 30분일 경우 23시 30분까지
      let iddx = '';
      for (let i in arrayObject) {
        if (divBoxIndex == 0) {
          iddx = "";
        } else {
          iddx = divBoxIndex;
        }
        if (i == 'value' + iddx) {
          this.divArray = arrayObject[i]
        }
      }
      if (Number(val.substr(0, 2)) > 23) {
        if (startend === "startTime") {
          this.divArray[aidx].startTime = this.divArray[aidx].startTime.slice(0, -2);
        } else {
          this.divArray[aidx].endTime = this.divArray[aidx].endTime.slice(0, -2);
        }
      }
    },
    clickDay(day, divBoxIndex) {
      this.sameDaySelectCheck(day, divBoxIndex);
      // console.log(this.day)
    },
    sameDaySelectCheck(day, divBoxIndex) {
      let dayIf = {
        dayIf: true,
        day: day,
        divBoxIndex: divBoxIndex,
        onActive: day + divBoxIndex
      }
      if (this.clickDayCheck(dayIf, day, divBoxIndex)) {
        let days = {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        }

        switch (day) {
          case 'monday':
            days.monday = !days.monday;
            break;
          case 'tuesday':
            days.tuesday = !days.tuesday;
            break;
          case 'wednesday':
            days.wednesday = !days.wednesday;
            break;
          case 'thursday':
            days.thursday = !days.thursday;
            break;
          case 'friday':
            days.friday = !days.friday;
            break;
          case 'saturday':
            days.saturday = !days.saturday;
            break;
          case 'sunday':
            days.sunday = !days.sunday;
            break;
        }
        if (this.dayGroup.length == 0) {
          this.dayGroup.push(days)
        } else {
          this.dayGroup.forEach((el, idx) => {
            if (idx !== divBoxIndex) {
              //있는경우 추가 방지
              if (this.dayGroup[divBoxIndex] === undefined) {
                this.dayGroup.push(days)
              }
            } else {
              if (el[day] == true) {
                el[day] = false;
              } else {
                el[day] = true;
              }
            }
          });

          // 수정 일 경우 다시 재체크
          this.dayGroup.forEach((el, idx) => {
            if (idx == divBoxIndex && el[day] == false) { // 이미 체크된 요일 체크 풀기
              document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default';
            } else {
              document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default btn-selweek';
            }
          });
        }
        // console.log(this.dayGroup)
      } else {
        return;
      }

    },
    clickDayCheck(dayIf, day, divBoxIndex) {
      let isww = false;
      let secondLoop = true;
      if (this.dayClass.length == 0) {
        document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default btn-selweek';
        this.dayClass = [];
        this.dayClass.push(dayIf);
        isww = true;
      } else {
        this.dayClass.forEach((el, idx) => {
          if (el.day == day && el.divBoxIndex == divBoxIndex && el.onActive == String(day + divBoxIndex)) { // 이미 체크된 요일 체크 풀기
            if (el.onActive === String(day + divBoxIndex)) {
              this.dayClass.splice(idx, 1);
            }
            document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default';
            // console.log('같음')
            isww = true;
            secondLoop = false;
          }
        });
        //배열안에 이미 있는 요일 체크
        if (secondLoop) {
          let ss = this.dayClass.find((item) => {
            return item.day === day;
          })
          if (ss == undefined) {
            document.getElementById(String(day + divBoxIndex)).className = 'btn btn-default btn-selweek';
            this.dayClass.push(dayIf);
            isww = true;
          } else {
            isww = false;
            return;
          }
        }
      }
      return isww;
    },
    keyPress(e, val, aidx, startend, divBoxIndex, arrayObject, bidx) { // 인풋박스 입력시 밸리데이션
      this.startend = startend;
      let iddx = '';
      this.bidx = bidx;
      for (let i in arrayObject) {
        if (divBoxIndex == 0) {
          iddx = "";
        } else {
          iddx = divBoxIndex;
        }
        if (i == 'value' + iddx) {
          this.divArray = arrayObject[i]
        }
      }

      //숫자 : 만 입력 가능
      const regExp = /[^-\:0-9]/g;
      const ele = e.target;

      if (regExp.test(ele.value)) {
        ele.value = ele.value.replace(regExp, '');
        if (startend === "startTime") {
          this.$set(this.divArray[aidx], 'startTime', ele.value);
        } else {
          this.$set(this.divArray[aidx], 'endTime', ele.value);
        }
        return
      } else {
        val = ele.value;
        if (startend === "startTime") {
          this.$set(this.divArray[aidx], 'startTime', ele.value);
        } else {
          this.$set(this.divArray[aidx], 'endTime', ele.value);
        }
      }
      //숫자 : 만 입력 가능 end
      if (val.length === 1) {
        this.slicemethods(startend, aidx, 'first', e, divBoxIndex, arrayObject, val.substr(-1), e.key);
      }
      if (val.length === 2) {
        this.slicemethods(startend, aidx, 'Backspace', e, divBoxIndex, arrayObject, val.substr(-1), e.key);

      }
      if (val.length === 3) {
        //빠르게 입력시 오탈자 막기
        if (!val.includes(':')) {
          if (startend === "startTime") {
            this.$set(this.divArray[aidx], 'startTime', val.substr(3, 5));
          } else {
            this.$set(this.divArray[aidx], 'endTime', val.substr(3, 5));
          }
        }
      } else if (val.length === 5) {
        this.moveArray(val, aidx, divBoxIndex);
        this.loopCheck = true;
      } else {
        this.loopCheck = false;
      }

      this.bigNumber('30', aidx, startend, val, arrayObject); // 23시 30분까지만 써지게 하기
      if (val.length === 4) {
        if (Number(e.key) !== 0 && Number(e.key) !== 3) {
          this.slicemethods(startend, aidx, null, null, divBoxIndex, arrayObject, val.substr(-1), e.key);
        }
      } else {
        if (val.length === 5) {
          if (Number(e.key) !== 0) {
            this.slicemethods(startend, aidx, null, null, divBoxIndex, arrayObject, val.substr(-1), e.key);
          }
        }
      }
    },

    validationCheck(startTime, end, aidx, arrayType, noLoop, bidx) {
      if (this.bidx !== bidx) { //주중,사용자지정일경우 인풋 검사시 오브젝트 별로 진행
        return;
      }
      let isture = 0;
      let iddx = '';

      if (this.timeZoneMakeModal) { // 무한루프 방지
        if (noLoop !== undefined) {
          return;
        }
      } else {
        if (noLoop !== "second") {
          return;
        }
      }
      for (let i in arrayType) {
        if (aidx == 0) {
          iddx = "";
        } else {
          iddx = aidx;
        }
        if (i == bidx) { //bidx는 인풋 키업 이벤트시 해당 오브젝트 키값
          this.divArray = arrayType[i]
        }
      }
      this.divArray.find((eld, idx) => {
        //1시간 단위
        let arrayStart = Number(eld.startTime.substr(0, 2)),
          arrayStartMin = Number(eld.startTime.substr(3, 5)),
          arrayEnd = Number(eld.endTime.substr(0, 2)),
          arrayEndMin = Number(eld.endTime.substr(3, 5)),
          nowStart = Number(startTime.substr(0, 2)),
          nowStartMin = Number(startTime.substr(3, 5)),
          nowEnd = Number(end.substr(0, 2)),
          nowEndMin = Number(end.substr(3, 5));

        if (startTime.length === 5) {
          if (aidx !== idx) {
            if (nowStart == arrayStart && nowEndMin === nowStartMin) {
              if (arrayStartMin === nowStartMin) {
                return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
              }
            } else if (arrayEnd > nowStart && nowStart >= arrayStart) {
              return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
            } else if (arrayEnd === nowStart && nowStartMin === arrayEndMin) {
              return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
            } else if (arrayStart === 0 || arrayEnd === 0) {
              return;
            } else {
              return isture = 0; // this.validationCheckText = "";
            }
          } else if (aidx === idx) {
            if (arrayEnd == 0) {
              return isture = 2; // this.validationCheckText = "예약 받는 시간 간격을 선택해 주세요.";
            } else if (arrayStart > nowEnd) {
              return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
            }
          }
        }
        if (end.length === 5) {
          if (aidx == idx) {
            if (nowEnd == arrayStart && nowEndMin === nowStartMin) {
              // return isture = 1; // this.validationCheckText = "운영 시간은 겹칠 수 없습니다.";
            } else if (nowEnd === arrayStart) {
              if (nowEndMin < nowStartMin) {
                return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
              }

            } else if (arrayStart > nowEnd) {
              return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
            } else {
              return isture = 0; // this.validationCheckText = "";
            }
          } else {
            if (arrayStart > nowEnd) {
              return isture = 3; // this.validationCheckText = "마지막 시간은 시작 시간보다 빠를 수 없습니다.";
            }
          }
        }
        return isture;
      });
      return isture;
    },
    inputDelete(divBoxIndex, aidx, arrayObject) {
      let iddx = '';
      for (let i in arrayObject) {
        if (divBoxIndex == 0) {
          iddx = 0;
        } else {
          iddx = divBoxIndex;
        }
        if (i == 'value' + iddx) {
          this.divArray = arrayObject[i]
        }
      }
      this.divArray.splice(aidx, 1);
      // console.log(this.divArray)
    },
    timeAdd(idx, arrayObject) { //시간추가
      let pp = {
        startTime: "",
        endTime: "",
        percent: 0,
      };
      arrayObject.value.push(pp);

    },
  }
}
</script>

<style scoped>
th {
  background-color: #dee2e685;
}

.right-btn {
  text-align: right;
}

.strongText {
  font-size: 18px;
}

.mt-6 {
  margin-top: 60px;
}

.changeInput {
  color: red;
  margin-top: 5px;
}

.btn-selweek {
  background-color: #5B9BD5;
  color: #FFFFFF;
}

.maxwidth {
  max-width: 200px;
  display: inline-block;
  vertical-align: middle;
}

.ml-1 {
  margin-left: 10px;

}

.mt-1,
.mt1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.bold {
  font-weight: 900;
}

.ml05 {
  margin-left: 5px;
}

.empty {
  color: #878787;
  font-size: 15px;
}

.mb-1 {
  margin-bottom: 10px;
}

.modal {
  overflow: auto;
}

.maxwidth2 {
  max-width: 73px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  margin-right: 8px;
}

.mr-2 {
  margin-left: 10px;
}
</style>
