<template>
  <section class="content">
    <div class="col-sm-offset-4 col-sm-4">
      <div class="box box-solid">
        <div class="box-body form-horizontal">
          <div class="form-group">
            <label for="password" class="col-sm-4 control-label">기존 비밀번호</label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                v-model="password"
                id="password"
                placeholder="기존 비밀번호"
              />
              <span class="text-red">{{pswmsg}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="newpassword" class="col-sm-4 control-label">새로운 비밀번호</label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                v-model="newpassword"
                id="newpassword"
                placeholder="새로운 비밀번호"
              />
              <span class="text-red">{{newmsg}}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="repassword" class="col-sm-4 control-label">비밀번호 확인</label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                v-model="repassword"
                id="repassword"
                placeholder="비밀번호 확인"
              />
              <span class="text-red">{{remsg}}</span>
            </div>
          </div>
        </div>
        <div class="box-footer text-center">
          <!-- <button type="button" class="btn btn-default" @click="cancel">취소</button> -->
          <button type="button" class="btn btn-success" @click="clickSave">저장</button>
        </div>
      </div>
    </div>
    <div class="modal" :style="showmodal && {'display': 'block'}">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-body ">
            <p>수정이 완료되었습니다.</p>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-success" @click="closemodal">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import { reqPost } from "../../lib/request";
import { adminapi } from "../../lib/api";
export default {
  data() {
    return {
      password: '',
      pswmsg:'',
      newpassword: '',
      newmsg:'',
      repassword:'',
      remsg:'',
      showmodal:false,
    }
  },
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_HOME,
      path2: menuval.LV1_HOME,
      title: "비밀번호 변경",
    });
  },
  computed: {
    authuser(){
      return this.$store.getters.authuser;
    },
    authToken(){
      return this.$store.getters.authToken;
    },
  },
  methods: {
    clickSave() {
      if(this.password == "")
      {
        this.pswmsg = "기존 비밀번호를 입력하세요.";
        return ;
      }else{
        this.pswmsg = "";
      }

      if(this.newpassword == ""){
        this.newmsg = "새로운 비밀번호를 입력하세요.";
        return ;
      }else{
        this.newmsg = "";
      }

      if(this.newpassword != this.repassword){
        this.remsg = "비밀번호가 일치 하지 않습니다.";
        return ;
      }else{
        this.remsg = "";
      }

      reqPost(adminapi.base, adminapi.changePassword, this.authToken, {username:this.authuser, oldPassword:this.password, newPassword:this.newpassword}, (data)=>{
          if(data != null)
          {
            if(data.code == rescode.REQ_SUCCESS)
            {
              this.showmodal = true;
              // this.$router.push('/');
            }else{
              this.pswmsg = data.message;
            }
          }else{
            this.pswmsg = "비밀번호 변경 실패 되었습니다.";
          }
        });
    },
    cancel() {
      this.$router.push('/');
    },
    closemodal() {
      this.showmodal = false;
    }
  }
};
</script>
<style scoped>
.modal-dialog{
  top: 40%;
}
</style>