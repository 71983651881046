<template>
<section class="content">
    <div class="box box-solid">
        <div class="box-body">
            <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
                <select class="form-control" v-model="keywordId" style="width:auto;margin-right:10px" @change="keywordIdChange">
                    <option v-for="(item,idx) in keywordIdList" :key="idx" :value="item.id">{{ item.keyword }}</option>
                </select>
                <DetePicker v-model="dateFrom" :last="dateTo" />
                <h4 style="margin:5px 10px;">~</h4>
                <DetePicker v-model="dateTo" :first="dateFrom" style="margin-right:10px" />
                <select class="form-control" v-model="reviewSearchType" style="width:auto;margin-right:10px">
                    <option value="NoKeyword"></option>
                    <option value="StoreCode">매장코드</option>
                    <!-- <option value="StoreName">매장명</option> -->
                </select>
                <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
                    <input type="text" class="form-control" v-model="keyword" @change="refreshPage" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin:0px;margin-bottom:20px">
        <button class="btn btn-default" @click="keyManagement()">
            <i class="fa fa-cogs"></i> 키워드 관리
        </button>
        <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
            <option>30</option>
            <option>35</option>
            <option>40</option>
            <option>45</option>
        </select>
    </div>

    <div class="box box-solid">
        <div class="box-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th class="text-center">번호</th>
                        <th class="text-center">매장코드</th>
                        <th class="text-center">브랜드명</th>
                        <th class="text-center">매장명</th>
                        <th class="text-center">키워드</th>
                        <th class="text-center">리뷰사진</th>
                        <th class="text-center">등록일</th>
                        <th class="text-center">작성자</th>
                        <th class="text-center">삭제</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- v-for="(review, index) in review" v-bind:key="index" -->
                    <tr v-for="(review, index) in content" v-bind:key="index">
                        <td class="text-center">{{ totalElements - (startnum + index) }}</td>
                        <td class="text-center">{{ review.storeCode  }}</td>
                        <td class="text-center">{{ brandNameReturn(review.storeCode) }}</td>
                        <td class="text-center">{{ storeNameReturn(review.storeCode) }}</td>
                        <td class="text-center">
                            <p v-for="(item,idx) in review.reviewKeywordList" :key="idx">{{item.keyword}}</p>
                        </td>
                        <td class="text-center"><button class="btn btn-default" @click="ReviewPictureLook(review.reviewId)">보기</button></td>
                        <td class="text-center">{{ review.regDate.substring(0,10) }}</td>
                        <td class="text-center">{{ review.userName === null ? '' : review.userName  }}</td>
                        <td class="text-center">
                            <button class="btn btn-default" @click="delReview(review.reviewId)">삭제</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="box-footer text-center">
            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
        </div>
    </div>
    <ReviewModal v-if="ReviewModalIf" @ReviewModalClose="ReviewModalClose"></ReviewModal>
    <ReviewPicture :addsubimgid="addsubimgid" v-if="ReviewPictureIf" @ReviewPictureClose="ReviewPicture"></ReviewPicture>
</section>
</template>

<script>
import {
    rescode,
    menuval
} from "../../lib/consts";
import DetePicker from '../../components/DatePicker.vue';
import Pagination from "../../components/Pagination";
import ReviewModal from "./component/ReviewModal.vue";
import ReviewPicture from "./component/ReviewPicture.vue";
import moment from 'moment';
import {
    personalization,
    brandapi
} from "../../lib/api";
import {
    reqGet,
    reqPost
} from "../../lib/request";

export default {
    components: {
        DetePicker,
        Pagination,
        ReviewModal,
        ReviewPicture
    },
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
        tartnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
        startnum() {
            return (this.pageNumber - 1) * this.pageSize;
        },
    },
    data() {
        return {
            addsubimgid: 0,
            keywordId: null,
            reviewSearchType: "NoKeyword",
            shownum: 10, //pagination에 보여지는 번호개수
            keyword: '',
            dateFrom: moment().subtract(1,'months').format("YYYY-MM-DD"),
            dateTo: moment().format("YYYY-MM-DD"),
            totalPages: 0, //전체 페이지 수
            totalElements: 0,
            numberOfElements: 0, //현재 조회된 아이템 수
            pageNumber: 1,
            pageSize: 20,
            content: [],
            ReviewModalIf: false,
            ReviewPictureIf: false,
            keywordIdList: [],
            storeCodesArr: [],
            sbnameArr: [],
        }
    },
    watch: {
        reviewSearchType(newVal) {
            localStorage.review_reviewSearchType = newVal;
        },
        keyword(newkey) {
            localStorage.review_keyword = newkey;
        },
        pageNumber(newnum) {
            localStorage.review_Number = newnum;
        },
        pageSize(newsize) {
            localStorage.review_Size = newsize;
        },
    },
    created() {
        if (localStorage.initval == "1") {
            if (localStorage.review_reviewSearchType) {
                this.reviewSearchType = localStorage.review_reviewSearchType;
            }
            if (localStorage.review_keyword) {
                this.keyword = localStorage.review_keyword;
            }
            if (localStorage.review_Number) {
                this.pageNumber = parseInt(localStorage.review_Number);
            }
            if (localStorage.review_Size) {
                this.pageSize = parseInt(localStorage.review_Size);
            }
            if (localStorage.review_dateFrom) {
                this.dateFrom = localStorage.review_dateFrom;
            }
            if (localStorage.review_dateTo) {
                this.dateTo = localStorage.review_dateTo;
            }
        }
        this.$store.dispatch("doSetPath", {
            path1: menuval.LV1_BRD,
            path2: menuval.LV1_HOME,
            title: "리뷰관리",
        });
        this.refreshPage();
        this.keywordIdChange();
    },
    mounted() {
        if (localStorage.initval == "0") {
            localStorage.initval = "1";
            localStorage.review_reviewSearchType = this.reviewSearchType;
            localStorage.review_keyword = this.keyword;
            localStorage.review_Number = this.pageNumber;
            localStorage.review_Size = this.pageSize;
        }

    },
    methods: {
        storeNameReturn(val) {
            let vm = this;
            let storeName = '';
            console.log(val)
            if (vm.sbnameArr !== undefined) {
                vm.sbnameArr.forEach(el => {
                    if (el.storeCode === val) {
                        return storeName = el.storeName;
                    }
                });
            }
            return storeName;
        },
        brandNameReturn(val) {
            let vm = this;
            let brandName = ''
            if (vm.sbnameArr !== undefined) {
                vm.sbnameArr.forEach(el => {
                    if (el.storeCode === val) {
                        return brandName = el.brandName;
                    }
                });
            }
            return brandName;
        },
        delReview(reviewId) {
            if (confirm("삭제하시겠습니까? 삭제된 리뷰는 되돌리 수 없습니다.")) {

                reqPost(
                    personalization.base,
                    personalization.reviewDel,
                    this.authToken, {
                        reviewId: reviewId,
                    },
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                this.refreshPage();
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        keywordIdChange() {
            reqGet(
                personalization.base,
                personalization.reviewKeywordList,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.keywordIdList = [];
                            this.keywordIdList.push({
                                keyword: '선택해주세요.',
                                id: null
                            })
                            result.data.forEach(el => {
                                this.keywordIdList.push({
                                    keyword: el.keyword,
                                    id: el.id
                                })
                            });
                        }
                    }
                }
            );
        },
        ReviewModalClose() {
            this.ReviewModalIf = false;
        },
        refreshPage(flag) {
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            reqPost(
                personalization.base,
                personalization.reviewListPage,
                this.authToken, {
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    pageNumber: pageNumber,
                    pageSize: this.pageSize,
                    reviewSearchType: this.reviewSearchType,
                    keyword: this.keyword,
                    keywordId: this.keywordId
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.content = result.data.content;
                            this.content.forEach(el => {
                                this.storeCodesArr.push(el.storeCode)
                            });
                            this.totalPages = result.data.totalPages; //전체 페이지 수
                            this.totalElements = result.data.totalElements; //전체 아이템 수
                            this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
                            /*
                            data.data.size : 페이지 사이즈
                            data.data.number : 페이지 번호
                            data.data.first : 첫 페이지일 경우 true
                            data.data.last : 마지막 페이지일 경우 true
                            data.data.empty : 조회된 아이템이 없으면 true
                             */
                            this.getSBname();
                        } else {
                            this.content = [];
                            this.totalPages = 0; //전체 페이지 수
                            this.totalElements = 0; //전체 아이템 수
                            this.numberOfElements = 0; //현재 조회된 아이템 수
                        }
                    } else {
                        this.content = [];
                        this.totalPages = 0; //전체 페이지 수
                        this.totalElements = 0; //전체 아이템 수
                        this.numberOfElements = 0; //현재 조회된 아이템 수
                    }
                }
            );
        },
        getSBname() {
            reqPost(
                brandapi.base,
                brandapi.admofStores,
                this.authToken, {
                    "storeCodes": this.storeCodesArr,
                    "filter": {
                        "enabledStoresOnly": null
                    }
                },
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.sbnameArr = [];
                            result.data.byBrands.forEach(el => {
                                el.stores.forEach(sl => {
                                    this.sbnameArr.push({
                                        storeCode: sl.storeCode,
                                        brandName: el.brandName,
                                        storeName: sl.storeName,
                                    })
                                });
                            });
                            console.log(this.sbnameArr)
                        } else {
                            // alert(result.message);
                        }
                    }
                }
            );
        },
        keyManagement() {
            this.ReviewModalIf = true;
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.refreshPage(false);
        },
        ReviewPicture() {
            this.ReviewPictureIf = false;
        },
        ReviewPictureLook(id) {
            this.ReviewPictureIf = true;
            this.addsubimgid = id;
        }
    },
}
</script>

<style>

</style>
