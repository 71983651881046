<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body form-horizontal">
        <div class="table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <colgroup>
              <col width="100px">
              <col width="*">
            </colgroup>
            <tbody>
              <tr>
                <th>브랜드</th>
                <td colspan="3">{{brandName}}</td>
                <!-- <th>게시코드</th>
                <td>12345678</td> -->
              </tr>
              <tr>
                <th>제목 *</th>
                <td colspan="3"><input type="text" class="form-control" v-model="title"/></td>
              </tr>
              <tr>
                <th>기간 *</th>
                <td colspan="3"><div class="input-group" style="display: flex;flex-wrap: wrap;">
                  <DatePicker v-model="fromDate" :last="toDate"/>
                  <span class="input-group-addon" style="border:none;">-</span>
                  <DatePicker v-model="toDate" :first="fromDate"/> </div></td>
              </tr>
              <tr>
                <td colspan="4">
                  <!-- <editor v-model="pcheadText" :options="editorOptions"/> -->
                  <editor
                    api-key="02p3raa9urge93k9p07ljuvfbkovzy2wrad7dprwav8xe682"
                    :init="{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'help | code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    }"
                    v-model="content"
                  />
                </td>
              </tr>
              <tr>
                <th>노출 제목 *</th>
                <td colspan="3">
                  <input type="text" class="form-control" v-model="showTitle"/>
                </td>
              </tr>
              <tr>
                <th>섬네일 이미지 *</th>
                <td colspan="3"><SingleImgUpload v-model="thumbnailImageUrl" downlabel="" :imgtype="imgtype.SITE"/></td>
              </tr>
              <tr>
                <th>사이트 적용 *</th>
                <td colspan="3">
                  <div class="checkbox">
                    <label><input type="checkbox" :checked="getAllChecked()" @change="onClickAll($event)"/>전체</label>
                    <label><input type="checkbox" value="PC" v-model="channels"/>PC</label>
                    <label><input type="checkbox" value="MOBILE" v-model="channels"/>Mobile</label>
                    <label><input type="checkbox" value="EZWEL" v-model="channels"/>이지웰</label>
                    <label><input type="checkbox" value="BENEPIA" v-model="channels"/>SK베네피아</label>
                    <label><input type="checkbox" value="HAPPY" v-model="channels"/>해피머니</label>
                    <label> <input type="checkbox" value="LIFECARE" v-model="channels" />LIFECARE </label>
                    <label> <input type="checkbox" value="CHALETKOREA" v-model="channels" />샬레코리아 </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>연결 URL *</th>
                <td colspan="3"><input type="text" class="form-control" v-model="linkUrl"/></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group text-center">
          <button type="button" class="btn btn-default" style="margin-right: 10px" @click="goBack">
            뒤로
          </button>
          <button type="button" class="btn btn-default" @click="doRegister">저장</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval, imgtype } from "../../lib/consts";
import routepath from "../../lib/routes";
import SingleImgUpload from "../../components/SingleImgUpload";
import DatePicker from "../../components/DatePicker";
import { reqGet, reqPost } from "../../lib/request";
import { display } from "../../lib/api";
import { DateFormat } from "../../lib/utility";

// import 'codemirror/lib/codemirror.css';
// import '@toast-ui/editor/dist/toastui-editor.css';
// import '@toast-ui/editor/dist/i18n/ko-kr';
// import { Editor } from '@toast-ui/vue-editor';
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    DatePicker,
    SingleImgUpload,
    editor: Editor,
  },
  created() {
    this.promotionId = this.$route.query.promotionId;
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_DSP,
      path2: menuval.LV1_HOME,
      title: "",
    });
  },
  data() {
    return {
      pcheadText: "",
      editorOptions: {
        minHeight: "200px",
        language: "ko-KR",
        useCommandShortcut: true,
        useDefaultHTMLSanitizer: true,
        usageStatistics: true,
        hideModeSwitch: false,
        toolbarItems: [
          "heading",
          "bold",
          "italic",
          "strike",
          "divider",
          "hr",
          "quote",
          "divider",
          "ul",
          "ol",
          "task",
          "indent",
          "outdent",
          "divider",
          "table",
          "image",
          "link",
          "divider",
          "code",
          "codeblock",
        ],
      },
      promotionId:0,
      title:"",//프로모션 명
      showTitle:"",//노출 제목
      fromDate:"",//시작일자
      toDate:"",//종료일자
      brandCode:"",//브랜드코드
      brandName:"",//브랜드명
      content:"",//내용
      linkUrl:"",//연결링크
      thumbnailImageUrl:"",//썸네일이미지Url
      channels:[],//[PC, MOBILE, EZWEL, HAPPY, BENEPIA] 적용사이트
      useFlag:true,//활성화여부
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    imgtype(){
      return imgtype;
    }
  },
  mounted() {
    if(this.promotionId != 0)
    {
      this.refreshPage();
    }
    tinymce.activeEditor.getBody().setAttribute('contenteditable', true)
  },
  methods: {
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm:00");
    },
    refreshPage() {
      reqGet(
        display.base,
        display.promotiondetail + "?promotionId=" + this.promotionId,
        this.authToken,
        (result) => {
          if (result != null)
          {
            if(result.code == rescode.REQ_SUCCESS) {
              this.title = result.data.title;//프로모션 명
              this.showTitle = result.data.showTitle;//노출 제목
              this.fromDate = result.data.fromDate;//시작일자
              this.toDate = result.data.toDate;//종료일자
              this.brandCode = result.data.brandCode;//브랜드코드
              this.brandName = result.data.brandName;//브랜드명
              this.content = result.data.content;//내용
              this.linkUrl = result.data.linkUrl;//연결링크
              this.thumbnailImageUrl = result.data.thumbnailImageUrl;//썸네일이미지Url
              this.channels = result.data.channels;//[PC, MOBILE, EZWEL, HAPPY, BENEPIA] 적용사이트
              this.useFlag = result.data.useFlag;//활성화여부
            }else{
              alert(result.message);
            }
          }
        }
      );
    },
    goBack(){
      this.$router.go(-1);
    },
    onClickAll(event)
    {
      if(event.target.checked)
      {
        this.channels = ["PC", "MOBILE", "EZWEL", "HAPPY", "BENEPIA","LIFECARE","CHALETKOREA"];
      }else{
        this.channels = [];
      }
    },
    getAllChecked()
    {
      return this.channels.length == 7;
    },
    doRegister()
    {
      if(this.title == "")
      {
        alert("제목을 입력하세요.");
        return ;
      }
      if(this.fromDate == "" || this.toDate == "")
      {
        alert("기간을 입력하세요.");
        return ;
      }
      if(this.channels.length == 0)
      {
        alert("사이트 적용을 체크하세요.");
        return;
      }

      if(this.showTitle == "" || this.showTitle == null)
      {
        alert("노출제목이 입력되지 않았습니다.");
        return ;
      }
      if(this.thumbnailImageUrl == "" || this.thumbnailImageUrl == null)
      {
        alert("섬네일이 입력되지 않았습니다.");
        return ;
      }
      if(this.linkUrl == "" || this.linkUrl == null)
      {
        alert("연결URL이 입력되지 않았습니다.");
        return ;
      }
      if(this.promotionId == 0)
      {
        this.$store.dispatch('setDisabledLayout');

        reqPost(
          display.base,
          display.promotionregister,
          this.authToken,
          {
            title:this.title,
            showTitle:this.showTitle,
            fromDate:this.fromDate,
            toDate:this.toDate,
            brandCode:this.brandCode,
            brandName:this.brandName,
            content:this.content,
            linkUrl:this.linkUrl,
            thumbnailImageUrl:this.thumbnailImageUrl,
            channels:this.channels
          },
          (result) => {
            if (result != null) {
              this.$store.dispatch('setDisabledLayout');
              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              }else{
                alert(result.message);
              }
            }
          }
        );
      }else{
        reqPost(
          display.base,
          display.promotionmodify,
          this.authToken,
          {
            promotionId:this.promotionId,
            title:this.title,
            showTitle:this.showTitle,
            fromDate:this.fromDate,
            toDate:this.toDate,
            brandCode:this.brandCode,
            brandName:this.brandName,
            content:this.content,
            linkUrl:this.linkUrl,
            thumbnailImageUrl:this.thumbnailImageUrl,
            channels:this.channels
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {
                this.goBack();
              }else{
                alert(result.message);
              }
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped>
th {
  background-color: #dee2e685;
}
.form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.radio > label {
  margin-right: 10px;
}

.checkbox > label {
  margin-right: 10px;
}
</style>
