<template>
<div class="box box-solid">
    <div class="box-header">
        <h3 class="box-title">매장 이미지</h3>
    </div>
    <div class="box-body form-horizontal">
        <div class="form-group">
            <label class="col-sm-2 control-label">매장 이미지</label>
            <div class="col-sm-10">
                <div class="table-responsive p-0">
                    <table class="table table-hover text-nowrap text-center">
                        <thead>
                            <tr>
                                <th>타이틀</th>
                                <th>등록 이미지 개수</th>
                                <th>순서</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody v-for="(oitem, idx) in fakeui" :key="idx">
                            <tr>
                                <td class="linkcmp" @click="showstoreEditEVe(oitem,oitem.storeImages)">{{ oitem.title }}</td>
                                <td>{{ oitem.storeImages.length }}</td>
                                <td>
                                    <drop @drop="optionsort(idx, $event)">
                                        <drag class="btn btn-default btn-social-icon cursorMove" :transfer-data="{index:idx, mark:'option'}">
                                            <i class="fa fa-fw fa-arrows-v"></i>
                                        </drag>
                                    </drop>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-social-icon" @click="rmvoption(idx)">
                                        <i class="fa fa-fw fa-trash-o"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-right">
                        <button class="btn btn-default" @click="storeImgAddItemModal">+ 항목추가</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="text-center">
            <button type="button" class="btn btn-default" @click="storeImagesUpdate()">저장</button>
        </div> -->
    </div>
    <div class="modal" :style="showstoreEdit && {'display': 'block'}" id="newww" ref="scrollEl">
        <div class="modal-dialog new">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">{{ itemModalName }}</div>
                <div class="modal-body form-horizontal">
                    <div class="row">
                        <label class="col-sm-2">
                            타이틀 ＊
                        </label>
                        <div class="col-sm-10">
                            <select class="form-control newrenewalS" v-model="storeTitleSelect" :disabled="!addorEdit || directInput" @change="storeTitleChange">
                                <option value="0">선택</option>
                                <option value="인테리어">인테리어</option>
                                <option value="메뉴">메뉴</option>
                                <option value="익스테리어">익스테리어</option>
                                <option value="주차장">주차장</option>
                                <option value="부대시설">부대시설</option>
                            </select>
                            <label class="control-label">
                                직접입력
                                <input type="checkbox" :checked="directInput" v-model="directInput" :disabled="!addorEdit" style="margin-right:10px" @change="storeTitleChange" />
                            </label>
                            <label class="control-label">
                                <input v-if="directInput" type="text" maxlength="12" :disabled="!addorEdit" v-model="directInputWrit" class="form-control yyu" id="directInput">
                            </label>
                            <!-- <button class="btn btn-default titex" @click="titleExist" v-if="addorEdit">중복체크</button> -->
                            <div class="titleWarningText">{{ titleWarningText }}</div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-2">타이틀 설명</label>
                        <div class="col-sm-10">
                            <textarea type="text" class="form-control" v-model="description"></textarea>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-2">이미지 ＊</label>
                        <div class="col-sm-10">
                            <div :class="sctopClass ? 'ssio' : 'ssio on'">
                                <div class="nenenen">
                                    <stroeImgUploda :imgFile2="{}" :trueImg2="''" value :index="0" style="margin-right:10px" :change="onstoreImages" ref="prodimg" />
                                </div>
                            </div>
                            <p class="mt-2"><strong>미리보기</strong></p>
                            <!-- {{ detailImgArray.mainimg }} -->
                            <SubImageContentViewer ref="prodaddimgs" @movemouse="movemouse" @descriptionChange="descriptionChange" v-for="(simg, sidx) in detailImgArray.mainimg" :key="sidx" :index="sidx" :title="simg.title" :imgdes="simg.description" :images="simg.images" @remove="emvsubimg(sidx)" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="showstoreEditClose">닫기</button>
                    <button type="button" class="btn btn-default" @click="titleExist">{{ btnName }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :style="detailImg && {'display': 'block'}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">상세 이미지</div>
                <div class="modal-body form-horizontal">
                    <div class="row">
                        <label class="col-sm-2">이미지 ＊</label>
                        <div class="col-sm-10">
                            <div v-if="storeImages.images.length > 0">
                                <stroeImgUploda :imgFile2="{}" :trueImg2="''" :index="0" :value="storeImages.images[0].url" style="margin-right:10px" :change="onRmvSubImg" />
                            </div>
                            <div v-else>
                                <stroeImgUploda :imgFile2="{}" :trueImg2="''" value :index="0" style="margin-right:10px" :change="onstoreImages" ref="prodimg" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-2">이미지 설명</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" v-model="storeImages.description">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="detailImgClose">닫기</button>
                    <button v-if="detailImgModalBtnType" type="button" class="btn btn-default" @click="onEdtsubImages">적용</button>
                    <button v-if="!detailImgModalBtnType" type="button" class="btn btn-default" @click="onAddsubImages">적용</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Drag,
    Drop
} from "vue-drag-drop";
import stroeImgUploda from "../../../../components/stroeImgUploda";
import SubImageContentViewer from "../../../../components/SubImageContentViewer";
import {
    rescode,
    menuval
} from "@/lib/consts";
import {
    reqGet,
    reqPost
} from "../../../../lib/request";
import {
    adminapi,
    brandapi
} from "../../../../lib/api";
export default {
    computed: {
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        let target = document.getElementById('newww');
        target.addEventListener('scroll', (e) => {
            this.handleScroll(e.target.scrollTop)
        });
        this.refresh();
        // console.log(this.store.options)
    },
    components: {
        Drag,
        Drop,
        SubImageContentViewer,
        stroeImgUploda
    },
    data() {
        return {
            titleWarningText:'',
            sctopClass: true,
            btnName: '',
            detailImgModalBtnType: false,
            overlap: false,
            fakeui: [],
            description: '',
            storeImglist: [],
            storeTitleSelect: '0',
            showstoreEdit: false,
            addorEdit: false,
            directInput: false,
            detailImg: false,
            itemModalName: "",
            prodimgVal: "",
            editsubimgidx: 0,
            detailImgArray: {
                mainimg: [],
            },
            edtoitem: {},
            directInputWrit: "",
            storeImages: {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
            },
        }
    },
    methods: {
        handleScroll(sctop) {
            if (sctop >= 300) {
                this.sctopClass = false;
            } else {
                this.sctopClass = true;
            }
        },
        movemouse(drop, drag) {
            if (drag !== undefined) {
                if (drag.mark != 'subimgs') {
                    return;
                }
                var t_array = this.detailImgArray.mainimg;
                this.detailImgArray.mainimg = [];
                var b = t_array[drag.index];
                t_array[drag.index] = t_array[drop];
                t_array[drop] = b;
                this.detailImgArray.mainimg = t_array;
            }
        },
        descriptionChange(idx, descriptionkk) {
            this.detailImgArray.mainimg[idx].description = descriptionkk;
        },
        storeTitleChange() {
            this.overlap = false;
        },
        titleExist() {
            if (this.btnName === '변경') {
                this.onMdfsubImages();

            } else {
                let titles = [];
                this.fakeui.forEach(el => {
                    titles.push(el.title)
                });
                let blank_pattern = /^\s+|\s+$/g;
                if (!this.directInput) {
                    if (this.storeTitleSelect === '0') {
                        // alert('타이틀을 선택해주세요.');
                        this.titleWarningText = '타이틀을 선택해주세요.';
                        this.$refs.scrollEl.scrollTo(0, 0)
                        return;
                    }
                } else {
                    if (this.directInputWrit === '') {
                        // alert('타이틀을 입력해주세요.');
                        this.titleWarningText = '타이틀을 입력해주세요.';
                        this.$refs.scrollEl.scrollTo(0, 0)
                        return;
                    }
                    if (this.directInputWrit.replace(blank_pattern, '') === "") {
                        // alert('공백만 입력되었습니다.');
                        this.titleWarningText = '공백만 입력되었습니다.';
                        this.$refs.scrollEl.scrollTo(0, 0)
                        return;
                    }
                }
                let title = this.directInput ? this.directInputWrit : this.storeTitleSelect;
                let nosavetitle = false;
                if (titles.includes(title)) {
                    nosavetitle = true;
                }
                reqGet(
                    brandapi.base,
                    brandapi.storeImagesTitleExist + "?storeCode=" + this.$route.query.stcode + "&titleName=" + title,
                    this.authToken,
                    (result) => {
                        if (result != null) {
                            if (result.code == rescode.REQ_SUCCESS) {
                                if (nosavetitle === true) {
                                    this.titleWarningText = '타이틀이 중복된 값입니다.';
                                    this.$refs.scrollEl.scrollTo(0, 0)
                                    this.overlap = '중복';
                                } else {
                                    if (result.data === true) {
                                        this.overlap = '중복';
                                        this.titleWarningText = '타이틀이 중복된 값입니다.';
                                        this.$refs.scrollEl.scrollTo(0, 0)
                                    } else {
                                        this.overlap = '가능';
                                        this.titleWarningText = '';
                                      this.$emit('get-kakao-alert', 'STORE');
                                    }
                                    this.onMdfsubImages();
                                }
                            } else {
                                alert(result.message);
                            }
                        }
                    }
                );
            }
        },
        storeImagesUpdate() {
            reqPost(
                brandapi.base,
                brandapi.storeImagesUpdate,
                this.authToken, {
                    images: this.fakeui,
                    storeCode: this.$route.query.stcode,
                },
                (result) => {
                    if (result != null) {
                        if (result.code === rescode.REQ_SUCCESS) {

                            alert('저장되었습니다.');
                            this.refresh();
                        }
                    }
                }
            );
        },
        refresh() {
            reqGet(
                brandapi.base,
                brandapi.storeImageslist + "?storeCode=" + this.$route.query.stcode,
                this.authToken,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            this.fakeui = result.data.list;
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );
        },
        storeTitleSelecteve() {
            if (this.addorEdit == true) {
                this.storeTitleSelect = '0';
            }
        },
        detailImgClose() {
            this.detailImg = false
        },
        optionsort(selidx, drag) {
            if (drag.mark != 'option') {
                return;
            }
            var t_array = this.fakeui; //기존 배열
            this.fakeui = []; //배열비우고
            var b = t_array[drag.index]; //드래그할배열 담고
            let newTrp = JSON.parse(JSON.stringify(t_array[selidx].priority)); //드래그해서 움직일 tr에 priority
            let existingTr = JSON.parse(JSON.stringify(b.priority)); //기존위치에 tr에 priority

            t_array[selidx].priority = existingTr; // priority 변경
            t_array[drag.index].priority = newTrp; //드래그 한 tr priority 변경
            t_array[drag.index] = t_array[selidx]; //드래그 한 tr
            t_array[selidx] = b; // 놓여질부분
            this.fakeui = t_array; //다시 생성
          this.$emit('get-kakao-alert', 'STORE');
            this.storeImagesUpdate();
        },
        rmvoption(ridx) {
            if (confirm("매장 이미지를 삭제 하시겠습니까?")) {
                this.fakeui.splice(ridx, 1);
              this.$emit('get-kakao-alert', 'STORE');
                this.storeImagesUpdate();
            }
        },
        showstoreEditEVe(oitem, storeImages) {
            this.showstoreEdit = true;
            this.itemModalName = "항목상세";
            this.titleWarningText = '';
            this.btnName = '변경';
            this.addorEdit = false;
            this.detailImgArray.mainimg = [];
            var new_images = oitem.storeImages.map((item) => {
                return item
            });
            oitem.storeImages.forEach((element, idx) => {
                this.detailImgArray.mainimg.push({
                    new_images,
                    images: [element],
                    description: element.description,
                    title: element.title
                });
                // this.detailImgArray.mainimg.push()
            });
            this.directInput = oitem.deleted;
            if (oitem.deleted) {
                this.directInputWrit = oitem.title;
            } else {
                this.storeTitleSelect = oitem.title;
            }
            this.description = oitem.description;
            this.edtoitem = oitem;
            console.log(this.detailImgArray.mainimg)
            this.storeTitleSelecteve();
        },
        showstoreEditClose() {
            this.showstoreEdit = false;
        },
        detailImgModal(val) {
            if (!val) {
                this.storeImages = {
                    title: "", //서브 이미지 제목
                    description: "", //서브 이미지 설명
                    displayOrder: 1, //서브 이미지 배열 순서
                    images: [] //
                };
            }
            this.detailImgModalBtnType = val;
            this.detailImg = true;
        },
        onstoreImages(file) {
            if (file.length > 0) {
                file.forEach((el, idx) => {
                    let _url = el.azureUrl ? el.azureUrl : el.url;
                    this.storeImages.images = [{
                        'width': el.width,
                        'height': el.height,
                        'url': _url,
                    }]
                    this.onAddsubImages();
                });

            } else {
                if (!Array.isArray(file)) {
                    let _url = file.azureUrl ? file.azureUrl : file.url;
                    this.storeImages.images = [{
                        'width': file.width,
                        'height': file.height,
                        'url': _url,
                    }]
                    this.onAddsubImages();
                } else {
                    this.storeImages.images = null
                }

            }
        },
        onAddsubImages() { // 상세이미지 추가
            this.storeImages.displayOrder = this.detailImgArray.mainimg.length + 1;
            var new_images = this.storeImages.images.map((item) => {
                return item
            });

            if (new_images.length == 0) {
                alert("이미지를 추가하세요.");
                return;
            }
            this.detailImgArray.mainimg.push({
                ...this.storeImages,
                images: new_images
            });
            console.log(this.detailImgArray.mainimg)

            this.detailImgClose();
        },
        onEdtsubImages() { // 상세이미지 수정
            let new_images = this.storeImages.images.map((item) => {
                return item
            });
            if (new_images.length == 0) {
                alert("이미지를 추가하세요.");
                return;
            }
            this.detailImgArray.mainimg[this.editsubimgidx] = {
                ...this.storeImages,
                images: new_images
            };

            this.detailImgClose();
        },
        onMdfsubImages() { // 상세이미지 추가 저장
            var new_images = this.detailImgArray.mainimg.map((item) => {
                return item
            });
            if (!this.detailImg && this.addorEdit) {
                if (this.overlap === '중복') {
                    this.titleWarningText = '타이틀이 중복된 값입니다.';
                    return;
                } else {
                    this.titleWarningText = '';
                }
                if (this.directInput == true) {
                    if (this.directInputWrit === "") {
                        this.titleWarningText = '타이틀을 적어주세요.';
                        return;
                    } else {
                        this.titleWarningText = '';
                    }
                } else if (this.storeTitleSelect == "0") {
                    this.titleWarningText = '타이틀을 선택해주세요.';
                    return;
                } else {
                    this.titleWarningText = '';
                }
            }
            if (new_images.length == 0) {
                alert("이미지를 추가하세요.");
                return;
            }
            if (this.detailImg) {
                this.detailImgClose();
            } else {
                let newarray = [];
                this.detailImgArray.mainimg.forEach(el => {
                    newarray.push({
                        'url': el.images[0].url,
                        'width': el.images[0].width,
                        'height': el.images[0].height,
                        'description': el.description,
                    })
                });
                if (!this.addorEdit) {
                    this.fakeui.forEach((el, idx) => {
                        if (el.priority === this.edtoitem.priority) {
                            el.storeImages = newarray;
                            el.title = this.directInput ? this.directInputWrit : this.storeTitleSelect;
                            el.description = this.description;
                            el.deleted = this.directInput;
                        }
                    });
                } else {
                    let priorityArr = [];
                    let newpriority = 0;
                    let newpNum = 0;
                    if (this.fakeui.length === 0) {
                        //배열이 0인경우는 신규 추가시는 길이 + 1 에러안뜸
                        newpNum = this.fakeui.length + 1
                    } else {
                        //우선순위 정해줄때 기존배열에 가장큰수보다 1증감시켜서 넣기
                        this.fakeui.forEach(el => {
                            priorityArr.push(el.priority)
                        });

                        var m = Math.max(...priorityArr);
                        newpriority = m;
                        newpNum = newpriority + 1
                    }

                    // console.log(newpriority);
                    this.fakeui.push({
                        "storeImages": newarray,
                        'title': this.directInput ? this.directInputWrit : this.storeTitleSelect,
                        'description': this.description,
                        'deleted': this.directInput,
                        'priority': newpNum,
                    });
                }
                this.storeImages = {
                    title: "", //서브 이미지 제목
                    description: "", //서브 이미지 설명
                    displayOrder: 1, //서브 이미지 배열 순서
                    images: [] //
                };
                this.storeImagesUpdate();
                this.showstoreEditClose();
            }
            // this.editsubimgidx = -1;

        },
        showEditSubImage(selsubimg, selidx) {
            this.editsubimgidx = selidx;
            var new_images = selsubimg.images.map((item) => {
                return item
            });
            this.storeImages = {
                ...selsubimg,
                images: new_images
            };
            // this.addimgdlg = true;
            this.detailImgModal(true);
        },
        emvsubimg(ridx) {
            this.detailImgArray.mainimg.splice(ridx, 1);
        },
        storeImgAddItemModal() {
            this.showstoreEdit = true;
            this.itemModalName = "항목추가";
            this.titleWarningText = '';
            this.btnName = '추가';
            this.directInputWrit = '';
            this.addorEdit = true;
            this.directInput = false;
            this.description = '';
            this.detailImgArray.mainimg = [];
            this.storeImages = {
                title: "", //서브 이미지 제목
                description: "", //서브 이미지 설명
                displayOrder: 1, //서브 이미지 배열 순서
                images: [] //
            };
            this.storeTitleSelecteve();
        },
        onRmvSubImg(idx) {
            this.storeImages.images.splice(idx, 1);
        },
        sortupsub(sidx) {
            if (sidx > 0) {
                var t_array = this.detailImgArray.mainimg;
                this.detailImgArray.mainimg = [];
                var s_val = t_array[sidx];
                t_array[sidx] = t_array[sidx - 1];
                t_array[sidx - 1] = s_val;
                this.detailImgArray.mainimg = t_array;
            }
        },
        sortdownsub(sidx) {
            if (sidx + 1 < this.detailImgArray.mainimg.length) {
                var t_array = this.detailImgArray.mainimg;
                this.detailImgArray.mainimg = [];
                var s_val = t_array[sidx];
                t_array[sidx] = t_array[sidx + 1];
                t_array[sidx + 1] = s_val;
                this.detailImgArray.mainimg = t_array;
            }
        },
    }
}
</script>

<style scoped>
.mt-2 {
    margin-top: 20px;
}

.newrenewalS {
    width: auto;
    margin-left: 0px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

th {
    background-color: #dee2e685;
}

.radio>label {
    margin-right: 10px;
}

.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.tbl_h200 {
    height: 200px;
    overflow: scroll;
}

.nav-tabs li a {
    cursor: pointer;
}

.col-inline {
    display: inline-flex;
    align-items: center;
}

.btn-selweek {
    background-color: #5B9BD5;
    color: #FFFFFF;
}

@media (min-width: 768px) {
    .sm-inline {
        display: flex;
    }
}

.redmsg {
    color: #ff0000;
}

.blurmsg {
    color: #0000ff;
}

.titex {
    position: absolute;
    top: 7px;
}

.yyu {
    /* max-width: 50%; */
}

.cursorMove {
    cursor: move;
}

.nenenen {
    min-height: 120px;
}

.ssio {
    z-index: 999;
}

.ssio.on {
    position: sticky;
    min-width: 100%;
    width: 100%;
    max-width: 37%;
    z-index: 999999999999999999999;
    background: #fff;
    top: 0;
}

.titleWarningText {
    color: red;
    margin-top: 5px;
}
</style>
