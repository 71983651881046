<template>
  <div>
    <div class="table-responsive p-0">
      <table class="table table-hover text-nowrap">
        <colgroup>
          <col width="*%"/>
          <col width="7"/>
          <col width="10%" v-if="storeKakaoId"/>
          <col width="10%" v-if="storeKakaoId"/>
          <col width="*%"/>
          <col width="7%"/>
          <col width="7%"/>
          <col width="7%"/>
          <col width="5%"/>
          <col width="5%"/>
          <col width="5%"/>
          <col width="5%"/>
        </colgroup>
        <thead>
          <tr>
            <th class="text-center" style="align-content: center" rowspan="2">상품명</th>
            <th class="text-center" style="align-content: center" rowspan="2">유형</th>
            <th class="text-center" style="align-content: center" rowspan="2" v-if="storeKakaoId">판매 채널</th>
            <th class="text-center" style="align-content: center" rowspan="2" v-if="storeKakaoId">채널 추가 정보</th>
            <th class="text-center" colspan="5">예약옵션</th>
            <th class="text-center" style="align-content: center" rowspan="2">수정</th>
            <th class="text-center" style="align-content: center" rowspan="2">노출 순서</th>
            <th class="text-center" style="align-content: center" rowspan="2">삭제</th>
          </tr>
          <tr>
            <th class="text-center">옵션명</th>
            <th class="text-center">정상가</th>
            <th class="text-center">판매가</th>
            <th class="text-center">기준 인원</th>
            <th class="text-center">권종</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rmitem, rmidx) in optionsetList" :key="rmidx">
            <td class="text-center">{{ rmitem.goodsName }}</td>
            <td class="text-center">{{ rmitem.menuOptionType | opnameM }}</td>
            <td class="text-center d-flex" style="justify-content: center; align-items: center" v-if="storeKakaoId">
              <template v-for="tmpItem in rmitem.saleChannels ">
                <div v-if="tmpItem == 'KAKAO' && storeKakaoId" class="imgBox margin-r-5" style="background-color: #FEE500;" >
                 <img  src="/static/img/icon/kakao.png" height="25" width="25">
                </div>
                <div v-if="tmpItem == 'TABLENJOY'" class="imgBox margin-r-5"  style="background-color: red;" >
                  <img  src="/static/img/logo_V.png" height="25" width="25">
                </div>
              </template>
            </td>
            <td class="text-center" v-if="storeKakaoId">
              <button class="btn btn-default"
                      v-if="rmitem.saleChannels.filter(x=>x==='KAKAO').length>0"
                      @click="channelAdditionalModalOpen(rmitem.goodsCode)">수정
              </button>
            </td>
            <td class="text-center">
              <template v-for="tmpItem in rmitem.options.list ">
                <div>
                  {{tmpItem.optionName}}
                </div>
              </template>
            </td>
            <td class="text-center">
              <template v-for="tmpItem in rmitem.options.list ">
                <div>
                  {{tmpItem.listPrice | numFormat}}
                </div>
              </template>
            </td>
            <td class="text-center">
              <template v-for="tmpItem in rmitem.options.list ">
                <div>
                  {{tmpItem.salePrice | numFormat}}
                </div>
              </template>
            </td>
            <td class="text-center">
              <template v-for="tmpItem in rmitem.options.list ">
                <div>
                  {{tmpItem.headCount }}
                </div>
              </template>
            </td>
            <td class="text-center">
              <template v-for="tmpItem in rmitem.options.list ">
                <div>
                  {{tmpItem.ageGroup | getTypeTextReturn}}
                </div>
              </template>
            </td>
            <td class="text-center">
              <button class="btn btn-default"
                      @click="optionEdit(rmidx, rmitem.goodsCode)">수정
              </button>
            </td>
            <td class="text-center">
              <i class="fa fa-fw fa-arrow-up optionUpDownBtn"
                 @click="optionUpDown(rmitem, rmidx, optionsetList, 'up')"></i>
              <i class="fa fa-fw fa-arrow-down optionUpDownBtn"
                 @click="optionUpDown(rmitem, rmidx, optionsetList, 'down')"></i>
            </td>
            <td class="text-center">
              <button class="btn btn-default"
                      @click="optionDel(rmitem.goodsCode)"><i
                class="fa fa-fw fa-trash-o"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="right-btn">

      <button v-if="optionsetList.length==0" class="btn btn-default" @click="optionMake">옵션 만들기</button>
      <button v-else class="btn btn-default" @click="optionMake">예약상품 등록</button>
    </div>

    <!----------------------------------  모달 ---------------------------------------------->
    <div class="modal" :style="optionModal && { 'display': 'block' }"  >
      <div class="modal-dialog" style="width: 80%">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">{{ optionModalName }}</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="col-sm-2">
                상품명
              </div>
              <div class="col-sm-10 d-flex">
                <input type="text" class="form-control" v-model="menuGoods.goodsName" style="width: 90%" />
                <div class="check-item-length ">{{ menuGoods.goodsName.length }}/50 자</div>
              </div>
              <div class="col-sm-2" v-if="storeKakaoId">
                판매채널
              </div>
              <div class="col-sm-10" v-if="storeKakaoId">
                <div class="input-group checkbox">
                  <label style="margin-right:10px">
                    <input type="checkbox" v-model="menuGoods.saleChannels" value="TABLENJOY"/>
                    테이블엔조이
                  </label>
                  <label v-if="storeKakaoId">
                    <input type="checkbox" v-model="menuGoods.saleChannels" value="KAKAO"/>
                    카카오톡 예약하기
                  </label>
                </div>
              </div>
              <div class="col-sm-2">
                예약 유형
              </div>
              <div class="col-sm-10">
                <div class="radio">
                  <label style="margin-right:10px">
                    <input type="radio" value="MenuBooking" v-model="menuGoods.menuOptionType"
                           :disabled="optionModalName=='옵션 수정'" @change="onmenutRadio"/>
                    메뉴
                  </label>
                  <label>
                    <input type="radio" value="DepositBooking" v-model="menuGoods.menuOptionType"
                           :disabled="optionModalName=='옵션 수정'" @change="onmenutRadio"/>
                    예약금
                  </label>
<!--                  <label>-->
<!--                    <input type="radio" value="PersonBooking" v-model="menuGoods.menuOptionType"-->
<!--                           :disabled="optionModalName=='옵션 수정'" @change="onmenutRadio"/>-->
<!--                    인원수-->
<!--                  </label>-->
<!--                  <label v-if="menuGoods.menuOptionType =='DepositBooking'" style="margin-right:10px">-->
<!--                    <input type="checkbox" ref="isDeposit" v-model="menuGoods.isDeposit" :value="false"  @change="saveDeposit()" :disabled="optionModalName=='옵션 수정'"/>-->
<!--                    예약금 등록-->
<!--                  </label>-->
                </div>
              </div>
            </div>
            <div class="form-group" >
              <div class="col-sm-2">
                예약 옵션
              </div>
              <div class="col-sm-10" v-if="menuGoods.menuOptionType == 'MenuBooking' || menuGoods.menuOptionType == 'DepositBooking'">
                <input v-if="menuGoods.menuOptionType=='MenuBooking'" type="button" class="btn btn-default pull-right margin-bottom" @click="menuSelectOpen" value="메뉴선택">
                <input v-else type="button" class="btn btn-default pull-right margin-bottom" @click="menuLineAddit" value="옵션추가">

                <table class="table table-hover text-nowrap" style="margin-bottom:0px;">
                  <thead>
                    <tr>
                      <th class="text-center" v-if="menuGoods.menuOptionType=='MenuBooking'">메뉴코드</th>
                      <th class="text-center">옵션명</th>
                      <th class="text-center">정상가</th>
                      <th class="text-center">판매가</th>
                      <th class="text-center">기준 인원</th>
                      <th class="text-center">권종</th>
                      <th class="text-center">노출순서</th>
                      <th class="text-center">삭제</th>
                    </tr>
                  </thead>
                  <tbody class="scrollHeight">
                  <template v-if="selectedMenuOptions">
                    <tr v-for="(item, idx) in selectedMenuOptions " :key="idx" >
                      <td class="text-center" v-if="menuGoods.menuOptionType == 'MenuBooking'">{{item.menuCode}}</td>
                      <td>
                        <div class="text-center">
                          <input class="form-control newTitle" type="text" v-model="item.optionName">
                          <p>
                            <span style="min-width: 150px;">{{item.optionName.length}}/25자</span>
                          </p>

                        </div>
                      </td>
                      <td class="text-center">
                        <input class="form-control newTitle" type="text" v-model="item.listPrice"
                               style="max-width:100px;" readonly>
                      </td>
                      <td class="text-center">
                        <input class="form-control newTitle" type="text" v-model="item.salePrice"
                               style="max-width:100px;" :disabled=" menuGoods.menuOptionType == 'PersonBooking' " @keyup="chkMenual( $event,idx)">
                        <p
                          v-if="percent(uncomma(item.listPrice) - uncomma(item.salePrice), uncomma(item.listPrice)) !== '0.00%' && menuGoods.menuOptionType == 'MenuBooking'">
                            <span style="min-width: 150px;" v-if="Number(item.salePrice) !== 0">
                              ({{
                                percent(uncomma(item.listPrice) - uncomma(item.salePrice),
                                  uncomma(item.listPrice))
                              }})
                            </span>
                        </p>
                      </td>
                      <td class="text-center">
                        <input class="form-control newTitle" type="text" v-model="item.headCount"
                               style="max-width:100px;" readonly>
                      </td>
                      <td>
                        <div>
                          <label >
                            <input type="radio" value="Adult" v-model="item.ageGroup"  />성인
                          </label>
                        </div>
                        <div>
                          <label >
                            <input type="radio" value="Child" v-model="item.ageGroup"   />소인
                          </label>
                        </div>
                        <div>
                          <label>
                            <input type="radio" value="Baby" v-model="item.ageGroup" />
                            유아
                          </label>
                        </div>
                      </td>
                      <td>
                        <i class="fa fa-fw fa-arrow-up optionUpDownBtn"
                           @click="menuUpDown(item, idx, selectedMenuOptions, 'up')"></i>
                        <i class="fa fa-fw fa-arrow-down optionUpDownBtn"
                           @click="menuUpDown(item, idx, selectedMenuOptions,'down')"></i>
                      </td>
                      <td class="text-center">
                        <button class="btn btn-default"
                                @click="menuDel(idx,item.optionCode)"><i
                          class="fa fa-fw fa-trash-o"></i></button>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>


            </div>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-default" @click="optionModalClose">취소</button>
            <button type="button" class="btn btn-default" @click="optionModalSave" v-if="menuGoods.menuOptionType == 'MenuBooking' || menuGoods.menuOptionType == 'DepositBooking'">저장</button>
          </div>
        </div>
      </div>
    </div>
    <!----------------------------------  모달 end ---------------------------------------------->
    <!----------------------------------  메뉴선택 모달 START ---------------------------------------------->
    <div class="modal" :style="menuSelect && { 'display': 'block' }" style="z-index: 9999;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">메뉴선택</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="col-sm-12">
                <div class="table-responsive tbl_h200 p-0 ">

                  <table class="table table-hover text-nowrap" style="margin-bottom:0px;">
                    <thead>
                    <tr>
                      <th class="text-center">선택</th>
                      <th class="text-center">메뉴코드</th>
                      <th class="text-center">메뉴명</th>
                      <th class="text-center">정상가</th>
                      <th class="text-center">기준 인원</th>
                    </tr>
                    </thead>
                    <tbody class="scrollHeight">
                      <tr v-for="(mitem, midx) in menuList" :key="midx"
                          @click="menuListTrClick(mitem.menuCode, midx)"
                          :style="menuListTrStyle(midx) && { 'background-color': '#D3E4F4' }">
                        <td v-if="!mitem.chevron"></td>
                        <td class="text-center" v-else><i class="fa fa-chevron-down"></i>
                        </td>
                        <td class="text-center"> {{ mitem.menuCode }}</td>
                        <td class="text-center"> {{ mitem.menuName }}</td>
                        <td class="text-center"> {{ mitem.listPrice | numFormat }}</td>
                        <td class="text-center"> {{ mitem.headCount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-default" @click="menuSelectClose">취소</button>
            <button type="button" class="btn btn-default" @click="menuSelectSave">추가</button>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------------- 메뉴선택 모달 end ---------------------------------------------->
    <!----------------------------------  채널 추가 정보 모달 START ---------------------------------------------->
    <div class="modal" :style="channelModalToggle && { 'display': 'block' }" >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">채널 추가 정보</div>
          <div class="modal-body form-horizontal">
            <div class="form-group">
              <div class="col-sm-2">
                이용가능 연령
              </div>
              <div class="col-sm-10 margin-bottom" style="padding-top: 0px !important;">
                <div class="input-group checkbox d-flex" style="align-items: center" >
                  <label style="margin-right:40px">
                    <input type="checkbox" v-model="usableAgeToggle" :value="false"/>
                    이용 연령 제한
                  </label>
                  <div class="d-flex " style="align-items: center" v-if="usableAgeToggle">
                    만
                    <input class="form-control newTitle margin-bottom" type="text"  v-model="usableAge"
                           style="max-width:100px;">세

                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                유의사항
              </div>
              <div class="col-sm-10 margin-bottom">
                <textarea class="form-control" rows="5" maxlength="2000" v-model="notice" ></textarea>
                <span class="mainIntroNum" >{{!notice ? 0:notice.length}}/ 2000 자</span>
              </div>
              <div class="col-sm-2">
                이용안내
              </div>
              <div class="col-sm-10 margin-bottom">
                <textarea class="form-control" rows="5" maxlength="2000" v-model="useInformation" ></textarea>
                <span class="mainIntroNum" >{{!useInformation? 0:useInformation.length}}/ 2000 자</span>
              </div>
              <div class="col-sm-2">
                상품이미지
              </div>
              <div class="col-sm-10 margin-bottom">
<!--                <button class="btn btn-default margin-bottom"-->
<!--                        @click="storeImage1ModalOpen('kakao')">매장 이미지에서 선택-->
<!--                </button>-->
                <div class="d-flex">
                  <SingleThumbnail v-for="(image, i) in kaKaoGoodsImages" :key="i" :value="kaKaoGoodsImages[i].url" :imgFile="{}"
                                   :imgtype="imgtype.GOODS" style="margin-right:10px"
                                   :change="(newval, w, h)=>{onChangeGoodsImg(i, newval, w, h)}"/>
                  <SingleThumbnail v-if="kaKaoGoodsImages.length<10" value="" downlabel="" :imgtype="this.imgtype.GOODS" :change="onNewGoodsImg" :imgFile="{}"/>
                </div>
                <label class="col-sm-12 control-label">
                  • 미등록 시 카카오톡 예약하기 장소 대표 이미지로 대체되어 등록됩니다.<br/>
                  • 이미지는 최대 10개 까지 등록 가능합니다.
                </label>
              </div>
              <div class="col-sm-2">
                상품 설명 이미지
              </div>
              <div class="col-sm-10 ">
<!--                <button class="btn btn-default margin-bottom"-->
<!--                        @click="storeImage1ModalOpen('description')">매장 이미지에서 선택-->
<!--                </button>-->
                <div class="d-flex">
                  <SingleThumbnail v-for="(image, i) in goodsDescriptionImages" :key="i" :value="goodsDescriptionImages[i].url" :imgFile="{}"
                                   :imgtype="imgtype.GOODS" style="margin-right:10px"
                                   :change="(newval, w, h)=>{onChangeDescriptionImg(i, newval, w, h)}"/>
                  <SingleThumbnail v-if="goodsDescriptionImages.length<10" value="" downlabel="" :imgtype="this.imgtype.STORE" :change="onNewGoodsDescriptionImg" :imgFile="{}"/>
                </div>
                <label class="col-sm-12 control-label">
                  • 미등록 시 카카오톡 예약하기 장소 대표 이미지로 대체되어 등록됩니다.<br/>
                  • 이미지는 최대 10개 까지 등록 가능합니다.
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button type="button" class="btn btn-default" @click="channelAdditionalModalClose">취소</button>
            <button type="button" class="btn btn-default" @click="channelAdditionalModalSave">수정</button>
          </div>
        </div>
      </div>
    </div>
    <!----------------------------------  채널 추가 정보 모달 END ---------------------------------------------->
    <!----------------------------------  상품 이미지 모달 START ---------------------------------------------->
<!--    <div class="modal" :style="storeImage1Toggle && {'display': 'block'}">-->
<!--      <div class="modal-dialog drag" style="width: 50%">-->
<!--        <div class="modal-content">-->
<!--          <div class="modal-header border-bottom-0">-->
<!--            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >-->
<!--              <span aria-hidden="true">×</span>-->
<!--            </button>-->
<!--            상품 이미지-->
<!--          </div>-->
<!--          <div class="modal-body addmodals">-->
<!--            <div class="addimgInputGroup" style="margin-left: 16px;">-->

<!--              <div class="form-group">-->
<!--                <label class="control-label">설명</label>-->
<!--                <input type="text" class="form-control" v-model="addsubimg.description" />-->
<!--              </div>-->
<!--              <div class="form-group">-->
<!--                <div class="addlist" style="width:100%">-->
<!--                  <StoreProdImg :value="addsubimg.images" :index="addsubimg.images.length" style="margin-right:10px" @getsubFileData="getsubFileData" @newimgadd="onAddsubImg" :change="onRmvSubImg" ref="subImages" />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="imgBoxDivGroup" >-->
<!--              <div class="imgDragbox" style="background: #dedede;">-->

<!--                <ul class="titleArr">-->
<!--                  <li class="cls_click" v-for="(titem,idx) in titleArr" :key="idx" @click="titleArrClcik(titem)">-->
<!--                    <a>{{titem.title}}</a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--                <div>-->
<!--                  <ul class="tab">-->
<!--                    <li v-for="title in storeTitleList" :key="title">{{ title }}</li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--                <div :id="'holder' + addsubimg.images.length" class="dropImgBox" v-on:dragover="allowDrop" v-on:drop="ondrop($event,1)" @dragenter="onDragenter">-->
<!--                  <img v-for="(img,idx) in imgDragList" :key="idx" class="imgboxChild" :src="img.url" :width="img.width" :height="img.height" draggable="true" v-on:dragstart="drag" :id="'imgidx'+idx">-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="modal-footer text-center">-->
<!--            <button type="button" class="btn btn-default" @click="storeImage1ModalClose">취소</button>-->
<!--            <button type="button" class="btn btn-default" @click="storeImage1ModalSave">저장</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import {reqGet, reqPost} from "@/lib/request";
import {brandapi} from "@/lib/api";
import {imgtype, rescode} from "@/lib/consts";
import {getPercent} from "@/lib/utility";
import SingleThumbnail from "@/components/SingleThumbnail.vue";
import StoreProdImg from "@/components/StoreProdImg.vue";

export default {
  components: {StoreProdImg, SingleThumbnail},
  props:{
    kakaoId:Number
  },
  data() {
    return {
      optionsetList: [],
      edtss: false,
      optionCode: '',
      tab: 0,
      menutRadio: false,
      optionMakeModal: false,
      optionModal: false,
      optionModalName: "",
      menuList: [],

      mOpName: "",
      mNprice: "",
      mPrice: 0,
      mPersonnel: "",
      clickMenuCode: "",
      ageGroup: 'Adult',
      menuSelect:false,
      channelModalToggle: false,
      kakaoGoodsImage:"",
      kaKaoGoodsImages:[],
      goodsDescriptionImages:[],
      storeImage1Toggle:false,
      deleteOptionIds:[],
      menuGoods:{
        goodsName:"",
        saleChannels:['TABLENJOY'],
        menuOptionType:"MenuBooking",
      },
      menuOptions:[],
      usableAge:0,
      useInformation:"",
      notice:"",
      addsubimg: {
        description: "", //서브 이미지 설명
        displayOrder: 1, //서브 이미지 배열 순서
        images: [] //
        /*
        url      String      서브 이미지 URL
        width    Number      서브 이미지 width
        height   Number      서브 이미지 height
        */
      },
      addStoreImgUse:false,
      imgDragList:[],
      titleArr:[],
      storeTitleList:[],
      selectedMenuOptions:[],
      tmpSelectedMenuOptions:[],
      kakaoGoodsId: "",
      kkoOrDesToggle:"",
      usableAgeToggle:false,
      menuGoodsCode:-1,
      storeKakaoId:null,
      channelGoodsId:0,

    }
  },
  mounted() {
    this.refresh();
    this.optiionEdtReq();
    if(this.storeKakaoId){
      if(!this.storeKakaoId){
        this.menuGoods.saleChannels = ['TABLENJOY']
      }else{
        this.menuGoods.saleChannels = ['TABLENJOY','KAKAO']
      }
    }
    // this.storeImgList();
  },
  filters: {
    opnameM(val) {
      if (val == 'DepositBooking') {
        return '예약금'
      } else {
        return '메뉴'
      }
    },
    getTypeTextReturn(type) {
      if (type == 'Adult') {
        return '성인';
      } else if (type == 'Child') {
        return '소인';
      } else {
        return '유아';
      }
    },
  },
  computed: {
    imgtype() {
      return imgtype;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
  },
  methods: {
    onNewGoodsImg(newval, w, h) {
      var newimg = {
        url: newval,
        width: w,
        height: h
      };
      this.kaKaoGoodsImages.push(newimg);
      this.kaKaoGoodsImages.forEach((item,idx)=>{
        item.priority = idx
      })
      // console.log(newim
      // console.log(newimg)
    },
    onChangeGoodsImg(index, newval, w, h) {
      if (newval != "") {
        this.kaKaoGoodsImages[index].url = newval;
        // console.log(w,h)
        this.kaKaoGoodsImages[index].width = w;
        this.kaKaoGoodsImages[index].height = h;
      } else {
        this.kaKaoGoodsImages.splice(index, 1);
      }
    },
    onChangeDescriptionImg(index, newval, w, h) {
      if (newval != "") {
        this.goodsDescriptionImages[index].url = newval;
        // console.log(w,h)
        this.goodsDescriptionImages[index].width = w;
        this.goodsDescriptionImages[index].height = h;
      } else {
        this.goodsDescriptionImages.splice(index, 1);
      }
    },
    onNewGoodsDescriptionImg(newval, w, h) {
      var newimg = {
        url: newval,
        width: w,
        height: h
      };
      this.goodsDescriptionImages.push(newimg);
      this.goodsDescriptionImages.forEach((item,idx)=>{
        item.priority = idx
      })
      // console.log(newimg)
    },


    comma(str) {
      str = String(str);
      return str.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, '');
    },
    refresh() {
      reqGet(
        brandapi.base,
        brandapi.storeGoodsOptionList + "?storeCode=" + this.$route.query.stcode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.optionsetList = result.data.list;
              this.storeKakaoId = result.data.storeKakaoId
              // console.log(this.optionsetList)
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    optionModalSave() {
      // let mme = this.menuList.find((menu) => {
      //   return menu.chevron === true;
      // })
      // if (mme === undefined && this.menutRadio === false) {
      //   alert('메뉴를 선택 해주세요.');
      //   return;
      // }
      // if (this.mOpName === "") {
      //   alert('옵션명을 적어주세요.');
      //   return;
      // }
      // if (this.mPrice === '') {
      //   alert('판매가를 적어주세요.');
      //   return;
      // }
      if(this.selectedMenuOptions.length>10){
        alert("예약 옵션은 최대 10개까지 등록 가능합니다.")
        return
      }else if(this.selectedMenuOptions.length == 0){
        alert("예약 옵션을 선택해 주세요")
        return
      }
      let chkValid = true
      let errmsg = ''
      // menuGoods: {
      //   goodsName: this.menuGoods.goodsName,
      //     menuOptionType: this.menuGoods.menuOptionType,
      //     saleChannels: this.menuGoods.saleChannels,
      //     isDeposit: this.menuGoods.isDeposit,
      // },
      let tmpChkKakao = []

      if(this.menuGoods.goodsName.length ==0) {
        alert('상품명을 입력해주세요');
        return;
      }else if(this.menuGoods.saleChannels.length ==0){
        alert('판매채널을 입력해주세요');
        return;
      }else if(!this.menuGoods.menuOptionType){
        alert('예약유형을 선택해주세요.');
        return;
      }



      for(let i = 0 ; i<this.selectedMenuOptions.length; i++){
        let item = this.selectedMenuOptions[i]
        if(item.optionName.length ==0){
          chkValid = false;
          errmsg = '옵션명을 입력해 주세요'
          break;
        }else if(!item.ageGroup){
          chkValid = false;
          errmsg = '권종을 입력해 주세요'
          break;
        }else if(Number(item.salePrice)<1 && this.menuGoods.menuOptionType=='MenuBooking'){
          chkValid = false;
          errmsg = '판매가를 1원 이상 입력해 주세요'
          break;
        }else if(Number(item.salePrice)<1 && this.menuGoods.menuOptionType=='DepositBooking'){
          chkValid = false;
          errmsg = '판매가를 1원 이상 입력해 주세요'
          break;
        }else if(Number(item.salePrice) != 0 && this.menuGoods.menuOptionType=='PersonBooking' ){
          chkValid = false;
          errmsg = '판매가는 0원이 필수입니다.'
          break;
        }
      }

      // DepositBooking
      if(!chkValid){
        alert(errmsg);
        return;
      }

      let url = '';
      this.selectedMenuOptions.forEach(item=>{
        item.menuOptionType = this.menuGoods.menuOptionType
      })
      let param = {};
      if (this.optionModalName === '옵션 수정') {
        url = brandapi.updateStoreReservationGoods;
        param = {
          menuGoods: {
            goodsName: this.menuGoods.goodsName,
            menuOptionType: this.menuGoods.menuOptionType,
            saleChannels: this.menuGoods.saleChannels,
          },
          deleteOptionIds:this.deleteOptionIds,
          menuOptions:this.selectedMenuOptions,
          storeCode: this.$route.query.stcode,
          menuGoodsCode:this.menuGoodsCode
        }
      } else {
        url = brandapi.regStoreReservationGoods;
        param = {
          menuGoods: {
            goodsName: this.menuGoods.goodsName,
            menuOptionType: this.menuGoods.menuOptionType,
            saleChannels: this.menuGoods.saleChannels,
          },
          menuOptions:this.selectedMenuOptions,
          storeCode: this.$route.query.stcode,
        }
      }


      // this.optionsetList.push(param);
      reqPost(
        brandapi.base,
        url,
        this.authToken,
        param,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.menuGoodsCode = -1
              // this.refresh();

              alert('저장되었습니다.');
              this.menuGoods.goodsName= ""
              this.menuGoods.menuOptionType= "MenuBooking"
              if(!this.storeKakaoId){
                this.menuGoods.saleChannels = ['TABLENJOY']
              }else{
                this.menuGoods.saleChannels = ['TABLENJOY','KAKAO']
              }
              // this.menuGoods.isDeposit= true
              this.selectedMenuOptions = []
              this.tmpSelectedMenuOptions=[]
              this.deleteOptionIds=[]
              this.menuGoodsCode = -1
              this.refresh();
            } else {
              this.deleteOptionIds=[]
              alert(result.message);
            }
          }
        }
      );
      this.optionModal = false;
    },


    optiionEdtReq() {
      reqGet(
        brandapi.base,
        brandapi.menuList + "?storeCode=" + this.$route.query.stcode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.menuList = result.data.list;
              console.log(this.menuList)
              this.menuList.forEach(el => {
                el.push({
                  chevron: false
                })
              });
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    optionDel(optionCode) {
      if (confirm("삭제하시겠습니까? 예약시간에 연결된 옵션 모두 삭제됩니다.")) {
        reqPost(
          brandapi.base,
          brandapi.storeGoodsOptionDelete,
          this.authToken, {
            "goodsId": optionCode,
            "storeCode": this.$route.query.stcode,
          },
          (result) => {
            if (result != null) {
              if (result.code == rescode.REQ_SUCCESS) {

                this.refresh();
              } else {
                alert(result.message);
              }
            }
          }
        );
      }
    },
    optionUpDown(item, idx, optionSetList, type) {
      if (Number(idx) === 0 && type === 'up') {
        return;
      }
      if (Number(idx) === (optionSetList.length - 1) && type === 'down') {
        return;
      }
      let newOptionSetList1 = [];
      for (const idx2 in optionSetList) {
        newOptionSetList1.push({
          goodsId: optionSetList[idx2].goodsCode,
          sortNumber: Number(optionSetList[idx2].sortNumber),
        })
      }
      let newOptionSetList = JSON.parse(JSON.stringify(newOptionSetList1));
      if (type === 'up') {
        newOptionSetList.splice(Number(idx) - 1, 0, newOptionSetList[idx])
        newOptionSetList.splice(Number(idx) + 1, 1)
      } else {
        newOptionSetList.splice(Number(idx), 2, newOptionSetList[idx + 1], newOptionSetList[idx])
      }

      let number = 0;
      for (const listIdx in newOptionSetList) {
        newOptionSetList[listIdx].sortNumber = number += 1
      }
      // console.log(newOptionSetList)
      reqPost(
        brandapi.base,
        brandapi.storeGoodsNumberUpdate,
        this.authToken, {
          storeCode: this.$route.query.stcode,
          sortNumberList: newOptionSetList,
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {

              this.refresh();
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    optionEdit(rmidx, optionCode) {
      this.optionCode = optionCode;
      this.optionModal = true;
      this.optionModalName = '옵션 수정';
      // this.menuList.forEach(menu => {
      //     menu.chevron = false;
      // });
      this.menuGoodsCode = optionCode
      reqGet(
        brandapi.base,
        brandapi.viewStoreReservationGoods + "?storeCode=" + this.$route.query.stcode + "&menuGoodsCode=" + optionCode,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.menuList.forEach((el, idx) => {
                if (result.data.menuCode == el.menuCode) {
                  this.menuList[idx].chevron = true;
                } else {
                  el.chevron = false;
                }
              });

              this.menuGoods.goodsName= result.data.goodsName
              this.menuGoods.menuOptionType= result.data.menuOptionType
              this.menuGoods.saleChannels= result.data.saleChannels
              // this.menuGoods.isDeposit= result.data.isDeposit
              this.selectedMenuOptions = result.data.options.list
              this.selectedMenuOptions.forEach(item=>{
                item.newOption = false
                item.updateOptionId = item.optionCode
              })


            } else {
              alert(result.message);
            }
          }
        }
      );
      this.edtss = true;
    },
    optionMake() {
      this.optionModal = true;
      this.menutRadio = false;
      this.optionModalName = '예약상품 등록';
      this.menuList.forEach(menu => {
        menu.chevron = false;
      });
      this.mOpName = '';
      this.ageGroup = 'Adult';
      this.listPrice = '';
      this.mPrice = '';
      this.mPersonnel = '';
      if(!this.storeKakaoId){
        this.menuGoods.saleChannels = ['TABLENJOY']
      }else{
        this.menuGoods.saleChannels = ['TABLENJOY','KAKAO']
      }
    },
    changeNum: function (value) {
      return value = this.comma(this.uncomma(value));
    },
    chkMenual(val,idx) {
      // console.log(val.target._value)
      this.selectedMenuOptions[idx].salePrice = val.target._value;
      if (this.menuGoods.menuOptionType == 'DepositBooking') {
        this.selectedMenuOptions[idx].listPrice = val.target._value;
      }


    },
    menuListTrClick(code, idx) {

      if (this.menuList[idx].chevron === true) {
        this.menuList[idx].chevron = false;
        let tmpIdx = this.tmpSelectedMenuOptions.findIndex(x => x.menuCode == code)
        console.log("chevron true tmpidx = " + tmpIdx)
        if(tmpIdx>-1){
          this.tmpSelectedMenuOptions.splice(tmpIdx,1);
        }
        this.$forceUpdate()
      } else {
        // this.menuList.forEach(el => {
        //   el.chevron = false;
        // });
        this.menuList[idx].chevron = true;
        var tmp = {
          optionName: this.menuList[idx].menuName,
          salePrice: this.changeNum(this.menuList[idx].salePrice),
          ageGroup : "",
          menuCode:this.menuList[idx].menuCode,
          listPrice: this.changeNum(this.menuList[idx].listPrice),
          headCount:this.menuList[idx].headCount,
          sortNumber:this.tmpSelectedMenuOptions.length,
        }
        let tmpIdx = this.tmpSelectedMenuOptions.findIndex((x)=>{x.menuCode == code})
        console.log("else tmpidx = " + tmpIdx)
        if(tmpIdx == -1){
          this.tmpSelectedMenuOptions.push(tmp)
        }
        this.$forceUpdate()


      }


    },
    menuListTrStyle(midx) {
      let vm = this;
      if (vm.menuList[midx].chevron === true) {
        return true
      } else {
        return false
      }
    },
    optionModalClose() {
      this.menuGoods.goodsName= ""
      this.menuGoods.menuOptionType= "MenuBooking"
      if(!this.storeKakaoId){
        this.menuGoods.saleChannels = ['TABLENJOY']
      }else{
        this.menuGoods.saleChannels = ['TABLENJOY','KAKAO']
      }
      // this.menuGoods.isDeposit= true
      this.selectedMenuOptions = []
      this.deleteOptionIds=[]
      this.tmpSelectedMenuOptions=[]
      this.menuGoodsCode = -1
      this.optionModal = false;
    },
    onmenutRadio() {
        this.selectedMenuOptions = []
    },
    ageGroupChange() {

    },
    percent(nval, oval) {
      return getPercent(nval, oval);
    },
    menuSelectOpen(){
      //여기
      this.menuList.forEach(menu => {
        menu.chevron = false;
      });
      this.menuSelect = true

    },
    menuSelectClose(){
      this.tmpSelectedMenuOptions=[]
      this.menuSelect = false
    },
    menuSelectSave(){
      // this.selectedMenuOptions = [...this.tmpSelectedMenuOptions]
      // let tmpArr = this.selectedMenuOptions
      //
      if(this.tmpSelectedMenuOptions.length + this.selectedMenuOptions.length>10){
        alert("예약 옵션은 최대 10개까지 등록 가능합니다.")
        return
      }else{
        this.selectedMenuOptions.forEach((item,idx)=>{
          item.sortNumber = idx+1
        })
        this.tmpSelectedMenuOptions.forEach(item2=>{
          item2.newOption = true
          item2.sortNumber = this.selectedMenuOptions.length+1
          // item2.updateOptionId = item2.menuCode
          this.selectedMenuOptions.push(item2)
        })

        this.tmpSelectedMenuOptions = []

        this.$forceUpdate()
        this.menuSelect = false
      }

    },
    channelAdditionalModalOpen(val){

      this.channelGoodsId = Number(val);
      console.log(this.channelGoodsId)
      reqGet(
        brandapi.base,
        brandapi.storeChannelAdditionalQuery + "?storeCode=" + this.$route.query.stcode + "&menuGoodsCode=" + val,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              // result.data.

              this.kakaoGoodsId = result.data.kakaoGoodsId;
              this.usableAge = result.data.usableAge
              this.useInformation = result.data.useInformation
              this.kaKaoGoodsImages = result.data.kaKaoGoodsImages
              this.notice = result.data.notice
              this.goodsDescriptionImages = result.data.descriptionImages
              if(this.usableAge != 0 ){
                this.usableAgeToggle = true;
              }

            } else {
              alert(result.message);
            }
          }
        }
      );
      this.channelModalToggle = true

    },
    channelAdditionalModalClose(){
      this.kakaoGoodsId = "";
      this.usableAge = ""
      this.useInformation = ""
      this.kaKaoGoodsImages = []
      this.notice = ""
      this.goodsDescriptionImages = []
      this.channelModalToggle = false
      this.channelGoodsId = 0;
      this.usableAgeToggle = false
    },
    channelAdditionalModalSave(){
      console.log("this.channelGoodsId")
      console.log(this.channelGoodsId)
      if(this.usableAgeToggle == false){
        this.usableAge = 0
      }

      let reqData = {
        goodsId: this.channelGoodsId,
        usableAge: this.usableAge,
        useInformation: this.useInformation,
        notice:this.notice,
        kaKaoGoodsImages: this.kaKaoGoodsImages,
        descriptionImages: this.goodsDescriptionImages,
      }
      reqPost(
        brandapi.base,
        brandapi.storeKakaoGoodsUpdate,
        this.authToken, reqData,
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.channelModalToggle = false;
              this.kakaoGoodsId = "";
              this.usableAge = ""
              this.useInformation = ""
              this.kaKaoGoodsImages = []
              this.notice = ""
              this.goodsDescriptionImages = []
              this.usableAgeToggle = false
              this.channelGoodsId = 0;

              this.refresh();
            } else {
              alert(result.message);
            }
          }
        }
      );



    },


    menuUpDown(item, idx, optionSetList, type) {
      if (Number(idx) === 0 && type === 'up') {
        return;
      }
      if (Number(idx) === (optionSetList.length - 1) && type === 'down') {
        return;
      }
      let newOptionSetList1 = [];

      for (const idx2 in optionSetList) {
        newOptionSetList1.push({
          optionName: optionSetList[idx2].optionName, //
          salePrice: optionSetList[idx2].salePrice, //
          ageGroup : optionSetList[idx2].ageGroup, //
          menuCode:optionSetList[idx2].menuCode,
          listPrice: optionSetList[idx2].listPrice,
          headCount:optionSetList[idx2].headCount,
          sortNumber:optionSetList[idx2].sortNumber,
          newOption:optionSetList[idx2].newOption,
          updateOptionId:optionSetList[idx2].updateOptionId,


        })



      }

      let newOptionSetList = JSON.parse(JSON.stringify(newOptionSetList1));
      if (type === 'up') {
        newOptionSetList.splice(Number(idx) - 1, 0, newOptionSetList[idx])
        newOptionSetList.splice(Number(idx) + 1, 1)

      } else {
        newOptionSetList.splice(Number(idx), 2, newOptionSetList[idx + 1], newOptionSetList[idx])
      }

      let number = 0;
      for (const listIdx in newOptionSetList) {
        newOptionSetList[listIdx].sortNumber = number += 1
      }


      this.selectedMenuOptions = newOptionSetList
      this.$forceUpdate()
    },
    menuDel(idx,code){
      this.deleteOptionIds.push(code)
      this.selectedMenuOptions.splice(idx,1);
      this.selectedMenuOptions.forEach((item,idx)=>{
        item.sortNumber = idx+1
      })
      this.$forceUpdate();
    },
    // titleArrClcik(titleArr) {
    //   // console.log(titleArr)
    //   let vm = this;
    //   vm.imgDragList = [];
    //   titleArr.storeImages.forEach((el, idx) => {
    //     vm.imgDragList.push({
    //       url: el.url,
    //       width: el.width,
    //       height: el.height,
    //     })
    //   });
    //   vm.imgDragListOriginal = JSON.parse(JSON.stringify(vm.imgDragList));
    // },
    // storeImgList() {
    //   reqGet(
    //     brandapi.base,
    //     brandapi.storeImageslist + "?storeCode=" + this.$route.query.stcode,
    //     this.authToken,
    //     (result) => {
    //       if (result != null) {
    //         if (result.code == rescode.REQ_SUCCESS) {
    //           this.imgDragList = [];
    //           this.imgDragListOriginal = [];
    //           this.titleArr = [];
    //           // console.log(result.data.list)
    //           result.data.list.forEach(el => {
    //             // console.log(.url)
    //             this.titleArr.push({
    //               title: el.title,
    //               storeImages: el.storeImages
    //             })
    //             this.imgDragListOriginal = JSON.parse(JSON.stringify(this.imgDragList));
    //           });
    //           this.titleArrClcik(result.data.list[0]);
    //         } else {
    //           alert(result.message);
    //         }
    //       }
    //     }
    //   );
    // },
    uploadImgArr() {
      let add = this.fakeArray.filter(function (item) {
        return item.url !== '';
      });
      this.change(add);
    },
    saveDeposit(){
      let tmpArr = []
      if(this.menuGoods.menuOptionType =='PersonBooking'){
        this.selectedMenuOptions.forEach(item=>{
          item.listPrice = 0
          item.salePrice = 0
        })
      }
    },
    menuLineAddit(){
      let length = this.selectedMenuOptions.length
      if(length==10){
        alert("예약 옵션은 최대 10개까지 등록 가능합니다.")
          return
      }
      this.selectedMenuOptions.forEach((item,idx)=>{
        item.sortNumber = idx+1
      })
      this.selectedMenuOptions.push({
        optionName: "", //
        salePrice: "", //
        ageGroup : "Adult", //
        listPrice: "",
        headCount:1,
        sortNumber:Number(length+1),
        newOption:true,
      })
      this.$forceUpdate()
    }


  }
}
</script>

<style scoped>
ul .tab li {
  list-style: none;
  display: inline-block;
  border-bottom: 1px solid #337ab7;
  margin-left: 15px;
  color: #337ab7;
  cursor: pointer;
}

ul.titleArr {
  margin: 0;
  text-align: left;
  padding: 0;
  margin-left: 14px;
}
ul.titleArr li {
  list-style: none;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

ul.titleArr li:first-child {
  margin: 0;
}

.imgBox{
  width: 36px;
  height: 36px;
  border-radius: 4px;
  align-content: center;
}


th {
  background-color: #dee2e685;
}

td {
  vertical-align: middle !important;
}

.right-btn {
  text-align: right;
}

.chevron {
  display: none;
}

.chevron.on {
  display: block;
}

input.newTitle {
  display: inline-block;
}

.text-center {
  text-align: center !important;
}

.tbl_h200 {
  height: 222px;
  overflow-y: scroll;
}

.optionUpDownBtn {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
}
.check-item-length {
  border: solid 1px #d2d6de;
  height: 34px;
  align-content: center;
  padding-right: 16px;
  padding-left: 16px;
  text-align: center;
}
.d-flex{
  display: flex;
}

.tr-selected {
  background-color: #D3E4F4;
}

.modal-body.addmodals {
  display: flex;
  justify-content: space-between;
}
.modal-body.addmodals .imgBoxDivGroup {
  width: 50%;
}
.addimgInputGroup {
  width: 45%;
  vertical-align: top;
}

.modal-body.addmodals .imgBoxDivGroup .imgDragbox {
  width: 100%;
}
.imgBoxDivGroup {
  display: flex;
  width: 100%;
  justify-content: center;
}

.imgboxChild:nth-child(1n) {
  margin-right: 10px;
}

.imgBoxDivGroup>div .imgboxChild {
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
  display: inline-block;
  object-fit: contain;
  background: #f5f5f5;
  padding: 12px;
}

.imgDragbox {
  text-align: center;
  padding: 20px;
  margin-left: 20px;
  width: 50%;
}

@media (min-width: 768px) {
  .mtitle {
    margin-bottom: 50px;
  }

  .weeksel {
    margin-left: 30px;
  }

  .pgheader {
    margin-bottom: 10px;
  }

  .linebg {
    width: 100%;
    height: 20px;
  }

  .addlist {
    display: inline-flex;
  }
}

@media (max-width: 768px) {
  .weeksel {
    margin-top: 10px;
  }

  .pgheader {
    margin-bottom: 30px;
  }

  .linebg {
    width: 100%;
    height: 20px;
  }

  .ptlbl {
    position: absolute;
    top: 20px;
    left: -50%;
    width: 200%;
  }

  .pttop {
    position: absolute;
    bottom: 20px;
    left: -50%;
    width: 200%;
  }

  .addlist>.thumbnail {
    margin-bottom: 10px;
  }
}
</style>
