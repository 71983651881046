<template>
  <div class="root">
    <div class="input-group">
      <select class="form-control" style="width: auto;margin-left:0px" v-model="dweektype" @change="onChangeWeek($event)">
        <option value="Everyday">매일</option>
        <option value="Weekday">주중</option>
        <option value="Weekend">주말</option>
        <option value="1">월</option>
        <option value="2">화</option>
        <option value="3">수</option>
        <option value="4">목</option>
        <option value="5">금</option>
        <option value="6">토</option>
        <option value="7">일</option>
      </select>
      <select class="form-control" style="width: auto;" v-model="fhours" @change="onReportChange">
        <option value>시</option>
        <option value="0">00시</option>
        <option value="1">01시</option>
        <option value="2">02시</option>
        <option value="3">03시</option>
        <option value="4">04시</option>
        <option value="5">05시</option>
        <option value="6">06시</option>
        <option value="7">07시</option>
        <option value="8">08시</option>
        <option value="9">09시</option>
        <option value="10">10시</option>
        <option value="11">11시</option>
        <option value="12">12시</option>
        <option value="13">13시</option>
        <option value="14">14시</option>
        <option value="15">15시</option>
        <option value="16">16시</option>
        <option value="17">17시</option>
        <option value="18">18시</option>
        <option value="19">19시</option>
        <option value="20">20시</option>
        <option value="21">21시</option>
        <option value="22">22시</option>
        <option value="23">23시</option>
      </select>
      <select class="form-control" style="width: auto;" v-model="fminutes" @change="onReportChange">
        <option value>분</option>
        <option value="0">00분</option>
        <option value="10">10분</option>
        <option value="20">20분</option>
        <option value="30">30분</option>
        <option value="40">40분</option>
        <option value="50">50분</option>
      </select>
    </div>
    <div class="input-group">
      <span class="input-group-addon" style="border:none;width: fit-content;">-</span>
    </div>
    <div class="input-group">
      <select class="form-control" style="width: auto;" v-model="thours" @change="onReportChange">
        <option value>시</option>
        <option value="0">00시</option>
        <option value="1">01시</option>
        <option value="2">02시</option>
        <option value="3">03시</option>
        <option value="4">04시</option>
        <option value="5">05시</option>
        <option value="6">06시</option>
        <option value="7">07시</option>
        <option value="8">08시</option>
        <option value="9">09시</option>
        <option value="10">10시</option>
        <option value="11">11시</option>
        <option value="12">12시</option>
        <option value="13">13시</option>
        <option value="14">14시</option>
        <option value="15">15시</option>
        <option value="16">16시</option>
        <option value="17">17시</option>
        <option value="18">18시</option>
        <option value="19">19시</option>
        <option value="20">20시</option>
        <option value="21">21시</option>
        <option value="22">22시</option>
        <option value="23">23시</option>
      </select>
      <select class="form-control" style="width: auto;" v-model="tminutes" @change="onReportChange">
        <option value>분</option>
        <option value="0">00분</option>
        <option value="10">10분</option>
        <option value="20">20분</option>
        <option value="30">30분</option>
        <option value="40">40분</option>
        <option value="50">50분</option>
      </select>
        <button type="button" class="btn btn-default" @click="onRemove">삭제</button>
    </div>
  </div>
</template>

<script>
import {filterHours, filterMinutes} from "../lib/utility";
export default {
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    change:{
      type: Function,
      default: ()=>{},
    }
  },
  data() {
    return {
      dweektype:this.dayOfWeekType(),
      monday:this.value.monday,
      tuesday:this.value.tuesday,
      wednesday:this.value.wednesday,
      thursday:this.value.thursday,
      friday:this.value.friday,
      saturday:this.value.saturday,
      sunday:this.value.sunday,
      fhours:this.fromHours,
      fminutes:this.fromMinutes,
      thours:this.toHours,
      tminutes:this.toMinutes,
    };
  },
  watch: {
    value()
    {
      this.dweektype = this.dayOfWeekType();
      this.monday = this.value.monday;
      this.tuesday = this.value.tuesday;
      this.wednesday = this.value.wednesday;
      this.thursday = this.value.thursday;
      this.friday = this.value.friday;
      this.saturday = this.value.saturday;
      this.sunday = this.value.sunday;
      this.fhours = this.fromHours;
      this.fminutes = this.fromMinutes;
      this.thours = this.toHours;
      this.tminutes = this.toMinutes;
    },
  },
  computed: {
    /*
    openingHourType String        Open24Hours: 24시간 영업, RunningTime: 영업 시간 지정, BreakTime: 휴식 시간 지정
                    [BreakTime]
    timeFrom        String        openingHourType 이 Custom 일 경우 영업 시작 시간을 지정
                    HH:mm:ss
    timeTo          String        openingHourType 이 Custom 일 경우 영업 종료 시간을 지정
                    HH:mm:ss
    comment         String        부가 설명
    */
   fromHours(){
     return filterHours(this.value.timeFrom);
   },
   fromMinutes(){
     return filterMinutes(this.value.timeFrom);
   },
   toHours(){
     return filterHours(this.value.timeTo);
   },
   toMinutes(){
     return filterMinutes(this.value.timeTo);
   },
  },
  mounted() {
    this.dweektype = this.dayOfWeekType();
    this.monday = this.value.monday;
    this.tuesday = this.value.tuesday;
    this.wednesday = this.value.wednesday;
    this.thursday = this.value.thursday;
    this.friday = this.value.friday;
    this.saturday = this.value.saturday;
    this.sunday = this.value.sunday;
    this.fhours = this.fromHours;
    this.fminutes = this.fromMinutes;
    this.thours = this.toHours;
    this.tminutes = this.toMinutes;
  },
  methods: {
    /*
    dayOfWeekType   String          Everyday: 매일, Weekday: 주중, Weekend: 주말, Custom: 직접 지정
                    [Everyday, Weekday, Weekend, Custom]
    monday          Boolean       dayOfWeekType 이 Custom 일 경우 사용: 월요일을 지정할 때 true 로 설정
    tuesday         Boolean       dayOfWeekType 이 Custom 일 경우 사용: 화요일을 지정할 때 true 로 설정
    wednesday       Boolean       dayOfWeekType 이 Custom 일 경우 사용: 수요일을 지정할 때 true 로 설정
    thursday        Boolean       dayOfWeekType 이 Custom 일 경우 사용: 목요일을 지정할 때 true 로 설정
    friday          Boolean       dayOfWeekType 이 Custom 일 경우 사용: 금요일을 지정할 때 true 로 설정
    saturday        Boolean       dayOfWeekType 이 Custom 일 경우 사용: 토요일을 지정할 때 true 로 설정
    sunday          Boolean       dayOfWeekType 이 Custom 일 경우 사용: 일요일을 지정할 때 true 로 설정
  */
    dayOfWeekType(){
      if(this.value.dayOfWeekType != "Custom")
      {
        return this.value.dayOfWeekType;
      }else{
        if(this.value.monday)
        {
          return "1";
        }else if(this.value.tuesday)
        {
          return "2";
        }else if(this.value.wednesday)
        {
          return "3";
        }else if(this.value.thursday)
        {
          return "4";
        }else if(this.value.friday)
        {
          return "5";
        }else if(this.value.saturday)
        {
          return "6";
        }else
        {
          return "7";
        }
      }
    },
    onReportChange()
    {
      var t_dweektype = this.dweektype;
      if(t_dweektype != "Everyday" && t_dweektype !="Weekday" && t_dweektype != "Weekend")
      {
        t_dweektype = "Custom";
      }
      var edit_cmmt = {
        monday:this.monday,
        tuesday:this.tuesday,
        wednesday:this.wednesday,
        thursday:this.thursday,
        friday:this.friday,
        saturday:this.saturday,
        sunday:this.sunday,
        dayOfWeekType:t_dweektype,
        openingHourType:"BreakTime",
        timeFrom:this.fhours.toString().padStart(2, 0)+":"+this.fminutes.toString().padStart(2, 0)+":00",
        timeTo:this.thours.toString().padStart(2, 0)+":"+this.tminutes.toString().padStart(2, 0)+":00",
        comment:"",
      };
      this.change(edit_cmmt);
    },
    onChangeWeek(event)
    {
      var weekday = event.target.value;
      this.monday = false;
      this.tuesday = false;
      this.wednesday = false;
      this.thursday = false;
      this.friday = false;
      this.saturday = false;
      this.sunday = false;

      if(weekday == "1")
      {
        this.monday = true;
      }else if(weekday == "2"){
        this.tuesday = true;
      }else if(weekday == "3"){
        this.wednesday = true;
      }else if(weekday == "4"){
        this.thursday = true;
      }else if(weekday == "5"){
        this.friday = true;
      }else if(weekday == "6"){
        this.saturday = true;
      }else if(weekday == "7"){
        this.sunday = true;
      }
      this.onReportChange();
    },

    onChangeFromHours(event){
      var fromhours = event.target.value;
      var timeFrom = null;
      if(fromhours != "")
      {
        timeFrom = fromhours+":"+this.fminutes;
      }
      var edit_cmmt = {
        ... this.value,
        timeFrom:timeFrom
      };
      this.$emit('input', edit_cmmt);
      this.change(edit_cmmt);
    },
    onChangeFromMinutes(event){
      var fromminutes = event.target.value;
      var timeFrom = null;
      if(fromminutes != "")
      {
        timeFrom = this.fhours+":"+fromminutes;
      }
      var edit_cmmt = {
        ... this.value,
        timeFrom:timeFrom
      };
      this.$emit('input', edit_cmmt);
      this.change(edit_cmmt);
    },
    onChangeToHours(event){
      var tohours = event.target.value;
      var timeTo = null;
      if(tohours != "")
      {
        timeTo = tohours+":"+this.tminutes;
      }
      var edit_cmmt = {
        ... this.value,
        timeTo:timeTo
      };
      this.$emit('input', edit_cmmt);
      this.change(edit_cmmt);
    },
    onChangeToMinutes(event){
      var tominutes = event.target.value;
      var timeTo = null;
      if(tominutes != "")
      {
        timeTo = this.thours+":"+tominutes;
      }
      var edit_cmmt = {
        ... this.value,
        timeTo:timeTo
      };
      this.$emit('input', edit_cmmt);
      this.change(edit_cmmt);
    },
    onRemove(){
      this.change(null);
    }
  },
};
</script>
<style scoped>
select {
  margin: 0px 5px;
}
@media (min-width: 768px) {
  .root {
    display: inline-flex;
  }
}
</style> 