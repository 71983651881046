<template>
  <section class="content">
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">LS 발급 주문취소</h3>
      </div>
      <div class="box-body form">
        <div class="form-group">
          <h4>[주문 취소 처리]</h4>
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="lscompany.orderNo"
                     placeholder="주문번호 (테이블엔조이 주문번호)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="orderJustSetCancelled">실행</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* 잔디 알림 "[Ls 티켓발권 취소 실패] 발생 시 사용 *</b><br>
                        1) ls쪽 취소가 티켓단위로 제대로 되어있는지 확인<br>
                        2) 전부 취소가 되어있는 상태일 경우<br>
                        3) 해당 기능으로 주문 취소 처리.<br>
                        4) [주문관리 > 주문내역조회 > 주문정보]에서 "주문상태 -> 취소완료" 상태인지 확인.<br>
                    </span>
        </div>
      </div>
    </div>
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">네이버 (NAVER)</h3>
      </div>
      <div class="box-body form">
        <div class="form-group">
          <h4>[ 네이버 스마트스토어 상품 주문 조회 (연동 매칭) ]</h4>
          <span class="help-block"><b>* 조회 시작일로 부터 1일이내*</b><br></span>
          <div class="form-group row">

            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="coupang.dateFrom"
                     placeholder="조회시작 날짜 (YYYY-mm-dd)">
            </div>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="coupang.dateTo"
                     placeholder="조회종료 날짜 (YYYY-mm-dd)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="naverPucharesMatching">실행</button>
            </div>
          </div>
          <span class="help-block">

                  <b>* 잔디 알림 "[NAVER]  orderPutProcess - B2bOrderCreate 발생 시 사용 (1번만 클릭)  *</b><br><br>
                        1) 관리자페이지에 해당 주문이 있는지 확인 및 B2B / ht_channel_order_create 테이블에 해당 주문 상태값이 "Fail" 인지 확인<br>
                        2) 해당주문이 없으면서 상태값이 "Fail"일 경우 해당 기능으로 주문 재연동 처리.<br>
                        3) [주문관리 > 주문내역조회]에서 해당 주문 확인 및 b2b ht_channel_order_create 테이블 상태값이 "Complete"으로 변경되었는지 확인<br>
                        * 클릭했음에도 잔디에 알림이 발생이되면 해당 상품 확인해볼 것 *
                    </span>
        </div>
        <hr>
      </div>
    </div>

    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">카카오 톡스토어 (KAKAOS)</h3>
      </div>
      <div class="box-body form">
        <div class="form-group">
          <h4>[ 쿠폰 발송완료 처리 ]</h4>
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="kakao.completeB2bOrderNo"
                     placeholder="B2B주문번호 (카카오 톡스토어 주문번호)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="kakaoTicketsComplete">실행</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* 잔디 알림 "[KAKAOS] ticketsSendComplete" 발생 시 사용 *</b><br>
                        1) [테이블엔조이 관리자 > 주문관리]에서 주문연동 정상 처리되었는지 확인.<br>
                        2) [카카오 톡스토어 관리자 > 판매관리 > 통합 주문관리]에서 "발송 준비중" 상태인지 확인.<br>
                        3) 해당 기능으로 발송완료 처리.<br>
                        4) [카카오 톡스토어 관리자 > 판매관리 > 통합 주문관리]에서 "발송 완료" 상태인지 확인.<br>
                    </span>
        </div>
      </div>
    </div>
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">티몬 (TMON)</h3>
      </div>
      <div class="box-body form">
        <div class="form-group">
          <h4>[ 딜(상품) 정보 조회 ]</h4>
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="tmon.searchMatchGoodsCode"
                     placeholder="외부사 딜 번호 (채널 상품 관리코드)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="tmonDealsDetail">조회</button>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <textarea class="form-control" v-model="tmon.showDealsDetail" rows="8"></textarea>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-group">
          <h4>[ 딜(상품) 수동 매칭 ]</h4>
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="tmon.MatchingMatchGoodsCode"
                     placeholder="외부사 딜 번호 (채널 상품 관리코드)">
            </div>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="tmon.vendorPolicyNo"
                     placeholder="수수료정책번호(vendorPolicyNo)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="tmonDealsMatching">실행</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* '딜(상품) 정보 조회'로 상품정보 확인 후 해당 기능 사용 *</b><br>
                    </span>
        </div>
        <hr>
        <div class="form-group">
          <h4>[ 딜(상품) 판매 중지 ]</h4>
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="tmon.pauseTmonDealNo" placeholder="티몬 딜 번호 (채널 판매코드)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="tmonDealsPause">실행</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* 관리자에 매칭되지 않은 상품에 한하여 티몬에 직접 판매중지 처리 시 사용 *</b><br>
                    </span>
        </div>
        <hr>
        <div class="form-group">
          <h4>[ 딜(상품) 판매 재개 ]</h4>
          <div class="form-group row">
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="tmon.resumeTmonDealNo" placeholder="티몬 딜 번호 (채널 판매코드)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="tmonDealsResume">실행</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* 관리자에 매칭된 상품에 한하여 티몬에 직접 판매재개 필요 시 사용 *</b><br>
                    </span>
        </div>
        <hr>
        <div class="form-group">
          <h4>[ 딜(상품) 카운트 조회 ]</h4>
          <div class="form-group row">
            <div class="col-sm-12">
              <b style="margin-right: 20px">딜 개수 : {{ tmon.dealsCount }}</b>
              <button type="button" class="btn btn-default" @click="tmonDealsCount">조회</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* 티몬에 등록된 상품(전체상태) 개수 조회 시 사용 *</b><br>
                    </span>
        </div>
        <hr>
        <div class="form-group">
          <h4>[ 딜(상품) 리스트 조회 ]</h4>
          <div class="form-group row">
            <div class="col-sm-1">
              <div class="input-group checkbox">
                <label><input type="checkbox" :value="tmon.dealOnSale" v-model="tmon.dealOnSale"/>판매중</label>
              </div>
            </div>
            <div class="col-sm-2">
              <input type="text" class="form-control" v-model="tmon.pageFrom" placeholder="시작 페이지 (1부터)">
            </div>
            <div class="col-sm-2">
              <input type="text" class="form-control" v-model="tmon.pageTo" placeholder="종료 페이지">
            </div>
            <div class="col-sm-7">
              <button type="button" class="btn btn-default" @click="tmonDeals">조회</button>
            </div>
          </div>
          <span class="help-block">
                        <b>* 1페이지당 50개씩 조회됨. 한번에 너무 많은 페이지 조회 하지 마세요. *</b><br>
                    </span>
          <div class="form-group row">
            <div class="col-sm-12">
              <textarea class="form-control" v-model="tmon.showDeals" rows="8"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">쿠팡 (COUPANG)</h3>
      </div>
      <div class="box-body form">
        <hr>
        <div class="form-group">
          <h4>[ 쿠팡 티켓 구매자 정보조회 (연동 매칭) ]</h4>
          <span class="help-block"><b>* 조회 시작일로 부터 1주일이내*</b><br></span>
          <div class="form-group row">

            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="coupang.dateFrom"
                     placeholder="조회시작 날짜 (YYYY-mm-dd)">
            </div>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="coupang.dateTo"
                     placeholder="조회종료 날짜 (YYYY-mm-dd)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="coupangPucharesMatching">실행</button>
            </div>
          </div>
          <span class="help-block">

                  <b>* 잔디 알림 "[COUPANG]  orderPutProcess - B2bOrderCreate 발생 시 사용 (1번만 클릭)  *</b><br><br>
                        1) 관리자페이지에 해당 주문이 있는지 확인 및 B2B / ht_channel_order_create 테이블에 해당 주문 상태값이 "Fail" 인지 확인<br>
                        2) 해당주문이 없으면서 상태값이 "Fail"일 경우 해당 기능으로 주문 재연동 처리.<br>
                        3) [주문관리 > 주문내역조회]에서 해당 주문 확인 및 b2b ht_channel_order_create 테이블 상태값이 "Complete"으로 변경되었는지 확인<br>
                        * 클릭했음에도 잔디에 알림이 발생이되면 해당 상품 확인해볼 것 *
                    </span>
        </div>
        <hr>
      </div>
    </div>
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">롯데온 (LotteOn)</h3>
      </div>
      <div class="box-body form">
        <hr>
        <div class="form-group">
          <h4>[ e쿠폰 발송/재발송 목록 조회 ]</h4>
          <div class="form-group row">

            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="lotteOn.dateFrom"
                     placeholder="조회시작 날짜 (YYYY-mm-dd)">
            </div>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model="lotteOn.dateTo"
                     placeholder="조회종료 날짜 (YYYY-mm-dd)">
            </div>
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="lotteOnECouponOrderSearch">실행</button>
            </div>
          </div>

        </div>
        <hr>
      </div>
    </div>
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">지마켓 (GMARKET)</h3>
      </div>
      <div class="box-body form">
        <hr>
        <div class="form-group">
          <h4>[ 지마켓 주문 재연동 시도]</h4>
          <div class="form-group row">
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="gmarketECouponOrderCreate">실행</button>
            </div>
          </div>
          <span class="help-block">
                <b>* 잔디 알림 "[GMK]  orderPutProcess - B2bOrderCreate 발생 시 사용 (1번만 클릭)  *</b><br><br>
                        1) 관리자페이지에 해당 주문이 있는지 확인 및 B2B / ht_channel_order_create 테이블에 해당 주문 상태값이 "Fail" 인지 확인<br>
                        2) 해당주문이 없으면서 상태값이 "Fail"일 경우 해당 기능으로 주문 재연동 처리.<br>
                        3) [주문관리 > 주문내역조회]에서 해당 주문 확인 및 b2b ht_channel_order_create 테이블 상태값이 "Complete"으로 변경되었는지 확인<br>
                        * 클릭했음에도 잔디에 알림이 발생이되면 해당 상품 확인해볼 것 *
                    </span>
        </div>
        <hr>
      </div>
    </div>
    <div class="box box-solid box-primary">
      <div class="box-header">
        <h3 class="box-title">옥션 (AUCTION)</h3>
      </div>
      <div class="box-body form">
        <hr>
        <div class="form-group">
          <h4>[ 옥션 주문 재연동 시도]</h4>
          <div class="form-group row">
            <div class="col-sm-9">
              <button type="button" class="btn btn-default" @click="auctionECouponOrderCreate">실행</button>
            </div>
          </div>
          <span class="help-block">
                            <b>* 잔디 알림 "[AUCTION]  orderPutProcess - B2bOrderCreate 발생 시 사용 (1번만 클릭)  *</b><br><br>
                        1) 관리자페이지에 해당 주문이 있는지 확인 및 B2B / ht_channel_order_create 테이블에 해당 주문 상태값이 "Fail" 인지 확인<br>
                        2) 해당주문이 없으면서 상태값이 "Fail"일 경우 해당 기능으로 주문 재연동 처리.<br>
                        3) [주문관리 > 주문내역조회]에서 해당 주문 확인 및 b2b ht_channel_order_create 테이블 상태값이 "Complete"으로 변경되었는지 확인<br>
                        * 클릭했음에도 잔디에 알림이 발생이되면 해당 상품 확인해볼 것 *
                    </span>
        </div>
        <hr>
      </div>
    </div>
  </section>
</template>

<script>
import {rescode, menuval} from "../../lib/consts";
import {b2bTestScheduler, reqGet, reqGetb2bTestScheduler, reqPost} from "../../lib/request";
import {b2bapi, orderapi} from "../../lib/api";
import moment from "moment";

export default {
  created() {
    this.$store.dispatch("doSetPath", {
      path1: menuval.LV1_DEV,
      path2: menuval.LV1_HOME,
      title: "B2B 연동 수동처리 (※ 테스트 하지마세요!!! 채널 측 운영서버에 즉시 반영됩니다! ※)",
    });
  },
  data() {
    return {
      lscompany: {
        orderNo: ""
      },
      kakao: {
        completeB2bOrderNo: ""
      },
      tmon: {
        searchMatchGoodsCode: "",
        showDealsDetail: "",
        MatchingMatchGoodsCode: "",
        vendorPolicyNo: "",
        pauseTmonDealNo: "",
        resumeTmonDealNo: "",
        dealsCount: 0,
        dealOnSale: true,
        pageFrom: null,
        pageTo: null,
        showDeals: ""
      },
      coupang: {
        dateFrom: moment().subtract(0,'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      naver: {
        dateFrom: moment().subtract(0,'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      lotteOn: {
        dateFrom: moment().subtract(0,'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    authToken() {
            return this.$store.getters.authToken;
        }
  },
  methods: {
    orderJustSetCancelled() {
      if(!this.lscompany.orderNo.trim()) {
        alert("'주문번호 (테이블엔조이 주문번호)' 를 입력하세요.");
        return false;
      }
      var target = {
        orderNo: this.lscompany.orderNo.trim(),
        orderCancel : true
      };

      reqPost(
        orderapi.base,
        orderapi.justSetCancelled,
        this.authToken, {
          target: target,
          ignoreB2bTicketCancel: true,
        },
         (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
        }
      );
    },
    kakaoTicketsComplete() {
      if (!this.kakao.completeB2bOrderNo.trim()) {
        alert("'B2B주문번호 (카카오 톡스토어 주문번호)' 를 입력하세요.");
        return false;
      }
      reqPost(
        b2bapi.base,
        b2bapi.kakaoTicketsComplete,
        this.authToken,
        {
          b2bOrderNo: this.kakao.completeB2bOrderNo.trim()
        },
        (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
        }
      );
    },
    tmonDealsDetail() {
      if (!this.tmon.searchMatchGoodsCode.trim()) {
        alert("'외부사 딜 번호 (채널 상품 관리코드)' 를 입력하세요.");
        return false;
      }
      reqPost(
        b2bapi.base,
        b2bapi.tmonDealsDetail,
        this.authToken,
        {
          matchGoodsCode: this.tmon.searchMatchGoodsCode.trim()
        },
        (result) => {
          if (result != null) {
            if (result.code === rescode.REQ_SUCCESS) {
              this.tmon.showDealsDetail = JSON.stringify(result.data, null, 4);
            } else {
              alert(result.message);
            }
          } else {
            alert("실패");
          }
        }
      );
    },
    tmonDealsMatching() {
      if (!this.tmon.MatchingMatchGoodsCode.trim() || !this.tmon.vendorPolicyNo.trim()) {
        alert("'외부사 딜 번호 (채널 상품 관리코드) 수수료정책코드' 를 입력하세요.");
        return false;
      }
      reqPost(
        b2bapi.base,
        b2bapi.tmonDealsMatching,
        this.authToken,
        {
          matchGoodsCode: this.tmon.MatchingMatchGoodsCode.trim(),
          vendorPolicyNo: Number(this.tmon.vendorPolicyNo.trim())
        },
        (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
        }
      );
    },
    tmonDealsPause() {
      if (!this.tmon.pauseTmonDealNo.trim()) {
        alert("'티몬 딜 번호 (채널 판매코드)' 를 입력하세요.");
        return false;
      }
      reqPost(
        b2bapi.base,
        b2bapi.tmonDealsPause,
        this.authToken,
        {
          tmonDealNo: this.tmon.pauseTmonDealNo.trim()
        },
        (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
        }
      );
    },
    tmonDealsResume() {
      if (!this.tmon.resumeTmonDealNo.trim()) {
        alert("'티몬 딜 번호 (채널 판매코드)' 를 입력하세요.");
        return false;
      }
      reqPost(
        b2bapi.base,
        b2bapi.tmonDealsResume,
        this.authToken,
        {
          tmonDealNo: this.tmon.resumeTmonDealNo.trim()
        },
        (result) => {
          if (result != null) {
            alert(result.message);
          } else {
            alert("실패");
          }
        }
      );
    },
    tmonDealsCount() {
      reqGet(
        b2bapi.base,
        b2bapi.tmonDealsCount,
        this.authToken,
        (result) => {
          if (result != null) {
            if (result.code === rescode.REQ_SUCCESS) {
              this.tmon.dealsCount = result.data.dealsCount;
            } else {
              this.tmon.dealsCount = 0;
              alert(result.message);
            }
          } else {
            this.tmon.dealsCount = 0;
            alert("실패");
          }
        }
      );
    },
    tmonDeals() {
      reqPost(
        b2bapi.base,
        b2bapi.tmonDeals,
        this.authToken,
        {
          onSale: this.tmon.dealOnSale,
          pageFrom: this.tmon.pageFrom,
          pageTo: this.tmon.pageTo
        },
        (result) => {
          if (result != null) {
            if (result.code === rescode.REQ_SUCCESS) {
              this.tmon.showDeals = JSON.stringify(result.data.itemList, null, 4);
            } else {
              alert(result.message);
            }
          } else {
            alert("실패");
          }
        }
      );
    },
    naverPucharesMatching() {
      // YYYY-mm-dd 정규 표현식
      let regexDate = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;
      if (!this.naver.dateFrom.trim() || !this.naver.dateTo.trim()) {
        alert("조회 시작, 종료 날짜를 입력해주세요!");
        return false;
      }
      if (!regexDate.test(this.naver.dateFrom) || !regexDate.test(this.naver.dateTo)) {
        alert("날짜는 YYYY-MM-DD 형식으로 입력해주세요.");
        return false;
      }
      // 날짜차이 계산
      let dateDiff = this.getDateDiff(this.naver.dateFrom, this.naver.dateTo);
      if( dateDiff>=2) {
        alert("시작일 기준 1일이내에 조회하실 수 있습니다.");
        return false;
      }
      reqGetb2bTestScheduler(
        b2bapi.b2bTestScheduler,
        b2bapi.naverPucharesMatching +
        "?searchStartDate=" +
        this.naver.dateFrom.trim() +
        "&searchEndDate=" +
        this.naver.dateTo.trim(),
        this.authToken,
        (result) => {
          console.log(result);
          if (result != null) {
            alert("성공");
          } else {
            alert("실패");
          }
        }
      );
    },
    coupangPucharesMatching() {
      // YYYY-mm-dd 정규 표현식
      let regexDate = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;
      if (!this.coupang.dateFrom.trim() || !this.coupang.dateTo.trim()) {
        alert("조회 시작, 종료 날짜를 입력해주세요!");
        return false;
      }
      if (!regexDate.test(this.coupang.dateFrom) || !regexDate.test(this.coupang.dateTo)) {
        alert("날짜는 YYYY-MM-DD 형식으로 입력해주세요.");
        return false;
      }
      // 날짜차이 계산
      let dateDiff = this.getDateDiff(this.coupang.dateFrom, this.coupang.dateTo);
      if( dateDiff>= 7) {
        alert("시작일 기준 7일이내에 조회하실 수 있습니다.");
        return false;
      }
      reqGetb2bTestScheduler(
        b2bapi.b2bTestScheduler,
        b2bapi.coupangPucharesMatching +
        "?searchStartDate=" +
        this.coupang.dateFrom.trim() +
        "&searchEndDate=" +
        this.coupang.dateTo.trim(),
        this.authToken,
        (result) => {
          console.log(result);
          if (result != null) {
            alert("성공");
          } else {
            alert("실패");
          }
        }
      );
    },
    lotteOnECouponOrderSearch() {
      // YYYY-mm-dd 정규 표현식
      let regexDate = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;
      if (!this.lotteOn.dateFrom.trim() || !this.lotteOn.dateTo.trim()) {
        alert("조회 시작, 종료 날짜를 입력해주세요!");
        return false;
      }
      if (!regexDate.test(this.lotteOn.dateFrom) || !regexDate.test(this.lotteOn.dateTo)) {
        alert("날짜는 YYYY-MM-DD 형식으로 입력해주세요.");
        return false;
      }
      reqGetb2bTestScheduler(
        b2bapi.b2bTestScheduler,
        b2bapi.lotteOneCouponOrderSearch +
        "?searchStartDate=" +
        this.lotteOn.dateFrom.trim() +
        "&searchEndDate=" +
        this.lotteOn.dateTo.trim(),
        this.authToken,
        (result) => {
          if (result != null) {
            alert("성공");
          } else {
            alert("실패");
          }
        }
      );
    },
    gmarketECouponOrderCreate() {
      reqGetb2bTestScheduler(
        b2bapi.b2bTestScheduler,
        b2bapi.gmarketECouponOrderCreate,
        this.authToken,
        (result) => {
          if (result != null) {
            alert("성공");
          } else {
            alert("실패");
          }
        }
      );
    },
    auctionECouponOrderCreate() {
      reqGetb2bTestScheduler(
        b2bapi.b2bTestScheduler,
        b2bapi.auctionECouponOrderCreate,
        this.authToken,
        (result) => {
          if (result != null) {
            alert("성공");
          } else {
            alert("실패");
          }
        }
      );
    },
    getDateDiff(dateFrom, dateTo) {
        const DateFrom = new Date(dateFrom);
        const DateTo = new Date(dateTo);
        const diffDate = DateFrom.getTime() - DateTo.getTime();
        return Math.abs(diffDate / (1000 * 3600 * 24));
    }

  }
}
</script>

<style scoped>

</style>
