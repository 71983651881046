<template>
    <div class="modal" style="display:block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h3>예약 조회</h3>
                </div>
                <div class="box-body">
                    <!-- <div class="form-group has-float-label">
                        <button @click="onClickPrev()" type="button" class="arrowbtn">◀</button>
                        <DatePicker v-model="searchDate" />
                        <button @click="onClickNext()" type="button" class="arrowbtn">▶</button>
                    </div> -->
                    <div class="divfelex">
                        <div class="form-group has-float-label firstbox">
                            <v-select :options="searchOptions" v-model="searchKind" :reduce="item => item.value"
                                label="label"></v-select>
                        </div>
                        <div>
                            <input :placeholder="searchPlaceholder" v-model.trim="searchKeyword" class="heightUnity"
                                :maxlength="searchKind == 'PhoneNumber4' ? 4 : 13" @input="onlyNumberCheck"
                                @keyup.enter="onSearch" type="text" />
                            <button type="button" v-show="!searchFlag" @click="onSearch"
                                variant="outline-primary default"
                                :class="searchFlag ? 'heightUnity' : 'd-none d-sm-block heightUnity'">조회</button>
                            <button v-show="searchFlag" @click="searchCancel" variant="outline-primary default"
                                :class="searchFlag ? 'd-none d-sm-block heightUnity' : 'heightUnity'">취소</button>
                        </div>
                    </div>
                    <div v-if="isLoad">
                        <booking-list :items="items" :fields="fields" :per-page="perPage" :currentPage="currentPage" />
                        <div class="box-footer text-center" v-if="totalPages > 1">
                            <Pagination :curpage="pageNumber" :totalpages="totalPages" :visibles="shownum" :click="gotoPage" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="text-align:center !important">
                    <button type="button" class="btn btn-default" @click="closemodal">닫기</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import Pagination from "../../components/Pagination";
import DatePicker from "../../components/DatePicker.vue";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css'
import BookingVisitDetail from '../../views/booking/components/BookingDetailView'
import BookingList from "../../views/booking/components/BookingList";
import BookingVisitedCancel from '../../views/booking/components/BookingVisitedCancel.vue';
import { alignTableCenter } from '../../lib/utility.js';

import {
    brandapi,
    orderapi
} from "../../lib/api";
import {
    reqGet,
    reqPost
} from "../../lib/request";

import {
    menuval,
    rescode,
    imgtype
} from "../../lib/consts";


export default {
    components: {
        Pagination,
        DatePicker,
        'v-select': vSelect,
        'booking-visit-detail': BookingVisitDetail,
        'booking-list': BookingList,
        'booking-visited-cancel': BookingVisitedCancel
    },
    props: ['data', 'dateData', 'topBookingBtn', 'calendarType', 'calendarTime', 'timeUnit'],
    data() {
        return {
            shownum: 10,
            cancelResparvar: true,
            noPenalty: false,
            noShowFlag: null,
            transProps: {
                // Transition name
                name: 'list'
            },
            searchKind: 'NoKeyword',
            searchOptions: [
            {
                label: '전체',
                value:'NoKeyword',
            },    
            {
                label: '전화번호',
                value: 'PhoneNumber4'
            },
            {
                label: '예약번호',
                value: 'BookingNo'
            },
            ],
            searchDate: this.$moment().format("YYYY-MM-DD"),
            isLoad: false,
            searchFlag: false,
            currentPage: 1,
            perPage: 30,
            lastPage: 1,
            total: 1,
            searchKeyword: "",
            items: [],
            fields: [{
                key: 'bookingNo',
                label: "예약번호",
                ...alignTableCenter
            },
            {
                key: 'storeName',
                label: "매장명",
                ...alignTableCenter
            },
            {
                key: 'visitorName',
                label: "예약자",
                ...alignTableCenter
            },
            {
                key: 'visitorPhone',
                label: "전화번호",
                ...alignTableCenter
            },
            {
                key: 'reservationDate',
                label: "방문예정일",
                ...alignTableCenter
            },
            {
                key: 'reservationDateTime',
                label: "방문예정시간",
                ...alignTableCenter
            },
            {
                key: 'headcount',
                label: "인원",
                ...alignTableCenter
            },
            {
                key: 'bookingStatus',
                label: "상태",
                ...alignTableCenter
            },
            {
                key: 'freeBooking',
                label: "예약경로",
                ...alignTableCenter
            },
            ],
            bookingInfo: {
                bookingNo: null,
                visitorName: null,
                visitorPhone: null,
                visitorRequests: null,
                storeName: null,
                reservationDate: null,
                bookingRequestDate: null,
                bookingStatus: null,
                orderItems: [],
            },
            selectedBookingNo: null,
            visitedInfo: [],
            inception: true,
            pageNumber: 1,
            totalPages: 0,
        }
    },
    watch: {
        currentPage() {
            this.searchData()
            // console.log('currentPage()')
        },
        searchDate(value) {
            if (value && this.inception === true) {
                this.searchDate = this.$moment(this.dateData).format("YYYY-MM-DD");
                this.searchData()
                // console.log('searchDate(value)')
            }
        },
        noShowFlag() { },
    },
    computed: {
        searchPlaceholder: {
            get: function () {
                return this.searchKind === "PhoneNumber4" ? "전화번호 뒷4자리를 입력하세요." : "예약번호를 입력하세요";
            }
        },
        authToken() {
            return this.$store.getters.authToken;
        },
    },
    mounted() {
        this.searchKeyword = this.data;
        // console.log(this.topBookingBtn)
        if (this.topBookingBtn == true) {
            this.searchData()
        }
        if (this.searchKeyword == '') {
            this.searchData()
            // console.log("this.searchKeyword != ''")
        }
        console.log(this.dateData)
        if (this.dateData) {
            this.searchDate = '';
            this.searchDate = this.$moment(this.dateData).format("YYYY-MM-DD");
            let today = this.$moment(new Date()).format("YYYY-MM-DD") ;   
            if (today == this.searchDate){
                this.searchDate = this.$moment(this.dateData).format("YYYY-MM-DD");
                this.searchData()
            }
            console.log(this.searchDate)
        }
    },
    methods: {
        onClickPrev() {
            this.inception = false;
            let newDay = this.$moment(this.searchDate).subtract(1, 'day').toDate();
            this.searchDate = this.$moment(newDay).format("YYYY-MM-DD");
        },
        onClickNext() {
            this.inception = false;
            let newDay = this.$moment(this.searchDate).add(1, 'day').toDate();
            this.searchDate = this.$moment(newDay).format("YYYY-MM-DD");
        },
        onSearch() {
            if (this.searchKeyword === "" && this.searchKind !== 'NoKeyword') {
                alert('검색어를 입력 하세요.');
                return false
            }
            this.currentPage = 1
            // this.searchFlag = true
            this.searchData()
        },
        searchCancel() {
            this.searchFlag = false
            this.searchKeyword = ""
            this.searchData()
        },
        gotoPage(pnum) {
            this.pageNumber = pnum;
            this.searchData(false);
        },
        searchData(flag) {
            this.isLoad = true;
            this.items = [];
            // bookingspage
            var pageNumber = 0;
            if (!flag) {
                pageNumber = this.pageNumber - 1;
            } else {
                this.pageNumber = 1;
            }
            // console.log(this.pageNumber)
            let timestart = '';
            let timeend = '';
            let timestartstrmHours = this.calendarTime.substring(0, 2);
            let timestartstrmmintus = this.calendarTime.substring(3, 5);
            if (this.calendarType === 'w') {
                if (this.timeUnit === 'M30') {
                    if (timestartstrmmintus === '00') {
                        timestart = this.calendarTime + ':00';
                        timeend = timestartstrmHours +':'+ '29:00';
                    } else {
                        timestart = this.calendarTime + ':00';
                        timeend = timestartstrmHours + ':'+ '59:00';
                    }
                } else {
                    timestart = this.calendarTime + ':00';
                    timeend = timestartstrmHours + ':'+ '59:00';
                }
            }
            let _keywordType = this.searchKeyword === "" ? "NoKeyword" : this.searchKind //키워드 내용없을경우
            const params = {
                dateType: 'VisitDate',
                dateFrom: this.$moment(this.searchDate).format('YYYY-MM-DD'),
                dateTo: this.$moment(this.searchDate).format('YYYY-MM-DD'),
                keywordType: _keywordType,
                keyword: this.searchKeyword,
                pageNumber: pageNumber,
                pageSize: this.perPage,
                storeCode: this.$route.query.stcode,
                timeFrom: timestart,
                timeTo: timeend,
            }

            reqPost(
                orderapi.base,
                orderapi.bookingspage,
                this.authToken,
                params,
                (result) => {
                    if (result != null) {
                        if (result.code == rescode.REQ_SUCCESS) {
                            const _data = result.data
                            this.totalPages = result.data.totalPages;
                            this.total = result.data.totalElements
                            let goods2 = _data.content;
                            this.items = goods2;
                          
                            // console.log(this.$moment(this.dateData).format("HH:MM"))
                        } else {
                            alert(result.message);
                        }
                    }
                }
            );


        },
        closemodal() {
            this.$emit('closeModal');
            this.inception = true;
        },
        onlyNumberCheck(value) {
            if (!isNaN(value.data)) {
                return;
            } else {
                let val = value.data.replace(/[^0-9]/g, '')
                this.searchKeyword = val
            }

        },
    }
}
</script>

<style>
.modal {
    background-color: #00000047;
    overflow: scroll;
}

.modal-footer {
    text-align: center;
}

.divfelex {
    display: flex;
    justify-content: flex-start;
}

.firstbox {
    min-width: 164px;
    line-height: 29px;
    margin-right: 10px;
}

button.arrowbtn {
    background: none;
    border: 1px solid #dedede;
    padding: 6px 10px;
    margin: 0 2px;
}

.heightUnity {
    margin-right: 3px;
    line-height: 32px;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    min-width: 214px;
    padding: 2px 5px;
}

button.heightUnity {
    background: none;
    border: 1px solid #2c6689;
    border-radius: 4px;
    padding: 2px 14px;
    color: #2c6689;
    min-width: 71px;
}
</style>