<template>
  <section class="content">
    <div class="box box-solid">
      <div class="box-body">
        <div style="display:inline-flex;flex-wrap: wrap;width: 100%;">
          <div class="input-group radio" style="margin-right:10px">
            <label>
              <input type="radio" :value="null" v-model="completed"/>전체
            </label>
            <label>
              <input type="radio" :value="false" v-model="completed"/>미처리
            </label>
            <label>
              <input type="radio" :value="true" v-model="completed"/>처리완료
            </label>
          </div>
          <DatePicker v-model="fromDate" :last="toDate"/>
          <h4 style="margin:5px 10px;">~</h4>
          <DatePicker v-model="toDate" :first="fromDate" style="margin-right:10px"/>
          <select class="form-control" v-model="keywordType" @change="refreshPage" style="width:auto;margin-right:10px">
            <option value="NoKeyword"></option>
            <option value="TitleContaining">제목</option>
            <option value="RegisteredBy">작성자 ID</option>
          </select>
          <div class="input-group" style="flex:1;max-width:400px;min-width:100px">
            <input type="text" class="form-control" v-model="keyword" @change="refreshPage"/>
            <span class="input-group-btn">
              <button type="button" class="btn btn-default" @click="refreshPage">검색</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin:0px;margin-bottom:20px">
      <select class="form-control pull-right" style="width: 70px;" v-model="pageSize" @change="refreshPage">
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>25</option>
        <option>30</option>
        <option>35</option>
        <option>40</option>
        <option>45</option>
      </select>
    </div>
    <div class="box box-solid">
      <div class="box-body table-responsive p-0">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>
                <input type="checkbox" class="allch" @change="allckech($event)"/>
              </th>
              <th class="text-center">번호</th>
              <th class="text-center">처리상태</th>
              <th>제목</th>
              <th class="text-center">등록일</th>
              <th class="text-center">작성자</th>
              <th class="text-center">조회</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cusq, index) in content" :key="cusq.questionNo">
              <th>
                <input type="checkbox" class="chitem" :value="cusq.questionNo"/>
              </th>
              <td class="text-center">{{ totalElements - (startnum + index) }}</td>
              <td class="text-center">{{getProcessStr(cusq.completed)}}</td>
              <td class="linkcmp" @click="showEditmodal(cusq.questionNo)" style="display: inline-flex;position: relative;">{{cusq.title}}<div v-if="cusq.secreteQuestion" class="passwordkey"></div></td>
              <td class="text-center">{{viewDate(cusq.registeredDate)}}</td>
              <td class="text-center">{{cusq.registeredBy}}</td>
              <td class="text-center">{{cusq.viewedCount | numFormat}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-footer text-center">
        <Pagination
          :curpage="pageNumber"
          :totalpages="totalPages"
          :visibles="shownum"
          :click="gotoPage"
        />
      </div>
    </div>
    <div class="modal" v-if="editmodal" :style="{'display': 'block', 'z-index':'2000'}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">문의내역 상세</div>
          <div class="modal-body form-horizontal" style="padding:0px;">
            <div class="form-group" style="margin:0px;">
              <div class="col-sm-6" style="padding:0px;">
                <div class="table-responsive editmodal p-0">
                  <table class="table table-hover text-nowrap">
                    <colgroup>
                      <col width="100px">
                      <col width="*">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>제목</th>
                        <td>{{queinf.title}}</td>
                      </tr>
                      <tr>
                        <th>작성자</th>
                        <td>{{queinf.registeredBy}}</td>
                      </tr>
                      <tr>
                        <th>등록일</th>
                        <td>{{viewDate(queinf.registeredDate)}}</td>
                      </tr>
                      <tr>
                        <th colspan="2">문의내용</th>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <textarea class="form-control" rows="5" disabled v-model="queinf.question"></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-sm-6" style="padding:0px;">
                <div class="table-responsive editmodal p-0">
                  <table class="table table-hover text-nowrap">
                    <colgroup>
                      <col width="25%">
                      <col width="25%">
                      <col width="25%">
                      <col width="25%">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th colspan="4">답변</th>
                      </tr>
                      <tr>
                        <td colspan="4"><textarea class="form-control" rows="8" v-model="answer" :disabled="queinf.completed"></textarea></td>
                      </tr>
                      <tr v-if="queinf.completed">
                        <th>답변일</th>
                        <td>{{viewDate(queinf.completedDate)}}</td>
                        <th>답변ID</th>
                        <td>{{queinf.completedBy}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="button" class="btn btn-default" @click="closeEditmodal">닫기</button>
              <button type="button" v-if="!queinf.completed" class="btn btn-default" @click="doAnswer" :disabled="buttonDissabled">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { rescode, menuval } from "../../lib/consts";
import routepath from "../../lib/routes";
import Pagination from "../../components/Pagination";
import { reqPost } from "../../lib/request";
import { board } from "../../lib/api";
import { DateFormat } from "../../lib/utility";
import DatePicker from "../../components/DatePicker";
import moment from 'moment';
import $ from 'jquery';
export default {
  components: {
    Pagination,
    DatePicker,
  },
  created() {
    if(localStorage.initval == "1")
    {
      if (localStorage.CusCen_keywordType) {
        this.keywordType = localStorage.CusCen_keywordType;
      }
      if (localStorage.CusCen_keyword) {
        this.keyword = localStorage.CusCen_keyword;
      }
      if (localStorage.CusCen_Number) {
        this.pageNumber = parseInt(localStorage.CusCen_Number);
      }
      if (localStorage.CusCen_Size) {
        this.pageSize = parseInt(localStorage.CusCen_Size);
      }
      if (localStorage.CusCen_fromDate) {
        this.fromDate = localStorage.CusCen_fromDate;
      }
      if (localStorage.CusCen_toDate) {
        this.toDate = localStorage.CusCen_toDate;
      }
    }
    this.$store.dispatch('doSetPath', {path1:menuval.LV1_BOD, path2:menuval.LV1_HOME, title:"고객센터"});
  },
  watch: {
    keywordType(newVal) {
      localStorage.CusCen_keywordType = newVal;
    },
    keyword(newkey)
    {
      localStorage.CusCen_keyword = newkey;
    },
    pageNumber(newnum)
    {
      localStorage.CusCen_Number = newnum;
    },
    pageSize(newsize)
    {
      localStorage.CusCen_Size = newsize;
    },
    fromDate(nfrom)
    {
      localStorage.CusCen_fromDate = nfrom;
    },
    toDate(nto)
    {
      localStorage.CusCen_toDate = nto;
    },
  },
  data() {
    return {
      shownum: 10, //pagination에 보여지는 번호개수
      keywordType: "NoKeyword",
      keyword: "",
      fromDate:moment().format("YYYY-01-01"),
      toDate:moment().format("YYYY-MM-DD"),
      completed:null,
      pageNumber: 1, //현재 페이지 번호
      pageSize: 20, //페지당 개수
      content: [],
      /**
       * questionNo               Number    문의 번호
       * title                    String    제목
       * registeredDate           String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * registeredBy             String    등록자. 익명으로 글을 쓴 경우 null
       * viewedCount              Number    조회 회수
       * secreteQuestion          Boolean   비밀글 여부
       * completed                Boolean   true: 처리완료, false: 미처리
       * deleted                  Boolean   삭제 여부
       * subPosts                 Array     하위 문의 사항
       * subPosts[].questionNo    Number    문의 번호
       * subPosts[].title         String    제목
       * subPosts[].registeredDate    String    UTC yyyy-MM-ddTHH:mm:ss 등록일
       * subPosts[].registeredBy      String    등록자. 익명으로 글을 쓴 경우 null
       * subPosts[].viewedCount       Number    조회 회수
       * subPosts[].secreteQuestion   Boolean   비밀글 여부
       * subPosts[].completed         Boolean   true: 처리완료, false: 미처리
       * subPosts[].deleted           Boolean   삭제 여부
       */
      totalPages: 0, //전체 페이지 수
      totalElements: 0, //전체 아이템 수
      numberOfElements: 0, //현재 조회된 아이템 수
      first: true, //첫 페이지일 경우 true
      last: false, //마지막 페이지일 경우 true
      editmodal: false,
      queinf:{
        questionNo:0,//문의 번호
        title:"",//제목
        question:"",//문의 내용
        registeredDate:"",//UTC yyyy-MM-ddTHH:mm:ss 등록일
        registeredBy:"",//등록자. 익명으로 글을 쓴 경우 null
        viewedCount:0,//조회 회수
        secreteQuestion:false,//비밀글 여부
        answer:"",//답변
        completedBy:"",//처리완료 관리자 ID
        completed:false,//처리완료여부. true: 처리완료, false: 미처리
        completedDate:"",//UTC yyyy-MM-ddTHH:mm:ss 처리완료일
        deleted:false,//삭제 여부
      },
      answer:"",
      buttonDissabled : false,
    };
  },
  computed: {
    routes() {
      return routepath;
    },
    authToken() {
      return this.$store.getters.authToken;
    },
    startnum() {
      return (this.pageNumber - 1) * this.pageSize;
    },
  },
  mounted() {
    if(localStorage.initval == "0")
    {
      localStorage.initval = "1";
      localStorage.CusCen_keywordType = this.keywordType;
      localStorage.CusCen_keyword = this.keyword;
      localStorage.CusCen_Number = this.pageNumber;
      localStorage.CusCen_Size = this.pageSize;
      localStorage.CusCen_fromDate = this.fromDate;
      localStorage.CusCen_toDate = this.toDate;
    }
    this.refreshPage();
  },
  methods: {
    allckech(event){
      $('input:checkbox.chitem').prop('checked', event.target.checked);
    },
    viewDate(val) {
      return DateFormat(val, "YYYY-MM-DD HH:mm");
    },
    gotoPage(pnum) {
      this.pageNumber = pnum;
      this.refreshPage(false);
    },
    refreshPage(flag) {
      var pageNumber = 0;
      if(!flag) {
        pageNumber = this.pageNumber - 1;
      } else {
        this.pageNumber = 1;
      }

      reqPost(
        board.base,
        board.cusServ_page,
        this.authToken,
        {
          keywordType:this.keywordType,
          keyword:this.keyword,
          completed:this.completed,
          fromDate:this.fromDate,
          toDate:this.toDate,
          pageNumber:pageNumber,
          pageSize:this.pageSize
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.content = result.data.content;
              this.totalPages = result.data.totalPages; //전체 페이지 수
              this.totalElements = result.data.totalElements; //전체 아이템 수
              this.numberOfElements = result.data.numberOfElements; //현재 조회된 아이템 수
            } else {
              this.content = [];
              this.totalPages = 0; //전체 페이지 수
              this.totalElements = 0; //전체 아이템 수
              this.numberOfElements = 0; //현재 조회된 아이템 수
              alert(result.message);
            }
          } else {
            this.content = [];
            this.totalPages = 0; //전체 페이지 수
            this.totalElements = 0; //전체 아이템 수
            this.numberOfElements = 0; //현재 조회된 아이템 수
          }
        }
      );
    },
    closeEditmodal() {
      this.editmodal = false;
    },
    showEditmodal(questionNo) {
      reqPost(
        board.base,
        board.cusServ_detail,
        this.authToken,
        {
          questionNo:questionNo
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.queinf = result.data;
              this.answer = result.data.answer;
              this.editmodal = true;
            } else {
              alert(result.message);
            }
          }
        }
      );
    },
    getProcessStr(val)
    {
      return val?"처리완료":"미처리";
    },
    doAnswer()
    {
      this.buttonDissabled = true;
      if(this.answer == null)
      {
        this.answer = "";
      }
      var t_answer = this.answer.trim();
      if(t_answer == "")
      {
        this.buttonDissabled = false;
        alert("답변을 입력하세요.");
        return;
      }
      reqPost(
        board.base,
        board.cusServ_answer,
        this.authToken,
        {
          questionNo:this.queinf.questionNo,
          answer:this.answer
        },
        (result) => {
          if (result != null) {
            if (result.code == rescode.REQ_SUCCESS) {
              this.buttonDissabled = false;
              this.refreshPage();
              this.closeEditmodal();
              alert("답변이 등록되었습니다.");
            } else {
              this.buttonDissabled = false;
              alert(result.message);
            }
          }
        }
      );
    }
  },
};
</script>

<style scoped>
.checkbox > label {
  margin-right: 10px;
}

.radio{
  margin-bottom:0px;
}

.radio > label {
  margin-right: 10px;
}

.modal {
  background-color: #00000047;
  overflow: scroll;
}

.modal-footer {
  text-align: center;
}

.editmodal th {
  background-color: #dee2e685;
}

.editmodal::-webkit-scrollbar {
  display: none;
}

.modal-body .control-label{
  margin:0px;
}

.passwordkey{
  background-image: url("/static/img/icon/ic_pswkey.png");
  background-repeat: no-repeat;
  background-position: center;
  width:10px;
  height:10px;
  position: absolute;
  right: -10px;
  bottom: 10px;
}

.modal .table{
  margin-bottom: 0px;
}
</style>
